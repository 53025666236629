import React from 'react'
import { useIsActiveResearchDexcom } from '@src/utils/hooks'
import { DexcomSensorSettingsContainer } from './DexcomSensorSettings'
import { SensorSettingsContainer } from './SensorSettings'

export const SensorSettingsScreen = () => {
  const isActiveResearchDexcom = useIsActiveResearchDexcom()

  if (isActiveResearchDexcom) {
    return <DexcomSensorSettingsContainer />
  }

  return <SensorSettingsContainer />
}
