import { intersection } from 'lodash'
import { TypeFilter, TypeFilterKey } from '@src/models/app.types'

export const OCCURRED_AT = 'occurred_at'

const DEFAULT_ORDER_BY_FIELD = OCCURRED_AT

enum HistoryTypes {
  Meals = 'meals',
  Activities = 'activities',
  Measurements = 'measurements',
  Journal = 'journal',
}

const ALL_FIELDS = {
  includes: () => true,
}

const VALID_FIELDS_BY_EVENT_TYPES = {
  [HistoryTypes.Meals]: ALL_FIELDS,
  [HistoryTypes.Activities]: [OCCURRED_AT],
  [HistoryTypes.Measurements]: [OCCURRED_AT],
  [HistoryTypes.Journal]: [OCCURRED_AT],
}

export enum Fields {
  OrderBy = 'orderBy',
  Types = 'types',
  Query = 'query',
}

export const getTypesAfterOrderByChanged = (
  orderByValue: string,
  possibleTypes: TypeFilter,
  selectedTypes: TypeFilter,
) => {
  const possibleTypesKeys = Object.keys(possibleTypes) as HistoryTypes[]
  const eligibleTypesKeys = possibleTypesKeys.filter((type) =>
    VALID_FIELDS_BY_EVENT_TYPES[type].includes(orderByValue),
  ) as TypeFilterKey[]

  if (eligibleTypesKeys.length === possibleTypesKeys.length) {
    // all possible types can have this field, nothing to do
    return selectedTypes
  }

  // select only types that can have this field
  const selectedTypesKeys = Object.keys(selectedTypes)
  const newTypes = {} as TypeFilter

  if (
    selectedTypesKeys.length === 0 ||
    intersection(selectedTypesKeys, eligibleTypesKeys).length === 0
  ) {
    // no eligible types selected, we need to select types that can have this field
    eligibleTypesKeys.forEach((type) => {
      newTypes[type] = possibleTypes[type]
    })
  } else {
    // keep current selected types
    eligibleTypesKeys.forEach((type) => {
      if (selectedTypes[type]) {
        newTypes[type] = selectedTypes[type]
      }
    })
  }

  return newTypes
}

export const getOrderByAfterTypesChanged = (types: TypeFilter, selectedOrderBy: string) => {
  if (selectedOrderBy === DEFAULT_ORDER_BY_FIELD) {
    return selectedOrderBy
  }

  const typesKeys = Object.keys(types) as TypeFilterKey[]
  const eligibleTypesKeys = typesKeys.filter((type) =>
    VALID_FIELDS_BY_EVENT_TYPES[type].includes(selectedOrderBy),
  )

  if (typesKeys.length === 0 || typesKeys.length !== eligibleTypesKeys.length) {
    // orderBy should be 'occurred_at' as non-meals type is selected
    return DEFAULT_ORDER_BY_FIELD
  }

  return selectedOrderBy
}
