import React from 'react'
import { Icon as DefaultIcon, IconProps as DefaultIconProps } from '@ui-kitten/components'
import { IconPackMap, IconPackKey } from '../icons'

type IconProps = Omit<DefaultIconProps, 'name'> & {
  name: IconPackKey
}

/**
 * @deprecated Use generic Icon component instead
 */
export const withPack = (WrappedComponent: React.ComponentType<DefaultIconProps>) => (
  props: IconProps,
) => {
  const { name, ...others } = props
  const item = IconPackMap[name]
  return <WrappedComponent name={item.name} pack={item.pack} {...others} />
}

/**
 * @deprecated Use generic Icon component instead
 */
export const LegacyIcon = withPack(DefaultIcon)
