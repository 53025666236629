import { gql } from '@apollo/client'

export const DISABLE_LIBRE_LINKUP_CONNECTION = gql`
  mutation disableLinkupConnection {
    disableLinkupConnection {
      active
      status
      lastSyncedAt
    }
  }
`
