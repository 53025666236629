import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Icon } from '@components/base'
import { useNotificationBanner } from '@src/components/notifications/utils/hooks'
import {
  NotificationEngineNotificationKind,
  NotificationEngineNotificationStyle,
  NotificationEngineScreen,
} from '@src/types'
import { AppStackParamList } from '@src/navigation/types'
import { ChatlikeNotificationBanner } from './ChatlikeNotificationBanner'

interface NotificationBannerProps {
  screen: NotificationEngineScreen
  calendarDate?: string
}

export const NotificationBanner = ({ screen, calendarDate }: NotificationBannerProps) => {
  const styles = useStyleSheet(themedStyles)
  const data = useNotificationBanner(screen, calendarDate)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  if (!data) {
    return null
  }

  const { iconName, content, action, actionButtonLabel, dismiss, style, kind } = data

  if (style === NotificationEngineNotificationStyle.Chatlike) {
    const isLabResultsNotification = [
      NotificationEngineNotificationKind.SubmitLabResultsCustomDate,
      NotificationEngineNotificationKind.SubmitLabResultsPeriodical,
    ].includes(kind)

    const dismissHandler = () => {
      dismiss()
      if (isLabResultsNotification) {
        navigation.navigate('SetReminderModal', {
          dismissButtonText: 'Dismiss Notification',
          goBackOnDismiss: false,
        })
      }
    }

    return (
      <ChatlikeNotificationBanner
        action={action}
        actionButtonLabel={actionButtonLabel}
        content={content}
        dismiss={dismissHandler}
      />
    )
  }

  return (
    <View style={styles.banner}>
      <View style={styles.contentWrapper}>
        <Icon name={iconName} style={styles.icon} />
        <Text type="regular" style={styles.content}>
          {content}
        </Text>
      </View>
      <View style={styles.buttonWrapper}>
        <Button
          type="transparent"
          size="small"
          accessibilityLabel="Mark As Read"
          style={styles.button}
          onPress={dismiss}
        >
          Mark As Read
        </Button>
        {action && (
          <Button
            type="primary"
            size="small"
            accessibilityLabel={actionButtonLabel}
            style={styles.button}
            onPress={action}
          >
            {actionButtonLabel}
          </Button>
        )}
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  banner: {
    margin: 16,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
  },
  contentWrapper: {
    flexDirection: 'row',
    marginBottom: 12,
  },
  icon: {
    color: 'theme.error.base',
    marginRight: 16,
  },
  content: {
    flex: 1,
  },
  buttonWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    marginLeft: 8,
  },
})
