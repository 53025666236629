import React, { useEffect } from 'react'
import { useRoute } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { AppRouteProp } from '@navigation/types'
import { useMeal } from '@screens/Meals/hooks'
import { getDraftMeal } from '@screens/Meals/utils'
import { mealDraftSelector } from '@selectors/meals'
import { MealEditorWrapper } from './MealEditorWrapper'

export const EditMeal = () => {
  const dispatch = useDispatch()
  const { params } = useRoute<AppRouteProp<'EditMeal'>>()

  const { meal } = useMeal(params.id)
  const draftMeal = useSelector(mealDraftSelector)

  useEffect(() => {
    if (!meal) {
      return
    }

    dispatch({
      type: 'meals/saveDraft',
      payload: getDraftMeal(meal, true),
    })
  }, [dispatch, meal])

  if (draftMeal.id !== params.id) {
    return null
  }

  return <MealEditorWrapper />
}
