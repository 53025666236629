import { gql } from '@apollo/client'

export const FETCH_PRODUCTS = gql`
  query allProducts($category: BillingProductCategory) {
    allProducts(category: $category) {
      products {
        id
        key
        core
        dietitian
        dietitianMenu
        recurring
        addon
        order
        title
        programsName
        details
        includedOptions
        includesCgm
        icon
        description
        shortDescription
        commitmentMonths
        billingDateChangeable
        requiresShipping
        trialDays
        interval {
          key
          description
          suffix
        }
        price
        productGroup {
          id
          key
          order
          kind
          title
          description
          shortDescription
          details
          interval {
            key
            description
            suffix
          }
        }
        plan {
          id
          stripeId
          amount
        }
        incompatibleAddons {
          id
          key
        }
        eligibleUpgradeProducts {
          id
          key
        }
        insuranceCoverage
        eligibilityListItems
        schedulingRule {
          appointmentType
          chargeType
          count
          key
        }
      }
    }
  }
`
