import React from 'react'
import { IndexPath, Select, SelectItem } from '@ui-kitten/components'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { Answer, Maybe, Response } from '@src/types'
import { useSetDefaultValue } from './Shared/hooks'

interface SelectInputProps {
  field: ControllerRenderProps<FieldValues, any>
  label?: Maybe<string>
  response: Response
}

export const SelectInput = ({ field, response, label }: SelectInputProps) => {
  const { onChange, value } = field

  useSetDefaultValue({ fieldValue: value, onChange, defaultValue: null })

  const answers = response.answersGroups?.flatMap((answerGroup) => answerGroup.answers) || []

  const selectedIndex = answers.findIndex((answer) => answer.value === value)
  const selectedAnswer: Answer | undefined = answers[selectedIndex]
  const selectedIndexPath = selectedIndex >= 0 ? new IndexPath(selectedIndex) : undefined

  return (
    <Select
      placeholder={label || undefined}
      selectedIndex={selectedIndexPath}
      value={selectedAnswer?.label}
      onSelect={(index) => {
        const answer = answers[(index as IndexPath).row]
        onChange(answer.value)
      }}
      size="medium"
      style={{ marginBottom: 16 }}
    >
      {answers.map((answer) => (
        <SelectItem key={answer.value} title={answer.label} />
      ))}
    </Select>
  )
}
