import React, { useEffect } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import pluralize from 'pluralize'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { Benefit } from '@components'
import { useGoBack } from '@src/utils'
import { OnboardingStackParamsList } from '@src/navigation/types'

export const FreeTrialSubscriptionOffer = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const goBack = useGoBack()
  const route = useRoute<RouteProp<OnboardingStackParamsList, 'FreeTrialSubscriptionOffer'>>()
  const { product } = route.params

  useEffect(() => {
    if (!product.trialDays) {
      goBack()
    }
  }, [goBack, product.trialDays])

  if (!product.trialDays) {
    return null
  }

  const onSubscribePress = () => {
    navigation.navigate('SubscriptionCheckout', { product })
  }

  return (
    <SafeAreaView edges={['top', 'bottom']} style={styles.contentContainer}>
      <Text style={styles.title} type="title-2">
        First {pluralize('day', product.trialDays, true)} free
      </Text>
      {!!product.description && (
        <Text style={styles.description} type="large">
          {product.description}
        </Text>
      )}
      <View style={styles.benefits}>
        {product.details?.map((benefit) => (
          <Benefit key={benefit} benefit={benefit} />
        ))}
      </View>
      <View style={styles.buttons}>
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Try Free & Subscribe"
          onPress={onSubscribePress}
        >
          Try Free & Subscribe
        </Button>

        <Text style={styles.buttonDescription} type="regular">
          {pluralize('day', product.trialDays, true)} free, then ${product.price}/
          {product.interval.suffix}. Cancel Anytime
        </Text>
      </View>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  contentContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  benefits: {
    marginTop: 8,
  },
  content: {
    flex: 1,
    paddingHorizontal: 16,
  },
  title: {
    textAlign: 'center',
    marginTop: 24,
  },
  description: {
    alignSelf: 'center',
    marginTop: 16,
    maxWidth: 300,
  },
  detailsContainer: {
    flex: 1,
    marginTop: 32,
  },
  buttons: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  buttonDescription: {
    marginTop: 16,
    color: 'theme.text.secondary',
  },
})
