import React, { Ref, useContext, useEffect, useRef, useState } from 'react'
import { ScrollView, View } from 'react-native'
import FastImage, { ImageStyle } from 'react-native-fast-image'
import { Control, Controller, ControllerFieldState, FieldValues, useWatch } from 'react-hook-form'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { InlineAlert, NavigationBar, ProgressBar, ScrollableAvoidKeyboard } from '@src/components'
import {
  UiInlineAlert,
  Maybe,
  Question,
  Response,
  SurveysConfigObjectQuestionsGroupQuestionResponseKind as ResponseKinds,
  AddressCountries,
} from '@src/types'
import { QuestionnaireScreenName, QuestionnaireStackParamList } from '@src/navigation/types'
import { Validators } from '@src/utils'
import { MarkdownView } from '@src/components/Markdown'
import { userSelector } from '@src/selectors/app'
import { getCountryCodeByCountry } from '@src/utils/phoneInput'
import { inputTypeToInputComponentMap } from '../components/inputs'
import { AnswerRef, InputPropsBase } from '../components/inputs/types'
import { QuestionText } from '../components/QuestionText'
import { QuestionnaireContext } from '../questionnaire.navigator'
import { useAnswersGroups } from '../components/inputs/Shared/hooks'

export const QuestionnaireQuestionResponse = (
  props: InputPropsBase & {
    response: Response
    questionIndex?: number
    sectionIndex?: number
    control: Control<FieldValues>
    label?: Maybe<string>
    fieldState: ControllerFieldState
  },
) => {
  const InputComponent = inputTypeToInputComponentMap[props.response.kind]
  if (!InputComponent) {
    console.error('Missing component', props.response.kind)

    return <View />
  }

  return <InputComponent {...props} kindMetadata={props.response.kindMetadata} />
}

export const QuestionnaireQuestion = ({
  fieldKey,
  label,
  required,
  response,
  note,
  imageUrl,
  questionIndex,
  sectionIndex,
  control,
  isFollowup,
  answerRef,
}: Question & {
  fieldKey: string
  questionIndex?: number
  sectionIndex?: number
  control: Control<FieldValues>
  isFollowup?: boolean
  answerRef?: Ref<AnswerRef>
}) => {
  const styles = useStyleSheet(themedStyles)
  const isAnswerRequired = required

  const user = useSelector(userSelector)

  const [imageAspectRatio, setImageAspectRatio] = useState<number | undefined>()

  const onImageLoad = (event: { nativeEvent: { width: number; height: number } }) => {
    const { width, height } = event.nativeEvent
    setImageAspectRatio(width / height)
  }

  return (
    <>
      {label && (
        <QuestionText answerRequired={isAnswerRequired} isFollowup={isFollowup}>
          {label}
        </QuestionText>
      )}
      {note && (
        <View style={isFollowup && styles.followupContainer}>
          <MarkdownView content={note} />
        </View>
      )}
      {!!imageUrl && (
        <FastImage
          resizeMode="contain"
          onLoad={onImageLoad}
          source={{ uri: imageUrl }}
          style={[styles.image as ImageStyle, { aspectRatio: imageAspectRatio }]}
        />
      )}
      <Controller
        name={fieldKey}
        control={control}
        render={({ field, fieldState }) => (
          <QuestionnaireQuestionResponse
            control={control}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
            response={response}
            label={label}
            field={field}
            fieldState={fieldState}
            answerRef={answerRef}
          />
        )}
        rules={{
          ...response.kindMetadata,
          validate: (nextValue) => {
            const isNestedValue = nextValue?.value !== undefined
            const fieldValue = nextValue?.value ?? nextValue

            if (isAnswerRequired) {
              let hasValue = !!fieldValue

              if (isNestedValue) {
                hasValue = Array.isArray(fieldValue)
                  ? fieldValue.length > 0
                  : fieldValue !== null || fieldValue !== undefined
              }

              if (!hasValue) {
                return false
              }
            }

            if (response.kind === ResponseKinds.PhoneNumber) {
              const country =
                (user?.address?.country as AddressCountries | undefined) || AddressCountries.Us
              const countryCode = getCountryCodeByCountry(country)
              return Validators.PhoneNumberValidator(fieldValue, countryCode)
            }

            return true
          },
        }}
      />
    </>
  )
}

export const QuestionnaireQuestionScreen = () => {
  const { questionScreenConfig: config, allowEarlyExit } = useContext(QuestionnaireContext)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const route = useRoute<
    RouteProp<QuestionnaireStackParamList, QuestionnaireScreenName.QuestionnaireQuestion>
  >()
  const { sectionIndex, questionIndex } = route.params

  const { sectionIndex: newSectionIndex, questionIndex: newQuestionIndex } = config.questionId

  useEffect(() => {
    if (newSectionIndex === sectionIndex) {
      navigation.setParams({ questionIndex: newQuestionIndex })
    }
  }, [navigation, newQuestionIndex, newSectionIndex, sectionIndex])

  const { banner, questions } = config.getSectionConfig(sectionIndex)
  const question = questions[questionIndex]
  const {
    control,
    formState: { isValid },
    trigger,
  } = config.questionnaireForm

  const styles = useStyleSheet(themedStyles)

  const response = useWatch({ control, name: question.key }) as
    | undefined
    | string[]
    | { value: string | boolean | number }

  const responseValue = response && Array.isArray(response) ? response : response?.value

  const answersGroups = useAnswersGroups(question.response.answersGroups || [])
  const answers = answersGroups.flatMap((answerGroup) => answerGroup.answers) || []

  const { onAnswerChanged } = config

  useEffect(() => {
    onAnswerChanged?.()
  }, [responseValue, onAnswerChanged])

  const inlineAlert = answers.find((answer) => answer.value === responseValue)?.inlineAlert

  const scrollViewRef = useRef<ScrollView>()

  useEffect(() => {
    trigger(question.key)
  }, [question.key, responseValue, trigger])

  useEffect(() => {
    scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: false })
  }, [question.key])

  const insets = useSafeAreaInsets()

  const onSignOutPress = () => {
    dispatch({ type: 'app/logout' })
  }

  const overallQuestionIndex = config.getCurrentQuestionIndex()
  const allQuestionsCount = config.getQuestionsCount()

  const title =
    (!config.showProgressIndicator && config.header) ||
    `${overallQuestionIndex + 1} of ${allQuestionsCount}`

  const answerRef = useRef<AnswerRef>()

  const onNextPress = async () => {
    if (answerRef.current?.process) {
      if (await answerRef.current.process()) {
        config.onNextButtonPress()
      }
      return
    }

    config.onNextButtonPress()
  }

  return (
    <View style={styles.container}>
      <NavigationBar
        title={title}
        style={styles.navigationBar}
        backgroundColor={styles.container.backgroundColor}
        leftAccessory={
          allowEarlyExit
            ? {
                iconDescriptor: { pack: 'eva', name: 'close' },
                onPress: config.onCloseButtonPress,
              }
            : undefined
        }
      />
      {config.showProgressIndicator && allQuestionsCount > 1 && (
        <ProgressBar
          stepsCount={allQuestionsCount}
          currentStep={overallQuestionIndex}
          duration={0}
          style={styles.progressBar}
          withoutSpaces
        />
      )}
      {banner && questionIndex === 0 && (
        <View style={styles.banner}>
          <Text type="small" style={styles.bannerText}>
            {banner}
          </Text>
        </View>
      )}
      <ScrollableAvoidKeyboard
        extraScrollHeight={100}
        showsVerticalScrollIndicator={question.response.kind === ResponseKinds.Consent}
        contentContainerStyle={[
          styles.scrollViewContentContainer,
          { paddingBottom: insets?.bottom || 0 },
        ]}
        innerRef={(ref) => {
          scrollViewRef.current = (ref as unknown) as ScrollView
        }}
        style={styles.scrollView}
      >
        <View style={styles.questionContainer}>
          <QuestionnaireQuestion
            {...question}
            fieldKey={question.key}
            sectionIndex={sectionIndex}
            questionIndex={questionIndex}
            control={control}
            answerRef={answerRef}
          />
        </View>
        {inlineAlert && !config.breakingOutcome && (
          <InlineAlert category={inlineAlert.type} message={inlineAlert.message} />
        )}
        {config.breakingOutcome && (
          <InlineAlert category={UiInlineAlert.Warning} message={config.breakingOutcome} />
        )}
        <View style={styles.navigationButtonsGroup}>
          {config.breakingOutcome ? (
            <Button
              type="transparent"
              size="block"
              onPress={onSignOutPress}
              accessibilityLabel="Back"
              style={styles.backButton}
            >
              Sign Out
            </Button>
          ) : (
            <>
              {!(questionIndex === 0 && sectionIndex === 0) && (
                <Button
                  type="outline"
                  size="block"
                  onPress={config.onBackButtonPress}
                  accessibilityLabel="Back"
                  style={styles.backButton}
                >
                  Back
                </Button>
              )}
              <Button
                type="primary"
                size="block"
                disabled={!isValid}
                onPress={onNextPress}
                accessibilityLabel="Next"
                style={styles.nextButton}
              >
                Next
              </Button>
            </>
          )}
        </View>
      </ScrollableAvoidKeyboard>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  navigationBar: {
    marginBottom: 0,
  },
  scrollView: {
    marginTop: 24,
  },
  scrollViewContentContainer: {
    paddingHorizontal: 16,
  },
  banner: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginTop: 24,
    marginBottom: -8,
    backgroundColor: 'theme.accent.citron',
  },
  bannerText: {
    color: 'theme.solid.black',
  },
  questionContainer: {
    flex: 1,
  },
  navigationButtonsGroup: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 32,
    marginBottom: 16,
  },
  backButton: {
    flex: 1,
    marginRight: 8,
  },
  nextButton: {
    flex: 2,
  },
  progressBar: {
    marginHorizontal: 16,
  },
  followupContainer: {
    marginVertical: -4,
  },
  image: {
    width: '100%',
    height: undefined,
    marginBottom: 16,
  },
})
