import { toPairs, fromPairs, Dictionary } from 'lodash'
import { KeyValuePairFloat } from '@src/types'

export const fromKeyValuePairs = (
  array: KeyValuePairFloat[],
  formatter: (param: any) => void = (param) => param,
) => {
  return fromPairs((array || []).map((pair) => [formatter(pair.key), pair.value]))
}

export const toKeyValuePairs = (object: Dictionary<any>) => {
  return toPairs(object || {}).map((pair) => ({ key: pair[0], value: pair[1] }))
}
