import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { View, TouchableOpacity } from 'react-native'
import Config from 'react-native-config'
import { LongPressGestureHandler } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Text, Button } from '@components/base'
import { NutrisenseLogo, ScrollableAvoidKeyboard } from '@components'
import { useSignIn } from '@src/hooks/useSignIn'
import { Debug } from '@config'
import { SignInButton, SignInButtonVariant } from '@screens/SignIn'
import { termsOfUseSelector } from '@selectors/app'
import { openUrl } from '@utils'
import { useSnack } from '@utils/navigatorContext'
import { useAllowSignInWithApple, useAllowSignInWithGoogle } from '@utils/hooks'

export const SignInOptions = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const showSnack = useSnack()
  const tosUrl = useSelector(termsOfUseSelector)
  const allowSignInWithGoogle = useAllowSignInWithGoogle()
  const allowSignInWithApple = useAllowSignInWithApple()
  const { signUpWithApple, signUpWithGoogle } = useSignIn()

  const onSignInPress = () => {
    navigation.navigate('SignIn')
  }

  const onTermsOfServicePress = () => {
    openUrl(tosUrl)
  }

  const onSignUpWithEmailPress = () => {
    navigation.navigate('SignUp')
  }

  const onSignUpWithGooglePress = async () => {
    try {
      await signUpWithGoogle()
    } catch (error: any) {
      showSnack(error.message)
    }
  }

  const onSignUpWithApplePress = async () => {
    try {
      await signUpWithApple()
    } catch (error: any) {
      showSnack(error.message)
    }
  }

  const onAdminPress = () => {
    if (Debug.useAdminMenu) {
      navigation.navigate('ShowAdmin')
    }
  }

  return (
    <ScrollableAvoidKeyboard
      style={styles.container}
      contentContainerStyle={styles.content}
      keyboardShouldPersistTaps="handled"
    >
      <View style={styles.headerContainer} testID="SigninScreen/Logo">
        <LongPressGestureHandler
          enabled={Config.ENV !== 'production'}
          minDurationMs={50}
          onHandlerStateChange={onAdminPress}
        >
          <View>
            <NutrisenseLogo />
          </View>
        </LongPressGestureHandler>
      </View>
      <SafeAreaView edges={['bottom']} style={styles.signInButtonContainer}>
        <Text type="large" lineSpacing="none" style={styles.signInPrompt}>
          Already have an account?
        </Text>
        <Button
          type="primary"
          size="block"
          style={[styles.signInButton, styles.ctaButton]}
          accessibilityLabel="Log In"
          onPress={onSignInPress}
        >
          Log In
        </Button>
        <Text type="large" lineSpacing="none" style={styles.signInPrompt}>
          New to Nutrisense? Sign Up
        </Text>
        <SignInButton
          variant={SignInButtonVariant.Email}
          style={styles.signInButton}
          onPress={onSignUpWithEmailPress}
        />
        {allowSignInWithGoogle && (
          <SignInButton
            variant={SignInButtonVariant.Google}
            style={styles.signInButton}
            onPress={onSignUpWithGooglePress}
          />
        )}
        {allowSignInWithApple && (
          <SignInButton
            variant={SignInButtonVariant.Apple}
            style={styles.signInButton}
            onPress={onSignUpWithApplePress}
          />
        )}
        <View style={styles.bottom}>
          <Text type="regular" lineSpacing="none">
            By creating an account you agree to our
          </Text>
          <TouchableOpacity
            accessibilityLabel="Terms of Service"
            onPress={onTermsOfServicePress}
            testID="SignInOptionsScreen/TermsOfServiceButton"
          >
            <Text type="regular" lineSpacing="none" bold style={styles.termsOfService}>
              Terms of Service
            </Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    </ScrollableAvoidKeyboard>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
  },
  headerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  signInButtonContainer: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  signInPrompt: {
    width: '100%',
    marginBottom: 24,
    textAlign: 'center',
  },
  signInButton: {
    marginBottom: 16,
  },
  ctaButton: {
    marginBottom: 48,
  },
  bottom: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
  },
  termsOfService: {
    marginTop: 16,
    color: 'theme.text.link',
  },
})
