import React from 'react'
import { ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { TutorialGroup } from '../models/tutorials.types'
import { TutorialPreview } from './TutorialPreview'

export const TutorialsGroup = ({ tutorialGroup }: { tutorialGroup: TutorialGroup }) => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const { summary, tutorials = [] } = tutorialGroup

  const onScanButtonPress = () => {
    navigation.navigate('AddScan', { action: 'activate' })
  }

  return (
    <SafeAreaView edges={['bottom']} style={styles.container}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Text type="regular" style={styles.summaryText}>
          {summary}
        </Text>
        {tutorials.map((tutorial) => (
          <TutorialPreview key={tutorial.id} tutorial={tutorial} />
        ))}
      </ScrollView>
      <Button
        type="primary"
        size="block"
        accessibilityLabel="Activate your sensor"
        onPress={onScanButtonPress}
      >
        Skip Tutorials and Activate Your Sensor
      </Button>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  summaryText: {
    marginVertical: 16,
    textAlign: 'center',
  },
})
