import { gql } from '@apollo/client'

const UPDATE_ADDRESS = gql`
  mutation updateAddress(
    $name: String!
    $street: String!
    $street2: String!
    $city: String!
    $state: String
    $zipCode: String!
    $country: AddressCountries!
  ) {
    updateAddress(
      name: $name
      street: $street
      street2: $street2
      city: $city
      state: $state
      zipCode: $zipCode
      country: $country
    ) {
      name
      street
      street2
      city
      state
      zipCode
      country
    }
  }
`

export default UPDATE_ADDRESS
