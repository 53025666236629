import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Radio, Text } from '@components/base'
import { useStyleSheet, StyleService } from '@src/style/service'

interface GlucosePresetOptionProps {
  title: string
  description: string
  selected: boolean
  onSelect: () => void
}

export const GlucosePresetOption = ({
  title,
  description,
  selected,
  onSelect,
}: GlucosePresetOptionProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <TouchableOpacity
      accessibilityLabel={title}
      onPress={onSelect}
      style={[styles.container, selected && styles.selectedContainer]}
    >
      <View style={styles.content}>
        <Text type="regular">{title}</Text>
        <Text type="small" lineSpacing="tight" style={styles.description}>
          {description}
        </Text>
      </View>
      <Radio style={styles.radio} checked={selected} onPress={onSelect} />
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 16,
    borderWidth: 2,
    borderColor: 'theme.surface.base2',
  },
  selectedContainer: {
    borderColor: 'theme.primary.base',
  },
  content: {
    flexShrink: 1,
  },
  description: {
    color: 'theme.text.tertiary',
  },
  radio: {
    marginLeft: 16,
  },
})
