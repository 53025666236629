import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'

export const tutorialsStateSelector = (state: RootStoreState) => state.tutorials

export const tutorialGroupSelector = (groupId: number) => {
  return createSelector(tutorialsStateSelector, (tutorials) => {
    return tutorials.groups.find(({ id }) => id === `${groupId}`)
  })
}

export const currentTutorialSelector = createSelector(
  tutorialsStateSelector,
  (tutorials) => tutorials.currentTutorial,
)
