import { useSelector } from 'react-redux'
import { isEqualWithTolerance } from '@utils/global'
import {
  useDefaultRangeConfig,
  useDefaultChartRanges,
  MAX_CHART_VALUE,
} from '@src/hooks/useDefaultRangeConfig'
import { thresholdsSettingsSelector } from '@src/selectors/settings'
import { RangeItem } from '@screens/Insights/types'
import { ChartData } from '@src/screens/Events/models/events.types'
import { RangeRating } from '@src/utils/ratings'
import { ChartTypes } from './constants'

export const useTimelineChartRanges = (
  data: ChartData,
  hasChartData: boolean,
  type: ChartTypes,
): RangeItem[] => {
  const metric = type === ChartTypes.Ketones ? 'ketones_max' : 'max'

  const settings = useSelector(thresholdsSettingsSelector)
  const ranges = useDefaultChartRanges(metric)
  const { ranges: defaultRanges = [] } = useDefaultRangeConfig(metric) ?? {}

  if (!hasChartData) {
    return []
  }

  const settingsLowThreshold =
    type === ChartTypes.Glucose ? settings.glucoseLowThreshold : settings.ketoneLowThreshold
  const settingsHighThreshold =
    type === ChartTypes.Glucose ? settings.glucoseHighThreshold : settings.ketoneHighThreshold

  // The settings thresholds are always returned using imperial units; use data.range instead
  // (we should always have goalMin and goalMax; the fallback should theoretically never be used)
  //
  const customLowThreshold = data.range.goalMin ?? settingsLowThreshold
  const customHighThreshold = data.range.goalMax ?? settingsHighThreshold
  const defaultGoodRange = defaultRanges.find((range) => range.rating === RangeRating.GOOD)

  // If the user's threshold settings have been customized and do not match the default good range,
  // then we cannot infer the 'ok' or 'critical' ranges.
  // (The default ranges are not determined algorithmically; they are based on research results)
  //
  const isUsingDefaultRange =
    defaultGoodRange &&
    defaultGoodRange.from !== null &&
    defaultGoodRange.to !== null &&
    isEqualWithTolerance(defaultGoodRange.from, customLowThreshold) &&
    isEqualWithTolerance(defaultGoodRange.to, customHighThreshold)

  if (!isUsingDefaultRange) {
    // Consider everything within the bounds of the user's custom threshold settings as 'good';
    // consider everything else as 'bad'
    return [
      {
        from: 0,
        to: customLowThreshold,
        type: 'unbounded',
        rating: RangeRating.BAD,
      },
      {
        from: customLowThreshold,
        to: customHighThreshold,
        type: 'bounded',
        rating: RangeRating.GOOD,
      },
      {
        from: customHighThreshold,
        to: MAX_CHART_VALUE,
        type: 'unbounded',
        rating: RangeRating.BAD,
      },
    ]
  }

  const rangeWithLowestThreshold = ranges.reduce(
    (prevRange: RangeItem | undefined, thisRange: RangeItem) => {
      if (!prevRange || thisRange.from === null || thisRange.from === 0) {
        return thisRange
      }
      if (prevRange.from === null || prevRange.from === 0) {
        return prevRange
      }
      return thisRange.from < prevRange.from ? thisRange : prevRange
    },
  )

  // At present, we don't want to show the 0 -> 0.4 ketone range in the legend since it falls into
  // a sort of nutritional 'grey area'. As such, it is not included in the range config. However,
  // we would like to add a color treatment to the chart line for values in this bottom range.
  //
  // If the bottom range is 'good' and has a concrete and bounded threshold, then
  // we can apply some special treatment to the bottom of the Ketone chart.
  //
  const isMissingLowerBound =
    rangeWithLowestThreshold &&
    rangeWithLowestThreshold.rating === 'good' &&
    rangeWithLowestThreshold.from !== null &&
    rangeWithLowestThreshold.from > 0

  if (type === ChartTypes.Ketones && isMissingLowerBound) {
    // Render the chart line at bottom of the ketone chart using the 'ok' rating style
    return [
      ...ranges,
      {
        from: 0,
        to: rangeWithLowestThreshold.from,
        type: 'unbounded',
        rating: 'ok',
      },
    ]
  }

  return ranges
}
