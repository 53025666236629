import React, { useContext } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ImageStyle } from 'react-native-fast-image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Icon, UI_ICONS_MAPPING } from '@components/base'
import { NavigationBar, LoadingFastImage, InlineAlert } from '@components'
import { IntroScreen } from '@src/types'
import { MarkdownView } from '@src/components/Markdown'
import { QuestionnaireContext } from '../questionnaire.navigator'

export const QuestionnaireSectionStartScreen = () => {
  const {
    sectionStartScreenConfig: {
      introScreen,
      currentSectionIndex,
      sectionsCount,
      onContinueButtonPress,
      onCloseButtonPress,
      onCompleteLaterButtonPress,
    },
    allowEarlyExit,
    showAlertOnClose,
  } = useContext(QuestionnaireContext)
  const styles = useStyleSheet(themedStyles)

  const insets = useSafeAreaInsets()

  const {
    imageUrl,
    icon,
    header,
    intro,
    introMarkdown,
    footnote,
    inlineAlert,
  } = introScreen as IntroScreen

  const hasMultipleSections = sectionsCount > 1

  const showNavigationBar = hasMultipleSections || allowEarlyExit

  return (
    <View style={styles.topLevelContainer}>
      {showNavigationBar && (
        <NavigationBar
          // eslint-disable-next-line react/jsx-no-useless-fragment
          title={<></>}
          backgroundColor={styles.topLevelContainer.backgroundColor}
          leftAccessory={
            allowEarlyExit
              ? {
                  iconDescriptor: {
                    pack: 'eva',
                    name: hasMultipleSections ? 'close' : 'arrow-ios-back-outline',
                  },
                  onPress: onCloseButtonPress,
                }
              : undefined
          }
        />
      )}
      <ScrollView
        bounces={false}
        contentContainerStyle={[
          styles.container,
          !showNavigationBar && styles.topSpacing,
          { paddingBottom: (insets?.bottom || 0) + 16 },
        ]}
      >
        <View style={styles.headContainer}>
          {!!imageUrl && (
            <LoadingFastImage source={{ uri: imageUrl }} style={styles.image as ImageStyle} />
          )}
          {!!icon && <Icon name={UI_ICONS_MAPPING[icon]} weight="duotone" style={styles.icon} />}
          <Text type="title-2" style={styles.title}>
            {header}
          </Text>
          {introMarkdown ? (
            <MarkdownView content={introMarkdown} paragraphStyle={styles.description} />
          ) : (
            !!intro && (
              <Text type="regular" style={styles.description}>
                {intro}
              </Text>
            )
          )}
        </View>
        <View>
          {!!inlineAlert && (
            <InlineAlert category={inlineAlert.type} message={inlineAlert.message} />
          )}
          {!!footnote && (
            <Text type="tiny" style={styles.footnote}>
              {footnote}
            </Text>
          )}
          <Button
            type="primary"
            size="block"
            onPress={onContinueButtonPress}
            accessibilityLabel="Start questionnaire section"
            style={styles.continueButton}
          >
            {currentSectionIndex === 0 ? 'Start' : 'Continue'}
          </Button>
          {allowEarlyExit && showAlertOnClose && currentSectionIndex === 0 && (
            <Button
              type="transparent"
              size="block"
              onPress={onCompleteLaterButtonPress}
              accessibilityLabel="Complete questionnaire later"
              style={styles.continueButton}
            >
              Complete later
            </Button>
          )}
        </View>
      </ScrollView>
    </View>
  )
}

const themedStyles = StyleService.create({
  spinnerContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  topLevelContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  topSpacing: {
    paddingTop: 60,
  },
  image: {
    marginTop: 32,
    marginBottom: 24,
    aspectRatio: 2 / 1,
    width: '100%',
    resizeMode: 'cover',
  },
  icon: {
    marginTop: 24,
    marginBottom: 16,
    width: 48,
    height: 48,
    color: 'theme.primary.base',
  },
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  headContainer: {
    alignItems: 'center',
  },
  title: {
    marginTop: 16,
    marginBottom: 32,
  },
  description: {
    textAlign: 'center',
    color: 'theme.text.primary',
  },
  footnote: {
    marginTop: 8,
    textAlign: 'center',
  },
  continueButton: {
    marginTop: 8,
  },
})
