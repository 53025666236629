import React, { useEffect, useState } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { LocalPackage } from 'react-native-code-push'
import { CodePush } from '@config'
import { App } from '@config'
import { TouchableSection } from '@components'

interface VersionSectionProps {
  style?: StyleProp<ViewStyle>
}

const onPress = () => null

const VersionSection = (props: VersionSectionProps) => {
  const { style } = props

  const [update, setUpdate] = useState<LocalPackage>()

  const { appVersion, packageHash } = update || {}

  useEffect(() => {
    CodePush.getUpdateMetadata().then((update) => {
      if (update) {
        setUpdate(update)
      }
    })
  }, [])

  return (
    <TouchableSection
      onPress={onPress}
      style={style}
      title={
        `Version: ${App.version} (${App.buildNumber})` +
        (appVersion
          ? ` [${appVersion}-${CodePush.revisionSuffix}.${packageHash?.slice(0, 5)}]`
          : '')
      }
    />
  )
}

export default VersionSection
