import { SeparationLineConfig } from './types'
import { ColumnConfig } from './types'

type SeparationLineConfigsGenerator = (
  columnConfigs: ColumnConfig[],
  chartNumberOfDays: number,
) => SeparationLineConfig[]

export const getSeparationLineConfigs = (
  chartNumberOfDays: number,
  columnConfigs: ColumnConfig[],
): SeparationLineConfig[] => {
  const generateSeparationLineConfigs = getSeparationLineConfigsGenerator(chartNumberOfDays)

  return generateSeparationLineConfigs(columnConfigs, chartNumberOfDays)
}

const getSeparationLineConfigsGenerator = (
  chartNumberOfDays: number,
): SeparationLineConfigsGenerator => {
  if (chartNumberOfDays <= 14 || (chartNumberOfDays > 30 && chartNumberOfDays <= 63)) {
    return generateSeparationLinesAroundEachColumn
  }

  return generateSeparationLinesAroundSections
}

const generateSeparationLinesAroundEachColumn = (
  columnConfigs: ColumnConfig[],
): SeparationLineConfig[] => [
  { columnIndex: -1 }, // corresponds to the separation line before the first column. Which is actually y-axis line
  ...columnConfigs.map((columnConfig) => ({
    columnIndex: columnConfig.index,
  })),
]

const generateSeparationLinesAroundSections: SeparationLineConfigsGenerator = (
  columnConfigs,
  chartNumberOfDays,
) => {
  const numberOfSections = getNumberOfSections(chartNumberOfDays)
  const numberOfColumnsInRegularSection = Math.round(columnConfigs.length / numberOfSections)

  const configs = [{ columnIndex: -1 }]

  for (let i = 1; i < numberOfSections; i++) {
    configs.push({
      columnIndex: i * numberOfColumnsInRegularSection - 1,
    })
  }

  return [
    ...configs,
    {
      columnIndex: columnConfigs.length - 1,
    },
  ]
}

const getNumberOfSections = (numberOfDays: number): number => (numberOfDays <= 30 ? 4 : 3)
