import { gql } from '@apollo/client'

export const CREATE_FILE_FROM_SIGNED_ID = gql`
  mutation createFileFromSignedId($signedId: String!, $fileTag: String!) {
    createFileFromSignedId(signedId: $signedId, fileTag: $fileTag) {
      downloadFileUrl
      permanentUrl
      fileName
      id
    }
  }
`
