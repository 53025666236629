import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { DATE_PICKER_FORMAT } from '@config/momentFormat'
import { Button, Input, Text, Toggle } from '@components/base'
import {
  CircleIcon,
  ScrollableAvoidKeyboard,
  SelectDateTime,
  SelectFromActionSheet,
} from '@components'
import { ActivityFormData } from '@screens/Activities/types'
import { activityTypesSelector } from '@selectors/app'
import { Global, useGoBack } from '@utils'
import { useSnack } from '@utils/navigatorContext'
import { TIME_FORMAT } from '@utils/global'
import { Activity } from '@src/types'

interface ActivityFormProps {
  activity?: Omit<Activity, 'id'> & { id: string | undefined }
  onModifiedStateChange: (isModified: boolean) => void
}

export const ActivityForm = ({ activity, onModifiedStateChange }: ActivityFormProps) => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const showSnack = useSnack()

  const activityTypes = useSelector(activityTypesSelector)

  const [type, setType] = useState(activity?.type ?? activityTypes[0])
  const [startDate, setStartDate] = useState(moment(activity?.startedAt).format(TIME_FORMAT))
  const [endDate, setEndDate] = useState(moment(activity?.endedAt).format(TIME_FORMAT))
  const [isFavorite, setFavorite] = useState(activity?.favorite ?? false)

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<ActivityFormData>({
    defaultValues: {
      description: activity?.description ?? '',
    },
  })

  const isSubmitting = useRef(false)

  const onSaveButtonPress = handleSubmit(({ description }) => {
    if (isSubmitting.current) {
      return
    }

    isSubmitting.current = true

    const payload = {
      id: activity?.id,
      type,
      description,
      startTime: Global.formatTime(startDate),
      endTime: Global.formatTime(endDate),
      favorite: isFavorite,
      statistics: activity?.statistics || {},

      // fake data for optimistic reducer
      __typename: 'Activity',
      occurredAt: Global.formatTime(startDate),
      createdAt: moment().format(),
      startedAt: Global.formatTime(startDate),
      endedAt: Global.formatTime(endDate),
      title: type || 'Exercise',
      fake: true,
    }

    dispatch({
      payload,
      type: 'activities/submit',
      success: goBack,
      failure: (error: any) => {
        showSnack(error?.message || 'Saving failed!', null, 'error')
      },
    })
  })

  useEffect(() => {
    const isModified =
      isDirty ||
      type !== activity?.type ||
      !moment(startDate, TIME_FORMAT).isSame(moment(activity?.startedAt), 'minute') ||
      !moment(endDate, TIME_FORMAT).isSame(moment(activity?.endedAt), 'minute') ||
      isFavorite !== !!activity?.favorite

    onModifiedStateChange(isModified)
  }, [activity, endDate, isDirty, isFavorite, onModifiedStateChange, startDate, type])

  return (
    <SafeAreaView edges={['bottom']} style={styles.container}>
      <ScrollableAvoidKeyboard showsVerticalScrollIndicator={false}>
        <View style={[styles.row, styles.block]}>
          <CircleIcon name="pulse" style={styles.icon} />
          <SelectFromActionSheet
            disabled={!!activity?.id}
            label="Type"
            options={activityTypes}
            style={styles.typeSelect}
            testID="ActivityComponent/Type"
            title="Activity Type"
            value={type}
            onChange={setType}
          />
        </View>
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Input
              {...field}
              autoCapitalize="sentences"
              label="What did you do?"
              placeholder="Describe your activity"
              returnKeyType="done"
              style={styles.block}
              testID="ActivityComponent/Description"
            />
          )}
        />
        <View style={styles.content}>
          <SelectDateTime
            inputProps={{
              label: 'From',
              placeholder: 'Select start date and time',
              style: styles.block,
              testID: 'ActivityComponent/From',
              value: moment(startDate, TIME_FORMAT).format(DATE_PICKER_FORMAT),
            }}
            pickerProps={{
              date: Global.toLocalTimezoneDate(startDate),
              maximumDate: Global.toLocalTimezoneDate(endDate),
              mode: 'datetime',
            }}
            onChange={(date: Date) => setStartDate(moment(date).local().format(TIME_FORMAT))}
          />
          <SelectDateTime
            inputProps={{
              label: 'To',
              placeholder: 'Select end date and time',
              testID: 'ActivityComponent/To',
              style: styles.block,
              value: moment(endDate, TIME_FORMAT).format(DATE_PICKER_FORMAT),
            }}
            pickerProps={{
              date: Global.toLocalTimezoneDate(endDate),
              minimumDate: Global.toLocalTimezoneDate(startDate),
              mode: 'datetime',
            }}
            onChange={(date: Date) => setEndDate(moment(date).local().format(TIME_FORMAT))}
          />
          <Toggle
            checked={isFavorite}
            onChange={setFavorite}
            style={styles.favoriteToggle}
            text="Add to favorites"
          />
        </View>
        {!!activity?.externalSource && (
          <Text type="large" style={styles.sourceOfData}>
            Source of data{' '}
            <Text type="large" bold>
              {activity.externalSource}
            </Text>
          </Text>
        )}
      </ScrollableAvoidKeyboard>
      <Button
        accessibilityLabel="Save"
        size="block"
        type="primary"
        testID="Activity/Save"
        onPress={onSaveButtonPress}
      >
        Save
      </Button>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 24,
    paddingBottom: 16,
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
  },
  typeSelect: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  block: {
    marginBottom: 24,
  },
  favoriteToggle: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 32,
  },
  sourceOfData: {
    marginBottom: 32,
    textAlign: 'center',
    opacity: 0.5,
  },
  icon: {
    marginRight: 16,
    padding: 16,
  },
})
