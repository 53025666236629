import React, { useState } from 'react'
import { View } from 'react-native'
import moment from 'moment'
import { Audio } from 'expo-av'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Device } from '@src/config'
import { Text } from '@components/base'
import { WaveForm } from './WaveForm'
export { Audio }

interface AudioPlayerSliderProps {
  duration: number
  position: number
  playableDuration: number
  onSeek: (position: number) => void
}

export const AudioPlayerSlider = ({
  duration,
  position: audioPosition,
  playableDuration,
  onSeek,
}: AudioPlayerSliderProps) => {
  const styles = useStyleSheet(themedStyles)

  const [sliderWidth, setSliderWidth] = useState(0)
  const [slidingPosition, setSlidingPosition] = useState<number>()
  const [playableDurationStart, setPlayableDurationStart] = useState(0)
  const sliderHeight = styles.seekSlider.height as number
  const position = slidingPosition ?? audioPosition

  const positionPercentage = `${(position / duration) * 100}%` as const
  const timeLeft = duration - position
  const playableDurationLeftPercentage = `${(playableDurationStart / duration) * 100}%` as const
  const playableDurationRightPercentage = `${
    ((duration - (playableDuration || position)) / duration) * 100
  }%` as const
  const timeFormat = duration < moment.duration(1, 'hour').asMilliseconds() ? 'mm:ss' : 'HH:mm:ss'

  return (
    <View
      style={styles.seekSliderContainer}
      onLayout={(e) => setSliderWidth(e.nativeEvent.layout.width)}
      onTouchMove={(e) => {
        const { locationX, locationY } = e.nativeEvent

        // sliding outside the view doesn't work as expect on Android
        // see: https://github.com/facebook/react-native/issues/31843
        const xIsInBounds = 0 <= locationX && locationX <= sliderWidth
        const yIsInBounds = 0 <= locationY && locationY <= sliderHeight
        if (Device.android && (!xIsInBounds || !yIsInBounds)) {
          return
        }

        const sliderMarginHorizontal = Number(styles.seekPositionContainer.marginHorizontal)
        const maxWidth = sliderWidth - 2 * sliderMarginHorizontal
        const x = Math.max(0, Math.min(maxWidth, locationX))
        const newPosition = (x / maxWidth) * duration

        setSlidingPosition(newPosition)
      }}
      onTouchEnd={() => {
        setPlayableDurationStart(position)
        onSeek(position)
        setSlidingPosition(undefined)
      }}
    >
      <View style={styles.seekSlider}>
        {0 < duration && (
          <View style={styles.seekPositionContainer}>
            <View
              style={[
                styles.playableDurationBar,
                {
                  width: '100%',
                  marginLeft: playableDurationLeftPercentage,
                  marginRight: playableDurationRightPercentage,
                },
              ]}
              pointerEvents="none"
            />
            <View style={[styles.seekPositionBar, { width: positionPercentage }]} />
          </View>
        )}
        <View style={styles.waveFormContainer} pointerEvents="none">
          <WaveForm
            width={sliderWidth}
            height={sliderHeight}
            paddingHorizontal={styles.seekPositionContainer.marginHorizontal as number}
          />
        </View>
      </View>
      <View style={styles.timeTextContainer}>
        <Text type="small">{moment.utc(position).format(timeFormat)}</Text>
        <Text type="small">-{moment.utc(timeLeft).format(timeFormat)}</Text>
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  seekSliderContainer: {
    marginLeft: 16,
    flexGrow: 1,
  },
  seekSlider: {
    height: 48, // must stay a number
    borderRadius: 32,
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: 'theme.surface.base0',
  },
  seekPositionContainer: {
    marginHorizontal: 16, // must stay a number
  },
  seekPositionBar: {
    height: '100%',
    backgroundColor: 'theme.primary.base',
  },
  playableDurationBar: {
    position: 'absolute',
    height: '100%',
    backgroundColor: 'theme.surface.base2',
  },
  waveFormContainer: {
    position: 'absolute',
  },
  timeTextContainer: {
    marginTop: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
