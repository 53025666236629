import React from 'react'
import { Product, Subscription } from '@src/types'
import { isTrial } from '../../utils/utils'
import { ProductSummaryFields, Fields } from './ProductSummaryFields'

interface SubscriptionFieldsProps {
  subscription: Pick<
    Subscription,
    | 'price'
    | 'currentPeriodEndAt'
    | 'commitmentEndAt'
    | 'cancelAt'
    | 'resumesAt'
    | 'resetBillingCycleAnchorAt'
    | 'status'
    | 'paused'
  > & { primaryProduct: Pick<Product, 'interval'> }
  mode: 'full' | 'compact'
  fields: Fields[]
}

export const SubscriptionFields = ({
  subscription,
  mode = 'full',
  fields,
}: SubscriptionFieldsProps) => {
  return (
    <ProductSummaryFields
      product={subscription.primaryProduct}
      price={subscription.price}
      currentPeriodEndAt={subscription.currentPeriodEndAt}
      commitmentEndAt={subscription.commitmentEndAt}
      cancelAt={subscription.cancelAt}
      resumesAt={subscription.resumesAt}
      resetBillingCycleAnchorAt={subscription.resetBillingCycleAnchorAt}
      isTrial={isTrial(subscription)}
      mode={mode}
      fields={fields}
    />
  )
}
