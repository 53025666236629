import React from 'react'
import { View } from 'react-native'
import { Control, FieldValues } from 'react-hook-form'
import { StyleService } from '@src/style/service'
// eslint-disable-next-line max-len
import { SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind as FollowupKinds } from '@src/types'
import { QuestionnaireQuestion } from '../../screens/QuestionnaireQuestionScreen'
import { CheckboxMultiInputConfig } from '../../types'
import { InputPropsBase } from './types'
import { Option, OptionType } from './Shared/Option'
import { useAnswersGroups, useSetValueObject } from './Shared/hooks'

interface CheckBoxMultiInputProps extends InputPropsBase {
  response: CheckboxMultiInputConfig
  questionIndex?: number
  sectionIndex?: number
  control: Control<FieldValues>
}

type CheckBoxFieldValue = {
  value: (string | boolean)[] | undefined
}

export const CheckBoxMultiInput = ({
  field,
  response,
  questionIndex,
  sectionIndex,
  control,
}: CheckBoxMultiInputProps) => {
  const answersGroups = useAnswersGroups(response.answersGroups)
  const fieldValue: CheckBoxFieldValue | undefined = field.value
  const values = Array.isArray(fieldValue) ? fieldValue : fieldValue?.value || []

  const selectedGroupIndex = answersGroups.findIndex((group) =>
    group.answers.map((answer) => answer.value).some((value) => values.includes(value)),
  )

  const onChange = field.onChange

  useSetValueObject({ onChange, fieldValue, answersGroups })

  const handleChange = (inputValue: string | boolean) => {
    onChange({
      value: values.includes(inputValue)
        ? values.filter((existingValue) => existingValue !== inputValue)
        : [...values, inputValue],
    })
  }

  return (
    <View>
      {answersGroups?.map((group, i) => (
        <View key={i}>
          {group.answers.map((answer) => {
            const isSelected = values.includes(answer.value)
            const isDisabled = selectedGroupIndex !== -1 && selectedGroupIndex !== i
            return (
              <View key={`${answer.value}`}>
                <Option
                  label={answer.label}
                  selected={isSelected}
                  disabled={isDisabled}
                  onPress={() => handleChange(answer.value)}
                  type={OptionType.Checkbox}
                />
                {isSelected &&
                  answer.followups
                    .filter((followup) => followup.kind === FollowupKinds.Inline)
                    .map((followup) => (
                      <View style={styles.followupContainer} key={followup.question.key}>
                        <QuestionnaireQuestion
                          {...followup.question}
                          isFollowup
                          sectionIndex={sectionIndex}
                          questionIndex={questionIndex}
                          control={control}
                          fieldKey={followup.question.key}
                        />
                      </View>
                    ))}
              </View>
            )
          })}
        </View>
      ))}
    </View>
  )
}

const styles = StyleService.create({
  followupContainer: {
    marginBottom: 16,
  },
})
