import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import moment from 'moment'
import { useStyleSheet, StyleService } from '@src/style/service'
import { DATE_FORMAT, formatDateRange } from '@config/momentFormat'
import { Icon, Text } from '@components/base'
import { DateSelectDirection } from './types'

const DOTS_COUNT = 34 // enough to fit "Nov 30 - Dec 2"

interface DateSelectorProps {
  startDate: string
  endDate: string
  onDatePickerPress: () => void
  onDateChange: (startDate: string, endDate: string) => void
}

export const DateSelector = ({
  startDate,
  endDate,
  onDatePickerPress,
  onDateChange,
}: DateSelectorProps) => {
  const styles = useStyleSheet(themedStyle)

  const onArrowPress = (direction: DateSelectDirection) => {
    const newStartDate = moment(startDate).add(direction, 'd').format(DATE_FORMAT)
    const newEndDate = moment(endDate).add(direction, 'd').format(DATE_FORMAT)

    onDateChange(newStartDate, newEndDate)
  }

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => onArrowPress(DateSelectDirection.PREVIOUS)}
        accessibilityLabel="Previous"
        style={styles.iconContainer}
      >
        <Icon name="caret-left" style={styles.icon} />
      </TouchableOpacity>
      <TouchableOpacity
        accessibilityLabel="formatDateRange"
        style={styles.dateContainer}
        onPress={onDatePickerPress}
      >
        <Text type="large" bold style={styles.date}>
          {formatDateRange(startDate, endDate)}
        </Text>
        <View style={styles.dashLine}>
          {Array.from(Array(DOTS_COUNT).keys()).map((key) => (
            <View key={key} style={styles.dot} />
          ))}
        </View>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => onArrowPress(DateSelectDirection.NEXT)}
        accessibilityLabel="Next"
        style={styles.iconContainer}
      >
        <Icon name="caret-right" style={styles.icon} />
      </TouchableOpacity>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 4,
  },
  date: {
    marginHorizontal: 4,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
  },
  icon: {
    height: 16,
    width: 16,
    color: 'theme.text.secondary',
  },
  dashLine: {
    position: 'absolute',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    flexWrap: 'wrap',
    height: 2,
    bottom: -4,
  },
  dot: {
    marginHorizontal: 1,
    width: 2,
    height: 2,
    backgroundColor: 'theme.surface.base2',
  },
})
