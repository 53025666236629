// https://stackoverflow.com/a/52618217
// Used for turning RN hex file hash into base64

import { encode } from 'base-64'

const hexToBase64 = (string: string): string =>
  encode(
    (string.match(/.{1,2}/g) || []).map((byte) => String.fromCharCode(parseInt(byte, 16))).join(''),
  )

export default hexToBase64
