import React from 'react'
import FastImage from 'react-native-fast-image'
import { LoadingFastImage } from '@src/components/LoadingFastImage'
import { EditorBlock, EditorBlockType } from '../../lesson.types'

interface RichTextViewImageProps {
  block: EditorBlock
}

export const RichTextViewImage = ({ block }: RichTextViewImageProps) => {
  if (block.type !== EditorBlockType.Image) {
    return null
  }

  const image = block.data.file.formats?.medium || block.data.file

  return (
    <LoadingFastImage
      source={{ uri: image.url }}
      resizeMode={FastImage.resizeMode.contain}
      style={{
        width: '100%',
        height: undefined,
        aspectRatio: image.width / image.height,
        borderRadius: 8,
        marginBottom: 24,
      }}
    />
  )
}
