import React from 'react'
import { View } from 'react-native'
import { StyleService } from '@src/style/service'
import { LegendItem, LegendItemProps } from './LegendItem'

export type LegendItemConfig = LegendItemProps

interface LegendProps {
  legendItems: LegendItemConfig[]
}

export const Legend = ({ legendItems }: LegendProps) => {
  return (
    <View style={styles.container}>
      {legendItems.map((item, index) => (
        <LegendItem key={index} {...item} />
      ))}
    </View>
  )
}

const styles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
})
