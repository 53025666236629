import { IconName } from '@components/base'
import { BaseIngredient } from '@screens/Ingredients/types'
import { Ingredient, Meal, MealKind } from '@src/types'
import { ReactNativeFile } from '@src/utils/image'

type IgnoredDraftMealFields =
  | '__typename'
  | 'createdBy'
  | 'glucose'
  | 'ingredients' // overridden
  | 'nutrition'
  | 'source'
  | 'statistics'
  | 'time' // deprecated
  | 'type' // deprecated
  | 'updatedAt'

export interface DraftMeal extends Omit<Meal, IgnoredDraftMealFields> {
  file?: ReactNativeFile | null
  hasUnsavedChanges?: boolean
  ingredients?: (Ingredient | BaseIngredient)[]
  kind?: MealKind
  source?: Meal
}

export const MEAL_ICON_KIND_MAP: Record<MealKind, IconName> = {
  [MealKind.Breakfast]: 'fork-knife',
  [MealKind.Dessert]: 'cake',
  [MealKind.Dinner]: 'fork-knife',
  [MealKind.Drink]: 'wine',
  [MealKind.Lunch]: 'fork-knife',
  [MealKind.Snack]: 'carrot',
  [MealKind.Supplement]: 'pill',
}

export type ChartValue = {
  x: string
  y: number
}

type GlucoseDataPoint = {
  index: string
  x: number
  y: number | null
}
type EventDataPoint = GlucoseDataPoint & {
  description?: string
  type?: string
}

export interface MealComparisonChartData {
  data: GlucoseDataPoint[]
  events: EventDataPoint[]
  yLabel: string
}
