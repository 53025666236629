import { CardFieldInput, confirmSetupIntent } from '@stripe/stripe-react-native'
import { Dispatch, AnyAction } from 'redux'
import { User } from '@src/types'
import { CreateProviderSetupIntentMutation } from '@src/screens/Marketplace/graphql/createProviderSetupIntent.generated'
import { dispatchAsync as createDispatchAsync } from './dispatch'

interface CreatePaymentParams {
  user: Pick<User, 'email' | 'fullName' | 'phoneNumber'>
  dispatch: Dispatch<AnyAction>
  card: CardFieldInput.Details
}

export const createPaymentMethod = async ({ user, dispatch, card }: CreatePaymentParams) => {
  const dispatchAsync = createDispatchAsync(dispatch)

  const { fullName, phoneNumber, email } = user
  const {
    createProviderSetupIntent: { clientSecret },
  } = await dispatchAsync<CreateProviderSetupIntentMutation>({
    type: 'marketplace/createProviderSetupIntent',
    payload: {
      email,
      fullName,
      phoneNumber,
    },
  })

  const { setupIntent, error } = await confirmSetupIntent(clientSecret, {
    paymentMethodType: 'Card',
  })

  if (error) {
    throw error
  }

  dispatch({
    type: 'app/updateUserState',
    payload: {
      paymentMethod: {
        stripeId: setupIntent.paymentMethodId,
        cardType: card.brand,
        last4: card.last4,
        expirationDate: new Date(card.expiryYear, card.expiryMonth - 1, 1),
      },
    },
  })
}
