import React, { useState } from 'react'
import { FlatList, ListRenderItemInfo, View } from 'react-native'
import { orderBy } from 'lodash'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { FuseSearchBar } from '@src/components/FuseSearchBar'
import { MealListItem } from '@src/screens/Meals/components/MealListItem'
import { Meal } from '@src/screens/Events/models/events.types'

interface MealsListProps {
  items: Meal[]
  emptyListText: string
  emptyListIcon: JSX.Element
  onItemSelected: (item: Meal) => void
  onToggleFavorite: (item: Meal) => void
}

const FUSE_OPTIONS = {
  includeScore: true,
  threshold: 0.25,
  keys: ['description', 'title'],
}

export const MealsList = ({
  items,
  emptyListText,
  emptyListIcon,
  onItemSelected,
  onToggleFavorite,
}: MealsListProps) => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  const [filteredList, setFilteredList] = useState<Meal[]>([])

  const orderedItems = orderBy(filteredList, 'createdAt', 'desc')

  const renderItem = ({ item }: ListRenderItemInfo<Meal>) => (
    <MealListItem
      item={item}
      onItemSelected={() => onItemSelected(item)}
      setFavorite={() => onToggleFavorite(item)}
    />
  )

  if (items.length === 0) {
    return (
      <View style={styles.emptyList}>
        {emptyListIcon}
        <Text type="title-2" style={styles.emptyTitle}>
          It's empty here...
        </Text>
        <Text type="regular" style={styles.emptyDescription}>
          {emptyListText}
        </Text>
      </View>
    )
  }

  return (
    <>
      <FuseSearchBar rawList={items} onSearchResult={setFilteredList} options={FUSE_OPTIONS} />
      <FlatList
        data={orderedItems}
        renderItem={renderItem}
        keyExtractor={(item: Meal) => item.id}
        style={styles.mealsList}
        contentContainerStyle={{ paddingBottom: insets.bottom }}
      />
    </>
  )
}

const themedStyle = StyleService.create({
  mealsList: {
    flex: 1,
    flexBasis: 0,
    backgroundColor: 'theme.background',
  },
  emptyList: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 64,
    paddingHorizontal: 24,
    paddingBottom: 16,
  },
  emptyTitle: {
    marginTop: 32,
    textAlign: 'center',
  },
  emptyDescription: {
    marginTop: 16,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
})
