import React, { useEffect, useState, useRef } from 'react'
import { View, TouchableOpacity, Image, StyleProp, ImageStyle } from 'react-native'
import { BarCodeReadEvent, RNCamera, TakePictureResponse } from 'react-native-camera'
import BarcodeMask from 'react-native-barcode-mask'
import { useNavigation, useRoute, useIsFocused, RouteProp } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@components/base'
import { useSnack } from '@src/utils/navigatorContext'
import { useGoBack } from '@src/utils/navigation'
import { generateReactNativeFile, IMAGE_SIZE } from '@src/utils/image'
import { CAMERA_DENIED_SNACK_MESSAGE } from '@src/utils/camera'
import { CommonStackParamList } from '@src/navigation/types'
import { Device } from '@src/config'

const CameraNotAuthorizedView = ({ goBack }: { goBack: () => void }) => {
  const showSnack = useSnack()

  useEffect(() => {
    showSnack(CAMERA_DENIED_SNACK_MESSAGE, null, 'error')
    goBack()
  }, [showSnack, goBack])

  return null
}

interface CameraProps {
  onBarCodeRead?: (event: BarCodeReadEvent) => void
}

const ICON_SIZE = 50
const CAPTURE_SIZE = 70
const BOTTOM_MARGIN = 30
const TOP_MARGIN = 40
const HORIZONTAL_MARGIN = 20
const IMAGE_ASPECT_RATIO = 3 / 4

const CameraContainer = ({ onBarCodeRead }: CameraProps) => {
  const styles = useStyleSheet(themedStyles)

  const navigation = useNavigation()
  const goBack = useGoBack()

  const route = useRoute<RouteProp<CommonStackParamList, 'Camera'>>()

  const params = route.params || {}
  const { parentScreen } = params

  const [isCameraReady, setIsCameraReady] = useState(false)
  const [backCamera, setBackCamera] = useState(true)
  const [enableFlash, setEnableFlash] = useState(false)

  const toggleBackCamera = () => {
    setBackCamera(!backCamera)
  }

  const toggleFlashMode = () => {
    setEnableFlash(!enableFlash)
  }

  const cameraRef = useRef<RNCamera>(null)

  const [data, setData] = useState<TakePictureResponse | undefined>()

  const capture = async () => {
    const data = await cameraRef.current?.takePictureAsync({
      mirrorImage: !backCamera,
      forceUpOrientation: !backCamera,
      fixOrientation: !backCamera,
      // on iOS it resizes photo, so width is equal to IMAGE_SIZE
      // on Android it resizes photo to fit in IMAGE_SIZExIMAGE_SIZE
      width: Device.ios ? IMAGE_SIZE : IMAGE_SIZE / IMAGE_ASPECT_RATIO,
    })
    setData(data)
  }

  const resetData = () => {
    setData(undefined)
  }

  const onSavePhoto = () => {
    if (!data) {
      return
    }

    navigation.navigate(parentScreen, { photo: generateReactNativeFile(data) })
  }

  const isFocused = useIsFocused()

  if (!isFocused) {
    return null
  }

  return (
    <View style={styles.container}>
      {!data ? (
        <>
          <RNCamera
            ref={cameraRef}
            flashMode={
              enableFlash ? RNCamera.Constants.FlashMode.torch : RNCamera.Constants.FlashMode.off
            }
            onBarCodeRead={onBarCodeRead}
            onCameraReady={() => setIsCameraReady(true)}
            style={styles.preview}
            type={backCamera ? RNCamera.Constants.Type.back : RNCamera.Constants.Type.front}
            captureAudio={false}
            notAuthorizedView={<CameraNotAuthorizedView goBack={goBack} />}
          >
            {onBarCodeRead && <BarcodeMask />}
          </RNCamera>
          {!onBarCodeRead && (
            <>
              <TouchableOpacity
                style={styles.captureContainer}
                accessibilityLabel="capture"
                disabled={!isCameraReady}
                onPress={capture}
              >
                <View style={styles.capture} />
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.iconContainer, styles.toggleCameraType]}
                onPress={toggleBackCamera}
                accessibilityLabel="Toggle camera"
              >
                <Icon name="arrows-left-right" style={styles.icon} />
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.iconContainer, styles.toggleFlashMode]}
                onPress={toggleFlashMode}
                accessibilityLabel="Toggle flash"
              >
                <Icon name={enableFlash ? 'lightning' : 'lightning-slash'} style={styles.icon} />
              </TouchableOpacity>
            </>
          )}
          <TouchableOpacity
            accessibilityLabel="Close"
            style={[styles.iconContainer, styles.close]}
            onPress={goBack}
          >
            <Icon name="x" style={styles.icon} />
          </TouchableOpacity>
        </>
      ) : (
        <>
          <Image
            source={{ uri: data.uri }}
            resizeMode="contain"
            style={styles.imagePreview as StyleProp<ImageStyle>}
          />
          <TouchableOpacity
            style={styles.captureContainer}
            accessibilityLabel="Save photo"
            onPress={onSavePhoto}
          >
            <Icon name="check" style={[styles.icon, styles.checkIcon]} />
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.iconContainer, styles.retry]}
            accessibilityLabel="Retry"
            onPress={resetData}
          >
            <Icon name="arrows-clockwise" style={styles.icon} />
          </TouchableOpacity>
        </>
      )}
    </View>
  )
}

export default CameraContainer

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.solid.black',
    justifyContent: 'center',
  },
  preview: {
    aspectRatio: IMAGE_ASPECT_RATIO,
  },
  imagePreview: {
    flex: 1,
    backgroundColor: 'theme.solid.black',
  },
  close: {
    left: HORIZONTAL_MARGIN,
    top: TOP_MARGIN,
    borderWidth: 0,
  },
  retry: {
    left: HORIZONTAL_MARGIN,
    bottom: BOTTOM_MARGIN,
  },
  toggleCameraType: {
    right: HORIZONTAL_MARGIN,
    bottom: BOTTOM_MARGIN,
  },
  toggleFlashMode: {
    right: HORIZONTAL_MARGIN,
    bottom: BOTTOM_MARGIN + CAPTURE_SIZE,
  },
  captureContainer: {
    position: 'absolute',
    bottom: BOTTOM_MARGIN,
    width: CAPTURE_SIZE,
    height: CAPTURE_SIZE,
    borderRadius: CAPTURE_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: 'theme.solid.white',
  },
  capture: {
    width: CAPTURE_SIZE - 10,
    height: CAPTURE_SIZE - 10,
    borderRadius: CAPTURE_SIZE - 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: 'theme.solid.black',
  },
  checkIcon: {
    width: 32,
    height: 32,
    color: 'theme.solid.black',
  },
  icon: {
    color: 'theme.solid.white',
  },
  iconContainer: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    borderRadius: ICON_SIZE,
    borderWidth: 2,
    borderColor: 'theme.solid.white',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
})
