import React from 'react'
import moment from 'moment'
import { BaseListItem } from '@src/components/list/common/BaseListItem'
import { Text } from '@components/base'
import { HeaderItem } from '@src/screens/Scans/components/ScansList'
import { HOUR_MINUTE_AM_PM } from '@src/config/momentFormat'

export interface ScanListLiveItemProps {
  item: HeaderItem
}

export const ScanListLiveItem = ({ item }: ScanListLiveItemProps) => {
  const { title, data, occurredAt } = item

  const renderImageContent = () => {
    return <Text type="title-3">{data}</Text>
  }

  return (
    <BaseListItem
      title={title}
      info={moment(occurredAt).format(HOUR_MINUTE_AM_PM)}
      renderImageContent={renderImageContent}
    />
  )
}
