import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@components/base'
import { useGoBack } from '@src/utils/navigation'
import { chatbotLastShownSelector } from '@src/selectors/chatbot'
import { NavigationContainer } from '@src/screens/Common/containers'
import { ChatScreen as ChatScreenComponent } from '../components/ChatScreen'
import { Title } from '../components/Title'
import { useStreamChat } from '../utils/hooks'

export const ChatScreenContainer = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const goBack = useGoBack()

  const lastShownTimestamp = useSelector(chatbotLastShownSelector)
  const { client, channel } = useStreamChat()

  useEffect(() => {
    if (!lastShownTimestamp) {
      navigation.navigate('ChatWelcomeModal')
    }
  }, [lastShownTimestamp, navigation])

  return (
    <NavigationContainer
      title={<Title />}
      goBack={goBack}
      showLoadingIndicator
      rightAccessories={[
        {
          renderIconComponent: () => <Icon name="arrow-counter-clockwise" style={styles.icon} />,
          onPress: () => {
            if (channel?.id) {
              navigation.navigate('ChatDelimiterConfirmationModal', { channelId: channel.id })
            }
          },
          accessibilityLabel: 'Refresh',
        },
      ]}
    >
      {channel ? (
        <ChatScreenComponent client={client} channel={channel} />
      ) : (
        <View style={styles.emptyView} />
      )}
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  emptyView: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  icon: {
    marginRight: 8,
  },
})
