import { chunk, every, mean } from 'lodash'
import { ColumnConfig, DatesRangeColumnConfig, OneDayColumnConfig } from './types'

export const aggregateColumns = (columnConfigs: ColumnConfig[], chartNumberOfDays: number) => {
  if (!areOnlyOneDayColumns(columnConfigs)) {
    return columnConfigs
  }

  const runColumnsAggregator = getColumnsAggregator(chartNumberOfDays)

  return runColumnsAggregator(columnConfigs)
}

const areOnlyOneDayColumns = (
  columnConfigs: ColumnConfig[],
): columnConfigs is OneDayColumnConfig[] =>
  columnConfigs.every((columnConfig) => columnConfig.type === 'oneDay')

const getColumnsAggregator = (chartNumberOfDays: number) => {
  if (chartNumberOfDays <= 30) {
    return createByNDaysColumnAggregator(1)
  }
  if (chartNumberOfDays <= 63) {
    return createByNDaysColumnAggregator(7)
  }

  // If numberOfDays > 63 there should be 12 columns.
  const numberOfColumns = 12
  const numberOfDaysToAggregateBy = Math.round(chartNumberOfDays / numberOfColumns)
  return createByNDaysColumnAggregator(numberOfDaysToAggregateBy, numberOfColumns)
}

const createByNDaysColumnAggregator = (numberOfDays: number, maxColumnsNumber?: number) => {
  if (numberOfDays === 1) {
    return (columnConfigs: OneDayColumnConfig[]) => columnConfigs
  }

  return (columnConfigs: OneDayColumnConfig[]) =>
    customChunk(columnConfigs, numberOfDays, maxColumnsNumber).map(
      (columnsChunk, index): DatesRangeColumnConfig => ({
        type: 'datesRange',
        index,
        fromDate: columnsChunk[0].date,
        toDate: columnsChunk[columnsChunk.length - 1].date,
        value: every(columnsChunk, { value: null })
          ? null
          : mean(
              columnsChunk.flatMap((columnConfig) =>
                columnConfig.value !== null ? [columnConfig.value] : [],
              ),
            ),
      }),
    )
}

const customChunk = <T>(array: T[], chunkSize: number, numberOfChunks?: number) => {
  const chunkedArray = chunk(array, chunkSize)

  if (numberOfChunks === undefined) {
    return chunkedArray
  }

  return chunkedArray.length > numberOfChunks
    ? // If there are more chunks than needed, concat all the last chunks
      [...chunkedArray.slice(0, numberOfChunks - 1), chunkedArray.slice(numberOfChunks - 1).flat()]
    : chunkedArray
}
