import { useStyledActionSheet } from './useStyledActionSheet'

const CANCEL_OPTION = 'Cancel'

interface ShowDestructiveActionSheetParams {
  title: string
  message: string
  label: string
  handler: () => void
}

interface ShowActionSheetParams<OptionType> {
  title: string
  options: OptionType[]
  callback: (option: OptionType) => void
  onCancel?: () => void
}

export const useApplicationActionSheet = <OptionType extends string>() => {
  const { showActionSheetWithOptions } = useStyledActionSheet()

  const showDestructiveActionSheet = ({
    title,
    message,
    label,
    handler,
  }: ShowDestructiveActionSheetParams): void => {
    const options = [label, CANCEL_OPTION]
    const destructiveButtonIndex = 0
    const cancelButtonIndex = 1

    showActionSheetWithOptions(
      {
        title,
        message,
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        if ((buttonIndex as number) < 1) {
          handler()
        }
      },
    )
  }

  const showActionSheet = ({
    title,
    options,
    callback,
    onCancel,
  }: ShowActionSheetParams<OptionType>) => {
    const allOptions = [...options, CANCEL_OPTION]
    const cancelButtonIndex = allOptions.length - 1

    showActionSheetWithOptions(
      {
        title,
        options: allOptions,
        cancelButtonIndex,
      },
      (buttonIndex) => {
        if (buttonIndex === cancelButtonIndex) {
          onCancel?.()
          return
        }

        if (typeof callback === 'function') {
          callback(allOptions[buttonIndex as number] as OptionType)
        }
      },
    )
  }

  return {
    showActionSheet,
    showDestructiveActionSheet,
  }
}
