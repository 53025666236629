import { PaletteKeys } from '@src/style'

type FilterNotStartingWith<S, P extends string> = S extends `${P}${string}` ? never : S
type GeneralPalette = FilterNotStartingWith<PaletteKeys, 'palette.light' | 'palette.dark'>
type LightPalette = FilterNotStartingWith<PaletteKeys, 'palette.dark'>
type DarkPalette = FilterNotStartingWith<PaletteKeys, 'palette.light'>

export interface GenericTheme {
  [key: string]:
    | GeneralPalette
    | {
        light: LightPalette
        dark: DarkPalette
      }
}

// https://www.figma.com/file/JC5Lqpd1B6yMATjfq7yzp3/Nutrisense-Design-System?type=design&node-id=2643%3A11
export const theme = {
  /* Solid colors */
  'transparent': 'palette.transparent',
  'solid.white': 'palette.solid.white',
  'solid.black': 'palette.solid.black',

  'accent.citron': 'palette.accent.citron',
  'accent.brick': 'palette.accent.brick',
  'accent.moonstone': 'palette.accent.moonstone',
  'accent.tea': 'palette.accent.tea',

  'success.lightest': 'palette.success.lightest',
  'success.lighter': 'palette.success.lighter',
  'success.light_': 'palette.success.light',
  'success.base': 'palette.success.base',
  'success.darkest': 'palette.success.darkest',

  'warning.lightest': 'palette.warning.lightest',
  'warning.lighter': 'palette.warning.lighter',
  'warning.light_': 'palette.warning.light',
  'warning.base': 'palette.warning.base',
  'warning.darkest': 'palette.warning.darkest',

  'error.lightest': 'palette.error.lightest',
  'error.lighter': 'palette.error.lighter',
  'error.light_': 'palette.error.light',
  'error.base': 'palette.error.base',
  'error.darkest': 'palette.error.darkest',

  'info.lightest': 'palette.info.lightest',
  'info.lighter': 'palette.info.lighter',
  'info.light_': 'palette.info.light',
  'info.base': 'palette.info.base',
  'info.darkest': 'palette.info.darkest',

  'like': 'palette.like',

  /* Theme colors */
  'primary.base': {
    light: 'palette.light.primary.base',
    dark: 'palette.dark.primary.base',
  },
  'primary.dark': {
    light: 'palette.light.primary.dark',
    dark: 'palette.dark.primary.dark',
  },
  'secondary.base': {
    light: 'palette.light.secondary.base',
    dark: 'palette.dark.secondary.base',
  },
  'secondary.dark': {
    light: 'palette.light.secondary.dark',
    dark: 'palette.dark.secondary.dark',
  },
  'secondary.darker': {
    light: 'palette.light.secondary.darker',
    dark: 'palette.dark.secondary.darker',
  },
  'text.primary': {
    light: 'palette.light.text.primary',
    dark: 'palette.dark.text.primary',
  },
  'text.secondary': {
    light: 'palette.light.text.secondary',
    dark: 'palette.dark.text.secondary',
  },
  'text.tertiary': {
    light: 'palette.light.text.tertiary',
    dark: 'palette.dark.text.tertiary',
  },
  'text.quaternary': {
    light: 'palette.light.text.quaternary',
    dark: 'palette.dark.text.quaternary',
  },
  'text.link': {
    light: 'palette.light.text.link',
    dark: 'palette.dark.text.link',
  },
  'text.inColor': {
    light: 'palette.light.text.inColor',
    dark: 'palette.dark.text.inColor',
  },
  'background': {
    light: 'palette.light.background.base',
    dark: 'palette.dark.background.base',
  },
  'background.light_': {
    light: 'palette.light.background.light',
    dark: 'palette.dark.background.light',
  },
  'background.modal': {
    light: 'palette.light.background.modal',
    dark: 'palette.dark.background.modal',
  },
  'background.event': {
    light: 'palette.light.background.event',
    dark: 'palette.dark.background.event',
  },
  'background.drawer': {
    light: 'palette.light.background.drawer',
    dark: 'palette.dark.background.drawer',
  },
  'background.selected': {
    light: 'palette.light.background.selected',
    dark: 'palette.dark.background.selected',
  },
  'surface.base0': {
    light: 'palette.light.surface.base0',
    dark: 'palette.dark.surface.base0',
  },
  'surface.base': {
    light: 'palette.light.surface.base',
    dark: 'palette.dark.surface.base',
  },
  'surface.base1': {
    light: 'palette.light.surface.base1',
    dark: 'palette.dark.surface.base1',
  },
  'surface.base2': {
    light: 'palette.light.surface.base2',
    dark: 'palette.dark.surface.base2',
  },
  'surface.base3': {
    light: 'palette.light.surface.base3',
    dark: 'palette.dark.surface.base3',
  },
  'surface.banner': {
    light: 'palette.light.surface.banner',
    dark: 'palette.dark.surface.banner',
  },
  'disabled.background': {
    light: 'palette.light.disabled.background',
    dark: 'palette.dark.disabled.background',
  },
  'disabled.text': {
    light: 'palette.light.disabled.text',
    dark: 'palette.dark.disabled.text',
  },
  'range.good': {
    light: 'palette.light.range.good',
    dark: 'palette.dark.range.good',
  },
  'range.regular': {
    light: 'palette.light.range.regular',
    dark: 'palette.dark.range.regular',
  },
  'range.bad': {
    light: 'palette.light.range.bad',
    dark: 'palette.dark.range.bad',
  },
  'rangeText.good': {
    light: 'palette.light.rangeText.good',
    dark: 'palette.dark.rangeText.good',
  },
  'rangeText.regular': {
    light: 'palette.light.rangeText.regular',
    dark: 'palette.dark.rangeText.regular',
  },
  'rangeText.bad': {
    light: 'palette.light.rangeText.bad',
    dark: 'palette.dark.rangeText.bad',
  },
  'barChart.primary': {
    light: 'palette.light.barChart.primary',
    dark: 'palette.dark.barChart.primary',
  },
  'barChart.secondary': {
    light: 'palette.light.barChart.secondary',
    dark: 'palette.dark.barChart.secondary',
  },
  'tooltip.background': {
    light: 'palette.light.tooltip.background',
    dark: 'palette.dark.tooltip.background',
  },
  'tooltip.surface': {
    light: 'palette.light.tooltip.surface',
    dark: 'palette.dark.tooltip.surface',
  },
  'tooltip.textPrimary': {
    light: 'palette.light.tooltip.textPrimary',
    dark: 'palette.dark.tooltip.textPrimary',
  },
  'tooltip.textSecondary': {
    light: 'palette.light.tooltip.textSecondary',
    dark: 'palette.dark.tooltip.textSecondary',
  },
  'tooltip.link': {
    light: 'palette.light.tooltip.link',
    dark: 'palette.dark.tooltip.link',
  },

  /* Base components */
  'button.text.primary': {
    light: 'palette.solid.white',
    dark: 'palette.solid.white',
  },

  /* Components */
  'overlay': 'palette.opacity.black70',
} satisfies GenericTheme // eslint-disable-line prettier/prettier
