import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useImpersonationContext } from '@src/config/impersonation'
import { Env, Apollo, App } from '@config'
import { userSelector } from '@src/selectors/app'
import { Text } from '@components/base'
import { useWebPresentationModeContext } from '@src/config/webPresentationMode'

const ImpersonationBar = () => {
  const styles = useStyleSheet(themedStyle)
  const isImpersonating = useImpersonationContext()
  const { isWebPresentationMode } = useWebPresentationModeContext()

  const user = useSelector(userSelector)
  const userName = user?.fullName ?? user?.email ?? ''

  if (!isImpersonating || App.build.production) {
    return null
  }

  const endpointEnv = Apollo.endpointEnv as Env
  const barMessage = isWebPresentationMode
    ? `${endpointEnv} data`
    : ` ${userName} in ${endpointEnv}`

  return (
    <View style={[styles.container, styles[endpointEnv]]}>
      <Text type="small" style={styles.text}>
        {`You're viewing ${barMessage}`}
      </Text>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'theme.solid.black',
  },
  production: {
    backgroundColor: 'theme.error.base',
  },
  staging: {
    backgroundColor: 'theme.warning.base',
  },
  development: {
    backgroundColor: 'theme.info.base',
  },
})

export default ImpersonationBar
