import React, { View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Pill, Text } from '@components/base'
import {
  AddressType,
  formatAddress,
} from '@src/screens/Marketplace/components/checkout/AddressForm'
import { PaymentMethodInfo } from '@src/screens/Marketplace/components/checkout/PaymentMethodInfo'
import { AccountSection, ContactSection, SectionItem, ValueWithLabel } from '@src/screens/Profile'
import { ProfilePhoto } from '@src/components'
import { Address, PaymentMethod, Subscription } from '@src/types'
import { getSubscriptionTagDescriptor } from '@src/screens/Marketplace/utils/utils'
import { openUrl } from '@utils'
import { clientConfigStoreStateSelector } from '@selectors/app'
import { ReactNativeFile } from '@src/utils/image'

interface LegacyProfileContentProps {
  onChangeProfilePhoto: (photo: ReactNativeFile) => void
  photoSource: { uri?: string }
  accountDetails: { label: string; value: string }[]
  shouldShowDexcomResearch: boolean
  isDexcomConsentGiven: boolean
  onResearchConsentPress: () => void
  subscription:
    | (Pick<Subscription, 'id' | 'commitmentEndAt' | 'cancelAt' | 'status' | 'paused'> & {
        primaryProduct: { title: string }
      })
    | undefined
  paymentMethod: Pick<PaymentMethod, 'expirationDate' | 'last4' | 'cardType'> | null | undefined
  addressType: AddressType
  addressTitle: 'Shipping Address' | 'Billing Address'
  address:
    | Pick<Address, 'name' | 'street' | 'street2' | 'city' | 'state' | 'zipCode'>
    | null
    | undefined
  isDexcomResearchEnabled: boolean
  isDexcomIntegration: boolean
}

export const LegacyProfileContent = ({
  onChangeProfilePhoto,
  photoSource,
  accountDetails,
  shouldShowDexcomResearch,
  isDexcomConsentGiven,
  onResearchConsentPress,
  subscription,
  paymentMethod,
  addressType,
  addressTitle,
  address,
  isDexcomResearchEnabled,
  isDexcomIntegration,
}: LegacyProfileContentProps) => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()

  const goToSubscription = () =>
    navigation.navigate('ProgramDetails', { subscriptionId: subscription?.id })

  const { pillLabel = '', pillStatus = 'neutral' } = subscription
    ? getSubscriptionTagDescriptor(subscription)
    : {}

  const config = useSelector(clientConfigStoreStateSelector)

  return (
    <>
      <ProfilePhoto
        source={photoSource}
        onPhotoChange={onChangeProfilePhoto}
        style={styles.profilePhoto}
      />

      <AccountSection
        title="Account Details"
        onEditPress={() => navigation.navigate('EditAccountDetails')}
      >
        {accountDetails.map(({ label, value }, index) => (
          <SectionItem hasBottomBorder={index !== accountDetails.length - 1} key={label}>
            <ValueWithLabel label={label} value={value} />
          </SectionItem>
        ))}
      </AccountSection>

      <AccountSection title="Security" onEditPress={() => navigation.navigate('ChangePassword')}>
        <SectionItem>
          <ValueWithLabel label="Password" value={'• '.repeat(16)} />
        </SectionItem>
      </AccountSection>

      {subscription && (
        <AccountSection title="Manage Subscription">
          <SectionItem onPress={goToSubscription}>
            <ValueWithLabel label="Current Plan" value={subscription.primaryProduct.title} />
            <Pill text={pillLabel} status={pillStatus} />
          </SectionItem>
        </AccountSection>
      )}

      <AccountSection title="Payment Method">
        <SectionItem onPress={() => navigation.navigate('CreatePaymentMethod')}>
          {paymentMethod && <PaymentMethodInfo paymentMethod={paymentMethod} />}
          <Text type="regular" bold style={styles.changeText}>
            {paymentMethod ? 'Change' : 'Add'}
          </Text>
        </SectionItem>
      </AccountSection>

      <AccountSection title={addressTitle}>
        <SectionItem onPress={() => navigation.navigate('ChangeAddress', { addressType })}>
          <Text type="regular" numberOfLines={2} style={styles.address}>
            {address ? formatAddress(address) : ''}
          </Text>
          <Text type="regular" bold style={styles.changeText}>
            {address ? 'Change' : 'Add'}
          </Text>
        </SectionItem>
      </AccountSection>

      {shouldShowDexcomResearch && (
        <AccountSection title="Research Consent">
          <SectionItem onPress={onResearchConsentPress}>
            <View style={styles.textGrow}>
              <Text type="regular">Longitudinal Outcomes of CGM use</Text>
              <Text type="regular" style={isDexcomConsentGiven ? styles.optedIn : styles.optedOut}>
                {isDexcomConsentGiven ? 'Opted In' : 'Opted Out'}
              </Text>
            </View>
            <Text type="regular" bold style={styles.changeText}>
              Change
            </Text>
          </SectionItem>
        </AccountSection>
      )}

      {isDexcomResearchEnabled && isDexcomIntegration && (
        <AccountSection title="Research Surveys">
          <SectionItem icon="note-pencil" onPress={() => navigation.navigate('ResearchSurveys')}>
            <Text type="regular" style={styles.textGrow}>
              Review and edit surveys
            </Text>
          </SectionItem>
        </AccountSection>
      )}

      <AccountSection title="Referral">
        <SectionItem
          icon="user-plus"
          onPress={() => navigation.navigate('Referral', { source: 'Account' })}
        >
          <Text type="regular" style={styles.textGrow}>
            Refer a friend
          </Text>
        </SectionItem>
      </AccountSection>
      <ContactSection />
      <AccountSection hasDivider={false} title="Account">
        <SectionItem icon="trash-simple" onPress={() => openUrl(config.privacyCenterUrl)}>
          <Text type="regular" style={styles.textGrow}>
            Delete my account
          </Text>
        </SectionItem>
      </AccountSection>
    </>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  profilePhoto: {
    alignSelf: 'center',
    marginTop: 16,
    marginBottom: 24,
  },

  changeText: {
    marginLeft: 16,
    color: 'theme.text.link',
  },
  address: {
    flexShrink: 1,
  },
  textGrow: {
    flex: 1,
  },
  optedIn: {
    marginTop: 4,
    color: 'theme.success.darkest',
  },
  optedOut: {
    marginTop: 4,
    color: 'theme.disabled.text',
  },
})
