import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { userSelector } from '@src/selectors/app'
import { openUrl, Zendesk } from '@src/utils'

export const EligibilityRejection = () => {
  const user = useSelector(userSelector)
  const styles = useStyleSheet(themedStyles)

  const onContactSupportPress = () => {
    openUrl(Zendesk.baseUrl(user?.email))
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.topContainer}>
        <Text type="regular" style={styles.headerText}>
          We're Sorry
        </Text>
        <Icon name="x-circle" style={styles.rejectionIcon} />
        <Text type="title-2" style={styles.rejectionText}>
          Thank you for your interest in the Nutrisense program
        </Text>
        <Text type="regular" style={styles.rejectionText}>
          Unfortunately, you are not currently eligible for our program.
        </Text>
      </View>
      <Button
        accessibilityLabel="Contact support"
        onPress={onContactSupportPress}
        type="transparent"
        size="block"
      >
        Contact Support
      </Button>
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  topContainer: {
    flex: 1,
  },
  headerText: {
    textAlign: 'center',
  },
  rejectionText: {
    textAlign: 'center',
    marginBottom: 24,
  },
  rejectionIcon: {
    alignSelf: 'center',
    width: 64,
    marginTop: 48,
    marginBottom: 24,
    color: 'theme.error.base',
  },
  contactSupport: {
    paddingVertical: 12,
    textAlign: 'center',
  },
})
