import React from 'react'
import { ImageStyle } from 'react-native'
import { Icon } from '@src/components/Icon/Icon'

export type IconKey = keyof typeof IconSources
export type IconSource = typeof IconSources[IconKey]

export const IconSources = {
  adsClick: require('./source/ads-click.png'),
  healthAndSafety: require('./source/health-and-safety.png'),
  restaurant: require('./source/restaurant.png'),
  stats: require('./source/stats.png'),
  accessibilityNew: require('./source/accessibility-new.png'),
  fitnessCenter: require('./source/fitness-center.png'),
  cloudUpload: require('./source/cloud-upload.png'),
  closeOutline: require('./source/close-outline.png'),
  healthcare: require('./source/healthcare.png'),
  hourglass: require('./source/hourglass.png'),
  hourglassDark: require('./source/hourglass-dark.png'),
  checkmarkCircle: require('./source/checkmark-circle.png'),
  amex: require('./source/cards/Amex.png'),
  mastercard: require('./source/cards/Master.png'),
  visa: require('./source/cards/Visa.png'),
  checkmarkBoldIcon: require('./source/checkmark-bold.png'),
  iconCutlery: require('./source/cutlery.png'),
  drag: require('./source/drag.png'),
  iconDumbbell: require('./source/dumbbell.png'),
  fitbitIcon: require('./source/fitbit.png'),
  freeStyleLibreIcon: require('./source/freestylelibre.png'),
  appleHealth: require('./source/applehealth.png'),
  garminIcon: require('./source/garmin.png'),
  ketomojo: require('./source/ketomojo.png'),
  nutrisense: require('./source/nutrisense.png'),
  googleFitIcon: require('./source/google_fit.png'),
  myFitnessPal: require('./source/myfitnesspal.png'),
  iconMeasurement: require('./source/measurement.png'),
  ouraIcon: require('./source/oura.png'),
  plus: require('./source/plus.png'),
  radioButtonOn: require('./source/radio-button-on.png'),
  trackingDevice: require('./source/tracking-device.png'),
  infoIcon: require('./source/info-icon.png'),
  barChartIcon: require('./source/bar-chart-icon.png'),
  settingsIcon: require('./source/settings-icon.png'),
  calendarIcon: require('./source/calendar.png'),
  instagram: require('./source/instagram.png'),
}

/**
 * @deprecated since we have generic icon component
 */
export const RadioButtonOn = (style: ImageStyle) => (
  <Icon iconDescriptor={{ pack: 'customImage', name: 'radioButtonOn' }} style={style} />
)

/**
 * @deprecated since we have generic icon component
 */
export const CutleryIcon = (style: ImageStyle) => (
  <Icon iconDescriptor={{ pack: 'customImage', name: 'iconCutlery' }} style={style} />
)

/**
 * @deprecated since we have generic icon component
 */
export const DumbbellIcon = (style: ImageStyle) => (
  <Icon iconDescriptor={{ pack: 'customImage', name: 'iconDumbbell' }} style={style} />
)

/**
 * @deprecated since we have generic icon component
 */
export const CheckmarkBoldIcon = (style: ImageStyle) => (
  <Icon iconDescriptor={{ pack: 'customImage', name: 'checkmarkBoldIcon' }} style={style} />
)
