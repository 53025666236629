import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import moment from 'moment'
import { fastingSettingsSelector } from '@src/selectors/settings'
import CustomSetting from './CustomSetting'

const SLIDER_START = 12
const HOURS_IN_DAY = 24

const getFastingTime = (fasting: number) => moment(fasting % HOURS_IN_DAY, 'h').format('hh:mm a')

const FastingWindowSetting = () => {
  const { fastingStart, fastingEnd } = useSelector(fastingSettingsSelector)

  const fastingStartFormatted =
    fastingStart < SLIDER_START ? fastingStart + HOURS_IN_DAY : fastingStart

  const fastingEndFormatted = fastingEnd <= SLIDER_START ? fastingEnd + HOURS_IN_DAY : fastingEnd

  const navigation = useNavigation()

  const text = `${getFastingTime(fastingStartFormatted)} - ${getFastingTime(fastingEndFormatted)}`

  const onPress = () => {
    navigation.navigate('ChangeFastingWindow')
  }

  return <CustomSetting title="Fasting Window" onPress={onPress} text={text} />
}

export default FastingWindowSetting
