import { useCallback, useEffect, useMemo, useState } from 'react'
import { Appearance } from 'react-native'
import { useSelector } from 'react-redux'
import { darkModeStoreStateSelector } from '@src/selectors/settings'
import { DarkMode } from '@src/screens/Settings'
import { getTheme, ThemeName } from '@style'

const useDefaultThemeName = () => {
  const [mode, setMode] = useState(
    () => (Appearance.getColorScheme() as ThemeName) || ThemeName.Light,
  )

  const onAppearancePreferencesChange = useCallback(() => {
    // using value from params is not reliable
    // see: https://github.com/facebook/react-native/issues/30484
    const colorScheme = Appearance.getColorScheme()
    if (colorScheme === mode) {
      return
    }
    setMode((colorScheme as ThemeName) || ThemeName.Light)
  }, [mode])

  useEffect(() => {
    const appearanceListener = Appearance.addChangeListener(onAppearancePreferencesChange)

    return () => {
      appearanceListener.remove()
    }
  }, [onAppearancePreferencesChange])

  return { defaultThemeName: mode }
}

export const useTheme = () => {
  const darkModeSetting = useSelector(darkModeStoreStateSelector)
  const { defaultThemeName } = useDefaultThemeName()

  const themeName = useMemo(() => {
    switch (darkModeSetting) {
      case DarkMode.On:
        return ThemeName.Dark
      case DarkMode.Off:
        return ThemeName.Light
      case DarkMode.Auto:
      default:
        return defaultThemeName
    }
  }, [darkModeSetting, defaultThemeName])

  const theme = useMemo(() => getTheme(themeName), [themeName])

  return {
    theme,
    themeName,
  }
}

export const useIsDarkMode = () => {
  const { themeName } = useTheme()
  return themeName === ThemeName.Dark
}
