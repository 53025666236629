import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Icon, Input, InputProps } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'

export const SearchBar = ({
  onChange,
  onChangeText,
  placeholder,
  value,
  style,
  ...rest
}: InputProps) => {
  const styles = useStyleSheet(themedStyle)

  const handleClearText = () => {
    onChange?.('')
    onChangeText?.('')
  }

  const clearTextIcon = (
    <TouchableOpacity accessibilityLabel="Clear" onPress={handleClearText}>
      <Icon name="x" size={20} />
    </TouchableOpacity>
  )

  return (
    <Input
      accessoryRight={value ? clearTextIcon : null}
      autoCapitalize="none"
      autoCorrect={false}
      containerStyle={styles.inputContainer}
      iconLeft="magnifying-glass"
      onChange={onChange}
      onChangeText={onChangeText}
      placeholder={placeholder ?? 'Search'}
      returnKeyType="search"
      spellCheck={false}
      style={[styles.container, style]}
      value={value}
      {...rest}
    />
  )
}

const themedStyle = StyleService.create({
  container: {
    margin: 16,
  },
  inputContainer: {
    height: 40,
    paddingHorizontal: 8,
    paddingVertical: 0,
    borderColor: 'theme.transparent',
    backgroundColor: 'theme.surface.base2',
  },
})
