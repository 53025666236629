import Config from 'react-native-config'
import { oauthLogin } from '@src/utils/oauth'

const REDIRECT_URI = 'nutrisense.auth://app/oauth/dexcom'

const BASE_API_URL = 'https://api.dexcom.com'
const SANDBOX_BASE_API_URL = 'https://sandbox-api.dexcom.com'

const getConfig = ({ useSandbox }: { useSandbox: boolean }) => {
  const baseUrl = useSandbox ? SANDBOX_BASE_API_URL : BASE_API_URL
  const clientId = useSandbox ? Config.STAGING_DEXCOM_CLIENT_ID : Config.PRODUCTION_DEXCOM_CLIENT_ID
  return {
    clientId,
    redirectUrl: REDIRECT_URI,
    scopes: ['offline_access'],
    responseTypes: ['code'],
    serviceConfiguration: {
      authorizationEndpoint: `${baseUrl}/v2/oauth2/login`,
      tokenEndpoint: `${baseUrl}/v2/oauth2/token`,
    },
    skipCodeExchange: true,
  }
}

const login = async ({ useSandbox }: { useSandbox: boolean }) => {
  const result = await oauthLogin(getConfig({ useSandbox }))

  return { authorizationCode: result.user?.authorizationCode, status: result.status }
}

export default { login }
