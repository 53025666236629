import { gql } from '@apollo/client'

const ALL_INGREDIENTS = gql`
  query allCustomIngredients {
    allCustomIngredients {
      ingredients {
        id
        description
        servingAmount
        servingUnits
        calories
        createdAt
        nutrition {
          key
          value
        }
      }
    }
  }
`

export default ALL_INGREDIENTS
