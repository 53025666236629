import React, { ReactNode } from 'react'
import { View, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Button, Text } from '@src/components/base'
import { NavigationContainer } from '@src/screens/Common/containers'

interface PauseSubscriptionContainerProps {
  title: string
  subtitle: string
  description: string
  children: ReactNode
  nextTitle: string
  nextDisabled?: boolean
  cancelTitle?: string
  onNext: () => void
  onCancel?: () => void
}

export const PauseSubscriptionContainer = ({
  title,
  subtitle,
  description,
  children,
  nextTitle,
  nextDisabled = false,
  cancelTitle,
  onNext,
  onCancel,
}: PauseSubscriptionContainerProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <NavigationContainer title={title}>
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <View style={styles.contentContainer}>
          <ScrollView contentContainerStyle={styles.scrollView}>
            <View style={styles.heading}>
              <Text type="title-3">{subtitle}</Text>
              <Text type="regular" style={styles.description}>
                {description}
              </Text>
            </View>
            {children}
          </ScrollView>
          <View style={styles.actionsContainer}>
            <Button
              type="primary"
              size="block"
              accessibilityLabel={nextTitle}
              disabled={nextDisabled}
              onPress={onNext}
            >
              {nextTitle}
            </Button>
            {cancelTitle && (
              <Button
                type="transparent"
                size="block"
                accessibilityLabel="Cancel"
                onPress={onCancel}
                style={styles.cancelButton}
              >
                {cancelTitle}
              </Button>
            )}
          </View>
        </View>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  scrollView: {
    flexGrow: 1,
  },
  heading: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  description: {
    marginTop: 16,
  },
  actionsContainer: {
    padding: 16,
  },
  cancelButton: {
    marginTop: 8,
  },
})
