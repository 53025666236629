import { gql } from '@apollo/client'

export const SWITCH_SENSOR_ON_CANCELLATION = gql`
  mutation switchSensorOnCancellation {
    switchSensorOnCancellation {
      userId
      sensorKind
    }
  }
`
