import { DeviceEventEmitter, NativeEventEmitter, NativeModules } from 'react-native'
import { Libre3BluetoothManagerEvents, libre3BluetoothManager } from 'react-native-freestyle-libre'
import { BleEventType } from 'react-native-ble-manager'
import { BackgroundServiceEvents } from './constants'

const BleManagerModule = NativeModules.BleManager
const bleManagerEmitter = new NativeEventEmitter(BleManagerModule)

export class ListenersManager {
  private listenersCleanup: (() => void)[] = []

  constructor() {
    this.addBackgroundServiceEventListener(BackgroundServiceEvents.Cleanup, () => {
      this.listenersCleanup.forEach((cleanup) => cleanup())
    })
  }

  addBackgroundServiceEventListener = (
    event: BackgroundServiceEvents,
    listener: (value: any) => void,
  ) => {
    const subscription = DeviceEventEmitter.addListener(event, listener)

    this.listenersCleanup.push(() => {
      subscription.remove()
    })
  }

  addLibre3BluetoothManagerListener = (
    event: Libre3BluetoothManagerEvents,
    listener: (params: any) => void,
  ) => {
    libre3BluetoothManager.on(event, listener)

    this.listenersCleanup.push(() => {
      libre3BluetoothManager.removeListener(event, listener)
    })
  }

  addBleManagerListener = (event: BleEventType, listener: (params: any) => void) => {
    const subscription = bleManagerEmitter.addListener(event, listener)

    this.listenersCleanup.push(() => {
      subscription.remove()
    })
  }
}
