import React, { useState } from 'react'
import { WebView } from 'react-native-webview'
import { WebViewNavigationEvent } from 'react-native-webview/lib/WebViewTypes'
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationBar } from '@src/components'
import { AppStackParamList } from '@src/navigation/types'

export const WebViewModal = () => {
  const styles = useStyleSheet(themedStyles)
  const [pageTitle, setPageTitle] = useState('Loading...')
  const navigation = useNavigation()
  const {
    params: { uri, customTitle = null },
  } = useRoute<RouteProp<AppStackParamList, 'WebViewModal'>>()

  const onCloseButtonPress = () => {
    navigation.goBack()
  }

  const onLoad = (event: WebViewNavigationEvent) => {
    setPageTitle(customTitle || event.nativeEvent.title)
  }

  return (
    <SafeAreaView style={styles.container} edges={['bottom']}>
      <NavigationBar
        title={pageTitle}
        titleStyle={styles.navigationTitle}
        style={styles.navigationBar}
        backgroundColor={styles.container.backgroundColor}
        leftAccessory={{
          iconDescriptor: { pack: 'eva', name: 'close' },
          iconTintColor: 'theme.solid.white',
          onPress: onCloseButtonPress,
        }}
      />
      <WebView source={{ uri }} onLoad={onLoad} />
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.surface.base',
  },
  navigationBar: {
    marginBottom: 16,
  },
  navigationTitle: {
    color: 'theme.solid.white',
  },
})
