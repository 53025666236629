/*
 * From the USDA website:
 *
 * Carbohydrates provide 4 calories per gram, protein provides 4 calories per gram, and fat provides 9 calories per gram
 * This information is also included at the end of the Nutrition Facts label on food packages.
 * For more information about these nutrients, view the following resources about macro-nutrients:
 *
 * https://www.nal.usda.gov/programs/fnic
 */
export const PROTEIN_CALORIES = 4
export const CARB_CALORIES = 4
export const FAT_CALORIES = 9
