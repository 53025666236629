import React, { useRef, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { CircleIcon, GestureDismissibleModal, SelectFromActionSheet } from '@components'
import { showSnack } from '@src/services/Bluetooth/utils'
import { useGoBack } from '@src/utils'
import { healthSettingsSelector } from '@src/selectors/app'
import { settingsSelector } from '@src/selectors/settings'
import { fieldValueSelector } from '../../UserSettings/ToggleSetting'
import { orderIntervalRanges } from '../utils'

// We will migrate scan reminders to work as regular reminders in the future
// For now its easier to handle it in separate component
export const EditScanReminder = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const goBack = useGoBack()

  const settings = useSelector(settingsSelector)
  const scanReminderEnabled = useSelector(fieldValueSelector('reminders'))
  const healthSettings = useSelector(healthSettingsSelector)

  const options = orderIntervalRanges(healthSettings.scanReminderOptions)
  const currentReminderInterval = options.find(
    (option) => option.value === settings['scanReminder'],
  )

  const [interval, setInterval] = useState(currentReminderInterval?.text)

  const isSubmitting = useRef(false)

  const onDeleteButtonPress = () => {
    if (isSubmitting.current) {
      return
    }
    isSubmitting.current = true
    dispatch({
      type: 'settings/update',
      payload: { reminders: false },
      success: () => {
        showSnack('Reminder deleted!')
        goBack()
      },
      complete: () => {
        isSubmitting.current = false
      },
    })
  }

  const onSaveButtonPress = () => {
    if (isSubmitting.current) {
      return
    }
    isSubmitting.current = true
    const value = options.find((option) => option.text === interval)?.value
    dispatch({
      type: 'settings/update',
      payload: { scanReminder: value },
      success: () => {
        showSnack('Reminder updated!')
        goBack()
      },
      complete: () => {
        isSubmitting.current = false
      },
    })
  }

  return (
    <GestureDismissibleModal dismissButtonText="Cancel">
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <Text type="title-3" style={styles.header}>
          {scanReminderEnabled ? 'Edit Reminder' : 'Add Reminder'}
        </Text>
        <View style={styles.form}>
          <View style={styles.typeTitle}>
            <CircleIcon name="bell-ringing" style={styles.icon} />
            <Text type="title-2">Scan Reminder</Text>
          </View>
          <SelectFromActionSheet
            label="Reminder Interval"
            title="Reminder Interval"
            options={options.map(({ text }) => text)}
            value={interval}
            onChange={setInterval}
          />
        </View>
        <View>
          <Button
            type="primary"
            size="block"
            accessibilityLabel="Add reminder"
            onPress={onSaveButtonPress}
          >
            Save
          </Button>

          <Button
            type="transparent"
            size="block"
            accessibilityLabel="Delete reminder"
            onPress={onDeleteButtonPress}
            style={styles.deleteButton}
            textStyle={styles.deleteButtonText}
          >
            Delete Reminder
          </Button>
        </View>
      </SafeAreaView>
    </GestureDismissibleModal>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    marginBottom: 16,
  },
  header: {
    textAlign: 'center',
    marginBottom: 16,
  },
  form: {
    flex: 1,
  },
  typeTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 16,
  },
  deleteButton: {
    marginTop: 8,
  },
  deleteButtonText: {
    color: 'theme.error.base',
  },
  icon: {
    marginRight: 16,
    padding: 16,
  },
})
