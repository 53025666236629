import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReduxNetworkProvider } from 'react-native-offline'
import { Action } from 'redux'
import { offlineActionCreators } from 'react-native-offline'
import { AppState } from 'react-native'
import { networkSelector } from '@selectors/network'
import Device from '../device'

const QUEUE_RELEASE_INTERVAL = 10000

export const NetworkProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch()
  const network = useSelector(networkSelector)
  const [shouldPing, setShouldPing] = useState(true)
  const [prevAppState, setPrevAppState] = useState(AppState.currentState)

  useEffect(() => {
    const interval = setInterval(() => {
      if (!network.isConnected) {
        return
      }

      for (const action of network.actionQueue) {
        dispatch(offlineActionCreators.removeActionFromQueue(action))
        dispatch(action as Action)
      }
    }, QUEUE_RELEASE_INTERVAL)

    return () => {
      clearInterval(interval)
    }
  }, [network, dispatch])

  useEffect(() => {
    const subscription = AppState.addEventListener('change', (state) => {
      if (prevAppState === 'background' && state === 'active') {
        setShouldPing(false)
        setTimeout(() => {
          setShouldPing(true)
        }, 1000 * 3)
      }
      setPrevAppState(state)
    })

    return () => subscription.remove()
  }, [prevAppState])

  return (
    <ReduxNetworkProvider
      pingInterval={5000}
      pingOnlyIfOffline
      pingTimeout={10000}
      httpMethod="HEAD"
      pingServerUrl="https://www.google.com/"
      pingInBackground={false}
      shouldPing={!Device.web && shouldPing}
    >
      {children}
    </ReduxNetworkProvider>
  )
}
