import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFocusEffect } from '@react-navigation/native'
import { productsByCategorySelector, videoCallProductSelector } from '@selectors/app.ts'
import { BillingProductCategory } from '@src/types'

export const useNutritionistMenuProducts = () => {
  const dispatch = useDispatch()
  let products = useSelector(productsByCategorySelector(BillingProductCategory.NutritionistHub))

  const videoCallProduct = useSelector(videoCallProductSelector)
  if (videoCallProduct) {
    products = products.filter((product) => product.id !== videoCallProduct.id)
  }

  useFocusEffect(
    useCallback(() => {
      dispatch({
        type: 'app/fetchProducts',
        payload: { category: BillingProductCategory.NutritionistHub },
      })
    }, [dispatch]),
  )

  return products
}
