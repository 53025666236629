import { mergeWith } from 'lodash'
import { fromKeyValuePairs } from '@transforms'
import { NutritionCard } from '@src/types'

export const transformNutrition = (event: NutritionCard) => {
  return { ...event, nutrition: fromKeyValuePairs(event.nutrition.today) }
}

export const combineNutrition = (
  nutritionA: Record<string, number>,
  nutritionB: Record<string, number>,
  sign: 1 | -1,
) => mergeWith(nutritionA, nutritionB, (a, b) => Number(a || 0) + sign * Number(b || 0))
