import { useEffect, useState } from 'react'
import { Image } from 'react-native'

type UseImageDownloadStateLoading = { loading: true }
type UseImageDownloadStateLoaded = { loading: false; width: number; height: number }
type UseImageDownloadStateFailed = { loading: false; error: true; errorMessage: string }
const useImageDownloadState = (
  uri?: string,
): UseImageDownloadStateFailed | UseImageDownloadStateLoaded | UseImageDownloadStateLoading => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<false | string>(false)
  const [metaData, setMetaData] = useState<{ width: number; height: number } | null>(null)
  useEffect(() => {
    if (!uri) {
      return
    }
    setLoading(true)
    setError(false)
    setMetaData(null)
    Image.prefetch(uri)
      .then(() => {
        Image.getSize(
          uri,
          (width, height) => {
            setMetaData({ width, height })
            setLoading(false)
          },
          (e) => {
            console.error(e)
            setError(e.message)
            setLoading(false)
          },
        )
      })
      .catch((e) => {
        console.error(e)
        setError(e.message)
        setLoading(false)
      })
  }, [uri])

  if (loading || !metaData) {
    return { loading: true }
  }
  if (error) {
    return {
      loading,
      error: true,
      errorMessage: error,
    }
  }
  return {
    loading,
    ...metaData,
  }
}

export default useImageDownloadState
