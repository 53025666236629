import React from 'react'
import { View } from 'react-native'
import { TouchableOpacity } from 'react-native'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useSlateToReact } from '@src/screens/NutritionistHub/hooks/nutritionistNotes/useSlateToReact'
import { Icon, Text } from '@src/components/base'
import { latestUnpinnedMemberNoteSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { Note } from '@src/types'
import { FULL_DAY_MONTH_AND_DATE_FORMAT } from '@src/screens/NutritionistHub/constants'
import { CustomEventTypes } from '@src/config'
import { useNutritionistHubTracker } from '@src/screens/NutritionistHub/hooks'
export interface MemberNoteCardProps {
  note: Note
  showLatestTag?: boolean
  hidePinnedAt?: boolean
}

export const MemberNoteCard = ({
  note: {
    id,
    pinnedAt,
    body,
    createdAt,
    updatedAt,
    creator: { id: creatorId },
  },
  showLatestTag = false,
  hidePinnedAt = false,
}: MemberNoteCardProps) => {
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyles)
  const latestUnpinnedNote = useSelector(latestUnpinnedMemberNoteSelector)
  const track = useNutritionistHubTracker()
  const { conciseView } = useSlateToReact(body)

  const handleNoteCardPress = () => {
    track(CustomEventTypes.NutritionistHubNoteCardTapped, { noteId: id, creatorId })
    navigation.navigate('MemberNoteDetail', { noteId: id })
  }

  const footerDescription =
    pinnedAt && !hidePinnedAt ? (
      <>
        <Icon size="16" name="push-pin" />
        <Text type="small" style={styles.timestamp}>
          Pinned on {moment(pinnedAt).format(FULL_DAY_MONTH_AND_DATE_FORMAT)}
        </Text>
      </>
    ) : (
      <Text type="small" style={styles.timestamp}>
        Added on {moment(createdAt).format(FULL_DAY_MONTH_AND_DATE_FORMAT)}
      </Text>
    )

  return (
    <TouchableOpacity
      accessibilityLabel="note"
      style={styles.noteCard}
      onPress={handleNoteCardPress}
    >
      <View style={styles.note}>
        <View style={styles.body}>{conciseView}</View>
        <View style={styles.footer}>
          {footerDescription}
          {latestUnpinnedNote?.updatedAt === updatedAt && showLatestTag && (
            <View style={styles.badge}>
              <Text type="regular" bold style={styles.badgeText}>
                Latest
              </Text>
            </View>
          )}
        </View>
      </View>
      <Icon name="caret-right" size="16" style={styles.caret} />
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  noteCard: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: 'theme.surface.base1',
    shadowColor: 'theme.solid.black',
    backgroundColor: 'theme.background',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 17,
    elevation: 5, // Android shadow property
  },
  caret: {
    flex: 1,
    marginRight: 8,
  },
  note: {
    flex: 9,
    padding: 16,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  body: {
    maxHeight: 48,
    lineHeight: 20,
  },
  footer: {
    marginTop: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  timestamp: {
    marginLeft: 4,
    color: 'theme.text.secondary',
  },
  badge: {
    height: 24,
    backgroundColor: 'theme.info.lightest',
    borderRadius: 32,
    borderWidth: 1,
    borderColor: 'theme.info.lighter',
    paddingHorizontal: 16,
    marginLeft: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  badgeText: {
    color: 'theme.info.darkest',
  },
})
