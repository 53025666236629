import { gql } from '@apollo/client'

export const FETCH_PROGRAMS = gql`
  query allPrograms($ids: [ID]!) @api(name: cms) {
    programs(sort: "position", filters: { id: { in: $ids } }) {
      data {
        id
        attributes {
          position
          title
          subtitle
          description
          featuredImage {
            data {
              id
              attributes {
                url
                previewUrl
              }
            }
          }
        }
      }
    }
  }
`
