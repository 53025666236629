import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import FastImage, { ImageStyle } from 'react-native-fast-image'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { DrawerNavigationHelpers } from '@react-navigation/drawer/lib/typescript/src/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { CircleIcon, Feature, useFeatureFlag } from '@components'
import { Debug } from '@config'
import { AccountAvatar } from '@src/components/AccountAvatar'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import { useOneTimeVisitedState } from '@src/hooks/useOneTimeVisitedState'
import { Storage, User } from '@src/utils'
import { useHasLargeScreen } from '@src/config/device'
import { WalkthroughTooltip } from '@src/components/WalkthroughTooltip'
import { useScreenStay } from '@src/hooks/useScreenDelayedFocusTracking'
import { MobileAppFeature } from '@src/types'

export interface DrawerHeaderProps {
  title: string
  imageSource: any
  navigation: DrawerNavigationHelpers
}

export const DRAWER_ACCOUNT_AVATAR_TOP_PADDING = 16

export const DrawerHeader = ({ title, imageSource, navigation }: DrawerHeaderProps) => {
  const styles = useStyleSheet(headerThemedStyle)
  const insets = useSafeAreaInsets()

  const hasLargeScreen = useHasLargeScreen()
  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)
  const screenIsFocused = useScreenStay()
  const { shouldShow, markAsShown } = useOneTimeVisitedState(
    Storage.ACCOUNT_DASHBOARD_WALKTHROUGH_VISITED_KEY,
  )

  const onViewAccount = () => {
    navigation.navigate('Account')
    navigation.closeDrawer()
  }
  const onAdminPress = () => {
    if (Debug.useAdminMenu && User.hasFeature(MobileAppFeature.AdminAccess)) {
      navigation.navigate('AdminSettings')
      navigation.closeDrawer()
    }
  }

  const legacyDrawerAccountAvatar = (
    <TouchableOpacity
      onPress={onViewAccount}
      onLongPress={onAdminPress}
      accessibilityLabel="View account"
      style={[styles.container, { paddingTop: insets.top + DRAWER_ACCOUNT_AVATAR_TOP_PADDING }]}
    >
      <View style={styles.avatarContainer}>
        {imageSource?.uri ? (
          <FastImage style={styles.avatar as ImageStyle} source={imageSource} />
        ) : (
          <CircleIcon name="user" style={styles.avatar} />
        )}
        <View style={styles.nameContainer}>
          <Text type="large">{title}</Text>
          <Text type="regular" style={styles.viewAccountLink}>
            View account
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  )

  const drawerAccountAvatar = (
    <View
      style={[styles.container, { paddingTop: insets.top + DRAWER_ACCOUNT_AVATAR_TOP_PADDING }]}
    >
      <View style={styles.avatarContainer}>
        <WithWalkthrough
          isVisible={screenIsFocused && shouldShow}
          onPress={onViewAccount}
          onLongPress={onAdminPress}
          accessibilityLabel="Account"
          tooltipStyle={{ marginLeft: -12, width: 300 }}
          content={<WalkthroughTooltip title="Check out your new Account section!" />}
          onClose={markAsShown}
        >
          <View style={{ marginTop: 2 }}>
            <AccountAvatar />
          </View>
        </WithWalkthrough>
        <View style={styles.nameContainer}>
          <Text type="large">{title}</Text>
          <Text type="regular" style={styles.viewAccountLink}>
            View account
          </Text>
        </View>
      </View>
    </View>
  )

  return appNavigationUpdatesFeatureEnabled && hasLargeScreen
    ? drawerAccountAvatar
    : legacyDrawerAccountAvatar
}

const headerThemedStyle = StyleService.create({
  container: {
    padding: 16,
  },
  avatarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    height: 48,
    width: 48,
    marginRight: 16,
    borderRadius: 24,
  },
  nameContainer: {
    flexShrink: 1,
    justifyContent: 'space-between',
  },
  viewAccountLink: {
    color: 'theme.text.secondary',
  },
})
