import React from 'react'
import { DeviceEventEmitter } from 'react-native'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { TimeZoneContext } from '@src/config/timezone'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AdminSettings as AdminSettingsComponent } from '../components/AdminSettings'

class AdminSettings extends React.Component {
  onSelectTimeZone = (item) => {
    const { timeZone, setTimeZone } = this.context

    if (item.text !== timeZone) {
      setTimeZone(item.text)

      this.props.dispatch({
        type: 'app/clearCaches',
        success: () => DeviceEventEmitter.emit('onRefreshPress', {}),
      })
    }
  }

  render() {
    const timeZones = get(this.props, 'clientConfig.timeZones', []).map((tz) => ({
      text: tz,
      value: tz,
    }))
    const { users } = this.props
    const { timeZone } = this.context

    return (
      <NavigationContainer title="Admin Settings">
        <AdminSettingsComponent
          users={users}
          timeZones={timeZones}
          selectedTimeZone={timeZone}
          onSelectTimeZone={this.onSelectTimeZone}
        />
      </NavigationContainer>
    )
  }
}

AdminSettings.contextType = TimeZoneContext

export const AdminSettingsContainer = connect(({ app, users }) => ({
  clientConfig: app.clientConfig,
  users,
}))(AdminSettings)
