import React, { useEffect, useState } from 'react'
import { View, ViewStyle, StyleProp } from 'react-native'
import { ViewPager } from '@ui-kitten/components'
import { compact } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { PageDots } from './PageDots'

export interface HorizontalViewPagerProps {
  children: JSX.Element[]
  dotStyle: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  onSelect?: (index: number) => void
  lazy?: boolean
  selectedIndex?: number
}

export const HorizontalViewPager = ({
  children,
  dotStyle,
  onSelect,
  containerStyle,
  lazy,
  selectedIndex = 0,
}: HorizontalViewPagerProps) => {
  const styles = useStyleSheet(themedStyles)
  const [currentIndex, setCurrentIndex] = useState(selectedIndex)

  useEffect(() => {
    onSelect && onSelect(currentIndex)
  }, [currentIndex, onSelect])

  const handleOnSelect = (selectedIndex: number) => {
    if (currentIndex !== selectedIndex) {
      setCurrentIndex(selectedIndex)
    }
  }

  const compactedChildren = compact(children)

  return (
    <>
      <View style={[styles.container, containerStyle]}>
        <ViewPager
          selectedIndex={currentIndex}
          onSelect={handleOnSelect}
          shouldLoadComponent={(index) => {
            if (lazy) {
              return selectedIndex === index
            }
            return true
          }}
          pointerEvents="auto"
          style={containerStyle}
        >
          {compactedChildren}
        </ViewPager>
      </View>
      <PageDots dots={compactedChildren} dotStyle={dotStyle} selectedIndex={currentIndex} />
    </>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    overflow: 'hidden',
    marginBottom: 16,
  },
})
