import React from 'react'
import { StyleSheet } from 'react-native'
import MaterialIcon from 'react-native-vector-icons/MaterialIcons'
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import FontAwesome6Pro from 'react-native-vector-icons/FontAwesome6Pro'
import { IconPack } from '@src/components/Icon/types'

const CustomIcon = ({
  name,
  pack,
  color,
  size,
  style,
  solid,
}: {
  name: string
  pack: string
  color?: string
  size?: number
  style?: any
  solid?: boolean
}) => {
  const { width, tintColor, ...iconStyle } = StyleSheet.flatten(style || {})
  const iconColor = color || tintColor
  const iconSize = size || width
  switch (pack) {
    case IconPack.Material:
      return <MaterialIcon name={name} color={iconColor} size={iconSize} style={iconStyle} />
    case IconPack.MaterialCommunity:
      return (
        <MaterialCommunityIcon name={name} color={iconColor} size={iconSize} style={iconStyle} />
      )
    case IconPack.FontAwesome6Pro:
      return (
        <FontAwesome6Pro
          name={name}
          color={iconColor}
          size={iconSize}
          style={iconStyle}
          solid={solid}
        />
      )
    default:
      return null
  }
}

const IconProvider = (name: string, pack: string) => ({
  toReactElement: (props: any) => CustomIcon({ name, pack, ...props }),
})

const createIconsMap = (pack: string) =>
  new Proxy(
    {},
    {
      get: (_, name: string) => IconProvider(name, pack),
    },
  )

export const MaterialIconsPack = {
  name: IconPack.Material,
  icons: createIconsMap(IconPack.Material),
}

export const MaterialCommunityIconsPack = {
  name: IconPack.MaterialCommunity,
  icons: createIconsMap(IconPack.MaterialCommunity),
}

export const FontAwesome6ProIconsPack = {
  name: IconPack.FontAwesome6Pro,
  icons: createIconsMap(IconPack.FontAwesome6Pro),
}
