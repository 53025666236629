import React, { useEffect, useRef } from 'react'
import { useRoute } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'react-native'
import { AppRouteProp } from '@navigation/types'
import { MealEditorWrapper } from '@screens/Meals'
import { getDraftMeal } from '@screens/Meals/utils'
import { mealDraftSelector } from '@selectors/meals'

export const CreateMeal = () => {
  const dispatch = useDispatch()
  const { params = {} } = useRoute<AppRouteProp<'AddMeal'>>()
  const { mealKind } = params

  const draftMeal = useSelector(mealDraftSelector)
  const shouldShowDraftAlert = useRef(true)

  // Display alert for continuing from unfinished meal
  useEffect(() => {
    if (draftMeal.hasUnsavedChanges && shouldShowDraftAlert.current && !mealKind) {
      Alert.alert('', 'We found an unfinished meal. Do you want to continue from it?', [
        { text: 'Yes', onPress: () => dispatch({ type: 'meals/saveDraft', payload: draftMeal }) },
        { text: 'No', onPress: () => dispatch({ type: 'meals/saveDraft' }) },
      ])
    }
    shouldShowDraftAlert.current = false
  }, [dispatch, draftMeal, mealKind])

  // Handle creating a new meal by clicking on notification
  useEffect(() => {
    if (!mealKind) {
      return
    }

    dispatch({
      type: 'meals/saveDraft',
      payload: getDraftMeal({ title: mealKind.toUpperCase(), kind: mealKind }),
    })
  }, [dispatch, mealKind])

  return <MealEditorWrapper />
}
