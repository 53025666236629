import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ScrollView, TouchableOpacity, useWindowDimensions } from 'react-native'
import { capitalize, debounce, isEqual, map, xor } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Device } from '@src/config'
import { TypeFilter } from '@src/models/app.types'
import { Text } from '@components/base'
import { getOrderByAfterTypesChanged } from '../ListFilter/utils'
import {
  FilterType,
  FormattedFilter,
  ListQuickFilterInnerProps,
  NestedFiltersType,
} from './ListQuickFilter.types'

export const ListQuickFilter = (props: ListQuickFilterInnerProps) => {
  const styles = useStyleSheet(themedStyle)
  const dimensions = useWindowDimensions()
  const { filters, sort, onSortUpdated, useNestedQuickFilters = false } = props

  const formattedFilters = useMemo(() => {
    const newFilters: FormattedFilter[] = [{ index: 0, title: 'All', value: 'all', types: [] }]
    if (useNestedQuickFilters) {
      Object.keys(filters).forEach((filter, i) =>
        newFilters.push({
          index: i + 1,
          title: capitalize(filter),
          value: filter,
          types: (filters as NestedFiltersType)[filter],
        }),
      )
    } else {
      ;(filters as FilterType).forEach((filter, i) =>
        newFilters.push({
          index: i + 1,
          title: capitalize(filter),
          value: filter,
          types: [],
        }),
      )
    }

    return newFilters
  }, [filters, useNestedQuickFilters])

  const activeFiltersMemoized = useMemo(() => {
    const nextFilters = formattedFilters.reduce((acc: number[], filter: FormattedFilter) => {
      const isQuickFilterSelected =
        isEqual(sort.types[filter.value], filter.types) ||
        (Array.isArray(sort.types) && sort.types.includes(filter.value))
      if (isQuickFilterSelected) {
        acc.push(filter.index)
      }
      return acc
    }, [])

    if (!Object.keys(sort.types).length) {
      nextFilters.push(0)
    }
    return nextFilters
  }, [sort.types, formattedFilters])

  const partiallyActiveFiltersMemoized = useMemo(
    () =>
      formattedFilters
        .filter((f) => f.index !== 0)
        .reduce((acc: number[], filter: FormattedFilter) => {
          const isQuickFilterPartiallySelected =
            sort.types[filter.value]?.some((val: string) => filter.types.includes(val)) &&
            !isEqual(sort.types[filter.value], filter.types)

          if (isQuickFilterPartiallySelected) {
            acc.push(filter.index)
          }
          return acc
        }, []),
    [sort.types, formattedFilters],
  )

  const [activeFilters, setActiveFilters] = useState<number[]>()
  const [partiallyActiveFilters, setPartiallyActiveFilters] = useState<number[]>()

  useEffect(() => {
    setActiveFilters(activeFiltersMemoized)
    setPartiallyActiveFilters(partiallyActiveFiltersMemoized)
  }, [activeFiltersMemoized, partiallyActiveFiltersMemoized])

  // needs to take activeFilters and partiallyActiveFilters as param
  // to avoid it to be bound to initial value(of 0) by useRef closure
  const saveSort = useCallback(
    (activeFilters: number[], partiallyActiveFilters: number[], sort: any) => {
      const nextFilters = activeFilters.reduce((acc: Record<string, any>, filterIndex: number) => {
        if (filterIndex) {
          const { value, types } = formattedFilters[filterIndex]
          acc[value] = types
        }
        return acc
      }, {})

      const nextPartialFilters = partiallyActiveFilters.reduce(
        (acc: Record<string, any>, filterIndex: number) => {
          if (filterIndex) {
            const { value } = formattedFilters[filterIndex]
            acc[value] = sort.types[value]
          }
          return acc
        },
        {},
      )

      const types = useNestedQuickFilters
        ? { ...nextPartialFilters, ...nextFilters }
        : Object.keys({ ...nextPartialFilters, ...nextFilters })

      const payload = {
        ...sort,
        types,
      }

      if (useNestedQuickFilters) {
        // we can order by non occurred_at fields only meals
        // if any other type is selected, we should order by occurred_at
        payload.orderBy = getOrderByAfterTypesChanged(types as TypeFilter, sort.orderBy)
      }

      if (!isEqual(sort, payload)) {
        onSortUpdated(payload)
      }
    },
    [formattedFilters, onSortUpdated, useNestedQuickFilters],
  )

  const debounceSaveSort = useMemo(() => debounce(saveSort, 300), [saveSort])

  const toggleFilter = (index: number) => () => {
    if (index === 0) {
      if (activeFilters?.includes(0)) {
        return
      }
      setActiveFilters([])
      setPartiallyActiveFilters([])
      debounceSaveSort([], [], sort)
    } else {
      const nextFilters = xor(
        activeFilters?.filter((a) => a !== 0),
        [index],
      )
      setActiveFilters(nextFilters)
      debounceSaveSort(nextFilters, partiallyActiveFilters || [], sort)
    }
  }

  const renderFilterOption = (filter: any, index: number) => {
    const isActive = activeFilters?.includes(index)
    return (
      <TouchableOpacity
        testID={filter.title}
        key={`${filter.title}-${index}`}
        accessibilityLabel={filter.title}
        onPress={toggleFilter(index)}
        style={[
          styles.tab,
          activeFilters?.includes(index) ? styles.activeTabColor : styles.defaultTabColor,
          partiallyActiveFilters?.includes(index) ? styles.partiallySelectedTab : {},
        ]}
      >
        <Text type="regular" bold style={isActive && styles.activeTabLabel}>
          {filter.title}
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <ScrollView
      contentContainerStyle={[
        styles.quickFilterBar,
        Device.hasLargeScreen(dimensions) && { justifyContent: 'center', flex: 1 },
      ]}
      showsHorizontalScrollIndicator={false}
      horizontal
    >
      {map(formattedFilters, renderFilterOption)}
    </ScrollView>
  )
}

const themedStyle = StyleService.create({
  quickFilterBar: {
    paddingHorizontal: 8,
    paddingVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tab: {
    height: 40,
    borderRadius: 8,
    paddingHorizontal: 12,
    marginHorizontal: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultTabColor: {
    backgroundColor: 'theme.surface.base2',
  },
  activeTabColor: {
    backgroundColor: 'theme.primary.base',
  },
  partiallySelectedTab: {
    borderColor: 'theme.secondary.darker',
    borderWidth: 1,
  },
  activeTabLabel: {
    color: 'theme.solid.white',
  },
})
