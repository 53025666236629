import React from 'react'
import { View } from 'react-native'
import { chunk } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { FlexView } from '@src/components/FlexView'
import { Product } from '@src/types'
import { Text } from '@components/base'
import { GroupableProduct } from '../types/types'
import { SubscriptionItem } from './SubscriptionItem'

export interface AddonsProps {
  addons: Product[]
  onItemSelected: (product: Product) => void
}

export const Addons = ({ addons, onItemSelected }: AddonsProps) => {
  const styles = useStyleSheet(themedStyle)

  const addonsSortedByPrice = addons.sort((a, b) => a.price - b.price)

  const handleItemSelected = (item: GroupableProduct) => onItemSelected(item as Product)

  return (
    <View style={styles.container}>
      <Text type="small" bold lineSpacing="none" style={styles.title}>
        Add-ons
      </Text>
      <View style={styles.addons}>
        {chunk(addonsSortedByPrice, 2).map((addonPair) => {
          const [firstAddon, secondAddon] = addonPair

          return (
            <View style={styles.row} key={firstAddon.id}>
              <FlexView style={styles.rightPadding}>
                <SubscriptionItem
                  title={firstAddon.title}
                  description={firstAddon.shortDescription ?? firstAddon.description}
                  item={firstAddon}
                  onItemPress={handleItemSelected}
                />
              </FlexView>
              <FlexView style={styles.leftPadding}>
                {secondAddon && (
                  <SubscriptionItem
                    title={secondAddon.title}
                    description={secondAddon.shortDescription ?? secondAddon.description}
                    item={secondAddon}
                    onItemPress={handleItemSelected}
                  />
                )}
              </FlexView>
            </View>
          )
        })}
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  title: {
    marginTop: 24,
    marginBottom: 16,
    textTransform: 'uppercase',
    letterSpacing: 0.3,
  },
  addons: {
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  rightPadding: {
    paddingRight: 8,
  },
  leftPadding: {
    paddingLeft: 8,
  },
})
