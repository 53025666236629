import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  View,
} from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { InsightsStackParamsList } from '@src/navigation/types'
import { statsSelector } from '@src/screens/Insights/models/insights.selectors'
import { ScoreSectionEnum } from '@src/screens/Insights/utils/pageConfig'
import { Feature, LinearGradient, useFeatureFlag } from '@src/components'
import { PaginationDots } from './PaginationDots'
import { ScoreArcIndicatorWithSeeDetailsButton } from './ScoreArcIndicatorWithSeeDetailsButton'
import { ARC_INDICATOR_PROPERTIES, ARC_INDICATOR_PROPERTIES_OLD } from './ArcIndicator'

const SPACE_BETWEEN_INDICATORS = 36

export const ArcIndicatorsPager = () => {
  const navigation = useNavigation<
    StackNavigationProp<InsightsStackParamsList, 'InsightsDashboard'>
  >()
  const stats = useSelector(statsSelector)

  const statsToDisplay = stats.filter(
    (stat) =>
      stat.meta.key === ScoreSectionEnum.DAILY_AVERAGE ||
      stat.meta.key === ScoreSectionEnum.MEALS_AVERAGE,
  )

  const indicatorConfigs = statsToDisplay.map((stat) => ({
    score: stat.value || undefined,
    title: stat.meta.key === ScoreSectionEnum.DAILY_AVERAGE ? 'Glucose Score' : 'Meal Score',
    onPressSeeDetails: () => navigation.navigate('InsightsDetailedPage', { statistic: stat }),
  }))

  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()

  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)

  const { width: arcIndicatorWidth } = userGoalsEnabled
    ? ARC_INDICATOR_PROPERTIES
    : ARC_INDICATOR_PROPERTIES_OLD

  const [containerWidth, setContainerWidth] = React.useState(0)
  const centeringHorizontalMargin =
    (containerWidth - arcIndicatorWidth - SPACE_BETWEEN_INDICATORS) / 2

  const [selectedIndicatorIndex, setSelectedIndicatorIndex] = React.useState(0)
  const onScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const { contentOffset } = event.nativeEvent
    const index = Math.round(contentOffset.x / (arcIndicatorWidth + SPACE_BETWEEN_INDICATORS))
    setSelectedIndicatorIndex(index)
  }

  const onLayout = (event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width)
  }

  const gradientColor = theme['theme.background']

  return (
    <View onLayout={onLayout} style={styles.arcIndicatorsPagerContainer}>
      <ScrollView
        decelerationRate="fast"
        snapToInterval={arcIndicatorWidth + SPACE_BETWEEN_INDICATORS}
        onScroll={onScroll}
        horizontal
        scrollEventThrottle={200}
        showsHorizontalScrollIndicator={false}
        style={userGoalsEnabled ? {} : { marginBottom: 16 }}
      >
        {indicatorConfigs.map((indicatorConfig, index) => (
          <View
            key={index}
            style={[
              styles.arcIndicatorWrapper,
              index === 0 && { marginLeft: centeringHorizontalMargin },
              index === indicatorConfigs.length - 1 && {
                marginRight: centeringHorizontalMargin,
              },
            ]}
          >
            <ScoreArcIndicatorWithSeeDetailsButton
              title={indicatorConfig.title}
              value={indicatorConfig.score}
              onPressSeeDetails={indicatorConfig.onPressSeeDetails}
              enableNewLayout={userGoalsEnabled}
            />
          </View>
        ))}
      </ScrollView>
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        startColor={gradientColor}
        endColor={gradientColor}
        startOpacity={1}
        endOpacity={0}
        style={[styles.edgeGradient, styles.leftEdgeGradient]}
      />
      <LinearGradient
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 0 }}
        startColor={gradientColor}
        endColor={gradientColor}
        startOpacity={0}
        endOpacity={1}
        style={[styles.edgeGradient, styles.rightEdgeGradient]}
      />
      {!userGoalsEnabled && (
        <PaginationDots
          pagesNumber={indicatorConfigs.length}
          selectedIndex={selectedIndicatorIndex}
          spaceBetweenDots={4}
          dotStyle={styles.paginationDot}
          selectedDotStyle={styles.selectedPaginationDot}
        />
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  arcIndicatorsPagerContainer: {
    marginVertical: 12,
  },
  arcIndicatorWrapper: {
    paddingHorizontal: SPACE_BETWEEN_INDICATORS / 2,
  },
  paginationDot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: 'theme.surface.base',
  },
  selectedPaginationDot: {
    backgroundColor: 'theme.primary.base',
  },
  edgeGradient: {
    position: 'absolute',
    width: 60,
    top: 0,
    bottom: 0,
  },
  leftEdgeGradient: {
    left: 0,
  },
  rightEdgeGradient: {
    right: 0,
  },
})
