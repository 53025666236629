import React, { useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { Image, ImageStyle, ScrollView, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { OnboardingStackParamsList } from '@src/navigation/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Text } from '@src/components/base/Text'
import { Button } from '@src/components/base/Button'
import { useGoBack } from '@src/utils/navigation'
import { ScrollableAvoidKeyboard } from '@src/components/ScrollableAvoidKeyboard'
import {
  libre1SensorBoxImage,
  libre2SensorBoxImage,
  libre3SensorBoxImage,
} from '@src/assets/images'
import { InlineAlert } from '@src/components/InlineAlert'
import { UiInlineAlert } from '@src/types'

const SUPPORTED_SENSORS = [
  { source: libre1SensorBoxImage.imageSource, name: 'Libre 1' },
  { source: libre2SensorBoxImage.imageSource, name: 'Libre 2' },
  { source: libre3SensorBoxImage.imageSource, name: 'Libre 3' },
]

export const SupportedCGM = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const goBack = useGoBack()
  const dispatch = useDispatch()

  const route = useRoute<RouteProp<OnboardingStackParamsList, 'SupportedCGM'>>()
  const { nextScreen, nextScreenParams } = route.params

  const [showUnsupportedSensorAlert, setShowUnsupportedSensorAlert] = useState(false)

  const handleGoBack = () => {
    setShowUnsupportedSensorAlert(false)
    goBack()
  }

  const handleOnContinue = () => {
    navigation.navigate(nextScreen, nextScreenParams)
  }

  const handleUnsupportedSensorPress = () => setShowUnsupportedSensorAlert(true)

  const handleOnSignOut = () => {
    dispatch({ type: 'app/logout' })
  }

  return (
    <NavigationContainer
      title="Supported CGMs"
      style={styles.navigationContainer}
      goBack={handleGoBack}
    >
      <ScrollableAvoidKeyboard
        style={styles.container}
        contentContainerStyle={[{ paddingBottom: insets.bottom + 16 }]}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        bounces={false}
      >
        <Text type="regular" style={styles.title}>
          Maximize your CGM data by using it with the Nutrisense app, and jumpstart progress to your
          goals today!
        </Text>
        <View style={styles.innerContainer}>
          <View>
            <Text type="tiny" style={styles.supportedSensorsText}>
              SUPPORTED SENSORS
            </Text>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={styles.sensorsContainer}
              contentContainerStyle={{
                gap: 8,
              }}
            >
              {SUPPORTED_SENSORS.map((sensor) => {
                return (
                  <View key={sensor.name} style={styles.sensorBox}>
                    <Image source={sensor.source} style={styles.sensorImage as ImageStyle} />
                    <Text type="regular" style={styles.sensorName}>
                      {sensor.name}
                    </Text>
                  </View>
                )
              })}
            </ScrollView>
            <Text type="regular" style={styles.supportedSensorsSubText}>
              Today, the Nutrisense app supports the FreeStyle Libre 14-day (Libre 1), Libre 2, and
              Libre 3.
            </Text>
          </View>
          <View style={styles.buttons}>
            {showUnsupportedSensorAlert ? (
              <>
                <InlineAlert
                  style={styles.inlineAlert}
                  category={UiInlineAlert.Warning}
                  // eslint-disable-next-line max-len
                  message="We’re working on giving support to other type of sensors and will let you know when they’re available."
                />
                <Button
                  type="transparent"
                  size="block"
                  onPress={handleOnSignOut}
                  accessibilityLabel="Back"
                >
                  Sign Out
                </Button>
              </>
            ) : (
              <>
                <Text type="regular" style={styles.buttonHelperText}>
                  Please note that Libre 3 CGMs must be activated in the Nutrisense app to sync.
                </Text>
                <Button
                  type="primary"
                  size="block"
                  accessibilityLabel="Continue"
                  onPress={handleOnContinue}
                >
                  Continue
                </Button>
                <Button
                  type="outline"
                  size="block"
                  accessibilityLabel="I have a different sensor"
                  onPress={handleUnsupportedSensorPress}
                >
                  I have a different sensor
                </Button>
              </>
            )}
          </View>
        </View>
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  navigationContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  title: {
    alignSelf: 'center',
    textAlign: 'center',
    paddingHorizontal: 16,
    marginTop: 16,
    marginBottom: 40,
    maxWidth: 340,
  },
  innerContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  supportedSensorsText: {
    textAlign: 'center',
    marginBottom: 16,
  },
  sensorsContainer: {
    flexDirection: 'row',
    gap: 4,
  },
  sensorBox: {
    paddingVertical: 12,
    paddingHorizontal: 12,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: 'theme.surface.base',
  },
  sensorImage: {
    marginTop: 8,
    width: 124,
    height: 100,
  },
  sensorName: {
    marginTop: 12,
    textAlign: 'center',
  },
  supportedSensorsSubText: {
    textAlign: 'center',
    alignSelf: 'center',
    marginTop: 16,
    maxWidth: 260,
  },
  buttonHelperText: {
    fontSize: 13,
    textAlign: 'center',
    marginBottom: 8,
    paddingHorizontal: 24,
  },
  buttons: {
    gap: 12,
  },
  inlineAlert: {
    marginBottom: 16,
  },
})
