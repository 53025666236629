import React, { useRef, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dictionary } from 'lodash'
import { useNavigation } from '@react-navigation/core'
import { NavigationBarAccessory } from '@components'
import { historySelector } from '@src/selectors/history'
import { Calendar, Sort } from '@src/models/app.types'
import { isModified } from '@src/navigation/utils'
import { HistoryItemType } from '@src/screens/History/models/history.types'
import { HistoryList } from '@src/screens/History/components/HistoryList'
import { OCCURRED_AT } from '@src/containers/ListFilter/utils'
import { Logger, LoggerScreens, Messages } from '@src/config'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { buildSections } from '../utils/utils'

export interface HistoryProps {
  items: HistoryItemType[]
  sort: Sort
  calendar: Calendar
  filters: Dictionary<string[]>
}

export const HistoryContainer = () => {
  const listRef = useRef()
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const { items, sort, calendar, filters } = useSelector(historySelector)

  const [query, setQuery] = useState<string>(sort.query || '')

  useEffect(() => {
    setQuery(sort.query)
  }, [sort.query])

  const onQueryUpdated = (text: string) => {
    setQuery(text)
  }

  const onSearchUpdated = () => {
    dispatch({
      type: 'history/updateSort',
      payload: {
        ...sort,
        query,
      },
    })

    Logger.sendInfo(LoggerScreens.History, Messages.GeneralSearch, {
      query,
    })
  }

  const sections = useMemo(() => buildSections(items), [items])

  const rightAccessories: NavigationBarAccessory[] = [
    {
      iconDescriptor: { pack: 'eva', name: 'options-2' },
      testID: 'History/Filter',
      iconTintColor: isModified(sort, 'history') ? 'theme.primary.base' : undefined,
      onPress: () => navigation.navigate('HistoryEdit'),
      accessibilityLabel: 'History Settings',
    },
  ]

  return (
    <AccountAvatarNavigationBar rightAccessories={rightAccessories} hideLeftIcon>
      <HistoryList
        listRef={listRef}
        items={sections}
        name="history"
        dataPath="events.events"
        filter={{ scope: 'history' }}
        sort={sort}
        pagination={{ type: sort.orderBy === OCCURRED_AT ? 'date' : 'page' }}
        useCache
        filters={filters}
        query={query}
        calendar={calendar}
        onQueryUpdated={onQueryUpdated}
        onSearchUpdated={onSearchUpdated}
      />
    </AccountAvatarNavigationBar>
  )
}

HistoryContainer.whyDidYouRender = true
