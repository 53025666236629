import React from 'react'
import { ColorValue } from 'react-native'
import Svg, { LinearGradient as SvgLinearGradient, Rect, Stop } from 'react-native-svg'

interface Coord {
  x: number
  y: number
}

interface LinearGradientProps {
  style?: Svg['props']['style']

  /**
   * Color in format rgb or hex. Without opacity
   */
  startColor: ColorValue

  /**
   * Color in format rgb or hex. Without opacity
   */
  endColor: ColorValue

  startOpacity: number
  endOpacity: number
  start: Coord
  end: Coord
}

export const LinearGradient = ({
  start,
  end,
  startColor,
  endColor,
  startOpacity,
  endOpacity,
  style = {},
}: LinearGradientProps) => {
  const [gradientX1, gradientY1, gradientX2, gradientY2] = [start.x, start.y, end.x, end.y].map(
    (coord) => (coord * 100).toString() + '%',
  )

  return (
    <Svg style={style}>
      <SvgLinearGradient
        id="gradient"
        x1={gradientX1}
        y1={gradientY1}
        x2={gradientX2}
        y2={gradientY2}
      >
        <Stop offset="0%" stopColor={startColor} stopOpacity={startOpacity} />
        <Stop offset="100%" stopColor={endColor} stopOpacity={endOpacity} />
      </SvgLinearGradient>
      <Rect x="0" y="0" width="100%" height="100%" fill="url(#gradient)" />
    </Svg>
  )
}
