import { gql } from '@apollo/client/core'
import { CORE_SUBSCRIPTION_FIELDS } from '@src/graphql/fragments/subscription'

const CANCEL_SUBSCRIPTION = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  mutation cancelSubscription($id: ID!) {
    cancelSubscription(id: $id) {
      ...CoreSubscriptionFields
    }
  }
`

export default CANCEL_SUBSCRIPTION
