import React from 'react'
import { Keyboard, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useNavigation } from '@react-navigation/native'
import { StyleService } from '@src/style/service'
import { Button } from '@components/base'
import { SecureInput } from '@components/inputs/SecureInput'
import { PasswordValidationInfo } from '@components/inputs/PasswordValidationInfo'
import { useSnack } from '@utils/navigatorContext'
import { PasswordMatchValidator } from '@utils/validators'
import { Validators } from '@src/utils'

interface PasswordResetFormProps {
  passwordToken: string
}

export const PasswordResetForm = ({ passwordToken }: PasswordResetFormProps) => {
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const showSnack = useSnack()

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      password: '',
      passwordRepeat: '',
    },
  })

  const password = watch('password')
  const passwordRepeat = watch('passwordRepeat')

  const validatePasswordsMatch = (value: string) => PasswordMatchValidator(password, value)

  const onPasswordResetPress = handleSubmit(({ password }) => {
    Keyboard.dismiss()

    dispatch({
      type: 'users/resetPassword',
      payload: {
        password,
        passwordToken,
      },
      success: () => {
        showSnack('Your password was reset successfully!')
        navigation.navigate('SignIn')
      },
      failure: () => showSnack('Your token has expired!', null, 'error'),
    })
  })

  return (
    <View style={styles.container}>
      <View style={styles.container}>
        <Controller
          control={control}
          name="password"
          rules={{ required: true, validate: Validators.PasswordValidator }}
          render={({ field }) => (
            <SecureInput
              {...field}
              hasError={!!errors.password}
              label="New Password"
              placeholder="Set New Password"
              returnKeyType="next"
              style={styles.input}
              onSubmitEditing={onPasswordResetPress}
            />
          )}
        />
        <Controller
          control={control}
          name="passwordRepeat"
          rules={{ required: true, validate: validatePasswordsMatch }}
          render={({ field }) => (
            <SecureInput
              {...field}
              hasError={!!errors.passwordRepeat}
              label="Repeat Password"
              placeholder="Repeat Password"
              returnKeyType="done"
              style={styles.input}
              onSubmitEditing={onPasswordResetPress}
            />
          )}
        />
        <PasswordValidationInfo
          password={password}
          passwordRepeat={passwordRepeat}
          validatePasswordsMatch={PasswordMatchValidator(password, passwordRepeat)}
        />
      </View>
      <Button
        allowPressWhenDisabled
        accessibilityLabel="Set new password"
        disabled={!isValid}
        onPress={onPasswordResetPress}
        size="block"
        style={styles.button}
        type="primary"
      >
        Set new password
      </Button>
    </View>
  )
}

const styles = StyleService.create({
  container: {
    flex: 1,
  },
  input: {
    marginBottom: 16,
  },
  button: {
    marginBottom: 24,
  },
})
