import { gql } from '@apollo/client'

export const FETCH_LESSONS = gql`
  query allLessons($ids: [ID]!) @api(name: cms) {
    lessons(sort: "position", filters: { id: { in: $ids } }) {
      data {
        id
        attributes {
          position
          title
          duration
          description
          thumbnail {
            data {
              id
              attributes {
                url
                previewUrl
                formats
              }
            }
          }
          items {
            __typename
            ... on ComponentContentReading {
              id
              contentDescription
              title
              body
            }
            ... on ComponentContentFact {
              id
              contentDescription
              title
              body
            }
            ... on ComponentContentAudio {
              id
              contentDescription
              title
              media {
                data {
                  attributes {
                    url
                    previewUrl
                  }
                }
              }
            }
            ... on ComponentContentVideo {
              id
              contentDescription
              title
              media {
                data {
                  attributes {
                    url
                    previewUrl
                  }
                }
              }
            }
            ... on ComponentContentMultipleChoice {
              id
              contentDescription
              question
              correctExplanation
              incorrectExplanation
              answers {
                id
                body
                correct
              }
            }
            ... on ComponentContentExperiment {
              id
              body
              actionTitle
              skipTitle
              action
            }
            ... on ComponentContentPrompt {
              title
              subtitle
              actions {
                id
                title
                description
                action
                actionTarget
              }
            }
          }
        }
      }
    }
  }
`
