import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { MenuItemProps } from '@ui-kitten/components'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import DraggableFlatList, { DragEndParams, RenderItemParams } from 'react-native-draggable-flatlist'
import { isFunction, lowerCase } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { PlaceholderView, SearchBar, CommonRefreshControl } from '@components'
import { Haptic } from '@src/utils/index'
import { PlaceholderTypes } from '../PlaceholderView'
import { InsetBottomType } from './types'
import { ReorderableListItem, ReorderableListItemSize } from './ReorderableListItem'

export type FilterBy = 'title' | 'description'

export interface ReorderableListProps<T> {
  data: T[]
  refreshing?: boolean
  filterBy?: FilterBy
  itemType?: PlaceholderTypes
  activationDistance?: number
  withSearchBar?: boolean
  menuItems?: MenuItemProps[]
  onRefresh?: () => void
  keyExtractor?: (_: T, index: number) => string
  renderItem?: (params: RenderItemParams<T>) => JSX.Element
  onDragBegin?: () => void
  onDragEnd: (params: DragEndParams<T>) => void
  onItemPress?: (item: T) => void
  size?: ReorderableListItemSize
  insetBottomType?: InsetBottomType
  renderFooterList?: () => JSX.Element
}

// distance from the edge of component + button height + distance between last item and button
const LIST_INSET = 16 + 52 + 10

const defaultKeyExtractor = (_: any, index: number) => `draggable-item-${index}`

interface Item {
  id?: string
  title?: string
  description?: string
  occurredAt?: string
  tag?: string
  __typename?: string
}

export const ReorderableList = <T extends Item>({
  onRefresh,
  data,
  onDragBegin,
  onDragEnd,
  itemType,
  filterBy = 'title',
  activationDistance = 15,
  renderItem,
  keyExtractor,
  refreshing = false,
  withSearchBar = false,
  menuItems,
  onItemPress,
  size = 'normal',
  insetBottomType = InsetBottomType.None,
  renderFooterList,
}: ReorderableListProps<T>) => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  const [searchBarQuery, setSearchBarQuery] = useState('')
  const [items, setItems] = useState<T[]>([])
  const [dragActive, setDragActive] = useState(false)

  useEffect(() => {
    setItems(data)
  }, [data])

  const handleDragBegin = useCallback(() => {
    setDragActive(true)
    Haptic.heavyTap()
    if (isFunction(onDragBegin)) {
      onDragBegin()
    }
  }, [onDragBegin])

  const handleItemPress = useCallback(
    (item: T) => () => {
      if (isFunction(onItemPress)) {
        onItemPress(item)
      }
    },
    [onItemPress],
  )

  const handleDragEnd = useCallback(
    (params: DragEndParams<T>) => {
      setDragActive(false)
      setItems(params.data)
      onDragEnd(params)
    },
    [onDragEnd],
  )

  const defaultRenderItem = useCallback(
    ({ item, drag, isActive }: RenderItemParams<T>) => (
      <ReorderableListItem
        item={item}
        drag={drag}
        isActive={isActive}
        menuItems={menuItems}
        handleItemPress={handleItemPress(item)}
        size={size}
      />
    ),
    [handleItemPress, menuItems, size],
  )

  const onChangeText = useCallback((query: string) => {
    setSearchBarQuery(query)
  }, [])

  const listEmptyComponent = itemType ? (
    <PlaceholderView type={itemType} showSearchResults={searchBarQuery.length > 0} />
  ) : null

  const filteredData =
    (withSearchBar &&
      items.filter((item: T) => lowerCase(item[filterBy]).includes(lowerCase(searchBarQuery)))) ||
    items

  const renderRefreshControl = () => {
    if (!onRefresh) {
      return
    }
    return (
      <CommonRefreshControl enabled={!dragActive} onRefresh={onRefresh} refreshing={refreshing} />
    )
  }

  const contentContainerStyle = useMemo(() => {
    let paddingBottom = 0
    if (insetBottomType === InsetBottomType.BottomButton) {
      paddingBottom = (insets?.bottom || 0) + LIST_INSET
    }

    return { paddingBottom }
  }, [insetBottomType, insets?.bottom])

  return (
    <>
      {withSearchBar && <SearchBar value={searchBarQuery} onChangeText={onChangeText} />}
      <DraggableFlatList
        refreshControl={renderRefreshControl()}
        data={filteredData}
        renderItem={renderItem || defaultRenderItem}
        activationDistance={activationDistance}
        keyExtractor={keyExtractor || defaultKeyExtractor}
        onDragBegin={handleDragBegin}
        onDragEnd={handleDragEnd}
        ListEmptyComponent={listEmptyComponent}
        ListFooterComponent={renderFooterList}
        contentContainerStyle={contentContainerStyle}
        style={styles.container}
        containerStyle={styles.container}
      />
    </>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})
