import { useCallback } from 'react'
import RNFetchBlob from 'rn-fetch-blob'
import { CreateDirectUploadMutation } from '@src/graphql/createDirectUpload.generated'
import { CreateFileFromSignedIdMutation } from '@src/graphql/createFileFromSignedId.generated'
import { FileMetadata, UserFileTag } from '@src/types'
import { useDispatchAsync } from '@src/utils'
import hexToBase64 from '@src/utils/hexToBase64'

interface UploadFileParams {
  dataURL: string
  fileTag: UserFileTag
  filename?: string
  contentType?: string
}

const defaultContentType = 'image/jpeg'
const defaultFilename = 'image.jpg'

export const useDirectUpload = () => {
  const dispatchAsync = useDispatchAsync()

  const uploadFile = useCallback(
    async ({
      dataURL,
      fileTag,
      contentType = defaultContentType,
      filename = defaultFilename,
    }: UploadFileParams) => {
      try {
        const [photoHash, { size }] = await Promise.all([
          RNFetchBlob.fs.hash(dataURL, 'md5'),
          RNFetchBlob.fs.stat(dataURL),
        ])

        const upload = await dispatchAsync<CreateDirectUploadMutation['createDirectUpload']>({
          type: 'app/createDirectUpload',
          payload: {
            file: {
              checksum: hexToBase64(photoHash),
              filename,
              contentType,
              byteSize: size,
            } as FileMetadata,
          },
        })

        await RNFetchBlob.fetch(
          'PUT',
          upload.url,
          upload.headers.reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {}),
          RNFetchBlob.wrap(dataURL),
        )

        const file = await dispatchAsync<CreateFileFromSignedIdMutation['createFileFromSignedId']>({
          type: 'app/createFileFromSignedId',
          payload: {
            signedId: upload.signedId,
            fileTag,
          },
        })

        return file
      } catch (error) {
        console.error('Upload file error:', error)
        return null
      }
    },
    [dispatchAsync],
  )

  return uploadFile
}
