import React, { useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useRoute, RouteProp } from '@react-navigation/native'
import { isEqual, omit } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { BottomSheet, MacroSlider } from '@components'
import { DEFAULT_GOALS } from '@src/components/nutritionCard/utils'
import { macroGoalSettingsSelector } from '@src/selectors/app'
import { useGoBack } from '@src/utils/navigation'
import { AppStackParamList } from '@src/navigation/types'
import { macroGoalsDailySelector, showNetCarbsStoreStateSelector } from '@src/selectors/settings'
import { Analytics, CustomEventTypes } from '@config'
import { MacroGoalsDaily } from '../models/settings.types'
import { PROTEIN_CALORIES, CARB_CALORIES, FAT_CALORIES } from '../constants'
import { ToggleSetting } from './UserSettings/ToggleSetting'

const getInitialMacroGoals = (macroGoalsDaily: MacroGoalsDaily) => {
  let netCarbs = macroGoalsDaily.netCarbs || DEFAULT_GOALS.netCarbs
  if (macroGoalsDaily.carbs < netCarbs) {
    netCarbs = macroGoalsDaily.carbs
  }

  return { ...macroGoalsDaily, netCarbs }
}

export const MacroGoalsModal = () => {
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()
  const dispatch = useDispatch()

  const {
    params: { source },
  } = useRoute<RouteProp<AppStackParamList, 'ChangeMacroGoals'>>()

  const { caloriesMax, proteinMax, carbsMax, fatMax } = useSelector(macroGoalSettingsSelector)
  const showNetCarbs = useSelector(showNetCarbsStoreStateSelector)
  const macroGoalsDaily = useSelector(macroGoalsDailySelector)

  const [macroGoals, setMacroGoals] = useState(() => getInitialMacroGoals(macroGoalsDaily))
  const { calories, carbs, fat, protein, netCarbs } = macroGoals

  const proteinPercent = calories === 0 ? 0 : (protein * PROTEIN_CALORIES) / calories
  const carbsPercent = calories === 0 ? 0 : (carbs * CARB_CALORIES) / calories
  const fatPercent = calories === 0 ? 0 : (fat * FAT_CALORIES) / calories
  const caloriesPercent = calories === 0 ? 0 : proteinPercent + carbsPercent + fatPercent

  const onMacroGoalsChange = (change: Partial<MacroGoalsDaily>) => {
    setMacroGoals((prevMacroGoals) => ({
      ...prevMacroGoals,
      ...change,
    }))
  }

  const onMacroGoalChange = (macro: string, value: number) => onMacroGoalsChange({ [macro]: value })

  const onNetCarbsChange = (macro: string, newValue: number) => {
    if (newValue < netCarbs || netCarbs === 0) {
      onMacroGoalsChange({
        [macro]: newValue,
        netCarbs: newValue || DEFAULT_GOALS.netCarbs,
      })
    } else {
      onMacroGoalsChange({ [macro]: newValue })
    }
  }

  const onSaveButtonPress = () => {
    if (!isEqual(macroGoalsDaily, macroGoals)) {
      const newValues = omit(macroGoals, '__typename')
      Analytics.track(CustomEventTypes.MacroGoalsSave, { source, ...newValues })

      dispatch({
        type: 'settings/update',
        payload: { macroGoalsDaily: newValues },
      })
    }

    goBack()
  }

  return (
    <BottomSheet
      header="Update Daily Macro Goals"
      primaryButton={{ text: 'Save', onPress: onSaveButtonPress }}
      secondaryButton={{ text: 'Cancel', onPress: goBack }}
    >
      <Text type="regular" style={[styles.description, styles.topDescription]}>
        Daily macro goals are targets you set for the intake of macronutrients, such as calories,
        carbs, protein, and fat in your diet.
      </Text>
      <Text type="regular" style={styles.description}>
        Use the sliders to adjust your daily macro goals according to your needs.
      </Text>
      <MacroSlider
        macro="calories"
        value={calories}
        maxValue={caloriesMax}
        percent={caloriesPercent}
        step={50}
        style={styles.macroSlider}
        variant="edit"
        onValueChange={onMacroGoalChange}
      />
      <MacroSlider
        macro="protein"
        value={protein}
        maxValue={proteinMax}
        percent={proteinPercent}
        style={styles.macroSlider}
        variant="edit"
        onValueChange={onMacroGoalChange}
      />
      <MacroSlider
        macro="carbs"
        value={carbs}
        maxValue={carbsMax}
        percent={carbsPercent}
        style={styles.macroSlider}
        variant="edit"
        onValueChange={onNetCarbsChange}
      />
      <MacroSlider
        macro="fat"
        value={fat}
        maxValue={fatMax}
        percent={fatPercent}
        style={styles.macroSlider}
        variant="edit"
        onValueChange={onMacroGoalChange}
      />
      {showNetCarbs && (
        <MacroSlider
          macro="netCarbs"
          value={netCarbs}
          maxValue={carbs || DEFAULT_GOALS.carbs}
          style={styles.macroSlider}
          variant="edit"
          onValueChange={onMacroGoalChange}
        />
      )}
      <View style={styles.useNetCarbsContainer}>
        <View>
          <Text type="regular" bold>
            NET CARBS
          </Text>
          <Text type="regular" style={styles.useNetCarbsDescription}>
            Turn the toggle {showNetCarbs ? 'off' : 'on'} to {showNetCarbs ? 'hide' : 'use'} Net
            Carbs
          </Text>
        </View>
        <ToggleSetting field="showNetCarbs" />
      </View>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  topDescription: {
    marginTop: 12,
  },
  description: {
    marginBottom: 24,
    textAlign: 'center',
  },
  macroSlider: {
    marginBottom: 16,
    width: '100%',
  },
  useNetCarbsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: 4,
  },
  useNetCarbsDescription: {
    color: 'theme.text.secondary',
  },
})
