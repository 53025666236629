import * as React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const EmojiLevel2 = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 40 40" fill="none" {...props}>
      <Path
        d="M20 38c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18z"
        fill="#B5B5B6"
        stroke="#11494F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M13 18.25a3 3 0 100-6 3 3 0 000 6zM26.5 18.25a3 3 0 100-6 3 3 0 000 6z"
        fill="#11494F"
      />
      <Path
        d="M12.4 26.991a9.019 9.019 0 0115.6 0"
        stroke="#11494F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default EmojiLevel2
