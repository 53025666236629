import React from 'react'
import { useTheme } from '@ui-kitten/components'
import { View } from 'react-native'
import Chart from 'react-native-f2chart'
import { StyleService, useStyleSheet } from '@src/style/service'
import { insightsLineChart } from '@src/components/charts'
import type { InsightsLineChartConfig } from '@src/components/charts/insightsLineChart'
import { CommonChart } from '@src/components'

interface LineChartProps extends Omit<InsightsLineChartConfig, 'style'> {
  chartRef: React.MutableRefObject<Chart | null>
  height?: number
  isAnimationDisabled?: boolean
  isScanDisabled?: boolean
  onLoad?: () => void
}

export const LineChart = ({
  chartRef,
  data,
  height = 180,
  ranges,
  yLabel,
  yTicks,
  yMin,
  yMax,
  isAnimationDisabled,
  isScanDisabled,
  onLoad,
}: LineChartProps) => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)

  const chartScript = insightsLineChart({
    data,
    ranges,
    yLabel,
    yTicks,
    yMin,
    yMax,
    isAnimationDisabled,
    isScanDisabled,
    style: getChartStyles(theme),
  })

  return (
    <View style={styles.container}>
      <CommonChart chartRef={chartRef} chartScript={chartScript} height={height} onLoad={onLoad} />
    </View>
  )
}

const getChartStyles = (theme: Record<string, string>): InsightsLineChartConfig['style'] => ({
  line: {
    good: theme['theme.range.good'],
    ok: theme['theme.range.regular'],
    bad: theme['theme.range.bad'],
    critical: theme['theme.range.bad'],
    default: theme['theme.tooltip.surface'],
  },
  axisLine: theme['theme.surface.base'],
  axisLabel: theme['theme.text.secondary'],
  goodRangeBackground: theme['theme.range.good'],
  tooltipBackground: theme['theme.tooltip.background'],
  tooltipMarkerFill: theme['theme.solid.black'],
  tooltipMarkerStroke: theme['theme.solid.white'],
  tooltipTextColor: theme['theme.tooltip.textPrimary'],
  tooltipDateTextColor: theme['theme.tooltip.textSecondary'],
  noDataTextColor: theme['theme.text.tertiary'],
})

const themedStyle = StyleService.create({
  container: {
    marginTop: -24,
  },
})
