import {
  Product,
  ProductGroup,
  PromotionCode,
  Schedule,
  SubscriptionCheckoutOptionKind,
} from '@src/types'

export interface MarketplaceStoreState {
  promotionCode: PromotionCode | null
  estimatedTax: number | null
  subscriptionSchedules: Schedule[]
}

export interface PromotionCodeResponse {
  promotionCode: PromotionCode
}

export type ProductSelection = {
  product: Product
  savings?: number
  quantity?: number
  subscriptionId?: string
  trialEndsAt?: string | null
  checkoutKind?: CheckoutKind
  checkoutOptionKinds?: SubscriptionCheckoutOptionKind[]
}

export enum CheckoutKind {
  Default = 'default',
  UpgradeTrial = 'upgrade_trial',
  UpgradeProduct = 'upgrade_product',
}

export enum ProductIcon {
  Question = 'question_mark',
  Goals = 'checklist',
  Nutrition = 'food',
  VideoCall = 'video_call',
}

export interface GroupedProducts extends ProductGroup {
  products: Product[]
}

export type GroupableProduct = Product | GroupedProducts

export const isProduct = (item: GroupableProduct): item is Product => item.__typename === 'Product'

export const isGroupedProducts = (item: GroupableProduct): item is GroupedProducts =>
  item.__typename === 'ProductGroup'
