import { useCallback, useEffect, useRef, useState } from 'react'
import BleManager, { BleEventType, Peripheral } from 'react-native-ble-manager'
import {
  GlucoseData,
  Libre3BluetoothManagerConnectionState,
  PatchInfoData,
  libre3BluetoothManager,
} from 'react-native-freestyle-libre'
import { DeviceEventEmitter, NativeEventEmitter, NativeModules } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { isNumber } from 'lodash'
import { Storage } from '@src/utils'
import { Feature, useFeatureFlag } from '@src/components'
import { useSnack } from '@src/utils/navigatorContext'
import { checkBluetoothPermissions } from '@src/utils/bluetooth'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import {
  BACKGROUND_SERVICE_OPTIONS,
  BackgroundServiceEvents,
  backgroundServiceHandler,
  BackgroundService,
} from '@src/services/Bluetooth'
import { Device, Logger, LoggerScreens } from '@src/config'
import { sensorSelector } from '@src/selectors/sensor'

const BleManagerModule = NativeModules.BleManager
const bleManagerEmitter = new NativeEventEmitter(BleManagerModule)

const getBleAddressKey = ({ serialNumber }: { serialNumber: string }) => {
  return `${serialNumber}-ble-address`
}

const getBlePinKey = ({ serialNumber }: { serialNumber: string }) => {
  return `${serialNumber}-ble-pin`
}

const onBleManagerCentralManagerWillRestoreState = (args: { peripherals: Peripheral[] }) => {
  Logger.sendInfo(
    LoggerScreens.GlobalNFCListener,
    BleEventType.BleManagerCentralManagerWillRestoreState,
    { payload: JSON.stringify(args) },
  )
}

bleManagerEmitter.addListener(
  BleEventType.BleManagerCentralManagerWillRestoreState,
  onBleManagerCentralManagerWillRestoreState,
)

export const useBluetoothScanning = ({
  currentSensorSerialNumber,
  currentSensorIsLibre3,
  submitBluetoothScan,
}: {
  currentSensorSerialNumber: string | undefined
  currentSensorIsLibre3: boolean | undefined
  submitBluetoothScan: (params: {
    serialNumber: string
    glucoseData: GlucoseData[]
    onSuccess?: () => void
  }) => void
}) => {
  const showSnack = useSnack()
  const navigation = useNavigation()

  const disconnectFromSensor = useFeatureFlag(Feature.Libre3DisconnectOnSensorRemoval)

  const bleManagerInitializingRef = useRef(false)
  const bleManagerInitializedRef = useRef(false)
  const [isBluetoothEnabled, setBluetoothEnabled] = useState<boolean>()
  const [isBluetoothPermitted, setBluetoothPermitted] = useState<boolean>()

  const [connectionState, setConnectionState] = useState(
    Libre3BluetoothManagerConnectionState.NotConnected,
  )
  const [patchInfo, setPatchInfo] = useState<PatchInfoData | null>(null)
  const [realTimeGlucoseValue, setRealTimeGlucoseValue] = useState<number | null>(null)

  const [realTimeGlucoseTime, setRealTimeGlucoseTime] = useState<Date | null>(null)

  const unitSystem = useSelector(unitSystemStoreStateSelector)
  const unitSystemRef = useRef(unitSystem)

  const sensor = useSelector(sensorSelector)
  const sensorBaselineAdjustment = sensor?.baselineAdjustment
  const baselineAdjustmentRef = useRef(sensorBaselineAdjustment)

  useEffect(() => {
    const connectionStateSubscription = DeviceEventEmitter.addListener(
      BackgroundServiceEvents.ConnectionState,
      setConnectionState,
    )

    const patchInfoSubscription = DeviceEventEmitter.addListener(
      BackgroundServiceEvents.PatchInfo,
      setPatchInfo,
    )

    const realTimeGlucoseValueSubscription = DeviceEventEmitter.addListener(
      BackgroundServiceEvents.RealTimeGlucoseValue,
      setRealTimeGlucoseData,
    )

    const bluetoothEnabledSubscription = DeviceEventEmitter.addListener(
      BackgroundServiceEvents.BluetoothEnabled,
      setBluetoothEnabled,
    )

    const showSnackSubscription = DeviceEventEmitter.addListener(
      BackgroundServiceEvents.ShowSnack,
      showSnack,
    )

    return () => {
      connectionStateSubscription.remove()
      patchInfoSubscription.remove()
      realTimeGlucoseValueSubscription.remove()
      bluetoothEnabledSubscription.remove()
      showSnackSubscription.remove()
    }
  }, [showSnack])

  const handleBluetoothConnection = useCallback(
    async ({
      serialNumber,
      bleAddress,
      blePin,
    }: {
      serialNumber: string
      bleAddress: string
      blePin: string
    }) => {
      const { isBluetoothDenied } = await checkBluetoothPermissions()

      setBluetoothPermitted(!isBluetoothDenied)

      if (isBluetoothDenied) {
        navigation.navigate('BluetoothPermissionModal')
        return
      }

      if (!bleManagerInitializedRef.current) {
        // initialize BLE manager
        if (bleManagerInitializingRef.current) {
          return
        }

        bleManagerInitializingRef.current = true

        await BleManager.start({
          showAlert: true,
          restoreIdentifierKey: 'react-native-freestyle-libre',
          queueIdentifierKey: 'react-native-freestyle-libre',
        })

        bleManagerInitializedRef.current = true
      }

      const startBackgroundServiceHandler = () => {
        backgroundServiceHandler({
          currentSensorSerialNumber: serialNumber as string,
          unitSystem: unitSystemRef.current,
          baselineAdjustment: baselineAdjustmentRef.current || 0,
          bleAddress,
          blePin,
          submitBluetoothScan,
        })
      }

      if (Device.ios) {
        // do not start background task for iOS
        startBackgroundServiceHandler()
        return
      }

      if (BackgroundService.isRunning()) {
        DeviceEventEmitter.emit(BackgroundServiceEvents.Cleanup)
        await BackgroundService.stop()
      }

      const backgroundServiceTask = async () => {
        // promise that is never resolved for infinite background service execution
        await new Promise(startBackgroundServiceHandler)
      }

      await BackgroundService.start(backgroundServiceTask, BACKGROUND_SERVICE_OPTIONS)
    },
    [navigation, submitBluetoothScan],
  )

  const onLibre3Scan = useCallback(
    async (sensor: { serialNumber: string; blePin: string; bleAddress: string }) => {
      if (disconnectFromSensor && sensor.serialNumber !== currentSensorSerialNumber) {
        await libre3BluetoothManager.disconnectFromSensor()
      }

      Storage.set(getBleAddressKey({ serialNumber: sensor.serialNumber }), sensor.bleAddress)
      Storage.set(getBlePinKey({ serialNumber: sensor.serialNumber }), sensor.blePin)

      handleBluetoothConnection({
        serialNumber: sensor.serialNumber,
        bleAddress: sensor.bleAddress,
        blePin: sensor.blePin,
      })
    },
    [disconnectFromSensor, handleBluetoothConnection, currentSensorSerialNumber],
  )

  const connectToSensor = useCallback(async () => {
    if (!currentSensorSerialNumber || !currentSensorIsLibre3) {
      await libre3BluetoothManager.stopScanning()

      if (BackgroundService.isRunning()) {
        DeviceEventEmitter.emit(BackgroundServiceEvents.Cleanup)
        // we don't need background service anymore (as there is no Libre 3 sensor)
        BackgroundService.stop()
      }
      return
    }

    if (BackgroundService.isRunning()) {
      // we need to sync data from background service
      DeviceEventEmitter.emit(BackgroundServiceEvents.SyncData)
      // we don't need to connect again as old background service is still running
      return
    }

    const connectToSensorBySerialNumber = (serialNumber: string) => {
      const bleAddress = Storage.get<string>(getBleAddressKey({ serialNumber }))
      const blePin = Storage.get<string>(getBlePinKey({ serialNumber }))

      if (!bleAddress || !blePin) {
        showSnack('Please scan the sensor for bluetooth connection!', null, 'warning')
        return
      }

      handleBluetoothConnection({ serialNumber, bleAddress, blePin })
    }

    connectToSensorBySerialNumber(currentSensorSerialNumber)
  }, [currentSensorIsLibre3, currentSensorSerialNumber, handleBluetoothConnection, showSnack])

  const setRealTimeGlucoseData = (glucoseData: {
    newRealTimeGlucoseValue: number | null
    newRealTimeGlucoseTime: Date | null
  }) => {
    setRealTimeGlucoseValue(glucoseData.newRealTimeGlucoseValue)
    setRealTimeGlucoseTime(glucoseData.newRealTimeGlucoseTime)
  }

  useEffect(() => {
    connectToSensor()
  }, [connectToSensor])

  useEffect(() => {
    unitSystemRef.current = unitSystem
    DeviceEventEmitter.emit(BackgroundServiceEvents.UnitSystem, unitSystem)
  }, [unitSystem])

  useEffect(() => {
    baselineAdjustmentRef.current = sensorBaselineAdjustment
    if (isNumber(baselineAdjustmentRef.current)) {
      DeviceEventEmitter.emit(BackgroundServiceEvents.baselineAdjustment, sensorBaselineAdjustment)
    }
  }, [sensorBaselineAdjustment])

  return {
    onLibre3Scan,
    connectionState,
    realTimeGlucoseValue,
    realTimeGlucoseTime,
    patchInfo,
    isBluetoothEnabled,
    isBluetoothPermitted,
    connectToSensor,
  }
}
