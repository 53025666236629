/* eslint-disable max-len */
import * as React from 'react'
import { Path } from 'react-native-svg'
import NsPermission from './nsPermission'

const NsNearbyDevices = () => {
  return (
    <NsPermission>
      <Path
        d="M176.455 48l9.547-9.547L195.546 48l-9.544 9.544L176.455 48zm29.709-2.02l-18.138-18.14a2.854 2.854 0 00-3.12-.622 2.859 2.859 0 00-.929.622l-18.141 18.14a2.861 2.861 0 00-.836 2.017 2.86 2.86 0 00.836 2.017l18.148 18.15a2.853 2.853 0 004.032 0l18.148-18.15a2.86 2.86 0 00.836-2.016 2.86 2.86 0 00-.836-2.017zM186 63.27l-15.273-15.274L186 32.722l15.273 15.274L186 63.271z"
        fill="#3599EF"
      />
    </NsPermission>
  )
}

export default NsNearbyDevices
