import React, { useEffect } from 'react'
import { useWindowDimensions, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ImageStyle } from 'react-native-fast-image'
import { isEmpty, isNumber } from 'lodash'
import { Divider } from '@ui-kitten/components'
import moment from 'moment'
import { useNavigation } from '@react-navigation/native'
import { StatScores } from '@screens/Meals/components/StatScores'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { Feature, Score, ScrollViewWithFade, TutorialLink, useFeatureFlag } from '@components'
import { ActivityChart } from '@src/containers/ActivityChart/ActivityChart'
import { GlucoseStatistics } from '@src/components/list/common/GlucoseStatistics'
import { formatScore } from '@src/components/nutritionCard/utils'
import { extractIntervalValues } from '@src/components/list/utils/utils'
import { Meal } from '@screens/Events/models/events.types'
import { IngredientsTotal, NutritionFacts } from '@screens/Ingredients'
import { Device } from '@src/config/index'
import { ImageWithFullscreen } from '@src/components/Image/FastImageWithPlaceholder'
import { MealAction } from './MealAction'

interface MealDetailComponentProps {
  favorite: boolean
  setFavorite: (value: boolean) => void
  onJumpToDate: () => void
  copyMeal: () => void
  onShare: () => void
  item: Meal
  disableAnimation?: boolean
  onLoad?: () => void
}

export const MealDetailComponent = (props: MealDetailComponentProps) => {
  const styles = useStyleSheet(themedStyle)
  const dimensions = useWindowDimensions()
  const navigation = useNavigation()
  const isMealExperimentationEnabled = useFeatureFlag(Feature.MealExperimentation)

  const {
    item,
    favorite,
    setFavorite,
    onJumpToDate,
    copyMeal,
    onShare,
    disableAnimation = false,
    onLoad,
  } = props

  const { statistics, description, nutrition, ingredients } = item
  const source = { uri: item?.avatar }
  const hasDescription = !!description
  const hasUri = !!source && !!source.uri
  const showGlucoseScore = isNumber(statistics?.glucoseScore)
  const showMealGraph = !!extractIntervalValues(item)
  const hasIngredients = !isEmpty(ingredients)
  const shareEnabled =
    hasDescription || hasUri || showGlucoseScore || showMealGraph || hasIngredients

  useEffect(() => {
    if (onLoad && !showMealGraph) {
      onLoad()
    }
  }, [onLoad, showMealGraph])

  return (
    <SafeAreaView style={styles.container} edges={['bottom']}>
      <ScrollViewWithFade style={styles.scrollContainer}>
        <View style={styles.imageWithActions}>
          {hasUri ? (
            <ImageWithFullscreen
              testID="MealDetail/Logo"
              style={styles.image as ImageStyle}
              source={source}
              placeholderComponent={
                <View style={[styles.image, styles.noImage]}>
                  <Icon name="image" style={styles.imageIcon} />
                  <Text type="small">No Image</Text>
                </View>
              }
            />
          ) : (
            <View style={[styles.image, styles.noImage]}>
              <Icon name="image" style={styles.imageIcon} />
              <Text type="small">No Image</Text>
            </View>
          )}
          <View style={styles.actions}>
            <MealAction icon="share" name="Share" onPress={onShare} disabled={!shareEnabled} />
            <MealAction icon="copy" name="Duplicate" onPress={copyMeal} />
            <MealAction icon="calendar" name="Jump to date" onPress={onJumpToDate} />
            <MealAction
              testID="MealDetail/Favorite"
              icon="heart"
              iconWeight={favorite ? 'fill' : 'regular'}
              name="Favorite"
              iconStyles={favorite ? styles.redIcon : {}}
              onPress={() => setFavorite(!favorite)}
              textStyles={favorite ? styles.activeText : undefined}
            />
          </View>
        </View>
        {hasDescription && (
          <View style={styles.spaceAround}>
            <View style={styles.row}>
              <Text type="regular" bold>
                Description
              </Text>
              <Text type="small" style={styles.mealTime}>
                {moment(item.occurredAt).format('ll · LT')}
              </Text>
            </View>
            <Text type="regular" testID="MealDetail/Description">
              {description}
            </Text>
          </View>
        )}
        {hasIngredients && (
          <>
            <Divider appearance="large" />
            <IngredientsTotal ingredients={ingredients} />
          </>
        )}
        {showGlucoseScore && (
          <>
            <Divider appearance="large" />
            <View style={Device.hasLargeScreen(dimensions) ? styles.largeScreenCard : {}}>
              <View style={styles.sectionHeader}>
                <View>
                  <Text type="regular" bold>
                    Meal Score
                  </Text>
                  <TutorialLink tutorialKey="mealDetails" />
                </View>
                <Score number={formatScore(statistics.glucoseScore)} />
              </View>
              <StatScores statistics={statistics} style={styles.paddingHorizontal} />
            </View>
          </>
        )}
        {showMealGraph && (
          <>
            <Divider appearance="large" />
            <View style={Device.hasLargeScreen(dimensions) ? styles.largeScreenCard : {}}>
              <View style={styles.sectionHeader}>
                <Text type="regular" bold>
                  Meal Graph
                </Text>
              </View>
              <View style={styles.paddedRow}>
                <GlucoseStatistics statistics={statistics} mode="large" style={styles.statistics} />
                <ActivityChart disableAnimation={disableAnimation} onLoad={onLoad} item={item} />
              </View>
            </View>
          </>
        )}
        {!isEmpty(nutrition) && (
          <>
            <Divider appearance="large" />
            <NutritionFacts
              nutrition={nutrition}
              style={styles.paddingHorizontal}
              testID="MealDetail/Nutrition"
            />
          </>
        )}
      </ScrollViewWithFade>
      {isMealExperimentationEnabled && (
        <Button
          accessibilityLabel="Compare This Meal"
          accessoryLeft={<Icon name="arrows-left-right" />}
          disabled={!showGlucoseScore}
          style={[styles.spaceAround, styles.compareButton]}
          size="large"
          type="primary"
          onPress={() => navigation.navigate('CompareMeals', { initialMealIds: item.id })}
        >
          Compare This Meal
        </Button>
      )}
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  scrollContainer: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  imageWithActions: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    marginTop: 16,
    marginBottom: 24,
  },
  image: {
    height: 128,
    aspectRatio: 1,
    marginRight: 24,
    borderRadius: 24,
  },
  noImage: {
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: 'theme.surface.base',
  },
  imageIcon: {
    marginBottom: 4,
    color: 'theme.text.secondary',
  },
  redIcon: {
    color: 'theme.like',
  },
  spaceAround: {
    marginHorizontal: 16,
    marginBottom: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  sectionHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    minHeight: 48,
    padding: 16,
  },
  mealTime: {
    marginLeft: 8,
    color: 'theme.text.secondary',
  },
  actions: {
    flex: 1,
    justifyContent: 'space-between',
    paddingVertical: 8,
  },
  paddedRow: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  statistics: {
    marginRight: 24,
  },
  largeScreenCard: {
    maxWidth: 360,
  },
  activeText: {
    color: 'theme.text.primary',
  },
  paddingHorizontal: {
    paddingHorizontal: 16,
  },
  compareButton: {
    width: 280,
  },
})
