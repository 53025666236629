import React, { useMemo } from 'react'
import Svg, { ClipPath, Defs, Rect } from 'react-native-svg'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'

export interface WaveFormProps {
  width: number
  height: number
  paddingHorizontal: number
}

const generateRects = (width: number, height: number, _paddingHorizontal = 0) => {
  if (!width) {
    return
  }

  const paddingHorizontal = _paddingHorizontal + 1
  const paddingVertical = 10
  const rectWidth = 2
  const space = 5
  const offset = rectWidth + space

  const amount = Math.floor(width / offset)
  const actualOffset = (width - 2 * paddingHorizontal + space) / amount

  const maxRectHeight = height - 2 * paddingVertical
  const rectHeights = Array(11)
    .fill(0)
    .map((_, i) => maxRectHeight - 2 * i)

  return Array(amount)
    .fill(0)
    .map((_, i) => {
      const rectHeight = rectHeights[Math.floor(Math.random() * 11)]
      const x = i * actualOffset + paddingHorizontal
      const y = paddingVertical + (maxRectHeight - rectHeight) / 2

      return <Rect key={`rect${i}`} x={x} y={y} width={rectWidth} height={rectHeight} />
    })
}

export const WaveForm = (props: WaveFormProps) => {
  const { width, height, paddingHorizontal } = props
  const styles = useStyleSheet(themedStyles)
  const rects = useMemo(() => {
    return generateRects(width, height, paddingHorizontal)
  }, [width, height, paddingHorizontal])

  return (
    <View style={{ width, height }}>
      <Svg fill="none" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <Defs>
          <ClipPath id="clip">
            <Rect x="0" y="0" width={width} height={height} />
            {rects}
          </ClipPath>
        </Defs>
        <Rect
          x="0"
          y="0"
          width={width}
          height={height}
          fill={styles.waveForm.backgroundColor}
          clipPath="url(#clip)"
        />
      </Svg>
    </View>
  )
}

const themedStyles = StyleService.create({
  waveForm: {
    backgroundColor: 'theme.surface.base',
  },
})
