import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { FadeFromBottomModal } from '@components'
import { useGoBack } from '@src/utils/navigation'
import { requestInAppReview } from '@src/utils/appReview'
import { Storage } from '@src/utils'

export const RateOurAppModal = () => {
  const styles = useStyleSheet(themedStyles)

  const goBack = useGoBack()

  const onRateButtonPress = () => {
    requestInAppReview()
    Storage.set(Storage.REVIEW_LEFT_KEY, true)
  }

  return (
    <FadeFromBottomModal closeModal={goBack} contentStyles={styles.content}>
      <View style={styles.container}>
        <Text type="large" bold lineSpacing="tight" style={styles.title}>
          Your opinion matters to us!
        </Text>
        <Text type="regular" lineSpacing="tight" style={styles.subtitle}>
          We work super hard to make Nutrisense better for you, and we would love to know how you
          would rate our app.
        </Text>
        <Button
          type="primary"
          size="block"
          style={styles.confirmButton}
          onPress={onRateButtonPress}
          accessibilityLabel="saveButton"
        >
          Rate
        </Button>
        <Button type="transparent" size="block" accessibilityLabel="cancelButton" onPress={goBack}>
          Not now
        </Button>
      </View>
    </FadeFromBottomModal>
  )
}

const themedStyles = StyleService.create({
  content: {
    backgroundColor: 'theme.background',
  },
  container: {
    marginTop: 16,
  },
  title: {
    textAlign: 'center',
    marginBottom: 24,
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: 24,
    marginHorizontal: 24,
  },
  confirmButton: {
    marginBottom: 8,
  },
})
