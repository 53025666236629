import React from 'react'
import { View } from 'react-native'
import { capitalCase } from 'change-case'
import { Dictionary } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Input } from '@components/base'
import {
  ListSettingsSection,
  TimeOfDaySlider,
  CommonSelect,
  MultiSelect,
  SelectGroup,
} from '@components'
import { ListFilterComponentProps } from '@src/containers/ListFilter/ListFilter'

const buildOption = (option: string) => ({ value: option, text: capitalCase(option) })
const buildOptions = (options: string[]) => options.map(buildOption)

export const ListFilter = (props: ListFilterComponentProps) => {
  const {
    possibleOrderByFields,
    possibleOrder,
    possibleTypes,
    order,
    types,
    orderBy,
    query,
    startHour,
    endHour,
    onChange,
    disableFilter,
    disableSearch,
    disableTime,
    disableOrderBy,
    disableOrder,
    enableGroupSelect = false,
  } = props
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      {!disableFilter && (
        <ListSettingsSection testID="ListFilter/Filter" label="Filter">
          {enableGroupSelect ? (
            <SelectGroup
              style={styles.row}
              groupedOptions={(possibleTypes as unknown) as Dictionary<string[]>}
              selectedOptions={(types as unknown) as Dictionary<string[]>}
              placeholder="Select"
              onSelect={onChange('types')}
            />
          ) : (
            <MultiSelect
              style={styles.row}
              options={(possibleTypes as unknown) as string[]}
              selectedOptions={(types as unknown) as string[]}
              placeholder="Select"
              onSelect={onChange('types')}
              accessibilityLabel="Filter"
            />
          )}
        </ListSettingsSection>
      )}
      {!disableOrderBy && (
        <CommonSelect
          options={buildOptions(possibleOrderByFields)}
          selectedOption={buildOption(orderBy)}
          disabled={possibleOrderByFields.length < 2}
          placeholder="Select"
          onSelect={(option) => onChange('orderBy')(option.value)}
          accessibilityLabel="Order by"
          label="Order By"
          style={styles.row}
        />
      )}
      {!disableOrder && (
        <CommonSelect
          options={buildOptions(possibleOrder)}
          selectedOption={buildOption(order)}
          placeholder="Select"
          onSelect={(option) => onChange('order')(option.value)}
          accessibilityLabel="Order"
          label="Order"
          style={styles.row}
        />
      )}
      {!disableSearch && (
        <Input
          accessibilityLabel="Keywords"
          autoCapitalize="none"
          iconRight="pencil-simple"
          label="Keywords"
          onChangeText={onChange('query')}
          placeholder="Type here..."
          testID="ListFilter/Keywords"
          value={query}
          style={styles.row}
        />
      )}
      {!disableTime && (
        <ListSettingsSection testID="ListFilter/Time" label="Time">
          <TimeOfDaySlider startValue={startHour} endValue={endHour} onChange={onChange} />
        </ListSettingsSection>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  row: {
    marginBottom: 24,
  },
})
