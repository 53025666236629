import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { useSelector } from 'react-redux'
import { openUrl, Zendesk } from '@src/utils'
import { userSelector } from '@src/selectors/app'
import { SurveysConfigKind } from '@src/types'
import { useSubscriptionFromRoute } from '../utils/hooks'
import { Fields, SubscriptionFields } from './ProgramDetails'
import { SubscriptionActionScreen } from './SubscriptionActionScreen'
import { Actions } from './ProgramDetails/Actions'

export const CancelSubscriptionOnCommitment = () => {
  const navigation = useNavigation()
  const user = useSelector(userSelector)
  const { subscription, primaryProduct } = useSubscriptionFromRoute()

  if (!subscription) {
    return null
  }

  const onCancelSubscription = () => {
    const params = { subscriptionId: subscription.id }

    if (!primaryProduct?.core || !primaryProduct?.includesCgm) {
      // Only show the cancellation survey for core cgm subscriptions
      navigation.navigate('CancelSubscriptionConfirmation', params)
      return
    }

    navigation.navigate('Questionnaire', {
      questionnaire: SurveysConfigKind.Cancellation,
      nextScreen: { params } as any,
    })
  }

  const cancelProgramEarly = () => {
    openUrl(Zendesk.cancelProgramUrl(user?.email))
  }

  return (
    <SubscriptionActionScreen
      name="Cancel Subscription?"
      title="We will miss you..."
      description="Unfortunately, you're still on commitment period."
    >
      <SubscriptionFields
        fields={[Fields.discountedRate, Fields.commitmentEnds]}
        mode="full"
        subscription={subscription}
      />
      <Actions
        primaryButtonText="Cancel When Commitment Ends"
        onPrimaryButtonPress={onCancelSubscription}
        primaryButtonType="outline"
        secondaryButtonText="Contact Support"
        onSecondaryButtonPress={cancelProgramEarly}
      />
    </SubscriptionActionScreen>
  )
}
