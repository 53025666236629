import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import Config from 'react-native-config'
import { SafeAreaView } from 'react-native-safe-area-context'
import { LongPressGestureHandler } from 'react-native-gesture-handler'
import { useNavigation } from '@react-navigation/native'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Debug } from '@config'
import { Text } from '@components/base'
import { NutrisenseLogo, ScrollableAvoidKeyboard } from '@components'
import { SignInButton, SignInButtonVariant, SignInForm } from '@screens/SignIn'
import { useSignIn } from '@src/hooks/useSignIn'
import { useAllowSignInWithApple, useAllowSignInWithGoogle } from '@utils/hooks'
import { useSnack } from '@utils/navigatorContext'

export const SignIn = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const showSnack = useSnack()
  const allowSignInWithGoogle = useAllowSignInWithGoogle()
  const allowSignInWithApple = useAllowSignInWithApple()
  const { signInWithApple, signInWithGoogle } = useSignIn()

  const onSignInWithGooglePress = async () => {
    try {
      await signInWithGoogle()
    } catch (error: any) {
      showSnack(error.message)
    }
  }

  const onSignInWithApplePress = async () => {
    try {
      await signInWithApple()
    } catch (error: any) {
      showSnack(error.message)
    }
  }

  const onForgotPasswordPress = () => navigation.navigate('PasswordResetRequest')

  const onSignUpPress = () => navigation.navigate('SignInOptions')

  const onAdminPress = () => {
    if (Debug.useAdminMenu) {
      navigation.navigate('ShowAdmin')
    }
  }

  return (
    <ScrollableAvoidKeyboard
      style={styles.container}
      contentContainerStyle={styles.content}
      keyboardShouldPersistTaps="handled"
    >
      <SafeAreaView edges={['top', 'bottom']} style={{ flex: 1 }}>
        <View style={styles.headerContainer} testID="SigninScreen/Logo">
          <LongPressGestureHandler
            onHandlerStateChange={onAdminPress}
            enabled={Config.ENV !== 'production'}
            minDurationMs={50}
          >
            <View>
              <NutrisenseLogo />
            </View>
          </LongPressGestureHandler>
        </View>
        <Text type="large" lineSpacing="none" style={styles.welcomeText}>
          Welcome! Let's log you in.
        </Text>
        <SignInForm />
        <TouchableOpacity
          accessibilityLabel="Forgot your password?"
          onPress={onForgotPasswordPress}
        >
          <Text type="large" lineSpacing="none" bold style={styles.forgotPassword}>
            Forgot password?
          </Text>
        </TouchableOpacity>
        <View style={styles.signInButtonContainer}>
          {allowSignInWithGoogle && (
            <SignInButton
              variant={SignInButtonVariant.Google}
              style={styles.signInButton}
              onPress={onSignInWithGooglePress}
            />
          )}
          {allowSignInWithApple && (
            <SignInButton
              variant={SignInButtonVariant.Apple}
              style={styles.signInButton}
              onPress={onSignInWithApplePress}
            />
          )}
          <View style={styles.bottom}>
            <Text type="regular" lineSpacing="none">
              Don't Have An Account?
            </Text>
            <TouchableOpacity
              accessibilityLabel="Register"
              onPress={onSignUpPress}
              testID="SigninScreen/RegisterButton"
            >
              <Text type="regular" lineSpacing="none" bold style={styles.signUp}>
                Register
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    </ScrollableAvoidKeyboard>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 16,
  },
  headerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  welcomeText: {
    width: '100%',
    marginBottom: 24,
    textAlign: 'center',
  },
  forgotPassword: {
    paddingVertical: 16,
    textAlign: 'center',
  },
  signInButtonContainer: {
    paddingBottom: 16,
    marginTop: 48,
  },
  signInButton: {
    marginBottom: 16,
  },
  bottom: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 32,
  },
  signUp: {
    marginTop: 16,
    color: 'theme.text.link',
  },
})
