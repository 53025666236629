import { gql } from '@apollo/client'
import { INSURANCE_POLICY_FIELDS } from './fragments/insurancePolicy'

const SUBMIT_INSURANCE_POLICY = gql`
  ${INSURANCE_POLICY_FIELDS}
  mutation submitInsurancePolicy {
    submitInsurancePolicy {
      ...InsurancePolicyFields
    }
  }
`

export default SUBMIT_INSURANCE_POLICY
