import { gql } from '@apollo/client'

export const FETCH_LIBRE_LINKUP_CONNECTION = gql`
  query linkupConnection {
    linkupConnection {
      active
      status
      lastSyncedAt
    }
  }
`
