import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { useWindowDimensions, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Device } from '@src/config'
import { GoalMetric, Statistic } from '@src/types'
import { statsSettingsStateSelector } from '@src/selectors/settings'
import { BLOOD_PRESSURE_KEYS } from '@src/screens/Insights/constants'
import { StatCard, ValueColorLegend } from '@src/screens/Insights/components'
import { statsSelector } from '@src/screens/Insights/models/insights.selectors'
import { getInitialSections, getKey, GLUCOSE_SECTION } from '@src/screens/Insights/utils/utils'
import { Text } from '@components/base'

import { User } from '@src/utils'
import { MobileAppFeature } from '@src/types'

interface InsightsListProps {
  primaryMetrics: GoalMetric[]
}

export const InsightsList = (props: InsightsListProps) => {
  const { primaryMetrics } = props

  const styles = useStyleSheet(themedStyle)
  const insightsSettings = useSelector(statsSettingsStateSelector)
  const stats = useSelector(statsSelector)
  const dimensions = useWindowDimensions()
  const hasSmallScreen = Device.hasSmallScreen(dimensions)

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  if (stats.length === 0) {
    return null
  }

  const statsMap = stats.reduce((acc, stat) => {
    const { key, section } = stat.meta

    if (BLOOD_PRESSURE_KEYS.includes(key)) {
      return acc
    } else {
      acc[getKey({ section, key })] = stat
    }

    return acc
  }, {} as Record<string, Statistic>)

  const sectionItems = insightsSettings.reduce((acc, item) => {
    if (!item.visible || item.section === 'score' || item.key === 'blood_pressure') {
      return acc
    }

    if (
      primaryMetrics.some(
        (metric) => metric.section === item.section && metric.statKey === item.key,
      )
    ) {
      return acc
    }

    const stat = statsMap[getKey(item)]

    if (!stat) {
      return acc
    }

    if (!isCGMFeatureAvailable && item.section === GLUCOSE_SECTION) {
      return acc
    }

    acc[item.section].items.push(stat)

    return acc
  }, getInitialSections())

  return Object.values(sectionItems).map(({ title, items }, index) => {
    if (items.length === 0) {
      return null
    }

    return (
      <Fragment key={title}>
        <View style={styles.sectionHeading}>
          <Text type={hasSmallScreen ? 'small' : 'regular'} bold style={styles.sectionTitle}>
            {title}
          </Text>
          {index === 0 && <ValueColorLegend />}
        </View>
        {items.map((item) => (
          <StatCard key={item.meta.key} item={item} />
        ))}
      </Fragment>
    )
  })
}

const themedStyle = StyleService.create({
  sectionHeading: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 8,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  sectionTitle: {
    color: 'theme.text.secondary',
  },
})
