import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Pill, Text } from '@components/base'
import { FadeFromBottomModal, Feature, useFeatureFlag } from '@components'
import { useGoBack } from '@src/utils/navigation'
import { useDexcomIntegration } from '@src/utils/hooks'

const WELCOME_TEXT = [
  'Nora, as an AI, may occasionally provide inaccurate facts. If you notice inaccuracies, please contact support.',
]
const WELCOME_TEXT_RESEARCH = [
  'Nora is a new AI in development (Beta), and may occasionally provide inaccurate responses. ' +
    'For any questions regarding research programs and sensors at Nutrisense, always refer to ' +
    'our research study help articles or contact our Support Team.',
  'While using Nora, if you notice any inaccuracies, please contact support.',
]

export const ChatWelcomeModal = () => {
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()
  const isDexcomIntegration = useDexcomIntegration()
  const isDexcomResearchEnabled = useFeatureFlag(Feature.DexcomResearch)

  const description =
    isDexcomIntegration && isDexcomResearchEnabled ? WELCOME_TEXT_RESEARCH : WELCOME_TEXT

  return (
    <FadeFromBottomModal closeModal={goBack} closeAccessory="none" contentStyles={styles.container}>
      <Icon name="sparkle" style={styles.icon} />
      <View style={styles.titleContainer}>
        <Text type="title-2">Welcome To Nora AI</Text>
        <Pill text="Beta" size="s" fill="citron" style={styles.betaTag} />
      </View>
      {description.map((text) => (
        <Text key={text} type="regular" style={styles.text}>
          {text}
        </Text>
      ))}
      <Button
        type="primary"
        size="block"
        onPress={goBack}
        accessibilityLabel="Continue"
        style={styles.button}
      >
        Continue
      </Button>
    </FadeFromBottomModal>
  )
}

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
  },
  icon: {
    marginTop: 8,
    marginBottom: 16,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  betaTag: {
    marginLeft: 8,
  },
  text: {
    textAlign: 'center',
    marginBottom: 16,
  },
  button: {
    marginTop: 16,
  },
})
