import { useEffect, useState } from 'react'
import moment from 'moment'
import { LatestUserAppointment } from '@src/types.ts'
import Storage from '@utils/storage.ts'

export const useCallSoonAlert = (appointment: LatestUserAppointment | null) => {
  const [showCallSoonAlert, setShowCallSoonAlert] = useState(false)
  const callSoonAlertClosedKey = `${Storage.CALL_SOON_ALERT_CLOSED_KEY}_${appointment?.id}`

  const onClose = () => {
    if (appointment) {
      Storage.set(callSoonAlertClosedKey, true)
    }
    setShowCallSoonAlert(false)
  }

  useEffect(() => {
    const isCallSoonAlertClosed = Storage.get<boolean>(callSoonAlertClosedKey)

    if (
      !isCallSoonAlertClosed &&
      appointment?.date &&
      new Date(appointment.date) > new Date() &&
      !appointment.meetingStatus &&
      moment(appointment.date).diff(moment(), 'hours') < 24
    ) {
      setShowCallSoonAlert(true)
    }
  }, [appointment, callSoonAlertClosedKey])

  return { showCallSoonAlert, onClose }
}
