import React, { useContext } from 'react'

export const TimeZoneContext = React.createContext<{
  timeZone: string
  setTimeZone: (timeZone: string) => void
}>({
  timeZone: '',
  setTimeZone: () => null,
})

export const useTimeZone = (): [string, (timeZone: string) => void] => {
  const timeZoneContextValue = useContext(TimeZoneContext)
  return [timeZoneContextValue.timeZone, timeZoneContextValue.setTimeZone]
}
