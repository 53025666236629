import React, { useEffect } from 'react'
import { Image, View, ActivityIndicator } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Icon } from '@components/base'
import { topPriorityCoachAssignmentSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { nutritionistImage } from '@src/assets/images'
import { useSnack } from '@src/utils'
import { ErrorMessages } from '@src/config/logger'
import Device from '@src/config/device'
import { ImageWithPlaceholder } from '../Image/FastImageWithPlaceholder'

interface ChatlikeNotificationBannerProps {
  action: (() => void) | undefined
  dismiss: (() => void) | undefined
  content: string
  actionButtonLabel: string
}

const NUTRITIONIST_PHOTO_SIZE = 32
const defaultNutritionistPhotoUri = Device.web
  ? null
  : Image.resolveAssetSource(nutritionistImage.imageSource).uri

export const ChatlikeNotificationBanner = (props: ChatlikeNotificationBannerProps) => {
  const { action, dismiss, content, actionButtonLabel } = props
  const styles = useStyleSheet(themedStyles)
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const showSnack = useSnack()
  const dispatch = useDispatch()

  const nutritionistPhotoUri =
    coachAssignment?.coach?.coachProfile?.photo || defaultNutritionistPhotoUri

  useEffect(() => {
    dispatch({
      type: 'nutritionistHub/fetchCoachAssignments',
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
    })
  }, [dispatch, showSnack])

  return (
    <View style={styles.banner}>
      <View style={styles.nutritionistPhotoAndContentContainer}>
        {nutritionistPhotoUri && (
          <View style={styles.nutritionistPhotoContainer}>
            <ImageWithPlaceholder
              style={styles.nutritionistPhoto as ImageStyle}
              source={{ uri: nutritionistPhotoUri }}
              placeholderComponent={
                <ActivityIndicator animating size="small" {...styles.spinner} />
              }
            />
          </View>
        )}
        <View style={styles.contentWrapper}>
          <Text type="regular" lineSpacing="tight" style={styles.contentText}>
            {content}
          </Text>
          <View style={styles.leftArrow} />
          <View style={styles.leftArrowOverlap} />
        </View>
      </View>
      <View style={styles.buttonWrapper}>
        <Button
          type="transparent"
          size="small"
          accessibilityLabel="Dismiss"
          style={styles.button}
          onPress={dismiss}
        >
          Dismiss
        </Button>
        {action && (
          <Button
            type="primary"
            size="small"
            accessoryRight={<Icon name="arrow-right" />}
            accessibilityLabel={actionButtonLabel}
            style={styles.button}
            onPress={action}
          >
            {actionButtonLabel}
          </Button>
        )}
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  banner: {
    margin: 16,
    paddingHorizontal: 16,
    borderRadius: 18,
    flexDirection: 'column',
  },
  nutritionistPhotoAndContentContainer: {
    flexDirection: 'row',
    marginBottom: 12,
    marginRight: 10,
    height: 92,
  },
  nutritionistPhotoContainer: {
    justifyContent: 'flex-end',
    zIndex: 99,
  },
  contentWrapper: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 18,
    flexDirection: 'column',
    marginBottom: 2,
    borderColor: 'theme.surface.base1',
    backgroundColor: 'theme.surface.base1',
    marginLeft: 10,
  },
  contentText: {
    flex: 1,
  },
  leftArrow: {
    position: 'absolute',
    backgroundColor: 'theme.surface.base1',
    width: 20,
    height: 25,
    bottom: 0,
    borderBottomRightRadius: 30,
    left: -10,
  },
  leftArrowOverlap: {
    position: 'absolute',
    backgroundColor: 'theme.background',
    width: 20,
    height: 35,
    bottom: -6,
    borderBottomRightRadius: 18,
    left: -20,
  },
  buttonWrapper: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  button: {
    marginRight: 8,
  },
  nutritionistPhoto: {
    width: NUTRITIONIST_PHOTO_SIZE,
    height: NUTRITIONIST_PHOTO_SIZE,
    borderRadius: NUTRITIONIST_PHOTO_SIZE / 2,
  },
  spinner: {
    color: 'theme.surface.base',
  },
})
