import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { Product } from '@src/types'
import { useGroupedProducts, useInsuranceCoverage } from '../utils/hooks'
import { GroupableProduct } from '../types/types'
import { SubscriptionItem } from './SubscriptionItem'

export interface DietitianAddonsProps {
  products: Product[]
  onItemSelected: (item: GroupableProduct) => void
}

export const DietitianAddons = ({ products, onItemSelected }: DietitianAddonsProps) => {
  const styles = useStyleSheet(themedStyle)

  const groupedProducts = useGroupedProducts(products)
  const insuranceCoverageAvailable = useInsuranceCoverage()

  if (!groupedProducts) {
    return null
  }

  return (
    <View style={styles.container}>
      <Text type="small" bold lineSpacing="none" style={styles.title}>
        Nutrition Coaching
      </Text>
      {groupedProducts.map((item) => (
        <SubscriptionItem
          title={item.title}
          description={item.shortDescription ?? item.description}
          style={styles.item}
          item={item}
          key={item.id}
          onItemPress={onItemSelected}
          insuranceCoverageAvailable={insuranceCoverageAvailable(item as Product)}
        />
      ))}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
  },
  title: {
    marginTop: 24,
    marginBottom: 16,
    textTransform: 'uppercase',
    letterSpacing: 0.3,
  },
  item: {
    minHeight: 136,
    marginBottom: 16,
  },
})
