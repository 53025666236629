import React from 'react'
import { Keyboard } from 'react-native'
import { Input } from '@src/components/base'
import { InputType, TextAreaWithSkipInputConfig } from '../../types'
import { INPUT_SANITIZER } from './Shared/utils'
import { Option } from './Shared/Option'
import { OptionType } from './Shared/Option'
import { InputPropsBase } from './types'

interface TextAreaWithSkipInputProps extends InputPropsBase {
  response: TextAreaWithSkipInputConfig
}

export const TextAreaWithSkipInput = ({
  field,
  response,
}: TextAreaWithSkipInputProps): JSX.Element => {
  const {
    type = InputType.STRING,
    skip_label: skipLabel = 'Skip',
    skip_value: skipValue,
    placeholder = 'Type your answer',
  } = response.kindMetadata || {}

  const [isOptionSelected, setIsOptionSelected] = React.useState(field.value === skipValue)
  const [inputValue, setInputValue] = React.useState(field.value === skipValue ? '' : field.value)

  const onOptionPress = () => {
    if (isOptionSelected) {
      setIsOptionSelected(false)
      field.onChange(inputValue)
    } else {
      setIsOptionSelected(true)
      field.onChange(skipValue)
    }
  }

  const onTextChange = (value: string) => {
    const processedValue = INPUT_SANITIZER[type](value)

    if (processedValue === null) {
      return
    }

    setInputValue(processedValue)
    field.onChange(processedValue.trim() || null)
  }

  const onTextAreaFocus = () => {
    setIsOptionSelected(false)
    field.onChange(inputValue)
  }

  return (
    <>
      <Option
        label={skipLabel}
        onPress={() => {
          onOptionPress()
          setInputValue('')
          Keyboard.dismiss()
        }}
        selected={isOptionSelected}
        type={OptionType.Radio}
      />
      <Input
        placeholder={placeholder}
        onChangeText={onTextChange}
        value={inputValue}
        style={{ marginBottom: 16 }}
        numberOfLines={3}
        onFocus={onTextAreaFocus}
        keyboardType={type === InputType.NUMBER ? 'numeric' : 'default'}
        blurOnSubmit
        returnKeyType="done"
      />
    </>
  )
}
