import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Pill, Text } from '@components/base'
import { CircleIcon } from '@components'

interface MealPlaceholderProps {
  meal: 'primary' | 'secondary'
}

export const MealPlaceholder = ({ meal }: MealPlaceholderProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.placeholderContainer}>
      <CircleIcon style={styles.placeholderIcon} name="fork-knife" />
      <Text type="large" style={styles.placeholderText}>
        {meal === 'primary'
          ? 'Select a meal to start a comparison'
          : 'Select another meal to compare'}
      </Text>
      <Pill status="success" text="??" style={styles.placeholderScore} />
    </View>
  )
}

const themedStyle = StyleService.create({
  placeholderContainer: {
    flexDirection: 'row',
    gap: 16,
    height: 80,
    paddingHorizontal: 16,
    marginBottom: 16,
    alignItems: 'center',
    backgroundColor: 'theme.surface.base3',
    borderRadius: 8,
    borderWidth: 2,
    borderStyle: 'dashed',
    borderColor: 'theme.surface.base1',
  },
  placeholderIcon: {
    width: 56,
    height: 56,
    padding: 0,
    backgroundColor: 'theme.surface.base1',
  },
  placeholderText: {
    flex: 1,
  },
  placeholderScore: {
    opacity: 0.3,
  },
})
