import defaultReducers from '@src/models/defaultReducers'
import { HealthMetricCollection } from '@src/types'
import { LabResultsStoreState } from './labResults.types'

export const reducers = {
  ...defaultReducers,
  updateHealthMetrics: (
    state: LabResultsStoreState,
    { payload }: { payload: HealthMetricCollection },
  ) => {
    return {
      ...state,
      healthMetrics: payload,
    }
  },
}

export default reducers
