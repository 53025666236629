import React, { useState } from 'react'
import DateTimePicker from 'react-native-modal-datetime-picker'
import { useDispatch } from 'react-redux'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet } from '@src/components'
import { useIsDarkMode } from '@src/config/theme'
import { NotificationEngineReminderKind } from '@src/types'
import { Analytics, CustomEventTypes } from '@src/config'
import { useGoBack, useSnack } from '@src/utils'
import { Icon, Text } from '@src/components/base'
import { AppStackParamList } from '@src/navigation/types'
import { StyleService, useStyleSheet } from '@src/style/service'

export const SetReminderModal = () => {
  const isDarkMode = useIsDarkMode()
  const [reminderDatePickerVisible, setReminderDatePickerVisible] = useState(false)
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const goBack = useGoBack()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const route = useRoute<RouteProp<AppStackParamList, 'SetReminderModal'>>()
  const { dismissButtonText, goBackOnDismiss } = route.params
  const styles = useStyleSheet(themedStyle)

  const reminderDateSelected = (date: Date) => {
    Analytics.track(CustomEventTypes.OutcomesTrackingLabResultsReminderDateSelected)
    setReminderDatePickerVisible(false)
    const payload = {
      sendOn: date,
      kind: NotificationEngineReminderKind.SubmitLabResults,
    }

    dispatch({
      type: 'settings/scheduleReminder',
      payload,
      success: () => {
        showSnack('Reminder scheduled!')
        navigation.pop()
        if (goBackOnDismiss) {
          goBack()
        }
      },
      failure: (error: any) => {
        if (error?.message) {
          showSnack(error.message, null, 'error')
        }
      },
    })
  }

  return (
    <BottomSheet
      primaryButton={{
        text: 'Set Reminder',
        onPress: () => {
          setReminderDatePickerVisible(true)
        },
        accessoryRight: <Icon name="clock" />,
      }}
      secondaryButton={{
        text: dismissButtonText,
        onPress: () => {
          navigation.pop()
          if (goBackOnDismiss) {
            goBack()
          }
        },
      }}
      buttonDirection="column-reverse"
    >
      <DateTimePicker
        isDarkModeEnabled={isDarkMode}
        isVisible={reminderDatePickerVisible}
        focusable={false}
        onCancel={() => {
          setReminderDatePickerVisible(false)
        }}
        onConfirm={reminderDateSelected}
        minimumDate={new Date()}
        themeVariant={isDarkMode ? 'dark' : 'light'}
        mode="date"
      />
      <Text type="title-2" style={styles.title}>
        Don't have lab results yet?
      </Text>

      <Text type="regular" style={styles.content}>
        We can remind you to submit your lab results at a later date
      </Text>
    </BottomSheet>
  )
}

export const themedStyle = StyleService.create({
  title: {
    textAlign: 'center',
    marginTop: 16,
  },
  content: {
    textAlign: 'center',
    marginVertical: 24,
  },
})
