import React from 'react'
import { ActivityIndicator, ColorValue, StyleProp, View, ViewStyle } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'

interface LoadingIndicatorProps {
  size?: 'small' | 'large'
  viewStyles?: StyleProp<ViewStyle>
  color?: ColorValue
}

export const LoadingIndicator = ({ size, viewStyles, color }: LoadingIndicatorProps) => {
  const theme = useTheme()
  const defaultColor = theme['theme.surface.base']
  const defaultStyles = useStyleSheet(themedStyle)

  return (
    <View style={viewStyles ?? defaultStyles.loadingIndicatorContainer}>
      <ActivityIndicator size={size} color={color ?? defaultColor} />
    </View>
  )
}

const themedStyle = StyleService.create({
  loadingIndicatorContainer: {
    flex: 1,
    justifyContent: 'center',
  },
})
