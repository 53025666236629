import { gql } from '@apollo/client'

export const CREATE_REFILL_CONSENT = gql`
  mutation createRefillConsent {
    createRefillConsent {
      id
      givenAt
      invalidatedAt
    }
  }
`
