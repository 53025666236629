import { gql } from '@apollo/client'

export const FETCH_TUTORIAL = gql`
  query fetchTutorial($id: ID!) @api(name: cms) {
    tutorial(id: $id) {
      data {
        id
        attributes {
          position
          name
          title
          duration
          description
          buttonText
          tutorialType
          image {
            data {
              id
              attributes {
                url
                previewUrl
              }
            }
          }
          tutorialSteps(sort: "position", filters: { publishedAt: { notNull: true } }) {
            data {
              id
              attributes {
                title
                description
                duration
                buttonText
                buttonIcon
                position
                warning
                image {
                  data {
                    id
                    attributes {
                      url
                      previewUrl
                    }
                  }
                }
                action {
                  __typename
                  ... on ComponentActionActionLink {
                    id
                    url
                  }
                  ... on ComponentActionActionVideo {
                    id
                    description
                    title
                    video {
                      data {
                        attributes {
                          url
                          previewUrl
                        }
                      }
                    }
                  }
                  ... on ComponentActionPlatformLink {
                    id
                    android {
                      url
                    }
                    ios {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
