import { Model } from '@models'
import SUBMIT_HEALTH_METRICS from '../graphql/submitHealthMetrics'
import FETCH_HEALTH_METRICS from '../graphql/fetchHealthMetrics'
import DELETE_HEALTH_METRIC from '../graphql/deleteHealthMetric'
import CREATE_HEALTH_METRIC from '../graphql/createHealthMetric'
import UPDATE_HEALTH_METRIC from '../graphql/updateHealthMetric'
import reducers from './labResults.reducers'

export default class LabResults {
  namespace = 'labResults'

  state = {
    ...Model.defaultState,
    healthMetrics: null,
  }

  effects = {
    submit: Model.buildEffect({
      name: `${this.namespace}/submit`,
      query: SUBMIT_HEALTH_METRICS,
      caching: false,
      dataPath: 'submitLabResults',
    }),
    fetchHealthMetrics: Model.buildEffect({
      name: `${this.namespace}/fetchHealthMetrics`,
      query: FETCH_HEALTH_METRICS,
      caching: false,
      dataPath: 'healthMetrics',
      reducers: [{ name: 'updateHealthMetrics' }],
    }),
    deleteHealthMetric: Model.buildEffect({
      name: `${this.namespace}/deleteHealthMetric`,
      query: DELETE_HEALTH_METRIC,
      caching: false,
      dataPath: 'deleteHealthMetric',
    }),
    createHealthMetric: Model.buildEffect({
      name: `${this.namespace}/createHealthMetric`,
      query: CREATE_HEALTH_METRIC,
      caching: false,
      dataPath: 'createHealthMetric',
    }),
    updateHealthMetric: Model.buildEffect({
      name: `${this.namespace}/updateHealthMetric`,
      query: UPDATE_HEALTH_METRIC,
      caching: false,
      dataPath: 'updateHealthMetric',
    }),
  }

  reducers = reducers
}
