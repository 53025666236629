import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { CreateNewIngredientButton } from '@screens/Ingredients'

interface NoSearchResultsProps {
  query: string
}

export const NoSearchResults = ({ query }: NoSearchResultsProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        <View style={styles.iconBackground} />
        <Icon name="warning-circle" style={styles.icon} />
      </View>
      <Text type="large" style={styles.text}>
        No Results Found
      </Text>
      <CreateNewIngredientButton name={query} />
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    alignItems: 'center',
    padding: 40,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconBackground: {
    backgroundColor: 'theme.accent.brick',
    width: 48,
    height: 48,
    borderRadius: 24,
    position: 'absolute',
  },
  icon: {
    width: 60,
    height: 60,
  },
  text: {
    marginVertical: 16,
    textAlign: 'center',
  },
})
