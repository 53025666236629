import { Options } from 'check-password-strength'

const passwordStrengthOptions: Options<string> = [
  {
    id: 0,
    value: 'Weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Average',
    minDiversity: 2,
    minLength: 6,
  },
  {
    id: 2,
    value: 'Good',
    minDiversity: 3,
    minLength: 8,
  },
  {
    id: 3,
    value: 'Awesome',
    minDiversity: 4,
    minLength: 10,
  },
]

export default passwordStrengthOptions
