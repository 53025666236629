import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet } from '@src/components'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useGoBack } from '@src/utils'
import { AppStackParamList } from '@src/navigation/types'
import { NutritionistPhotoIcon } from './NutritionistPhotoIcon'

export const VideoCallsIntroModal = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()

  const onGetStartedPress = () => {
    navigation.replace('VideoCallsGettingStartedScreen')
  }

  return (
    <BottomSheet
      buttonDirection="column-reverse"
      primaryButton={{ text: 'Get Started', onPress: onGetStartedPress }}
      secondaryButton={{ text: 'Dismiss', onPress: goBack }}
    >
      <NutritionistPhotoIcon style={styles.nutritionistPhoto} />
      <Text style={styles.content} type="title-2">
        Video Calls with your Nutritionist!
      </Text>
      <Text style={styles.content} type="large">
        We're super excited to introduce video calls with our top-notch nutritionists. Good news:
        video calls are{' '}
        <Text type="large" bold>
          $0 out of pocket
        </Text>{' '}
        with most insurance.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  nutritionistPhoto: {
    alignSelf: 'center',
  },
  content: {
    textAlign: 'center',
    margin: 8,
  },
})
