import React from 'react'
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { DismissibleModal } from '@components'
import { useGoBack } from '@src/utils/navigation'
import { Icon, Text } from '@components/base'

interface FadeFromBottomModalProps {
  children: React.ReactNode
  title?: string
  closeModal?: () => void
  contentStyles?: StyleProp<ViewStyle>
  closeAccessory?: 'none' | 'button'
}

// make sure that you add fadeFromBottomModalOptions to screen when using this modal
const FadeFromBottomModal = (props: FadeFromBottomModalProps) => {
  const { children, title, closeModal, contentStyles, closeAccessory = 'button' } = props
  const styles = useStyleSheet(themedStyle)
  const goBack = useGoBack()

  return (
    <DismissibleModal containerStyle={styles.modalContainer} modalStyle={styles.modal}>
      {closeAccessory === 'button' && (
        <TouchableOpacity
          style={styles.closeButton}
          activeOpacity={0.7}
          onPress={closeModal || goBack}
          accessibilityLabel="closeButton"
        >
          <Icon name="x" style={styles.icon} />
        </TouchableOpacity>
      )}
      <SafeAreaView edges={['bottom']} style={[styles.content, contentStyles]}>
        {!!title && (
          <Text type="large" bold style={styles.title}>
            {title}
          </Text>
        )}
        {children}
      </SafeAreaView>
    </DismissibleModal>
  )
}

export default FadeFromBottomModal

const themedStyle = StyleService.create({
  modalContainer: {
    justifyContent: 'flex-end',
  },
  modal: {
    flex: 0,
    justifyContent: 'flex-end',
    backgroundColor: 'theme.transparent',
  },
  content: {
    padding: 16,
    backgroundColor: 'theme.background',
    borderTopLeftRadius: 32,
    borderTopRightRadius: 32,
  },
  title: {
    textAlign: 'center',
    marginBottom: 16,
  },
  icon: {
    alignSelf: 'flex-end',
  },
  closeButton: {
    marginBottom: 8,
    marginRight: 16,
  },
})
