import React, { useContext, useMemo } from 'react'
import { CommonActions, RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import pluralize from 'pluralize'
import { Divider } from '@ui-kitten/components'
import { authenticatedUserSelector } from '@src/selectors/app'
import { CheckoutContainer } from '@src/screens/Marketplace/components/checkout/CheckoutContainer'
import { ProductDescription } from '@src/screens/Marketplace/components/checkout/ProductDescription'
import { ProductPayment } from '@src/screens/Marketplace/components/checkout/ProductPayment'
import { AddressForm, AddressType } from '@src/screens/Marketplace/components/checkout/AddressForm'
import { CommonAuthorizedStackParamList } from '@src/navigation/types'
import {
  usePromotionCode,
  usePurchaseOneTimePayment,
  useTaxEstimation,
} from '@screens/Marketplace/utils/hooks'
import { BillingProduct } from '@src/types'
import { NavigatorContext } from '@utils'

export const AddonCheckout = () => {
  const navigation = useNavigation()
  const route = useRoute<RouteProp<CommonAuthorizedStackParamList, 'AddonCheckout'>>()
  const { selections } = route.params
  const { enableLoader, disableLoader } = useContext(NavigatorContext)

  const lowestPricedProduct = useMemo(
    () =>
      selections.reduce(
        (min, selection) => (selection.product.price < min.price ? selection.product : min),
        selections[0].product,
      ),
    [selections],
  )
  const promotionCode = usePromotionCode(lowestPricedProduct.key as BillingProduct)

  const user = useSelector(authenticatedUserSelector)
  const { address } = user

  // we don't support multiple selections anymore
  const selection = selections[0]
  const estimatedTax = useTaxEstimation(selection.product.key as BillingProduct, promotionCode)

  const fullPrice =
    selections.reduce(
      (sum, selection) => sum + selection.product.price * (selection.quantity ?? 1),
      0,
    ) || 0

  const requiresShipping = selections.some((selection) => selection.product.requiresShipping)

  const addressType = requiresShipping ? AddressType.Shipping : AddressType.Billing

  const onEditAddressPress = () => {
    navigation.navigate('ChangeAddress', { addressType })
  }

  const onSuccessNavigationAction = () => {
    navigation.dispatch((state) => {
      // Remove the last 2 routes (Checkout and ProductsSelection) from current list of routes
      const routes = state.routes.slice(0, -2)

      // navigate to OrderConfirmedScreen
      const newRoutes = [...routes, { name: 'OrderConfirmed' }]

      return CommonActions.reset({
        index: newRoutes.length - 1,
        routes: newRoutes,
      } as any)
    })
  }

  const purchaseOneTimePayment = usePurchaseOneTimePayment()

  const onCheckout = async () => {
    enableLoader()

    try {
      await purchaseOneTimePayment(selection, promotionCode)
      onSuccessNavigationAction()
    } finally {
      disableLoader()
    }
  }

  return (
    <CheckoutContainer
      checkoutDisabled={selections.length === 0}
      checkoutButtonTitle="Confirm Order"
      onCheckout={onCheckout}
    >
      {selections.map((selection, index) => (
        <ProductDescription
          key={selection.product.id}
          title={selection.product.title}
          description={pluralize('piece', selection.quantity, true)}
          price={`$${selection.product.price * (selection.quantity ?? 1)}`}
          showDivider={index !== selections.length - 1}
        />
      ))}
      <Divider appearance="large" />
      <ProductPayment
        product={lowestPricedProduct.key as BillingProduct}
        estimatedTax={estimatedTax}
        fullPrice={fullPrice}
        promotionCode={promotionCode}
      />
      <Divider appearance="large" />
      <AddressForm
        addressType={addressType}
        address={address}
        onEditAddressPress={onEditAddressPress}
      />
    </CheckoutContainer>
  )
}
