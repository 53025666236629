import React from 'react'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { DrawerNavigationProp } from '@react-navigation/drawer'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Statistic } from '@src/types'
import { Text } from '@src/components/base'
import {
  AppStackParamList,
  CommonAuthorizedStackParamList,
  DrawerParamList,
} from '@src/navigation/types'
import {
  GlucoseKetonesEnum,
  MacrosSectionEnum,
  MeasurementEnum,
  ScoreSectionEnum,
} from '../utils/pageConfig'
import { statsSelector } from '../models/insights.selectors'
import { getValuePercentChangeWithSign } from '../utils/utils'

export type WeekOverWeekToolTipProps = {
  statMetaKey: ScoreSectionEnum | GlucoseKetonesEnum | MacrosSectionEnum | MeasurementEnum
  statName: string
}

export const WeekOverWeekToolTip = ({ statMetaKey, statName }: WeekOverWeekToolTipProps) => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<
    DrawerNavigationProp<DrawerParamList> &
      StackNavigationProp<CommonAuthorizedStackParamList & AppStackParamList>
  >()

  const stats = useSelector(statsSelector)
  const metric = stats.find((stat) => isEqual(stat.meta.key, statMetaKey)) as Statistic
  const { percentChange, percentChangeDirection } = getValuePercentChangeWithSign(metric)

  const onAskNutritionistPress = () => {
    navigation.replace('Drawer', { screen: 'Dashboard', params: { screen: 'NutritionistHub' } })
  }

  const title = statName.toLowerCase()

  const averageText = title.includes('average') ? '' : 'average'

  const text = `Your ${averageText} ${title} was ${percentChange}% ${percentChangeDirection} than the last period`

  // Filter edge cases like (infinity, null) and diffs less than 5%
  const validDiff = percentChange >= 5 && percentChange < 100
  if (!validDiff) {
    return null
  }

  return (
    <View style={[styles.container]}>
      <View style={styles.textContainer}>
        <Text type="small" style={styles.text}>
          {text}
        </Text>
      </View>
      <TouchableOpacity
        style={styles.linkedTextContainer}
        onPress={onAskNutritionistPress}
        accessibilityLabel="Ask Nutritionist"
      >
        <Text type="small" bold style={styles.linkedText}>
          Ask Nutritionist
        </Text>
      </TouchableOpacity>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'theme.tooltip.background',
    paddingLeft: 12,
    paddingRight: 16,
    paddingVertical: 8,
    borderRadius: 4,
    marginHorizontal: 16,
    marginBottom: 24,
  },
  textContainer: {
    flex: 2,
    marginRight: 20,
  },
  linkedTextContainer: {
    flex: 1,
  },
  text: {
    color: 'theme.tooltip.textPrimary',
  },
  linkedText: {
    color: 'theme.tooltip.link',
  },
})
