import React, { useMemo } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RouteProp, useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { InlineAlert, LoadingIndicator } from '@components'
import { userSelector } from '@src/selectors/app'
import { openUrl, Zendesk } from '@src/utils'
import { replaceTextInStringWithObj } from '@src/utils/replaceTextInStringWithObj'
import { QuestionnaireScreenName, QuestionnaireStackParamList } from '@src/navigation/types'
import { useSyncHealthQuestionnaireState } from '@src/hooks/useSyncHealthQuestionnaireState'
import { UiInlineAlert } from '@src/types'

const GENERIC_REASON =
  'Thank you so much for your interest in the Nutrisense program. Unfortunately, you are currently not ' +
  'approved for our program.'

export const HealthQuestionnaireRejectionScreen = () => {
  const user = useSelector(userSelector)
  const styles = useStyleSheet(themedStyles)
  const route = useRoute<
    RouteProp<
      QuestionnaireStackParamList,
      QuestionnaireScreenName.HealthQuestionnaireRejectionReason
    >
  >()

  const { outcome: rejectedReason } = route.params

  const onContactSupportPress = () => {
    openUrl(Zendesk.baseUrl(user?.email))
  }

  useSyncHealthQuestionnaireState()

  const formattedContent = useMemo(() => {
    if (!rejectedReason) {
      return null
    }
    const { reason } = rejectedReason
    const content = [reason.message.content] as (string | JSX.Element)[]
    const { links } = reason.message
    if (!links || links.length === 0) {
      return content
    }
    // loop all links and replace all occurrences in content
    for (const { placeholder, url } of links) {
      for (let i = 0; i < content.length; i++) {
        const item = content[i]
        // skip all Text elements or items that don't contain the placeholder
        if (typeof item !== 'string' || !item.includes(placeholder)) {
          continue
        }

        // replace all placeholders with text link items
        const replacementSlice = replaceTextInStringWithObj({
          string: item,
          replaceOn: placeholder,
          onMatch: (i) => (
            <Text
              type="regular"
              style={[styles.rejectedReasonTextLink, styles.rejectedReasonText]}
              onPress={() => openUrl(url)}
              key={`${placeholder}-${url}-${i}`}
            >
              {placeholder}
            </Text>
          ),
        })

        // remove existing content and replace with new content with substituted links
        content.splice(i, 1, ...replacementSlice)
      }
    }
    return content
  }, [rejectedReason, styles])

  const reason = rejectedReason?.reason || null

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.topContainer}>
        {reason ? (
          <>
            <Text type="regular" style={styles.headerText}>
              We're Sorry
            </Text>
            <Icon name="x-circle" style={styles.rejectionIcon} />
            <Text type="title-2" style={styles.titleText}>
              {reason?.headline || GENERIC_REASON}
            </Text>
            <Text type="regular" style={styles.rejectedReasonText}>
              {formattedContent || reason?.message.content || GENERIC_REASON}
            </Text>
            {reason?.notes && (
              <InlineAlert
                category={UiInlineAlert.Info}
                message={reason.notes.map((note) => `${note}.`).join('\n')}
              />
            )}
          </>
        ) : (
          <LoadingIndicator size="large" />
        )}
      </View>
      <Button
        type="transparent"
        size="block"
        accessibilityLabel="contact support"
        onPress={onContactSupportPress}
      >
        Contact Support
      </Button>
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  topContainer: {
    flex: 1,
  },
  headerText: {
    textAlign: 'center',
  },
  titleText: {
    textAlign: 'center',
    marginBottom: 24,
  },
  rejectedReasonText: {
    textAlign: 'center',
    marginBottom: 24,
  },
  rejectedReasonTextLink: {
    color: 'theme.text.link',
  },
  rejectionIcon: {
    alignSelf: 'center',
    width: 48,
    marginVertical: 24,
    color: 'theme.error.base',
  },
})
