import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, ButtonProps } from '@components/base'

interface ActionsProps {
  primaryButtonText?: string
  secondaryButtonText?: string
  primaryButtonDisabled?: boolean
  primaryButtonType?: ButtonProps['type']
  secondaryButtonType?: ButtonProps['type']
  onPrimaryButtonPress?: () => void
  onSecondaryButtonPress?: () => void
  primaryButtonIndicator?: number | string
  warning?: string
  style?: StyleProp<ViewStyle>
}

export const Actions = (props: ActionsProps) => {
  const {
    primaryButtonText,
    secondaryButtonText,
    primaryButtonDisabled,
    primaryButtonType = 'primary',
    secondaryButtonType = 'transparent',
    onPrimaryButtonPress,
    onSecondaryButtonPress,
    primaryButtonIndicator,
    warning,
    style,
  } = props
  const styles = useStyleSheet(themedStyle)

  if (!primaryButtonText && !secondaryButtonText) {
    return null
  }

  return (
    <View style={[styles.actions, style]}>
      {!!warning && (
        <Text type="small" bold style={styles.warning}>
          {warning}
        </Text>
      )}
      {!!primaryButtonText && (
        <View style={styles.confirmButtonContainer}>
          <Button
            type={primaryButtonType}
            size="block"
            style={styles.button}
            disabled={primaryButtonDisabled}
            onPress={onPrimaryButtonPress}
            accessibilityLabel={primaryButtonText}
            allowPressWhenDisabled
          >
            {primaryButtonText}
          </Button>
          {primaryButtonIndicator && (
            <View style={styles.indicator}>
              <Text type="regular" style={styles.indicatorText}>
                {primaryButtonIndicator}
              </Text>
            </View>
          )}
        </View>
      )}
      {!!secondaryButtonText && (
        <Button
          type={secondaryButtonType}
          size="block"
          style={[styles.button, !!primaryButtonText && { marginTop: 8 }]}
          onPress={onSecondaryButtonPress}
          accessibilityLabel={secondaryButtonText}
        >
          {secondaryButtonText}
        </Button>
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  warning: {
    textAlign: 'center',
    color: 'theme.text.secondary',
    marginBottom: 8,
  },
  actions: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingHorizontal: 16,
    marginTop: 40,
    marginBottom: 20,
  },
  button: {
    paddingHorizontal: 16,
  },
  confirmButtonContainer: {
    justifyContent: 'center',
  },
  indicator: {
    position: 'absolute',
    right: 16,
    width: 32,
    height: 32,
    borderRadius: 32,
    backgroundColor: 'theme.solid.white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicatorText: {
    color: 'theme.solid.black',
  },
})
