import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { View } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
import { BottomSheet, InlineAlert } from '@src/components'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Storage, useGoBack } from '@src/utils'
import { AppStackParamList } from '@src/navigation/types'
import { SurveysConfigKind, UiInlineAlert } from '@src/types.ts'
import { useStorageValue } from '@utils/storage/mmkv.ts'
import { userSelector } from '@selectors/app.ts'
import { Analytics, CustomEventTypes } from '@config'

export const VideoCallsUpsellDetailsModal = () => {
  const user = useSelector(userSelector)
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const route = useRoute<RouteProp<AppStackParamList, 'VideoCallsUpsellDetailsModal'>>()
  const { details, description } = route.params
  const [_, setModalCompleted] = useStorageValue<boolean>(
    `${Storage.VIDEO_CALLS_UPSELL_MODAL_COMPLETED_KEY}_${user?.id}`,
  )

  const markCompleted = () => {
    setModalCompleted(true)
  }

  const onDismiss = () => {
    markCompleted()
    Analytics.track(CustomEventTypes.VideoCallsUpsellModalDismissed, { userId: user?.id })
    goBack()
  }

  const onScheduleCallPress = () => {
    markCompleted()
    Analytics.track(CustomEventTypes.VideoCallsUpsellModalScheduleCallClicked, { userId: user?.id })
    navigation.replace('Questionnaire', {
      questionnaire: SurveysConfigKind.Insurance,
      skipIntroScreen: true,
    })
  }

  return (
    <BottomSheet
      primaryButton={{ text: 'Schedule Call', onPress: onScheduleCallPress }}
      secondaryButton={{ text: 'Dismiss', onPress: onDismiss }}
      onClose={markCompleted}
      buttonDirection="column-reverse"
    >
      <Icon name="gift" style={styles.giftIcon} weight="duotone" />
      <Text style={styles.content} type="title-2">
        Insurance Covered Video Call
      </Text>
      <Text style={styles.content} type="regular">
        {description.body}
        <Text type="regular" bold>
          {description.emphasis}
        </Text>
      </Text>
      {details.map((detail, index) => (
        <View key={index} style={styles.detailContainer}>
          <Icon name="check" style={styles.checkIcon} weight="bold" />
          <Text style={styles.detailContent} type="regular">
            <Text type="regular" bold>
              {detail.title}
            </Text>
            {detail.body}
          </Text>
        </View>
      ))}
      <InlineAlert
        category={UiInlineAlert.Neutral}
        style={styles.info}
        message="If you are not sure now,  you can always start your flow in nutritionist tab later."
      />
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  giftIcon: {
    alignSelf: 'center',
    width: 48,
    height: 48,
    color: 'theme.accent.citron',
    marginTop: 16,
    marginBottom: 16,
  },
  checkIcon: {
    width: 16,
    height: 16,
    color: 'theme.success.base',
    marginHorizontal: 8,
    marginTop: 10,
  },
  content: {
    textAlign: 'center',
    margin: 8,
  },
  detailContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  detailContent: {
    margin: 8,
    paddingRight: 16,
  },
  info: {
    marginTop: 32,
    marginBottom: 16,
  },
})
