import React from 'react'
import { TouchableOpacity } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { PromotionCode } from '@src/types'

export interface DiscountOfferProps {
  code: PromotionCode | null
  onEditPromotionCode: () => void
}

export const DiscountOffer = (props: DiscountOfferProps) => {
  const styles = useStyleSheet(themedStyle)
  const { code, onEditPromotionCode } = props

  return (
    <TouchableOpacity
      accessibilityLabel="Discount code"
      activeOpacity={0.7}
      style={styles.container}
      onPress={onEditPromotionCode}
    >
      <Text type="regular">Discount code</Text>
      <Text type="regular" bold style={styles.actionText}>
        {code?.code ?? 'Add code'}
      </Text>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 64,
    marginHorizontal: 16,
  },
  actionText: {
    color: 'theme.text.link',
  },
})
