import React, { useEffect, useState } from 'react'
import { View, TouchableOpacity, ScrollView } from 'react-native'
import { useSelector } from 'react-redux'
import { map, Dictionary, get } from 'lodash'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Haptic } from '@utils'
import { clientConfigStoreStateSelector } from '@src/selectors/app'
import { Calendar } from '@src/models/app.types'
import { Text } from '@components/base'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_FORMAT, TODAY, YESTERDAY } from '../config/momentFormat'

export interface DaysTabBarProps<T> {
  onDayTabChange: (days: T) => void
  selectedTab: string
  calendar: Calendar
  customTabs?: Dictionary<string>
  hideLeftPadding?: boolean
}

const defaultDayTabs = {
  '0': 'Cal',
  '7': '7 days',
  '14': '14 days',
  '30': '30 days',
  '60': '60 days',
  '120': '120 days',
}

export const DaysTabBar = <T extends string>(props: DaysTabBarProps<T>) => {
  const styles = useStyleSheet(themedStyle)
  const { onDayTabChange, selectedTab, calendar, customTabs, hideLeftPadding = false } = props

  const { dayTabs } = useSelector(clientConfigStoreStateSelector)

  const tabs = customTabs || dayTabs || defaultDayTabs

  const [tab, setTab] = useState<string>(selectedTab)

  useEffect(() => {
    setTab(selectedTab)
  }, [selectedTab])

  const handleRoutePress = (days: string) => () => {
    setTab(days)
    Haptic.heavyTap()
    onDayTabChange((days as unknown) as T)
  }

  const range = (days: string) => {
    const endDate = moment(get(calendar, 'endDate'), DATE_FORMAT)
    let startDate = moment(get(calendar, 'startDate'), DATE_FORMAT)

    if (Number(days) > 0) {
      startDate = endDate.clone().subtract(Number(days) - 1, 'days') // adjust to be consistent with the day tab values
    }

    return {
      startDate,
      endDate,
    }
  }

  const getStartDateLabel = () => {
    const startDate = moment(get(calendar, 'startDate'))
    if (startDate.diff(moment(), 'days') === 0) {
      return TODAY
    }
    if (startDate.diff(moment(), 'days') === -1) {
      return YESTERDAY
    }
    return startDate.format(MONTH_NAME_AND_DATE_FORMAT)
  }

  const hasCustomLabel =
    getStartDateLabel() !== moment(get(calendar, 'startDate')).format(MONTH_NAME_AND_DATE_FORMAT)

  const renderTab = (route: string, index: number) => {
    const tabIndexes = Object.keys(tabs)
    const routeTabIndex = tabIndexes[index]
    const isActiveTab = tab === routeTabIndex
    const { startDate, endDate } = range(routeTabIndex)

    const formattedStartDate = startDate.format(MONTH_NAME_AND_DATE_FORMAT)
    const formattedEndDate = endDate.format(MONTH_NAME_AND_DATE_FORMAT)

    const multiDays = formattedStartDate !== formattedEndDate
    const hasSubtitle = multiDays || hasCustomLabel

    return (
      <TouchableOpacity
        key={route}
        onPress={handleRoutePress(routeTabIndex)}
        style={[styles.tab, isActiveTab ? styles.activeTab : styles.defaultTab]}
        accessibilityLabel={route}
      >
        {route === defaultDayTabs['0'] ? (
          <>
            <Text type="regular" bold style={isActiveTab && styles.activeTabLabel}>
              {multiDays ? `${endDate.diff(startDate, 'd') + 1} days` : getStartDateLabel()}
            </Text>

            {hasSubtitle && (
              <Text type="tiny" style={isActiveTab && styles.activeSubtitleLabel}>
                {multiDays ? `${formattedStartDate} - ${formattedEndDate}` : formattedStartDate}
              </Text>
            )}
          </>
        ) : (
          <>
            <Text type="regular" bold style={isActiveTab && styles.activeTabLabel}>
              {route}
            </Text>
            <Text type="tiny" style={isActiveTab && styles.activeSubtitleLabel}>
              {`${formattedStartDate} - ${formattedEndDate}`}
            </Text>
          </>
        )}
      </TouchableOpacity>
    )
  }

  return (
    <ScrollView showsHorizontalScrollIndicator={false} horizontal>
      <View style={[styles.tabBar, hideLeftPadding && styles.tabBarHideLeftPadding]}>
        {map(Object.values(tabs), renderTab)}
      </View>
    </ScrollView>
  )
}

const themedStyle = StyleService.create({
  tabBar: {
    flex: 1,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    height: 64,
  },
  tabBarHideLeftPadding: {
    paddingLeft: 0,
  },
  tab: {
    height: 48,
    borderRadius: 32,
    paddingHorizontal: 20,
    marginHorizontal: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultTab: {
    backgroundColor: 'theme.surface.base2',
  },
  activeTab: {
    backgroundColor: 'theme.primary.base',
  },
  activeTabLabel: {
    color: 'theme.solid.white',
  },
  activeSubtitleLabel: {
    color: 'theme.text.inColor',
  },
})
