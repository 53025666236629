import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Pill, Text } from '@components/base'

export const Title = () => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.container}>
      <Icon name="sparkle" />
      <Text type="large" bold style={styles.text}>
        Nora AI
      </Text>
      <Pill fill="citron" size="s" text="Beta" />
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    marginLeft: 4,
    marginRight: 8,
  },
})
