import React, { useEffect, ReactNode } from 'react'
import { View, ViewStyle, KeyboardAvoidingView, StyleProp } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useNavigation } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { Device } from '@config'
import { Button } from '@components/base'
import { useGoBack } from '@src/utils/navigation'

export interface GestureDismissibleModalProps {
  containerStyle?: ViewStyle
  contentContainerStyle?: StyleProp<ViewStyle>
  dismissButtonText?: string
  onDismiss?: () => void
  children?: ReactNode
  useKeyboardAvoidingView?: boolean
}

export const GestureDismissibleModal = ({
  children,
  containerStyle,
  contentContainerStyle,
  onDismiss,
  dismissButtonText,
  useKeyboardAvoidingView = true,
}: GestureDismissibleModalProps) => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const headerMarginBottom = dismissButtonText ? undefined : { marginBottom: 32 }

  useEffect(() => {
    return navigation.addListener('gestureEnd', () => {
      onDismiss?.()
    })
  }, [navigation, onDismiss])

  const renderContent = () => {
    return (
      <>
        <View style={[styles.headerContainer, headerMarginBottom]}>
          <View style={styles.handle} />
          {dismissButtonText && (
            <Button
              type="transparent"
              size="small"
              style={styles.dismissButton}
              accessibilityLabel="Dismiss"
              onPress={goBack}
            >
              {dismissButtonText}
            </Button>
          )}
        </View>
        <View style={[styles.content, contentContainerStyle]}>{children}</View>
      </>
    )
  }

  if (useKeyboardAvoidingView) {
    return (
      <KeyboardAvoidingView
        behavior={Device.ios ? 'padding' : undefined}
        style={[styles.container, containerStyle]}
      >
        {renderContent()}
      </KeyboardAvoidingView>
    )
  }

  return <View style={[styles.container, containerStyle]}>{renderContent()}</View>
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    marginTop: 64,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    backgroundColor: 'theme.background.modal',
  },
  headerContainer: {
    alignItems: 'center',
    marginTop: 10,
  },
  handle: {
    height: 5,
    width: 135,
    borderRadius: 2.5,
    backgroundColor: 'theme.surface.base0',
  },
  dismissButton: {
    marginLeft: 'auto',
  },
  content: {
    flex: 1,
    width: '100%',
  },
})
