import React, { useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { SensorStatus, SensorTypes } from 'react-native-freestyle-libre'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { Feature, GestureDismissibleModal, useFeatureFlag } from '@src/components'
import { useGoBack } from '@src/utils/navigation'
import { sensorSelector } from '@src/selectors/sensor'
import { AppStackParamList } from '@src/navigation/types'
import NsSensor from '@src/assets/svgs/permissions/nsSensor'
import { useSecondsAfterSensorActivation } from '../hooks/useSecondsAfterSensorActivation'
import { sensorIsWarmingUp } from '../utils/utils'
import {
  LIBRE1_SENSOR_WARM_UP_PERIOD_IN_SECONDS,
  LIBRE3_SENSOR_WARM_UP_PERIOD_IN_SECONDS,
  LIBRE3_LIVE_WARM_UP_PERIOD_IN_SECONDS,
} from '../constants'

const formatTime = (time: number) => {
  // prepend leading 0 if necessary
  if (time < 10) {
    return `0${time}`
  }

  return `${time}`
}

export const SensorWarmUpModal = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const sensor = useSelector(sensorSelector)
  const libre3RealTime = useFeatureFlag(Feature.Libre3RealTime)

  const secondsAfterSensorActivation = useSecondsAfterSensorActivation({
    updateCondition: sensorIsWarmingUp,
  })

  const shouldShowWarmUpModal =
    secondsAfterSensorActivation !== undefined &&
    sensorIsWarmingUp({
      secondsAfterSensorActivation,
      sensorModel: sensor?.model as SensorTypes | undefined,
      liveDataEnabled: libre3RealTime,
    }) &&
    sensor?.status !== SensorStatus.Ready

  useEffect(() => {
    if (shouldShowWarmUpModal) {
      return
    }

    goBack()
  }, [goBack, shouldShowWarmUpModal])

  if (!shouldShowWarmUpModal) {
    return null
  }

  const currentSensorIsLibre3 = sensor?.model === SensorTypes.Libre3
  const libre3WarmupTime = libre3RealTime
    ? LIBRE3_LIVE_WARM_UP_PERIOD_IN_SECONDS
    : LIBRE3_SENSOR_WARM_UP_PERIOD_IN_SECONDS

  const sensorWarmUpPeriodInSeconds = currentSensorIsLibre3
    ? libre3WarmupTime
    : LIBRE1_SENSOR_WARM_UP_PERIOD_IN_SECONDS

  const totalSecondsLeft = sensorWarmUpPeriodInSeconds - secondsAfterSensorActivation
  const hoursLeft = Math.floor(totalSecondsLeft / 60 / 60)
  const minutesLeft = Math.floor(totalSecondsLeft / 60) % 60
  const secondsLeft = totalSecondsLeft % 60

  const description = currentSensorIsLibre3
    ? 'Your sensor is warming up. Your glucose level data will be available soon. ' +
      'Set your preferred glucose range to get personalized glucose insights tailored just for you. ' +
      'Please keep your phone within range of your sensor (30 feet)'
    : 'Your sensor is warming up. Your glucose level data will be available soon. ' +
      'Set your preferred glucose range to get personalized glucose insights tailored just for you.'

  const onPersonalizeGlucosePress = () => {
    navigation.replace('GlucoseThresholdSettings', { source: 'SensorWarmUpModal' })
  }

  return (
    <GestureDismissibleModal containerStyle={styles.container}>
      <SafeAreaView edges={['bottom']} style={styles.contentContainer}>
        <View style={styles.content}>
          <NsSensor />
          <View>
            <Text type="title-2" style={styles.title}>
              Your sensor is warming up.
            </Text>
            <Text type="large" bold style={styles.timer}>
              {formatTime(hoursLeft)}:{formatTime(minutesLeft)}:{formatTime(secondsLeft)}
            </Text>
            <Text type="regular" style={styles.description}>
              {description}
            </Text>
          </View>
        </View>
        <View>
          <Button
            type="primary"
            size="block"
            accessibilityLabel="Personalize Glucose Range"
            onPress={onPersonalizeGlucosePress}
          >
            Personalize Glucose Range
          </Button>
          <Button
            type="transparent"
            size="block"
            style={styles.secondaryButton}
            accessibilityLabel="Got it!"
            onPress={goBack}
          >
            Got it!
          </Button>
        </View>
      </SafeAreaView>
    </GestureDismissibleModal>
  )
}

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  content: {
    flex: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
  },
  timer: {
    marginTop: 16,
    textAlign: 'center',
    color: 'theme.info.base',
  },
  description: {
    marginTop: 24,
    textAlign: 'center',
  },
  secondaryButton: {
    marginTop: 8,
  },
})
