import React from 'react'
import { StyleProp, TouchableWithoutFeedback, View, ViewStyle } from 'react-native'
import { capitalize } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Input } from '@components/base'
import { useApplicationActionSheet } from '@src/hooks/useApplicationActionSheet'
import { validateFloatNumberInput } from '@src/utils/validators'

interface IngredientPortionFormProps {
  autoFocus?: boolean
  serving: string
  servingOptions: string[]
  showLabels?: boolean
  style?: StyleProp<ViewStyle>
  quantity: string
  onServingChange: (newServing: string) => void
  onQuantityChange: (newQuantity: string) => void
}

export const IngredientPortionForm = ({
  autoFocus,
  serving,
  servingOptions,
  showLabels,
  style,
  quantity,
  onServingChange,
  onQuantityChange,
}: IngredientPortionFormProps) => {
  const styles = useStyleSheet(themedStyle)

  const { showActionSheet } = useApplicationActionSheet()

  const handleQuantityChange = (text: string) => {
    if (!text) {
      onQuantityChange('')
      return
    }

    const newQuantity = validateFloatNumberInput(text)

    if (newQuantity) {
      onQuantityChange(newQuantity)
    }
  }

  return (
    <View style={[style, styles.portionDetails]}>
      <Input
        accessibilityLabel="Quantity"
        autoFocus={autoFocus}
        iconLeft="calculator"
        keyboardType="decimal-pad"
        label={showLabels ? 'Quantity' : undefined}
        maxLength={4}
        onChangeText={handleQuantityChange}
        returnKeyType="done"
        style={styles.quantityInput}
        value={quantity}
      />
      <TouchableWithoutFeedback
        accessibilityLabel="Select measurement"
        onPress={() =>
          showActionSheet({
            title: 'Unit of measure',
            options: servingOptions,
            callback: onServingChange,
          })
        }
      >
        <View style={styles.unitSelect}>
          <Input
            iconRight="caret-down"
            label={showLabels ? 'Unit of Measure' : undefined}
            value={capitalize(serving)}
          />
          <View style={styles.selectOverlay} />
        </View>
      </TouchableWithoutFeedback>
    </View>
  )
}

const themedStyle = StyleService.create({
  portionDetails: {
    flexDirection: 'row',
  },
  quantityInput: {
    flex: 2,
    marginRight: 16,
  },
  unitSelect: {
    flex: 5,
  },
  selectOverlay: {
    position: 'absolute',
    zIndex: 1,
    width: '100%',
    height: '100%',
  },
})
