import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationBar, NavigationBarProps } from '@src/components/navigationBar/NavigationBar'

export const TransparentNavigationBar = (props: NavigationBarProps) => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  return (
    <NavigationBar
      titleStyle={[styles.text, !navigation.canGoBack() && styles.noBackIcon]}
      backgroundColor="transparent"
      {...props}
    />
  )
}

const themedStyle = StyleService.create({
  text: {
    alignSelf: 'flex-start',
    marginLeft: 40,
  },
  noBackIcon: {
    marginLeft: 10,
  },
})
