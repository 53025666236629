import { gql } from '@apollo/client'

export const SCHEDULE_REMINDER = gql`
  mutation scheduleReminder($kind: NotificationEngineReminderKind!, $sendOn: ISO8601Date!) {
    scheduleReminder(kind: $kind, sendOn: $sendOn) {
      id
      kind
      frequency
      dayOfWeek
      timeOfDay
      hoursInterval
      sendOn
    }
  }
`
