import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconButton, Text } from '@components/base'
import { User } from '@src/utils'
import { MobileAppFeature } from '@src/types'
import { PopoverMenu } from './PopoverMenu'

const iconButtonProps = {
  size: 's',
  type: 'transparent',
  shape: 'square',
} as const

export interface TimelineHeaderProps {
  title: string
  charts: string[]
  testID?: string
  selectedChartIndex: number
  onSelectChartIndex: (index: number) => void
  onExpandPress: () => void
  onInfoPress: () => void
  onSettingsPress: () => void
}

export const TimelineHeader = ({
  title,
  charts,
  selectedChartIndex,
  onSelectChartIndex,
  onExpandPress,
  onInfoPress,
  onSettingsPress,
}: TimelineHeaderProps) => {
  const styles = useStyleSheet(themedStyle)
  const [isChartMenuVisible, setChartMenuVisible] = useState(false)

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  const renderTitle = (isActive = false) => (
    <Text
      type="regular"
      bold
      lineSpacing="none"
      style={[styles.title, isActive && styles.activeOpacity]}
    >
      {title}
    </Text>
  )

  const renderChartSelectionButton = () => (
    <TouchableOpacity
      style={styles.chartSelectionButton}
      accessibilityLabel={`View ${title} Chart`}
      onPress={() => setChartMenuVisible(true)}
    >
      {renderTitle(isChartMenuVisible)}
      <Icon
        name="caret-down"
        style={[styles.chartSelectionButtonIcon, isChartMenuVisible && styles.activeOpacity]}
      />
    </TouchableOpacity>
  )

  const handleSelectChartIndex = (index: number) => {
    onSelectChartIndex(index)
    setChartMenuVisible(false)
  }

  return (
    <View style={styles.container}>
      <View style={styles.accessories}>
        <IconButton
          {...iconButtonProps}
          iconName="info"
          onPress={onInfoPress}
          accessibilityLabel="Chart Info"
        />
        {charts.length === 1 ? (
          renderTitle()
        ) : (
          <PopoverMenu
            anchor={renderChartSelectionButton}
            items={charts}
            visible={isChartMenuVisible}
            selectedIndex={selectedChartIndex}
            onSelectIndex={handleSelectChartIndex}
            onDismiss={() => setChartMenuVisible(false)}
            style={styles.chartSelectionMenu}
          />
        )}
      </View>
      <View style={styles.accessories}>
        <IconButton
          {...iconButtonProps}
          iconName="corners-out"
          onPress={onExpandPress}
          disabled={!isCGMFeatureAvailable}
          accessibilityLabel="Full Screen Chart"
        />
        <IconButton
          {...iconButtonProps}
          iconName="gear"
          disabled={!isCGMFeatureAvailable}
          onPress={onSettingsPress}
          accessibilityLabel="Chart Settings"
        />
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 16,
    paddingHorizontal: 8,
  },
  accessories: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    marginTop: 2,
    marginHorizontal: 8,
  },
  activeOpacity: {
    opacity: 0.5,
  },
  chartSelectionButton: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 4,
    paddingRight: 8,
    height: 32,
    width: 150,
  },
  chartSelectionButtonIcon: {
    height: 16,
    width: 16,
  },
  chartSelectionMenu: {
    width: 150,
  },
})
