import { gql } from '@apollo/client'

export const UPSERT_DEXCOM_CONNECTION = gql`
  mutation upsertDexcomConnection($authorizationCode: String!) {
    upsertDexcomConnection(authorizationCode: $authorizationCode) {
      status
      lastSyncedAt
    }
  }
`
