import React, { useEffect } from 'react'
import { ScrollView } from 'react-native'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { useSnack } from '@src/utils'
import { Analytics, CustomEventTypes, ErrorMessages } from '@src/config'
import {
  clientConfigStoreStateSelector,
  measurementTypeDefLookupSelector,
} from '@src/selectors/app'
import { HealthMetric, HealthMetricsKind } from '@src/types'
import { Measurement } from '@src/screens/Events/models/events.types'
import { useUnitSystemMeasurements } from '@src/screens/Measurements/hooks'
import { NavigationContainer } from '@src/screens/Common/containers'
import { healthMetricsSelector } from '../models/labResults.selectors'
import { BiomarkerCard } from './BiomarkerCard'
import { MeasurementCard } from './MeasurementCard'
import { NoLabResultsProvidedComponent } from './NoLabResultsProvidedComponent'

export const LabResultsScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const healthMetrics = useSelector(healthMetricsSelector)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const isFocused = useIsFocused()
  const showSnack = useSnack()
  const dispatch = useDispatch()
  const clientConfig = useSelector(clientConfigStoreStateSelector)
  const healthMetricsConfig = get(clientConfig, 'healthMetrics', [])
  const measurementTypesDefinitions = useSelector(measurementTypeDefLookupSelector)
  const measurementUnitConfig = useUnitSystemMeasurements()

  const biomarkers = healthMetricsConfig.flatMap((config) => {
    const biomarkerData =
      healthMetrics?.healthMetrics.filter(
        (healthMetric: HealthMetric) =>
          config.kind === HealthMetricsKind.Biomarker &&
          healthMetric.kind === config.key.toString(),
      ) || []

    if (biomarkerData.length === 0) {
      return []
    }

    return {
      data: biomarkerData,
      title: config.label,
      unit: config.unit || undefined,
      metricKey: config.key,
    }
  })

  const measurementTypes = new Set(
    healthMetrics?.measurements.map((measurement) => measurement.title),
  )

  const measurements = Array.from(measurementTypes).flatMap((type) => {
    const measurementTypeDefinition = Object.values(measurementTypesDefinitions).find(
      (measurement) => measurement.label === type,
    )

    if (!measurementTypeDefinition) {
      return []
    }

    const measurementType = measurementTypeDefinition.key

    const measurementTypeData =
      healthMetrics?.measurements.filter((measurement) => measurement.title === type) || []

    if (measurementTypeData.length === 0) {
      return []
    }

    const metricKey = healthMetricsConfig.find(
      (config) => config.key === measurementType.toString(),
    )

    if (!metricKey) {
      return []
    }

    return {
      type: measurementType,
      title: measurementTypeDefinition?.label,
      data: measurementTypeData as Measurement[],
      metricKey: metricKey.key,
      unit: measurementUnitConfig[measurementType]?.units,
    }
  })

  useEffect(() => {
    if (!isFocused) {
      return
    }

    dispatch({
      type: 'labResults/fetchHealthMetrics',
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
    })
  }, [dispatch, showSnack, isFocused])

  const onAddLabResultsPress = () => {
    Analytics.track(CustomEventTypes.OutcomesTrackingAddLabResultsTapped)
    navigation.navigate('AddLabResults')
  }

  return (
    <NavigationContainer
      title="Lab Results"
      style={styles.container}
      rightAccessories={[
        {
          iconDescriptor: { pack: 'eva', name: 'plus-circle-outline' },
          onPress: onAddLabResultsPress,
        },
      ]}
    >
      {biomarkers.length === 0 && measurements.length === 0 ? (
        <NoLabResultsProvidedComponent onShareLabResultsPress={onAddLabResultsPress} />
      ) : (
        <ScrollView>
          {biomarkers.map((biomarker) => (
            <BiomarkerCard key={biomarker.metricKey} {...biomarker} />
          ))}

          {measurements.map((measurement) => (
            <MeasurementCard key={measurement.metricKey} {...measurement} />
          ))}
        </ScrollView>
      )}
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
    padding: 16,
  },
})
