import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { TouchableSection } from '@components'

const SystemSection = () => {
  const navigation = useNavigation()

  const onPress = () => {
    navigation.navigate('UserSettings')
  }

  return (
    <TouchableSection
      onPress={onPress}
      iconName="faders-horizontal"
      title="System"
      showRightArrow
    />
  )
}

export default SystemSection
