import React from 'react'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, ButtonProps, Icon, IconName } from '@components/base'

interface PillButtonProps extends Omit<ButtonProps, 'children' | 'type' | 'size'> {
  icon: IconName
  text: string
}

export const PillButton = ({
  icon,
  style,
  text,
  onPress,
  accessibilityLabel,
  ...rest
}: PillButtonProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <Button
      type="outline"
      size="small"
      accessibilityLabel={accessibilityLabel}
      accessoryLeft={<Icon name={icon} style={styles.icon} />}
      style={[styles.button, style]}
      onPress={onPress}
      {...rest}
    >
      {text}
    </Button>
  )
}

const themedStyle = StyleService.create({
  button: {
    marginRight: 8,
    marginBottom: 16,
  },
  content: {
    marginLeft: 8,
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 8,
  },
})
