import React from 'react'
import { TouchableOpacity } from 'react-native'
import { Text } from '@components/base'
import { InlineAlert } from '@src/components/InlineAlert'
import { UiInlineAlert } from '@src/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useShowStartDexcomPrompts } from '@src/utils/hooks'
import { useStartParticipationBottomSheet } from '@src/screens/Dexcom/Research/hooks/useStartParticipationBottomSheet'

export const DexcomSwitchPopup = () => {
  const hidePopup = !useShowStartDexcomPrompts()
  const styles = useStyleSheet(themedStyles)
  const showBottomSheet = useStartParticipationBottomSheet({ returnHome: false })

  if (hidePopup) {
    return null
  }

  const onPress = () => {
    showBottomSheet()
  }

  return (
    <TouchableOpacity
      accessibilityLabel="Dexcom switch"
      activeOpacity={0.7}
      style={styles.wrapperStyle}
      onPress={onPress}
    >
      <InlineAlert
        style={styles.popup}
        category={UiInlineAlert.Info}
        message="Ready to switch over to your Dexcom G7 sensors?"
      />
      <Text type="regular" bold style={styles.textStyle}>
        Tap here
      </Text>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  wrapperStyle: {
    justifyContent: 'center',
    marginHorizontal: 16,
    marginTop: 8,
  },
  popup: {
    paddingRight: 92,
  },
  textStyle: {
    position: 'absolute',
    right: 16,
    color: 'theme.text.link',
  },
})
