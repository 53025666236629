import QuickActions from 'react-native-quick-actions' /* eslint-disable-line */
import { NativeModules } from 'react-native'
import { Device } from '@config'

const { scheme } = NativeModules.Configuration

const configure = (disableInAppScan: boolean) => {
  const shortcutItems = [
    {
      type: 'Nutritionist',
      title: 'Ask Nutritionist',
      icon: Device.ios ? 'Message' : 'message',
      userInfo: {
        url: `${scheme}://app/modals/newMessage`,
      },
    },
    {
      type: 'Activities',
      title: 'Log Activity',
      icon: Device.ios ? 'Compose' : 'note',
      userInfo: {
        url: `${scheme}://app/modals/newActivity`,
      },
    },
    {
      type: 'Meals',
      title: 'Log Meal',
      icon: Device.ios ? 'CapturePhoto' : 'food',
      userInfo: {
        url: `${scheme}://app/modals/newMeal`,
      },
    },
  ]

  if (disableInAppScan) {
    shortcutItems.push({
      type: 'Sensor',
      title: 'Sensor',
      icon: Device.ios ? 'taskCompleted' : 'circle_dot_solid',
      userInfo: {
        url: `${scheme}://app/settings/sensor`,
      },
    })
  } else {
    shortcutItems.push({
      type: 'Scans', // Required
      title: 'Scan', // Optional, if empty, `type` will be used instead
      // subtitle: '',       // Optional
      icon: Device.ios ? 'Confirmation' : 'check_circle',
      userInfo: {
        url: `${scheme}://app/modals/newScan`, // Provide any custom data like deep linking URL
      },
    })
  }

  QuickActions.setShortcutItems(shortcutItems)
}

export default { configure }
