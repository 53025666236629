import React from 'react'
import { RefCallBack } from 'react-hook-form'
import { KeyboardType } from 'react-native'
import { Input } from '@src/components/base'

interface AddressTextInputProps {
  label: string
  placeholder: string
  value: string
  keyboardType?: KeyboardType
  isInvalid: boolean
  disabled?: boolean
  fieldRef: RefCallBack
  onChange: (text: string) => void
  onBlur?: () => void
}

export const AddressTextInput = ({
  label,
  placeholder,
  value,
  keyboardType,
  isInvalid,
  disabled,
  fieldRef,
  onChange,
  onBlur,
}: AddressTextInputProps) => (
  <Input
    ref={fieldRef}
    disabled={disabled}
    hasError={isInvalid}
    label={label}
    placeholder={placeholder}
    keyboardType={keyboardType}
    value={value}
    onChangeText={onChange}
    onBlur={onBlur}
  />
)
