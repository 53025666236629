import commonReducers from '@src/models/commonReducers'
import { PromotionCode, Subscription } from '@src/types'
import { subscriptionsSelector } from '@src/selectors/app'
import { MarketplaceStoreState } from '@src/screens/Marketplace/types/types'

export const reducers = {
  fetchList: commonReducers.fetchList,

  subscriptionPurchased: (store: MarketplaceStoreState) => ({ ...store, promotionCode: null }),

  updateDiscount: (
    store: MarketplaceStoreState,
    { payload }: { payload: { promotionCode: PromotionCode } },
  ) => {
    const promotionCode = payload?.promotionCode

    if (!promotionCode || !promotionCode.applicable) {
      return {
        ...store,
        promotionCode: null,
      }
    }

    return {
      ...store,
      promotionCode,
    }
  },
}

export function* updatedSubscriptionsPayload(updatedSubscription: Subscription, { select }: any) {
  const subscriptions: Subscription[] = yield select(subscriptionsSelector)

  return {
    subscriptions: [
      ...subscriptions.filter((subscription) => subscription.id !== updatedSubscription.id),
      updatedSubscription,
    ],
  }
}
