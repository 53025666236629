import { AppState, Linking } from 'react-native'
import { ParamListBase, PathConfigMap, getStateFromPath } from '@react-navigation/native'
import { last } from 'lodash'
import { SUPPORT_EMAIL } from '@src/config/constants'
import { Bugsnag } from '@config'
import { navigationRef } from '@src/config/navigation'
import { RootStackParamList } from '@src/navigation/types'

export const openUrl = async (url: string) => {
  // navigationRef is not ready when the app is starting
  if (!navigationRef.isReady()) {
    setTimeout(() => openUrl(url), 100)
    return
  }
  try {
    const canOpenUrl = await Linking.canOpenURL(url)
    canOpenUrl ? Linking.openURL(url) : console.log(`Can't handle url: ${url}`)
  } catch (err: any) {
    console.error('An error occurred', err)
    if (AppState.currentState !== 'background') {
      // avoid sending https://github.com/nutrisense/nutrisense-mobile/issues/3212
      Bugsnag.notify(err)
    }
  }
}

export const getDeepLinkRoute = (
  deepLink: string,
  linkingConfig:
    | {
        initialRouteName?: string | undefined
        screens: PathConfigMap<ParamListBase>
      }
    | undefined,
) => {
  const state = getStateFromPath<RootStackParamList>(deepLink, linkingConfig)
  return last(state?.routes || [])
}

export const contactSupport = async () => {
  await openUrl(`mailto:${SUPPORT_EMAIL}`)
}
