import React from 'react'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { App } from '@src/config'

export interface SkipButtonProps {
  title: string
  onPress: () => void
  testID?: string
}

export const SkipButton = (props: SkipButtonProps) => {
  const styles = useStyleSheet(themedStyle)
  const { title, onPress, testID } = props

  return (
    <Button
      type="transparent"
      size="block"
      testID={testID}
      style={styles.container}
      onPress={onPress}
      accessibilityLabel={title}
    >
      {title + (App.build.production ? '' : ' [Debug]')}
    </Button>
  )
}

const themedStyle = StyleService.create({
  container: {
    marginTop: 8,
  },
})
