import React from 'react'
import { useSelector } from 'react-redux'
import { ActivityIndicator, View, ViewStyle } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { CircleIcon, OutlinedIcon } from '@src/components'
import { topPriorityCoachAssignmentSelector } from '../models/nutritionistHub.selectors'

interface NutritionistPhotoIconProps {
  style?: ViewStyle
}

const PHOTO_SIZE = 104

export const NutritionistPhotoIcon = (props: NutritionistPhotoIconProps) => {
  const styles = useStyleSheet(themedStyle)
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const nutritionistPhoto = coachAssignment?.coach?.coachProfile?.photo

  return nutritionistPhoto ? (
    <View style={props.style}>
      <ImageWithPlaceholder
        style={styles.nutritionistPhoto as ImageStyle}
        source={{ uri: nutritionistPhoto }}
        placeholderComponent={<ActivityIndicator animating size="small" {...styles.spinner} />}
      />
      <CircleIcon
        name="video-camera"
        iconStyle={styles.nutritionistCameraIcon}
        style={styles.videoCamera}
      />
    </View>
  ) : (
    <OutlinedIcon iconName="video-camera" iconStyle={styles.cameraIcon} style={props.style} />
  )
}

const themedStyle = StyleService.create({
  nutritionistPhoto: {
    width: PHOTO_SIZE,
    height: PHOTO_SIZE,
    borderRadius: PHOTO_SIZE / 2,
  },
  nutritionistCameraIcon: {
    color: 'theme.solid.white',
  },
  cameraIcon: {
    color: 'theme.primary.base',
  },
  videoCamera: {
    width: 45,
    height: 45,
    backgroundColor: 'theme.success.base',
    position: 'absolute',
    bottom: -8,
    right: -8,
  },
  spinner: {
    color: 'theme.surface.base',
  },
})
