/* eslint-disable @typescript-eslint/ban-types, prefer-arrow-functions/prefer-arrow-functions */
// modified from https://stackoverflow.com/a/65923825

/**
 * Flattens an object with a nested structure such that the path to the leave nodes become the keys
 *
 * Example:
 * flattenObject({
 *   a: {
 *     b: { c: 'some value' },
 *     c: 2,
 *   },
 *   c: 'some other value',
 *   d: 3,
 * })
 * -> { 'a.b.c': 'some value', 'a.c': 2, c: 'some other value', d: 3 }
 */
function transformData<T extends object>(obj: T, prefix?: string): Flatten<T>
function transformData(obj: any, prefix: string | number): any
function transformData(obj: any, prefix?: string | number): any {
  const newObj = {}
  for (const key in obj) {
    const k = obj[key]
    const fullKey = prefix ? `${prefix}.${key}` : key
    if (typeof k === 'object' && !Array.isArray(k) && k !== null) {
      Object.assign(newObj, transformData(k, fullKey))
    } else {
      Object.assign(newObj, { [fullKey]: _isNull(k) })
    }
  }
  return newObj
}
export const flattenObject = transformData

const _isNull = (value: any) => {
  const res = value === null || undefined ? null : value
  return res
}

type Flatten<T extends object> = object extends T
  ? object
  : {
      [K in keyof T]-?: (
        x: NonNullable<T[K]> extends infer V
          ? V extends object
            ? V extends readonly any[]
              ? Pick<T, K>
              : Flatten<V> extends infer FV
              ? {
                  [P in keyof FV as `${Extract<K, string | number>}.${Extract<
                    P,
                    string | number
                  >}`]: FV[P]
                }
              : never
            : Pick<T, K>
          : never,
      ) => void
    } extends Record<keyof T, (y: infer O) => void>
  ? O extends unknown
    ? { [K in keyof O]: O[K] }
    : never
  : never
