import { AppRegistry } from 'react-native'
import { name as appName } from '../app.json'
import App from '../src/App'
import './main.css'

AppRegistry.registerComponent(appName, () => App)

AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: global.document.getElementById('app-root'),
})
