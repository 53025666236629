import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIsFocused } from '@react-navigation/native'
import {
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  NoteDynamicFilterField,
  NoteKind,
  NoteSortField,
  SortDirection,
} from '@src/types'
import { userSelector } from '@src/selectors/app'

export const useAllMemberNotes = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelector)
  const isFocused = useIsFocused()

  useEffect(() => {
    if (!isFocused) {
      return
    }
    dispatch({
      type: 'nutritionistHub/fetchMemberNotes',
      payload: {
        sorts: [
          {
            direction: SortDirection.Desc,
            field: NoteSortField.OccurredAt,
          },
        ],
        dynamicFilters: {
          items: [
            {
              field: NoteDynamicFilterField.Kind,
              operation: DynamicFilterItemOperation.Eq,
              value: NoteKind.MemberNote,
            },
          ],
          operator: DynamicFilterOperator.And,
        },
      },
    })
  }, [dispatch, isFocused, user])
}
