import moment from 'moment'
import { get, uniqBy } from 'lodash'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { Calendar } from './app.types'

export const calculateRangeDays = (startDate: string, endDate: string) => {
  return moment(endDate).diff(moment(startDate), 'days') + 1
}

export const initCalendar = () => ({
  startDate: moment().format(DATE_FORMAT),
  endDate: moment().format(DATE_FORMAT),
  rangeDays: 1,
  calendarData: [],
})

export type DateRange = { startDate: string; endDate: string }

export const validateDateRange = (range: DateRange) => validateDateRangeBase(range)

const validateDateRangeBase = ({ startDate, endDate }: DateRange, amountOfDays = 120) => {
  const diff = moment(endDate).diff(moment(startDate), 'days')
  const error = (diff > amountOfDays && `Range must be ${amountOfDays} days or fewer!`) || ''

  return { valid: !error, error }
}

export const processDateRangePayload = (payload: DateRange) => {
  const { startDate, endDate } = payload
  const rangeDays = calculateRangeDays(startDate, endDate)
  return {
    ...payload,
    rangeDays,
  }
}

export const todayDateRangePayload = () => ({
  startDate: moment().format(DATE_FORMAT),
  endDate: moment().format(DATE_FORMAT),
  rangeDays: 1,
})

export const processCalendarData = (calendarSelector: any) => {
  return function* (response: any, { select }: any) {
    const calendar: Calendar = yield select(calendarSelector)
    const calendarData = uniqBy(
      [...get(response, 'calendar.dates', ...calendar.calendarData, [])],
      'date',
    )

    return {
      calendarData,
    }
  }
}
