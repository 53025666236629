import React, { useState } from 'react'
import { LayoutChangeEvent, StyleProp, View, ViewStyle } from 'react-native'
import { pie, arc } from 'd3-shape'
import Svg, { G, Path } from 'react-native-svg'

interface ProgressCircleProps {
  style: StyleProp<ViewStyle>
  progress: number
  progressColor: string
  backgroundColor: string
  strokeWidth: number
}

export const ProgressCircle = (props: ProgressCircleProps) => {
  const { style, progress, progressColor, backgroundColor, strokeWidth } = props

  const [dimensions, setDimensions] = useState({ height: 0, width: 0 })

  const onLayout = (event: LayoutChangeEvent) => {
    const {
      nativeEvent: {
        layout: { height, width },
      },
    } = event
    setDimensions({ height, width })
  }

  const outerDiameter = Math.min(dimensions.width, dimensions.height)

  const data = [
    {
      key: 'rest',
      value: 1 - progress,
      color: backgroundColor,
    },
    {
      key: 'progress',
      value: progress,
      color: progressColor,
    },
  ]

  const startAngle = 0
  const endAngle = Math.PI * 2
  const cornerRadius = 45

  const pieSlices = pie()
    .value((d: any) => d.value)
    .sort((a: any) => (a.key === 'rest' ? 1 : -1))
    .startAngle(startAngle)
    .endAngle(endAngle)(data as any)

  const arcs = pieSlices.map((slice, index) => ({
    ...data[index],
    ...slice,
    path: arc().cornerRadius(cornerRadius)({
      outerRadius: outerDiameter / 2,
      innerRadius: outerDiameter / 2 - strokeWidth,
      startAngle: index === 0 ? startAngle : slice.startAngle,
      endAngle: index === 0 ? endAngle : slice.endAngle,
    }),
  }))

  return (
    <View style={style} onLayout={onLayout}>
      {dimensions.height > 0 && dimensions.width > 0 && (
        <Svg style={{ height: dimensions.height, width: dimensions.width }}>
          <G x={dimensions.width / 2} y={dimensions.height / 2}>
            {arcs.map((shape, index) => (
              <Path key={index} fill={shape.color} d={shape.path || undefined} />
            ))}
          </G>
        </Svg>
      )}
    </View>
  )
}
