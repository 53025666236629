import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Icon } from '@components/base'
import { SurveysConfigKind } from '@src/types'
import { Debug } from '@src/config'
import { AppStackParamList } from '@src/navigation/types'
import { SkipButton } from '../SkipButton'
import { useApprovalFlow, ApprovalFlowStep } from './useApprovalFlow'

export const AlmostThere = () => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const insets = useSafeAreaInsets()
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const { approvalStep, isPrescriptionRequired, isResearchRequired } = useApprovalFlow()

  const hasMultipleSteps = isPrescriptionRequired && isResearchRequired
  const introText = hasMultipleSteps
    ? 'Your CGMs will not ship until the following are complete:'
    : 'Your CGMs will not ship until the following is complete:'

  const onNextStepPress = () => {
    switch (approvalStep) {
      case ApprovalFlowStep.CgmPrerequisiteSurvey:
        return navigation.replace('Questionnaire', {
          questionnaire: SurveysConfigKind.CgmPrerequisite,
        })
      case ApprovalFlowStep.ResearchEligibilitySurvey:
        return navigation.replace('Questionnaire', {
          questionnaire: SurveysConfigKind.MedicalHistory,
        })
      case ApprovalFlowStep.ResearchConsentSurvey:
        return navigation.replace('Questionnaire', {
          questionnaire: SurveysConfigKind.DexcomResearchConsent,
        })
      case ApprovalFlowStep.PrescriptionEligibilitySurvey:
      default:
        navigation.replace('Questionnaire', { questionnaire: SurveysConfigKind.Health })
    }
  }

  const onSkipPress = () => {
    dispatch({
      type: 'app/skipSurvey',
      payload: SurveysConfigKind.Health,
    })
  }

  return (
    <View
      style={[styles.container, { paddingTop: insets.top + 20, paddingBottom: insets.bottom + 16 }]}
    >
      <View style={styles.contentContainer}>
        <Icon name="warning" weight="duotone" style={styles.icon} />
        <Text type="title-2" style={styles.title}>
          Payment successful.{'\n'}Finish the next {hasMultipleSteps ? 'steps' : 'step'} to complete
          your order.
        </Text>
        <Text type="regular" style={styles.paragraph} bold>
          {introText}
        </Text>
        {isPrescriptionRequired && (
          <View style={styles.paragraph}>
            <Text type="regular" bold style={[styles.stepText, styles.stepTitle]}>
              {hasMultipleSteps ? 'Step 1: ' : ''}Health Questionnaire
            </Text>
            <Text type="regular" style={styles.stepText}>
              This will be shared securely with an evaluating physician to provide a CGM
              prescription.
            </Text>
          </View>
        )}
        {isResearchRequired && (
          <View style={styles.paragraph}>
            <Text type="regular" bold style={[styles.stepText, styles.stepTitle]}>
              {hasMultipleSteps ? 'Step 2: ' : ''}Optional Research Enrollment
            </Text>
            <Text type="regular" style={styles.stepText}>
              Enroll to use the Dexcom G7 CGM.{'\n'}You can opt out at any time.
            </Text>
          </View>
        )}
      </View>
      <View style={styles.actionsContainer}>
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Get Started"
          onPress={onNextStepPress}
        >
          Get Started
        </Button>
        {Debug.allowSkipQuestionnaire && <SkipButton title="Not now" onPress={onSkipPress} />}
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
    justifyContent: 'space-between',
  },
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionsContainer: {
    flexShrink: 0,
  },
  icon: {
    width: 48,
    height: 48,
    color: 'theme.accent.brick',
  },
  title: {
    marginTop: 16,
    marginBottom: 32,
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: 24,
    textAlign: 'center',
  },
  stepText: {
    textAlign: 'center',
  },
  stepTitle: {
    color: 'theme.info.base',
  },
})
