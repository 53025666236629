import { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { merge } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useHasLargeScreen, useHasSmallScreen } from '@src/config/device'

type NamedStyles<T> = StyleSheet.NamedStyles<T>
const emptyStyles = StyleService.create({})

interface UseResponsiveStyleSheetProps<S, SS, LS> {
  styles: NamedStyles<S>
  smallScreenStyles?: NamedStyles<SS>
  largeScreenStyles?: NamedStyles<LS>
}

export const useResponsiveStyleSheet = <S, SS, LS>(
  props: UseResponsiveStyleSheetProps<S, SS, LS>,
): NamedStyles<S> => {
  const hasSmallScreen = useHasSmallScreen()
  const hasLargeScreen = useHasLargeScreen()

  const styles = useStyleSheet(props.styles)
  const smallScreenStyles = useStyleSheet(props.smallScreenStyles || emptyStyles)
  const largeScreenStyles = useStyleSheet(props.largeScreenStyles || emptyStyles)

  const resultStyle = useMemo(() => {
    return merge(
      styles,
      hasSmallScreen ? smallScreenStyles : emptyStyles,
      hasLargeScreen ? largeScreenStyles : emptyStyles,
    ) as NamedStyles<S>
  }, [hasLargeScreen, hasSmallScreen, largeScreenStyles, smallScreenStyles, styles])

  return resultStyle
}
