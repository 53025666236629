import React from 'react'
import { TouchableOpacity, ViewStyle, TextStyle, View, StyleProp } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon as OldIcon, IconDescriptor } from '@components'
import { IconName, Icon } from '@components/base'
import { Text } from '@components/base'

interface TouchableSectionProps {
  title: string
  labelStyle?: TextStyle
  style?: StyleProp<ViewStyle>
  /** @deprecated Use `iconName` instead */
  iconContainerStyle?: StyleProp<ViewStyle>
  /** @deprecated Use `iconName` instead */
  iconDescriptor?: IconDescriptor
  /** @deprecated Use `iconName` instead */
  iconSolid?: boolean
  /** @deprecated Use `iconName` instead */
  iconStyle?: StyleProp<ViewStyle>
  iconName?: IconName
  tagComponent?: React.ReactNode
  accessoryLeft?: React.ReactNode
  showRightArrow?: boolean
  children?: React.ReactNode
  onPress?: () => void
  disabled?: boolean
}

export const TouchableSection = (props: TouchableSectionProps) => {
  const {
    style,
    iconDescriptor,
    iconStyle,
    labelStyle,
    iconSolid,
    iconName,
    iconContainerStyle,
    title,
    children,
    showRightArrow = false,
    onPress,
    disabled = false,
    tagComponent,
    accessoryLeft,
  } = props
  const styles = useStyleSheet(themedStyle)

  const icon = iconDescriptor && (
    <OldIcon
      style={[iconDescriptor.pack !== 'customImage' && styles.icon, iconStyle]}
      iconDescriptor={iconDescriptor}
      solid={iconSolid}
    />
  )

  let leftAccessory = null

  if (accessoryLeft) {
    leftAccessory = accessoryLeft
  } else if (iconName) {
    leftAccessory = <Icon name={iconName} style={styles.newIcon} />
  } else if (icon) {
    leftAccessory = iconContainerStyle ? <View style={iconContainerStyle}>{icon}</View> : icon
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      style={[styles.container, style]}
      activeOpacity={onPress ? 0.65 : 1}
      accessibilityLabel={title}
      onPress={onPress}
    >
      <View style={styles.left}>
        {leftAccessory}
        <Text type="regular" bold lineSpacing="tight" style={[styles.titleLabel, labelStyle]}>
          {title}
        </Text>
      </View>
      {children}
      <View style={styles.left}>
        {tagComponent}
        {showRightArrow && <Icon name="caret-right" />}
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 64,
    paddingHorizontal: 16,
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    tintColor: 'theme.text.primary',
    height: 24,
    width: 24,
    marginRight: 12,
  },
  newIcon: {
    marginRight: 12,
  },
  titleLabel: {
    minWidth: 128,
  },
})
