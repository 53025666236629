import React, { useState, useRef } from 'react'
import { View, ViewStyle, ColorValue, StyleProp, StyleSheet } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'

const VIEW_BOX_HEIGHT = 56
const VIEW_BOX_WIDTH = 100

interface ProgressBarStyle extends ViewStyle {
  color?: ColorValue
  strokeWidth?: number
}

export interface GaugeIndicatorProps {
  progress: number
  style?: StyleProp<ProgressBarStyle>
}

export const GaugeIndicator = ({ style, progress = 0.0 }: GaugeIndicatorProps) => {
  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()
  const progressRef = useRef<Path | null>(null)
  const [pathLength, setPathLength] = useState(0)

  const onLayout = () => {
    if (progressRef.current) {
      setPathLength(progressRef.current.getTotalLength() ?? 0)
    }
  }

  const progressLength = progress * pathLength
  const flattenedStyle = StyleSheet.flatten(style)
  const backgroundColor = theme['theme.surface.base3']
  const progressColor = flattenedStyle.color ?? theme['theme.primary.base']
  const strokeWidth = flattenedStyle?.strokeWidth ?? 11

  return (
    <View style={[styles.container, flattenedStyle]}>
      <View style={[styles.progressBar]}>
        <Svg
          width="100%"
          height="100%"
          viewBox={`0 0 ${VIEW_BOX_WIDTH} ${VIEW_BOX_HEIGHT}`}
          fill="none"
        >
          <Path
            ref={progressRef}
            onLayout={onLayout}
            d="M6 50C6.16667 35.3333 16 6 50 6C84 6 93.8333 35.3333 94 50"
            stroke={backgroundColor}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
          />
        </Svg>
      </View>
      {progressLength > 0 && (
        <View style={[styles.progressBar]}>
          <Svg
            width="100%"
            height="100%"
            viewBox={`0 0 ${VIEW_BOX_WIDTH} ${VIEW_BOX_HEIGHT}`}
            fill="none"
          >
            <Path
              d="M6 50C6.16667 35.3333 16 6 50 6C84 6 93.8333 35.3333 94 50"
              stroke={progressColor}
              strokeWidth={strokeWidth}
              strokeLinecap="round"
              strokeDasharray={[progressLength, pathLength]}
            />
          </Svg>
        </View>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    width: VIEW_BOX_WIDTH,
    height: VIEW_BOX_HEIGHT,
  },
  progressBar: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
})
