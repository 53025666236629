import React from 'react'
import Rate from 'react-native-rate'
import { useSelector } from 'react-redux'
import { App } from '@config'
import { IconName, Text } from '@components/base'
import { AccountSection, SectionItem } from '@screens/Profile'
import { Zendesk, openUrl } from '@utils'
import { StyleService, useStyleSheet } from '@src/style/service'
import { authenticatedUserSelector } from '@src/selectors/app'

interface ContactItem {
  icon: IconName
  label: string
  onPress: () => void
}

export const ContactSection = () => {
  const styles = useStyleSheet(themedStyles)
  const user = useSelector(authenticatedUserSelector)

  const contactItems: ContactItem[] = [
    {
      icon: 'chat-circle-text',
      label: 'Contact us',
      onPress: () => openUrl(Zendesk.baseUrl(user.email)),
    },
    {
      icon: 'instagram-logo',
      label: 'Follow us on Instagram',
      onPress: () => openUrl('https://www.instagram.com/nutrisenseio/'),
    },
    {
      icon: 'facebook-logo',
      label: 'Join Facebook Group',
      onPress: () => openUrl('https://www.facebook.com/nutrisenseio/'),
    },
    {
      icon: 'note-pencil',
      label: 'Leave a review',
      onPress: () =>
        Rate.rate({
          AppleAppID: App.appleAppID,
          GooglePackageName: App.googlePackageName,
        }),
    },
  ]

  return (
    <AccountSection title="Contact">
      {contactItems.map(({ icon, label, onPress }, index) => (
        <SectionItem
          hasBottomBorder={index !== contactItems.length - 1}
          icon={icon}
          key={label}
          onPress={onPress}
        >
          <Text type="regular" style={styles.text}>
            {label}
          </Text>
        </SectionItem>
      ))}
    </AccountSection>
  )
}

const themedStyles = StyleService.create({
  text: {
    flex: 1,
  },
})
