import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'

export interface ListFooterProps {
  loadedAll: boolean
  isLoadingMore: boolean
  isListEmpty: boolean
}

export const ListFooter = ({ isLoadingMore }: ListFooterProps) => {
  const styles = useStyleSheet(themedStyle)

  const loadingIndicator = (
    <View style={styles.footerLoader}>
      <ActivityIndicator animating size="large" {...styles.loaderColor} />
    </View>
  )

  if (isLoadingMore) {
    return loadingIndicator
  } else {
    return <View style={styles.footer} />
  }
}

const themedStyle = StyleService.create({
  footerLoader: {
    height: 80,
    paddingVertical: 10,
    marginTop: 10,
    marginBottom: 30,
  },
  loaderColor: {
    color: 'theme.surface.base2',
  },
  footer: {
    backgroundColor: 'theme.background',
    marginBottom: 30,
  },
})
