import React, { ReactNode } from 'react'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import { TouchableWithoutFeedback, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconButton } from '@components/base'
import { useHasLargeScreen } from '@src/config/device'
import { useGoBack } from '@utils/navigation'
import { TAB_NAVIGATION_BAR_HEIGHT } from '@src/navigation/navigators/tabs.navigator'
import { PLUS_MENU_BOTTOM_MARGIN } from '@src/screens/Events/containers/Events'

export const ActionsMenuWrapperModal = ({ children }: { children: ReactNode }) => {
  const goBack = useGoBack()
  const hasLargeScreen = useHasLargeScreen()
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  const largeScreenMarginBottom = insets.bottom ? 0 : PLUS_MENU_BOTTOM_MARGIN

  const floatingCloseButtonStyles = [
    styles.closeButton,
    {
      marginBottom: hasLargeScreen
        ? largeScreenMarginBottom
        : TAB_NAVIGATION_BAR_HEIGHT + PLUS_MENU_BOTTOM_MARGIN,
    },
  ]

  return (
    <TouchableWithoutFeedback accessible={false} onPress={goBack} style={styles.background}>
      <SafeAreaView edges={['bottom']} style={styles.content}>
        <View style={styles.actions}>{children}</View>
        <IconButton
          accessibilityLabel="Close"
          iconName="x"
          onPress={goBack}
          size="l"
          type="secondary"
          style={floatingCloseButtonStyles}
        />
      </SafeAreaView>
    </TouchableWithoutFeedback>
  )
}

const themedStyle = StyleService.create({
  background: {
    flex: 1,
    backgroundColor: 'theme.overlay',
  },
  content: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  actions: {
    padding: 20,
    marginHorizontal: 16,
    marginBottom: 12,
    backgroundColor: 'theme.background.modal',
    borderRadius: 16,
  },
  closeButton: {
    alignSelf: 'flex-end',
    right: 20,
  },
})
