import { isEmpty } from 'lodash'
import {
  StackCardInterpolatedStyle,
  StackCardInterpolationProps,
  StackNavigationOptions,
} from '@react-navigation/stack'

const OVERLAY_BACKGROUND_COLOR = '#0A0A0CB3' // 'theme.solid.black' with 0.7 opacity

export const modalWithOverlayOptions: StackNavigationOptions = {
  cardOverlayEnabled: true,
  presentation: 'transparentModal',
}

export const tutorialModalOptions: StackNavigationOptions = {
  ...modalWithOverlayOptions,
  cardStyleInterpolator: ({ current: { progress } }) => ({
    cardStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 0.5, 0.9, 1],
        outputRange: [0, 0.25, 0.7, 1],
      }),
    },
    overlayStyle: {
      opacity: progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 0.7],
        extrapolate: 'clamp',
      }),
    },
  }),
}

export const isModified = (sort: any, key: string) => {
  const { types, query, startHour, endHour, orderBy, order } = sort || {}

  if (key === 'dashboard') {
    return (
      (query && !isEmpty(query)) ||
      (types && !isEmpty(types)) ||
      (startHour && startHour !== 0) ||
      (endHour && endHour !== 24) ||
      (orderBy && orderBy !== 'occurred_at') ||
      (order && order !== 'descending')
    )
  } else if (key === 'history') {
    return (
      (query && !isEmpty(query)) ||
      (types && !isEmpty(types)) ||
      (startHour && startHour !== 0) ||
      (endHour && endHour !== 24) ||
      (order && order !== 'descending')
    )
  }
  return false
}

const forFadeFromBottomInterpolator = ({
  current,
  layouts: { screen },
}: StackCardInterpolationProps): StackCardInterpolatedStyle => {
  const translateY = current.progress.interpolate({
    inputRange: [0, 1],
    outputRange: [screen.height, 0],
  })

  const opacity = current.progress.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  })

  return {
    overlayStyle: {
      opacity,
      backgroundColor: OVERLAY_BACKGROUND_COLOR,
    },
    cardStyle: {
      transform: [{ translateY }],
    },
  }
}

const fadeInInterpolator = ({
  current,
}: StackCardInterpolationProps): StackCardInterpolatedStyle => {
  const opacity = current.progress.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  })
  const delayedOpacity = current.progress.interpolate({
    inputRange: [0, 0, 0, 0, 1],
    outputRange: [0, 0, 0, 0, 1],
  })

  return {
    overlayStyle: {
      opacity,
      backgroundColor: OVERLAY_BACKGROUND_COLOR,
    },
    cardStyle: {
      opacity: delayedOpacity,
    },
  }
}

export const fadeFromBottomModalOptions: StackNavigationOptions = {
  ...modalWithOverlayOptions,
  gestureEnabled: false,
  cardStyleInterpolator: forFadeFromBottomInterpolator,
}

export const fadeInModalOptions: StackNavigationOptions = {
  ...modalWithOverlayOptions,
  gestureEnabled: false,
  cardStyleInterpolator: fadeInInterpolator,
}

export const gestureDismissibleModalOptions: StackNavigationOptions = {
  ...fadeFromBottomModalOptions,
  gestureEnabled: true,
}
