import React from 'react-native'
import { startCase } from 'lodash'
import { useSelector } from 'react-redux'
import { useEffect, useRef } from 'react'
import { ReactNativeFile } from '@src/utils/image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { Feature, ProfilePhoto, useFeatureFlag } from '@src/components'
import { AccountRowsSection } from '@src/screens/Profile/components/AccountRowsSection'
import { ProfileContentGrid } from '@src/screens/Profile/components/ProfileContentGrid'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import { WalkthroughTooltip } from '@src/components/WalkthroughTooltip'
import { useOneTimeVisitedState } from '@src/hooks/useOneTimeVisitedState'
import { Storage } from '@src/utils'
import { onboardingChecklistItemsSelector } from '@src/screens/Checklist/models/checklist.selectors'
import { OnboardingChecklist } from './OnboardingChecklist'

interface ProfileContentProps {
  onChangeProfilePhoto: (photo: ReactNativeFile) => void
  photoSource: { uri?: string }
  userFullName: string
}

export const ProfileContent = ({
  onChangeProfilePhoto,
  photoSource,
  userFullName,
}: ProfileContentProps) => {
  const styles = useStyleSheet(themedStyles)

  const {
    shouldShow: shouldShowAccountProfileWalkthrough,
    markAsShown: markAccountProfileWalkthroughAsShown,
  } = useOneTimeVisitedState(Storage.ACCOUNT_PROFILE_WALKTHROUGH_VISITED_KEY)

  const onboardingChecklistItems = useSelector(onboardingChecklistItemsSelector)

  const allOnboardingChecklistItemsCompleted =
    onboardingChecklistItems.length > 0 && onboardingChecklistItems.every((item) => item.completed)

  const enableOnboardingChecklist = useFeatureFlag(Feature.OnboardingChecklist)

  const shouldShowOnboardingChecklist = useRef(!allOnboardingChecklistItemsCompleted).current

  useEffect(() => {
    Storage.set(Storage.ACCOUNT_SCREEN_VISITED_KEY, true)
  }, [])

  return (
    <>
      <ProfilePhoto
        source={photoSource}
        onPhotoChange={onChangeProfilePhoto}
        style={styles.profilePhoto}
      />
      <Text style={styles.profileName} type="regular">
        {startCase(userFullName)}
      </Text>
      {enableOnboardingChecklist && shouldShowOnboardingChecklist && <OnboardingChecklist />}
      <WithWalkthrough
        isVisible={shouldShowAccountProfileWalkthrough}
        placement="top"
        accessibilityLabel="Manage Account"
        tooltipStyle={styles.tooltipStyle}
        content={
          <WalkthroughTooltip title="We have added all of the most useful menu items here in your new Account area." />
        }
        onClose={markAccountProfileWalkthroughAsShown}
      >
        <ProfileContentGrid />
      </WithWalkthrough>
      <AccountRowsSection />
    </>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  profilePhoto: {
    alignSelf: 'center',
    marginTop: 16,
    marginBottom: 16,
  },
  tooltipStyle: {
    maxWidth: 324,
  },
  grid: {
    padding: 16,
    flexWrap: 'wrap',
    gap: 8,
  },
  gridRow: {
    gap: 8,
    flexDirection: 'row',
  },
  profileName: {
    alignSelf: 'center',
    marginBottom: 24,
  },
})
