import { gql } from '@apollo/client'

const UPDATE_JOURNAL_ENTRY = gql`
  mutation updateJournalEntry(
    $id: ID!
    $body: String
    $time: String
    $kind: String
    $measurements: [JournalMeasurementsData!]
  ) {
    updateJournalEntry(
      id: $id
      body: $body
      time: $time
      kind: $kind
      measurements: $measurements
    ) {
      id
      title
      body
      occurredAt
      createdAt
      creatorId
      kind
      score
      measurements {
        id
        type
        title
        description
        value
        values {
          ... on SingleValueMeasurement {
            value
          }
        }
        units
        occurredAt
        createdAt
      }
    }
  }
`

export default UPDATE_JOURNAL_ENTRY
