import { gql } from '@apollo/client/core'
import { CORE_SUBSCRIPTION_FIELDS } from '@src/graphql/fragments/subscription'

const SCHEDULE_SUBSCRIPTION_CHANGE = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  mutation scheduleSubscriptionChange($id: ID!, $product: BillingProduct!) {
    scheduleSubscriptionChange(id: $id, product: $product) {
      ...CoreSubscriptionFields
    }
  }
`

export default SCHEDULE_SUBSCRIPTION_CHANGE
