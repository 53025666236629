import { gql } from '@apollo/client'
import { QUESTION_FIELDS } from './question'

export const SURVEY_FIELDS = gql`
  ${QUESTION_FIELDS}
  fragment SurveyFields on Survey {
    kind
    behavior {
      allowEarlyExit
      showAlertOnClose
      allowMultipleSubmissions
      showProgressIndicator
      trackResponses
      warnBeforeStopping
    }
    showAlertOnCloseBehavior {
      title
      body
      icon
      confirmTitle
      cancelTitle
    }
    version
    followups {
      parentAnswer {
        value
      }
      kind
      parentQuestionKey
      question {
        ...QuestionFields
      }
    }
    header
    research
    completedScreen {
      title
      header
      intro
      icon
      primaryActionText
      footnote
      secondaryActionLink {
        text
        url
      }
    }
    questionsGroups {
      introScreen {
        header
        imageUrl
        intro
        introMarkdown
        icon
        footnote
        inlineAlert {
          type
          message
        }
      }
      banner
      questions {
        ...QuestionFields
      }
    }
  }
`
