import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { Device } from '@src/config'
import { healthKitSyncStatusSelector, healthLastSyncedSelector } from '@src/selectors/integrations'
import { openUrl } from '@src/utils'
import { NavigationContainer } from '@src/screens/Common/containers'
import ToggleSetting from '../UserSettings/ToggleSetting'
import LastSyncedSection from './LastSyncedSection'

export const HealthKitIntegration = () => {
  const lastSyncedHealth = useSelector(healthLastSyncedSelector)
  const healthKitSync = useSelector(healthKitSyncStatusSelector)
  const styles = useStyleSheet(themedStyles)

  if (!Device.hasHealthKit) {
    return null
  }

  const healthKitSyncToggle = (
    <ToggleSetting key="healthKitSync" title="Apple Health Sync" field="healthKitSync" />
  )

  const healthDataLastSynced = <LastSyncedSection lastSynced={lastSyncedHealth} />

  const title = 'Apple Health'

  const lastSync = healthKitSync && healthDataLastSynced

  const onConfigurePress = () => {
    openUrl('App-Prefs:Privacy&path=HEALTH')
  }

  const configureHealthKitButton = (
    <Button
      type="primary"
      size="block"
      style={styles.configureButton}
      onPress={onConfigurePress}
      accessibilityLabel="Configure"
    >
      Configure
    </Button>
  )

  return (
    <NavigationContainer title={title} showLoadingIndicator>
      <View style={styles.container}>
        {healthKitSyncToggle}
        {lastSync}
        {healthKitSync && configureHealthKitButton}
      </View>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    flexGrow: 1,
    backgroundColor: 'theme.background',
  },
  configureButton: {
    marginTop: 'auto',
    marginBottom: 24,
    marginHorizontal: 16,
  },
})
