import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation, useRoute } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Toggle } from '@components/base'
import { Section } from '@src/components'
import { libreLinkupConnectionSelector } from '@src/selectors/integrations'
import { useSnack } from '@src/utils/navigatorContext'
import { LibreLinkupConnectionStatus } from '@src/types'
import { AppRouteProp } from '@src/navigation/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import LastSyncedSection from './LastSyncedSection'

export const Libre3Integration = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const route = useRoute<AppRouteProp<'Libre3Integration'>>()
  const { cancel: isDeleteConfirmed } = route.params || {}

  const dispatch = useDispatch()
  const showSnack = useSnack()
  const linkupConnection = useSelector(libreLinkupConnectionSelector)
  const [connectionActive, setConnectionActive] = useState(linkupConnection?.active || false)

  const confirmSyncDelete = () => {
    navigation.navigate('CancelModal', {
      title: `Are you sure you want to delete synchronization with Libre3?`,
      cancelText: 'No, Keep it synced',
      confirmText: 'Yes, delete synchronization',
      parentScreen: 'Libre3Integration',
    })
  }

  useEffect(() => {
    if (isDeleteConfirmed) {
      setConnectionActive(false)
      dispatch({
        type: 'settings/disableLibreLinkupConnection',
        success: () => {
          showSnack('Libre3 sync turned off')
        },
        failure: () => {
          setConnectionActive(true)
        },
      })
    }
  }, [dispatch, setConnectionActive, showSnack, isDeleteConfirmed])

  useEffect(() => {
    dispatch({
      type: 'settings/fetchLibreLinkupConnection',
    })
  }, [dispatch])

  useEffect(() => {
    setConnectionActive(!!linkupConnection?.active)
  }, [linkupConnection])

  const linkupTogglePress = () => {
    if (connectionActive) {
      confirmSyncDelete()
    } else {
      navigation.navigate('LibreAuth')
    }
  }

  const hideConnectingText =
    !linkupConnection ||
    linkupConnection.status !== LibreLinkupConnectionStatus.ConnectionAuthorized

  return (
    <NavigationContainer title="Libre 3" showLoadingIndicator>
      <View style={styles.container}>
        <Section title="Libre3">
          <Toggle checked={connectionActive} onChange={linkupTogglePress} />
        </Section>
        {!hideConnectingText && (
          <Text type="small" style={styles.connectionInProgress}>
            Data will appear on dashboard shortly
          </Text>
        )}
        {connectionActive && linkupConnection?.status === LibreLinkupConnectionStatus.Connected && (
          <LastSyncedSection lastSynced={linkupConnection?.lastSyncedAt} />
        )}
      </View>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexGrow: 1,
    backgroundColor: 'theme.background',
  },
  connectionInProgress: {
    marginTop: 16,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
})
