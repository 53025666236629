import React, { useState } from 'react'
import { Image, ImageStyle, View, TouchableOpacity, TextStyle } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { Icon, InlineAlert, ProgressBar, ScrollViewWithFade, VideoPlayer } from '@components'
import { openUrl } from '@src/utils'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Tutorial } from '../models/tutorials.types'
import { isPlatformLink, isTutorialLink, isTutorialVideo, platformLinkUrl } from './utils'

const Link = ({ text, url, style }: { text: string; url: string; style: TextStyle }) => (
  <TouchableOpacity accessibilityLabel={text} onPress={() => openUrl(url)}>
    <Text type="regular" style={style}>
      {text}
    </Text>
  </TouchableOpacity>
)

export const CarouselTutorial = ({ tutorial }: { tutorial: Tutorial }) => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const { tutorialSteps } = tutorial

  if (tutorialSteps.length === 0) {
    return null
  }

  const currentStep = tutorialSteps[currentStepIndex]
  const isLastStep = currentStepIndex === tutorialSteps.length - 1

  const onBackPress = () => {
    if (currentStepIndex === 0) {
      navigation.goBack()
    } else {
      setCurrentStepIndex(currentStepIndex - 1)
    }
  }

  const onNextPress = () => {
    if (isLastStep) {
      return
    }

    setCurrentStepIndex(currentStepIndex + 1)
  }

  const videoAction = currentStep.action.find(isTutorialVideo)
  const linkAction = currentStep.action.find(isTutorialLink)
  const platformLink = currentStep.action.find(isPlatformLink)
  const buttonUrl = platformLink ? platformLinkUrl(platformLink) : linkAction?.url

  return (
    <NavigationContainer title={currentStep.title}>
      <View style={[styles.container, { paddingBottom: insets.bottom + 16 }]}>
        <ProgressBar
          style={styles.progressBar}
          currentStep={currentStepIndex}
          stepsCount={tutorialSteps.length}
          duration={900}
        />
        <ScrollViewWithFade scrollViewStyle={styles.scrollView}>
          {currentStep.image?.data && !videoAction && (
            <Image
              source={{ uri: currentStep.image.data.attributes.url }}
              style={styles.media as ImageStyle}
            />
          )}
          {videoAction && (
            <VideoPlayer
              source={{ uri: videoAction.video?.data.attributes.url }}
              style={styles.media}
              showControls={false}
              isLooping
              isMuted
              playOnLoad
            />
          )}
          {currentStep.description && (
            <Text type="regular" style={styles.description}>
              {currentStep.description}
            </Text>
          )}
          {currentStep.warning && (
            <InlineAlert style={styles.warningMessage} message={currentStep.warning} />
          )}
          {linkAction && !isLastStep && !platformLink && (
            <Link text={currentStep.buttonText} url={linkAction.url} style={styles.linkAction} />
          )}

          {platformLink && !isLastStep && (
            <Link
              text={currentStep.buttonText}
              url={platformLinkUrl(platformLink)}
              style={styles.linkAction}
            />
          )}
        </ScrollViewWithFade>
        <View style={styles.buttonsContainer}>
          <Button
            type="outline"
            size="block"
            style={styles.backButton}
            onPress={onBackPress}
            accessibilityLabel="Back"
          >
            Back
          </Button>
          {!isLastStep ? (
            <Button
              type="primary"
              size="block"
              style={styles.nextButton}
              onPress={onNextPress}
              accessibilityLabel="Next"
            >
              Next
            </Button>
          ) : (
            (linkAction || platformLink) && (
              <Button
                type="primary"
                size="block"
                style={styles.nextButton}
                onPress={() => buttonUrl && openUrl(buttonUrl)}
                accessibilityLabel={currentStep.buttonText}
                accessoryLeft={
                  currentStep.buttonIcon && (
                    <Icon
                      solid
                      style={styles.actionButtonIcon}
                      iconDescriptor={{
                        pack: 'fontAwesome6Pro',
                        name: currentStep.buttonIcon,
                      }}
                    />
                  )
                }
              >
                {currentStep.buttonText}
              </Button>
            )
          )}
        </View>
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  progressBar: {
    margin: 16,
  },
  scrollView: {
    padding: 16,
  },
  media: {
    width: '100%',
    maxWidth: 600,
    maxHeight: 600,
    alignSelf: 'center',
    height: undefined,
    aspectRatio: 1,
    borderRadius: 16,
  },
  description: {
    marginTop: 24,
    marginBottom: 16,
  },
  actionButtonIcon: {
    width: 16,
  },
  warningMessage: {
    marginVertical: 16,
  },
  linkAction: {
    marginBottom: 16,
    color: 'theme.text.link',
    textDecorationLine: 'underline',
  },
  buttonsContainer: {
    flexDirection: 'row',
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  backButton: {
    flex: 1,
    marginRight: 8,
  },
  nextButton: {
    flex: 2,
  },
})
