import React from 'react'
import { View } from 'react-native'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

export interface ProductDescriptionProps {
  title: string
  description: string
  price: string
  showDivider?: boolean
}

export const ProductDescription = (props: ProductDescriptionProps) => {
  const styles = useStyleSheet(themedStyle)
  const { title, description, price, showDivider = false } = props

  return (
    <>
      <View style={styles.container}>
        <View style={styles.textContent}>
          <Text type="regular" bold lineSpacing="none">
            {title}
          </Text>
          <Text type="small" lineSpacing="tight" style={styles.description}>
            {description}
          </Text>
        </View>
        <Text type="large" bold>
          {price}
        </Text>
      </View>
      {showDivider && <Divider />}
    </>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 64,
    paddingVertical: 16,
    marginHorizontal: 16,
  },
  description: {
    flex: 1,
    marginTop: 8,
  },
  textContent: {
    flex: 1,
    marginRight: 16,
  },
})
