import { memo } from 'react'
import { FeatureFlagProps } from './FeatureFlag.types'
import { useFeatureFlag } from './FeatureFlagUtils'

const FeatureFlag = ({ feature, children, fallbackComponent }: FeatureFlagProps) => {
  const isEnabled = useFeatureFlag(feature)

  if (isEnabled && children) {
    return children
  }

  return fallbackComponent || null
}

export default memo(FeatureFlag)
