import { capitalize } from 'lodash'
import moment from 'moment-timezone'

export const TIME_FORMAT = 'MM/DD/YYYY h:mm a'
const LOG_TIME_FORMAT = 'YYYY-MM-DDThh:mm'

export const formatTime = (timeString: string) => {
  return moment(timeString, TIME_FORMAT).format()
}

// new Date only used on react-native-modal-datetime-picker
export const toLocalTimezoneDate = (timeString: moment.MomentInput) => {
  const time = moment(timeString, TIME_FORMAT)

  // avoid using moment().toDate() as it keeps time zone
  return new Date(time.year(), time.month(), time.date(), time.hours(), time.minutes())
}

export const formatTimeToLogTime = (timeString: string, utc = false) => {
  if (utc) {
    return moment(timeString, TIME_FORMAT).utc().format(LOG_TIME_FORMAT)
  }
  return moment(timeString, TIME_FORMAT).format(LOG_TIME_FORMAT)
}

export const isToday = (dateString: string) => moment(dateString).isSame(moment(), 'day')

// Do not use the '===' operator when comparing floats; use this
// ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/EPSILON#examples
//
export const isEqualWithTolerance = (x: number, y: number, tolerance = Number.EPSILON) => {
  return Math.abs(x - y) < tolerance
}

// lodash `startCase` removes special characters like `&` and `(`
export const safeStartCase = (string: string) => {
  return string.replace(/\w+/g, capitalize)
}

export default {
  formatTime,
  toLocalTimezoneDate,
  formatTimeToLogTime,
  isToday,
  isEqualWithTolerance,
  safeStartCase,
}
