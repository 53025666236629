import React, { View } from 'react-native'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'

export interface WalkthroughTooltipProps {
  title: string
  subtitle?: string
}

export const WalkthroughTooltip = ({ title, subtitle }: WalkthroughTooltipProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View>
      <Text type={subtitle ? 'large' : 'regular'} bold={!!subtitle} style={styles.tooltipText}>
        {title}
      </Text>
      {!!subtitle && (
        <Text type="regular" style={styles.tooltipText}>
          {subtitle}
        </Text>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  tooltipText: {
    color: 'theme.tooltip.textPrimary',
    textAlign: 'center',
  },
})
