import {
  commonColors,
  comparativeChartColors,
  fontFamily,
} from '@src/components/charts/common-styles'
import { useThemeColors } from '@src/hooks/useThemeColors'
import { nbInternationalFont } from '@src/components/charts/fonts/nbInternational'

interface Point {
  x: string
  y: number
  type: string
  hasPair: boolean
}
interface SingleDayScoreChartConfig {
  maxScore?: number
  data: Point[]
}

const BAR_HEIGHT = 11

export const useMealTypeChartRenderFunction = ({
  data,
  maxScore = 10,
}: SingleDayScoreChartConfig) => {
  const colors = useThemeColors({
    ...commonColors,
    ...comparativeChartColors,
  })

  return `(function(){
    const style = document.createElement('style');
    style.textContent = \`
      ${nbInternationalFont}
      .f2-tooltip {
        padding: 4px 8px;
        border-radius: 4px;
        color: ${colors.tooltipText};
        background-color: ${colors.tooltipBackground};
        font-family: 'NBInternationalProReg';
        font-size: 13px;
        font-weight: 600;
        line-height: 16px;
      }
      .f2-tooltip:after {
        border-top-color: ${colors.tooltipBackground};
        bottom: 0;
        left: 50%;
        margin-bottom: -5px;
      }
    \`;

    document.body.append(style)

    const basicLabelStyle = {
      fontFamily: '${fontFamily}',
      fontSize: 11,
      fill: '${colors.axisLabel}',
      fontWeight: 700,
    }

    const chart = new F2.Chart({
      id: 'chart',
      padding: [40, 'auto', 'auto', 100],
      pixelRatio: window.devicePixelRatio
    });

    chart.legend(false);
    chart.source(${JSON.stringify(data)});

    chart.axis('x', {
      label(text) {
        return {
          ...basicLabelStyle,
          text: text.toUpperCase(),
        }
      },
      labelOffset: 16,
      grid: null
    });
    chart.axis('y', {
      grid: {
        lineWidth: 1,
        stroke: '${colors.axisLine}',
      },
      label: basicLabelStyle,
    });

    chart.scale({
      y: {
        tickCount: 6,
        min: 0,
        max: ${maxScore},
      },
    });

    const columnsAdjustConfig = {
      type: 'dodge',
      marginRatio: 0.45,
    }

    // Render bars
    chart.interval()
      .position('x*y')
      .color('type', type =>
        type === 'current' ? '${(colors as any).currentPeriod}' : '${
    (colors as any).previousPeriod
  }'
      )
      .adjust(columnsAdjustConfig)
      .style({
        height: ${BAR_HEIGHT},
        radius: 5.5,
      })

    chart.coord({ transposed: true })
    chart.render();

    const canvas = chart.get('canvas');
    const axisShapes = canvas.getChildren()[0]._attrs.children[0]._attrs.children
    const xAxisLineShape = axisShapes.find((shape) => shape._attrs.className === 'axis-line')

    const customShapesGroup = canvas.addGroup();

    customShapesGroup.addShape('line', {
      attrs: {
        x1: xAxisLineShape._attrs.attrs.x1,
        y1: xAxisLineShape._attrs.attrs.y1,
        x2: 0,
        y2: xAxisLineShape._attrs.attrs.y1,
        stroke: '${colors.axisLine}',
        lineWidth: 1,
      }
    })

    const yAxisLabel = axisShapes.find((shape) =>
      shape._attrs.className === 'axis-label' && shape._id.includes('axis-y')
    )
    const xAxisLabel = axisShapes.find((shape) =>
      shape._attrs.className === 'axis-label' && shape._id.includes('axis-x')
    )

    customShapesGroup.addShape('text', {
      attrs: {
        ...basicLabelStyle,
        x: xAxisLabel._attrs.attrs.x,
        y: yAxisLabel._attrs.attrs.y,
        text: 'SCORE',
        textBaseline: 'top',
        textAlign: 'right',
      }
    });

    /* Tooltip */
    const renderTooltipRow = ({ markerColor, value }) => \`
      <div
        style="display: flex; flex-direction: row; align-items: center;"
      >
        <div style="height: 6px; width: 6px; background-color: \${markerColor}; border-radius: 50%; margin-right: 4px"></div>
        <div>\${value}</div>
      </div>
    \`

    chart.tooltip({
      showCrosshairs: false,
      showItemMarker: false,
      custom: true,
      onChange: ({ items, ...rest }) => {
        const tooltipEl = document.querySelector('.f2-tooltip');
        const x = Math.max(...items.map(({ x }) => x));
        const y = Math.min(...items.map(({ y }) => y));

        const tooltipContent = items.reverse().map((item) => renderTooltipRow({
          markerColor: item.color,
          value: item.value,
        }));

        const groupTitle = items[0].title;
        const bars = canvas.getChildren()[1].getChildren()[0].getChildren()

        bars.forEach((bar) => {
          const isCurrentGroup = bar._id.includes(groupTitle);

          bar.attr('opacity', isCurrentGroup ? 1 : 0.2);
        });

        // Needed to hide the default canvas tooltip
        items.forEach(item => {
          item.name = null;
          item.value = null;
        });

        tooltipEl.innerHTML = '<div>' + tooltipContent.join('') + '</div>';
        tooltipEl.style.whiteSpace='nowrap';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = (x - tooltipEl.clientWidth) + 'px';
        tooltipEl.style.top = (y - tooltipEl.clientHeight - ${BAR_HEIGHT}) + 'px';
      },
    });
  })();
`
}
