import {
  NutritionixBrandedItem,
  NutritionixCommonItem,
  NutritionixItemWithNutrients,
} from '@src/services/Nutritionix/types'

export const isCommonIngredient = (item: unknown): item is NutritionixCommonItem => {
  const { tag_id: tagId } = item as NutritionixCommonItem

  return !!tagId
}

export const isBrandedIngredient = (item: unknown): item is NutritionixBrandedItem => {
  const { brand_name: brandName, nix_item_id: nutritionixId } = item as NutritionixBrandedItem

  return !!(brandName && nutritionixId)
}

export const isIngredientWithNutrients = (item: unknown): item is NutritionixItemWithNutrients => {
  const { alt_measures: altMeasures } = item as NutritionixItemWithNutrients

  return !!altMeasures
}
