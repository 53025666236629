export default {
  ENV: process.env.ENV,
  API_ENDPOINT: process.env.API_ENDPOINT,
  GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
  NUTRITIONIX_APPLICATION_ID: process.env.NUTRITIONIX_APPLICATION_ID,
  NUTRITIONIX_APPLICATION_KEYS: process.env.NUTRITIONIX_APPLICATION_KEYS,
  UNLEASH_URL: process.env.UNLEASH_URL,
  UNLEASH_CLIENT_KEY: process.env.UNLEASH_CLIENT_KEY,
  COURSES_CMS_ENDPOINT: process.env.COURSES_CMS_ENDPOINT,
  COURSES_CMS_API_KEY: process.env.COURSES_CMS_API_KEY,
  STREAM_CHAT_DEVELOPMENT_API_KEY: process.env.STREAM_CHAT_DEVELOPMENT_API_KEY,
  STREAM_CHAT_STAGING_API_KEY: process.env.STREAM_CHAT_STAGING_API_KEY,
  STREAM_CHAT_PRODUCTION_API_KEY: process.env.STREAM_CHAT_PRODUCTION_API_KEY,
}
