export const mergeChartData = <T extends { x: string; y: number | null }>(
  currentData: T[],
  previousData: T[],
): Array<T & { type: 'current' | 'previous'; hasPair: boolean }> => {
  const hashByMealPrevious = new Set(previousData.map((item) => item.x))
  const hashByMealCurrent = new Set(currentData.map((item) => item.x))
  const result = [
    ...currentData.map((item) => ({
      ...item,
      type: 'current' as const,
      hasPair: hashByMealPrevious.has(item.x),
    })),
    ...previousData.map((item) => ({
      ...item,
      type: 'previous' as const,
      hasPair: hashByMealCurrent.has(item.x),
    })),
  ]

  result.sort((a, b) => {
    if (a.type === b.type) {
      return 0
    }
    return a.type === 'previous' ? 1 : -1
  })

  return result
}
