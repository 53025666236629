import React, { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import {
  pastAppointmentsSelector,
  upcomingAppointmentsSelector,
} from '@screens/NutritionistHub/models/nutritionistHub.selectors.ts'
import { CallCard } from '@screens/NutritionistHub/components/UpcomingCalls/CallCard.tsx'
import { Button } from '@components/base'

export const AllVideoCalls = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()
  const upcomingAppointments = useSelector(upcomingAppointmentsSelector)
  const pastAppointments = useSelector(pastAppointmentsSelector)
  const [upcomingSelected, setUpcomingSelected] = useState(true)

  useEffect(() => {
    dispatch({ type: 'nutritionistHub/fetchPastAppointments' })
  }, [dispatch])

  return (
    <NavigationContainer title="Video calls">
      <View style={styles.buttons}>
        <Button
          accessibilityLabel="upcomingCalls"
          type={upcomingSelected ? 'primary' : 'outline'}
          size="small"
          onPress={() => setUpcomingSelected(true)}
        >
          Upcoming
        </Button>
        <Button
          accessibilityLabel="pastCalls"
          type={upcomingSelected ? 'outline' : 'primary'}
          size="small"
          onPress={() => setUpcomingSelected(false)}
        >
          Past
        </Button>
      </View>
      <ScrollView
        style={styles.container}
        contentContainerStyle={{ flexGrow: 1, paddingBottom: (insets?.bottom || 0) + 16 }}
      >
        <View style={styles.callsContainer}>
          {(upcomingSelected ? upcomingAppointments : pastAppointments).map((appointment) => (
            <CallCard key={appointment.id} appointment={appointment} />
          ))}
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 8,
    padding: 16,
  },
  callsContainer: {
    paddingHorizontal: 16,
    rowGap: 24,
  },
})
