import React from 'react'
import moment from 'moment'
import { Control, Controller, UseFormWatch } from 'react-hook-form'
import { upperFirst } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  NotificationEngineReminderDayOfWeek,
  NotificationEngineReminderFrequency,
  NotificationEngineReminderKind,
  NumericalSelect,
  ReminderKind,
} from '@src/types'
import { SelectDateTime, SelectFromActionSheet } from '@src/components'
import { Global } from '@src/utils'
import { TIME_FORMAT } from '@src/utils/global'
import { frequencyTitle } from './utils'

export interface ReminderFormData {
  kind: NotificationEngineReminderKind
  frequency: string
  timeOfDay: string
  dayOfWeek: string
  hoursInterval: string
}

interface ReminderFormInputsProps {
  control: Control<ReminderFormData>
  watch: UseFormWatch<ReminderFormData>
  reminderKind: ReminderKind
  hoursIntervalRanges: NumericalSelect[]
}

export const ReminderFormInputs = ({
  control,
  watch,
  reminderKind,
  hoursIntervalRanges,
}: ReminderFormInputsProps) => {
  const styles = useStyleSheet(themedStyles)

  const watchFrequency = watch('frequency')

  const allowedFrequencies = (freq: NotificationEngineReminderFrequency) =>
    reminderKind.allowedFrequencies.map((freq) => freq.key).includes(freq)

  if (!allowedFrequencies(NotificationEngineReminderFrequency.HoursInterval)) {
    return (
      <>
        <Controller
          control={control}
          name="frequency"
          render={({ field: { value, onChange } }) => (
            <SelectFromActionSheet
              label="Frequency"
              onChange={onChange}
              options={reminderKind.allowedFrequencies.map(({ key }) => frequencyTitle(key))}
              disabled={reminderKind.allowedFrequencies.length === 1}
              value={value}
              title="Select frequency"
              style={styles.dropdown}
            />
          )}
        />
        {watchFrequency !== frequencyTitle(NotificationEngineReminderFrequency.Monthly) &&
          watchFrequency !== frequencyTitle(NotificationEngineReminderFrequency.Bimonthly) &&
          watchFrequency !== frequencyTitle(NotificationEngineReminderFrequency.Quarterly) && (
            <Controller
              control={control}
              name="timeOfDay"
              render={({ field: { value, onChange } }) => {
                return (
                  <SelectDateTime
                    inputProps={{
                      label: 'Time',
                      placeholder: 'Select time',
                      value,
                      style: styles.dropdown,
                    }}
                    pickerProps={{
                      mode: 'time',
                      date: Global.toLocalTimezoneDate(moment(value, 'hh:mmA').format(TIME_FORMAT)),
                    }}
                    onChange={(value) => onChange(moment(value).format('hh:mmA'))}
                  />
                )
              }}
            />
          )}

        {watchFrequency === frequencyTitle(NotificationEngineReminderFrequency.Weekly) && (
          <Controller
            control={control}
            name="dayOfWeek"
            render={({ field: { value, onChange } }) => (
              <SelectFromActionSheet
                label="Day"
                title="Day"
                options={Object.values(NotificationEngineReminderDayOfWeek).map((day) =>
                  upperFirst(day),
                )}
                value={value}
                onChange={onChange}
              />
            )}
          />
        )}
      </>
    )
  } else {
    return (
      <Controller
        control={control}
        name="hoursInterval"
        render={({ field: { value, onChange } }) => (
          <SelectFromActionSheet
            label="Reminder Interval"
            title="Select Interval"
            options={hoursIntervalRanges.map(({ text }) => text)}
            value={value}
            onChange={onChange}
          />
        )}
      />
    )
  }
}

const themedStyles = StyleService.create({
  dropdown: {
    marginBottom: 16,
  },
})
