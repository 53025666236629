import { gql } from '@apollo/client'

export const FETCH_COACH_ASSIGNMENTS = gql`
  query coachAssignments {
    coachAssignments {
      coachAssignments {
        state
        supportType
        coach {
          firstName
          lastName
          coachProfile {
            bio
            photo
            credentials
          }
        }
      }
    }
  }
`
