import { gql } from '@apollo/client'
import { CORE_SENSOR_FIELDS } from '@src/graphql/fragments/sensor'

const CREATE_ACTIVATION = gql`
  ${CORE_SENSOR_FIELDS}
  mutation createActivation($data: ActivationData!) {
    createActivation(data: $data) {
      ...CoreSensorFields
    }
  }
`

export default CREATE_ACTIVATION
