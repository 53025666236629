import { isFunction, round, omitBy, orderBy } from 'lodash'
import moment from 'moment'
import commonReducers, { Action } from '@models/commonReducers'
import defaultReducers from '@models/defaultReducers'
import { Calendar } from '@models/app.types'
import { HealthDataMeasurementType } from '@src/types'
import { transformCharts } from '../transforms/chart'
import { transformNutrition, combineNutrition } from '../transforms/events'
import { defaultEventsState } from '../constants'
import { EventsStoreState, EventsItemType } from '../models/events.types'

const { cacheSet: defaultCacheSet } = defaultReducers

const reducers = {
  ...defaultReducers,
  fetchList: commonReducers.fetchList,
  updateSort: commonReducers.updateSort,
  updateListItem: commonReducers.updateListItem,

  // Custom cache reducer that skips caching allNutrition query results if
  // thresholds were recently updated. Without this, stale nutrition data
  // will be cached before the backend finishes recalculating daily statistics.
  //
  cacheSet: (state: EventsStoreState, action: Action) => {
    if (!action.cacheKey?.startsWith('events/fetchNutrition')) {
      return defaultCacheSet(state, action)
    }

    const { glucoseThresholdLastUpdatedAt: lastUpdatedAt } = state
    const payloadUpdatedAt = moment(action.payload?.updatedAt)

    if (lastUpdatedAt && payloadUpdatedAt.isValid() && payloadUpdatedAt.isBefore(lastUpdatedAt)) {
      // Thresholds have previously been updated but the payload data is stale
      return state
    }

    return defaultCacheSet(state, action)
  },

  glucoseThresholdsUpdated: (state: EventsStoreState, { payload }: { payload: any }) => ({
    ...state,
    glucoseThresholdLastUpdatedAt: moment(payload.updatedAt),
  }),

  fetchNutritionData: (state: EventsStoreState, { payload }: { payload: any }) => ({
    ...state,
    ...transformNutrition(payload),
  }),

  fetchChartsData: (state: EventsStoreState, { payload }: { payload: any }) => ({
    ...state,
    charts: {
      ...defaultEventsState.charts,
      ...transformCharts(payload.charts),
    },
  }),

  appendOrReplaceList: (state: EventsStoreState, action: any) => {
    const { payload, transform, replaceFake: replaceParam } = action

    const { events, nutrition } = state

    const event = isFunction(transform) ? transform(payload) : payload

    const insertEvent = (eventList: EventsItemType[]): [EventsItemType[], any] => {
      let newNutrition
      let newEvents: EventsItemType[] = []

      let eventIdx = eventList.findIndex(
        (el) => el.id === event.id && el.__typename === event.__typename,
      )
      if (replaceParam && eventIdx < 0) {
        eventIdx = eventList.findIndex(
          (el) => el.id === undefined && 'fake' in el && el.fake === true,
        )
      }
      // New manual daily measurements will replace an existing measurement with the same type and date
      if (event.isDailyMeasurement) {
        eventIdx = eventList.findIndex(
          (el) =>
            'type' in el &&
            el.type === event.type &&
            moment(event.occurredAt).isSame(el.occurredAt, 'day'),
        )
      }

      if (eventIdx > -1) {
        newEvents = eventList.map((element, index) => {
          if (index === eventIdx) {
            return event
          }
          return element
        })
        newNutrition = combineNutrition(nutrition, (eventList[eventIdx] as any).nutrition, -1)
        newNutrition = omitBy(newNutrition, (val) => round(val) <= 0)
        newNutrition = combineNutrition(newNutrition, event.nutrition, 1)
      } else {
        newEvents = [...eventList, event]
        newNutrition = combineNutrition(nutrition, event.nutrition, 1)
      }

      newEvents = orderBy(newEvents, ['occurredAt', 'type', 'title'], ['desc'])

      return [newEvents, newNutrition]
    }

    const [newEvents, newNutrition] = insertEvent(events)

    return {
      ...state,
      events: newEvents,
      nutrition: newNutrition,
    }
  },

  backup: (state: EventsStoreState) => {
    const { events } = state
    return {
      ...state,
      backup: {
        events,
      },
    }
  },

  restore: (state: EventsStoreState) => {
    if (!state.backup) {
      return state
    }
    return {
      ...state,
      events: state.backup.events,
    }
  },

  clear: (state: EventsStoreState) => ({
    ...state,
    ...defaultEventsState,
  }),

  deleteList: (
    state: EventsStoreState,
    { payload, transform }: { payload: any; transform: any; selectGlobal: any },
  ) => {
    const { events: eventList, nutrition } = state
    const event = isFunction(transform) ? transform(payload) : { ...payload }

    const eventIdx = eventList.findIndex((el) => el.id === event.id)

    let newEvents = eventList
    let newNutrition = nutrition

    const deletePrimaryEvent = () => {
      newEvents = eventList.filter((_el, index) => index !== eventIdx)
    }

    if (eventIdx > -1) {
      if (event.type === HealthDataMeasurementType.BloodGlucose) {
        deletePrimaryEvent()
      } else {
        deletePrimaryEvent()
        newNutrition = combineNutrition(nutrition, event.nutrition, -1)
        newNutrition = omitBy(newNutrition, (val: number) => round(val) <= 0)
      }
    }

    return {
      ...state,
      events: newEvents,
      nutrition: newNutrition,
    }
  },

  updateCalendarState: (state: EventsStoreState, { payload }: { payload: Partial<Calendar> }) => ({
    ...state,
    calendar: {
      ...state.calendar,
      ...payload,
    },
  }),
}

export default reducers
