import { createSelector } from 'reselect'
import { historyFiltersSelector, mealsFiltersSelector } from './app'
import { historyStoreStateSelector } from './history'

const filtersSelector = createSelector(
  [historyFiltersSelector, mealsFiltersSelector],
  (history, meals) => ({
    history,
    meals,
  }),
)

export const listFilterSelector = createSelector(
  [filtersSelector, historyStoreStateSelector],
  (filters, history) => ({
    filters,
    history,
  }),
)
