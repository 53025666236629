import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { OverflowMenu, MenuItem, OverflowMenuProps, MenuItemProps } from '@ui-kitten/components'
import pluralize from 'pluralize'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@components/base'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { useApplicationActionSheet } from '@src/hooks/useApplicationActionSheet'

export interface CardOverflowProps<T> extends Omit<OverflowMenuProps, 'anchor'> {
  item: T
  menuItems?: MenuItemProps[]
  disabled?: boolean
}

interface BasicItemProps {
  id?: string
  __typename?: string
  occurredAt?: string
}

const hitSlopValue = 12
const hitSlop = {
  top: hitSlopValue,
  bottom: hitSlopValue,
  left: hitSlopValue,
  right: hitSlopValue,
}

export const CardOverflow = <T extends BasicItemProps>({
  item,
  menuItems,
  disabled,
  ...props
}: CardOverflowProps<T>) => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const route = useRoute()
  const dispatch = useDispatch()

  const [visible, setVisible] = useState(false)

  const { showDestructiveActionSheet } = useApplicationActionSheet()

  const onDeleteWrapper = (onPress: (item: T) => void) => {
    showDestructiveActionSheet &&
      showDestructiveActionSheet({
        title: 'Delete',
        message: 'This will delete the selected item. Are you sure?',
        label: 'Delete',
        handler: () => onPress(item),
      })
  }

  const onShowDefault = (item: T) => {
    navigation.navigate(`${item.__typename}Detail` as any, {
      item,
      mealId: item.id,
      previousRoute: route && route.name,
    })
  }

  const onEditDefault = (item: T) => {
    navigation.navigate(`Add${item.__typename}` as any, {
      item,
      screen: `Add${item.__typename}`,
      params: { item },
    })
  }

  const onDeleteDefault = (item: T) => {
    dispatch({
      type: `${pluralize((item.__typename || '').toLowerCase())}/delete`,
      payload: item,
    })
  }

  const onJumpToDateDefault = (item: T) => {
    navigation.navigate('Dashboard', { screen: 'Events' })

    const startDate = moment(item.occurredAt).format(DATE_FORMAT)
    const endDate = startDate

    dispatch({
      type: 'events/changeDateRange',
      payload: {
        startDate,
        endDate,
      },
    })
  }

  const defaultMenuItems = {
    Show: {
      title: 'Show',
      onPress: onShowDefault,
    },
    Edit: {
      title: 'Edit',
      onPress: onEditDefault,
    },
    Delete: {
      title: 'Delete',
      onPress: onDeleteDefault,
    },
    'Jump to Date': {
      title: 'Jump to Date',
      onPress: onJumpToDateDefault,
    },
  }

  const renderMenuItems = () => {
    const menuItemsRender = menuItems?.map((menuItem, index) => {
      const wrapper =
        {
          Delete: onDeleteWrapper,
        }[menuItem.title as 'Delete'] || ((onPress: (item: T) => void) => onPress(item))

      const onPress =
        menuItem.onPress ||
        defaultMenuItems[menuItem.title as keyof typeof defaultMenuItems]?.onPress

      const menuItemOnPress = () => {
        onPress && wrapper(onPress as (item: T) => void)
        setVisible(false)
      }

      return (
        <MenuItem
          key={`${item.id}-${menuItem.title}`}
          title={menuItem.title}
          onPress={menuItemOnPress}
          appearance="overflowMenu"
          accessibilityLabel={menuItem.title as string}
          style={index !== 0 && styles.topBorder}
        />
      )
    })

    // remove any menuItems with missing onPress handlers
    return menuItemsRender?.filter((item) => item.props?.onPress)
  }

  const onItemSelect = () => {
    const menuItems = renderMenuItems()
    if (menuItems?.length === 0) {
      return
    }

    setVisible(false)
  }

  const renderAnchor = () => {
    return (
      <TouchableOpacity
        style={styles.button}
        disabled={disabled}
        onPress={() => setVisible(true)}
        hitSlop={hitSlop}
        accessibilityLabel="cardOverflowAnchor"
      >
        <Icon name="dots-three" />
      </TouchableOpacity>
    )
  }

  return (
    <OverflowMenu
      {...props}
      anchor={renderAnchor}
      visible={visible}
      onSelect={onItemSelect}
      onBackdropPress={() => setVisible(false)}
      style={styles.overflowMenu}
    >
      <View style={styles.container}>{renderMenuItems()}</View>
    </OverflowMenu>
  )
}

const themedStyles = StyleService.create({
  overflowMenu: {
    borderWidth: 0,
    borderRadius: 8,
    zIndex: 10,
  },
  container: {
    overflow: 'hidden',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: 'theme.surface.base',
  },
  button: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 16,
  },
  topBorder: {
    borderTopWidth: 1,
    borderTopColor: 'theme.surface.base2',
  },
})
