import { Model } from '@models'
import { CREATE_CHATBOT_DELIMITER } from '@src/graphql/createChatbotDelimiter'
import { CREATE_CHAT_TOKEN } from '@src/graphql/createChatToken'
import { ChatbotStoreState } from './chatbot.types'

export default class Chatbot {
  namespace = 'chatbot'

  state = {}

  effects = {
    createChatbotDelimiter: Model.buildEffect({
      name: `${this.namespace}/createChatbotDelimiter`,
      query: CREATE_CHATBOT_DELIMITER,
    }),

    createChatbotToken: Model.buildEffect({
      name: `${this.namespace}/createChatbotToken`,
      query: CREATE_CHAT_TOKEN,
      dataPath: 'createChatToken',
    }),

    create: Model.buildEffect({
      name: `${this.namespace}/setLastShownTimestamp`,
      caching: false,
      reducers: [{ name: 'setLastShownTimestamp' }],
    }),
  }

  reducers = {
    ...Model.defaultReducers,

    setLastShownTimestamp: (state: ChatbotStoreState, { payload }: { payload: string }) => ({
      ...state,
      lastShownTimestamp: payload,
    }),
  }
}
