import { Model } from '@models'

import { CREATE_REFILL_CONSENT } from '../graphql/createRefillConsent'
import { RefillConsentsStoreState } from '../types'

export default class RefillConsents {
  namespace = 'refillConsents'

  state = {
    refillConsentNeeded: false,
    ...Model.defaultState,
  }

  effects = {
    create: Model.buildEffect({
      name: `${this.namespace}/create`,
      query: CREATE_REFILL_CONSENT,
      dataPath: 'createRefillConsent',
      reducers: [{ name: 'setRefillConsentNeeded', payload: () => false }],
    }),
  }

  reducers = {
    ...Model.defaultReducers,

    setRefillConsentNeeded: (
      state: RefillConsentsStoreState,
      { payload }: { payload: boolean },
    ) => ({
      ...state,
      refillConsentNeeded: payload,
    }),
  }
}
