import { get } from 'lodash'
import commonReducers from '@src/models/commonReducers'
import defaultReducers from '@src/models/defaultReducers'
import { Activity } from '@src/types'
import { ActivitiesStoreState } from '../types'

const reducers = {
  ...defaultReducers,
  fetchList: commonReducers.fetchList,

  updateActivityFavoriteStatus: (
    state: ActivitiesStoreState,
    {
      payload,
    }: {
      payload: {
        activity: Activity
      }
    },
  ) => {
    const activity = get(payload, 'activity', {})

    const { id, favorite } = activity as Activity

    const favorites = state.favorites.filter((item) => item.id !== id)
    const activityIndex = state.recentActivities.findIndex((item) => item.id === id)

    const newRecentActivities = [...state.recentActivities]
    if (activityIndex >= 0) {
      const newRecentActivity = newRecentActivities[activityIndex]
      newRecentActivities[activityIndex] = {
        ...newRecentActivity,
        favorite,
      }
    }

    if (favorite) {
      return {
        ...state,
        favorites: [...favorites, activity],
        recentActivities: newRecentActivities,
      }
    }

    return {
      ...state,
      favorites,
      recentActivities: newRecentActivities,
    }
  },

  updateRecentActivities: (
    state: ActivitiesStoreState,
    {
      payload: activity = { id: undefined, recentItem: undefined },
    }: { payload: { recentItem?: boolean; id?: string } },
  ) => {
    const { recentItem, id } = activity
    const recentActivities = state.recentActivities.filter((item) => item.id !== id)
    if (recentItem) {
      return {
        ...state,
        recentActivities: [...recentActivities, activity],
      }
    }
    return {
      ...state,
      recentActivities,
    }
  },
}

export default reducers
