import React from 'react'
import { LessonItemContentProps } from '../../lesson.types'
import { LessonItemContentType } from '../../models/courses.types'
import { useLessonCompleted } from '../../hooks'
import { RichTextView } from './RichTextView'
import { LessonItemContainer } from './LessonItemContainer'

export const ReadingComponent = (props: LessonItemContentProps) => {
  const { item, onComplete } = props

  useLessonCompleted(onComplete)

  return (
    <LessonItemContainer
      {...props}
      lessonItemIcon={
        item['__typename'] === LessonItemContentType.Fact ? 'lightbulb-filament' : 'book-open'
      }
      lessonItemTitle={item.title}
    >
      <RichTextView body={item.body} />
    </LessonItemContainer>
  )
}
