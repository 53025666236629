import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/native'

import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { RootStackParamList } from '@src/navigation/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { SubscriptionItem } from '../SubscriptionItem'

const InsuranceOption = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

  const onInsuranceOptionPress = () => {
    navigation.replace('Drawer', { screen: 'Dashboard', params: { screen: 'NutritionistHub' } })
    return
  }

  return (
    <TouchableOpacity
      style={styles.insuranceOptionContainer}
      activeOpacity={0.7}
      onPress={onInsuranceOptionPress}
      accessibilityLabel="Insurance Eligibility Check"
    >
      <View style={styles.row}>
        <Text type="regular" bold lineSpacing="none" style={styles.title}>
          Insurance-covered Appointment
        </Text>
        <Icon name="caret-right" style={styles.icon} />
      </View>
      <Text type="small" style={styles.description}>
        Learn if your insurance covers video consults; available nutritionist might differ from
        yours.
      </Text>

      <View style={styles.priceContainer}>
        <Text type="regular" bold lineSpacing="none">
          Free 60 or 90 minute call if eligible
        </Text>
      </View>
    </TouchableOpacity>
  )
}

export const CheckoutWithInsuranceOption = () => {
  const styles = useStyleSheet(themedStyle)

  const {
    params: { product: item },
  } = useRoute<RouteProp<RootStackParamList, 'CheckoutWithInsuranceOption'>>()

  const navigation = useNavigation()

  return (
    <NavigationContainer title="Select option" style={styles.contentContainer}>
      <View style={styles.container}>
        <SubscriptionItem
          title="Nutritionist Appointment"
          description="Pay now and schedule a call with your nutritionist"
          style={styles.subscriptionItem}
          item={item}
          key={item.id}
          onItemPress={() => {
            navigation.navigate('DietitianMenuCheckout', {
              selection: { product: item },
            })
          }}
        />

        <InsuranceOption />
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  subscriptionItem: {
    minHeight: 136,
    marginBottom: 16,
  },
  insuranceOptionContainer: {
    minHeight: 136,
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderRadius: 16,
    marginBottom: 15,
    borderWidth: 2,
    borderColor: 'theme.secondary.dark',
    backgroundColor: 'theme.secondary.base',
  },
  row: {
    flexDirection: 'row',
  },
  title: {
    flex: 1,
    marginBottom: 4,
  },
  description: {
    marginTop: 4,
    marginBottom: 8,
  },
  icon: {
    width: 16,
    height: 16,
    marginLeft: 16,
    color: 'theme.primary.base',
  },
  priceContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  contentContainer: {
    flexGrow: 1,
  },
  infoAlert: {
    marginVertical: 10,
  },
})
