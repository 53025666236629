import { SensorTypes } from 'react-native-freestyle-libre'
import {
  LIBRE1_SENSOR_WARM_UP_PERIOD_IN_SECONDS,
  LIBRE3_LIVE_WARM_UP_PERIOD_IN_SECONDS,
  LIBRE3_SENSOR_WARM_UP_PERIOD_IN_SECONDS,
} from '../constants'

export const sensorIsWarmingUp = ({
  secondsAfterSensorActivation,
  sensorModel,
  liveDataEnabled,
}: {
  secondsAfterSensorActivation: number
  sensorModel?: SensorTypes
  liveDataEnabled: boolean
}) => {
  const libre3WarmupTime = liveDataEnabled
    ? LIBRE3_LIVE_WARM_UP_PERIOD_IN_SECONDS
    : LIBRE3_SENSOR_WARM_UP_PERIOD_IN_SECONDS
  const sensorWarmUpPeriodInSeconds =
    sensorModel === SensorTypes.Libre3 ? libre3WarmupTime : LIBRE1_SENSOR_WARM_UP_PERIOD_IN_SECONDS
  return secondsAfterSensorActivation < sensorWarmUpPeriodInSeconds
}
