import React from 'react'
import {
  StyleProp,
  ViewStyle,
  TextStyle,
  ActivityIndicator,
  useWindowDimensions,
  View,
} from 'react-native'
import FastImage from 'react-native-fast-image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Device } from '@src/config'
import { IPHONE_7_SCREEN_HEIGHT_BREAKPOINT } from '@src/utils/screenBreakpoints'
import { openUrl } from '@src/utils'
import { Text, TextProps } from '@components/base'
import { useIsDarkMode } from '@src/config/theme'
import { TutorialStep } from '@src/types'
import { ImageWithPlaceholder } from '../Image/FastImageWithPlaceholder'
import { VideoPlayer } from '../VideoPlayer'

const PARAGRAPH_DELIMITING_SYMBOL = '\\p'

const HORIZONTAL_PADDING = 64

interface ContentProps {
  item: TutorialStep
  mediaContainerStyle?: StyleProp<TextStyle>
  descriptionStyle?: StyleProp<TextStyle>
  descriptionContainerStyle?: StyleProp<ViewStyle>
  textType?: TextProps['type']
}

export const Content = ({
  item,
  mediaContainerStyle,
  descriptionStyle,
  descriptionContainerStyle,
  textType = 'regular',
}: ContentProps) => {
  const isDarkMode = useIsDarkMode()
  const styles = useStyleSheet(themedStyles)
  const dimensions = useWindowDimensions()

  const photoWidth = Math.min(
    dimensions.height > IPHONE_7_SCREEN_HEIGHT_BREAKPOINT
      ? dimensions.width - HORIZONTAL_PADDING
      : 275,
    600,
  )

  // height is calculated from width based on average aspect ratio
  // more info: https://github.com/nutrisense/nutrisense-mobile/issues/3553#issuecomment-910201693
  const photoHeight = photoWidth * 0.59

  const { videoUrl, photoUrlLight, photoUrlDark, description, moreUrl } = item

  const photoUrl = isDarkMode ? photoUrlDark : photoUrlLight

  return (
    <>
      {videoUrl ? (
        <View style={styles.media}>
          <VideoPlayer
            source={{ uri: videoUrl }}
            style={{
              ...styles.video,
              width: Device.hasSmallScreen(dimensions) ? 170 : 200,
              height: Device.hasSmallScreen(dimensions) ? 300 : 355,
            }}
          />
        </View>
      ) : (
        photoUrl && (
          <View style={[styles.media, mediaContainerStyle]}>
            <ImageWithPlaceholder
              style={{ width: photoWidth, height: photoHeight }}
              resizeMode={FastImage.resizeMode.contain}
              source={{ uri: photoUrl }}
              placeholderComponent={
                <ActivityIndicator animating size="small" {...styles.spinner} />
              }
            />
          </View>
        )
      )}
      {description &&
        description.split(PARAGRAPH_DELIMITING_SYMBOL).map((paragraph, index) => (
          <View key={index} style={descriptionContainerStyle}>
            <Text type={textType} style={descriptionStyle}>
              {paragraph}
            </Text>
          </View>
        ))}
      {moreUrl && (
        <View style={styles.linkContainer}>
          <Text
            type="regular"
            style={styles.moreUrl}
            accessibilityLabel="Read more"
            onPress={() => openUrl(moreUrl)}
          >
            Read More
          </Text>
        </View>
      )}
    </>
  )
}

const themedStyles = StyleService.create({
  media: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkContainer: {
    paddingBottom: 10,
  },
  video: {
    marginVertical: 16,
  },
  moreUrl: {
    textAlign: 'center',
    color: 'theme.text.link',
  },
  spinner: {
    color: 'theme.surface.base',
  },
})
