import React from 'react'
import { Image, ImageStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { TouchableSection } from '@src/components'
import { IconSource } from '@src/assets/icons'

interface IntegrationTouchableSectionProps {
  sectionTitle: string
  imageSource: IconSource
  onPress: () => void
}

export const IntegrationTouchableSection = ({
  sectionTitle,
  imageSource,
  onPress,
}: IntegrationTouchableSectionProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <TouchableSection
      accessoryLeft={<Image source={imageSource} style={styles.providerImage as ImageStyle} />}
      title={sectionTitle}
      showRightArrow
      style={styles.providersSection}
      onPress={onPress}
    />
  )
}

const themedStyles = StyleService.create({
  providerImage: {
    width: 24,
    height: 24,
    marginRight: 12,
  },
  providersSection: {
    borderBottomWidth: 1,
    borderBottomColor: 'theme.surface.base',
  },
})
