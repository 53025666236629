import { useEffect, useState } from 'react'
import { useChatContext } from 'stream-chat-react-native'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Channel, Event } from 'stream-chat'
import { useSnack } from '@src/utils/navigatorContext'
import {
  authenticatedUserSelector,
  chatbotResponseTimeoutInSecondsSelector,
} from '@src/selectors/app'
import StreamChat from '@src/services/StreamChat'
import { Token } from '@src/types'
import { useDispatchAsync } from '@src/utils'

export const useIsProcessing = () => {
  const responseTimeoutInSeconds = useSelector(chatbotResponseTimeoutInSecondsSelector)
  const { client } = useChatContext()
  const showSnack = useSnack()

  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    let timeoutHandle: ReturnType<typeof setTimeout> | null = null

    const onNewMessage = (event: Event) => {
      const progressInterval: ReturnType<typeof setInterval> | null = null
      if (event?.message?.user?.id === client?.userID) {
        setIsProcessing(true)

        timeoutHandle = setTimeout(() => {
          progressInterval && clearInterval(progressInterval)
          setIsProcessing(false)

          showSnack('Try again! Something went wrong.', null, 'error')
        }, responseTimeoutInSeconds * 1000)
      } else {
        if (event?.message?.nutrisense_type === 'delimiter') {
          return // Do nothing
        }
        setIsProcessing(false)
        timeoutHandle && clearTimeout(timeoutHandle)
      }
    }

    if (client) {
      client.on('message.new', onNewMessage)
    }

    return () => {
      client?.off('message.new', onNewMessage)
      timeoutHandle && clearTimeout(timeoutHandle)
    }
  }, [client, showSnack, responseTimeoutInSeconds])

  return { isProcessing }
}

export const useStreamChat = () => {
  const client = StreamChat.getClient()
  const user = useSelector(authenticatedUserSelector)
  const dispatchAsync = useDispatchAsync()
  const [channel, setChannel] = useState<Channel | null>(null)

  useEffect(() => {
    const connectUser = async () => {
      await client.connectUser(
        {
          id: user.id,
          name: user.fullName,
        },
        async () => (await dispatchAsync<Token>({ type: 'chatbot/createChatbotToken' })).value,
      )

      const channel = client.channel('chatbot-web', 'channel-id-' + user.id, {
        name: 'Health AI',
        members: [user.id],
      })
      await channel.watch()
      setChannel(channel)

      dispatchAsync({ type: 'chatbot/setLastShownTimestamp', payload: moment().toISOString() })
    }

    connectUser()

    return () => {
      setChannel(null)
      client.disconnectUser().then(() => console.log('connection closed'))
    }
  }, [user.id, user.fullName, client, dispatchAsync])

  return { client, channel }
}
