import React from 'react'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { tutorialsSelector } from '@src/selectors/app'
import { Stats } from '@src/screens/Events/models/events.types'
import { ScoreBreakdown } from '@src/components/ScoreBreakdown'
import { BottomSheet } from '@components'
import { useGoBack } from '@src/utils'
import { useScoreStatistics } from '../../utils/useScoreStatistics'
import { GlucoseScoreGauge } from './GlucoseScoreGauge'

const METRIC_KEYS = ['scorePeak', 'scoreMean', 'scoreTimeOutsideRange', 'scoreStdDev']

export const GlucoseScoreModal = () => {
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()

  const { score } = useScoreStatistics()
  const tutorials = useSelector(tutorialsSelector)
  const [tutorial] = tutorials.dailyGlucoseScore

  const values = METRIC_KEYS.map((key) => ({
    ...tutorials[key][0],
    key,
    value: score[key as keyof Stats] ?? 0,
  }))

  return (
    <BottomSheet header={tutorial.title} primaryButton={{ text: 'Got it', onPress: goBack }}>
      <GlucoseScoreGauge score={score.score ?? 0} variant="medium" style={styles.gauge} />
      {!!tutorial.description && <Text type="regular">{tutorial.description}</Text>}
      <ScoreBreakdown values={values} style={styles.scoreBreakdown} />
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  gauge: {
    alignSelf: 'center',
    marginBottom: 16,
    marginTop: 20,
  },
  scoreBreakdown: {
    marginTop: 16,
  },
})
