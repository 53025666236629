import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment, { Moment } from 'moment'
import { useRoute } from '@react-navigation/native'
import { useGoBack } from '@src/utils/navigation'
import { useHideSnack, useSnack } from '@src/utils/navigatorContext'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { calendarDateSelector } from '@src/selectors/app'
import { eventsCalendarSelector } from '@src/selectors/events'
import { mealsCalendarSelector } from '@src/selectors/meals'
import { insightsCalendarSelector } from '@src/screens/Insights/models/insights.selectors'
import { DateRangePickerRef } from '@components/DateRangePicker/DateRangePicker.types'
import { CalendarModalComponent } from '../components/CalendarModal'
import { AppRouteProp } from '../types'

const DATE_SELECTOR_MAP = {
  app: calendarDateSelector,
  events: eventsCalendarSelector,
  insights: insightsCalendarSelector,
  meals: mealsCalendarSelector,
}

const LOAD_CALENDAR_EFFECT_MAP = {
  app: 'app/fetchCalendarData',
  events: 'app/fetchEventsCalendarData',
  insights: 'app/fetchCalendarData',
  meals: 'meals/fetch',
}

export type CalendarType = keyof typeof DATE_SELECTOR_MAP

export const CalendarModal = () => {
  const route = useRoute<AppRouteProp<'ChangeDateRange'>>()
  const type = (route.params.type as CalendarType) || 'app'
  const dateSelector = DATE_SELECTOR_MAP[type]
  const calendar = useSelector(dateSelector)
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const hideSnack = useHideSnack()
  const goBack = useGoBack()
  const calendarModalRef = useRef<DateRangePickerRef>(null)

  useEffect(() => {
    return () => {
      hideSnack()
    }
  }, [hideSnack])

  const onDateChange = useCallback(
    (startDate: string, endDate: string) => {
      dispatch({
        type: `${type}/changeDateRange`,
        payload: { startDate, endDate },
        success: () => {
          goBack()
        },
        failure: (error: string) => {
          calendarModalRef.current?.reset()
          showSnack(error, undefined, 'error')
        },
      })
    },
    [dispatch, goBack, showSnack, type],
  )

  const loadCalendarData = useCallback(
    (date: Moment) => {
      const payload = {
        filter: {
          startDate: date.startOf('month').format(DATE_FORMAT),
          endDate: moment.min(date.endOf('month'), moment()).format(DATE_FORMAT),
        },
      }

      dispatch({
        type: LOAD_CALENDAR_EFFECT_MAP[type],
        payload,
      })
    },
    [dispatch, type],
  )

  const onDatePickerRangeSelectionStart = () => {
    showSnack('You are in range mode now, please choose end date')
  }

  return (
    <CalendarModalComponent
      ref={calendarModalRef}
      calendar={calendar}
      allowRangeSelection={type !== 'events'}
      loadCalendarData={loadCalendarData}
      onCancelButtonPress={goBack}
      onDateChange={onDateChange}
      onDatePickerRangeSelectionStart={onDatePickerRangeSelectionStart}
    />
  )
}
