import React, { Fragment } from 'react'
import { View } from 'react-native'
import { Control, FieldValues } from 'react-hook-form'
import { StyleService } from '@src/style/service'
// eslint-disable-next-line max-len
import { SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind as FollowupKinds } from '@src/types'
import { QuestionnaireQuestion } from '../../screens/QuestionnaireQuestionScreen'
import { RadioInputConfig } from '../../types'
import { InputPropsBase } from './types'
import { Option, OptionType } from './Shared/Option'
import { useAnswersGroups, useSetValueObject } from './Shared/hooks'

interface RadioInputProps extends InputPropsBase {
  response: RadioInputConfig
  questionIndex?: number
  sectionIndex?: number
  control: Control<FieldValues>
}

type RadioFieldValue = {
  value: string | boolean
}

export const RadioInput = ({
  field,
  response,
  questionIndex,
  sectionIndex,
  control,
}: RadioInputProps) => {
  const answersGroups = useAnswersGroups(response.answersGroups)

  const fieldValue: RadioFieldValue = field.value

  const onChange = field.onChange

  useSetValueObject({ onChange, fieldValue, answersGroups })

  return answersGroups?.map((group) =>
    group.answers.map((answer) => {
      const responseValue = typeof fieldValue === 'object' ? fieldValue.value : fieldValue
      const isSelected = responseValue === answer.value

      return (
        <Fragment key={`${answer.value}`}>
          <Option
            label={answer.label}
            selected={isSelected}
            onPress={() => {
              onChange({ value: answer.value })
            }}
            type={OptionType.Radio}
          />
          {isSelected &&
            answer.followups.flatMap((followup) => {
              if (followup.kind !== FollowupKinds.Inline) {
                return []
              }

              return (
                <View style={styles.followupContainer} key={followup.question.key}>
                  <QuestionnaireQuestion
                    {...followup.question}
                    isFollowup
                    sectionIndex={sectionIndex}
                    questionIndex={questionIndex}
                    control={control}
                    fieldKey={followup.question.key}
                  />
                </View>
              )
            })}
        </Fragment>
      )
    }),
  )
}

const styles = StyleService.create({
  followupContainer: {
    marginBottom: 16,
  },
})
