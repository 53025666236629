import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { useGoBack } from '@src/utils/navigation'
import { AppStackParamList } from '@src/navigation/types'
import { FadeFromBottomModal, Feature, useFeatureFlag } from '@components'
import { useDexcomIntegration, useLibre3NativeEnabled } from '@src/utils/hooks'
import { getTutorialGroup } from '@src/utils/tutorials'
import { openUrl } from '@src/utils'
import { researchFaqsSelector } from '@src/selectors/app'

export const SensorActivationContainer = () => {
  const styles = useStyleSheet(themedStyles)
  const isLibreLinkup = useFeatureFlag(Feature.LibrelinkupConnection)
  const { uxSensorModePendingLibre3 } = useLibre3NativeEnabled()
  const isDexcomIntegration = useDexcomIntegration()
  const researchFaqs = useSelector(researchFaqsSelector)

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const route = useRoute<RouteProp<AppStackParamList, 'CoursesSensorActivation'>>()
  const goBack = useGoBack()

  const handleCancelPress = () => {
    goBack()
  }

  const handleActivatePress = () => {
    if (isDexcomIntegration) {
      openUrl(researchFaqs.gettingStartedUrl)
    } else if (isLibreLinkup || uxSensorModePendingLibre3) {
      navigation.replace('TutorialsGroup', {
        group: getTutorialGroup(uxSensorModePendingLibre3),
      })
    } else {
      navigation.replace('CGMEducation', {
        parentScreen: 'CoursesModule',
        parentScreenParams: route.params,
      })
    }
  }

  return (
    <FadeFromBottomModal closeAccessory="none">
      <View style={styles.container}>
        <View style={styles.sensorIcon}>
          <View style={styles.sensorIconOuterRing} />
          <View style={styles.sensorIconInnerRing} />
        </View>
        <Text type="title-3" style={styles.title}>
          Activate your sensor
        </Text>
        <Text type="regular" style={styles.description}>
          Please activate your sensor before you start the next module. It is recommended to have an
          active sensor to get the most out of this program.
        </Text>
        <Button
          type="primary"
          size="block"
          style={styles.actionButton}
          accessibilityLabel="OK"
          onPress={handleActivatePress}
        >
          OK, got it
        </Button>
        <Button
          type="transparent"
          size="block"
          accessibilityLabel="Skip"
          onPress={handleCancelPress}
        >
          Skip
        </Button>
      </View>
    </FadeFromBottomModal>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 16,
  },
  sensorIcon: {
    width: 30,
    height: 30,
    marginBottom: 16,
  },
  sensorIconOuterRing: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 30,
    height: 30,
    borderWidth: 2,
    borderRadius: 15,
    borderColor: 'theme.text.primary',
  },
  sensorIconInnerRing: {
    position: 'absolute',
    top: 5,
    left: 5,
    width: 20,
    height: 20,
    borderWidth: 3,
    borderRadius: 10,
    borderColor: 'theme.text.primary',
  },
  title: {
    textAlign: 'center',
    marginBottom: 8,
  },
  description: {
    marginBottom: 32,
    color: 'theme.text.secondary',
    textAlign: 'center',
  },
  actionButton: {
    marginBottom: 8,
  },
})
