import React, { MutableRefObject } from 'react'
import { useSelector } from 'react-redux'
import { EventsList } from '@src/screens/Events/components/EventsList'
import { Timeline } from '@src/screens/Events/components/Timeline'
import { eventsCalendarSelector } from '@src/selectors/events'
import { useCoursesEngagementPrompt } from '@src/screens/Learn/hooks'
import { useAllMemberNotes } from '@src/screens/NutritionistHub'
import { VideoCallsUpsellBanner } from '@screens/Events/components/VideoCallsUpsellBanner.tsx'
import { useOnboardingModals } from '../utils/hooks'
import { EventListIndexPath } from '../models/events.types'
import { OnboardingChecklistBanner } from './OnboardingChecklistBanner'

const PAGINATION_CONFIG = { pageSize: 20 }

interface EventsComponentProps {
  listRef: MutableRefObject<any>
  primaryChartRef: MutableRefObject<any>
  secondaryChartRef: MutableRefObject<any>
  events: any
  highlightedIndex?: EventListIndexPath
  onChartLoadEnd: () => void
  onDataLoadStart: (useCache: boolean) => void
  onChartHover: (payload: any) => void
  multiSliderValuesChange: (values: [number, number]) => void
  onViewableItemsChanged: (info: any) => void
  onDragStart: () => void
  onDragEnd?: () => void
  onMomentumScrollEnd: (event: any) => void
}

const EventsComponent = (props: EventsComponentProps) => {
  const {
    listRef,
    primaryChartRef,
    secondaryChartRef,
    events = [],
    highlightedIndex,
    onChartLoadEnd,
    onDataLoadStart,
    onChartHover,
    multiSliderValuesChange,
    onViewableItemsChanged,
    onDragStart,
    onDragEnd,
    onMomentumScrollEnd,
  } = props

  const { startDate, endDate } = useSelector(eventsCalendarSelector)

  useOnboardingModals()
  useCoursesEngagementPrompt()
  useAllMemberNotes()

  return (
    <>
      <OnboardingChecklistBanner />
      <VideoCallsUpsellBanner />
      <Timeline
        primaryChartRef={primaryChartRef}
        secondaryChartRef={secondaryChartRef}
        onChartHover={onChartHover}
        onChartLoadEnd={onChartLoadEnd}
        multiSliderValuesChange={multiSliderValuesChange}
      />
      <EventsList
        listRef={listRef}
        name="events"
        dataPath="events.events"
        items={events}
        highlightedIndex={highlightedIndex}
        filter={{
          scope: 'events',
          startDate,
          endDate,
        }}
        pagination={PAGINATION_CONFIG}
        onDataLoadStart={onDataLoadStart}
        useCache
        onViewableItemsChanged={onViewableItemsChanged}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onMomentumScrollEnd={onMomentumScrollEnd}
      />
    </>
  )
}

export const Events = React.memo(EventsComponent)
