import React, { useMemo } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScrollViewWithFade, SearchBar } from '@src/components'
import { NavigationContainer } from '@src/screens/Common/containers'
import { MemberNoteCard } from '@src/screens/NutritionistHub/components/MemberNotes/MemberNoteCard'
import { allMemberNotesSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { useNotesUpdateInfo } from '@src/screens/NutritionistHub/hooks/nutritionistNotes/useNotesUpdateInfo'

const SEARCH_DELAY = 500

export const MemberNotes = () => {
  const styles = useStyleSheet(themedStyles)
  const notes = useSelector(allMemberNotesSelector)
  const { nutritionistName, updatedLast } = useNotesUpdateInfo(notes)
  const [filteredNotes, setFilteredNotes] = React.useState(notes)

  const handleSearchTextChange = useMemo(
    () =>
      debounce((search: string) => {
        if (search === '') {
          setFilteredNotes(notes)
          return
        }
        const nextFilteredNotes = notes.filter((note) => {
          return note.body.includes(search)
        })
        setFilteredNotes(nextFilteredNotes)
      }, SEARCH_DELAY),
    [notes],
  )

  return (
    <NavigationContainer title="Video call notes">
      <Text type="small" style={styles.subtitle}>
        {`Last updated ${updatedLast} by ${nutritionistName}`}
      </Text>
      <SearchBar onChangeText={handleSearchTextChange} />
      <ScrollViewWithFade style={styles.container}>
        <View style={styles.content}>
          <View style={styles.notesContainer}>
            {filteredNotes.map((note) => (
              <MemberNoteCard key={note.id} note={note} hidePinnedAt />
            ))}
          </View>
        </View>
      </ScrollViewWithFade>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  subtitle: { alignSelf: 'center', color: 'theme.text.secondary', top: -8 },
  content: {
    flex: 1,
  },
  notesContainer: {
    paddingHorizontal: 16,
    marginBottom: 16,
    gap: 8,
  },
})
