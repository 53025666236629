import {
  reject,
  zipObject,
  camelCase,
  concat,
  flatMap,
  last,
  pickBy,
  sortBy,
  Dictionary,
  upperFirst,
  cloneDeepWith,
} from 'lodash'
import moment from 'moment'
import { HealthValue } from 'react-native-health'
import { Bugsnag, Unleash } from '@src/config'
import { Feature } from '@components'
import { buildQueries as buildQueriesHealthKit } from '../HealthKit/healthKit'

export const GRANULARITY_CUTOFF = 13

export type HealthDataSampleResult = Dictionary<HealthValue[]>

type HealthSampleTransformType = ({
  samples,
  ignore,
  sourceIgnore,
}: {
  samples: HealthDataSampleResult
  ignore: string[]
  sourceIgnore: string[]
}) => HealthDataSampleResult

export interface HealthServiceParams {
  buildQueries: typeof buildQueriesHealthKit
  transform: HealthSampleTransformType
  lastSynced: string
  ignore: string[]
  sourceIgnore: string[]
}

export interface HealthServiceConstants {
  AGGREGATE_MEASUREMENT_TYPES: readonly string[]
  MEASUREMENT_TYPES: readonly string[]
  ACTIVITY_TYPES: readonly string[]
  DEBUG: boolean
  DATA_SOURCE: string
}

export const getSamples = async (
  { buildQueries, transform, lastSynced, ignore = [], sourceIgnore = [] }: HealthServiceParams,
  {
    AGGREGATE_MEASUREMENT_TYPES,
    MEASUREMENT_TYPES,
    ACTIVITY_TYPES,
    DEBUG,
    DATA_SOURCE,
  }: HealthServiceConstants,
) => {
  const sourceName = upperFirst(camelCase(DATA_SOURCE))
  const startDate = startDateTimestamp(lastSynced)

  try {
    const endDate = moment().toISOString()

    if (DEBUG) {
      console.log(`${sourceName} Start: `, startDate)
      console.log(`${sourceName} End: `, endDate)
    }

    const aggregateMeasurementTypes = reject(AGGREGATE_MEASUREMENT_TYPES, (t) => ignore.includes(t))
    const measurementTypes = reject(MEASUREMENT_TYPES, (t) => ignore.includes(t))
    const activityTypes = reject(ACTIVITY_TYPES, (t) => ignore.includes(t))

    const aggregateMeasurementQueries = buildQueries(aggregateMeasurementTypes, startDate, endDate)
    const measurementQueries = buildQueries(measurementTypes, startDate, endDate)
    const activityQueries = buildQueries(activityTypes, startDate, endDate)

    const allTypes = concat(aggregateMeasurementTypes, measurementTypes, activityTypes)
    const allQueries = concat(
      aggregateMeasurementQueries,
      measurementQueries,
      activityQueries,
    ) as Promise<HealthValue[]>[]

    const rawSamples = await Promise.all(allQueries)
    const samples = filterOldSamples(
      adjustTimestamps(zipObject(allTypes.map(camelCase), rawSamples)),
    )

    if (DEBUG) {
      console.log(`${sourceName} Samples: `, samples)
    }

    let includeCurrentDay: boolean
    try {
      includeCurrentDay = Unleash.client.isEnabled(Feature.IncludeCurrentDayInHealthDataSubmission)
    } catch (error: unknown) {
      Bugsnag.notify(error as string)
      includeCurrentDay = false
    }

    const filteredSamples = includeCurrentDay
      ? samples
      : filterOngoingMeasurements(samples, AGGREGATE_MEASUREMENT_TYPES)

    if (DEBUG) {
      console.log(`${sourceName} Filtered Samples: `, filteredSamples)
    }

    const transformedSamples = transform({ samples: filteredSamples, ignore, sourceIgnore })

    if (DEBUG) {
      console.log(`${sourceName} Transformed Samples: `, transformedSamples)
    }

    return { ...transformedSamples, source: DATA_SOURCE }
  } catch (error: unknown) {
    if (DEBUG) {
      console.log(`${sourceName} Error: `, error)
    }

    Bugsnag.notify(error as string)
    return { source: DATA_SOURCE }
  }
}

export const getLastTimestamp = (samples: HealthDataSampleResult) => {
  const adjustedSamples = pickBy(samples, (s) => Array.isArray(s))

  const lastSampleTimestamp = last(sortBy(flatMap(adjustedSamples), ['startDate']))?.startDate

  return lastSampleTimestamp && moment(lastSampleTimestamp).add(1, 'milliseconds').toISOString()
}

export const adjustTimestamps = (samples: HealthDataSampleResult) => {
  const result: HealthDataSampleResult = {}

  Object.keys(samples).forEach((key) => {
    const currentSamples = samples[key]

    result[key] = currentSamples.map((sample: any) => {
      if (sample.start && !sample.startDate) {
        const startDate = sample.start
        const endDate = sample.end
        return { ...sample, startDate, endDate }
      }
      return sample
    })
  })
  return result
}

const startDateTimestamp = (lastSynced: string) => {
  const defaultStartDate = moment().startOf('day').subtract(7, 'd').toISOString()
  // we add 1 day gap to correctly fetch aggregate types from previous day
  return lastSynced ? moment(lastSynced).subtract(1, 'd').toISOString() : defaultStartDate
}

// Temp filter for users whose sync stopped months ago, so we collect only 2 weeks
const filterOldSamples = (samples: HealthDataSampleResult) => {
  const result: HealthDataSampleResult = {}

  Object.keys(samples).forEach((key) => {
    const currentSamples = samples[key]

    result[key] = currentSamples.filter((sample) => moment().diff(sample.startDate, 'days') < 14)
  })
  return result
}

const filterOngoingMeasurements = (
  samples: HealthDataSampleResult,
  AGGREGATE_MEASUREMENT_TYPES: readonly string[],
) => {
  const result: HealthDataSampleResult = {}

  Object.keys(samples).forEach((key) => {
    const currentSamples = samples[key]

    if (AGGREGATE_MEASUREMENT_TYPES.includes(upperFirst(camelCase(key)))) {
      result[key] = removeOngoingAggregateMeasurements(currentSamples)
    } else {
      result[key] = currentSamples
    }
  })

  return result
}

// filter out aggregate daily measurements for today
const removeOngoingAggregateMeasurements = (samples: HealthValue[]) => {
  const filterFunc = (date: string) => moment().diff(date, 'days') > 0

  const samplesWithFilteredSteps = samples.map((sample) => {
    if ('steps' in sample) {
      return {
        ...sample,
        value: (sample as any).steps.filter((stepData: any) => filterFunc(stepData.date)),
      } as HealthValue
    }

    return sample
  })

  return samplesWithFilteredSteps.filter((sample) => {
    let foundDate: string | null = null

    cloneDeepWith(sample, (v, k) => {
      if (k === 'date' && !foundDate) {
        foundDate = v
      }
    })

    const sampleDate = sample.startDate || foundDate

    return filterFunc(sampleDate as string)
  })
}
