import React from 'react'
import { ScrollView } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Feature, useFeatureFlag } from '@src/components'
import { User } from '@src/utils'
import { MobileAppFeature } from '@src/types'
import AdminSection from './AdminSection'
import SystemSection from './SystemSection'
import SensorSection from './SensorSection'
import IntegrationsSection from './IntegrationsSection'
import ExportDataSection from './ExportDataSection'
import ClearCachesSection from './ClearCachesSection'
import ManageSubscriptionSection from './ManageSubscriptionSection'
import CancellationPolicySection from './CancellationPolicySection'
import PrivacyPolicySection from './PrivacyPolicySection'
import TermsOfUseSection from './TermsOfUseSection'
import VersionSection from './VersionSection'
import NotificationsSection from './NotificationsSection'

const SettingsComponent = () => {
  const styles = useStyleSheet(themedStyles)
  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)
  const displaySensorSettingsScreen =
    !useFeatureFlag(Feature.AbbottPartnership) && isCGMFeatureAvailable

  return (
    <ScrollView style={styles.container}>
      <AdminSection />
      <NotificationsSection />
      <SystemSection />
      {displaySensorSettingsScreen && <SensorSection />}
      <IntegrationsSection />
      {isCGMFeatureAvailable && <ExportDataSection style={styles.infoSection} />}
      <ClearCachesSection />
      <ManageSubscriptionSection />
      <CancellationPolicySection style={[styles.policySection, styles.infoSection]} />
      <PrivacyPolicySection style={styles.policySection} />
      <TermsOfUseSection style={styles.policySection} />
      <VersionSection style={[styles.policySection, styles.infoSection]} />
    </ScrollView>
  )
}

export default SettingsComponent

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  infoSection: {
    marginTop: 16,
  },
  policySection: {
    height: 48,
    paddingVertical: 0,
    paddingHorizontal: 24,
  },
})
