import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'
import { useWebPresentationModeContext } from '@src/config/webPresentationMode'

export const useWebPresentationModeLogoutIcon = () => {
  const styles = useStyleSheet(themedStyles)
  const { isWebPresentationMode } = useWebPresentationModeContext()
  const dispatch = useDispatch()

  const handleLogoutPress = () => {
    dispatch({ type: 'app/logout' })
  }

  const logoutLink = (
    <TouchableOpacity
      accessibilityLabel="logout-link"
      onPress={handleLogoutPress}
      style={styles.button}
    >
      <Text type="small">Exit</Text>
    </TouchableOpacity>
  )

  return isWebPresentationMode ? logoutLink : null
}

const themedStyles = StyleService.create({
  button: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
  },
})
