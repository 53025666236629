import { createContext, useContext } from 'react'
import { SnackLevel } from '@src/navigation/components/SnackContent'

interface NavigatorContextInjectedProps {
  showSnack: (snackTitle: string, snackContent?: string | null, level?: SnackLevel) => void
  hideSnack: () => void
  enableLoader: () => void
  disableLoader: () => void
}

export const NavigatorContext = createContext<NavigatorContextInjectedProps>({
  enableLoader: () => undefined,
  disableLoader: () => undefined,
} as NavigatorContextInjectedProps)

export const useSnack = () => {
  const { showSnack } = useContext(NavigatorContext) as NavigatorContextInjectedProps

  return showSnack
}

export const useHideSnack = () => {
  const { hideSnack } = useContext(NavigatorContext) as NavigatorContextInjectedProps

  return hideSnack
}
