import moment from 'moment'
import { useSelector } from 'react-redux'
import { useContext, useEffect, useMemo } from 'react'
import { useNavigation } from '@react-navigation/core'
import { SensorTypes } from 'react-native-freestyle-libre'
import { Alert } from 'react-native'
import { NavigationProp, useIsFocused } from '@react-navigation/native'
import { useFlagsStatus } from '@unleash/proxy-client-react'
import { AnyAction } from 'redux'
import Config from 'react-native-config'
import { sensorSelector } from '@src/selectors/sensor'
import Storage from '@src/utils/storage'
import { shouldRequestInAppReview } from '@src/utils/appReview'
import { refillConsentNeededSelector } from '@src/selectors/refillConsents'
import { useShouldShowPurchaseFlow } from '@src/utils/hooks'
import {
  ApprovalRequest,
  UserApprovalRequestState,
  BillingProduct,
  Sensor,
  SurveysConfigKind,
  SurveyLinkCollection,
  SurveysQuestionKey,
} from '@src/types'
import { TempAppState, TempAppStateContext } from '@src/context/tempAppStateContext'
import { skippedSurveysSelector, userSelector } from '@src/selectors/app'
import { User, useDispatchAsync } from '@src/utils'
import { useActiveSubscriptions } from '@src/hooks/useActiveSubscriptions'
import { TutorialGroups } from '@src/screens/Tutorials/containers/TutorialsGroup'
import { Feature, useFeatureFlag } from '@src/components'
import { BuildType } from '@src/config/app'
import { RootStackParamList } from '@src/navigation/types'
import { Device } from '@src/config'

export const markModalAsShown = (key: string) => {
  Storage.set(key, true)
}

export const shouldShowModalOnce = (key: string) => {
  const showModal = Storage.get<boolean>(key)

  if (showModal) {
    return false
  }

  return true
}

const getStoreReviewPromptStateInteractions = ({ sensor }: { sensor: Sensor | undefined }) => ({
  shouldShow: () => {
    const lastPromptDate = Storage.get<string>(Storage.LAST_REVIEW_PROMPT_DATE_KEY)
    const hasLeftReview = Storage.get(Storage.REVIEW_LEFT_KEY, false)
    const reviewPromptsCount = Storage.get(Storage.REVIEW_PROMPTS_COUNT_KEY, 0)

    return shouldRequestInAppReview({
      hasLeftReview,
      reviewPromptsCount,
      lastPromptDate,
      sensorExpirationTime: sensor?.expirationTime,
    })
  },

  markAsShown: () => {
    const reviewPromptsCount = Storage.get(Storage.REVIEW_PROMPTS_COUNT_KEY, 0)
    Storage.set(Storage.LAST_REVIEW_PROMPT_DATE_KEY, moment().toISOString())
    Storage.set(Storage.REVIEW_PROMPTS_COUNT_KEY, reviewPromptsCount + 1)
  },
})

const getSensorInfoModalStateInteractions = (sensor: Sensor | undefined) => {
  const isLibre3 = sensor?.model === SensorTypes.Libre3
  const sensorSerialNumber = sensor?.serialNumber
  const daysAfterActivation = sensor?.activationTime
    ? moment().diff(moment(sensor.activationTime), 'days')
    : 0

  return {
    shouldShow: () =>
      !isLibre3 &&
      !!sensorSerialNumber &&
      daysAfterActivation >= 1 &&
      shouldShowModalOnce(Storage.SENSOR_INFO_MODAL_VISITED_KEY),
    markAsShown: () => markModalAsShown(Storage.SENSOR_INFO_MODAL_VISITED_KEY),
  }
}

const getPermissionsModalStateInteractions = () => {
  return {
    shouldShow: () => shouldShowModalOnce(Storage.PERMISSIONS_MODAL_VISITED_KEY) && !Device.web,
    markAsShown: () => markModalAsShown(Storage.PERMISSIONS_MODAL_VISITED_KEY),
  }
}
const getEducationModalStateInteractions = ({ userId }: { userId?: string }) => {
  const educationModalAsyncStorageKey = `${Storage.EDUCATION_MODAL_VISITED_KEY}_${userId}`
  return {
    shouldShow: () => !!userId && shouldShowModalOnce(educationModalAsyncStorageKey),
    markAsShown: () => markModalAsShown(educationModalAsyncStorageKey),
  }
}

const getHealthQuestionnaireApprovalModalStateInteractions = ({
  approvalRequest,
  healthQuestionnaireWasSkipped,
}: {
  approvalRequest: Pick<ApprovalRequest, 'id' | 'state'> | null | undefined
  healthQuestionnaireWasSkipped?: boolean
}) => {
  const asyncStorageKey = `${Storage.HEALTH_QUESTIONNAIRE_APPROVAL_MODAL_VISITED_KEY}_${approvalRequest?.id}`
  return {
    shouldShow: () =>
      !healthQuestionnaireWasSkipped &&
      !!approvalRequest &&
      (approvalRequest.state === UserApprovalRequestState.PendingApproval ||
        approvalRequest.state === UserApprovalRequestState.PendingRejection) &&
      shouldShowModalOnce(asyncStorageKey),
    markAsShown: () => markModalAsShown(asyncStorageKey),
  }
}

const legacyShouldShowGoalsQuestionnaireModal = async (
  navigation: Pick<NavigationProp<RootStackParamList>, 'navigate'>,
  dispatchAsync: <T>(action: AnyAction) => Promise<T>,
  isImpersonating: boolean,
  goalsQuestionnaireWasShown: boolean,
  setTempAppState: ({ goalsQuestionnaireWasShown }: TempAppState) => void,
  userId?: string,
) => {
  const MAX_GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT = 3
  const goalsQuestionnaireAsyncStorageKey = `${Storage.GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT_KEY}_${userId}`
  if (isImpersonating || goalsQuestionnaireWasShown) {
    return false
  }

  const goalsQuestionnaireModalDisplayCount = Storage.get(goalsQuestionnaireAsyncStorageKey, 0)

  if (goalsQuestionnaireModalDisplayCount >= MAX_GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT) {
    // we've already shown this modal MAX_GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT times
    return false
  }

  const { surveyLinks }: SurveyLinkCollection = await dispatchAsync({
    type: 'app/surveyQuestionnaire',
  })

  const surveyLink = surveyLinks.find((link) => link.survey.kind === SurveysConfigKind.Goals)

  if (surveyLink && surveyLink.finished) {
    // goals questionnaire is filled
    return false
  }

  if (!surveyLink) {
    // filling goals questionnaire for the first time
    return true
  }

  // goals questionnaire is started but not completed
  Alert.alert(
    'Complete questionnaire',
    "You haven't completed Goals Questionnaire. Would you like to resume?",
    [
      {
        text: 'Skip',
        onPress: () => {
          // show skip alert once per session
          setTempAppState({ goalsQuestionnaireWasShown: true })
        },
      },
      {
        text: 'Resume',
        onPress: () => {
          setTempAppState({ goalsQuestionnaireWasShown: true })
          navigation.navigate('Questionnaire', { questionnaire: SurveysConfigKind.Goals })
        },
      },
    ],
  )

  Storage.set(goalsQuestionnaireAsyncStorageKey, goalsQuestionnaireModalDisplayCount + 1)

  return false
}

const DELAY_TIME_IN_MILLISECONDS = 72 * 60 * 60 * 1000 // 72 hours in milliseconds
const TESTING_DELAY_TIME_IN_MILLISECONDS = 5 * 60 * 1000 // 5 minutes in milliseconds

const shouldShowGoalsQuestionnaireModal = async (
  dispatchAsync: <T>(action: AnyAction) => Promise<T>,
  isImpersonating: boolean,
  userId?: string,
) => {
  const MAX_GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT = 1

  const goalsQuestionnaireAsyncStorageKey = `${Storage.GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT_KEY}_${userId}`

  if (isImpersonating) {
    return false
  }

  const goalsQuestionnaireModalDisplayCount = Storage.get(goalsQuestionnaireAsyncStorageKey, 0)

  if (goalsQuestionnaireModalDisplayCount >= MAX_GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT) {
    // we've already shown this modal MAX_GOALS_QUESTIONNAIRE_MODAL_DISPLAY_COUNT times
    return false
  }

  const { surveyLinks }: SurveyLinkCollection = await dispatchAsync({
    type: 'app/surveyQuestionnaire',
  })

  const surveyLink = surveyLinks.find((link) => link.survey.kind === SurveysConfigKind.Goals)

  if (surveyLink?.finished) {
    // goals questionnaire is filled
    return false
  }

  if (!surveyLink) {
    const firstLogin = Storage.get<string>(Storage.APP_FIRST_LOGIN_TIMESTAMP_KEY)

    // use a shorter delay for development builds
    const build = Config.ENV as BuildType
    const delay =
      build === 'production' ? DELAY_TIME_IN_MILLISECONDS : TESTING_DELAY_TIME_IN_MILLISECONDS

    if (!firstLogin || delay > moment().diff(firstLogin)) {
      return false
    }

    // filling goals questionnaire for the first time
    Storage.set(goalsQuestionnaireAsyncStorageKey, goalsQuestionnaireModalDisplayCount + 1)
    return true
  }

  return false
}

const shouldShowAccountInterestQuestionnaireModal = async ({
  dispatchAsync,
  isImpersonating,
  hasOwnSensorSubscription,
}: {
  dispatchAsync: <T>(action: AnyAction) => Promise<T>
  isImpersonating: boolean
  hasOwnSensorSubscription: boolean
}) => {
  if (isImpersonating) {
    return false
  }

  if (!hasOwnSensorSubscription) {
    return false
  }

  const { surveyLinks }: SurveyLinkCollection = await dispatchAsync({
    type: 'app/surveyQuestionnaire',
  })

  const surveyLink = surveyLinks.find(
    (link) => link.survey.kind === SurveysConfigKind.AccountInterest,
  )

  if (surveyLink && surveyLink.finished) {
    // questionnaire is filled
    return false
  }

  return true
}

const shouldShowVideoCallsUpsellModal = async ({
  userId,
  dispatchAsync,
}: {
  userId?: string
  dispatchAsync: <T>(action: AnyAction) => Promise<T>
}) => {
  if (!userId) {
    return false
  }

  const visitedCountKey = `${Storage.VIDEO_CALLS_UPSELL_MODAL_VISITED_COUNT_KEY}_${userId}`
  const visitedCount = Storage.get(visitedCountKey, 0)
  if (visitedCount > 0) {
    return false
  }

  const firstLogin = Storage.get<string>(Storage.APP_FIRST_LOGIN_TIMESTAMP_KEY)
  if (firstLogin && moment().diff(firstLogin, 'days') < 3) {
    return false
  }

  const { surveyLinks }: SurveyLinkCollection = await dispatchAsync({
    type: 'app/surveyQuestionnaire',
  })

  const insuranceSurveyLink = surveyLinks.find(
    (link) => link.survey.kind === SurveysConfigKind.Insurance,
  )

  if (insuranceSurveyLink && insuranceSurveyLink.finished) {
    // questionnaire is filled
    return false
  }

  const insuranceSkipSurveyLink = surveyLinks.find(
    (link) => link.survey.kind === SurveysConfigKind.InsuranceEarlyExit,
  )

  if (
    insuranceSkipSurveyLink?.responses?.find(
      (response) => response.questionKey === SurveysQuestionKey.InsuranceEarlyExitReason,
    )?.answer?.value === 'no_insurance'
  ) {
    return false
  }

  return true
}

export const OWN_SENSOR_PRODUCTS = [
  BillingProduct.SoftwareOnlyOwnSensorAnnualMembership,
  BillingProduct.FreeSoftwareOnlyOwnSensorAnnualMembership,
]

export const useOnboardingModals = () => {
  const user = useSelector(userSelector)
  const isFocused = useIsFocused()
  const { flagsReady, flagsError } = useFlagsStatus()
  const flagsLoaded = flagsReady || flagsError
  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)
  const videoCallFunnelOptimizationEnabled = useFeatureFlag(Feature.VideoCallFunnelOptimization)

  const skippedSurveys = useSelector(skippedSurveysSelector)

  const {
    tempAppState: { goalsQuestionnaireWasShown },
    setTempAppState: markGoalsQuestionnaireAsShown,
  } = useContext(TempAppStateContext)

  const navigation = useNavigation()
  const dispatchAsync = useDispatchAsync()

  const refillConsentNeeded = useSelector(refillConsentNeededSelector)
  const shouldShowPurchaseFlow = useShouldShowPurchaseFlow()

  const sensor = useSelector(sensorSelector)

  const activeSubscriptions = useActiveSubscriptions()

  const showRefillConsentModal = refillConsentNeeded && !shouldShowPurchaseFlow

  const subscriptionIncludesCgm = activeSubscriptions.some(
    (subscription) => subscription.primaryProduct.includesCgm,
  )

  const hasOwnSensorSubscription = activeSubscriptions.some((subscription) =>
    OWN_SENSOR_PRODUCTS.includes(subscription.primaryProduct.key as BillingProduct),
  )
  const userId = user?.id

  const healthQuestionnaireWasSkipped = skippedSurveys[SurveysConfigKind.Health]

  const {
    shouldShow: shouldShowStoreReviewPrompt,
    markAsShown: markStoreReviewPromptAsShown,
  } = useMemo(() => getStoreReviewPromptStateInteractions({ sensor }), [sensor])

  const {
    shouldShow: shouldShowEducationModal,
    markAsShown: markEducationModalAsShown,
  } = useMemo(() => getEducationModalStateInteractions({ userId }), [userId])

  const {
    shouldShow: shouldShowSensorInfoModal,
    markAsShown: markSensorInfoModalAsShown,
  } = useMemo(() => getSensorInfoModalStateInteractions(sensor), [sensor])

  const {
    shouldShow: shouldShowPermissionsModal,
    markAsShown: markPermissionsModalAsShown,
  } = useMemo(() => getPermissionsModalStateInteractions(), [])

  const {
    shouldShow: shouldShowHealthQuestionnaireApprovalModal,
    markAsShown: markHealthQuestionnaireApprovalModalAsShown,
  } = useMemo(
    () =>
      getHealthQuestionnaireApprovalModalStateInteractions({
        approvalRequest: user?.lastPrescriptionApprovalRequest,
        healthQuestionnaireWasSkipped,
      }),
    [healthQuestionnaireWasSkipped, user?.lastPrescriptionApprovalRequest],
  )

  useEffect(() => {
    const handleModals = async () => {
      if (!flagsLoaded || !isFocused) {
        return
      }

      // using the useImpersonationContext leads to a race condition that shows the welcome tour during impersonation
      const isImpersonating = User.isImpersonating()

      if (isImpersonating) {
        return
      }

      const routes: { name: string; params?: any }[] = []

      const showStoreReviewPrompt = shouldShowStoreReviewPrompt()
      const showEducationModal = shouldShowEducationModal()
      const showSensorInfoModal = shouldShowSensorInfoModal()
      const showPermissionsModal = shouldShowPermissionsModal()
      const showHealthQuestionnaireApprovalModal = shouldShowHealthQuestionnaireApprovalModal()

      if (showStoreReviewPrompt) {
        routes.push({ name: 'RateOurAppModal' })
        markStoreReviewPromptAsShown()
      }

      if (videoCallFunnelOptimizationEnabled) {
        const showVideoCallsUpsellModal = await shouldShowVideoCallsUpsellModal({
          userId,
          dispatchAsync,
        })
        if (showVideoCallsUpsellModal) {
          routes.push({ name: 'VideoCallsUpsellModal' })
          const visitedCountKey = `${Storage.VIDEO_CALLS_UPSELL_MODAL_VISITED_COUNT_KEY}_${userId}`
          Storage.set(visitedCountKey, 1)
        }
      }

      if (!appNavigationUpdatesFeatureEnabled && showEducationModal) {
        if (subscriptionIncludesCgm) {
          routes.push({ name: 'EducationModal' })
        } else {
          routes.push({
            name: 'TutorialsGroup',
            params: {
              group: TutorialGroups.LibreNative,
            },
          })
        }
        markEducationModalAsShown()
      }

      if (showSensorInfoModal) {
        routes.push({ name: 'SensorInfoModal' })
        markSensorInfoModalAsShown()
      }
      if (showRefillConsentModal) {
        routes.push({ name: 'RefillConsent' })
      }

      const showAccountInterestQuestionnaireModal = await shouldShowAccountInterestQuestionnaireModal(
        {
          dispatchAsync,
          isImpersonating,
          hasOwnSensorSubscription,
        },
      )

      if (showAccountInterestQuestionnaireModal) {
        routes.push({
          name: 'Questionnaire',
          params: { questionnaire: SurveysConfigKind.AccountInterest },
        })
      } else {
        const showGoalsQuestionnaireModal = await (appNavigationUpdatesFeatureEnabled
          ? shouldShowGoalsQuestionnaireModal(dispatchAsync, isImpersonating, userId)
          : legacyShouldShowGoalsQuestionnaireModal(
              navigation,
              dispatchAsync,
              isImpersonating,
              goalsQuestionnaireWasShown,
              markGoalsQuestionnaireAsShown,
              userId,
            ))
        if (showGoalsQuestionnaireModal) {
          routes.push({
            name: 'Questionnaire',
            params: { questionnaire: SurveysConfigKind.Goals },
          })
          !appNavigationUpdatesFeatureEnabled &&
            markGoalsQuestionnaireAsShown({ goalsQuestionnaireWasShown: true })
        }
      }

      if (showPermissionsModal) {
        routes.push({ name: 'PermissionsCarousel' })
        markPermissionsModalAsShown()
      }

      if (showHealthQuestionnaireApprovalModal) {
        routes.push({ name: 'HQApproval' })
        markHealthQuestionnaireApprovalModalAsShown()
      }

      routes.forEach(({ name, params }) => {
        navigation.navigate(name as any, params)
      })
    }

    handleModals()
  }, [
    appNavigationUpdatesFeatureEnabled,
    videoCallFunnelOptimizationEnabled,
    dispatchAsync,
    flagsLoaded,
    goalsQuestionnaireWasShown,
    hasOwnSensorSubscription,
    isFocused,
    markEducationModalAsShown,
    markGoalsQuestionnaireAsShown,
    markHealthQuestionnaireApprovalModalAsShown,
    markPermissionsModalAsShown,
    markSensorInfoModalAsShown,
    markStoreReviewPromptAsShown,
    navigation,
    shouldShowEducationModal,
    shouldShowHealthQuestionnaireApprovalModal,
    shouldShowPermissionsModal,
    shouldShowSensorInfoModal,
    shouldShowStoreReviewPrompt,
    showRefillConsentModal,
    subscriptionIncludesCgm,
    userId,
  ])
}
