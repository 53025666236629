import React from 'react'
import { useDispatch } from 'react-redux'
import { TouchableSection } from '@components'
import { useSnack } from '@src/utils/navigatorContext'

const ClearCachesSection = () => {
  const dispatch = useDispatch()

  const showSnack = useSnack()

  const onPress = () => {
    dispatch({
      type: 'app/clearCaches',
      success: () => showSnack('Cache Cleared!'),
      failure: () => showSnack('Cache Clear Failed!', null, 'error'),
    })
  }

  return <TouchableSection onPress={onPress} iconName="trash" title="Clear Caches" />
}

export default ClearCachesSection
