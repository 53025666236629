import React from 'react'
import moment from 'moment'
import { useGoBack } from '@utils/navigation'
import { GestureDismissibleAlertModal } from '@components'
import { MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { useSubscriptionFromRoute } from '../../utils/hooks'

export const ChangeBillingCycleSuccess = () => {
  const goBack = useGoBack()
  const { subscription } = useSubscriptionFromRoute()

  if (!subscription) {
    return
  }

  const nextBillingDate = subscription.resetBillingCycleAnchorAt || subscription.currentPeriodEndAt
  const formattedNextBillingDate = moment(nextBillingDate).format(
    MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT,
  )

  return (
    <GestureDismissibleAlertModal
      title="Your shipping and billing dates have been changed."
      description={`Your next order will be processed on\n${formattedNextBillingDate}`}
      actionTitle="Ok"
      onActionPress={goBack}
    />
  )
}
