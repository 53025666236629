import React, { useState } from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Analytics, CustomEventTypes } from '@config'
import { Icon, Button, Text, Input } from '@components/base'
import { FadeFromBottomModal } from '@components'
import { useGoBack } from '@src/utils/navigation'
import { useSnack } from '@src/utils/navigatorContext'

export const DietitianMenuFeedbackModal = () => {
  const styles = useStyleSheet(themedStyles)
  const showSnack = useSnack()
  const goBack = useGoBack()

  const [feedbackText, setFeedbackText] = useState('')

  const handleSendPress = () => {
    Analytics.track(CustomEventTypes.DietitianMenuFeedbackSend, { feedbackText })
    showSnack('Feedback sent!', null, 'success')
    goBack()
  }

  const sendDisabled = feedbackText.length < 5

  return (
    <FadeFromBottomModal closeAccessory="none">
      <View style={styles.container}>
        <Icon name="chats-circle" />
        <Text type="title-2" style={styles.title}>
          Give us feedback
        </Text>
        <Text type="regular" style={styles.description}>
          What is your question? We are always working to expand our support.
        </Text>
        <Input
          numberOfLines={3}
          placeholder="Enter your suggestions here..."
          style={styles.input}
          onChangeText={setFeedbackText}
        />
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Send"
          onPress={handleSendPress}
          disabled={sendDisabled}
        >
          Send
        </Button>
      </View>
    </FadeFromBottomModal>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 16,
  },
  title: {
    marginVertical: 16,
    textAlign: 'center',
  },
  description: {
    marginBottom: 32,
    textAlign: 'center',
  },
  input: {
    width: '100%',
    marginBottom: 32,
  },
})
