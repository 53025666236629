import React, { useState, useCallback, ReactElement } from 'react'
import { Alert } from 'react-native'
import { useDispatch } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { useNavigation, useRoute, RouteProp, useIsFocused } from '@react-navigation/native'
import moment from 'moment'
import { AppStackParamList } from '@src/navigation/types'
import { updateEngagementPromptConfig } from '@src/screens/Learn/utils'
import {
  ReadingComponent,
  VideoComponent,
  UnknownComponent,
  MultipleChoiceComponent,
  PromptComponent,
  AudioComponent,
} from '../components/items'
import { LessonItemContentProps } from '../lesson.types'
import { LessonItemContentMedia, LessonItemContentType } from '../models/courses.types'
import { useCheckModuleCompletion, useNextCourseItem, useResetNavigationToModule } from '../hooks'

const getItemComponent = (props: LessonItemContentProps): ReactElement => {
  switch (props.item['__typename']) {
    case LessonItemContentType.Video:
      return <VideoComponent {...props} item={props.item as LessonItemContentMedia} />
    case LessonItemContentType.Audio:
      return <AudioComponent {...props} item={props.item as LessonItemContentMedia} />
    case LessonItemContentType.Reading:
    case LessonItemContentType.Fact:
      return <ReadingComponent {...props} />
    case LessonItemContentType.MultipleChoice:
      return <MultipleChoiceComponent {...props} />
    case LessonItemContentType.Prompt:
      return <PromptComponent {...props} />
    case LessonItemContentType.Experiment:
      return <UnknownComponent {...props} />
  }
}

export const LessonItemContainer = () => {
  const dispatch = useDispatch()
  const isFocused = useIsFocused()
  const [isItemCompleted, setIsItemCompleted] = useState(false)

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const route = useRoute<RouteProp<AppStackParamList, 'CoursesLessonItem'>>()
  const { lesson, index = 0, parentScreenParams } = route.params || {}
  const item = lesson.items[index]

  const { nextLesson, nextModule } = useNextCourseItem(lesson, parentScreenParams)
  const resetNavigationToModule = useResetNavigationToModule(parentScreenParams)
  const { isModuleComplete, goToModuleCompletion } = useCheckModuleCompletion(
    lesson.id,
    parentScreenParams,
  )

  const isLastItem = index === lesson.items.length - 1

  const onLessonComplete = useCallback(() => {
    updateEngagementPromptConfig({ lastLessonCompletedDate: moment().toISOString() })
    resetNavigationToModule()

    if (isModuleComplete) {
      goToModuleCompletion()
    } else if (nextLesson) {
      const nextScreen = nextLesson.isExperiment ? 'CoursesExperiment' : 'CoursesLesson'

      navigation.navigate(nextScreen, {
        lesson: nextLesson,
        parentScreenParams,
      })
    } else if (nextModule) {
      navigation.replace('CoursesModule', {
        ...parentScreenParams,
        moduleId: nextModule.id,
      })
    } else {
      navigation.replace('CoursesLearn')
    }
  }, [
    goToModuleCompletion,
    isModuleComplete,
    navigation,
    nextLesson,
    nextModule,
    parentScreenParams,
    resetNavigationToModule,
  ])

  const onBack = useCallback(() => {
    if (!isFocused) {
      return
    }
    index !== 0 && navigation.pop()
  }, [index, navigation, isFocused])

  const onNext = useCallback(() => {
    if (!isItemCompleted || !isFocused) {
      return
    }

    dispatch({
      type: 'courses/recordLessonProgress',
      payload: { lessonId: lesson.id, complete: isLastItem },
    })

    if (isLastItem) {
      onLessonComplete()
    } else {
      navigation.push('CoursesLessonItem', { lesson, index: index + 1, parentScreenParams })
    }
  }, [
    dispatch,
    lesson,
    isLastItem,
    isItemCompleted,
    isFocused,
    index,
    onLessonComplete,
    navigation,
    parentScreenParams,
  ])

  const onComplete = useCallback(() => {
    setIsItemCompleted(true)
  }, [])

  const onClose = () => {
    if (lesson.progress?.complete) {
      return resetNavigationToModule()
    }

    Alert.alert(
      'Leave this lesson?',
      'Your progress will not be saved',
      [
        { text: 'Nevermind', style: 'cancel' },
        { text: 'Leave', onPress: resetNavigationToModule },
      ],
      { cancelable: true },
    )
  }

  let nextButtonTitle = 'Next'

  if (isLastItem && !isModuleComplete) {
    if (nextLesson) {
      nextButtonTitle = nextLesson?.isExperiment ? 'Start Experiment' : 'Start Next Lesson'
    } else if (nextModule) {
      nextButtonTitle = 'Start Next Module'
    }
  }

  return getItemComponent({
    ...parentScreenParams,
    item,
    currentStep: index,
    totalSteps: lesson.items.length,
    isCompleted: isItemCompleted,
    nextButtonTitle,
    onBack,
    onNext,
    onComplete,
    onClose,
  })
}
