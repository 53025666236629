import { Cache } from '@src/models/app.types'
import { ExpandableModule, ExpandableProgram, Lesson } from '@src/types'

export interface CoursesStoreState {
  cache: Cache
  published: {
    programs: ExpandableProgram[]
  }

  programs: ProgramContent[]
  modules: ModuleContent[]
  lessons: LessonContent[]
}

interface CmsModel {
  id: string
  position: number
  title: string
  description: string

  [prop: string]: any
}

interface CmsMedia {
  data: {
    attributes: {
      url: string
      formats?: {
        small?: {
          url: string
        }
      }
    }
  }
}

export interface ProgramContent extends CmsModel {
  title: string
  subtitle: string
  description: string
  featuredImage?: CmsMedia
}

export interface ModuleContent extends CmsModel {
  title: string
  description: string
}

export interface LessonContent extends CmsModel {
  title: string
  description: string
  duration: string
  thumbnail?: CmsMedia
  items: LessonItemContent[]
}

export enum LessonItemContentType {
  Video = 'ComponentContentVideo',
  Audio = 'ComponentContentAudio',
  Reading = 'ComponentContentReading',
  Fact = 'ComponentContentFact',
  MultipleChoice = 'ComponentContentMultipleChoice',
  Prompt = 'ComponentContentPrompt',
  Experiment = 'ComponentContentExperiment',
}
export interface LessonItemContent {
  __typename: LessonItemContentType
  id: string

  [prop: string]: any
}
export interface LessonItemContentMultipleChoiceAnswerOption {
  id: string
  body: string
  correct: boolean
}
export interface LessonItemContentMultipleChoice extends LessonItemContent {
  __typename: LessonItemContentType.MultipleChoice
  id: string
  contentDescription: string
  question: string
  correctExplanation: string
  incorrectExplanation: string
  answers: LessonItemContentMultipleChoiceAnswerOption[]
}
export interface LessonItemContentMedia extends LessonItemContent {
  __typename: LessonItemContentType.Video | LessonItemContentType.Audio
  title: string
  media: {
    data: {
      attributes: {
        previewUrl: string
        url: string
      }
    }
  }
}

export enum LessonItemContentPromptActionType {
  Link = 'link',
  Question = 'question',
}

export interface LessonItemContentPromptAction {
  id: string
  title: string
  description: string
  action: LessonItemContentPromptActionType
  actionTarget: string
}

export interface LessonItemContentPrompt {
  __typename: LessonItemContentType.MultipleChoice
  title: string
  subtitle: string
  actions: LessonItemContentPromptAction[]
}

export enum LessonItemContentExperimentAction {
  None = 'none',
  LogMeal = 'logMeal',
  LogActivity = 'logActivity',
  LogStress = 'logStress',
  LogExercise = 'logExercise',
  LogNote = 'logNote',
  AdjustGlucoseThreshold = 'adjustGlucoseThreshold',
  AdjustMacros = 'adjustMacros',
  Calibrate = 'calibrate',
}

export interface LessonItemContentExperiment {
  __typename: LessonItemContentType.Experiment
  id: string
  title: string
  body: string
  actionTitle: string
  skipTitle: string
  action: LessonItemContentExperimentAction
}

export interface ProgramWithContent extends ExpandableProgram {
  title: string
  subtitle: string
  description: string
  featuredImage?: CmsMedia

  [prop: string]: any
}

export interface ModuleWithContent extends ExpandableModule {
  title: string
  description: string
  sensorActivationRecommended?: boolean

  [prop: string]: any
}

export interface LessonWithContent extends Lesson {
  title: string
  description: string
  duration: string
  thumbnail?: CmsMedia
  items: LessonItemContent[]
  isExperiment: boolean

  [prop: string]: any
}
