import React from 'react'
import { SectionList, SectionListData } from 'react-native'
import { Moment } from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { CommonRefreshControl, ListFooter } from '@components'
import { Text } from '@components/base'
import { withDataProvider } from '@components/list/DataProviderList'
import { ListInjectedProps } from '@components/list/types'
import { EmptyMealsList, MealHistoryListItem } from '@screens/Meals/components/CompareMeals'
import { Meal } from '@src/types'

type SectionItem = SectionListData<Meal, { occurredAt: Moment; title: string }>

interface MealHistoryProps extends ListInjectedProps {
  sections: SectionItem[]
  onMealSelect: (meal: Meal) => void
}

const MealHistory = ({
  initialNumToRender,
  items,
  isLoadingMore,
  loadedAll,
  onEndReached,
  onListScroll,
  onMealSelect,
  onRefresh,
  refreshing,
}: MealHistoryProps) => {
  const styles = useStyleSheet(themedStyle)

  const renderSectionHeader = ({ section }: { section: SectionItem }) => {
    const { occurredAt, title } = section

    return (
      <Text type="regular" style={styles.sectionHeader}>
        <Text type="regular" bold>
          {occurredAt.isSame(new Date(), 'day') ? 'Today ' : ''}
        </Text>
        {title}
      </Text>
    )
  }

  return (
    <SectionList
      sections={items}
      keyExtractor={(item) => item.id}
      initialNumToRender={initialNumToRender}
      refreshing={refreshing}
      stickySectionHeadersEnabled={false}
      showsVerticalScrollIndicator={false}
      renderItem={({ item }) => <MealHistoryListItem item={item} onMealSelect={onMealSelect} />}
      renderSectionHeader={renderSectionHeader}
      refreshControl={<CommonRefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      ListEmptyComponent={EmptyMealsList}
      ListFooterComponent={
        <ListFooter
          isLoadingMore={isLoadingMore}
          isListEmpty={items.length === 0}
          loadedAll={loadedAll}
        />
      }
      onScroll={onListScroll}
      onEndReached={onEndReached}
    />
  )
}

export const MealHistoryList = withDataProvider(MealHistory)

const themedStyle = StyleService.create({
  sectionHeader: {
    marginVertical: 8,
  },
})
