import React from 'react'
import { Text } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'

export interface TabBarLabelProps {
  name: string
  focused: boolean
}

export const TabBarLabel = ({ name, focused }: TabBarLabelProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <Text type="small" bold={focused} style={[styles.title, !focused && styles.titleColor]}>
      {name}
    </Text>
  )
}

const themedStyle = StyleService.create({
  title: {
    marginBottom: 4,
    textAlign: 'center',
  },
  titleColor: {
    color: 'theme.text.tertiary',
  },
})
