import React, { useEffect, useRef } from 'react'
import { useTheme } from '@ui-kitten/components'
import { passwordStrength } from 'check-password-strength'
import { Animated, Easing, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import passwordStrengthOptions from './passwordStrengthOptions'

interface PasswordStrengthProps {
  password: string
}

export const PasswordStrength = ({ password }: PasswordStrengthProps) => {
  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()

  const passwordStrengthResult = passwordStrength(password, passwordStrengthOptions)

  const passwordStrengthValue = passwordStrengthResult.value
  const passwordStrengthId = passwordStrengthResult.id as 0 | 1 | 2 | 3

  const animatedValue = useRef(new Animated.Value(passwordStrengthId)).current

  useEffect(() => {
    Animated.timing(animatedValue, {
      toValue: passwordStrengthId,
      duration: 250,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start()
  }, [animatedValue, passwordStrengthId])

  const colorByStrengthId = {
    0: theme['theme.error.base'],
    1: theme['theme.warning.base'],
    2: theme['theme.success.base'],
    3: theme['theme.success.lighter'],
  }

  const color = colorByStrengthId[passwordStrengthId]

  return (
    <View style={styles.container}>
      <View style={styles.progressBar}>
        <Animated.View
          style={[
            styles.progressBarFill,
            {
              backgroundColor: animatedValue.interpolate({
                inputRange: [0, 1, 2, 3],
                outputRange: [
                  colorByStrengthId[0],
                  colorByStrengthId[1],
                  colorByStrengthId[2],
                  colorByStrengthId[3],
                ],
              }),
            },
            {
              width: animatedValue.interpolate({
                inputRange: [0, 1, 2, 3],
                outputRange: ['25%', '50%', '75%', '100%'],
              }),
            },
          ]}
        />
      </View>
      <Text type="small" bold style={styles.text}>
        Password Strength:{' '}
        <Text type="small" bold style={{ color }}>
          {passwordStrengthValue}
        </Text>
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    marginTop: 12,
    marginBottom: 20,
  },
  progressBar: {
    backgroundColor: 'theme.surface.base',
    height: 4,
    borderRadius: 4,
  },
  progressBarFill: {
    position: 'absolute',
    height: '100%',
    borderRadius: 4,
  },
  text: {
    color: 'theme.text.secondary',
    marginTop: 8,
  },
})
