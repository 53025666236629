import React from 'react'
import { View, StyleSheet } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { useGoBack } from '@src/utils/navigation'
import { ScrollViewWithFade, ScrollableAvoidKeyboard } from '@components'
import { GestureDismissibleModal, GestureDismissibleModalProps } from './GestureDismissibleModal'

interface GestureDismissibleActionModalProps extends GestureDismissibleModalProps {
  title: string
  doneTitle: string
  actionTitle?: string
  actionDisabled?: boolean
  avoidKeyboard?: boolean
  onDonePress?: () => void
  onActionPress?: () => void
}

export const GestureDismissibleActionModal = ({
  title,
  doneTitle,
  actionTitle,
  actionDisabled,
  onDonePress,
  onActionPress = () => 0,
  avoidKeyboard = false,
  children,
  ...parentProps
}: GestureDismissibleActionModalProps) => {
  const styles = useStyleSheet(themedStyles)
  const { containerStyle } = parentProps

  const insets = useSafeAreaInsets()

  const goBack = useGoBack()

  const backgroundColor = StyleSheet.flatten([styles.container, containerStyle]).backgroundColor

  const handleDonePress = () => {
    onDonePress ? onDonePress() : goBack()
  }

  const renderButtons = () => {
    return (
      <View style={styles.buttonsContainer}>
        <Button
          type={actionTitle ? 'outline' : 'primary'}
          size="block"
          accessibilityLabel={doneTitle}
          style={styles.doneButton}
          onPress={handleDonePress}
        >
          {doneTitle}
        </Button>
        {actionTitle && (
          <Button
            type="primary"
            size="block"
            accessibilityLabel={actionTitle}
            style={styles.actionButton}
            onPress={onActionPress}
            disabled={actionDisabled}
          >
            {actionTitle}
          </Button>
        )}
      </View>
    )
  }

  return (
    <GestureDismissibleModal useKeyboardAvoidingView={!avoidKeyboard} {...parentProps}>
      <View
        style={[styles.container, containerStyle, { paddingBottom: Math.max(insets.bottom, 16) }]}
      >
        <Text type="large" bold style={styles.title}>
          {title}
        </Text>
        {avoidKeyboard ? (
          <ScrollableAvoidKeyboard
            showsVerticalScrollIndicator
            extraScrollHeight={100}
            contentContainerStyle={styles.contentContainer}
            style={[styles.container, containerStyle]}
          >
            <View style={styles.content}>{children}</View>
          </ScrollableAvoidKeyboard>
        ) : (
          <ScrollViewWithFade
            style={[styles.container, containerStyle]}
            fadeColor={backgroundColor}
            scrollViewStyle={styles.contentContainer}
          >
            <View style={styles.content}>{children}</View>
          </ScrollViewWithFade>
        )}
        {renderButtons()}
      </View>
    </GestureDismissibleModal>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background.modal',
  },
  contentContainer: {
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'space-between',
  },
  title: {
    alignSelf: 'center',
    marginBottom: 16,
  },
  content: {
    paddingBottom: 32,
    paddingHorizontal: 16,
  },
  buttonsContainer: {
    flexDirection: 'row',
    paddingLeft: 16,
    paddingRight: 8,
    paddingBottom: 8,
  },
  doneButton: {
    flex: 112,
    marginRight: 8,
  },
  actionButton: {
    flex: 207,
  },
})
