import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'
import { calendarDateSelector, historyFiltersTypesSelector } from '@src/selectors/app'

export const historyStoreStateSelector = (state: RootStoreState) => state.history

export const historySelector = createSelector(
  [historyStoreStateSelector, calendarDateSelector, historyFiltersTypesSelector],
  (history, calendar, filters) => ({
    sort: history.sort,
    items: history.history,
    calendar,
    filters,
  }),
)
