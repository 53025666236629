import React from 'react'
import { useRoute } from '@react-navigation/native'
import { NavigationContainer } from '@src/screens/Common/containers'
import { HorizontalChartModal as HorizontalChartModalComponent } from '../components/HorizontalChartModal'
import { AppRouteProp } from '../types'

const HorizontalChartModal = () => {
  const route = useRoute<AppRouteProp<'ShowHorizontalChart'>>()

  const { params } = route

  const chartScript = params?.chartScript
  const title = params.title

  return (
    <NavigationContainer title={title}>
      <HorizontalChartModalComponent chartScript={chartScript} />
    </NavigationContainer>
  )
}

export { HorizontalChartModal }
