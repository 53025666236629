import React from 'react'
import { CommonSelect, FadeFromBottomModal } from '@components'
import { Env } from '@config'
import { SelectOption } from '@src/components/CommonSelect.types'

const endpointSelectItems: SelectOption[] = [
  { text: 'Production', value: 'production' },
  { text: 'Staging', value: 'staging' },
  { text: 'Development', value: 'development' },
]

interface AdminModalProps {
  onSelect: (option: { value: Env }) => void
  onCancelPress: () => void
  selectedOption: Env
}

export const AdminModal = (props: AdminModalProps) => {
  const { onSelect, onCancelPress, selectedOption } = props

  const option = endpointSelectItems.find((option) => option.value === selectedOption)

  return (
    <FadeFromBottomModal title="Admin Settings" closeModal={onCancelPress}>
      <CommonSelect
        options={endpointSelectItems}
        selectedOption={option}
        onSelect={onSelect}
        size="small"
      />
    </FadeFromBottomModal>
  )
}
