import moment from 'moment'
import { Model } from '@models'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { UPDATE_SENSOR_SETTINGS } from '../graphql/updateSensorSettings'
import { UpdateSensorStatusMutation } from '../graphql/updateSensorStatus.generated'
import { UPDATE_SENSOR_STATUS } from '../graphql/updateSensorStatus'
import { reducers } from './reducers'

const sensorCacheClearPayload = (response: UpdateSensorStatusMutation['updateSensorStatus']) => {
  const { activationTime, expirationTime } = response

  const includeDates = [activationTime]

  let date = moment(activationTime).add(1, 'day')

  while (date.isBefore(expirationTime, 'day')) {
    includeDates.push(date.format(DATE_FORMAT))
    date = date.add(1, 'day')
  }

  includeDates.push(expirationTime)

  return { includeDates }
}

export default class Sensor {
  namespace = 'sensor'

  state = {
    ...Model.defaultState,
    sensorsInfoModalLifecycleMap: {},
  }

  effects = {
    updateSettings: Model.buildEffect({
      name: `${this.namespace}/updateSettings`,
      query: UPDATE_SENSOR_SETTINGS,
      dataPath: 'updateSensorSettings',
      optimistic: true,
      optimisticReducers: [{ name: 'backup' }, { name: 'updateSensor' }],
      reducers: [
        { name: 'updateSensor' },
        { name: 'history/cacheClear', payload: { matchName: 'history/fetch' } },
        {
          name: 'events/cacheClear',
          payload: sensorCacheClearPayload,
        },
        {
          name: 'insights/cacheClear',
          payload: sensorCacheClearPayload,
        },
      ],
      errorReducers: [{ name: 'restore' }],
    }),

    updateStatus: Model.buildEffect({
      name: `${this.namespace}/updateStatus`,
      query: UPDATE_SENSOR_STATUS,
      dataPath: 'updateSensorStatus',
      reducers: [{ name: 'updateSensor' }],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    ...reducers,
  }
}
