import React, { useEffect, useRef, useState } from 'react'
import {
  Animated,
  // eslint-disable-next-line no-restricted-imports
  Button,
  Easing,
  InputAccessoryView,
  Keyboard,
  KeyboardAvoidingView,
  View,
} from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Input, Text } from '@components/base'
import { useUpdateDraftMeal } from '@screens/Meals/hooks'
import { loadingEffectsSelector } from '@selectors/loading'
import { mealDraftSelector } from '@selectors/meals'
import { Device } from '@src/config'

const HINT_HEIGHT = 66

export const MealDescription = () => {
  const styles = useStyleSheet(themedStyle)
  const updateDraftMeal = useUpdateDraftMeal()

  const height = useRef(new Animated.Value(HINT_HEIGHT)).current

  const draftMeal = useSelector(mealDraftSelector)
  const isGeneratingDescriptionFromImage = useSelector(loadingEffectsSelector)[
    'meals/getMealImageDescription'
  ]

  const [description, setDescription] = useState(draftMeal.description)

  useEffect(() => {
    setDescription(draftMeal.description)
  }, [draftMeal.description])

  const hasDescription = !!description

  useEffect(() => {
    Animated.timing(height, {
      toValue: hasDescription ? 0 : HINT_HEIGHT,
      duration: 200,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start()
  }, [hasDescription, height])

  return (
    <KeyboardAvoidingView style={styles.container}>
      <Text type="regular" style={styles.label}>
        Describe your meal
      </Text>
      {Device.ios && (
        <InputAccessoryView nativeID="Done">
          <View style={styles.accessory}>
            <Button onPress={() => Keyboard.dismiss()} accessibilityLabel="done" title="Done" />
          </View>
        </InputAccessoryView>
      )}
      <Input
        autoCorrect={false}
        disabled={isGeneratingDescriptionFromImage}
        numberOfLines={3}
        placeholder="Half a chicken breast, a side of mashed potatoes, and a can of coke"
        testID="AddMealComponent/Description"
        value={description}
        inputAccessoryViewID="Done"
        onChangeText={(description) => {
          setDescription(description)
          updateDraftMeal({ description }, true)
        }}
      />
      <Animated.View style={[styles.hint, { height }]}>
        <Icon name="sparkle" style={styles.sparkleIcon} />
        <Text type="regular" style={styles.hintText}>
          Automatically generate nutrition info by taking a photo or entering a description of your
          meal.
        </Text>
      </Animated.View>
    </KeyboardAvoidingView>
  )
}

const themedStyle = StyleService.create({
  container: {
    marginHorizontal: 16,
  },
  accessory: {
    height: 48,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 8,
    backgroundColor: 'theme.surface.base2',
  },
  label: {
    marginBottom: 8,
  },
  hint: {
    flexDirection: 'row',
    marginTop: 16,
    overflow: 'hidden',
  },
  sparkleIcon: {
    width: 32,
    height: 32,
    marginTop: 8,
    marginRight: 12,
    color: 'theme.accent.citron',
  },
  hintText: {
    flexShrink: 1,
    height: HINT_HEIGHT,
  },
})
