import { AuthConfiguration, AuthorizeResult, authorize } from 'react-native-app-auth'
import { Bugsnag } from '@config'

export enum AuthResultStatus {
  Successful = 'Successful',
  Canceled = 'Canceled',
  Failed = 'Failed',
}

interface AuthResult {
  user: AuthorizeResult | null
  status: AuthResultStatus
}

export const oauthLogin = async (config: AuthConfiguration): Promise<AuthResult> => {
  try {
    const response = await authorize(config)
    return { user: response, status: AuthResultStatus.Successful }
  } catch (error: any) {
    if (
      error.message.includes('org.openid.appauth.general error -3') ||
      error.message.includes('User cancelled flow')
    ) {
      // user cancel auth flow
      // https://github.com/openid/AppAuth-iOS/blob/master/Source/AppAuthCore/OIDError.h
      return { user: null, status: AuthResultStatus.Canceled }
    }

    Bugsnag.notify(error)
    return { user: null, status: AuthResultStatus.Failed }
  }
}
