import { gql } from '@apollo/client'

export const CORE_ADDRESS_FIELDS = gql`
  fragment CoreAddressFields on Address {
    name
    street
    street2
    city
    state
    country
    zipCode
  }
`
