import { Model } from '@models'
import { ACTIVE_NOTIFICATIONS } from '@src/graphql/activeNotifications'
import { NotificationsStoreState } from '@src/models/notifications.types'
import { COMPLETE_NOTIFICATION } from '@src/graphql/completeNotification'
import { DISMISS_NOTIFICATION } from '@src/graphql/dismissNotification'
import commonReducers from './commonReducers'

export default class Notifications {
  namespace = 'notifications'

  state: NotificationsStoreState = {
    ...Model.defaultState,

    notifications: [],
  }

  effects = {
    fetch: Model.buildEffect({
      name: `${this.namespace}/fetch`,
      query: ACTIVE_NOTIFICATIONS,
      dataPath: 'activeNotifications',
      reducers: [
        {
          name: 'fetchList',
          storePath: () => 'notifications',
          dataPath: 'notifications',
        },
      ],
    }),
    complete: Model.buildEffect({
      name: `${this.namespace}/complete`,
      query: COMPLETE_NOTIFICATION,
      dataPath: 'completeNotification',
      reducers: [
        {
          name: 'deleteListItem',
          storePath: () => 'notifications',
          dataPath: 'completeNotification',
        },
      ],
    }),
    dismiss: Model.buildEffect({
      name: `${this.namespace}/dismiss`,
      query: DISMISS_NOTIFICATION,
      dataPath: 'dismissNotification',
      reducers: [
        {
          name: 'deleteListItem',
          storePath: () => 'notifications',
          dataPath: 'completeNotification',
        },
      ],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    fetchList: commonReducers.fetchList,
    deleteListItem: commonReducers.deleteList,
  }
}
