import React from 'react'
import { View } from 'react-native'
import { BottomSheet } from '@src/components'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useGoBack } from '@src/utils'

export const FeePaymentIncompleteModal = () => {
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()

  return (
    <BottomSheet
      buttonDirection="column-reverse"
      primaryButton={{ text: 'Dismiss', onPress: goBack }}
    >
      <View style={styles.iconContainer}>
        <Icon name="warning" style={styles.icon} weight="duotone" />
      </View>
      <Text style={styles.content} type="title-2">
        Payment Incomplete
      </Text>
      <Text style={styles.content} type="regular">
        Payment could not be processed, and the requested change cannot be made to your call. Please
        update your payment information and try again.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  content: {
    textAlign: 'center',
    margin: 8,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 48,
    height: 48,
    color: 'theme.warning.darkest',
  },
})
