import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ScrollView, View } from 'react-native'
import { Divider } from '@ui-kitten/components'
import { NavigationContainer } from '@src/screens/Common/containers'
import { dexcomConnectionSelector, researchFaqsSelector } from '@src/selectors/app'
import { Button, Toggle } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Intercom, openUrl } from '@src/utils'
import { Apollo } from '@src/config'
import { Dexcom } from '@src/services'
import { InlineAlert, Section } from '@src/components'
import { DexcomConnectionStatus, UiInlineAlert } from '@src/types'
import LastSyncedSection from '../components/Integrations/LastSyncedSection'

const ACTIVATE_SENSOR_TEXT = 'How to Activate a Sensor'
const REQUEST_SUPPORT_TEXT = 'Request Sensor Support'
const DEXCOM_CONNECTION_SUCCESS_TEXT =
  'Dexcom G7 integration activated! Sensor data will appear in Nutrisense app shortly, typically within a minute.'
const DEXCOM_CONNECTION_ERROR_TEXT =
  'Dexcom G7 integration failed. Issues with setup? Request sensor support below.'

export const DexcomSensorSettingsContainer = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const dispatch = useDispatch()

  const dexcomConnection = useSelector(dexcomConnectionSelector)
  const researchFaqs = useSelector(researchFaqsSelector)

  const [toggleActive, setToggleActive] = useState(
    dexcomConnection?.status === DexcomConnectionStatus.Active,
  )
  const [error, setError] = useState<string | null>(null)

  const onAuth = async () => {
    const { authorizationCode } = await Dexcom.login({
      useSandbox: Apollo.endpointEnv !== 'production',
    })

    if (authorizationCode) {
      setToggleActive(true)
      setError(null)
      dispatch({
        type: 'app/upsertDexcomConnection',
        payload: { authorizationCode },
        failure: () => {
          setToggleActive(false)
          setError(DEXCOM_CONNECTION_ERROR_TEXT)
        },
      })
    } else {
      setToggleActive(false)
      setError(DEXCOM_CONNECTION_ERROR_TEXT)
    }
  }

  const onLogout = () => {
    setToggleActive(false)

    dispatch({
      type: 'app/deleteDexcomConnection',
      failure: () => {
        setToggleActive(true)
      },
    })
  }

  const onSyncToggle = () => {
    if (toggleActive) {
      setToggleActive(false)
      onLogout()
    } else {
      setToggleActive(true)
      onAuth()
    }
  }

  const onActivateSensorPress = () => {
    openUrl(researchFaqs.gettingStartedUrl)
  }

  const onRequestSupportPress = () => {
    Intercom.showIntercomMessenger({ source: 'SensorSettings' })
  }

  return (
    <NavigationContainer title="Sensor Settings" showLoadingIndicator>
      <ScrollView
        contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
        style={styles.container}
      >
        <View style={styles.content}>
          <Section title="Dexcom">
            <Toggle checked={toggleActive} onChange={onSyncToggle} />
          </Section>
          <Divider />
          {dexcomConnection?.lastSyncedAt && (
            <>
              <LastSyncedSection lastSynced={dexcomConnection.lastSyncedAt} />
              <Divider />
            </>
          )}
          {dexcomConnection && !dexcomConnection.lastSyncedAt && (
            <InlineAlert
              category={UiInlineAlert.Info}
              message={DEXCOM_CONNECTION_SUCCESS_TEXT}
              style={styles.inlineAlert}
            />
          )}
          {error && (
            <InlineAlert
              category={UiInlineAlert.Danger}
              message={DEXCOM_CONNECTION_ERROR_TEXT}
              style={styles.inlineAlert}
            />
          )}
          {dexcomConnection?.status === DexcomConnectionStatus.Invalidated && (
            <InlineAlert
              style={styles.inlineAlert}
              category={UiInlineAlert.Danger}
              // eslint-disable-next-line max-len
              message="Your Dexcom G7 data integration authentication has been reset. Please authenticate again to integrate your data."
            />
          )}
        </View>
        <View style={styles.buttonsWrapper}>
          <Button
            type="primary"
            size="block"
            onPress={onActivateSensorPress}
            accessibilityLabel={ACTIVATE_SENSOR_TEXT}
          >
            {ACTIVATE_SENSOR_TEXT}
          </Button>
          <Button
            accessibilityLabel={REQUEST_SUPPORT_TEXT}
            type="transparent"
            size="block"
            style={styles.secondaryButton}
            onPress={onRequestSupportPress}
          >
            {REQUEST_SUPPORT_TEXT}
          </Button>
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 16,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
  },
  inlineAlert: {
    marginTop: 24,
    marginHorizontal: 16,
  },
  buttonsWrapper: {
    marginTop: 24,
    marginHorizontal: 16,
  },
  secondaryButton: {
    marginTop: 8,
  },
})
