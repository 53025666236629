import React from 'react'
import { TextAreaInputConfig } from '../../types'
import { InputPropsBase } from './types'
import { TextInput } from './TextInput'

interface TextAreaInputProps extends InputPropsBase {
  response: TextAreaInputConfig
}

export const TextAreaInput = ({ field, response }: TextAreaInputProps): JSX.Element => {
  return <TextInput field={field} response={response} numberOfLines={3} />
}
