import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconName, Text } from '@components/base'
import { NavigationBar, ProgressBar, ScrollViewWithFade } from '@src/components'
import { LessonItemContentProps } from '../../lesson.types'
import { NavigationButtons, NavigationButtonsProps } from './NavigationButtons'
import { ContentDescription } from './ContentDescription'

export type LessonItemContainerProps = LessonItemContentProps &
  Omit<NavigationButtonsProps, 'canGoBack' | 'nextTitle'> & {
    lessonItemIcon?: IconName
    lessonItemTitle?: string
    preCompleteNextTitle?: string
  }

export const LessonItemContainer = ({
  item,
  isCompleted,
  currentStep,
  totalSteps,
  lessonItemIcon,
  lessonItemTitle = '',
  nextButtonTitle,
  preCompleteNextTitle,
  onClose,
  nextDisabled,
  onBack,
  onNext,
  children,
}: LessonItemContainerProps) => {
  const styles = useStyleSheet(themedStyles)

  const nextTitle = (!isCompleted && preCompleteNextTitle) || nextButtonTitle
  const showContentDescription = !!(lessonItemTitle || item.contentDescription)

  return (
    <SafeAreaView style={styles.container} edges={['bottom']}>
      <NavigationBar
        title={`${currentStep + 1} out of ${totalSteps}`}
        backgroundColor={styles.container.backgroundColor}
        leftAccessory={{
          iconDescriptor: { pack: 'eva', name: 'close' },
          onPress: onClose,
        }}
      />
      <View style={styles.header}>
        <ProgressBar currentStep={currentStep} stepsCount={totalSteps} duration={600} />
        {showContentDescription && (
          <ContentDescription iconName={lessonItemIcon} description={item.contentDescription} />
        )}
        {!!lessonItemTitle && <Text type="title-3">{lessonItemTitle}</Text>}
      </View>
      <ScrollViewWithFade scrollViewStyle={styles.content}>{children}</ScrollViewWithFade>
      <NavigationButtons
        nextTitle={nextTitle}
        canGoBack={currentStep !== 0}
        nextDisabled={nextDisabled}
        onBack={onBack}
        onNext={onNext}
      />
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  header: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
  content: {
    padding: 16,
  },
})
