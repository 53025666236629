import React from 'react'
import { View, StatusBar, Platform } from 'react-native'
import { StyleService } from '@src/style/service'
import { useIsDarkMode } from '@src/config/theme'

export const DynamicStatusBar = () => {
  const isDarkMode = useIsDarkMode()
  const themedBarStyle = isDarkMode ? 'light-content' : 'dark-content'
  return (
    <View style={styles.container}>
      <StatusBar translucent backgroundColor="transparent" barStyle={themedBarStyle} />
    </View>
  )
}

const styles = StyleService.create({
  container: {
    height: Platform.select({
      android: 0,
      ios: StatusBar.currentHeight,
    }),
  },
})
