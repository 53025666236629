import React from 'react'
import { BottomTabNavigationOptions, createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  HistoryScreen,
  EventsScreen,
  LearnScreen,
  NutritionistHubScreen,
  InsightsScreen,
} from '@src/screens'
import { IconName } from '@components/base'
import { TabBarIcon } from '@src/navigation/components/TabBarIcon'
import { TabBarLabel } from '@src/navigation/components/TabBarLabel'
import { TabBarCustomItem } from '@src/navigation/components/TabBarCustomItem'
import { useNotifications } from '@src/components/notifications/utils/hooks'
import { MobileAppFeature, NotificationEngineScreen } from '@src/types'
import { Feature, useFeatureFlag } from '@src/components'
import { Analytics, CustomEventTypes } from '@config'
import { Storage, User } from '@src/utils'
import { useStorageValue } from '@src/utils/storage'
import { TabParamList } from '../types'

const customNavigationOptions = (params: {
  label: string
  iconName?: IconName
  largeItem?: boolean
  redDot?: boolean
}) => (): BottomTabNavigationOptions => {
  const { label, iconName, largeItem = false, redDot = false } = params
  return {
    tabBarIcon:
      largeItem || !iconName
        ? undefined
        : ({ focused }) => <TabBarIcon focused={focused} iconName={iconName} redDot={redDot} />,
    tabBarButton: largeItem ? () => <TabBarCustomItem /> : undefined,
    tabBarLabel: largeItem
      ? undefined
      : ({ focused }) => <TabBarLabel focused={focused} name={label} />,
    tabBarLabelPosition: 'below-icon',
    title: largeItem ? undefined : label,
    tabBarTestID: largeItem ? 'TabBar/PlusButton' : `TabBar/${label}`,
    headerShown: false,
  }
}

export const TAB_NAVIGATION_BAR_HEIGHT = 56

const Tab = createBottomTabNavigator<TabParamList>()

export const TabNavigator = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  const insightsRedDot = useNotifications(NotificationEngineScreen.Insights).length !== 0
  const [nutritionistHubRedDot] = useStorageValue(Storage.NUTRITIONIST_HUB_RED_DOT)

  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)

  const insightsTitle = userGoalsEnabled ? 'Trends' : 'Insights'

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  const initialRouteName = isCGMFeatureAvailable ? 'Events' : 'NutritionistHub'

  return (
    <Tab.Navigator
      initialRouteName={initialRouteName}
      screenOptions={{
        tabBarStyle: [styles.tabBar, { height: TAB_NAVIGATION_BAR_HEIGHT + insets.bottom }],
      }}
    >
      <Tab.Screen
        name="Events"
        component={EventsScreen}
        options={customNavigationOptions({
          label: 'Home',
          iconName: 'house-simple',
        })}
      />
      <Tab.Screen
        name="History"
        component={HistoryScreen}
        options={customNavigationOptions({
          label: 'History',
          iconName: 'clock',
        })}
      />
      <Tab.Screen
        name="Insights"
        component={InsightsScreen}
        options={customNavigationOptions({
          label: insightsTitle,
          iconName: 'trend-up',
          redDot: insightsRedDot,
        })}
      />
      <Tab.Screen
        name="NutritionistHub"
        component={NutritionistHubScreen}
        listeners={{
          tabPress: () => Analytics.track(CustomEventTypes.BaseBarNutritionistHubTapped),
        }}
        options={customNavigationOptions({
          label: 'Nutritionist',
          iconName: 'users',
          redDot: !!nutritionistHubRedDot,
        })}
      />
      <Tab.Screen
        name="Learn"
        component={LearnScreen}
        options={customNavigationOptions({
          label: 'Learn',
          iconName: 'book-open',
        })}
      />
    </Tab.Navigator>
  )
}

const themedStyle = StyleService.create({
  tabBar: {
    backgroundColor: 'theme.background',
    borderTopColor: 'theme.surface.base1',
    borderTopWidth: 1,
  },
})
