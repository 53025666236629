import { ActionSheetProps, useActionSheet } from '@expo/react-native-action-sheet'
import { StyleService, useStyleSheet } from '@src/style/service'

export const useStyledActionSheet = () => {
  const { showActionSheetWithOptions } = useActionSheet()
  const styles = useStyleSheet(themedStyles)

  const showStyledActionSheetWithOptions: ActionSheetProps['showActionSheetWithOptions'] = (
    options,
    callback,
  ) =>
    showActionSheetWithOptions(
      {
        containerStyle: styles.containerStyle,
        textStyle: styles.textStyle,
        titleTextStyle: styles.titleTextStyle,
        messageTextStyle: styles.messageTextStyle,
        ...options,
      },
      callback,
    )

  return {
    showActionSheetWithOptions: showStyledActionSheetWithOptions,
  }
}

const themedStyles = StyleService.create({
  containerStyle: {
    backgroundColor: 'theme.background',
  },
  textStyle: {
    color: 'theme.text.secondary',
  },
  titleTextStyle: {
    color: 'theme.text.primary',
  },
  messageTextStyle: {
    color: 'theme.text.secondary',
  },
})
