import React, { useContext } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Divider } from '@ui-kitten/components'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { BillingProduct, Product, UiInlineAlert } from '@src/types'
import { CheckoutContainer } from '@src/screens/Marketplace/components/checkout/CheckoutContainer'
import { ProductDescription } from '@src/screens/Marketplace/components/checkout/ProductDescription'
import { ProductPayment } from '@src/screens/Marketplace/components/checkout/ProductPayment'
import {
  usePromotionCode,
  usePurchaseOneTimePayment,
  useTaxEstimation,
} from '@src/screens/Marketplace/utils/hooks'
import { InlineAlert } from '@src/components'
import { videoCallProductSelector } from '@src/selectors/app'
import { RootStackParamList } from '@src/navigation/types'
import { CustomEventTypes } from '@config'
import { NavigatorContext } from '@utils'
import { useNutritionistHubTracker } from '../hooks'

export const VideoCallCheckoutScreen = () => {
  const styles = useStyleSheet(themedStyle)
  const purchaseOneTimePayment = usePurchaseOneTimePayment()
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const { enableLoader, disableLoader } = useContext(NavigatorContext)

  const track = useNutritionistHubTracker()

  const product = useSelector(videoCallProductSelector)

  const productKey = product?.key as BillingProduct
  const promotionCode = usePromotionCode(productKey)
  const estimatedTax = useTaxEstimation(productKey, promotionCode)

  const onCheckoutPress = async () => {
    enableLoader()

    try {
      await purchaseOneTimePayment({ product: product as Product, quantity: 1 }, promotionCode)

      track(CustomEventTypes.NutritionistHubSuccessfulCheckout, {
        product: {
          key: productKey,
          title: product?.title,
        },
      })

      navigation.replace('DietitianAddonOrderConfirmed', {
        product: product as Product,
      })
    } finally {
      disableLoader()
    }
  }

  if (!product) {
    navigation.replace('Drawer', { screen: 'Dashboard', params: { screen: 'NutritionistHub' } })
    return null
  }

  const productTitle = product.title ?? 'Nutritionist Video Call'
  const productDescription =
    product.description ??
    'Discuss your health goals, and answer your nutrition-related questions\
    through a video call with your licensed nutritionist.'
  const productDetails =
    product.details?.join('\n') ??
    'Your licensed nutritionist will message you within 1 business day to set up a video call consultation.'

  return (
    <CheckoutContainer checkoutButtonTitle="Purchase Now" onCheckout={onCheckoutPress}>
      <ProductDescription
        title={productTitle}
        description={productDescription}
        price={`$${product.price}`}
      />

      <ProductPayment
        product={product.key as BillingProduct}
        fullPrice={product.price}
        estimatedTax={estimatedTax}
        promotionCode={promotionCode}
      />

      <Divider appearance="large" />

      <InlineAlert
        category={UiInlineAlert.Info}
        message="HOW IT WORKS"
        secondaryMessage={productDetails}
        style={styles.infoAlert}
      />
    </CheckoutContainer>
  )
}

const themedStyle = StyleService.create({
  infoAlert: {
    marginHorizontal: 16,
    marginVertical: 32,
  },
})
