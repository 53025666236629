import moment from 'moment'
import {
  ChartLabelsConfig,
  ColumnConfig,
  LabelConfig,
  LabelsPosition,
  SeparationLineConfig,
} from './types'

type LabelConfigsGenerator = (
  columnConfigs: ColumnConfig[],
  separationLineConfigs: SeparationLineConfig[],
) => LabelConfig[]

export const getChartLabelsConfig = (
  chartNumberOfDays: number,
  columnConfigs: ColumnConfig[],
  separationLineConfigs: SeparationLineConfig[],
): ChartLabelsConfig => {
  const generateLabelConfigs = getLabelConfigsGenerator(chartNumberOfDays)

  return {
    labelsPosition: getLabelsPosition(chartNumberOfDays),
    labelConfigs: generateLabelConfigs(columnConfigs, separationLineConfigs),
  }
}

const getLabelsPosition = (chartNumberOfDays: number): LabelsPosition =>
  chartNumberOfDays <= 14 ? 'under-columns' : 'under-separation-lines'

const getLabelConfigsGenerator = (chartNumberOfDays: number): LabelConfigsGenerator => {
  if (chartNumberOfDays <= 9) {
    return generateLabelsUnderEachColumn
  }
  if (chartNumberOfDays <= 14) {
    return generateLabelsUnderOddColumns
  }
  return generateLabelsUnderEachSeparationLine
}

const generateLabelsUnderEachColumn = (columnConfigs: ColumnConfig[]): LabelConfig[] =>
  columnConfigs.map((columnConfig) => ({
    columnIndex: columnConfig.index,
    text: getColumnLabel(columnConfig),
  }))

const generateLabelsUnderOddColumns = (columnConfigs: ColumnConfig[]): LabelConfig[] =>
  columnConfigs
    .filter((columnConfig) => columnConfig.index % 2 === 0)
    .map((columnConfig) => ({
      columnIndex: columnConfig.index,
      text: getColumnLabel(columnConfig),
    }))

const generateLabelsUnderEachSeparationLine: LabelConfigsGenerator = (
  columnConfigs,
  separationLineConfigs,
) =>
  separationLineConfigs.map((separationLineConfig) => ({
    columnIndex: separationLineConfig.columnIndex,
    text: getSeparationLineLabel(separationLineConfig, columnConfigs),
  }))

const getSeparationLineLabel = (
  separationLineConfig: SeparationLineConfig,
  columnConfigs: ColumnConfig[],
): string => {
  const columnConfig = columnConfigs.find(
    (columnConfig) =>
      columnConfig.index ===
      (separationLineConfig.columnIndex === -1 ? 0 : separationLineConfig.columnIndex),
  ) as ColumnConfig

  const date = getColumnDate(
    columnConfig,
    separationLineConfig.columnIndex === -1 ? 'fromDate' : 'toDate',
  )

  if (separationLineConfig.columnIndex !== -1) {
    date.add(1, 'day')
  }

  return date.format('M/D')
}

const getColumnDate = (
  columnConfig: ColumnConfig,
  dateIfRange: 'toDate' | 'fromDate' = 'toDate',
): moment.Moment => (columnConfig.type === 'oneDay' ? columnConfig.date : columnConfig[dateIfRange])

const getColumnLabel = (
  columnConfig: ColumnConfig,
  endIfRange: 'toDate' | 'fromDate' = 'toDate',
) => {
  const date = columnConfig.type === 'oneDay' ? columnConfig.date : columnConfig[endIfRange]

  return date.format('M/D')
}
