import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { Rect } from 'react-content-loader/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ContentLoader } from '@src/components/ContentLoader'
import { LessonWithContent } from '../models/courses.types'
import { LessonProgressIndicator, LessonProgressState } from './LessonProgressIndicator'
import { LessonCardContent } from './LessonCardContent'
import { LessonCardExperiment } from './LessonCardExperiment'

interface LessonCardProps {
  lesson: LessonWithContent
  locked: boolean
  isFirst: boolean
  isLast: boolean
  onPress?: (lesson: LessonWithContent) => void
}

const getProgressState = (lesson: LessonWithContent) => {
  if (lesson.progress?.complete) {
    return LessonProgressState.Complete
  } else if (lesson.progress?.complete === false) {
    return LessonProgressState.Started
  } else if (lesson.isExperiment) {
    return LessonProgressState.Actionable
  }
  return LessonProgressState.New
}

export const LoadingLessonCard = () => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.container}>
      <View style={styles.progressBar} />
      <View style={styles.card}>
        <View style={styles.cardLoadingImage} key="loading-image">
          <ContentLoader>
            <Rect x="0" y="0" width="97" height="116" rx="6" ry="6" />
          </ContentLoader>
        </View>
        <View style={styles.cardLoadingText} key="loading-text">
          <ContentLoader>
            <Rect x="0" y="0" width="100%" height="20" rx="6" ry="6" />
            <Rect x="0" y="22" width="60%" height="20" rx="6" ry="6" />
            <Rect x="0" y="44" width="70" height="19" rx="6" ry="6" />
            <Rect x="0" y="76" width="100%" height="40" rx="6" ry="6" />
          </ContentLoader>
        </View>
      </View>
    </View>
  )
}

export const LessonCard = ({ lesson, locked, isFirst, isLast, onPress }: LessonCardProps) => {
  const styles = useStyleSheet(themedStyle)
  const progress = getProgressState(lesson)

  const cardPositionStyle = isLast ? styles.cardBottom : null
  const experimentStyle = lesson.isExperiment ? styles.cardExperiment : null

  const progressBarStyles = [
    styles.progressBar,
    ...(isFirst ? [styles.progressBarTop] : []),
    ...(isLast ? [styles.progressBarBottom] : []),
  ]

  return (
    <View style={styles.container}>
      <View style={progressBarStyles} />
      <View style={styles.progressIndicator}>
        <LessonProgressIndicator progress={progress} />
      </View>
      <TouchableOpacity
        style={[styles.card, experimentStyle, cardPositionStyle]}
        onPress={() => onPress?.(lesson)}
        accessibilityLabel={lesson.title}
        disabled={locked}
      >
        {lesson.isExperiment ? (
          <LessonCardExperiment lesson={lesson} />
        ) : (
          <LessonCardContent lesson={lesson} />
        )}
      </TouchableOpacity>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
  },
  progressBar: {
    position: 'absolute',
    left: 24,
    top: 0,
    width: 8,
    height: '100%',
    backgroundColor: 'theme.secondary.base',
  },
  progressBarTop: {
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  progressBarBottom: {
    borderBottomLeftRadius: 3,
    borderBottomRightRadius: 3,
  },
  progressIndicator: {
    position: 'absolute',
    top: '50%',
    left: 16,
    marginTop: -12,
    borderRadius: 16,
    backgroundColor: 'theme.secondary.base',
  },
  card: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexGrow: 1,
    padding: 16,
    marginLeft: 32,
    marginBottom: 16,
    height: 148,
    backgroundColor: 'theme.secondary.base',
    borderRadius: 16,
  },
  cardExperiment: {
    height: 'auto',
    alignItems: 'center',
  },
  cardBottom: {
    marginBottom: 0,
  },
  cardLoadingImage: {
    width: 105,
  },
  cardLoadingText: {
    flex: 1,
  },
})
