import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { Feature, NavigationBarAccessory, useFeatureFlag } from '@components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Debug } from '@config'
import { Intercom } from '@utils'
import { ChatbotIcon } from '@src/screens/Events/containers/ChatbotIcon'
import { MessageIcon } from '@src/screens/Events/containers/MessageIcon'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { NutritionistHubScreen as NutritionistHubComponent } from '../components/NutritionistHubScreen'

export const NutritionistHubScreenContainer = () => {
  const rightAccessories: NavigationBarAccessory[] = []
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyle)

  if (useFeatureFlag(Feature.Chatbot)) {
    rightAccessories.push({
      renderIconComponent: () => <ChatbotIcon />,
      onPress: () => navigation.navigate('Chat'),
      accessibilityLabel: 'Chat',
    })
  }

  const showMessages = Debug.shouldEnableIntercom()
  if (showMessages) {
    rightAccessories.push({
      renderIconComponent: () => <MessageIcon />,
      onPress: () => Intercom.showIntercomMessenger({ source: 'NutritionistHub' }),
      accessibilityLabel: 'Message',
    })
  }

  return (
    <AccountAvatarNavigationBar
      title="Nutritionist"
      rightAccessories={rightAccessories}
      hideLeftIcon
      style={styles.container}
    >
      <NutritionistHubComponent />
    </AccountAvatarNavigationBar>
  )
}

const themedStyle = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
})
