import React, { useRef, useState } from 'react'
import DateTimePicker, {
  ReactNativeModalDateTimePickerProps,
} from 'react-native-modal-datetime-picker'
import { useTheme } from '@ui-kitten/components'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Input, InputProps, InputRef } from '@components/base'
import { useIsDarkMode } from '@config/theme'

type PickerProps = Omit<
  ReactNativeModalDateTimePickerProps,
  'isDarkModeEnabled' | 'isVisible' | 'onConfirm' | 'onCancel'
>

interface SelectDateTimeProps {
  inputProps?: InputProps
  pickerProps?: PickerProps
  style?: StyleProp<ViewStyle>
  onChange: (time: Date) => void
}

export const SelectDateTime = ({
  inputProps = {},
  pickerProps = {},
  style,
  onChange,
}: SelectDateTimeProps) => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)
  const isDarkMode = useIsDarkMode()

  const [isPickerVisible, setPickerVisible] = useState(false)

  const inputRef = useRef<InputRef>(null)

  const handlePress = () => {
    setPickerVisible(true)
    inputRef.current?.focus()
  }

  const handleConfirm = (time: Date) => {
    setPickerVisible(false)
    onChange(time)
  }

  return (
    <TouchableOpacity
      accessibilityLabel="Select date/time"
      disabled={inputProps.disabled}
      onPress={handlePress}
      style={style}
    >
      <View pointerEvents="none">
        <Input
          {...inputProps}
          caretHidden
          iconRight="caret-down"
          placeholder={inputProps.placeholder || 'Select'}
          ref={inputRef}
          showSoftInputOnFocus={false}
        />
        <DateTimePicker
          {...pickerProps}
          isDarkModeEnabled={isDarkMode}
          isVisible={isPickerVisible}
          focusable={false}
          onCancel={() => setPickerVisible(false)}
          onConfirm={handleConfirm}
          pickerContainerStyleIOS={styles.pickerContainerIOS}
          textColor={theme['theme.text.primary']}
          themeVariant={isDarkMode ? 'dark' : 'light'}
        />
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  pickerContainerIOS: {
    backgroundColor: 'theme.secondary.base',
  },
})
