import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StyleService } from '@src/style/service'
import { openUrl, Zendesk } from '@src/utils'
import { userSelector } from '@src/selectors/app'
import { Subscription } from '@src/types'
import { Option } from './Option'

interface OptionsProps {
  subscription: Pick<
    Subscription,
    'paused' | 'allowedPauseCycles' | 'resetBillingCycleAnchorAt' | 'id'
  >
  inAppSubscriptionPausing: boolean
}

export const Options = ({ subscription, inAppSubscriptionPausing }: OptionsProps) => {
  const user = useSelector(userSelector)
  const navigation = useNavigation()

  const canPauseProgram =
    !subscription.paused &&
    subscription.allowedPauseCycles > 0 &&
    !subscription.resetBillingCycleAnchorAt

  const pauseProgram = () => {
    if (inAppSubscriptionPausing) {
      const params = { subscriptionId: subscription.id }
      navigation.navigate('PauseSubscription', params)
    } else {
      openUrl(Zendesk.pauseProgramUrl(user?.email))
    }
  }

  const changeProgram = () => {
    openUrl(Zendesk.changeProgramUrl(user?.email))
  }

  return (
    <View style={styles.container}>
      {canPauseProgram && (
        <View style={styles.bottomMargin}>
          <Option
            iconName="pause-circle"
            title="Snooze your program"
            description="Snooze your subscription for 30 day increments"
            onPress={pauseProgram}
          />
        </View>
      )}
      <Option
        iconName="squares-four"
        title="Change your plan"
        description="Our support team will help find the right program for you"
        onPress={changeProgram}
      />
    </View>
  )
}

const styles = StyleService.create({
  container: {
    marginHorizontal: 16,
  },
  bottomMargin: {
    marginBottom: 16,
  },
})
