import React from 'react'
import { ScrollView, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Tutorial } from '../models/tutorials.types'
import { ListTutorialStep } from './ListTutorialStep'
import { Duration } from './shared/Duration'

export const ListTutorial = ({ tutorial }: { tutorial: Tutorial }) => {
  const styles = useStyleSheet(themedStyle)
  const { title, description, duration, tutorialSteps = [] } = tutorial

  return (
    <NavigationContainer title={title} navigationBarProps={styles.navigationBar}>
      <ScrollView style={styles.container} contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.summary}>
          <Duration duration={duration} />
          <Text type="regular" style={styles.summaryText}>
            {description}
          </Text>
        </View>
        <View style={styles.stepsContainer}>
          {tutorialSteps.map((step) => (
            <ListTutorialStep key={step.position} step={step} />
          ))}
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  navigationBar: {
    backgroundColor: 'theme.background',
  },
  container: {
    backgroundColor: 'theme.background',
  },
  summary: {
    padding: 16,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  summaryText: {
    marginTop: 16,
  },
  stepsContainer: {
    padding: 16,
  },
})
