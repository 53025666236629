import React, { useContext } from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { FadeFromBottomModal } from '@components'
import { useGoBack } from '@utils/navigation'
import { QuestionnaireContext } from '@screens/Questionnaire/questionnaire.navigator'

export const HealthQuestionnaireWarningModal = () => {
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()
  const { questionScreenConfig: config } = useContext(QuestionnaireContext)

  const handleConfirm = () => {
    config.onNextButtonPress(true)
    goBack()
  }

  return (
    <FadeFromBottomModal>
      <View style={styles.textContainer}>
        <Text type="title-3" style={styles.title}>
          Are you sure you want to proceed with this option?
        </Text>
        <Text type="regular">
          Please confirm that you want to proceed with this answer and that it was not a mistake.
          There is a high probability of your request not being approved.
        </Text>
      </View>
      <View style={styles.buttonContainer}>
        <Button
          type="outline"
          size="block"
          onPress={goBack}
          accessibilityLabel="Cancel"
          style={styles.backButton}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          size="block"
          onPress={handleConfirm}
          accessibilityLabel="Confirm"
          style={styles.nextButton}
        >
          Confirm
        </Button>
      </View>
    </FadeFromBottomModal>
  )
}

const themedStyles = StyleService.create({
  textContainer: {
    marginVertical: 20,
  },
  title: {
    marginBottom: 8,
  },
  buttonContainer: {
    flexDirection: 'row',
  },
  backButton: {
    flex: 2,
    marginRight: 8,
  },
  nextButton: {
    flex: 3,
  },
})
