import {
  appleAuth as auth,
  AppleRequestResponseFullName,
} from '@invertase/react-native-apple-authentication'
import Storage from '@src/utils/storage'

const STORAGE_KEY = 'AppleAuth/fullName'

class SignInError extends Error {
  code: number

  constructor(message: string, code: number) {
    super(message)
    this.code = code
  }
}

class AppleAuth {
  Error = {
    ...auth.Error,
    AUTHORIZATION_FAILED: 9001,
  }

  async signIn() {
    // Note: it appears putting FULL_NAME scope first is important
    // (see issue #293 (invertase/react-native-apple-authentication))
    const response = await auth.performRequest({
      requestedOperation: auth.Operation.LOGIN,
      requestedScopes: [auth.Scope.FULL_NAME, auth.Scope.EMAIL],
    })
    const credentialState = await auth.getCredentialStateForUser(response.user)
    if (credentialState !== auth.State.AUTHORIZED) {
      throw new SignInError(
        'Sign in succeeded, but credential state is not authorized',
        this.Error.AUTHORIZATION_FAILED,
      )
    }
    if (response?.fullName?.givenName && response?.fullName?.familyName) {
      // Apple will only return the user's first/last name the first time they authenticate.
      // Subsequent sign-ins will NOT include a name. So, it's important to store the name as soon as it comes in
      Storage.set(STORAGE_KEY, response.fullName)
    }
    return response
  }

  getFullName() {
    return Storage.get<AppleRequestResponseFullName>(STORAGE_KEY)
  }
}

export default new AppleAuth()
