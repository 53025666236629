import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { View, ViewStyle } from 'react-native'
import { WebView } from 'react-native-webview'
import Chart from 'react-native-f2chart'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { MultiSlider, timelineChart, sliderMiniChart } from '@components'
import { TWELVE_HOURS_TIME_FORMAT } from '@src/config/momentFormat'
import { eventsCalendarSelector } from '@src/selectors/events'
import { ChartData } from '@src/screens/Events/models/events.types'
import { useTimelineChartData } from './useTimelineChartData'
import { useTimelineChartRanges } from './useTimelineChartRanges'
import { useSliderFilteredChart } from './useSliderFilteredChart'

import { addDisplayDateToData, formatDates, getTickOptions, isDataAvailable } from './utils'
import Marker from './Marker'
import {
  LEFT_PADDING,
  MINI_CHART_HEIGHT,
  RIGHT_PADDING,
  SLIDER_PADDING,
  TOP_PADDING,
  ZOOM_MODE_SLIDER_PADDING,
  ChartTypes,
} from './constants'

interface TimelineChartProps {
  chartRef: any
  data: ChartData
  type: ChartTypes
  onChange?: any
  onLoadEnd?: any
  mode?: 'zoom'
  placeholder: string | null
  showPoints?: boolean
  style: ViewStyle
  connectNulls?: boolean
  initialMultiSliderValue?: [number, number]
  multiSliderValuesChange?: (values: [number, number]) => void
  chartRightPadding?: number
  disableAnimation?: boolean
  isChartVisible?: boolean
}

const onRenderLoading = () => null

export const TimelineChart = ({
  chartRef,
  data,
  type,
  onChange,
  onLoadEnd,
  mode,
  style,
  placeholder,
  showPoints = false,
  multiSliderValuesChange,
  initialMultiSliderValue,
  connectNulls = false,
  chartRightPadding = RIGHT_PADDING,
  disableAnimation = false,
  isChartVisible = true,
}: TimelineChartProps) => {
  const sliderChartRef = useRef<any>(null)
  const sliderRef = useRef<any>(null)
  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()
  const calendar = useSelector(eventsCalendarSelector)

  const hasChartData = data.values.length > 0 && isDataAvailable(data.values)
  const isZoomMode = mode === 'zoom'
  const displayDateFormat =
    calendar.rangeDays > 1 ? `MM/DD ${TWELVE_HOURS_TIME_FORMAT}` : TWELVE_HOURS_TIME_FORMAT

  const ranges = useTimelineChartRanges(data, hasChartData, type)
  const { chartData, events } = useTimelineChartData(data)
  const {
    filteredData,
    onMultiSliderValuesChange,
    onLayoutChange,
    sliderLength,
    minSliderValue,
    maxSliderValue,
    multiSliderValue,
  } = useSliderFilteredChart({
    chartRef,
    sliderChartRef,
    data,
    chartData,
    hasChartData,
    isChartVisible,
    initialMultiSliderValue,
    multiSliderValuesChange,
    placeholder,
  })

  const filteredDataWithDisplayDate = {
    ...filteredData,
    values: addDisplayDateToData(filteredData.values, displayDateFormat),
  }

  const { tickCount, tickDateFormat } = getTickOptions(filteredData.values, calendar.rangeDays)

  const getDefaultDataStyles = () => {
    if (!hasChartData) {
      return {
        ...styles.chart,
        lineColor: theme['theme.transparent'],
      }
    }
    return styles.chart
  }

  const script = timelineChart({
    data: filteredDataWithDisplayDate,
    events,
    ranges,
    showPoints,
    tickCount,
    showEvents: hasChartData,
    showTooltip: hasChartData,
    customTooltip: isZoomMode,
    formattedDates: formatDates(
      filteredData.values.map((value) => value.x),
      tickDateFormat,
    ),
    padding: `[ ${TOP_PADDING}, ${chartRightPadding}, 'auto', ${LEFT_PADDING} ]`,
    style: getDefaultDataStyles() as any,
    placeholder,
    hasChartData,
    disableAnimation,
    lineRatingColor: {
      good: theme['theme.range.good'],
      ok: theme['theme.range.regular'],
      bad: theme['theme.range.bad'],
      critical: theme['theme.range.bad'],
    },
  })

  const sliderChartScript = sliderMiniChart({
    data: data.values
      // Filter out events and keep only the glucose/ketones data on the slider chart
      // Items with tag `null` are needed in order to display gaps on empty chart regions
      .filter((item) => ['Glucose', 'Ketones', null].includes(item.tag))
      .map((value: any) => ({
        ...value,
        x: value.x.unix(),
      })),
    min: data.range.min,
    max: data.range.max,
    padding: '0',
    style: {
      strokeColor: theme['theme.surface.base'],
      fillColor: theme['theme.surface.base'],
    },
    connectNulls,
    disableAnimation,
  })

  const onChartChange = (data: any) => {
    if (data.type === 'loaded' && onLoadEnd) {
      onLoadEnd()
      return
    }

    onChange?.(data)
  }

  return (
    <View style={[styles.container, style]}>
      <Chart
        ref={chartRef}
        onChange={onChartChange}
        initScript={script}
        WebView={WebView}
        renderLoading={onRenderLoading}
      />
      <View
        style={[styles.sliderContainer, isZoomMode && styles.zoomMode]}
        onLayout={onLayoutChange}
      >
        <View style={styles.sliderChartContainer}>
          <Chart
            ref={sliderChartRef}
            initScript={sliderChartScript}
            WebView={WebView}
            renderLoading={onRenderLoading}
          />
        </View>
        {!!sliderLength && (
          <View style={styles.slider}>
            <MultiSlider
              ref={sliderRef}
              values={multiSliderValue}
              onValuesChangeFinish={onMultiSliderValuesChange}
              min={minSliderValue}
              max={maxSliderValue}
              sliderLength={sliderLength}
              sliderHeight={MINI_CHART_HEIGHT}
              selectedStyle={styles.selectedStyle}
              markerOffsetX={0}
              customMarker={Marker}
            />
          </View>
        )}
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  slider: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    top: 0,
  },
  zoomMode: {
    marginHorizontal: ZOOM_MODE_SLIDER_PADDING,
  },
  sliderContainer: {
    marginHorizontal: SLIDER_PADDING,
    height: MINI_CHART_HEIGHT,
    marginBottom: 10,
  },
  sliderChartContainer: {
    flex: 1,
  },
  selectedStyle: {
    borderWidth: 1,
    borderColor: 'theme.surface.base1',
  },
  chart: {
    guideLine: 'theme.surface.base1',
    pointColorMeal: 'theme.error.light_',
    pointColorActivity: 'theme.info.light_',
    pointColorJournalEntry: 'theme.warning.light_',
    pointColorOther: 'theme.solid.black',
    strokeColor: 'theme.solid.white',
    tooltipColor: 'theme.tooltip.background',
    tooltipMarkerColor: 'theme.tooltip.background',
    crosshairColor: 'theme.tooltip.background',
    fontColor: 'theme.tooltip.textPrimary',
    goodRangeBackground: 'theme.range.good',
    axisLine: 'theme.surface.base1',
    placeholderTextColor: 'theme.text.tertiary',
  } as any,
})
