import React from 'react'
import { View, Image, StyleProp, ImageStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { coursesModuleDescriptionImage } from '@assets/images'
import { Icon, Text } from '@components/base'

interface ModuleDescriptionProps {
  description: string
  locked: boolean
}

export const ModuleDescription = ({ description, locked }: ModuleDescriptionProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View>
      <View style={styles.card}>
        <Image
          style={styles.icon as StyleProp<ImageStyle>}
          source={coursesModuleDescriptionImage.imageSource}
        />
        <View style={styles.description}>
          <Text type="regular">{description}</Text>
        </View>
      </View>
      {locked && (
        <View style={styles.locked}>
          <Icon name="lock" />
          <Text type="regular" style={styles.lockedDescription}>
            Complete the previous module to unlock.
          </Text>
        </View>
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    margin: 16,
    backgroundColor: 'theme.secondary.base',
    borderRadius: 24,
  },
  icon: {
    width: 111,
    height: 127,
    resizeMode: 'contain',
  },
  description: {
    flex: 1,
    marginLeft: 16,
  },
  locked: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 16,
    marginBottom: 16,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 16,
    backgroundColor: 'theme.secondary.base',
  },
  lockedDescription: {
    marginLeft: 12,
    color: 'theme.text.primary',
  },
})
