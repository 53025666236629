import { gql } from '@apollo/client'

export const CREATE_CHATBOT_DELIMITER = gql`
  mutation createChatbotDelimiter($providerConversationId: ID!) {
    createChatbotDelimiter(providerConversationId: $providerConversationId) {
      id
      createdAt
    }
  }
`
