import * as React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const EmojiLevel0 = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 30 30" fill="none" {...props}>
      <Path
        d="M15 28.5c7.456 0 13.5-6.044 13.5-13.5S22.456 1.5 15 1.5 1.5 7.544 1.5 15 7.544 28.5 15 28.5z"
        fill="#fff"
        stroke="#7F8381"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default EmojiLevel0
