import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'

const Marker = () => {
  const styles = useStyleSheet(themedStyles)
  return (
    <View style={styles.dragHandle}>
      <View style={styles.dragGrip} />
      <View style={styles.dragGrip} />
    </View>
  )
}

export default Marker

const themedStyles = StyleService.create({
  dragHandle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: 36,
    width: 20,
    padding: 2,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: 'theme.surface.base',
    backgroundColor: 'theme.surface.base2',
  },
  dragGrip: {
    height: 12,
    width: 2,
    backgroundColor: 'theme.primary.base',
    borderRadius: 1,
  },
})
