import { useEffect, useMemo } from 'react'
import { shuffle } from 'lodash'
import {
  AnswersGroup,
  SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswersOrder as AnswersOrder,
} from '@src/types'

// convert boolean strings values to booleans
export const useAnswersGroups = (answersGroups: AnswersGroup[]) => {
  return useMemo(() => {
    return answersGroups?.map(({ answers, answersOrder, ...rest }) => {
      const answersGroup = {
        ...rest,
        answers: answers.map(({ value, ...rest }) => {
          return {
            ...rest,
            value: value === 'true' || value === 'false' ? value === 'true' : value,
          }
        }),
      }
      if (answersOrder === AnswersOrder.Random) {
        answersGroup.answers = shuffle(answersGroup.answers)
      }
      return answersGroup
    })
  }, [answersGroups])
}

// store "{ value }" object instead of "value" in form to allow "false" values submission
export const useSetValueObject = <T>({
  onChange,
  fieldValue,
  answersGroups,
}: {
  onChange: ({ value }: { value: T | T[] }) => void
  fieldValue: T | { value: T } | undefined
  answersGroups: ReturnType<typeof useAnswersGroups>
}) => {
  useEffect(() => {
    if (
      typeof fieldValue !== 'undefined' &&
      (typeof fieldValue !== 'object' || Array.isArray(fieldValue))
    ) {
      onChange({ value: fieldValue })
      return
    }
  }, [onChange, fieldValue, answersGroups])
}

export const useSetDefaultValue = ({
  onChange,
  fieldValue,
  defaultValue,
}: {
  onChange: (value: any) => void
  fieldValue: any
  defaultValue: number | null | false
}) => {
  useEffect(() => {
    if (typeof fieldValue === 'undefined' || fieldValue === '') {
      onChange(defaultValue)
      return
    }
  }, [onChange, fieldValue, defaultValue])
}
