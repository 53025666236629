import { SensorStatus } from 'react-native-freestyle-libre'

const statusDescription = ({
  isNfcSupported,
  isNfcEnabled,
  status,
  action,
}: {
  isNfcSupported: boolean
  isNfcEnabled: boolean
  status: SensorStatus
  action: 'scan' | 'activate'
}) => {
  if (!isNfcSupported) {
    return 'NFC scanning is not supported on your device.'
  }

  if (!isNfcEnabled) {
    return 'NFC scanning is not enabled on your device.'
  }

  if (action === 'activate') {
    return 'Ready to Activate!\nPlease place phone near Libre sensor.'
  }

  return {
    [SensorStatus.NotStarted]: 'Please activate your Sensor.\n(Settings -> Activate Sensor)',
    [SensorStatus.Starting]: 'Your sensor is not ready yet.\nPlease try again in 30 minutes.',
    [SensorStatus.Ready]: 'Ready to Scan!\nPlease place phone near Libre sensor.',
    [SensorStatus.Expired]: 'Your sensor has expired.\nPlease replace with a new one.',
    [SensorStatus.Shutdown]: 'Your sensor has expired.\nPlease replace with a new one.',
    [SensorStatus.Failure]: 'There was a problem with the scan.\nPlease try again in 15 minutes.',
    [SensorStatus.Unknown]: 'There was a problem with the scan.\nPlease try again in 15 minutes.',
  }[status]
}

export default { statusDescription }
