import { LogBox } from 'react-native'
import { Apollo } from './apollo'
import App from './app'
import Device from './device'

LogBox.ignoreLogs([
  /.*Support for defaultProps will be removed from function components in a future major release.*/,
  'Sending `onAnimatedValueUpdate` with no listeners registered.',
])

export default {
  showDevScreens: __DEV__,
  useFixtures: false,
  ezLogin: false,
  reduxLogging: __DEV__,
  includeExamples: __DEV__,
  useCodePush: App.build.beta || App.build.production,
  useAdminMenu: App.build.development || App.build.beta || App.build.alpha,
  shouldEnableIntercom: () => {
    if (Device.web) {
      return false
    }
    // beta and production builds use the production Intercom API key
    if (App.build.beta || App.build.production) {
      // Only enable Intercom when using the production endpoint to avoid corrupting production users' data
      return Apollo.endpointEnv === 'production'
    }
    // dev and alpha builds use the Intercom [TEST] API key
    // Only enable Intercom when using the staging endpoint to avoid corrupting other testers' user data
    return Apollo.endpointEnv === 'staging'
  },
  shouldEnableSegment: () => {
    // only beta and production builds use production segment API key
    if (App.build.beta || App.build.production) {
      return Apollo.endpointEnv === 'production'
    }

    // we don't need to enable it for development endpoint as we have different data locally
    return Apollo.endpointEnv === 'staging'
  },
  shouldEnableBetterStack: App.build.alpha || App.build.beta || App.build.production,
  allowExpiredSensor: App.build.development || App.build.beta || App.build.alpha,
  allowSkipQuestionnaire: App.build.development || App.build.beta || App.build.alpha,
}
