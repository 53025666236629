import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { TouchableSection } from '@components'

const NotificationsSection = () => {
  const navigation = useNavigation()

  const onPress = () => {
    navigation.navigate('NotificationsSettings')
  }

  return (
    <TouchableSection
      onPress={onPress}
      iconName="bell-simple"
      title="Notifications"
      showRightArrow
    />
  )
}

export default NotificationsSection
