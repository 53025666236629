import React, { useEffect } from 'react'
import { useRoute, RouteProp } from '@react-navigation/core'
import { useDispatch, useSelector } from 'react-redux'
import { AppStackParamList } from '@src/navigation/types'
import { useSnack } from '@src/utils/navigatorContext'
import { ListTutorial } from '../components/ListTutorial'
import { TutorialTypes } from '../models/tutorials.types'
import { CarouselTutorial } from '../components/CarouselTutorial'
import { currentTutorialSelector } from '../models/tutorials.selectors'

const LOADING_ERROR = 'Failed to load tutorial, please check your internet connection.'

export const CMSTutorial = () => {
  const {
    params: { tutorialId },
  } = useRoute<RouteProp<AppStackParamList, 'CMSTutorial'>>()
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const tutorial = useSelector(currentTutorialSelector)

  useEffect(() => {
    dispatch({
      type: 'tutorials/fetchTutorial',
      payload: { id: tutorialId },
      failure: () => {
        showSnack(LOADING_ERROR, null, 'error')
      },
    })
  }, [dispatch, showSnack, tutorialId])

  switch (tutorial.tutorialType) {
    case TutorialTypes.Carousel:
      return <CarouselTutorial tutorial={tutorial} />
    case TutorialTypes.List:
      return <ListTutorial tutorial={tutorial} />
    default:
      return null
  }
}
