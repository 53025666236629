import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { TouchableSection } from '@components'
import { useActiveSubscriptions } from '@src/hooks/useActiveSubscriptions'

const ManageSubscriptionSection = () => {
  const navigation = useNavigation()
  const subscription = useActiveSubscriptions()[0]

  if (!subscription) {
    return null
  }

  const onPress = () => navigation.navigate('ProgramDetails', { subscriptionId: subscription.id })

  return (
    <TouchableSection
      onPress={onPress}
      iconDescriptor={{ pack: 'eva', name: 'credit-card-outline' }}
      title="Manage Subscription"
      showRightArrow
    />
  )
}

export default ManageSubscriptionSection
