import React, { useRef, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { SelectFromActionSheet } from '@components'
import { healthSettingsSelector } from '@src/selectors/app'
import { showSnack } from '@src/services/Bluetooth/utils'
import { useGoBack } from '@src/utils'
import { settingsSelector } from '@src/selectors/settings'
import { orderIntervalRanges } from '../utils'

export const AddScanReminderForm = () => {
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()
  const dispatch = useDispatch()
  const settings = useSelector(settingsSelector)
  const healthSettings = useSelector(healthSettingsSelector)
  const options = orderIntervalRanges(healthSettings.scanReminderOptions)
  const currentReminderInterval = options.find(
    (option) => option.value === settings['scanReminder'],
  )
  const [interval, setInterval] = useState(currentReminderInterval?.text)
  const isSubmitting = useRef(false)

  const onSaveButtonPress = () => {
    if (isSubmitting.current) {
      return
    }
    isSubmitting.current = true
    const value = options.find((option) => option.text === interval)?.value
    dispatch({
      type: 'settings/update',
      payload: { scanReminder: value, reminders: true },
      success: () => {
        showSnack('Reminder saved!')
        goBack()
      },
      complete: () => {
        isSubmitting.current = false
      },
    })
  }
  return (
    <View style={styles.container}>
      <View style={styles.container}>
        <SelectFromActionSheet
          label="Reminder Interval"
          title="Reminder Interval"
          options={options.map(({ text }) => text)}
          value={interval}
          onChange={setInterval}
        />
      </View>
      <Button
        type="primary"
        size="block"
        accessibilityLabel="Add reminder"
        onPress={onSaveButtonPress}
      >
        Save
      </Button>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
})
