import React, { useContext } from 'react'
import { GestureResponderEvent, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { BottomSheet } from '@components'
import { Text } from '@components/base'
import { useGoBack } from '@src/utils'
import { ActionBottomSheetContext } from './useActionBottomSheet'

export const ActionBottomSheet = () => {
  const styles = useStyleSheet(themedStyles)
  const {
    props: {
      icon,
      title,
      body,
      primaryButton: primaryButtonOptions,
      secondaryButton: secondaryButtonOptions,
    },
  } = useContext(ActionBottomSheetContext)

  const goBack = useGoBack()

  const primaryButton = primaryButtonOptions
    ? {
        ...primaryButtonOptions,
        onPress: (event: GestureResponderEvent) => {
          goBack()
          primaryButtonOptions.onPress?.(event)
        },
      }
    : undefined

  const secondaryButton = secondaryButtonOptions
    ? {
        ...secondaryButtonOptions,
        onPress: (event: GestureResponderEvent) => {
          goBack()
          secondaryButtonOptions.onPress?.(event)
        },
      }
    : undefined

  return (
    <BottomSheet
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      buttonDirection="column-reverse"
    >
      <View style={styles.content}>
        {!!icon && <View style={styles.iconContainer}>{icon}</View>}
        {!!title && (
          <Text type="title-2" style={styles.text}>
            {title}
          </Text>
        )}
        {body && (
          <View style={styles.body}>
            {typeof body === 'string' ? (
              <Text type="regular" style={styles.text}>
                {body}
              </Text>
            ) : (
              body
            )}
          </View>
        )}
      </View>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  content: {
    marginTop: 16,
  },
  iconContainer: {
    alignItems: 'center',
  },
  body: {
    marginBottom: 24,
  },
  text: {
    textAlign: 'center',
  },
  buttons: {
    gap: 8,
  },
})
