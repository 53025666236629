import { useMemo } from 'react'
import { StyleSheet } from 'react-native'
import { merge } from 'lodash'
import { useStyleSheet } from '@src/style/service'

type NamedStyles<T> = StyleSheet.NamedStyles<T>

export const useVariantStyleSheet = <
  CommonStyle,
  VariantStyle,
  Variant extends keyof Variants,
  Variants extends Record<Variant, StyleSheet.NamedStyles<VariantStyle>>
>(
  commonStyles: NamedStyles<CommonStyle>,
  variant: Variant,
  variantStyles: Variants,
) => {
  const common = useStyleSheet(commonStyles)
  const selected = useStyleSheet(variantStyles[variant])

  return useMemo(() => {
    return merge(common, selected) as NamedStyles<CommonStyle & typeof variantStyles[Variant]>
  }, [common, selected])
}
