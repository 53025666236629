import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

export const EmptyMealsList = () => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.noResults}>
      <Text type="large" style={styles.centeredText}>
        No entries found.
      </Text>
      <Text type="large" style={styles.centeredText}>
        Try selecting a different meal type or remove the filters to view all meals.
      </Text>
    </View>
  )
}

const themedStyle = StyleService.create({
  noResults: {
    flexGrow: 1,
    padding: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredText: {
    textAlign: 'center',
  },
})
