interface TextType {
  fontSize: number
  fontWeight?: '400' | '700'
  lineHeights: {
    normal: number
    tight?: number
    none?: number
  }
}

export const textStyleValues = {
  color: 'theme.text.primary',
  fontFamilies: {
    normal: 'NBInternationalProReg',
    bold: 'NBInternationalProBol',
  },
  fontWeights: {
    normal: '400',
    bold: '700',
  },
  types: {
    score: {
      fontSize: 44,
      fontWeight: '700',
      lineHeights: {
        normal: 44,
      },
    },
    'title-1': {
      fontSize: 32,
      fontWeight: '700',
      lineHeights: {
        normal: 42,
      },
    },
    'title-2': {
      fontSize: 24,
      fontWeight: '700',
      lineHeights: {
        normal: 32,
      },
    },
    'title-3': {
      fontSize: 18,
      fontWeight: '700',
      lineHeights: {
        normal: 24,
      },
    },
    large: {
      fontSize: 17,
      lineHeights: {
        none: 18,
        tight: 20,
        normal: 24,
      },
    },
    regular: {
      fontSize: 15,
      lineHeights: {
        none: 16,
        tight: 20,
        normal: 22,
      },
    },
    small: {
      fontSize: 13,
      lineHeights: {
        none: 14,
        tight: 16,
        normal: 20,
      },
    },
    tiny: {
      fontSize: 11,
      lineHeights: {
        none: 12,
        tight: 14,
        normal: 16,
      },
    },
    micro: {
      fontSize: 9,
      lineHeights: {
        normal: 11,
      },
    },
  } as { [key: string]: TextType },
} as const
