import React from 'react'
import { View } from 'react-native'
import Svg, { Path } from 'react-native-svg'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ArcIndicatorInfo, ArcIndicatorStateConfigs } from './ArcIndicatorInfo'
import { ArcIndicatorInfoOld } from './ArcIndicatorInfoOld'
const { PI, cos, sin } = Math

// Angle will be a bit more than 180°. Change coefficient to make indicator looks more or less like a circle
const arcIndicatorAngleSize = 1.2 * PI

export const ARC_INDICATOR_PROPERTIES = { width: 170, height: 120, strokeWidth: 17 }
export const ARC_INDICATOR_PROPERTIES_OLD = { width: 222, height: 155, strokeWidth: 22 }

export interface ArcIndicatorProps {
  title: string
  value?: number
  minValue: number
  maxValue: number
  stateConfigs: ArcIndicatorStateConfigs
  enableNewLayout?: boolean
}

export const ArcIndicator = ({
  title,
  value,
  minValue,
  maxValue,
  stateConfigs,
  enableNewLayout,
}: ArcIndicatorProps) => {
  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()

  const { width, height, strokeWidth } = enableNewLayout
    ? ARC_INDICATOR_PROPERTIES
    : ARC_INDICATOR_PROPERTIES_OLD

  const radius = (width - strokeWidth) / 2
  const arcLength = radius * arcIndicatorAngleSize

  const distanceFromCircleCenterToArcEnds =
    cos(PI - arcIndicatorAngleSize / 2) * radius + strokeWidth / 2

  const centerX = width / 2
  const centerY = height - distanceFromCircleCenterToArcEnds

  const startAngle = PI / 2 + arcIndicatorAngleSize / 2
  const endAngle = startAngle - arcIndicatorAngleSize

  const startX = centerX - radius * cos(startAngle)
  const startY = centerY - radius * sin(startAngle)
  const endX = centerX - radius * cos(endAngle)
  const endY = centerY - radius * sin(endAngle)

  const arcPath = `M ${startX} ${startY} A ${radius} ${radius} 0 1 0 ${endX} ${endY}`

  const progress = value === undefined ? 0 : value / (maxValue - minValue)
  const strokeDashoffset = arcLength * progress - arcLength

  const stateConfig =
    value === undefined
      ? stateConfigs.noDataAvailableConfig
      : stateConfigs.rangeConfigs.find(
          (config) => config.minValue <= value && value <= config.maxValue,
        )

  const ArcIndicatorInfoComponent = enableNewLayout ? ArcIndicatorInfo : ArcIndicatorInfoOld

  return (
    <View style={styles.container}>
      <Svg width={width} height={height}>
        <Path
          stroke={theme['theme.surface.base2']}
          fill="none"
          strokeLinecap="round"
          {...{
            strokeWidth,
            d: arcPath,
          }}
        />
        <Path
          stroke={stateConfig?.color}
          fill="none"
          strokeDasharray={`${arcLength}, ${arcLength}`}
          strokeLinecap="round"
          {...{
            strokeDashoffset,
            strokeWidth,
            d: arcPath,
          }}
        />
      </Svg>
      <ArcIndicatorInfoComponent title={title} value={value} stateConfig={stateConfig} />
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
  },
})
