import React from 'react'
import {
  KeyboardAwareScrollView,
  KeyboardAwareScrollViewProps,
} from 'react-native-keyboard-aware-scroll-view'
import { StyleService } from '@src/style/service'

interface ScrollableAvoidKeyboardProps extends KeyboardAwareScrollViewProps {
  children: React.ReactNode | React.ReactNode[]
}

export const ScrollableAvoidKeyboard = ({
  style,
  contentContainerStyle,
  ...restProps
}: ScrollableAvoidKeyboardProps) => (
  <KeyboardAwareScrollView
    style={[styles.container, style]}
    contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
    enableOnAndroid={false}
    {...restProps}
  />
)

const styles = StyleService.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flexGrow: 1,
  },
})
