import { isArray } from 'lodash'
import { fromKeyValuePairs } from '@transforms'

import { LEGACY_MEASUREMENT_TYPE_DEF_MAP } from '../screens/Events/models/events.types'

export const transformHistoryOrEventItem = (item) => {
  let type = item.type

  if (LEGACY_MEASUREMENT_TYPE_DEF_MAP[type]) {
    type = LEGACY_MEASUREMENT_TYPE_DEF_MAP[type]
  }

  return {
    ...item,
    nutrition: isArray(item.nutrition) ? fromKeyValuePairs(item.nutrition) : item.nutrition,
    type,
  }
}
