import { orderBy, startCase } from 'lodash'
import { useEffect, useState } from 'react'
import { Note } from '@src/types'
import { formatRelativeDate } from '@src/config/momentFormat'

export const useNotesUpdateInfo = (notes: Note[]) => {
  const [updatedLast, setUpdatedOn] = useState<string>('')

  useEffect(() => {
    const orderedNotes = orderBy(notes, 'updatedAt', 'desc')
    const updatedLast = formatRelativeDate(orderedNotes[0]?.updatedAt)

    setUpdatedOn(updatedLast)
  }, [notes])

  const nutritionist = notes[0]?.creator || {}
  const { firstName, lastName = '' } = nutritionist

  const nutritionistName = `${startCase(firstName)} ${startCase((lastName || '').charAt(0))}`

  return {
    nutritionistName,
    updatedLast,
  }
}
