import { gql } from '@apollo/client/core'
import { CORE_SUBSCRIPTION_FIELDS } from '@src/graphql/fragments/subscription'

const SET_SUBSCRIPTION_BILLING_CYCLE = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  mutation setSubscriptionBillingCycle($id: ID!, $newBillingCycleAnchor: ISO8601DateTime!) {
    setSubscriptionBillingCycle(id: $id, newBillingCycleAnchor: $newBillingCycleAnchor) {
      ...CoreSubscriptionFields
    }
  }
`

export default SET_SUBSCRIPTION_BILLING_CYCLE
