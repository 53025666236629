import { createContext } from 'react'
import { Libre3BluetoothManagerConnectionState, PatchInfoData } from 'react-native-freestyle-libre'

interface ScanningContextValue {
  realTimeGlucoseValue: number | null
  realTimeGlucoseTime: Date | null
  bluetoothConnectionState: Libre3BluetoothManagerConnectionState
  patchInfo: PatchInfoData | null
  isBluetoothEnabled?: boolean
  isBluetoothPermitted?: boolean
  isNfcEnabled: boolean
  isNfcSupported: boolean
  connectToSensor: () => void
}

export const ScanningContext = createContext<ScanningContextValue>({
  realTimeGlucoseValue: null,
  realTimeGlucoseTime: null,
  bluetoothConnectionState: Libre3BluetoothManagerConnectionState.NotConnected,
  patchInfo: null,
  isBluetoothEnabled: undefined,
  isBluetoothPermitted: undefined,
  isNfcEnabled: true,
  isNfcSupported: true,
  connectToSensor: () => undefined,
})
