import { gql } from '@apollo/client'

export const FETCH_AVAILABLE_SLOTS = gql`
  query insuranceSchedulingAvailableSlots(
    $appointmentType: InsuranceBerryStreetAppointmentType
    $timeZone: String
    $date: String
  ) {
    insuranceSchedulingAvailableSlots(
      appointmentType: $appointmentType
      timeZone: $timeZone
      date: $date
    ) {
      slots {
        time
        coachId
      }
    }
  }
`
