import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { authenticatedUserSelector } from '@src/selectors/app'
import { JournalEntry } from '@src/types'
import { ListItemProps } from '@src/components/list/ListItemFactory'
import { useMenuHandling } from '@src/components/list/MenuHandler'
import {
  EmojiLevel0,
  EmojiLevel1,
  EmojiLevel2,
  EmojiLevel3,
  EmojiLevel4,
  EmojiLevel5,
} from '@src/assets/svgs/journal'
import { ListItemWithScore } from '../common/ListItemWithScore'

const TIME_FORMAT = 'h:mm a'
const ADMIN_BADGE_TEXT = 'from your Coach'
const DISPLAYED_LINE_COUNT = 1

type JournalEntryListItemProps = ListItemProps<JournalEntry>

const scoreEmoji = (score: number | null | undefined) => {
  if (!score) {
    return null
  }

  return [EmojiLevel0, EmojiLevel1, EmojiLevel2, EmojiLevel3, EmojiLevel4, EmojiLevel5][score]
}

export const JournalEntryListItem = ({
  item,
  highlighted,
  canJumpToDate,
}: JournalEntryListItemProps) => {
  const { defaultMenuItems, onItemEdit } = useMenuHandling(item, canJumpToDate)
  const user = useSelector(authenticatedUserSelector)

  const { title, body, occurredAt, creatorId, score } = item
  return (
    <ListItemWithScore
      title={title ?? ''}
      subtitle={body}
      info={moment(occurredAt).format(TIME_FORMAT)}
      menuItems={defaultMenuItems}
      iconDescriptor={{ pack: 'fontAwesome6Pro', name: 'note' }}
      onItemPress={onItemEdit}
      SecondaryIcon={scoreEmoji(score)}
      tag={creatorId && user.id !== creatorId ? ADMIN_BADGE_TEXT : undefined}
      numberOfLines={DISPLAYED_LINE_COUNT}
      highlighted={highlighted}
    />
  )
}
