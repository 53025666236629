import reducers from '@screens/Education/reducers'
import { EducationStoreState } from '@screens/Education/types'

export default class Education {
  namespace = 'education'

  state: EducationStoreState = {
    visitedTutorials: {},
  }

  reducers = reducers
}
