import { gql } from '@apollo/client'

export const UPDATE_DAILY_MEASUREMENTS_SOURCE_PRIORITY = gql`
  mutation updateDailyMeasurementsSourcePriority(
    $type: HealthDataMeasurementType!
    $sources: [HealthDataMeasurementSource!]!
  ) {
    updateDailyMeasurementsSourcePriority(type: $type, sources: $sources) {
      type
      sources
    }
  }
`
