import React from 'react'
import { TopNavigation, TopNavigationAction } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { useGoBack } from '@src/utils/navigation'

interface TabNavigationProps {
  title: string
  onArrowBackClick?: () => void
}

export const PasswordResetTopNavigation = ({ title, onArrowBackClick }: TabNavigationProps) => {
  const styles = useStyleSheet(themedStyle)
  const goBack = useGoBack()

  return (
    <TopNavigation
      alignment="center"
      accessoryLeft={
        <TopNavigationAction
          icon={<Icon name="caret-left" />}
          onPress={onArrowBackClick ?? goBack}
        />
      }
      style={styles.topNavigation}
      title={() => (
        <Text type="title-3" style={styles.title}>
          {title}
        </Text>
      )}
    />
  )
}

const themedStyle = StyleService.create({
  topNavigation: {
    backgroundColor: 'theme.background',
  },
  title: {
    textAlign: 'center',
  },
})
