import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { CircleIcon } from '@components'
import { Activity } from '@src/types'

const LIST_ITEM_TIME_FORMAT = 'MMM D - H:mma'

interface ActivityListItemProps {
  item: Activity
  onItemSelected: (item: Activity) => void
  onToggleFavorite: (item: Activity) => void
}

export const ActivityListItem = ({
  item,
  onItemSelected,
  onToggleFavorite,
}: ActivityListItemProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <TouchableOpacity
      accessibilityLabel={item.title}
      style={styles.itemContainer}
      activeOpacity={0.7}
      onPress={() => onItemSelected(item)}
    >
      <CircleIcon name="pulse" style={styles.activityIcon} />
      <View style={styles.itemContent}>
        <Text type="large">
          {item.title}
          <Text type="regular" style={styles.secondaryText}>
            {' · '}
            {moment(item.occurredAt).format(LIST_ITEM_TIME_FORMAT)}
          </Text>
        </Text>
        {!!item.description && (
          <Text type="regular" numberOfLines={1} style={styles.secondaryText}>
            {item.description}
          </Text>
        )}
      </View>
      <TouchableOpacity
        accessibilityLabel="Toggle favorite"
        style={styles.iconButton}
        onPress={() => onToggleFavorite(item)}
      >
        <Icon name="heart" style={styles.icon} weight={item.favorite ? 'fill' : 'regular'} />
      </TouchableOpacity>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  itemContainer: {
    height: 80,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'theme.surface.base1',
  },
  activityIcon: {
    height: 56,
    width: 56,
    marginRight: 16,
  },
  itemContent: {
    flex: 1,
    justifyContent: 'center',
    paddingVertical: 24,
  },
  secondaryText: {
    color: 'theme.text.secondary',
  },
  iconButton: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    width: 24,
    height: 24,
    color: 'theme.like',
  },
})
