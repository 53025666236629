import React, { useState } from 'react'
import { Pressable, PressableProps, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName, IconProps } from '@components/base'

export type IconButtonProps = PressableProps & {
  accessibilityLabel: string
  allowPressWhenDisabled?: boolean
  disabled?: boolean
  iconName: IconName
  iconProps?: Omit<IconProps, 'name'>
  shape?: 'round' | 'square'
  size: 'xs' | 's' | 'm' | 'l'
  style?: ViewStyle
  type: 'primary' | 'secondary' | 'outline' | 'transparent'
}

const BUTTON_SIZE = {
  xs: 24,
  s: 32,
  m: 48,
  l: 64,
}
const BUTTON_SQUARE_RADIUS = {
  xs: 4,
  s: 4,
  m: 8,
  l: 12,
}
const ICON_SIZE = {
  xs: 16,
  s: 20,
  m: 24,
  l: 32,
}

export const IconButton = ({
  accessibilityLabel,
  allowPressWhenDisabled,
  disabled,
  iconName,
  iconProps,
  shape = 'round',
  size,
  style,
  type,
  ...props
}: IconButtonProps) => {
  const styles = useStyleSheet(themedStyle)

  const [isPressed, setIsPressed] = useState(false)

  const buttonStyle = [
    styles.button,
    styles[type],
    isPressed && styles[`${type}Pressed`],
    disabled && styles[`${type}Disabled`],
    {
      width: BUTTON_SIZE[size],
      height: BUTTON_SIZE[size],
      borderRadius: shape === 'round' ? BUTTON_SIZE[size] / 2 : BUTTON_SQUARE_RADIUS[size],
    },
    style,
  ]
  const iconStyle = [
    styles[`${type}Icon`],
    disabled && styles.disabledIcon,
    {
      width: ICON_SIZE[size],
      height: ICON_SIZE[size],
    },
    iconProps?.style,
  ]

  return (
    <Pressable
      {...props}
      disabled={disabled && !allowPressWhenDisabled}
      style={buttonStyle}
      accessibilityLabel={accessibilityLabel}
      onPressIn={(e) => {
        setIsPressed(true)
        props.onPressIn?.(e)
      }}
      onPressOut={(e) => {
        setIsPressed(false)
        props.onPressOut?.(e)
      }}
    >
      <Icon {...iconProps} name={iconName} style={iconStyle} />
    </Pressable>
  )
}

const themedStyle = StyleService.create({
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    aspectRatio: 1,
  },
  // Variant
  primary: {
    backgroundColor: 'theme.primary.base',
  },
  secondary: {
    backgroundColor: 'theme.secondary.base',
  },
  outline: {
    backgroundColor: 'theme.transparent',
    borderColor: 'theme.surface.base1',
    borderWidth: 2,
  },
  transparent: {
    backgroundColor: 'theme.transparent',
  },
  // Pressed
  primaryPressed: {
    backgroundColor: 'theme.primary.dark',
  },
  secondaryPressed: {
    backgroundColor: 'theme.secondary.darker',
  },
  outlinePressed: {
    borderWidth: 4,
  },
  transparentPressed: {
    backgroundColor: 'theme.surface.base1',
  },
  // Disabled
  primaryDisabled: {
    backgroundColor: 'theme.disabled.background',
  },
  secondaryDisabled: {
    backgroundColor: 'theme.disabled.background',
  },
  outlineDisabled: {
    borderColor: 'theme.surface.base3',
  },
  transparentDisabled: {
    backgroundColor: 'theme.transparent',
  },
  // Icon color
  primaryIcon: {
    color: 'theme.button.text.primary',
  },
  secondaryIcon: {
    color: 'theme.text.primary',
  },
  outlineIcon: {
    color: 'theme.text.primary',
  },
  transparentIcon: {
    color: 'theme.text.primary',
  },
  disabledIcon: {
    color: 'theme.disabled.text',
  },
})
