import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

export interface FormButtonProps {
  label: string
  title: string
  isReadonlyMode?: boolean
  testID?: string
  onPress?: () => void
}

export const PickerButtonBlock = (props: FormButtonProps) => {
  const styles = useStyleSheet(themedStyle)
  const { label, title, isReadonlyMode, onPress, testID } = props

  return (
    <TouchableOpacity
      testID={testID}
      style={styles.container}
      activeOpacity={0.7}
      onPress={onPress}
      disabled={isReadonlyMode}
      accessibilityLabel={label}
    >
      <View style={styles.contentContainer}>
        <Text type="small" style={styles.label}>
          {label}
        </Text>
        <Text type="large" bold style={styles.title} numberOfLines={1}>
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: 16,
  },
  label: {
    color: 'theme.text.secondary',
  },
  title: {
    marginTop: 4,
  },
})
