import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { clientConfigStoreStateSelector } from '@src/selectors/app'
import { HealthDataMeasurementType, HealthMetricsKind, HealthMetricsParam } from '@src/types'
import { useUnitSystemMeasurements } from '../Measurements/hooks'

export const useMeasurementHealthMetricsUnits = () => {
  const measurementUnitConfig = useUnitSystemMeasurements()
  const clientConfig = useSelector(clientConfigStoreStateSelector)
  const healthMetricsConfig = get(clientConfig, 'healthMetrics', [])

  const measurementHealthMetrics = healthMetricsConfig.filter(
    (metric) => metric.kind === HealthMetricsKind.Measurement,
  )

  return measurementHealthMetrics.reduce((acc, metric) => {
    const enumKey = Object.keys(HealthDataMeasurementType)[
      Object.values(HealthDataMeasurementType)
        .map((val) => val.toString())
        .indexOf(metric.key)
    ]
    const unit =
      measurementUnitConfig[
        HealthDataMeasurementType[enumKey as keyof typeof HealthDataMeasurementType]
      ]?.units
    return { ...acc, [metric.key]: unit }
  }, {} as Record<HealthMetricsParam, string>)
}
