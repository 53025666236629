import { useState, useEffect } from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { productsSelector } from '@src/selectors/app'
import { Feature, useFeatureFlag } from '@src/components'
import { Subscription } from '@src/types'

const NEXT_BILLING_CYCLE_TOO_CLOSE_HOURS = 12

export const useCanChangeBillingCycle = (
  subscription?: Pick<
    Subscription,
    'resetBillingCycleAnchorAt' | 'currentPeriodEndAt' | 'cancelAt' | 'paused'
  > & { primaryProduct: { id: string } },
) => {
  const isChangeBillingCycleEnabled = useFeatureFlag(Feature.SubscriptionChangeBillingCycle)
  const products = useSelector(productsSelector)
  const isTooCloseToBillingDate = useIsTooCloseToBillingDate(subscription)

  if (!subscription || isTooCloseToBillingDate) {
    return false
  }

  const subscriptionProduct = products.find(
    (product) => subscription.primaryProduct.id === product.id,
  )

  return (
    (isChangeBillingCycleEnabled &&
      subscriptionProduct?.billingDateChangeable &&
      !subscription?.cancelAt &&
      !subscription?.paused) ||
    false
  )
}

const isCloseToBillingDate = (
  subscription: Pick<Subscription, 'resetBillingCycleAnchorAt' | 'currentPeriodEndAt'>,
) => {
  const scheduledBillingDate = moment(
    subscription.resetBillingCycleAnchorAt || subscription.currentPeriodEndAt,
  )

  const nowWithBuffer = moment.max(
    moment().endOf('day'),
    moment().add(NEXT_BILLING_CYCLE_TOO_CLOSE_HOURS, 'hours'),
  )

  if (scheduledBillingDate.isAfter(nowWithBuffer)) {
    return false
  }

  // The current billing cycle period-end or new billing date is too close to safely make changes
  return true
}

const useIsTooCloseToBillingDate = (
  subscription?: Pick<Subscription, 'resetBillingCycleAnchorAt' | 'currentPeriodEndAt'>,
) => {
  const [tooClose, setTooClose] = useState(false)

  useEffect(() => {
    if (!subscription) {
      return
    }

    if (isCloseToBillingDate(subscription)) {
      setTooClose(true)
      return
    }

    const interval = setInterval(() => {
      if (isCloseToBillingDate(subscription)) {
        setTooClose(true)
      }
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [tooClose, setTooClose, subscription])

  return tooClose
}
