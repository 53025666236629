import React from 'react'
import { View } from 'react-native'
import { BottomSheet } from '@src/components'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useGoBack } from '@src/utils'

export const FeePaymentSuccessfulModal = () => {
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()

  return (
    <BottomSheet
      buttonDirection="column-reverse"
      primaryButton={{ text: 'Dismiss', onPress: goBack }}
    >
      <View style={styles.iconContainer}>
        <Icon name="check-circle" style={styles.icon} weight="duotone" />
      </View>
      <Text style={styles.content} type="title-2">
        Payment Successful!
      </Text>
      <Text style={styles.content} type="regular">
        Prevent fees by rescheduling or canceling at least 24 hours before your call.
      </Text>
      <Text style={styles.content} type="regular">
        Thanks for understanding!
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  content: {
    textAlign: 'center',
    margin: 8,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 48,
    height: 48,
  },
})
