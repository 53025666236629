import { gql } from '@apollo/client'

export const FETCH_CALENDAR_DATA = gql`
  query calendar($filter: DateFilter) {
    calendar(filter: $filter) {
      dates {
        date
        score
      }
    }
  }
`
