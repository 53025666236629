import {
  SurveysConfigObjectQuestionsGroupQuestionResponseAnswersGroupAnswerFollowupKind as FollowupKinds,
  Question,
  SurveysQuestionKey,
  Survey,
} from '@src/types'

export type QuestionId = {
  sectionIndex: number
  questionIndex: number
}

export class QuestionnaireConfigInteractor {
  private questionnaireConfig: Survey
  private standaloneFollowups: {
    parentQuestionKey: SurveysQuestionKey
    answerValue: any
    question: Question
  }[] = []

  constructor(questionnaireConfig: Survey) {
    this.questionnaireConfig = questionnaireConfig

    this.standaloneFollowups = questionnaireConfig.followups.flatMap((followup) => {
      if (followup.kind !== FollowupKinds.Standalone) {
        return []
      }

      return [
        {
          parentQuestionKey: (followup.parentQuestionKey as unknown) as SurveysQuestionKey,
          answerValue: followup.parentAnswer.value,
          question: followup.question,
        },
      ]
    })
  }

  // find all followups for question
  public findQuestionStandaloneFollowups = ({
    questionKey,
  }: {
    questionKey: SurveysQuestionKey
  }) => {
    return this.standaloneFollowups.filter((followup) => followup.parentQuestionKey === questionKey)
  }

  // find followup for specific question/answer
  public findQuestionStandaloneFollowupsWithAnswer = ({
    questionKey,
    answerValues,
  }: {
    questionKey: SurveysQuestionKey
    answerValues: string[]
  }) => {
    return this.findQuestionStandaloneFollowups({ questionKey }).filter((followup) =>
      answerValues.includes(followup.answerValue),
    )
  }

  public removeQuestionByKey = (key: string, { sectionIndex }: QuestionId) => {
    this.questionnaireConfig.questionsGroups[
      sectionIndex
    ].questions = this.questionnaireConfig.questionsGroups[sectionIndex].questions.filter(
      (question) => question.key !== key,
    )

    this.questionnaireConfig.questionsGroups = this.questionnaireConfig.questionsGroups.filter(
      (group) => group.questions.length > 0,
    )
  }

  public addQuestionAfterCurrent = (
    question: Question,
    { sectionIndex, questionIndex }: QuestionId,
  ) => {
    if (
      this.questionnaireConfig.questionsGroups[sectionIndex].questions.find(
        (q2) => q2.key === question.key,
      )
    ) {
      return
    }

    this.questionnaireConfig.questionsGroups[sectionIndex].questions.splice(
      questionIndex + 1,
      0,
      question,
    )
  }

  public findQuestion = (questionKey: SurveysQuestionKey): QuestionId | null => {
    for (
      let sectionIndex = 0;
      sectionIndex < this.questionnaireConfig.questionsGroups.length;
      sectionIndex += 1
    ) {
      for (
        let questionIndex = 0;
        questionIndex < this.questionnaireConfig.questionsGroups[sectionIndex].questions.length;
        questionIndex += 1
      ) {
        const question = this.questionnaireConfig.questionsGroups[sectionIndex].questions[
          questionIndex
        ]
        if (question.key === questionKey) {
          return { sectionIndex, questionIndex }
        }
      }
    }

    return null
  }

  public getQuestionsCount = () => {
    return this.questionnaireConfig.questionsGroups.reduce(
      (acc, group) => acc + group.questions.length,
      0,
    )
  }

  public getCurrentQuestionIndex = ({ sectionIndex, questionIndex }: QuestionId) => {
    return (
      this.questionnaireConfig.questionsGroups
        .slice(0, sectionIndex)
        .reduce((acc, group) => acc + group.questions.length, 0) + questionIndex
    )
  }

  public getSectionConfig = (sectionIndex: number) => {
    return this.questionnaireConfig.questionsGroups[sectionIndex]
  }

  public isLastQuestionInSection = ({ sectionIndex, questionIndex }: QuestionId) => {
    return questionIndex >= this.getSectionConfig(sectionIndex).questions.length - 1
  }

  public isLastQuestion = ({ sectionIndex, questionIndex }: QuestionId) => {
    return (
      sectionIndex >= this.questionnaireConfig.questionsGroups.length - 1 &&
      this.isLastQuestionInSection({ sectionIndex, questionIndex })
    )
  }

  public getNumberOfSections = () => {
    return this.questionnaireConfig.questionsGroups.length
  }
}
