import React, { useState } from 'react'
import { StyleService } from '@src/style/service'
import { SliderInputWithSkipConfig } from '../../types'
import { Option } from './Shared/Option'
import { OptionType } from './Shared/Option'
import { InputPropsBase } from './types'
import { SliderInput } from './SliderInput'

interface TextAreaWithSkipInputProps extends InputPropsBase {
  kindMetadata: SliderInputWithSkipConfig['kindMetadata']
}

export const SliderWithSkipInput = ({
  field,
  kindMetadata,
}: TextAreaWithSkipInputProps): JSX.Element => {
  const skipLabel = kindMetadata?.skip_label || 'Skip'
  const skipValue = kindMetadata?.skip_value
  const [isOptionSelected, setIsOptionSelected] = React.useState(field.value === skipValue)
  const [sliderValue, setSliderValue] = useState<number>()

  const onOptionPress = () => {
    if (isOptionSelected) {
      setIsOptionSelected(false)
      field.onChange(sliderValue)
    } else {
      setIsOptionSelected(true)
      field.onChange(skipValue)
    }
  }

  const onSliderValueChange = (newValue: number) => {
    if (!isOptionSelected || !field.value || typeof sliderValue !== 'undefined') {
      // set initial field value with slider value and remove selected option on slider change
      setIsOptionSelected(false)
      field.onChange(newValue)
    }
    setSliderValue(newValue)
  }

  return (
    <>
      <SliderInput
        kindMetadata={kindMetadata}
        field={{
          value: sliderValue,
          onChange: onSliderValueChange,
        }}
        style={isOptionSelected && styles.disabledSlider}
      />
      <Option
        style={styles.skipOption}
        label={skipLabel}
        onPress={onOptionPress}
        selected={isOptionSelected}
        type={OptionType.Radio}
      />
    </>
  )
}

const styles = StyleService.create({
  disabledSlider: {
    opacity: 0.7,
  },
  skipOption: {
    marginTop: 12,
  },
})
