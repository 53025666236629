import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'
import { ExpandableProgram, ExpandableModule, Lesson } from '@src/types'
import {
  ProgramWithContent,
  ModuleWithContent,
  LessonWithContent,
  LessonItemContentType,
} from './courses.types'

export const coursesStateSelector = (state: RootStoreState) => state.courses

export const publishedProgramsSelector = createSelector(coursesStateSelector, (courses) => {
  return courses.published.programs
})

export const publishedProgramSelector = (programId: string) =>
  createSelector(publishedProgramsSelector, (programs) => programs.find((p) => p.id === programId))

export const publishedModulesSelector = (programId: string) =>
  createSelector(publishedProgramSelector(programId), (program) => program?.modules)

export const publishedModuleSelector = (programId: string, moduleId: string) =>
  createSelector(publishedModulesSelector(programId), (modules) =>
    modules?.find((m) => m.id === moduleId),
  )

export const programContentsSelector = createSelector(coursesStateSelector, (courses) => {
  return courses.programs
})

export const programsWithContentSelector = (programIds: string[]) => {
  return createSelector(
    publishedProgramsSelector,
    programContentsSelector,
    (programs, programContents) =>
      programs
        .filter((p) => programIds.includes(p.id))
        .reduce((filtered: ProgramWithContent[], program: ExpandableProgram) => {
          const content = programContents.find((p) => p.id === program.contentId)
          if (content) {
            filtered.push({
              ...content,
              ...program,
            })
          }
          return filtered
        }, []),
  )
}

export const programWithContentSelector = (programId: string) =>
  createSelector(programsWithContentSelector([programId]), (programs) => programs?.[0])

export const moduleContentsSelector = createSelector(coursesStateSelector, (courses) => {
  return courses.modules
})

export const modulesWithContentSelector = (programId: string, moduleIds: string[]) =>
  createSelector(
    publishedProgramSelector(programId),
    moduleContentsSelector,
    (program, moduleContents) =>
      program?.modules
        ?.filter((m) => moduleIds.includes(m.id))
        ?.reduce((filtered: ModuleWithContent[], module: ExpandableModule) => {
          const content = moduleContents.find((l) => l.id === module.contentId)
          if (content) {
            filtered.push({
              ...content,
              ...module,
            })
          }
          return filtered
        }, []),
  )

export const moduleWithContentSelector = (programId: string, moduleId: string) =>
  createSelector(modulesWithContentSelector(programId, [moduleId]), (modules) => modules?.[0])

export const lessonContentsSelector = createSelector(coursesStateSelector, (courses) => {
  return courses.lessons
})

export const lessonsWithContentSelector = (
  programId: string,
  moduleId: string,
  lessonIds: string[],
) =>
  createSelector(
    publishedModuleSelector(programId, moduleId),
    lessonContentsSelector,
    (module, lessonContents) =>
      (module?.lessons || [])
        .filter((l) => lessonIds.includes(l.id))
        .reduce((filtered: LessonWithContent[], lesson: Lesson) => {
          const content = lessonContents.find((l) => l.id === lesson.contentId)
          const isExperiment =
            content?.items?.some((item) => item.__typename === LessonItemContentType.Experiment) ||
            false

          if (content) {
            filtered.push({
              ...content,
              ...lesson,
              isExperiment,
            })
          }
          return filtered
        }, []),
  )
