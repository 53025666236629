import { gql } from '@apollo/client'
import { CORE_USER_FIELDS } from './fragments/user'
import { CORE_ADDRESS_FIELDS } from './fragments/address'

export const SIGN_IN_THIRD_PARTY_USER = gql`
  ${CORE_USER_FIELDS}
  ${CORE_ADDRESS_FIELDS}
  mutation signInThirdPartyUser(
    $provider: ThirdPartySignInProvider!
    $providerToken: String!
    $providerUserId: String
  ) {
    signInThirdPartyUser(
      provider: $provider
      providerToken: $providerToken
      providerUserId: $providerUserId
    ) {
      token
      success
      user {
        ...CoreUserFields
        address {
          ...CoreAddressFields
        }
        paymentMethod {
          stripeId
          cardType
          last4
          expirationDate
        }
      }
      features
    }
  }
`
