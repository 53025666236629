import { gql } from '@apollo/client'

const SUBMIT_HEALTH_METRICS = gql`
  mutation submitHealthMetrics(
    $healthMetricsData: [HealthMetricData!]
    $measurementsData: [MeasurementData!]
    $date: ISO8601Date!
  ) {
    submitHealthMetrics(
      healthMetricsData: $healthMetricsData
      measurementsData: $measurementsData
      date: $date
    ) {
      success
    }
  }
`

export default SUBMIT_HEALTH_METRICS
