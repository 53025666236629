import ReactNativeHapticFeedback from 'react-native-haptic-feedback'
import { Device } from '@config'

const options = {
  enableVibrateFallback: false,
  ignoreAndroidSystemSettings: false,
}

const lightTap = () => {
  Device.ios
    ? ReactNativeHapticFeedback.trigger('impactLight', options)
    : ReactNativeHapticFeedback.trigger('keyboardTap', options)
}

const heavyTap = () => {
  Device.ios
    ? ReactNativeHapticFeedback.trigger('impactHeavy', options)
    : ReactNativeHapticFeedback.trigger('keyboardTap', options)
}

export default { lightTap, heavyTap }
