import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { Divider } from '@ui-kitten/components'
import { authenticatedUserSelector } from '@src/selectors/app'
import { DiscountOffer } from '@src/screens/Marketplace/components/checkout/DiscountOffer'
import { OrderSummary } from '@src/screens/Marketplace/components/checkout/OrderSummary'
import { calculateDiscount } from '@src/screens/Marketplace/utils/utils'
import { PaymentMethodCard } from '@src/screens/Marketplace/components/checkout/PaymentMethodCard'
import { BillingProduct, PromotionCode } from '@src/types'

interface ProductPaymentProps {
  product: BillingProduct
  estimatedTax: number
  fullPrice: number
  promotionCode: PromotionCode | null
}

export const ProductPayment = ({
  product,
  estimatedTax,
  fullPrice,
  promotionCode,
}: ProductPaymentProps) => {
  const navigation = useNavigation()
  const user = useSelector(authenticatedUserSelector)
  const { paymentMethod } = user

  const onEditPromotionCode = () => {
    navigation.navigate('DiscountCode', { product })
  }

  const onEditPaymentMethodPress = () => {
    navigation.navigate('CreatePaymentMethod')
  }

  const discount = calculateDiscount(fullPrice, promotionCode)
  const discountedPrice = fullPrice - discount

  return (
    <>
      <DiscountOffer code={promotionCode} onEditPromotionCode={onEditPromotionCode} />
      <Divider appearance="large" />
      <OrderSummary
        price={fullPrice}
        discount={discount}
        estimatedTax={discountedPrice > 0 ? estimatedTax : 0}
      />
      <Divider appearance="large" />
      <PaymentMethodCard
        paymentMethod={paymentMethod}
        onEditPaymentMethodPress={onEditPaymentMethodPress}
      />
    </>
  )
}
