import moment from 'moment'
import React from 'react'
import { DatesRange } from '@src/screens/Insights/components/ScoreChart/types'
import { TODAY, YESTERDAY } from '@src/config/momentFormat'
import { comparativeChartColors } from './common-styles'
import { Legend, LegendItemConfig } from './Legend/Legend'

enum PeriodType {
  'yesterday/today' = 'yesterday/today',
  'pastWeek/thisWeek' = 'pastWeek/thisWeek',
  'pastPeriod/thisPeriod' = 'pastPeriod/thisPeriod',
}

const periodTypeToLegendItemsMap: Record<PeriodType, LegendItemConfig[]> = {
  [PeriodType['yesterday/today']]: [
    { label: YESTERDAY, themeMarkerColor: comparativeChartColors.previousPeriod },
    { label: TODAY, themeMarkerColor: comparativeChartColors.currentPeriod },
  ],
  [PeriodType['pastWeek/thisWeek']]: [
    { label: 'Past week', themeMarkerColor: comparativeChartColors.previousPeriod },
    { label: 'This week', themeMarkerColor: comparativeChartColors.currentPeriod },
  ],
  [PeriodType['pastPeriod/thisPeriod']]: [
    { label: 'Past period', themeMarkerColor: comparativeChartColors.previousPeriod },
    { label: 'This period', themeMarkerColor: comparativeChartColors.currentPeriod },
  ],
}

const getPeriodType = (startDate: string, endDate: string) => {
  const isEndDateToday = moment(endDate).isSame(moment(), 'day')
  if (!isEndDateToday) {
    return PeriodType['pastPeriod/thisPeriod']
  }

  if (moment(endDate).isSame(startDate, 'day')) {
    return PeriodType['yesterday/today']
  }
  if (moment(endDate).diff(startDate, 'day') === 6) {
    return PeriodType['pastWeek/thisWeek']
  }

  return PeriodType['pastPeriod/thisPeriod']
}

interface CurrentPreviousPeriodLegendProps {
  datesRange: DatesRange
}

export const CurrentPreviousPeriodLegend = ({
  datesRange: { startDate, endDate },
}: CurrentPreviousPeriodLegendProps) => {
  const legendItems = periodTypeToLegendItemsMap[getPeriodType(startDate, endDate)]

  return <Legend legendItems={legendItems} />
}
