import {
  EmojiLevel0,
  EmojiLevel1,
  EmojiLevel2,
  EmojiLevel3,
  EmojiLevel4,
  EmojiLevel5,
} from '@src/assets/svgs/journal'
import Storage from '@src/utils/storage'
import { JournalEngagementPromptConfig } from './types'

export const EMOJI_SCALE = [
  EmojiLevel0,
  EmojiLevel1,
  EmojiLevel2,
  EmojiLevel3,
  EmojiLevel4,
  EmojiLevel5,
]

const REVERSE_RANGE = ['stress']

export const isReverseRange = (itemKey: string) => REVERSE_RANGE.indexOf(itemKey) >= 0

export const emojiByScore = (score: number) => EMOJI_SCALE[score]

const getJournalEngagementPromptConfig = () => {
  const value = Storage.get<Partial<JournalEngagementPromptConfig>>(
    Storage.JOURNAL_ENGAGEMENT_PROMPT_CONFIG_KEY,
  )
  // promptLastDisplayDate was used in previous prompt condition,
  // if present - means user already saw prompt at least once
  const previouslyShown = value?.promptLastDisplayDate
  return {
    canShowEngagementPrompt: !previouslyShown,
  }
}

export const updateJournalEngagementPromptConfig = (
  config: Partial<JournalEngagementPromptConfig>,
) => {
  const storedConfig = getJournalEngagementPromptConfig()
  const updatedConfig = { ...storedConfig, ...config }
  Storage.set(Storage.JOURNAL_ENGAGEMENT_PROMPT_CONFIG_KEY, updatedConfig)

  return updatedConfig
}
