import React from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Text } from '@components/base'

interface SubscriptionActionScreenProps {
  name: string
  title: string
  description: string
  children?: React.ReactNode
}

export const SubscriptionActionScreen = (props: SubscriptionActionScreenProps) => {
  const { name, title, description, children } = props

  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  return (
    <NavigationContainer title={name}>
      <ScrollView
        style={styles.container}
        contentContainerStyle={{ flexGrow: 1, paddingBottom: (insets?.bottom || 0) + 16 }}
      >
        <View style={styles.titleContainer}>
          <Text type="large" bold>
            {title}
          </Text>
          <Text type="regular" style={styles.subtitle}>
            {description}
          </Text>
        </View>
        {children}
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  titleContainer: {
    justifyContent: 'space-between',
    marginTop: 24,
    marginBottom: 32,
    marginHorizontal: 16,
  },
  subtitle: {
    marginTop: 8,
    color: 'theme.text.secondary',
  },
})
