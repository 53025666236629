import React from 'react'
import { useRoute } from '@react-navigation/native'
import { Linking } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'
import { BottomSheet, OutlinedIcon } from '@src/components'
import { AppRouteProp } from '@src/navigation/types'
import { useGoBack } from '@src/utils/navigation'

export const ConnectivityErrorModal = () => {
  const styles = useStyleSheet(themedStyles)
  const route = useRoute<AppRouteProp<'ConnectivityErrorModal'>>()
  const { error } = route.params
  const goBack = useGoBack()

  const onActionPress = () => {
    Linking.openSettings()
  }

  return (
    <BottomSheet
      primaryButton={{ text: 'Go to Settings', onPress: onActionPress }}
      secondaryButton={{ text: 'Done', onPress: goBack }}
      buttonDirection="column-reverse"
    >
      <OutlinedIcon iconName="warning" style={styles.icon} />
      <Text type="title-2" style={styles.primaryText}>
        Please check your {error} connection.
      </Text>
      <Text type="large" style={styles.secondaryText}>
        You must have bluetooth turned on and an internet connection to receive glucose data in the
        app.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  primaryText: {
    marginBottom: 32,
    textAlign: 'center',
  },
  secondaryText: {
    textAlign: 'center',
    paddingHorizontal: 32,
  },
  icon: {
    alignSelf: 'center',
  },
})
