import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { ScrollableAvoidKeyboard } from '@components'
import { AuthStackParamList } from '@navigation/types'
import { PasswordResetForm, PasswordResetTopNavigation } from '@screens/PasswordReset'

export const PasswordReset = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const { params } = useRoute<RouteProp<AuthStackParamList, 'PasswordReset'>>()
  const { email, passwordToken } = params || { email: '', passwordToken: '' }

  const onBackPress = () => navigation.navigate('SignIn')

  return (
    <ScrollableAvoidKeyboard keyboardShouldPersistTaps="handled">
      <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
        <PasswordResetTopNavigation title="Recover Password" onArrowBackClick={onBackPress} />
        <View style={styles.content}>
          <Text type="title-2" style={styles.headerTitle}>
            Let's pick a new password
          </Text>
          <Text type="regular" style={styles.headerText}>
            Enter a new password for your
            {email && (
              <Text type="regular" bold style={styles.userEmail}>
                {' ' + email + ' '}
              </Text>
            )}
            account.
          </Text>
          <PasswordResetForm passwordToken={passwordToken} />
        </View>
      </SafeAreaView>
    </ScrollableAvoidKeyboard>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 16,
  },
  headerTitle: {
    marginVertical: 16,
  },
  headerText: {
    marginBottom: 24,
  },
  userEmail: {
    color: 'theme.text.link',
  },
})
