import { gql } from '@apollo/client'

export const UPSERT_UI_STATE = gql`
  mutation upsertUiState($name: UIStateNames!, $value: Boolean!) {
    upsertUiState(name: $name, value: $value) {
      name
      value
    }
  }
`
