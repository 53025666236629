import React, { useCallback, useMemo, useState } from 'react'
import { LayoutChangeEvent, SectionList, SectionListData } from 'react-native'
import sectionListGetItemLayout from 'react-native-section-list-get-item-layout'
import { ListInjectedProps } from '@src/components/list/types'
import { PlaceholderView, CommonRefreshControl } from '@components'
import { getListItem, getListItemHeight } from '@src/components/list/ListItemFactory'
import { withDataProvider } from '@src/components/list/DataProviderList'
import { SectionHeader } from '@src/components/SectionHeader'
import {
  EXPANDED_LIST_HEADER_HEIGHT,
  LIST_SECTION_HEADER_HEIGHT,
  LIST_VERTICAL_PADDINGS,
} from '../constants'
import { EventListIndexPath, EventsItemType } from '../models/events.types'
import { HighlightsSection } from './Highlights/HighlightsSection'
import { ExpiringSensorPopup } from './Popups/ExpiringSensorPopup'
import { SensorStatusPopup } from './Popups/SensorStatusPopup'
import { MissingDexcomIntegrationPopup } from './Popups/MissingDexcomIntegrationPopup'
import { DexcomDataSyncPopup } from './Popups/DexcomDataSyncPopup'
import { DexcomSwitchPopup } from './Popups/DexcomSwitchPopup'

export type EventSectionItem = SectionListData<any, { title: string; index: number }>

export interface EventsListProps extends ListInjectedProps {
  items: EventSectionItem[]
  highlightedIndex?: EventListIndexPath
  onViewableItemsChanged: (info: any) => void
  onDragStart: () => void
  onDragEnd: () => void
  onMomentumScrollEnd: (event: any) => void
}

const keyExtractor = (item: { id: string; title: string }) => `${item.id}-${item.title}`

const EventsListComponent = (props: EventsListProps) => {
  const {
    listRef,
    items,
    highlightedIndex,
    initialNumToRender,
    refreshing,
    onEndReached,
    onRefresh,
    onViewableItemsChanged,
    onDragStart,
    onDragEnd,
    onMomentumScrollEnd,
  } = props

  const [bottomInset, setBottomInset] = useState(0)

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    // list height minus item height minus top and bottom vertical paddings
    setBottomInset(event.nativeEvent.layout.height - LIST_VERTICAL_PADDINGS)
  }, [])

  const getItemLayout = useMemo(
    () =>
      sectionListGetItemLayout({
        // we need to update row height once the list UI changes
        getItemHeight: (rowData) => getListItemHeight(rowData),
        getSectionHeaderHeight: () => LIST_SECTION_HEADER_HEIGHT,
        listHeaderHeight: EXPANDED_LIST_HEADER_HEIGHT,
      }),
    [],
  )

  const renderSectionHeader = useCallback(({ section }: { section: EventSectionItem }) => {
    return <SectionHeader title={section.title} />
  }, [])

  const insets = useMemo(() => ({ paddingBottom: bottomInset }), [bottomInset])

  const renderItem = ({
    item,
    index,
    section,
  }: {
    item: EventsItemType
    index: number
    section: { index: number }
  }) => {
    return getListItem(item, {
      highlighted:
        section.index === highlightedIndex?.sectionIndex && index === highlightedIndex?.itemIndex,
      canJumpToDate: false,
    })
  }

  const refreshControl = useMemo(
    () => (
      <CommonRefreshControl
        accessibilityLabel="refresh-control"
        refreshing={refreshing}
        onRefresh={onRefresh}
      />
    ),
    [onRefresh, refreshing],
  )

  return (
    <SectionList
      ref={listRef}
      style={{ flex: 1 }}
      contentContainerStyle={insets}
      sections={items}
      keyExtractor={keyExtractor}
      initialNumToRender={initialNumToRender}
      stickySectionHeadersEnabled={false}
      renderItem={renderItem}
      renderSectionHeader={renderSectionHeader}
      refreshControl={refreshControl}
      ListHeaderComponent={
        <React.Fragment>
          <MissingDexcomIntegrationPopup />
          <DexcomDataSyncPopup />
          <DexcomSwitchPopup />
          <SensorStatusPopup />
          <ExpiringSensorPopup />
          <HighlightsSection />
        </React.Fragment>
      }
      ListEmptyComponent={<PlaceholderView type="events" />}
      showsVerticalScrollIndicator={false}
      onMomentumScrollEnd={onMomentumScrollEnd}
      onEndReached={onEndReached}
      onScrollBeginDrag={onDragStart}
      onScrollEndDrag={onDragEnd}
      onViewableItemsChanged={onViewableItemsChanged}
      onLayout={onLayout}
      getItemLayout={getItemLayout as any}
      extraData={highlightedIndex}
      viewabilityConfig={{
        itemVisiblePercentThreshold: 95,
        waitForInteraction: true,
      }}
    />
  )
}

export const EventsList = withDataProvider(EventsListComponent)
