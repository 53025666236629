import React, { useMemo } from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Choice, ChoiceAccessory } from '@components/Choice'
import { Text } from '@components/base'
import {
  LessonItemContentMultipleChoice,
  LessonItemContentMultipleChoiceAnswerOption,
} from '../../models/courses.types'

interface MultipleChoiceExplanationProps {
  question: LessonItemContentMultipleChoice
  selectedOption?: LessonItemContentMultipleChoiceAnswerOption
}

export const MultipleChoiceExplanation = ({
  question,
  selectedOption,
}: MultipleChoiceExplanationProps) => {
  const styles = useStyleSheet(themedStyles)
  const correctOption = useMemo(() => question.answers.find((o) => o.correct), [question])

  if (!selectedOption) {
    return null
  }

  return (
    <>
      <Choice
        content={selectedOption.body}
        accessory={selectedOption?.correct ? ChoiceAccessory.Correct : ChoiceAccessory.Incorrect}
      />
      <View
        style={[
          styles.explanation,
          selectedOption?.correct && styles.explanationCorrect,
          !selectedOption?.correct && styles.explanationIncorrect,
        ]}
      >
        <Text type="regular" style={styles.text}>
          <Text type="regular" bold style={styles.text}>
            {selectedOption?.correct ? 'Correct: ' : 'Incorrect: '}
          </Text>
          {selectedOption?.correct ? question.correctExplanation : question.incorrectExplanation}
        </Text>
      </View>
      {!selectedOption?.correct && (
        <View style={styles.correction}>
          <Text type="tiny" bold style={styles.correctionTitle}>
            CORRECT ANSWER
          </Text>
          <Text type="regular" lineSpacing="none">
            {correctOption?.body}
          </Text>
        </View>
      )}
    </>
  )
}

const themedStyles = StyleService.create({
  text: {
    color: 'theme.solid.black',
  },
  explanation: {
    padding: 16,
    marginBottom: 16,
    borderRadius: 16,
    borderWidth: 1,
  },
  explanationCorrect: {
    backgroundColor: 'theme.success.lightest',
    borderColor: 'theme.success.lighter',
  },
  explanationIncorrect: {
    backgroundColor: 'theme.error.lightest',
    borderColor: 'theme.error.lighter',
  },
  correction: {
    padding: 16,
    borderRadius: 16,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
  },
  correctionTitle: {
    color: 'theme.text.tertiary',
    marginBottom: 4,
  },
})
