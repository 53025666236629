import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useMenuHandling } from '@components/list/MenuHandler'
import { Menu, MenuInfoItem } from '@components/list/common/Menu'
import { BaseIngredient } from '@screens/Ingredients/types'

interface IngredientOptionsProps {
  ingredient: BaseIngredient
}

export const IngredientOptions = ({ ingredient }: IngredientOptionsProps) => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const { defaultMenuItems } = useMenuHandling(ingredient)

  const onIngredientEdit = () => {
    navigation.navigate('CustomIngredient', {
      ingredientId: ingredient.id,
    })
  }

  const menuItems: MenuInfoItem[] = [
    { title: 'Edit', onPress: onIngredientEdit, isEnabled: true },
    ...defaultMenuItems,
  ]

  return <Menu menuItems={menuItems} menuButtonStyle={styles.menuButton} />
}

const themedStyles = StyleService.create({
  menuButton: {
    marginLeft: 8,
  },
})
