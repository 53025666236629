import React, { useMemo } from 'react'
import { useWindowDimensions, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { CommonChart } from '@src/components'
import { CurrentPreviousPeriodLegend } from '@src/components/charts/CurrentPreviousPeriodLegend'
import NoData from '@src/components/charts/NoData/NoData'
import { Device } from '@src/config'
import { useThemeColors } from '@src/hooks/useThemeColors'
import { Text } from '@components/base'
import { DatesRange } from './types'

interface BaseChartProps {
  renderFunction: (style: any) => string | null
  title: string
  height?: number
  onLoad?: () => void
  datesRange: DatesRange
  showLegend?: boolean
}

const chartThemedColors = {
  currentPeriodDataColor: 'theme.barChart.primary',
  previousPeriodDataColor: 'theme.barChart.secondary',
  markingLinesColor: 'theme.surface.base',
  lineColor: 'theme.surface.base',
  axisLabelsColor: 'theme.text.secondary',
  tooltipBackgroundColor: 'theme.tooltip.background',
  tooltipTextColor: 'theme.tooltip.textPrimary',
}

export const BaseChart = ({
  renderFunction,
  title,
  height = 200,
  onLoad,
  datesRange,
  showLegend = true,
}: BaseChartProps) => {
  const styles = useStyleSheet(themedStyle)
  const chartColors = useThemeColors(chartThemedColors)
  const dimensions = useWindowDimensions()

  const hasSmallScreen = Device.hasSmallScreen(dimensions)

  const script = useMemo(() => {
    return renderFunction(chartColors)
  }, [renderFunction, chartColors])

  return (
    <View style={[styles.container, { height }]}>
      <View style={styles.topContainer}>
        {title ? (
          <Text type={hasSmallScreen ? 'small' : 'large'} bold>
            {title}
          </Text>
        ) : (
          <View />
        )}
        {showLegend && <CurrentPreviousPeriodLegend datesRange={datesRange} />}
      </View>

      {script ? <CommonChart chartScript={script} onLoad={onLoad} /> : <NoData />}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    marginBottom: 20,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginBottom: -16,
  },
})
