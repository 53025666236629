import React from 'react'
import { IconButton, IconProps } from '@components/base'

interface CalendarIconProps {
  onPress: () => void
  iconProps?: Omit<IconProps, 'name'>
}

export const CalendarIcon = (props: CalendarIconProps) => {
  const { onPress, iconProps } = props

  return (
    <IconButton
      accessibilityLabel="Select Date Range"
      iconName="calendar-blank"
      size="m"
      type="outline"
      onPress={onPress}
      iconProps={iconProps}
    />
  )
}
