import { useSelector } from 'react-redux'
import { startCase, camelCase, mapKeys, round } from 'lodash'
import { eventsNutritionSelector } from '@src/selectors/events'
import { unitsAndDailyValuesSelector } from '@src/selectors/app'

const TITLE_OVERRIDES: Record<string, string> = {
  vitaminAIu: 'Vitamin A',
}

interface MicroData {
  title: string
  value: number
  units: string
  maxValue: number
  percent: number
}

export const useMicroData = () => {
  const rawNutrition = useSelector(eventsNutritionSelector)

  const { units, dailyValues } = useSelector(unitsAndDailyValuesSelector)

  const nutrition = mapKeys(rawNutrition, (_, key) => camelCase(key))

  return Object.keys(units).reduce((acc, nutrientKey) => {
    const value = round(nutrition[nutrientKey], 2) || 0 // Default value of 0 when no nutrition data exists
    const maxValue = dailyValues[nutrientKey] || value
    const title = TITLE_OVERRIDES[nutrientKey] || startCase(nutrientKey)

    return {
      ...acc,
      [nutrientKey]: {
        title,
        value,
        units: units[nutrientKey],
        maxValue,
        percent: value / maxValue,
      },
    }
  }, {} as Record<string, MicroData>)
}
