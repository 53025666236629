import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { MenuItemProps, Divider } from '@ui-kitten/components'
import { isEmpty } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { CardOverflow } from '@components'
import { Icon, Pill, Text } from '@components/base'

export type ReorderableListItemSize = 'normal' | 'large'

export interface ReorderableListItemProps<T> {
  menuItems?: MenuItemProps[]
  item: T
  drag: () => void
  handleItemPress?: () => void
  isActive: boolean
  size?: ReorderableListItemSize
}

interface Item {
  id?: string
  title?: string
  description?: string
  occurredAt?: string
  tag?: string
  __typename?: string
}

export const ReorderableListItem = <T extends Item>({
  menuItems,
  item,
  drag,
  handleItemPress,
  isActive,
  size = 'normal',
}: ReorderableListItemProps<T>) => {
  const styles = useStyleSheet(themedStyle)

  const { title, description } = item

  return (
    <>
      <TouchableOpacity
        style={[styles.row, size === 'large' && styles.largeRow, isActive && styles.activeRow]}
        activeOpacity={0.7}
        onPress={handleItemPress}
        onLongPress={drag}
        accessibilityLabel={title}
      >
        <View style={styles.title}>
          <Icon name="dots-six-vertical" />
          <View style={styles.content}>
            <View style={styles.titleContainer}>
              <Text
                type="large"
                bold
                lineSpacing="tight"
                style={styles.titleLabel}
                numberOfLines={1}
              >
                {title}
              </Text>
              {item.tag && <Pill text={item.tag} status="info" />}
            </View>
            {!isEmpty(description) && (
              <Text type="tiny" style={styles.descriptionLabel} numberOfLines={1}>
                {description}
              </Text>
            )}
          </View>
        </View>
        {item.id && menuItems?.length && (
          <CardOverflow item={item} menuItems={menuItems} placement="bottom" />
        )}
      </TouchableOpacity>
      <Divider />
    </>
  )
}

const themedStyle = StyleService.create({
  content: {
    paddingLeft: 12,
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: 56,
    paddingLeft: 16,
    paddingRight: 20,
    backgroundColor: 'theme.background',
  },
  largeRow: {
    height: 88,
  },
  activeRow: {
    backgroundColor: 'theme.surface.base2',
  },
  title: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  titleLabel: {
    flexShrink: 1,
    marginRight: 12,
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  descriptionLabel: {
    color: 'theme.text.secondary',
  },
})
