import { gql } from '@apollo/client'

const CHARTS_DATA = gql`
  query chartsData($filter: ChartFilter!) {
    chartsData(filter: $filter) {
      values {
        section
        key
        data {
          ... on TimePair {
            x
            y
            interpolated
          }
          ... on NumericPair {
            x
            y
          }
          ... on StringPair {
            x
            y
          }
          ... on RangePair {
            x {
              min
              max
            }
            y
          }
        }
      }
    }
  }
`

export default CHARTS_DATA
