import React, { useMemo } from 'react'
import { ScrollView, View } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Goal, GoalMetric, Statistic, UserGoalMetricKind } from '@src/types'
import { Text } from '@src/components/base'
import { statsSelector } from '../models/insights.selectors'
import { GoalMetricCard } from './GoalMetricCard'
import { GoalInfo } from './GoalInfo'

interface GoalViewProps {
  goal: Goal
  metrics: GoalMetric[]
}

export const GoalView = (props: GoalViewProps) => {
  const { goal, metrics } = props
  const styles = useStyleSheet(themedStyles)

  const stats = useSelector(statsSelector)

  const statByMetricKind = useMemo(() => {
    return metrics.reduce((acc, metric) => {
      acc[metric.kind] = stats.find(
        (stat) => stat.meta.section === metric.section && stat.meta.key === metric.statKey,
      )
      return acc
    }, {} as { [key in UserGoalMetricKind]: Statistic | undefined })
  }, [metrics, stats])

  const isSingleMetric = metrics.length === 1

  return (
    <View style={styles.container}>
      <View style={styles.goalContainer}>
        <View style={styles.goalText}>
          <Text type="regular" bold style={styles.label}>
            My Goal
          </Text>
          <Text type="large" bold>
            {goal.description || goal.title}
          </Text>
        </View>
        <View>
          <GoalInfo />
        </View>
      </View>
      {metrics.length > 0 && (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={styles.metricsContainer}
          bounces={false}
        >
          {metrics.map((metric) => {
            const stat = statByMetricKind[metric.kind]

            if (!stat) {
              return null
            }

            return (
              <GoalMetricCard
                key={metric.kind}
                stat={stat}
                metric={metric}
                isSingleMetric={isSingleMetric}
              />
            )
          })}
        </ScrollView>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.surface.base2',
  },
  goalContainer: {
    margin: 16,
    flexDirection: 'row',
  },
  goalText: {
    flex: 1,
  },
  label: {
    color: 'theme.text.secondary',
  },
  metricsContainer: {
    flexDirection: 'row',
    gap: 8,
    paddingHorizontal: 16,
    marginBottom: 16,
    flexGrow: 1,
  },
})
