import { gql } from '@apollo/client'

export const INIT_LIBRE_LINKUP_CONNECTION = gql`
  mutation initLinkupConnection($libreEmail: String!, $librePassword: String!) {
    initLinkupConnection(libreEmail: $libreEmail, librePassword: $librePassword) {
      active
      status
      lastSyncedAt
    }
  }
`
