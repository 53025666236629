import React from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Section } from '@components'
import { Text } from '@components/base'

interface CustomSettingProps {
  title: string
  onPress: () => void
  text: string
}

const CustomSetting = (props: CustomSettingProps) => {
  const { title, onPress, text } = props
  const styles = useStyleSheet(themedStyles)

  return (
    <Section title={title}>
      <TouchableWithoutFeedback accessibilityLabel={text} onPress={onPress}>
        <View style={styles.textContainer}>
          <Text type="tiny" bold>
            {text}
          </Text>
        </View>
      </TouchableWithoutFeedback>
    </Section>
  )
}

const themedStyles = StyleService.create({
  textContainer: {
    width: 180,
    minHeight: 40,
    justifyContent: 'center',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 8,
    backgroundColor: 'theme.surface.base2',
  },
})

export default CustomSetting
