import { NavigationContainerRef, NavigationProp } from '@react-navigation/native'
import React, { useContext, useEffect } from 'react'
import { createNavigationContainerRef } from '@react-navigation/native'
import { RootStackParamList } from '@src/navigation/types'

export const navigationRef = createNavigationContainerRef<RootStackParamList>()

export const navigate = (name: string, params?: any) => {
  if (!navigationRef.isReady()) {
    return
  }

  navigationRef.navigate(name as any, params)
}

export const getCurrentRouteName = () => {
  if (!navigationRef.isReady()) {
    return undefined
  }

  const navigationState = navigationRef.current?.getRootState()

  return navigationState?.routes[navigationState.index].name
}

export const NavigationContext = React.createContext<{
  route?: string
  params?: any
  reset?: () => void
  navigation?: NavigationProp<ReactNavigation.RootParamList>
}>({})

export const useNavigationContext = (navigation: NavigationContainerRef<any> | null) => {
  const navigationContext = useContext(NavigationContext)
  const { route, params, reset } = navigationContext
  useEffect(() => {
    if (route && navigation) {
      navigation.navigate(route, params)
      reset?.()
    }
  }, [navigation, params, reset, route])
}
