import { gql } from '@apollo/client'

const DELETE_HEALTH_METRIC = gql`
  mutation deleteHealthMetric($id: ID!) {
    deleteHealthMetric(id: $id) {
      success
    }
  }
`

export default DELETE_HEALTH_METRIC
