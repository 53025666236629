import React from 'react'
import { capitalize } from 'lodash'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Text } from '@components/base'
import { AppStackParamList } from '@src/navigation/types'
import { OptionsSlider } from '@src/screens/Settings/components/OptionsSlider'
import { useGoBack } from '@src/utils/navigation'
import { SettingsModalSharedContainer as SettingsModalShared } from '../SettingsModalShared'
import { useThresholdSettings } from './useThresholdSettings'

export const ThresholdsModal = () => {
  const goBack = useGoBack()
  const styles = useStyleSheet(themedStyles)

  const {
    params: { type, source },
  } = useRoute<RouteProp<AppStackParamList, 'ThresholdSettings'>>()

  const {
    allOptions,
    lowOptions,
    highOptions,
    selectedLowOption,
    selectedHighOption,
    onThresholdChange,
    saveThresholds,
  } = useThresholdSettings(type, source)

  const onSaveButtonPress = async () => {
    try {
      await saveThresholds()
      goBack()
    } catch {
      // Ignore errors for now
    }
  }

  return (
    <SettingsModalShared
      onSaveButtonPress={onSaveButtonPress}
      onCancelButtonPress={goBack}
      title={`${capitalize(type)} Target Range`}
    >
      <Text type="regular" style={styles.description}>
        Please note that target range may vary depending on your health status.
      </Text>
      <Text type="regular" style={styles.description}>
        If you want to know what your target range should be, please reach out to your dietitian.
      </Text>
      <OptionsSlider
        selectedOptions={[selectedLowOption, selectedHighOption]}
        limits={[lowOptions[lowOptions.length - 1].value, highOptions[0].value]}
        options={allOptions}
        onChange={onThresholdChange}
        style={styles.slider}
      />
    </SettingsModalShared>
  )
}

const themedStyles = StyleService.create({
  slider: {
    marginBottom: 20,
  },
  description: {
    marginBottom: 16,
  },
  alert: {
    marginBottom: 20,
  },
})
