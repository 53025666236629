import { MutableRefObject, useEffect } from 'react'
import { Subscription, addScreenshotListener, getPermissionsAsync } from 'expo-screen-capture'
import { Analytics, CustomEventTypes } from '@src/config'
import { NavigationRouteConfig } from '@src/utils/analytics'

export const useScreenCaptureTracking = (
  routeNameRef: MutableRefObject<NavigationRouteConfig['name'] | null>,
) => {
  useEffect(() => {
    let subscription: Subscription

    getPermissionsAsync().then((permissions) => {
      if (permissions.status !== 'granted') {
        Analytics.track(CustomEventTypes.ScreenshotPermissionMissing)
        return
      }

      subscription = addScreenshotListener(() => {
        Analytics.track(CustomEventTypes.ScreenshotTaken, { screen_name: routeNameRef.current })
      })
    })

    return () => {
      subscription?.remove()
    }
  }, [routeNameRef])
}
