import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

interface RangeStateConfig {
  minValue: number
  maxValue: number
  color: string
  status: string
}

export interface ArcIndicatorStateConfigs {
  rangeConfigs: RangeStateConfig[]
  noDataAvailableConfig: Pick<RangeStateConfig, 'color' | 'status'>
}

export interface ArcIndicatorProps {
  title: string
  value?: number
  stateConfig: Pick<RangeStateConfig, 'color' | 'status'> | undefined
}

export const ArcIndicatorInfoOld = ({ title, value, stateConfig }: ArcIndicatorProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.infoContainer}>
      <Text type="title-3" style={styles.value}>
        {value ?? 'N/A'}
      </Text>
      <Text type="small" bold style={styles.title}>
        {title}
      </Text>
      <Text type="large" bold style={[styles.status, { color: stateConfig?.color }]}>
        {stateConfig?.status.toUpperCase()}
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  title: {
    marginBottom: 22,
    textAlign: 'center',
  },
  status: {
    textAlign: 'center',
    marginBottom: 5,
    maxWidth: 120,
  },
  value: {
    textAlign: 'center',
  },
  infoContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})
