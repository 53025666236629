import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'
import { MarketplaceStoreState } from '@src/screens/Marketplace/types/types'

export const marketplaceSelector = (state: RootStoreState): MarketplaceStoreState =>
  state.marketplace

export const promoCodeSelector = createSelector(
  marketplaceSelector,
  (marketplace: MarketplaceStoreState) => marketplace.promotionCode,
)

export const subscriptionSchedulesSelector = createSelector(
  marketplaceSelector,
  (marketplace: MarketplaceStoreState) => marketplace.subscriptionSchedules,
)
