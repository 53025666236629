import { CommonActions, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RootStackParamList } from '@src/navigation/types'
import { BillingProduct, InsuranceBerryStreetAppointmentType, Product } from '@src/types.ts'
import { ProductSelection } from '@screens/Marketplace/types/types.ts'
import { usePurchaseOneTimePayment } from '@screens/Marketplace/utils/hooks.ts'
import { useDispatchAsync, useSnack } from '@src/utils'
import { ErrorMessages } from '@src/config'

const MAX_RETRY_COUNT = 10

export const useChargeFee = (
  videoCallId: string,
  appointmentType: InsuranceBerryStreetAppointmentType,
  feeProduct: Product | undefined,
) => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const purchaseOneTimePayment = usePurchaseOneTimePayment()
  const dispatchAsync = useDispatchAsync()
  const showSnack = useSnack()

  const onSuccess = async () => {
    if (feeProduct?.key === BillingProduct.VideoCallLateCancellationFee) {
      try {
        await dispatchAsync({
          type: 'nutritionistHub/cancelVideoCall',
          payload: {
            id: videoCallId,
          },
        })

        navigation.dispatch((state) => {
          // Remove current route
          const routes = state.routes.slice(0, -1)

          // navigate to NutritionistHub with FeePaymentSuccessfulModal
          const newRoutes = [
            ...routes,
            {
              name: 'Drawer',
              params: { screen: 'Dashboard', params: { screen: 'NutritionistHub' } },
            },
            { name: 'FeePaymentSuccessfulModal' },
          ]

          return CommonActions.reset({
            index: newRoutes.length - 1,
            routes: newRoutes,
          } as any)
        })
      } catch (error: any) {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      }
    } else if (feeProduct?.key === BillingProduct.VideoCallLateRescheduleFee) {
      navigation.replace('CallScheduling', {
        appointmentType,
        appointmentId: videoCallId,
        allowBackNavigation: true,
      })
    }
  }

  let retryCount = 0

  // We can call this recursively as backend will not create new invoices if there is recent same invoice,
  // but will return invoice from DB
  const chargeFee = async () => {
    const selection = { product: feeProduct, quantity: 1 } as ProductSelection

    try {
      const metadata = [{ key: 'appointment_id', value: videoCallId }]
      const invoice = await purchaseOneTimePayment(selection, null, metadata)

      if (invoice?.status === 'paid') {
        await onSuccess()
        return
      }

      retryCount += 1
      if (retryCount >= MAX_RETRY_COUNT) {
        retryCount = 0
        navigation.replace('PaymentFailed', { videoCallId, feeProduct, appointmentType })

        return
      }

      setTimeout(() => {
        chargeFee()
      }, 3000)
    } catch (error: any) {
      showSnack(error?.message || ErrorMessages.ServerError, null, 'error')
    }
  }

  return chargeFee
}
