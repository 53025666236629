import React, { useRef } from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { SUPPORT_EMAIL } from '@src/config/constants'
import { contactSupport, openUrl, Zendesk } from '@src/utils'
import { Actions } from '@src/screens/Marketplace/components/ProgramDetails/Actions'
import { userSelector } from '@src/selectors/app'
import { useGoBack } from '@src/utils/navigation'

export const RefillConsent = () => {
  const styles = useStyleSheet(themedStyle)
  const user = useSelector(userSelector)
  const goBack = useGoBack()
  const dispatch = useDispatch()
  const loadingRef = useRef(false)

  const onContactSupportPress = () => {
    openUrl(Zendesk.baseUrl(user?.email))
  }

  const onAccept = () => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    dispatch({
      type: 'refillConsents/create',
      success: () => {
        goBack()
      },
      complete: () => {
        loadingRef.current = false
      },
    })
  }

  return (
    <ScrollView contentContainerStyle={styles.contentContainer} bounces={false}>
      <View style={styles.modal}>
        <SafeAreaView edges={['bottom']} style={styles.content}>
          <Icon name="prescription" weight="fill" style={styles.icon} />
          <Text type="title-2" style={styles.title}>
            Consent For Auto-Refill
          </Text>
          <Text type="regular" style={styles.description}>
            I understand that I am consenting to have my CGM prescription automatically refilled by
            the pharmacy by opting in for the ‘auto-refill’ program and that I can opt out of an
            automatic refill at any time by reaching out to the customer support team at
          </Text>
          <TouchableOpacity accessibilityLabel="contactSupport" onPress={contactSupport}>
            <Text type="regular" style={styles.link}>
              {SUPPORT_EMAIL}.
            </Text>
          </TouchableOpacity>
          <Actions
            style={styles.actions}
            primaryButtonText="Accept and Continue"
            onPrimaryButtonPress={onAccept}
            secondaryButtonText="Contact Support"
            onSecondaryButtonPress={onContactSupportPress}
          />
        </SafeAreaView>
      </View>
    </ScrollView>
  )
}

const themedStyle = StyleService.create({
  contentContainer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  modal: {
    width: '100%',
    borderRadius: 24,
    backgroundColor: 'theme.background',
  },
  content: {
    paddingHorizontal: 16,
    paddingTop: 24,
    paddingBottom: 16,
  },
  title: {
    marginVertical: 16,
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
  },
  link: {
    color: 'theme.text.link',
    textAlign: 'center',
  },
  icon: {
    alignSelf: 'center',
    width: 40,
    height: 40,
  },
  actions: {
    marginTop: 24,
    paddingHorizontal: 0,
  },
})
