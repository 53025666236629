import { useState } from 'react'
import { GoogleSignin, statusCodes } from '@react-native-google-signin/google-signin'
import { AppleAuth } from '@services'
import { useDispatchAsync } from '@src/utils'

class ThirdPartyAuthError extends Error {}

export const useSignIn = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatchAsync()

  const thirdPartySignIn = async (
    provider: string,
    providerToken?: string | null,
    providerUserId?: string,
  ) => {
    try {
      await dispatch({
        payload: {
          provider,
          providerToken,
          providerUserId,
        },
        type: 'app/thirdPartyLogin',
      })
    } catch (error: any) {
      onThirdPartySignInError(error)
    }
  }

  const thirdPartySignUp = async (
    provider: string,
    firstName: string,
    lastName: string,
    providerToken?: string | null,
    providerUserId?: string,
  ) => {
    try {
      await dispatch({
        payload: {
          provider,
          providerToken,
          providerUserId,
          firstName,
          lastName,
        },
        type: 'users/createThirdParty',
      })
    } catch (error: any) {
      onThirdPartySignInError(error)
    }
  }

  const signUpWithGoogle = async () => {
    if (loading) {
      return
    }
    setLoading(true)

    try {
      await GoogleSignin.hasPlayServices()
      const userInfo = await GoogleSignin.signIn()

      if (!userInfo.user?.givenName || !userInfo.user?.familyName) {
        throw new ThirdPartyAuthError('A name is required to create an account')
      }

      await thirdPartySignUp(
        'google',
        userInfo.user.givenName,
        userInfo.user.familyName,
        userInfo.idToken,
      )
    } catch (error: any) {
      onGoogleSignInError(error)
    } finally {
      setLoading(false)
    }
  }

  const signInWithGoogle = async () => {
    if (loading) {
      return
    }
    setLoading(true)

    try {
      await GoogleSignin.hasPlayServices()
      const userInfo = await GoogleSignin.signIn()
      await thirdPartySignIn('google', userInfo.idToken)
    } catch (error: any) {
      onGoogleSignInError(error)
    } finally {
      setLoading(false)
    }
  }

  const signUpWithApple = async () => {
    if (loading) {
      return
    }
    setLoading(true)

    try {
      const response = await AppleAuth.signIn()
      const fullName = AppleAuth.getFullName()

      if (fullName?.givenName && fullName.familyName) {
        await thirdPartySignUp(
          'apple',
          fullName.givenName,
          fullName.familyName,
          response.identityToken,
          response.user,
        )
      } else {
        // Apple only passes name as part of the first request; attempt a regular sign in if it is not present
        // ref: https://developer.apple.com/forums/thread/121496
        //
        await thirdPartySignIn('apple', response.identityToken, response.user)
      }
    } catch (error: any) {
      onAppleSignInError(error)
    } finally {
      setLoading(false)
    }
  }

  const signInWithApple = async () => {
    if (loading) {
      return
    }
    setLoading(true)

    try {
      const response = await AppleAuth.signIn()
      await thirdPartySignIn('apple', response.identityToken, response.user)
    } catch (error: any) {
      onAppleSignInError(error)
    } finally {
      setLoading(false)
    }
  }

  const signInWithEmail = async (email: string, password: string, impersonate?: string) => {
    if (loading) {
      return
    }
    setLoading(true)

    try {
      await dispatch({
        payload: { email, password, impersonate },
        type: 'app/login',
      })
    } catch (error: any) {
      throw new Error('Incorrect email/password!')
    } finally {
      setLoading(false)
    }
  }

  return {
    signInWithApple,
    signUpWithApple,
    signInWithGoogle,
    signUpWithGoogle,
    signInWithEmail,
    loading,
  }
}

const onThirdPartySignInError = (error: any) => {
  if (error?.networkError?.statusCode === 401) {
    throw new ThirdPartyAuthError("We couldn't find an account with this email address")
  } else {
    throw new ThirdPartyAuthError(
      'Network request failed. Please check your connection and try again',
    )
  }
}

const onAppleSignInError = (error: any) => {
  if (error instanceof ThirdPartyAuthError) {
    throw error
  }

  if (error?.code === AppleAuth.Error.AUTHORIZATION_FAILED) {
    throw new Error('Apple authorization failed')
  } else if (error?.code !== AppleAuth.Error.CANCELED) {
    throw new Error(`Failed to connect to Apple Sign In services (${error.code})`)
  }
}

const onGoogleSignInError = (error: any) => {
  if (error instanceof ThirdPartyAuthError) {
    throw error
  }

  if (error.code === statusCodes.PLAY_SERVICES_NOT_AVAILABLE) {
    throw new Error('Google Play services are unavailable')
  } else {
    throw new Error(`Failed to connect to Google Sign In services (${error.code})`)
  }
}
