import { gql } from '@apollo/client'

export const FETCH_USER_FILES = gql`
  query allFiles(
    $page: Int
    $perPage: Int
    $sorts: [FileSort!]
    $dynamicFilters: [FileDynamicFilterItem!]
  ) {
    allFiles(page: $page, perPage: $perPage, sorts: $sorts, dynamicFilters: $dynamicFilters) {
      files {
        id
        tag
        fileType
        fileName
        downloadFileUrl
      }
    }
  }
`
