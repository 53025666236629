import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Haptic } from '@utils'
import { Bugsnag } from '@config'
import { eventsChartsSelector } from '@src/selectors/events'
import { loadingEffectsSelector } from '@src/selectors/loading'
import {
  GroupedEvent,
  EventsItemType,
  EventListIndexPath,
  EventListViewableItem,
  ViewableItem,
} from '../models/events.types'

const getViewableItem = (viewableItems: EventListViewableItem[]): ViewableItem => {
  // The first item with a '__typename' should be the first event list item
  // (viewableItem.item should be empty for the section list header)
  const viewableItem = viewableItems.find((item) => item.item?.__typename)

  if (!viewableItem) {
    return {}
  }

  return {
    item: viewableItem.item,
    indexPath: { sectionIndex: viewableItem.section.index, itemIndex: viewableItem.index },
  }
}

export let hoverTime: string | undefined = undefined

export const useChartInteractions = (groupedEvents: GroupedEvent[]) => {
  const charts = useSelector(eventsChartsSelector)
  const isFetching = useSelector(loadingEffectsSelector)['events/fetch']

  const [highlightedIndex, setHighlightedIndex] = useState<EventListIndexPath | undefined>(
    undefined,
  )

  const isScrolling = useRef(false)
  const isScrollUserInitiated = useRef(false)
  const chartLoaded = useRef(false)
  const selectedItemOnChart = useRef<EventsItemType>()
  const lastChartCommandAt = useRef(moment())
  const highlightedIndexRef = useRef<EventListIndexPath | undefined>(undefined)

  const listRef = useRef<any>()
  const primaryChartRef = useRef<any>()
  const secondaryChartRef = useRef<any>()

  const scrollToIndex = (indexPath: EventListIndexPath, animated: boolean, message: string) => {
    try {
      listRef.current?.scrollToLocation?.({
        animated,
        viewPosition: 0,
        viewOffset: 0,
        ...indexPath,
      })
    } catch (err: any) {
      console.error(`Events:${message}: `, err)
      Bugsnag.notify(err)
    }
  }

  const selectEventOnChart = (item: EventsItemType) => {
    const glucoseValuesPresent =
      charts.primary.values.filter((value) => value.y && value.tag === 'Glucose').length !== 0

    const ketonesValuesPresent =
      charts.secondary.values.filter((value) => value.y && value.tag === 'Ketones').length !== 0

    if (!glucoseValuesPresent && !ketonesValuesPresent) {
      return
    }

    const command = `chart && chart.showTooltip(chart.getPosition(
        {x: "${item.occurredAt}", y: 114, tag: "Glucose" }
      )); true;`
    lastChartCommandAt.current = moment()

    if (!chartLoaded.current) {
      return
    }

    if (glucoseValuesPresent) {
      primaryChartRef.current?.repaint(command)
    }

    if (ketonesValuesPresent) {
      secondaryChartRef.current?.repaint(command)
    }
  }

  const onChartLoadEnd = () => {
    chartLoaded.current = true
    hoverTime = undefined // reset hover time on date change
  }

  const onChartHover = (payload: any) => {
    if (!payload.items[0] || moment().diff(lastChartCommandAt.current) < 1000) {
      hoverTime = undefined
      return
    }

    hoverTime = payload.items[0].occurredAt

    const hoverRangeStart = moment(hoverTime).subtract(30, 'm')
    const hoverRangeEnd = moment(hoverTime).add(30, 'm')

    let hoverDiff: number
    let indexPath: EventListIndexPath | undefined

    groupedEvents.forEach((section, sectionIndex) => {
      section.data.forEach((event, itemIndex) => {
        if (moment(event.occurredAt).isBetween(hoverRangeStart, hoverRangeEnd)) {
          const diff = Math.abs(moment(hoverTime).diff(moment(event.occurredAt)))
          if (!hoverDiff || diff < hoverDiff) {
            hoverDiff = diff
            indexPath = {
              sectionIndex,
              itemIndex,
            }
          }
        }
      })
    })

    if (indexPath) {
      highlightedIndexRef.current = indexPath
      isScrolling.current = true
      isScrollUserInitiated.current = false
      scrollToIndex(indexPath, true, 'onChartHover')
    } else {
      highlightedIndexRef.current = undefined
      setHighlightedIndex(undefined)
    }
  }

  const onEventListViewableItemsChanged = (info: { viewableItems: EventListViewableItem[] }) => {
    if (isScrolling.current || !isScrollUserInitiated.current || isFetching) {
      return
    }

    const { viewableItems } = info
    const { item, indexPath } = getViewableItem(viewableItems)

    if (indexPath && item && item !== selectedItemOnChart.current) {
      selectEventOnChart(item)

      // to prevent double vibration for same item
      selectedItemOnChart.current = item
      highlightedIndexRef.current = indexPath
      setHighlightedIndex(highlightedIndexRef.current)

      Haptic.lightTap()
    }
  }

  const onEventListDragStart = () => {
    isScrollUserInitiated.current = true
  }

  const onEventListMomentumScrollEnd = () => {
    isScrolling.current = false

    setHighlightedIndex(highlightedIndexRef.current)
  }

  return {
    listRef,
    primaryChartRef,
    secondaryChartRef,
    highlightedIndex,
    onChartLoadEnd,
    onChartHover,
    onEventListViewableItemsChanged,
    onEventListDragStart,
    onEventListMomentumScrollEnd,
  }
}
