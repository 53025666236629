import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useGlucosePrecision } from '@src/screens/Settings/utils/hooks'
import { insightsCalendarSelector } from '@src/screens/Insights/models/insights.selectors'
import { ChartQueryType, DetailedFetchKeyMap } from '@src/screens/Insights/helper'
import { Period } from '@src/types'
import { useChartData } from './useChartData'
import { ByHourChart, ByHourChartProps } from './ByHourChart'

export interface ByHourChartDataItem {
  x: Hour24
  y: number | null
}

interface ByHourChartManagerProps extends Pick<ByHourChartProps, 'yLabel' | 'periodsDisplayType'> {
  type: ChartQueryType
  section: string
}

const ByHourChartManagerComponent = ({
  type,
  section,
  yLabel,
  periodsDisplayType,
}: ByHourChartManagerProps) => {
  const fetchKey = DetailedFetchKeyMap[type]
  const calendar = useSelector(insightsCalendarSelector)
  const { data } = useChartData<ByHourChartDataItem>(calendar, fetchKey, section, Period.Hour)

  const [currentDataByHour, previousDataByHour] = data

  const glucosePrecision = useGlucosePrecision()

  const precision = section === 'glucose' && type !== 'GLUCOSE_TIME_IN_RANGE' ? glucosePrecision : 0

  return (
    <ByHourChart
      datesRange={{ startDate: calendar.startDate, endDate: calendar.endDate }}
      precision={precision}
      currentDataByHour={currentDataByHour}
      previousDataByHour={previousDataByHour}
      yLabel={yLabel}
      periodsDisplayType={periodsDisplayType}
    />
  )
}

export const ByHourChartManager = memo(ByHourChartManagerComponent)
