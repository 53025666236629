import React, { useCallback } from 'react'
import moment from 'moment'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation, useFocusEffect } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { MarkedDates } from 'react-native-calendars/src/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { DateRangePicker } from '@components'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Button, Icon, Text } from '@src/components/base'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { useGoBack } from '@src/utils'
import { useSnack } from '@src/utils/navigatorContext'
import { useSubscriptionFromRoute } from '../../utils/hooks'
import { useCanChangeBillingCycle } from './useCanChangeBillingCycle'
import { useCalendarSelection } from './useCalendarSelection'

export const ChangeBillingCycle = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const showSnack = useSnack()
  const { subscription } = useSubscriptionFromRoute()
  const canChangeBillingCycle = useCanChangeBillingCycle(subscription)
  const { selectedDate, selectableRange, onSelectDate } = useCalendarSelection(subscription)

  useFocusEffect(
    useCallback(() => {
      // If the user leaves this screen open, backgrounds the app, then re-opens the app at a later time,
      // then it's possible they are no longer allowed to change their billing cycle.
      if (!canChangeBillingCycle) {
        goBack()
      }
    }, [goBack, canChangeBillingCycle]),
  )

  if (!subscription) {
    return null
  }

  const nextBillingDate = subscription.resetBillingCycleAnchorAt || subscription.currentPeriodEndAt
  const formattedMarkedDate = moment(nextBillingDate).format(DATE_FORMAT)
  const formattedNextBillingDate = moment(nextBillingDate).format(
    MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT,
  )
  const formattedSelectedDate = selectedDate?.format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT)
  const submitButtonTitle = formattedSelectedDate
    ? `Change billing date to ${formattedSelectedDate}`
    : 'Select a new billing date'

  const markedDates: MarkedDates = {
    [formattedMarkedDate]: {
      marked: true,
      dotColor: styles.marker.backgroundColor as string,
    },
  }

  const handleSubmit = () => {
    if (!selectedDate) {
      return
    }

    dispatch({
      type: 'marketplace/setSubscriptionBillingCycle',
      payload: { id: subscription.id, newBillingCycleAnchor: selectedDate.toISOString() },
      success: () => {
        const params = {
          subscriptionId: subscription.id,
        }
        navigation.replace('ChangeBillingCycleSuccessModal', params)
      },
      failure: (error: any) => {
        showSnack(error.message, null, 'error')
      },
    })
  }

  return (
    <NavigationContainer
      title="Change shipping & billing date"
      leftIcon={{ pack: 'eva', name: 'close' }}
      style={styles.navigationContainer}
      navigationBarProps={{
        backgroundColor: 'transparent',
      }}
    >
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <View>
          <Text type="large" style={styles.description}>
            Your next order will be processed on
          </Text>
          <Text type="large">{formattedNextBillingDate}</Text>
        </View>
        <DateRangePicker
          markedDates={markedDates}
          initialRange={['', '']}
          allowRangeSelection={false}
          onSuccess={onSelectDate}
          showTutorialLink={false}
          enableSwipeMonths
          minDate={selectableRange.start}
          maxDate={selectableRange.end}
          variant="bordered"
        />
        <View>
          <Button
            type="primary"
            size="block"
            accessibilityLabel={submitButtonTitle}
            disabled={!selectedDate}
            onPress={handleSubmit}
          >
            {submitButtonTitle}
          </Button>
          <View style={styles.reminderContainer}>
            <Icon name="info" weight="bold" style={styles.reminderIcon} />
            <Text type="regular" lineSpacing="tight" style={styles.reminderText}>
              Reminder that changing the shipping date will also modify your billing date
            </Text>
          </View>
        </View>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  navigationContainer: {
    backgroundColor: 'theme.background',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
  },
  description: {
    marginTop: 8,
  },
  reminderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    paddingHorizontal: 16,
  },
  reminderIcon: {
    color: 'theme.text.tertiary',
  },
  reminderText: {
    flexShrink: 1,
    marginLeft: 16,
    color: 'theme.text.tertiary',
  },
  marker: {
    backgroundColor: 'theme.primary.base',
  },
})
