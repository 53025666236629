import React, { useEffect, useMemo, useRef, useState } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { upperFirst } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, IconName, Button } from '@components/base'
import { CircleIcon, GestureDismissibleModal, SelectFromActionSheet } from '@components'
import { NotificationEngineReminderDayOfWeek, ReminderKind } from '@src/types'
import { showSnack } from '@src/services/Bluetooth/utils'
import { useGoBack } from '@src/utils'
import { remindersHoursIntervalSelector } from '@src/selectors/app'
import { ReminderFormData, ReminderFormInputs } from './ReminderFormInputs'
import {
  buildDayOfWeek,
  buildHoursInterval,
  buildTimeOfDay,
  frequencyKeyByTitle,
  frequencyTitle,
  orderIntervalRanges,
  TempScanReminderKind,
} from './utils'
import { AddScanReminderForm } from './ScanReminders/AddScanReminderForm'
import { useAvailableReminders } from './hooks'

const AddNewReminderForm = ({ reminderKind }: { reminderKind: ReminderKind }) => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const reminderIntervalSettings = useSelector(remindersHoursIntervalSelector)
  const hoursIntervalRanges = orderIntervalRanges(reminderIntervalSettings.glucoseCheckOptions)

  const isSubmitting = useRef(false)

  const defaultValues = useMemo(
    () => ({
      reminderKind: reminderKind.key,
      frequency: frequencyTitle(reminderKind.allowedFrequencies[0].key),
      timeOfDay: moment().format('hh:mmA'),
      dayOfWeek: upperFirst(NotificationEngineReminderDayOfWeek.Monday),
      hoursInterval: hoursIntervalRanges[hoursIntervalRanges.length / 2].text,
    }),
    [reminderKind, hoursIntervalRanges],
  )

  const { control, handleSubmit, watch, reset } = useForm<ReminderFormData>({
    defaultValues,
  })

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reminderKind, reset])

  const onSaveButtonPress = handleSubmit((data: ReminderFormData) => {
    if (isSubmitting.current) {
      return
    }
    isSubmitting.current = true

    const payload = {
      kind: reminderKind.key,
      frequency: frequencyKeyByTitle(data.frequency),
      dayOfWeek: buildDayOfWeek(data),
      timeOfDay: buildTimeOfDay(data),
      hoursInterval: buildHoursInterval(data, hoursIntervalRanges),
      __typename: 'Reminder',
    }
    dispatch({
      type: 'settings/createReminder',
      payload,
      success: () => {
        showSnack('Reminder saved!')
        goBack()
      },
      failure: (error: any) => {
        if (error?.message) {
          showSnack(error.message, null, 'error')
        }
      },
      complete: () => {
        isSubmitting.current = false
      },
    })
  })

  return (
    <>
      <View style={styles.form}>
        <ReminderFormInputs
          reminderKind={reminderKind}
          control={control}
          watch={watch}
          hoursIntervalRanges={hoursIntervalRanges}
        />
      </View>
      <Button
        type="primary"
        size="block"
        accessibilityLabel="Add reminder"
        onPress={onSaveButtonPress}
      >
        Save
      </Button>
    </>
  )
}

export const AddReminder = () => {
  const styles = useStyleSheet(themedStyles)

  const availableReminders = useAvailableReminders()

  const [reminderKind, setReminderKind] = useState(availableReminders[0])

  const onTypeChange = (selectedType: string) => {
    setReminderKind(
      availableReminders.find(({ title }) => title === selectedType) || availableReminders[0],
    )
  }

  return (
    <GestureDismissibleModal dismissButtonText="Cancel">
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <Text type="title-3" style={styles.header}>
          Add Reminder
        </Text>
        <View style={styles.form}>
          <View style={styles.typeDropdownContainer}>
            {!!reminderKind.icon && (
              <CircleIcon name={reminderKind.icon as IconName} style={styles.icon} />
            )}
            <SelectFromActionSheet
              onChange={onTypeChange}
              options={availableReminders.map(({ title }) => title)}
              label="Type"
              title="Select type"
              value={reminderKind.title}
            />
          </View>
          {reminderKind.key === TempScanReminderKind ? (
            <AddScanReminderForm />
          ) : (
            <AddNewReminderForm reminderKind={reminderKind as ReminderKind} />
          )}
        </View>
      </SafeAreaView>
    </GestureDismissibleModal>
  )
}
const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    marginBottom: 16,
  },
  header: {
    textAlign: 'center',
    marginBottom: 16,
  },
  form: {
    flex: 1,
  },
  typeDropdownContainer: {
    marginVertical: 16,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  icon: {
    marginRight: 16,
    padding: 16,
  },
})
