import React, { useEffect, useMemo, useState } from 'react'
import { AppState, DevSettings, DimensionValue, Platform } from 'react-native'
import { Provider as PaperProvider } from 'react-native-paper'
import {
  ApplicationProvider as KittenProvider,
  IconRegistry,
  Icon,
  ModalService,
} from '@ui-kitten/components'
import { EvaIconsPack } from '@ui-kitten/eva-icons'
import * as eva from '@eva-design/eva'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import FlagProvider from '@unleash/proxy-client-react'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
import { StyleService } from '@src/style/service'
import { CodePush, Bugsnag, Device, Unleash } from '@config'
import { useTheme } from '@config/theme'
import { GoogleAuth } from '@services'
import createDvaStore from '@config/state/dva'
import { Navigation } from '@navigation'
import {
  MaterialIconsPack,
  MaterialCommunityIconsPack,
  FontAwesome6ProIconsPack,
} from '@src/config/icons'
import { createAppStateNavigator } from '@navigation/containers/AppStateNavigator'
import { setTextDefaultProps } from './utils/setTextDefaultProps'
import { defaultTempAppState, TempAppStateContext } from './context/tempAppStateContext'
import { NetworkProvider } from './config/state/networkProvider'
import { Storage } from './utils'
import withStorageMigration from './utils/storageMigration'
import { AccessibilityProvider } from './config/accessibility'

setTextDefaultProps()

if (Platform.OS === 'android') {
  // Otherwise the top insets on Android don't get taken into account when creating popovers
  // and causing elements to be placed ~20 units higher than they should be
  // this should only be enabled for Android otherwise it will have undesired effects
  ModalService.setShouldUseTopInsets = true
}

if (!Device.web && __DEV__) {
  DevSettings.addMenuItem('Clear Storage', () => {
    Storage.clear()
  })
}

const App = () => {
  // temp app state that is reset on app restart
  const [tempAppState, setTempAppState] = useState(defaultTempAppState)
  const tempAppStateContextProviderValue = useMemo(() => {
    return {
      tempAppState,
      setTempAppState,
    }
  }, [tempAppState])

  const { theme } = useTheme()

  useEffect(() => {
    // start unleash client
    Unleash.client.start()

    // do not ping unleash in background
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (nextAppState === 'active') {
        Unleash.client.start()
      } else if (nextAppState.match(/inactive|background/)) {
        Unleash.client.stop()
      }
    })

    return () => {
      subscription.remove()
      Unleash.client.stop()
    }
  }, [])

  return (
    <GestureHandlerRootView style={styles.container}>
      <FlagProvider unleashClient={Unleash.client} startClient={false}>
        <IconRegistry
          icons={[
            EvaIconsPack,
            MaterialIconsPack,
            MaterialCommunityIconsPack,
            FontAwesome6ProIconsPack,
          ]}
        />
        <KittenProvider {...eva} theme={theme}>
          <PaperProvider settings={{ icon: (props) => <Icon {...props} pack="material" /> }}>
            <TempAppStateContext.Provider value={tempAppStateContextProviderValue}>
              <ActionSheetProvider>
                <AccessibilityProvider>
                  <Navigation />
                </AccessibilityProvider>
              </ActionSheetProvider>
            </TempAppStateContext.Provider>
          </PaperProvider>
        </KittenProvider>
      </FlagProvider>
    </GestureHandlerRootView>
  )
}

const styles = StyleService.create({
  container: {
    height: Device.web ? ('100vh' as DimensionValue) : '100%',
  },
})

const AppWithStateNavigator = createAppStateNavigator(App)

const DvaApp = () => {
  const store = useMemo(() => createDvaStore(), [])

  return (
    <Provider store={store}>
      <NetworkProvider>
        <PersistGate persistor={store.persistor}>
          <AppWithStateNavigator />
        </PersistGate>
      </NetworkProvider>
    </Provider>
  )
}

Bugsnag.configure()
GoogleAuth.configure()

// TODO: remove `withStorageMigration` after a while (when everyone has migrated)
export default CodePush.configure(withStorageMigration(DvaApp))
