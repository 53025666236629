import React, { useContext, useEffect } from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet } from '@src/components'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { NavigatorContext, useSnack } from '@src/utils'
import { BillingProductCategory } from '@src/types.ts'
import { ErrorMessages } from '@src/config'

export const RescheduleOrCancelVideoCallModal = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const route = useRoute<RouteProp<AppStackParamList, 'RescheduleOrCancelVideoCallModal'>>()
  const { videoCallId, appointmentType, nutritionistName } = route.params
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const { enableLoader, disableLoader } = useContext(NavigatorContext)
  const showSnack = useSnack()

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      payload: { category: BillingProductCategory.Fee },
    })
  }, [dispatch])

  const onReschedulePress = () => {
    navigation.navigate('CallScheduling', {
      appointmentType,
      appointmentId: videoCallId,
      allowBackNavigation: true,
    })
  }

  const onCancelPress = () => {
    enableLoader()
    dispatch({
      type: 'nutritionistHub/cancelVideoCall',
      payload: {
        id: videoCallId,
      },
      success: () => {
        navigation.replace('Drawer', { screen: 'Dashboard', params: { screen: 'NutritionistHub' } })
      },
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
      complete: () => {
        disableLoader()
      },
    })
  }

  const effectiveNutritionistName = nutritionistName || 'Nutritionist'

  return (
    <BottomSheet
      primaryButton={{ text: 'Reschedule', onPress: onReschedulePress }}
      secondaryButton={{ text: 'Cancel Appointment', onPress: onCancelPress }}
      buttonDirection="column-reverse"
    >
      <Text style={styles.title} type="title-2">
        Would you like to reschedule instead of cancelling?
      </Text>
      <Text style={styles.content} type="regular">
        Life happens! Opt to reschedule instead of canceling to receive personalized guidance from{' '}
        {effectiveNutritionistName} and reach your health goals.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  title: {
    textAlign: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },
  content: {
    textAlign: 'center',
  },
  nutritionistPhoto: {
    alignSelf: 'center',
  },
})
