import React from 'react'
import { useRoute } from '@react-navigation/core'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { VideoPlayer } from '@components'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AppRouteProp } from '@src/navigation/types'

export const VideoTutorial = () => {
  const route = useRoute<AppRouteProp<'VideoTutorial'>>()
  const styles = useStyleSheet(themedStyles)

  const { description, url, title } = route.params

  return (
    <NavigationContainer title="Video Tutorial" navigationBarProps={styles.navigationBar}>
      <View style={styles.contentContainer}>
        <View style={styles.summary}>
          <Icon name="video" style={styles.descriptionIcon} />
          <Text type="regular">{description}</Text>
        </View>
        <Text type="large" bold style={styles.title}>
          {title}
        </Text>
        <View style={styles.videoContainer}>
          <VideoPlayer source={{ uri: url }} />
        </View>
      </View>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  navigationBar: {
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    height: '100%',
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
  },
  summary: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  descriptionIcon: {
    width: 16,
    marginRight: 8,
  },
  title: {
    marginTop: 8,
    marginBottom: 16,
  },
  videoContainer: {
    flex: 1,
  },
})
