import { useSelector } from 'react-redux'
import { measurementsConfigSelector } from '@src/selectors/app'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import { HealthDataMeasurementType, MeasurementConfigUnit } from '@src/types'

export const useUnitSystemMeasurements = () => {
  const unitSystem = useSelector(unitSystemStoreStateSelector)
  const measurementsConfig = useSelector(measurementsConfigSelector)

  return measurementsConfig.reduce((acc, measurement) => {
    return {
      ...acc,
      [measurement.name]: measurement[unitSystem],
    }
  }, {} as Record<HealthDataMeasurementType, MeasurementConfigUnit>)
}
