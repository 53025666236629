import { gql } from '@apollo/client'
import { CORE_MEASUREMENT_FIELDS } from '@src/graphql/fragments/measurement'
import { CORE_SCAN_FIELDS } from '@src/graphql/fragments/scan'

const ALL_SCANS = gql`
  ${CORE_MEASUREMENT_FIELDS}
  ${CORE_SCAN_FIELDS}
  query allMeasurements($pagination: Pagination, $filter: MeasurementFilter) {
    allMeasurements(pagination: $pagination, filter: $filter) {
      measurements {
        ...CoreMeasurementFields
        scan {
          ...CoreScanFields
        }
      }
    }
  }
`

export default ALL_SCANS
