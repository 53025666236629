import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconButton, Text } from '@src/components/base'
import { HealthMetricsParam } from '@src/types'
import { HealthMetricValue, HealthMetricValueProps } from './HealthMetricValue'

interface HealthMetricCardProps {
  onAddAction: () => void
  title: string
  metricKey: HealthMetricsParam
  unit: string | undefined
  values: HealthMetricValueProps[]
}

export const HealthMetricCard = (props: HealthMetricCardProps) => {
  const { onAddAction, title, values, metricKey } = props
  const styles = useStyleSheet(themedStyles)

  // First and last elements in list have specific border radius
  const healthMetricValueComponents = values.map((props, index) => {
    let style

    if (values.length === 1) {
      style = styles.singleHealthMetricValue
    } else if (index === 0) {
      style = styles.topHealthMetricValue
    } else if (index === values.length - 1) {
      style = styles.bottomHealthMetricValue
    }

    return <HealthMetricValue {...props} key={`${metricKey}-${index}`} style={style} />
  })

  return (
    <View style={styles.healthMetricContainer}>
      <View style={styles.healthMetricContainerTitle}>
        <Text type="large" lineSpacing="none">
          {title}
        </Text>
        <IconButton
          size="s"
          iconName="plus"
          accessibilityLabel="Add new value"
          type="transparent"
          onPress={onAddAction}
        />
      </View>
      <View style={styles.healthMetricValuesContainer}>{healthMetricValueComponents}</View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
    padding: 16,
  },
  healthMetricContainer: {
    backgroundColor: 'theme.surface.base2',
    borderRadius: 16,
    padding: 16,
    marginBottom: 8,
  },
  healthMetricContainerTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  healthMetricValuesContainer: {
    marginTop: 16,
  },
  topHealthMetricValue: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
  },
  bottomHealthMetricValue: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  singleHealthMetricValue: {
    borderRadius: 16,
  },
})
