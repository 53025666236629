import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { Icon, IconProps } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'

interface CircleIconProps extends IconProps {
  style?: StyleProp<ViewStyle>
  iconStyle?: StyleProp<ViewStyle>
}

export const CircleIcon = ({ style, iconStyle, ...iconProps }: CircleIconProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={[styles.container, style]}>
      <Icon {...iconProps} style={[styles.icon, iconStyle]} />
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 6,
    borderRadius: 100,
    backgroundColor: 'theme.secondary.base',
  },
  icon: {
    width: 24,
    height: 24,
    color: 'theme.text.primary',
  },
})
