import React, { useRef } from 'react'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { Input, InputProps, InputRef } from '@components/base'
import { useApplicationActionSheet } from '@src/hooks/useApplicationActionSheet'

interface SelectFromActionSheetProps<Option> extends Omit<InputProps, 'onChange'> {
  options: Option[]
  style?: StyleProp<ViewStyle>
  title: string
  onChange: (value: Option) => void
}

export const SelectFromActionSheet = <Option extends string>({
  options,
  style,
  title,
  onChange,
  ...inputProps
}: SelectFromActionSheetProps<Option>) => {
  const { showActionSheet } = useApplicationActionSheet<Option>()

  const inputRef = useRef<InputRef>(null)

  const handlePress = () => {
    inputRef.current?.focus()

    showActionSheet({
      title,
      options,
      callback: onChange,
    })
  }

  return (
    <TouchableOpacity
      accessibilityLabel="Select an option"
      disabled={inputProps.disabled}
      onPress={handlePress}
      style={style}
    >
      <View pointerEvents="none">
        <Input
          {...inputProps}
          caretHidden
          iconRight="caret-down"
          placeholder={inputProps.placeholder || 'Select'}
          ref={inputRef}
          showSoftInputOnFocus={false}
        />
      </View>
    </TouchableOpacity>
  )
}
