import React, { useState, useCallback } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSelector, useDispatch } from 'react-redux'
import { AsYouType } from 'libphonenumber-js'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { ReactNativeFile } from '@src/utils/image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Feature, ScrollableAvoidKeyboard, useFeatureFlag } from '@components'
import { AddressType } from '@screens/Marketplace/components/checkout/AddressForm'
import { NavigationContainer } from '@screens/Common/containers'
import { userSelector } from '@selectors/app'
import { useActiveSubscriptions } from '@src/hooks/useActiveSubscriptions'
import { AddressCountries, SurveysConfigKind, UiStateNames } from '@src/types'
import { useSnack } from '@utils'
import { useDexcomIntegration, useIsDexcomConsentGiven } from '@src/utils/hooks'
import { getCountryCodeByCountry } from '@src/utils/phoneInput'
import { LegacyProfileContent } from '@src/screens/Profile/components/LegacyProfileContent'
import { ProfileContent } from '@src/screens/Profile/components/ProfileContent'
import { Text } from '@src/components/base'

export const Profile = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const insets = useSafeAreaInsets()

  const user = useSelector(userSelector)

  const isDexcomResearchEnabled = useFeatureFlag(Feature.DexcomResearch)
  const isDexcomIntegration = useDexcomIntegration()
  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)
  const activeSubscriptions = useActiveSubscriptions()
  const isDexcomConsentGiven = useIsDexcomConsentGiven()

  const [photoSource, setPhotoSource] = useState<ReactNativeFile | { uri: string }>({
    uri: user?.avatar || '',
  })

  const onChangeProfilePhoto = useCallback(
    (photo: ReactNativeFile) => {
      const currentPhotoSource = photoSource
      setPhotoSource(photo)
      dispatch({
        payload: { photo },
        type: 'users/updatePhoto',
        success: () => showSnack('Photo saved!'),
        failure: () => {
          showSnack('Photo saving failed!', null, 'error')
          setPhotoSource(currentPhotoSource)
        },
      })
    },
    [dispatch, photoSource, showSnack],
  )

  if (!user) {
    return null
  }

  const { address, email, firstName, lastName, fullName, paymentMethod, phoneNumber = '' } = user

  const shouldShowDexcomResearch =
    isDexcomResearchEnabled &&
    user.uiStates.some((uiState) => uiState.name === UiStateNames.DexcomSensorPrompt)

  const onResearchConsentPress = () => {
    if (isDexcomConsentGiven) {
      navigation.navigate('Questionnaire', {
        questionnaire: SurveysConfigKind.DexcomResearchConsent,
        allowEarlyExit: true,
        showCompletedScreen: false,
      })
      return
    }

    navigation.navigate('DexcomResearchOptedOutModal')
  }

  const subscription: typeof activeSubscriptions[0] | undefined = activeSubscriptions[0]

  const countryCode = getCountryCodeByCountry(
    (address?.country as AddressCountries | undefined) || AddressCountries.Us,
  )

  const accountDetails = [
    { label: 'Email', value: email },
    { label: 'First Name', value: firstName },
    { label: 'Last Name', value: lastName },
    { label: 'Phone', value: new AsYouType(countryCode).input(phoneNumber || '') },
  ]

  const addressType = activeSubscriptions.some(
    (subscription) => subscription.primaryProduct.requiresShipping,
  )
    ? AddressType.Shipping
    : AddressType.Billing

  const addressTitle = addressType === AddressType.Shipping ? 'Shipping Address' : 'Billing Address'

  const content = appNavigationUpdatesFeatureEnabled ? (
    <ProfileContent
      onChangeProfilePhoto={onChangeProfilePhoto}
      photoSource={photoSource}
      userFullName={fullName}
    />
  ) : (
    <LegacyProfileContent
      onChangeProfilePhoto={onChangeProfilePhoto}
      photoSource={photoSource}
      accountDetails={accountDetails}
      shouldShowDexcomResearch={shouldShowDexcomResearch}
      isDexcomConsentGiven={isDexcomConsentGiven}
      onResearchConsentPress={onResearchConsentPress}
      subscription={subscription}
      paymentMethod={paymentMethod}
      addressType={addressType}
      addressTitle={addressTitle}
      address={address}
      isDexcomResearchEnabled={isDexcomResearchEnabled}
      isDexcomIntegration={isDexcomIntegration}
    />
  )

  const rightAccessories = appNavigationUpdatesFeatureEnabled
    ? [
        {
          renderIconComponent: () => (
            <Text type="large" bold style={styles.editAccount}>
              Edit
            </Text>
          ),
          onPress: () => navigation.navigate('EditAccountDetails'),
        },
      ]
    : undefined

  return (
    <NavigationContainer
      title="Account"
      isDrawerScreen
      rightAccessories={rightAccessories}
      showLoadingIndicator
    >
      <ScrollableAvoidKeyboard
        style={styles.container}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{ paddingBottom: (insets.bottom || 0) + 16 }}
      >
        {content}
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  editAccount: {
    marginRight: 16,
    color: 'theme.text.link',
  },
  profilePhoto: {
    alignSelf: 'center',
    marginTop: 16,
  },
  profileName: {
    alignSelf: 'center',
    marginBottom: 24,
  },
  changeText: {
    marginLeft: 16,
    color: 'theme.text.link',
  },
  address: {
    flexShrink: 1,
  },
  textGrow: {
    flex: 1,
  },
  optedIn: {
    marginTop: 4,
    color: 'theme.success.darkest',
  },
  optedOut: {
    marginTop: 4,
    color: 'theme.disabled.text',
  },
})
