import { gql } from '@apollo/client/core'

const CREATE_PROVIDER_SETUP_INTENT = gql`
  mutation createProviderSetupIntent($email: String!, $fullName: String!, $phoneNumber: String) {
    createProviderSetupIntent(email: $email, fullName: $fullName, phoneNumber: $phoneNumber) {
      clientSecret
    }
  }
`

export default CREATE_PROVIDER_SETUP_INTENT
