import { base64Url } from './nbInternationalBase64Url'
import { base64Url as boldBase64Url } from './nbInternationalBoldBase64Url'

export const nbInternationalFont = `
@font-face {
  font-family: 'NBInternationalProReg';
  font-style: normal;
  font-weight: 400;
  src: url(${base64Url}) format('truetype');
}
`

export const nbInternationalBoldFont = `
@font-face {
  font-family: 'NBInternationalProBol';
  font-style: normal;
  font-weight: 700;
  src: url(${boldBase64Url}) format('truetype');
}
`
