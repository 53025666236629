import { gql } from '@apollo/client'
import { CORE_SETTINGS_FIELDS } from '@src/graphql/fragments/settings'

export const UPDATE_SETTINGS = gql`
  ${CORE_SETTINGS_FIELDS}
  mutation updateSettings(
    $reminders: Boolean
    $healthKitSync: Boolean
    $ketoMojoSync: Boolean
    $showNetCarbs: Boolean
    $scanReminder: Int
    $canScanExpired: Boolean
    $unitSystem: String
    $glucoseLowThreshold: Int
    $glucoseHighThreshold: Int
    $ketoneLowThreshold: Float
    $ketoneHighThreshold: Float
    $dashboardGraph: String
    $macroGoalsDaily: MacroGoalsDaily
    $integrationsSyncSettings: IntegrationsSyncSettings
    $fastingStart: Int
    $fastingEnd: Int
    $statsSettings: [StatsSettingsOrder!]
    $patternsNotifications: Boolean
  ) {
    updateSettings(
      reminders: $reminders
      healthKitSync: $healthKitSync
      unitSystem: $unitSystem
      ketoMojoSync: $ketoMojoSync
      showNetCarbs: $showNetCarbs
      scanReminder: $scanReminder
      canScanExpired: $canScanExpired
      glucoseLowThreshold: $glucoseLowThreshold
      glucoseHighThreshold: $glucoseHighThreshold
      ketoneLowThreshold: $ketoneLowThreshold
      ketoneHighThreshold: $ketoneHighThreshold
      dashboardGraph: $dashboardGraph
      macroGoalsDaily: $macroGoalsDaily
      integrationsSyncSettings: $integrationsSyncSettings
      fastingStart: $fastingStart
      fastingEnd: $fastingEnd
      statsSettings: $statsSettings
      patternsNotifications: $patternsNotifications
    ) {
      ...CoreSettingsFields
    }
  }
`
