import { createSelector } from 'reselect'
import md5 from 'md5'
import { BillingProduct, BillingProductCategory, Subscription } from '@src/types'
import { RootStoreState } from '@src/models/app.types'
import { DEFAULT_CLIENT_CONFIG } from '@src/fallbacks/fetchClientConfig'
import { SigninUserMutation } from '@src/graphql/signInUser.generated'
import { CurrentUserQuery } from '@src/graphql/fetchUser.generated'

export const appStoreStateSelector = (state: RootStoreState) => state.app

export const clientConfigStoreStateSelector = createSelector(
  appStoreStateSelector,
  (appStore) => appStore.clientConfig,
)

export const calendarDateSelector = createSelector(
  appStoreStateSelector,
  (appStore) => appStore.calendar,
)

export const loginSelector = createSelector(appStoreStateSelector, (appStore) => appStore.login)

export const userSelector = createSelector(
  loginSelector,
  (login) =>
    login.user as
      | NonNullable<NonNullable<SigninUserMutation['signinUser']>>['user']
      | CurrentUserQuery['currentUser'],
)

export const fulfillmentConfigurationSelector = createSelector(userSelector, (user) =>
  user && 'fulfillmentConfiguration' in user ? user.fulfillmentConfiguration : undefined,
)

export const uxSensorModeSelector = createSelector(userSelector, (user) =>
  user && 'uxSensorMode' in user ? user.uxSensorMode : undefined,
)

export const uxSensorModeKindSelector = createSelector(
  uxSensorModeSelector,
  (sensorMode) => sensorMode?.kind,
)

export const libreLinkUpPatientIdSelector = createSelector(userSelector, (user) =>
  user && 'libreAccount' in user ? user?.libreAccount?.libreId : undefined,
)

export const dexcomConnectionSelector = createSelector(userSelector, (user) =>
  user && 'dexcomConnection' in user ? user?.dexcomConnection : undefined,
)

export const authenticatedUserSelector = createSelector(
  userSelector,
  (user) => user as NonNullable<typeof user>,
)

export const userPropertiesSelector = createSelector(userSelector, (user) =>
  user && 'userProperties' in user ? user.userProperties : undefined,
)

export const uiStatesSelector = createSelector(userSelector, (user) => user?.uiStates || [])

export const paymentMethodSelector = createSelector(userSelector, (user) => user?.paymentMethod)

export const subscriptionsSelector = createSelector(userSelector, (user) =>
  user && 'subscriptions' in user ? (user.subscriptions as Subscription[]) : [],
)

export const primaryGoalSelector = createSelector(userSelector, (user) =>
  user && 'primaryGoal' in user ? user.primaryGoal : undefined,
)

export const goalMetricsSelector = createSelector(userSelector, (user) =>
  user && 'goalMetrics' in user ? user.goalMetrics : [],
)

export const coachChatConversationSelector = createSelector(userSelector, (user) =>
  user && 'coachChatConversation' in user ? user.coachChatConversation : undefined,
)

export const skippedSurveysSelector = createSelector(
  appStoreStateSelector,
  (appStore) => appStore.skippedSurveys || {},
)

export const addonLimitsSelector = createSelector(userSelector, (user) =>
  user && 'addonLimits' in user ? user.addonLimits : [],
)

export const referralUrlSelector = createSelector(userSelector, (user) =>
  user && 'referralUrl' in user ? user.referralUrl : undefined,
)

export const onboardingSelector = createSelector(
  appStoreStateSelector,
  (appStore) => appStore.onboarding,
)

export const onboardingSkippedSelector = createSelector(
  onboardingSelector,
  (onboarding) => onboarding.skipped,
)

export const lastOpenedSelector = createSelector(
  appStoreStateSelector,
  (appStore) => appStore.lastOpened,
)

export const lastRequestStatusSelector = createSelector(
  appStoreStateSelector,
  (app) => app.lastRequestStatus,
)

export const productsSelector = createSelector(
  appStoreStateSelector,
  (appStore) => appStore.products,
)

export const productsByCategorySelector = (category: BillingProductCategory) =>
  createSelector(appStoreStateSelector, (appStore) => appStore.productsByCategory[category] || [])

export const videoCallProductSelector = createSelector(appStoreStateSelector, (appStore) => {
  const nutritionistHubProducts =
    appStore.productsByCategory[BillingProductCategory.NutritionistHub]

  return (
    nutritionistHubProducts?.find(
      (product) => product.key === BillingProduct.DmDietitianVideo_30Min,
    ) || null
  )
})

export const monthlyNutritionCoachingProductSelector = createSelector(
  appStoreStateSelector,
  (appStore) => {
    const marketplaceProducts = appStore.productsByCategory[BillingProductCategory.Marketplace]

    return (
      marketplaceProducts?.find((product) => product.key === BillingProduct.NutrisenseDietitian) ||
      null
    )
  },
)

export const userUniqStorageKeySelector = createSelector(userSelector, (userState) =>
  userState?.email ? md5(userState?.email) : undefined,
)

export const healthSettingsSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.healthSettings,
)

export const remindersHoursIntervalSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.remindersSettings.hoursInterval,
)

export const educationCollectionsSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.education.collections,
)

export const macroGoalSettingsSelector = createSelector(
  healthSettingsSelector,
  (healthSettings) => healthSettings.macroGoalSettings,
)

const clientConfigSettingsSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.settings,
)

export const cancellationPolicySelector = createSelector(
  clientConfigSettingsSelector,
  (settings) => settings.cancellationPolicy,
)

export const privacyPolicySelector = createSelector(
  clientConfigSettingsSelector,
  (settings) => settings.privacyPolicy,
)

export const termsOfUseSelector = createSelector(
  clientConfigSettingsSelector,
  (settings) => settings.termsOfUse,
)

const customIngredientsConfigSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.customIngredients,
)

export const customIngredientsMacrosSelector = createSelector(
  customIngredientsConfigSelector,
  (customIngredientsConfig) => customIngredientsConfig.macros,
)

export const healthKitIgnoreSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.healthKit.ignore,
)

export const healthKitSourceIgnoreSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.healthKit.sourceIgnore || [],
)

// todo: remove selectors accessing `clientConfig.biteAi` when there are no more
// users on app versions < 2.7.0, and biteAi gets cleaned up from client config
const biteAiConfigSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.biteAi,
)

export const nutritionUnitsSelector = createSelector(
  biteAiConfigSelector,
  (biteAiConfig) => biteAiConfig.units,
)

const dailyValuesStoreState = createSelector(
  biteAiConfigSelector,
  (biteAiConfig) => biteAiConfig.dailyValues,
)

export const unitsAndDailyValuesSelector = createSelector(
  [nutritionUnitsSelector, dailyValuesStoreState],
  (units, dailyValues) => ({ units, dailyValues }),
)

export const referralConfigSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.referral,
)

export const referralCreditsSelector = createSelector(
  referralConfigSelector,
  (referralConfig) => referralConfig.credits,
)

export const scoreSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.score,
)

export const sensorSettingsSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.sensorSettings,
)

export const usStatesSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.usStates || [], // # TODO: remove fallback (added in 2.1.0)
)

export const caProvincesSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.caProvinces || [], // # TODO: remove fallback (added in 2.1.0)
)

export const mealTypesSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.mealTypes,
)

export const mealsFiltersSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.meals.filters,
)

export const mealsFiltersTypesSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.meals.filters.types,
)

export const historyFiltersSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.history.filters,
)

export const historyFiltersTypesSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.history.filters.types,
)

export const measurementsConfigSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.measurements,
)

export const activityTypesSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.activityTypes,
)

export const tutorialsSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.onboarding.tutorials,
)

export const goalOptionsSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.goalOptions || DEFAULT_CLIENT_CONFIG.clientConfig.goalOptions,
)

export const goalMetricOptionsSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) =>
    clientConfig.goalMetricOptions || DEFAULT_CLIENT_CONFIG.clientConfig.goalMetricOptions,
)

export const measurementTypeDefLookupSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.healthData.measurementTypeDefLookup,
)

export const measurementTypeDefsSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.healthData.measurementTypeDefs,
)

export const measurementSourceDefLookupSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.healthData.measurementSourceDefLookup,
)

export const dailyMeasurementTypeDefsSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.healthData.dailyMeasurementTypeDefs,
)

export const dailyMeasurementTypeDefLookupSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.healthData.dailyMeasurementTypeDefLookup,
)

export const dailyMeasurementSourceDefLookupSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.healthData.dailyMeasurementSourceDefLookup,
)

const journalConfigSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.journal || DEFAULT_CLIENT_CONFIG.clientConfig.journal,
)

export const journalMeasurementTypesSelector = createSelector(
  journalConfigSelector,
  (journal) => journal.measurementTypes,
)

export const journalMeasurementRangeSelector = createSelector(
  journalConfigSelector,
  (journal) => journal.range,
)

const chatbotConfigSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.chatbot || DEFAULT_CLIENT_CONFIG.clientConfig.chatbot,
)

export const chatbotLastShownCutoffAtSelector = createSelector(
  chatbotConfigSelector,
  (chatbot) => chatbot.lastShownCutoffAt,
)

export const chatbotResponseTimeoutInSecondsSelector = createSelector(
  chatbotConfigSelector,
  (chatbot) => chatbot.responseTimeoutInSeconds,
)

export const remindersConfigSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.reminders || DEFAULT_CLIENT_CONFIG.clientConfig.reminders,
)

export const appointmentTypesSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.appointmentTypes || [],
)

export const researchFaqsSelector = createSelector(
  clientConfigStoreStateSelector,
  (clientConfig) => clientConfig.researchFaqs,
)
