import React, { useContext, useState } from 'react'
import { View } from 'react-native'
import { Layout } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Apollo, App } from '@config'
import { Section, CommonSelect, useFeatureFlag, Feature } from '@components'
import { AdminUserAutocomplete } from '@containers'
import { SelectOption } from '@src/components/CommonSelect.types'
import { CodePush } from '@config'
import { NavigatorContext, useSnack } from '@src/utils/navigatorContext'
import { Button, Text } from '@components/base'
import { MobileAppFeature, UserMin } from '@src/types'
import { Storage, User } from '@src/utils'
import { appVersionSelectOptions } from '../utils/utils'
import ToggleSetting from './UserSettings/ToggleSetting'

interface AdminSettingsProps {
  selectedTimeZone: string
  users: { autocomplete: UserMin[] }
  timeZones: SelectOption[]
  onSelectTimeZone: (option: any) => void
}

export const AdminSettings = (props: AdminSettingsProps) => {
  const { users, timeZones, selectedTimeZone, onSelectTimeZone } = props

  const styles = useStyleSheet(themedStyles)

  const canImpersonate = User.hasFeature(MobileAppFeature.AdminImpersonate)

  const [timeZoneSelectDisabled, setTimeZoneSelectDisabled] = useState(false)
  const [selectedOption, setSelectedOption] = useState<SelectOption>()
  const { enableLoader, disableLoader } = useContext(NavigatorContext)

  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)

  const appVersionTitle = 'Download latest app version from'
  const timeZoneTitle = 'Time Zone'

  const disableTimezoneSelect = () => {
    setTimeZoneSelectDisabled(true)
  }

  const onAppVersionSelect = (option: SelectOption) => {
    CodePush.sync(option.value as string, { enableLoader, disableLoader })
    setSelectedOption(option)
  }

  const onResetWalkthroughPress = () => {
    Storage.WALKTHROUGH_STATE_KEYS.forEach((key) => {
      Storage.set(key, false)
    })
    showSnack('Done 🙌')
  }

  const showSnack = useSnack()

  return (
    <View style={styles.container}>
      {canImpersonate && (
        <Section title="View">
          <Layout style={styles.autocomplete}>
            <AdminUserAutocomplete
              placeholder="Search Name"
              users={users}
              onSelect={disableTimezoneSelect}
            />
          </Layout>
        </Section>
      )}
      <Section title={appVersionTitle}>
        <CommonSelect
          style={styles.autocomplete}
          options={appVersionSelectOptions}
          selectedOption={selectedOption}
          onSelect={onAppVersionSelect}
          disabled={App.build.development}
          accessibilityLabel={appVersionTitle}
          size="small"
        />
      </Section>
      <Section title={timeZoneTitle}>
        <CommonSelect
          style={styles.autocomplete}
          options={timeZones}
          selectedOption={{ text: selectedTimeZone } as SelectOption}
          onSelect={onSelectTimeZone}
          disabled={timeZoneSelectDisabled}
          accessibilityLabel={timeZoneTitle}
          size="small"
        />
      </Section>
      <ToggleSetting title="Scan expired sensor" field="canScanExpired" />
      <Section title="Build">
        <Text type="regular" bold style={styles.versionText}>
          {Apollo.env}
        </Text>
      </Section>
      <Section title="Endpoint">
        <Text type="regular" bold style={styles.versionText}>
          {Apollo.endpoint}
        </Text>
      </Section>
      {appNavigationUpdatesFeatureEnabled && (
        <Section title="Walkthrough">
          <Button
            size="small"
            type="primary"
            onPress={onResetWalkthroughPress}
            accessibilityLabel="Reset walkthrough state"
          >
            Reset walkthrough state
          </Button>
        </Section>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingTop: 16,
  },
  autocomplete: {
    flex: 1,
  },
  versionText: {
    maxWidth: 240,
  },
})
