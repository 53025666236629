import React from 'react'
import { Image, ImageStyle, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { calendarHeartImage } from '@src/assets/images'
import { Button, Text } from '@src/components/base'

interface NoLabResultsProvidedComponentProps {
  onShareLabResultsPress: () => void
}

export const NoLabResultsProvidedComponent = (props: NoLabResultsProvidedComponentProps) => {
  const { onShareLabResultsPress } = props
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.content}>
      <Image
        style={styles.calendarHeartImage as ImageStyle}
        source={calendarHeartImage.imageSource}
      />
      <Text type="title-3" style={styles.titleText}>
        No lab results submitted
      </Text>
      <Text type="large" style={styles.contentText}>
        Please share your lab results to track all your data and keep progress of your health
        outcomes.
      </Text>
      <Button
        type="primary"
        size="block"
        accessibilityLabel="Share Lab Results"
        onPress={onShareLabResultsPress}
      >
        Share lab results
      </Button>
    </View>
  )
}

const themedStyles = StyleService.create({
  content: {
    alignItems: 'center',
  },
  calendarHeartImage: {
    marginTop: 56,
    marginBottom: 48,
  },
  titleText: {
    textAlign: 'center',
    marginBottom: 32,
  },
  contentText: {
    textAlign: 'center',
    marginBottom: 48,
  },
})
