import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

const AppleLogo = ({ fill }: { fill: string }) => (
  <Svg fill="none" viewBox="0 0 24 24">
    <Path
      fill={fill}
      fillRule="evenodd"
      // eslint-disable-next-line max-len
      d="M16.286 1c.206 1.285-.36 2.542-1.107 3.432-.8.954-2.178 1.693-3.51 1.654-.242-1.23.382-2.495 1.138-3.348.834-.94 2.26-1.66 3.479-1.738zm3.79 18.933c.69-.972.945-1.463 1.48-2.564-3.882-1.363-4.506-6.46-.664-8.417-1.173-1.358-2.819-2.144-4.372-2.144-1.121 0-1.89.27-2.587.515-.581.205-1.114.392-1.762.392-.7 0-1.32-.205-1.97-.42-.714-.237-1.464-.485-2.394-.485-1.746 0-3.602.984-4.78 2.668-1.655 2.369-1.374 6.825 1.31 10.622.96 1.359 2.242 2.886 3.92 2.9.694.006 1.157-.185 1.657-.392.574-.238 1.197-.496 2.277-.502 1.086-.005 1.7.256 2.265.496.488.208.94.4 1.63.394 1.678-.014 3.032-1.705 3.99-3.063z"
      clipRule="evenodd"
    />
  </Svg>
)

export default AppleLogo
