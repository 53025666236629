import React, { useEffect, useState } from 'react'
import Fuse from 'fuse.js'
import { SearchBar } from './SearchBar'

interface FuseSearchBarProps<T> {
  rawList: T[]
  onSearchResult: (items: T[]) => void
  options: Fuse.IFuseOptions<T>
}

export const FuseSearchBar = <T,>(props: FuseSearchBarProps<T>) => {
  const { rawList, onSearchResult, options } = props

  const [fuse, setFuse] = useState<Fuse<T> | null>(null)
  const [searchQuery, setSearchQuery] = useState('')

  useEffect(() => {
    const _fuse = new Fuse(rawList, options)
    setFuse(_fuse)
  }, [rawList, options, onSearchResult])

  useEffect(() => {
    let filteredList = rawList
    if (fuse && searchQuery.length > 0) {
      const result = fuse.search(searchQuery)
      filteredList = result.map(({ item }) => item)
    }
    onSearchResult(filteredList)
  }, [rawList, searchQuery, fuse, onSearchResult])

  return <SearchBar value={searchQuery} onChangeText={setSearchQuery} />
}
