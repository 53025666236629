import { gql } from '@apollo/client'

export const SET_PRIMARY_GOAL_METRICS = gql`
  mutation setPrimaryGoalMetrics($kinds: [UserGoalMetricKind!]) {
    setPrimaryGoalMetrics(kinds: $kinds) {
      primary
      kind
      lowThresholdValue
      highThresholdValue
      section
      statKey
    }
  }
`
