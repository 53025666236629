import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet } from '@src/components'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { BillingProduct } from '@src/types.ts'
import { NutritionistPhotoIcon } from '@screens/NutritionistHub/components/NutritionistPhotoIcon.tsx'
import { useFeeProduct } from '@screens/NutritionistHub/hooks/useFeeProduct.ts'

export const FreePassLateRescheduleOrCancelVideoCallModal = () => {
  const styles = useStyleSheet(themedStyles)
  const route = useRoute<
    RouteProp<AppStackParamList, 'FreePassLateRescheduleOrCancelVideoCallModal'>
  >()
  const { videoCallId, appointmentType, nutritionistName } = route.params
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const lateCancellationFeeProduct = useFeeProduct(BillingProduct.VideoCallLateCancellationFee)

  const onReschedulePress = () => {
    navigation.navigate('CallScheduling', {
      appointmentType,
      appointmentId: videoCallId,
      allowBackNavigation: true,
    })
  }

  const onCancelPress = () => {
    navigation.navigate('FeePaymentScreen', {
      videoCallId,
      feeProduct: lateCancellationFeeProduct,
      appointmentType,
    })
  }

  const effectiveNutritionistName = nutritionistName || 'Nutritionist'

  return (
    <BottomSheet
      primaryButton={{ text: 'Reschedule', onPress: onReschedulePress }}
      secondaryButton={{ text: 'Confirm Fee and Cancel', onPress: onCancelPress }}
      buttonDirection="column-reverse"
    >
      <NutritionistPhotoIcon style={styles.nutritionistPhoto} />
      <Text style={styles.title} type="title-2">
        Cannot make it?
      </Text>
      <Text style={styles.content} type="regular">
        {effectiveNutritionistName} has reserved this time for you. Grab this one-time free
        reschedule to dodge a ${lateCancellationFeeProduct?.price || ''} fee for changes made within
        24 hours of your call.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  title: {
    textAlign: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },
  content: {
    textAlign: 'center',
  },
  nutritionistPhoto: {
    alignSelf: 'center',
  },
})
