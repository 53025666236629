import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService } from '@src/style/service'
import { Section, CommonSelect } from '@components'
import { settingsSelector } from '@src/selectors/settings'
import { healthSettingsSelector } from '@src/selectors/app'
import { SelectOption } from '@src/components/CommonSelect.types'
import { HealthSettings } from '@src/types'

interface SelectSettingProps {
  title: string
  field: 'scanReminder' | 'unitSystem' | 'dashboardGraph'
  optionsKey: string
  disabled?: boolean
}

const SelectSetting = (props: SelectSettingProps) => {
  const { title, field, optionsKey, disabled } = props

  const settings = useSelector(settingsSelector)

  const healthSettings = useSelector(healthSettingsSelector)

  const options = healthSettings[optionsKey as keyof HealthSettings] as SelectOption[]

  const dispatch = useDispatch()

  const onSelect = (option: { value: number | string }) => {
    if (settings[field] === option.value) {
      return
    }

    dispatch({
      type: 'settings/update',
      payload: { [field]: option.value },
    })
  }

  return (
    <Section title={title} disabled={disabled}>
      <CommonSelect
        style={styles.selectContainer}
        options={options}
        selectedOption={options?.find((option) => option.value === settings[field])}
        onSelect={onSelect}
        disabled={disabled}
        accessibilityLabel={title}
        size="small"
      />
    </Section>
  )
}

const styles = StyleService.create({
  selectContainer: {
    width: 180,
  },
})

export default SelectSetting
