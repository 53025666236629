import { gql } from '@apollo/client'

export const FETCH_REMINDERS = gql`
  query reminders {
    reminders {
      reminders {
        id
        kind
        frequency
        dayOfWeek
        timeOfDay
        hoursInterval
      }
    }
  }
`
