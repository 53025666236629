import { gql } from '@apollo/client'

const ALL_FAVORITE_ACTIVITIES = gql`
  query allActivities($pagination: Pagination, $filter: ActivityFilter) {
    allActivities(pagination: $pagination, filter: $filter) {
      activities {
        id
        type
        title
        startedAt
        endedAt
        favorite
        description
        occurredAt
        createdAt
        externalSource
      }
    }
  }
`

export default ALL_FAVORITE_ACTIVITIES
