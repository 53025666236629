import React from 'react'
import { View } from 'react-native'
import FastImage, { ImageStyle } from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { useTheme } from '@ui-kitten/components'
import { Text } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { userSelector } from '@src/selectors/app'
import { useUserInitials } from '@src/utils/hooks'
import { useIsDarkMode } from '@src/config/theme'
import { Storage } from '@src/utils'
import { useFeatureFlag, Feature } from '@src/components'
import { useStorageValue } from '@src/utils/storage'
import { useWebPresentationModeContext } from '@src/config/webPresentationMode'

export const AccountAvatar = () => {
  const styles = useStyleSheet(themedStyle)
  const { avatar } = useSelector(userSelector) ?? {}
  const initials = useUserInitials()
  const theme = useTheme()
  const isDarkMode = useIsDarkMode()

  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)

  const [profileScreenVisited] = useStorageValue<boolean>(Storage.ACCOUNT_SCREEN_VISITED_KEY)

  const { isWebPresentationMode } = useWebPresentationModeContext()

  if (isWebPresentationMode) {
    return
  }

  return (
    <View style={styles.container}>
      {avatar ? (
        <FastImage style={styles.avatar as ImageStyle} source={{ uri: avatar }} />
      ) : (
        <View
          style={[
            styles.initialsContainer,
            {
              backgroundColor: isDarkMode
                ? theme['theme.accent.brick']
                : theme['theme.accent.citron'],
            },
          ]}
        >
          <Text type="regular" bold style={styles.initials}>
            {initials}
          </Text>
        </View>
      )}
      {appNavigationUpdatesFeatureEnabled && !profileScreenVisited && (
        <View style={styles.redDot} />
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    width: 40,
    height: 40,
    marginHorizontal: 16,
  },
  initialsContainer: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    height: '100%',
    width: '100%',
    borderRadius: 100,
  },
  initials: {
    color: 'theme.primary.dark',
  },
  redDot: {
    position: 'absolute',
    top: -3,
    right: -3,
    borderWidth: 3,
    borderColor: 'theme.background',
    width: 16,
    height: 16,
    borderRadius: 16,
    backgroundColor: 'theme.error.base',
  },
})
