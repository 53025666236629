import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { merge } from 'lodash'
import XDate from 'xdate'
import moment, { Moment } from 'moment'
import { Calendar, DateData } from 'react-native-calendars'
import { useWindowDimensions } from 'react-native'
import { MarkedDates } from 'react-native-calendars/src/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { TutorialLink } from '@components'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { Haptic, setupSelectedDates } from '@utils'
import { useIsDarkMode } from '@src/config/theme'
import { useVariantStyleSheet } from '@src/hooks/useVariantStyleSheet'
import Day from '../calendar/Day'
import { DateRangePickerRef } from './DateRangePicker.types'

const HEADER_HEIGHT = 60
const HEADER_BUTTON_WIDTH = 60
const CALENDAR_HEADER_WIDTH = 218 // min width that fits "September 2020" with arrows
const INITIAL_IS_RANGE_SELECTION = false

interface DateRangePickerProps {
  markedDates: MarkedDates
  initialRange: [string, string]
  allowRangeSelection?: boolean
  loadCalendarData?: (date: Moment) => void
  onSuccess: (startDate: string, endDate: string) => void
  onRangeSelectionStart?: () => void
  showTutorialLink: boolean
  enableSwipeMonths: boolean
  minDate?: string
  maxDate?: string
  variant?: 'divided' | 'bordered'
}

export const DateRangePicker = forwardRef<DateRangePickerRef, DateRangePickerProps>(
  (
    {
      markedDates,
      initialRange: [fromDateRange, toDate],
      allowRangeSelection = true,
      loadCalendarData,
      onSuccess,
      onRangeSelectionStart,
      showTutorialLink,
      enableSwipeMonths = true,
      minDate,
      maxDate,
      variant = 'divided',
    },
    ref,
  ) => {
    const styles = useVariantStyleSheet(themedStyles, variant, {
      divided: dividedStyles,
      bordered: borderedStyles,
    })
    const dimensions = useWindowDimensions()
    const isDarkMode = useIsDarkMode()

    let headerMarginLeft = 0

    if (dimensions.width - CALENDAR_HEADER_WIDTH - HEADER_BUTTON_WIDTH * 2 < 0) {
      // on small devices calendar header (month + arrows) should be centered relatively to left part of header:
      // | < September 2020 > | x |
      // on large devices it's centered relatively to header container:
      // |     < September 2020 > | x |
      // otherwise it would look this way on small devices:
      // | < September 2020 |>x |
      headerMarginLeft = -HEADER_BUTTON_WIDTH
    }

    const colorConfig = styles.colors as {
      color: string
      textColor: string
    }

    const getSelectedDate = useCallback(
      (date: string, endingDay = false): MarkedDates => ({
        [date]: {
          startingDay: true,
          selected: true,
          endingDay,
          ...colorConfig,
        },
      }),
      [colorConfig],
    )

    const [fromDate, setFromDate] = useState(fromDateRange)

    const [selectedDates, setSelectedDates] = useState(() => {
      return setupSelectedDates(fromDate, toDate, getSelectedDate(fromDate), colorConfig)
    })

    const [isRangeSelection, setIsRangeSelection] = useState(INITIAL_IS_RANGE_SELECTION)

    const onEndDayPress = useCallback(
      (day: DateData) => {
        let markedSelectedDates = { ...selectedDates }

        let startDate = fromDate
        let endDate = day.dateString
        let range = moment(endDate).diff(moment(startDate), 'days')
        if (range < 0) {
          range = moment(startDate).diff(moment(endDate), 'days')
          startDate = day.dateString
          endDate = fromDate
          markedSelectedDates = getSelectedDate(startDate)
        }

        if (range >= 0) {
          setSelectedDates(setupSelectedDates(startDate, endDate, markedSelectedDates, colorConfig))
          setFromDate(startDate)
          setIsRangeSelection(false)
          onSuccess(startDate, endDate)
        }
      },
      [colorConfig, fromDate, getSelectedDate, selectedDates, onSuccess],
    )

    const onDayPress = useCallback(
      (date: DateData) => {
        Haptic.heavyTap()

        if (isRangeSelection) {
          onEndDayPress(date)
        } else {
          setSelectedDates(getSelectedDate(date.dateString, true))
          setFromDate(date.dateString)
          setIsRangeSelection(false)
          onSuccess(date.dateString, date.dateString)
        }
      },
      [getSelectedDate, isRangeSelection, onEndDayPress, onSuccess],
    )

    const onDayLongPress = useCallback(
      (date: DateData) => {
        if (!allowRangeSelection) {
          return
        }

        Haptic.heavyTap()

        if (isRangeSelection) {
          onEndDayPress(date)
        } else {
          setSelectedDates(getSelectedDate(date.dateString))
          setFromDate(date.dateString)
          setIsRangeSelection(true)
          onRangeSelectionStart?.()
        }
      },
      [
        getSelectedDate,
        isRangeSelection,
        allowRangeSelection,
        onEndDayPress,
        onRangeSelectionStart,
      ],
    )

    const renderArrow = useCallback(
      (direction: 'left' | 'right') => {
        return <Icon name={`caret-${direction}`} style={styles.arrowIcon} />
      },
      [styles],
    )

    const renderHeader = useCallback(
      (date?: XDate): ReactNode => {
        return (
          <Text type="large" bold style={styles.headerTitle}>
            {date?.toString('MMMM yyyy')}
          </Text>
        )
      },
      [styles.headerTitle],
    )

    const onMonthChange = (month: DateData) => {
      const date = moment(month.dateString)

      loadCalendarData?.(date)
    }

    useEffect(() => {
      const currentMonth = moment().month()
      const endOfDateRangeDate = moment(toDate, DATE_FORMAT)
      const endOfDateRangeMonth = endOfDateRangeDate.month()

      // if the toDate is not in current month, we need to fetch the data for that month
      loadCalendarData?.(currentMonth === endOfDateRangeMonth ? moment() : endOfDateRangeDate)
    }, [toDate, loadCalendarData])

    const mainStyles = useStyleSheet(calendarMainStyles)
    let headerStyles = useVariantStyleSheet(commonCalendarHeaderStyles, variant, {
      divided: dividedCalendarHeaderStyles,
      bordered: {},
    })

    if (showTutorialLink && headerStyles.header.marginLeft !== headerMarginLeft) {
      headerStyles = {
        ...headerStyles,
        header: { ...headerStyles.header, marginLeft: headerMarginLeft },
      }
    }

    const reset = () => {
      setSelectedDates(
        setupSelectedDates(fromDateRange, toDate, getSelectedDate(fromDateRange), colorConfig),
      )
      setFromDate(fromDateRange)
      setIsRangeSelection(INITIAL_IS_RANGE_SELECTION)
    }

    useImperativeHandle(ref, () => ({
      reset,
    }))

    return (
      <>
        <Calendar
          key={`${isDarkMode}${headerStyles.header.marginLeft}`}
          theme={{
            calendarBackground: 'transparent',
            // @ts-ignore https://github.com/wix/react-native-calendars/issues/1864
            'stylesheet.calendar.main': mainStyles,
            'stylesheet.calendar.header': headerStyles,
          }}
          style={styles.calendarContainer}
          current={fromDate}
          markingType="period"
          markedDates={merge(selectedDates, markedDates)}
          onDayPress={onDayPress}
          onDayLongPress={onDayLongPress}
          renderHeader={renderHeader}
          renderArrow={renderArrow}
          dayComponent={Day}
          enableSwipeMonths={enableSwipeMonths}
          showSixWeeks
          onMonthChange={onMonthChange}
          minDate={minDate}
          maxDate={maxDate}
        />
        {showTutorialLink && (
          <TutorialLink
            tutorialKey="calendar"
            hitSlop={{}}
            style={styles.headerButton}
            showText={false}
          />
        )}
      </>
    )
  },
)

const calendarMainStyles = StyleService.create({
  container: {
    padding: 0,
  },
  week: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
})

const commonCalendarHeaderStyles = StyleService.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    width: CALENDAR_HEADER_WIDTH,
    height: HEADER_HEIGHT,
    marginLeft: 0,
  },
  week: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 40,
  },
  dayHeader: {
    width: 32,
    textAlign: 'center',
    fontSize: 13,
    fontFamily: 'NBInternationalProReg',
    fontWeight: '400',
    color: 'theme.text.secondary',
  },
})

const dividedCalendarHeaderStyles = StyleService.create({
  week: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: 'theme.surface.base2',
  },
})

const themedStyles = StyleService.create({
  calendarContainer: {
    paddingBottom: 8,
  },
  headerButton: {
    position: 'absolute',
    right: 0,
    width: HEADER_BUTTON_WIDTH,
    height: HEADER_HEIGHT,
    marginRight: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderColor: 'theme.surface.base2',
  },
  headerTitle: {
    textAlign: 'center',
  },
  colors: {
    color: 'theme.primary.base',
    textColor: 'theme.solid.white',
  },
  arrowIcon: {
    height: 24,
    width: 24,
    color: 'theme.text.secondary',
  },
})

const dividedStyles = StyleService.create({
  calendarContainer: {
    borderBottomWidth: 1,
    borderColor: 'theme.surface.base2',
  },
})

const borderedStyles = StyleService.create({
  calendarContainer: {
    borderWidth: 2,
    borderRadius: 8,
    borderColor: 'theme.surface.base2',
  },
})
