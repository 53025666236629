export const EXPANDED_LIST_HEADER_HEIGHT = 197
export const LIST_SECTION_HEADER_HEIGHT = 32
export const LIST_VERTICAL_PADDINGS = 92

export const defaultEventsState = {
  nutrition: {},
  score: {},
  statistics: { today: { statistics: {} } },
  events: [],
  charts: {
    primary: { values: [], range: { min: 0, max: 0 }, yAxis: '' },
    secondary: { values: [], range: { min: 0, max: 0 }, yAxis: '' },
  },
}
