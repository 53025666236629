import { Model } from '@models'
import { SET_PRIMARY_GOAL_METRICS } from '@src/screens/Settings/graphql/setPrimaryGoalMetrics'
import { UPSERT_PRIMARY_GOAL } from '@src/screens/Settings/graphql/upsertPrimaryGoal'

export default class Goals {
  namespace = 'goals'

  state = {
    ...Model.defaultState,
  }

  effects = {
    upsertPrimaryGoal: Model.buildEffect({
      name: `${this.namespace}/upsertPrimaryGoal`,
      query: UPSERT_PRIMARY_GOAL,
    }),
    setPrimaryGoalMetrics: Model.buildEffect({
      name: `${this.namespace}/setPrimaryGoalMetrics`,
      query: SET_PRIMARY_GOAL_METRICS,
    }),
  }

  reducers = {
    ...Model.defaultReducers,
  }
}
