import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Storage, useSnack } from '@src/utils'
import { Login } from '@src/models/app.types'
import { useFeatureFlag, Feature } from '@src/components'
import { Apollo, App } from '@src/config'
import { useStorageValue } from '@src/utils/storage'

export const useIframeImpersonation = (adminLogin: Login | undefined) => {
  const adminEmail = adminLogin?.user?.email
  const showSnack = useSnack()
  const dispatch = useDispatch()
  const isPresentationFeatureEnabled = useFeatureFlag(Feature.MobileAppPresentationMode)
  const [endpoint] = useStorageValue('endpoint')

  useEffect(() => {
    // @ts-ignore-next-line
    const handleMemberDataMessage = (event: any) => {
      if (event.data.type !== 'member_data' || !adminEmail || !isPresentationFeatureEnabled) {
        return
      }

      try {
        dispatch({
          type: 'app/logout',
          success: () => {
            Storage.set('login', adminLogin)
            Storage.set('admin_login', adminLogin)

            Apollo.resetEndpoint({ ...App.build, endpoint: endpoint as string })

            dispatch({
              type: 'app/login',
              payload: {
                email: adminEmail,
                password: '',
                impersonate: event.data.userEmail,
              },
            })
          },
        })
      } catch (error: any) {
        showSnack(error.message)
      }
    }

    // @ts-ignore-next-line
    window.addEventListener('message', handleMemberDataMessage)
    // @ts-ignore-next-line
    return () => window.removeEventListener('message', handleMemberDataMessage)

    // we already check adminEmail so we don't need to check adminLogin
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminEmail, dispatch, isPresentationFeatureEnabled, showSnack])

  const hasAdmin = !!adminEmail

  useEffect(() => {
    if (hasAdmin && isPresentationFeatureEnabled) {
      // @ts-ignore-next-line
      window.parent.postMessage({ type: 'admin_authenticated' }, '*')
    }
  }, [hasAdmin, isPresentationFeatureEnabled])
}
