import React from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useEffect, useState } from 'react'
import { MMKV } from 'react-native-mmkv'
import { Bugsnag } from '@src/config'

const storage = new MMKV()

const hasMigratedFromAsyncStorage = storage.getBoolean('hasMigratedFromAsyncStorage')

const migrateFromAsyncStorage = async (): Promise<void> => {
  console.log('Migrating from AsyncStorage -> MMKV...')
  const keys = await AsyncStorage.getAllKeys()

  for (const key of keys) {
    try {
      const value = await AsyncStorage.getItem(key)

      if (value != null) {
        storage.set(key, value)
        console.log(`Migrated key "${key}"`)
        AsyncStorage.removeItem(key)
      }
    } catch (error: any) {
      console.error(`Failed to migrate key "${key}" from AsyncStorage to MMKV!`, error)
      Bugsnag.notify(error)
    }
  }

  storage.set('hasMigratedFromAsyncStorage', true)

  console.log(`Migrated from AsyncStorage -> MMKV!`)
}

const withStorageMigration = (WrappedComponent: any) => () => {
  const [hasMigrated, setHasMigrated] = useState(hasMigratedFromAsyncStorage)

  useEffect(() => {
    if (!hasMigratedFromAsyncStorage) {
      try {
        migrateFromAsyncStorage().then(() => {
          setHasMigrated(true)
        })
      } catch (e) {
        console.error('Failed to migrate from AsyncStorage -> MMKV!', e)
      }
    }
  }, [])

  if (!hasMigrated) {
    return null
  }

  return <WrappedComponent />
}

export default withStorageMigration
