import React from 'react'
import { TouchableOpacity, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

export interface RadioProps {
  checked?: boolean
  disabled?: boolean
  hasError?: boolean
  style?: ViewStyle
  text?: string
  onPress?: () => void
}

export const Radio = ({ checked, disabled, hasError, style, text, onPress }: RadioProps) => {
  const styles = useStyleSheet(themedStyle)

  const radioStyle = [
    styles.circle,
    checked && styles.filled,
    hasError && styles.error,
    disabled && styles.disabled,
    checked && disabled && styles.filledDisabled,
  ]
  const dotStyle = [styles.circleDot, checked && disabled && styles.circleDotDisabled]

  return (
    <TouchableOpacity
      accessibilityLabel={text}
      disabled={disabled}
      style={[style, styles.radioContainer]}
      onPress={onPress}
    >
      <View style={radioStyle}>{checked && <View style={dotStyle} />}</View>
      {!!text && (
        <Text type="regular" style={styles.text}>
          {text}
        </Text>
      )}
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  radioContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
  },
  circle: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderWidth: 2,
    borderRadius: 12,
    borderColor: 'theme.surface.base0',
  },
  circleDot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: 'theme.solid.white',
  },
  filled: {
    borderColor: 'theme.primary.base',
    backgroundColor: 'theme.primary.base',
  },
  error: {
    borderColor: 'theme.error.base',
  },
  disabled: {
    borderColor: 'theme.disabled.background',
    backgroundColor: 'theme.transparent',
  },
  circleDotDisabled: {
    backgroundColor: 'theme.surface.base3',
  },
  filledDisabled: {
    borderColor: 'theme.surface.base1',
    backgroundColor: 'theme.surface.base1',
  },
  text: {
    marginLeft: 8,
  },
})
