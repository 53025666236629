import { compact } from 'lodash'
import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'
import { IngredientsStoreState } from '@src/screens/Ingredients/models/ingredients.types'

export const getIngredientsState = (state: RootStoreState): IngredientsStoreState =>
  state.ingredients

export const ingredientsSelector = createSelector(getIngredientsState, (ingredientsState) => {
  const ingredients = ingredientsState.ingredients

  const ingredientsById = Object.fromEntries(
    ingredients.map((ingredient) => [ingredient.id, ingredient]),
  )

  return compact([
    ...ingredients.filter((ingredient) => !ingredient.id),
    ...ingredientsState.order.map((id: string) => ingredientsById[id]),
  ])
})
