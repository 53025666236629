import React from 'react'
import { useSelector } from 'react-redux'
import { StyleProp, ViewStyle } from 'react-native'
import { TouchableSection } from '@components'
import { privacyPolicySelector } from '@src/selectors/app'
import { openUrl } from '@src/utils'

interface PrivacyPolicySectionProps {
  style?: StyleProp<ViewStyle>
}

const PrivacyPolicySection = (props: PrivacyPolicySectionProps) => {
  const url = useSelector(privacyPolicySelector)

  const onPress = () => {
    openUrl(url)
  }

  const { style } = props

  return <TouchableSection style={style} onPress={onPress} title="Privacy Policy" />
}

export default PrivacyPolicySection
