import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Period } from '@src/types'
import { Calendar } from '@src/models/app.types'
import { useDispatchAsync } from '@src/utils'
import { insightsCacheSelector } from '@src/screens/Insights/models/insights.selectors'
import { cacheKey } from '@src/screens/Insights/models/insights'
import { previousEndDate, previousStartDate } from './helper'

const getType = (section: string, value: string) => [{ key: section, value: [value] }]

export const useGetCacheValue = <DataItemType extends Record<string, any>>({
  startDate,
  endDate,
  period,
  section,
  fetchKey,
}: {
  startDate: string
  endDate: string
  period: Period
  section: string
  fetchKey: string
}): DataItemType[] => {
  const cache = useSelector(insightsCacheSelector)
  const payload = {
    filter: {
      startDate,
      endDate,
      period,
      types: getType(section, fetchKey),
    },
  }
  const key = cacheKey(payload)
  return cache[key]?.response?.values[0]?.data ?? []
}

const FETCH_CHARTS_EFFECT_NAME = 'insights/fetchChartsData'

export const useChartData = <DataItemType extends Record<string, any>>(
  { startDate, endDate, rangeDays }: Calendar,
  fetchKey: string,
  section: string,
  period: Period,
) => {
  const prevStartDate = previousStartDate(startDate, rangeDays)
  const prevEndDate = previousEndDate(startDate)

  const [loading, setLoading] = useState(false)

  const currentCachedData = useGetCacheValue<DataItemType>({
    startDate,
    endDate,
    period,
    section,
    fetchKey,
  })
  const previousCachedData = useGetCacheValue<DataItemType>({
    startDate: prevStartDate,
    endDate: prevEndDate,
    period,
    section,
    fetchKey,
  })

  const localCurrentData = useRef(currentCachedData)
  const localPreviousData = useRef(previousCachedData)

  const currentData = useMemo(() => {
    if (currentCachedData.length === 0) {
      return localCurrentData.current
    }

    localCurrentData.current = currentCachedData

    return currentCachedData
  }, [currentCachedData])

  const previousData = useMemo(() => {
    if (previousCachedData.length === 0) {
      return localPreviousData.current
    }

    localPreviousData.current = previousCachedData

    return previousCachedData
  }, [previousCachedData])

  const dispatchAsync = useDispatchAsync()

  const fetchChartsData = useCallback(async () => {
    setLoading(true)

    const types = getType(section, fetchKey)

    const promises = [
      dispatchAsync({
        type: FETCH_CHARTS_EFFECT_NAME,
        useCache: false,
        payload: {
          filter: {
            startDate,
            endDate,
            period,
            types,
          },
        },
      }),
      dispatchAsync({
        type: FETCH_CHARTS_EFFECT_NAME,
        useCache: false,
        payload: {
          filter: {
            startDate: prevStartDate,
            endDate: prevEndDate,
            period,
            types,
          },
        },
      }),
    ]

    try {
      await Promise.all(promises)
    } finally {
      setLoading(false)
    }
  }, [dispatchAsync, endDate, fetchKey, period, prevEndDate, prevStartDate, section, startDate])

  const currentCachedDataIsMissing = currentCachedData.length === 0

  useEffect(() => {
    if (currentCachedDataIsMissing) {
      fetchChartsData()
    }
  }, [currentCachedDataIsMissing, fetchChartsData])

  const refetch = useCallback(() => {
    fetchChartsData()
  }, [fetchChartsData])

  return { data: [currentData, previousData] as const, loading, refetch }
}
