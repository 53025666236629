import React from 'react'
import { View, ViewProps, ViewStyle, StyleProp } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@components/base'

interface CircularCheckProps extends ViewProps {
  selected: boolean
  iconStyle?: StyleProp<ViewStyle>
}

export const CircularCheck = ({ selected, style, iconStyle, ...props }: CircularCheckProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View
      {...props}
      style={[styles.checkboxContainer, selected && styles.checkboxContainerSelected, style]}
    >
      {selected && <Icon name="check-circle" style={[styles.checkbox, iconStyle]} weight="fill" />}
    </View>
  )
}
const themedStyles = StyleService.create({
  checkboxContainer: {
    width: 32,
    height: 32,
    borderRadius: 100,
    borderWidth: 2,
    borderColor: 'theme.surface.base',
  },
  checkboxContainerSelected: {
    borderWidth: 0,
  },
  checkbox: {
    // The 'check-circle' icon canvas has ~12% padding on all sides.
    // Scale-up and offset the padding of the icon to match it's container.
    top: '-12%',
    left: '-12%',
    width: '124%',
    height: '124%',
    color: 'theme.success.base',
  },
})
