import { gql } from '@apollo/client'

export const FETCH_TERRA_PROVIDERS = gql`
  query allTerraProviders {
    allTerraProviders {
      providers {
        provider
        active
        connectionId
        name
        status
        errorReason
        disabledTypes
        availableTypes
      }
    }
  }
`
