import { ViewStyle, TextStyle, ImageStyle } from 'react-native'
// eslint-disable-next-line no-restricted-imports
import { StyleService as UIKittenStyleService } from '@ui-kitten/components'
import { ThemeColor } from '@src/style/theme'

// eslint-disable-next-line no-restricted-imports
export { useStyleSheet } from '@ui-kitten/components'

type TransformColorStyleValues<T> = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  [K in keyof T]: K extends `${infer _}Color` ? ThemeColor : K extends 'color' ? ThemeColor : T[K]
}

type NamedStyles<T> = {
  [P in keyof T]: TransformColorStyleValues<ViewStyle | TextStyle | ImageStyle>
}

export const StyleService = {
  create: UIKittenStyleService.create as <T extends NamedStyles<T>>(styles: T) => T,
}
