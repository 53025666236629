import React from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'
import { CalendarProps } from 'react-native-calendars'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

const Day: CalendarProps['dayComponent'] = (props) => {
  const { date, state, marking, onPress, onLongPress } = props
  const styles = useStyleSheet(themedStyle)
  const disabled = state === 'disabled'
  const today = state === 'today'

  const containerStyles = [styles.day]
  const textStyles = [styles.dayText]

  if (disabled) {
    textStyles.push(styles.dayDisabledText)
    containerStyles.push(styles.dayDisabled)
  } else if (today) {
    containerStyles.push(styles.dayToday)
  }

  if (marking) {
    if (marking.startingDay || marking.endingDay) {
      // single selected day or start/end of selected range
      containerStyles.push(styles.daySelected)
      textStyles.push(styles.dayInRangeText)
      if (!marking.endingDay) {
        // start of selected range
        containerStyles.push(styles.dayRangeStart)
      } else if (!marking.startingDay) {
        // end of selected range
        containerStyles.push(styles.dayRangeEnd)
      }
    } else if (marking.selected) {
      // day in range
      containerStyles.push(styles.daySelected)
      containerStyles.push(styles.dayInRange)
      textStyles.push(styles.dayInRangeText)
    }
  }

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (!onPress) {
          return
        }
        onPress(date)
      }}
      onLongPress={() => {
        if (!onLongPress) {
          return
        }
        onLongPress(date)
      }}
      accessibilityLabel={date?.day?.toString() || 'Day'}
    >
      <View style={containerStyles}>
        <Text type="large" bold style={textStyles}>
          {date?.day}
        </Text>
        <View
          style={[
            styles.dot,
            !marking?.selected && marking?.dotColor ? { backgroundColor: marking.dotColor } : {},
          ]}
        />
      </View>
    </TouchableWithoutFeedback>
  )
}

export default React.memo(Day)

const themedStyle = StyleService.create({
  dayText: {
    marginTop: 2,
  },
  dayDisabledText: {
    color: 'theme.disabled.text',
  },
  dayInRangeText: {
    color: 'theme.solid.white',
  },
  day: {
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  dayDisabled: {
    opacity: 0.5,
  },
  dayToday: {
    borderWidth: 1,
    borderColor: 'theme.primary.base',
    borderRadius: 4,
  },
  daySelected: {
    backgroundColor: 'theme.primary.base',
    borderRadius: 4,
  },
  dayRangeStart: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  dayRangeEnd: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  dayInRange: {
    borderRadius: 0,
  },
  dot: {
    width: 4,
    height: 4,
    marginTop: 2,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    borderBottomLeftRadius: 2,
    borderBottomRightRadius: 2,
  },
})
