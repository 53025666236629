import { inspect } from 'util'
// eslint-disable-next-line no-restricted-imports
import Bugsnag, { BreadcrumbType, OnErrorCallback } from '@bugsnag/react-native'
import BugsnagPluginReactNavigation, {
  BugsnagPluginReactNavigationResult,
} from '@bugsnag/plugin-react-navigation'
import { NavigationContainer } from '@react-navigation/native'
import { Platform } from 'react-native'
import Config from 'react-native-config'
import { UserPrimaryInfo as User } from '@src/models/user.types'
import { App } from '@config'
import Codepush from '@src/config/codepush'

class BugsnagClient {
  configured: boolean
  user?: User
  admin?: User

  constructor() {
    this.configured = false
  }

  configure() {
    if (Config.ENV !== 'development') {
      if (Platform.OS === 'ios' || Platform.OS === 'android') {
        this.configured = true
        Bugsnag.start({
          codeBundleId: Codepush.useCodeBundleId
            ? `${App.version}-${Codepush.revisionSuffix}`
            : undefined,
          plugins: [new BugsnagPluginReactNavigation()],
          onError: (event) => {
            if (this.user) {
              event.setUser(this.user.id, this.user.email, this.user.name)
            }
            if (this.admin) {
              event.addMetadata('admin', this.admin)
            }
          },
        })
      }
    }
  }

  setUser(user?: User, admin?: User) {
    this.user = user
    this.admin = admin
    if (!this.configured) {
      this.configure()
    }
    if (!this.configured) {
      return
    }

    if (user) {
      const { id, name, email } = user
      Bugsnag.setUser(id, email, name)
    } else {
      Bugsnag.setUser()
    }

    if (admin) {
      Bugsnag.addMetadata('admin', admin)
    } else {
      Bugsnag.clearMetadata('admin')
    }
  }

  reset() {
    if (!this.configured) {
      return
    }

    Bugsnag.setUser()
    Bugsnag.clearMetadata('admin')
  }

  leaveBreadcrumb(message: string, metadata = {}, type: BreadcrumbType = 'request') {
    if (!this.configured) {
      return
    }

    Bugsnag.leaveBreadcrumb(message, metadata, type)
  }

  notify(error: Error | string, onError?: OnErrorCallback) {
    if (!this.configured) {
      return
    }

    if (error && error instanceof Error) {
      Bugsnag.notify(error, onError)
    } else {
      Bugsnag.notify(new Error(inspect(error)), onError)
    }
  }

  createNavigationContainer(navigationContainer = NavigationContainer) {
    if (!this.configured) {
      this.configure()
    }
    if (!this.configured) {
      return navigationContainer
    }

    const { createNavigationContainer } = Bugsnag.getPlugin(
      'reactNavigation',
    ) as BugsnagPluginReactNavigationResult

    // The returned BugsnagNavigationContainer has exactly the same usage
    // except now it tracks route information to send with your error reports
    const BugsnagNavigationContainer = createNavigationContainer(navigationContainer)
    return BugsnagNavigationContainer
  }
}

export default new BugsnagClient()
