import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { Address } from '@src/types'

export enum AddressType {
  Shipping = 'Shipping',
  Billing = 'Billing',
}

export interface ShippingProps {
  address?: Pick<Address, 'name' | 'street' | 'street2' | 'city' | 'state' | 'zipCode'> | null
  addressType: AddressType
  onEditAddressPress: () => void
}

export const formatAddress = (
  address: Pick<Address, 'name' | 'street' | 'street2' | 'city' | 'state' | 'zipCode'>,
) => {
  const { name, street: street1, street2, city, state, zipCode } = address
  const street = !street2 ? street1 : `${street1} ${street2}`
  return `${name}, ${street}, ${city}, ${state ? `${state} ` : ''}${zipCode}`
}

export const AddressForm = (props: ShippingProps) => {
  const styles = useStyleSheet(themedStyle)
  const { address, onEditAddressPress, addressType = AddressType.Shipping } = props

  const getTitle = () => {
    if (address) {
      if (addressType === AddressType.Shipping) {
        return 'Shipping Address'
      }

      return 'Billing Address'
    }

    if (addressType === AddressType.Shipping) {
      return 'Add shipping address'
    }

    return 'Add billing address'
  }

  const title = getTitle()

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text type="small" bold lineSpacing="none" style={styles.title}>
          {title}
        </Text>
        {!address && (
          <Button
            accessibilityLabel="Add Address"
            accessoryRight={<Icon name="arrow-right" />}
            style={styles.addButton}
            size="small"
            type="primary"
            onPress={onEditAddressPress}
          >
            Add
          </Button>
        )}
      </View>
      {!!address && (
        <TouchableOpacity
          accessibilityLabel={title}
          activeOpacity={0.7}
          style={styles.cardInfoRow}
          onPress={onEditAddressPress}
        >
          <Text type="regular" style={styles.address}>
            {formatAddress(address)}
          </Text>
          <Text type="regular" bold style={styles.actionText}>
            Change
          </Text>
          <Icon name="caret-right" style={styles.icon} />
        </TouchableOpacity>
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    paddingHorizontal: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: 24,
    marginBottom: 16,
    textTransform: 'uppercase',
    letterSpacing: 0.3,
  },
  addButton: {
    marginVertical: 16,
  },
  icon: {
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  address: {
    flex: 1,
  },
  cardInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  actionText: {
    marginHorizontal: 16,
    color: 'theme.text.link',
  },
})
