import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Button, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  ApprovalFlowStep,
  useApprovalFlow,
} from '@src/screens/SignUp/components/Approvals/useApprovalFlow'
import { InlineAlert } from '@src/components'
import { UiInlineAlert } from '@src/types'

interface UpsellCGMBannerProps {
  header?: any
  image: React.ReactElement
}

export const UpsellCGMBanner = ({ header, image }: UpsellCGMBannerProps) => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const { approvalStep } = useApprovalFlow()

  if (approvalStep === ApprovalFlowStep.Rejected) {
    return null
  }
  if (approvalStep === ApprovalFlowStep.PendingApproval) {
    return (
      <InlineAlert
        category={UiInlineAlert.Warning}
        message="Your approval for CGM order is pending. We will check and confirm it within 2 working days."
        style={styles.alert}
      />
    )
  }

  return (
    <>
      {header}
      <View style={styles.container}>
        {image}
        <View style={styles.content}>
          <Text style={styles.upsellCopy} type="regular">
            Purchase a CGM to track your body's glucose response to food and exercise. Get access to
            superior health insights.
          </Text>
          <Button
            onPress={() => {
              navigation.navigate('NSSubscriptions')
            }}
            accessibilityLabel="Upsell"
            type="primary"
            size="small"
          >
            Buy Now
          </Button>
        </View>
      </View>
    </>
  )
}

const themedStyles = StyleService.create({
  container: {
    marginBottom: 8,
  },
  content: {
    position: 'absolute',
    bottom: '30%',
    left: 0,
    width: '100%',
    paddingHorizontal: 16,
  },
  upsellCopy: {
    paddingVertical: 8,
    textAlign: 'center',
  },
  alert: {
    marginHorizontal: 16,
    marginTop: 16,
    marginBottom: 16,
  },
})
