import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

interface ValueWithLabelProps {
  label: string
  value: string
}

export const ValueWithLabel = ({ label, value }: ValueWithLabelProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <Text type="small" lineSpacing="none" style={styles.label}>
        {label}
      </Text>
      <Text type="regular" lineSpacing="none">
        {value}
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  label: {
    marginBottom: 8,
    color: 'theme.text.secondary',
  },
})
