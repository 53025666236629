import { Platform, ScaledSize, useWindowDimensions } from 'react-native'

const isIos = Platform.OS === 'ios'
const isAndroid = Platform.OS === 'android'
const isWeb = Platform.OS === 'web'

const hasLargeScreen = (dimensions: ScaledSize) => dimensions.width >= 800
const hasSmallScreen = (dimensions: ScaledSize) => dimensions.width < 360
const shouldLockToPortrait = (dimensions: ScaledSize) =>
  dimensions.width < 500 || dimensions.height < 500

export const useHasLargeScreen = () => {
  const dimensions = useWindowDimensions()
  return hasLargeScreen(dimensions)
}

export const useHasSmallScreen = () => {
  const dimensions = useWindowDimensions()
  return hasSmallScreen(dimensions)
}

export const useShouldLockToPortrait = () => {
  const dimensions = useWindowDimensions()
  return shouldLockToPortrait(dimensions)
}

const device = {
  platform: Platform.OS,
  ios: isIos,
  android: isAndroid,
  web: isWeb,
  hasHealthKit: isIos,
  hasNFCEvents: isAndroid,
  hasLargeScreen,
  hasSmallScreen,
  shouldLockToPortrait,
}

export default device
