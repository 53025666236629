import React, { ReactElement } from 'react'
import { Choice } from './Choice'
import { ChoiceAccessory, ChoiceItem } from './Choice.types'

interface ChoiceListProps<Item extends ChoiceItem> {
  items: Item[]
  selectedItemId?: string | null
  onPress: (option: Item) => void
}

export const ChoiceList: <Item extends ChoiceItem>(
  props: ChoiceListProps<Item>,
) => ReactElement = ({ items, selectedItemId, onPress }) => {
  return (
    <>
      {items.map((item) => (
        <Choice
          key={item.id}
          content={item.body}
          tag={item.tag}
          accessory={
            selectedItemId === item.id ? ChoiceAccessory.Selected : ChoiceAccessory.Default
          }
          onPress={() => onPress(item)}
        />
      ))}
    </>
  )
}
