import React from 'react'
import { useDispatch } from 'react-redux'
import { GestureDismissibleAlertModal } from '@components'
import { useGoBack } from '@utils/navigation'
import { useSnack } from '@utils'
import { useSubscriptionFromRoute } from '../../utils/hooks'

export const ReactivateSubscription = () => {
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const goBack = useGoBack()
  const { subscription } = useSubscriptionFromRoute()

  if (!subscription) {
    return null
  }

  const handleReactivate = () => {
    dispatch({
      type: 'marketplace/uncancelSubscription',
      payload: { id: subscription.id },
      success: () => {
        goBack()
      },
      failure: (error: any) => {
        showSnack(error.message, null, 'error')
      },
    })
  }

  return (
    <GestureDismissibleAlertModal
      title="Reactivate Subscription"
      description="You will be charged on your next billing date."
      actionTitle="Reactivate"
      dismissButtonText="Cancel"
      onActionPress={handleReactivate}
    />
  )
}
