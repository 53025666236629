import React, { useContext } from 'react'
import { RouteProp, useRoute } from '@react-navigation/native'
import { Image, ImageStyle, ScrollView, View } from 'react-native'
import { SafeAreaView, useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { Feature, NavigationBar, useFeatureFlag } from '@components'
import { QuestionnaireScreenName, QuestionnaireStackParamList } from '@src/navigation/types'
import { useIsDarkMode } from '@src/config/theme'
import { IconSources } from '@src/assets/icons'
import { goalsQuestionnaireGroupImage } from '@src/assets/images'
import { QuestionnaireContext } from '../questionnaire.navigator'

export enum ProgramType {
  NutritionCoaching = 'NutritionCoaching',
  CGM = 'CGM',
}

export const GoalsQuestionnaireStartScreen = () => {
  const { startScreenConfig: config } = useContext(QuestionnaireContext)
  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)
  const isDarkMode = useIsDarkMode()
  const legacyStyles = useStyleSheet(legacyThemedStyles)
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()

  const { params } = useRoute<
    RouteProp<QuestionnaireStackParamList, QuestionnaireScreenName.GoalsQuestionnaireStart>
  >()

  const programTitle =
    params?.programType === ProgramType.NutritionCoaching
      ? 'Nutrition Coaching'
      : 'the Nutrisense CGM'

  const legacyContent = (
    <SafeAreaView style={legacyStyles.topLevelContainer} edges={['bottom']}>
      <NavigationBar
        title="Welcome to Nutrisense!"
        leftAccessory={{
          iconDescriptor: { pack: 'eva', name: 'close' },
          onPress: config.onCloseButtonPress,
        }}
      />
      <View style={legacyStyles.container}>
        <ScrollView bounces={false}>
          <Icon name="hand-waving" style={legacyStyles.wavingHand} />
          <Text type="title-2" style={legacyStyles.title}>
            Let's get you started!
          </Text>
          <Text type="regular" style={legacyStyles.description}>
            Welcome to {programTitle} Program! Please tell us more about your goals.
          </Text>
          <View style={legacyStyles.clause}>
            <Image source={IconSources.healthcare} style={legacyStyles.clauseImage as ImageStyle} />
            <Text type="regular" style={legacyStyles.clauseText}>
              Help us better understand you and your health goals, so we can personalize your
              experience.
            </Text>
          </View>
          <View style={legacyStyles.clause}>
            <Image
              source={isDarkMode ? IconSources.hourglassDark : IconSources.hourglass}
              style={legacyStyles.clauseImage as ImageStyle}
            />
            <Text type="regular" style={legacyStyles.clauseText}>
              It should not take longer than 15 minutes to complete this.
            </Text>
          </View>
        </ScrollView>
        <Button
          accessibilityLabel="Start questionnaire"
          type="primary"
          size="block"
          onPress={config.onStartButtonPress}
        >
          Start Questionnaire
        </Button>
      </View>
    </SafeAreaView>
  )
  const content = (
    <View style={styles.topLevelContainer}>
      <NavigationBar
        title="Goals Questionnaire"
        leftAccessory={{
          iconDescriptor: { pack: 'eva', name: 'arrow-ios-back' },
          onPress: config.onCloseButtonPress,
        }}
      />
      <ScrollView contentContainerStyle={styles.container} bounces={false}>
        <Text type="title-1" style={styles.title}>
          We’d love to hear about your goals!
        </Text>
        <Text type="regular">
          Tell us about your lifestyle, goals, and preferences to help your nutritionist support you
          best and customize your app experience.
        </Text>
        <Image
          source={goalsQuestionnaireGroupImage.imageSource}
          style={styles.image as ImageStyle}
        />
      </ScrollView>
      <Button
        type="primary"
        size="block"
        onPress={config.onStartButtonPress}
        accessibilityLabel="saveButton"
        style={[styles.button, { marginBottom: insets.bottom + 32 }]}
      >
        Get Started
      </Button>
    </View>
  )

  return appNavigationUpdatesFeatureEnabled ? content : legacyContent
}

const legacyThemedStyles = StyleService.create({
  topLevelContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  container: {
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: 48,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  wavingHand: {
    alignSelf: 'center',
    width: 64,
    height: 64,
    marginBottom: 24,
    color: 'theme.info.base',
  },
  title: {
    marginBottom: 16,
    textAlign: 'center',
  },
  description: {
    marginBottom: 32,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  clause: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 32,
  },
  clauseImage: {
    marginRight: 16,
    width: 40,
    height: 40,
  },
  clauseText: {
    flex: 1,
  },
})

const themedStyles = StyleService.create({
  topLevelContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  container: {
    marginTop: 16,
    paddingHorizontal: 16,
  },
  title: {
    textAlign: 'left',
    marginBottom: 8,
  },
  image: {
    marginTop: 49,
    marginBottom: 104,
    aspectRatio: 765 / 699,
    alignSelf: 'center',
    height: undefined,
    width: 255,
    maxHeight: 233,
  },
  button: {
    marginHorizontal: 16,
  },
})
