import moment from 'moment'
import { MarkedDates } from 'react-native-calendars/src/types'
import { DATE_FORMAT } from '@src/config/momentFormat'

export const setupSelectedDates = (
  fromDate: string,
  toDate: string,
  markedDates: MarkedDates,
  colorConfig: {
    color: string
    textColor: string
  },
): MarkedDates => {
  const range = moment(toDate).diff(moment(fromDate), 'days')

  if (range < 0) {
    return markedDates
  }

  if (range === 0) {
    return {
      [toDate]: {
        selected: true,
        startingDay: true,
        endingDay: true,
        ...colorConfig,
      },
    }
  }

  const newMarkedDates = { ...markedDates }

  for (let i = 1; i <= range; i++) {
    const tempDate = moment(fromDate).add(i, 'days').format(DATE_FORMAT)
    if (i < range) {
      newMarkedDates[tempDate] = {
        selected: true,
        ...colorConfig,
      }
    } else {
      newMarkedDates[tempDate] = {
        endingDay: true,
        selected: true,
        ...colorConfig,
      }
    }
  }

  return newMarkedDates
}
