import React, { useRef, useState } from 'react'
import { Keyboard, TouchableOpacity } from 'react-native'
import { RouteProp, useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Input, InputRef } from '@components/base'
import { FadeFromBottomModal } from '@components'
import { useGoBack } from '@src/utils/navigation'
import { useSnack } from '@src/utils/navigatorContext'
import { CommonAuthorizedStackParamList } from '@src/navigation/types'
import { useActivateDiscount } from '@screens/Marketplace/utils/hooks'

const DiscountCodeModal = () => {
  const styles = useStyleSheet(themedStyle)
  const showSnack = useSnack()
  const activateDiscount = useActivateDiscount()
  const goBack = useGoBack()

  const { params } = useRoute<RouteProp<CommonAuthorizedStackParamList, 'DiscountCode'>>()
  const { product } = params
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const inputRef = useRef<InputRef>(null)
  const codeInvalid = !code.trim() || !!error

  const inputAccessory = code ? (
    <TouchableOpacity
      accessibilityLabel="Clear"
      onPress={() => {
        setCode('')
        setError('')
        inputRef.current?.focus()
      }}
    >
      <Icon name="x" />
    </TouchableOpacity>
  ) : null

  const onCodeActivate = () => {
    if (codeInvalid) {
      inputRef.current?.focus()
      return
    }

    Keyboard.dismiss()

    activateDiscount({
      code,
      product,
      success: ({ promotionCode }) => {
        if (!promotionCode) {
          setError('Code is invalid')
        }

        if (!promotionCode.applicable) {
          showSnack('Code is not applicable', null, 'error')
        }
        goBack()
      },
      failure: (error: any) => {
        showSnack(error?.message || 'Server error!', null, 'error')
      },
    })
  }

  const onChangeText = (text: string) => {
    setCode(text)
    setError('')
  }

  return (
    <FadeFromBottomModal title="Enter discount code">
      <Input
        accessoryRight={inputAccessory}
        autoCapitalize="characters"
        errorText="Code is invalid"
        hasError={!!error}
        label="Enter code"
        onChangeText={onChangeText}
        placeholder="Enter code here"
        ref={inputRef}
        style={styles.input}
        value={code}
      />
      <Button
        accessibilityLabel="Activate"
        allowPressWhenDisabled
        disabled={codeInvalid}
        onPress={onCodeActivate}
        size="block"
        type="primary"
      >
        Submit
      </Button>
    </FadeFromBottomModal>
  )
}

export default DiscountCodeModal

const themedStyle = StyleService.create({
  input: {
    height: 104,
    marginVertical: 16,
  },
})
