import * as React from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

const EmojiLevel5 = (props: SvgProps) => {
  return (
    <Svg viewBox="0 0 40 40" fill="none" {...props}>
      <Path
        d="M20 38c9.941 0 18-8.059 18-18S29.941 2 20 2 2 10.059 2 20s8.059 18 18 18z"
        fill="#C2D0AE"
        stroke="#11494F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        // eslint-disable-next-line max-len
        d="M23 16a3.788 3.788 0 011.27-1.464A3.113 3.113 0 0126 14c.608 0 1.204.185 1.73.536.527.35.965.856 1.27 1.464M11 16a3.788 3.788 0 011.27-1.464A3.113 3.113 0 0114 14c.607 0 1.204.185 1.73.536.527.35.965.856 1.27 1.464M27.8 23.5a9.019 9.019 0 01-15.6 0"
        stroke="#11494F"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default EmojiLevel5
