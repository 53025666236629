import { Model } from '@models'
import { unlessEventHasSelectedDate } from '@src/models/utils'
import { JournalEntry } from '@src/types'
import CREATE_JOURNAL_ENTRY from '../graphql/createJournalEntry'
import DELETE_JOURNAL_ENTRY from '../graphql/deleteJournalEntry'
import UPDATE_JOURNAL_ENTRY from '../graphql/updateJournalEntry'

const journalEntriesCacheClearPayload = (response: JournalEntry) => ({
  includeDates: [response.occurredAt],
})

export default class JournalEntries {
  namespace = 'journalEntries'

  state = {
    ...Model.defaultState,
  }

  effects = {
    create: Model.buildEffect({
      name: `${this.namespace}/create`,
      optimistic: true,
      query: CREATE_JOURNAL_ENTRY,
      dataPath: 'createJournalEntry',
      optimisticReducers: [
        { name: 'events/backup' },
        { name: 'history/backup', storePath: 'history' },
        { name: 'events/appendOrReplaceList', unless: unlessEventHasSelectedDate },
        { name: 'history/appendOrReplaceList', storePath: 'history' },
      ],
      reducers: [
        {
          name: 'events/appendOrReplaceList',
          replaceFake: true,
          unless: unlessEventHasSelectedDate,
        },
        {
          name: 'history/appendOrReplaceList',
          replaceFake: true,
          storePath: 'history',
        },
      ],
      errorReducers: [
        { name: 'events/restore' },
        { name: 'history/restore', storePath: 'history' },
      ],
      cacheReducers: [
        { name: 'history/cacheClear', payload: { matchName: 'history/fetch' } },
        {
          name: 'events/cacheClear',
          payload: journalEntriesCacheClearPayload,
        },
        {
          name: 'insights/cacheClear',
          payload: journalEntriesCacheClearPayload,
        },
      ],
    }),
    delete: Model.buildEffect({
      name: `${this.namespace}/delete`,
      optimistic: true,
      query: DELETE_JOURNAL_ENTRY,
      dataPath: 'deleteJournalEntry',
      variables: ({ id }: JournalEntry) => ({ id }),
      optimisticReducers: [
        { name: 'events/backup' },
        { name: 'history/backup', storePath: 'history' },
        { name: 'events/deleteList' },
        { name: 'history/deleteList', storePath: 'history' },
      ],
      errorReducers: [
        { name: 'events/restore' },
        { name: 'history/restore', storePath: 'history' },
      ],
      cacheReducers: [
        { name: 'history/cacheClear', payload: { matchName: 'history/fetch' } },
        {
          name: 'events/cacheClear',
          payload: journalEntriesCacheClearPayload,
        },
        {
          name: 'insights/cacheClear',
          payload: journalEntriesCacheClearPayload,
        },
      ],
    }),
    update: Model.buildEffect({
      name: `${this.namespace}/update`,
      optimistic: true,
      query: UPDATE_JOURNAL_ENTRY,
      dataPath: 'updateJournalEntry',
      optimisticReducers: [
        { name: 'events/backup' },
        { name: 'history/backup', storePath: 'history' },
        { name: 'events/appendOrReplaceList', unless: unlessEventHasSelectedDate },
        { name: 'history/appendOrReplaceList', storePath: 'history' },
      ],
      reducers: [
        {
          name: 'events/appendOrReplaceList',
          replaceFake: true,
          unless: unlessEventHasSelectedDate,
        },
        {
          name: 'history/appendOrReplaceList',
          replaceFake: true,
          storePath: 'history',
        },
      ],
      errorReducers: [
        { name: 'events/restore' },
        { name: 'history/restore', storePath: 'history' },
      ],
      cacheReducers: [
        { name: 'history/cacheClear', payload: { matchName: 'history/fetch' } },
        {
          name: 'events/cacheClear',
          payload: journalEntriesCacheClearPayload,
        },
        {
          name: 'insights/cacheClear',
          payload: journalEntriesCacheClearPayload,
        },
      ],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
  }
}
