import React from 'react'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Section } from '@src/components'

interface SettingsSectionProps {
  title: string
  children: React.ReactNode
}

const SettingsSection = ({ title, children }: SettingsSectionProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <>
      <Section title={title} style={styles.section} />
      {children}
    </>
  )
}

const themedStyles = StyleService.create({
  section: {
    height: 48,
    backgroundColor: 'theme.surface.base2',
  },
})

export default SettingsSection
