import { gql } from '@apollo/client'

export const ALL_NOTES = gql`
  query allNotes($sorts: [NoteSort!], $dynamicFilters: [NoteDynamicFilterItem!]) {
    allNotes(sorts: $sorts, dynamicFilters: $dynamicFilters) {
      notes {
        id
        body
        kind
        occurredAt
        updatedAt
        pinnedAt
        creator {
          id
          firstName
          lastName
          coachProfile {
            photo
            credentials
          }
        }
      }
    }
  }
`
