import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { AsYouType } from 'libphonenumber-js'
import { FieldValues, useForm } from 'react-hook-form'
import { useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  authenticatedUserSelector,
  usStatesSelector,
  caProvincesSelector,
} from '@src/selectors/app'
import { useGoBack, useDispatchAsync } from '@src/utils'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Place } from '@src/services/GooglePlaces/GooglePlaces'
import { TransparentNavigationBar } from '@src/components/navigationBar/TransparentNavigationBar'
import { Address, AddressCountries } from '@src/types'
import { CommonAuthorizedRouteProps } from '@src/navigation/types'
import { AddressType } from '@src/screens/Marketplace/components/checkout/AddressForm'
import { getCountryCodeByCountry } from '@src/utils/phoneInput'
import { useAddress } from '@src/hooks/useAddress'
import { AddressModal as AddressModalComponent } from '../components/AddressModal'

const RESET_FIELDS_ON_COUNTRY_CHANGE = ['state', 'zipCode', 'street', 'street2'] as const

interface AddressFormData extends FieldValues {
  country: AddressCountries
  name: string
  street: string
  street2: string
  city: string
  state: string
  zipCode: string
  phoneNumber: string
}

export const AddressModalContainer = () => {
  const dispatch = useDispatchAsync()
  const user = useSelector(authenticatedUserSelector)
  const usStates = useSelector(usStatesSelector)
  const caProvinces = useSelector(caProvincesSelector)

  const route = useRoute<CommonAuthorizedRouteProps<'ChangeAddress'>>()

  const addressType = route.params?.addressType || AddressType.Shipping

  const address = (user.address || {}) as Partial<Address>
  const requirePhoneNumber = !user.phoneNumber

  const styles = useStyleSheet(themedStyle)
  const goBack = useGoBack()

  const defaultCountry = (address.country as AddressCountries | undefined) || AddressCountries.Us

  const defaultValues: AddressFormData = {
    country: defaultCountry,
    name: address.name || `${user.firstName} ${user.lastName}`,
    street: address.street || '',
    street2: address.street2 || '',
    city: address.city || '',
    state: address.state || '',
    zipCode: address.zipCode || '',
    phoneNumber: new AsYouType(getCountryCodeByCountry(defaultCountry)).input(
      user.phoneNumber || '',
    ),
  }

  const didMount = useRef(false)

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { isValid },
    watch,
  } = useForm({ mode: 'onTouched', defaultValues })

  const street = watch('street')
  const country = watch('country')
  const state = watch('state')
  const phoneNumber = watch('phoneNumber')

  const states = (country === AddressCountries.Us ? usStates : caProvinces).map((item) => ({
    text: item.abbreviation,
    value: item.abbreviation,
  }))

  const { placesMatchingSearch, debounceFetchPlaces, fetchAddressForPlace } = useAddress()

  useEffect(() => {
    debounceFetchPlaces.current(street, country)
  }, [street, country, debounceFetchPlaces])

  useEffect(() => {
    if (didMount.current) {
      RESET_FIELDS_ON_COUNTRY_CHANGE.forEach((field) => {
        setValue(field, '')
      })
    }
    didMount.current = true
  }, [country, setValue])

  const loadAddressData = async (place: Place) => {
    const address = await fetchAddressForPlace(place, country)

    clearErrors(['street', 'city', 'state', 'zipCode'])

    setValue('street', address.street, { shouldValidate: true, shouldTouch: true })
    setValue('street2', '')
    setValue('city', address.city, { shouldValidate: true, shouldTouch: true })
    setValue('state', address.state, { shouldValidate: true, shouldTouch: true })
    setValue('zipCode', address.zipCode, {
      shouldValidate: true,
      shouldTouch: true,
    })
  }

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async ({ phoneNumber, ...address }: AddressFormData) => {
    if (isSubmitting) {
      return
    }

    const stateFromServer = states.find((item) => item.text.toLowerCase() === state.toLowerCase())

    setIsSubmitting(true)
    try {
      await dispatch({
        type: 'address/update',
        payload: {
          ...address,
          state: stateFromServer?.value,
        },
      })

      if (requirePhoneNumber || phoneNumber) {
        await dispatch({ type: 'users/update', payload: { phoneNumber } })
      }
      goBack()
    } finally {
      setIsSubmitting(false)
    }
  }

  const title = addressType === AddressType.Shipping ? 'Shipping Address' : 'Billing Address'

  const allowCountryChange =
    defaultValues.country !== AddressCountries.Us || addressType === AddressType.Billing

  return (
    <NavigationContainer
      title={title}
      goBack={goBack}
      NavigationBarComponent={TransparentNavigationBar}
      style={styles.container}
    >
      <AddressModalComponent
        onSubmit={handleSubmit(onSubmit)}
        control={control}
        placesMatchingSearch={placesMatchingSearch}
        loadAddressData={loadAddressData}
        country={country}
        state={state}
        isValid={isValid}
        states={states}
        allowCountryChange={allowCountryChange}
        phoneNumber={phoneNumber}
        showPhoneNumber={requirePhoneNumber}
        showShippingAddressAlert={addressType === AddressType.Shipping}
      />
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})
