const BASE_REQUEST_URL = 'https://support.nutrisense.io/hc/en-us/requests/new'

const FIELDS = {
  SUBJECT: 'tf_subject',
  DESCRIPTION: 'tf_description',
  EMAIL: 'tf_anonymous_requester_email',
  TICKET_TYPE: 'tf_1500014715561',
}

const buildUrl = ({
  subject,
  description,
  ticketType,
}: {
  subject?: string
  description?: string
  ticketType?: string
} = {}) => (email?: string) => {
  const url = new URL(BASE_REQUEST_URL)

  if (subject) {
    url.searchParams.append(FIELDS.SUBJECT, subject)
  }

  if (description) {
    url.searchParams.append(FIELDS.DESCRIPTION, description)
  }

  if (ticketType) {
    url.searchParams.append(FIELDS.TICKET_TYPE, ticketType)
  }

  if (email) {
    url.searchParams.append(FIELDS.EMAIL, email)
  }

  return url.href.replace(/\+/g, '%20') // encode plus sign
}

const pauseProgramUrl = buildUrl({
  subject: 'Snooze subscription',
  description: 'Hi there, please help me snooze my subscription.',
  ticketType: 'snooze_ticket_type',
})

const changeProgramUrl = buildUrl({
  subject: 'Change subscription',
  description: 'Hi there, please help me change my subscription.',
  ticketType: 'change_subscription_ticket_type',
})

const cancelProgramUrl = buildUrl({
  subject: 'Cancel my subscription early',
  description: 'Hi there, please help me cancel my subscription.',
  ticketType: 'cancel_ticket_type',
})

export default {
  baseUrl: buildUrl(),
  pauseProgramUrl,
  changeProgramUrl,
  cancelProgramUrl,
}
