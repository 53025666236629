import React from 'react'
import { ScrollView, TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { BaseIngredient } from '@screens/Ingredients/types'
import { ingredientsAreEqual } from '@screens/Ingredients/utils'

const MAX_SUGGESTIONS = 10

export interface IngredientsPickerProps {
  suggestions?: BaseIngredient[]
  ingredients: BaseIngredient[]
}

export const SuggestionsPicker = ({ suggestions = [], ingredients }: IngredientsPickerProps) => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()

  const filteredSuggestions = suggestions.filter((suggestion) => {
    return !ingredients.some((item) => ingredientsAreEqual(item, suggestion))
  })
  const topTenSuggestions = filteredSuggestions.slice(0, MAX_SUGGESTIONS)

  const onIngredientPress = (ingredient: BaseIngredient) => {
    dispatch({
      type: 'meals/addDraftIngredient',
      payload: ingredient,
    })
  }

  if (filteredSuggestions.length === 0) {
    return null
  }

  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false} style={styles.content}>
      {topTenSuggestions.map((item) => (
        <TouchableOpacity
          key={item.id}
          accessibilityLabel={item.description}
          onPress={() => onIngredientPress(item)}
          style={styles.itemContainer}
        >
          <Icon name="plus" style={styles.itemIcon} />
          <Text type="regular">{item.description}</Text>
          <Text type="tiny" style={styles.itemServing}>
            {`${item.servingAmount} ${item.servingUnits}`}
          </Text>
        </TouchableOpacity>
      ))}
    </ScrollView>
  )
}

const themedStyle = StyleService.create({
  content: {
    flexGrow: 0,
    marginTop: 24,
    paddingHorizontal: 16,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
    marginRight: 8,
    paddingHorizontal: 16,
    borderRadius: 20,
    borderWidth: 1,
    borderColor: 'theme.surface.base1',
  },
  itemServing: {
    marginLeft: 8,
    color: 'theme.text.tertiary',
  },
  itemIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
    color: 'theme.primary.base',
  },
})
