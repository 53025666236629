import React, { useCallback } from 'react'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { TouchableOpacity, View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ProgressCircle } from '@src/components'
import { onboardingChecklistItemsSelector } from '@src/screens/Checklist/models/checklist.selectors'

export const OnboardingChecklist = () => {
  const checklistItems = useSelector(onboardingChecklistItemsSelector)

  const dispatch = useDispatch()

  const navigation = useNavigation()

  const styles = useStyleSheet(themedStyles)

  const theme = useTheme()

  useFocusEffect(
    useCallback(() => {
      dispatch({ type: 'checklist/fetchOnboardingItems' })
    }, [dispatch]),
  )

  const completeItems = checklistItems.filter((item) => item.completed)

  const completeItemsCount = completeItems.length
  const itemsCount = checklistItems.length

  if (itemsCount === 0) {
    return null
  }

  const allItemsCompleted = itemsCount > 0 && itemsCount === completeItemsCount

  const onPress = () => {
    navigation.navigate('OnboardingChecklist')
  }

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={onPress}
      accessibilityLabel="Onboarding Checklist"
    >
      <View style={styles.progressCircleContainer}>
        <ProgressCircle
          style={styles.progressCircle}
          progress={completeItemsCount / itemsCount}
          progressColor={
            allItemsCompleted ? theme['theme.success.base'] : theme['theme.warning.base']
          }
          backgroundColor={
            allItemsCompleted ? theme['theme.success.lightest'] : `${theme['theme.warning.base']}40` // add 0.25 opacity
          }
          strokeWidth={8}
        />
        <Text style={styles.progressText} type="small">
          {completeItemsCount}/{itemsCount}
        </Text>
      </View>
      <View style={styles.textContainer}>
        <Text type="regular" bold>
          Your Checklist
        </Text>
        <Text type="regular">
          {allItemsCompleted ? 'All tasks are completed' : `Complete your remaining tasks!`}
        </Text>
      </View>
      <Icon name="caret-right" size={16} weight="bold" style={styles.icon} />
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    padding: 16,
    flexDirection: 'row',
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
    borderRadius: 16,
    marginHorizontal: 16,
    alignItems: 'center',
    marginBottom: 8,
  },
  progressCircleContainer: {
    width: 54,
    height: 54,
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressCircle: {
    width: '100%',
    height: '100%',
  },
  progressText: {
    position: 'absolute',
    color: 'theme.text.secondary',
  },
  textContainer: {
    marginLeft: 16,
    flex: 1,
  },
  icon: {
    color: 'theme.surface.base0',
  },
})
