import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService } from '@src/style/service'
import { ScoreArcIndicator as ScoreArcIndicatorBase } from '@src/components'
import { statsSelector } from '@src/screens/Insights/models/insights.selectors'

interface ScoreArcIndicatorProps {
  scoreKey: string
  title: string
}

export const ScoreArcIndicator = ({ scoreKey, title }: ScoreArcIndicatorProps) => {
  const stats = useSelector(statsSelector)
  const displayedStat = stats.find((stat) => stat.meta.key === scoreKey)

  if (!displayedStat) {
    return null
  }

  return (
    <View style={styles.container}>
      <ScoreArcIndicatorBase title={title} value={displayedStat.value || undefined} />
    </View>
  )
}

const styles = StyleService.create({
  container: {
    marginTop: 16,
    marginBottom: 20,
  },
})
