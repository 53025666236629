import { gql } from '@apollo/client'

const ALL_CHARTS = gql`
  query allCharts($filter: DateFilter) {
    allCharts(filter: $filter) {
      charts {
        type
        title
        description
        xAxis
        yAxis
        range {
          min
          max
          goal
          goalMin
          goalMax
          tickCount
        }
        meta {
          key
          tag
          section
        }
        values {
          ... on TimePair {
            x
            y
            interpolated
          }
          ... on NumericPair {
            x
            y
          }
          ... on StringPair {
            name
            x
            y
          }
          ... on RangePair {
            x {
              min
              max
            }
            y
          }
        }
      }
    }
  }
`

export default ALL_CHARTS
