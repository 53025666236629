import React from 'react'
import { View, ViewStyle } from 'react-native'
import { MenuItem, Popover, Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'

export interface TimelineHeaderProps {
  anchor: () => React.ReactElement
  items: string[]
  visible: boolean
  selectedIndex: number
  onSelectIndex: (index: number) => void
  onDismiss: () => void
  style?: ViewStyle
}

export const PopoverMenu = ({
  anchor,
  items,
  visible,
  selectedIndex,
  onSelectIndex,
  onDismiss,
  style,
}: TimelineHeaderProps) => {
  const styles = useStyleSheet(themedStyle)

  const selectedChartAccessory = () => (
    <Icon name="check-circle" weight="fill" style={styles.selectedIcon} />
  )

  return (
    <Popover
      anchor={anchor}
      visible={visible}
      onBackdropPress={onDismiss}
      style={[styles.popover, style]}
    >
      <View style={styles.container}>
        {items.map((title, index) => (
          <View key={title}>
            {index > 0 && <Divider />}
            <MenuItem
              title={() => (
                <Text type="small" bold={selectedIndex === index}>
                  {title}
                </Text>
              )}
              appearance="overflowMenu"
              style={styles.item}
              onPress={() => onSelectIndex(index)}
              accessoryRight={selectedIndex === index ? selectedChartAccessory : undefined}
            />
          </View>
        ))}
      </View>
    </Popover>
  )
}

const themedStyle = StyleService.create({
  popover: {
    borderWidth: 0,
    borderRadius: 8,
    zIndex: 10,
    overflow: 'visible',
  },
  container: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    width: 150,
    overflow: 'hidden',
  },
  item: {
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  selectedItemText: {
    color: 'theme.primary.base',
  },
  selectedIcon: {
    height: 20,
    width: 20,
    color: 'theme.primary.base',
    backgroundColor: 'theme.solid.white',
    borderRadius: 10,
    borderWidth: 2,
    borderColor: 'theme.primary.base',
    overflow: 'hidden',
  },
})
