import { gql } from '@apollo/client'

const CREATE_HEALTH_METRIC = gql`
  mutation createHealthMetric(
    $kind: HealthDataMetricBiomarker!
    $value: Float!
    $date: ISO8601Date!
  ) {
    createHealthMetric(kind: $kind, value: $value, date: $date) {
      id
      value
      kind
      date
    }
  }
`

export default CREATE_HEALTH_METRIC
