import React, { useEffect, useState } from 'react'
import { View, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useQuery } from '@apollo/client'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Button, Text, IconName } from '@components/base'
import { AppStackParamList } from '@src/navigation/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import {
  InsuranceBerryStreetAppointmentSchedulingRule,
  InsuranceBerryStreetAppointmentStatus,
  InsuranceBerryStreetAppointmentType,
  Product,
} from '@src/types'
import { FETCH_VIDEO_CALL_INFO } from '@src/screens/NutritionistHub/graphql/fetchVideoCallInfo'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { Intercom, useGoBack } from '@src/utils'
import { LatestUserAppointmentQuery } from '@src/screens/NutritionistHub/graphql/fetchVideoCallInfo.generated'
import { ProductIcon } from '../types/types'

const iconNameForProduct = (product: Product): IconName | undefined => {
  switch (product.icon) {
    case ProductIcon.Question:
      return 'seal-question'
    case ProductIcon.Goals:
      return 'list-checks'
    case ProductIcon.Nutrition:
      return 'bowl-food'
    case ProductIcon.VideoCall:
      return 'video-camera'
    default:
      return
  }
}
const POLLING_INTERVAL = 3000 // 3 seconds
const TOTAL_POLLING_DURATION = 30 * 1000 // 30 seconds

export const DietitianAddonOrderConfirmed = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const goBack = useGoBack()
  const {
    params: { product, groupedProducts, goBackOnSuccess },
  } = useRoute<RouteProp<AppStackParamList, 'DietitianAddonOrderConfirmed'>>()

  const titleIcon = iconNameForProduct(product)

  const [appointmentLoadingError, setAppointmentLoadingError] = useState(false)

  const isSchedulingRequired =
    product.schedulingRule.key !== InsuranceBerryStreetAppointmentSchedulingRule.NoScheduling

  // If product has scheduling rule which requires scheduling we need to have pending appointment from backend.
  // Here we start polling to receive appointment once invoice is turned into paid state.
  const { data: latestAppointmentData, stopPolling } = useQuery<LatestUserAppointmentQuery>(
    FETCH_VIDEO_CALL_INFO,
    {
      skip: !isSchedulingRequired,
      pollInterval: POLLING_INTERVAL,
    },
  )
  const latestAppointment = latestAppointmentData?.latestUserAppointment
  const isPendingAppointmentPresent =
    latestAppointment?.status === InsuranceBerryStreetAppointmentStatus.SchedulingPending

  const handleDonePress = () => {
    if (goBackOnSuccess) {
      goBack()
      return
    }

    if (!isSchedulingRequired) {
      navigation.navigate('Subscriptions')
      return
    }

    const pendingAppointmentId = isPendingAppointmentPresent ? latestAppointment?.id : undefined
    navigation.navigate('CallScheduling', {
      appointmentType: InsuranceBerryStreetAppointmentType.NoneCovered,
      pendingAppointmentId,
      allowBackNavigation: true,
    })
  }

  const handleErrorPress = () => {
    Intercom.showIntercomMessenger({ source: 'DietitianAddonOrderConfirmed' })
    navigation.navigate('Dashboard')
  }

  // Set timeout for polling if we need to receive appointment to proceed
  useEffect(() => {
    if (!isSchedulingRequired) {
      return
    }
    if (isPendingAppointmentPresent) {
      return
    }

    const timeoutHandle = setTimeout(() => {
      stopPolling()
      if (!isPendingAppointmentPresent) {
        setAppointmentLoadingError(true)
      }
    }, TOTAL_POLLING_DURATION)

    return () => {
      stopPolling()
      clearTimeout(timeoutHandle)
    }
  }, [isPendingAppointmentPresent, isSchedulingRequired, stopPolling])

  // Once pending appointment is present we can stop polling and proceed
  useEffect(() => {
    if (isPendingAppointmentPresent) {
      stopPolling()
    }
  }, [isPendingAppointmentPresent, stopPolling])

  const { title, description, details } = groupedProducts ?? product

  if (isSchedulingRequired && !isPendingAppointmentPresent) {
    return (
      <LoadingContainer
        loadingMessage="Confirming your purchase"
        errorMessage={"Can't confirm your purchase"}
        error={appointmentLoadingError}
      >
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Continue"
          onPress={handleErrorPress}
        >
          Contact Support
        </Button>
      </LoadingContainer>
    )
  }

  return (
    <NavigationContainer title={title}>
      <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
        <SafeAreaView style={styles.container} edges={['bottom']}>
          <View style={styles.confirmationContainer}>
            <View>
              <Icon name="confetti" style={styles.congratsIcon} />
              <Text type="title-3" style={styles.congratsTitle}>
                Success!
              </Text>
              <Text type="large" bold style={styles.congratsSubtitle}>
                You have purchased a new nutrition coaching service:
              </Text>
            </View>
            <View style={styles.productContainer}>
              <View style={styles.productTitle}>
                {titleIcon && <Icon name={titleIcon} style={styles.productIcon} />}
                <Text type="large" bold style={styles.productName}>
                  {title}
                </Text>
              </View>
              <Text type="regular" style={styles.productDescription}>
                {description ?? ''}
              </Text>
            </View>
            {details && !isSchedulingRequired && (
              <View style={styles.nextStepsContainer}>
                <Text type="regular" bold style={styles.nextStepsTitle}>
                  WHAT'S NEXT?
                </Text>
                {details.map((detailsText) => (
                  <Text type="small" key={detailsText} style={styles.nextStepsDescription}>
                    {detailsText}
                  </Text>
                ))}
              </View>
            )}
          </View>
          <Button
            type="primary"
            size="block"
            accessibilityLabel="Done"
            style={styles.doneButton}
            onPress={handleDonePress}
          >
            {isSchedulingRequired ? 'Schedule call' : 'Done'}
          </Button>
        </SafeAreaView>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flexGrow: 1,
    flexShrink: 0,
    paddingHorizontal: 16,
  },
  confirmationContainer: {
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'center',
  },
  congratsIcon: {
    alignSelf: 'center',
    width: 48,
    height: 48,
  },
  congratsTitle: {
    marginTop: 24,
    textAlign: 'center',
  },
  congratsSubtitle: {
    marginTop: 16,
    textAlign: 'center',
  },
  productContainer: {
    marginTop: 16,
    padding: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'theme.primary.base',
    backgroundColor: 'theme.surface.base2',
  },
  productTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  productIcon: {
    marginRight: 8,
    width: 16,
    height: 16,
  },
  productName: {
    color: 'theme.primary.base',
  },
  productDescription: {
    marginTop: 4,
    color: 'theme.text.secondary',
  },
  nextStepsContainer: {
    marginTop: 32,
  },
  nextStepsTitle: {
    color: 'theme.primary.base',
  },
  nextStepsDescription: {
    marginTop: 2,
  },
  doneButton: {
    marginVertical: 16,
  },
})
