import React, { useCallback, useEffect } from 'react'
import { useFocusEffect } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollView, View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ProgressCircle } from '@src/components'
import { usePrevious } from '@src/utils/hooks'
import { Analytics, CustomEventTypes } from '@src/config'
import { onboardingChecklistItemsSelector } from '../models/checklist.selectors'
import { ChecklistItem } from './ChecklistItem'

export const OnboardingChecklist = () => {
  const checklistItems = useSelector(onboardingChecklistItemsSelector)

  const dispatch = useDispatch()

  const styles = useStyleSheet(themedStyles)

  const theme = useTheme()

  const insets = useSafeAreaInsets()

  useFocusEffect(
    useCallback(() => {
      dispatch({ type: 'checklist/fetchOnboardingItems' })
    }, [dispatch]),
  )

  const completeItems = checklistItems.filter((item) => item.completed)
  const incompleteItems = checklistItems.filter((item) => !item.completed)

  const completeItemsCount = completeItems.length
  const itemsCount = checklistItems.length

  const allItemsCompleted = itemsCount > 0 && itemsCount === completeItemsCount

  const previousCompleteItems = usePrevious(completeItems)

  const recentlyCompletedItems = completeItems.filter((item) => {
    return (
      previousCompleteItems && !previousCompleteItems.find((prevItem) => prevItem.key === item.key)
    )
  })

  useEffect(() => {
    recentlyCompletedItems.forEach((item) => {
      Analytics.track(CustomEventTypes.OnboardingChecklistItemCompleted, { key: item.key })
    })
  }, [recentlyCompletedItems])

  useEffect(() => {
    if (allItemsCompleted) {
      Analytics.track(CustomEventTypes.OnboardingChecklistCompleted)
    }
  }, [allItemsCompleted])

  const renderDescription = () => {
    if (itemsCount === 0) {
      return null
    }

    if (allItemsCompleted) {
      return (
        <View style={styles.descriptionContainer}>
          <Text type="regular" style={styles.completedDescription}>
            All tasks are completed
          </Text>
        </View>
      )
    }

    return (
      <View style={styles.descriptionContainer}>
        <Text type="large" bold>
          {completeItemsCount}
        </Text>
        <Text type="regular" style={styles.secondaryDescription}>
          /{itemsCount} tasks completed
        </Text>
      </View>
    )
  }

  const renderChecklistItem = (item: typeof checklistItems[0]) => (
    <ChecklistItem
      style={styles.checklistItem}
      key={item.key}
      title={item.title}
      completed={item.completed}
      deepLink={item.deepLink}
    />
  )

  return (
    <NavigationContainer
      title="Your Checklist"
      leftIcon={<Icon name="x" size="20" weight="bold" style={styles.closeIcon} />}
      showLoadingIndicator
    >
      <ScrollView
        style={styles.container}
        contentContainerStyle={[styles.contentContainer, { paddingBottom: insets.bottom + 16 }]}
        bounces={false}
      >
        <Text type="regular" style={styles.title}>
          {allItemsCompleted
            ? 'Well done! You don’t have any remaining tasks!'
            : 'Complete your remaining tasks'}
        </Text>
        {itemsCount > 0 && (
          <ProgressCircle
            style={styles.progressCircle}
            progress={completeItemsCount / itemsCount}
            progressColor={
              allItemsCompleted ? theme['theme.success.base'] : theme['theme.warning.base']
            }
            backgroundColor={
              allItemsCompleted
                ? theme['theme.success.lightest']
                : `${theme['theme.warning.base']}40` // add 0.25 opacity
            }
            strokeWidth={16}
          />
        )}
        {renderDescription()}
        {incompleteItems.map(renderChecklistItem)}
        {completeItems.length > 0 && !allItemsCompleted && (
          <View style={styles.dividerContainer}>
            <View style={styles.divider} />
            <Text type="small" style={styles.dividerText}>
              Completed
            </Text>
          </View>
        )}
        {completeItems.reverse().map(renderChecklistItem)}
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flexGrow: 1,
    padding: 16,
  },
  checklistItem: {
    marginBottom: 16,
  },
  title: {
    textAlign: 'center',
    marginBottom: 32,
    maxWidth: 260,
    alignSelf: 'center',
  },
  closeIcon: {
    marginLeft: 16,
  },
  descriptionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: 40,
  },
  secondaryDescription: {
    color: 'theme.text.tertiary',
  },
  completedDescription: {
    color: 'theme.text.secondary',
  },
  progressCircle: {
    width: 96,
    height: 96,
    alignSelf: 'center',
    marginBottom: 28,
  },
  dividerContainer: {
    marginTop: 8,
    marginBottom: 24,
    alignItems: 'center',
    justifyContent: 'center',
    height: 1,
    paddingHorizontal: 8,
  },
  dividerText: {
    color: 'theme.text.secondary',
    marginBottom: 16,
    position: 'absolute',
    backgroundColor: 'theme.background',
    paddingHorizontal: 2,
  },
  divider: {
    height: '100%',
    width: '100%',
    backgroundColor: 'theme.surface.base2',
  },
})
