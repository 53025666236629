import React, { useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { EditorBlock, EditorBlockType } from '../../lesson.types'
import { RichTextViewList, RichTextViewStyle } from './RichTextViewList'
import { RichTextViewImage } from './RichTextViewImage'

// TODO: parse and style all text decorations
const sanitizedText = (text: string) =>
  text
    .replace(/<[^>]*>?/gm, '')
    .replace(/&nbsp;/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&amp;/g, '&')
    .replace(/&quotgt;/g, '"')
    .replace(/&apos;/g, "'")
    .trim()

interface RichTextViewProps {
  body: string
}

export const RichTextView = ({ body }: RichTextViewProps) => {
  const styles = useStyleSheet(themedStyles)

  const getBodyPartForBlock = useCallback(
    (block: EditorBlock) => {
      switch (block.type) {
        case EditorBlockType.Image:
          return <RichTextViewImage key={block.id} block={block} />
        case EditorBlockType.Header: {
          const text = sanitizedText(block.data.text)
          return text.length === 0 ? null : (
            <Text type="title-3" key={block.id} style={styles.bodyBlock}>
              {text}
            </Text>
          )
        }
        case EditorBlockType.Paragraph: {
          const text = sanitizedText(block.data.text)
          return text.length === 0 ? null : (
            <Text type="large" key={block.id} style={styles.bodyBlock}>
              {text}
            </Text>
          )
        }
        case EditorBlockType.List:
          return (
            <RichTextViewList
              key={block.id}
              items={block.data.items.map(sanitizedText)}
              listStyle={block.data.style as RichTextViewStyle}
            />
          )
        default:
          return null
      }
    },
    [styles],
  )

  const bodyContent = useMemo(() => {
    const { blocks } = JSON.parse(body) as { blocks: EditorBlock[] }
    return blocks.map(getBodyPartForBlock)
  }, [body, getBodyPartForBlock])

  return <View style={styles.container}>{bodyContent}</View>
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  bodyBlock: {
    marginBottom: 16,
  },
})
