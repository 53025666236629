import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { thresholdsSettingsSelector } from '@src/selectors/settings'
import { healthSettingsSelector } from '@src/selectors/app'
import { Option, ThresholdType } from '@src/screens/Settings/models/settings.types'
import CustomSetting from './CustomSetting'

interface ThresholdSettingProps {
  type: ThresholdType
  title: string
  lowOptionsKey: 'glucoseLowOptions' | 'ketoneLowOptions'
  highOptionsKey: 'glucoseHighOptions' | 'ketoneHighOptions'
  lowField: 'glucoseLowThreshold' | 'ketoneLowThreshold'
  highField: 'glucoseHighThreshold' | 'ketoneHighThreshold'
}

const ThresholdSetting = (props: ThresholdSettingProps) => {
  const { type, title, lowField, highField, lowOptionsKey, highOptionsKey } = props

  const settings = useSelector(thresholdsSettingsSelector)
  const healthSettings = useSelector(healthSettingsSelector)

  const navigation = useNavigation()

  const lowOption = healthSettings[lowOptionsKey].find(
    (x: Option) => x.value === settings[lowField],
  )
  const highOption = healthSettings[highOptionsKey].find(
    (x: Option) => x.value === settings[highField],
  )

  const onPress = () => {
    if (type === ThresholdType.Glucose) {
      navigation.navigate('GlucoseThresholdSettings', { source: 'UserSettings' })
    } else {
      navigation.navigate('ThresholdSettings', {
        type: ThresholdType.Ketone,
        source: 'UserSettings',
      })
    }
  }

  return (
    <CustomSetting
      title={title}
      onPress={onPress}
      text={`${lowOption?.text} - ${highOption?.text}`}
    />
  )
}

export default ThresholdSetting
