import { gql } from '@apollo/client'

export const FETCH_AVAILABLE_DAYS = gql`
  query insuranceSchedulingAvailableDates(
    $appointmentType: InsuranceBerryStreetAppointmentType
    $timeZone: String
    $appointmentId: ID
  ) {
    insuranceSchedulingAvailableDates(
      appointmentType: $appointmentType
      timeZone: $timeZone
      appointmentId: $appointmentId
    ) {
      dates
    }
  }
`
