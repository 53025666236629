import { gql } from '@apollo/client/core'

const PURCHASE_ONE_TIME_PAYMENT = gql`
  mutation createOneTimePayment(
    $email: String!
    $fullName: String!
    $phoneNumber: String
    $providerPaymentMethodId: String!
    $providerPromotionCodeId: String
    $purchasableItems: [PurchasableItem!]!
    $metadata: [KeyValueString!]
  ) {
    createOneTimePayment(
      email: $email
      fullName: $fullName
      phoneNumber: $phoneNumber
      providerPaymentMethodId: $providerPaymentMethodId
      providerPromotionCodeId: $providerPromotionCodeId
      purchasableItems: $purchasableItems
      metadata: $metadata
    ) {
      invoice {
        id
        stripeId
        total
        status
        primaryProduct {
          id
          key
          title
        }
      }
    }
  }
`

export default PURCHASE_ONE_TIME_PAYMENT
