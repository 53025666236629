import { useRef, useState } from 'react'
import { debounce } from 'lodash'
import GooglePlaces from '@src/services/GooglePlaces'
import { Place } from '@src/services/GooglePlaces/GooglePlaces'
import { AddressCountries } from '@src/types'

const FETCH_PLACES_DELAY = 500

export const useAddress = () => {
  const [placesMatchingSearch, setPlacesMatchingSearch] = useState<Place[]>([])

  const fetchPlacesMatchingStreet = async (street: string, country: AddressCountries) => {
    const places = await GooglePlaces.fetchPlacesForStreetAndCountry({ street, country })
    setPlacesMatchingSearch(places)
  }

  const debounceFetchPlaces = useRef(debounce(fetchPlacesMatchingStreet, FETCH_PLACES_DELAY))

  const fetchAddressForPlace = async (place: Place, country: AddressCountries) => {
    const address = await GooglePlaces.fetchAddressForPlace({
      place,
      country,
    })

    return address
  }

  return { debounceFetchPlaces, placesMatchingSearch, fetchAddressForPlace }
}
