import React from 'react'
import { View, ViewStyle, TextStyle, TouchableWithoutFeedback, StyleProp } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

interface SectionProps {
  style?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
  labelTextType?: 'regular' | 'small' | 'large'
  leftStyle?: ViewStyle
  title: string
  children?: React.ReactNode
  onPress?: () => void
  disabled?: boolean
  bold?: boolean
}

export const Section = (props: SectionProps) => {
  const {
    style,
    leftStyle,
    labelStyle,
    labelTextType = 'regular',
    title,
    children,
    onPress,
    disabled,
    bold = true,
  } = props

  const styles = useStyleSheet(themedStyle)

  const section = (
    <View style={[styles.container, disabled && styles.disabled, style]}>
      <View style={[styles.left, leftStyle]}>
        <Text type={labelTextType} bold={bold} style={labelStyle}>
          {title}
        </Text>
      </View>
      {children}
    </View>
  )

  if (!onPress) {
    return section
  }

  return (
    <TouchableWithoutFeedback accessibilityLabel="Section" onPress={onPress}>
      {section}
    </TouchableWithoutFeedback>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 64,
    paddingHorizontal: 16,
  },
  disabled: {
    opacity: 0.5,
  },
  left: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
  button: {
    width: 20,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: 16,
    width: 16,
    marginLeft: 8,
  },
})
