import React from 'react'
import moment from 'moment'
import pluralize from 'pluralize'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { AppStackParamList } from '@src/navigation/types'
import { DAY_MONTH_YEAR_FORMAT } from '@src/config/momentFormat'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Text } from '@src/components/base'
import { useSnack } from '@src/utils/navigatorContext'
import { useSubscriptionFromRoute } from '../../utils/hooks'
import { PauseSubscriptionContainer } from './PauseSubscriptionContainer'

export const PauseSubscriptionReview = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const { subscription } = useSubscriptionFromRoute()
  const { params } = useRoute<RouteProp<AppStackParamList, 'PauseSubscriptionReview'>>()
  const { cycleCount } = params
  const dispatch = useDispatch()
  const showSnack = useSnack()

  if (!subscription) {
    return null
  }

  const description =
    "You're pausing your subscription and shipments will continue when it resumes."

  const currentPeriodEndAt = moment(subscription.currentPeriodEndAt)
  const pauseStart = currentPeriodEndAt.format(DAY_MONTH_YEAR_FORMAT)
  const pauseStartDaysLeft = currentPeriodEndAt.diff(moment(), 'days')

  const pauseEndAt = currentPeriodEndAt.add(cycleCount, 'months')
  const pauseEnd = pauseEndAt.format(DAY_MONTH_YEAR_FORMAT)
  const pauseEndDaysLeft = pauseEndAt.diff(moment(), 'days')

  const handleNext = () => {
    dispatch({
      type: 'marketplace/pauseSubscription',
      payload: { id: subscription.id, cycleCount },
      success: () => {
        navigation.navigate('PauseSubscriptionSuccess')
      },
      failure: (error: any) => {
        showSnack(error.message, null, 'error')
      },
    })
  }

  const handleCancel = () => {
    navigation.pop(2)
  }

  return (
    <PauseSubscriptionContainer
      title="Snooze your Program"
      subtitle="Let's double check"
      description={description}
      nextTitle="Confirm"
      onNext={handleNext}
      cancelTitle="Cancel"
      onCancel={handleCancel}
    >
      <View style={styles.container}>
        <View style={styles.selectionItem}>
          <Text type="regular">Pause starts</Text>
          <View style={styles.selectionItemValues}>
            <Text type="regular" bold>
              {pauseStart}
            </Text>
            <Text type="small">{pluralize('day', pauseStartDaysLeft, true)} left</Text>
          </View>
        </View>
        <View style={styles.selectionItem}>
          <Text type="regular">Subscription resumes</Text>
          <View style={styles.selectionItemValues}>
            <Text type="regular" bold>
              {pauseEnd}
            </Text>
            <Text type="small">{pluralize('day', pauseEndDaysLeft, true)} left</Text>
          </View>
        </View>
      </View>
    </PauseSubscriptionContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    marginTop: 32,
    borderTopWidth: 2,
    borderColor: 'theme.surface.base2',
  },
  selectionItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 64,
    paddingHorizontal: 16,
    borderColor: 'theme.surface.base2',
    borderBottomWidth: 2,
  },
  selectionItemValues: {
    alignItems: 'flex-end',
  },
  actionsContainer: {
    padding: 16,
  },
  cancelButton: {
    marginTop: 8,
  },
})
