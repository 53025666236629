import React from 'react'
import { NativeModules, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { openUrl } from '@src/utils'

const BASE_URL = `${NativeModules.Configuration.scheme}://`

interface ChecklistItemProps {
  title: string
  completed: boolean
  deepLink: string
  style?: StyleProp<ViewStyle>
}

export const ChecklistItem = (props: ChecklistItemProps) => {
  const styles = useStyleSheet(themedStyles)

  const { title, completed, deepLink, style } = props

  if (completed) {
    return (
      <View style={[styles.container, styles.completedContainer, style]}>
        <View style={styles.checkboxContainer}>
          <Icon name="check" style={styles.checkIcon} weight="bold" />
        </View>
        <Text style={styles.text} type="regular">
          {title}
        </Text>
      </View>
    )
  }

  const onPress = () => {
    openUrl(`${BASE_URL}${deepLink}`)
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      accessibilityLabel={title}
      style={[styles.container, style]}
    >
      <Text style={styles.text} type="regular">
        {title}
      </Text>
      <Icon name="caret-right" size="16" weight="bold" style={styles.icon} />
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    borderWidth: 2,
    borderRadius: 16,
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderColor: 'theme.surface.base',
    flexDirection: 'row',
    gap: 16,
    alignItems: 'center',
  },
  completedContainer: {
    borderWidth: 0,
    backgroundColor: 'theme.surface.base2',
  },
  text: {
    flex: 1,
  },
  icon: {
    color: 'theme.surface.base0',
  },
  checkIcon: {
    width: 16,
    height: 16,
    color: 'theme.solid.white',
  },
  checkboxContainer: {
    borderRadius: 14,
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'theme.success.base',
  },
})
