import React, { useCallback } from 'react'
import { BaseChart } from './BaseChart'
import { renderColumnChart } from './renderColumnChart'
import { DatesRange } from './types'

export interface WithinTimeChartPointType {
  y: number
  x: string
  type: string
}
interface WithinTimeChartProps {
  data: WithinTimeChartPointType[]
  datesRange: DatesRange
  onLoad?: () => void
}

export const WithinTimeChart = ({ data, datesRange, onLoad }: WithinTimeChartProps) => {
  const hasData = data.some(({ y }) => y > 0)

  const renderFunction = useCallback(
    (style: any) => {
      if (!hasData) {
        return null
      }
      return renderColumnChart({
        data,
        yLabel: '%',
        style,
        maxColumnWidth: 24,
      })
    },
    [data, hasData],
  )

  return (
    <BaseChart
      renderFunction={renderFunction}
      title="By time in range"
      height={250}
      datesRange={datesRange}
      onLoad={onLoad}
      showLegend={false}
    />
  )
}
