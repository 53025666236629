import { GoogleSignin } from '@react-native-google-signin/google-signin'
import Config from 'react-native-config'

class GoogleAuth {
  configure() {
    GoogleSignin.configure({
      webClientId: Config.GOOGLE_WEB_CLIENT_ID,
      offlineAccess: false,
      googleServicePlistPath: `GoogleService-Info-${Config.ENV}`,
    })
  }

  async signOut() {
    try {
      await GoogleSignin.signOut()
    } catch {
      // We can safely ignore these
    }
  }
}

export default new GoogleAuth()
