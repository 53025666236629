import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

export enum RichTextViewStyle {
  Ordered = 'ordered',
  Unordered = 'unordered',
}

interface RichTextViewProps {
  items: string[]
  listStyle: RichTextViewStyle
}

export const RichTextViewList = ({ items, listStyle }: RichTextViewProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.list}>
      {items.map((text, index) => (
        <View key={index} style={styles.item}>
          <View style={styles.listStyle}>
            <Text type="large" bold>
              {listStyle === RichTextViewStyle.Ordered ? `${index + 1}.` : '\u2022 '}
            </Text>
          </View>
          <View style={styles.content}>
            <Text type="large">{text}</Text>
          </View>
        </View>
      ))}
    </View>
  )
}

const themedStyles = StyleService.create({
  list: {
    marginBottom: 24,
  },
  item: {
    flexDirection: 'row',
    marginLeft: 8,
    marginBottom: 8,
    alignItems: 'flex-start',
  },
  listStyle: {
    flexShrink: 0,
    width: 24,
    textAlign: 'right',
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 8,
  },
})
