import { gql } from '@apollo/client'

export const UPSERT_PRIMARY_GOAL = gql`
  mutation upsertPrimaryGoal($kind: UserGoalKind!, $description: String) {
    upsertPrimaryGoal(kind: $kind, description: $description) {
      title
      kind
      description
    }
  }
`
