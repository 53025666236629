import React from 'react'
import { ScrollView, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { SUPPORT_EMAIL } from '@src/config/constants'
import { Text } from '@components/base'
import SubscriptionForm, { SubscriptionFormProps } from './SubscriptionForm'

const SubscriptionComponent = (props: SubscriptionFormProps & { subscriptions: object[] }) => {
  const { address, paymentMethod, subscriptions, onAddressFocus, onPaymentMethodFocus } = props

  const styles = useStyleSheet(themedStyles)

  return (
    <ScrollView style={styles.container}>
      <SubscriptionForm
        address={address}
        onAddressFocus={onAddressFocus}
        paymentMethod={paymentMethod}
        onPaymentMethodFocus={onPaymentMethodFocus}
      />
      {subscriptions.length > 0 && (
        <View style={styles.textContainer}>
          <Text type="regular" style={styles.descriptionLabel}>
            You can tap your credit card number or shipping address to keep them up to date
          </Text>
          <Text type="regular" style={styles.descriptionLabel}>
            {`If you would like to change your plan, please contact support at ${SUPPORT_EMAIL}`}
          </Text>
        </View>
      )}
    </ScrollView>
  )
}

export default SubscriptionComponent

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 216,
    marginHorizontal: 16,
  },
  descriptionLabel: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
})
