import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

export const ValueColorLegend = () => {
  const styles = useStyleSheet(themedStyles)

  const items = [
    { title: 'Good', style: styles.boxGood },
    { title: 'Ok', style: styles.boxOk },
    { title: 'Needs improvement', style: styles.boxBad },
  ]

  return (
    <View style={styles.container}>
      {items.map(({ title, style }) => (
        <View key={title} style={styles.row}>
          <View style={[styles.box, style]} />
          <Text type="tiny" bold style={styles.text}>
            {title}
          </Text>
        </View>
      ))}
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 8,
  },
  box: {
    width: 12,
    height: 12,
    borderRadius: 6,
    marginRight: 4,
  },
  boxGood: {
    backgroundColor: 'theme.range.good',
  },
  boxOk: {
    backgroundColor: 'theme.range.regular',
  },
  boxBad: {
    backgroundColor: 'theme.range.bad',
  },
  text: {
    color: 'theme.text.secondary',
  },
})
