import React from 'react'
import { ViewStyle } from 'react-native'
import { View } from 'react-native'

interface PaginationDotsProps {
  pagesNumber: number
  selectedIndex: number
  spaceBetweenDots: number
  dotStyle: ViewStyle
  selectedDotStyle: ViewStyle
}

export const PaginationDots = ({
  pagesNumber,
  selectedIndex,
  spaceBetweenDots,
  dotStyle,
  selectedDotStyle,
}: PaginationDotsProps) => {
  return (
    <View style={{ alignSelf: 'center', flexDirection: 'row' }}>
      {Array.from({ length: pagesNumber }, (_, index) => {
        const style = [dotStyle, index === selectedIndex && selectedDotStyle].filter(Boolean)
        return (
          <View
            key={index}
            style={[
              ...style,
              {
                marginHorizontal: spaceBetweenDots / 2,
              },
            ]}
          />
        )
      })}
    </View>
  )
}
