import { ApolloClient, from } from '@apollo/client/core'
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache'
import { Storage } from '@utils'
import App, { BuildType } from '../app'

import {
  buildAuthLink,
  requestCacheLink,
  afterwareLink,
  retryLink,
  errorLink,
  buildMultiApiLink,
} from './links'

export type Env = 'production' | 'staging' | 'development'

interface ResetEndpointOptions {
  env?: BuildType | Env
  endpoint?: string
}

interface BuildClientOptions {
  endpoint?: string
  timeZone?: string
}

const getEnvFromEndpoint = (endpoint: string) => {
  const { production, staging, development } = App.defaultEndpoints

  const envs = {
    [production]: 'production',
    [staging]: 'staging',
    [development]: 'development',
  }

  return (envs[endpoint] as Env) || 'development'
}

const printConnectionDetails = (connection: Api) => {
  console.log('Connection Details')
  console.log('Env: ', connection?.env)
  console.log('Endpoint: ', connection?.endpoint)
  console.log('TimeZone: ', connection?.timeZone)
}

class Api {
  env: string
  endpoint: string
  endpointEnv: Env
  client?: ApolloClient<NormalizedCacheObject>
  timeZone?: string
  abortController: AbortController

  constructor() {
    this.env = App.build.env
    this.endpoint = App.build.endpoint || App.defaultEndpoints[this.env as Env]
    this.endpointEnv = getEnvFromEndpoint(this.endpoint)
    this.client = undefined
    this.timeZone = App.timeZone
    this.abortController = new AbortController()
  }

  getClient = (): ApolloClient<NormalizedCacheObject> => {
    if (this.client) {
      return this.client
    }

    const storedEndpoint = Storage.get<string>('endpoint')
    console.log('storedEndpoint: ', storedEndpoint)

    this.resetEndpoint({
      env: App.build.env,
      endpoint: storedEndpoint || App.build.endpoint,
    })

    return (this.client as unknown) as ApolloClient<NormalizedCacheObject>
  }

  resetEndpoint = (opts: ResetEndpointOptions = {}) => {
    const { env, endpoint } = opts

    if (env && endpoint) {
      // set explicitly
      this.env = env
      this.endpoint = endpoint
      this.endpointEnv = getEnvFromEndpoint(this.endpoint)
    } else if (env && App.defaultEndpoints[env]) {
      // set by env
      this.env = env
      this.endpoint = App.defaultEndpoints[env]
      this.endpointEnv = getEnvFromEndpoint(this.endpoint)
    } else if (this.env && this.endpoint) {
      // reset
      this.endpoint = App.build.endpoint || App.defaultEndpoints[this.env as Env]
      this.endpointEnv = getEnvFromEndpoint(this.endpoint as string)
    }
    Storage.set('endpoint', this.endpoint)
    console.log('resetEndpoint: ', this.endpoint)

    this.client = this.buildClient(this)
    printConnectionDetails(this)
  }

  setTimeZone = (timeZone: string) => {
    this.timeZone = timeZone || App.timeZone
    this.client = this.buildClient(this)
    printConnectionDetails(this)
  }

  cancelPendingRequests = () => {
    this.abortController.abort()
    this.abortController = new AbortController()
  }

  buildClient = (config: BuildClientOptions = {}) => {
    return new ApolloClient({
      link: from([
        buildAuthLink(this.abortController, config.timeZone),
        requestCacheLink,
        afterwareLink,
        retryLink,
        errorLink,
        buildMultiApiLink(config.endpoint || ''),
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    })
  }
}

export default new Api()
