import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'

export const Duration = ({ duration }: { duration: string }) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <Icon name="timer" style={styles.icon} />
      <Text type="regular" lineSpacing="tight">
        {duration}
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    width: 16,
    marginRight: 8,
  },
})
