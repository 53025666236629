import { gql } from '@apollo/client'

export const CREATE_CHAT_CONVERSATION_NOTE = gql`
  mutation createChatConversationNote($body: String!) {
    createChatConversationNote(body: $body) {
      id
      value
      createdAt
      createdBy {
        id
      }
    }
  }
`
