/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export const MATERIAL_COMMUNITY_ICONS = 'materialCommunity' as const

/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export enum MaterialCommunityPack {
  BarcodeScan = 'barcode-scan',
  Instagram = 'instagram',
}

/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export type MaterialCommunityIcon = MaterialCommunityPack[keyof MaterialCommunityPack]

/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export const materialCommunityIcon = (name: MaterialCommunityIcon) => ({
  name,
  pack: MATERIAL_COMMUNITY_ICONS,
})
