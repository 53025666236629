import defaultReducers from '@src/models/defaultReducers'
import {
  LatestUserAppointment,
  CoachAssignmentCollection,
  CoachAssignmentSupportType,
  CoachAssignmentState,
  NoteCollection,
  AppointmentCollection,
} from '@src/types'
import { NutritionistHubStoreState } from './nutritionistHub.types'

export const reducers = {
  ...defaultReducers,
  updateLastUserAppointment: (
    state: NutritionistHubStoreState,
    { payload }: { payload: LatestUserAppointment },
  ) => {
    return {
      ...state,
      latestUserAppointment: payload,
    }
  },
  updateInsurancePolicy: (state: NutritionistHubStoreState, { payload }: { payload: any }) => {
    return {
      ...state,
      insurancePolicy: payload,
    }
  },
  updateTopPriorityCoachAssignment: (
    state: NutritionistHubStoreState,
    { payload }: { payload: CoachAssignmentCollection },
  ) => {
    const supportTypePriorityOrder = {
      [CoachAssignmentSupportType.VideoCall]: 1,
      [CoachAssignmentSupportType.AsyncChat]: 2,
      [CoachAssignmentSupportType.VideoChat]: 3,
      [CoachAssignmentSupportType.MealPlan]: 4,
      [CoachAssignmentSupportType.SupplementReview]: 5,
    }

    const statePriorityOrder = {
      [CoachAssignmentState.Inactive]: 1,
      [CoachAssignmentState.Paused]: 2,
      [CoachAssignmentState.Pending]: 3,
      [CoachAssignmentState.Active]: 4,
    }

    const sortedCoachAssignments = payload.coachAssignments.sort(
      (a, b) =>
        statePriorityOrder[b.state] - statePriorityOrder[a.state] ||
        supportTypePriorityOrder[b.supportType] - supportTypePriorityOrder[a.supportType],
    )

    const topPriorityCoachAssignment = sortedCoachAssignments[0]

    return {
      ...state,
      topPriorityCoachAssignment,
    }
  },
  updatedMemberNotes: (
    state: NutritionistHubStoreState,
    { payload }: { payload: NoteCollection },
  ) => {
    return {
      ...state,
      allMemberNotes: payload.notes || [],
    }
  },
  updateMemberNotesUpdatedAtTimestamp: (
    state: NutritionistHubStoreState,
    { payload }: { payload: string },
  ) => {
    return {
      ...state,
      memberNotesUpdatedAt: payload,
    }
  },
  updateUpcomingAppointments: (
    state: NutritionistHubStoreState,
    { payload }: { payload: AppointmentCollection },
  ) => {
    return {
      ...state,
      upcomingAppointments: payload.appointments || [],
    }
  },
  updatePastAppointments: (
    state: NutritionistHubStoreState,
    { payload }: { payload: AppointmentCollection },
  ) => {
    return {
      ...state,
      pastAppointments: payload.appointments || [],
    }
  },
}

export default reducers
