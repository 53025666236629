import prepareArm from './source/prepare-arm.mp4'
import sanitize from './source/sanitize.mp4'
import prepareSensor from './source/prepare-sensor.mp4'
import alignApplicator from './source/align-applicator.mp4'
import connectApplicator from './source/connect-applicator.mp4'
import getReadyToApply from './source/get-ready-to-apply.mp4'
import applyCgm from './source/apply-cgm.mp4'
import bandage from './source/bandage.mp4'

export const prepareArmVideo = {
  source: prepareArm,
}

export const sanitizeVideo = {
  source: sanitize,
}

export const prepareSensorVideo = {
  source: prepareSensor,
}

export const alignApplicatorVideo = {
  source: alignApplicator,
}

export const connectApplicatorVideo = {
  source: connectApplicator,
}

export const getReadyToApplyVideo = {
  source: getReadyToApply,
}

export const applyCgmVideo = {
  source: applyCgm,
}

export const bandageVideo = {
  source: bandage,
}
