import React, { useContext, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useTheme } from '@ui-kitten/components'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { capitalize } from 'lodash'
import { StackNavigationProp } from '@react-navigation/stack'
import { Analytics, CustomEventTypes } from '@config'
import { DATE_FORMAT } from '@config/momentFormat'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, IconButton, Input, Text } from '@components/base'
import { GestureDismissibleModal, ScrollViewWithFade } from '@components'
import { AppStackParamList } from '@navigation/types'
import { useDirectUpload } from '@src/hooks/useDirectUpload'
import { ComparisonDetails } from '@screens/Meals/components/CompareMeals'
import { useMeals } from '@screens/Meals/hooks'
import { getCompareMealsChartColors } from '@screens/Meals/utils'
import { authenticatedUserSelector } from '@selectors/app'
import { Capture, NavigatorContext, useDispatchAsync, useGoBack, useSnack } from '@utils'
import { useKeyboardVisibility } from '@src/utils/hooks'
import { UserFileTag } from '@src/types'

export const ShareMealComparison = () => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const showSnack = useSnack()
  const dispatchAsync = useDispatchAsync()
  const insets = useSafeAreaInsets()
  const isKeyboardVisible = useKeyboardVisibility(true)
  const { enableLoader, disableLoader } = useContext(NavigatorContext)

  const { params } = useRoute<RouteProp<AppStackParamList, 'ShareMealComparison'>>()
  const mealIds = params.mealIds.split(',')

  const [note, setNote] = useState('')
  const [chartImage, setChartImage] = useState<string>()

  const user = useSelector(authenticatedUserSelector)

  const { meals } = useMeals(mealIds)
  const uploadFile = useDirectUpload()

  const chartColors = getCompareMealsChartColors(theme)

  const scrollViewRef = useRef<ScrollView>(null)
  const chartContainerRef = useRef<View>(null)

  const [isChartLoaded, setChartLoaded] = useState(false)
  const [transitionEnd, setTransitionEnd] = useState(false)

  const generateHPLinks = () => {
    return meals
      .map((meal, index) => {
        const date = moment(meal.occurredAt).format(DATE_FORMAT)
        const mealType = capitalize(meal.kind || meal.type)
        const mealDisplayDate = moment(meal.occurredAt).format('lll')

        const href = `/users/${user.id}?startDate=${date}&endDate=${date}`
        const label = `Meal ${index + 1} - ${mealType} on ${mealDisplayDate}`

        return `<p><a target="_blank" href="${href}">${label}</a></p>`
      })
      .join('')
  }

  const onSendPress = async () => {
    if (!chartImage) {
      return
    }

    enableLoader()

    try {
      const file = await uploadFile({
        dataURL: chartImage.substring(7),
        fileTag: UserFileTag.IntercomAttachment,
      })

      if (!file) {
        throw new Error('Failed to send image')
      }

      await dispatchAsync({
        type: 'app/sendIntercomUserMessage',
        payload: {
          body: `<img width="360" src="${file.permanentUrl}" />${
            note.trim() ? `<p>${note}</p>` : ''
          }`,
        },
      })
      dispatchAsync({
        type: 'app/createChatConversationNote',
        payload: { body: generateHPLinks() },
      })

      Analytics.track(CustomEventTypes.MealComparisonShared, { hasNote: !!note.trim() })

      navigation.navigate('CompareMeals', { initialMealIds: params.mealIds })
      showSnack(
        'Message sent!',
        'Your meal comparison has been shared with your nutritionist. Find your post in your messages within the app.',
        'success',
      )
    } catch (error: any) {
      showSnack('An error occurred!', error.message || 'Failed to share meal comparison.', 'error')
    } finally {
      disableLoader()
    }
  }

  useEffect(() => {
    const subscription = navigation.addListener('transitionEnd', () => {
      setTransitionEnd(true)
    })

    return subscription
  }, [navigation])

  useEffect(() => {
    if (isChartLoaded && transitionEnd) {
      Capture.captureView(chartContainerRef, ({ uri }) => {
        if (uri) {
          setChartImage(uri)
        }
      })
    }
  }, [isChartLoaded, transitionEnd])

  return (
    <GestureDismissibleModal
      containerStyle={styles.modalContainer}
      contentContainerStyle={styles.contentContainer}
    >
      <View style={styles.topRow}>
        <IconButton
          accessibilityLabel="Close"
          iconName="x"
          size="s"
          type="transparent"
          style={styles.closeButton}
          onPress={goBack}
        />
        <Text type="title-3">Share with Nutritionist</Text>
      </View>
      <ScrollViewWithFade
        ref={scrollViewRef}
        style={styles.scrollView}
        scrollViewStyle={{ paddingBottom: insets.bottom + 16 + (isKeyboardVisible ? 92 : 0) }}
        fadeColor={styles.scrollView.backgroundColor}
        onLayout={() => scrollViewRef.current?.scrollToEnd({ animated: true })}
      >
        <View style={styles.comparisonDetails} ref={chartContainerRef}>
          <ComparisonDetails
            disableChartAnimation
            mealIds={mealIds}
            onLoad={() => setChartLoaded(true)}
          />
        </View>
        <View style={styles.content}>
          <View style={styles.mealDetails}>
            {meals.map((meal, index) => (
              <View key={meal.id} style={styles.mealItem}>
                <View style={[styles.mealMarker, { backgroundColor: chartColors[index] }]} />
                <Text
                  type="regular"
                  lineSpacing="tight"
                  numberOfLines={2}
                  style={styles.mealDescription}
                >
                  {meal.description || meal.ingredients.map((i) => i.description).join(', ')}
                </Text>
              </View>
            ))}
          </View>
          <Input
            label="Add Note (optional)"
            placeholder="Example: Observed a higher glucose spike with the same meal..."
            numberOfLines={5}
            value={note}
            onChangeText={setNote}
          />
          <View style={styles.spacer} />
          <Button
            type="primary"
            size="block"
            accessibilityLabel="Send"
            disabled={!chartImage}
            onPress={onSendPress}
          >
            Send
          </Button>
        </View>
      </ScrollViewWithFade>
    </GestureDismissibleModal>
  )
}

const themedStyles = StyleService.create({
  modalContainer: {
    marginTop: 'auto',
    maxHeight: '95%',
    paddingHorizontal: 16,
    flex: 0,
    flexBasis: 'auto',
  },
  contentContainer: {
    flex: 0,
    flexBasis: 'auto',
  },
  scrollView: {
    flex: 0,
    flexBasis: 'auto',
    backgroundColor: 'theme.background.modal',
  },
  topRow: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 16,
  },
  closeButton: {
    position: 'absolute',
    left: -8,
  },
  comparisonDetails: {
    height: 410,
    paddingHorizontal: 16,
    marginHorizontal: -16,
    backgroundColor: 'theme.background.modal',
  },
  content: {
    marginTop: -196,
    backgroundColor: 'theme.background.modal',
  },
  mealDetails: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    minHeight: 40,
    marginTop: 8,
    marginBottom: 24,
  },
  mealItem: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '40%',
  },
  mealMarker: {
    width: 4,
    height: 32,
    borderRadius: 2,
    marginRight: 12,
  },
  mealDescription: {
    flex: 1,
  },
  spacer: {
    marginBottom: 24,
  },
})
