import { gql } from '@apollo/client'

const CREATE_ACTIVITY = gql`
  mutation createActivity(
    $id: ID
    $type: String!
    $favorite: Boolean!
    $startTime: String!
    $endTime: String!
    $description: String
  ) {
    createActivity(
      id: $id
      type: $type
      favorite: $favorite
      startTime: $startTime
      endTime: $endTime
      description: $description
    ) {
      id
      type
      title
      startedAt
      endedAt
      status
      length
      favorite
      glucose {
        range
        healthyRange
        values {
          timestamp
          value
        }
      }
      statistics {
        aucScore
        glucoseMax
        glucoseMin
        glucoseDelta
        glucose2hrDelta
        glucoseScoreDelta
        glucoseScorePeak
        glucoseScoreAuc
        glucoseScore2hrDelta
        glucoseScore
      }
      description
      occurredAt
      createdAt
    }
  }
`

export default CREATE_ACTIVITY
