import React from 'react'
import { View, Image } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { imageReadyToScan } from '@assets/images'
import { LoadingIndicator } from '@src/components/LoadingIndicator'
import Device from '@src/config/device'

interface ScanModalComponentProps {
  statusDescription: string
  isScanning: boolean
  isReadyToScan: boolean
  onRetryButtonPress(): void
  onCancelButtonPress(): void
}

export const ScanModalComponent = ({
  statusDescription,
  isScanning,
  isReadyToScan,
  onCancelButtonPress,
  onRetryButtonPress,
}: ScanModalComponentProps) => {
  const insets = useSafeAreaInsets()
  const styles = useStyleSheet(themedStyle)

  const textContainerStyle = isReadyToScan ? { flex: 1 } : { minHeight: 280 }

  return (
    <View style={styles.container}>
      <View style={[styles.textContainer, textContainerStyle]}>
        <Text type="large" bold style={styles.descriptionLabel}>
          {statusDescription}
        </Text>
        {isReadyToScan && <Image resizeMode="contain" source={imageReadyToScan.imageSource} />}
      </View>
      <View style={[styles.buttonsContainer, { paddingBottom: (insets?.bottom || 0) + 16 }]}>
        {Device.ios && (
          <Button
            type="primary"
            size="block"
            style={styles.retryButton}
            disabled={!isReadyToScan || isScanning}
            onPress={onRetryButtonPress}
            accessibilityLabel="Retry scan"
            accessoryRight={
              isScanning && <LoadingIndicator viewStyles={styles.loadingView} color="black" />
            }
            isAccessoryInline
          >
            RETRY SCAN
          </Button>
        )}
        <Button
          type="outline"
          size="block"
          onPress={onCancelButtonPress}
          accessibilityLabel="Cancel"
        >
          CANCEL
        </Button>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    justifyContent: 'space-between',
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 16,
  },
  descriptionLabel: {
    marginBottom: 16,
    paddingHorizontal: 8,
    textAlign: 'center',
  },
  buttonsContainer: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
  },
  retryButton: {
    marginBottom: 12,
  },
  loadingView: {
    height: 20,
    marginLeft: 4,
  },
})
