import { isArray, mapValues } from 'lodash'
import { BaseIngredient } from '@screens/Ingredients/types'
import { ABBREVIATED_GRAM, NORMAL_GRAM } from '@screens/Ingredients/utils'
import { Ingredient } from '@src/types'
import { fromKeyValuePairs } from '@transforms'

export const normalizeNutrisenseItem = (item: Ingredient): BaseIngredient => {
  // Display Gram/Grams instead of g/gs
  const servingUnits = item.servingUnits === ABBREVIATED_GRAM ? NORMAL_GRAM : item.servingUnits
  const nutrition = isArray(item.nutrition) ? fromKeyValuePairs(item.nutrition) : item.nutrition

  return {
    ...item,
    isUpToDate: true,
    nutrition,
    servingOptions: [
      {
        name: servingUnits,
        quantity: item.servingAmount,
        nutritionPerServing: mapValues(nutrition, (value) => value / item.servingAmount),
      },
    ],
    servingUnits,
  }
}
