import { usePhotoUpload } from '@src/utils/hooks'
import { ReactNativeFile } from '@src/utils/image'
import { useApplicationActionSheet } from './useApplicationActionSheet'

export enum ImageRequestActionSheetOption {
  ChoseFromLibrary = 'Choose from Library',
  TakePhoto = 'Take photo',
}

interface ShowImageRequestActionSheetParams {
  title?: string
  options: ImageRequestActionSheetOption[]
}

export const useImageRequestActionSheet = (onPhotoReceived: (photo: ReactNativeFile) => void) => {
  const { showActionSheet } = useApplicationActionSheet<ImageRequestActionSheetOption>()
  const { launchCameraScreen, launchImageLibrary } = usePhotoUpload(onPhotoReceived)

  const showImageRequestActionSheet = ({
    title = 'Select Photo',
    options,
  }: ShowImageRequestActionSheetParams) => {
    showActionSheet({
      title,
      options,
      callback: (option: ImageRequestActionSheetOption) => {
        switch (option) {
          case ImageRequestActionSheetOption.ChoseFromLibrary:
            launchImageLibrary()
            break
          case ImageRequestActionSheetOption.TakePhoto:
            launchCameraScreen()
            break
        }
      },
    })
  }

  return { showImageRequestActionSheet }
}
