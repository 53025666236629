import React, { useMemo, useState } from 'react'
import { Pressable, PressableProps, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@style/service'
import { Text } from './Text'
import { IconContext } from './Icon'

export type ButtonProps = PressableProps & {
  type: 'primary' | 'secondary' | 'outline' | 'transparent'
  disabled?: boolean
  accessibilityLabel: string
  style?: ViewStyle
  textStyle?: TextStyle
  children: React.ReactNode
  accessoryLeft?: React.ReactNode
  accessoryRight?: React.ReactNode
  allowPressWhenDisabled?: boolean
  size: 'large' | 'small' | 'block'
  isAccessoryInline?: boolean
}

export const Button = (props: ButtonProps) => {
  const [isPressed, setIsPressed] = useState(false)
  const {
    type = 'primary',
    size = 'large',
    disabled = false,
    accessibilityLabel,
    children,
    accessoryLeft,
    accessoryRight,
    allowPressWhenDisabled = false,
    textStyle: textStyleFromProps,
    ...restProps
  } = props

  const styles = useStyleSheet(themedStyle)
  const textStyles = useStyleSheet(textThemedStyles)
  const spaceOutAccessories = props.size === 'block' && !props.isAccessoryInline
  const hasAccessory = accessoryLeft || accessoryRight

  const buttonStyle = useMemo(() => {
    const buttonStyle = StyleSheet.flatten<ViewStyle>([
      styles.button,
      // type
      styles[type],
      isPressed && styles[`${type}Pressed`],
      disabled && styles[`${type}Disabled`],
      // size
      styles[size],
      // accessory
      spaceOutAccessories && styles.spacedOutAccessories,
      // prop overrides
      props.style,
    ])

    if (typeof buttonStyle.paddingHorizontal === 'number') {
      buttonStyle.paddingHorizontal -= buttonStyle.borderWidth || 0
    }

    return buttonStyle
  }, [styles, type, size, disabled, spaceOutAccessories, isPressed, props.style])

  const textStyle = StyleSheet.flatten<TextStyle>([
    type === 'primary' ? textStyles.primary : textStyles.regular,
    disabled && textStyles.disabled,
    hasAccessory ? { marginHorizontal: size === 'small' ? 4 : 8 } : {},
    textStyleFromProps,
  ])

  const accessorySize = size === 'small' ? 16 : 24
  const accessoryStyle = spaceOutAccessories && hasAccessory ? { width: accessorySize } : {}

  const content =
    typeof children === 'string' ? (
      <Text type={size === 'small' ? 'regular' : 'large'} bold lineSpacing="none" style={textStyle}>
        {children}
      </Text>
    ) : (
      children
    )

  return (
    <Pressable
      {...restProps}
      disabled={disabled && !allowPressWhenDisabled}
      style={buttonStyle}
      accessibilityLabel={accessibilityLabel}
      onPressIn={(e) => {
        setIsPressed(true)
        props.onPressIn?.(e)
      }}
      onPressOut={(e) => {
        setIsPressed(false)
        props.onPressOut?.(e)
      }}
    >
      <IconContext.Provider
        value={{
          color: textStyle.color as string,
          size: accessorySize,
        }}
      >
        <View style={accessoryStyle}>{accessoryLeft}</View>
        <View>{content}</View>
        <View style={accessoryStyle}>{accessoryRight}</View>
      </IconContext.Provider>
    </Pressable>
  )
}

const themedStyle = StyleService.create({
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 40,
  },
  primary: {
    backgroundColor: 'theme.primary.base',
  },
  secondary: {
    backgroundColor: 'theme.secondary.base',
  },
  outline: {
    backgroundColor: 'theme.transparent',
    borderColor: 'theme.surface.base1',
    borderWidth: 2,
  },
  transparent: {
    backgroundColor: 'theme.transparent',
  },
  primaryPressed: {
    backgroundColor: 'theme.primary.dark',
  },
  secondaryPressed: {
    backgroundColor: 'theme.secondary.darker',
  },
  outlinePressed: {
    borderWidth: 4,
  },
  transparentPressed: {
    backgroundColor: 'theme.surface.base1',
  },
  primaryDisabled: {
    backgroundColor: 'theme.disabled.background',
  },
  secondaryDisabled: {
    backgroundColor: 'theme.disabled.background',
  },
  outlineDisabled: {
    borderColor: 'theme.surface.base3',
  },
  transparentDisabled: {
    backgroundColor: 'theme.transparent',
  },
  block: {
    alignSelf: 'stretch',
    height: 56,
    paddingHorizontal: 24,
  },
  large: {
    alignSelf: 'center',
    height: 56,
    paddingHorizontal: 24,
  },
  small: {
    alignSelf: 'center',
    height: 40,
    paddingHorizontal: 16,
  },
  spacedOutAccessories: {
    justifyContent: 'space-between',
  },
  disabled: {
    backgroundColor: 'theme.disabled.background',
  },
})

const textThemedStyles = StyleService.create({
  regular: {
    color: 'theme.text.primary',
  },
  primary: {
    color: 'theme.button.text.primary',
  },
  disabled: {
    color: 'theme.disabled.text',
  },
})
