import React, { useEffect } from 'react'
import { ActivityIndicator, Image, ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { ImageStyle } from 'react-native-fast-image'
import { useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Button, Icon, Text } from '@components/base'
import { topPriorityCoachAssignmentSelector } from '@screens/NutritionistHub/models/nutritionistHub.selectors.ts'
import { nutritionistImage } from '@assets/images'
import Device from '@config/device.ts'
import { ImageWithPlaceholder } from '@components/Image/FastImageWithPlaceholder.tsx'
import { SurveysConfigKind, UiInlineAlert } from '@src/types.ts'
import { AppRouteProp, AppStackParamList } from '@navigation/types'
import { InlineAlert } from '@components'

const details = [
  'Pay $0 out-of-pocket if your insurance is eligible. Takes less than a minute',
  'Get personalized guidance from a certified nutritionist on a video call',
  'View guided highlights and action steps after each call',
  'Track your progress towards your health goal',
]
const NUTRITIONIST_PHOTO_SIZE = 100
const defaultNutritionist = {
  firstName: 'Kara',
  lastName: 'C',
  coachProfile: {
    credentials: 'CNS',
    photo: Device.web ? null : Image.resolveAssetSource(nutritionistImage.imageSource).uri,
  },
}

export const InsuranceCallsUpsellScreen = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const styles = useStyleSheet(themedStyle)
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)
  const nutritionist = coachAssignment?.coach || defaultNutritionist
  const nutritionistPhotoUri = nutritionist?.coachProfile?.photo
  const { params = {} } = useRoute<AppRouteProp<'InsuranceCallsUpsellScreen'>>()
  const { showSuccessAlert = true } = params
  const insets = useSafeAreaInsets()

  useEffect(() => {
    dispatch({
      type: 'nutritionistHub/fetchCoachAssignments',
    })
  }, [dispatch])

  const navigateToInsuranceQuestionnaire = () => {
    navigation.replace('Questionnaire', {
      questionnaire: SurveysConfigKind.Insurance,
      skipIntroScreen: true,
    })
  }

  return (
    <NavigationContainer
      title="Insurance-covered Video Calls"
      leftIcon={<Icon name="x" size="20" weight="bold" style={styles.closeIcon} />}
    >
      <View style={styles.container}>
        <ScrollView
          contentContainerStyle={[styles.contentContainer, { paddingBottom: insets.bottom + 16 }]}
        >
          {nutritionistPhotoUri && (
            <View style={styles.nutritionistPhotoContainer}>
              <ImageWithPlaceholder
                style={styles.nutritionistPhoto as ImageStyle}
                source={{ uri: nutritionistPhotoUri }}
                placeholderComponent={
                  <ActivityIndicator animating size="small" {...styles.spinner} />
                }
              />
              <View style={styles.dotContainer}>
                <Icon name="circle" style={styles.dot} weight="fill" />
              </View>
            </View>
          )}
          <Text type="large">
            {`${nutritionist?.firstName} ${nutritionist?.lastName?.charAt(0)}, ${
              nutritionist?.coachProfile?.credentials
            }`}
          </Text>
          <Text type="regular" style={styles.secondary}>
            Nutritionist
          </Text>
          <Text type="large" bold style={styles.body}>
            Did you know that you can continue working with our nutritionist on video calls even if
            you cancel?
          </Text>
          <View style={styles.detailsContainer}>
            {details.map((detail, index) => (
              <View key={index} style={styles.detailContainer}>
                <Icon name="check" style={styles.checkIcon} weight="bold" />
                <Text style={styles.detailContent} type="regular">
                  {detail}
                </Text>
              </View>
            ))}
          </View>
        </ScrollView>

        {showSuccessAlert && (
          <InlineAlert
            message="Subscription cancelled successfully"
            category={UiInlineAlert.Success}
            style={styles.alert}
          />
        )}

        <Button
          type="primary"
          size="block"
          accessibilityLabel="Check Eligibility"
          onPress={navigateToInsuranceQuestionnaire}
          style={styles.button}
        >
          Check Eligibility
        </Button>
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    margin: 16,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
    alignItems: 'center',
  },
  body: {
    margin: 16,
    textAlign: 'center',
  },
  secondary: {
    color: 'theme.text.secondary',
  },
  closeIcon: {
    marginLeft: 16,
  },
  nutritionistPhoto: {
    width: NUTRITIONIST_PHOTO_SIZE,
    height: NUTRITIONIST_PHOTO_SIZE,
    borderRadius: NUTRITIONIST_PHOTO_SIZE / 2,
  },
  nutritionistPhotoContainer: {
    marginBottom: 16,
  },
  spinner: {
    color: 'theme.surface.base',
  },
  dotContainer: {
    position: 'absolute',
    top: 2,
    right: 2,
    width: 25,
    height: 25,
    borderColor: 'theme.success.lightest',
    borderRadius: 12.5,
    borderWidth: 2,
  },
  dot: {
    position: 'absolute',
    top: -2,
    right: -2,
    width: 25,
    height: 25,
    color: 'theme.success.base',
  },
  detailsContainer: {
    alignItems: 'flex-start',
  },
  detailContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  detailContent: {
    margin: 8,
    paddingRight: 16,
  },
  checkIcon: {
    width: 16,
    height: 16,
    color: 'theme.success.base',
    marginHorizontal: 8,
    marginTop: 10,
  },
  alert: {
    marginTop: 24,
    marginBottom: 16,
  },
  button: {
    marginVertical: 16,
  },
})
