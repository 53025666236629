import { Platform } from 'react-native'
import Config from 'react-native-config'
import {
  DdSdkReactNative,
  DdSdkReactNativeConfiguration,
  DdRum,
  SdkVerbosity,
} from '@datadog/mobile-react-native'
import { UserPrimaryInfo as User } from '@src/models/user.types'
import Bugsnag from './bugsnag'

class DatadogClient {
  configured: boolean
  isEnabled: boolean
  user?: User
  admin?: User

  constructor() {
    this.configured = false
    this.isEnabled = Platform.OS === 'ios' || Platform.OS === 'android'
    this.user = undefined
    this.admin = undefined
  }

  configure = async ({ samplingRate }: { samplingRate: number }) => {
    if (!this.isEnabled) {
      return
    }

    const config = new DdSdkReactNativeConfiguration(
      Config.DATADOG_CLIENT_TOKEN,
      Config.ENV,
      Config.DATADOG_RUM_APPLICATION_ID,
      // track User interactions e.g.: Tap on buttons.
      // You can use 'accessibilityLabel' element property to give tap action the name,
      // otherwise element type will be reported)
      true,
      // track XHR Resources
      !__DEV__,
      // track Errors
      !__DEV__,
    )

    config.site = 'US1'
    // Optional: enable or disable native crash reports
    config.nativeCrashReportEnabled = true
    // Optional: sample RUM sessions
    config.sessionSamplingRate = samplingRate
    // Optional: sample tracing integrations for network calls between your app and your backend
    config.resourceTracingSamplingRate = samplingRate
    // You need to specify the hosts of your backends to enable tracing with these backends
    config.firstPartyHosts = ['nutrisense.io']
    // Optional: let the SDK print internal logs
    // (above or equal to the provided level. Default = undefined (meaning no logs))
    config.verbosity = SdkVerbosity.ERROR

    try {
      await DdSdkReactNative.initialize(config)
      this.configured = true
      if (this.user) {
        this.setUser(this.user, this.admin)
      }
    } catch (error: any) {
      Bugsnag.notify(error)
    }
  }

  setUser = (user?: User, admin?: User) => {
    if (!this.configured) {
      if (user) {
        this.user = user
      }
      if (admin) {
        this.admin = admin
      }
      return
    }

    if (user) {
      const { id, name, email } = user
      DdSdkReactNative.setUser({ id, email, name })
    } else {
      DdSdkReactNative.setUser({ id: undefined, email: undefined, name: undefined })
    }

    if (admin) {
      DdSdkReactNative.setAttributes({ admin: true, admin_id: admin.id, admin_email: admin.email })
    } else {
      DdSdkReactNative.setAttributes({
        admin: undefined,
        admin_id: undefined,
        admin_email: undefined,
      })
    }
  }

  sendInfo = (screen: string, message: string, context?: Record<string, unknown>) => {
    DdRum.addAction(screen as any, message, context, Date.now())
  }

  sendError = (
    screen: string,
    message: string,
    context?: Record<string, unknown>,
    trace?: string,
  ) => {
    DdRum.addError(message, screen as any, trace || '', context, Date.now())
  }

  reset() {
    if (!this.configured) {
      this.user = undefined
      this.admin = undefined
      return
    }

    DdSdkReactNative.setUser({ id: undefined, email: undefined, name: undefined })
    DdSdkReactNative.setAttributes({
      admin: undefined,
      admin_id: undefined,
      admin_email: undefined,
    })
  }
}

export default new DatadogClient()
