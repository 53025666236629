import React from 'react'
import { NavigationContainer } from '@src/screens/Common/containers'
import { UserSettingsComponent } from '../components/UserSettings/UserSettings'

export const UserSettingsContainer = () => {
  return (
    <NavigationContainer title="System Settings" showLoadingIndicator>
      <UserSettingsComponent />
    </NavigationContainer>
  )
}
