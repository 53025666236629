import React, { useCallback, useMemo } from 'react'
import { SectionList, SectionListData, View } from 'react-native'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ListInjectedProps } from '@src/components/list/types'
import { PlaceholderView, CommonRefreshControl } from '@components'
import { ItemType, getListItem } from '@src/components/list/ListItemFactory'
import { SearchBar } from '@src/components/SearchBar'
import { ListQuickFilter } from '@src/containers/ListQuickFilter'
import { withDataProvider } from '@src/components/list/DataProviderList'
import { ListFooter } from '@src/components/list/common/ListFooter'
import { isModified } from '@src/navigation/utils'
import { SectionHeader } from '@src/components/SectionHeader'
import { HistoryItemType } from '../models/history.types'
import { HistoryDateInfoBar } from './HistoryDateInfoBar'

export interface HistoryListProps extends ListInjectedProps {
  items: SectionListData<any, { title: string; index: number }>[]
  filters: any
  sort: any
  query: string
  onSearchUpdated: () => void
  onQueryUpdated: (text: string) => void
  ListFooterComponent: React.ReactNode
}

const keyExtractor = (item: HistoryItemType) => item.id

const HistoryListComponent = (props: HistoryListProps) => {
  const styles = useStyleSheet(themedStyle)
  const {
    items,
    initialNumToRender,
    refreshing,
    isLoadingMore,
    loadedAll,
    onListScroll,
    onSortUpdated,
    onEndReached,
    onRefresh,
    sort,
    filters,
    query,
    onSearchUpdated,
    onQueryUpdated,
    listRef,
  } = props

  const renderSectionHeader = useCallback(
    ({ section: { title } }: { section: { title: string } }) => {
      return <SectionHeader title={title} />
    },
    [],
  )

  const refreshControl = useMemo(
    () => <CommonRefreshControl refreshing={refreshing} onRefresh={onRefresh} />,
    [onRefresh, refreshing],
  )

  const listEmptyComponent = useMemo(
    () => <PlaceholderView showSearchResults={isModified(sort, 'history')} type="history" />,
    [sort],
  )

  const listFooterComponent = useMemo(
    () => (
      <ListFooter
        isLoadingMore={isLoadingMore}
        isListEmpty={items.length === 0}
        loadedAll={loadedAll}
      />
    ),
    [isLoadingMore, items.length, loadedAll],
  )

  const renderItem = useCallback(({ item }: { item: ItemType }) => getListItem(item), [])

  return (
    <View style={styles.container}>
      <Divider />
      <View>
        <ListQuickFilter
          filters={filters}
          sort={sort}
          useNestedQuickFilters
          onSortUpdated={onSortUpdated}
        />
        <Divider />
        <SearchBar
          testID="HistoryList/SearchBar"
          value={query}
          onBlur={onSearchUpdated}
          onChangeText={onQueryUpdated}
        />
        <HistoryDateInfoBar />
      </View>
      <SectionList
        ref={listRef}
        sections={items}
        keyExtractor={keyExtractor}
        initialNumToRender={initialNumToRender}
        stickySectionHeadersEnabled={false}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        refreshing={refreshing}
        onScroll={onListScroll}
        onEndReached={onEndReached}
        refreshControl={refreshControl}
        ListEmptyComponent={listEmptyComponent}
        ListFooterComponent={listFooterComponent}
      />
    </View>
  )
}

export const HistoryList = withDataProvider(HistoryListComponent)

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})
