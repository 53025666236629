import React, { useMemo } from 'react'
import { SectionList, SectionListData, View } from 'react-native'
import { isEmpty } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ListInjectedProps } from '@src/components/list/types'
import {
  PlaceholderView,
  CommonRefreshControl,
  SectionHeader,
  useFeatureFlag,
  Feature,
} from '@components'
import { ScanListItem } from '@src/components/list/items/ScanListItem'
import { withDataProvider } from '@src/components/list/DataProviderList'
import { ListFooter } from '@src/components/list/common/ListFooter'
import { Measurement } from '@src/types'
import { ScanListLiveItem } from '@src/components/list/items/ScanListLiveItem'
import { IconName } from '@src/components/base'

export type ScanSectionItem = SectionListData<Measurement & { lastScan?: boolean }>
export type HeaderItem = {
  label: string
  title: string
  occurredAt: Date
  data: number
  icon?: IconName
}
export interface ScansListProps extends ListInjectedProps {
  items: ScanSectionItem[]
  headerItem?: HeaderItem
}

const keyExtractor = (item: Measurement) => item.id

const renderItem = ({ item }: { item: Measurement & { lastScan?: boolean } }) => {
  return <ScanListItem item={item} />
}

const renderSectionHeader = ({ section }: { section: ScanSectionItem }) => {
  return <SectionHeader title={section.title} />
}

const listEmptyComponent = <PlaceholderView type="scans" />

const ScansListComponent = (props: ScansListProps) => {
  const styles = useStyleSheet(themedStyle)
  const libre3RealTime = useFeatureFlag(Feature.Libre3RealTime)
  const {
    items,
    headerItem,
    initialNumToRender,
    refreshing,
    onListScroll,
    listRef,
    isLoadingMore,
    loadedAll,
    onEndReached,
    onRefresh,
  } = props

  const refreshControl = useMemo(
    () => <CommonRefreshControl refreshing={refreshing} onRefresh={onRefresh} />,
    [onRefresh, refreshing],
  )

  const listFooterComponent = useMemo(
    () => (
      <ListFooter
        isLoadingMore={isLoadingMore}
        isListEmpty={isEmpty(items)}
        loadedAll={loadedAll}
      />
    ),
    [isLoadingMore, items, loadedAll],
  )

  return (
    <>
      {headerItem && libre3RealTime && (
        <>
          <View style={styles.headerItem}>
            <SectionHeader title={headerItem.label} icon={headerItem.icon} />
          </View>
          <ScanListLiveItem item={headerItem} />
        </>
      )}
      <SectionList
        ref={listRef}
        style={styles.container}
        sections={items}
        keyExtractor={keyExtractor}
        initialNumToRender={initialNumToRender}
        stickySectionHeadersEnabled={false}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        refreshControl={refreshControl}
        onScroll={onListScroll}
        onEndReached={onEndReached}
        ListEmptyComponent={listEmptyComponent}
        ListFooterComponent={listFooterComponent}
      />
    </>
  )
}

export const ScansList = withDataProvider(ScansListComponent)

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.surface.base2',
  },
  headerItem: {
    backgroundColor: 'theme.surface.base2',
  },
})
