import React from 'react'
import { RouteProp, useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { UiInlineAlert } from '@src/types'
import { InlineAlert } from '@src/components'
import { INSURANCE_CHECK_IN_PROGRESS_MESSAGE } from '../constants'
import { VideoCallDetailsModal } from './VideoCallDetailsModal'

export const VideoCallInReviewModal = () => {
  const styles = useStyleSheet(themedStyles)
  const route = useRoute<RouteProp<AppStackParamList, 'VideoCallInReviewModal'>>()
  const { title, nutritionistName, date } = route.params

  return (
    <VideoCallDetailsModal
      title={title}
      nutritionistName={nutritionistName}
      date={date}
      isCallConfirmed={false}
    >
      <InlineAlert
        category={UiInlineAlert.Warning}
        message={INSURANCE_CHECK_IN_PROGRESS_MESSAGE}
        style={styles.infoContainer}
      />
    </VideoCallDetailsModal>
  )
}

const themedStyles = StyleService.create({
  infoContainer: {
    marginTop: 24,
    marginBottom: 13,
  },
})
