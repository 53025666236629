import { isNumber } from 'lodash'
import { HealthDataMeasurementType } from '@src/types'
import {
  MeasurementFormData,
  MeasurementFormOptions,
  MeasurementFormType,
  MeasurementValues,
} from '@screens/Measurements/types'

export const getFormType = (type: HealthDataMeasurementType): MeasurementFormType => {
  if (type === HealthDataMeasurementType.Stress) {
    return MeasurementFormType.Slider
  }
  if (type === HealthDataMeasurementType.BloodPressure) {
    return MeasurementFormType.Double
  }
  return MeasurementFormType.Single
}

export const getFormOptions = (type: HealthDataMeasurementType): MeasurementFormOptions => {
  if (type === HealthDataMeasurementType.Stress) {
    return {
      sliderRange: { min: 1, max: 5 },
    }
  }
  if (type === HealthDataMeasurementType.BloodPressure) {
    return {
      labels: ['systolic', 'diastolic'],
    }
  }

  return {}
}

export const prepareValues = (data: MeasurementFormData, type: HealthDataMeasurementType) => {
  const { value, secondValue } = data

  if (type === HealthDataMeasurementType.BloodPressure) {
    return {
      systolic: value ? Number.parseFloat(value) : undefined,
      diastolic: secondValue ? Number.parseFloat(secondValue) : undefined,
    }
  }

  return { value: value ? Number.parseFloat(value) : undefined }
}

export const extractValues = (values: MeasurementValues): string[] => {
  if (isNumber(values?.value)) {
    return [`${values?.value}`]
  }
  if (isNumber(values?.systolic) && isNumber(values?.diastolic)) {
    return [`${values?.systolic}`, `${values?.diastolic}`]
  }
  return ['', '']
}

export const getFormDefaultValues = (values: MeasurementValues) => {
  const [value, secondValue] = extractValues(values)

  return {
    value: `${value ?? ''}`,
    secondValue: `${secondValue ?? ''}`,
  }
}
