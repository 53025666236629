import { LessonItemContentType } from '../models/courses.types'

export const attributesTransform = ({ id, attributes }) => ({
  id,
  ...attributes,
})

// Filter out items with unknown/unsupported types
//
const knownItemTypes = Object.fromEntries(
  Object.values(LessonItemContentType).map((type) => [type, true]),
)

export const knownItemAttributesTransform = ({ id, attributes }) => {
  const items = attributes?.items?.filter((item) => knownItemTypes[item['__typename']])

  return {
    id,
    ...attributes,
    items,
  }
}
