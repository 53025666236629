import React, { useEffect, useMemo } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { Question, SurveyLink, SurveysQuestionKey } from '@src/types'
import { QuestionnaireQuestionResponse } from '@src/screens/Questionnaire/screens/QuestionnaireQuestionScreen'
import { Debug } from '@src/config'
import { SkipButton } from '../SkipButton'

interface AccountInterestQuestionnaireComponentProps {
  surveyLink: SurveyLink
  onSkipPress: () => void
  onSubmit: (responses: { [key: string]: any }) => void
}

export const AccountInterestQuestionnaireComponent = (
  props: AccountInterestQuestionnaireComponentProps,
) => {
  const { surveyLink, onSkipPress, onSubmit } = props
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  const defaultValues = useMemo(() => {
    const { responses } = surveyLink

    return responses.reduce((acc, response) => {
      acc[response.questionKey] = response.answer.value
      return acc
    }, {} as { [key: string]: any })
  }, [surveyLink])

  const questionnaireForm = useForm({
    mode: 'all',
    defaultValues,
  })

  const {
    control,
    watch,
    unregister,
    formState: { isValid },
  } = questionnaireForm

  const {
    survey: { header, questionsGroups },
  } = surveyLink

  const questions = questionsGroups.flatMap((questionGroup) => questionGroup.questions)

  const responses = watch()

  const questionsWithFollowUps = questions.reduce((acc, question) => {
    acc.push(question)
    const responseQuestionKey = Object.keys(responses).find(
      (questionKey) => question.key === questionKey,
    )

    if (responseQuestionKey) {
      const answers =
        question.response.answersGroups?.flatMap((answerGroup) => answerGroup.answers) || []
      const questionAnswer = answers.find(
        (answer) => answer.value === responses[responseQuestionKey],
      )

      if (questionAnswer?.followups?.length) {
        acc.push(...questionAnswer.followups.map((followup) => followup.question))
      }
    }
    return acc
  }, [] as Question[])

  const questionsWithFollowUpsKeys = questionsWithFollowUps.map((question) => question.key)

  const questionsKeyToUnregister = Object.keys(responses).filter(
    (questionKey) => !questionsWithFollowUpsKeys.includes(questionKey as SurveysQuestionKey),
  )

  useEffect(() => {
    if (questionsKeyToUnregister.length > 0) {
      unregister(questionsKeyToUnregister)
    }
  }, [questionsKeyToUnregister, unregister])

  const handleSubmit = () => {
    const responsesWithValues = Object.keys(responses).reduce((acc, questionKey) => {
      if (typeof responses[questionKey] !== 'undefined') {
        acc[questionKey] = responses[questionKey]
      }
      return acc
    }, {} as typeof responses)
    onSubmit(responsesWithValues)
  }

  return (
    <View
      style={[styles.container, { paddingTop: insets.top + 20, paddingBottom: insets.bottom + 16 }]}
    >
      {header && (
        <Text type="title-2" style={styles.title}>
          {header}
        </Text>
      )}
      <ScrollView style={styles.contentContainer}>
        {questionsWithFollowUps.map((question) => (
          <Controller
            key={question.key}
            name={question.key}
            control={control}
            rules={{ required: question.required }}
            render={({ field, fieldState }) => (
              <QuestionnaireQuestionResponse
                label={question.label}
                response={question.response}
                control={control}
                field={field as ControllerRenderProps}
                fieldState={fieldState}
              />
            )}
          />
        ))}
      </ScrollView>
      <View>
        <Button
          type="primary"
          size="block"
          disabled={!isValid}
          accessibilityLabel="Next Step"
          onPress={handleSubmit}
        >
          Submit
        </Button>
        {Debug.allowSkipQuestionnaire && <SkipButton title="Not now" onPress={onSkipPress} />}
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
    justifyContent: 'space-between',
  },
  contentContainer: {
    flex: 1,
  },
  title: {
    paddingHorizontal: 8,
    marginBottom: 24,
  },
  select: {
    marginBottom: 16,
  },
})
