import React from 'react'
import { TouchableHighlight, View } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { SliderInputBlock } from '@components/modalBlocks/SliderInputBlock'
import { JournalMeasurement } from '@screens/JournalEntries/types'
import { EMOJI_SCALE, emojiByScore } from '@screens/JournalEntries/utils'
import { journalMeasurementRangeSelector } from '@selectors/app'

export interface WellnessTrackingProps {
  measurements: JournalMeasurement[]
  onChange: (measurement: JournalMeasurement) => void
}

const SCALE_EMOJI_WIDTH = 36

const Scale = () => {
  const styles = useStyleSheet(themedStyle)
  return (
    <View style={styles.scaleContainer}>
      {EMOJI_SCALE.map((EmojiComponent, index) =>
        index === 0 ? (
          <View key={index} style={styles.scalePlaceholder} />
        ) : (
          <EmojiComponent key={index} style={styles.scaleEmoji} />
        ),
      )}
    </View>
  )
}

const WellnessMarker = (props: { value: number }) => {
  const styles = useStyleSheet(themedStyle)
  const { value } = props

  const EmojiComponent = emojiByScore(value)
  return (
    <TouchableHighlight accessibilityLabel="marker">
      <View style={styles.sliderMarker}>
        <EmojiComponent style={styles.sliderEmoji} />
      </View>
    </TouchableHighlight>
  )
}

export const WellnessTracking = ({ measurements, onChange }: WellnessTrackingProps) => {
  const styles = useStyleSheet(themedStyle)
  const sliderRange = useSelector(journalMeasurementRangeSelector)

  return (
    <View style={styles.container}>
      <Text type="small" bold style={styles.sectionTitle}>
        Wellness Tracking
      </Text>
      <Text type="regular">
        Use the sliders to rate the values on a scale of {sliderRange.min} to {sliderRange.max},
        where {sliderRange.min} represents a low rating and {sliderRange.max} represents a high
        rating.
      </Text>
      <Scale />
      {measurements.map((measurement) => (
        <SliderInputBlock
          showSteps
          key={measurement.type}
          label={measurement.type.toUpperCase()}
          min={0}
          max={sliderRange.max}
          showStepLabels={false}
          showValue={false}
          style={styles.sliderBlock}
          value={measurement.value}
          customMarker={(e) => <WellnessMarker value={e.currentValue} />}
          onValueChanged={(value: number) => onChange({ ...measurement, value })}
        />
      ))}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    marginHorizontal: 16,
    marginVertical: 24,
  },
  sectionTitle: {
    marginBottom: 4,
    color: 'theme.text.secondary',
  },
  sliderBlock: {
    marginBottom: 16,
  },
  sliderMarker: {
    marginTop: 4,
    marginLeft: -2,
  },
  scaleContainer: {
    marginTop: 24,
    marginBottom: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  scaleEmoji: {
    width: SCALE_EMOJI_WIDTH,
    height: SCALE_EMOJI_WIDTH,
  },
  scalePlaceholder: {
    width: SCALE_EMOJI_WIDTH * 1.4,
    marginLeft: -SCALE_EMOJI_WIDTH / 2,
  },
  sliderEmoji: {
    width: 24,
    height: 24,
  },
})
