import React from 'react'
import { StackNavigationProp } from '@react-navigation/stack'
import { useNavigation } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { BottomSheet, MacroSlider } from '@components'
import { useGoBack } from '@src/utils'
import { useMacroData } from '../../utils/useMacroData'
import { MicroSummary } from './MicroSummary'

export const NutritionSummaryModal = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()

  const { values, goals, percents, showNetCarbs } = useMacroData()

  const onEditPress = () => {
    navigation.navigate('ChangeMacroGoals', { source: 'NutritionSummaryModal' })
  }

  return (
    <BottomSheet
      header="Daily Nutrition Summary"
      primaryButton={{ text: 'Update Goals', onPress: onEditPress, style: styles.primaryButton }}
      secondaryButton={{ text: 'Close', onPress: goBack }}
    >
      <MacroSlider
        macro="calories"
        value={values.calories}
        maxValue={goals.calories}
        percent={percents.calories}
        style={styles.topSlider}
        variant="expanded"
      />
      <MacroSlider
        macro={showNetCarbs ? 'netCarbs' : 'carbs'}
        value={showNetCarbs ? values.netCarbs : values.carbs}
        maxValue={showNetCarbs ? goals.netCarbs : goals.carbs}
        percent={showNetCarbs ? percents.netCarbs : percents.carbs}
        style={styles.macroSlider}
        variant="expanded"
      />
      <MacroSlider
        macro="protein"
        value={values.protein}
        maxValue={goals.protein}
        percent={percents.protein}
        style={styles.macroSlider}
        variant="expanded"
      />
      <MacroSlider
        macro="fat"
        value={values.fat}
        maxValue={goals.fat}
        percent={percents.fat}
        style={styles.bottomSlider}
        variant="expanded"
      />
      <MicroSummary />
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  topSlider: {
    marginBottom: 24,
    width: '100%',
    marginTop: 16,
  },
  description: {
    marginBottom: 24,
  },
  macroSlider: {
    marginBottom: 24,
    width: '100%',
  },
  bottomSlider: {
    marginBottom: 36,
    width: '100%',
  },
  primaryButton: {
    flex: 2,
  },
})
