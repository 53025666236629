import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

interface SectionHeaderProps {
  children: React.ReactNode
  hasDivider?: boolean
  title: string
  onEditPress?: () => void
}

const editActionHitSlop = { top: 16, bottom: 16, right: 16, left: 16 }

export const AccountSection = ({
  children,
  hasDivider = true,
  title,
  onEditPress,
}: SectionHeaderProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <>
      <View style={styles.header}>
        <Text type="small" bold lineSpacing="none" style={styles.title}>
          {title}
        </Text>
        {onEditPress && (
          <TouchableOpacity
            accessibilityLabel={`Edit ${title}`}
            hitSlop={editActionHitSlop}
            style={styles.editAction}
            onPress={onEditPress}
          >
            <Text type="large" bold lineSpacing="none" style={styles.editActionText}>
              Edit
            </Text>
          </TouchableOpacity>
        )}
      </View>
      {children}
      {hasDivider && <View style={styles.divider} />}
    </>
  )
}

const themedStyles = StyleService.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  title: {
    marginTop: 24,
    marginBottom: 16,
    textTransform: 'uppercase',
  },
  editAction: {
    marginTop: 20,
  },
  editActionText: {
    color: 'theme.text.link',
  },
  divider: {
    height: 8,
    backgroundColor: 'theme.surface.base2',
  },
})
