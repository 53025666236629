import { gql } from '@apollo/client'
import { CORE_MEASUREMENT_FIELDS } from '@src/graphql/fragments/measurement'

const DELETE_MEASUREMENT = gql`
  ${CORE_MEASUREMENT_FIELDS}
  mutation deleteMeasurement($id: ID!) {
    deleteMeasurement(id: $id) {
      ...CoreMeasurementFields
    }
  }
`

export default DELETE_MEASUREMENT
