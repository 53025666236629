import React from 'react'
import { StyleProp, View, ActivityIndicator } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconButton } from '@components/base'
import { ImageWithFullscreen } from '@components/Image/FastImageWithPlaceholder'
import { PillButton } from '@screens/Meals'
import { useUpdateDraftMeal } from '@screens/Meals/hooks'
import { mealDraftSelector } from '@selectors/meals'
import { Image } from '@utils'
import { usePhotoUpload } from '@utils/hooks'
import { useApplicationActionSheet } from '@src/hooks/useApplicationActionSheet'

interface PhotoPickerProps extends StyleProp<any> {
  testID?: string
}

export const PhotoPicker = ({ testID }: PhotoPickerProps) => {
  const styles = useStyleSheet(themedStyle)
  const { file } = useSelector(mealDraftSelector)

  const updateDraftMeal = useUpdateDraftMeal()

  const { showActionSheet } = useApplicationActionSheet<Image.Options>()

  const { launchCameraScreen, launchImageLibrary } = usePhotoUpload((file) =>
    updateDraftMeal({ file }),
  )

  const onPhotoPress = () => {
    const callback = (type: Image.Options) => {
      switch (type) {
        case Image.PHOTO_OPTION: {
          launchCameraScreen()
          break
        }
        case Image.LIBRARY_OPTION: {
          launchImageLibrary()
          break
        }
        case Image.REMOVE_PHOTO_OPTION: {
          updateDraftMeal({ file: null })
          break
        }
      }
    }

    const hasSelectedPhoto = !!file
    const title = hasSelectedPhoto ? 'Select or Remove Photo' : 'Select Photo'
    const options: Image.Options[] = hasSelectedPhoto
      ? [Image.REMOVE_PHOTO_OPTION, ...Image.DEFAULT_OPTIONS]
      : Image.DEFAULT_OPTIONS

    showActionSheet({ title, options, callback })
  }

  if (!file?.uri) {
    return (
      <PillButton
        icon="plus"
        text="Photo"
        testID={testID}
        accessibilityLabel="Meal Photo"
        onPress={onPhotoPress}
      />
    )
  }

  return (
    <View style={styles.photo}>
      <ImageWithFullscreen
        placeholderComponent={<ActivityIndicator color="theme.text.secondary" />}
        resizeMode="cover"
        source={{ uri: file?.uri }}
        style={styles.photo as ImageStyle}
      />
      <IconButton
        accessibilityLabel="Update Meal Photo"
        iconName="x"
        size="s"
        style={styles.removePhotoButton}
        type="secondary"
        onPress={onPhotoPress}
      />
    </View>
  )
}

const themedStyle = StyleService.create({
  photo: {
    width: '100%',
    height: 160,
    marginBottom: 16,
    borderRadius: 16,
  },
  removePhotoButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
})
