import { Platform } from 'react-native'
import Logger, { LoggerScreens } from '../logger'

import { configurePlatform } from './configure'

const configure = (
  tokenCallback: (params: { os: typeof Platform.OS; token: string }) => void,
  notificationCallback: (notification: any) => void,
) => {
  const onTokenReceived = (token: string) => {
    Logger.sendInfo(LoggerScreens.PushNotification, 'onRegister', { token })
    tokenCallback({ os: Platform.OS, token })
  }

  const onRegistrationError = (error: any) => {
    Logger.sendError(LoggerScreens.PushNotification, 'onRegistrationError', { error })
  }

  const onNotificationReceived = (notification: any) => {
    Logger.sendInfo(LoggerScreens.PushNotification, 'onNotification', {
      notification,
    })
    notificationCallback(notification)
  }

  configurePlatform({ onTokenReceived, onRegistrationError, onNotificationReceived })
}

export default { configure }
