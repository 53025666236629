import React, { useState } from 'react'
import { View, TouchableOpacity, StyleProp, ViewStyle } from 'react-native'
import { Divider, MenuItem, OverflowMenu } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@components/base'

export interface MenuInfoItem {
  title: string
  isEnabled: boolean
  onPress: () => void
}

export interface MenuProps {
  menuItems?: MenuInfoItem[]
  menuEnabled?: boolean
  menuButtonStyle?: StyleProp<ViewStyle>
}

export const Menu = ({ menuEnabled = true, menuItems, menuButtonStyle }: MenuProps) => {
  const styles = useStyleSheet(themedStyle)

  const [menuVisible, setMenuVisible] = useState(false)

  if (!menuItems || menuItems.length === 0) {
    return null
  }

  const renderMenuButton = () => (
    <TouchableOpacity
      testID="OverflowMenu/More"
      style={[styles.menuButton, menuButtonStyle]}
      activeOpacity={0.7}
      disabled={!menuEnabled}
      accessibilityLabel="OverflowMenu/More"
      onPress={() => setMenuVisible(true)}
    >
      <Icon name="dots-three" />
    </TouchableOpacity>
  )

  return (
    <OverflowMenu
      style={styles.overflowMenu}
      appearance="noDivider"
      anchor={renderMenuButton}
      visible={menuVisible}
      onBackdropPress={() => setMenuVisible(false)}
    >
      <View style={styles.container}>
        {menuItems.map((item: MenuInfoItem, index: number) => {
          const onPress = () => {
            item.onPress()
            setMenuVisible(false)
          }

          const menuItem = (
            <MenuItem
              testID={item.title}
              title={item.title}
              disabled={!item.isEnabled}
              onPress={onPress}
              accessibilityLabel={item.title}
              appearance="overflowMenu"
            />
          )

          // have to do it this way rather than with a conditional divider because
          // having a null inside a mapped Fragment leads to some errors
          if (index === 0) {
            return <React.Fragment key={index}>{menuItem}</React.Fragment>
          }

          return (
            <React.Fragment key={index}>
              <Divider />
              {menuItem}
            </React.Fragment>
          )
        })}
      </View>
    </OverflowMenu>
  )
}

const themedStyle = StyleService.create({
  overflowMenu: {
    borderWidth: 0,
    borderRadius: 8,
    zIndex: 10,
  },
  container: {
    overflow: 'hidden',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: 'theme.surface.base',
  },
  menuButton: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
