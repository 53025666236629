import { Divider } from '@ui-kitten/components'
import React, { useState } from 'react'
import { View } from 'react-native'
import { StyleService } from '@src/style/service'
import { Toggle } from '@components/base'
import { Section } from '@src/components'

const ITEM_HEIGHT = 64

interface StatToggleProps {
  title: string
  defaultState: boolean
  onChange: (value: string) => void
  id: string
}

export const StatToggle = ({ title, onChange, defaultState, id }: StatToggleProps) => {
  const [checked, setChecked] = useState(defaultState)

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.toggle}>
          <Section title={title} labelTextType="large" bold={false}>
            <Toggle
              checked={checked}
              onChange={(toggle) => {
                onChange(id)
                setChecked(toggle)
              }}
            />
          </Section>
        </View>
      </View>
      <Divider />
    </View>
  )
}

const styles = StyleService.create({
  container: {
    height: ITEM_HEIGHT,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  toggle: {
    flex: 1,
  },
})
