import { ThemeColor } from '@src/style/theme'

export const commonColors = {
  axisLine: 'theme.surface.base',
  axisLabel: 'theme.text.secondary',
  tooltipBackground: 'theme.tooltip.background',
  tooltipText: 'theme.tooltip.textPrimary',
  tooltipTextSecondary: 'theme.tooltip.textSecondary',
  legendText: 'theme.text.primary',
}

export const comparativeChartColors: Record<string, ThemeColor> = {
  currentPeriod: 'theme.barChart.primary',
  previousPeriod: 'theme.barChart.secondary',
}

export const fontFamily = 'NBInternationalProReg'
