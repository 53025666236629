import React from 'react'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { NavigationContainer } from '@src/screens/Common/containers'

interface BiomarkerEntryScreenProps {
  children: React.ReactNode
  biomarkerName: string
  isEdit: boolean
  onDeletePress: () => void
}

export const BiomarkerEntryScreen = (props: BiomarkerEntryScreenProps) => {
  const { children, biomarkerName, isEdit, onDeletePress } = props
  const styles = useStyleSheet(themedStyles)

  return (
    <NavigationContainer
      title={
        <Text type="title-3">
          {isEdit ? 'Edit ' : ''}
          {biomarkerName}
        </Text>
      }
      style={styles.container}
      rightAccessories={
        isEdit
          ? [
              {
                iconDescriptor: { pack: 'eva', name: 'trash-outline' },
                iconTintColor: 'theme.primary.base',
                onPress: onDeletePress,
              },
            ]
          : undefined
      }
    >
      {children}
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
    padding: 16,
    flex: 1,
  },
})
