import React from 'react'
import { View } from 'react-native'
import { MessageFooterProps, useChannelContext, useMessageContext } from 'stream-chat-react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconButton, Text } from '@components/base'
import { useImpersonationContext } from '@src/config/impersonation'

export const CustomMessageFooter = ({ formattedDate }: MessageFooterProps) => {
  const styles = useStyleSheet(footerStyle)
  const { channel } = useChannelContext()
  const {
    message: { id, own_reactions: ownReactions },
    hasReactions,
    isMyMessage,
    lastReceivedId,
  } = useMessageContext()
  const isImpersonating = useImpersonationContext()

  const showMyReaction = !isMyMessage && hasReactions
  const showReactionButtons =
    !isMyMessage && !hasReactions && lastReceivedId === id && !isImpersonating
  const myReaction = ownReactions && ownReactions[0]?.type

  return (
    <View style={styles.container}>
      <Text type="small" style={[styles.date, isMyMessage && styles.myMessageDate]}>
        {formattedDate as string}
      </Text>
      {showMyReaction && (
        <Icon
          name={myReaction === 'like' ? 'thumbs-up' : 'thumbs-down'}
          style={[styles.thumbIcon, styles.thumbIconReacted]}
        />
      )}
      {showReactionButtons && (
        <View style={styles.reactionContainer}>
          <Text type="small" style={styles.reactionText}>
            How was my response?
          </Text>
          <IconButton
            accessibilityLabel="Like"
            iconName="thumbs-up"
            size="s"
            type="transparent"
            onPress={() => channel.sendReaction(id, { type: 'like' }, { enforce_unique: true })}
          />
          <IconButton
            accessibilityLabel="Dislike"
            iconName="thumbs-down"
            size="s"
            type="transparent"
            onPress={() => channel.sendReaction(id, { type: 'sad' }, { enforce_unique: true })}
          />
        </View>
      )}
    </View>
  )
}

const footerStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    paddingHorizontal: 8,
    marginTop: 4,
    marginBottom: 16,
  },
  date: {
    color: 'theme.text.tertiary',
  },
  myMessageDate: {
    marginLeft: 'auto',
  },
  reactionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  reactionText: {
    marginRight: 8,
    color: 'theme.text.secondary',
  },
  thumbIcon: {
    width: 20,
    height: 20,
    color: 'theme.primary.base',
  },
  thumbIconReacted: {
    color: 'theme.text.tertiary',
  },
})
