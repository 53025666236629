import React from 'react'
import { Image, ImageStyle, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useRoute } from '@react-navigation/native'
import { startCase } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Icon } from '@components/base'
import { NavigationContainer } from '@screens/Common/containers'
import { useGoBack } from '@utils/navigation'
import { ScrollViewWithFade } from '@src/components'
import { libre3BluetoothImage } from '@src/assets/images'
import { AppRouteProp } from '@src/navigation/types'
import { UserSensorKind } from '@src/types'

export const SensorSwitchConfirmed = () => {
  const goBack = useGoBack()
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const route = useRoute<AppRouteProp<'SensorSwitchConfirmed'>>()
  const { params } = route
  const { newSensorKind = UserSensorKind.Libre3 } = params || {}

  return (
    <NavigationContainer
      title="You're all set!"
      style={styles.container}
      allowBackNavigation={false}
    >
      <ScrollViewWithFade scrollViewStyle={styles.scrollView}>
        <View style={styles.textContainer}>
          <Text type="regular" style={styles.text}>
            Your future shipments will deliver FreeStyle {startCase(newSensorKind)}s
          </Text>
          <Text type="regular" style={styles.text}>
            You may see a few changes in your app, but don't worry — any Libre sensors you have left
            will still work normally.
          </Text>
        </View>
        <View style={styles.imageContainer}>
          <Image source={libre3BluetoothImage.imageSource} style={styles.image as ImageStyle} />
        </View>
        <View style={styles.textContainer}>
          <Text type="regular" style={styles.text}>
            To learn more about your new {startCase(newSensorKind)} sensors, visit the Learn section
            and tap on Sensor Guides!
          </Text>
        </View>
        <View style={styles.alertContainer}>
          <View style={styles.alert}>
            <Icon name="check" style={styles.checkmarkIcon} />
            <Text type="regular" style={styles.alertText}>
              Success! Your plan is updated to deliver {startCase(newSensorKind)} CGMs.
            </Text>
          </View>
        </View>
      </ScrollViewWithFade>
      <View style={[styles.actionContainer, { paddingBottom: insets.bottom + 16 }]}>
        <Button type="primary" size="block" accessibilityLabel="Done" onPress={goBack}>
          Done
        </Button>
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    flexGrow: 1,
  },
  textContainer: {
    marginTop: 32,
    marginHorizontal: 16,
    gap: 24,
  },
  text: {
    textAlign: 'center',
  },
  imageContainer: {
    marginTop: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 140,
    height: 135,
  },
  actionContainer: {
    paddingHorizontal: 16,
  },
  alertContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  alert: {
    marginVertical: 40,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    paddingHorizontal: 16,
    marginHorizontal: 32,
    gap: 16,
    backgroundColor: 'theme.success.lightest',
    borderWidth: 1,
    borderColor: 'theme.success.lighter',
    borderRadius: 8,
  },
  alertText: {
    flex: 1,
    color: 'theme.solid.black',
  },
  checkmarkIcon: {
    color: 'theme.success.darkest',
  },
})
