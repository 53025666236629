import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { take } from 'lodash'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Button, Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScrollViewWithFade } from '@src/components'
import { upcomingAppointmentsSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { CallCard } from '@screens/NutritionistHub/components/UpcomingCalls/CallCard.tsx'
import { AppStackParamList } from '@navigation/types'

export const UpcomingCalls = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const dispatch = useDispatch()
  const upcomingAppointments = useSelector(upcomingAppointmentsSelector)

  useEffect(() => {
    dispatch({ type: 'nutritionistHub/fetchUpcomingAppointments' })
  }, [dispatch])

  const handleSeeAllPress = () => {
    navigation.navigate('AllVideoCalls')
  }

  return (
    <ScrollViewWithFade>
      <View style={styles.container}>
        <View style={styles.sectionHeader}>
          <View style={styles.header}>
            <Icon name="calendar-blank" />
            <View style={styles.headerText}>
              <Text type="regular" bold>
                Upcoming Calls
              </Text>
            </View>
          </View>
          <Button
            accessibilityLabel="seeAllCalls"
            type="outline"
            size="small"
            style={styles.seeAllButton}
            onPress={handleSeeAllPress}
          >
            See All
          </Button>
        </View>
        <View style={styles.callsContainer}>
          {take(upcomingAppointments, 5).map((appointment) => (
            <CallCard key={appointment.id} appointment={appointment} />
          ))}
        </View>
      </View>
    </ScrollViewWithFade>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    marginBottom: 24,
  },
  sectionHeader: {
    marginTop: 24,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerText: {
    marginLeft: 20,
    lineHeight: 24,
    flexDirection: 'column',
  },
  seeAllButton: {
    alignSelf: 'center',
  },
  callsContainer: {
    rowGap: 24,
  },
})
