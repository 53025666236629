import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useState } from 'react'
import { Tooltip } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName, Text } from '../base'

import { Placements } from './IconTooltip.types'

export interface IconTooltipProps {
  accessibilityLabel: string
  iconName: IconName
  message: string
  placement?: Placements
}

export const IconTooltip = ({
  accessibilityLabel,
  iconName,
  message,
  placement = 'top',
}: IconTooltipProps) => {
  const styles = useStyleSheet(themedStyle)

  const [visible, setVisible] = useState(false)

  const toggleVisibility = () => setVisible(!visible)

  return (
    <Tooltip
      anchor={() => (
        <TouchableOpacity accessibilityLabel={accessibilityLabel} onPress={toggleVisibility}>
          <Icon name={iconName} />
        </TouchableOpacity>
      )}
      visible={visible}
      onBackdropPress={toggleVisibility}
      placement={placement}
      style={styles.tooltipContainer}
    >
      {() => (
        <Text type="small" style={styles.text}>
          {message}
        </Text>
      )}
    </Tooltip>
  )
}

const themedStyle = StyleService.create({
  tooltipContainer: {
    backgroundColor: 'theme.primary.base',
  },
  text: {
    color: 'theme.tooltip.textPrimary',
  },
})
