import { UnitSystem } from '@src/screens/Settings'

export const getGlucoseUnit = (unitSystem: UnitSystem) =>
  unitSystem === UnitSystem.Imperial ? 'mg/dL' : 'mmol/L'

export const getGlucoseValue = (unitSystem: UnitSystem, valueInMgPerDl: number) =>
  unitSystem === UnitSystem.Imperial ? valueInMgPerDl : Number((valueInMgPerDl / 18.0).toFixed(1))

export const formatNutritionValue = (value: number, precision = 3) => {
  if (isNaN(value)) {
    return ''
  }

  const precisionBase = 10 ** precision
  const roundedValue = Math.round(value * precisionBase) / precisionBase

  return roundedValue.toString()
}
