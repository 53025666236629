import React from 'react'
import { View, Image, ImageStyle } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { ScrollViewWithFade } from '@src/components'
import { useHealthPermissionScreenProps } from '@src/screens/Events/utils/useHealthPermissionScreenProps'

interface HealthPermissionProps {
  width: number
}

export const HealthPermission = ({ width }: HealthPermissionProps) => {
  const styles = useStyleSheet(themedStyle)

  const {
    title,
    imageSource,
    allowButtonText,
    onAllowButtonPress,
  } = useHealthPermissionScreenProps()

  return (
    <SafeAreaView edges={['bottom']} style={[styles.container, { width }]}>
      <ScrollViewWithFade style={styles.scrollView} fadeColor={styles.container.backgroundColor}>
        <View style={styles.imageContainer}>
          <Image source={imageSource} style={styles.image as ImageStyle} />
        </View>
        <View style={styles.textContent}>
          <Text type="title-2" style={styles.title}>
            {title}
          </Text>
          <Text type="large" style={styles.description}>
            We’ll automatically integrate your activity and glucose data, so you can skip the manual
            logging and discover how you react to events at a glance.
          </Text>
          <Text type="large" style={styles.description}>
            You always control which data you share with Nutrisense.
          </Text>
        </View>
      </ScrollViewWithFade>
      <Button
        type="primary"
        size="block"
        onPress={onAllowButtonPress}
        accessibilityLabel={allowButtonText}
      >
        {allowButtonText}
      </Button>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    flex: 1,
  },
  imageContainer: {
    flexGrow: 3,
    justifyContent: 'center',
  },
  image: {
    maxWidth: 250,
    height: undefined,
    maxHeight: 130,
    alignSelf: 'center',
    aspectRatio: 16 / 9,
  },
  textContent: {
    flexGrow: 1,
    paddingTop: 16,
  },
  title: {
    textAlign: 'center',
  },
  description: {
    paddingTop: 24,
    paddingBottom: 8,
    textAlign: 'center',
  },
})
