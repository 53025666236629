import React from 'react'
import { View, TouchableOpacity, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'

export interface PageIndicatorProps {
  pages: number
  selectedIndex: number
  style?: ViewStyle
  goToSlide: (index: number) => void
}

const hitSlop = { top: 4, bottom: 4, right: 0, left: 0 }

export const PageIndicator = (props: PageIndicatorProps) => {
  const styles = useStyleSheet(themedStyle)
  const { pages, selectedIndex, style, goToSlide } = props

  const renderDot = (_: any, index: number) => {
    const dotStyle = [styles.dot, index === selectedIndex && styles.selectedDot].filter(Boolean)

    return (
      <TouchableOpacity
        onPress={() => {
          goToSlide(index)
        }}
        hitSlop={hitSlop}
        key={index}
        style={dotStyle}
        accessibilityLabel="dot"
      />
    )
  }

  const dots = Array.from({ length: pages }, renderDot)

  return <View style={[styles.dots, style]}>{dots}</View>
}

const themedStyle = StyleService.create({
  dots: {
    flexDirection: 'row',
    position: 'absolute',
    justifyContent: 'center',
  },
  dot: {
    width: 10,
    height: 10,
    backgroundColor: 'theme.surface.base1',
    borderRadius: 10,
    marginHorizontal: 4,
  },
  selectedDot: {
    backgroundColor: 'theme.primary.base',
  },
})
