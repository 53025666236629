import React, { useRef, useState } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { cgmDexcomImage, cgmLibreImage } from '@src/assets/images'
import { Button, Icon, Text } from '@components/base'
import {
  AppStackParamList,
  CommonAuthorizedStackParamList,
  OnboardingStackParamsList,
  SubscriptionsStackParamsList,
} from '@src/navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import {
  SensorModel,
  SubscriptionCheckoutOptionKind,
  SurveysConfigKind,
  SurveysUserLinkState,
} from '@src/types'
import { openUrl, useDispatchAsync, useSnack } from '@utils'
import { researchFaqsSelector, userSelector } from '@src/selectors/app'
import { ScrollViewWithFade } from '@src/components'
import { SensorOption, SensorConfig, SelectedSensor } from './SensorOption'

const FADE_BUFFER = 24

const sensorConfig: Record<SelectedSensor, SensorConfig> = {
  [SelectedSensor.Default]: {
    sensor: SelectedSensor.Default,
    name: 'Freestyle Libre 14-Day',
    highlight: 'Manually Scan Every 8 Hours',
    benefits: [
      '2 x 14-day sensors (28 days) per month',
      'Water resistant to 3ft',
      'Requires prescription',
    ],
    image: cgmLibreImage,
    model: SensorModel.LibreUs,
    checkoutOptionKind: SubscriptionCheckoutOptionKind.Prescription,
  },
  [SelectedSensor.Research]: {
    sensor: SelectedSensor.Research,
    name: 'Dexcom G7',
    highlight: 'Automatic Bluetooth Syncing',
    benefits: [
      '3 x 10-day sensors (30 days) per month',
      'Waterproof to 8ft',
      'Requires research opt-in*',
    ],
    image: cgmDexcomImage,
    model: SensorModel.DexcomG7,
    tagIcon: 'bluetooth',
    checkoutOptionKind: SubscriptionCheckoutOptionKind.Research,
  },
}

export const SensorSelection = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation<
    StackNavigationProp<AppStackParamList & OnboardingStackParamsList>
  >()
  const route = useRoute<
    | RouteProp<OnboardingStackParamsList, 'SensorSelection'>
    | RouteProp<SubscriptionsStackParamsList, 'SensorSelection'>
    | RouteProp<CommonAuthorizedStackParamList, 'SensorSelection'>
  >()

  const loadingRef = useRef(false)
  const showSnack = useSnack()

  const user = useSelector(userSelector)
  const researchFaqs = useSelector(researchFaqsSelector)

  const { product, savings, requireEligibilityCheck = false, sensorOptions = undefined } =
    route.params ?? {}

  const parsedSensorOptions = Object.values(sensorConfig)
    .map((sensor) => sensor.model)
    .filter((model) => !sensorOptions || sensorOptions.includes(model))

  const selectedSensorDefault =
    parsedSensorOptions.length === 1
      ? (Object.keys(sensorConfig).find(
          (key) => sensorConfig[key as SelectedSensor].model === parsedSensorOptions[0],
        ) as SelectedSensor)
      : SelectedSensor.Research

  const [selectedSensor, setSelectedSensor] = useState<SelectedSensor>(selectedSensorDefault)

  const isOnlyLibre =
    parsedSensorOptions?.length === 1 &&
    (parsedSensorOptions[0] === SensorModel.LibreUs ||
      parsedSensorOptions[0] === SensorModel.Libre3)

  const handleSelect = (sensor: SelectedSensor) => {
    setSelectedSensor(sensor)
  }

  const handleDataNoticePress = () => {
    openUrl(researchFaqs.dataProtectionUrl)
  }

  const handleEligibilityFAQPress = () => {
    openUrl(researchFaqs.eligibilityUrl)
  }

  const handleNextStepPress = async () => {
    if (loadingRef.current || !selectedSensor) {
      return
    }

    // We're in the checkout process
    if (product) {
      // A subscription will be provided when sensor selection is presented as part of checkout
      const checkoutOptionKinds = [sensorConfig[selectedSensor].checkoutOptionKind]

      if (requireEligibilityCheck) {
        navigation.navigate('EligibilityCheck', {
          product,
          savings,
          checkoutOptionKinds,
        })
        return
      }

      navigation.navigate('SubscriptionCheckout', {
        product,
        savings,
        checkoutOptionKinds,
      })
      return
    }

    const surveyLinks = user?.surveyLinks || []

    if (selectedSensor === SelectedSensor.Default) {
      loadingRef.current = true
      try {
        await dispatchAsync({
          type: 'app/selectSensor',
          payload: { model: sensorConfig[selectedSensor].model },
        })

        await dispatchAsync({ type: 'users/fetch' })

        const isHealthSurveyCompleted = surveyLinks.some(
          (surveyLink) =>
            surveyLink.survey.kind === SurveysConfigKind.Health && surveyLink.finished,
        )

        if (!isHealthSurveyCompleted) {
          navigation.replace('Questionnaire', { questionnaire: SurveysConfigKind.Health })
          return
        }

        const isInsuranceSurveyCompleted = surveyLinks.some(
          (surveyLink) =>
            surveyLink.survey.kind === SurveysConfigKind.Insurance && surveyLink.finished,
        )

        if (isInsuranceSurveyCompleted) {
          navigation.replace('Drawer', { screen: 'Dashboard' })
          return
        }

        navigation.replace('Questionnaire', { questionnaire: SurveysConfigKind.Insurance })
      } catch {
        showSnack('Failed to select sensor. Please try again.', null, 'warning')
      } finally {
        loadingRef.current = false
      }
    } else {
      const isMedicalHistoryQuestionnaireCompleted = surveyLinks.some(
        (surveyLink) =>
          surveyLink.survey.kind === SurveysConfigKind.MedicalHistory &&
          surveyLink.finished &&
          surveyLink.state !== SurveysUserLinkState.Stopped,
      )

      loadingRef.current = true
      try {
        await dispatchAsync({
          type: 'app/selectSensor',
          payload: { model: sensorConfig[selectedSensor].model },
        })
      } catch {
        showSnack('Failed to select sensor. Please try again.', null, 'warning')
        return
      } finally {
        loadingRef.current = false
      }

      const questionnaire = isMedicalHistoryQuestionnaireCompleted
        ? SurveysConfigKind.DexcomResearchConsent
        : SurveysConfigKind.MedicalHistory

      navigation.replace('Questionnaire', { questionnaire })
    }
  }

  return (
    <NavigationContainer
      title="Sensor Preference"
      rightAccessories={[
        {
          renderIconComponent: () => (
            <View style={styles.questionIcon}>
              <Icon name="question" />
            </View>
          ),
          onPress: () => openUrl(researchFaqs.sensorComparisonUrl),
          accessibilityLabel: 'Refresh',
        },
      ]}
    >
      <SafeAreaView style={styles.content} edges={['bottom']}>
        <ScrollViewWithFade style={styles.scrollView} scrollViewStyle={styles.scrollViewContent}>
          {isOnlyLibre && (
            <Text type="large" bold style={styles.headerText}>
              As you have opted out of the research program, we’ll get you set up with the
              prescription Libre Freestyle sensor:
            </Text>
          )}
          {parsedSensorOptions.includes(SensorModel.DexcomG7) && (
            <>
              <SensorOption
                config={sensorConfig.research}
                selected={selectedSensor === SelectedSensor.Research}
                onSelect={() => handleSelect(SelectedSensor.Research)}
              />
              <Text type="small" style={styles.dataNotice}>
                *Participant data is{' '}
                <Text type="small" onPress={handleDataNoticePress} style={styles.dataNoticeLink}>
                  de-identified and protected
                </Text>{' '}
                at all times.
              </Text>
            </>
          )}
          {(parsedSensorOptions.includes(SensorModel.LibreUs) ||
            parsedSensorOptions.includes(SensorModel.Libre3)) && (
            <SensorOption
              config={sensorConfig.default}
              selected={selectedSensor === SelectedSensor.Default}
              onSelect={() => handleSelect(SelectedSensor.Default)}
            />
          )}
          {parsedSensorOptions.length > 1 && (
            <Text type="small" style={styles.eligibilityNotice}>
              Your eligibility for your preferred sensor will be confirmed in a later step.{' '}
              <Text
                type="small"
                style={styles.eligibilityNoticeLink}
                onPress={handleEligibilityFAQPress}
              >
                Learn more here
              </Text>
              .
            </Text>
          )}
        </ScrollViewWithFade>
        <Button
          accessibilityLabel="Next"
          disabled={!selectedSensor}
          onPress={handleNextStepPress}
          style={styles.nextButton}
          size="block"
          type="primary"
        >
          Next
        </Button>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  content: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  scrollView: {
    top: -FADE_BUFFER,
  },
  scrollViewContent: {
    paddingVertical: FADE_BUFFER,
  },
  headerText: {
    marginBottom: 16,
  },
  questionIcon: {
    padding: 12,
    marginRight: 4,
  },
  dataNotice: {
    marginTop: 4,
    marginBottom: 12,
  },
  dataNoticeLink: {
    textDecorationLine: 'underline',
  },
  eligibilityNotice: {
    marginTop: 12,
  },
  eligibilityNoticeLink: {
    color: 'theme.info.base',
    textDecorationLine: 'underline',
  },
  nextButton: {
    marginTop: -FADE_BUFFER,
    marginBottom: 8,
  },
})
