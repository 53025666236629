import React, { useState, useRef } from 'react'
import { View } from 'react-native'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useRoute } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  FULL_DISPLAY_TIME_FORMAT,
  MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT,
} from '@config/momentFormat'
import { Button, Text } from '@components/base'
import {
  CircleIcon,
  InlineAlert,
  ScrollableAvoidKeyboard,
  SelectDateTime,
  SelectFromActionSheet,
} from '@components'
import { AppRouteProp } from '@navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import { MeasurementFormInputs } from '@screens/Measurements'
import { MANUAL_LOGGING_MEASUREMENT_TYPES } from '@src/screens/Measurements/constants'
import { useUnitSystemMeasurements } from '@src/screens/Measurements/hooks'
import { MeasurementFormData, MeasurementFormType } from '@screens/Measurements/types'
import {
  getFormDefaultValues,
  getFormOptions,
  getFormType,
  prepareValues,
} from '@screens/Measurements/utils'
import {
  measurementSourceDefLookupSelector,
  measurementTypeDefLookupSelector,
} from '@selectors/app'
import { lastMeasurementTypeSelector } from '@selectors/measurements'
import { showSnack } from '@services/Bluetooth/utils'
import { HealthDataMeasurementSource, HealthDataMeasurementType, UiInlineAlert } from '@src/types'
import { Global } from '@utils'
import { TIME_FORMAT } from '@utils/global'
import { useCancelModal } from '@utils/hooks'
import { useGoBack } from '@utils/navigation'

export const MeasurementModal = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const measurementUnitConfig = useUnitSystemMeasurements()

  const { params } = useRoute<AppRouteProp<'AddMeasurement'>>()
  const { externalSource, id, occurredAt, values } = params?.item || {}

  const type = params?.item?.type || params?.type

  const measurementTypes = useSelector(measurementTypeDefLookupSelector)
  const measurementSources = useSelector(measurementSourceDefLookupSelector)
  const lastMeasurementType = useSelector(lastMeasurementTypeSelector)

  const [measurementType, setMeasurementType] = useState(
    type || lastMeasurementType || HealthDataMeasurementType.Weight,
  )
  const [formType, setFormType] = useState(getFormType(measurementType))
  const [time, setTime] = useState(moment(occurredAt).format(TIME_FORMAT))
  const [sliderValue, setSliderValue] = useState(() => {
    const options = getFormOptions(measurementType)
    return values?.value || options?.sliderRange?.min || 1
  })

  const {
    control,
    formState: { isDirty, errors },
    clearErrors,
    handleSubmit,
  } = useForm<MeasurementFormData>({
    mode: 'onBlur',
    defaultValues: getFormDefaultValues(values),
  })

  const isSubmitting = useRef(false)

  const source = externalSource ? measurementSources[externalSource]?.label : null
  const { units } = measurementUnitConfig[measurementType] || {}
  const { isDailyMeasurement, label } = measurementTypes[measurementType] || {}

  const onSaveButtonPress = handleSubmit((formData) => {
    if (isSubmitting.current) {
      return
    }

    isSubmitting.current = true

    const preparedValues =
      formType === MeasurementFormType.Slider
        ? { value: sliderValue }
        : prepareValues(formData, measurementType)

    const payload = {
      id,
      type: measurementType,
      externalSource: externalSource || HealthDataMeasurementSource.Nutrisense,
      values: preparedValues,
      createdAt: moment().format(),
      occurredAt: Global.formatTime(time),

      // fake data for optimistic reducer
      __typename: 'Measurement',
      fake: true,
      isDailyMeasurement,
      title: label,
      units,
      value: preparedValues.value,
    }

    dispatch({
      payload,
      type: 'measurements/submit',
      success: goBack,
      complete: () => {
        isSubmitting.current = false
      },
    })
  })

  const onMeasurementTypeChange = (label: string) => {
    const type = Object.values(measurementTypes).find((measurement) => measurement.label === label)
      ?.key

    if (!type) {
      showSnack('This is a bug! The selected type cannot be handled', null, 'error')
      return
    }

    setMeasurementType(type)
    setFormType(getFormType(type))
    clearErrors()

    if (type === HealthDataMeasurementType.Stress) {
      const options = getFormOptions(type)
      setSliderValue(options?.sliderRange?.min || 1)
    }
  }

  const handleDateChange = (time: Date) => {
    setTime(moment(time).local().format(TIME_FORMAT))
  }

  const openCancelModal = useCancelModal({
    goBack,
    isModified: isDirty,
    itemName: 'measurement',
  })

  const isNewMeasurement = !params?.item?.id
  const displayFormat = isDailyMeasurement
    ? MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT
    : FULL_DISPLAY_TIME_FORMAT
  const measurementOptions = Object.values(measurementTypes)
    .filter((typeDef) => MANUAL_LOGGING_MEASUREMENT_TYPES.has(typeDef.key))
    .map((type) => type.label)

  return (
    <NavigationContainer
      title={isNewMeasurement ? 'Add Measurement' : 'Edit Measurement'}
      goBack={isNewMeasurement ? openCancelModal : goBack}
      leftIcon={isNewMeasurement ? { pack: 'fontAwesome6Pro', name: 'xmark' } : undefined}
    >
      <ScrollableAvoidKeyboard>
        <SafeAreaView edges={['bottom']} style={styles.container}>
          <View style={styles.content}>
            <View style={styles.row}>
              <CircleIcon name="ruler" style={styles.icon} />
              <SelectFromActionSheet
                disabled={!isNewMeasurement}
                label="Type"
                options={measurementOptions}
                placeholder="Select measurement type"
                style={styles.input}
                title="Measurement Type"
                value={label}
                onChange={onMeasurementTypeChange}
              />
            </View>
            <MeasurementFormInputs
              control={control}
              errors={errors}
              formType={formType}
              measurement={measurementType}
              onSliderChange={setSliderValue}
              sliderValue={sliderValue}
              unit={units}
            />
            <SelectDateTime
              inputProps={{
                disabled: !isNewMeasurement,
                label: isDailyMeasurement ? 'Date' : 'Time',
                placeholder: isDailyMeasurement ? 'Select date' : 'Select time',
                value: moment(time, TIME_FORMAT).format(displayFormat),
              }}
              pickerProps={{
                date: Global.toLocalTimezoneDate(time),
                mode: isDailyMeasurement ? 'date' : 'datetime',
              }}
              onChange={handleDateChange}
            />
            {isDailyMeasurement && isNewMeasurement && (
              <InlineAlert
                category={UiInlineAlert.Info}
                message={`You can only have one ${label} measurement for each day. If you log more than one for a day, the latest measurement will override the previous ${label}`}
                style={styles.alert}
              />
            )}
            {!!source && (
              <Text type="large" style={styles.sourceOfData}>
                Source of data{' '}
                <Text type="large" bold>
                  {source}
                </Text>
              </Text>
            )}
          </View>
          <Button accessibilityLabel="Save" size="block" type="primary" onPress={onSaveButtonPress}>
            Save
          </Button>
        </SafeAreaView>
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginBottom: 24,
  },
  input: {
    flex: 1,
  },
  sourceOfData: {
    marginTop: 32,
    textAlign: 'center',
    opacity: 0.5,
  },
  alert: {
    marginTop: 32,
  },
  icon: {
    marginRight: 16,
    padding: 16,
  },
})
