import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { groupBy, uniqBy, uniqueId } from 'lodash'
import { ScansList } from '@src/screens/Scans/components/ScansList'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { NavigationContainer } from '@src/screens/Common/containers'
import { eventsCalendarSelector } from '@src/selectors/events'
import { scansMeasurementsSelector } from '@src/selectors/scans'
import { Measurement, Scan } from '@src/types'
import { useLiveGlucoseValue } from '@src/utils/hooks'

const FILTER = { scope: 'graph' }
const PAGINATION = { type: 'date' }
const SORT = {
  orderBy: 'occurred_at',
  order: 'descending',
}

export const ScansContainer = () => {
  const measurements = useSelector(scansMeasurementsSelector)
  const calendar = useSelector(eventsCalendarSelector)
  const { realTimeGlucoseValue, realTimeGlucoseTime } = useLiveGlucoseValue()
  const headerItem = {
    label: 'Live Reading',
    title: 'Glucose',
    occurredAt: realTimeGlucoseTime,
    data: realTimeGlucoseValue,
    icon: 'bluetooth',
  }

  const items = useMemo(() => {
    const scans = uniqBy(
      measurements.filter(({ scan }) => scan),
      'scan.id',
    ).map(({ scan }) => scan) as Scan[]

    const items = measurements.sort(
      (a, b) => moment(b.occurredAt).unix() - moment(a.occurredAt).unix(),
    )

    scans.forEach((scan) => {
      const scanItems = items.filter((measurement) => measurement.scan?.id === scan.id)
      const [lastScanMeasurement] = scanItems as [Measurement & { lastScan?: boolean }]
      if (
        lastScanMeasurement &&
        moment(lastScanMeasurement.occurredAt).isSame(scan.createdAt, 'day')
      ) {
        lastScanMeasurement.lastScan = true
      }
    })

    const dateGroups = groupBy(items, (i) => moment(i.occurredAt).format(DATE_FORMAT))
    return Object.keys(dateGroups).map((date) => ({
      id: uniqueId(date),
      title: moment(date).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT),
      occurredAt: moment(date),
      data: dateGroups[date],
    }))
  }, [measurements])

  return (
    <NavigationContainer title="Sensor Data">
      <ScansList
        items={items}
        headerItem={realTimeGlucoseValue ? headerItem : undefined}
        name="scans"
        dataPath="measurements"
        filter={FILTER}
        sort={SORT}
        pagination={PAGINATION}
        calendar={calendar}
        useCache
      />
    </NavigationContainer>
  )
}
