import CookieManager from '@react-native-cookies/cookies'
import { Intercom, Storage } from '@utils'
import { Bugsnag, Apollo, Datadog } from '@config'
import { GoogleAuth } from '@services'
import Analytics from '@src/config/analytics'
import { Login } from '@src/models/app.types'
import { MobileAppFeature, User, UserAuth } from '@src/types'

const loginDefaults = { success: false, user: null }

// Local Storage
//
const setLocalStorage = (login: Login) => {
  const previousAdminLogin = Storage.get<Login>('admin_login')
  const previousAdminUser = previousAdminLogin?.user
  const loggedInUser = login.user

  Storage.set('login', login)

  if (hasAdminAccess(login)) {
    // do not set admin token while impersonating
    if (!previousAdminUser || previousAdminUser?.id === loggedInUser?.id) {
      Storage.set('admin_login', login)
    }
  } else {
    // when admin role was revoked
    if (loggedInUser?.email === previousAdminUser?.email) {
      Storage.set('admin_login', null)
    }
  }

  console.log(`Logged In ${loggedInUser?.role}:`, loggedInUser?.email)
}

const clearLocalStorage = () => {
  Storage.set('login', loginDefaults)
  Storage.set('admin_login', loginDefaults)
  Storage.remove('notification_token')
}

// Bugsnag & Analytics
//
const setTrackingUser = (login: Login) => {
  const loginUser = login.user as User
  const user = {
    id: loginUser.id,
    email: loginUser.email,
    name: loginUser.fullName,
  }
  const adminLogin = Storage.get<Login>('admin_login')
  if (adminLogin?.token && login.token !== adminLogin.token && adminLogin.user) {
    const adminUser = {
      id: adminLogin.user.id,
      email: adminLogin.user.email,
      name: adminLogin.user.fullName,
    }
    Bugsnag.setUser(user, adminUser)
    Datadog.setUser(user, adminUser)
    Analytics.setUser(user, adminUser)
  } else {
    Bugsnag.setUser(user)
    Datadog.setUser(user)
    Analytics.setUser(user)
  }
}

const resetBugsnag = () => {
  Bugsnag.reset()
}

const resetAnalytics = () => {
  Analytics.reset()
}

const resetDatadog = () => {
  Datadog.reset()
}

// User Callbacks
//
const onLogin = async (login: Login) => {
  await CookieManager.clearAll()
  setLocalStorage(login)
  setTrackingUser(login)
}

const onLogout = async () => {
  clearLocalStorage()
  await GoogleAuth.signOut()
  Apollo.cancelPendingRequests()
  resetAnalytics()
  resetBugsnag()
  resetDatadog()
}

const onCreate = async (login: Login) => {
  await CookieManager.clearAll()
  setLocalStorage(login)
  setTrackingUser(login)
}

const onUpdate = (user: User) => {
  let login = Storage.get<Login>('login', {} as Login)
  login = { ...login, user }

  setLocalStorage(login)
  setTrackingUser(login)
  Intercom.updateUser(user)
}

const onFetch = (user: User) => {
  let login = Storage.get<Login>('login', {} as Login)

  login = { ...login, user }

  setLocalStorage(login)
  setTrackingUser(login)
}

// Impersonation
//
const isImpersonating = () => {
  const login = Storage.get<Login>('login')
  const adminLogin = Storage.get<Login>('admin_login')

  return adminLogin?.token ? login?.token !== adminLogin.token : false
}

const hasFeature = (feature: MobileAppFeature) => {
  const login = Storage.get<Login>('login')
  const adminLogin = Storage.get<Login>('admin_login')

  const activeUser = adminLogin?.user ?? login?.user
  return !!activeUser?.features?.includes(feature)
}

const hasAdminAccess = (login: UserAuth) => login.features?.includes(MobileAppFeature.AdminAccess)

export default {
  hasFeature,
  isImpersonating,
  onLogin,
  onLogout,
  onFetch,
  onCreate,
  onUpdate,
}
