import { gql } from '@apollo/client'
import { SURVEY_LINK } from './fragments/surveyLink'

export const FETCH_QUESTIONNAIRE = gql`
  ${SURVEY_LINK}
  query allSurveyLinks {
    allSurveyLinks {
      surveyLinks {
        ...SurveyLink
        completionDeepLink
      }
    }
  }
`
