import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Intercom } from '@utils'
import { DrawerParamList } from '@src/navigation/types'
import { useAllowDietitianSupport } from '@src/utils/hooks'
import { Text } from '@components/base'
import { ActionsMenuWrapperModal } from './ActionsMenuWrapperModal'

interface RouteInfo {
  text: string
  onPress?: () => void
}

export const IntercomMenuModal = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<DrawerParamList>>()
  const hasDietitianSupport = useAllowDietitianSupport()

  const routes: RouteInfo[] = [
    {
      text: 'My Conversations',
      onPress: () =>
        Intercom.showIntercomMessenger({
          source: 'IntercomMenu',
          space: Intercom.Space.messages,
        }),
    },
    {
      text: 'Customer Support',
      onPress: () =>
        Intercom.showIntercomMessenger({ source: 'IntercomMenu', space: Intercom.Space.home }),
    },
  ]

  if (!hasDietitianSupport) {
    routes.splice(1, 0, {
      text: 'New Nutrition Coaching Request',
      onPress: () => navigation.replace('Subscriptions'),
    })
  }

  return (
    <ActionsMenuWrapperModal>
      {routes.map(({ text, onPress }, index) => (
        <TouchableOpacity
          key={text}
          style={[styles.action, { borderBottomWidth: index === routes.length - 1 ? 0 : 0.5 }]}
          activeOpacity={0.6}
          onPress={onPress}
          accessibilityLabel={text}
        >
          <Text type="regular" bold>
            {text}
          </Text>
        </TouchableOpacity>
      ))}
    </ActionsMenuWrapperModal>
  )
}

const themedStyle = StyleService.create({
  action: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,
    borderColor: 'theme.surface.base2',
  },
})
