import React from 'react'
import NoData from '@src/components/charts/NoData/NoData'
import { CommonChart } from '../../CommonChart'
import { ByDateColumnChartData } from './types'
import { usePrimaryColumnChartFunction } from './usePrimaryColumnChartFunction'

interface ByDateColumnChartProps {
  data: ByDateColumnChartData
  yLabel: string
  height?: number
}

export const ByDateColumnChart = ({ data, yLabel, height = 200 }: ByDateColumnChartProps) => {
  const hasNoData = data.every(({ y }) => !y)
  const chartRenderFunction = usePrimaryColumnChartFunction({ data, yLabel })

  if (hasNoData) {
    return <NoData style={{ height, flexBasis: height }} text="No Data Available" />
  }

  return <CommonChart height={height} chartScript={chartRenderFunction} />
}
