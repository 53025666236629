import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconDescriptor, Section, TouchableSection } from '@components'
import { dailyMeasurementTypeDefsSelector } from '@selectors/app'
import { HealthDataMeasurementType } from '@src/types'
import { IconPack } from '@src/components/Icon'

export const TypesSection = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const typeDefs = useSelector(dailyMeasurementTypeDefsSelector)

  const onTypePress = (measurementType: HealthDataMeasurementType) => {
    navigation.navigate('SourcePriorityList', { measurementType })
  }

  return (
    <>
      <Section title="Assign source priorities" style={styles.section} />
      {typeDefs.map((typeDef) => (
        <TouchableSection
          key={typeDef.key}
          iconDescriptor={typeDef.iconDef as IconDescriptor<IconPack> | undefined}
          iconStyle={styles.typeIcon}
          iconContainerStyle={styles.typeIconContainer}
          iconSolid
          title={typeDef.label}
          showRightArrow
          style={styles.typeSection}
          onPress={() => onTypePress(typeDef.key)}
        />
      ))}
    </>
  )
}

const themedStyle = StyleService.create({
  section: {
    height: 48,
    backgroundColor: 'theme.surface.base2',
  },
  typeSection: {
    borderBottomWidth: 1,
    borderBottomColor: 'theme.surface.base2',
  },
  typeIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 40,
    marginRight: 16,
    borderRadius: 8,
    backgroundColor: 'theme.surface.base2',
  },
  typeIcon: {
    marginRight: 0,
    height: 16,
    width: 16,
    color: 'theme.primary.base',
  },
})
