import { gql } from '@apollo/client'
import { SURVEY_LINK } from './fragments/surveyLink'

export const START_QUESTIONNAIRE = gql`
  ${SURVEY_LINK}
  mutation startSurvey($kind: String!) {
    startSurvey(kind: $kind) {
      ...SurveyLink
    }
  }
`
