import { useEffect, useRef, useState, MutableRefObject } from 'react'
import { LayoutChangeEvent } from 'react-native'
import { isEqual } from 'lodash'
import { ChartData } from '@src/screens/Events/models/events.types'
import { usePrevious } from '@src/utils/hooks'
import { useIsDarkMode } from '@src/config/theme'
import { buildPrimaryValues, getMultisliderMinMax } from './utils'

const reloadCharts = (...chartRefs: any) => {
  chartRefs.forEach((chartRef: any) => {
    chartRef.current?.chart?.current?.reload()
  })
}

const filterData = (data: ChartData, hasChartData: boolean, multiSliderValue: [number, number]) => {
  if (!hasChartData) {
    return data
  }

  const startTimestamp = multiSliderValue[0]
  const endTimestamp = multiSliderValue[1]

  return {
    ...data,
    // Filter by x in primary, and by w in secondary.
    values: data.values.filter((item) => {
      const timestamp = item.x.unix()
      return startTimestamp <= timestamp && timestamp <= endTimestamp
    }),
  }
}

interface UseSliderFilteredChartProps {
  chartRef: MutableRefObject<any>
  sliderChartRef: MutableRefObject<any>
  data: ChartData
  chartData: ChartData
  hasChartData: boolean
  isChartVisible: boolean
  initialMultiSliderValue?: [number, number]
  multiSliderValuesChange?: (values: [number, number]) => void
  placeholder: string | null
}

export const useSliderFilteredChart = ({
  chartRef,
  sliderChartRef,
  data,
  chartData,
  hasChartData,
  isChartVisible,
  initialMultiSliderValue,
  multiSliderValuesChange,
  placeholder,
}: UseSliderFilteredChartProps) => {
  const [sliderLength, setSliderLength] = useState<number | null>(null)
  const [multiSliderMinMax] = useState(() => getMultisliderMinMax(data.values))
  const [minSliderValue, setMinSliderValue] = useState(multiSliderMinMax.minMoment)
  const [maxSliderValue, setMaxSliderValue] = useState(multiSliderMinMax.maxMoment)
  const [multiSliderValue, setMultiSliderValue] = useState<[number, number]>(
    initialMultiSliderValue || [minSliderValue, maxSliderValue],
  )

  const isDarkMode = useIsDarkMode()
  const primaryValues = buildPrimaryValues(data)
  const prevPrimaryValues = usePrevious(primaryValues)
  const valuesChanged = !!prevPrimaryValues && !isEqual(primaryValues, prevPrimaryValues)

  // this dependency changes its value (false => true and vice a versa) only when values changed
  const valuesDependency = useRef(valuesChanged)
  if (valuesChanged) {
    valuesDependency.current = !valuesDependency.current
  }

  useEffect(() => {
    // refresh the webview only when some data or theme changes, otherwise use chart own update function
    const { minMoment, maxMoment } = getMultisliderMinMax(data.values)
    const graphIsFiltered =
      !multiSliderValue.includes(minSliderValue) || !multiSliderValue.includes(maxSliderValue)
    const newMultiSliderValue: [number, number] = graphIsFiltered
      ? multiSliderValue
      : [minMoment, maxMoment]

    if (minSliderValue !== minMoment) {
      setMinSliderValue(minMoment)
    }
    if (maxSliderValue !== maxMoment) {
      setMaxSliderValue(maxMoment)
    }
    if (!isEqual(multiSliderValue, newMultiSliderValue) && newMultiSliderValue.length) {
      setMultiSliderValue(newMultiSliderValue)

      if (multiSliderValuesChange) {
        multiSliderValuesChange(newMultiSliderValue)
      }
    }

    isChartVisible && reloadCharts(chartRef, sliderChartRef)
    // depend on valuesDependency instead of values to avoid extra re-renders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chartRef,
    maxSliderValue,
    minSliderValue,
    multiSliderValue,
    multiSliderValuesChange,
    valuesDependency.current,
    isDarkMode,
    isChartVisible,
    placeholder, // re-render chart on placeholder change
  ])

  const onMultiSliderValuesChange = (values: any, shouldReloadChart = true) => {
    if (isEqual(multiSliderValue, values)) {
      return
    }

    setMultiSliderValue(values)

    if (multiSliderValuesChange) {
      multiSliderValuesChange(values)
    }

    if (!shouldReloadChart) {
      return
    }

    reloadCharts(chartRef)
  }

  const onLayoutChange = ({
    nativeEvent: {
      layout: { width },
    },
  }: LayoutChangeEvent) => {
    const newSliderLength = width

    if (newSliderLength && sliderLength !== newSliderLength) {
      setSliderLength(newSliderLength)
    }

    reloadCharts(chartRef, sliderChartRef)
  }

  const filteredData = filterData(chartData, hasChartData, multiSliderValue)

  return {
    filteredData,
    onMultiSliderValuesChange,
    onLayoutChange,
    sliderLength,
    minSliderValue,
    maxSliderValue,
    multiSliderValue,
  }
}
