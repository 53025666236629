import React from 'react'
import { ScrollView } from 'react-native'
import { FadeFromBottomModal } from '@components'
import { Button } from '@components/base'

interface SettingsModalShared {
  children: React.ReactNode
  onSaveButtonPress: () => void
  onCancelButtonPress: () => void
  title?: string
}

export const SettingsModalSharedContainer = ({
  children,
  onSaveButtonPress,
  onCancelButtonPress,
  title,
}: SettingsModalShared) => (
  <ScrollView contentContainerStyle={{ flexGrow: 1 }} bounces={false}>
    <FadeFromBottomModal title={title} closeModal={onCancelButtonPress}>
      {children}
      <Button type="primary" size="block" accessibilityLabel="Save" onPress={onSaveButtonPress}>
        Save
      </Button>
    </FadeFromBottomModal>
  </ScrollView>
)
