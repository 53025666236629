import { createSelector } from 'reselect'
import moment from 'moment'
import { RootStoreState } from '@src/models/app.types'
import { interpolateEvents } from '@src/screens/Events/transforms/chart'
import { dailyMeasurementTypeDefLookupSelector } from './app'

export const eventsStoreStateSelector = (state: RootStoreState) => state.events

export const eventsObjectsSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.events,
)

export const eventsNutritionSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.nutrition,
)

export const eventsStatisticsSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.statistics,
)

export const eventsScoreSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.score,
)

const chartsSelector = createSelector(eventsStoreStateSelector, (events) => events.charts)

export const eventsChartsSelector = createSelector(
  [eventsObjectsSelector, chartsSelector, dailyMeasurementTypeDefLookupSelector],
  (events, charts, dailyMeasurementTypeDefLookup) => {
    const { primary, secondary } = charts

    const primaryValuesWithMoment = primary.values.map((value) => ({
      ...value,
      x: moment(value.x),
    }))

    const secondaryValuesWithMoment = secondary.values.map((value) => ({
      ...value,
      x: moment(value.x),
    }))

    const eventsWithoutDailyMeasurements = events.filter(
      (event) => !('type' in event) || !dailyMeasurementTypeDefLookup[event.type],
    )

    const interpolatedPrimaryEvents = interpolateEvents(
      primaryValuesWithMoment,
      eventsWithoutDailyMeasurements,
      primary.range,
    )

    if (secondary) {
      const interpolatedSecondaryEvents = interpolateEvents(
        secondaryValuesWithMoment,
        eventsWithoutDailyMeasurements,
        secondary.range,
      )

      return {
        ...charts,
        primary: { ...primary, values: primaryValuesWithMoment.concat(interpolatedPrimaryEvents) },
        secondary: {
          ...secondary,
          values: secondaryValuesWithMoment.concat(interpolatedSecondaryEvents),
        },
      }
    }

    return {
      ...charts,
      primary: { ...primary, values: primary.values.concat(interpolatedPrimaryEvents) },
    }
  },
)

export const eventsCalendarSelector = createSelector(
  eventsStoreStateSelector,
  (events) => events.calendar,
)
