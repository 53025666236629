import React from 'react'
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack'
import {
  AdminModal,
  PasswordResetRequestScreen,
  PasswordResetRequestSuccessScreen,
  PasswordResetScreen,
  SignInOptionsScreen,
  SignInScreen,
  SignUpScreen,
} from '@screens'
import { useReducedMotion } from '@src/config/accessibility'
import { fadeFromBottomModalOptions } from '../utils'
import { AuthStackParamList } from '../types'

const AuthStack = createStackNavigator<AuthStackParamList>()

export const AuthNavigator = () => {
  const reduceMotionEnabled = useReducedMotion()

  const screenOptions = {
    headerShown: false,
    animationEnabled: !reduceMotionEnabled,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  }

  return (
    <AuthStack.Navigator initialRouteName="SignInOptions" screenOptions={screenOptions}>
      <AuthStack.Screen name="SignInOptions" component={SignInOptionsScreen} />
      <AuthStack.Screen name="SignIn" component={SignInScreen} />
      <AuthStack.Screen name="SignUp" component={SignUpScreen} />
      <AuthStack.Screen name="PasswordResetRequest" component={PasswordResetRequestScreen} />
      <AuthStack.Screen name="PasswordReset" component={PasswordResetScreen} />
      <AuthStack.Screen
        name="PasswordResetRequestSuccess"
        component={PasswordResetRequestSuccessScreen}
      />
      <AuthStack.Screen
        name="ShowAdmin"
        component={AdminModal}
        options={fadeFromBottomModalOptions}
      />
    </AuthStack.Navigator>
  )
}
