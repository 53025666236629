import { gql } from '@apollo/client/core'

const ACTIVATE_DISCOUNT = gql`
  query promotionCode($code: String!, $product: BillingProduct) {
    promotionCode(code: $code, product: $product) {
      providerId
      applicable
      code
      percentOff
      amountOff
    }
  }
`

export default ACTIVATE_DISCOUNT
