/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export const MATERIAL_ICONS = 'material' as const

/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export enum MaterialPack {
  Timeline = 'timeline',
  RadioButtonChecked = 'radio-button-checked',
  Create = 'create',
  DirectionsWalk = 'directions-walk',
  Restaurant = 'restaurant',
  Tune = 'tune',
  Receipt = 'receipt',
  History = 'history',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  Edit = 'edit',
  Delete = 'delete',
  Fullscreen = 'fullscreen',
  ExpandMore = 'expand-more',
}

/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export type MaterialIcon = MaterialPack[keyof MaterialPack]

/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export const materialIcon = (name: MaterialIcon) => ({ name, pack: MATERIAL_ICONS })
