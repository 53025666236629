import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { AppStackParamList } from '@src/navigation/types'
import { Debug } from '@config'
import { LessonItemContentProps } from '../../lesson.types'
import { programWithContentSelector } from '../../models/courses.selectors'
import {
  LessonItemContentPromptAction,
  LessonItemContentPromptActionType,
} from '../../models/courses.types'
import { useLessonCompleted } from '../../hooks'
import { LessonItemContainer } from './LessonItemContainer'

export const PromptComponent = (props: LessonItemContentProps) => {
  const { item, onComplete } = props
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const styles = useStyleSheet(themedStyles)
  const program = useSelector(programWithContentSelector(props.programId))

  useLessonCompleted(onComplete)

  const actions = Debug.shouldEnableIntercom()
    ? item.actions
    : item.actions.filter(
        ({ action }: LessonItemContentPromptAction) =>
          action !== LessonItemContentPromptActionType.Question,
      )

  const onActionPress = (action: LessonItemContentPromptAction) => {
    const linkQueryParams = `?utm_source=${program.title
      .toLowerCase()
      .split(' ')
      .join('-')}-course&utm_medium=ns-mobile-app`

    switch (action.action) {
      case LessonItemContentPromptActionType.Link:
        return navigation.navigate('WebViewModal', {
          uri: `${action.actionTarget}${linkQueryParams}`,
        })
      case LessonItemContentPromptActionType.Question:
        return navigation.navigate('IntercomMenu')
    }
  }

  return (
    <LessonItemContainer {...props}>
      <View style={styles.container}>
        <View>
          <Icon name="confetti" style={styles.congratsIcon} />
          <Text type="title-2" style={styles.congratsTitle}>
            {item.title}
          </Text>
          <Text type="large" style={styles.congratsSubtitle}>
            {item.subtitle}
          </Text>
        </View>
        <View>
          {actions.map((action: LessonItemContentPromptAction) => (
            <TouchableOpacity
              key={action.id}
              accessibilityLabel={action.title}
              style={styles.action}
              onPress={() => onActionPress(action)}
            >
              <Icon name={action.action === 'link' ? 'question' : 'chat-text'} />
              <View style={styles.actionContent}>
                <Text type="large" bold>
                  {action.title}
                </Text>
                <Text type="regular" style={styles.actionDescription}>
                  {action.description}
                </Text>
              </View>
              <Icon name="caret-right" style={styles.actionArrowIcon} />
            </TouchableOpacity>
          ))}
        </View>
      </View>
    </LessonItemContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    justifyContent: 'space-evenly',
  },
  congratsIcon: {
    alignSelf: 'center',
    width: 48,
    height: 48,
    color: 'theme.text.secondary',
  },
  congratsTitle: {
    marginTop: 16,
    marginBottom: 24,
    textAlign: 'center',
  },
  congratsSubtitle: {
    textAlign: 'center',
  },
  action: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    minHeight: 88,
    marginBottom: 16,
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base2',
  },
  actionContent: {
    flex: 1,
    marginHorizontal: 16,
  },
  actionDescription: {
    color: 'theme.text.secondary',
  },
  actionArrowIcon: {
    width: 16,
    height: 16,
  },
})
