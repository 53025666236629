import { gql } from '@apollo/client'
import { CORE_SENSOR_FIELDS } from '@src/graphql/fragments/sensor'

export const UPDATE_SENSOR_SETTINGS = gql`
  ${CORE_SENSOR_FIELDS}
  mutation updateSensorSettings($baselineAdjustment: Int!) {
    updateSensorSettings(baselineAdjustment: $baselineAdjustment) {
      ...CoreSensorFields
    }
  }
`
