import { useTheme } from '@ui-kitten/components'
import { RangeItem } from '@src/screens/Insights/types'
import { getRatingColor, getRatingTitle, getValueRating } from '@src/utils/ratings'

export const useRangeRepresentations = (ranges: RangeItem[], value?: number | null) => {
  const theme = useTheme()

  if (!value) {
    return {}
  }

  const rating = getValueRating(value, ranges)

  if (!rating) {
    return {}
  }

  return {
    rangeTitle: getRatingTitle(rating),
    rangeColor: theme[getRatingColor(rating)],
  }
}
