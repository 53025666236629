import React, { MutableRefObject, useRef } from 'react'
import { RefCallBack } from 'react-hook-form'
import { Autocomplete, AutocompleteItem } from '@ui-kitten/components'
import { Text } from '@components/base'

interface AddressAutoCompleteProps {
  onChange: (text: string) => void
  onBlur?: () => void
  options: { text: string; value: string }[]
  value: string
  onSelect?: (idx: number) => void
  isInvalid: boolean
  label: string
  placeholder: string
  fieldRef: RefCallBack
}

export const AddressAutoComplete = ({
  onChange,
  onSelect,
  onBlur,
  options,
  value,
  isInvalid,
  label,
  fieldRef,
  placeholder,
}: AddressAutoCompleteProps) => {
  const autocompleteRef = useRef() as MutableRefObject<Autocomplete | null>

  return (
    <Autocomplete
      ref={(e) => {
        if (typeof fieldRef === 'function') {
          fieldRef(e)
        }
        autocompleteRef.current = e
      }}
      placeholder={placeholder}
      value={value}
      label={label}
      status={isInvalid ? 'danger' : 'basic'}
      onChangeText={onChange}
      onBlur={onBlur}
      onSelect={(idx) => {
        autocompleteRef.current?.blur()
        if (onSelect) {
          onSelect(idx)
        } else {
          onChange(options[idx].value)
        }
      }}
    >
      {options.map((option) => (
        <AutocompleteItem key={option.value}>
          <Text type="regular">{option.text}</Text>
        </AutocompleteItem>
      ))}
    </Autocomplete>
  )
}
