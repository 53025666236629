import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { Radio, Text } from '@components/base'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Interval } from '@src/types'

interface OptionProps {
  title: string
  description: string
  price: number
  interval: Interval
  selected: boolean
  onSelect: () => void
}

export const Option = ({
  title,
  description,
  price,
  interval,
  selected,
  onSelect,
}: OptionProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <TouchableOpacity
      accessibilityLabel={title}
      onPress={onSelect}
      style={[styles.container, selected && styles.selectedContainer]}
    >
      <View style={styles.heading}>
        <Text type="title-3">{title}</Text>
        <View style={styles.headingRight}>
          <View style={styles.price}>
            <Text type="large" bold>
              {`$${price}`}
            </Text>
            <Text type="regular">/{interval.suffix}</Text>
          </View>
          <Radio style={styles.radio} checked={selected} onPress={onSelect} />
        </View>
      </View>
      <Text type="regular" style={styles.description}>
        {description}
      </Text>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    marginBottom: 8,
    padding: 12,
    borderRadius: 24,
    borderWidth: 2,
    borderColor: 'theme.surface.base2',
    backgroundColor: 'theme.surface.base2',
  },
  selectedContainer: {
    borderColor: 'theme.primary.base',
    backgroundColor: 'theme.background',
  },
  heading: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headingRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  price: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  radio: {
    marginLeft: 16,
  },
  description: {
    marginTop: 4,
    color: 'theme.text.secondary',
  },
})
