import React from 'react'
import { View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { LoadingIndicator } from '@components'
import { CompareMealsChart, MealItem } from '@screens/Meals/components/CompareMeals'
import { useMeals, useMealsComparisonChartData } from '@screens/Meals/hooks'
import { getCompareMealsChartColors } from '@screens/Meals/utils'
import { StyleService, useStyleSheet } from '@src/style/service'

interface ComparisonDetailsProps {
  disableChartAnimation?: boolean
  mealIds: string[]
  onLoad?: () => void
}

export const ComparisonDetails = ({
  disableChartAnimation,
  mealIds,
  onLoad,
}: ComparisonDetailsProps) => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)

  const { meals } = useMeals(mealIds)
  const { chartData } = useMealsComparisonChartData(meals)

  const chartColors = getCompareMealsChartColors(theme)

  return (
    <>
      <View style={styles.chartContainer}>
        {chartData === null ? (
          <LoadingIndicator size="large" viewStyles={styles.loadingContainer} />
        ) : (
          <CompareMealsChart
            disableAnimation={disableChartAnimation}
            onLoad={onLoad}
            {...chartData}
          />
        )}
      </View>
      {meals.map((meal, index) => (
        <View key={meal.id} style={[styles.mealItem, { borderColor: chartColors[index] }]}>
          <MealItem meal={meal} showDate />
        </View>
      ))}
    </>
  )
}

const themedStyle = StyleService.create({
  chartContainer: {
    height: 210,
    marginBottom: 8,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  mealItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    borderWidth: 2,
    borderRadius: 8,
  },
})
