import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { productsSelector } from '@src/selectors/app'
import { Feature, useFeatureFlag } from '@src/components'
import { useGroupedProducts } from '@src/screens/Marketplace/utils/hooks'
import { isProduct } from '@src/screens/Marketplace/types/types'
import { useShouldShowPurchaseFlow } from '@src/utils/hooks'
import { BillingProduct } from '@src/types'
import { OWN_SENSOR_PRODUCTS } from '@src/screens/Events/utils/hooks'

export const useSignUpProducts = (options: { reloadProducts?: boolean } = {}) => {
  const { reloadProducts = false } = options

  const dispatch = useDispatch()
  const isByosFreeExperimentEnabled = useFeatureFlag(Feature.BYOSFreeExperiment)
  const [productsLoaded, setProductsLoaded] = useState(false)

  const showPurchaseFlow = useShouldShowPurchaseFlow()

  const products = useSelector(productsSelector)
  const coreProducts = products.filter(
    (product) =>
      product.core &&
      (!showPurchaseFlow || !OWN_SENSOR_PRODUCTS.includes(product.key as BillingProduct)),
  )
  const groupedProducts = useGroupedProducts(coreProducts)
  const filteredProducts = groupedProducts.filter(
    (product) => product.key !== BillingProduct.FreeSoftwareOnlyOwnSensorAnnualMembership,
  )

  const byosProductKey = isByosFreeExperimentEnabled
    ? BillingProduct.FreeSoftwareOnlyOwnSensorAnnualMembership
    : BillingProduct.SoftwareOnlyOwnSensorAnnualMembership

  const byosProduct =
    products.find((product) => product.key === byosProductKey) ||
    products.find((product) => product.key === BillingProduct.SoftwareOnlyOwnSensorAnnualMembership)

  const requiresPlanSelection =
    groupedProducts.length > 1 || groupedProducts.some((group) => isProduct(group))

  useEffect(() => {
    if (!reloadProducts) {
      return
    }
    dispatch({
      type: 'app/fetchProducts',
      complete: () => {
        setProductsLoaded(true)
      },
    })
  }, [dispatch, reloadProducts])

  return {
    groupedProducts: filteredProducts,
    byosProduct,
    showPurchaseFlow,
    requiresPlanSelection,
    loading: !productsLoaded,
  }
}
