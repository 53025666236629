import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { IconProps } from 'phosphor-react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName, Text, TextProps } from '@components/base'

interface ActionRequiredContainerProps {
  subtitle: string
  subtitleProps?: TextProps
  description: string
  iconName?: IconName
  iconProps?: Omit<IconProps, 'name'>
  children: React.ReactNode | React.ReactNode[]
}

const ActionRequiredContainer = ({
  subtitle,
  subtitleProps = { type: 'large' },
  description,
  iconName,
  iconProps,
  children,
}: ActionRequiredContainerProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <SafeAreaView edges={['bottom']} style={styles.container}>
      {iconName && <Icon name={iconName} style={styles.icon} {...iconProps} />}
      <Text type="title-2" style={styles.subtitle}>
        {subtitle}
      </Text>
      <Text {...subtitleProps} style={styles.description}>
        {description}
      </Text>
      {children}
    </SafeAreaView>
  )
}

export default ActionRequiredContainer

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 48,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  subtitle: {
    marginTop: 24,
    maxWidth: 260,
    alignSelf: 'center',
    textAlign: 'center',
  },
  description: {
    alignSelf: 'center',
    marginTop: 16,
    marginBottom: 32,
    maxWidth: 260,
    textAlign: 'center',
  },
  icon: {
    marginTop: 80,
    alignSelf: 'center',
    width: 48,
    height: 48,
    color: 'theme.accent.moonstone',
    borderWidth: 2,
    borderColor: 'theme.background',
    borderRadius: 24,
    backgroundColor: 'theme.background',
    overflow: 'hidden',
  },
})
