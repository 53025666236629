import React from 'react'
import { ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { ScoreBreakdown } from '@components'
import { statsSelector } from '@screens/Insights/models/insights.selectors'
import { EntryStatistics, StatMeta } from '@src/types'

interface StatScoresProps {
  statistics: EntryStatistics
  style?: ViewStyle
}

interface StatConfig {
  stat: keyof EntryStatistics
  configKey: StatMeta['key']
  title: string
}

const DISPLAYED_STATS: StatConfig[] = [
  { stat: 'glucoseScorePeak', configKey: 'meals_peak_average', title: 'Peak' },
  { stat: 'glucoseScoreAuc', configKey: 'meals_exposure_average', title: 'Exposure' },
  { stat: 'glucoseScoreDelta', configKey: 'meals_stability_average', title: 'Stability' },
  { stat: 'glucoseScore2hrDelta', configKey: 'meals_recovery_average', title: 'Recovery' },
]

export const StatScores = ({ statistics, style }: StatScoresProps) => {
  const stats = useSelector(statsSelector)

  const scoreValues = DISPLAYED_STATS.map(({ stat, configKey, title }) => {
    const value = statistics[stat] as number
    const { description = '' } = stats.find((item) => item.meta.key === configKey) || {}

    return {
      key: configKey,
      title,
      description,
      value,
    }
  })

  return <ScoreBreakdown values={scoreValues} size="small" style={style} />
}
