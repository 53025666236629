import React, { useState } from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Input } from '@components/base'
import { validateIntegerNumberInput } from '@src/utils/validators'
import { InputPropsBase } from './types'
import { useSetDefaultValue } from './Shared/hooks'

const FEET_IN_INCHES = 12

const MAX_HEIGHT_IN_INCHES = 9
const MIN_HEIGHT_IN_INCHES = 3

export const HeightImperialInput = ({ field }: InputPropsBase): JSX.Element => {
  useSetDefaultValue({ fieldValue: field.value, onChange: field.onChange, defaultValue: null })

  const { onChange, value } = field
  const styles = useStyleSheet(themedStyles)
  const heightInInchesFromAnswerValue = Number(value) || 0

  const feetFromAnswerValue = Math.floor(heightInInchesFromAnswerValue / FEET_IN_INCHES)
  const inchesFromAnswerValue = heightInInchesFromAnswerValue % FEET_IN_INCHES

  const [feet, setFeet] = useState((feetFromAnswerValue || '').toString())
  const [inches, setInches] = useState((inchesFromAnswerValue || '').toString())

  const handleFeetValueChange = (value: string) => {
    const processedValue = validateIntegerNumberInput(value)

    if (processedValue === null) {
      return
    }

    const numericValue = Number(processedValue)
    if (numericValue >= MAX_HEIGHT_IN_INCHES) {
      return
    }

    setFeet(processedValue)

    if (Number(processedValue) >= MIN_HEIGHT_IN_INCHES) {
      onChange(`${Number(processedValue) * FEET_IN_INCHES + Number(inches)}`)
    } else {
      onChange(null)
    }
  }

  const handleInchesValueChange = (value: string) => {
    const processedValue = validateIntegerNumberInput(value)

    if (processedValue === null) {
      return
    }

    const numericValue = Number(processedValue)
    if (numericValue >= FEET_IN_INCHES) {
      return
    }

    setInches(processedValue)
    if (Number(feet) >= MIN_HEIGHT_IN_INCHES) {
      onChange(`${Number(feet) * FEET_IN_INCHES + Number(processedValue)}`)
    } else {
      onChange(null)
    }
  }

  return (
    <View style={styles.container}>
      <Input
        placeholder="Enter value"
        label="Feet"
        value={feet}
        onChangeText={handleFeetValueChange}
        keyboardType="numeric"
        style={styles.input}
      />
      <Input
        placeholder="Enter value"
        label="Inches"
        value={inches}
        onChangeText={handleInchesValueChange}
        keyboardType="numeric"
        style={styles.input}
      />
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    gap: 16,
  },
  input: {
    flex: 1,
  },
})
