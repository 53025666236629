import { gql } from '@apollo/client'
import { SURVEY_LINK } from './fragments/surveyLink'

export const COMPLETE_SURVEY = gql`
  ${SURVEY_LINK}
  mutation completeSurvey($id: ID!) {
    completeSurvey(id: $id) {
      ...SurveyLink
      completionDeepLink
    }
  }
`
