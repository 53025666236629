import { HealthDataMeasurementType } from '@src/types'

// TODO we should consider providing this as part of TypeDef
export const MANUAL_LOGGING_MEASUREMENT_TYPES = new Set([
  HealthDataMeasurementType.BloodGlucose,
  HealthDataMeasurementType.BloodKetones,
  HealthDataMeasurementType.BreathKetones,
  HealthDataMeasurementType.BloodPressure,
  HealthDataMeasurementType.BodyFatPercentage,
  HealthDataMeasurementType.BodyTemperature,
  HealthDataMeasurementType.CyclingDistance,
  HealthDataMeasurementType.RunningDistance,
  HealthDataMeasurementType.FlightsClimbed,
  HealthDataMeasurementType.StepCount,
  HealthDataMeasurementType.ExerciseTime,
  HealthDataMeasurementType.Weight,
])
