import { isArray, startCase } from 'lodash'
import { fromKeyValuePairs, toKeyValuePairs } from '@transforms'
import { Ingredient } from '@src/types'

export const toUnderscore = (name: string) => {
  return name.replace(/([A-Z])/g, '_$1').toLowerCase()
}

export const toWord = (name: string) => {
  return startCase(name.replace(/([A-Z])/g, ' $1'))
}

export const transform = (item: Ingredient) => {
  return {
    ...item,
    nutrition: isArray(item.nutrition) ? fromKeyValuePairs(item.nutrition) : item.nutrition,
  }
}

export const transformPayload = (item: Ingredient) => {
  return { ...item, nutrition: toKeyValuePairs(item.nutrition) }
}
