import { gql } from '@apollo/client'

const FETCH_HEALTH_METRICS = gql`
  query healthMetrics {
    healthMetrics {
      healthMetrics {
        id
        kind
        value
        date
      }
      measurements {
        id
        values {
          ... on BloodPressureMeasurement {
            systolic
            diastolic
          }
          ... on SingleValueMeasurement {
            value
          }
        }
        value
        occurredAt
        description
        title
        externalSource
        type
        units
        createdAt
      }
    }
  }
`

export default FETCH_HEALTH_METRICS
