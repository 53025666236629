import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { DEFAULT_GOALS } from '@src/components/nutritionCard/utils'
import { macroGoalsDailySelector, showNetCarbsStoreStateSelector } from '@src/selectors/settings'
import CustomSetting from './CustomSetting'

const MacroGoalSetting = () => {
  const navigation = useNavigation()

  const macroGoalsDaily = useSelector(macroGoalsDailySelector)

  const showNetCarbs = useSelector(showNetCarbsStoreStateSelector)

  const { calories, protein, carbs, fat } = macroGoalsDaily

  let netCarbs = macroGoalsDaily.netCarbs || DEFAULT_GOALS.netCarbs
  if (carbs < netCarbs) {
    netCarbs = carbs
  }

  const text = `${calories}cal / ${protein}g / ${carbs}g${
    showNetCarbs ? ` / ${netCarbs}g` : ''
  } / ${fat}g`

  const onPress = () => {
    navigation.navigate('ChangeMacroGoals', { source: 'UserSettings' })
  }

  return <CustomSetting title="Daily Macro Goals" onPress={onPress} text={text} />
}

export default MacroGoalSetting
