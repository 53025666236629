import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { Feature, useFeatureFlag } from '@src/components'
import { ScoreArcIndicator, ScoreArcIndicatorProps } from './ScoreArcIndicator'

interface ScoreArcIndicatorWithSeeDetailsButtonProps extends ScoreArcIndicatorProps {
  onPressSeeDetails: () => void
}

export const ScoreArcIndicatorWithSeeDetailsButton = ({
  onPressSeeDetails,
  ...arcIndicatorProps
}: ScoreArcIndicatorWithSeeDetailsButtonProps) => {
  const styles = useStyleSheet(themedStyles)

  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)

  return (
    <View>
      <ScoreArcIndicator {...arcIndicatorProps} />
      {userGoalsEnabled ? (
        <TouchableOpacity
          onPress={onPressSeeDetails}
          accessibilityLabel="See Details"
          style={styles.seeDetailsContainer}
        >
          <Text type="regular" bold>
            See Details
          </Text>
          <Icon name="caret-right" weight="bold" size="12" style={styles.icon} />
        </TouchableOpacity>
      ) : (
        <Button
          type="outline"
          size="small"
          onPress={onPressSeeDetails}
          accessibilityLabel="See Details"
          style={styles.seeDetailsButton}
        >
          SEE DETAILS
        </Button>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  seeDetailsButton: {
    alignSelf: 'center',
    marginTop: 16,
  },
  seeDetailsContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    flexDirection: 'row',
  },
  icon: {
    marginLeft: 4,
  },
})
