import { Sensor } from '@src/types'
import { SensorStoreState } from './sensor.types'

export const reducers = {
  backup: (state: SensorStoreState) => ({
    ...state,
    backup: state.sensor,
  }),

  restore: (state: SensorStoreState) => {
    if (!state.backup) {
      return state
    }
    return {
      ...state,
      ...state.backup,
    }
  },

  updateSensor: (state: SensorStoreState, { payload }: { payload: Sensor | null }) => ({
    ...state,
    sensor: payload,
  }),
}
