import moment from 'moment'
import { isEmpty, compact, map } from 'lodash'

const transform = ({ samples, sourceIgnore }) => {
  // don't allow granularity higher than ~15 minutes
  //
  if (!isEmpty(samples.bloodGlucose)) {
    let glucoseSamples = samples.bloodGlucose

    let lastSample = glucoseSamples[0]

    glucoseSamples = compact(
      map(glucoseSamples, (sample) => {
        const timeDiff = moment(sample.startDate).diff(lastSample.startDate, 'minutes')
        const includeSample = sourceIgnore.every((source) => !sample.sourceId.includes(source))

        if (Math.abs(timeDiff) > 13 && includeSample) {
          lastSample = sample
          return sample
        }

        return null
      }),
    )

    return { ...samples, bloodGlucose: glucoseSamples }
  }

  return samples
}

export { transform }
