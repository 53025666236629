import React from 'react'
import { View, ImageBackground, TextStyle } from 'react-native'
import { useStyleSheet, StyleService } from '@src/style/service'
import { membershipCardBgImage } from '@src/assets/images'
import { NutrisenseLogo } from '@src/components'
import { Text } from '@src/components/base'
import { Interval } from '@src/types'

interface OptionCardProps {
  title: string
  price: number
  interval: Interval
}

export const OptionCard = ({ title, price, interval }: OptionCardProps) => {
  const styles = useStyleSheet(themedStyle)
  const fill = (styles.text as TextStyle).color

  return (
    <ImageBackground
      resizeMode="cover"
      style={styles.container}
      source={membershipCardBgImage.imageSource}
    >
      <View style={styles.content}>
        <NutrisenseLogo height={19} width={120} fill={fill} />
        <View style={styles.details}>
          <View style={styles.title}>
            <Text type="regular" style={styles.text}>
              {title}
            </Text>
          </View>
          <View style={styles.price}>
            <Text type="regular" style={styles.text}>
              only{' '}
            </Text>
            <Text type="title-3" style={styles.text}>
              ${price}
            </Text>
            <Text type="regular" style={styles.text}>
              /{interval.suffix}
            </Text>
          </View>
        </View>
      </View>
    </ImageBackground>
  )
}

const themedStyle = StyleService.create({
  container: {
    marginBottom: 8,
    width: '100%',
    borderRadius: 24,
    aspectRatio: 353 / 194,
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
    padding: 24,
  },
  details: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  price: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  title: {
    paddingVertical: 6,
    paddingHorizontal: 9,
    borderWidth: 1,
    borderColor: 'theme.solid.white',
    borderRadius: 32,
  },
  text: {
    color: 'theme.solid.white',
  },
  cardBackground: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
})
