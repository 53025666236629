import React from 'react'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Checkbox, Radio, Text } from '@components/base'

export enum OptionType {
  Checkbox = 'checkbox',
  Radio = 'radio',
}

export interface OptionProps {
  key?: string
  type: OptionType
  label: string
  selected: boolean
  disabled?: boolean
  onPress: () => void
  displayBottomMargin?: boolean
  style?: StyleProp<ViewStyle>
}

export const Option = ({
  label,
  selected,
  disabled,
  displayBottomMargin,
  onPress,
  type,
  style,
}: OptionProps): JSX.Element => {
  const styles = useStyleSheet(themedStyles)
  const displayBottomMarginInternal = displayBottomMargin ?? true
  const OptionComponent = type === OptionType.Checkbox ? Checkbox : Radio

  return (
    <TouchableOpacity
      accessibilityLabel={`${label} option`}
      activeOpacity={0.7}
      onPress={onPress}
      disabled={disabled}
      style={[
        styles.container,
        selected && styles.containerSelected,
        displayBottomMarginInternal && styles.containerMarginBottom,
        style,
      ]}
    >
      <Text type="regular" style={[styles.label, disabled && styles.labelDisabled]}>
        {label}
      </Text>
      <View pointerEvents="none">
        <OptionComponent checked={selected} disabled={disabled} />
      </View>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderRadius: 16,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
  },
  containerSelected: {
    borderColor: 'theme.primary.base',
  },
  containerMarginBottom: {
    marginBottom: 16,
  },
  label: {
    marginRight: 20,
    flexShrink: 1,
  },
  labelDisabled: {
    color: 'theme.disabled.text',
  },
})
