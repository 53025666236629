import { nbInternationalFont } from '@src/components/charts/fonts/nbInternational'
const fontFamily = 'NBInternationalProReg'

export interface Point {
  x: string | number
  y: null | number
  type: string
}

export interface SingleDayScoreChartConfig {
  data: Point[]
  yLabel?: string
  style: {
    currentPeriodDataColor: string
    previousPeriodDataColor: string
    axisLabelsColor: string
    markingLinesColor: string
    tooltipBackgroundColor: string
    tooltipTextColor: string
  }
  xScaleValues?: string[]
  maxColumnWidth?: number
}

export const renderColumnChart = ({
  data,
  yLabel = '',
  style: {
    axisLabelsColor,
    currentPeriodDataColor,
    previousPeriodDataColor,
    markingLinesColor,
    tooltipBackgroundColor,
    tooltipTextColor,
  },
  xScaleValues,
  maxColumnWidth = 10,
}: SingleDayScoreChartConfig) => {
  const currentPrevDataPairsByX = data.reduce(
    (acc, point) => ({
      ...acc,
      [point.x]: {
        ...acc[point.x],
        [point.type]: point.y,
      },
    }),
    {} as Record<string, Record<string, number | null>>,
  )

  return `(function(){
  const data = ${JSON.stringify(data)};
  const currentPrevDataPairsByX = ${JSON.stringify(currentPrevDataPairsByX)}

  const dataTypeToColorMap = {
    current: '${currentPeriodDataColor}',
    previous: '${previousPeriodDataColor}',
  }

  const isValueRenderable = (value) => value !== null && value !== undefined

  const columnShapesGroupedByOriginalX = {}

  const highlightColumnsAtX = (xToHighlightColumns) => {
    Object.entries(columnShapesGroupedByOriginalX)
      .forEach(([originalX, columnShapes]) => {
        if (originalX === xToHighlightColumns) {
          columnShapes.forEach((shape) => {
            shape.attr('opacity', 1)
          })
        } else {
          columnShapes.forEach((shape) => {
            shape.attr('opacity', 0.2)
          })
        }
      })
  }

  const style = document.createElement('style');
  style.textContent = \`
    ${nbInternationalFont}
    .f2-tooltip {
      background-color: ${tooltipBackgroundColor};
      padding: 4px 8px;
    }
    .f2-tooltip:after {
      border-top-color: ${tooltipBackgroundColor};
    }
  \`;
  document.body.append(style)

  const CHART_LEFT_PADDING = 50

  const chart = new F2.Chart({
    id: 'chart',
    pixelRatio: window.devicePixelRatio,
    padding: [ 50, 'auto', 'auto', CHART_LEFT_PADDING ] // Add left padding to make enough space for y-axis label
  });

  const hasPreviousData = ${data.some((item) => item.type === 'previous' && item.y !== null)};

  chart.legend(false)

  const basicAxisLabelStyle = {
    fill: '${axisLabelsColor}',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: '${fontFamily}'
  }

  const basicAxisLineStyle = { stroke: '${markingLinesColor}', lineWidth: 1 }

  chart.axis('x', {
    label: basicAxisLabelStyle,
    line: basicAxisLineStyle,
  })

  const AXIS_LABEL_OFFSET = 7.5

  chart.axis('y', {
    grid: null,
    label: { ...basicAxisLabelStyle, textAlign: 'right' },
    line: basicAxisLineStyle,
    labelOffset: AXIS_LABEL_OFFSET,
  })

  chart.source(data);

  // Set order of x axis labels if they are provided
  ${
    xScaleValues
      ? `chart.scale('x', {
    values: ${JSON.stringify(xScaleValues)},
  })`
      : ''
  }

  // Add invisible columns to just setup coordinate system
  chart.interval()
    .position('x*y')
    .color('rgba(0, 0, 0, 0)')

  // Tooltip functionality

  const TOOLTIP_BOTTOM_HEIGHT = 5
  const OFFSET_BETWEEN_TOOLTIP_AND_COLUMN_TOP = 4

  const renderTooltipValueRow = ({ markerColor, value, isLast }) => \`
    <div style="display: flex; flex-direction: row; align-items: center; \${!isLast ? 'margin-bottom: 2px' : ''}">
      <div style="height: 6px; width: 6px; background-color: \${markerColor}; border-radius: 3px; margin-right: 4px"></div>
      <div style="font-size: 13px; font-weight: 600; color: ${tooltipTextColor};">\${value}</div>
    </div>
  \`

  chart.tooltip({
    custom: true,
    showTooltipMarker: false,
    onShow: function(selectedColumn) {
      /*
        We cannot use just selectedColumn.items here to render tooltip values.
        In case previous period and current period values will be equal
        the selectedColumn object will contain only one value.
        So we need to find all values for the same x coordinate.
      */
      const selectedColumnOriginalX = selectedColumn.items[0].origin.x
      const dataPair = currentPrevDataPairsByX[selectedColumnOriginalX]

      if (!dataPair.current) return;

      highlightColumnsAtX(selectedColumnOriginalX)

      const tooltipEl = document.querySelector('.f2-tooltip');

      const tooltipHtmlContent = Object.entries(dataPair)
        .filter(([_dataPeriodType, value]) => isValueRenderable(value))
        .map(([dataPeriodType, value], index, array) =>
          renderTooltipValueRow({
            value,
            markerColor: dataTypeToColorMap[dataPeriodType],
            isLast: index === array.length - 1
          })
        ).join('');

      tooltipEl.innerHTML = '<div>' + tooltipHtmlContent + '</div>';

      const tooltipCenterXToTooltipLeftOffset = (centerX) => centerX - tooltipEl.clientWidth / 2
      const tooltipBottomYToTooltipTopOffset = (bottomY) =>
        bottomY - tooltipEl.clientHeight - TOOLTIP_BOTTOM_HEIGHT - OFFSET_BETWEEN_TOOLTIP_AND_COLUMN_TOP

      const tooltipCenterX = selectedColumn.items[0].x
      const tooltipBottomY = Math.min(
        ...selectedColumn.items.map(item => item.y)
      )

      tooltipEl.style.fontFamily = 'NBInternationalProReg';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = tooltipCenterXToTooltipLeftOffset(tooltipCenterX) + 'px';
      tooltipEl.style.top = tooltipBottomYToTooltipTopOffset(tooltipBottomY) + 'px';
    },
  });

  chart.render();

  const canvas = chart.get('canvas');

  const zeroPointY = chart.getPosition({ x: 0, y: 0 }).y;

  // Add y axis measurement system label

  const yLabelGroup = canvas.addGroup();
  const yLabel = '${yLabel}';
  if (yLabel) {
    yLabelGroup.addShape('text', {
      attrs: {
        textAlign: 'right',
        x: CHART_LEFT_PADDING - AXIS_LABEL_OFFSET,
        y: 40,
        text: yLabel,
        fontSize: '10',
        fontFamily: '${fontFamily}',
        fill: '${axisLabelsColor}'
      }
    });
  }

  // Draw visible columns

  // from 0 to 1
  const columnCellInfillRatio = 0.5;

  const columnCellWidth = chart.getPosition({ x: 1, y: 0 }).x - chart.getPosition({ x: 0, y: 0 }).x

  const areaDedicatedForColumnInCellWidth = columnCellWidth * columnCellInfillRatio;

  const currentPreviousPeriodColumnsMargin = 2

  const barsGroup = canvas.addGroup();

  let columnWidth = hasPreviousData
    ? (areaDedicatedForColumnInCellWidth - currentPreviousPeriodColumnsMargin) / 2
    : areaDedicatedForColumnInCellWidth;

  columnWidth = Math.min(${maxColumnWidth}, columnWidth);

  const halfColumnWidth = columnWidth / 2;
  const columnBottomY = zeroPointY - halfColumnWidth;

  const columnOffsetFromCellCenter = hasPreviousData
    ? (columnWidth + currentPreviousPeriodColumnsMargin) / 2
    : 0

  const renderItemColumn = ({ x, y, type }) => {
    const { x: columnCellCenterX, y: columnTopY } = chart.getPosition({ x, y });

    const columnCenterX = columnCellCenterX +
      (columnOffsetFromCellCenter * (type === 'current' ? 1 : -1));

    const rectX = columnCenterX - halfColumnWidth;

    const columnHeight = zeroPointY - columnTopY;

    const fillColor = type === 'previous' ? '${previousPeriodDataColor}' : '${currentPeriodDataColor}';

    return barsGroup.addShape('rect', {
      attrs: {
        x: rectX,
        y: columnTopY,
        width: columnWidth,
        height: columnHeight,
        fill: fillColor,
        radius: columnHeight < columnWidth ? columnHeight / 2 : halfColumnWidth
      }
    });
  }

  Object.entries(currentPrevDataPairsByX)
    .forEach(([x, {current: currentY, previous: previousY}]) => {
      const renderedColumnShapes = [];

      if (isValueRenderable(currentY)) {
        renderedColumnShapes.push(
          renderItemColumn({ x, y: currentY, type: 'current' })
        );
      }
      if (isValueRenderable(currentY) && isValueRenderable(previousY)) {
        renderedColumnShapes.push(
          renderItemColumn({ x, y: previousY, type: 'previous' })
        );
      }

      columnShapesGroupedByOriginalX[x] = [
        ...(columnShapesGroupedByOriginalX[x] || []),
        ...renderedColumnShapes
      ]
    });

  // Add vertical grid lines
  const verticalGridGroup = canvas.addGroup();

  const drawVerticalDivisionLine = (x) => {
    verticalGridGroup.addShape('line', {
      attrs: {
        x1: x,
        y1: 55,
        x2: x,
        y2: zeroPointY,
        stroke: '${markingLinesColor}',
        lineWidth: 1,
      }
    });
  }

  const halfColumnCellWidth = columnCellWidth / 2
  drawVerticalDivisionLine(chart.getPosition({ x: data[0].x, y: 0 }).x - halfColumnCellWidth);
  data.forEach((dataItem, index) => {
    const itemPosition = chart.getPosition(dataItem);
    const x = itemPosition.x + halfColumnCellWidth;
    drawVerticalDivisionLine(x);
  });

})(); true;
  `
}
