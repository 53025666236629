import { batchedSubscribe } from 'redux-batched-subscribe'
import { debounce } from 'lodash'

const debounceNotify = debounce((notify) => notify())

const debounceBlacklist = [
  'history/appendOrReplaceList',
  'history/deleteList',
  'ingredients/deleteList',
]

const batchEnhancer = batchedSubscribe(debounceNotify, debounceBlacklist)

export default batchEnhancer
