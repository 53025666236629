import React, { useRef, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { Feature, InlineAlert, useFeatureFlag } from '@src/components'
import { TransparentNavigationBar } from '@src/components/navigationBar/TransparentNavigationBar'
import { SkipButton } from '@src/screens/SignUp/components/SkipButton'
import { NavigationContainer } from '@src/screens/Common/containers'
import { ProductItem } from '@src/screens/Marketplace/components/ProductItem'
import { GroupableProduct, isProduct } from '@src/screens/Marketplace/types/types'
import { useAllowSkipPurchaseFlow } from '@src/utils/hooks'
import { UiInlineAlert } from '@src/types'
import { useSignUpProducts } from '../hooks'

export const SelectPlan = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const [selectedItem, setSelectedItem] = useState<GroupableProduct | undefined>()
  const { groupedProducts, loading, showPurchaseFlow } = useSignUpProducts({ reloadProducts: true })
  const scrollViewRef = useRef<ScrollView>(null)

  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)
  const sensorSelectionAtCheckout = useFeatureFlag(Feature.SensorSelectionAtCheckout)

  const onSkipPress = () => {
    dispatch({
      type: 'app/skipOnboardingFlow',
    })
  }

  const onItemSelected = (item: GroupableProduct) => {
    setSelectedItem(item)
    scrollViewRef.current?.scrollToEnd()
  }

  const onContinuePress = () => {
    if (!selectedItem) {
      return
    }

    if (selectedItem && isProduct(selectedItem)) {
      if (selectedItem.includesCgm && sensorSelectionAtCheckout) {
        return navigation.navigate('SensorSelection', {
          product: selectedItem,
          requireEligibilityCheck: true,
        })
      }

      return navigation.navigate('EligibilityCheck', {
        product: selectedItem,
      })
    }

    navigation.navigate('SelectSubscription', {
      productGroupId: selectedItem.id,
      requireEligibilityCheck: true,
    })
  }

  const allowSkipPurchaseFlow = useAllowSkipPurchaseFlow()

  const onSignOutPress = () => {
    dispatch({ type: 'app/logout' })
  }

  return (
    <NavigationContainer
      title="Programs"
      isDrawerScreen={!appNavigationUpdatesFeatureEnabled}
      style={styles.container}
      NavigationBarComponent={TransparentNavigationBar}
      showLoadingIndicator={loading}
    >
      <ScrollView
        ref={scrollViewRef}
        style={styles.container}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 16,
          paddingBottom: (insets?.bottom || 0) + 16,
        }}
      >
        <View style={styles.content}>
          <View style={styles.titleContainer}>
            <Text type="title-2">Get started with the right program for you.</Text>
          </View>
          {groupedProducts.map((item) => (
            <ProductItem
              key={`${item.__typename}-${item.id}`}
              item={item}
              onItemPress={onItemSelected}
              selected={selectedItem?.id === item.id}
            />
          ))}
        </View>
        {selectedItem && (
          <Button
            type="primary"
            size="block"
            accessibilityLabel="continueButton"
            onPress={onContinuePress}
          >
            {isProduct(selectedItem) ? 'Continue' : `View ${selectedItem.title || 'Programs'}`}
          </Button>
        )}
        {showPurchaseFlow && (
          <>
            <InlineAlert
              category={UiInlineAlert.Info}
              message={`Did you already pay on web and don't see your purchase? Please log in again using the same email you used to purchase.`}
              style={styles.reminderText}
            />
            <Button
              type="transparent"
              size="block"
              onPress={onSignOutPress}
              accessibilityLabel="Sign Out"
            >
              Sign Out
            </Button>
            {allowSkipPurchaseFlow && <SkipButton title="Not now" onPress={onSkipPress} />}
          </>
        )}
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    flexGrow: 1,
  },
  titleContainer: {
    marginVertical: 24,
    textAlign: 'left',
  },
  reminderText: {
    marginVertical: 16,
  },
})
