import React from 'react'
import { StyleProp, View } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { FlexView, LoadingFastImage } from '@src/components'
import { CarouselItemProps } from './Carousel.types'

export const CarouselItem = ({
  title,
  description,
  image,
  width,
  fullScreenImage,
}: CarouselItemProps) => {
  const styles = useStyleSheet(themedStyles)
  return (
    <FlexView style={{ width }}>
      <View style={[styles.image, fullScreenImage && styles.fullScreenImage]}>
        <LoadingFastImage source={image} style={styles.fastImage as StyleProp<ImageStyle>} />
      </View>
      <View style={styles.textInfo}>
        <Text type="title-2" style={styles.title}>
          {title}
        </Text>
        <Text type="regular" style={styles.subtitle} numberOfLines={4}>
          {description}
        </Text>
      </View>
    </FlexView>
  )
}

const themedStyles = StyleService.create({
  image: {
    flex: 1,
    marginVertical: 24,
    paddingHorizontal: 24,
  },
  fullScreenImage: {
    paddingHorizontal: 0,
  },
  textInfo: {
    flex: 0.4,
  },
  fastImage: {
    flex: 1,
    width: '100%',
    borderRadius: 8,
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    marginTop: 16,
    marginHorizontal: 24,
  },
})
