import React from 'react'
import { useTheme } from '@ui-kitten/components'
import Chart from 'react-native-f2chart'
import { CommonChart } from '@components'
import { MealComparisonChartConfig, mealComparisonChart } from '@components/charts'
import { getCompareMealsChartColors } from '@screens/Meals/utils'

interface CompareMealsChartProps extends Omit<MealComparisonChartConfig, 'style'> {
  chartRef?: React.MutableRefObject<Chart | null>
  disableAnimation?: boolean
  height?: number
  onLoad?: () => void
}

export const CompareMealsChart = ({
  chartRef,
  data,
  disableAnimation,
  events,
  lineColors,
  height = 210,
  onLoad,
}: CompareMealsChartProps) => {
  const theme = useTheme()

  const chartScript = mealComparisonChart({
    data,
    events,
    lineColors: lineColors || getCompareMealsChartColors(theme),
    disableAnimation,
    style: {
      axisLabel: theme['theme.text.secondary'],
      axisLine: theme['theme.surface.base'],
      mealIconBackground: theme['theme.primary.base'],
      pointColorActivity: theme['theme.info.base'],
      pointColorJournalEntry: theme['theme.solid.black'],
      pointColorMeal: theme['theme.error.base'],
      pointStrokeColor: theme['theme.solid.white'],
      tooltipBackground: theme['theme.tooltip.background'],
      tooltipTitleColor: theme['theme.tooltip.textPrimary'],
      tooltipTextColor: theme['theme.tooltip.textSecondary'],
    },
  })

  return (
    <CommonChart chartRef={chartRef} chartScript={chartScript} height={height} onLoad={onLoad} />
  )
}
