import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet } from '@src/components'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList, RootStackParamList } from '@src/navigation/types'
import { Intercom } from '@src/utils'
import { coachChatConversationSelector } from '@src/selectors/app'
import { BillingProduct } from '@src/types.ts'
import { NutritionistPhotoIcon } from '@screens/NutritionistHub/components/NutritionistPhotoIcon.tsx'
import { Debug } from '@config'
import { useFeeProduct } from '@screens/NutritionistHub/hooks/useFeeProduct.ts'

export const LateRescheduleVideoCallModal = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const route = useRoute<RouteProp<AppStackParamList, 'LateRescheduleVideoCallModal'>>()
  const { videoCallId, appointmentType } = route.params
  const coachChatConversation = useSelector(coachChatConversationSelector)
  const lateRescheduleFeeProduct = useFeeProduct(BillingProduct.VideoCallLateRescheduleFee)

  const onReschedulePress = () => {
    navigation.navigate('FeePaymentScreen', {
      videoCallId,
      feeProduct: lateRescheduleFeeProduct,
      appointmentType,
    })
  }

  const goToIntercom = () => {
    if (!Debug.shouldEnableIntercom()) {
      return
    }

    if (coachChatConversation) {
      Intercom.showIntercomConversation(coachChatConversation.intercomId)
    } else {
      Intercom.showIntercomMessenger({ source: 'NutritionistHub' })
    }
  }

  return (
    <BottomSheet
      primaryButton={{ text: 'Confirm fee and Reschedule', onPress: onReschedulePress }}
      secondaryButton={{ text: 'Message your nutritionist', onPress: goToIntercom }}
      buttonDirection="column-reverse"
    >
      <NutritionistPhotoIcon style={styles.nutritionistPhoto} />
      <Text style={styles.title} type="title-2">
        Need to Reschedule?
      </Text>
      <Text style={styles.content} type="regular">
        We get it, life happens.
      </Text>
      <Text style={styles.content} type="regular">
        Rescheduling within 24hrs of the call is subject to a $
        {lateRescheduleFeeProduct?.price || ''} fee as your nutritionist reserved this time just for
        you.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  title: {
    textAlign: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },
  content: {
    textAlign: 'center',
    paddingBottom: 16,
  },
  nutritionistPhoto: {
    alignSelf: 'center',
  },
})
