import { gql } from '@apollo/client/core'
import { CORE_SUBSCRIPTION_FIELDS } from '@src/graphql/fragments/subscription'

const UNCANCEL_SUBSCRIPTION = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  mutation uncancelSubscription($id: ID!) {
    uncancelSubscription(id: $id) {
      ...CoreSubscriptionFields
    }
  }
`

export default UNCANCEL_SUBSCRIPTION
