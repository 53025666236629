import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconButton } from '@components/base'

export const TabBarCustomItem = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()

  return (
    <IconButton
      accessibilityLabel="Open Menu"
      iconName="plus"
      size="l"
      style={styles.toggle}
      type="primary"
      onPress={() => navigation.navigate('ShowActions')}
    />
  )
}

const themedStyles = StyleService.create({
  toggle: {
    marginBottom: 12,
  },
})
