import React from 'react'
import { TouchableOpacity, View, StyleProp, ViewStyle, useWindowDimensions } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { Benefit, CircularCheck } from '@components'
import { GroupableProduct, isProduct } from '../types/types'
import { getPriceRangeDescriptor } from '../utils/utils'

export interface SubscriptionItemProps {
  title: string
  description?: string | null
  details?: string[] | null
  item: GroupableProduct
  savings?: number
  tag?: string
  style?: StyleProp<ViewStyle>
  onItemPress: (item: GroupableProduct) => void
  ctaText?: string
  insuranceCoverageAvailable?: boolean
  selectMode?: boolean
  selected?: boolean
}

export const SubscriptionItem = ({
  title,
  description,
  details,
  item,
  savings = 0,
  tag,
  style,
  onItemPress,
  ctaText,
  insuranceCoverageAvailable = false,
  selectMode = false,
  selected = false,
}: SubscriptionItemProps) => {
  const styles = useStyleSheet(themedStyle)
  const products = isProduct(item) ? [item] : item.products
  const amount = isProduct(item) ? item.price : 0
  const recurring = products.some((product) => product.recurring)
  const priceDescriptor = getPriceRangeDescriptor(item)
  const dimensions = useWindowDimensions()
  const hasNarrowDevice = dimensions.width < 390

  const handlePress = () => {
    onItemPress(item)
  }

  return (
    <TouchableOpacity
      style={[styles.container, selected && styles.containerSelected, style]}
      activeOpacity={0.7}
      onPress={handlePress}
      accessibilityLabel={title}
    >
      <View style={styles.row}>
        {hasNarrowDevice ? (
          <Text type="regular" bold style={styles.title}>
            {title}
          </Text>
        ) : (
          <Text type="title-3" style={styles.title}>
            {title}
          </Text>
        )}
        {selectMode ? (
          <CircularCheck selected={selected} />
        ) : (
          <Icon name="caret-right" style={styles.icon} />
        )}
      </View>
      {!!description && (
        <Text type="regular" style={styles.description}>
          {description}
        </Text>
      )}
      {details && (
        <View style={styles.details}>
          {details?.map((detail) => (
            <Benefit key={detail} benefit={detail} />
          ))}
        </View>
      )}
      <View style={styles.priceContainer}>
        <View style={styles.priceDescription}>
          <View style={styles.priceRow}>
            {recurring ? (
              <Text type="large" bold>
                {priceDescriptor.amount}
                <Text type="small" bold>
                  {priceDescriptor.suffix}
                </Text>
              </Text>
            ) : (
              <>
                <Text type="regular" bold style={styles.priceWithoutDiscount}>
                  {priceDescriptor.amount}
                  <Text type="small" bold>
                    {priceDescriptor.suffix}
                  </Text>
                </Text>
                {savings > 0 && (
                  <Text type="large" bold>
                    {`$${amount - savings}`}
                  </Text>
                )}
              </>
            )}
            {ctaText && (
              <View style={styles.cta}>
                <Button
                  type="primary"
                  size="small"
                  onPress={handlePress}
                  accessibilityLabel={ctaText}
                >
                  {ctaText}
                </Button>
              </View>
            )}
          </View>
          {insuranceCoverageAvailable && (
            <View style={styles.insuranceNotice}>
              <Text type="small" bold style={styles.saveText}>
                Free 60 or 90 minute call if eligible
              </Text>
            </View>
          )}
        </View>
        {tag && (
          <View style={styles.saveContainer}>
            <Text type="small" bold style={styles.saveText}>
              {tag}
            </Text>
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    minHeight: 180,
    padding: 16,
    borderRadius: 24,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
    backgroundColor: 'theme.surface.base3',
  },
  containerSelected: {
    borderColor: 'theme.success.base',
  },
  row: {
    flexDirection: 'row',
  },
  title: {
    flex: 1,
    marginBottom: 4,
  },
  icon: {
    width: 16,
    height: 16,
    marginLeft: 16,
    color: 'theme.primary.base',
  },
  description: {
    marginTop: 4,
    marginBottom: 8,
  },
  details: {
    marginBottom: 26,
  },
  priceContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  priceRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cta: {
    marginLeft: 24,
  },
  priceWithoutDiscount: {
    textDecorationLine: 'theme.surface.base',
    marginRight: 6,
  },
  saveContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    height: 32,
    paddingHorizontal: 16,
    marginTop: 8,
    borderRadius: 16,
    backgroundColor: 'theme.accent.citron',
  },
  priceDescription: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  insuranceNotice: {
    height: 32,
    backgroundColor: 'theme.accent.citron',
    justifyContent: 'center',
    borderRadius: 16,
    paddingHorizontal: 16,
    marginLeft: 10,
  },
  saveText: {
    color: 'theme.solid.black',
  },
})
