import Axios from 'axios'
import Config from 'react-native-config'
import parseGooglePlace from 'parse-google-place'
import { isEmpty, get } from 'lodash'
import { Bugsnag } from '@config'
import { AddressCountries } from '@src/types'

const client = Axios.create({ baseURL: 'https://maps.googleapis.com/maps/' })

export interface Place {
  description: string
  // eslint-disable-next-line camelcase
  place_id: string
}

const countryFilter = (country = AddressCountries.Us) => ({ components: `country:${country}` })

const QUERY_DEFAULTS = {
  key: Config.GOOGLE_API_KEY,
  language: 'en',
  types: 'address',
  ...countryFilter(),
}

client.defaults.headers.post['Content-Type'] = 'application/json'

const fetchPlaces = async (street: string, queryOverrides: ReturnType<typeof countryFilter>) => {
  if (isEmpty(street)) {
    return []
  }
  const response = await request({
    method: 'get',
    params: {
      input: street,
      ...QUERY_DEFAULTS,
      ...queryOverrides,
    },
    url: 'api/place/autocomplete/json',
  })
  return get<Place[]>(response, 'predictions', [])
}

const fetchPlaceDetail = async (
  placeId: string,
  queryOverrides: ReturnType<typeof countryFilter>,
) => {
  if (isEmpty(placeId)) {
    return []
  }

  const response = await request({
    method: 'get',
    params: {
      placeid: placeId,
      ...QUERY_DEFAULTS,
      ...queryOverrides,
    },
    url: 'api/place/details/json',
  })
  return get(response, 'result', {})
}

const fetchPlacesForStreetAndCountry = async ({
  street,
  country,
}: {
  street: string
  country: AddressCountries
}) => {
  const places = await fetchPlaces(street, countryFilter(country))
  return places.map(({ description, place_id: placeId }) => ({ description, place_id: placeId }))
}

const fetchAddressForPlace = async ({
  place,
  country,
}: {
  place: Place
  country: AddressCountries
}) => {
  const data = await fetchPlaceDetail(place.place_id, countryFilter(country))
  return extractAddressFromPlace(data)
}

const extractAddressFromPlace = (data: any) => {
  const place = parseGooglePlace(data)

  return {
    street: get<string>(place, 'address', ''),
    city: get<string>(place, 'city', ''),
    state: get<string>(place, 'stateShort', ''),
    zipCode: get<string>(place, 'zipCode', ''),
  }
}

const request = async (options: any) => {
  try {
    const response = await client(options)
    const { data } = response

    return data
  } catch (error: any) {
    Bugsnag.notify(error)
    return null
  }
}

export default {
  fetchPlacesForStreetAndCountry,
  fetchAddressForPlace,
}
