export enum Feature {
  AbbottPartnership = 'abbott_partnership',
  AccountInterestQuestionnaire = 'account_interest_questionnaire',
  AllCourseModulesUnlocked = 'all_course_modules_unlocked',
  AllowExpiredSensorScan = 'allow_expired_sensor_scan',
  AllowSkipPurchaseFlow = 'allow_skip_purchase_flow',
  AppNavigationUpdates = 'app_navigation_updates',
  AvalaraEstimateTax = 'avalara_estimate_tax',
  BYOSFreeExperiment = 'byos_free_experiment',
  Chatbot = 'chatbot',
  DatadogRum = 'datadog_rum',
  DebugBluetoothSensorScanning = 'debug_bluetooth_sensor_scanning',
  DebugNfcSensorScanning = 'debug_nfc_sensor_scanning',
  DebugNfcSensorScanningError = 'debug_nfc_sensor_scanning_error',
  DexcomIntegration = 'dexcom_integration',
  DexcomReadyToParticipate = 'dexcom_ready_to_participate',
  DexcomResearch = 'dexcom_research',
  GlucoseCheckReminder = 'glucose_check_reminders_mobile',
  IncludeCurrentDayInHealthDataSubmission = 'include_current_day_in_health_data_submission',
  InsightsTooltip = 'insights_tooltip',
  InsuranceCoveredVideoConsult = 'insurance_covered_video_consult',
  Libre2Support = 'libre2_support',
  Libre3DisconnectOnSensorRemoval = 'libre3_sensor_disconnect',
  Libre3RealTime = 'libre_3_real_time',
  Libre3RealTimeGlucoseChart = 'libre_3_real_time_glucose_chart',
  Libre3ScanningWithoutLibreLinkUpPatientId = 'libre3_scanning_without_libre_link_up_patient_id',
  LibrelinkupConnection = 'librelinkup_connection',
  MealExperimentation = 'meal_experimentation',
  MemberNotes = 'member_notes',
  MobileAppPresentationMode = 'mobile_app_presentation_mode',
  OnboardingChecklist = 'onboarding_checklist',
  OutcomesTracking = 'outcomes_tracking',
  OwnSensorOnboardingFlow = 'own_sensor_onboarding_flow',
  SaveCgmLogsToBetterStack = 'save_cgm_logs_to_better_stack',
  SaveCgmLogsToSegment = 'save_cgm_logs_to_segment',
  SensorSelectionAtCheckout = 'sensor_selection_at_checkout',
  SeparateEnrollment = 'research_prescription_separate_enrollment_mobile',
  StartDexcomParticipation = 'start_dexcom_participation',
  SubscriptionChangeBillingCycle = 'subscription_change_billing_cycle',
  SubscriptionPausing = 'subscription_pausing',
  TerraKetoMojo = 'terra_ketomojo',
  UkSupport = 'uk_support',
  UserGoals = 'user_goals',
  UxSensorModeDexcomEnabled = 'sensor_mode_dexcom',
  UxSensorModeLibre3Enabled = 'sensor_mode_libre_3',
  VideoCallFees = 'video_call_fees',
  NutritionistMenuMostPopularTag = 'nutritionist_menu_most_popular_tag',
  NutritionistMenuProductsSequence = 'nutritionist_menu_products_sequence',
  VideoCallFunnelOptimization = 'video_call_funnel_optimization',
  VideoCallRetention = 'video_call_retention',
}

export interface FeatureFlagProps {
  feature: Feature
  children: any
  fallbackComponent?: React.ReactNode
}
