import React, { useEffect } from 'react'
import { RouteProp, useRoute } from '@react-navigation/native'
import { RootStackParamList } from '@navigation/types'
import { useChargeFee } from '@screens/NutritionistHub/hooks/useChargeFee.ts'
import { LoadingContainer } from '@screens/Common/containers/LoadingContainer.tsx'

export const FeePaymentScreen = () => {
  const route = useRoute<RouteProp<RootStackParamList, 'FeePaymentScreen'>>()
  const { videoCallId, feeProduct, appointmentType } = route.params
  const chargeFee = useChargeFee(videoCallId, appointmentType, feeProduct)

  useEffect(() => {
    chargeFee()
  }, [chargeFee])

  return (
    <LoadingContainer
      loadingMessage="Processing your payment"
      secondaryMessage="It might take up to 20 seconds"
    />
  )
}
