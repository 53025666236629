import React, { Fragment } from 'react'
import { Image, ImageStyle, TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconName, Text } from '@components/base'
import { SensorModel, SubscriptionCheckoutOptionKind } from '@src/types'
import { CircularCheck } from '@src/components'

export enum SelectedSensor {
  Default = 'default',
  Research = 'research',
}

export interface SensorConfig {
  sensor: SelectedSensor
  name: string
  highlight: string
  benefits: string[]
  image: {
    imageSource: any
  }
  model: SensorModel
  tagIcon?: IconName
  checkoutOptionKind: SubscriptionCheckoutOptionKind
}

interface SensorOptionProps {
  config: SensorConfig
  selected: boolean
  onSelect: () => void
}

export const SensorOption = ({ config, selected, onSelect }: SensorOptionProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <TouchableOpacity
      accessibilityLabel={`Select ${config.name} sensor`}
      activeOpacity={0.7}
      style={[styles.sensorCard, selected && styles.selectedSensorCard]}
      onPress={onSelect}
    >
      <View style={styles.imageContainer}>
        <Image
          source={config.image.imageSource}
          style={[styles.image, styles[`${config.sensor}Image`]] as ImageStyle[]}
        />
      </View>
      <Text type="title-3" style={styles.name}>
        {config.name}
      </Text>
      <Text type="large" bold style={styles.highlight}>
        {config.highlight}
      </Text>
      <View style={styles.benefits}>
        {config.benefits.map((benefit, index) => (
          <Fragment key={benefit}>
            {index !== 0 && <View style={styles.divider} />}
            <Text type="regular" style={styles.benefit}>
              {benefit}
            </Text>
          </Fragment>
        ))}
      </View>
      <CircularCheck selected={selected} pointerEvents="none" style={styles.checkbox} />
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  sensorCard: {
    alignItems: 'center',
    borderRadius: 24,
    borderWidth: 2,
    borderColor: 'theme.surface.base',
    paddingVertical: 16,
    overflow: 'hidden',
  },
  selectedSensorCard: {
    borderColor: 'theme.success.base',
  },
  imageContainer: {
    width: 50,
    height: 50,
  },
  image: {
    // The cgm image assets have the same size, but the cgm illustrations
    // are slightly displaced by their drop-shadows within the image canvas.
    // These margin offsets account for the varying drop-shadow values.
    marginLeft: -23,
    width: 96,
    height: 96,
  },
  defaultImage: {
    marginTop: -10,
  },
  researchImage: {
    marginTop: -3,
  },
  name: {
    marginTop: 16,
  },
  highlight: {
    marginTop: 8,
    color: 'theme.info.base',
  },
  benefits: {
    paddingTop: 8,
  },
  benefit: {
    textAlign: 'center',
  },
  divider: {
    flexBasis: '100%',
    marginVertical: 4,
    height: 1,
    backgroundColor: 'theme.surface.base1',
  },
  checkbox: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
})
