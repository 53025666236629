import { ApolloLink, NextLink, Observable, Operation, ServerParseError } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import md5 from 'md5'
import { NativeModules, Platform } from 'react-native'
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link'
import Config from 'react-native-config'
import { Storage } from '@utils'
import { Login } from '@src/models/app.types'
import { ReactNativeFile } from '@src/utils/image'
import App from '../app'

const { scheme } = NativeModules.Configuration

interface OperationCache {
  eTag: string
  response: any
}

const ETAG_HEADER_KEY = 'etag'
const CACHED_OPERATIONS_KEY = 'cachedOperations'
const eTagOperations = ['clientConfig']

const buildRequestCacheKey = (operationName: string, requestDigest: string) =>
  `${CACHED_OPERATIONS_KEY}.${operationName}.${requestDigest}`

export const buildAuthLink = (abortController: AbortController, timeZone?: string) =>
  setContext((_operation, previousContext) => {
    const login = Storage.get<Login>('login')
    const adminLogin = Storage.get<Login>('admin_login')

    const { token } = login || {}
    const { token: adminToken } = adminLogin || {}

    return {
      ...previousContext,
      fetchOptions: {
        signal: abortController.signal,
      },
      backendHeaders: {
        authorization: token ? `Bearer ${token}` : '',
        authorization_admin: adminToken ? `Bearer ${adminToken}` : '',
        build_number: App.buildNumber,
        client_version: App.version,
        time_zone: timeZone,
        platform: Platform.OS,
        app_scheme: scheme,
      },
    }
  })

export const requestCacheLink = setContext((operation, previousContext) => {
  const operationName = operation.operationName || ''
  let requestCacheKey = undefined

  if (!eTagOperations.includes(operationName)) {
    return previousContext
  }

  const requestDigest = md5(JSON.stringify(operation))

  requestCacheKey = buildRequestCacheKey(operationName, requestDigest)

  const storedOperationCache = Storage.get<OperationCache>(requestCacheKey)
  const cacheHeaders = storedOperationCache ? { if_none_match: storedOperationCache.eTag } : {}

  return {
    ...previousContext,
    headers: {
      ...previousContext.headers,
      ...cacheHeaders,
    },
    storedOperationCache,
    requestCacheKey,
  }
})

export const afterwareLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  return forward(operation).map((response) => {
    const context = operation.getContext()

    const {
      response: { headers },
    } = context

    const storedOperationCache: OperationCache | null = context.storedOperationCache
    const requestCacheKey: string | undefined = context.requestCacheKey

    if (!requestCacheKey) {
      return response
    }

    const eTag: string = headers.get(ETAG_HEADER_KEY)

    if (!storedOperationCache || eTag !== storedOperationCache.eTag) {
      Storage.set(requestCacheKey, {
        eTag,
        response,
      } as OperationCache)

      return response
    }

    return storedOperationCache.response
  })
})

export const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      // retry if there is a network error or if we have timeout during subscription creation
      return (
        error.message === 'Network request failed' ||
        (error.statusCode === 500 && operation.operationName === 'createStripeSubscription')
      )
    },
  },
})

export const errorLink = onError(({ networkError, operation }) => {
  if (networkError && (networkError as ServerParseError).statusCode !== 304) {
    return
  }

  // with enabled network inspect Apollo receives empty response and it causes error.

  const storedOperationCache: OperationCache | null = operation.getContext().storedOperationCache

  if (!storedOperationCache) {
    return
  }

  const { response } = storedOperationCache

  // pass cached response down to afterwareLink
  return new Observable((observer) => {
    observer.next(response)
    observer.complete()
  })
})

export const buildMultiApiLink = (backendEndpoint: string) => {
  return new MultiAPILink({
    endpoints: {
      backend: backendEndpoint,
      cms: Config.COURSES_CMS_ENDPOINT,
    },
    defaultEndpoint: 'backend',
    httpSuffix: '',
    createHttpLink: () =>
      createUploadLink({
        isExtractableFile: (value: unknown): value is ReactNativeFile =>
          value instanceof ReactNativeFile,
      }),
    getContext: (endpoint, getCurrentContext) => {
      const previousContext = getCurrentContext()

      if (endpoint === 'backend') {
        return {
          ...previousContext,
          headers: {
            ...previousContext.headers,
            ...previousContext.backendHeaders,
          },
        }
      } else if (endpoint === 'cms') {
        return {
          ...previousContext,
          headers: {
            ...previousContext.headers,
            authorization: `Bearer ${Config.COURSES_CMS_API_KEY}`,
          },
        }
      }
      return previousContext
    },
  })
}
