import { useState } from 'react'
import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Analytics, CustomEventTypes } from '@config'
import { healthSettingsSelector } from '@src/selectors/app'
import { RootStackParamList } from '@src/navigation/types'
import { thresholdsSettingsSelector } from '@src/selectors/settings'
import { useDispatchAsync } from '@src/utils'
import { Option, ThresholdType } from '../../models/settings.types'

const getInitialThresholdOption = (options: Option[], value: number): Option | undefined => {
  return options.find((option) => option.value === value)
}

export const useThresholdSettings = (type: ThresholdType, source: keyof RootStackParamList) => {
  const dispatchAsync = useDispatchAsync()
  const settings = useSelector(thresholdsSettingsSelector)
  const {
    glucoseLowOptions,
    glucoseHighOptions,
    ketoneHighOptions,
    ketoneLowOptions,
  } = useSelector(healthSettingsSelector)

  const settingsLowThreshold =
    type === ThresholdType.Glucose ? settings.glucoseLowThreshold : settings.ketoneLowThreshold
  const settingsHighThreshold =
    type === ThresholdType.Glucose ? settings.glucoseHighThreshold : settings.ketoneHighThreshold
  const lowOptions = type === ThresholdType.Glucose ? glucoseLowOptions : ketoneLowOptions
  const highOptions = type === ThresholdType.Glucose ? glucoseHighOptions : ketoneHighOptions
  const allOptions = [...lowOptions, ...highOptions]

  const [selectedLowOption, setLowOption] = useState(() =>
    getInitialThresholdOption(lowOptions, settingsLowThreshold),
  )
  const [selectedHighOption, setHighOption] = useState(() =>
    getInitialThresholdOption(highOptions, settingsHighThreshold),
  )

  const onThresholdChange = (low: Option, high?: Option) => {
    setLowOption(low)
    high?.value && setHighOption(high)
  }

  const saveThresholds = () => {
    const payload: { [key: string]: number } = {}

    if (selectedLowOption && settingsLowThreshold !== selectedLowOption.value) {
      payload[`${type}LowThreshold`] = selectedLowOption.value
    }

    if (selectedHighOption && settingsHighThreshold !== selectedHighOption.value) {
      payload[`${type}HighThreshold`] = selectedHighOption.value
    }

    if (isEmpty(payload)) {
      return
    }

    Analytics.track(CustomEventTypes.ThresholdSettingsSave, { source, type, ...payload })

    return dispatchAsync({
      type: 'settings/update',
      payload,
    })
  }

  return {
    allOptions,
    lowOptions,
    highOptions,
    selectedLowOption,
    selectedHighOption,
    onThresholdChange,
    saveThresholds,
  }
}
