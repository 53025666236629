import { gql } from '@apollo/client/core'
import { CORE_SUBSCRIPTION_FIELDS } from '@src/graphql/fragments/subscription'

const PURCHASE_SUBSCRIPTION = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  mutation createSubscription(
    $providerPaymentMethodId: String
    $product: BillingProduct!
    $email: String!
    $fullName: String!
    $address: SubscriptionAddress!
    $providerPromotionCodeId: String
    $checkoutOptionKinds: [SubscriptionCheckoutOptionKind!]
  ) {
    createSubscription(
      providerPaymentMethodId: $providerPaymentMethodId
      product: $product
      email: $email
      fullName: $fullName
      address: $address
      providerPromotionCodeId: $providerPromotionCodeId
      checkoutOptionKinds: $checkoutOptionKinds
    ) {
      subscription {
        ...CoreSubscriptionFields
      }
      providerSubscriptionId
      clientSecret
    }
  }
`

export default PURCHASE_SUBSCRIPTION
