import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { compact } from 'lodash'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text, Icon } from '@src/components/base'
import { topMemberNotesSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { MemberNoteCard } from '@src/screens/NutritionistHub/components/MemberNotes/MemberNoteCard'
import { ScrollViewWithFade } from '@src/components'
import { useNotesUpdateInfo } from '@src/screens/NutritionistHub/hooks/nutritionistNotes/useNotesUpdateInfo'
import { CustomEventTypes } from '@src/config'
import { useNutritionistHubTracker } from '@src/screens/NutritionistHub/hooks'
import { NoNotesPlaceholder } from '@src/screens/NutritionistHub/components/MemberNotes/NoNotesPlaceholder'

export const MemberTopNotes = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const topNotes = compact(useSelector(topMemberNotesSelector))
  const track = useNutritionistHubTracker()
  const noNotes = topNotes.length === 0

  const handleSeeAllPress = () => {
    track(CustomEventTypes.NutritionistHubSeeAllNotesTapped)
    navigation.navigate('MemberNotes')
  }
  const { nutritionistName, updatedLast } = useNotesUpdateInfo(topNotes)

  const updateInfo = !noNotes && (
    <Text type="small" style={styles.subtitle}>
      {`Last updated ${updatedLast} by ${nutritionistName}`}
    </Text>
  )

  const seeAllButton = !noNotes && (
    <Button
      accessibilityLabel="seeAllNotes"
      type="outline"
      size="small"
      style={styles.seeAllButton}
      onPress={handleSeeAllPress}
    >
      See All
    </Button>
  )

  const noNotesPlaceholder = noNotes && <NoNotesPlaceholder />

  return (
    <ScrollViewWithFade>
      <View style={styles.container}>
        <View style={styles.sectionHeader}>
          <View style={styles.header}>
            <Icon name="notepad" />
            <View style={styles.headerText}>
              <Text type="regular" bold>
                Nutritionist Notes
              </Text>
              {updateInfo}
            </View>
          </View>
          {seeAllButton}
        </View>
        <View style={styles.notesContainer}>
          {noNotesPlaceholder}
          {topNotes.map((note) => (
            <MemberNoteCard key={note.id} note={note} showLatestTag />
          ))}
        </View>
      </View>
    </ScrollViewWithFade>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    marginBottom: 24,
  },
  sectionHeader: {
    marginTop: 24,
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: { flexDirection: 'row', alignItems: 'center' },
  headerText: {
    marginLeft: 20,
    lineHeight: 24,
    flexDirection: 'column',
  },
  subtitle: {
    color: 'theme.text.secondary',
  },
  seeAllButton: {
    alignSelf: 'center',
  },
  notesContainer: {
    rowGap: 8,
  },
})
