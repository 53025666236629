import { AVPlaybackStatus, AVPlaybackStatusSuccess, Video, VideoProps } from 'expo-av'
import {
  ActivityIndicator,
  Animated,
  StyleSheet,
  TouchableWithoutFeedback,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { useEffect, useImperativeHandle, useRef, useState } from 'react'
import React from 'react'
import { Slider } from '@components'
import { Text, Icon } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ControlStates, ErrorSeverity, PlaybackStates } from './constants'
import { ErrorMessage, TouchableButton, getMinutesSecondsFromMilliseconds } from './utils'
import { ErrorType } from './constants'

/**
 * Code adapted from expo-video-player
 * https://github.com/ihmpavel/expo-video-player
 *
 * This is only needed while we are on an older version of react native. It only serves the
 * purpose of offering a custom video controls UI since `useNativeControls` causes a crash
 * on iOS version 17.2+
 * ref: https://github.com/expo/expo/issues/25713
 *
 * After we update react native and expo, we should attempt to use the native video controls
 * again. If they no longer trigger a crash when unmounted, then this should be removed in favour
 * of `useNativeControls`.
 *
 * Alternatively, this component can be cleaned up and we can have our own fully-customized
 * video controls if product/design wills it.
 *
 * I originally installed expo-video-player, but the wrapping component and styling assumptions
 * prevent us from adapting the video size dynamically to fit on our course/lesson screens.
 *
 * Please don't blame me for some of what you might find in this folder <3
 */
interface VideoPlayerProps {
  videoProps: VideoProps
  containerStyle?: StyleProp<ViewStyle>
  errorCallback: (error: ErrorType) => void
  playbackCallback: (status: AVPlaybackStatus) => void
  defaultControlsVisible?: boolean
  showControls?: boolean
  animation?: {
    fadeInDuration?: number
    fadeOutDuration?: number
  }
  autoHidePlayer?: boolean
  mute?: {
    enterMute?: () => void
    exitMute?: () => void
    isMute?: boolean
    visible?: boolean
  }
}

const VideoPlayer = React.forwardRef<Video | null, VideoPlayerProps>((props, forwardedRef) => {
  const {
    videoProps,
    containerStyle,
    errorCallback,
    playbackCallback,
    defaultControlsVisible = false,
    animation = {
      fadeInDuration: 300,
      fadeOutDuration: 300,
    },
    showControls = false,
    autoHidePlayer = true,
    mute = {
      isMute: false,
      visible: false,
    },
  } = props

  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()

  const playbackInstance = useRef<Video | null>(null)
  useImperativeHandle(forwardedRef, () => playbackInstance.current as Video, [])

  let controlsTimer: NodeJS.Timeout | null = null
  let initialShow = defaultControlsVisible

  const [errorMessage, setErrorMessage] = useState('')
  const controlsOpacity = useRef(new Animated.Value(defaultControlsVisible ? 1 : 0)).current
  const [controlsState, setControlsState] = useState(
    defaultControlsVisible ? ControlStates.Visible : ControlStates.Hidden,
  )
  const [playbackInstanceInfo, setPlaybackInstanceInfo] = useState({
    position: 0,
    duration: 0,
    state: videoProps.source ? PlaybackStates.Loading : PlaybackStates.Error,
  })

  useEffect(() => {
    if (!videoProps.source) {
      console.error(
        '[VideoPlayer] `Source` is a required in `videoProps`. ' +
          'Check https://docs.expo.io/versions/latest/sdk/video/#usage',
      )
      setErrorMessage('`Source` is a required in `videoProps`')
      setPlaybackInstanceInfo((playbackInstanceInfo) => ({
        ...playbackInstanceInfo,
        state: PlaybackStates.Error,
      }))
    }
  }, [videoProps.source])

  const hideAnimation = () => {
    Animated.timing(controlsOpacity, {
      toValue: 0,
      duration: animation.fadeOutDuration,
      useNativeDriver: true,
    }).start(({ finished }) => {
      if (finished) {
        setControlsState(ControlStates.Hidden)
      }
    })
  }

  const animationToggle = () => {
    if (controlsState === ControlStates.Hidden) {
      Animated.timing(controlsOpacity, {
        toValue: 1,
        duration: animation.fadeInDuration,
        useNativeDriver: true,
      }).start(({ finished }) => {
        if (finished) {
          setControlsState(ControlStates.Visible)
        }
      })
    } else if (controlsState === ControlStates.Visible) {
      hideAnimation()
    }

    if (controlsTimer === null && autoHidePlayer) {
      controlsTimer = setTimeout(() => {
        if (
          playbackInstanceInfo.state === PlaybackStates.Playing &&
          controlsState === ControlStates.Hidden
        ) {
          hideAnimation()
        }
        if (controlsTimer) {
          clearTimeout(controlsTimer)
        }
        controlsTimer = null
      }, 2000)
    }
  }

  const updatePlaybackCallback = (status: AVPlaybackStatus) => {
    playbackCallback(status)

    if (status.isLoaded) {
      setPlaybackInstanceInfo({
        ...playbackInstanceInfo,
        position: status.positionMillis,
        duration: status.durationMillis || 0,
        state: ((status: AVPlaybackStatusSuccess) => {
          if (status.positionMillis === status.durationMillis) {
            return PlaybackStates.Ended
          } else if (status.isBuffering) {
            return PlaybackStates.Buffering
          } else if (status.isPlaying) {
            return PlaybackStates.Playing
          }
          return PlaybackStates.Paused
        })(status),
      })
      if (
        (status.didJustFinish && controlsState === ControlStates.Hidden) ||
        (status.isBuffering && controlsState === ControlStates.Hidden && initialShow)
      ) {
        animationToggle()
        initialShow = false
      }
    } else {
      if (status.isLoaded === false && status.error) {
        const errorMsg = `Encountered a fatal error during playback: ${status.error}`
        setErrorMessage(errorMsg)
        errorCallback({ type: ErrorSeverity.Fatal, message: errorMsg, obj: {} })
      }
    }
  }

  const togglePlay = async () => {
    if (controlsState === ControlStates.Hidden) {
      return
    }
    const shouldPlay = playbackInstanceInfo.state !== PlaybackStates.Playing
    if (playbackInstance.current !== null) {
      await playbackInstance.current?.setStatusAsync({
        shouldPlay,
        ...(playbackInstanceInfo.state === PlaybackStates.Ended && { positionMillis: 0 }),
      })
      setPlaybackInstanceInfo({
        ...playbackInstanceInfo,
        state:
          playbackInstanceInfo.state === PlaybackStates.Playing
            ? PlaybackStates.Paused
            : PlaybackStates.Playing,
      })
      if (shouldPlay) {
        animationToggle()
      }
    }
  }

  if (playbackInstanceInfo.state === PlaybackStates.Error) {
    return (
      <View style={styles.errorContainer}>
        <ErrorMessage style={styles.errorMessage} message={errorMessage} />
      </View>
    )
  }

  return (
    <>
      {playbackInstanceInfo.state === PlaybackStates.Loading && (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color={theme['theme.solid.white']} />
        </View>
      )}
      <Video
        ref={playbackInstance}
        {...videoProps}
        onPlaybackStatusUpdate={updatePlaybackCallback}
      />

      <Animated.View
        pointerEvents={controlsState === ControlStates.Visible ? 'auto' : 'none'}
        style={[
          styles.topInfoWrapper,
          {
            opacity: controlsOpacity,
          },
        ]}
      />

      {showControls && (
        <TouchableWithoutFeedback accessibilityLabel="Toggle Controls" onPress={animationToggle}>
          <Animated.View style={[styles.controlsContainer, { opacity: controlsOpacity }]}>
            <View style={[styles.controlsOverlay, containerStyle]} />
            <View pointerEvents={controlsState === ControlStates.Visible ? 'auto' : 'none'}>
              <View style={styles.iconWrapper}>
                <TouchableButton accessibilityLabel="Toggle playback" onPress={togglePlay}>
                  <View>
                    {playbackInstanceInfo.state === PlaybackStates.Buffering && (
                      <ActivityIndicator size="large" color={theme['theme.solid.white']} />
                    )}
                    {playbackInstanceInfo.state === PlaybackStates.Playing && (
                      <Icon name="pause-circle" weight="fill" size={64} />
                    )}
                    {playbackInstanceInfo.state === PlaybackStates.Paused && (
                      <Icon name="play-circle" weight="fill" size={64} />
                    )}
                    {playbackInstanceInfo.state === PlaybackStates.Ended && (
                      <Icon name="arrow-clockwise" weight="fill" size={64} />
                    )}
                  </View>
                </TouchableButton>
              </View>
            </View>
          </Animated.View>
        </TouchableWithoutFeedback>
      )}

      <Animated.View
        pointerEvents={controlsState === ControlStates.Visible ? 'auto' : 'none'}
        style={[
          styles.bottomInfoWrapper,
          {
            opacity: controlsOpacity,
          },
        ]}
      >
        {showControls && (
          <Text type="tiny" style={styles.time} bold>
            {getMinutesSecondsFromMilliseconds(playbackInstanceInfo.position)}
          </Text>
        )}
        {showControls && (
          <Slider
            wrapperStyle={styles.slider}
            min={0}
            max={100}
            step={1}
            values={[
              playbackInstanceInfo.duration
                ? (playbackInstanceInfo.position / playbackInstanceInfo.duration) * 100
                : 0,
            ]}
            onValuesChangeStart={() => {
              if (playbackInstanceInfo.state === PlaybackStates.Playing) {
                togglePlay()
                setPlaybackInstanceInfo({ ...playbackInstanceInfo, state: PlaybackStates.Paused })
              }
            }}
            onValuesChangeFinish={async (values) => {
              const position = (values[0] / 100) * playbackInstanceInfo.duration
              if (playbackInstance.current) {
                await playbackInstance.current?.setStatusAsync({
                  positionMillis: position,
                  shouldPlay: true,
                })
              }
              setPlaybackInstanceInfo({
                ...playbackInstanceInfo,
                position,
              })
            }}
          />
        )}
        {showControls && (
          <Text type="tiny" style={styles.time} bold>
            {getMinutesSecondsFromMilliseconds(playbackInstanceInfo.duration)}
          </Text>
        )}
        {mute.visible && (
          <TouchableButton
            accessibilityLabel="Toggle mute"
            onPress={() => (mute.isMute ? mute.exitMute?.() : mute.enterMute?.())}
          >
            <View>
              <Icon
                name="speaker-simple-slash"
                weight="bold"
                size={24}
                style={styles.controlIcon}
              />
              <Icon name="speaker-simple-high" weight="bold" size={24} style={styles.controlIcon} />
            </View>
          </TouchableButton>
        )}
        {showControls && (
          <TouchableButton
            accessibilityLabel="Toggle full screen"
            onPress={() => {
              playbackInstance.current?.presentFullscreenPlayer()
              playbackInstance.current?.setStatusAsync({ shouldPlay: true })
            }}
          >
            <View>
              <Icon name="arrows-out-simple" weight="bold" size={24} style={styles.controlIcon} />
            </View>
          </TouchableButton>
        )}
      </Animated.View>
    </>
  )
})

const themedStyles = StyleService.create({
  topInfoWrapper: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 999,
  },
  bottomInfoWrapper: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
    bottom: 4,
    left: 0,
    right: 0,
  },
  iconWrapper: {
    borderRadius: 100,
    overflow: 'hidden',
    padding: 10,
  },
  time: {
    marginHorizontal: 8,
    color: 'theme.solid.white',
  },
  slider: {
    flex: 1,
    marginHorizontal: 8,
    marginBottom: 1,
  },
  errorMessage: {
    color: 'theme.solid.white',
  },
  errorContainer: {
    flex: 1,
    backgroundColor: 'theme.transparent',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'theme.transparent',
  },
  controlsContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
  },
  controlsOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'theme.solid.black',
    opacity: 0.3,
  },
  controlIcon: {
    marginRight: 8,
    color: 'theme.solid.white',
  },
})

export default VideoPlayer
