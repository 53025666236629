import React from 'react'
import { View, Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { NutrisenseLogo } from '@components'
import { openUrl } from '@src/utils'

export const OutdatedScreen = () => {
  const insets = useSafeAreaInsets()
  const styles = useStyleSheet(themedStyle)

  const onButtonPress = async () => {
    const productionAppID = '1479100171'
    let url = `https://apps.apple.com/app/nutrisense/${productionAppID}`
    if (Platform.OS === 'android') {
      url = 'market://details?id=com.nutrisense'
    }
    try {
      await openUrl(url)
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    <View style={[styles.container, { paddingBottom: insets.bottom + 16 }]}>
      <View style={styles.content}>
        <NutrisenseLogo />
        <Text type="title-2" style={styles.title}>
          It's time for an update
        </Text>
        <Text type="regular" style={styles.description}>
          The current version of the application is no longer supported. Please update the app.
        </Text>
      </View>
      <Button accessibilityLabel="Update Now" type="primary" size="block" onPress={onButtonPress}>
        Update Now
      </Button>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginTop: 64,
    marginBottom: 16,
  },
  description: {
    textAlign: 'center',
  },
})
