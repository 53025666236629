import React from 'react'
import { TextStyle, TouchableOpacity, ViewStyle } from 'react-native'
import { IconWeight } from 'phosphor-react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName, Text } from '@components/base'

interface MealActionProps {
  icon: IconName
  iconWeight?: IconWeight
  name: string
  onPress: () => void
  iconStyles?: ViewStyle
  textStyles?: TextStyle
  disabled?: boolean
  testID?: string
}

export const MealAction = ({
  icon,
  iconWeight,
  name,
  onPress,
  iconStyles = {},
  textStyles = {},
  testID,
  disabled = false,
}: MealActionProps) => {
  const styles = useStyleSheet(themedStyle)
  const disabledStyles = disabled && styles.disabledColor

  return (
    <TouchableOpacity
      accessible={false}
      hitSlop={{ top: 8, bottom: 8 }}
      onPress={onPress}
      disabled={disabled}
      style={styles.action}
      accessibilityLabel={name}
    >
      <Icon style={[styles.icon, disabledStyles, iconStyles]} name={icon} weight={iconWeight} />
      <Text type="regular" testID={testID} style={[disabledStyles, textStyles]}>
        {name}
      </Text>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  action: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  disabledColor: {
    color: 'theme.disabled.text',
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
})
