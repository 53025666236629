export interface CircularProgressChartParams {
  value: number
  min: number
  max: number
  style?: any
}

export const circularProgressChart = ({
  value = 0,
  min = 0,
  max = 100,
  style,
}: CircularProgressChartParams) => `(function(){
  chart = new F2.Chart({
    id: 'chart',
    pixelRatio: window.devicePixelRatio,
    padding: 0
  });

  chart.source([{x: '1', y: ${value}}], {
     y: {
       min: ${min},
       max: ${max}
     }
   })

  chart.legend(false);
  chart.tooltip(false);
  chart.axis(false);

  chart.coord('polar', {
    transposed: true,
    innerRadius: 0.5,
    radius: 1
  });

  chart.guide().arc({
    start: [ 0, 0 ],
    end: [ 1, 99.98 ],
    top: false,
    style: {
      lineWidth: 4,
      stroke: '#ccc'
    }
  })
  chart.guide().text({
    position: [ '50%', '50%' ],
    content: '${value}',
    style: {
      fontSize: ${style?.fontSize},
      fill: '${style?.textColor}' || '#1890FF'
    }
  })
  chart.interval()
    .position('x*y')
    .color('${style?.color}' || '#8BC440')
    .size(6)
  chart.render()
})(); true;
`
