import React from 'react'
import { Control, Controller, Validate } from 'react-hook-form'
import { KeyboardType, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'

interface ControllerComponentProps {
  useFieldRef?: boolean
  Component: any
  control: Control<any>
  label?: string
  name: string
  required?: boolean
  validate?: Validate<any, any>
  placeholder?: string
  keyboardType?: KeyboardType
  options?: {
    text: string
    value: string
  }[]
  onChangeText?: (value: string) => string
  onSelect?: (idx: number) => void
  selectedOption?: {
    value: string
    text: string
  }
  value?: string
  disabled?: boolean
}

export const ControllerComponent = ({
  useFieldRef = true,
  Component,
  control,
  label,
  name,
  required,
  validate,
  onChangeText,
  ...others
}: ControllerComponentProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.input}>
      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { invalid } }) => (
          <Component
            fieldRef={useFieldRef ? ref : undefined}
            label={label}
            onChange={(text: string) => {
              const newText = onChangeText?.(text) ?? text
              onChange(newText)
            }}
            onBlur={onBlur}
            value={value}
            isInvalid={invalid}
            {...others}
          />
        )}
        name={name}
        rules={{ required, validate }}
      />
    </View>
  )
}

const themedStyle = StyleService.create({
  input: {
    marginBottom: 16,
  },
})
