import { gql } from '@apollo/client'

const ALL_NUTRITION = gql`
  query allNutrition($filter: DateFilter) {
    allNutrition(filter: $filter) {
      nutrition {
        today {
          key
          value
        }
      }
      score {
        today {
          scoreTimeOutsideRange
          scorePeak
          scoreMean
          scoreStdDev
          score
        }
      }
      statistics {
        today {
          mean
        }
      }
      updatedAt
    }
  }
`

export default ALL_NUTRITION
