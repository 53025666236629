import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { GoalMetric, Statistic } from '@src/types'
import { Icon, Text } from '@src/components/base'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import {
  goalMetricOptionsSelector,
  measurementsConfigSelector,
  scoreSelector,
} from '@src/selectors/app'
import { insightsCalendarSelector } from '../models/insights.selectors'
import {
  getMeasurementUnitSymbol,
  getPeriodComparisonText,
  getShortStatTitle,
  getValueChangeText,
} from '../utils/utils'
import { getPageConfig } from '../utils/pageConfig'

interface GoalMetricCardProps {
  metric: GoalMetric
  stat: Statistic | undefined
  isSingleMetric: boolean
}

export const GoalMetricCard = (props: GoalMetricCardProps) => {
  const { metric, stat, isSingleMetric } = props
  const styles = useStyleSheet(themedStyles)

  const navigation = useNavigation()

  const unitSystem = useSelector(unitSystemStoreStateSelector)
  const measurementsConfig = useSelector(measurementsConfigSelector)
  const scoreSettings = useSelector(scoreSelector)
  const calendar = useSelector(insightsCalendarSelector)
  const goalMetricOptions = useSelector(goalMetricOptionsSelector)

  const pageConfig = getPageConfig(scoreSettings)

  if (!stat) {
    return null
  }

  const onPress = () => {
    navigation.navigate('InsightsDetailedPage', { statistic: stat })
  }

  const measurementConfig = measurementsConfig.find(({ name }) => name === stat.meta.key)
  const config = pageConfig[stat.meta.section + stat.meta.key]

  const unit = getMeasurementUnitSymbol({
    stat,
    defaultUnit: config?.units,
    unitSystem,
    measurementConfig,
  })

  const title =
    goalMetricOptions.find((option) => option.value === metric.kind)?.text ||
    getShortStatTitle(stat.meta.key, stat.meta.section)

  const valueChangeText = getValueChangeText(stat)
  const periodComparisonText = getPeriodComparisonText(calendar)
  const displayedUnit = valueChangeText === 'No change' ? '' : unit

  return (
    <TouchableOpacity
      accessibilityLabel={title}
      key={metric.kind}
      style={styles.metricContainer}
      onPress={onPress}
    >
      <View style={styles.titleContainer}>
        <Text type="regular">{title}</Text>
        <Icon name="caret-right" weight="bold" style={styles.caret} />
      </View>
      <View style={isSingleMetric ? styles.singleMetricContainer : {}}>
        <View style={styles.metricValueContainer}>
          {stat.value === 0 ? (
            <Text type="title-1" style={styles.noValue}>
              N/A
            </Text>
          ) : (
            <>
              <Text type="title-1">{stat.value}</Text>
              <Text type="small" bold style={styles.unit}>
                {unit}
              </Text>
            </>
          )}
        </View>
        {!!stat.value && !!stat.valuePrev && (
          <Text type="small" style={styles.valueChange}>
            {`${valueChangeText}${displayedUnit} ${periodComparisonText}`}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  metricContainer: {
    flex: 1,
    width: '100%',
    minWidth: 170,
    borderRadius: 16,
    borderWidth: 2,
    padding: 12,
    borderColor: 'theme.surface.base1',
    backgroundColor: 'theme.background',
  },
  metricValueContainer: {
    marginTop: 3,
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  unit: {
    marginBottom: 6,
  },
  caret: {
    color: 'theme.surface.base0',
    height: 16,
    width: 16,
  },
  valueChange: {
    marginTop: 17,
    color: 'theme.text.secondary',
  },
  singleMetricContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  noValue: {
    color: 'theme.text.secondary',
  },
})
