import AppleHealthKit, {
  HealthValue,
  HealthInputOptions,
  HealthValueOptions,
  HealthKitPermissions,
  HealthObserver,
} from 'react-native-health'
import moment from 'moment'
import { Device, Bugsnag } from '@config'
import { Measurement } from '@src/types'
import { getSamples as getSamplesBase } from '../ThirdPartyHealth'
import { transform } from './transform'

const PERMS = AppleHealthKit.Constants.Permissions

const AGGREGATE_MEASUREMENT_TYPES = [
  'DailyDistanceWalkingRunning',
  'DailyDistanceCycling',
  'DailyFlightsClimbed',
  'AppleExerciseTime',
  'DailyStepCount',
] as const

const MEASUREMENT_TYPES = ['BodyTemperature', 'BloodGlucose', 'Weight'] as const

const ACTIVITY_TYPES = ['Sleep', 'Workout'] as const

const ALL_TYPES = [...AGGREGATE_MEASUREMENT_TYPES, ...MEASUREMENT_TYPES, ...ACTIVITY_TYPES] as const

const healthKitOptions = {
  permissions: {
    read: [
      PERMS.ActiveEnergyBurned,
      PERMS.AppleExerciseTime,
      PERMS.BasalEnergyBurned,
      PERMS.BiologicalSex,
      PERMS.BloodGlucose,
      PERMS.BloodPressureDiastolic,
      PERMS.BloodPressureSystolic,
      PERMS.BodyFatPercentage,
      PERMS.BodyMassIndex,
      PERMS.BodyTemperature,
      PERMS.DateOfBirth,
      PERMS.DistanceCycling,
      PERMS.DistanceSwimming,
      PERMS.DistanceWalkingRunning,
      PERMS.EnergyConsumed,
      PERMS.FlightsClimbed,
      PERMS.HeartRate,
      PERMS.HeartRateVariability,
      PERMS.Height,
      PERMS.LeanBodyMass,
      PERMS.MindfulSession,
      PERMS.RespiratoryRate,
      PERMS.RestingHeartRate,
      PERMS.SleepAnalysis,
      PERMS.StepCount,
      PERMS.WalkingHeartRateAverage,
      PERMS.Weight,
      PERMS.Workout,
    ],
    write: [PERMS.BloodGlucose],
  },
} as HealthKitPermissions

const DEBUG = false

const DATA_SOURCE = 'healthkit'

const initialize = () => {
  if (!Device.hasHealthKit) {
    return
  }

  const result: Promise<HealthValue> = new Promise((resolve) => {
    AppleHealthKit.initHealthKit(healthKitOptions, (err, results) => {
      if (err) {
        console.log('Error initializing Healthkit: ', err)
      } else {
        console.log('initialize Healthkit successfully!')
      }
      resolve(results)
    })
  })

  return result
}

export const buildQueries = (types: readonly string[], startDate: string, endDate: string) => {
  return (types as
    | typeof AGGREGATE_MEASUREMENT_TYPES
    | typeof MEASUREMENT_TYPES
    | typeof ACTIVITY_TYPES).map((type) => {
    const period = 1440 // in 1 day increments
    const options: HealthInputOptions = {
      type: type as HealthObserver,
      period,
      startDate,
      endDate,
      includeManuallyAdded: true,
    }

    if (DEBUG) {
      console.log('HealthKit Type: ', type)
    }
    if (DEBUG) {
      console.log('HealthKit Options: ', options)
    }

    let result: Promise<HealthValue[]>

    if (type === 'AppleExerciseTime') {
      result = new Promise((resolve) =>
        AppleHealthKit.getAppleExerciseTime(options, (error, results) => {
          if (error) {
            if (!isPermissionsNotDeterminedError(error)) {
              Bugsnag.notify(error)
            }
            resolve([])
            return
          }
          resolve(results)
        }),
      )
    } else if (type === 'Workout') {
      result = new Promise((resolve) =>
        AppleHealthKit.getSamples(options, (error, results) => {
          if (error) {
            if (!isPermissionsNotDeterminedError(error)) {
              Bugsnag.notify(error)
            }
            resolve([])
            return
          }

          resolve(results)
        }),
      )
    } else {
      result = new Promise((resolve) =>
        AppleHealthKit[`get${type}Samples`](options, (error, results) => {
          if (error) {
            if (!isPermissionsNotDeterminedError(error)) {
              Bugsnag.notify(error)
            }
            resolve([])
            return
          }

          resolve(results)
        }),
      )
    }

    return result
  })
}

const getSamples = ({
  lastSynced,
  ignore = [],
  sourceIgnore = [],
}: {
  lastSynced: string
  ignore: string[]
  sourceIgnore: string[]
}) => {
  return getSamplesBase(
    { lastSynced, transform, buildQueries, ignore, sourceIgnore },
    { AGGREGATE_MEASUREMENT_TYPES, MEASUREMENT_TYPES, ACTIVITY_TYPES, DEBUG, DATA_SOURCE },
  )
}

const saveGlucoseSample = ({ id, value, occurredAt, units }: Measurement) => {
  const unit =
    units === 'mg/dL'
      ? AppleHealthKit.Constants.Units.mgPerdL
      : AppleHealthKit.Constants.Units.mmolPerL
  const options: HealthValueOptions = {
    value: value as number,
    unit,
    startDate: moment(occurredAt).toISOString(),
    metadata: {
      // https://developer.apple.com/documentation/healthkit/hkmetadatakeysyncidentifier
      HKMetadataKeySyncIdentifier: id,
      HKMetadataKeySyncVersion: 1,
    },
  }
  AppleHealthKit.saveBloodGlucoseSample(options, (error, update) => {
    if (DEBUG) {
      if (error) {
        console.log('HealthKit Error: ', error)
        if (!isPermissionsNotDeterminedError(error)) {
          Bugsnag.notify(error)
        }
      } else {
        console.log('HealthKit Update: ', update)
      }
    }
  })
}

const saveGlucoseSamples = (samples: Measurement[]) => {
  samples.forEach((sample) => saveGlucoseSample(sample))
}

const isPermissionsNotDeterminedError = (error: any) => {
  return error['message'] && error['message'].includes('Authorization not determined')
}

const samplesTypeResolver = (type: typeof ALL_TYPES[number]) => {
  if (type === 'AppleExerciseTime') {
    return AppleHealthKit.getAppleExerciseTime
  } else if (type === 'Workout') {
    return AppleHealthKit.getSamples
  }
  return AppleHealthKit[`get${type}Samples`]
}

// Not determined status means that we didn't ask for Apple Health permissions screen for some reason
// Possible reason is that user delete app and install it again or login on another iPhone
// There is no direct way to check permissions status, but we can try detecting it
// via running getSamples queries and analyzing error message.
//  If it contains 'Authorization not determined' it means that permissions status is not determined
const isPermissionsStatusDetermined = async () => {
  const startDate = moment().subtract(1, 'day').toISOString()
  const endDate = moment().toISOString()
  const period = 1440 // 1 day in minutes

  const allPermissionsState = await Promise.all(
    ALL_TYPES.map((type) => {
      const resolver = samplesTypeResolver(type)
      const options: HealthInputOptions = {
        type: type as HealthObserver,
        period,
        startDate,
        endDate,
        includeManuallyAdded: true,
      }
      return new Promise((resolve) => {
        resolver(options, (error) => {
          if (error && isPermissionsNotDeterminedError(error)) {
            resolve(false)
          }
          resolve(true)
        })
      })
    }),
  )
  return allPermissionsState.every((isDetermined) => isDetermined)
}

export default {
  initialize,
  getSamples,
  saveGlucoseSample,
  saveGlucoseSamples,
  isPermissionsStatusDetermined,
}
