import url from 'url'
import { NativeModules } from 'react-native'
import { getBuildNumber } from 'react-native-device-info'
import { getTimeZone } from 'react-native-localize'
import Config from 'react-native-config'
import { isEmpty } from 'lodash'
import version from './version'

const hostname = () => {
  const ip = url.parse(NativeModules.SourceCode.scriptURL).hostname
  const ipConfig = Config.IP_LOCAL_SERVER

  return (!isEmpty(ip) && ip) || (!isEmpty(ipConfig) && ipConfig) || 'localhost'
}

export type BuildType = 'production' | 'development' | 'alpha' | 'beta'

const build = (Config.ENV as BuildType) || 'development'

const config = {
  version,
  buildNumber: getBuildNumber(),
  appleAppID: Config.APPLE_APP_ID,
  googlePackageName: Config.GOOGLE_PACKAGE_NAME,
  timeZone: getTimeZone(),
  allowTextFontScaling: true,
  enableCaching: true,
  bundle: {
    env: __DEV__ ? 'development' : 'production',
    development: __DEV__,
    production: !__DEV__,
  },
  build: {
    env: build,
    development: build === 'development',
    production: build === 'production',
    alpha: build === 'alpha',
    beta: build === 'beta',
    endpoint: Config.API_ENDPOINT ? `${Config.API_ENDPOINT}/graphql` : undefined,
  },
  defaultEndpoints: {
    production: 'https://api-production.nutrisense.io/graphql',
    beta: 'https://api-production.nutrisense.io/graphql',
    staging: 'https://api-staging.nutrisense.io/graphql',
    alpha: 'https://api-staging.nutrisense.io/graphql',
    development: `http://${hostname()}:3000/graphql`,
  },
}

console.log('ENV Config:', Config)
console.log('App Config:', JSON.stringify(config))

export default config
