import React, { useState, useCallback, useMemo } from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AudioPlayer, Audio } from '@src/components/AudioPlayer'
import { LessonItemContentProps } from '../../lesson.types'
import { LessonItemContentMedia } from '../../models/courses.types'
import { LessonItemContainer } from './LessonItemContainer'

type AudioComponentProps = LessonItemContentProps & {
  item: LessonItemContentMedia
}

export const AudioComponent = (props: AudioComponentProps) => {
  const { item, isCompleted, onNext, onComplete } = props
  const styles = useStyleSheet(themedStyles)
  const audioRef = React.useRef<Audio.Sound | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const audioUrl = item?.media?.data?.attributes?.url

  const onPlay = () => onComplete()
  const onReady = useCallback(() => setIsLoading(false), [])
  const source = useMemo(() => ({ uri: audioUrl }), [audioUrl])

  const onNextPress = () => {
    if (isCompleted) {
      audioRef.current?.pauseAsync()
      onNext()
    } else {
      audioRef.current?.playAsync()
    }
  }

  const navButtonOverrideProps = {
    nextDisabled: isLoading,
    onNext: onNextPress,
    preCompleteNextTitle: 'Start',
  }

  return (
    <LessonItemContainer
      {...props}
      {...navButtonOverrideProps}
      lessonItemIcon="speaker-low"
      lessonItemTitle={item.title}
    >
      <View style={styles.container}>
        <AudioPlayer ref={audioRef} source={source} onReady={onReady} onPlay={onPlay} />
      </View>
    </LessonItemContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
})
