import React, { useState } from 'react'
import { Input } from '@components/base'
import { InputType, TextInputConfig } from '../../types'
import { INPUT_SANITIZER } from './Shared/utils'
import { InputPropsBase } from './types'
import { useSetDefaultValue } from './Shared/hooks'

interface TextInputProps extends InputPropsBase {
  response: TextInputConfig
  numberOfLines?: number
}

export const TextInput = ({ field, response, numberOfLines }: TextInputProps): JSX.Element => {
  const [value, setValue] = useState<string>(field.value || '')

  useSetDefaultValue({ fieldValue: field.value, onChange: field.onChange, defaultValue: null })

  const { type = InputType.STRING, placeholder, label } = response.kindMetadata || {}

  const onChangeText = (value: string) => {
    const processedValue = INPUT_SANITIZER[type](value)

    if (processedValue === null) {
      return
    }

    setValue(processedValue)
    field.onChange(processedValue.trim() || null)
  }

  return (
    <Input
      label={label}
      placeholder={placeholder ?? 'Type your answer'}
      onChangeText={onChangeText}
      value={value}
      blurOnSubmit
      keyboardType={type === InputType.NUMBER ? 'numeric' : 'default'}
      returnKeyType="done"
      numberOfLines={numberOfLines}
    />
  )
}
