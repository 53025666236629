import React from 'react'
import { ScrollView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { NavigationContainer } from '@src/screens/Common/containers'
import { openUrl } from '@src/utils'
import { AppStackParamList } from '../types'

const CALIBRATION_URL =
  // eslint-disable-next-line max-len
  'https://support.nutrisense.io/hc/en-us/articles/4402816254231-Calibrating-Your-CGM?utm_source=sensor-info-modal&utm_medium=ns-mobile-app'

export const SensorInfoModal = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const goToManualCalibration = () => {
    navigation.navigate('SensorSettings')
  }
  const goToLearnAboutCalibration = () => {
    openUrl(CALIBRATION_URL)
  }

  return (
    <NavigationContainer
      title="Info About Your CGM"
      leftIcon={{ pack: 'eva', name: 'close' }}
      style={styles.container}
      navigationBarProps={{ backgroundColor: 'transparent' }}
    >
      <ScrollView contentContainerStyle={styles.flexContainer}>
        <View style={styles.mainContentContainer}>
          <View style={styles.infoIconContainer}>
            <Icon name="info" weight="fill" style={styles.infoIcon} />
          </View>
          <Text type="title-2" style={styles.heading}>
            Great News!
          </Text>
          <Text type="regular" style={styles.secondaryText}>
            Your CGM’s initial self-calibration is completed
          </Text>

          <View style={styles.card}>
            <Icon name="align-left" weight="fill" style={styles.calibrateIcon} />
            <Text type="regular" style={styles.mainText}>
              If the CGM absolute value is slightly off, you have the option to manually calibrate
            </Text>
          </View>
          <View style={styles.card}>
            <Icon name="gear" weight="fill" style={styles.gearIcon} />
            <Text type="regular" style={styles.mainText}>
              Take your fasted glucose value and manually calibrate the CGM to match up
            </Text>
          </View>
        </View>

        <View style={[styles.buttonsContainer, { paddingBottom: insets.bottom + 16 }]}>
          <Button
            type="primary"
            size="block"
            onPress={goToLearnAboutCalibration}
            style={styles.filledButton}
            accessibilityLabel="Learn about Calibration"
          >
            Learn about Calibration
          </Button>
          <Button
            type="transparent"
            size="block"
            onPress={goToManualCalibration}
            accessibilityLabel="Manual calibration"
          >
            Manual calibration
          </Button>
        </View>
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  flexContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  container: {
    backgroundColor: 'theme.background.modal',
  },
  mainContentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingTop: 32,
  },
  heading: {
    marginVertical: 16,
  },
  secondaryText: {
    marginBottom: 48,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  mainText: {
    marginHorizontal: 16,
  },
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 24,
    marginBottom: 32,
  },
  infoIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: 80,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base1',
  },
  infoIcon: {
    color: 'theme.info.base',
    width: 48,
    height: 48,
  },
  gearIcon: {
    width: 32,
    height: 32,
    color: 'theme.text.tertiary',
  },
  calibrateIcon: {
    width: 32,
    height: 32,
    color: 'theme.success.darkest',
  },
  buttonsContainer: {
    marginHorizontal: 16,
  },
  filledButton: {
    marginBottom: 8,
  },
})
