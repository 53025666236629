import React, { useState, useCallback, useEffect } from 'react'
import { Drawer, DrawerItem, IndexPath } from '@ui-kitten/components'
import { useSelector } from 'react-redux'
import { DrawerContentComponentProps } from '@react-navigation/drawer'
import { useWindowDimensions, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Pill, Text } from '@components/base'
import { userSelector } from '@src/selectors/app'
import { DrawerHeader } from '@src/navigation/components/drawer/DrawerHeader'
import { IPHONE_7_SCREEN_HEIGHT_BREAKPOINT } from '@src/utils/screenBreakpoints'
import { useWebPresentationModeContext } from '@src/config/webPresentationMode'
import { useDrawerItems, DrawerItemInfo } from './hooks'
import { DrawerFooter } from './DrawerFooter'

export const DrawerContent = ({ navigation, state }: DrawerContentComponentProps) => {
  const dimensions = useWindowDimensions()
  const styles = useStyleSheet(themedStyles)
  const [selectedIndex, setSelectedIndex] = useState(-1)

  const { fullName = '', avatar } = useSelector(userSelector) ?? {}
  const { visibleDrawerItems } = useDrawerItems()
  const { isWebPresentationMode } = useWebPresentationModeContext()

  const onSelect = ({ row: index }: IndexPath) => {
    const item: DrawerItemInfo = visibleDrawerItems[index]
    if (item.routeName) {
      setSelectedIndex(index)
      navigation.navigate(item.routeName, item.routeParams)
      navigation.closeDrawer()
    } else if (item.onItemPress) {
      item.onItemPress()
    }
  }

  useEffect(() => {
    const routeName = state.routeNames[state.index]
    const menuIndex = visibleDrawerItems.findIndex(
      (item) => !!routeName && item.routeName === routeName,
    )
    setSelectedIndex(menuIndex)
  }, [state.index, state.routeNames, visibleDrawerItems, selectedIndex])

  const renderHeader = useCallback(
    (headerProps: any) => (
      <DrawerHeader
        {...headerProps}
        title={fullName}
        imageSource={avatar && { uri: avatar }}
        navigation={navigation}
      />
    ),
    [fullName, avatar, navigation],
  )

  const drawerItemStyle = {
    height: dimensions.height > IPHONE_7_SCREEN_HEIGHT_BREAKPOINT ? 56 : 48,
  }

  return (
    <Drawer
      appearance="noDivider"
      selectedIndex={new IndexPath(selectedIndex)}
      onSelect={onSelect}
      header={isWebPresentationMode ? undefined : renderHeader}
      footer={DrawerFooter}
    >
      {visibleDrawerItems.map((item: DrawerItemInfo, index: number) => (
        <DrawerItem
          key={index}
          style={drawerItemStyle}
          title={item.title}
          accessoryLeft={<Icon name={item.icon} />}
          accessoryRight={() => (
            <>
              {!!item.tag && <Pill fill="citron" text={item.tag} />}
              {!!item.indicator && (
                <View style={styles.indicator}>
                  <Text type="regular" bold style={styles.indicatorText}>
                    {item.indicator}
                  </Text>
                </View>
              )}
            </>
          )}
        />
      ))}
    </Drawer>
  )
}

const themedStyles = StyleService.create({
  indicator: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: 'theme.error.base',
  },
  indicatorText: {
    color: 'theme.solid.white',
  },
})
