import React from 'react'
import { GestureDismissibleAlertModal } from '@components'
import { useGoBack } from '@utils/navigation'
import { Intercom } from '@src/utils'

export const DexcomResearchOptedOutModal = () => {
  const goBack = useGoBack()

  const handleContactSupport = () => {
    Intercom.showIntercomMessenger({ source: 'DexcomResearchOptedOutModal' })
    goBack()
  }

  return (
    <GestureDismissibleAlertModal
      title="You have opted out of research"
      description="Please contact support if you'd like to Opt In."
      actionTitle="Contact Support"
      dismissButtonText="Close"
      onActionPress={handleContactSupport}
    />
  )
}
