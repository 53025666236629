import React from 'react'
import { StyleProp, TextStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { TextWithBoldHighlight } from './TextWithBoldHighlight'

interface QuestionTextProps {
  answerRequired: boolean
  isFollowup?: boolean
  style?: StyleProp<TextStyle>
  children: string
}

// \u00A0 is a non-breaking space character - needed to avoid having * alone in new line
const requiredSign = '\u00A0*'

export const QuestionText = ({
  answerRequired,
  isFollowup,
  children,
  style,
}: QuestionTextProps): JSX.Element => {
  const styles = useStyleSheet(themedStyles)

  return (
    <Text type="regular" style={[isFollowup ? styles.followupQuestion : styles.question, style]}>
      {isFollowup ? (
        <Text type="regular">{children}</Text>
      ) : (
        <>
          <TextWithBoldHighlight>{children}</TextWithBoldHighlight>
          <Text type="regular" style={styles.requiredSignText}>
            {answerRequired ? requiredSign : ''}
          </Text>
        </>
      )}
    </Text>
  )
}

const themedStyles = StyleService.create({
  question: {
    marginBottom: 16,
  },
  followupQuestion: {
    marginBottom: 8,
  },
  requiredSignText: {
    color: 'theme.error.base',
  },
})
