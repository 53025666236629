import React, { useEffect, useState } from 'react'
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import {
  AccountInterestQuestionnaireScreen,
  EligibilityCheckScreen,
  SelectPlanScreen,
  SelectSubscriptionScreen,
  SubscriptionCheckoutScreen,
} from '@screens'
import { authenticatedUserSelector, skippedSurveysSelector } from '@src/selectors/app'
import { SurveysConfigKind } from '@src/types'
import { Feature, useFeatureFlag } from '@src/components'
import {
  ProductsLoadingScreen,
  StartPurchaseFlowScreen,
  FreeTrialSubscriptionOfferScreen,
  RegionalEligibilityScreen,
  SensorSelection,
  SupportedCGMScreen,
} from '@src/screens/SignUp'
import { useReducedMotion } from '@src/config/accessibility'
import { OnboardingStackParamsList } from '../types'

const OnboardingStack = createStackNavigator<OnboardingStackParamsList>()

export const OnboardingNavigator = () => {
  const reduceMotionEnabled = useReducedMotion()

  const screenOptions = {
    headerShown: false,
    animationEnabled: !reduceMotionEnabled,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  }

  const user = useSelector(authenticatedUserSelector)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [productsLoaded, setProductsLoaded] = useState(false)

  const enableAccountInterestQuestionnaire = useFeatureFlag(Feature.AccountInterestQuestionnaire)

  const surveyLinks = user?.surveyLinks || []

  const skippedSurveys = useSelector(skippedSurveysSelector)

  const shouldShowAccountInterestQuestionnaire =
    (enableAccountInterestQuestionnaire &&
      !skippedSurveys[SurveysConfigKind.AccountInterest] &&
      !surveyLinks.some(
        (surveyLink) =>
          surveyLink.survey.kind === SurveysConfigKind.AccountInterest && surveyLink.finished,
      )) ||
    false

  const getInitialRouteName = () => {
    if (shouldShowAccountInterestQuestionnaire) {
      return 'AccountInterestQuestionnaire' as const
    }

    if (!productsLoaded) {
      return 'ProductsLoading' as const
    }

    return 'StartPurchaseFlow' as const
  }

  const initialRouteName = getInitialRouteName()

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      complete: () => {
        setProductsLoaded(true)
      },
    })
  }, [dispatch, navigation])

  return (
    <OnboardingStack.Navigator initialRouteName={initialRouteName} screenOptions={screenOptions}>
      {shouldShowAccountInterestQuestionnaire && (
        <OnboardingStack.Screen
          name="AccountInterestQuestionnaire"
          component={AccountInterestQuestionnaireScreen}
        />
      )}
      {!productsLoaded && (
        <OnboardingStack.Screen name="ProductsLoading" component={ProductsLoadingScreen} />
      )}
      <OnboardingStack.Screen name="StartPurchaseFlow" component={StartPurchaseFlowScreen} />
      <OnboardingStack.Screen
        name="FreeTrialSubscriptionOffer"
        component={FreeTrialSubscriptionOfferScreen}
      />
      <OnboardingStack.Screen name="RegionalEligibility" component={RegionalEligibilityScreen} />
      <OnboardingStack.Screen name="SelectPlan" component={SelectPlanScreen} />
      <OnboardingStack.Screen name="SelectSubscription" component={SelectSubscriptionScreen} />
      <OnboardingStack.Screen name="SensorSelection" component={SensorSelection} />
      <OnboardingStack.Screen name="EligibilityCheck" component={EligibilityCheckScreen} />
      <OnboardingStack.Screen name="SupportedCGM" component={SupportedCGMScreen} />
      <OnboardingStack.Screen name="SubscriptionCheckout" component={SubscriptionCheckoutScreen} />
    </OnboardingStack.Navigator>
  )
}
