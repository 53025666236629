import { useThemeColors } from '@src/hooks/useThemeColors'
import { commonColors, fontFamily } from '../common-styles'
import { nbInternationalFont } from '../fonts/nbInternational'
import { datesDataToColumnConfigs } from './ColumnConfigsGenerator'
import { aggregateColumns } from './ColumnsAggregator'
import { getChartLabelsConfig } from './LabelsConfigGenerator'
import { getSeparationLineConfigs } from './SeparationLineConfigsGenerator'
import { ByDateColumnChartData } from './types'

interface UsePrimaryColumnChartFunctionType {
  (params: { data: ByDateColumnChartData; yLabel?: string }): string
}

export const usePrimaryColumnChartFunction: UsePrimaryColumnChartFunctionType = ({
  data,
  yLabel,
}) => {
  const colors = useThemeColors({
    ...commonColors,
    ...themedColors,
  })
  const rawColumnConfigs = datesDataToColumnConfigs(data)
  const columnConfigs = aggregateColumns(rawColumnConfigs, data.length)
  const separationLineConfigs = getSeparationLineConfigs(data.length, columnConfigs)
  const chartLabelsConfig = getChartLabelsConfig(data.length, columnConfigs, separationLineConfigs)

  return `
    (() => {
      const columnConfigs = ${JSON.stringify(
        columnConfigs.map((item) => ({ ...item, value: item.value || 0 })),
      )};
      const separationLineConfigs = ${JSON.stringify(separationLineConfigs)};
      const chartLabelsConfig = ${JSON.stringify(chartLabelsConfig)};

      const style = document.createElement('style');
      style.textContent = \`
        ${nbInternationalFont}
        .f2-tooltip {
          padding: 4px 8px;
          background-color: ${colors.tooltipBackground};
        }
        .f2-tooltip:after {
          border-top-color: ${colors.tooltipBackground};
        }
      \`;
      document.body.append(style)

      const CHART_LEFT_PADDING = 50

      const chart = new F2.Chart({
        id: 'chart',
        pixelRatio: window.devicePixelRatio,
        padding: [ 50, 40, 20, CHART_LEFT_PADDING ], // Add left padding to make enough space for y-axis label
      });

      chart.source(columnConfigs);

      // Add invisible columns to just setup coordinate system
      chart.interval()
        .position('index*value')
        .color('rgba(0, 0, 0, 0)')

      chart.scale('index', {
        type: 'timeCat',
        tickCount: columnConfigs.length,
      })

      chart.render();

      const chartGeometries = chart.get('geoms');
      const intervalGeom = chartGeometries[0];

      const columnWidth = intervalGeom.getSize()
      const halfColumnWidth = columnWidth / 2;

      // Render visible columns
      chart.interval()
        .position('index*value')
        .color('${colors.column}')
        .style('index', {
          radius(index) {
            const columnHeight = intervalGeom._attrs.shapes
              .find((shape) => shape._attrs.origin.index === index)
              ._attrs.attrs.height

            return columnHeight < columnWidth ? columnHeight / 2 : halfColumnWidth
          }
        })

      chart.interaction('interval-select', {
        unSelectStyle: { fillOpacity: 0.2 },
        selectAxis: false,
      })

      const AXIS_LABEL_OFFSET = 7.5

      const basicAxisLineStyle = { stroke: '${colors.axisLine}', lineWidth: 1 }

      const basicAxisLabelStyle = {
        fill: '${colors.axisLabel}',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: '${fontFamily}'
      }

      chart.axis('value', {
        grid: null,
        label: { ...basicAxisLabelStyle, textAlign: 'right' },
        line: basicAxisLineStyle,
        labelOffset: AXIS_LABEL_OFFSET,
      })

      chart.axis('index', {
        // Render x axis vertical separation lines
        grid(text, index, total) {
          const displaySeparationLine = separationLineConfigs.find(separationLineConfig => separationLineConfig.columnIndex === index)

          return displaySeparationLine ? {
            stroke: '${colors.axisLine}',
            lineDash: null,
            lineWidth: 1,
          } : null
        },
        label(text, index, total) {
          const labelConfig = chartLabelsConfig.labelConfigs.find(labelConfig => labelConfig.columnIndex === index)
          return labelConfig ? {
            ...basicAxisLabelStyle,
            text: labelConfig.text,
          } : null
        },
        line: basicAxisLineStyle,
      })

      // Tooltip functionality

      const TOOLTIP_BOTTOM_HEIGHT = 5
      const OFFSET_BETWEEN_TOOLTIP_AND_COLUMN_TOP = 4
      let previousSelectedColumnIndex = null

      chart.tooltip({
        custom: true,
        showTooltipMarker: false,
        onShow(selectedColumn) {
          const selectedItem = selectedColumn.items[0]

          const tooltipEl = document.querySelector('.f2-tooltip');

          if (selectedItem.origin.index === previousSelectedColumnIndex) {
            previousSelectedColumnIndex = null
            return
          }

          if (selectedItem.origin.value === 0) return;

          const dateToDisplay = selectedItem.origin.type === 'oneDay'
            ? new Date(selectedItem.origin.date)
              .toLocaleString('default', { day: 'numeric', month: 'short' })
            : new Date(selectedItem.origin.fromDate).toLocaleString('default', { day: 'numeric', month: 'short' }) +
              ' - ' +
              new Date(selectedItem.origin.toDate).toLocaleString('default', { day: 'numeric', month: 'short' })

          const dateToDisplayHtml = dateToDisplay
            ? \`
              <div style="
                font-family: ${fontFamily};
                font-size: 10px;
                font-weight: 400;
                text-align: center;
                color: ${colors.tooltipTextSecondary};
              ">
                \${dateToDisplay}
              </div>
            \` : ''

          const tooltipHtmlContent = \`
            <div style="display: flex; flex-direction: column; align-items: center;">
              <div style="
                font-family: ${fontFamily};
                font-size: 13px;
                font-weight: 600;
                text-align: center;
                color: ${colors.tooltipText};
              ">\${Math.round(selectedItem.origin.value)} ${yLabel}</div>
              \${dateToDisplayHtml}
            </div>
          \`
          tooltipEl.innerHTML = tooltipHtmlContent;

          const tooltipCenterX = selectedItem.x
          const tooltipLeftOffset = tooltipCenterX - tooltipEl.clientWidth / 2

          const tooltipBottomY = selectedItem.y
          const tooltipTopOffset = tooltipBottomY
            - tooltipEl.clientHeight
            - TOOLTIP_BOTTOM_HEIGHT
            - OFFSET_BETWEEN_TOOLTIP_AND_COLUMN_TOP

          tooltipEl.style.display = 'block';
          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = tooltipLeftOffset + 'px';
          tooltipEl.style.top = tooltipTopOffset + 'px';

          previousSelectedColumnIndex = selectedItem.origin.index
        },
        onHide() {
          document.querySelector('.f2-tooltip').style.display = 'none';
        },
      });

      chart.repaint();

      const canvas = chart.get('canvas');

      const xAxisLabelShapes = canvas.get('children')[0]._attrs.children[0]._attrs.children
        .filter(child => child._attrs.className === 'axis-label' && child._id.includes('axis-x'))

      const columnCellWidth = intervalGeom._attrs.shapes[1]._attrs.origin.x - intervalGeom._attrs.shapes[0]._attrs.origin.x
      const halfColumnCellWidth = columnCellWidth / 2

      // Render label under y axis if required
      if (chartLabelsConfig.labelConfigs.some(labelConfig => labelConfig.columnIndex === -1)) {
        const firstXAxisLabelShape = xAxisLabelShapes[0]

        const xAxisLabelY = firstXAxisLabelShape._attrs.attrs.y

        canvas.addShape('text', {
          attrs: {
            x: chart.getPosition({ index: 0 }).x - halfColumnCellWidth,
            y: xAxisLabelY,
            text: chartLabelsConfig.labelConfigs.find(labelConfig => labelConfig.columnIndex === -1).text,
            fill: '${colors.axisLabel}',
            textAlign: 'center',
            fontSize: 10,
            fontFamily: '${fontFamily}',
            textBaseline: 'top',
          },
        })
      }

      // Move all x-axis labels to right to place them under vertical separation lines if required
      if (chartLabelsConfig.labelsPosition === 'under-separation-lines') {
        xAxisLabelShapes.forEach(shape => shape.translate(halfColumnCellWidth, 0))
      }

      // Move grid lines right
      const xAxisGridShapes = canvas.get('children')[0]._attrs.children[0]._attrs.children
          .filter((child) => child._id?.includes('axis-x-grid'))

      xAxisGridShapes.forEach((shape) => shape.translate(halfColumnCellWidth, 0))

      // Add y axis measurement system label
      const yLabelGroup = canvas.addGroup();
      const yLabel = '${yLabel}';
      if (yLabel) {
        yLabelGroup.addShape('text', {
          attrs: {
            ...basicAxisLabelStyle,
            textAlign: 'right',
            x: CHART_LEFT_PADDING - AXIS_LABEL_OFFSET,
            y: 40,
            text: yLabel,
          }
        });
      }
    })()
  `
}

const themedColors = {
  column: 'theme.barChart.primary',
}
