import { gql } from '@apollo/client'

export const CREATE_REMINDER = gql`
  mutation createReminder(
    $kind: NotificationEngineReminderKind
    $frequency: NotificationEngineReminderFrequency
    $timeOfDay: String
    $dayOfWeek: NotificationEngineReminderDayOfWeek
    $hoursInterval: Int
  ) {
    createReminder(
      kind: $kind
      frequency: $frequency
      timeOfDay: $timeOfDay
      dayOfWeek: $dayOfWeek
      hoursInterval: $hoursInterval
    ) {
      id
      kind
      frequency
      dayOfWeek
      timeOfDay
      hoursInterval
    }
  }
`
