import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { MessageSystemProps, useChannelContext } from 'stream-chat-react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { useImpersonationContext } from '@src/config/impersonation'

const defaultCannedResponses = {
  title: 'Try Asking Me',
  questions: [
    'How does the CGM work?',
    'How to log a Meal?',
    'How does hot shower affect glucose levels?',
  ],
}

const DelimiterComponent = () => {
  const styles = useStyleSheet(themedStyleDelimiter)

  return (
    <View style={styles.container} testID="message-system">
      <View style={styles.line} />
      <Text type="small" style={styles.text}>
        End
      </Text>
      <View style={styles.line} />
    </View>
  )
}

const CannedQuestionsComponent = ({
  id,
  title,
  questions,
}: {
  id: string
  title: string
  questions: string[]
}) => {
  const styles = useStyleSheet(themedStyleQuestions)
  const { channel } = useChannelContext()
  const isImpersonating = useImpersonationContext()

  if (channel.lastMessage().id !== id) {
    return null
  }

  const onQuestionPress = (question: string) => {
    channel.sendMessage({
      text: question,
    })
  }

  return (
    <View style={styles.container} testID="message-system">
      <Text type="regular" bold style={styles.text}>
        {title}
      </Text>
      {questions.map((item: string, index: number) => (
        <TouchableOpacity
          key={index}
          accessibilityLabel={item}
          onPress={() => onQuestionPress(item)}
          style={styles.questionContainer}
          disabled={isImpersonating}
        >
          <Text type="regular" style={styles.question}>
            {item}
          </Text>
          <Icon name="paper-plane-tilt" style={styles.sendIcon} />
        </TouchableOpacity>
      ))}
    </View>
  )
}

export const CustomMessageSystem = ({
  message: { nutrisense_type: nutrisenseType, text, id },
}: MessageSystemProps) => {
  if (nutrisenseType === 'delimiter') {
    return <DelimiterComponent />
  }

  if (nutrisenseType === 'cannedQuestions') {
    let cannedQuestions = defaultCannedResponses
    try {
      cannedQuestions = JSON.parse(text || '{}')
    } catch {
      console.warn('Error parsing canned questions, text:', text)
    }
    return (
      <CannedQuestionsComponent
        title={cannedQuestions.title}
        questions={cannedQuestions.questions}
        id={id}
      />
    )
  }

  return null
}

const themedStyleQuestions = StyleService.create({
  container: {
    marginHorizontal: 8,
  },
  text: {
    marginBottom: 8,
  },
  questionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 40,
    paddingHorizontal: 20,
    paddingVertical: 8,
    marginBottom: 8,
    borderRadius: 40,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
  },
  question: {
    flex: 1,
    flexWrap: 'wrap',
    flexShrink: 1,
  },
  sendIcon: {
    width: 20,
    height: 20,
    marginLeft: 8,
  },
})

const themedStyleDelimiter = StyleService.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    marginVertical: 16,
    marginHorizontal: 24,
  },
  line: {
    flex: 1,
    height: 1,
    backgroundColor: 'theme.surface.base2',
  },
  text: {
    marginHorizontal: 8,
    color: 'theme.text.secondary',
  },
})
