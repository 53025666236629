import React from 'react'
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
import { SelectPlan } from '@src/screens/SignUp/components/SelectPlan'
import { MarketplaceScreen, SelectSubscriptionScreen, EligibilityCheckScreen } from '@screens'
import { productsSelector } from '@src/selectors/app'
import { useActiveSubscriptions } from '@src/hooks/useActiveSubscriptions'
import { useReducedMotion } from '@src/config/accessibility'
import { SubscriptionsStackParamsList } from '../types'

const SubscriptionsStack = createStackNavigator<SubscriptionsStackParamsList>()

export const SubscriptionsNavigator = () => {
  const reduceMotionEnabled = useReducedMotion()

  const screenOptions = {
    headerShown: false,
    animationEnabled: !reduceMotionEnabled,
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  }

  const activeSubscriptions = useActiveSubscriptions()

  const products = useSelector(productsSelector)

  const showPlanPicker = activeSubscriptions.length === 0 && products.length > 1

  return (
    <SubscriptionsStack.Navigator
      initialRouteName={showPlanPicker ? 'SelectPlan' : 'Marketplace'}
      screenOptions={screenOptions}
    >
      {showPlanPicker ? (
        <>
          <SubscriptionsStack.Screen name="SelectPlan" component={SelectPlan} />
          <SubscriptionsStack.Screen
            name="SelectSubscription"
            component={SelectSubscriptionScreen}
          />
          <SubscriptionsStack.Screen name="EligibilityCheck" component={EligibilityCheckScreen} />
        </>
      ) : (
        <SubscriptionsStack.Screen name="Marketplace" component={MarketplaceScreen} />
      )}
    </SubscriptionsStack.Navigator>
  )
}
