import { Model } from '@models'
import { unlessEventHasSelectedDate } from '@src/models/utils'

import CREATE_MEASUREMENT from '../graphql/createMeasurement'
import DELETE_MEASUREMENT from '../graphql/deleteMeasurement'
import { transformHistoryOrEventItem } from '../../../transforms/eventAndHistoryItemTransform'

const measurementsCacheClearPayload = (response) => ({ includeDates: [response.occurredAt] })

export default class Measurements {
  namespace = 'measurements'

  state = {
    ...Model.defaultState,
    lastMeasurementType: null,
  }

  effects = {
    submit: Model.buildEffect({
      name: `${this.namespace}/submit`,
      optimistic: true,
      query: CREATE_MEASUREMENT,
      dataPath: 'createMeasurement',
      optimisticReducers: [
        { name: 'events/backup' },
        { name: 'history/backup', storePath: 'history' },
        {
          name: 'events/appendOrReplaceList',
          unless: unlessEventHasSelectedDate,
          transform: transformHistoryOrEventItem,
        },
        {
          name: 'history/appendOrReplaceList',
          storePath: 'history',
          transform: transformHistoryOrEventItem,
        },
        {
          name: 'dailyMeasurements/saveAvailableMeasurement',
          unless: (event) => !event.isDailyMeasurement,
          transform: transformHistoryOrEventItem,
        },
        {
          name: 'updateLastMeasurementType',
          payload: (_, { effectPayload }) => effectPayload.type,
        },
      ],
      reducers: [
        {
          name: 'events/appendOrReplaceList',
          replaceFake: true,
          unless: unlessEventHasSelectedDate,
          transform: transformHistoryOrEventItem,
        },
        {
          name: 'history/appendOrReplaceList',
          replaceFake: true,
          storePath: 'history',
          transform: transformHistoryOrEventItem,
        },
        {
          name: 'dailyMeasurements/saveAvailableMeasurement',
          unless: (event) => !event.isDailyMeasurement,
          transform: transformHistoryOrEventItem,
        },
        { name: 'events/fetchCharts' },
      ],
      errorReducers: [
        { name: 'events/restore' },
        { name: 'history/restore', storePath: 'history' },
      ],
      cacheReducers: [
        { name: 'history/cacheClear', payload: { matchName: 'history/fetch' } },
        {
          name: 'events/cacheClear',
          payload: measurementsCacheClearPayload,
        },
        {
          name: 'insights/cacheClear',
          payload: measurementsCacheClearPayload,
        },
      ],
    }),

    delete: Model.buildEffect({
      name: `${this.namespace}/deleteMeasurement`,
      optimistic: true,
      query: DELETE_MEASUREMENT,
      dataPath: 'deleteMeasurement',
      variables: (payload) => ({ id: payload.id }),
      optimisticReducers: [
        { name: 'events/backup' },
        { name: 'history/backup', storePath: 'history' },
        { name: 'events/deleteList' },
        { name: 'history/deleteList', storePath: 'history' },
      ],
      reducers: [{ name: 'events/fetchCharts' }],
      errorReducers: [
        { name: 'history/restore', storePath: 'history' },
        { name: 'events/restore' },
      ],
      cacheReducers: [
        { name: 'history/cacheClear', payload: { matchName: 'history/fetch' } },
        {
          name: 'events/cacheClear',
          payload: measurementsCacheClearPayload,
        },
        {
          name: 'insights/cacheClear',
          payload: measurementsCacheClearPayload,
        },
      ],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    updateLastMeasurementType: this.updateLastMeasurementType,
  }

  updateLastMeasurementType(state, action) {
    return { ...state, lastMeasurementType: action.payload }
  }
}
