import React, { useState } from 'react'
import { Rect } from 'react-content-loader/native'
import FastImage, { FastImageProps } from 'react-native-fast-image'
import { ContentLoader } from './ContentLoader'
export { ImageStyle } from 'react-native-fast-image'

type LoadingFastImageProps = FastImageProps

export const LoadingFastImage = (props: LoadingFastImageProps) => {
  const { children, onLoad = () => 0, ...restProps } = props
  const [isLoading, setIsLoading] = useState(true)

  return (
    <FastImage
      {...restProps}
      onLoad={(e) => {
        setIsLoading(false)
        onLoad(e)
      }}
    >
      {!isLoading ? (
        children
      ) : (
        <ContentLoader>
          <Rect x="0" y="0" width="100%" height="100%" />
        </ContentLoader>
      )}
    </FastImage>
  )
}
