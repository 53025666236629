import React from 'react'
import {
  SelectGroup as KittenSelectGroup,
  Select,
  SelectItem,
  IndexPath,
} from '@ui-kitten/components'
import { compact, Dictionary, isUndefined } from 'lodash'
import { capitalCase } from 'change-case'
import { StyleService, useStyleSheet } from '@src/style/service'
import { SelectGroupInnerProps } from './SelectGroup.types'

export const SelectGroup = ({
  groupedOptions,
  selectedOptions,
  onSelect,
  style,
  ...restProps
}: SelectGroupInnerProps) => {
  const styles = useStyleSheet(themedStyle)

  const renderGroup = (title: string, i: number) => {
    const capitalizedTitle = capitalCase(title)

    return (
      <KittenSelectGroup
        title={capitalizedTitle}
        accessibilityLabel={capitalizedTitle}
        key={`group-select-${title}-${i}`}
      >
        {groupedOptions[title].map((title: string, i: number) => {
          return (
            <SelectItem
              key={`select-item-${title}-${i}`}
              testID={`Group${capitalizedTitle}/SelectItem/${capitalCase(title)}`}
              style={styles.nestedItem}
              title={capitalCase(title)}
            />
          )
        })}
      </KittenSelectGroup>
    )
  }

  const getInputValue = (options: Dictionary<string[]>) =>
    Object.keys(options).reduce((acc: string[], type: string) => {
      return [...acc, ...options[type]]
    }, [])

  const getValue = () =>
    getInputValue(selectedOptions)
      .map((t) => capitalCase(t))
      .join(', ')

  const getIndexPathFromValues = () => {
    const filters: IndexPath[] = []

    Object.keys(selectedOptions).forEach((sectionKey) => {
      const section = Object.keys(groupedOptions).indexOf(sectionKey)
      selectedOptions[sectionKey].map((val) => {
        const row = groupedOptions[sectionKey].findIndex((value) => value === val)
        if (row > -1) {
          filters.push(new IndexPath(row, section))
        }
      })
    })

    return filters
  }

  const getValuesFromIndexPath = (indexes: IndexPath[]) => {
    const filtersArray = compact(
      indexes.map((index) => {
        if (!isUndefined(index.section)) {
          const sectionKey = Object.keys(groupedOptions)[index.section]
          return {
            [sectionKey]: groupedOptions[sectionKey][index.row],
          }
        }
        return
      }),
    )

    return filtersArray.reduce((acc: Dictionary<string[]>, filter) => {
      const key = Object.keys(filter)[0]

      if (acc[key]) {
        acc[key].push(filter[key])
      } else {
        acc[key] = [filter[key]]
      }
      return acc
    }, {})
  }

  const handleSelect = (index: IndexPath | IndexPath[]) => {
    const currentIndex = Array.isArray(index) ? index : [index]
    const selectedValues = getValuesFromIndexPath(currentIndex)
    onSelect(selectedValues)
  }

  return (
    <Select
      {...restProps}
      style={style}
      multiSelect
      value={getValue()}
      selectedIndex={getIndexPathFromValues()}
      onSelect={handleSelect}
    >
      {Object.keys(groupedOptions).map(renderGroup)}
    </Select>
  )
}

const themedStyle = StyleService.create({
  nestedItem: {
    paddingLeft: 40,
  },
})
