import { gql } from '@apollo/client'
import { SURVEY_FIELDS } from './survey'
import { SURVEY_OUTCOME } from './surveyOutcome'

export const SURVEY_LINK = gql`
  ${SURVEY_FIELDS}
  ${SURVEY_OUTCOME}
  fragment SurveyLink on SurveyLink {
    id
    state
    inProgress
    startedAt
    finished
    finishedAt
    outcome {
      ...SurveyOutcome
    }
    survey {
      ...SurveyFields
    }
    responses {
      id
      questionKey
      answer
    }
    earlyExitDeepLink
  }
`
