export type ChartQueryType =
  | 'DAILY_SCORE'
  | 'MEALS_AVERAGE'
  | 'MEALS_BY_TYPE'
  | 'GLUCOSE_MIN'
  | 'GLUCOSE_MAX'
  | 'GLUCOSE_STD_DEV'
  | 'GLUCOSE_TIME_IN_RANGE'
  | 'GLUCOSE_AVERAGE'
  | 'GLUCOSE_MORNING_AVERAGE'
  | 'GLUCOSE_SLEEP_AVERAGE'
  | 'KETONES_MIN'
  | 'KETONES_MAX'
  | 'KETONES_TIME_IN_RANGE'
  | 'KETONES_AVERAGE'
  | 'FAT'
  | 'CARBS'
  | 'PROTEIN'
  | 'CALORIES'
  | 'BODY_FAT'
  | 'BODY_TEMPERATURE'
  | 'WEIGHT'
  | 'BLOOD_GLUCOSE'
  | 'BLOOD_KETONES'
  | 'BREATH_KETONES'
  | 'BLOOD_PRESSURE'
  | 'STEP_COUNT'
  | 'FLIGHT_CLIMBED'
  | 'EXERCISE_TIME'
  | 'RUNNING_DISTANCE'
  | 'CYCLING_DISTANCE'
  | 'SWIMMING_DISTANCE'
  | 'MOOD'
  | 'ENERGY'
  | 'FOCUS'
  | 'STRESS'
  | 'APPETITE'

export const DetailedFetchKeyMap: Record<ChartQueryType, string> = {
  DAILY_SCORE: 'daily',
  MEALS_AVERAGE: 'meals_average',
  MEALS_BY_TYPE: 'meals_average_by_type',
  GLUCOSE_MIN: 'min',
  GLUCOSE_MAX: 'max',
  GLUCOSE_STD_DEV: 'standard_deviation',
  GLUCOSE_TIME_IN_RANGE: 'time_within_range',
  GLUCOSE_AVERAGE: 'daily_average',
  GLUCOSE_MORNING_AVERAGE: 'morning_average',
  GLUCOSE_SLEEP_AVERAGE: 'sleep_average',
  KETONES_MIN: 'min',
  KETONES_MAX: 'max',
  KETONES_TIME_IN_RANGE: 'time_within_range',
  KETONES_AVERAGE: 'daily_average',
  FAT: 'fat',
  CARBS: 'carbs',
  PROTEIN: 'protein',
  CALORIES: 'calories',
  BODY_FAT: 'body_fat_percentage',
  BODY_TEMPERATURE: 'body_temperature',
  WEIGHT: 'weight',
  BLOOD_GLUCOSE: 'blood_glucose',
  BLOOD_KETONES: 'blood_ketones',
  BREATH_KETONES: 'breath_ketones',
  BLOOD_PRESSURE: 'blood_pressure',
  STEP_COUNT: 'step_count',
  FLIGHT_CLIMBED: 'flights_climbed',
  EXERCISE_TIME: 'exercise_time',
  RUNNING_DISTANCE: 'running_distance',
  CYCLING_DISTANCE: 'cycling_distance',
  SWIMMING_DISTANCE: 'swimming_distance',
  MOOD: 'mood',
  ENERGY: 'energy',
  FOCUS: 'focus',
  STRESS: 'stress',
  APPETITE: 'appetite',
}

export const allTypes = [
  {
    key: 'score',
    value: [
      'daily_average',
      'meals_average',
      'peak_average',
      'mean_average',
      'std_dev_average',
      'time_outside_range_average',
      'meals_peak_average',
      'meals_exposure_average',
      'meals_stability_average',
      'meals_recovery_average',
    ],
  },
  {
    key: 'glucose',
    value: [
      'time_within_range',
      'standard_deviation',
      'mean',
      'min',
      'max',
      'sleep_average',
      'morning_average',
    ],
  },
  { key: 'ketones', value: ['time_within_range', 'mean', 'min', 'max'] },
  { key: 'macros', value: ['calories', 'protein', 'carbs', 'fat'] },
  {
    key: 'measurements',
    value: [
      'body_fat_percentage',
      'body_temperature',
      'weight',
      'blood_glucose',
      'blood_ketones',
      'breath_ketones',
      'systolic_blood_pressure',
      'diastolic_blood_pressure',
      'step_count',
      'flights_climbed',
      'exercise_time',
      'running_distance',
      'cycling_distance',
      'swimming_distance',
    ],
  },
  {
    key: 'journal',
    value: ['mood', 'energy', 'focus', 'stress', 'appetite'],
  },
]
