import React from 'react'
import { BaseSuccessContainer } from '@src/screens/Common/containers/BaseSuccessContainer'

export const CGMActivated = () => {
  return (
    <BaseSuccessContainer
      navigationTitle="CGM Activated"
      title="Great Job!"
      description="Your CGM is successfully activated"
      timeSectionDescription="You can begin scanning your sensor in 1 hour"
      dangerSectionDescription="Make sure to scan your sensor at least once every 8 hours"
    />
  )
}
