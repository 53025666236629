import { gql } from '@apollo/client/core'
import { CORE_SUBSCRIPTION_FIELDS } from '@src/graphql/fragments/subscription'

const UPGRADE_SUBSCRIPTION_PRODUCT = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  mutation upgradeSubscriptionProduct(
    $id: ID!
    $providerPaymentMethodId: String!
    $nextProduct: BillingProduct!
  ) {
    upgradeSubscriptionProduct(
      id: $id
      providerPaymentMethodId: $providerPaymentMethodId
      nextProduct: $nextProduct
    ) {
      subscription {
        ...CoreSubscriptionFields
      }
    }
  }
`

export default UPGRADE_SUBSCRIPTION_PRODUCT
