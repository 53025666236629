import { ReactNode } from 'react'

export enum ChoiceAccessory {
  Default,
  Selected,
  Correct,
  Incorrect,
}

export interface ChoiceItem {
  id: string
  body?: string | ReactNode
  tag?: string
}
