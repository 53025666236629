import React, { ReactNode } from 'react'
import { useNavigation } from '@react-navigation/native'
import { ViewStyle } from 'react-native'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AccountAvatar } from '@src/components/AccountAvatar'
import { Storage } from '@src/utils'
import { NavigationBarProps } from '@src/components/navigationBar/NavigationBar'
import { useFeatureFlag, Feature, IconDescriptor } from '@src/components'
import { Debug } from '@src/config'
import { WithWalkthrough } from '@src/components/WithWalkthrough'
import { useOneTimeVisitedState } from '@src/hooks/useOneTimeVisitedState'
import { WalkthroughTooltip } from '@src/components/WalkthroughTooltip'
import { useScreenStay } from '@src/hooks/useScreenDelayedFocusTracking'
import { User } from '@src/utils'
import { MobileAppFeature } from '@src/types'
import { useWebPresentationModeLogoutIcon } from '@src/hooks/useWebPresentationModeLogoutIcon'

interface AccountAvatarNavigationBarProps {
  children: ReactNode | ReactNode[]
  title?: string | ReactNode
  hideLeftIcon?: boolean
  rightAccessories?: NavigationBarProps['rightAccessories']
  style?: ViewStyle
  navigationBarProps?: Omit<NavigationBarProps, 'setHeaderHeight'>
}

export const AccountAvatarNavigationBar = ({
  children,
  title,
  hideLeftIcon,
  rightAccessories,
  style,
  navigationBarProps = {},
}: AccountAvatarNavigationBarProps) => {
  const navigation = useNavigation()
  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)
  const screenIsFocused = useScreenStay()
  const webPresentationModeLogoutIcon = useWebPresentationModeLogoutIcon()

  const { shouldShow, markAsShown } = useOneTimeVisitedState(
    Storage.ACCOUNT_DASHBOARD_WALKTHROUGH_VISITED_KEY,
  )

  const handleAccountIconPress = () => {
    navigation.navigate('Account')
  }

  const handleAccountIconLongPress = () => {
    if (Debug.useAdminMenu && User.hasFeature(MobileAppFeature.AdminAccess)) {
      navigation.navigate('AdminSettings')
    }
  }

  const legacyProps = hideLeftIcon
    ? null
    : {
        isDrawerScreen: true,
        goBack: (navigation as any).openDrawer,
        leftIcon: { pack: 'eva', name: 'menu' } as IconDescriptor,
      }

  const newProps = hideLeftIcon
    ? null
    : {
        leftIcon: webPresentationModeLogoutIcon || (
          <WithWalkthrough
            isVisible={screenIsFocused && shouldShow}
            onPress={handleAccountIconPress}
            onLongPress={handleAccountIconLongPress}
            accessibilityLabel="View account"
            tooltipStyle={{ marginLeft: -12 }}
            content={<WalkthroughTooltip title="Check out your new Account section!" />}
            onClose={markAsShown}
          >
            <AccountAvatar />
          </WithWalkthrough>
        ),
        goBack: handleAccountIconPress,
      }

  const props = appNavigationUpdatesFeatureEnabled ? newProps : legacyProps

  return (
    <NavigationContainer
      showLoadingIndicator
      title={title}
      rightAccessories={rightAccessories}
      style={style}
      navigationBarProps={navigationBarProps}
      allowBackNavigation={!hideLeftIcon}
      {...props}
    >
      {children}
    </NavigationContainer>
  )
}
