import { Action, Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { useMemo } from 'react'

export const useDispatchAsync = () => {
  const dispatch = useDispatch()
  return useMemo(() => dispatchAsync(dispatch), [dispatch])
}

export const injectDispatchAsync = (dispatch: Dispatch<Action>) => ({
  dispatchAsync: dispatchAsync(dispatch),
  dispatch,
})

export const dispatchAsync = <A extends Action>(dispatch: Dispatch<A>) => {
  return <R>(action: A) => {
    return new Promise<R>((resolve, reject) => {
      dispatch({
        ...action,
        success: (data: R) => {
          resolve(data)
        },
        failure: (error: any) => {
          reject(error)
        },
      })
    })
  }
}
