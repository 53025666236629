import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { PaymentMethod } from '@src/models/user.types'
import { PaymentMethodInfo } from './PaymentMethodInfo'

export interface PaymentMethodCardProps {
  paymentMethod?: PaymentMethod | null
  onEditPaymentMethodPress: () => void
}

export const PaymentMethodCard = (props: PaymentMethodCardProps) => {
  const styles = useStyleSheet(themedStyle)
  const { paymentMethod, onEditPaymentMethodPress } = props

  const paymentMethodText = paymentMethod ? 'Payment Method' : 'Add payment method'

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text type="small" bold lineSpacing="none" style={styles.title}>
          {paymentMethodText}
        </Text>
        {!paymentMethod && (
          <Button
            accessibilityLabel="Add Address"
            accessoryRight={<Icon name="arrow-right" />}
            style={styles.addButton}
            size="small"
            type="primary"
            onPress={onEditPaymentMethodPress}
          >
            Add
          </Button>
        )}
      </View>
      {!!paymentMethod && (
        <TouchableOpacity
          activeOpacity={0.7}
          accessibilityLabel={paymentMethodText}
          style={styles.cardInfoRow}
          onPress={onEditPaymentMethodPress}
        >
          <PaymentMethodInfo paymentMethod={paymentMethod} />
          <Text type="regular" bold style={styles.actionText}>
            Change
          </Text>
          <Icon name="caret-right" style={styles.icon} />
        </TouchableOpacity>
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    paddingHorizontal: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: 24,
    marginBottom: 16,
    textTransform: 'uppercase',
    letterSpacing: 0.3,
  },
  addButton: {
    marginVertical: 16,
  },
  icon: {
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  cardInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  },
  actionText: {
    marginHorizontal: 16,
    color: 'theme.text.link',
  },
})
