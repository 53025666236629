import React, { ReactNode } from 'react'
import { View, TouchableWithoutFeedback, ViewStyle, KeyboardAvoidingView } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useGoBack } from '@src/utils/navigation'
import { Device } from '@config'

export interface DismissibleModalProps {
  children: ReactNode
  containerStyle?: ViewStyle
  modalStyle?: ViewStyle
  onDismiss?: () => void
}

export const DismissibleModal = ({
  children,
  containerStyle = {},
  modalStyle = {},
  onDismiss,
}: DismissibleModalProps) => {
  const styles = useStyleSheet(themedStyles)

  const goBack = useGoBack()

  const onPress = () => {
    goBack()
    if (onDismiss) {
      onDismiss()
    }
  }

  return (
    <TouchableWithoutFeedback
      accessibilityLabel="dismissibleModal"
      accessible={false}
      onPress={onPress}
    >
      <KeyboardAvoidingView
        behavior={Device.ios ? 'padding' : undefined}
        style={[styles.container, containerStyle]}
      >
        <TouchableWithoutFeedback accessible={false}>
          <View style={[styles.modal, modalStyle]}>{children}</View>
        </TouchableWithoutFeedback>
      </KeyboardAvoidingView>
    </TouchableWithoutFeedback>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    width: '100%',
    borderRadius: 12,
    backgroundColor: 'theme.background',
  },
})
