import React from 'react'
import { Image, ImageStyle, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated'
import { StyleService, useStyleSheet } from '@src/style/service'
import {
  ImageRequestActionSheetOption,
  useImageRequestActionSheet,
} from '@src/hooks/useImageRequestActionSheet'
import useImageDownloadState from '@src/hooks/useImageDownloadState'
import { Icon, Text } from '@components/base'
import { ReactNativeFile } from '@src/utils/image'
import { LoadingIndicator } from './LoadingIndicator'

export type QuestionnairePhoto = { name: string; uri: string; type: 'file_upload'; id: string }
export interface PhotoUploadingProps {
  label?: string
  photo: ReactNativeFile | QuestionnairePhoto | null | undefined
  onPhotoChange: (photo: ReactNativeFile | undefined) => void
  style?: ViewStyle
}

export const PhotoUploading = ({
  onPhotoChange,
  photo,
  label,
  style,
}: PhotoUploadingProps): JSX.Element => {
  const styles = useStyleSheet(themedStyles)

  const { loading } = useImageDownloadState(photo?.uri)

  const { showImageRequestActionSheet } = useImageRequestActionSheet(onPhotoChange)

  const onRemovePhotoButtonPressed = () => {
    onPhotoChange(undefined)
  }

  const onPhotoUploadingSuggestionPress = () => {
    showImageRequestActionSheet({
      options: [
        ImageRequestActionSheetOption.ChoseFromLibrary,
        ImageRequestActionSheetOption.TakePhoto,
      ],
    })
  }

  const renderUploadSuggestion = () => (
    <TouchableOpacity
      style={styles.uploadSuggestionContainer}
      onPress={onPhotoUploadingSuggestionPress}
      accessibilityLabel="Upload Photo"
    >
      <Icon name="cloud-arrow-up" style={styles.uploadIcon} />
      <Text type="large">Choose file</Text>
    </TouchableOpacity>
  )

  const renderPhoto = () => (
    <>
      <View style={styles.photoContainer}>
        {loading && (
          <Animated.View style={styles.loadingContainer} entering={FadeIn} exiting={FadeOut}>
            <LoadingIndicator size="large" />
          </Animated.View>
        )}
        <Image
          source={{ uri: photo?.uri }}
          style={styles.photo as StyleProp<ImageStyle>}
          resizeMode="cover"
        />
      </View>
      <View style={styles.photoFooter}>
        <Text type="small">{photo?.name}</Text>
        <TouchableOpacity
          accessibilityLabel="Remove selected image"
          onPress={onRemovePhotoButtonPressed}
          hitSlop={{ bottom: 10, top: 10, left: 10, right: 10 }}
        >
          <Icon name="x" style={styles.removePhotoIcon} />
        </TouchableOpacity>
      </View>
    </>
  )

  return (
    <View>
      {label && (
        <Text type="large" bold style={styles.label}>
          {label}
        </Text>
      )}
      <View style={[styles.container, style]}>
        {photo ? renderPhoto() : renderUploadSuggestion()}
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  label: {
    marginBottom: 8,
  },
  container: {
    borderWidth: 2,
    borderColor: 'theme.primary.base',
    borderRadius: 16,
    height: 280,
  },
  uploadIcon: {
    height: 60,
    marginBottom: 16,
    color: 'theme.primary.base',
  },
  uploadSuggestionContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    position: 'absolute',
    zIndex: 99,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  photo: {
    flex: 1,
  },
  photoContainer: {
    flex: 1,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    overflow: 'hidden',
  },
  photoFooter: {
    borderTopWidth: 1,
    borderColor: 'theme.primary.base',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
  },
  removePhotoIcon: {
    height: 16,
    width: 16,
    color: 'theme.text.primary',
  },
})
