import { gql } from '@apollo/client/core'

const ESTIMATE_TAX = gql`
  query estimateTax($product: BillingProduct!, $providerPromotionCodeId: String) {
    estimateTax(product: $product, providerPromotionCodeId: $providerPromotionCodeId) {
      estimatedTax
    }
  }
`

export default ESTIMATE_TAX
