import { useSelector } from 'react-redux'
import { Subscription, SubscriptionStatus } from '@src/types'
import { subscriptionsSelector } from '@src/selectors/app'

export const isSubscriptionActive = (subscription: Pick<Subscription, 'status'>) => {
  return (
    subscription.status === SubscriptionStatus.Active ||
    subscription.status === SubscriptionStatus.PastDue ||
    subscription.status === SubscriptionStatus.Trialing
  )
}

export const useActiveSubscriptions = () => {
  const subscriptions = useSelector(subscriptionsSelector)

  return subscriptions.filter((subscription) => isSubscriptionActive(subscription))
}
