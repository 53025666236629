import React, { forwardRef, useRef } from 'react'
import { AsYouType, getCountryCallingCode, isValidPhoneNumber } from 'libphonenumber-js'
import { Input, InputProps, InputRef } from '@components/base'
import { AddressCountries } from '@src/types'
import { getCountryCodeByCountry } from '@src/utils/phoneInput'

interface PhoneInputProps extends InputProps {
  country: AddressCountries
}

export const PhoneInput = forwardRef<InputRef, PhoneInputProps>((props, ref) => {
  const { placeholder, value = '', onChangeText, onChange, country } = props

  const latestValue = useRef(value)

  const countryCode = getCountryCodeByCountry(country)

  const countryCallingCode = '+' + getCountryCallingCode(countryCode)

  const formatPhoneNumber = (rawPhoneString: string) => {
    // Remove the country code from the input
    let nextValue = rawPhoneString.slice(countryCallingCode.length + 1)

    // Remove non-numeric characters from the end of the string, like spaces, dashes and parentheses
    if (nextValue.match(/\D$/g)) {
      nextValue = nextValue.replace(/\D+$/g, '')
    }

    // Do not format the input on character removal, unless the number is valid
    const shouldFormatInput =
      nextValue.length > latestValue.current.length || isValidPhoneNumber(nextValue, countryCode)

    const nextPhoneNumber = shouldFormatInput
      ? new AsYouType(countryCode).input(nextValue)
      : nextValue

    latestValue.current = nextPhoneNumber

    return nextPhoneNumber
  }

  return (
    <Input
      {...props}
      ref={ref}
      keyboardType="phone-pad"
      placeholder={placeholder ?? 'Phone Number'}
      value={`${countryCallingCode} ${value}`}
      onChangeText={(text) => {
        const newPhoneNumber = formatPhoneNumber(text)
        onChangeText?.(newPhoneNumber)
        onChange?.(newPhoneNumber)
      }}
    />
  )
})
