import React, { useContext } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { InlineAlert } from '@components'
import {
  AppStackParamList,
  QuestionnaireScreenName,
  QuestionnaireStackParamList,
} from '@navigation/types'
import { QuestionnaireContext } from '@src/screens/Questionnaire/questionnaire.navigator'

type QuestionnaireRejectionScreenRoute = RouteProp<
  QuestionnaireStackParamList,
  QuestionnaireScreenName.QuestionnaireRejection
>

export const QuestionnaireRejectionScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const {
    params: { outcome, nextScreen, nextScreenParams },
  } = useRoute<QuestionnaireRejectionScreenRoute>()
  const { headline, message, notes, inlineAlert } = outcome.reason

  const insets = useSafeAreaInsets()

  const { rejectedScreenConfig } = useContext(QuestionnaireContext)
  const { onCloseButtonPress } = rejectedScreenConfig

  const onButtonPress = () => {
    if (nextScreen) {
      navigation.replace(nextScreen as any, nextScreenParams)
      return
    }

    onCloseButtonPress()
  }

  return (
    <ScrollView
      bounces={false}
      style={styles.container}
      contentContainerStyle={[
        styles.contentContainer,
        { paddingTop: insets.top + 40, paddingBottom: insets.bottom + 16 },
      ]}
    >
      <View style={styles.content}>
        <Text type="title-3" style={styles.title}>
          {headline}
        </Text>
        {message.content.split('\n').map((paragraph) => (
          <Text key={paragraph} type="regular" style={styles.paragraph}>
            {paragraph}
          </Text>
        ))}
        {notes.length > 0 && (
          <View style={styles.notes}>
            {notes.map((note) => (
              <View key={note} style={styles.noteContainer}>
                <Text type="regular" style={styles.listItem}>
                  •
                </Text>
                <Text key={note} type="regular">
                  {note}
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      <View style={styles.actions}>
        {!!inlineAlert && <InlineAlert category={inlineAlert.type} message={inlineAlert.message} />}
        <Button
          accessibilityLabel="Next"
          type="primary"
          size="block"
          style={styles.continueButton}
          onPress={onButtonPress}
        >
          {nextScreen ? 'Next' : 'Finish'}
        </Button>
      </View>
    </ScrollView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    paddingHorizontal: 16,
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  content: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textAlign: 'center',
  },
  icon: {
    color: 'theme.info.base',
  },
  paragraph: {
    marginTop: 24,
    textAlign: 'center',
  },
  notes: {
    marginTop: 24,
    marginHorizontal: 30,
    alignItems: 'flex-start',
  },
  listItem: {
    marginRight: 8,
  },
  noteContainer: {
    flexDirection: 'row',
    marginBottom: 16,
  },
  actions: {
    width: '100%',
  },
  continueButton: {
    marginTop: 24,
  },
})
