import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { useWindowDimensions, View } from 'react-native'
import { StyleService } from '@src/style/service'
import { useResponsiveStyleSheet } from '@src/hooks/useResponsiveStyleSheet'
import { useDefaultRangeConfig } from '@src/hooks/useDefaultRangeConfig'
import { ChartRangeName } from '@src/screens/Insights/types'
import { Device } from '@src/config'
import { Text } from '@components/base'

const ignoredUnits = ['decimal', 'percentage']

const DEFAULT_RANGE_LABELS: Record<ChartRangeName, string> = {
  good: 'Good',
  ok: 'Ok',
  bad: 'Needs improvement',
  critical: 'Critical',
}

interface RangesLegendProps {
  metric: string
  labels?: Record<ChartRangeName, string>
  style?: StyleProp<ViewStyle>
  metricStyle?: StyleProp<ViewStyle>
}

export const RangesLegend = ({
  metric,
  labels = DEFAULT_RANGE_LABELS,
  style,
  metricStyle,
}: RangesLegendProps) => {
  const styles = useResponsiveStyleSheet({ styles: themedStyle, smallScreenStyles })
  const dimensions = useWindowDimensions()
  const rangeConfig = useDefaultRangeConfig(metric)

  if (!rangeConfig) {
    return null
  }

  const { ranges, units } = rangeConfig
  const sign = units === 'percentage' ? '%' : ''

  const rangeValues = ranges
    .sort((a, b) => (a.from || a.to || 0) - (b.from || b.to || 0))
    .reduce(
      (acc, { from, to, rating }) => {
        let text = ''

        if (from === null) {
          text = `≤ ${to}${sign}`
        } else if (to === null) {
          text = `≥ ${from}${sign}`
        } else {
          text = `${from}${sign} - ${to}${sign}`
        }

        acc[rating].push(text)

        return acc
      },
      { good: [], ok: [], bad: [], critical: [] } as Record<ChartRangeName, string[]>,
    )

  const hasSmallScreen = Device.hasSmallScreen(dimensions)

  return (
    <View style={[styles.container, style]}>
      {Object.entries(rangeValues).map(
        ([key, values]) =>
          values.length > 0 && (
            <View key={key} style={[styles.row, metricStyle]}>
              <View style={[styles.colorLine, styles[key as ChartRangeName]]} />
              <View>
                <Text type="tiny" bold style={styles.name}>
                  {labels[key as ChartRangeName]}
                </Text>
                <View style={styles.row}>
                  {values.map((value, index) => (
                    <View key={value} style={styles.row}>
                      {index !== 0 && <View style={styles.separator} />}
                      <View>
                        <Text type={hasSmallScreen ? 'micro' : 'small'}>{value}</Text>
                        {!ignoredUnits.includes(units) && (
                          <Text type={hasSmallScreen ? 'micro' : 'small'} style={styles.unit}>
                            {units}
                          </Text>
                        )}
                      </View>
                    </View>
                  ))}
                </View>
              </View>
            </View>
          ),
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
  },
  colorLine: {
    width: 4,
    marginRight: 6,
    borderRadius: 2,
  },
  good: {
    backgroundColor: 'theme.range.good',
  },
  ok: {
    backgroundColor: 'theme.range.regular',
  },
  bad: {
    backgroundColor: 'theme.range.bad',
  },
  critical: {
    backgroundColor: 'theme.range.bad',
  },
  name: {
    color: 'theme.text.tertiary',
    textTransform: 'uppercase',
  },
  unit: {
    color: 'theme.text.tertiary',
  },
  separator: {
    width: 1,
    backgroundColor: 'theme.surface.base',
    marginVertical: 4,
    marginHorizontal: 8,
  },
})

const smallScreenStyles = StyleService.create({
  separator: {
    marginHorizontal: 4,
  },
})
