import React, { useRef } from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { TimelineChart } from '@components'
import { ChartTypes, PLACEHOLDER_TEXT_BY_CHART_TYPE } from '@src/components/Timeline/constants'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'
import { EventCharts } from '../models/events.types'

interface ChartModalProps {
  type: ChartTypes
  charts: EventCharts
}

export const ChartModal = (props: ChartModalProps) => {
  const {
    charts: { primary, secondary },
    type,
  } = props

  const styles = useStyleSheet(themedStyle)
  const chartRef = useRef()

  const isGlucose = type === ChartTypes.Glucose

  const data = isGlucose ? primary : secondary
  const isScanDisabled = useDisableInAppScan()

  const hideGlucosePlaceholder = isGlucose && isScanDisabled
  const placeholder = hideGlucosePlaceholder ? null : PLACEHOLDER_TEXT_BY_CHART_TYPE[type]

  return (
    <SafeAreaView edges={['left', 'bottom']} style={styles.container}>
      <TimelineChart
        chartRef={chartRef}
        style={styles.chart}
        data={data}
        type={type}
        chartRightPadding={40}
        mode="zoom"
        placeholder={placeholder}
        showPoints={!isGlucose}
        connectNulls={!isGlucose}
      />
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background.modal',
  },
  chart: {
    flex: 1,
  },
})
