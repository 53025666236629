import { gql } from '@apollo/client/core'
import { CORE_SUBSCRIPTION_FIELDS } from '@src/graphql/fragments/subscription'

const UPGRADE_SUBSCRIPTION_TRIAL = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  mutation upgradeSubscriptionTrial($id: ID!, $providerPaymentMethodId: String!) {
    upgradeSubscriptionTrial(id: $id, providerPaymentMethodId: $providerPaymentMethodId) {
      subscription {
        ...CoreSubscriptionFields
      }
    }
  }
`

export default UPGRADE_SUBSCRIPTION_TRIAL
