import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { SurveysConfigKind, SurveyLinkCollection } from '@src/types'

const SYNC_DELAY_IN_SECONDS = 5

export const useSyncHealthQuestionnaireState = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation()

  const syncHealthQuestionnaireState = useCallback(() => {
    const loadQuestionnaire = () => {
      dispatch({
        type: 'app/surveyQuestionnaire',
        success: ({ surveyLinks }: SurveyLinkCollection) => {
          const surveyLink = surveyLinks.find(
            (link) => link.survey.kind === SurveysConfigKind.Health,
          )

          if (surveyLink?.finished) {
            return
          }

          // go to the health questionnaire
          navigation.navigate('AlmostThere')
        },
      })
    }

    loadQuestionnaire()
  }, [dispatch, navigation])

  useFocusEffect(
    useCallback(() => {
      // sync it immediately
      syncHealthQuestionnaireState()

      const interval = setInterval(() => {
        syncHealthQuestionnaireState()
      }, SYNC_DELAY_IN_SECONDS * 1000)

      return () => clearInterval(interval)
    }, [syncHealthQuestionnaireState]),
  )
}
