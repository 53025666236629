import React, { useEffect, useRef, useState } from 'react'
import { Animated, Easing } from 'react-native'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { calendarDateSelector } from '@src/selectors/app'
import { MONTH_NAME_AND_DATE_FORMAT } from '@src/config/momentFormat'
import { formatDateRange, TODAY } from '@src/config/momentFormat'

const MESSAGE_BAR_HEIGHT = 30
const ANIMATION_DURATION = 100

export const HistoryDateInfoBar = () => {
  const styles = useStyleSheet(themedStyle)
  const { startDate, endDate } = useSelector(calendarDateSelector)
  const dateRangeString = formatDateRange(startDate, endDate)
  const translateYRef = useRef(new Animated.Value(-MESSAGE_BAR_HEIGHT))
  const [hidden, setHidden] = useState(dateRangeString === TODAY)

  useEffect(() => {
    if (dateRangeString === TODAY) {
      Animated.timing(translateYRef.current, {
        duration: ANIMATION_DURATION,
        easing: Easing.linear,
        toValue: -MESSAGE_BAR_HEIGHT,
        useNativeDriver: true,
      }).start(() => {
        setHidden(true)
      })
    } else {
      setHidden(false)
      Animated.timing(translateYRef.current, {
        duration: ANIMATION_DURATION,
        easing: Easing.linear,
        toValue: 0,
        useNativeDriver: true,
      }).start()
    }
  }, [dateRangeString])

  if (hidden) {
    return null
  }

  return (
    <Animated.View
      style={[
        styles.container,
        {
          transform: [
            {
              translateY: translateYRef.current,
            },
          ],
        },
      ]}
    >
      <Text type="small" style={styles.text}>
        Showing events up to {moment(endDate).format(MONTH_NAME_AND_DATE_FORMAT)}
      </Text>
    </Animated.View>
  )
}

const themedStyle = StyleService.create({
  container: {
    height: MESSAGE_BAR_HEIGHT,
    backgroundColor: 'theme.primary.base',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'theme.solid.white',
  },
})
