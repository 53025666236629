import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { BottomSheet, OutlinedIcon } from '@src/components'
import { Intercom } from '@utils'
import { AppStackParamList } from '@src/navigation/types'

export const ErrorScanningModal = () => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const styles = useStyleSheet(themedStyles)

  const route = useRoute<RouteProp<AppStackParamList, 'ErrorScanningModal'>>()

  const params = route.params || {}

  const onActionPress = () => {
    navigation.replace('AddScan', {
      action: 'activate',
      parentScreen: params.parentScreen,
      parentScreenParams: (params as any).parentScreenParams,
    })
  }

  const onSecondaryPress = () => {
    Intercom.showIntercomMessenger({ source: 'ErrorScanningModal' })
  }

  return (
    <BottomSheet
      primaryButton={{ text: 'Try Again', onPress: onActionPress }}
      secondaryButton={{ text: 'Contact Customer Service', onPress: onSecondaryPress }}
      buttonDirection="column-reverse"
    >
      <OutlinedIcon iconName="warning" style={styles.icon} />
      <Text type="title-2" style={styles.primaryText}>
        There was an error when scanning your sensor
      </Text>
      <Text type="large" style={styles.secondaryText}>
        Please wait 1 minute and try again. If repeated attempts fail, message customer support.
      </Text>
    </BottomSheet>
  )
}
const themedStyles = StyleService.create({
  primaryText: {
    marginBottom: 32,
    textAlign: 'center',
  },
  secondaryText: {
    textAlign: 'center',
  },
  icon: {
    alignSelf: 'center',
  },
})
