import React from 'react'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Feature, useFeatureFlag } from '@src/components'
import { SettingsComponent } from '../components/Settings'

const Settings = () => {
  const appNavigationUpdatesEnables = useFeatureFlag(Feature.AppNavigationUpdates)
  return (
    <NavigationContainer title="Settings" isDrawerScreen={!appNavigationUpdatesEnables}>
      <SettingsComponent />
    </NavigationContainer>
  )
}

export default Settings
