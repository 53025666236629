import { gql } from '@apollo/client'

export const CORE_SCAN_FIELDS = gql`
  fragment CoreScanFields on Scan {
    id
    createdAt
    sensor {
      model
    }
  }
`
