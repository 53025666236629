import React, { useState, useEffect, useRef, useImperativeHandle } from 'react'
import { TouchableOpacity, View } from 'react-native'
import {
  Video,
  VideoProps,
  ResizeMode,
  AVPlaybackStatus,
  Audio,
  VideoReadyForDisplayEvent,
} from 'expo-av'
import { Rect } from 'react-content-loader/native'
import { Device } from '@src/config'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ContentLoader } from '@src/components/ContentLoader'
import { LoadingIndicator } from '@src/components/LoadingIndicator'
import { useFitVideoInView } from './hooks'
import CustomizedVideoPlayer from './Customized/VideoPlayer'

export interface VideoPlayerProps extends Omit<VideoProps, 'useNativeControls'> {
  onPlay?: () => void
  onPause?: () => void
  showControls?: boolean
  playOnLoad?: boolean
}

export const VideoPlayer = React.forwardRef<Video | null, VideoPlayerProps>(
  (props, forwardedRef) => {
    const {
      source,
      onReadyForDisplay = () => 0,
      onPlay = () => 0,
      onPause = () => 0,
      onError = () => 0,
      style: videoStyle,
      showControls = true,
      playOnLoad = false,
      ...restProps
    } = props

    const styles = useStyleSheet(themedStyles)
    const [isReadyForDisplay, setIsReadyForDisplay] = useState(false)
    const videoRef = useRef<Video | null>(null)
    const [status, setStatus] = React.useState<AVPlaybackStatus>({ isLoaded: false })
    const { setViewDimensions, setVideoDimensions, dimensions } = useFitVideoInView()

    useImperativeHandle(forwardedRef, () => videoRef.current as Video, [])

    useEffect(() => {
      Audio.setAudioModeAsync({
        staysActiveInBackground: true,
        playsInSilentModeIOS: true,
      })
    }, [])

    useEffect(() => {
      if (playOnLoad && status.isLoaded) {
        videoRef.current?.playAsync()
      }
    }, [playOnLoad, status.isLoaded])

    const isReady = isReadyForDisplay && status.isLoaded
    const showBufferingIndicator = isReady && status.isBuffering && !status.isPlaying

    const handleReadyForDisplay = (response: VideoReadyForDisplayEvent) => {
      if (response.naturalSize) {
        setVideoDimensions(response.naturalSize)
      }
      setIsReadyForDisplay(true)
      onReadyForDisplay(response)
    }

    const handlePlaybackStatusUpdate = (newStatus: AVPlaybackStatus) => {
      if (!newStatus.isLoaded || !status.isLoaded) {
        setStatus(newStatus)
        return
      }

      const hasPlayingChanged = status.isPlaying !== newStatus.isPlaying
      const hasBufferingChanged = status.isBuffering !== newStatus.isBuffering

      if (hasPlayingChanged) {
        if (newStatus.isPlaying) {
          onPlay()
        } else if (!hasBufferingChanged) {
          onPause()
        }
      }
      setStatus(newStatus)
    }

    return (
      <View style={styles.container} onLayout={(e) => setViewDimensions(e.nativeEvent.layout)}>
        {!isReady && (
          <ContentLoader
            style={{ position: 'absolute', height: '100%', width: '100%', zIndex: 50 }}
          >
            <Rect x="0" y="0" width="100%" height="100%" rx="6" ry="6" />
          </ContentLoader>
        )}
        <View style={styles.videoPlayer}>
          {showControls && (
            <View style={styles.videoOverlay} pointerEvents="box-none">
              {showBufferingIndicator && (
                <TouchableOpacity
                  style={[styles.overlayButton, styles.bufferingButton]}
                  onPress={() => videoRef.current?.pauseAsync()}
                  accessibilityLabel="Pause"
                >
                  <LoadingIndicator
                    size="large"
                    viewStyles={styles.bufferIndicatorIcon}
                    color="black"
                  />
                </TouchableOpacity>
              )}
            </View>
          )}
          {Device.ios && showControls ? (
            // There is a bug on iOS 17.2+ with older version of expo-av (tied to older version of react native)
            // that causes the app to crash when a video component is unmounted if said video component
            // makes use of `useNativeControls`.
            //
            // Until we upgrade and the crash is resolved, use a set of custom video controls.
            //
            <CustomizedVideoPlayer
              ref={videoRef}
              videoProps={{
                source,
                style: [styles.video, dimensions, videoStyle],
                onReadyForDisplay: handleReadyForDisplay,
                onError,
                resizeMode: ResizeMode.COVER,
                ...restProps,
              }}
              showControls={showControls}
              defaultControlsVisible={showControls}
              containerStyle={styles.video}
              errorCallback={(error) => onError(error.message)}
              playbackCallback={handlePlaybackStatusUpdate}
            />
          ) : (
            <Video
              ref={videoRef}
              source={source}
              style={[styles.video, dimensions, videoStyle]}
              onPlaybackStatusUpdate={handlePlaybackStatusUpdate}
              onReadyForDisplay={handleReadyForDisplay}
              onError={onError}
              resizeMode={ResizeMode.COVER}
              useNativeControls={showControls}
              {...restProps}
            />
          )}
        </View>
      </View>
    )
  },
)

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
  videoPlayer: {
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  video: {
    borderRadius: 8,
  },
  videoOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  overlayButton: {
    alignSelf: 'center',
    borderRadius: 90,
    justifyContent: 'center',
    alignItems: 'center',
    width: 56,
    height: 56,
  },
  bufferingButton: {
    backgroundColor: 'theme.solid.white',
  },
  bufferIndicatorIcon: {
    paddingLeft: 3,
    paddingTop: 3,
  },
  controlIcon: {
    marginRight: 8,
    color: 'theme.solid.white',
  },
})
