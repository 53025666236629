import React from 'react'
import { View } from 'react-native'
import { chunk } from 'lodash'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Product } from '@src/types'
import { SubscriptionItem } from '@src/screens/Marketplace/components/SubscriptionItem'
import { useSelectedAddonModal } from '@src/screens/Marketplace/utils/hooks'

interface ProductsGridProps {
  products: Product[]
  productsPerRow: number
  showMostPopularTag: boolean
}

export const ProductsGrid = (props: ProductsGridProps) => {
  const { products, productsPerRow, showMostPopularTag } = props
  const styles = useStyleSheet(themedStyles)
  const chunkedProducts = chunk(products, productsPerRow)
  const navigation = useNavigation()
  const selectedAddonModal = useSelectedAddonModal()

  const onItemPress = (product: Product) => {
    if (product.dietitian) {
      navigation.navigate('SubscriptionCheckout', { product })
    } else if (product.addon && !product.dietitianMenu) {
      selectedAddonModal(product)
    } else {
      navigation.navigate('DietitianMenuCheckout', {
        selection: { product },
        goBackOnSuccess: true,
      })
    }
  }

  return (
    <>
      {chunkedProducts.map((productsChunk, chunkIndex) => (
        <View key={chunkIndex} style={styles.row}>
          {productsChunk.map((product, productIndex) => (
            <SubscriptionItem
              title={product.title}
              description={product.shortDescription ?? product.description}
              item={product}
              key={product.id}
              style={styles.item}
              tag={
                showMostPopularTag && chunkIndex === 0 && productIndex === 0
                  ? 'Most Popular'
                  : undefined
              }
              onItemPress={() => {
                onItemPress(product)
              }}
            />
          ))}
        </View>
      ))}
    </>
  )
}

const themedStyles = StyleService.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 12,
    marginBottom: 16,
    minHeight: 250,
  },
  item: {
    flex: 1,
    margin: 1,
  },
})
