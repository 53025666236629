import React, { useContext } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { RouteProp, useRoute } from '@react-navigation/core'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text, UI_ICONS_MAPPING } from '@components/base'
import { NavigationBar, OutlinedIcon } from '@src/components'
import { openUrl } from '@src/utils'
import {
  AppStackParamList,
  QuestionnaireScreenName,
  QuestionnaireStackParamList,
} from '@src/navigation/types'
import { QuestionnaireContext } from '../questionnaire.navigator'

type QuestionnaireCompletedScreenRoute = RouteProp<
  QuestionnaireStackParamList,
  QuestionnaireScreenName.QuestionnaireCompleted
>

export const QuestionnaireCompletedScreen = () => {
  const styles = useStyleSheet(themedStyles)

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const { params } = useRoute<QuestionnaireCompletedScreenRoute>()

  const { nextScreen, nextScreenParams } = params || {}

  const { completedScreenConfig } = useContext(QuestionnaireContext)
  const {
    onCloseButtonPress,
    title,
    header,
    intro,
    icon,
    primaryActionText,
    footnote,
    secondaryActionLink,
  } = completedScreenConfig

  const insets = useSafeAreaInsets()

  const onPrimaryButtonPress = () => {
    if (nextScreen) {
      navigation.replace(nextScreen as any, nextScreenParams)
      return
    }

    onCloseButtonPress()
  }

  const onLinkPress = () => {
    if (!secondaryActionLink) {
      return
    }

    openUrl(secondaryActionLink.url)
  }

  const showNavigationBar = !!title

  return (
    <View style={styles.topLevelContainer}>
      {showNavigationBar && (
        <NavigationBar
          title={title}
          style={styles.navigationBar}
          backgroundColor={styles.topLevelContainer.backgroundColor}
        />
      )}
      <ScrollView
        bounces={false}
        contentContainerStyle={[
          styles.container,
          !showNavigationBar && styles.topSpacing,
          { paddingBottom: insets?.bottom || 0 },
        ]}
      >
        <View style={styles.headContainer}>
          {!!icon && <OutlinedIcon iconName={UI_ICONS_MAPPING[icon]} iconStyle={styles.icon} />}
          <Text type="title-3" style={styles.title}>
            {header}
          </Text>
          <Text type="regular" style={styles.description}>
            {intro}
          </Text>
        </View>
        <View>
          {!!footnote && (
            <Text type="tiny" style={styles.footnote}>
              {footnote}
            </Text>
          )}
          <Button
            type="primary"
            size="block"
            onPress={onPrimaryButtonPress}
            accessibilityLabel="Close questionnaire"
            style={styles.button}
          >
            {primaryActionText}
          </Button>
          {secondaryActionLink && (
            <Button
              type="transparent"
              size="block"
              onPress={onLinkPress}
              accessibilityLabel="Complete questionnaire later"
              style={styles.button}
            >
              {secondaryActionLink.text}
            </Button>
          )}
        </View>
      </ScrollView>
    </View>
  )
}

const themedStyles = StyleService.create({
  topLevelContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  container: {
    paddingHorizontal: 16,
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  headContainer: {
    alignItems: 'center',
  },
  topSpacing: {
    paddingTop: 60,
  },
  navigationBar: {
    marginBottom: 32,
    alignSelf: 'stretch',
  },
  icon: {
    color: 'theme.info.base',
  },
  title: {
    marginBottom: 16,
    textAlign: 'center',
    maxWidth: 280,
  },
  description: {
    color: 'theme.text.primary',
    textAlign: 'center',
    marginBottom: 32,
  },
  footnote: {
    marginTop: 8,
    marginBottom: 8,
    textAlign: 'center',
  },
  button: {
    marginBottom: 8,
  },
})
