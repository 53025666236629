import { NativeModules } from 'react-native'
import { BackgroundTaskOptions } from 'react-native-background-actions'
import { Libre3BluetoothManagerConnectionState } from 'react-native-freestyle-libre'
import { FORMATTED_BLUETOOTH_CONNECTION_STATE } from '@src/utils/bluetooth'

const { scheme } = NativeModules.Configuration

export enum BackgroundServiceEvents {
  ConnectionState = 'ConnectionState',
  PatchInfo = 'PatchInfo',
  RealTimeGlucoseValue = 'RealTimeGlucoseValue',
  BluetoothEnabled = 'BluetoothEnabled',
  UnitSystem = 'UnitSystem',
  baselineAdjustment = 'BaselineAdjustment',
  SyncData = 'SyncData',
  Cleanup = 'Cleanup',
  ShowSnack = 'ShowSnack',
}

export const DEFAULT_HISTORY_LIFE_COUNT = 5 // request history from first 5 minutes by default

export const BACKGROUND_SERVICE_OPTIONS: BackgroundTaskOptions = {
  taskName: 'Sensor',
  taskTitle: 'Libre 3 sensor',
  taskDesc: FORMATTED_BLUETOOTH_CONNECTION_STATE[Libre3BluetoothManagerConnectionState.Connecting],
  taskIcon: {
    name: 'ic_launcher_foreground',
    type: 'drawable',
  },
  linkingURI: `${scheme}://app`,
}
