import React from 'react'
import moment from 'moment'
import { SensorTypes } from 'react-native-freestyle-libre'
import { BaseListItem } from '@src/components/list/common/BaseListItem'
import { Text } from '@components/base'
import { Measurement } from '@src/types'
import { HOUR_MINUTE_AM_PM } from '@src/config/momentFormat'

export interface ScanListItemProps {
  item: Measurement & { lastScan?: boolean }
}

export const ScanListItem = (props: ScanListItemProps) => {
  const { item } = props
  const { title, value, occurredAt, lastScan, scan } = item

  const renderImageContent = () => {
    return <Text type="title-3">{value as number}</Text>
  }

  const getTag = () => {
    if (!lastScan) {
      return undefined
    }

    if (scan?.sensor?.model === SensorTypes.Libre3) {
      return 'Sync'
    }

    return 'Scan'
  }

  return (
    <BaseListItem
      title={title}
      info={moment(occurredAt).format(HOUR_MINUTE_AM_PM)}
      tag={getTag()}
      renderImageContent={renderImageContent}
    />
  )
}
