import { Platform } from 'react-native'
import Config from 'react-native-config'

const platform = Platform.OS.toUpperCase() as 'IOS' | 'ANDROID'

export const appVersionSelectOptions = [
  {
    text: 'Production',
    value: Config[`${platform}_CODEPUSH_DEPLOYMENT_KEY_PRODUCTION`] || '',
  },
  {
    text: 'Staging',
    value: Config[`${platform}_CODEPUSH_DEPLOYMENT_KEY_STAGING`] || '',
  },
]
