import React from 'react'
import { View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

interface NoDataProps {
  style?: ViewStyle
  text?: string
}

const NoData = ({ style, text }: NoDataProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={[styles.emptyView, style]}>
      <Text type="regular" style={styles.emptyText}>
        {text || 'No Data'}
      </Text>
    </View>
  )
}

export default NoData

const themedStyles = StyleService.create({
  emptyView: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  emptyText: {
    color: 'theme.text.secondary',
  },
})
