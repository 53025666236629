import { camelCase, mapKeys, min, isNumber, round } from 'lodash'
import { MacroGoalsDaily } from '@src/screens/Settings/models/settings.types'
import { Macros, Percent, ChartData } from './types'

export const DEFAULT_GOALS = {
  calories: 2000,
  protein: 75,
  carbs: 150,
  fat: 75,
  netCarbs: 110,
}

export const extractData = (
  nutritionData: any,
  nutritionGoals?: MacroGoalsDaily,
): { values: Macros; goals: Macros } => {
  const nutrition = mapKeys(nutritionData, (_, key) => camelCase(key))
  const carbs = round(nutrition?.totalCarb ?? 0, 2)

  const values = {
    calories: round(nutrition?.calories ?? 0, 2),
    protein: round(nutrition?.protein ?? 0, 2),
    carbs,
    fat: round(nutrition?.totalFat ?? 0, 2),
    netCarbs: Math.max(carbs - round(nutrition?.fiber ?? 0, 2), 0),
  }

  let netCarbsGoal = round(nutritionGoals?.netCarbs || DEFAULT_GOALS.netCarbs, 2)
  const carbsGoal = round(nutritionGoals?.carbs || DEFAULT_GOALS.carbs, 2)
  if (carbsGoal < netCarbsGoal) {
    netCarbsGoal = carbsGoal
  }

  const goals = {
    calories: round(nutritionGoals?.calories || DEFAULT_GOALS.calories, 2),
    protein: round(nutritionGoals?.protein || DEFAULT_GOALS.protein, 2),
    carbs: carbsGoal,
    fat: round(nutritionGoals?.fat || DEFAULT_GOALS.fat, 2),
    netCarbs: netCarbsGoal,
  }

  return { values, goals }
}

export const toChartData = (
  nutritionData: any,
  nutritionGoals: MacroGoalsDaily | undefined,
  showNetCarbs: boolean,
): Array<ChartData> => {
  const { values, goals }: { values: Macros; goals: Macros } = extractData(
    nutritionData,
    nutritionGoals,
  )

  const percent: Percent = {}

  ;(Object.keys(values) as Array<keyof Macros>).forEach(
    (key) => (percent[key] = min([values[key] / goals[key], 1])),
  )

  return [
    {
      name: 'Fat',
      value: values.fat,
      percent: percent.fat,
      color: '1',
    },
    {
      name: showNetCarbs ? 'Net Carbs' : 'Carbs',
      value: showNetCarbs ? values.netCarbs : values.carbs,
      percent: showNetCarbs ? percent.netCarbs : percent.carbs,
      color: '2',
    },
    {
      name: 'Protein',
      value: values.protein,
      percent: percent.protein,
      color: '3',
    },
    {
      name: 'Calories',
      value: values.calories,
      percent: percent.calories,
      color: '4',
    },
    {
      name: 'Fat',
      value: values.fat,
      percent: 1 - percent.fat,
      color: '5',
    },
    {
      name: showNetCarbs ? 'Net Carbs' : 'Carbs',
      value: showNetCarbs ? values.netCarbs : values.carbs,
      percent: 1 - (showNetCarbs ? percent.netCarbs : percent.carbs),
      color: '5',
    },
    {
      name: 'Protein',
      value: values.protein,
      percent: 1 - percent.protein,
      color: '5',
    },
    {
      name: 'Calories',
      value: values.calories,
      percent: 1 - percent.calories,
      color: '5',
    },
  ]
}

const DEFAULT_NUTRITION_PERCENT = {
  protein: 0,
  carbs: 0,
  fat: 0,
}

// https://canvas.instructure.com/courses/1167174/pages/4-4-9-rule-and-calculating-percentages
const PROTEIN_MULTIPLIER = 4
const FAT_MULTIPLIER = 9
const CARBS_MULTIPLIER = 4

const calculateNutritionPercent = (values: Macros) => {
  if (!values.calories) {
    return DEFAULT_NUTRITION_PERCENT
  }

  const proteinCalories = values.protein * PROTEIN_MULTIPLIER
  const fatCalories = values.fat * FAT_MULTIPLIER
  const carbsCalories = values.carbs * CARBS_MULTIPLIER

  const totalCalories = proteinCalories + fatCalories + carbsCalories

  if (totalCalories === 0) {
    return DEFAULT_NUTRITION_PERCENT
  }

  const multiplier = 100 / totalCalories

  const proteinPercent = Math.floor(proteinCalories * multiplier)

  if (values.carbs === 0) {
    return {
      protein: proteinPercent,
      carbs: 0,
      fat: 100 - proteinPercent,
    }
  }

  const fatPercent = Math.floor(fatCalories * multiplier)

  return {
    protein: proteinPercent,
    carbs: 100 - proteinPercent - fatPercent,
    fat: fatPercent,
  }
}

const calculateNutritionGoalPercent = (goals: Macros) => {
  if (!goals.calories) {
    return DEFAULT_NUTRITION_PERCENT
  }

  const proteinPercentGoal = 100 * ((goals.protein * PROTEIN_MULTIPLIER) / goals.calories)
  const carbsPercentGoal = 100 * ((goals.carbs * CARBS_MULTIPLIER) / goals.calories)
  const fatPercentGoal = 100 * ((goals.fat * FAT_MULTIPLIER) / goals.calories)
  const percentSum = proteinPercentGoal + carbsPercentGoal + fatPercentGoal

  if (percentSum === 0) {
    return DEFAULT_NUTRITION_PERCENT
  }

  const percentCoefficient = 100.0 / percentSum

  // we need to use percentCoefficient so they sum to 100%
  const scaledProteinPercentGoal = Math.floor(percentCoefficient * proteinPercentGoal)
  const scaledFatPercentGoal = Math.floor(percentCoefficient * fatPercentGoal)
  const scaledCarbsPercentGoal = 100 - scaledProteinPercentGoal - scaledFatPercentGoal

  return {
    protein: scaledProteinPercentGoal,
    carbs: scaledCarbsPercentGoal,
    fat: scaledFatPercentGoal,
  }
}

export const toCaloriesChartData = (nutritionData: any, nutritionGoals?: MacroGoalsDaily) => {
  const { values, goals } = extractData(nutritionData, nutritionGoals)

  const percent = calculateNutritionPercent(values)
  const percentGoal = calculateNutritionGoalPercent(goals)

  return [
    {
      goal: percentGoal.protein,
      percent: percent.protein,
      type: 'protein',
    },
    {
      goal: percentGoal.carbs,
      percent: percent.carbs,
      type: 'carbs',
    },
    {
      goal: percentGoal.fat,
      percent: percent.fat,
      type: 'fat',
    },
  ]
}

export const scoreColor = (number: number, danger = 4, warning = 7) => {
  if (number <= danger) {
    return 'theme.range.bad'
  }
  if (number <= warning) {
    return 'theme.range.regular'
  }
  return 'theme.range.good'
}

export const formatScore = (score: number | null | undefined) =>
  isNumber(score) ? Math.round(score) : undefined
