import { gql } from '@apollo/client'
import { LATEST_USER_APPOINTMENT_FIELDS } from './fragments/latestUserAppointment'

export const BOOK_VIDEO_CALL = gql`
  ${LATEST_USER_APPOINTMENT_FIELDS}
  mutation bookVideoCall(
    $appointmentType: InsuranceBerryStreetAppointmentType
    $timeZone: String
    $date: String
    $coachId: ID
    $pendingAppointmentId: ID
  ) {
    bookVideoCall(
      appointmentType: $appointmentType
      timeZone: $timeZone
      date: $date
      coachId: $coachId
      pendingAppointmentId: $pendingAppointmentId
    ) {
      ...LatestUserAppointmentFields
    }
  }
`
