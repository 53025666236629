import React, { useEffect, useState } from 'react'
import { camelCase } from 'lodash'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Controller, RegisterOptions, useForm } from 'react-hook-form'
import { useNavigation, useRoute } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScrollableAvoidKeyboard } from '@components'
import { Button, Input, Text } from '@components/base'
import { AppRouteProp } from '@navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import { IngredientPortionForm } from '@screens/Ingredients'
import { toUnderscore, toWord } from '@screens/Ingredients/transforms'
import { normalizeNutrisenseItem } from '@screens/Ingredients/utils'
import { customIngredientsMacrosSelector, nutritionUnitsSelector } from '@selectors/app'
import { ingredientsSelector } from '@selectors/ingredients'
import { useCancelModal } from '@utils/hooks'
import { useGoBack } from '@utils/navigation'
import { useSnack } from '@utils/navigatorContext'
import { validateFloatNumberInput } from '@utils/validators'
import { Ingredient } from '@src/types'

const REQUIRED_FIELDS = ['description', 'serving', 'calories']
const SERVING_OPTIONS = ['Gram', 'Ounce', 'Cup', 'Other']

const getValidationRules = (fieldName: string) => {
  const rules: RegisterOptions = {}

  if (REQUIRED_FIELDS.includes(fieldName)) {
    rules.required = `${toWord(fieldName)} is required`
  }

  return rules
}

export const CustomIngredient = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const goBack = useGoBack()
  const showSnack = useSnack()
  const {
    control,
    formState: { isSubmitting, errors },
    getValues,
    handleSubmit,
    setValue,
  } = useForm()
  const { params } = useRoute<AppRouteProp<'CustomIngredient'>>()
  const { ingredientId, name } = params || {}

  const macros = useSelector(customIngredientsMacrosSelector)
  const allIngredients = useSelector(ingredientsSelector)
  const units = useSelector(nutritionUnitsSelector)

  const editedIngredient = allIngredients.find((ingredient) => ingredient.id === ingredientId)

  const [serving, setServing] = useState(editedIngredient?.servingUnits || SERVING_OPTIONS[0])
  const [quantity, setQuantity] = useState(`${editedIngredient?.servingAmount || 1}`)

  const isNewIngredient = !ingredientId

  const openCancelModal = useCancelModal({
    goBack,
    isModified: Object.values(getValues()).some((value) => value?.trim().length > 0),
    itemName: 'ingredient',
  })

  const saveIngredient = (form: Record<string, string>) => {
    const nutrition = macros.reduce((acc, { name }) => {
      return form[name] ? { ...acc, [toUnderscore(name)]: Number(form[name]) } : acc
    }, {} as Record<string, number>)

    const payload = {
      ingredientId: editedIngredient?.id,
      description: form.description,
      servingAmount: Number(quantity) || 1,
      servingUnits: serving === 'Other' ? form.serving : serving,
      calories: nutrition.calories,
      nutrition,
      id: editedIngredient?.id,
      createdAt: editedIngredient?.createdAt,
      __typename: 'Ingredient',
    }

    dispatch({
      type: 'ingredients/submit',
      payload,
      success: (result: Ingredient) => {
        navigation.navigate('IngredientSearch', { createdIngredientId: result.id })
      },
      failure: () => {
        showSnack('Ingredient could not be saved. Please try again later.', null, 'error')
      },
    })
  }

  useEffect(() => {
    if (editedIngredient) {
      const { nutrition } = normalizeNutrisenseItem(editedIngredient)

      setValue('description', editedIngredient.description)

      Object.entries(nutrition).forEach(([key, value]) => {
        setValue(camelCase(key), `${value}`)
      })
    }
  }, [editedIngredient, setValue])

  useEffect(() => {
    if (name) {
      setValue('description', name)
    }
  }, [name, setValue])

  return (
    <NavigationContainer
      showLoadingIndicator
      goBack={isNewIngredient ? openCancelModal : goBack}
      title={isNewIngredient ? 'New Ingredient' : 'Edit Ingredient'}
      rightAccessories={[
        <Button
          type="transparent"
          size="small"
          key="save-button"
          accessibilityLabel="Save"
          disabled={isSubmitting}
          onPress={handleSubmit(saveIngredient)}
        >
          Save
        </Button>,
      ]}
    >
      <SafeAreaView edges={['bottom']} style={styles.content}>
        <ScrollableAvoidKeyboard keyboardShouldPersistTaps="handled">
          <View style={styles.paddingView}>
            <Controller
              control={control}
              name="description"
              rules={getValidationRules('description')}
              render={({ field }) => (
                <Input
                  {...field}
                  autoCorrect={false}
                  autoFocus
                  accessibilityLabel="Ingredient name"
                  hasError={!!errors.description}
                  label="Name"
                  style={styles.input}
                  value={field.value || ''}
                  onChangeText={field.onChange}
                />
              )}
            />
            <IngredientPortionForm
              showLabels
              serving={serving}
              servingOptions={SERVING_OPTIONS}
              quantity={quantity}
              onServingChange={setServing}
              onQuantityChange={setQuantity}
            />
            {serving === 'Other' && (
              <View style={styles.otherServingContainer}>
                <View style={styles.invisiblePlaceholder} />
                <Controller
                  control={control}
                  name="serving"
                  rules={getValidationRules('serving')}
                  render={({ field }) => (
                    <Input
                      {...field}
                      autoCorrect={false}
                      accessibilityLabel="Serving name"
                      hasError={!!errors.serving}
                      placeholder="e.g. Piece"
                      style={styles.servingName}
                      value={field.value || ''}
                      onChangeText={field.onChange}
                    />
                  )}
                />
              </View>
            )}
          </View>
          <View style={styles.separator} />
          <View style={styles.paddingView}>
            <Text type="regular" bold style={styles.nutritionFactsHeading}>
              Nutrition Facts
            </Text>
            {macros.map(({ name, indent }) => (
              <View key={name}>
                <Controller
                  control={control}
                  name={name}
                  rules={getValidationRules(name)}
                  render={({ field }) => (
                    <Input
                      {...field}
                      accessibilityLabel={name}
                      accessoryRight={<Text type="regular">{units[name]}</Text>}
                      hasError={!!errors[name]}
                      keyboardType="decimal-pad"
                      label={toWord(name)}
                      maxLength={16}
                      placeholder="0"
                      returnKeyType="done"
                      style={[styles.input, indent === 1 && styles.inputSecondary]}
                      value={field.value || ''}
                      onChangeText={(text) => {
                        const value = validateFloatNumberInput(text)
                        if (value) {
                          field.onChange(value)
                        }
                      }}
                    />
                  )}
                />
              </View>
            ))}
          </View>
        </ScrollableAvoidKeyboard>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  content: {
    flex: 1,
    paddingTop: 24,
    backgroundColor: 'theme.background',
  },
  paddingView: {
    paddingHorizontal: 16,
  },
  input: {
    zIndex: 1,
    marginBottom: 24,
  },
  inputSecondary: {
    marginLeft: 24,
  },
  backgroundInput: {
    position: 'absolute',
    width: '100%',
  },
  otherServingContainer: {
    flexDirection: 'row',
    marginTop: 16,
  },
  invisiblePlaceholder: {
    flex: 2,
    marginRight: 16,
  },
  servingName: {
    flex: 5,
  },
  separator: {
    height: 8,
    marginVertical: 32,
    backgroundColor: 'theme.surface.base2',
  },
  nutritionFactsHeading: {
    marginBottom: 32,
  },
})
