import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { Divider } from '@ui-kitten/components'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { BillingProduct } from '@src/types'
import { CheckoutContainer } from '@src/screens/Marketplace/components/checkout/CheckoutContainer'
import { ProductDescription } from '@src/screens/Marketplace/components/checkout/ProductDescription'
import { ProductPayment } from '@src/screens/Marketplace/components/checkout/ProductPayment'
import {
  usePromotionCode,
  usePurchaseSubscription,
  useTaxEstimation,
} from '@src/screens/Marketplace/utils/hooks'
import { monthlyNutritionCoachingProductSelector } from '@src/selectors/app'
import { RootStackParamList } from '@src/navigation/types'

export const MonthlyNutritionCoachingCheckoutScreen = () => {
  const purchaseSubscription = usePurchaseSubscription()
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

  const product = useSelector(monthlyNutritionCoachingProductSelector)

  const productKey = product?.key as BillingProduct

  const onCheckoutPress = () => {
    purchaseSubscription({ productKey, promotionCode })
  }

  const promotionCode = usePromotionCode(productKey)
  const estimatedTax = useTaxEstimation(productKey, promotionCode)

  if (!product) {
    navigation.replace('Drawer', { screen: 'Dashboard', params: { screen: 'NutritionistHub' } })
    return null
  }

  const productTitle = product.title ?? 'Monthly Nutrition Coaching'
  const productDescription = product.description ?? 'Billed monthly, auto renewal month-to-month'

  return (
    <CheckoutContainer checkoutButtonTitle="Purchase Now" onCheckout={onCheckoutPress}>
      <ProductDescription
        title={productTitle}
        description={productDescription}
        price={`$${product.price}`}
      />

      <ProductPayment
        product={product.key as BillingProduct}
        fullPrice={product.price}
        estimatedTax={estimatedTax}
        promotionCode={promotionCode}
      />

      <Divider appearance="large" />
    </CheckoutContainer>
  )
}
