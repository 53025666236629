import React from 'react'
import { RefreshControl, Platform } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'

const NewRefreshControl = (props: any) => {
  const styles = useStyleSheet(themedStyle)
  return <RefreshControl {...props} {...styles.indicatorColor} />
}

export const RefreshControlComponent = Platform.OS === 'ios' ? NewRefreshControl : RefreshControl

const themedStyle = StyleService.create({
  indicatorColor: {
    tintColor: 'theme.text.secondary',
  },
})
