import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { Image, ImageStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Pill } from '@components/base'
import { Feature, TouchableSection, useFeatureFlag } from '@components'
import { IconSource, IconSources } from '@src/assets/icons'
import { ketoMojoSyncStatusSelector, terraProvidersSelector } from '@src/selectors/integrations'
import { Device } from '@src/config'
import {
  TERRA_APPLE_HEALTH,
  TERRA_GOOGLE_FIT,
  TERRA_KETO_MOJO,
  TerraProvider,
} from '../../models/settings.types'
import { IntegrationTouchableSection } from './IntegrationTouchableSection'

export const PROVIDER_ICONS: Record<TerraProvider['name'], IconSource> = {
  Garmin: IconSources.garminIcon,
  Fitbit: IconSources.fitbitIcon,
  Oura: IconSources.ouraIcon,
  'FreeStyle Libre': IconSources.freeStyleLibreIcon,
  MyFitnessPal: IconSources.myFitnessPal,
  'MyFitnessPal (BETA)': IconSources.myFitnessPal,
  [TERRA_KETO_MOJO]: IconSources.ketomojo,
  [TERRA_GOOGLE_FIT]: IconSources.googleFitIcon,
  [TERRA_APPLE_HEALTH]: IconSources.appleHealth,
}

export const TerraProviders = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const providers = useSelector(terraProvidersSelector)
  const ketoMojoSync = useSelector(ketoMojoSyncStatusSelector)
  const ketoMojoTerraFlag = useFeatureFlag(Feature.TerraKetoMojo)

  const hideTerraKetoMojo = ketoMojoSync || !ketoMojoTerraFlag
  const regularProviders = providers.filter(
    (provider) =>
      provider.name !== TERRA_APPLE_HEALTH &&
      (hideTerraKetoMojo ? provider.name !== TERRA_KETO_MOJO : true),
  )

  const appleHealthProvider = providers.find((provider) => provider.name === TERRA_APPLE_HEALTH)

  useEffect(() => {
    dispatch({
      type: 'settings/fetchTerraProviders',
    })
  }, [dispatch])

  const statusTag = (provider: TerraProvider) => {
    if (!provider.connectionId) {
      return
    }

    return (
      <Pill
        text={provider.active ? 'Active' : 'Inactive'}
        status={provider.active ? 'success' : 'alert'}
        size="s"
        style={styles.tag}
      />
    )
  }

  const onProviderPress = (item: TerraProvider) => {
    navigation.navigate('TerraProvider', { provider: item.provider })
  }

  return (
    <>
      {Device.hasHealthKit &&
        (appleHealthProvider ? (
          <TouchableSection
            key={appleHealthProvider.provider}
            accessoryLeft={
              <Image
                source={PROVIDER_ICONS[appleHealthProvider.name]}
                style={styles.providerIcon as ImageStyle}
              />
            }
            title={appleHealthProvider.name}
            showRightArrow
            style={styles.providersSection}
            tagComponent={statusTag(appleHealthProvider)}
            onPress={() => onProviderPress(appleHealthProvider)}
          />
        ) : (
          <IntegrationTouchableSection
            sectionTitle="Apple Health"
            imageSource={IconSources.appleHealth}
            onPress={() => navigation.navigate('HealthKitIntegration')}
          />
        ))}
      {regularProviders.map((provider) => (
        <TouchableSection
          key={provider.provider}
          accessoryLeft={
            <Image
              source={PROVIDER_ICONS[provider.name]}
              style={styles.providerIcon as ImageStyle}
            />
          }
          title={provider.name}
          showRightArrow
          style={styles.providersSection}
          tagComponent={statusTag(provider)}
          onPress={() => onProviderPress(provider)}
        />
      ))}
    </>
  )
}

const themedStyle = StyleService.create({
  providersSection: {
    borderBottomWidth: 1,
    borderBottomColor: 'theme.surface.base',
  },
  providerIcon: {
    width: 24,
    height: 24,
    marginRight: 12,
  },
  tag: {
    marginRight: 16,
  },
})
