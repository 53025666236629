import React, { ReactNode, createContext, useContext } from 'react'

export const WebPresentationModeContext = createContext<{
  isWebPresentationMode: boolean
}>({
  isWebPresentationMode: false,
})

export const useWebPresentationModeContext = () => {
  const { isWebPresentationMode } = useContext(WebPresentationModeContext)

  return {
    isWebPresentationMode,
  }
}

export const WebPresentationModeProvider = ({ children }: { children: ReactNode }) => {
  return (
    <WebPresentationModeContext.Provider
      // @ts-ignore-next-line
      value={{ isWebPresentationMode: window.isPresentationMode }}
    >
      {children}
    </WebPresentationModeContext.Provider>
  )
}
