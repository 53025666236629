import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'
import { HealthDataMeasurementType } from '@src/types'

export const measurementsStoreStateSelector = (state: RootStoreState) => state.measurements

export const lastMeasurementTypeSelector = createSelector(
  measurementsStoreStateSelector,
  (measurements) => measurements.lastMeasurementType as HealthDataMeasurementType | undefined,
)
