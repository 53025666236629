import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Image, ImageStyle, View } from 'react-native'
import { useSelector } from 'react-redux'
import { BottomSheet } from '@src/components'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Storage, useGoBack } from '@src/utils'
import { AppStackParamList } from '@src/navigation/types'
import { videoCallImage } from '@assets/images'
import { useStorageValue } from '@utils/storage/mmkv.ts'
import { userSelector } from '@selectors/app.ts'
import { Analytics, CustomEventTypes } from '@config'

const details = [
  { body: 'Pay $0 out-of-pocket if your insurance is eligible. Takes less than a minute' },
  { body: 'Get personalized guidance from a certified nutritionist on a video call' },
  { body: 'View guided highlights and action steps after each call' },
  { body: 'Track your progress towards your health goal' },
]

const description = {
  body: 'Looking to reach your health goals? Chat with a dedicated nutritionist - ',
  emphasis: 'covered by your insurance!',
}

export const VideoCallsUpsellModal = () => {
  const user = useSelector(userSelector)
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const [_, setModalCompleted] = useStorageValue<boolean>(
    `${Storage.VIDEO_CALLS_UPSELL_MODAL_COMPLETED_KEY}_${user?.id}`,
  )

  const onLearnMorePress = () => {
    navigation.replace('VideoCallsUpsellDetailsModal', { details, description })
  }

  const markCompleted = () => {
    setModalCompleted(true)
  }

  const onDismiss = () => {
    markCompleted()
    Analytics.track(CustomEventTypes.VideoCallsUpsellModalDismissed, { userId: user?.id })
    goBack()
  }

  return (
    <BottomSheet
      primaryButton={{ text: 'Learn More', onPress: onLearnMorePress }}
      secondaryButton={{ text: 'Dismiss', onPress: onDismiss }}
      onClose={markCompleted}
      buttonDirection="column-reverse"
    >
      <Text style={styles.content} type="title-2">
        Insurance Covered Video Call
      </Text>
      <Text style={styles.content} type="regular">
        Unlock personalized guidance in a{' '}
        <Text type="regular" bold>
          video call - covered by your insurance!
        </Text>
      </Text>
      <Text style={styles.content} type="regular">
        Looking to reach your health goals? Talk with a dedicated nutritionist - covered by your
        insurance!
      </Text>
      <View style={styles.imageContainer}>
        <Image source={videoCallImage.imageSource} style={styles.image as ImageStyle} />
        <Icon name="play-circle" weight="fill" size={60} style={styles.icon} />
        <Icon
          name="speaker-simple-slash"
          weight="bold"
          size={20}
          style={[styles.icon, styles.speakerIcon]}
        />
        <Icon
          name="corners-out"
          weight="bold"
          size={20}
          style={[styles.icon, styles.cornersOutIcon]}
        />
      </View>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  content: {
    textAlign: 'center',
    margin: 8,
  },
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginHorizontal: 8,
    marginVertical: 24,
  },
  image: {
    aspectRatio: 3 / 2,
    width: '100%',
    height: undefined,
    borderRadius: 20,
  },
  icon: {
    position: 'absolute',
    color: 'theme.solid.white',
    opacity: 0.9,
  },
  speakerIcon: {
    bottom: 16,
    left: 16,
  },
  cornersOutIcon: {
    bottom: 16,
    right: 16,
  },
})
