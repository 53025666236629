import { gql } from '@apollo/client'
import { CORE_MEASUREMENT_FIELDS } from '@src/graphql/fragments/measurement'
import { CORE_SCAN_FIELDS } from '@src/graphql/fragments/scan'
import { CORE_SENSOR_FIELDS } from '@src/graphql/fragments/sensor'

const CREATE_SCAN = gql`
  ${CORE_MEASUREMENT_FIELDS}
  ${CORE_SCAN_FIELDS}
  ${CORE_SENSOR_FIELDS}
  mutation createScan($data: ScanData!) {
    createScan(data: $data) {
      ...CoreScanFields
      measurements {
        ...CoreMeasurementFields
        scan {
          ...CoreScanFields
        }
      }
      sensor {
        ...CoreSensorFields
      }
    }
  }
`

export default CREATE_SCAN
