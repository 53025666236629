import { Model } from '@models'
import { CHECKLIST_ITEMS } from '@src/screens/Checklist/graphql/checklistItems'
import { UserChecklistItemsKind } from '@src/types'
import { ChecklistItemsQuery } from '../graphql/checklistItems.generated'
import reducers from './checklist.reducers'

export default class Checklist {
  namespace = 'checklist'

  state = {
    ...Model.defaultState,
    onboardingChecklistItems: [],
  }

  effects = {
    fetchOnboardingItems: Model.buildEffect({
      name: `${this.namespace}/fetchItems`,
      query: CHECKLIST_ITEMS,
      dataPath: 'checklistItems',
      variables: { kind: UserChecklistItemsKind.Onboarding },
      reducers: [
        {
          name: 'setOnboardingChecklistItems',
          payload: (response: ChecklistItemsQuery['checklistItems']) => response.checklistItems,
        },
      ],
    }),
  }

  reducers = reducers
}
