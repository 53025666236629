import { Calendar } from '@src/models/app.types'
import commonReducers from '@src/models/commonReducers'
import defaultReducers from '@src/models/defaultReducers'
import { InsightsStoreState } from './insights.types'

export const reducers = {
  ...defaultReducers,
  fetchList: commonReducers.fetchList,
  updateSort: commonReducers.updateSort,
  updateListItem: commonReducers.updateListItem,

  updateCalendarState: (state: InsightsStoreState, { payload }: { payload: Calendar }) => ({
    ...state,
    calendar: payload,
  }),
}

export default reducers
