import { gql } from '@apollo/client'

export const COMPLETE_NOTIFICATION = gql`
  mutation completeNotification($id: ID!) {
    completeNotification(id: $id) {
      id
      kind
      actionKind
      actionButtonLabel
      content
      payload
      deliveredAt
      canceledAt
      dismissedAt
      completedAt
      createdAt
      updatedAt
    }
  }
`
