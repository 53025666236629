import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { useRoute, RouteProp } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { tutorialsSelector } from '@src/selectors/app'
import { RangesLegend, BottomSheet } from '@components'
import { Content } from '@src/components/Tutorial/Content'
import { Text } from '@components/base'
import { useGoBack } from '@src/utils'

const rangeLabels = {
  Glucose: undefined,
  Ketone: { good: 'Optimal Ketosis', ok: 'Extended Fast', bad: 'High', critical: 'Ketoacidosis' },
}

const rangeMetrics = {
  Glucose: 'max',
  Ketone: 'ketones_max',
}

export const TimelineTutorialModal = () => {
  const styles = useStyleSheet(themedStyles)

  const route = useRoute<RouteProp<AppStackParamList, 'TimelineTutorialModal'>>()
  const { timeline } = route.params

  const tutorials = useSelector(tutorialsSelector)
  const [chartTutorial, ...extraTutorials] = tutorials[`timeline${timeline}Chart`]
  const [rangesTutorial] = tutorials[`timeline${timeline}Ranges`]

  const goBack = useGoBack()

  return (
    <BottomSheet header={chartTutorial.title} primaryButton={{ text: 'Got It!', onPress: goBack }}>
      <Content item={chartTutorial} descriptionStyle={styles.description} />
      <View style={styles.rangesContainer}>
        <Text type="tiny" bold style={styles.rangesTitle}>
          {rangesTutorial.title}
        </Text>
        <RangesLegend
          metric={rangeMetrics[timeline]}
          labels={rangeLabels[timeline]}
          style={[timeline === 'Ketone' && styles.ketoneLegend]}
          metricStyle={[styles.rangeItem, timeline === 'Ketone' && styles.ketoneItem]}
        />
        <Content
          item={rangesTutorial}
          descriptionStyle={styles.rangesDescription}
          textType="small"
        />
      </View>
      {extraTutorials.map((item) => (
        <Content key={item.title} item={item} descriptionStyle={styles.description} />
      ))}
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  description: {
    marginBottom: 8,
    lineHeight: 22,
  },
  rangesContainer: {
    marginTop: 8,
    marginBottom: 16,
    paddingTop: 10,
    paddingHorizontal: 16,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'theme.surface.base0',
  },
  rangesTitle: {
    paddingBottom: 8,
    textTransform: 'uppercase',
  },
  rangeItem: {
    marginBottom: 16,
  },
  ketoneLegend: {
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  ketoneItem: {
    width: '50%',
  },
  rangesDescription: {
    marginBottom: 16,
  },
})
