import { gql } from '@apollo/client'

const DELETE_INGREDIENT = gql`
  mutation deleteCustomIngredient($id: ID!) {
    deleteCustomIngredient(id: $id) {
      id
      description
      servingAmount
    }
  }
`

export default DELETE_INGREDIENT
