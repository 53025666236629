import Rate from 'react-native-rate'
import moment from 'moment'
import App from '@config/app'
import Storage from '@utils/storage'

const SENSOR_EXPIRATION_THRESHOLD = 24
const MONTHS_AFTER_LAST_PROMPT_THRESHOLD = 2
const MAX_REVIEW_PROMPTS_COUNT = 3

export const requestInAppReview = () => {
  Rate.rate(
    {
      AppleAppID: App.appleAppID,
      GooglePackageName: App.googlePackageName,
      preferInApp: true,
    },
    (success) => {
      if (success) {
        Storage.set(Storage.LAST_REVIEW_PROMPT_DATE_KEY, moment().toISOString())
      }
    },
  )
}

export const shouldRequestInAppReview = ({
  hasLeftReview,
  reviewPromptsCount,
  lastPromptDate,
  sensorExpirationTime,
}: {
  hasLeftReview: boolean
  reviewPromptsCount: number
  lastPromptDate: string | null
  sensorExpirationTime: string | undefined
}) => {
  if (hasLeftReview) {
    // never ask for review if user already left it
    return false
  }

  if (reviewPromptsCount >= MAX_REVIEW_PROMPTS_COUNT) {
    // don't show review prompt more than 3 times
    return false
  }

  if (!lastPromptDate) {
    // show first review prompt 1 day before sensor expiration
    const hoursUntilSensorExpiration = sensorExpirationTime
      ? moment(sensorExpirationTime).diff(moment(), 'hour')
      : Infinity

    return hoursUntilSensorExpiration <= SENSOR_EXPIRATION_THRESHOLD
  }

  // show review prompt 2 months after last time
  const monthsAfterLastPrompt = moment().diff(moment(lastPromptDate), 'month')
  return monthsAfterLastPrompt >= MONTHS_AFTER_LAST_PROMPT_THRESHOLD
}
