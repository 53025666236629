import { gql } from '@apollo/client'
import { CORE_MEAL_FIELDS } from '@src/graphql/fragments/meal'

const FETCH_MEAL = gql`
  ${CORE_MEAL_FIELDS}
  query meal($id: ID!) {
    meal(id: $id) {
      ...CoreMealFields
    }
  }
`

export default FETCH_MEAL
