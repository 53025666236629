import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'

type BackButtonProps =
  | { canGoBack: false; onBack?: () => void } // can contain onBack (though it won't be used) to simplify logic
  | { canGoBack: true; onBack: () => void }

export type NavigationButtonsProps = BackButtonProps & {
  backTitle?: string
  nextDisabled?: boolean
  nextTitle: string
  onNext: () => void
}

export const NavigationButtons = ({
  canGoBack,
  backTitle = 'Back',
  nextTitle,
  nextDisabled = false,
  onNext,
  onBack = () => 0,
}: NavigationButtonsProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.navigationButtons}>
      {canGoBack && (
        <Button
          type="outline"
          size="block"
          style={styles.backButton}
          accessibilityLabel={backTitle}
          onPress={onBack}
        >
          {backTitle}
        </Button>
      )}
      <Button
        type="primary"
        size="block"
        disabled={nextDisabled}
        style={styles.nextButton}
        accessibilityLabel={nextTitle}
        onPress={onNext}
      >
        {nextTitle}
      </Button>
    </View>
  )
}

const themedStyles = StyleService.create({
  navigationButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 16,
  },
  backButton: {
    flex: 1,
    marginRight: 8,
  },
  nextButton: {
    flex: 2,
  },
})
