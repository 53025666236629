import React from 'react'
import moment from 'moment'
import { Section } from '@src/components'
import { Text } from '@components/base'

interface LastSyncedSectionProps {
  lastSynced: string | null
}

const LastSyncedSection = (props: LastSyncedSectionProps) => {
  const { lastSynced } = props

  return (
    <Section title="Last Sync">
      <Text type="small">
        {moment(lastSynced).isValid() ? moment(lastSynced).format('MM/DD h:mma') : 'None'}
      </Text>
    </Section>
  )
}

export default LastSyncedSection
