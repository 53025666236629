import React from 'react'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@src/components/base'
import { Benefit, ScrollViewWithFade } from '@src/components'
import { AppStackParamList } from '@src/navigation/types'
import { useStartParticipationBottomSheet } from '../hooks/useStartParticipationBottomSheet'

export const ReadyToParticipate = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const showBottomSheet = useStartParticipationBottomSheet({ returnHome: true })
  const insets = useSafeAreaInsets()

  const instructions = [
    // eslint-disable-next-line max-len
    'Please finish using any Libre sensors you have remaining, then follow the prompts in your sensor settings to switch to your new Dexcom G7 sensors.',
    'Once you switch to use your new Dexcom G7 sensors, your app will not support other sensors.',
    'No remaining Libre sensors to use? Tap below to switch today.',
  ]

  const onConfirmPress = () => {
    showBottomSheet()
  }

  const onDeclinePress = () => {
    navigation.replace('Drawer', { screen: 'Dashboard' })
  }

  return (
    <View style={styles.container}>
      <ScrollViewWithFade scrollViewStyle={styles.scrollViewContent}>
        <Icon name="clock" style={styles.icon} />
        <Text type="title-2" style={styles.titleText}>
          Get set to use your new Dexcom G7 sensors
        </Text>
        {instructions.map((instruction) => (
          <Benefit key={instruction} benefit={instruction} />
        ))}
      </ScrollViewWithFade>
      <View style={[styles.buttonsWrapper, { marginBottom: insets.bottom + 16 }]}>
        <Button type="primary" size="block" onPress={onConfirmPress} accessibilityLabel="Confirm">
          Yes, I have used all my Libre sensors.
        </Button>
        <Button
          type="transparent"
          size="block"
          onPress={onDeclinePress}
          accessibilityLabel="Not yet"
          style={styles.secondaryButton}
        >
          Remind me later
        </Button>
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingTop: 72,
  },
  scrollViewContent: {
    paddingHorizontal: 32,
    paddingVertical: 24,
  },
  titleText: {
    textAlign: 'center',
    paddingHorizontal: 24,
    paddingVertical: 56,
  },
  buttonsWrapper: {
    paddingHorizontal: 16,
  },
  secondaryButton: {
    marginTop: 8,
  },
  icon: {
    alignSelf: 'center',
    width: 48,
    height: 48,
    color: 'theme.primary.base',
    borderWidth: 4,
    borderColor: 'theme.background',
    borderRadius: 24,
    backgroundColor: 'theme.accent.moonstone',
    overflow: 'hidden',
  },
})
