import { gql } from '@apollo/client'

const UPDATE_HEALTH_METRIC = gql`
  mutation updateHealthMetric($id: ID!, $value: Float!, $date: ISO8601Date!) {
    updateHealthMetric(id: $id, value: $value, date: $date) {
      id
      value
      kind
      date
    }
  }
`

export default UPDATE_HEALTH_METRIC
