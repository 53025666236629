import { useRef } from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useActionBottomSheet } from '@src/components'
import { useDispatchAsync } from '@src/utils'
import { useSnack } from '@src/utils/navigatorContext'
import { RootStackParamList } from '@src/navigation/types'

interface StartParticipationBottomSheetProps {
  returnHome?: boolean
}

export const useStartParticipationBottomSheet = ({
  returnHome,
}: StartParticipationBottomSheetProps) => {
  const showActionBottomSheet = useActionBottomSheet()
  const dispatchAsync = useDispatchAsync()
  const loadingRef = useRef(false)
  const showSnack = useSnack()
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

  const onActionPress = async () => {
    if (loadingRef.current) {
      return
    }

    try {
      loadingRef.current = true
      await dispatchAsync({
        type: 'app/startDexcomResearchParticipation',
      })

      await dispatchAsync({ type: 'users/fetch' })
      if (returnHome) {
        navigation.replace('Drawer', { screen: 'Dashboard' })
      }
    } catch {
      showSnack('Failed to switch to Dexcom. Please try again.', null, 'warning')
    } finally {
      loadingRef.current = false
    }
  }

  const primaryButton = {
    text: 'Yes, I have used all my Libre sensors',
    onPress: onActionPress,
  }

  const secondaryButton = {
    text: 'No, remind me later',
  }

  const title = "Let's confirm your switch"
  const body =
    'When you complete this switch, your app will not support Libre sensors ' +
    'unless you choose to stop participating in this research program.'

  return () => showActionBottomSheet({ title, body, primaryButton, secondaryButton })
}
