import { gql } from '@apollo/client'

const DELETE_ACTIVITY = gql`
  mutation deleteActivity($id: ID!) {
    deleteActivity(id: $id) {
      id
      type
      title
      startedAt
      endedAt
      status
      length
      description
      occurredAt
      createdAt
    }
  }
`

export default DELETE_ACTIVITY
