import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { GestureDismissibleModal } from '@components'
import { Text, Button } from '@components/base'
import { useGoBack } from '@utils/navigation'

interface GestureDismissibleAlertModalProps {
  title: string
  description: string
  actionTitle: string
  dismissButtonText?: string
  onActionPress: () => void
}

export const GestureDismissibleAlertModal = ({
  title,
  description,
  actionTitle,
  dismissButtonText,
  onActionPress,
}: GestureDismissibleAlertModalProps) => {
  const styles = useStyleSheet(themedStyle)
  const goBack = useGoBack()

  return (
    <GestureDismissibleModal
      containerStyle={styles.modalContainer}
      contentContainerStyle={styles.modalContent}
      onDismiss={goBack}
      dismissButtonText={dismissButtonText}
    >
      <SafeAreaView edges={['bottom']}>
        <Text type="title-2" style={styles.title}>
          {title}
        </Text>
        <Text type="large" style={styles.description}>
          {description}
        </Text>
        <Button
          type="primary"
          size="block"
          accessibilityLabel={actionTitle}
          onPress={onActionPress}
        >
          {actionTitle}
        </Button>
      </SafeAreaView>
    </GestureDismissibleModal>
  )
}

const themedStyle = StyleService.create({
  modalContainer: {
    flex: 0,
    flexBasis: 'auto',
    marginTop: 'auto',
  },
  modalContent: {
    flex: 0,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  title: {
    textAlign: 'center',
  },
  description: {
    marginVertical: 32,
    textAlign: 'center',
  },
})
