import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { SliderWithEdgeLabels } from '@src/components/SliderWithEdgeLabels'
import { Text, IconButton } from '@components/base'
import { SliderInputConfig } from '../../types'
import { InputPropsBase } from './types'
import { useSetDefaultValue } from './Shared/hooks'

interface SliderInputProps {
  kindMetadata: SliderInputConfig['kindMetadata']
  field: Pick<InputPropsBase['field'], 'onChange' | 'value'>
  style?: StyleProp<ViewStyle>
}

export const SliderInput = ({
  kindMetadata,
  field: { onChange, value: fieldValue },
  style,
}: SliderInputProps) => {
  const styles = useStyleSheet(themedStyle)

  const defaultValue = Math.round((kindMetadata.min + kindMetadata.max) / 2)

  const value = fieldValue || defaultValue

  useSetDefaultValue({ fieldValue, onChange, defaultValue })

  const onIncrementButtonPress = () => {
    onChange(value + 1)
  }

  const onDecrementButtonPress = () => {
    onChange(value - 1)
  }

  return (
    <View style={[styles.container, style]}>
      <View style={styles.counterContainer}>
        <IconButton
          type="primary"
          size="m"
          iconName="minus"
          accessibilityLabel="Counter decrement button"
          onPress={onDecrementButtonPress}
          disabled={value === kindMetadata.min}
        />
        <View style={styles.counterDigitsContainer}>
          <Text type="title-1" style={styles.currentValue}>
            {value}
          </Text>
          <Text type="large" bold>
            /{kindMetadata.max}
          </Text>
        </View>
        <IconButton
          type="primary"
          size="m"
          iconName="plus"
          accessibilityLabel="Counter increment button"
          onPress={onIncrementButtonPress}
          disabled={value === kindMetadata.max}
        />
      </View>
      <SliderWithEdgeLabels
        min={kindMetadata.min}
        max={kindMetadata.max}
        step={1}
        snapped
        leftEdgeLabel={kindMetadata.min_label}
        rightEdgeLabel={kindMetadata.max_label}
        values={[value]}
        onValuesChange={([value]) => onChange(value)}
      />
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    alignItems: 'stretch',
  },
  counterContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
    marginBottom: 32,
  },
  counterDigitsContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  currentValue: {
    marginBottom: -5,
  },
})
