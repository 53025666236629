import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useEffect } from 'react'
import { Analytics, CustomEventTypes, Device } from '@src/config'
import { AppStackParamList } from '@src/navigation/types'
import {
  googleFitPermissionDarkImage,
  googleFitPermissionLightImage,
  healthKitPermissionDarkImage,
  healthKitPermissionLightImage,
} from '@src/assets/images'
import { useIsDarkMode } from '@src/config/theme'
import { terraProvidersSelector } from '@src/selectors/integrations'
import { useGoBack } from '@src/utils/navigation'
import { useTerraAppleHealth } from '@src/screens/Settings/utils/hooks'

const GOOGLE_FIT_TERRA_PROVIDER_ID = 'google'

export const useHealthPermissionScreenProps = () => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const terraProviders = useSelector(terraProvidersSelector)
  const initTerraAppleHealthConnection = useTerraAppleHealth()

  useEffect(() => {
    // we need to fetch terra providers on android devices to make sure that the google fit provider is available
    if (Device.android && terraProviders.length === 0) {
      dispatch({
        type: 'settings/fetchTerraProviders',
      })
    }
  }, [dispatch, terraProviders])

  const title = Device.ios ? 'Sync with Apple Health' : 'Sync with Google Fit'
  const allowButtonText = Device.ios ? 'Connect Apple Health' : 'Connect Google Fit'

  const images = Device.ios
    ? { light: healthKitPermissionLightImage, dark: healthKitPermissionDarkImage }
    : { light: googleFitPermissionLightImage, dark: googleFitPermissionDarkImage }

  const isDarkMode = useIsDarkMode()
  const imageSource = isDarkMode ? images.dark.imageSource : images.light.imageSource

  // this function assumes it is called from the last slide in the carousel
  // when we navigate to terra provider screen we replace the current screen
  // and going back from terra provider screen will go back to the next correct screen in the stack
  const onAllowButtonPress = async () => {
    if (Device.ios) {
      await initTerraAppleHealthConnection()

      Analytics.track(CustomEventTypes.OnboardingHealthKitSyncEnabled, {
        source: 'Onboarding Permissions',
      })
      goBack()
    } else {
      navigation.replace('TerraProvider', {
        provider: GOOGLE_FIT_TERRA_PROVIDER_ID,
        source: 'Onboarding Permissions',
      })
    }
  }

  return {
    title,
    imageSource,
    allowButtonText,
    onAllowButtonPress,
  }
}
