import React, { useState, useRef, useMemo, useImperativeHandle } from 'react'
import {
  TextInput,
  TextInputProps,
  View,
  StyleSheet,
  StyleProp,
  ViewStyle,
  TextStyle,
  Platform,
} from 'react-native'
import { StyleService, useStyleSheet } from '@style/service'
import { Icon, IconContext, IconName } from './Icon'
import { Text } from './Text'

const DEFAULT_ICON_SIZE = 24
const LINE_HEIGHT = 22

export type InputRef = TextInput

export type InputProps = TextInputProps & {
  accessoryLeft?: React.ReactNode
  accessoryRight?: React.ReactNode
  caption?: string
  containerStyle?: StyleProp<ViewStyle>
  disabled?: boolean
  errorText?: string
  hasError?: boolean
  iconLeft?: IconName
  iconRight?: IconName
  label?: string
  onChange?: (value: string) => void
  style?: StyleProp<ViewStyle>
}

export const Input = React.forwardRef<InputRef, InputProps>((props, forwardedRef) => {
  const [isFocused, setIsFocused] = useState(false)
  const {
    accessoryLeft,
    accessoryRight,
    caption,
    containerStyle,
    disabled = false,
    errorText,
    hasError = false,
    iconLeft,
    iconRight,
    label,
    onChange,
    onChangeText,
    onFocus,
    onBlur,
    numberOfLines = 1,
    style,
    value,
    ...restProps
  } = props

  const inputRef = useRef<InputRef>(null)
  useImperativeHandle(forwardedRef, () => inputRef.current as InputRef, [inputRef])

  const shouldApplyErrorStyling = !disabled && hasError
  const hasLeftAccessory = !!accessoryLeft || !!iconLeft
  const hasRightAccessory = !!accessoryRight || !!iconRight

  const styles = useStyleSheet(themedStyle)
  const inputContainerStyle = useMemo(() => {
    const result = StyleSheet.flatten<ViewStyle>([
      styles.inputContainer,
      isFocused && styles.focused,
      disabled && styles.disabled,
      shouldApplyErrorStyling && styles.error,
      containerStyle,
    ])

    if (typeof result.paddingHorizontal === 'number') {
      result.paddingHorizontal -= result.borderWidth || 0
    }

    return result
  }, [containerStyle, disabled, isFocused, shouldApplyErrorStyling, styles])

  const inputStyle = StyleSheet.flatten<TextStyle>([
    { height: LINE_HEIGHT * numberOfLines },
    styles.input,
    hasLeftAccessory && styles.padLeft,
    hasRightAccessory && styles.padRight,
  ])

  const placeholderTextColor = (styles.placeholderText as TextStyle).color
  const iconStyle = StyleSheet.flatten<TextStyle>([
    styles.icon,
    !!value && styles.iconFilled,
    disabled && styles.iconDisabled,
  ])
  const captionStyle = [styles.caption, shouldApplyErrorStyling && !!errorText && styles.errorText]
  const captionText = shouldApplyErrorStyling && errorText ? errorText : caption

  return (
    <IconContext.Provider
      value={{
        color: iconStyle.color as string,
        size: DEFAULT_ICON_SIZE,
      }}
    >
      <View style={style}>
        {!!label && (
          <Text type="regular" lineSpacing="none" style={styles.label}>
            {label}
          </Text>
        )}
        <View style={inputContainerStyle}>
          {accessoryLeft || (iconLeft && <Icon name={iconLeft} style={iconStyle} />)}
          <TextInput
            {...restProps}
            editable={!disabled}
            multiline={numberOfLines > 1}
            numberOfLines={numberOfLines}
            placeholderTextColor={placeholderTextColor}
            ref={inputRef}
            style={inputStyle}
            textAlignVertical="top"
            value={value}
            onFocus={(event) => {
              onFocus?.(event)
              setIsFocused(true)
            }}
            onBlur={(event) => {
              onBlur?.(event)
              setIsFocused(false)
            }}
            onChange={onChange}
            onChangeText={(text) => {
              onChange?.(text)
              onChangeText?.(text)
            }}
          />
          {accessoryRight || (iconRight && <Icon name={iconRight} style={iconStyle} />)}
        </View>
        {!!captionText && (
          <Text type="regular" lineSpacing="none" style={captionStyle}>
            {captionText}
          </Text>
        )}
      </View>
    </IconContext.Provider>
  )
})

const themedStyle = StyleService.create({
  inputContainer: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    padding: 12,
    backgroundColor: 'theme.background',
    borderColor: 'theme.surface.base',
    borderWidth: 2,
    borderRadius: 8,
  },
  input: {
    flexShrink: 1,
    flexGrow: 1,
    // Padding top is needed for overwriting the default padding on iOS
    paddingTop: 0,
    // Padding needs to be removed on Android to prevent the text from being cut off
    padding: 0,
    color: 'theme.text.primary',
    fontSize: 17,
    lineHeight: LINE_HEIGHT,
    minHeight: 24,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  focused: {
    borderColor: 'theme.primary.base',
  },
  error: {
    borderColor: 'theme.error.base',
  },
  disabled: {
    backgroundColor: 'theme.disabled.background',
    borderColor: 'theme.disabled.background',
  },
  padLeft: {
    marginLeft: 12,
  },
  padRight: {
    marginRight: 12,
  },
  placeholderText: {
    color: 'theme.text.tertiary',
  },
  label: {
    marginBottom: 8,
  },
  caption: {
    marginTop: 8,
    color: 'theme.text.secondary',
  },
  errorText: {
    color: 'theme.error.base',
  },
  icon: {
    width: 24,
    height: 24,
    flexShrink: 0,
    color: 'theme.text.tertiary',
  },
  iconFilled: {
    color: 'theme.text.primary',
  },
  iconDisabled: {
    color: 'theme.disabled.text',
  },
})
