import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName, Text } from '@components/base'

interface ContentDescriptionProps {
  description: string
  iconName?: IconName
}

export const ContentDescription = ({ description, iconName }: ContentDescriptionProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      {iconName && <Icon name={iconName} style={styles.icon} />}
      <Text type="regular" bold style={styles.description}>
        {description}
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 16,
    paddingBottom: 8,
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: 8,
    color: 'theme.text.secondary',
  },
  description: {
    color: 'theme.text.secondary',
  },
})
