import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'

export const sensorStoreSelector = (state: RootStoreState) => state.sensor

export const sensorSelector = createSelector(sensorStoreSelector, (store) => store.sensor)

export const sensorActivationTimeSelector = createSelector(
  sensorSelector,
  (sensor) => sensor?.activationTime,
)
