import { gql } from '@apollo/client'

export const RECORD_LESSON_PROGRESS = gql`
  mutation recordLessonProgress($lessonId: ID!, $complete: Boolean!) {
    recordLessonProgress(lessonId: $lessonId, complete: $complete) {
      program {
        id
        contentId
        progress {
          complete
        }
      }
      module {
        id
        contentId
        progress {
          complete
        }
      }
      lesson {
        id
        contentId
        progress {
          complete
        }
      }
    }
  }
`
