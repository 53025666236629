import { AppStoreState, Calendar, Login, OnboardingState } from '@src/models/app.types'
import { SurveysConfigKind, User } from '@src/types'
import commonReducers from '@src/models/commonReducers'

export const BREAKING_OUTCOME = 'breaking_outcome'
const reducers = {
  fetchList: commonReducers.fetchList,

  updateAppState: (state: AppStoreState, { payload }: { payload: Partial<AppStoreState> }) => ({
    ...state,
    ...payload,
  }),

  updateLoginState: (state: AppStoreState, { payload }: { payload: Partial<Login> }) => {
    const { login } = state
    return {
      ...state,
      login: {
        ...login,
        ...payload,
      },
    }
  },
  // For some updates, our return value doesn't contain every user field
  // so we destructure user to avoid overwriting loaded user data with nil
  updateUserState: (state: AppStoreState, { payload }: { payload: Partial<User> }) => {
    const { login } = state

    return {
      ...state,
      login: {
        ...login,
        user: {
          ...login.user,
          ...payload,
        },
      },
    }
  },
  updateCalendarState: (state: AppStoreState, { payload }: { payload: Partial<Calendar> }) => {
    const { calendar } = state
    return {
      ...state,
      calendar: {
        ...calendar,
        ...payload,
      },
    }
  },
  updateOnboardingState: (
    state: AppStoreState,
    { payload }: { payload: Partial<OnboardingState> },
  ) => {
    const { onboarding } = state
    return {
      ...state,
      onboarding: {
        ...onboarding,
        ...payload,
      },
    }
  },
  skipSurvey: (state: AppStoreState, { payload }: { payload: SurveysConfigKind }) => ({
    ...state,
    skippedSurveys: { ...(state.skippedSurveys || {}), [payload]: true },
  }),
  skipOnboardingFlow: (state: AppStoreState) => {
    return {
      ...state,
      onboarding: {
        ...state.onboarding,
        skipped: true,
      },
    } as AppStoreState
  },
}

export default reducers
