import { gql } from '@apollo/client'

export const DISMISS_NOTIFICATION = gql`
  mutation dismissNotification($id: ID!) {
    dismissNotification(id: $id) {
      id
      kind
      actionKind
      actionButtonLabel
      content
      payload
      deliveredAt
      canceledAt
      dismissedAt
      completedAt
      createdAt
      updatedAt
    }
  }
`
