import React, { useContext } from 'react'

export const ImpersonationContext = React.createContext({
  isImpersonating: false,
})

export const useImpersonationContext = () => {
  const contextValue = useContext(ImpersonationContext)
  return contextValue.isImpersonating
}
