import { useSelector } from 'react-redux'
import { eventsNutritionSelector } from '@src/selectors/events'
import { showNetCarbsStoreStateSelector, macroGoalsDailySelector } from '@src/selectors/settings'
import { extractData } from '@src/components/nutritionCard/utils'
import { Macros } from '@src/components/nutritionCard/types'

export const useMacroData = () => {
  const showNetCarbs = useSelector(showNetCarbsStoreStateSelector)
  const rawNutrition = useSelector(eventsNutritionSelector)
  const rawGoals = useSelector(macroGoalsDailySelector)

  const { values, goals } = extractData(rawNutrition, rawGoals)
  const hasData = values.calories || values.carbs || values.protein || values.fat

  const macros = Object.keys(values) as Array<keyof Macros>
  const percents = macros.reduce(
    (acc, macro) => ({
      ...acc,
      [macro]: values[macro] / goals[macro],
    }),
    {},
  ) as Macros

  return {
    values,
    percents,
    goals,
    hasData,
    showNetCarbs,
  }
}
