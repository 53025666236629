import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService } from '@src/style/service'
import { Section, CommonSelect } from '@components'
import { darkModeStoreStateSelector } from '@src/selectors/settings'

const darkModeOptions = [
  { value: 'off', text: 'Off' },
  { value: 'on', text: 'On' },
  { value: 'auto', text: 'Auto' },
]

const DarkModeSetting = () => {
  const darkMode = useSelector(darkModeStoreStateSelector)

  const dispatch = useDispatch()

  const title = 'Dark Mode'

  const onSelect = (option: { value: number | string }) => {
    if (darkMode === option.value) {
      return
    }

    dispatch({
      type: 'settings/updateSettings',
      payload: { darkMode: option.value },
    })
  }

  return (
    <Section title={title}>
      <CommonSelect
        style={styles.selectContainer}
        options={darkModeOptions}
        selectedOption={darkModeOptions.find((option) => option.value === darkMode)}
        onSelect={onSelect}
        accessibilityLabel={title}
        size="small"
      />
    </Section>
  )
}

const styles = StyleService.create({
  selectContainer: {
    width: 180,
  },
})

export default DarkModeSetting
