import { gql } from '@apollo/client'
import { CORE_SENSOR_FIELDS } from '@src/graphql/fragments/sensor'

export const UPDATE_SENSOR_STATUS = gql`
  ${CORE_SENSOR_FIELDS}
  mutation updateSensorStatus($status: SensorStatus!, $serialNumber: String!) {
    updateSensorStatus(status: $status, serialNumber: $serialNumber) {
      ...CoreSensorFields
    }
  }
`
