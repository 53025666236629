import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'
import {
  Image,
  LayoutChangeEvent,
  NativeScrollEvent,
  ScrollView,
  useWindowDimensions,
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Feature, TimelineChart, useFeatureFlag } from '@components'
import { DashboardGraph, ThresholdType } from '@screens/Settings/models/settings.types'
import { ChartTypes, PLACEHOLDER_TEXT_BY_CHART_TYPE } from '@src/components/Timeline/constants'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'
import { eventsChartsSelector } from '@src/selectors/events'
import { settingsSelector } from '@src/selectors/settings'
import { Analytics, CustomEventTypes, Device } from '@src/config'
import { useLiveGlucoseValue } from '@src/utils/hooks'
import { MobileAppFeature } from '@src/types'
import { User } from '@src/utils'
import { blurredGlucoseChartImage } from '@assets/images'
import { useIsDarkMode } from '@src/config/theme'
import { ChartValue } from '../models/events.types'
import { TimelineHeader } from './TimelineHeader'
import { UpsellCGMBanner } from './UpsellCGMBanner'

export interface TimelineProps {
  primaryChartRef: MutableRefObject<any>
  secondaryChartRef: MutableRefObject<any>
  onChartLoadEnd: () => void
  onChartHover: (payload: any) => void
  multiSliderValuesChange: (values: [number, number]) => void
}

const CHART_WIDTH_HEIGHT_RATIO = 1.5
const MAX_CHART_HEIGHT = 330

export const Timeline = React.memo((props: TimelineProps) => {
  const {
    primaryChartRef,
    secondaryChartRef,
    onChartLoadEnd,
    onChartHover,
    multiSliderValuesChange,
  } = props

  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const scrollRef = useRef<any>()
  const [selectedChartIndex, setSelectedChartIndex] = useState(0)
  const { primary, secondary } = useSelector(eventsChartsSelector)
  const { dashboardGraph } = useSelector(settingsSelector)
  const { realTimeGlucoseValue, realTimeGlucoseTime } = useLiveGlucoseValue()
  const libre3RealTimeGlucoseChart = useFeatureFlag(Feature.Libre3RealTimeGlucoseChart)
  const isDarkMode = useIsDarkMode()

  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  if (libre3RealTimeGlucoseChart) {
    // filter out any existing live glucose values
    primary.values = primary.values.filter((point: ChartValue) => point.tag !== 'LiveGlucose')

    if (realTimeGlucoseValue && realTimeGlucoseTime) {
      const realTimeChartDataPoint = {
        tag: 'LiveGlucose',
        x: moment(realTimeGlucoseTime),
        y: realTimeGlucoseValue,
        fake: false,
      } as ChartValue

      // add the latest live glucose value
      primary.values.push(realTimeChartDataPoint)
    }
  }

  const dimensions = useWindowDimensions()
  const isScanDisabled = useDisableInAppScan()
  const [contentWidth, setContentWidth] = useState(dimensions.width)

  const onNavigateToCard = (index: number, contentWidth: number) => {
    if (!scrollRef.current) {
      return
    }

    scrollRef.current.scrollTo({ x: contentWidth * index, y: 0, animated: true })
  }

  const handleSelectChartIndex = (index: number) => {
    onNavigateToCard(index, contentWidth)
  }

  const onLayout = ({ nativeEvent }: LayoutChangeEvent) => {
    const newContentWidth = nativeEvent.layout.width
    if (!newContentWidth) {
      return
    }

    if (newContentWidth !== contentWidth) {
      onNavigateToCard(selectedChartIndex, newContentWidth)
    }
    setContentWidth(newContentWidth)
  }

  const onScroll = ({ nativeEvent }: { nativeEvent: NativeScrollEvent }) => {
    const index = Math.round(nativeEvent.contentOffset.x / contentWidth)
    if (index !== selectedChartIndex) {
      Analytics.track(CustomEventTypes.EventsSelectChart, {
        type: index === 0 ? 'glucose' : 'ketone',
      })
      setSelectedChartIndex(index)
    }
  }

  const onZoomChart = () => {
    const type = selectedChartIndex === 0 ? ChartTypes.Glucose : ChartTypes.Ketones
    navigation.navigate('ZoomChart', {
      type,
      forceLandscapeOrientation: !Device.hasLargeScreen(dimensions),
    })
  }

  const onInfoPress = () => {
    navigation.navigate('TimelineTutorialModal', {
      timeline: selectedChartIndex === 0 ? 'Glucose' : 'Ketone',
    })
  }

  const onSettingsPress = () => {
    if (selectedChartIndex === 0) {
      navigation.navigate('GlucoseThresholdSettings', { source: 'Events' })
    } else {
      navigation.navigate('ThresholdSettings', { type: ThresholdType.Ketone, source: 'Events' })
    }
  }

  const isKetoneChartIncluded = dashboardGraph === DashboardGraph.Combined

  useEffect(() => {
    if (!isKetoneChartIncluded) {
      setSelectedChartIndex(0)
      onNavigateToCard(0, contentWidth)
    }
  }, [isKetoneChartIncluded, setSelectedChartIndex, contentWidth])

  const chartItems = useMemo(
    () => (isKetoneChartIncluded ? ['Glucose Chart', 'Ketone Chart'] : ['Glucose Chart']),
    [isKetoneChartIncluded],
  )

  const chartStyle = {
    width: contentWidth,
  }

  const height = Math.min(contentWidth / CHART_WIDTH_HEIGHT_RATIO, MAX_CHART_HEIGHT)

  if (!isCGMFeatureAvailable) {
    return (
      <UpsellCGMBanner
        image={
          <Image
            style={{ height, width: contentWidth }}
            source={blurredGlucoseChartImage(isDarkMode).imageSource}
          />
        }
        header={
          <TimelineHeader
            title={chartItems[selectedChartIndex]}
            charts={chartItems}
            testID="Timeline"
            selectedChartIndex={selectedChartIndex}
            onSelectChartIndex={handleSelectChartIndex}
            onExpandPress={onZoomChart}
            onInfoPress={onInfoPress}
            onSettingsPress={onSettingsPress}
          />
        }
      />
    )
  }

  return (
    <>
      <TimelineHeader
        title={chartItems[selectedChartIndex]}
        charts={chartItems}
        testID="Timeline"
        selectedChartIndex={selectedChartIndex}
        onSelectChartIndex={handleSelectChartIndex}
        onExpandPress={onZoomChart}
        onInfoPress={onInfoPress}
        onSettingsPress={onSettingsPress}
      />
      <ScrollView
        ref={scrollRef}
        horizontal
        pagingEnabled
        scrollEnabled={false}
        bounces={false}
        showsHorizontalScrollIndicator={false}
        onScroll={onScroll}
        onLayout={onLayout}
        scrollEventThrottle={0}
        style={styles.scrollView}
        contentContainerStyle={{ height }}
      >
        <TimelineChart
          chartRef={primaryChartRef}
          style={chartStyle}
          data={primary}
          type={ChartTypes.Glucose}
          onChange={onChartHover}
          onLoadEnd={onChartLoadEnd}
          multiSliderValuesChange={multiSliderValuesChange}
          placeholder={isScanDisabled ? null : PLACEHOLDER_TEXT_BY_CHART_TYPE[ChartTypes.Glucose]}
        />
        {isKetoneChartIncluded && (
          <TimelineChart
            chartRef={secondaryChartRef}
            style={chartStyle}
            data={secondary}
            type={ChartTypes.Ketones}
            onChange={onChartHover}
            onLoadEnd={onChartLoadEnd}
            placeholder={PLACEHOLDER_TEXT_BY_CHART_TYPE[ChartTypes.Ketones]}
            showPoints
            connectNulls
          />
        )}
      </ScrollView>
    </>
  )
})

const themedStyles = StyleService.create({
  scrollView: {
    flexGrow: 0,
  },
})
