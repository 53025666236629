import React, { useState } from 'react'
import { Keyboard, ScrollView, View } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useRoute, RouteProp } from '@react-navigation/native'
import { useDispatch } from 'react-redux'
import pluralize from 'pluralize'
import { capitalize, mapValues } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NS } from '@assets/svgs'
import { GestureDismissibleModal, LoadingFastImage } from '@components'
import { Text, Button, Icon } from '@components/base'
import { AppStackParamList } from '@navigation/types'
import { IngredientPortionForm, NutritionFacts } from '@screens/Ingredients'
import { useGoBack } from '@utils/navigation'
import { useSnack } from '@utils/navigatorContext'
import { useAvailableScreenHeight } from '@src/utils/hooks'

export const IngredientPortion = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const showSnack = useSnack()
  const availableHeight = useAvailableScreenHeight()

  const { params } = useRoute<RouteProp<AppStackParamList, 'IngredientPortion'>>()
  const { ingredient, isUpdatingIngredient } = params

  const [quantityText, setQuantityText] = useState(`${ingredient.servingAmount || 1}`)
  const [servingName, setServingName] = useState(pluralize.singular(ingredient.servingUnits || ''))
  const [isNutritionVisible, setNutritionVisible] = useState(false)

  const quantity = Number.parseFloat(quantityText) || 0
  const selectedServing =
    ingredient.servingOptions.find(
      ({ name }) => name.toLowerCase() === servingName.toLowerCase(),
    ) || ingredient.servingOptions[0]
  const nutrition = mapValues(selectedServing?.nutritionPerServing, (value) => value * quantity)

  const onAddIngredient = () => {
    dispatch({
      type: isUpdatingIngredient ? 'meals/updateDraftIngredient' : 'meals/addDraftIngredient',
      payload: {
        ...ingredient,
        calories: nutrition.calories,
        // Prevent automatic update of ingredient serving/quantity when the meal description is updated
        isFromSuggestions: false,
        nutrition,
        servingAmount: quantity,
        servingUnits: selectedServing.name,
      },
    })
    if (!isUpdatingIngredient) {
      showSnack('Ingredient added to meal', null, 'success')
    }
    goBack()
  }

  const image = ingredient.imageURL ? (
    <LoadingFastImage source={{ uri: ingredient.imageURL }} style={styles.image as ImageStyle} />
  ) : (
    <NS width={56} height={56} />
  )

  const insets = useSafeAreaInsets()

  const onSeeNutritionFactsPress = () => {
    Keyboard.dismiss()
    setNutritionVisible(true)
  }

  return (
    <GestureDismissibleModal
      containerStyle={styles.modalContainer}
      contentContainerStyle={[styles.modalContent, { paddingBottom: Math.max(insets.bottom, 16) }]}
      onDismiss={goBack}
      dismissButtonText="Cancel"
    >
      <View style={styles.header}>
        {image}
        <Text type="title-2" numberOfLines={2} style={styles.title}>
          {ingredient.description}
        </Text>
        <Text type="regular" bold style={styles.calories}>
          {`${Number(nutrition.calories.toFixed(2))} cal`}
        </Text>
      </View>
      <IngredientPortionForm
        autoFocus
        serving={selectedServing.name}
        servingOptions={ingredient.servingOptions.map(({ name }) => capitalize(name)).sort()}
        style={styles.portionDetails}
        quantity={quantityText}
        onServingChange={setServingName}
        onQuantityChange={setQuantityText}
      />
      {isNutritionVisible ? (
        <View style={styles.nutritionFacts}>
          <Text type="small" bold style={styles.nutritionTitle}>
            Nutrition Facts
          </Text>
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ maxHeight: availableHeight - 310 }}
          >
            <NutritionFacts hideMicroNutrients nutrition={nutrition} />
          </ScrollView>
        </View>
      ) : (
        <Button
          type="transparent"
          size="small"
          accessibilityLabel="See Nutrition Facts"
          onPress={onSeeNutritionFactsPress}
          style={styles.toggleNutritionButton}
          accessoryRight={<Icon name="arrow-right" />}
        >
          See Nutrition Facts
        </Button>
      )}
      <Button
        type="primary"
        size="block"
        disabled={!quantity || !servingName}
        accessibilityLabel={isUpdatingIngredient ? 'Save Ingredient' : 'Add Ingredient'}
        onPress={onAddIngredient}
      >
        {isUpdatingIngredient ? 'Save' : 'Add'}
      </Button>
    </GestureDismissibleModal>
  )
}

const themedStyle = StyleService.create({
  modalContainer: {
    flex: 0,
    flexBasis: 'auto',
    marginTop: 'auto',
  },
  modalContent: {
    flex: 0,
    paddingHorizontal: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  image: {
    height: 56,
    width: 56,
  },
  title: {
    flex: 1,
    marginLeft: 16,
    marginRight: 12,
  },
  calories: {
    color: 'theme.text.secondary',
  },
  portionDetails: {
    marginTop: 24,
    marginBottom: 16,
  },
  nutritionFacts: {
    marginBottom: 16,
  },
  nutritionTitle: {
    marginVertical: 8,
  },
  toggleNutritionButton: {
    marginRight: 'auto',
    marginBottom: 16,
  },
})
