import { CommonActions, useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useContext, useEffect, useState } from 'react'
import { RootStackParamList } from '@src/navigation/types'
import { Scene, SceneContext } from '@src/config/scene'

export const useCloseSurvey = () => {
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()

  const [shouldNavigateToDashboard, setShouldNavigateToDashboard] = useState(false)

  const { scene } = useContext(SceneContext)

  useEffect(() => {
    if (shouldNavigateToDashboard && scene === Scene.AppAccess) {
      navigation.replace('Drawer', { screen: 'Dashboard' })
    }
  }, [navigation, scene, shouldNavigateToDashboard])

  return ({
    routesToRemoveFromStack = ['Questionnaire'],
    navigateToDashboard = false,
  }: {
    routesToRemoveFromStack?: string[]
    navigateToDashboard?: boolean
  } = {}) => {
    navigation.dispatch((state) => {
      let routes = state.routes.filter((route) => !routesToRemoveFromStack.includes(route.name))

      if (routes.length === 0) {
        routes = state.routes
        setShouldNavigateToDashboard(navigateToDashboard)
      }

      return CommonActions.reset({
        ...state,
        routes,
        index: routes.length - 1,
      })
    })
  }
}
