import { gql } from '@apollo/client'
import { CORE_MEAL_FIELDS } from '@src/graphql/fragments/meal'

const CREATE_MEAL = gql`
  ${CORE_MEAL_FIELDS}
  mutation createMeal(
    $id: ID
    $kind: MealKind!
    $photo: Upload
    $favorite: Boolean
    $description: String!
    $time: String!
    $ingredients: [IngredientData!]
    $imageUrl: String
  ) {
    createMeal(
      id: $id
      kind: $kind
      photo: $photo
      favorite: $favorite
      description: $description
      time: $time
      ingredients: $ingredients
      imageUrl: $imageUrl
    ) {
      ...CoreMealFields
    }
  }
`

export default CREATE_MEAL
