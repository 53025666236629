import React from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { View } from 'react-native'
import { HealthMetricsParam } from '@src/types'
import { Input, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { validateFloatNumberInput } from '@src/utils/validators'
import { isInputValueValid } from '../utils'

interface LabResultsFormInputProps {
  control: Control<FieldValues, any>
  label: string
  metricKey: HealthMetricsParam
  unit: string | undefined
}

export const LabResultsFormInput = (props: LabResultsFormInputProps) => {
  const { control, label, metricKey, unit } = props
  const styles = useStyleSheet(themedStyle)

  if (metricKey === HealthMetricsParam.BloodPressure) {
    return (
      <Controller
        control={control}
        name={HealthMetricsParam.BloodPressure}
        key={metricKey}
        rules={{
          validate: (value) => {
            return (
              (isInputValueValid(value?.systolic) && isInputValueValid(value?.diastolic)) ||
              (!isInputValueValid(value?.systolic) && !isInputValueValid(value?.diastolic))
            )
          },
        }}
        render={({ field, fieldState }) => (
          <>
            <View style={styles.inputGroup}>
              <Text type="regular" lineSpacing="tight" style={styles.input}>
                Blood Pressure Systolic
              </Text>
              <Input
                {...field}
                key="systolic"
                accessoryRight={<Text type="regular">{unit}</Text>}
                errorText={fieldState.error?.message}
                hasError={fieldState.invalid}
                style={styles.input}
                keyboardType="decimal-pad"
                value={field.value?.systolic}
                onChangeText={(text) => {
                  const value = validateFloatNumberInput(text)
                  field.onChange({ ...field.value, systolic: value })
                }}
              />
            </View>
            <View style={styles.inputGroup}>
              <Text type="regular" lineSpacing="tight" style={styles.input}>
                Blood Pressure Diastolic
              </Text>
              <Input
                {...field}
                key="diastolic"
                accessoryRight={<Text type="regular">{unit}</Text>}
                errorText={fieldState.error?.message}
                hasError={fieldState.invalid}
                style={styles.input}
                keyboardType="decimal-pad"
                value={field.value?.diastolic}
                onChangeText={(text) => {
                  const value = validateFloatNumberInput(text)
                  field.onChange({ ...field.value, diastolic: value })
                }}
              />
            </View>
          </>
        )}
      />
    )
  }

  return (
    <View key={`${metricKey}-view`} style={styles.inputGroup}>
      <Text key={`${metricKey}-label`} type="regular" lineSpacing="tight" style={styles.input}>
        {label}
      </Text>
      <Controller
        control={control}
        key={metricKey}
        name={metricKey}
        render={({ field }) => (
          <Input
            {...field}
            key={metricKey}
            accessoryRight={<Text type="regular">{unit}</Text>}
            style={styles.input}
            keyboardType="decimal-pad"
            value={field.value}
            onChangeText={(text) => {
              const value = validateFloatNumberInput(text)
              field.onChange(value)
            }}
          />
        )}
      />
    </View>
  )
}

const themedStyle = StyleService.create({
  inputGroup: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    marginTop: 12,
    flex: 1,
  },
})
