import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { groupBy, uniqueId } from 'lodash'
import Orientation from 'react-native-orientation-locker'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Debug, Device } from '@config'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'
import { eventsObjectsSelector, eventsCalendarSelector } from '@src/selectors/events'
import { useHasLargeScreen, useShouldLockToPortrait } from '@src/config/device'
import { Intercom, useDispatchAsync } from '@utils'
import { Feature, NavigationBarAccessory, useFeatureFlag } from '@components'
import { NotificationBanner } from '@src/components/notifications/NotificationBanner'
import { NotificationEngineScreen } from '@src/types'
import { dexcomConnectionSelector } from '@src/selectors/app'
import { IconButton } from '@components/base'
import { AccountAvatarNavigationBar } from '@src/components/navigationBar/AccountAvatarNavigationBar'
import { Events as EventsComponent } from '../components/Events'
import { GroupedEvent } from '../models/events.types'
import { useChartInteractions } from '../utils/useChartInteractions'
import { MessageIcon } from './MessageIcon'
import { ChatbotIcon } from './ChatbotIcon'

export const PLUS_MENU_BOTTOM_MARGIN = 14

export const EventsContainer = () => {
  const navigation = useNavigation()
  const dispatchAsync = useDispatchAsync()
  const styles = useStyleSheet(themedStyle)
  const isDataLoading = useRef(false)
  const insets = useSafeAreaInsets()

  const dexcomConnection = useSelector(dexcomConnectionSelector)

  const { startDate } = useSelector(eventsCalendarSelector)
  const events = useSelector(eventsObjectsSelector)

  const [multiSliderValues, setMultiSliderValues] = useState<[number, number]>()

  const filteredEvents = useMemo(() => {
    if (!multiSliderValues) {
      return events
    }

    const sliderStart = moment.unix(multiSliderValues[0])
    const sliderEnd = moment.unix(multiSliderValues[1])
    const selectedEvents = events.filter((event) =>
      moment(event.occurredAt).isBetween(sliderStart, sliderEnd, undefined, '[]'),
    )

    return selectedEvents
  }, [events, multiSliderValues])

  const groupedEvents: GroupedEvent[] = useMemo(() => {
    const dateGroups = groupBy(filteredEvents, (i) => moment(i.occurredAt).format(DATE_FORMAT))
    return Object.entries(dateGroups).map(([date, data], index) => ({
      id: uniqueId(date),
      index,
      title: moment(date).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT),
      occurredAt: moment(date),
      data,
    }))
  }, [filteredEvents])

  const {
    listRef,
    primaryChartRef,
    secondaryChartRef,
    highlightedIndex,
    onChartLoadEnd,
    onChartHover,
    onEventListViewableItemsChanged,
    onEventListDragStart,
    onEventListMomentumScrollEnd,
  } = useChartInteractions(groupedEvents)

  const shouldLockToPortrait = useShouldLockToPortrait()

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      console.log('Events:onFocus')
      if (shouldLockToPortrait) {
        Orientation.lockToPortrait()
      }
    })
    return unsubscribe
  }, [navigation, shouldLockToPortrait])

  const refreshData = useCallback(
    async (useCache: boolean) => {
      if (isDataLoading.current) {
        return
      }

      isDataLoading.current = true

      try {
        await Promise.all([
          dispatchAsync({
            type: 'events/fetchNutrition',
            useCache,
          }),
          dispatchAsync({
            type: 'events/fetchCharts',
            useCache,
          }),
        ])
      } finally {
        isDataLoading.current = false
      }
    },
    [dispatchAsync],
  )

  const shouldRefreshWithoutCacheOnFocus =
    moment(startDate).isSame(moment().format(DATE_FORMAT)) && !!dexcomConnection

  const loadDataOnFocus = useCallback(() => {
    refreshData(!shouldRefreshWithoutCacheOnFocus)
  }, [refreshData, shouldRefreshWithoutCacheOnFocus])

  useFocusEffect(loadDataOnFocus)

  const hasLargeScreen = useHasLargeScreen()
  const rightAccessories: NavigationBarAccessory[] = []

  if (useFeatureFlag(Feature.Chatbot) && !Device.web) {
    rightAccessories.push({
      renderIconComponent: () => <ChatbotIcon />,
      onPress: () => navigation.navigate('Chat'),
      accessibilityLabel: 'Chat',
    })
  }

  const showMessages = Debug.shouldEnableIntercom()
  if (showMessages) {
    rightAccessories.push({
      renderIconComponent: () => <MessageIcon />,
      onPress: () => Intercom.showIntercomMessenger({ source: 'Events' }),
      accessibilityLabel: 'Message',
    })
  }

  return (
    <AccountAvatarNavigationBar
      rightAccessories={rightAccessories}
      style={styles.container}
      hideLeftIcon={hasLargeScreen}
      navigationBarProps={{ dateSelectorType: 'events', backgroundColor: 'transparent' }}
    >
      <NotificationBanner screen={NotificationEngineScreen.Home} calendarDate={startDate} />
      <EventsComponent
        listRef={listRef}
        primaryChartRef={primaryChartRef}
        secondaryChartRef={secondaryChartRef}
        events={groupedEvents}
        highlightedIndex={highlightedIndex}
        onChartHover={onChartHover}
        onChartLoadEnd={onChartLoadEnd}
        onDataLoadStart={refreshData}
        multiSliderValuesChange={setMultiSliderValues}
        onViewableItemsChanged={onEventListViewableItemsChanged}
        onDragStart={onEventListDragStart}
        onMomentumScrollEnd={onEventListMomentumScrollEnd}
      />
      <IconButton
        accessibilityLabel="Open Menu"
        iconName="plus"
        size="l"
        style={[
          styles.toggle,
          { bottom: hasLargeScreen && insets.bottom ? insets.bottom : PLUS_MENU_BOTTOM_MARGIN },
        ]}
        type="primary"
        onPress={() => navigation.navigate('ShowActions')}
      />
    </AccountAvatarNavigationBar>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  toggle: {
    position: 'absolute',
    right: 20,
  },
})
