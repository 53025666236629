import React from 'react'
import { StyleProp, TouchableOpacity, View, ViewStyle, ActivityIndicator } from 'react-native'
import { isNumber } from 'lodash'
import FastImage, { ImageStyle } from 'react-native-fast-image'
import { SvgProps } from 'react-native-svg'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconTooltip, Score } from '@components'
import { IconDescriptor } from '@src/components/Icon/IconDescriptor'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { Text } from '@components/base'
import { Menu, MenuInfoItem } from './Menu'
import { ListTitle } from './ListTitle'

export interface ListItemWithScoreProps {
  title: string
  subtitle?: string
  tag?: string | null
  tagStatus?: string
  info?: string
  highlighted?: boolean
  menuItems?: MenuInfoItem[]
  isMenuEnabled?: boolean
  imageUri?: string
  SecondaryIcon?: ((props: SvgProps) => JSX.Element) | null
  placeholderImageUri?: string
  iconDescriptor?: IconDescriptor
  onItemPress?: () => void
  style?: StyleProp<ViewStyle>
  score?: number
  numberOfLines?: number
  warningTooltipMessage?: string
}

const IMAGE_SIZE = 56
const CONTAINER_PADDING_VERTICAL = 12

export const ITEM_HEIGHT = IMAGE_SIZE + CONTAINER_PADDING_VERTICAL * 2

export const ListItemWithScore = (props: ListItemWithScoreProps) => {
  const styles = useStyleSheet(themedStyle)
  const {
    title,
    subtitle,
    tag,
    tagStatus,
    info,
    highlighted = false,
    isMenuEnabled = true,
    menuItems,
    imageUri,
    SecondaryIcon,
    placeholderImageUri,
    iconDescriptor,
    onItemPress,
    style,
    score,
    numberOfLines,
    warningTooltipMessage,
  } = props

  const placeholderComponent = placeholderImageUri ? (
    <FastImage style={styles.image as ImageStyle} source={{ uri: placeholderImageUri }} />
  ) : (
    <ActivityIndicator animating size="small" {...styles.spinner} />
  )

  const imageContent = imageUri && (
    <ImageWithPlaceholder
      style={styles.image as ImageStyle}
      source={{ uri: imageUri }}
      placeholderComponent={placeholderComponent}
    />
  )

  return (
    <TouchableOpacity
      accessible={false}
      style={[styles.card, highlighted && styles.highlightedCard, style]}
      activeOpacity={0.7}
      disabled={!onItemPress}
      onPress={onItemPress}
      accessibilityLabel={title}
    >
      {imageContent && (
        <View testID="ListCard/Image" style={styles.imageContainer}>
          {imageContent}
        </View>
      )}
      {!imageContent && iconDescriptor?.name && (
        <View style={styles.imageContainer}>
          <Icon iconDescriptor={iconDescriptor} style={styles.icon} solid />
          {SecondaryIcon && <SecondaryIcon style={styles.secondaryIcon} />}
        </View>
      )}
      <View style={styles.contentContainer}>
        <View style={styles.content}>
          <ListTitle title={title} info={info} />
          {!!subtitle && (
            <Text
              type="regular"
              testID="ListCard/Subtitle"
              numberOfLines={numberOfLines}
              style={styles.subtitle}
            >
              {subtitle}
            </Text>
          )}
        </View>
        {isNumber(score) && <Score number={score} />}

        {warningTooltipMessage && (
          <View style={styles.warningContainer}>
            <IconTooltip
              iconName="warning-circle"
              message={warningTooltipMessage}
              accessibilityLabel="Meal Warning"
            />
          </View>
        )}
        {isMenuEnabled && <Menu menuItems={menuItems} menuEnabled={isMenuEnabled} />}
      </View>
      {!!tag && (
        <View
          style={[styles.tagContainer, tagStatus === 'secondary' && styles.tagContainerSecondary]}
        >
          <Text
            type="tiny"
            bold
            lineSpacing="none"
            style={[styles.tagText, tagStatus === 'secondary' && styles.tagTextSecondary]}
          >
            {tag}
          </Text>
        </View>
      )}
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  card: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
    paddingHorizontal: 16,
  },
  highlightedCard: {
    borderColor: 'theme.secondary.dark',
  },
  imageContainer: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 16,
    borderRadius: IMAGE_SIZE / 2,
    backgroundColor: 'theme.background.event',
  },
  icon: {
    height: 16,
    width: 16,
    tintColor: 'theme.text.primary',
  },
  secondaryIcon: {
    width: 20,
    height: 20,
    position: 'absolute',
    bottom: -5,
    right: -5,
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderColor: 'theme.surface.base2',
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
  },
  subtitle: {
    color: 'theme.text.secondary',
  },
  image: {
    height: IMAGE_SIZE,
    width: IMAGE_SIZE,
    borderRadius: IMAGE_SIZE / 2,
  },
  spinner: {
    color: 'theme.text.secondary',
  },
  tagContainer: {
    position: 'absolute',
    top: 4,
    right: 20,
    borderRadius: 4,
    backgroundColor: 'theme.success.lightest',
  },
  tagContainerSecondary: {
    backgroundColor: 'theme.info.lightest',
  },
  tagText: {
    padding: 4,
    textTransform: 'uppercase',
    color: 'theme.success.darkest',
  },
  tagTextSecondary: {
    color: 'theme.info.darkest',
  },
  warningContainer: {
    marginRight: 4,
  },
})
