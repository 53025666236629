import React, { ReactNode } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'

export interface PageDotsProps {
  dots: ReactNode[]
  dotStyle: StyleProp<ViewStyle>
  selectedIndex: number
}

export const PageDots = ({ dots, dotStyle, selectedIndex }: PageDotsProps) => {
  const styles = useStyleSheet(themedStyles)
  const style = dotStyle || styles.dot
  return dots.length > 1 ? (
    <View style={styles.pageControl} pointerEvents="none">
      {dots.map((_, index) => (
        <View key={index} style={[style, index === selectedIndex ? styles.activeDot : {}]} />
      ))}
    </View>
  ) : null
}

const themedStyles = StyleService.create({
  pageControl: {
    marginTop: 'auto',
    marginBottom: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeDot: {
    backgroundColor: 'theme.primary.base',
  },
  dot: {
    backgroundColor: 'theme.background',
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 3,
  },
})
