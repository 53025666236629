import React from 'react'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ScoreBreakdown as ScoreBreakdownBase } from '@src/components/ScoreBreakdown'
import { ScoreSectionEnum } from '@src/screens/Insights/utils/pageConfig'
import { SCORES_TITLES } from '@src/screens/Insights/constants'
import { statsSelector } from '@src/screens/Insights/models/insights.selectors'

interface ScoreBreakdownProps {
  score: ScoreSectionEnum
}

export const ScoreBreakdown = ({ score }: ScoreBreakdownProps) => {
  const styles = useStyleSheet(themedStyle)
  const stats = useSelector(statsSelector)
  const scoreTitles = SCORES_TITLES[score]

  const values = stats
    .filter((item) => scoreTitles[item.meta.key])
    .map(({ description, value, meta: { key } }) => ({
      key,
      title: scoreTitles[key],
      description,
      value,
    }))

  return <ScoreBreakdownBase values={values} style={styles.container} />
}

const themedStyle = StyleService.create({
  container: {
    paddingHorizontal: 16,
    marginBottom: 8,
  },
})
