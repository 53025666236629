import { gql } from '@apollo/client'

const FETCH_SUBSCRIPTION_SCHEDULES = gql`
  query allSubscriptionSchedules {
    allSubscriptionSchedules {
      schedules {
        id
        currentPhaseStartAt
        currentPhaseEndAt
        canceledAt
        completedAt
        releasedAt
        createdAt
        updatedAt
        status
        phases {
          id
          description
          startAt
          endAt
          createdAt
          updatedAt
          billingCycleAnchorChange
          price
          primaryProduct {
            id
            title
            interval {
              key
              description
              suffix
            }
          }
        }
      }
    }
  }
`

export default FETCH_SUBSCRIPTION_SCHEDULES
