import React, { forwardRef } from 'react'
import { useRoute } from '@react-navigation/native'
import { AppRouteProp } from '@src/navigation/types'

export const useForceLandscapeOrientation = () => {
  const route = useRoute<AppRouteProp<'ZoomChart' | 'ShowHorizontalChart'>>()
  const params = route.params || {}
  const { forceLandscapeOrientation = false } = params

  return forceLandscapeOrientation
}

export const withLandscapeOrientation = (Component: any) => {
  return forwardRef((props: any, ref) => {
    const isLandscape = useForceLandscapeOrientation()
    return <Component ref={ref} isLandscape={isLandscape} {...props} />
  })
}
