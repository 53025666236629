import React, { useContext, useEffect, useRef, useState } from 'react'
import { AppState, AppStateStatus, Linking, View } from 'react-native'
import { PERMISSIONS, RESULTS } from 'react-native-permissions'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { InlineAlert, BottomSheet } from '@components'
import { useGoBack } from '@src/utils/navigation'
import { NsBluetooth, NsLocation, NsNearbyDevices } from '@src/assets/svgs/permissions'
import {
  ALL_BLUETOOTH_PERMISSIONS,
  areBluetoothPermissionsGranted,
  checkBluetoothPermissions,
  checkPermissionStatus,
  requestBluetoothPermissions,
} from '@src/utils/bluetooth'
import { ScanningContext } from '@src/context/scanningContext'
import { UiInlineAlert } from '@src/types'

enum RequiredPermission {
  Bluetooth = 'Bluetooth',
  NearbyDevices = 'NearbyDevices',
  Location = 'Location',
}

let requiredPermission: RequiredPermission
if (ALL_BLUETOOTH_PERMISSIONS.includes(PERMISSIONS.IOS.BLUETOOTH_PERIPHERAL)) {
  requiredPermission = RequiredPermission.Bluetooth
} else if (ALL_BLUETOOTH_PERMISSIONS.includes(PERMISSIONS.ANDROID.BLUETOOTH_CONNECT)) {
  requiredPermission = RequiredPermission.NearbyDevices
} else {
  requiredPermission = RequiredPermission.Location
}

const ICON = (() => {
  switch (requiredPermission) {
    case RequiredPermission.Bluetooth:
      return <NsBluetooth />
    case RequiredPermission.Location:
      return <NsLocation />
    case RequiredPermission.NearbyDevices:
      return <NsNearbyDevices />
  }
})()

const TITLE_TEXT = (() => {
  switch (requiredPermission) {
    case RequiredPermission.Bluetooth:
      return 'Allow Bluetooth'
    case RequiredPermission.Location:
      return 'Allow Location Sharing'
    case RequiredPermission.NearbyDevices:
      return 'Allow Nearby Devices permission'
  }
})()

const SUMMARY_TEXT = (() => {
  switch (requiredPermission) {
    case RequiredPermission.Bluetooth:
      return 'Bluetooth is required to connect with your sensor and receive glucose data.'
    case RequiredPermission.Location:
      return 'Permission for location is required to find your sensor using Bluetooth.'
    case RequiredPermission.NearbyDevices:
      return 'Permission for nearby device discovery is required to find your sensor using Bluetooth.'
  }
})()

export const BluetoothPermissionModal = () => {
  const styles = useStyleSheet(themedStyle)
  const goBack = useGoBack()

  const checkingPermissionsRef = useRef(false)

  const [shouldTrackPermissionEvent, setShouldTrackPermissionEvent] = useState(false)

  const { connectToSensor } = useContext(ScanningContext)

  useEffect(() => {
    if (!shouldTrackPermissionEvent) {
      return
    }

    // listen for foreground event
    // check if permissions have been granted
    // reconnect to sensor
    // close modal

    const listener = async (nextAppState: AppStateStatus) => {
      if (nextAppState !== 'active') {
        return
      }

      const { isBluetoothDenied } = await checkBluetoothPermissions()
      if (isBluetoothDenied) {
        return
      }

      connectToSensor()

      goBack()
    }

    const subscription = AppState.addEventListener('change', listener)

    return () => {
      subscription.remove()
    }
  }, [connectToSensor, goBack, shouldTrackPermissionEvent])

  const onAllowButtonPress = async () => {
    if (checkingPermissionsRef.current) {
      return
    }

    checkingPermissionsRef.current = true

    let results: Awaited<ReturnType<typeof requestBluetoothPermissions>>

    try {
      results = await requestBluetoothPermissions()
    } catch (error) {
      console.error(error)
      checkingPermissionsRef.current = false
      return
    }

    if (areBluetoothPermissionsGranted(results)) {
      connectToSensor()
      goBack()
      return
    }

    if (!checkPermissionStatus(RESULTS.BLOCKED, Object.values(results))) {
      // permission is denied, we can request it again
      checkingPermissionsRef.current = false
      setShouldTrackPermissionEvent(false)
      return
    }

    // permission is blocked, user can only grant it manually from settings

    setShouldTrackPermissionEvent(true)

    try {
      await Linking.openSettings()
    } finally {
      checkingPermissionsRef.current = false
    }
  }

  const isRequiredBluetooth = requiredPermission === RequiredPermission.Bluetooth
  const isRequiredLocation = requiredPermission === RequiredPermission.Location

  return (
    <BottomSheet primaryButton={{ text: TITLE_TEXT, onPress: onAllowButtonPress }}>
      <Button
        type="transparent"
        size="small"
        style={styles.dismissButton}
        accessibilityLabel="Dismiss"
        onPress={goBack}
      >
        Not Now
      </Button>
      <View style={styles.bluetoothImage}>{ICON}</View>
      <View style={styles.textContainer}>
        <Text type="title-2" style={styles.titleText}>
          {TITLE_TEXT}
        </Text>
        <Text type="regular" style={styles.summaryText}>
          {SUMMARY_TEXT}
        </Text>
      </View>
      {(isRequiredBluetooth || isRequiredLocation) && (
        <InlineAlert
          category={isRequiredBluetooth ? UiInlineAlert.Warning : UiInlineAlert.Info}
          message={
            isRequiredBluetooth
              ? 'When Bluetooth is off, the app cannot receive and display your glucose data.'
              : `Nutrisense never uses or stores customer location. This consent is required because it is possible to track location with Bluetooth. We only use Bluetooth to detect and connect to your nearby sensor.`
          }
          style={styles.alert}
        />
      )}
    </BottomSheet>
  )
}

const themedStyle = StyleService.create({
  dismissButton: {
    marginLeft: 'auto',
    marginRight: 8,
  },
  bluetoothImage: {
    marginTop: 48,
    marginBottom: 64,
    alignSelf: 'center',
  },
  textContainer: {
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  titleText: {
    textAlign: 'center',
    marginHorizontal: 20,
  },
  summaryText: {
    flex: 1,
    marginTop: 16,
    marginHorizontal: 32,
    textAlign: 'center',
  },
  alert: {
    marginHorizontal: 16,
    marginVertical: 32,
  },
})
