import { MealComparisonChartData } from '@src/screens/Meals/types'
import { fontFamily } from '@components/charts/common-styles'
import { base64Url } from '@components/charts/fonts/nbInternationalBase64Url'

export interface MealComparisonChartConfig {
  data: MealComparisonChartData['data']
  events: MealComparisonChartData['events']
  lineColors?: string[]
  disableAnimation?: boolean
  style: {
    axisLabel: string
    axisLine: string
    mealIconBackground: string
    pointColorActivity: string
    pointColorJournalEntry: string
    pointColorMeal: string
    pointStrokeColor: string
    tooltipBackground: string
    tooltipTitleColor: string
    tooltipTextColor: string
  }
}

const TOOLTIP_SPACING = 16

export const mealComparisonChart = ({
  data,
  events,
  lineColors,
  disableAnimation,
  style,
}: MealComparisonChartConfig) => `(() => {
  var nbInternationalFont = new FontFace('${fontFamily}', 'url(${base64Url})');
  nbInternationalFont.load().then(function(font) {
    document.fonts.add(font);

    const data = ${JSON.stringify(data)};

    const style = document.createElement('style');
    style.textContent = \`
      .f2-tooltip {
        max-width: 160px;
        padding: 8px 12px;
        border-radius: 4px;
        background-color: ${style.tooltipBackground};
        font-family: 'NBInternationalProReg';
        transition: opacity 0.3s;
      }
      .f2-tooltip:after {
        display: none;
      }
      .tooltip-title {
        font-size: 13px;
        margin-bottom: 4px;
        color: ${style.tooltipTitleColor};
      }
      .tooltip-content {
        font-size: 11px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${style.tooltipTextColor};
      }
      .tooltip-arrow {
        position: absolute;
      }
      .arrow-horizontal {
        top: 50%;
        margin-top: -6px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
      }
      .arrow-vertical {
        left: 50%;
        margin-left: -6px;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
      }
      .arrow-down {
        bottom: -6px;
        border-top: 6px solid ${style.tooltipBackground};
      }
      .arrow-up {
        top: -6px;
        border-bottom: 6px solid ${style.tooltipBackground};
      }
      .arrow-left {
        left: -6px;
        border-right: 6px solid ${style.tooltipBackground};
      }
      .arrow-right {
        right: -6px;
        border-left: 6px solid ${style.tooltipBackground};
      }
      .meal-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: ${style.mealIconBackground};
      }
      .meal-icon svg {
        width: 12px;
        height: 12px;
      }
    \`;
    document.body.append(style)

    chart = new F2.Chart({
      id: 'chart',
      padding: [24, 16, 'auto', 24],
      pixelRatio: window.devicePixelRatio,
    });

    chart.source(data, {
      x: {
        range: [0, 1],
        ticks: [-60, -30, 0, 30, 60, 90, 120, 150, 180],
        formatter: (val) => val + 'min',
        min: -60,
        max: 180,
        nice: false,
      },
      y: {
        tickCount: 9,
        nice: true,
      }
    });

    chart.axis('x', {
      line: {
        stroke: '${style.axisLine}',
      },
      label: (text) => {
        if (text === '0min') {
          return { fill: 'transparent' };
        }

        return {
          fill: '${style.axisLabel}',
          fontFamily: '${fontFamily}',
        };
      },
      grid: (_, index) => {
        if (index === 0) {
          return {
            stroke: '${style.axisLine}',
            lineWidth: 2,
            lineDash: null,
          }
        }
      },
    });
    chart.axis('y', {
      grid: (_, index) => {
        if (index === 0) {
          return {
            stroke: '${style.axisLine}',
            lineWidth: 2,
            lineDash: null,
          }
        }

        if (index % 2 !== 0) {
          return null;
        }

        return {
          lineDash: [2, 2],
          stroke: '${style.axisLine}',
        };
      },
      label: (_, index) => ({
        fill: index === 0 ? 'transparent' : '${style.axisLabel}',
        fontFamily: '${fontFamily}',
        textBaseline: 'middle',
      }),
    });

    chart.line()
      .position('x*y')
      .shape('smooth')
      .style({ lineCap: 'butt' })
      .color('index', ${JSON.stringify(lineColors)});

    // Draw meal time line - vertical line at 0 min
    chart.guide().line({
      start: [0, 'min'],
      end: [0, 'max'],
      style: {
        stroke: '${style.axisLine}',
        lineWidth: 2,
        lineCap: 'round'
      }
    });

    // Draw fork and knife icon at 0 min
    chart.guide().html({
      position: [0, 'min'],
      html: \`
        <div class="meal-icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="#FFF" style="isolation:isolate" viewBox="0 0 16 16">
          <path
            d="M3.843 4.487V.65a.64.64 0 1 1 1.279 0v3.837a.64.64 0 1 1-1.279 0ZM15.355.65v14.71a.64.64 0 1 1-1.279 0v-3.837h-3.837a.64.64 0 0 1-.64-.64c.03-1.532.223-3.056.577-4.547.782-3.237 2.264-5.407 4.288-6.274a.641.641 0 0 1 .891.588Zm-1.279 1.111C11.504 3.726 11 8.51 10.902 10.244h3.174V1.761ZM7.671.545A.638.638 0 1 0 6.41.756l.631 3.782a2.559 2.559 0 0 1-5.117 0l.63-3.782A.64.64 0 1 0 1.292.545L.653 4.383a.6.6 0 0 0-.008.104 3.844 3.844 0 0 0 3.198 3.784v7.089a.64.64 0 1 0 1.279 0V8.271A3.845 3.845 0 0 0 8.32 4.487a.6.6 0 0 0-.009-.104L7.671.545Z" />
          </svg>
        </div>
      \`,
      alignX: 'center',
      alignY: 'bottom',
      offsetY: 26,
    });

    // Draw events
    ${JSON.stringify(events)}.forEach((item) => {
      chart.guide().point({
        position: [item.x, item.y],
        style: {
          r: 6,
          fill: (() => {
            if (item.tag === 'Meal') {
              return '${style.pointColorMeal}';
            } else if (item.tag === 'Activity') {
              return '${style.pointColorActivity}';
            } else if (item.tag == 'JournalEntry') {
              return '${style.pointColorJournalEntry}';
            }
          })(),
          stroke: '${style.pointStrokeColor}',
          strokeWidth: 2,
        },
      })
    })

    const tooltipEl = document.querySelector(".f2-tooltip");

    const getTooltipContent = ({ description, body, tag, type }) => {
      let title = type;
      let content = description;

      if (tag === 'JournalEntry') {
        title = 'Journal Entry';
        content = body;
      }

      return { title, content };
    }

    // Show tooltip only for events
    chart.tooltip({
      showCrosshairs: false,
      showItemMarker: false,
      showTooltipMarker: false,
      custom: true,

      onShow: ({ items, tooltip, y }) => {
        const events = items.filter((item) => item.origin.tag);

        if (events.length === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Find the closest event to the click y position
        const event = events.length === 1
          ? events[0]
          : events.reduce((prev, curr) => {
              return Math.abs(curr.y - y) < Math.abs(prev.y - y) ? curr : prev;
            });
        const { title, content } = getTooltipContent(event.origin);

        tooltipEl.innerHTML = \`
          <div class="tooltip-title">\${title}</div>
          <div class="tooltip-content">\${content}</div>
        \`;
        const tooltipArrow = document.createElement('span');

        const canvasWidth = tooltip.canvas.get('width');

        const showOnBottom = event.y < tooltipEl.clientHeight + ${TOOLTIP_SPACING};
        const showOnLeft = event.x > canvasWidth - tooltipEl.clientWidth / 2
        const showOnRight = event.x < tooltipEl.clientWidth / 2

        if (showOnLeft) {
          tooltipEl.style.left = event.x - tooltipEl.clientWidth - ${TOOLTIP_SPACING} + 'px';
          tooltipEl.style.top = event.y - tooltipEl.clientHeight / 2 + 'px';
          tooltipArrow.className = 'tooltip-arrow arrow-horizontal arrow-right';
        } else if (showOnRight) {
          tooltipEl.style.left = event.x + ${TOOLTIP_SPACING} + 'px';
          tooltipEl.style.top = event.y - tooltipEl.clientHeight / 2 + 'px';
          tooltipArrow.className = 'tooltip-arrow arrow-horizontal arrow-left';
        } else if (showOnBottom) {
          tooltipEl.style.left = event.x - tooltipEl.clientWidth / 2 + 'px';
          tooltipEl.style.top = event.y + ${TOOLTIP_SPACING} + 'px';
          tooltipArrow.className = 'tooltip-arrow arrow-vertical arrow-up';
        } else {
          tooltipEl.style.left = event.x - tooltipEl.clientWidth / 2 + 'px';
          tooltipEl.style.top = event.y - tooltipEl.clientHeight - ${TOOLTIP_SPACING} + 'px';
          tooltipArrow.className = 'tooltip-arrow arrow-vertical arrow-down';
        }
        tooltipEl.append(tooltipArrow);
        tooltipEl.style.opacity = 1;
      },
      onHide: (obj) => {
        tooltipEl.style.opacity = 0;
      }
    });

    chart.legend(false);

    ${disableAnimation ? 'chart.animate(false);' : ''}

    chart.render();

    window.ReactNativeWebView.postMessage(stringify({ type: 'loaded' }));
  });
})(); true;
`
