import React, { useState } from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { sum } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { FadeFromBottomModal } from '@components'
import { useGoBack } from '@src/utils/navigation'
import { AppStackParamList } from '@src/navigation/types'
import { openUrl, Zendesk } from '@src/utils'
import { userSelector } from '@src/selectors/app'
import { useAllowedAddonQuantity } from '@src/utils/hooks'
import { ProductSelection } from '../types/types'
import { Actions } from './ProgramDetails/Actions'

const ProductsSelectionModal = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const goBack = useGoBack()

  const route = useRoute<RouteProp<AppStackParamList, 'ProductsSelection'>>()
  const params = route.params || {}

  const user = useSelector(userSelector)

  const addons = params.addons.sort((a, b) => a.price - b.price) || []
  const selectedAddon = params.selectedAddon

  const { getAllowedAddonQuantity, addonLimitByProductId } = useAllowedAddonQuantity()

  const [selectedProductsCountById, setSelectedProductsCountById] = useState(() => {
    return addons.reduce((acc, addon) => {
      if (selectedAddon.id === addon.id) {
        const { allowedAddonQuantity } = getAllowedAddonQuantity(addon.id)
        acc[addon.id] = allowedAddonQuantity > 0 ? 1 : 0
      } else {
        acc[addon.id] = 0
      }
      return acc
    }, {} as { [id: string]: number })
  })

  const onCheckoutPress = () => {
    const selections = addons
      .filter((addon) => selectedProductsCountById[addon.id] > 0)
      .map((product) => ({
        product,
        quantity: selectedProductsCountById[product.id],
      }))

    navigation.navigate('AddonCheckout', { selections: selections as [ProductSelection] })
  }

  const onContactSupportPress = () => {
    openUrl(Zendesk.baseUrl(user?.email))
  }

  const onQuantityPress = (addonId: string, quantity: number) => {
    setSelectedProductsCountById({ ...selectedProductsCountById, [addonId]: quantity })
  }

  const selectedProductsCount = Object.values(selectedProductsCountById).reduce(
    (acc, val) => acc + val,
    0,
  )

  return (
    <FadeFromBottomModal closeModal={goBack} contentStyles={styles.modal}>
      {addons.map((addon) => {
        const [addonLimit] = addonLimitByProductId[addon.id]

        const { allowedAddonQuantity } = getAllowedAddonQuantity(addon.id)

        return (
          <View key={addon.id} style={styles.addon}>
            <Text type="title-3">{addon.title}</Text>
            {addonLimit?.description && (
              <Text type="regular" lineSpacing="tight" style={styles.description}>
                {addonLimit.description}
              </Text>
            )}
            <ScrollView
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={styles.quantityContainer}
              horizontal
            >
              {Array.from({ length: allowedAddonQuantity }, (_, index) => {
                const quantity = index + 1
                const selected = selectedProductsCountById[addon.id] === quantity

                return (
                  <TouchableOpacity
                    style={[styles.quantity, selected && styles.selectedQuantity]}
                    key={quantity}
                    onPress={() => onQuantityPress(addon.id, quantity)}
                    accessibilityLabel={`quantity ${quantity}`}
                  >
                    <Text type="large" bold style={selected && styles.selectedQuantityTitle}>
                      {quantity}
                    </Text>
                  </TouchableOpacity>
                )
              })}
            </ScrollView>
          </View>
        )
      })}
      <Actions
        style={styles.actions}
        primaryButtonText={
          sum(Object.values(selectedProductsCountById)) > 0 ? 'Checkout' : undefined
        }
        onPrimaryButtonPress={onCheckoutPress}
        secondaryButtonText="Contact Support"
        onSecondaryButtonPress={onContactSupportPress}
        primaryButtonIndicator={selectedProductsCount > 0 ? selectedProductsCount : undefined}
      />
    </FadeFromBottomModal>
  )
}

export default ProductsSelectionModal

const themedStyle = StyleService.create({
  modal: {
    paddingHorizontal: 0,
  },
  addon: {
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderBottomWidth: 1,
    borderBottomColor: 'theme.surface.base1',
  },
  description: {
    marginTop: 4,
  },
  quantityContainer: {
    marginTop: 16,
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  quantity: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
    marginRight: 4,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'theme.primary.base',
  },
  selectedQuantity: {
    backgroundColor: 'theme.primary.base',
  },
  selectedQuantityTitle: {
    color: 'theme.solid.white',
  },
  actions: {
    flex: undefined,
    marginTop: 24,
  },
})
