import { flattenObject } from '@src/utils/flattenObject'
import { ThemeName, RemoveSuffix } from '../types'
import { theme as themeMap } from './theme'

const flatTheme = flattenObject({ theme: themeMap })
type FlatTheme = typeof flatTheme

type ResolveThemeSpecificValues<T> = {
  [K in keyof T as RemoveSuffix<K, `.${ThemeName}`>]: T[K]
}
type Theme = ResolveThemeSpecificValues<FlatTheme>

const filterByTheme = (theme: FlatTheme, themeName = ThemeName.Light): Theme => {
  const keyRegex = new RegExp(`.${themeName}$`)
  const ignoredTheme = themeName === ThemeName.Light ? ThemeName.Dark : ThemeName.Light

  return Object.entries(theme).reduce((acc, [key, value]) => {
    // There are some values that are not theme specific so we are not
    // filtering by the current theme, but ignoring the other theme instead
    if (!key.endsWith(ignoredTheme)) {
      const newKey = key.replace(keyRegex, '')
      acc[newKey] = value.replace(/^palette/, '$palette')
    }
    return acc
  }, {} as Record<string, string>) as Theme
}

export const theme = {
  light: filterByTheme(flatTheme, ThemeName.Light),
  dark: filterByTheme(flatTheme, ThemeName.Dark),
}

export type ThemeColor = keyof typeof theme.light
