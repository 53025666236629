import { gql } from '@apollo/client'

export const CORE_MEASUREMENT_FIELDS = gql`
  fragment CoreMeasurementFields on Measurement {
    id
    type
    title
    description
    value
    units
    occurredAt
    createdAt
    externalSource
  }
`
