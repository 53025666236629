import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { Icon, IconDescriptor } from '@src/components'
import { HealthDataMeasurementSource } from '@src/types'
import { dailyMeasurementSourceDefLookupSelector as sourceDefLookupSelector } from '@selectors/app'
import { Measurement } from '@src/screens/Events/models/events.types'

export const SelectedSourceChoiceContent = ({ measurement }: { measurement: Measurement }) => {
  const navigation = useNavigation()

  const sourceDefLookup = useSelector(sourceDefLookupSelector)
  const sourceDef = sourceDefLookup[measurement.externalSource as HealthDataMeasurementSource]
  const iconDef = sourceDef.iconDef as IconDescriptor
  const styles = useStyleSheet(themedStyle)

  const isManualMeasurement = sourceDef.key === HealthDataMeasurementSource.Nutrisense

  const onClickManualMeasurementValue = () =>
    navigation.navigate('AddMeasurement', { item: measurement })

  return (
    <View style={styles.sourceContainer}>
      <View style={styles.sourceLabelContainer}>
        {iconDef && <Icon style={[styles.sourceIcon]} iconDescriptor={iconDef} />}
        <Text type="regular">{sourceDef.label || ''}</Text>
      </View>
      <View style={styles.measurementDataContainer}>
        <TouchableOpacity
          style={[isManualMeasurement && styles.measurementValueContainer]}
          accessibilityLabel="Edit Manual Measurement"
          disabled={!isManualMeasurement}
          onPress={onClickManualMeasurementValue}
        >
          <Text type="large" bold lineSpacing="none">{`${measurement.value || ''}`}</Text>
        </TouchableOpacity>
        <Text type="small" style={styles.units}>
          {` ${measurement.units}`}
        </Text>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  sourceContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingRight: 8,
  },
  sourceLabelContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  measurementDataContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  measurementValueContainer: {
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 6,
    backgroundColor: 'theme.background',
  },
  units: {
    alignSelf: 'flex-end',
  },
  sourceIcon: {
    height: 24,
    width: 24,
    marginRight: 8,
    marginLeft: 4,
  },
})
