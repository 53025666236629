import React, { useState, useCallback, useEffect } from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { partition, uniqBy } from 'lodash'
import { useRoute, RouteProp, useNavigation } from '@react-navigation/core'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconButton } from '@components/base'
import { ScrollableAvoidKeyboard, SearchBar, TabView } from '@components'
import { AppStackParamList } from '@navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import {
  CreateNewIngredientButton,
  IngredientItem,
  IngredientOptions,
  NoSearchResults,
} from '@screens/Ingredients'
import {
  useCustomIngredients,
  useIngredientSearch,
  useRecentIngredientsForMealType,
} from '@screens/Ingredients/utils/hooks'
import { BaseIngredient } from '@screens/Ingredients/types'
import {
  getFullNutritionixData,
  ingredientsAreEqual,
  normalizeNutritionixItem,
} from '@screens/Ingredients/utils'
import { mealDraftSelector } from '@selectors/meals'
import { IngredientSource } from '@src/types'

const CUSTOM_INGREDIENTS_TAB_INDEX = 3

export const IngredientSearch = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()
  const insets = useSafeAreaInsets()

  const { params } = useRoute<RouteProp<AppStackParamList, 'IngredientSearch'>>()
  const { createdIngredientId, mealType } = params

  const [query, setQuery] = useState('')
  const [tabIndex, setTabIndex] = useState(0)

  const { ingredients: addedIngredients = [] } = useSelector(mealDraftSelector)

  const { ingredients, isLoading } = useIngredientSearch(query)
  const customIngredients = useCustomIngredients(query)
  const recentIngredients = useRecentIngredientsForMealType(mealType, query)

  const allIngredients = uniqBy(
    [...recentIngredients, ...customIngredients, ...ingredients],
    'description',
  )
  const [branded, common] = partition(ingredients, (item) =>
    item.thirdPartyIngredientId?.startsWith('branded'),
  )

  const onItemSelect = useCallback(
    async (item: BaseIngredient) => {
      let ingredient = item

      if (item.thirdPartyIngredientSource === IngredientSource.Nutritionix) {
        const [ingredientWithNutrients] = await getFullNutritionixData([item])

        if (!ingredientWithNutrients) {
          return
        }

        ingredient = {
          ...normalizeNutritionixItem(ingredientWithNutrients),
          servingAmount: item.servingAmount,
          servingUnits: item.servingUnits,
        }
      }

      navigation.navigate('IngredientPortion', { ingredient })
    },
    [navigation],
  )

  const renderIngredientsList = useCallback(
    (ingredients: BaseIngredient[], isCustomTab?: boolean) => {
      const filteredResults = ingredients.filter((ingredient) => {
        return !addedIngredients.some((item) => ingredientsAreEqual(item, ingredient))
      })

      return (
        <ScrollableAvoidKeyboard
          keyboardShouldPersistTaps="handled"
          contentContainerStyle={{ paddingBottom: insets.bottom }}
        >
          {isCustomTab && <CreateNewIngredientButton />}
          {filteredResults.map((item) => (
            <IngredientItem
              hasPlusIcon
              key={item.id}
              item={item}
              onItemSelect={onItemSelect}
              rightAccessory={isCustomTab ? <IngredientOptions ingredient={item} /> : null}
            />
          ))}
        </ScrollableAvoidKeyboard>
      )
    },
    [addedIngredients, insets.bottom, onItemSelect],
  )

  const renderSceneComponent = useCallback(
    (ingredients: BaseIngredient[], isCustomTab?: boolean) => {
      if (!isLoading && query && ingredients.length === 0 && !isCustomTab) {
        return <NoSearchResults query={query} />
      }

      return renderIngredientsList(ingredients, isCustomTab)
    },
    [isLoading, query, renderIngredientsList],
  )

  const createdIngredient = customIngredients.find(
    (ingredient) => ingredient.id === createdIngredientId,
  )

  useEffect(() => {
    if (createdIngredient) {
      setTabIndex(CUSTOM_INGREDIENTS_TAB_INDEX)
      navigation.navigate('IngredientPortion', { ingredient: createdIngredient })
    }
  }, [createdIngredient, navigation])

  return (
    <NavigationContainer title="Find Ingredients" style={styles.container}>
      <TabView
        tabIndex={tabIndex}
        onChangeTabIndex={setTabIndex}
        swipeEnabled
        staticHeader={
          <View style={styles.header}>
            <SearchBar
              autoFocus
              testID="IngredientSearch/SearchBar"
              style={styles.searchBar}
              value={query}
              onChangeText={setQuery}
            />
            <IconButton
              accessibilityLabel="Scan Barcode"
              iconName="barcode"
              shape="square"
              size="m"
              type="transparent"
              onPress={() => navigation.navigate('BarcodeIngredientSearch')}
            />
          </View>
        }
        routes={[
          {
            key: 'all',
            title: 'All',
            sceneComponent: renderSceneComponent(allIngredients),
          },
          {
            key: 'branded',
            title: 'Branded',
            sceneComponent: renderSceneComponent(branded),
          },
          {
            key: 'common',
            title: 'Common',
            sceneComponent: renderSceneComponent(common),
          },
          {
            key: 'custom',
            title: 'Custom',
            sceneComponent: renderSceneComponent(customIngredients, true),
          },
        ]}
      />
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
  },
  searchBar: {
    flex: 1,
    marginRight: 8,
  },
})
