import React from 'react'
import { startCase } from 'lodash'
import { Control, Controller, FormState } from 'react-hook-form'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Input, Text } from '@components/base'
import { SliderInputBlock } from '@components/modalBlocks/SliderInputBlock'
import { useUnitSystemMeasurements } from '@screens/Measurements/hooks'
import { MeasurementFormData, MeasurementFormType } from '@screens/Measurements/types'
import { getFormOptions } from '@screens/Measurements/utils'
import { validateFloatNumberInput } from '@utils/validators'
import { HealthDataMeasurementType } from '@src/types'

interface MeasurementFormInputsProps {
  control: Control<MeasurementFormData>
  errors: FormState<MeasurementFormData>['errors']
  formType: MeasurementFormType
  measurement: HealthDataMeasurementType
  sliderValue: number
  unit?: string
  onSliderChange: (value: number) => void
}

export const MeasurementFormInputs = ({
  control,
  errors,
  formType,
  measurement,
  sliderValue,
  unit,
  onSliderChange,
}: MeasurementFormInputsProps) => {
  const styles = useStyleSheet(themedStyle)
  const measurementUnitConfig = useUnitSystemMeasurements()

  const options = getFormOptions(measurement)

  if (formType === MeasurementFormType.Slider) {
    return (
      <SliderInputBlock
        label="Value"
        value={sliderValue}
        min={options.sliderRange?.min}
        max={options.sliderRange?.max}
        style={styles.input}
        onValueChanged={onSliderChange}
      />
    )
  }

  const { minThreshold: min, maxThreshold: max } = measurementUnitConfig[measurement] || {}
  const hasThreshold = typeof min === 'number' && typeof max === 'number'

  const validationRules = {
    required: 'This field is required',
    validate: (value = '') => {
      if (!hasThreshold) {
        return true
      }
      return (
        (Number(value) >= min && Number(value) <= max) ||
        `Please enter a value from ${min} to ${max}`
      )
    },
  }

  return (
    <>
      <Controller
        control={control}
        name="value"
        rules={validationRules}
        render={({ field }) => (
          <Input
            {...field}
            accessoryRight={<Text type="regular">{unit}</Text>}
            errorText={errors.value?.message}
            keyboardType="decimal-pad"
            hasError={!!errors.value}
            label={startCase(options.labels?.[0]) || 'Value'}
            placeholder="Enter the value"
            style={styles.input}
            onChangeText={(text) => {
              const value = validateFloatNumberInput(text)

              if (value !== null) {
                field.onChange(value)
              }
            }}
          />
        )}
      />
      {formType === MeasurementFormType.Double && (
        <Controller
          control={control}
          name="secondValue"
          rules={validationRules}
          render={({ field }) => (
            <Input
              {...field}
              accessoryRight={<Text type="regular">{unit}</Text>}
              errorText={errors.secondValue?.message}
              keyboardType="decimal-pad"
              hasError={!!errors.secondValue}
              label={startCase(options.labels?.[1]) || 'Value 2'}
              placeholder="Enter the value"
              style={styles.input}
              onChangeText={(text) => {
                const value = validateFloatNumberInput(text)

                if (value !== null) {
                  field.onChange(value)
                }
              }}
            />
          )}
        />
      )}
    </>
  )
}

const themedStyle = StyleService.create({
  input: {
    marginBottom: 24,
  },
})
