import React from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/core'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { glucoseEducationImage, installCgmImage } from '@assets/images'
import { Text, Button } from '@components/base'
import { Feature, useFeatureFlag } from '@components'
import { AppRouteProp, AppStackParamList, DrawerParamList } from '@navigation/types'
import { visitedTutorialsSelector } from '@selectors/education'
import { NavigationContainer } from '@screens/Common/containers'
import { EducationSection } from '@screens/Education'
import { TutorialKey } from '@screens/Education/types'
import { useDexcomIntegration, useLibre3NativeEnabled } from '@utils/hooks'
import { getTutorialGroup } from '@utils/tutorials'
import { openUrl } from '@src/utils'
import { researchFaqsSelector } from '@src/selectors/app'

export const Education = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const route = useRoute<AppRouteProp<'EducationModal'> | RouteProp<DrawerParamList, 'Education'>>()

  const visitedTutorials = useSelector(visitedTutorialsSelector)
  const researchFaqs = useSelector(researchFaqsSelector)

  const displayFAQ = !useFeatureFlag(Feature.AbbottPartnership)
  const isLibreLinkup = useFeatureFlag(Feature.LibrelinkupConnection)
  const isDexcomIntegration = useDexcomIntegration()

  const { uxSensorModePendingLibre3 } = useLibre3NativeEnabled()
  const isLibre3 = uxSensorModePendingLibre3 || isLibreLinkup
  const specialSensorInstructions = isLibre3 || isDexcomIntegration

  const isModal = route.name === 'EducationModal'

  const skipTutorial = () => {
    navigation.navigate('SkipTutorial')
  }

  const goToGlucoseEducation = () => {
    navigation.navigate('GlucoseEducation')
  }

  const goToCGMEducation = () => {
    if (isDexcomIntegration) {
      openUrl(researchFaqs.gettingStartedUrl)
    } else if (isLibre3) {
      navigation.navigate('TutorialsGroup', {
        group: getTutorialGroup(uxSensorModePendingLibre3),
      })
    } else {
      navigation.navigate('CGMEducation', { parentScreen: route.name })
    }
  }

  const FAQLink = displayFAQ ? (
    <EducationSection
      key="FAQ"
      title="FAQ"
      accessibilityLabel="FAQ"
      imageSource={glucoseEducationImage.imageSource}
      onPress={goToGlucoseEducation}
      visited={visitedTutorials[TutorialKey.GlucoseEducation]}
    />
  ) : null
  const CGMLink = (
    <EducationSection
      key="CGM"
      title={specialSensorInstructions ? 'Activate a new sensor' : 'Apply your CGM'}
      accessibilityLabel={specialSensorInstructions ? 'Activate a new sensor' : 'Apply your CGM'}
      imageSource={installCgmImage.imageSource}
      onPress={goToCGMEducation}
      visited={visitedTutorials[TutorialKey.CGMEducation]}
    />
  )

  const educationLinks = specialSensorInstructions ? [CGMLink, FAQLink] : [FAQLink, CGMLink]

  return (
    <NavigationContainer
      title="Education"
      isDrawerScreen
      leftIcon={isModal ? { pack: 'eva', name: 'close' } : undefined}
      goBack={isModal ? skipTutorial : undefined}
      style={{ flex: 1 }}
    >
      <ScrollView
        style={styles.container}
        contentContainerStyle={{ flex: 1, paddingBottom: (insets?.bottom ?? 0) + 16 }}
      >
        <Text type="title-2">Here’s something to learn</Text>
        <Text type="regular" style={styles.subtitle}>
          If you don’t know what to do with the app or the CGM, you can learn it here!
        </Text>
        <View style={styles.sections}>{educationLinks}</View>
        {isModal && (
          <Button type="transparent" size="block" accessibilityLabel="Skip" onPress={skipTutorial}>
            Skip
          </Button>
        )}
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 16,
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
  },
  subtitle: {
    marginTop: 16,
  },
  sections: {
    flex: 1,
    marginVertical: 24,
  },
})
