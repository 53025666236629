import React from 'react'
import { View } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Button, Text } from '@src/components/base'
import { CustomEventTypes } from '@config'
import { SurveysConfigKind } from '@src/types'
import { AppStackParamList } from '@src/navigation/types'
import { INSURANCE_BASED_VIDEO_CALLS_PROMOTION_SLOGAN } from '../constants'
import { useNutritionistHubTracker } from '../hooks'
import { NutritionistPhotoIcon } from './NutritionistPhotoIcon'

export const VideoCallsGettingStartedScreen = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const track = useNutritionistHubTracker()

  const addInsurancePress = () => {
    track(CustomEventTypes.NutritionistHubAddInsuranceTapped)
    navigation.replace('Questionnaire', { questionnaire: SurveysConfigKind.Insurance })
  }

  return (
    <NavigationContainer title="Video Call">
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <NutritionistPhotoIcon style={styles.nutritionistPhoto} />
        <Text type="large" style={styles.descriptionText}>
          Sign up for a video call with your nutritionist to discuss your goals and determine a plan
          so that you can get the most out of the program.
        </Text>
        <Text type="title-3" style={styles.text}>
          {`${INSURANCE_BASED_VIDEO_CALLS_PROMOTION_SLOGAN}!`}
        </Text>

        <View style={styles.buttons}>
          <Button
            type="primary"
            size="block"
            accessibilityLabel="Add Insurance"
            style={styles.button}
            onPress={addInsurancePress}
          >
            Add Insurance
          </Button>
          <Button
            type="outline"
            size="block"
            accessibilityLabel="Book without Insurance"
            style={styles.button}
            onPress={() => {
              track(CustomEventTypes.NutritionistHubBookWithoutInsuranceTapped)
              navigation.navigate('VideoCallCheckout')
            }}
          >
            Book without Insurance
          </Button>
        </View>
        <Text type="tiny" style={styles.text}>
          FSA/HSA ACCEPTED
        </Text>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  nutritionistPhoto: {
    alignSelf: 'center',
    marginTop: 48,
  },
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
    justifyContent: 'center',
  },
  descriptionText: {
    textAlign: 'center',
    paddingHorizontal: 16,
    marginVertical: 32,
  },
  text: {
    textAlign: 'center',
    paddingHorizontal: 16,
  },
  buttons: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  button: {
    marginBottom: 8,
  },
})
