import { useSelector } from 'react-redux'
import { ChartQueryType } from '@src/screens/Insights/helper'
import { ChartTypes, YAxisConfig } from '@src/screens/Insights/types'
import { UnitSystem } from '@src/screens/Settings'
import { JournalMeasurementEnum } from '@src/screens/JournalEntries/types'
import { scoreSelector } from '@src/selectors/app'
import { UserConfigScore } from '@src/models/clientConfig.types'
import { Feature, useFeatureFlag } from '@src/components/FeatureFlag'

type CustomOverview = {
  title?: string
  minDateRange?: number
}
interface PageConfig {
  showScores: boolean
  title: string
  yAxisConfig?: YAxisConfig
  yLabel?: string
  chartQueryType: ChartQueryType
  chartsToDisplay: ChartTypes[]
  showData: boolean
  showWeeklyDiffTooltip?: boolean
  customOverview?: Partial<Record<'average' | 'min' | 'max', CustomOverview>>
  units?: string
}

export enum SectionEnum {
  SCORE = 'score',
  GLUCOSE = 'glucose',
  KETONES = 'ketones',
  MACROS = 'macros',
  MEASUREMENTS = 'measurements',
  JOURNAL = 'journal',
}

export enum ScoreSectionEnum {
  DAILY_AVERAGE = 'daily_average',
  MEALS_AVERAGE = 'meals_average',
}

export enum GlucoseKetonesEnum {
  TIME_WITHIN_RANGE = 'time_within_range',
  STANDARD_DEVIATION = 'standard_deviation',
  MEAN = 'mean',
  MIN = 'min',
  MAX = 'max',
  SLEEP_AVERAGE = 'sleep_average',
  MORNING_AVERAGE = 'morning_average',
}

export enum MacrosSectionEnum {
  CALORIES = 'calories',
  PROTEIN = 'protein',
  CARBS = 'carbs',
  FAT = 'fat',
}

export enum MeasurementEnum {
  BODY_FAT = 'body_fat_percentage',
  BODY_TEMPERATURE = 'body_temperature',
  WEIGHT = 'weight',
  STRESS = 'stress',
  BLOOD_GLUCOSE = 'blood_glucose',
  BLOOD_KETONES = 'blood_ketones',
  BREATH_KETONES = 'breath_ketones',
  BLOOD_PRESSURE = 'blood_pressure',
  STEP_COUNT = 'step_count',
  FLIGHT_CLIMBED = 'flights_climbed',
  EXERCISE_TIME = 'exercise_time',
  RUNNING_DISTANCE = 'running_distance',
  CYCLING_DISTANCE = 'cycling_distance',
  SWIMMING_DISTANCE = 'swimming_distance',
}

export const getPageConfig = (
  scoreConfig: UserConfigScore,
  { outcomesTrackingEnabled = false }: { outcomesTrackingEnabled?: boolean } = {},
): { [key: string]: PageConfig | undefined } => ({
  //score
  [SectionEnum.SCORE + ScoreSectionEnum.DAILY_AVERAGE]: {
    showScores: true,
    showData: true,
    showWeeklyDiffTooltip: true,
    chartsToDisplay: ['line', 'weekly'],
    units: '',
    yLabel: 'score',
    yAxisConfig: {
      [UnitSystem.Metric]: {
        min: 0,
        max: scoreConfig.max,
      },
      [UnitSystem.Imperial]: {
        min: 0,
        max: scoreConfig.max,
      },
    },
    title: 'Glucose Score',
    chartQueryType: 'DAILY_SCORE',
    customOverview: {
      average: { title: 'Average' },
      min: { title: 'Lowest' },
      max: { title: 'Highest' },
    },
  },
  [SectionEnum.SCORE + ScoreSectionEnum.MEALS_AVERAGE]: {
    showScores: true,
    showData: true,
    showWeeklyDiffTooltip: true,
    chartsToDisplay: ['line', 'weekly', 'meal'],
    units: '',
    yAxisConfig: {
      [UnitSystem.Metric]: {
        min: 0,
        max: scoreConfig.max,
      },
      [UnitSystem.Imperial]: {
        min: 0,
        max: scoreConfig.max,
      },
    },
    yLabel: 'score',
    title: 'Meal Score',
    chartQueryType: 'MEALS_AVERAGE',
    customOverview: {
      average: { title: 'Average' },
      min: { title: 'Lowest' },
      max: { title: 'Highest' },
    },
  },
  //glucose
  [SectionEnum.GLUCOSE + GlucoseKetonesEnum.TIME_WITHIN_RANGE]: {
    showScores: false,
    showData: true,
    showWeeklyDiffTooltip: true,
    chartsToDisplay: ['line', 'weekly', 'byHour', 'byTimeInRange'],
    yAxisConfig: {
      [UnitSystem.Metric]: {
        min: 0,
        max: 100,
      },
      [UnitSystem.Imperial]: {
        min: 0,
        max: 100,
      },
    },
    yLabel: '%',
    units: '%',
    title: 'Glucose Time in Range',
    chartQueryType: 'GLUCOSE_TIME_IN_RANGE',
    customOverview: {
      average: { title: 'Average' },
      min: { title: 'Lowest' },
      max: { title: 'Highest' },
    },
  },
  [SectionEnum.GLUCOSE + GlucoseKetonesEnum.STANDARD_DEVIATION]: {
    showScores: false,
    showData: true,
    showWeeklyDiffTooltip: true,
    chartsToDisplay: ['line', 'weekly', 'byHour'],
    yAxisConfig: {
      [UnitSystem.Metric]: {
        min: 0,
        max: 2.8,
      },
      [UnitSystem.Imperial]: {
        min: 0,
        max: 50,
      },
    },
    units: 'mg/dL',
    title: 'Glucose Variability',
    chartQueryType: 'GLUCOSE_STD_DEV',
    customOverview: {
      average: { title: 'Average' },
      min: { title: 'Lowest' },
      max: { title: 'Highest' },
    },
  },
  [SectionEnum.GLUCOSE + GlucoseKetonesEnum.MEAN]: {
    showScores: false,
    showData: true,
    showWeeklyDiffTooltip: true,
    chartsToDisplay: ['line', 'weekly', 'byHour'],
    yAxisConfig: {
      [UnitSystem.Metric]: {
        min: 2,
        max: 10,
      },
      [UnitSystem.Imperial]: {
        min: 40,
        max: 180,
      },
    },
    yLabel: 'mg/dL',
    units: 'mg/dL',
    title: 'Glucose Average',
    chartQueryType: 'GLUCOSE_AVERAGE',
    customOverview: {
      average: { title: 'Average' },
      min: { title: 'Lowest' },
      max: { title: 'Highest' },
    },
  },
  [SectionEnum.GLUCOSE + GlucoseKetonesEnum.MIN]: {
    showScores: false,
    showData: true,
    showWeeklyDiffTooltip: true,
    chartsToDisplay: ['line', 'weekly', 'byHour'],
    yAxisConfig: {
      [UnitSystem.Metric]: {
        min: 2,
        max: 10,
      },
      [UnitSystem.Imperial]: {
        min: 40,
        max: 180,
      },
    },
    yLabel: 'mg/dL',
    units: 'mg/dL',
    title: 'Glucose Min',
    chartQueryType: 'GLUCOSE_MIN',
    customOverview: {
      min: {
        title: 'Min',
        minDateRange: 1,
      },
    },
  },
  [SectionEnum.GLUCOSE + GlucoseKetonesEnum.MAX]: {
    showScores: false,
    showData: true,
    showWeeklyDiffTooltip: true,
    chartsToDisplay: ['line', 'weekly', 'byHour'],
    yAxisConfig: {
      [UnitSystem.Metric]: {
        min: 2,
        max: 10,
      },
      [UnitSystem.Imperial]: {
        min: 40,
        max: 180,
      },
    },
    yLabel: 'mg/dL',
    units: 'mg/dL',
    title: 'Glucose Max',
    chartQueryType: 'GLUCOSE_MAX',
    customOverview: {
      max: {
        title: 'Max',
        minDateRange: 1,
      },
    },
  },
  [SectionEnum.GLUCOSE + GlucoseKetonesEnum.SLEEP_AVERAGE]: {
    showScores: false,
    showData: true,
    showWeeklyDiffTooltip: true,
    chartsToDisplay: ['line', 'weekly', 'byHour'],
    yAxisConfig: {
      [UnitSystem.Metric]: {
        min: 2,
        max: 10,
      },
      [UnitSystem.Imperial]: {
        min: 20,
        max: 180,
      },
    },
    units: 'mg/dL',
    title: 'Glucose Sleep Average',
    chartQueryType: 'GLUCOSE_SLEEP_AVERAGE',
    customOverview: {
      average: { title: 'Average' },
      min: { title: 'Lowest' },
      max: { title: 'Highest' },
    },
  },
  [SectionEnum.GLUCOSE + GlucoseKetonesEnum.MORNING_AVERAGE]: {
    showScores: false,
    showData: true,
    showWeeklyDiffTooltip: true,
    chartsToDisplay: ['line', 'weekly'],
    yAxisConfig: {
      [UnitSystem.Metric]: {
        min: 2,
        max: 10,
      },
      [UnitSystem.Imperial]: {
        min: 40,
        max: 140,
      },
    },
    units: 'mg/dL',
    title: 'Glucose Morning Average',
    chartQueryType: 'GLUCOSE_MORNING_AVERAGE',
    customOverview: {
      average: { title: 'Average' },
      min: { title: 'Lowest' },
      max: { title: 'Highest' },
    },
  },
  //ketones
  [SectionEnum.KETONES + GlucoseKetonesEnum.TIME_WITHIN_RANGE]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['weekly', 'byHour', 'byTimeInRange'],
    yLabel: '%',
    units: '%',
    title: 'Ketones Time In Range',
    chartQueryType: 'KETONES_TIME_IN_RANGE',
  },
  [SectionEnum.KETONES + GlucoseKetonesEnum.MEAN]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['weekly', 'byHour'],
    yLabel: 'mmol/L',
    units: 'mmol/L',
    title: 'Ketones Average',
    chartQueryType: 'KETONES_AVERAGE',
  },
  [SectionEnum.KETONES + GlucoseKetonesEnum.MIN]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['weekly', 'byHour'],
    yLabel: 'mmol/L',
    units: 'mmol/L',
    title: 'Ketones Min',
    chartQueryType: 'KETONES_MIN',
  },
  [SectionEnum.KETONES + GlucoseKetonesEnum.MAX]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['weekly', 'byHour'],
    yLabel: 'mmol/L',
    units: 'mmol/L',
    title: 'Ketones Max',
    chartQueryType: 'KETONES_MAX',
  },
  [SectionEnum.MACROS + MacrosSectionEnum.CALORIES]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly', 'byHour', 'meal'],
    units: 'kcal',
    yLabel: 'calories',
    title: 'Calories',
    chartQueryType: 'CALORIES',
  },
  [SectionEnum.MACROS + MacrosSectionEnum.PROTEIN]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly', 'byHour', 'meal'],
    units: 'g',
    yLabel: 'grams',
    title: 'Protein',
    chartQueryType: 'PROTEIN',
  },
  [SectionEnum.MACROS + MacrosSectionEnum.CARBS]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly', 'byHour', 'meal'],
    units: 'g',
    yLabel: 'grams',
    title: 'Carbs',
    chartQueryType: 'CARBS',
  },
  [SectionEnum.MACROS + MacrosSectionEnum.FAT]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly', 'byHour', 'meal'],
    units: 'g',
    yLabel: 'grams',
    title: 'Fat',
    chartQueryType: 'FAT',
  },
  // measurement
  [SectionEnum.MEASUREMENTS + MeasurementEnum.BODY_FAT]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    yLabel: '%',
    units: '%',
    title: 'Body Fat',
    chartQueryType: 'BODY_FAT',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.BODY_TEMPERATURE]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: '°F',
    yLabel: '°F',
    title: 'Body Temperature',
    chartQueryType: 'BODY_TEMPERATURE',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.WEIGHT]: {
    showScores: false,
    showData: true,
    chartsToDisplay: [outcomesTrackingEnabled ? 'line' : 'byDate', 'weekly'],
    units: 'pounds',
    yLabel: 'pounds',
    title: 'Weight',
    chartQueryType: 'WEIGHT',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.STRESS]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: 'stress',
    yLabel: 'stress',
    title: 'Stress',
    chartQueryType: 'STRESS',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.BLOOD_GLUCOSE]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: 'mg/dL',
    yLabel: 'mg/dL',
    title: 'Blood Glucose',
    chartQueryType: 'BLOOD_GLUCOSE',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.BLOOD_KETONES]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: 'mmol/L',
    yLabel: 'mmol/L',
    title: 'Blood Ketones',
    chartQueryType: 'BLOOD_KETONES',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.BLOOD_PRESSURE]: {
    showScores: false,
    showData: true,
    chartsToDisplay: [],
    units: 'mmHg',
    yLabel: 'mmHg',
    title: 'Blood Pressure',
    chartQueryType: 'BLOOD_PRESSURE',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.BREATH_KETONES]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: 'aces',
    yLabel: 'aces',
    title: 'Breath Ketones',
    chartQueryType: 'BREATH_KETONES',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.STEP_COUNT]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: 'steps',
    yLabel: 'steps',
    title: 'Steps',
    chartQueryType: 'STEP_COUNT',
  },

  [SectionEnum.MEASUREMENTS + MeasurementEnum.FLIGHT_CLIMBED]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: 'flights',
    yLabel: 'flights',
    title: 'Flight Climbed',
    chartQueryType: 'FLIGHT_CLIMBED',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.EXERCISE_TIME]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: 'minutes',
    yLabel: 'minutes',
    title: 'Exercise time',
    chartQueryType: 'EXERCISE_TIME',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.SWIMMING_DISTANCE]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: 'miles',
    yLabel: 'miles',
    title: 'Swimming Distance',
    chartQueryType: 'SWIMMING_DISTANCE',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.RUNNING_DISTANCE]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: 'miles',
    yLabel: 'miles',
    title: 'Running Distance',
    chartQueryType: 'RUNNING_DISTANCE',
  },
  [SectionEnum.MEASUREMENTS + MeasurementEnum.CYCLING_DISTANCE]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: 'miles',
    yLabel: 'miles',
    title: 'Cycling Distance',
    chartQueryType: 'CYCLING_DISTANCE',
  },
  [SectionEnum.JOURNAL + JournalMeasurementEnum.MOOD]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: '',
    yLabel: 'mood',
    title: 'Mood',
    chartQueryType: 'MOOD',
  },
  [SectionEnum.JOURNAL + JournalMeasurementEnum.ENERGY]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: '',
    yLabel: 'energy',
    title: 'Energy',
    chartQueryType: 'ENERGY',
  },
  [SectionEnum.JOURNAL + JournalMeasurementEnum.FOCUS]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: '',
    yLabel: 'focus',
    title: 'Focus',
    chartQueryType: 'FOCUS',
  },
  [SectionEnum.JOURNAL + JournalMeasurementEnum.STRESS]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: '',
    yLabel: 'stress',
    title: 'Stress',
    chartQueryType: 'STRESS',
  },
  [SectionEnum.JOURNAL + JournalMeasurementEnum.APPETITE]: {
    showScores: false,
    showData: true,
    chartsToDisplay: ['byDate', 'weekly'],
    units: '',
    yLabel: 'appetite',
    title: 'Appetite',
    chartQueryType: 'APPETITE',
  },
})

export const usePageConfig = (section: string | null, key: string | null) => {
  const scoreSettings = useSelector(scoreSelector)
  const outcomesTrackingEnabled = useFeatureFlag(Feature.OutcomesTracking)
  const pageConfig = getPageConfig(scoreSettings, { outcomesTrackingEnabled })

  if (!section || !key) {
    return null
  }

  return pageConfig[section + key] as PageConfig
}
