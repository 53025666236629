interface ImageSource {
  uri?: string
}

export class RemoteIcon {
  source?: string = undefined

  imageSource?: ImageSource = undefined

  constructor(source?: string) {
    this.source = source
    this.imageSource = { uri: source }
  }
}
