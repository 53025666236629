import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'
import { SettingsStoreState } from '@src/screens/Settings/models/settings.types'

export const settingsSelector = (state: RootStoreState): SettingsStoreState => state.settings

export const showNetCarbsStoreStateSelector = createSelector(
  settingsSelector,
  (settings) => settings.showNetCarbs,
)

export const canScanExpiredSelector = createSelector(
  settingsSelector,
  (settings) => settings.canScanExpired,
)

export const unitSystemStoreStateSelector = createSelector(
  settingsSelector,
  (settings) => settings.unitSystem,
)

export const macroGoalsDailySelector = createSelector(
  settingsSelector,
  (settings) => settings.macroGoalsDaily,
)

export const fastingSettingsSelector = createSelector(settingsSelector, (settingsState) => ({
  fastingStart: settingsState.fastingStart,
  fastingEnd: settingsState.fastingEnd,
}))

export const thresholdsSettingsSelector = createSelector(settingsSelector, (settingsState) => ({
  glucoseLowThreshold: settingsState.glucoseLowThreshold,
  glucoseHighThreshold: settingsState.glucoseHighThreshold,
  ketoneLowThreshold: settingsState.ketoneLowThreshold,
  ketoneHighThreshold: settingsState.ketoneHighThreshold,
}))

export const darkModeStoreStateSelector = createSelector(
  settingsSelector,
  (settings) => settings.darkMode,
)

export const statsSettingsStateSelector = createSelector(
  settingsSelector,
  (settings) => settings.statsSettings,
)

export const integrationsSyncSettingsSelector = createSelector(settingsSelector, (settings) => {
  return settings.integrationsSyncSettings || {}
})

export const remindersSelector = createSelector(settingsSelector, (settings) => {
  return settings.userReminders || []
})
