import React from 'react'
import { CoursesModuleParams } from '@src/navigation/types'
import { LessonItemContent, LessonItemContentMedia } from './models/courses.types'

export interface LessonItemContentProps extends CoursesModuleParams {
  currentStep: number
  totalSteps: number
  item: LessonItemContent | LessonItemContentMedia
  isCompleted: boolean
  nextButtonTitle: string
  onBack: () => void
  onNext: () => void
  onComplete: () => void
  onClose: () => void
  children?: React.ReactNode
}

export interface EngagementPromptConfig {
  canShowStartPrompt: boolean
  canShowResumePrompt: boolean
  courseStartDisplayCount: number
  courseResumeDisplayCount: number
  courseStartLastDisplayDate: string
  courseResumeLastDisplayDate: string
  lastLessonCompletedDate: string
}

export enum EditorBlockType {
  Image = 'image',
  Header = 'header',
  Paragraph = 'paragraph',
  List = 'list',
}

interface EditorBlockImageDataProps {
  width: number
  height: number
  url: string
}

interface EditorBlockImageData {
  file: EditorBlockImageDataProps & {
    formats?: {
      medium?: EditorBlockImageDataProps
    }
  }
}

interface EditorBlockImage {
  id: string
  type: EditorBlockType.Image
  data: EditorBlockImageData
}

interface EditorBlockParagraph {
  id: string
  type: EditorBlockType.Paragraph
  data: {
    text: string
  }
}

interface EditorBlockHeader {
  id: string
  type: EditorBlockType.Header
  data: {
    text: string
  }
}

interface EditorBlockList {
  id: string
  type: EditorBlockType.List
  data: {
    items: string[]
    style: 'ordered' | 'unordered'
  }
}

export type EditorBlock =
  | EditorBlockImage
  | EditorBlockParagraph
  | EditorBlockHeader
  | EditorBlockList
