import { intersection } from 'lodash'
import { Model } from '@models'
import { DEFAULT_GOALS } from '@src/components/nutritionCard/utils'
import { reducers } from '@src/screens/Settings/reducers'
import { DailyMeasurementsSourcePriority as SourcePriority } from '@src/types'
import { dailyMeasurementsSourcePrioritiesSelector as sourcePrioritiesSelector } from '@selectors/integrations'
import { dailyMeasurementSourceDefLookupSelector as sourceDefLookupSelector } from '@selectors/app'
import { HealthDataMeasurementSourceDefLookup } from '@models/app.types'
import commonReducers from '@src/models/commonReducers'
import { UPDATE_SETTINGS } from '../graphql/updateSettings'
import { FETCH_TERRA_PROVIDERS } from '../graphql/terraProviders'
import { GENERATE_TERRA_AUTH_URL } from '../graphql/generateTerraAuthUrl'
import { DEAUTH_TERRA_PROVIDER } from '../graphql/deauthTerraProvider'
import { TOGGLE_PROVIDER_DATA_TYPE } from '../graphql/toggleProviderDataType'
import { FETCH_LIBRE_LINKUP_CONNECTION } from '../graphql/linkupConnection'
import { DISABLE_LIBRE_LINKUP_CONNECTION } from '../graphql/disableLibreLinkupConnection'
import { INIT_LIBRE_LINKUP_CONNECTION } from '../graphql/initLibreLinkupConnection'
import { COMPLETE_LIBRE_LINKUP_CONNECTION } from '../graphql/completeLibreLinkupConnection'
import { FETCH_DAILY_MEASUREMENTS_SOURCE_PRIORITY } from '../graphql/fetchDailyMeasurementsSourcePriority'
import { UPDATE_DAILY_MEASUREMENTS_SOURCE_PRIORITY } from '../graphql/updateDailyMeasurementsSourcePriority'
import { CREATE_REMINDER } from '../graphql/createReminder'
import { FETCH_REMINDERS } from '../graphql/fetchReminders'
import { DELETE_REMINDER } from '../graphql/deleteReminder'
import { UPDATE_REMINDER } from '../graphql/updateReminder'
import { GENERATE_TERRA_SDK_TOKEN } from '../graphql/generateTerraSdkToken'
import { SCHEDULE_REMINDER } from '../graphql/scheduleReminder'
import { SourcePriorities, SourcePriorityListItem, UnitSystem } from './settings.types'

export const DEFAULT_USER_SETTINGS = {
  healthKitSync: false,
  ketoMojoSync: false,
  canScanExpired: false,
  reminders: true,
  showNetCarbs: true,
  scanReminder: 8,
  glucoseLowThreshold: 70,
  glucoseHighThreshold: 140,
  ketoneLowThreshold: 1,
  ketoneHighThreshold: 5,
  dashboardGraph: 'glucose',
  unitSystem: UnitSystem.Imperial,
  fastingStart: 20,
  fastingEnd: 8,
  macroGoalsDaily: DEFAULT_GOALS,
  patternsNotifications: true,
  integrationsSyncSettings: {
    lastSyncedHealth: null,
    lastSyncedKetoMojo: null,
  },
  terraProviders: [],
  libreLinkupConnection: null,
  dailyMeasurementsSourcePriorities: {},
  userReminders: [],
}

export const DEFAULT_DEVICE_SETTINGS = {
  darkMode: 'auto',
}

export const KEYS_TO_INCLUDE = Object.keys(DEFAULT_USER_SETTINGS)

export const DEFAULT_STATE = {
  backup: null,
  statsSettings: [],
  ...Model.defaultState,
  ...DEFAULT_USER_SETTINGS, // user settings
  ...DEFAULT_DEVICE_SETTINGS, // device settings
}

const dailyMeasurementsSourcePriorityReducer = {
  name: 'updateSettings',
  *payload(response: SourcePriority, { select }: any) {
    const sourceDefLookup: HealthDataMeasurementSourceDefLookup = yield select(
      sourceDefLookupSelector,
    )
    const currentSourcePriorities: SourcePriorities = yield select(sourcePrioritiesSelector)
    return {
      dailyMeasurementsSourcePriorities: {
        ...currentSourcePriorities,
        [response.type]: response.sources.map((source) => {
          const sourceDef = sourceDefLookup[source]
          return {
            ...sourceDef,
            title: sourceDef.label,
            icon: sourceDef.iconDef,
          } as SourcePriorityListItem
        }),
      },
    }
  },
}

export default class Settings {
  namespace = 'settings'

  state = DEFAULT_STATE

  effects = {
    update: Model.buildEffect({
      name: `${this.namespace}/update`,
      optimistic: true,
      query: UPDATE_SETTINGS,
      dataPath: 'updateSettings',
      *onSuccess(payload, { put, effectPayload }) {
        if (effectPayload.unitSystem) {
          yield put({ type: 'app/clearCaches' })
          yield put({ type: 'app/config' })
          return
        }

        if (
          intersection(Object.keys(effectPayload), [
            'dashboardGraph',
            'ketoneLowThreshold',
            'ketoneHighThreshold',
          ]).length > 0
        ) {
          yield put({ type: 'events/cacheClear' })
          yield put({ type: 'insights/cacheClear' })
          yield put({ type: 'events/fetchCharts' })
          return
        }

        if ('glucoseLowThreshold' in effectPayload || 'glucoseHighThreshold' in effectPayload) {
          yield put({ type: 'events/cacheClear' })
          yield put({ type: 'insights/cacheClear' })
          yield put({
            type: 'events/setGlucoseThresholdsUpdated',
            payload: { updatedAt: payload.updatedAt },
          })
          yield put({ type: 'events/fetchCharts' })
          return
        }

        if (
          intersection(Object.keys(effectPayload), [
            'fastingStart',
            'fastingEnd',
            'macroGoalsDaily',
          ]).length > 0
        ) {
          yield put({ type: 'insights/cacheClear' })
          return
        }
      },
      optimisticReducers: [{ name: 'backup' }, { name: 'updateSettings' }],
      reducers: [{ name: 'updateSettings' }],
      errorReducers: [{ name: 'restore' }],
    }),

    updateIntegrationsSyncSettings: Model.buildEffect({
      name: `${this.namespace}/updateIntegrationsSyncSettings`,
      reducers: [
        {
          name: 'update',
          payload: (_: any, { effectPayload }: any) => {
            return {
              integrationsSyncSettings: effectPayload,
            }
          },
        },
      ],
    }),

    fetchDailyMeasurementsSourcePriority: Model.buildEffect({
      name: `${this.namespace}/fetchDailyMeasurementsSourcePriority`,
      query: FETCH_DAILY_MEASUREMENTS_SOURCE_PRIORITY,
      dataPath: 'dailyMeasurementsSourcePriority',
      reducers: [dailyMeasurementsSourcePriorityReducer],
    }),

    updateDailyMeasurementsSourcePriority: Model.buildEffect({
      name: `${this.namespace}/updateDailyMeasurementsSourcePriority`,
      query: UPDATE_DAILY_MEASUREMENTS_SOURCE_PRIORITY,
      dataPath: 'updateDailyMeasurementsSourcePriority',
      reducers: [dailyMeasurementsSourcePriorityReducer],
    }),

    fetchTerraProviders: Model.buildEffect({
      name: `${this.namespace}/fetchTerraProviders`,
      query: FETCH_TERRA_PROVIDERS,
      dataPath: 'allTerraProviders',
      reducers: [
        {
          name: 'updateSettings',
          payload: (response: any) => ({ terraProviders: response.providers }),
        },
      ],
    }),

    generateTerraAuthUrl: Model.buildEffect({
      name: `${this.namespace}/generateTerraAuthUrl`,
      query: GENERATE_TERRA_AUTH_URL,
      dataPath: 'generateTerraAuthUrl',
    }),

    generateTerraSdkToken: Model.buildEffect({
      name: `${this.namespace}/generateTerraSdkToken`,
      query: GENERATE_TERRA_SDK_TOKEN,
      dataPath: 'generateTerraSdkToken',
    }),

    deauthTerraProvider: Model.buildEffect({
      name: `${this.namespace}/deauthTerraProvider`,
      query: DEAUTH_TERRA_PROVIDER,
      dataPath: 'deauthTerraProvider',
      reducers: [
        {
          name: 'updateSettings',
          payload: (response: any) => ({ terraProviders: response.providers }),
        },
      ],
    }),

    toggleProviderDataType: Model.buildEffect({
      name: `${this.namespace}/toggleProviderDataType`,
      query: TOGGLE_PROVIDER_DATA_TYPE,
      dataPath: 'toggleProviderDataType',
      reducers: [
        {
          name: 'updateSettings',
          payload: (response: any) => ({ terraProviders: response.providers }),
        },
      ],
    }),

    fetchLibreLinkupConnection: Model.buildEffect({
      name: `${this.namespace}/fetchLibreLinkupConnection`,
      query: FETCH_LIBRE_LINKUP_CONNECTION,
      reducers: [
        {
          name: 'updateSettings',
          payload: (response: any) => ({ libreLinkupConnection: response.linkupConnection }),
        },
      ],
    }),

    disableLibreLinkupConnection: Model.buildEffect({
      name: `${this.namespace}/disableLibreLinkupConnection`,
      query: DISABLE_LIBRE_LINKUP_CONNECTION,
      reducers: [
        {
          name: 'updateSettings',
          payload: (response: any) => ({ libreLinkupConnection: response.disableLinkupConnection }),
        },
      ],
    }),

    initLibreLinkupConnection: Model.buildEffect({
      name: `${this.namespace}/initLibreLinkupConnection`,
      query: INIT_LIBRE_LINKUP_CONNECTION,
      reducers: [
        {
          name: 'updateSettings',
          payload: (response: any) => ({ libreLinkupConnection: response.initLinkupConnection }),
        },
      ],
    }),

    completeLibreLinkupConnection: Model.buildEffect({
      name: `${this.namespace}/completeLibreLinkupConnection`,
      query: COMPLETE_LIBRE_LINKUP_CONNECTION,
      reducers: [
        {
          name: 'updateSettings',
          payload: (response: any) => ({
            libreLinkupConnection: response.completeLinkupConnection,
          }),
        },
      ],
    }),

    createReminder: Model.buildEffect({
      name: `${this.namespace}/createReminder`,
      query: CREATE_REMINDER,
      dataPath: 'createReminder',
      reducers: [
        {
          name: 'appendListItem',
          storePath: 'userReminders',
          dataPath: 'createReminder',
        },
      ],
    }),
    fetchReminders: Model.buildEffect({
      name: `${this.namespace}/fetchReminders`,
      query: FETCH_REMINDERS,
      dataPath: 'reminders',
      reducers: [
        {
          name: 'fetchList',
          storePath: 'userReminders',
          dataPath: 'reminders',
        },
      ],
    }),
    updateReminder: Model.buildEffect({
      name: `${this.namespace}/updateReminder`,
      query: UPDATE_REMINDER,
      dataPath: 'updateReminder',
      reducers: [
        {
          name: 'updateListItem',
          storePath: 'userReminders',
          dataPath: 'updateReminder',
        },
      ],
    }),
    deleteReminder: Model.buildEffect({
      name: `${this.namespace}/deleteReminder`,
      query: DELETE_REMINDER,
      dataPath: 'deleteReminder',
      reducers: [
        {
          name: 'removeListItem',
          storePath: 'userReminders',
          dataPath: 'deleteReminder',
        },
      ],
    }),
    scheduleReminder: Model.buildEffect({
      name: `${this.namespace}/scheduleReminder`,
      query: SCHEDULE_REMINDER,
      dataPath: 'scheduleReminder',
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    ...reducers,
    fetchList: commonReducers.fetchList,
    appendListItem: commonReducers.appendOrReplaceList,
    removeListItem: commonReducers.deleteList,
    updateListItem: commonReducers.updateListItem,
  }
}
