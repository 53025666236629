import React, { useEffect, useRef } from 'react'
import { Image, ImageStyle, View } from 'react-native'
import { useDispatch } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/core'
import { startCase } from 'lodash'
import { useRoute } from '@react-navigation/native'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Button, Text } from '@components/base'
import { Benefit, ScrollViewWithFade } from '@components'
import { NavigationContainer } from '@screens/Common/containers'
import { useGoBack, useSnack } from '@src/utils'
import { UserSensorKind } from '@src/types'
import { libre3BluetoothImage } from '@src/assets/images'
import { AppRouteProp } from '@src/navigation/types'
import { useSubscriptionFromRoute } from '../utils/hooks'

export const CancelSubscriptionSwitchSensor = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const goBack = useGoBack()
  const { subscription } = useSubscriptionFromRoute()
  const showSnack = useSnack()
  const route = useRoute<AppRouteProp<'CancelSubscriptionSwitchSensor'>>()

  const { params } = route

  const { newSensorKind = UserSensorKind.Libre3 } = params || {}

  const loadingRef = useRef(false)

  useEffect(() => {
    if (!subscription) {
      goBack()
    }
  }, [goBack, subscription])

  if (!subscription) {
    return null
  }

  const handleSwitchPress = () => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    dispatch({
      type: 'app/switchSensorOnCancellation',
      success: () => {
        navigation.reset({
          index: 1,
          routes: [
            { name: 'Drawer', state: { index: 0, routes: [{ name: 'Dashboard' }] } },
            {
              name: 'SensorSwitchConfirmed',
              params: {
                newSensorKind,
              },
            },
          ],
        })
      },
      failure: () => {
        showSnack('Failed to switch sensor. Please try again.', null, 'error')
        loadingRef.current = false
      },
    })
  }

  const handleCancelPress = () => {
    const params = {
      subscriptionId: subscription.id,
      showInsuranceCallsUpsell: 'true',
    }

    navigation.navigate('CancelSubscriptionConfirmation', params)
  }

  return (
    <NavigationContainer title="Cancel Subscription" goBack={goBack}>
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <View style={styles.contentContainer}>
          <ScrollViewWithFade scrollViewStyle={styles.scrollView}>
            <Text type="large" style={styles.header}>
              Frustrated with daily scanning?
            </Text>
            <Text type="regular" style={styles.description}>
              Try the {startCase(newSensorKind)} with automatic Bluetooth data syncing.
            </Text>
            <Image source={libre3BluetoothImage.imageSource} style={styles.image as ImageStyle} />
            <View style={styles.benefits}>
              <Benefit
                benefit={
                  (
                    <>
                      <Text type="regular" bold>
                        No 8-hour scanning!
                      </Text>{' '}
                      Sends data to your phone via Bluetooth
                    </>
                  ) as any
                }
              />
              <Benefit
                benefit={
                  (
                    <>
                      <Text type="regular" bold>
                        Small profile:
                      </Text>{' '}
                      about the size of 2 stacked pennies
                    </>
                  ) as any
                }
              />
              <Benefit
                benefit={
                  (
                    <>
                      <Text type="regular" bold>
                        Limited, early access:
                      </Text>{' '}
                      switch today at no extra cost
                    </>
                  ) as any
                }
              />
            </View>
          </ScrollViewWithFade>
          <View style={styles.actionsContainer}>
            <Button
              type="primary"
              size="block"
              accessibilityLabel="Switch today"
              onPress={handleSwitchPress}
            >
              Switch today
            </Button>
            <Button
              type="transparent"
              size="block"
              accessibilityLabel="Cancel"
              style={styles.cancelButton}
              onPress={handleCancelPress}
            >
              Cancel my subscription
            </Button>
          </View>
        </View>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  scrollView: {
    flexGrow: 1,
  },
  header: {
    marginTop: 24,
    textAlign: 'center',
  },
  description: {
    marginTop: 16,
    textAlign: 'center',
    alignSelf: 'center',
    paddingHorizontal: 16,
    maxWidth: 250,
  },
  image: {
    marginTop: 32,
    width: 140,
    height: 135,
    alignSelf: 'center',
  },
  benefits: {
    marginTop: 32,
    marginBottom: 16,
    maxWidth: 302,
    alignSelf: 'center',
    width: '100%',
    paddingHorizontal: 16,
  },
  actionsContainer: {
    padding: 16,
  },
  cancelButton: {
    marginTop: 8,
  },
})
