import { gql } from '@apollo/client'

export const CORE_SETTINGS_FIELDS = gql`
  fragment CoreSettingsFields on UserSettings {
    id
    canScanExpired
    dashboardGraph
    fastingEnd
    fastingStart
    glucoseHighThreshold
    glucoseLowThreshold
    healthKitSync
    integrationsSyncSettings {
      lastSyncedHealth
      lastSyncedKetoMojo
      ketoMojoUser {
        refreshToken
        accessToken
        accessTokenExpirationDate
      }
    }
    ketoMojoSync
    ketoneHighThreshold
    ketoneLowThreshold
    patternsNotifications
    macroGoalsDaily {
      calories
      protein
      carbs
      netCarbs
      fat
    }
    reminders
    scanReminder
    showNetCarbs
    statsSettings {
      section
      key
      order
      visible
    }
    unitSystem
    updatedAt
  }
`
