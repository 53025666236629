import { TODAY } from '@src/config/momentFormat'
import { ScoreSectionEnum } from '@src/screens/Insights/utils/pageConfig'

export const CALENDAR_TABS: Record<number, string> = {
  1: TODAY,
  7: '7 days',
  14: '14 days',
  30: '30 days',
  60: '60 days',
  120: '120 days',
}

type ScoreDetails = Record<string, string>

export const SCORES_TITLES: Record<ScoreSectionEnum, ScoreDetails> = {
  daily_average: {
    peak_average: 'Peak',
    mean_average: 'Average',
    time_outside_range_average: 'Adaptability',
    std_dev_average: 'Variability',
  },
  meals_average: {
    meals_peak_average: 'Peak',
    meals_exposure_average: 'Exposure',
    meals_stability_average: 'Stability',
    meals_recovery_average: 'Recovery',
  },
}

export const BLOOD_PRESSURE_KEYS = ['systolic_blood_pressure', 'diastolic_blood_pressure']
