import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { TouchableSection } from '@components'
import { Debug } from '@src/config'
import { User } from '@src/utils'
import { MobileAppFeature } from '@src/types'

const AdminSection = () => {
  const navigation = useNavigation()

  if (!Debug.useAdminMenu || !User.hasFeature(MobileAppFeature.AdminAccess)) {
    return null
  }

  const onPress = () => {
    navigation.navigate('AdminSettings')
  }

  return <TouchableSection onPress={onPress} iconName="lock" title="Admin" showRightArrow />
}

export default AdminSection
