import React from 'react'
import { StyleSheet } from 'react-native'

export const VideoPlayer = (props: { [x: string]: any; source: any; style: any }) => {
  const { source, style, ...restProps } = props
  const attrs = {
    src: source.uri || source,
    autoPlay: true,
    loop: true,
    style: StyleSheet.flatten(style),
    ...restProps,
  }
  return <video {...attrs} />
}
