import React, { useCallback, useState } from 'react'
import { NativeSyntheticEvent, TextLayoutEventData } from 'react-native'
import { View } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { LoadingFastImage } from '@src/components/LoadingFastImage'
import { Text } from '@components/base'
import { LessonWithContent } from '../models/courses.types'

interface LessonCardContentProps {
  lesson: LessonWithContent
}

export const LessonCardContent = ({ lesson }: LessonCardContentProps) => {
  const styles = useStyleSheet(themedStyle)
  const [maxDescriptionLines, setMaxDescriptionLines] = useState(2)

  const attributes = lesson.thumbnail?.data?.attributes
  const thumbnail = attributes?.formats?.small?.url || attributes?.url

  const onTitleLayout = useCallback(
    (e: NativeSyntheticEvent<TextLayoutEventData>) => {
      setMaxDescriptionLines(e.nativeEvent.lines.length === 1 ? 3 : 2)
    },
    [setMaxDescriptionLines],
  )

  return (
    <>
      {thumbnail && (
        <LoadingFastImage source={{ uri: thumbnail }} style={styles.cardThumbnail as ImageStyle} />
      )}
      <View style={styles.cardContent}>
        <Text
          type="regular"
          bold
          style={styles.cardTitle}
          numberOfLines={2}
          onTextLayout={onTitleLayout}
        >
          {lesson.title}
        </Text>
        <Text type="regular" bold style={styles.cardDuration} numberOfLines={1}>
          {lesson.duration}
        </Text>
        <Text type="regular" style={styles.cardDescription} numberOfLines={maxDescriptionLines}>
          {lesson.description}
        </Text>
      </View>
    </>
  )
}

const themedStyle = StyleService.create({
  cardThumbnail: {
    width: 100,
    height: 116,
    marginRight: 8,
    resizeMode: 'cover',
    borderRadius: 4,
  },
  cardContent: {
    flex: 1,
  },
  cardTitle: {
    marginBottom: 1,
  },
  cardDuration: {
    color: 'theme.text.tertiary',
    marginBottom: 8,
  },
  cardDescription: {
    color: 'theme.text.secondary',
  },
})
