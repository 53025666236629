import React, { useState } from 'react'
import { View } from 'react-native'
import pluralize from 'pluralize'
import { useNavigation } from '@react-navigation/core'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Text } from '@components/base'
import { InlineAlert, SelectFromActionSheet } from '@components'
import { SelectOption } from '@src/components/CommonSelect.types'
import { UiInlineAlert } from '@src/types'
import { useSubscriptionFromRoute } from '../../utils/hooks'
import { PauseSubscriptionContainer } from './PauseSubscriptionContainer'

export const PauseSubscription = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const [selectedOption, setSelectedOption] = useState<SelectOption>()
  const { subscription } = useSubscriptionFromRoute()

  if (!subscription) {
    return null
  }

  const options = Array.from({ length: subscription.allowedPauseCycles }, (_, index) => ({
    value: index + 1,
    text: pluralize('month', index + 1, true),
  }))

  const handleSelect = (optionLabel: string) => {
    setSelectedOption(options.find((option) => option.text === optionLabel))
  }

  const handleNext = () => {
    if (!selectedOption) {
      return
    }

    const params = {
      cycleCount: selectedOption.value as number,
      subscriptionId: subscription.id,
    }

    navigation.navigate('PauseSubscriptionReview', params)
  }

  return (
    <PauseSubscriptionContainer
      title="Snooze your Program"
      subtitle="Don't be a stranger"
      description="Take a break and pause your subscription. You won't be charged while you are on a break."
      nextTitle="Continue"
      nextDisabled={!selectedOption}
      onNext={handleNext}
    >
      <View style={styles.container}>
        <Text type="regular">Snooze Duration</Text>
        <SelectFromActionSheet
          onChange={handleSelect}
          options={options.map((option) => option.text)}
          title="Select snooze duration"
          value={selectedOption?.text}
        />
        <InlineAlert
          category={UiInlineAlert.Info}
          message={`Snooze time available: ${subscription.allowedPauseCycles} months`}
          style={styles.alert}
        />
      </View>
    </PauseSubscriptionContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    marginTop: 32,
    paddingHorizontal: 16,
  },
  alert: {
    marginTop: 24,
  },
})
