import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { View } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button, Input } from '@components/base'
import { Validators } from '@src/utils'
import { useSnack } from '@src/utils/navigatorContext'

export const Libre2FaForm = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const isSubmittingRef = useRef(false)

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      libre2faCode: '',
    },
  })

  const onSubmit = (data: { libre2faCode: string }) => {
    if (isSubmittingRef.current) {
      return
    }

    isSubmittingRef.current = true

    dispatch({
      type: 'settings/completeLibreLinkupConnection',
      payload: data,
      complete: () => {
        isSubmittingRef.current = false
      },
      failure: (error: any) => {
        showSnack(error?.message || 'Please check verification code', null, 'error')
      },
    })
  }

  return (
    <View style={styles.container}>
      <View style={styles.container}>
        <Text type="regular" style={styles.title}>
          Please enter the code sent to your email
        </Text>
        <Controller
          control={control}
          name="libre2faCode"
          rules={{ required: true, validate: Validators.Libre2FACodeValidator }}
          render={({ field }) => (
            <Input
              {...field}
              hasError={!!errors.libre2faCode}
              errorText="Should be valid verification code"
              placeholder="Verification code"
            />
          )}
        />
      </View>
      <Button
        type="primary"
        size="block"
        accessibilityLabel="Confirm"
        disabled={!isValid}
        onPress={handleSubmit(onSubmit)}
      >
        Confirm
      </Button>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  title: {
    marginVertical: 16,
  },
})
