import React, { useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import * as Progress from 'react-native-progress'
import { useTheme } from '@ui-kitten/components/theme'
import { LoadingDots } from 'stream-chat-react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

import { chatbotResponseTimeoutInSecondsSelector } from '@src/selectors/app'
import { useIsProcessing } from '../utils/hooks'

export const CustomTypingIndicatorContainer = () => {
  const responseTimeoutInSeconds = useSelector(chatbotResponseTimeoutInSecondsSelector)
  const [progress, setProgress] = useState(0)
  const progressInterval = useRef<ReturnType<typeof setInterval> | null>(null)

  const { isProcessing } = useIsProcessing()

  const theme = useTheme()
  const styles = useStyleSheet(indicatorStyle)

  useEffect(() => {
    setProgress(0)

    if (!isProcessing) {
      return
    }

    const expectedTime = responseTimeoutInSeconds * 1000 * 0.6
    const intervalTime = 200
    const percentagePerInterval = intervalTime / expectedTime

    const handler = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 0.95) {
          progressInterval.current && clearInterval(progressInterval.current)
          progressInterval.current = null
          return 0.95
        }
        return Math.min(prevProgress + Math.random() * percentagePerInterval * 2, 0.95)
      })
    }, intervalTime)
    progressInterval.current = handler

    return () => {
      progressInterval.current && clearInterval(progressInterval.current)
      progressInterval.current = null
    }
  }, [isProcessing, responseTimeoutInSeconds])

  if (!isProcessing) {
    return null
  }

  return (
    <View style={[styles.container]} testID="typing-indicator">
      <View style={styles.textContainer}>
        <LoadingDots style={styles.loadingDots} />
        <Text type="regular" style={styles.typingText}>
          Nora is thinking...
        </Text>
      </View>
      <Progress.Bar
        progress={progress}
        width={null}
        color={theme['theme.primary.base']}
        unfilledColor={theme['theme.surface.base2']}
        borderWidth={0}
      />
    </View>
  )
}

const indicatorStyle = StyleService.create({
  container: {
    width: '100%',
    marginHorizontal: 16,
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  loadingDots: {
    marginLeft: 8,
  },
  typingText: {
    marginLeft: 8,
    color: 'theme.text.secondary',
  },
})
