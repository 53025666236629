import React from 'react'
import { View } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { LoadingFastImage } from '@components'
import { TutorialStep as TutorialStepData } from '../models/tutorials.types'
import { StepAction } from './StepAction'

export const ListTutorialStep = ({ step }: { step: TutorialStepData }) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.container}>
      {step.image?.data && (
        <LoadingFastImage
          source={{ uri: step.image.data.attributes.url }}
          style={styles.fastImage as ImageStyle}
        />
      )}
      <View style={styles.content}>
        <Text type="regular" style={styles.position}>
          Step {step.position}
        </Text>
        <Text type="regular" bold lineSpacing="tight">
          {step.title}
        </Text>
        {step.duration && (
          <Text type="small" style={styles.duration}>
            {step.duration}
          </Text>
        )}
        {step.description && (
          <Text type="small" bold lineSpacing="none" style={styles.description}>
            {step.description}
          </Text>
        )}
        {step.action?.length > 0 && <StepAction action={step.action[0]} step={step} />}
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'theme.background',
    padding: 16,
    marginBottom: 8,
    borderRadius: 8,
  },
  fastImage: {
    width: 105,
    height: 145,
    borderRadius: 8,
  },
  duration: {
    marginTop: 4,
  },
  content: {
    flex: 1,
    display: 'flex',
    flexWrap: 'nowrap',
    marginLeft: 16,
  },
  position: {
    color: 'theme.primary.base',
  },
  description: {
    marginVertical: 8,
  },
})
