import { useSelector } from 'react-redux'
import moment from 'moment'
import { eventsChartsSelector } from '@src/selectors/events'
import { SHORT_TWELVE_HOURS_TIME_FORMAT } from '@src/config/momentFormat'
import { useDefaultRangeConfig } from '@src/hooks/useDefaultRangeConfig'
import { useTimelineChartRanges } from '@src/components/Timeline/useTimelineChartRanges'
import { isDataAvailable } from '@src/components/Timeline/utils'
import { ChartTypes } from '@src/components/Timeline/constants'
import { useLiveGlucoseValue } from '@src/utils/hooks'
import { Feature, useFeatureFlag } from '@src/components'
import { useScoreStatistics } from './useScoreStatistics'

export const usePrimaryStatsData = ({ displayLastReading }: { displayLastReading: boolean }) => {
  const {
    units,
    summary: { mean = 0 },
  } = useScoreStatistics()

  const { primary } = useSelector(eventsChartsSelector)
  const libre3RealTime = useFeatureFlag(Feature.Libre3RealTime)

  const timelineChartRanges = useTimelineChartRanges(
    primary,
    primary.values.length > 0 && isDataAvailable(primary.values),
    ChartTypes.Glucose,
  )

  const { ranges: meanRanges = [] } = useDefaultRangeConfig('mean') ?? {}

  const { realTimeGlucoseValue, realTimeGlucoseTime } = useLiveGlucoseValue()

  const meanDisplayValue = mean ? `${mean}` : 'N/A'
  const meanUnits = mean ? units : ''

  if (displayLastReading && (!libre3RealTime || !realTimeGlucoseValue)) {
    const glucoseMeasurements = primary.values.filter(
      (value) => value.tag === 'Glucose' && value.y !== null,
    )

    const lastGlucoseMeasurement = glucoseMeasurements[glucoseMeasurements.length - 1]

    const lastReadingDisplayValue = lastGlucoseMeasurement ? `${lastGlucoseMeasurement.y}` : 'N/A'
    const lastReadingUnits = lastGlucoseMeasurement ? units : ''
    const lastReadingTime = lastGlucoseMeasurement
      ? `${moment(lastGlucoseMeasurement.x).format(SHORT_TWELVE_HOURS_TIME_FORMAT)}`
      : ''

    return {
      primaryStatRanges: timelineChartRanges,
      primaryStatValue: lastGlucoseMeasurement?.y,
      primaryStatDisplayValue: lastReadingDisplayValue,
      primaryStatUnits: lastReadingUnits,
      primaryStatTitle: 'Last Reading',
      lastReadingTime,
    }
  }

  if (libre3RealTime && displayLastReading && realTimeGlucoseValue) {
    return {
      primaryStatRanges: timelineChartRanges,
      primaryStatValue: realTimeGlucoseValue,
      primaryStatDisplayValue: realTimeGlucoseValue,
      primaryStatUnits: units,
      primaryStatTitle: 'Live Reading',
      isLiveReading: true,
      lastReadingTime: realTimeGlucoseTime
        ? moment(realTimeGlucoseTime).format(SHORT_TWELVE_HOURS_TIME_FORMAT)
        : undefined,
    }
  }

  return {
    primaryStatRanges: meanRanges,
    primaryStatValue: mean,
    primaryStatDisplayValue: meanDisplayValue,
    primaryStatUnits: meanUnits,
    primaryStatTitle: 'Daily Average',
    lastReadingTime: undefined,
  }
}
