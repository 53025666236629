/* eslint-disable max-len */
import * as React from 'react'
import { Circle } from 'react-native-svg'
import NsPermission from './nsPermission'

const NsSensor = () => {
  return (
    <NsPermission>
      <Circle cx="185.5" cy="47.5" r="17.5" stroke="#3599EF" strokeWidth="2" />
      <Circle cx="185.5" cy="47.5" r="13.5" stroke="#3599EF" strokeWidth="2" />
    </NsPermission>
  )
}

export default NsSensor
