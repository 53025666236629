import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { Rect } from 'react-content-loader/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Pill, Text } from '@components/base'
import { ContentLoader } from '@src/components/ContentLoader'
import { Feature, ProgressCircle, useFeatureFlag } from '@src/components'
import { ModuleWithContent } from '../models/courses.types'

interface ModuleCardProps {
  module: ModuleWithContent
  onPress: (module: ModuleWithContent, isLocked: boolean) => void
  isStartable: boolean
}

export const ModuleCard = ({ module, onPress, isStartable }: ModuleCardProps) => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)
  const allCourseModulesUnlocked = useFeatureFlag(Feature.AllCourseModulesUnlocked)
  const isCompleted = module.progress?.complete

  const renderTag = (module: ModuleWithContent) => {
    if (!isStartable && !isCompleted) {
      return null
    }

    let tagText = 'Completed'

    if (!isCompleted) {
      tagText = module.progress ? 'Continue' : 'Start'
    }

    return <Pill fill="moonstone" size="s" text={tagText} style={styles.tag} />
  }

  const lessons = module.lessons || []
  const isLocked = !allCourseModulesUnlocked && !isCompleted && !isStartable
  const completedLessons = lessons.filter((lesson) => lesson.progress?.complete)
  const progress = completedLessons.length / (lessons.length || 1)

  return (
    <TouchableOpacity
      style={styles.card}
      onPress={() => onPress(module, isLocked)}
      accessibilityLabel={module.title}
    >
      <View style={styles.cardContent}>
        {typeof module.position === 'number' && (
          <View style={styles.modulePositionContainer}>
            {isLocked && <Icon name="lock" style={styles.lockIcon} />}
            <Text type="small" bold lineSpacing="tight" style={styles.modulePositionText}>
              MODULE {module.position}
            </Text>
          </View>
        )}
        <Text type="regular" bold lineSpacing="tight">
          {module.title}
        </Text>
        {isLocked && (
          <Text type="small" lineSpacing="none" style={styles.subtitle}>
            Complete the previous module to unlock.
          </Text>
        )}
        {renderTag(module)}
      </View>
      <View style={styles.progressContainer}>
        <ProgressCircle
          style={themedStyle.progressCircle}
          progress={progress}
          progressColor={theme['theme.primary.base']}
          backgroundColor={theme['theme.surface.base']}
          strokeWidth={6}
        />
        <Text type="small" lineSpacing="none" style={styles.progressText}>
          {`${completedLessons.length || 0}/${lessons.length || 0}`}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

export const LoadingModuleCard = () => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.card}>
      <ContentLoader>
        <Rect x="0" y="0" width="40%" height="15" rx="6" ry="6" />
        <Rect x="0" y="20" width="90%" height="20" rx="6" ry="6" />
        <Rect x="0" y="45" width="100%" height="15" rx="6" ry="6" />
        <Rect x="0" y="65" width="20%" height="15" rx="6" ry="6" />
      </ContentLoader>
    </View>
  )
}

const themedStyle = StyleService.create({
  card: {
    padding: 16,
    marginHorizontal: 24,
    marginVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 16,
    backgroundColor: 'theme.secondary.base',
    minHeight: 112, // loading content + padding
  },
  cardContent: {
    flex: 1,
    alignItems: 'flex-start',
  },
  subtitle: {
    color: 'theme.text.secondary',
    marginTop: 4,
  },
  modulePositionContainer: {
    marginBottom: 4,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  modulePositionText: {
    color: 'theme.text.secondary',
  },
  lockIcon: {
    color: 'theme.text.secondary',
    width: 12,
    height: 12,
    marginRight: 4,
  },
  tag: {
    marginTop: 8,
  },
  progressContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 56,
    height: 56,
  },
  progressCircle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  progressText: {
    marginTop: 4,
    color: 'theme.text.secondary',
  },
})
