import { gql } from '@apollo/client/core'
import { CORE_SUBSCRIPTION_FIELDS } from '@src/graphql/fragments/subscription'

const PAUSE_SUBSCRIPTION = gql`
  ${CORE_SUBSCRIPTION_FIELDS}
  mutation pauseSubscription($id: ID!, $cycleCount: Int!) {
    pauseSubscription(id: $id, cycleCount: $cycleCount) {
      ...CoreSubscriptionFields
    }
  }
`

export default PAUSE_SUBSCRIPTION
