import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { Text, TextProps } from '@components/base'
import { emojiByScore, isReverseRange } from '@src/screens/JournalEntries/utils'
import { journalMeasurementRangeSelector } from '@src/selectors/app'

interface ValueWithEmojiProps {
  value: string | number
  emojiSize: number
  itemKey: string
}

export const ValueWithEmoji = ({
  value,
  emojiSize,
  itemKey,
  ...textProps
}: ValueWithEmojiProps & TextProps) => {
  const sliderRange = useSelector(journalMeasurementRangeSelector)

  if (value === 'N/A') {
    return null
  }

  const numValue = typeof value === 'number' ? value : parseInt(value.trim())
  const score = isReverseRange(itemKey) ? sliderRange.max + sliderRange.min - numValue : numValue
  const Emoji = emojiByScore(score)

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text {...textProps}>{value} </Text>
      <Emoji width={emojiSize} height={emojiSize} />
    </View>
  )
}
