import React, { useEffect } from 'react'
import { ImageBackground, Image } from '../react-native'

const FastImage = (props) => {
  const { onLoad } = props
  useEffect(() => {
    if (onLoad) {
      onLoad()
    }
  }, [onLoad])

  return props.children ? <ImageBackground {...props} /> : <Image {...props} />
}

FastImage.resizeMode = {
  contain: 'contain',
  cover: 'cover',
  stretch: 'stretch',
  center: 'center',
}

export default FastImage
