import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { TutorialLink } from '../Tutorial/TutorialLink'

interface GeneralCardProps {
  children: JSX.Element
  rightContent?: JSX.Element
  title: string
  tutorialKey?: string
  mode?: 'normal' | 'compact'
}

export const GeneralCard = (props: GeneralCardProps) => {
  const { children, rightContent, title, tutorialKey, mode = 'normal' } = props
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.card}>
      <View style={[styles.row, mode === 'compact' && styles.compactRow]}>
        {mode === 'normal' ? (
          <>
            <View style={styles.topSection}>
              <Text type="large" bold lineSpacing="tight">
                {title}
              </Text>
              {tutorialKey && <TutorialLink tutorialKey={tutorialKey} />}
            </View>
            {rightContent}
          </>
        ) : (
          <>
            <Text type="large" bold lineSpacing="tight">
              {title}
            </Text>
            {tutorialKey && <TutorialLink tutorialKey={tutorialKey} />}
          </>
        )}
      </View>
      <View style={styles.content}>{children}</View>
    </View>
  )
}

const themedStyle = StyleService.create({
  card: {
    flexDirection: 'column',
  },
  row: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 16,
    paddingVertical: 6,
  },
  compactRow: {
    paddingBottom: 10,
    paddingRight: 35,
  },
  topSection: {
    flexDirection: 'column',
  },
  content: {
    alignItems: 'center',
  },
})
