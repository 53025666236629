import React from 'react'
import { useNavigation, useRoute } from '@react-navigation/core'
import { View } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { FadeFromBottomModal } from '@components'
import { useGoBack } from '@src/utils/navigation'
import { AppRouteProp, AppStackParamList } from '@src/navigation/types'

export const CancelModal = () => {
  const styles = useStyleSheet(themedStyles)
  const route = useRoute<AppRouteProp<'CancelModal'>>()
  const params = route.params

  const {
    title,
    cancelText,
    confirmText,
    parentScreen,
    confirmScreen,
    confirmScreenParams,
    cancelScreen,
    cancelScreenParams,
    replace,
  } = params

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const goBack = useGoBack()

  const onCancelButtonPress = () => {
    if (cancelScreen) {
      if (replace) {
        navigation.replace(cancelScreen, cancelScreenParams)
      } else {
        navigation.navigate({ name: cancelScreen, params: cancelScreenParams, merge: true })
      }
      return
    }
    navigation.navigate({ name: parentScreen as any, params: { cancel: false }, merge: true })
  }

  const onSaveButtonPress = () => {
    if (confirmScreen) {
      if (replace) {
        navigation.replace(confirmScreen, confirmScreenParams)
      } else {
        navigation.navigate({ name: confirmScreen, params: confirmScreenParams, merge: true })
      }
      return
    }
    navigation.navigate({ name: parentScreen as any, params: { cancel: true }, merge: true })
  }

  return (
    <FadeFromBottomModal closeModal={goBack} contentStyles={styles.content}>
      <View style={styles.container}>
        <Text type="large" bold lineSpacing="tight" style={styles.title}>
          {title}
        </Text>

        <Button
          type="primary"
          size="block"
          accessibilityLabel="cancelButton"
          onPress={onCancelButtonPress}
        >
          {cancelText}
        </Button>
        {!!confirmText && (
          <Button
            type="transparent"
            size="block"
            style={styles.confirmButton}
            onPress={onSaveButtonPress}
            accessibilityLabel="saveButton"
          >
            {confirmText}
          </Button>
        )}
      </View>
    </FadeFromBottomModal>
  )
}

const themedStyles = StyleService.create({
  content: {
    backgroundColor: 'theme.background',
  },
  container: {
    marginTop: 16,
  },
  title: {
    textAlign: 'center',
    marginBottom: 24,
  },
  confirmButton: {
    marginTop: 8,
  },
})
