import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReactNativeFile } from '@src/utils/image'
import { AllFilesQuery } from '@src/graphql/fetchUserFiles.generated'
import {
  SortDirection,
  FileSortField,
  FileDynamicFilterField,
  DynamicFilterItemOperation,
  UserFileTag,
  DynamicFilterOperator,
} from '@src/types'
import { QuestionnairePhoto } from '@src/components/PhotoUploading'
import { FileUploadEndpoints } from '../types'

type UseUploadedFileLoading = {
  loading: true
  file: QuestionnairePhoto | ReactNativeFile | null
}
type UseUploadedFileFinalized = {
  file: QuestionnairePhoto | ReactNativeFile | null
  clear: () => void
  set: (file: QuestionnairePhoto | ReactNativeFile) => void
}
export const useUploadedFile = (
  key: UserFileTag,
  endpoint: FileUploadEndpoints,
): UseUploadedFileFinalized | UseUploadedFileLoading => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [file, setFile] = useState<null | QuestionnairePhoto | ReactNativeFile>(null)

  const fetchHandler: Record<
    FileUploadEndpoints,
    () => Promise<QuestionnairePhoto | null>
  > = useMemo(
    () => ({
      [FileUploadEndpoints.USER_FILES]: () =>
        new Promise((resolve, reject) => {
          dispatch({
            type: 'app/fetchUserFiles',
            payload: {
              sorts: [
                {
                  direction: SortDirection.Desc,
                  field: FileSortField.CreatedAt,
                },
              ],
              dynamicFilters: {
                items: [
                  {
                    field: FileDynamicFilterField.Tag,
                    operation: DynamicFilterItemOperation.Eq,
                    value: key,
                  },
                ],
                operator: DynamicFilterOperator.And,
              },
            },
            success: (data: AllFilesQuery['allFiles']) => {
              const [matchingFile] = data.files
              if (matchingFile) {
                resolve({
                  type: 'file_upload',
                  name: matchingFile.fileName,
                  uri: matchingFile.downloadFileUrl,
                  id: matchingFile.id,
                })
              }
              resolve(null)
            },
            failure: reject,
          })
        }),
    }),
    [dispatch, key],
  )

  useEffect(() => {
    const handler = async () => {
      try {
        const photo = await fetchHandler[endpoint]()
        setFile(photo)
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    handler()
  }, [endpoint, fetchHandler])

  const clear = useCallback(() => setFile(null), [setFile])
  const set = useCallback((file: QuestionnairePhoto | ReactNativeFile) => setFile(file), [setFile])

  if (loading) {
    return { loading, file }
  }

  return { file, clear, set }
}
