import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigation, RouteProp, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Text } from '@components/base'
import { BottomSheet, InlineAlert } from '@components'
import { AppStackParamList } from '@src/navigation/types'
import { healthSettingsSelector } from '@src/selectors/app'
import { OptionsSlider } from '@src/screens/Settings/components/OptionsSlider'
import { ThresholdType } from '@src/screens/Settings/models/settings.types'
import { useGoBack } from '@src/utils/navigation'
import { useSnack } from '@src/utils/navigatorContext'
import { UiInlineAlert } from '@src/types'
import { useGetGlucoseDescriptor } from '../../utils/hooks'
import { useThresholdSettings } from './useThresholdSettings'
import { GlucosePresetOption } from './GlucosePresetOption'

export const GlucoseThresholdsModal = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const getGlucoseDescriptor = useGetGlucoseDescriptor()
  const showSnack = useSnack()
  const { glucoseThresholdPresets } = useSelector(healthSettingsSelector)

  const {
    params: { source },
  } = useRoute<RouteProp<AppStackParamList, 'GlucoseThresholdSettings'>>()

  const {
    allOptions,
    lowOptions,
    highOptions,
    selectedLowOption,
    selectedHighOption,
    onThresholdChange,
    saveThresholds,
  } = useThresholdSettings(ThresholdType.Glucose, source)

  const initialPreset = glucoseThresholdPresets.find(
    (preset) =>
      preset.low === selectedLowOption?.value && preset.high === selectedHighOption?.value,
  )

  const [selectedPreset, setSelectedPreset] = useState<string | undefined>(
    () => initialPreset?.name,
  )

  const onDismiss = () => {
    if (navigation.canGoBack()) {
      goBack()
    } else {
      navigation.replace('Drawer', { screen: 'Dashboard' })
    }
  }

  const onSaveButtonPress = async () => {
    try {
      await saveThresholds()
      onDismiss()
    } catch {
      showSnack('Failed to save, please try again', null, 'error')
    }
  }

  const onSelectPreset = (name?: string) => {
    setSelectedPreset(name)

    const newPreset = glucoseThresholdPresets.find((preset) => preset.name === name)

    if (!newPreset) {
      return
    }

    const low = getGlucoseDescriptor(newPreset.low)
    const high = getGlucoseDescriptor(newPreset.high)

    onThresholdChange(
      { value: newPreset.low, text: `${low.value} ${low.unit}` },
      { value: newPreset.high, text: `${high.value} ${high.unit}` },
    )
  }

  return (
    <BottomSheet
      header="Glucose Target Range"
      primaryButton={{ text: 'Save', onPress: onSaveButtonPress }}
      secondaryButton={{ text: 'Cancel', onPress: onDismiss }}
    >
      <Text type="large" style={styles.description}>
        Select the glucose range that matches your health goals. Need help? Your nutritionist can
        help find a glucose range that best suits you!
      </Text>
      {glucoseThresholdPresets.map((preset) => {
        const low = getGlucoseDescriptor(preset.low)
        const high = getGlucoseDescriptor(preset.high)

        return (
          <GlucosePresetOption
            key={preset.name}
            title={`${preset.name}: ${low.value}-${high.value} ${low.unit}`}
            description={preset.description}
            selected={selectedPreset === preset.name}
            onSelect={() => onSelectPreset(preset.name)}
          />
        )
      })}
      <GlucosePresetOption
        title="Custom Range"
        description="Customize your glucose range to fit your unique lifestyle and health goals"
        selected={!selectedPreset}
        onSelect={() => onSelectPreset(undefined)}
      />
      {!selectedPreset && (
        <OptionsSlider
          selectedOptions={[selectedLowOption, selectedHighOption]}
          limits={[lowOptions[lowOptions.length - 1].value, highOptions[0].value]}
          options={allOptions}
          onChange={onThresholdChange}
        />
      )}
      <InlineAlert
        category={UiInlineAlert.Info}
        message="Updating the target range will recalculate your past scores"
        style={styles.alert}
      />
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  description: {
    marginTop: 16,
    marginBottom: 24,
    textAlign: 'center',
  },
  alert: {
    marginVertical: 16,
  },
})
