import { useEffect } from 'react'
import { Question, SurveysConfigKind } from '@src/types'
import { QuestionnaireConfigInteractor } from '../QuestionnaireConfigInteractor'
import { useLoadSurveyLink } from './useLoadSurveyLink'

export const useQuestionnaireState = (kind: SurveysConfigKind) => {
  const {
    loadSurveyLink,
    surveyLink,
    questionnaireConfigInteractor,
    currentQuestionIdentifier,
    setCurrentQuestionIdentifier,
    questionnaireForm,
  } = useLoadSurveyLink(kind)

  useEffect(() => {
    loadSurveyLink()
  }, [loadSurveyLink])

  if (!(questionnaireConfigInteractor instanceof QuestionnaireConfigInteractor)) {
    return questionnaireConfigInteractor
  }

  const { questions } = questionnaireConfigInteractor.getSectionConfig(
    currentQuestionIdentifier.sectionIndex,
  )
  const currentQuestion = questions[currentQuestionIdentifier.questionIndex]

  const currentSectionConfig = questionnaireConfigInteractor.getSectionConfig(
    currentQuestionIdentifier.sectionIndex,
  )

  const addQuestionAfterCurrent = (question: Question) => {
    questionnaireConfigInteractor.addQuestionAfterCurrent(question, currentQuestionIdentifier)
  }

  const removeQuestionByKey = (key: string) => {
    questionnaireConfigInteractor.removeQuestionByKey(key, currentQuestionIdentifier)
  }

  return {
    currentQuestionIdentifier,
    setCurrentQuestionIdentifier,
    currentQuestion,
    isLastQuestion: () => questionnaireConfigInteractor.isLastQuestion(currentQuestionIdentifier),
    isLastQuestionInSection: () =>
      questionnaireConfigInteractor.isLastQuestionInSection(currentQuestionIdentifier),
    getSectionConfig: questionnaireConfigInteractor.getSectionConfig,
    getCurrentQuestionIndex: () =>
      questionnaireConfigInteractor.getCurrentQuestionIndex(currentQuestionIdentifier),
    getQuestionsCount: questionnaireConfigInteractor.getQuestionsCount,
    currentSectionConfig,
    numberOfSections: questionnaireConfigInteractor.getNumberOfSections(),
    questionnaireForm,
    surveyLink,
    addQuestionAfterCurrent,
    removeQuestionByKey,
    findQuestionStandaloneFollowupsWithAnswer:
      questionnaireConfigInteractor.findQuestionStandaloneFollowupsWithAnswer,
    findQuestionStandaloneFollowups: questionnaireConfigInteractor.findQuestionStandaloneFollowups,
    loadSurveyLink,
  }
}
