import { gql } from '@apollo/client'

const MEAL_IMAGE_DESCRIPTION = gql`
  query mealImageDescription($image: Upload!) {
    mealImageDescription(image: $image) {
      description
    }
  }
`

export default MEAL_IMAGE_DESCRIPTION
