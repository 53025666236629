import React from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { TouchableOpacity } from 'react-native'
import { SensorStatus } from 'react-native-freestyle-libre'
import { useNavigation } from '@react-navigation/native'
import { StyleService } from '@src/style/service'
import { sensorSelector } from '@src/selectors/sensor'
import { InlineAlert } from '@src/components/InlineAlert'
import { MONTH_NAME_AND_DATE_FORMAT } from '@src/config/momentFormat'
import { Feature, useFeatureFlag } from '@src/components'
import { getTutorialGroup } from '@src/utils/tutorials'
import { useLibre3NativeEnabled } from '@src/utils/hooks'
import { UiInlineAlert } from '@src/types'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'

export const ExpiringSensorPopup = () => {
  const navigation = useNavigation()
  const sensor = useSelector(sensorSelector)

  const hidePopup = useFeatureFlag(Feature.AbbottPartnership)
  const { uxSensorModePendingLibre3 } = useLibre3NativeEnabled()
  const isScanDisabled = useDisableInAppScan()

  if (hidePopup) {
    return null
  }

  // We don't want to show this popup for expired sensors
  // Check for case of sensor expirationTime not existing when upgrading from old app versions
  if (!sensor || !sensor.expirationTime || sensor.status === SensorStatus.Expired) {
    return null
  }

  const sensorExpirationTime = moment(sensor.expirationTime)

  const hoursRemaining = sensorExpirationTime.diff(moment(), 'hours')

  if (hoursRemaining >= 12) {
    return null
  }

  const expirationTime = sensorExpirationTime.format(MONTH_NAME_AND_DATE_FORMAT)

  if (hoursRemaining >= 0) {
    return (
      <InlineAlert
        style={styles.popup}
        category={UiInlineAlert.Warning}
        message={`Your sensor expires ${expirationTime}`}
      />
    )
  }

  const popupText = isScanDisabled
    ? 'Your current sensor expired. Please start a new sensor'
    : `Your current sensor expired. Please make sure to do the last scan by ${expirationTime} ` +
      'to avoid loss of data.'

  const onPress = () => {
    if (uxSensorModePendingLibre3) {
      navigation.navigate('TutorialsGroup', {
        group: getTutorialGroup(uxSensorModePendingLibre3),
      })
    }
  }

  return (
    <TouchableOpacity accessibilityLabel="Activate sensor" activeOpacity={0.7} onPress={onPress}>
      <InlineAlert style={styles.popup} category={UiInlineAlert.Danger} message={popupText} />
    </TouchableOpacity>
  )
}

const styles = StyleService.create({
  popup: {
    marginHorizontal: 16,
    marginTop: 8,
  },
})
