import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Config from 'react-native-config'
import { useSelector } from 'react-redux'
import { Feature, useFeatureFlag } from '@src/components'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Storage } from '@src/utils'
import { BuildType } from '@src/config/app'
import { onboardingChecklistItemsSelector } from '@src/screens/Checklist/models/checklist.selectors'
import { useStorageValue } from '@src/utils/storage'

const TIME_CHECK_INTERVAL = 5 * 1000

const PRODUCTION_DELAY_TIME_IN_MILLISECONDS = 24 * 60 * 60 * 1000 // 1 day in milliseconds
const TESTING_DELAY_TIME_IN_MILLISECONDS = 2 * 60 * 1000 // 2 minutes in milliseconds

const build = Config.ENV as BuildType
const DELAY_TIME_IN_MILLISECONDS =
  build === 'production'
    ? PRODUCTION_DELAY_TIME_IN_MILLISECONDS
    : TESTING_DELAY_TIME_IN_MILLISECONDS

export const OnboardingChecklistBanner = () => {
  const navigation = useNavigation()

  const styles = useStyleSheet(themedStyles)

  const [firstLoginTimestampValue] = useStorageValue(Storage.APP_FIRST_LOGIN_TIMESTAMP_KEY)
  const firstLoginTimestamp = useMemo(() => {
    return firstLoginTimestampValue ? moment(firstLoginTimestampValue) : undefined
  }, [firstLoginTimestampValue])

  const [shouldShow, setShouldShow] = useState(false)
  const [visited, setVisited] = useStorageValue(Storage.ONBOARDING_CHECKLIST_REMINDER_VISITED_KEY)
  const intervalRef = useRef<ReturnType<typeof setInterval>>()

  const enableOnboardingChecklist = useFeatureFlag(Feature.OnboardingChecklist)

  const checklistItems = useSelector(onboardingChecklistItemsSelector)

  const completeItems = checklistItems.filter((item) => item.completed)

  const completeItemsCount = completeItems.length
  const itemsCount = checklistItems.length

  const allItemsCompleted = itemsCount > 0 && itemsCount === completeItemsCount

  const shouldPerformTimeCheck =
    firstLoginTimestamp && enableOnboardingChecklist && !visited && !allItemsCompleted

  useEffect(() => {
    if (!shouldPerformTimeCheck) {
      return
    }

    const callback = () => {
      if (DELAY_TIME_IN_MILLISECONDS < moment().diff(firstLoginTimestamp)) {
        clearInterval(intervalRef.current)
        setShouldShow(true)
      }
    }

    if (!intervalRef.current) {
      callback()
    }

    intervalRef.current = setInterval(callback, TIME_CHECK_INTERVAL)

    return () => {
      clearInterval(intervalRef.current)
    }
  }, [firstLoginTimestamp, shouldPerformTimeCheck])

  if (!enableOnboardingChecklist || !shouldShow || allItemsCompleted) {
    return null
  }

  const onPress = () => {
    navigation.navigate('OnboardingChecklist')
    setVisited(true)
    setShouldShow(false)
  }

  return (
    <TouchableOpacity
      accessibilityLabel="Onboarding Checklist"
      onPress={onPress}
      style={styles.container}
    >
      <View style={styles.iconContainer}>
        <Icon name="flag" size="20" weight="bold" style={styles.flagIcon} />
      </View>
      <View style={styles.textContainer}>
        <Text type="large" bold>
          Your Checklist
        </Text>
        <Text type="regular" style={styles.secondaryText}>
          Complete your remaining tasks!
        </Text>
      </View>
      <Icon name="caret-right" size="16" weight="bold" style={styles.caretIcon} />
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    marginTop: 8,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'theme.surface.banner',
  },
  iconContainer: {
    padding: 16,
    borderRadius: 56,
    backgroundColor: 'theme.primary.base',
    marginRight: 16,
  },
  flagIcon: {
    color: 'theme.solid.white',
  },
  textContainer: {
    flex: 1,
  },
  secondaryText: {
    color: 'theme.text.secondary',
  },
  caretIcon: {
    color: 'theme.surface.base0',
  },
})
