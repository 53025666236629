import React from 'react'
// eslint-disable-next-line no-restricted-imports
import { Toggle as ToggleBase, ToggleProps as TogglePropsBase } from '@ui-kitten/components'
import { Text } from '@src/components/base'

export interface ToggleProps extends Omit<TogglePropsBase, 'children'> {
  text?: string
}

export const Toggle = ({ text, ...toggleProps }: ToggleProps) => {
  return <ToggleBase {...toggleProps}>{() => <Text type="regular">{text}</Text>}</ToggleBase>
}
