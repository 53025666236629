import React from 'react'
import { useSelector } from 'react-redux'
import { StyleProp, ViewStyle } from 'react-native'
import { TouchableSection } from '@components'
import { termsOfUseSelector } from '@src/selectors/app'
import { openUrl } from '@src/utils'

interface TermsOfUseSectionProps {
  style?: StyleProp<ViewStyle>
}

const TermsOfUseSection = (props: TermsOfUseSectionProps) => {
  const url = useSelector(termsOfUseSelector)

  const onPress = () => {
    openUrl(url)
  }

  const { style } = props

  return <TouchableSection style={style} onPress={onPress} title="Terms of Use" />
}

export default TermsOfUseSection
