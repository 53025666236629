import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { Divider } from '@ui-kitten/components'
import { View } from 'react-native'
import { Product, SchedulePhase, Subscription } from '@src/types'
import { isSubscriptionActive } from '@src/hooks/useActiveSubscriptions'
import {
  isTrialUpgradable,
  getSubscriptionTagDescriptor,
  isProductUpgradable,
} from '../utils/utils'
import { ProductSummary } from './ProductSummary'

export interface ServicesProps {
  products: Product[]
  subscriptions: (Pick<
    Subscription,
    | 'id'
    | 'cancelAt'
    | 'price'
    | 'currentPeriodEndAt'
    | 'commitmentEndAt'
    | 'resumesAt'
    | 'resetBillingCycleAnchorAt'
    | 'status'
    | 'paused'
  > & {
    primaryProduct: Pick<Product, 'title' | 'details' | 'interval' | 'id'> & {
      eligibleUpgradeProducts: Pick<Product, 'id'>[]
    }
  })[]
  upcomingSubscriptionPhases: SchedulePhase[]
  onTrialUpgradePress: (
    subscription: Pick<Subscription, 'id' | 'cancelAt'> & { primaryProduct: Pick<Product, 'id'> },
  ) => void
  onProductUpgradePress: (
    subscription: Pick<Subscription, 'id'> & {
      primaryProduct: { eligibleUpgradeProducts: Pick<Product, 'id'>[] }
    },
  ) => void
}

export const Services = ({
  products,
  subscriptions,
  upcomingSubscriptionPhases,
  onTrialUpgradePress,
  onProductUpgradePress,
}: ServicesProps) => {
  const navigation = useNavigation()

  const handleManageSubscriptionPress = (
    subscription: Pick<Subscription, 'status' | 'id' | 'cancelAt'> & {
      primaryProduct: Pick<Product, 'id'> & { eligibleUpgradeProducts: Pick<Product, 'id'>[] }
    },
  ) => {
    if (isProductUpgradable(subscription)) {
      onProductUpgradePress(subscription)
    } else if (isTrialUpgradable(subscription)) {
      onTrialUpgradePress(subscription)
    } else {
      navigation.navigate('ProgramDetails', { subscriptionId: subscription.id })
    }
  }

  const handleManageUpcomingSubscriptionPress = () => {
    navigation.navigate('ManageUpcomingSubscriptionModal')
  }

  return (
    <>
      {subscriptions.map((subscription) => {
        const eligibleUpgradeProducts = products.filter((product) =>
          subscription.primaryProduct.eligibleUpgradeProducts.some(
            (minProduct) => minProduct.id === product.id,
          ),
        )

        return (
          <View key={subscription.id}>
            <ProductSummary
              product={subscription.primaryProduct}
              eligibleUpgradeProducts={eligibleUpgradeProducts}
              price={subscription.price}
              currentPeriodEndAt={subscription.currentPeriodEndAt}
              commitmentEndAt={subscription.commitmentEndAt}
              cancelAt={subscription.cancelAt}
              resumesAt={subscription.resumesAt}
              resetBillingCycleAnchorAt={subscription.resetBillingCycleAnchorAt}
              isActive={isSubscriptionActive(subscription)}
              manageTitle={isTrialUpgradable(subscription) ? 'Upgrade' : 'Show Program Details'}
              onManagePress={() => handleManageSubscriptionPress(subscription)}
              {...getSubscriptionTagDescriptor(subscription)}
            />
            <Divider appearance="large" />
          </View>
        )
      })}
      {upcomingSubscriptionPhases.map(
        (phase) =>
          phase.primaryProduct && (
            <View key={phase.id}>
              <ProductSummary
                product={phase.primaryProduct}
                price={phase.price}
                isActive
                isTrial={false}
                pillLabel="SCHEDULED TO START"
                pillStatus="success"
                manageTitle="Manage Subscription"
                onManagePress={handleManageUpcomingSubscriptionPress}
              />
              <Divider appearance="large" />
            </View>
          ),
      )}
    </>
  )
}
