import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { CommonChart } from '@components'
import NoData from '@src/components/charts/NoData/NoData'

interface HorizontalChartModalProps {
  chartScript?: string
}

export const HorizontalChartModal = ({ chartScript }: HorizontalChartModalProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <SafeAreaView edges={['left', 'right']} style={styles.container}>
      {chartScript ? <CommonChart chartScript={chartScript} /> : <NoData />}
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})
