import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize, debounce } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, IconButton, Text } from '@components/base'
import { SearchBar } from '@components'
import { mealsSortSelector } from '@selectors/meals'
import { getDateRangeText } from '@screens/Meals/utils'
import { MealKind } from '@src/types'

const STORE_QUERY_UPDATE_DELAY = 500
const ALL_MEAL_KINDS = Object.values(MealKind)

export const CompareFilters = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const dispatch = useDispatch()

  const { types: kinds, startDate, endDate, query } = useSelector(mealsSortSelector)

  const [searchText, setSearchText] = useState('')

  const isAllSelected = kinds.length === 0
  const hasDateFilter = !!(startDate && endDate)

  const updateSearchQuery = useCallback(
    (query: string) => dispatch({ type: 'meals/setQuery', payload: query }),
    [dispatch],
  )

  const debouncedQueryUpdate = useMemo(() => {
    return debounce(updateSearchQuery, STORE_QUERY_UPDATE_DELAY)
  }, [updateSearchQuery])

  const handleTextChange = (text: string) => {
    setSearchText(text)
    debouncedQueryUpdate(text)
  }

  const selectAllMealKinds = () => {
    if (!isAllSelected) {
      dispatch({ type: 'meals/selectAllMealKinds' })
    }
  }

  useEffect(() => {
    setSearchText(query)
  }, [query])

  return (
    <View style={styles.container}>
      <SearchBar value={searchText} style={styles.searchBar} onChangeText={handleTextChange} />
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.filtersRow}
        contentContainerStyle={styles.filtersRowContainer}
      >
        <Button
          accessibilityLabel="Date range"
          size="small"
          type={hasDateFilter ? 'primary' : 'outline'}
          style={styles.filterButton}
          onPress={() => navigation.navigate('ChangeDateRange', { type: 'meals' })}
        >
          <View style={styles.calendarFilterContent}>
            <Icon
              name="calendar-blank"
              style={[styles.buttonIcon, hasDateFilter && styles.buttonIconActive]}
            />
            {hasDateFilter && (
              <>
                <Text type="regular" lineSpacing="none" style={styles.dateRange}>
                  {getDateRangeText(startDate, endDate)}
                </Text>
                <IconButton
                  accessibilityLabel="Reset date filter"
                  iconName="x"
                  iconProps={{ weight: 'bold' }}
                  size="xs"
                  style={styles.closeIcon}
                  type="primary"
                  onPress={() => dispatch({ type: 'meals/resetCalendar' })}
                />
              </>
            )}
          </View>
        </Button>
        <Button
          accessibilityLabel="All meal types"
          size="small"
          type={isAllSelected ? 'primary' : 'outline'}
          style={styles.filterButton}
          onPress={selectAllMealKinds}
        >
          All
        </Button>
        {ALL_MEAL_KINDS.map((kind) => (
          <Button
            key={kind}
            accessibilityLabel={kind}
            size="small"
            type={!isAllSelected && kinds.includes(kind) ? 'primary' : 'outline'}
            style={styles.filterButton}
            onPress={() => dispatch({ type: 'meals/toggleMealKind', payload: kind })}
          >
            {capitalize(kind)}
          </Button>
        ))}
      </ScrollView>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    gap: 16,
    marginBottom: 16,
  },
  searchBar: {
    margin: 0,
  },
  filtersRow: {
    marginHorizontal: -16,
  },
  filtersRowContainer: {
    paddingHorizontal: 16,
    gap: 8,
  },
  filterButton: {
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
  calendarFilterContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dateRange: {
    marginTop: 2,
    marginLeft: 8,
    color: 'theme.solid.white',
  },
  closeIcon: {
    marginLeft: 4,
    marginRight: -4,
  },
  buttonIcon: {
    width: 20,
    height: 20,
  },
  buttonIconActive: {
    color: 'theme.solid.white',
  },
})
