import React from 'react'
import { StyleService } from '@src/style/service'
import { TimeOfDaySlider } from '@components'
import { SettingsModalSharedContainer as SettingsModalShared } from './SettingsModalShared'

export const HOURS_IN_DAY = 24
const SLIDER_START = 0
const SLIDER_END = SLIDER_START + HOURS_IN_DAY * 2

interface FastingModalProps {
  fastingStart: number
  fastingEnd: number
  handleFastingPeriodChange: (field: 'startHour' | 'endHour') => (value: number) => void
  onCancelButtonPress: () => void
  onSaveButtonPress: () => void
}

export const FastingWindowModal = ({
  fastingStart,
  fastingEnd,
  handleFastingPeriodChange,
  onCancelButtonPress,
  onSaveButtonPress,
}: FastingModalProps) => {
  return (
    <SettingsModalShared
      onSaveButtonPress={onSaveButtonPress}
      onCancelButtonPress={onCancelButtonPress}
      title="Fasting Window"
    >
      <TimeOfDaySlider
        containerStyle={styles.sliderContainer}
        onChange={handleFastingPeriodChange}
        min={SLIDER_START}
        max={SLIDER_END}
        modulo={HOURS_IN_DAY}
        startValue={fastingStart < SLIDER_START ? fastingStart + HOURS_IN_DAY : fastingStart}
        endValue={fastingEnd <= SLIDER_START ? fastingEnd + HOURS_IN_DAY : fastingEnd}
      />
    </SettingsModalShared>
  )
}

const styles = StyleService.create({
  sliderContainer: {
    marginTop: 16,
    marginBottom: 32,
  },
})
