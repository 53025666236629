import React from 'react'
import { Feature, useFeatureFlag } from '@src/components'
import { useSignUpProducts } from '../hooks'
import { CGMAvailability } from './CGMAvailability'
import { SelectPlan } from './SelectPlan'

export const StartPurchaseFlow = () => {
  const enableOwnSensorOnboardingFlow = useFeatureFlag(Feature.OwnSensorOnboardingFlow)
  const { byosProduct, groupedProducts, requiresPlanSelection } = useSignUpProducts()

  const shouldShowOwnSensorFlow = enableOwnSensorOnboardingFlow && byosProduct

  if (shouldShowOwnSensorFlow) {
    return (
      <CGMAvailability
        byosProduct={byosProduct}
        groupedProducts={groupedProducts}
        requiresPlanSelection={requiresPlanSelection}
      />
    )
  }

  return <SelectPlan />
}
