import React, { useEffect } from 'react'
import { KeyboardAvoidingView, TextStyle } from 'react-native'
import Clipboard from '@react-native-clipboard/clipboard'
import { useTheme } from '@ui-kitten/components'
import { StreamChat, Channel } from 'stream-chat'
import {
  Channel as ChannelComponent,
  MessageList,
  MessageInput,
  OverlayProvider,
  Chat,
  vw,
  DeepPartial,
  Theme,
} from 'stream-chat-react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RouteProp, useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Device } from '@src/config'
import { useSnack } from '@src/utils/navigatorContext'
import { AppStackParamList } from '@src/navigation/types'
import { CustomMessageInput } from './CustomMessageInput'
import { CustomMessageFooter } from './CustomMessageFooter'
import { CustomTypingIndicatorContainer } from './CustomTypingIndicator'
import { CustomMessageSystem } from './CustomMessageSystem'

const MESSAGE_OFFSET_FROM_SCREEN_EDGE = 60

interface ChatScreenProps {
  client: StreamChat
  channel: Channel
}

const EmptyComponent = () => null

export const ChatScreen = (props: ChatScreenProps) => {
  const styles = useStyleSheet(themedStyle)
  const showSnack = useSnack()
  const { chatTheme, myMessageTheme } = useChatTheme()
  const { client, channel } = props

  const { params } = useRoute<RouteProp<AppStackParamList, 'Chat'>>()
  const cannedPromptKey = params?.cannedPromptKey

  useEffect(() => {
    if (cannedPromptKey) {
      channel.sendMessage({ nutrisense_type: 'canned_prompts', canned_prompt_key: cannedPromptKey })
    }
  }, [channel, cannedPromptKey])

  return (
    <KeyboardAvoidingView behavior={Device.ios ? 'padding' : undefined} style={styles.container}>
      <SafeAreaView edges={['bottom']}>
        <OverlayProvider>
          <Chat client={client} style={chatTheme}>
            <ChannelComponent
              channel={channel}
              disableKeyboardCompatibleView
              // MessageList props
              MessageAvatar={EmptyComponent}
              InlineDateSeparator={EmptyComponent}
              DateHeader={EmptyComponent}
              ScrollToBottomButton={EmptyComponent}
              InlineUnreadIndicator={EmptyComponent}
              MessageSystem={CustomMessageSystem}
              MessageFooter={CustomMessageFooter}
              myMessageTheme={myMessageTheme}
              onLongPressMessage={({ message }) => {
                if (message?.text) {
                  Clipboard.setString(message.text)
                  showSnack('Message copied!')
                }
              }}
              // MessageInput props
              MoreOptionsButton={EmptyComponent}
              InputButtons={EmptyComponent}
              Giphy={EmptyComponent}
              autoCompleteTriggerSettings={() => ({})}
            >
              <MessageList TypingIndicatorContainer={CustomTypingIndicatorContainer} />
              <MessageInput Input={CustomMessageInput} />
            </ChannelComponent>
          </Chat>
        </OverlayProvider>
      </SafeAreaView>
    </KeyboardAvoidingView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})

const useChatTheme = () => {
  const theme = useTheme()
  const maxWidth = vw(100) - MESSAGE_OFFSET_FROM_SCREEN_EDGE

  const chatTheme: DeepPartial<Theme> = {
    colors: {
      // Please don't just remove this.
      // Without this, we are getting that weird white flash when new messages are added to the chat.
      // Can be reproduced by tapping the delimiter button, on dark theme.
      bg_gradient_start: 'theme.background',
    },
    messageSimple: {
      card: {
        container: {
          width: maxWidth,
        },
      },
      content: {
        container: {
          width: maxWidth,
          borderWidth: 0,
        },
        containerInner: {
          borderWidth: 0,
        },
        deletedContainerInner: {
          borderWidth: 0,
        },
        textContainer: {
          width: maxWidth,
          maxWidth,
          borderWidth: 0,
          backgroundColor: theme['theme.surface.base2'],
        },
        wrapper: {
          width: maxWidth,
        },
        markdown: {
          autolink: {
            fontWeight: 'bold',
            color: theme['theme.text.link'],
            textDecorationLine: 'underline',
          },
          text: {
            color: theme['theme.text.primary'],
            lineHeight: 22,
            fontSize: 15,
            fontFamily: 'NBInternationalProReg',
            fontWeight: '400',
          },
          listItemBullet: {
            color: theme['theme.text.primary'],
          },
          listItemNumber: {
            color: theme['theme.text.primary'],
          },
          // Stream overrides the react-native-markdown renderer to provide some special/custom handling of lists.
          // As part of their override, they also change the component type for listRow; however,
          //  they do not change the exported type.
          //
          listRow: {
            lineHeight: 22,
            marginBottom: 8,
          } as TextStyle,
        },
      },
      giphy: {
        container: {
          width: maxWidth,
        },
      },
      reactionList: {
        reactionSize: 0,
        reactionBubbleBackground: {
          display: 'none',
        },
        reactionBubble: {
          display: 'none',
        },
        container: {
          display: 'none',
        },
      },
    },
    messageInput: {
      container: {
        borderTopWidth: 0,
        backgroundColor: theme['theme.background'],
      },
    },
    messageList: {
      container: {
        paddingHorizontal: 8,
        backgroundColor: theme['theme.background'],
      },
    },
  }

  const myMessageTheme: DeepPartial<Theme> = {
    messageSimple: {
      content: {
        textContainer: {
          backgroundColor: theme['theme.primary.base'],
        },
        markdown: {
          autolink: {
            fontWeight: 'bold',
            color: theme['theme.solid.white'],
            textDecorationLine: 'underline',
          },
          listItemBullet: {
            color: theme['theme.solid.white'],
          },
          listItemNumber: {
            color: theme['theme.solid.white'],
          },
          text: {
            color: theme['theme.solid.white'],
          },
        },
      },
    },
  }

  return { chatTheme, myMessageTheme }
}
