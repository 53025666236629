import React, { useContext, useEffect, useImperativeHandle } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigatorContext, useSnack } from '@src/utils/navigatorContext'
import { Address, AddressCountries } from '@src/types'
import { authenticatedUserSelector, usStatesSelector } from '@src/selectors/app'
import { useAddress } from '@src/hooks/useAddress'
import { Place } from '@src/services/GooglePlaces/GooglePlaces'
import { ControllerComponent } from '@src/components/Form'
import { Input } from '@src/components/base'
import { Bugsnag } from '@src/config'
import { isValidField } from '@src/utils/form'
import { AddressAutoComplete, AddressTextInput } from '@src/components/Address'
import { InputPropsBase } from './types'
import { useSetDefaultValue } from './Shared/hooks'

interface AddressFormData extends FieldValues {
  country: AddressCountries
  street: string
  street2: string
  city: string
  state: string
  zipCode: string
}

export const AddressInput = ({ field, answerRef }: InputPropsBase): JSX.Element => {
  const styles = useStyleSheet(themedStyle)

  const dispatch = useDispatch()
  const showSnack = useSnack()
  const { enableLoader, disableLoader } = useContext(NavigatorContext)

  const user = useSelector(authenticatedUserSelector)
  const address = (user.address || {}) as Partial<Address>

  useEffect(() => {
    dispatch({ type: 'users/fetch' })
  }, [dispatch])

  const defaultValues: AddressFormData = {
    country: AddressCountries.Us,
    street: address.street || '',
    street2: address.street2 || '',
    city: address.city || '',
    state: address.state || '',
    zipCode: address.zipCode || '',
  }

  const {
    control,
    setValue,
    clearErrors,
    getValues,
    formState: { isValid },
    watch,
  } = useForm({ mode: 'onTouched', defaultValues })

  const { placesMatchingSearch, debounceFetchPlaces, fetchAddressForPlace } = useAddress()

  const street = watch('street')
  const country = watch('country')
  const state = watch('state')

  useEffect(() => {
    debounceFetchPlaces.current(street, AddressCountries.Us)
  }, [street, debounceFetchPlaces])

  const loadAddressData = async (place: Place) => {
    const address = await fetchAddressForPlace(place, country)

    clearErrors(['street', 'city', 'state', 'zipCode'])

    setValue('street', address.street, { shouldValidate: true, shouldTouch: true })
    setValue('street2', '')
    setValue('city', address.city, { shouldValidate: true, shouldTouch: true })
    setValue('state', address.state, { shouldValidate: true, shouldTouch: true })
    setValue('zipCode', address.zipCode, {
      shouldValidate: true,
      shouldTouch: true,
    })
  }

  const usStates = useSelector(usStatesSelector)
  const states = usStates.map((item) => ({
    text: item.abbreviation,
    value: item.abbreviation,
  }))
  const statesMatchingSearch = states.filter((item) =>
    item.text.toLowerCase().includes(state.toLowerCase()),
  )
  const isStateValid = (text: string) => {
    return states.some((item) => item.text.toLowerCase() === text.toLowerCase())
  }

  const addressName = address.name || `${user.firstName} ${user.lastName}`
  const addressState = states.find((item) => item.text.toLowerCase() === state.toLowerCase())?.value

  useImperativeHandle(
    answerRef,
    () => ({
      process: () => {
        enableLoader()

        const data = getValues()

        const formattedAddress = {
          ...data,
          name: addressName,
          state: addressState,
        }

        return new Promise<boolean>((resolve) => {
          dispatch({
            type: 'address/update',
            payload: formattedAddress,
            success: () => {
              disableLoader()
              resolve(true)
            },
            failure: (error: any) => {
              showSnack('An error happened while confirming your address', null, 'error')
              Bugsnag.notify(error)
              disableLoader()
              resolve(false)
            },
          })
        })
      },
    }),
    [addressName, addressState, disableLoader, dispatch, enableLoader, getValues, showSnack],
  )

  const { onChange: fieldOnChange, value: fieldValue } = field

  useSetDefaultValue({ fieldValue, onChange: fieldOnChange, defaultValue: null })

  useEffect(() => {
    fieldOnChange(isValid)
  }, [fieldOnChange, isValid])

  return (
    <>
      <Input disabled label="Country" value="United States" style={styles.country} />
      <ControllerComponent
        control={control}
        Component={AddressAutoComplete}
        name="street"
        label="Street"
        placeholder="53 Fox Hill Lane"
        required
        validate={(value) => isValidField(country)(value, 'street')}
        onSelect={(idx: number) => {
          loadAddressData(placesMatchingSearch[idx])
        }}
        options={placesMatchingSearch.map((place) => ({
          value: place.place_id,
          text: place.description,
        }))}
      />
      <ControllerComponent
        control={control}
        Component={AddressTextInput}
        label="Street 2"
        name="street2"
        placeholder="apt. 52"
      />
      <ControllerComponent
        control={control}
        Component={AddressTextInput}
        label="City"
        name="city"
        placeholder="Center Conway"
        required
        validate={(value) => isValidField(country)(value, 'city')}
      />
      <View style={styles.stateZipCode}>
        <View style={styles.stateZipCodeField}>
          <ControllerComponent
            control={control}
            Component={AddressAutoComplete}
            label="State"
            placeholder="NH"
            name="state"
            required
            options={statesMatchingSearch}
            validate={(value) => isStateValid(value)}
          />
        </View>
        <View style={styles.stateZipCodeField}>
          <ControllerComponent
            control={control}
            Component={AddressTextInput}
            label="Zip Code"
            name="zipCode"
            required
            placeholder="00001"
            validate={(value) => isValidField(country)(value, 'zipCode')}
          />
        </View>
      </View>
    </>
  )
}

const themedStyle = StyleService.create({
  country: {
    marginBottom: 16,
  },
  stateZipCode: {
    flexDirection: 'row',
    gap: 16,
  },
  stateZipCodeField: {
    flex: 1,
  },
})
