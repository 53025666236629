import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon } from '@components/base'

interface CreateNewIngredientButtonProps {
  name?: string
}

export const CreateNewIngredientButton = ({ name }: CreateNewIngredientButtonProps) => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation()

  return (
    <Button
      type="secondary"
      size="block"
      isAccessoryInline
      accessibilityLabel="Create new ingredient"
      accessoryLeft={<Icon name="plus" />}
      style={styles.createNewIngredientButton}
      onPress={() => navigation.navigate('CustomIngredient', { name })}
    >
      Create New Ingredient
    </Button>
  )
}

const themedStyles = StyleService.create({
  createNewIngredientButton: {
    margin: 16,
  },
})
