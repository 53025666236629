import React from 'react'
import { AsYouType, getCountryCallingCode } from 'libphonenumber-js'
import { ControllerFieldState } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Input, Text } from '@components/base'
import { userSelector } from '@src/selectors/app'
import { AddressCountries } from '@src/types'
import { getCountryCodeByCountry } from '@src/utils/phoneInput'
import { InputPropsBase } from './types'
import { useSetDefaultValue } from './Shared/hooks'

interface PhoneNumberInputProps extends InputPropsBase {
  fieldState: ControllerFieldState
}

export const PhoneNumberInput = ({ field, fieldState }: PhoneNumberInputProps): JSX.Element => {
  const styles = useStyleSheet(themedStyles)

  const user = useSelector(userSelector)

  const country = (user?.address?.country as AddressCountries | undefined) || AddressCountries.Us

  const countryCode = getCountryCodeByCountry(country)

  const countryCallingCode = '+' + getCountryCallingCode(countryCode)

  const phoneNumber = field.value

  useSetDefaultValue({ fieldValue: phoneNumber, onChange: field.onChange, defaultValue: null })

  const formatText = (text: string) => {
    const newText = countryCallingCode + text.trim()
    const formattedNewText = new AsYouType(countryCode).input(newText)

    if (newText.length < (phoneNumber || '').length && formattedNewText === phoneNumber) {
      // do not re-format on character removal
      return newText
    }

    return formattedNewText.slice(countryCallingCode.length + 1)
  }

  return (
    <Input
      hasError={fieldState.isTouched && fieldState.invalid}
      keyboardType="phone-pad"
      placeholder="Phone Number"
      returnKeyType="done"
      value={phoneNumber}
      onBlur={field.onBlur}
      onChangeText={(text) => field.onChange(formatText(text))}
      accessoryLeft={
        <Text
          type="large"
          style={[styles.countryCode, !phoneNumber && styles.countryCodePlaceholder]}
        >
          {countryCallingCode}
        </Text>
      }
    />
  )
}

const themedStyles = StyleService.create({
  countryCode: {
    marginLeft: 8,
    marginRight: -4,
  },
  countryCodePlaceholder: {
    color: 'theme.text.tertiary',
  },
})
