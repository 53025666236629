import React from 'react'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Icon } from '@components/base'

export enum LessonProgressState {
  New = 'circle',
  Started = 'clock',
  Complete = 'check-circle',
  Actionable = 'lightbulb',
}

interface LessonProgressIndicatorProps {
  progress: LessonProgressState
}

export const LessonProgressIndicator = ({ progress }: LessonProgressIndicatorProps) => {
  const styles = useStyleSheet(themedStyle)
  const colorStyle =
    progress === LessonProgressState.Complete ? styles.iconCompleteColor : styles.iconDefaultColor

  return <Icon name={progress} style={colorStyle} />
}

const themedStyle = StyleService.create({
  iconCompleteColor: {
    color: 'theme.primary.base',
  },
  iconDefaultColor: {
    color: 'theme.info.base',
  },
})
