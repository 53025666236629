import { gql } from '@apollo/client'
import { CORE_USER_FIELDS } from './fragments/user'

export const UPDATE_USER = gql`
  ${CORE_USER_FIELDS}
  mutation updateUser(
    $email: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $photo: Upload
    $password: String
  ) {
    updateUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      photo: $photo
      password: $password
    ) {
      ...CoreUserFields
    }
  }
`
