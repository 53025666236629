import React from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'
import { CalendarProps } from 'react-native-calendars'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

const Day: CalendarProps['dayComponent'] = (props) => {
  const { date, marking, onPress } = props
  const styles = useStyleSheet(themedStyle)

  const containerStyles = [styles.day]
  const textStyles = [styles.dayText]
  if (!marking) {
    textStyles.push(styles.dayDisabledText)
    containerStyles.push(styles.dayDisabled)
  }

  if (marking) {
    if (marking.selected) {
      containerStyles.push(styles.daySelected)
      textStyles.push(styles.daySelectedText)
    } else {
      containerStyles.push(styles.dayAvailable)
    }
  }

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (!onPress) {
          return
        }
        if (!marking) {
          return
        }
        onPress(date)
      }}
      accessibilityLabel={date?.day?.toString() || 'Day'}
    >
      <View style={containerStyles}>
        <Text type="large" bold style={textStyles}>
          {date?.day}
        </Text>
      </View>
    </TouchableWithoutFeedback>
  )
}

export default React.memo(Day)

const themedStyle = StyleService.create({
  dayText: {
    marginTop: 2,
  },
  dayDisabledText: {
    color: 'theme.disabled.text',
  },
  daySelectedText: {
    color: 'theme.solid.white',
  },
  dayAvailable: {
    color: 'theme.text.primary',
  },

  daySelected: {
    borderRadius: 22,
    borderWidth: 1,
    backgroundColor: 'theme.primary.dark',
    color: 'theme.solid.white',
  },

  day: {
    height: 44,
    width: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  dayDisabled: {
    opacity: 0.5,
  },
})
