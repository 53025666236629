import { useSelector } from 'react-redux'
import { Feature, useFeatureFlag } from '@src/components'
import { userSelector } from '@src/selectors/app'
import { UiStateNames } from '@src/types'

export const useShouldShowSensorSelection = () => {
  const user = useSelector(userSelector)
  const isDexcomResearchEnabled = useFeatureFlag(Feature.DexcomResearch)

  const isUserEligibleForResearchSensor = user?.uiStates.some(
    ({ name, value }) => name === UiStateNames.DexcomSensorPrompt && value === true,
  )
  return isUserEligibleForResearchSensor && isDexcomResearchEnabled
}
