import React from 'react'
import { useWindowDimensions, View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ThemeColor } from '@src/style/theme'
import { Text } from '@components/base'
import { Device } from '@src/config'

const MARKER_SIZE = 12

export interface LegendItemProps {
  label: string
  themeMarkerColor: ThemeColor
}

export const LegendItem = ({ label, themeMarkerColor }: LegendItemProps) => {
  const markerColor = useTheme()[themeMarkerColor]
  const styles = useStyleSheet(themedStyles)
  const dimensions = useWindowDimensions()

  const hasSmallScreen = Device.hasSmallScreen(dimensions)

  return (
    <View style={styles.container}>
      <View style={[styles.marker, { backgroundColor: markerColor }]} />
      <Text type={hasSmallScreen ? 'micro' : 'regular'} style={styles.label}>
        {label}
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 10,
  },
  marker: {
    width: MARKER_SIZE,
    height: MARKER_SIZE,
    borderRadius: MARKER_SIZE / 2,
    marginRight: 4,
  },
  label: {
    color: 'theme.text.secondary',
  },
})
