import React from 'react'
import moment from 'moment'
import { DeviceEventEmitter, NativeScrollEvent, NativeSyntheticEvent } from 'react-native'
import { delay, get, isEmpty, isEqual, isFunction, last } from 'lodash'
import { connect } from 'react-redux'
import { Haptic } from '@utils'
import { RootStoreState } from '@src/models/app.types'
import { DataProviderProps, DataProviderState, ListInjectedProps } from '@src/components/list/types'

export const DEFAULT_PAGE_SIZE = 20

const withDataProviderHOC = <P extends ListInjectedProps>(
  WrappedListComponent: (props: P) => JSX.Element,
): React.ComponentClass<P & DataProviderProps & any> =>
  class extends React.Component<P & DataProviderProps, DataProviderState> {
    refreshListener: any
    timerId: any
    endTime = ''

    paginationType = 'page'
    page = 1
    pageSize = DEFAULT_PAGE_SIZE
    endReachedWhileRefreshing = false

    constructor(props: P & DataProviderProps) {
      super(props)
      this.state = {
        refreshing: false,
        isLoadingMore: false,
        error: false,
        loadedAll: false,
        contentOffsetY: 0,
      }
    }

    initializePagination = () => {
      const {
        calendar,
        pagination: { type = 'page', pageSize = DEFAULT_PAGE_SIZE, endTime = calendar?.endDate },
      } = this.props

      this.paginationType = type
      this.pageSize = pageSize

      this.endTime = moment(endTime || calendar?.endDate)
        .endOf('day')
        .toISOString()
      this.page = 1 // controlled by CardList
    }

    componentDidMount() {
      this.refreshListener = DeviceEventEmitter.addListener('onRefreshPress', this.onRefresh)
      this.timerId = delay(this.onRefresh, 0)
    }

    componentDidUpdate(prevProps: DataProviderProps) {
      const { sort, settings, items, calendar, lastScanned = {}, filter } = this.props

      if (
        get(prevProps, 'calendar.startDate') !== calendar?.startDate ||
        get(prevProps, 'calendar.endDate') !== calendar?.endDate
      ) {
        if (calendar?.endDate) {
          this.endTime = moment(calendar.endDate).endOf('day').toISOString()
        }
        this.refreshAndReset(items)
        return
      }

      if (
        get(prevProps, 'filter.startDate') !== filter.startDate ||
        get(prevProps, 'filter.endDate') !== filter.endDate ||
        get(prevProps, 'lastScanned') !== lastScanned
      ) {
        this.refreshAndReset(items)
        return
      }

      if (!isEqual(prevProps.sort, sort) || prevProps.settings.unitSystem !== settings.unitSystem) {
        this.refreshAndReset(items, false)
        return
      }

      const { refreshing } = this.state

      // without this logic if user scrolls to the end of list while it's refreshing
      // onEndReached won't be called anymore, so infinite list functionality is broken
      if (this.endReachedWhileRefreshing && !refreshing) {
        this.endReachedWhileRefreshing = false
        this.onEndReached()
        return
      }
    }

    componentWillUnmount = () => {
      this.refreshListener.remove()
      clearTimeout(this.timerId)
    }

    refreshAndReset = (items: any, useCache = true) => {
      this.setState({ loadedAll: false })

      if (this.timerId) {
        clearTimeout(this.timerId)
      }

      this.timerId = delay(() => {
        items?.length > 0 && this.scrollToTop()
        this.onRefresh(useCache)
      }, 100)
    }

    scrollToTop = () => {
      const { listRef } = this.props

      if (listRef?.current?.scrollToOffset) {
        listRef?.current?.scrollToOffset({ offset: 0, animated: true })
      } else if (listRef?.current?._wrapperListRef._listRef.scrollToOffset) {
        listRef?.current?._wrapperListRef._listRef.scrollToOffset({ offset: 0, animated: true })
      }
    }

    onListScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const { onListScroll, hapticScroll } = this.props

      this.setState({
        contentOffsetY: event.nativeEvent.contentOffset.y,
      })

      if (onListScroll) {
        hapticScroll && Haptic.lightTap()
        onListScroll(event)
      }
    }

    onRefresh = (useCache: boolean) => {
      const { onDataLoadStart } = this.props
      const shouldUseCache = useCache ?? this.props.useCache
      this.initializePagination()
      this.loadData(shouldUseCache, true)
      isFunction(onDataLoadStart) && onDataLoadStart(shouldUseCache)
    }

    onRefreshWithoutCache = () => {
      this.setState({ loadedAll: false })

      this.onRefresh(false)
    }

    onEndReached = () => {
      const { useCache, onEndReached, items } = this.props
      if (isFunction(onEndReached)) {
        onEndReached()
        return
      }

      const { refreshing, isLoadingMore, loadedAll, error } = this.state

      if (refreshing) {
        this.endReachedWhileRefreshing = true
      }

      if (isLoadingMore || refreshing || loadedAll || isEmpty(items) || error) {
        return
      }

      this.loadData(useCache, false)
    }

    onLoadEnd = ({ isRefresh, useCache }: { isRefresh: boolean; useCache: boolean }) => {
      const { onDataLoadEnd } = this.props
      const { contentOffsetY } = this.state
      if (isRefresh) {
        this.setState({ refreshing: false })
        if (contentOffsetY < 0) {
          this.scrollToTop()
        }
      } else {
        this.setState({ isLoadingMore: false })
      }
      isFunction(onDataLoadEnd) && onDataLoadEnd(useCache)
    }

    onSortUpdated = (payload: any) => {
      const { name, sort, onSortUpdated } = this.props

      if (isFunction(onSortUpdated)) {
        onSortUpdated(payload)
        return
      }

      this.props.dispatch({
        type: `${name}/updateSort`,
        payload: { ...sort, ...payload },
      })
    }

    loadData = (useCache: boolean, isRefresh: boolean) => {
      const {
        name,
        dispatch,
        sort = {},
        filter: propsFilter,
        dataPath = name,
        loadDataEffect = `${name}/fetch`,
      } = this.props

      this.setState({ error: false })

      if (isRefresh) {
        this.setState({ refreshing: true })
      } else {
        this.setState({ isLoadingMore: true })
      }

      const filter = {
        order: 'descending',
        ...propsFilter,
        ...sort,
      }

      const isInfinite = !(filter.startDate && filter.endDate)

      const pagination = {
        page: this.page,
        pageSize: this.pageSize,
        type: this.paginationType,
        ...(isInfinite ? { endTime: this.endTime } : {}),
      }

      const payload = {
        ...(this.props.payload || {}),
        filter,
        pagination,
      }

      dispatch({
        type: loadDataEffect,
        payload,
        metadata: { isRefresh },
        useCache,
        success: (response: any) => {
          const responseItems = get(response, dataPath, [])

          if (pagination.type === 'date') {
            this.endTime = moment((last(responseItems) as any)?.occurredAt).toISOString()
          } else if (responseItems.length === this.pageSize) {
            this.page += 1
          }

          if (responseItems.length < this.pageSize) {
            this.setState({ loadedAll: true })
          }
        },
        failure: () => {
          // avoid infinite refresh
          this.setState({ error: true })
        },
        complete: () => {
          this.onLoadEnd({ isRefresh, useCache })
        },
      })
    }

    render() {
      const { listRef, items, ...props } = this.props
      const { refreshing, loadedAll, isLoadingMore } = this.state

      return (
        <WrappedListComponent
          {...(props as P)}
          listRef={listRef}
          items={items}
          refreshing={refreshing}
          isLoadingMore={isLoadingMore}
          loadedAll={loadedAll}
          initialNumToRender={this.pageSize}
          onRefresh={this.onRefreshWithoutCache}
          onEndReached={this.onEndReached}
          onListScroll={this.onListScroll}
          onSortUpdated={this.onSortUpdated}
        />
      )
    }
  }

export const withDataProvider = <T extends ListInjectedProps>(
  Component: (props: T) => JSX.Element,
) =>
  connect(
    ({ app, settings }: RootStoreState) => ({
      config: app.clientConfig,
      lastScanned: app.lastScanned,
      settings,
    }),
    null,
    null,
    {
      forwardRef: true,
    },
  )(withDataProviderHOC(Component))
