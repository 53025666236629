import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet } from '@src/components'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { useGoBack } from '@src/utils'
import { BillingProduct } from '@src/types.ts'
import { NutritionistPhotoIcon } from '@screens/NutritionistHub/components/NutritionistPhotoIcon.tsx'
import { useFeeProduct } from '@screens/NutritionistHub/hooks/useFeeProduct.ts'

export const FreePassLateRescheduleVideoCallModal = () => {
  const styles = useStyleSheet(themedStyles)
  const route = useRoute<RouteProp<AppStackParamList, 'FreePassLateRescheduleVideoCallModal'>>()
  const { videoCallId, appointmentType, nutritionistName } = route.params
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const lateRescheduleFeeProduct = useFeeProduct(BillingProduct.VideoCallLateRescheduleFee)

  const onReschedulePress = () => {
    navigation.navigate('CallScheduling', {
      appointmentType,
      appointmentId: videoCallId,
      allowBackNavigation: true,
    })
  }

  const goBack = useGoBack()
  const effectiveNutritionistName = nutritionistName || 'Nutritionist'

  return (
    <BottomSheet
      primaryButton={{ text: 'Reschedule Now', onPress: onReschedulePress }}
      secondaryButton={{ text: 'Cancel', onPress: goBack }}
      buttonDirection="column-reverse"
    >
      <NutritionistPhotoIcon style={styles.nutritionistPhoto} />
      <Text style={styles.title} type="title-2">
        Need to Reschedule?
      </Text>
      <Text style={styles.content} type="regular">
        Your time with {effectiveNutritionistName} is reserved!
      </Text>
      <Text style={styles.content} type="regular">
        While this time, we're waiving the fee, please remember future changes within 24 hours of
        your call will incur a ${lateRescheduleFeeProduct?.price || ''} fee.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  title: {
    textAlign: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },
  content: {
    textAlign: 'center',
    paddingBottom: 16,
  },
  nutritionistPhoto: {
    alignSelf: 'center',
  },
})
