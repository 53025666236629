import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Toggle } from '@components/base'
import { Section } from '@components'
import { settingsSelector } from '@src/selectors/settings'

type Field =
  | 'reminders'
  | 'showNetCarbs'
  | 'healthKitSync'
  | 'canScanExpired'
  | 'patternsNotifications'

interface ToggleSettingProps {
  title?: string
  field: Field
}

export const fieldValueSelector = (field: Field) =>
  createSelector(settingsSelector, (settings) => settings[field])

export const ToggleSetting = React.memo((props: ToggleSettingProps) => {
  const { title, field } = props

  const fieldValue = useSelector(fieldValueSelector(field))

  // there is a delay in toggle animation if we rely completely on redux store
  const [checked, setChecked] = useState(fieldValue)

  useEffect(() => {
    setChecked(fieldValue)
  }, [fieldValue])

  const dispatch = useDispatch()

  const onChange = (value: boolean) => {
    setChecked(value)
    dispatch({
      type: 'settings/update',
      payload: { [field]: value },
    })
  }

  return title ? (
    <Section title={title}>
      <Toggle checked={checked} onChange={onChange} />
    </Section>
  ) : (
    <Toggle checked={checked} onChange={onChange} />
  )
})

export default ToggleSetting
