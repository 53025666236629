import React from 'react'

export enum Scene {
  Outdated = 'Outdated',
  Walkthrough = 'Walkthrough',
  Auth = 'Auth',
  Onboarding = 'Onboarding',
  Approval = 'Approval',
  AppAccess = 'AppAccess',
}

export const SceneContext = React.createContext<{
  scene: Scene | undefined
}>({ scene: undefined })
