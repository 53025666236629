/*
    Replaces content in string with an object, returns an array of string and object
    Example:
    replaceTextInStringWithObj({
        string: "Hello World",
        "replaceOn": "o W",
        onMatch: () => "###"
    }) => ["Hell", "###", "rld"]

    replaceTextInStringWithObj({
        string: "Hello World o Ww",
        replaceOn: "o W",
        onMatch: (i) => ({match: i})
    }) => ["Hell", {match: 0}, "rld ", {match: 1}, "w"]
*/

export const replaceTextInStringWithObj = <T>(args: {
  string: string
  replaceOn: string
  onMatch: (index: number) => T
}): (T | string)[] => {
  if (args.replaceOn === '') {
    return [args.string]
  }
  let match = 0
  return args.string
    .split(new RegExp(`(${args.replaceOn})`))
    .map((splitText) => {
      if (splitText !== args.replaceOn) {
        return splitText
      }
      match += 1
      return args.onMatch(match - 1)
    })
    .filter((x) => x !== '')
}
