import React from 'react'
import { ScrollView } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { NavigationContainer } from '@src/screens/Common/containers'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Feature, useFeatureFlag } from '@src/components'
import { useNutritionistMenuProducts } from '../../hooks/useNutritionistMenuProducts'
import { ProductsGrid } from './ProductsGrid'

export const AllProductsScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const nutritionistMenuProducts = useNutritionistMenuProducts()
  const insets = useSafeAreaInsets()
  const mostPopularTagEnabled = useFeatureFlag(Feature.NutritionistMenuMostPopularTag)

  return (
    <NavigationContainer title="Upgrades and Add-ons" style={styles.container}>
      <ScrollView contentContainerStyle={{ paddingBottom: insets.bottom + 16 }}>
        <ProductsGrid
          products={nutritionistMenuProducts}
          productsPerRow={2}
          showMostPopularTag={mostPopularTagEnabled}
        />
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    margin: 16,
  },
})
