import React, { useEffect } from 'react'
import { ScrollView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useSnack } from '@src/utils'
import { Button, Icon, Text } from '@components/base'
import { AppStackParamList, RootStackParamList } from '@src/navigation/types'
import { ErrorMessages } from '@src/config'
import { UserInsurancePolicyStateKind } from '@src/types'
import { insurancePolicySelector } from '../models/nutritionistHub.selectors'
import { isInsuranceReviewInProgress } from '../utils'

export const BookingConfirmationScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const route = useRoute<RouteProp<RootStackParamList, 'BookingConfirmationScreen'>>()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const { date: time } = route.params
  const insurancePolicy = useSelector(insurancePolicySelector)
  const insuranceCheckInProgress =
    isInsuranceReviewInProgress(insurancePolicy) ||
    insurancePolicy?.lastState?.kind === UserInsurancePolicyStateKind.Created
  const showSnack = useSnack()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({
      type: 'nutritionistHub/fetchInsurancePolicy',
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        showSnack(errorMessage, null, 'error')
      },
    })
  }, [dispatch, showSnack])

  const onFinish = () => {
    navigation.replace('Drawer', { screen: 'Dashboard' })
  }
  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.containerContent}>
      <View>
        <Icon name="calendar-check" style={styles.successIcon} weight="fill" />
        <Text type="title-2" style={styles.titleText}>
          Appointment booked
        </Text>
        <Text type="large" style={styles.titleText}>
          {moment(time).format('MMMM Do YYYY, h:mm a')}
        </Text>
        {insuranceCheckInProgress && (
          <Text type="large" style={styles.informationText}>
            We'll verify your insurance coverage for video calls and send you a confirmation email
            once the benefits check is confirmed. If your insurance doesn't cover the full call
            cost, we won't charge anything without your consent.
          </Text>
        )}
      </View>

      <Button size="block" accessibilityLabel="Finish" type="primary" onPress={onFinish}>
        Next Step
      </Button>
    </ScrollView>
  )
}

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
    paddingHorizontal: 20,
    paddingTop: 60,
    paddingBottom: 24,
  },
  containerContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  successIcon: {
    alignSelf: 'center',
    width: 48,
    marginVertical: 24,
    color: 'theme.text.primary',
  },
  titleText: {
    textAlign: 'center',
    marginBottom: 24,
  },
  informationText: {
    textAlign: 'center',
    marginTop: 24,
  },
})
