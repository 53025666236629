import React, { useContext, useEffect, useRef, useState } from 'react'
import { Keyboard, KeyboardAvoidingView, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { CardFieldInput } from '@stripe/stripe-react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { Device } from '@src/config'
import { createPaymentMethod } from '@utils/payment'
import { useGoBack } from '@src/utils/navigation'
import { NavigationContainer } from '@src/screens/Common/containers'
import { CreditCardInput } from '@src/components'
import { NavigatorContext, useSnack } from '@src/utils/navigatorContext'
import { authenticatedUserSelector } from '@src/selectors/app'

export const CreatePaymentMethod = () => {
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const goBack = useGoBack()
  const styles = useStyleSheet(themedStyle)

  const user = useSelector(authenticatedUserSelector)
  const { enableLoader, disableLoader } = useContext(NavigatorContext)
  const isSubmittingRef = useRef(false)
  const cardRef = useRef<CardFieldInput.Methods>(null)
  const [card, setCard] = useState<CardFieldInput.Details>()

  const onSubmit = async () => {
    if (!card?.complete) {
      cardRef.current?.focus()
      return
    }

    if (isSubmittingRef.current) {
      return
    }

    isSubmittingRef.current = true

    enableLoader()

    Keyboard.dismiss()

    try {
      await createPaymentMethod({ user, dispatch, card })
      goBack()
    } catch (error: any) {
      showSnack(error?.message || "Couldn't create payment method!", null, 'error')
    } finally {
      isSubmittingRef.current = false
      disableLoader()
    }
  }

  useEffect(() => {
    cardRef.current?.focus()
  }, [])

  return (
    <NavigationContainer title="Payment method" goBack={goBack}>
      <KeyboardAvoidingView
        behavior={Device.ios ? 'padding' : undefined}
        style={styles.contentContainer}
      >
        <SafeAreaView edges={['bottom']} style={styles.content}>
          <View style={styles.cardContainer}>
            <CreditCardInput ref={cardRef} onCardChange={setCard} />
          </View>
          <Button
            allowPressWhenDisabled
            accessibilityLabel="Add Card"
            disabled={!card?.complete}
            size="block"
            type="primary"
            onPress={onSubmit}
          >
            Add Card
          </Button>
        </SafeAreaView>
      </KeyboardAvoidingView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  contentContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
    paddingTop: 24,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  cardContainer: {
    height: 64,
    justifyContent: 'center',
    borderRadius: 16,
    backgroundColor: 'theme.surface.base2',
  },
})
