import defaultReducers from '@src/models/defaultReducers'
import { ChecklistStoreState, OnboardingChecklistItem } from './checklist.types'

export const reducers = {
  ...defaultReducers,
  setOnboardingChecklistItems: (
    state: ChecklistStoreState,
    { payload }: { payload: OnboardingChecklistItem[] },
  ) => {
    return {
      ...state,
      onboardingChecklistItems: payload,
    }
  },
}

export default reducers
