import { useFlag, useVariant } from '@unleash/proxy-client-react'
import { useMemo } from 'react'
import { Feature } from './FeatureFlag.types'

// React Hook
export const useFeatureFlag = (feature: Feature): boolean => {
  return useFlag(feature)
}

export const useVariantPayload = <T>(feature: Feature) => {
  const variant = useVariant(feature)

  return useMemo(() => {
    if (!variant.enabled || !variant.payload) {
      return undefined
    }

    if (variant.payload.type === 'json') {
      return JSON.parse(variant.payload.value) as T
    }

    return undefined
  }, [variant.enabled, variant.payload])
}

export const useVariantValue = (feature: Feature) => {
  const variant = useVariant(feature)

  if (variant.enabled || variant.payload) {
    return variant.payload?.value as string
  }
}

export const useVariantName = (feature: Feature) => {
  const variant = useVariant(feature)

  if (variant.enabled || variant.payload) {
    return variant.name
  }
}
