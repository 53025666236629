import React, { useMemo } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { GroupableProduct } from '../types/types'
import { getPriceRangeDescription } from '../utils/utils'

export interface ProductItemProps {
  item: GroupableProduct
  onItemPress: (item: GroupableProduct) => void
  selected?: boolean
}

export const ProductItem = (props: ProductItemProps) => {
  const styles = useStyleSheet(themedStyle)
  const { item, onItemPress, selected = false } = props
  const { title, description, details } = item

  const priceDescription = useMemo(() => getPriceRangeDescription(item), [item])

  return (
    <TouchableOpacity
      style={[styles.container, selected && styles.selected]}
      activeOpacity={0.7}
      onPress={() => onItemPress(item)}
      accessibilityLabel={title}
    >
      {selected && <Icon name="check-circle" weight="fill" style={styles.checkmark} />}
      <View style={styles.row}>
        <View style={styles.textContainer}>
          <Text type="large" bold style={styles.title}>
            {title}
          </Text>
          <Text type="small" style={styles.description}>
            {description ?? ''}
          </Text>
        </View>
        <Text type="large" bold>
          {priceDescription}
        </Text>
      </View>
      <View style={styles.detailsContainer}>
        {details?.map((detail) => (
          <View key={detail} style={styles.row}>
            <Icon name="check" style={styles.icon} />
            <Text type="small" lineSpacing="tight" style={styles.detailText}>
              {detail}
            </Text>
          </View>
        ))}
      </View>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    marginBottom: 24,
    padding: 20,
    paddingBottom: 8,
    borderWidth: 2,
    backgroundColor: 'theme.secondary.base',
    borderColor: 'theme.secondary.dark',
    borderRadius: 24,
  },
  selected: {
    borderColor: 'theme.primary.base',
  },
  checkmark: {
    position: 'absolute',
    top: -12,
    right: -12,
    width: 32,
    height: 32,
    color: 'theme.primary.base',
    backgroundColor: 'theme.solid.white',
    borderColor: 'theme.primary.base',
    borderWidth: 4,
    borderRadius: 16,
    overflow: 'hidden',
  },
  title: {
    marginBottom: 8,
  },
  description: {
    color: 'theme.text.secondary',
  },
  textContainer: {
    flex: 1,
    marginRight: 8,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 12,
  },
  icon: {
    color: 'theme.success.base',
  },
  detailsContainer: {
    borderTopWidth: 1,
    borderTopColor: 'theme.secondary.dark',
    paddingTop: 16,
  },
  detailText: {
    marginLeft: 12,
    flex: 1,
    color: 'theme.text.secondary',
  },
})
