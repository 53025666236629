import React from 'react'
import { TouchableOpacity, View, ViewStyle } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'
import { MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT } from '@src/config/momentFormat'

export interface HealthMetricValueProps {
  onEditAction: () => void
  value: string
  date: string
  unit: string | undefined
  style?: ViewStyle
}

export const HealthMetricValue = (props: HealthMetricValueProps) => {
  const { onEditAction, value, date, unit, style } = props
  const styles = useStyleSheet(themedStyles)

  return (
    <TouchableOpacity
      style={[styles.healthMetricValueContainer, style]}
      accessibilityLabel="Edit Biomarker"
      onPress={onEditAction}
    >
      <Text type="regular" lineSpacing="none">
        {moment(date).format(MONTH_NAME_AND_DATE_WITH_YEAR_FORMAT)}
      </Text>
      <View style={styles.healthMetricValueAndUnitContainer}>
        <Text type="regular" bold>
          {value}{' '}
        </Text>
        <Text type="regular" style={styles.healthMetricUnitText}>
          {unit}
        </Text>
      </View>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  healthMetricValueContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 2,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  healthMetricValueAndUnitContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  healthMetricUnitText: {
    color: 'theme.text.secondary',
  },
})
