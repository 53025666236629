import { Icon as UIKittenIcon } from '@ui-kitten/components'
import React from 'react'
import { ImageStyle, ViewStyle, StyleProp } from 'react-native'
import { ImageIcon } from '../ImageIcon'
import { IconDescriptor } from './IconDescriptor'
import { IconPack } from './types'

interface IconProps<Pack extends IconPack> {
  iconDescriptor: IconDescriptor<Pack>
  style?: StyleProp<ViewStyle>
  solid?: boolean
}

const isCustomImageIconDescriptor = (
  iconDescriptor: IconDescriptor<IconPack>,
): iconDescriptor is IconDescriptor<IconPack.CustomImage> =>
  iconDescriptor.pack === IconPack.CustomImage

/**
 * @deprecated Use new Icon component from '@src/components/base'
 */
export const Icon = <Pack extends IconPack>({ iconDescriptor, style, solid }: IconProps<Pack>) =>
  isCustomImageIconDescriptor(iconDescriptor) ? (
    <ImageIcon name={iconDescriptor.name} style={style as ImageStyle} />
  ) : (
    <UIKittenIcon
      name={iconDescriptor.name}
      pack={iconDescriptor.pack}
      style={style}
      solid={solid}
    />
  )
