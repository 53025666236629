import { inspect } from 'util'
import React, { Component } from 'react'
import semver from 'semver'
import { connect, DispatchProp } from 'react-redux'
import {
  AppState,
  Linking,
  DeviceEventEmitter,
  NativeModules,
  Dimensions,
  AppStateStatus,
  NativeEventSubscription,
  EmitterSubscription,
} from 'react-native'
import QuickActions from 'react-native-quick-actions'
import Orientation from 'react-native-orientation-locker'
import moment from 'moment'
import { get, noop, pick } from 'lodash'
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client'
import { AuthorizeResult, RefreshResult } from 'react-native-app-auth'
import { getVersion } from 'react-native-device-info'
import Config from 'react-native-config'
import { KetoMojo } from '@services'
import {
  Device,
  Notification,
  Bugsnag,
  Apollo,
  Stripe,
  App,
  Analytics,
  CustomEventTypes,
} from '@config'
import { User, Intercom, Storage, openUrl } from '@utils'
import { IntercomProvider } from '@containers/Intercom'
import { TimeZoneContext } from '@src/config/timezone'
import { ImpersonationContext } from '@src/config/impersonation'
import { visitedTutorialsSelector } from '@src/selectors/education'
import { onboardingSelector, userSelector } from '@src/selectors/app'
import { ketoMojoUserSelector } from '@src/selectors/integrations'
import { OnboardingState, RootStoreState } from '@src/models/app.types'
import { User as UserType } from '@src/types'
import { EducationStoreState } from '@screens/Education/types'
import { SettingsStoreState, TERRA_APPLE_HEALTH } from '@src/screens/Settings/models/settings.types'
import { NavigationContext } from '@src/config/navigation'
import StreamChat from '@src/services/StreamChat'
import { handleEngineNotification } from '@src/components/notifications/utils/handler'
import terraHealthKit from '@src/services/TerraHealthKit/terraHealthKit'
import { ListProviders } from '@src/types'
import { shouldShowModalOnce } from '@src/screens/Events/utils/hooks'
import { BuildType } from '@src/config/app'
import { WebPresentationModeProvider } from '@src/config/webPresentationMode'

const { scheme } = NativeModules.Configuration
const PushNotification = Device.web ? null : require('react-native-push-notification')

// for testing purposes in alpha and beta builds
// @TODO: remove once we release v3.0.0
const build = (Config.ENV as BuildType) || 'development'
let currentVersion = getVersion()

// default to 2.9.5 because getVersion does not work for mobile web
currentVersion = currentVersion !== 'unknown' ? currentVersion : '2.9.5'
const WALKTHROUGH_TOOLTIP_VERSION = build !== 'production' ? currentVersion : '3.0.0'

interface AppWithStateProps extends DispatchProp {
  user: UserType | null
  isOnboardingInProgress: boolean
  ketoMojoUser: AuthorizeResult | undefined
  visitedTutorials: EducationStoreState['visitedTutorials']
  settings: SettingsStoreState
  onboarding: OnboardingState
}

interface AppWithStateState {
  apolloClient: ApolloClient<NormalizedCacheObject>
  timeZone: string
  isImpersonating: boolean
  navigationContext: { route?: string; params?: any }
}

export function createAppStateNavigator(WrappedComponent: any) {
  class AppWithState extends Component<AppWithStateProps, AppWithStateState> {
    appState: AppStateStatus | 'launch' | 'login' = AppState.currentState
    appStateChangeListener: NativeEventSubscription | undefined
    quickActionShortcutListener: EmitterSubscription | undefined
    linkingListener: EmitterSubscription | undefined

    constructor(props: AppWithStateProps) {
      super(props)
      this.state = {
        apolloClient: Apollo.getClient(),
        timeZone: moment.tz.guess(true),
        isImpersonating: false,
        navigationContext: {},
      }
    }

    setStateAsync = (state: Partial<AppWithStateState>) => {
      return new Promise<void>((resolve) => {
        this.setState(state as Parameters<typeof this.setState>[0], resolve)
      })
    }

    async componentDidMount() {
      const { user } = this.props
      console.log('AppState:componentDidMount', user)
      const isImpersonating = User.isImpersonating()

      Stripe.configure(Apollo.endpointEnv)
      StreamChat.configure(Apollo.endpointEnv)

      await this.setStateAsync({ isImpersonating })

      if (user) {
        this.logFirstLogin(isImpersonating)
        Notification.configure(this.handleNotificationToken, this.handleNotification)
        this.handleTimeZone(user)
      }

      if (Device.shouldLockToPortrait(Dimensions.get('window'))) {
        Orientation.lockToPortrait()
      }

      this.checkAppVersion()

      this.appStateChangeListener = AppState.addEventListener('change', this.handleAppStateChange)
      this.quickActionShortcutListener = DeviceEventEmitter.addListener(
        'quickActionShortcut',
        this.handleQuickAction,
      )
      Linking.getInitialURL().then(this.handleOpenURL).catch(noop)
      this.linkingListener = Linking.addEventListener('url', this.handleOpenURL)

      this.handleAppStateChange('launch')

      // process quick actions on cold launch
      QuickActions.popInitialAction().then(this.handleQuickAction).catch(console.error)
    }

    async componentDidUpdate(prevProps: AppWithStateProps) {
      const { user, isOnboardingInProgress } = this.props
      const isAuthorizedPrev = !!prevProps.user
      const isAuthorized = !!user

      if (isAuthorized && isAuthorized !== isAuthorizedPrev) {
        const isImpersonating = User.isImpersonating()

        this.logFirstLogin(isImpersonating)
        await this.setStateAsync({ isImpersonating })

        Notification.configure(this.handleNotificationToken, this.handleNotification)
        this.handleTimeZone(user)
        this.handleAppStateChange('login')
      } else if (!isOnboardingInProgress && prevProps.isOnboardingInProgress) {
        this.handleAppStateChange('login')
      }
    }

    componentWillUnmount() {
      console.log('AppState:componentWillUnmount')

      this.appStateChangeListener?.remove()
      this.quickActionShortcutListener?.remove()
      this.linkingListener?.remove()
    }

    syncHealth = ({ settings } = this.props) => {
      const { isImpersonating } = this.state
      const isAvailable = Device.hasHealthKit

      if (isImpersonating || !isAvailable) {
        return
      }

      const shouldShowPermissionModal = shouldShowModalOnce(Storage.PERMISSIONS_MODAL_VISITED_KEY)

      if (shouldShowPermissionModal) {
        return
      }
      const userId = get(this.props, 'user.id')
      const { healthKitSync: legacyHealthKitSync } = settings

      this.props.dispatch({
        type: 'settings/fetchTerraProviders',
        success: (response: ListProviders) => {
          const terraAppleHealthProvider = response.providers.find(
            (provider) => provider.name === TERRA_APPLE_HEALTH,
          )
          // If Terra <> Apple Health FF disabled
          if (!terraAppleHealthProvider) {
            if (legacyHealthKitSync) {
              this.props.dispatch({
                type: 'app/updateHealthData',
              })
            }
            console.log('AppState:syncHealth: ', {
              isEnabled: legacyHealthKitSync,
              isAuthorized: isAvailable,
              isImpersonating,
            })

            return
          }

          // Migrate or validate connection to AppleHealth via Terra SDK
          const newIntegrationEnabled = terraAppleHealthProvider.active
          if (legacyHealthKitSync && !newIntegrationEnabled) {
            terraHealthKit.migrateFromHealthKit(this.props.dispatch, userId)
            return
          }

          if (newIntegrationEnabled) {
            terraHealthKit.validateConnection(this.props.dispatch, userId)
            return
          }
        },
      })
    }

    syncThirdPartyIntegration = async ({
      syncEnabled,
      integrationUser,
      refreshToken,
      integrationUserKey,
      integrationUserFields = ['refreshToken', 'accessToken', 'accessTokenExpirationDate'],
      updateData,
    }: {
      syncEnabled: boolean
      integrationUser: AuthorizeResult | undefined
      refreshToken: (token: string) => Promise<RefreshResult | null>
      integrationUserKey: 'ketoMojoUser'
      integrationUserFields?: string[]
      updateData: () => void
    }) => {
      if (!syncEnabled || !integrationUser?.accessToken) {
        return
      }

      const isExpired = moment().isAfter(moment(integrationUser.accessTokenExpirationDate))

      if (!isExpired) {
        updateData()
        return
      }

      let user = await refreshToken(integrationUser.refreshToken)

      if (!user) {
        return
      }

      if (!user.refreshToken) {
        user = {
          ...user,
          refreshToken: integrationUser.refreshToken,
        }
      }

      this.props.dispatch({
        type: 'settings/updateIntegrationsSyncSettings',
        payload: {
          [integrationUserKey]: pick(user, integrationUserFields),
        },
        success: updateData,
      })
    }

    syncKetoMojo = () => {
      const { ketoMojoUser, settings, dispatch } = this.props
      const { ketoMojoSync } = settings

      this.syncThirdPartyIntegration({
        syncEnabled: ketoMojoSync,
        integrationUser: ketoMojoUser,
        refreshToken: KetoMojo.refreshToken,
        updateData: () => {
          dispatch({
            type: 'app/updateKetoMojoData',
            payload: {
              initial: false,
            },
          })
        },
        integrationUserKey: 'ketoMojoUser',
      })
    }

    refetchEventsData = () => {
      this.props.dispatch({ type: 'app/clearCaches' })
      this.props.dispatch({ type: 'events/fetch' })
      this.props.dispatch({ type: 'events/fetchCharts' })
      this.props.dispatch({ type: 'events/fetchNutrition' })
    }

    // App State
    handleAppStateChange = (nextAppState: AppStateStatus | 'launch' | 'login') => {
      const { user, isOnboardingInProgress } = this.props
      const isAuthorized = !!user

      if (!isAuthorized || isOnboardingInProgress || nextAppState === 'unknown') {
        return
      }

      console.log('AppState:handleAppStateChange:', this.appState, ' -> ', nextAppState)
      if (this.appState.match(/background/) && nextAppState === 'active') {
        console.log('AppState:handleAppStateChange:active')
        if (!Device.web) {
          PushNotification.setApplicationIconBadgeNumber(0)
        }
        this.handleTimeZone(user)
        this.refetchEventsData()
        this.props.dispatch({ type: 'app/resetCalendars' }) // This needs to be run before app/updateAppState
        this.props.dispatch({
          type: 'app/updateAppState',
          payload: {
            lastOpened: moment().toISOString(),
          },
        })
        this.props.dispatch({ type: 'notifications/fetch' })
        this.syncHealth()
        this.syncKetoMojo()
      } else if (
        (this.appState.match(/active|background|unknown/) && nextAppState === 'launch') ||
        (this.appState.match(/active|background|unknown|launch|login/) && nextAppState === 'login')
      ) {
        console.log('AppState:handleAppStateChange:launchOrLogin')
        this.setVisitedTutorials()
        this.props.dispatch({ type: 'app/config' })
        this.props.dispatch({ type: 'users/fetch' })
        this.props.dispatch({ type: 'checklist/fetchOnboardingItems' })
        this.props.dispatch({
          type: 'meals/fetchFavorites',
          payload: { pagination: { page: 1, pageSize: 100 }, filter: { favorite: true } },
        })
        this.props.dispatch({
          type: 'meals/fetchRecentMeals',
          payload: { pagination: { page: 1, pageSize: 25 }, filter: {} },
        })
        this.props.dispatch({
          type: 'activities/fetchFavorites',
          payload: { pagination: { page: 1, pageSize: 100 }, filter: { favorite: true } },
        })
        this.props.dispatch({
          type: 'activities/fetchRecentActivities',
          payload: { pagination: { page: 1, pageSize: 25 }, filter: {} },
        })
        this.props.dispatch({ type: 'notifications/fetch' })
        this.props.dispatch({ type: 'app/resetCalendars' })
        this.syncHealth()
        this.syncKetoMojo()
      }

      if (nextAppState === 'active') {
        Intercom.handlePushMessage()
      }

      this.appState = nextAppState
    }

    setVisitedTutorials = () => {
      const { visitedTutorials, dispatch } = this.props

      if (Object.keys(visitedTutorials).length > 0) {
        // we've already restored tutorials from Storage
        return
      }

      const visitedTutorialsFromAsyncStorage = Storage.get(Storage.VISITED_TUTORIALS_KEY, {})
      Object.keys(visitedTutorialsFromAsyncStorage).forEach((key) => {
        dispatch({ type: 'education/setVisitedTutorial', payload: key })
      })
    }

    handleOpenURL = (
      event:
        | string
        | null
        | {
            url: string
          },
    ) => {
      const url = typeof event === 'string' ? event : event?.url
      Analytics.track(CustomEventTypes.UrlOpened, { url })
      Bugsnag.leaveBreadcrumb('Handle Open URL', { url })
    }

    handleNotification = (notification: any) => {
      if (!notification?.data) {
        return
      }
      if (AppState.currentState === 'active') {
        this.props.dispatch({ type: 'notifications/fetch' })
      }
      const notificationData = notification.data

      if (notificationData.kind) {
        if (!notification.isClicked) {
          return
        }

        const userId = get(this.props, 'user.id')
        return handleEngineNotification({
          notificationData,
          userId,
          dispatch: this.props.dispatch,
        })
      }
    }

    handleNotificationToken = (payload: { os: string; token: string }) => {
      console.log('AppState:handleNotificationToken:', payload)
      Bugsnag.leaveBreadcrumb('Handle Notification Token', { payload: inspect(payload) })

      const isAuthorized = !!get(this.props, 'user')

      const { isImpersonating } = this.state

      if (!isAuthorized || isImpersonating) {
        return
      }

      const { os, token } = payload
      const userId = get(this.props, 'user.id')

      const existingToken = Storage.get<string>('notification_token')
      if (!existingToken) {
        this.props.dispatch({
          type: 'users/registerToken',
          payload: {
            id: userId,
            type: os === 'ios' ? 'apn' : 'fcm',
            token,
          },
          success: () => Storage.set('notification_token', token),
        })

        if (!Device.web) {
          Intercom.sendTokenToIntercom(token)
        }
      }
    }

    handleTimeZone = (user: UserType) => {
      const { isImpersonating, timeZone } = this.state

      const newTimeZone = isImpersonating && user.timeZone ? user.timeZone : moment.tz.guess(true)

      this.setTimeZone(newTimeZone)

      if (newTimeZone !== timeZone) {
        this.props.dispatch({
          type: 'app/clearCaches',
          success: () => {
            DeviceEventEmitter.emit('onRefreshPress', {})
          },
        })
      }
    }

    handleQuickAction = (data: any) => {
      console.log('AppState:handleQuickAction:', data)
      Bugsnag.leaveBreadcrumb('Handle Quick Action', { data: inspect(data) })

      if (data) {
        const { url } = data.userInfo

        if (url === `${scheme}://app/modals/newMessage`) {
          Intercom.showIntercomMessenger({ source: 'QuickActionMenu' })
        } else {
          openUrl(url)
        }
      }
    }

    setTimeZone = (timeZone: string) => {
      Apollo.setTimeZone(timeZone)

      // Hawaii instead of US/Hawaii
      const momentTimeZone = moment.tz.names().find((name) => name.includes(timeZone)) || timeZone
      moment.tz.setDefault(momentTimeZone)

      this.setState({
        timeZone,
      })
    }

    logFirstLogin = (isImpersonating: boolean) => {
      if (isImpersonating) {
        return
      }

      const firstLoginTimestamp = Storage.get<string>(Storage.APP_FIRST_LOGIN_TIMESTAMP_KEY)

      if (!firstLoginTimestamp) {
        Storage.set(Storage.APP_FIRST_LOGIN_TIMESTAMP_KEY, moment().toISOString())
      }
    }

    checkAppVersion = () => {
      const previousAppVersion = Storage.get<string>(Storage.APP_VERSION_KEY)

      const currentAppVersion = App.version

      if (
        typeof previousAppVersion === 'string' &&
        semver.lt(previousAppVersion, WALKTHROUGH_TOOLTIP_VERSION) &&
        semver.gte(currentAppVersion, WALKTHROUGH_TOOLTIP_VERSION) &&
        !this.state.isImpersonating
      ) {
        this.setTransitionWalkthroughVisitedState()
        Storage.set(Storage.ACCOUNT_SCREEN_VISITED_KEY, true)
      }

      if (currentAppVersion !== previousAppVersion) {
        this.onAppVersionUpdate()
        Storage.set(Storage.APP_VERSION_KEY, currentAppVersion)
      }
    }

    onAppVersionUpdate = () => {
      const { dispatch } = this.props

      dispatch({ type: 'app/clearCaches' })
      // reset last request status to avoid showing app Outdated version screen
      dispatch({ type: 'app/updateAppState', payload: { lastRequestStatus: null } })
    }

    resetNavigationContext = () => {
      this.setState({
        navigationContext: {},
      })
    }

    setTransitionWalkthroughVisitedState = () => {
      Storage.WALKTHROUGH_STATE_KEYS.forEach((key) => {
        Storage.set(key, false)
      })
    }

    render() {
      const { timeZone, isImpersonating, navigationContext } = this.state

      return (
        <TimeZoneContext.Provider value={{ timeZone, setTimeZone: this.setTimeZone }}>
          <WebPresentationModeProvider>
            <ImpersonationContext.Provider value={{ isImpersonating }}>
              <NavigationContext.Provider
                value={{
                  ...navigationContext,
                  reset: this.resetNavigationContext,
                }}
              >
                <ApolloProvider client={this.state.apolloClient}>
                  <IntercomProvider>
                    <WrappedComponent {...this.props} />
                  </IntercomProvider>
                </ApolloProvider>
              </NavigationContext.Provider>
            </ImpersonationContext.Provider>
          </WebPresentationModeProvider>
        </TimeZoneContext.Provider>
      )
    }
  }

  const mapStateToProps = (state: RootStoreState) => {
    const visitedTutorials = visitedTutorialsSelector(state)
    const ketoMojoUser = ketoMojoUserSelector(state)
    const user = userSelector(state)
    const { settings } = state
    const onboarding = onboardingSelector(state)
    const isOnboardingInProgress = onboarding.isInProgress

    return {
      ketoMojoUser,
      visitedTutorials,
      user,
      settings,
      isOnboardingInProgress,
    }
  }

  return connect(mapStateToProps)(AppWithState as any)
}
