import React, { useEffect } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { partition } from 'lodash'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon, Text } from '@components/base'
import { InlineAlert, ScrollViewWithFade } from '@components'
import { AppStackParamList } from '@navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import { ComparisonDetails } from '@screens/Meals/components/CompareMeals'
import { useMeals } from '@screens/Meals/hooks'
import { topPriorityCoachAssignmentSelector } from '@screens/NutritionistHub/models/nutritionistHub.selectors'
import { coachChatConversationSelector } from '@selectors/app'
import { CoachAssignmentState, UiInlineAlert } from '@src/types'
import { useGoBack, useSnack } from '@utils'

export const CompareMealsDetails = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const dispatch = useDispatch()
  const showSnack = useSnack()

  const { params } = useRoute<RouteProp<AppStackParamList, 'CompareMealsDetails'>>()
  const mealIds = params.mealIds.split(',')

  const coachChatConversation = useSelector(coachChatConversationSelector)
  const coachAssignment = useSelector(topPriorityCoachAssignmentSelector)

  const isChatApprovalPending =
    !coachChatConversation && coachAssignment?.state === CoachAssignmentState.Pending

  const { meals, isLoading, hasError } = useMeals(mealIds)

  const handleShareWithNutritionistPress = () => {
    if (coachChatConversation) {
      navigation.navigate('ShareMealComparison', { mealIds: params.mealIds })
    } else {
      navigation.navigate('UnlockNutritionistSupport')
    }
  }

  useEffect(() => {
    dispatch({ type: 'nutritionistHub/fetchCoachAssignments' })
  }, [dispatch])

  useEffect(() => {
    if (isLoading) {
      return
    }

    const [validMeals, mealsWithoutScore] = partition(
      meals,
      (meal) => meal.statistics?.glucoseScore,
    )

    // Handle invalid meals - this can occur only via deep links with invalid meal IDs
    if (hasError || mealsWithoutScore.length > 0) {
      showSnack(
        'Meal comparison not available',
        'Some of the selected meals do not exist or have no meal score. Please select valid meals to compare.',
        'warning',
      )
      navigation.replace('CompareMeals', {
        initialMealIds: validMeals.map((meal) => meal.id).join(','),
      })
    }
  }, [hasError, isLoading, meals, navigation, showSnack])

  return (
    <NavigationContainer title="Compare Meals">
      <SafeAreaView style={styles.container} edges={['bottom']}>
        <ScrollViewWithFade style={styles.scrollViewContainer} scrollViewStyle={styles.scrollView}>
          {isChatApprovalPending && (
            <InlineAlert
              category={UiInlineAlert.Info}
              message={
                'Your assignment is pending and someone will reach out to you soon. ' +
                'Once approved, you can share the meal comparison and get feedback from your nutritionist.'
              }
              style={styles.alert}
            />
          )}
          <Text type="regular" bold lineSpacing="none" style={styles.title}>
            Glucose Impact Comparison
          </Text>
          <Text type="regular">
            This chart displays your blood sugar levels after you eat. It helps you understand how
            various foods impact you. Remember, other things like workouts or previous meals can
            also affect your blood sugar. To get more details about these, just tap on the dots in
            the graph.
          </Text>
          <ComparisonDetails mealIds={mealIds} />
          {!isChatApprovalPending && (
            <View style={styles.infoContainer}>
              <Icon name="info" style={styles.infoIcon} />
              <Text type="regular" style={styles.infoText}>
                If you're curious about this difference, share it with your nutritionist for
                personalized guidance.
              </Text>
            </View>
          )}
        </ScrollViewWithFade>
        <Button
          accessibilityLabel="Share with Nutritionist"
          type="primary"
          size="block"
          onPress={handleShareWithNutritionistPress}
          disabled={isChatApprovalPending}
        >
          Share with Nutritionist
        </Button>
        <Button
          accessibilityLabel="Close"
          type="outline"
          size="block"
          style={styles.secondaryButton}
          onPress={goBack}
        >
          Close
        </Button>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  scrollViewContainer: {
    flex: 1,
    flexBasis: 0,
  },
  scrollView: {
    paddingVertical: 16,
  },
  title: {
    marginBottom: 8,
  },
  infoContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 24,
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 12,
    backgroundColor: 'theme.surface.base2',
  },
  infoIcon: {
    width: 24,
    marginRight: 16,
    color: 'theme.text.secondary',
  },
  infoText: {
    flex: 1,
    color: 'theme.text.secondary',
  },
  secondaryButton: {
    marginTop: 16,
  },
  alert: {
    marginBottom: 24,
  },
})
