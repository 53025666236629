export default {
  fetch: () => {
    return null
  },
  wrap: () => {
    return null
  },
  fs: () => ({
    hash: async () => '',
    stat: async () => ({
      size: 0,
    }),
  }),
}
