import { gql } from '@apollo/client'

export const FETCH_DAILY_MEASUREMENTS_SOURCE_PRIORITY = gql`
  query dailyMeasurementsSourcePriority($type: HealthDataMeasurementType!) {
    dailyMeasurementsSourcePriority(type: $type) {
      type
      sources
    }
  }
`
