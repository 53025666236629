import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { ColorValue, ScrollViewProps, StyleProp, View, ViewStyle } from 'react-native'
// ScrollView needs to be imported from react-native-gesture-handler
// to prevent gesture conflicts in BottomSheet component
import { ScrollView } from 'react-native-gesture-handler'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Device } from '@config'
import { ContentFade, ContentFadeEdge } from './ContentFade'

export interface ScrollViewWithFadeProps {
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
  scrollViewStyle?: StyleProp<ViewStyle>
  fadeColor?: ColorValue
  onLayout?: ScrollViewProps['onLayout']
  onScrollViewLayout?: ScrollViewProps['onLayout']
  onContentSizeChange?: ScrollViewProps['onContentSizeChange']
}

export const ScrollViewWithFade = forwardRef<ScrollView, ScrollViewWithFadeProps>((props, ref) => {
  const {
    children,
    style,
    scrollViewStyle,
    fadeColor,
    onLayout = () => 0,
    onScrollViewLayout = () => 0,
    onContentSizeChange = () => 0,
  } = props
  const styles = useStyleSheet(themedStyles)
  const scrollViewRef = useRef<ScrollView | null>(null)
  const [itemContainerHeight, setItemContainerHeight] = useState(0)
  const [scrollContentHeight, setScrollContentHeight] = useState(0)
  const isScrollable = itemContainerHeight < scrollContentHeight

  useImperativeHandle(ref, () => scrollViewRef.current as ScrollView)

  return (
    <View
      style={[styles.container, style]}
      onLayout={(e) => {
        setItemContainerHeight(e.nativeEvent.layout.height)
        onLayout(e)
      }}
    >
      <ScrollView
        ref={scrollViewRef}
        contentContainerStyle={[styles.scrollView, scrollViewStyle]}
        showsVerticalScrollIndicator={false}
        onContentSizeChange={(contentWidth, contentHeight) => {
          setScrollContentHeight(contentHeight)
          onContentSizeChange(contentWidth, contentHeight)
        }}
        onLayout={onScrollViewLayout}
        scrollEnabled={isScrollable}
      >
        {children}
      </ScrollView>
      {isScrollable && (
        <View style={styles.itemFadeContainer} pointerEvents="none">
          <ContentFade edge={ContentFadeEdge.Top} style={styles.itemFadeIn} fadeColor={fadeColor} />
          <ContentFade
            edge={ContentFadeEdge.Bottom}
            style={styles.itemFadeOut}
            fadeColor={fadeColor}
          />
        </View>
      )}
    </View>
  )
})

const themedStyles = StyleService.create({
  container: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 1,
  },
  itemFadeContainer: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: '100%',
  },
  itemFadeIn: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: 24,
  },
  itemFadeOut: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 24,
    ...(Device.web ? { transform: 'rotate(180deg)' } : {}),
  },
})
