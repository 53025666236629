import { gql } from '@apollo/client'
import { LATEST_USER_APPOINTMENT_FIELDS } from './fragments/latestUserAppointment'

export const FETCH_VIDEO_CALL_INFO = gql`
  ${LATEST_USER_APPOINTMENT_FIELDS}
  query latestUserAppointment {
    latestUserAppointment {
      ...LatestUserAppointmentFields
    }
  }
`
