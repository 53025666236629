import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import { TouchableOpacity, View } from 'react-native'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StackNavigationProp } from '@react-navigation/stack'
import { Feature, useFeatureFlag } from '@src/components'
import { Icon, Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Storage, useDispatchAsync } from '@src/utils'
import { useStorageValue } from '@src/utils/storage'
import {
  NotificationEngineScreen,
  SurveyLink,
  SurveyLinkCollection,
  SurveysConfigKind,
  SurveysQuestionKey,
} from '@src/types.ts'
import { AppStackParamList } from '@navigation/types'
import { eventsCalendarSelector } from '@selectors/events.ts'
import { useNotifications } from '@components/notifications/utils/hooks.ts'
import { userSelector } from '@selectors/app.ts'
import { Analytics, CustomEventTypes } from '@config'

const details = [
  {
    title: 'Personalized Insights: ',
    body: 'Each session builds on the last, tailoring advice to your evolving needs and goals.',
  },
  {
    title: 'Accountability: ',
    body:
      'Regular check-ins help you stay on track, ' +
      'providing the motivation and support needed to maintain healthy habits.',
  },
  {
    title: 'Expert Guidance: ',
    body:
      'As you encounter new challenges or changes in your lifestyle, ' +
      'a nutritionist can offer expert advice to adjust your plan accordingly.',
  },
]

const detailsDescription = {
  body:
    'Looking to reach your health goals? Here’s why talking to a nutritionist can make a difference:',
}

export const VideoCallsUpsellBanner = () => {
  const user = useSelector(userSelector)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const styles = useStyleSheet(themedStyles)
  const [preRequisitesSatisfied, setPreRequisitesSatisfied] = useState(false)
  const [shouldShow, setShouldShow] = useState(false)
  const [surveyLink, setSurveyLink] = useState<SurveyLink | undefined>(undefined)
  const [visitedCount, setVisitedCount] = useStorageValue<number>(
    `${Storage.VIDEO_CALLS_UPSELL_MODAL_VISITED_COUNT_KEY}_${user?.id}`,
  )
  const [upsellModalCompleted] = useStorageValue<boolean>(
    `${Storage.VIDEO_CALLS_UPSELL_MODAL_COMPLETED_KEY}_${user?.id}`,
  )
  const [checklistBannerVisited] = useStorageValue(
    Storage.ONBOARDING_CHECKLIST_REMINDER_VISITED_KEY,
  )
  const dispatchAsync = useDispatchAsync()

  const videoCallFunnelOptimizationEnabled = useFeatureFlag(Feature.VideoCallFunnelOptimization)
  const { startDate } = useSelector(eventsCalendarSelector)
  const notifications = useNotifications(NotificationEngineScreen.Home, startDate)

  useEffect(() => {
    setPreRequisitesSatisfied(
      videoCallFunnelOptimizationEnabled &&
        !notifications.length &&
        !!checklistBannerVisited &&
        !!upsellModalCompleted &&
        !!visitedCount &&
        visitedCount < 3,
    )
  }, [
    videoCallFunnelOptimizationEnabled,
    visitedCount,
    upsellModalCompleted,
    notifications,
    checklistBannerVisited,
  ])

  useFocusEffect(
    useCallback(() => {
      const evaluateShouldShow = async () => {
        if (!preRequisitesSatisfied) {
          setShouldShow(false)
          return
        }
        const { surveyLinks }: SurveyLinkCollection = await dispatchAsync({
          type: 'app/surveyQuestionnaire',
        })
        const insuranceSurveyLink = surveyLinks.find(
          (link) => link.survey.kind === SurveysConfigKind.Insurance,
        )

        setSurveyLink(insuranceSurveyLink)

        if (
          insuranceSurveyLink &&
          (insuranceSurveyLink.finished ||
            new Date(insuranceSurveyLink.startedAt) < moment().subtract(30, 'day').toDate())
        ) {
          setShouldShow(false)
          return
        }

        const insuranceSkipSurveyLink = surveyLinks.find(
          (link) => link.survey.kind === SurveysConfigKind.InsuranceEarlyExit,
        )

        setShouldShow(
          insuranceSkipSurveyLink?.responses?.find(
            (response) => response.questionKey === SurveysQuestionKey.InsuranceEarlyExitReason,
          )?.answer?.value !== 'no_insurance',
        )
      }

      evaluateShouldShow()
    }, [preRequisitesSatisfied, dispatchAsync]),
  )

  if (!shouldShow) {
    return null
  }

  const onPress = () => {
    if (surveyLink) {
      navigation.navigate('Questionnaire', {
        questionnaire: SurveysConfigKind.Insurance,
        skipIntroScreen: true,
      })
      Analytics.track(CustomEventTypes.VideoCallsUpsellBannerClicked, {
        userId: user?.id,
        type: 'insurance_questionnaire',
      })
    } else {
      navigation.navigate('VideoCallsUpsellDetailsModal', {
        details,
        description: detailsDescription,
      })
      Analytics.track(CustomEventTypes.VideoCallsUpsellBannerClicked, {
        userId: user?.id,
        type: 'upsell_modal',
      })
    }

    setVisitedCount((visitedCount || 0) + 1)
    setShouldShow(false)
  }

  const title = surveyLink ? 'Complete your eligibility check' : 'Insurance covered video calls'
  const description = surveyLink ? 'Resume and schedule call' : 'Tap to learn more!'
  const icon = surveyLink ? 'clock-countdown' : 'gift'

  return (
    <TouchableOpacity accessibilityLabel={title} onPress={onPress} style={styles.container}>
      <View style={styles.iconContainer}>
        <Icon name={icon} size="20" weight="bold" style={styles.icon} />
      </View>
      <View style={styles.textContainer}>
        <Text type="large" bold>
          {title}
        </Text>
        <Text type="regular" style={styles.secondaryText}>
          {description}
        </Text>
      </View>
      <Icon name="caret-right" size="16" weight="bold" style={styles.caretIcon} />
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    marginTop: 8,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'theme.surface.banner',
  },
  iconContainer: {
    padding: 16,
    borderRadius: 56,
    backgroundColor: 'theme.primary.base',
    marginRight: 16,
  },
  icon: {
    color: 'theme.solid.white',
  },
  textContainer: {
    flex: 1,
  },
  secondaryText: {
    color: 'theme.text.secondary',
  },
  caretIcon: {
    color: 'theme.surface.base0',
  },
})
