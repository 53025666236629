import { gql } from '@apollo/client'

const CREATE_JOURNAL_ENTRY = gql`
  mutation createJournalEntry(
    $body: String!
    $time: String
    $kind: String
    $measurements: [JournalMeasurementsData!]
  ) {
    createJournalEntry(body: $body, time: $time, kind: $kind, measurements: $measurements) {
      id
      title
      body
      occurredAt
      createdAt
      creatorId
      kind
      score
      measurements {
        id
        type
        title
        description
        value
        values {
          ... on SingleValueMeasurement {
            value
          }
        }
        units
        occurredAt
        createdAt
      }
    }
  }
`

export default CREATE_JOURNAL_ENTRY
