import React from 'react'
import { View, ViewStyle } from 'react-native'
import { MultiSliderProps } from '@ptomasroos/react-native-multi-slider'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { Slider } from '../Slider'

interface SliderInputBlockProps extends MultiSliderProps {
  label: string
  value: number
  min?: number
  max?: number
  onValueChanged: (value: number) => void
  showValue?: boolean
  style?: ViewStyle
}

export const SliderInputBlock = (props: SliderInputBlockProps) => {
  const styles = useStyleSheet(themedStyle)
  const {
    label,
    value,
    min = 1,
    max = 10,
    showValue = true,
    onValueChanged,
    style,
    ...sliderProps
  } = props

  const onValuesChange = (values: number[]) => {
    onValueChanged(values[0])
  }

  return (
    <View style={style}>
      <View style={styles.header}>
        <Text type="regular">{label}</Text>
        {showValue && (
          <Text type="large" bold>
            {value}
          </Text>
        )}
      </View>
      <Slider
        min={min}
        max={max}
        step={1}
        snapped
        values={[value]}
        onValuesChange={onValuesChange}
        {...sliderProps}
      />
    </View>
  )
}

const themedStyle = StyleService.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
})
