import React, { useMemo } from 'react'
import { Divider } from '@ui-kitten/components'
import { Parser } from 'commonmark'
import ReactRenderer from 'commonmark-react-renderer'
// The default styles applied by our base Text class override parent Text component styles;
// but, the markdown renderer relies on Text component style inheritance.
import { Text as UnstyledText, TextStyle, View } from 'react-native' // eslint-disable-line no-restricted-imports
import { StyleService, useStyleSheet } from '@src/style/service'
import { openUrl } from '@src/utils'
import { Text } from '@components/base'

const parser = new Parser()

interface MarkdownViewProps {
  content: string
  paragraphStyle?: TextStyle
}

const LEVEL_TITLES: { [prop: number]: 'title-1' | 'title-2' | 'title-3' | 'large' | 'regular' } = {
  [1]: 'title-1',
  [2]: 'title-2',
  [3]: 'title-3',
  [4]: 'large',
  [5]: 'regular',
  [6]: 'regular',
}

export const MarkdownView = ({ content, paragraphStyle }: MarkdownViewProps) => {
  const styles = useStyleSheet(themedStyles)

  const renderer = useMemo(
    () =>
      new ReactRenderer({
        renderers: {
          Linebreak: () => <Text type="regular">{'\n'}</Text>,
          List: ({ children }) => <View style={styles.paragraph}>{children}</View>,
          Item: ({ children }) => (
            <View style={styles.item}>
              <View style={styles.itemIcon} />
              <Text type="regular" style={styles.text}>
                {children}
              </Text>
            </View>
          ),
          Link: ({ href, children }) => (
            <Text type="regular" bold style={styles.link} onPress={() => openUrl(href)}>
              {children}
            </Text>
          ),
          Blockquote: ({ children }) => <Text type="regular">{children}</Text>,
          Image: () => null,
          Code: ({ children }) => (
            <Text type="regular" style={styles.code}>
              {children}
            </Text>
          ),
          Emph: ({ children }) => (
            <Text type="regular" style={styles.emph}>
              {children}
            </Text>
          ),
          Paragraph: ({ children }) => (
            <Text type="regular" style={[styles.paragraph, paragraphStyle]}>
              {children}
            </Text>
          ),
          Strong: ({ children }) => (
            <Text type="regular" bold>
              {children}
            </Text>
          ),
          ThematicBreak: () => <Divider />,
          HtmlBlock: () => null,
          HtmlInline: () => null,
          CodeBlock: ({ children }) => <View>{children}</View>,
          Heading: ({ level, children }) => (
            <Text type={LEVEL_TITLES[level]}>
              {children}
              {'\n'}
            </Text>
          ),
          Text: ({ literal }) => <UnstyledText>{literal ?? ''}</UnstyledText>,
          Softbreak: () => <Text type="regular">{'\n'}</Text>,
        },
      }),
    [styles, paragraphStyle],
  )

  const output = useMemo(() => {
    const abstractSyntaxTree = parser.parse(content)
    return renderer.render(abstractSyntaxTree)
  }, [renderer, content])

  return <View>{output}</View>
}

const themedStyles = StyleService.create({
  link: {
    color: 'theme.text.link',
  },
  code: {
    fontFamily: 'monospace',
  },
  paragraph: {
    marginBottom: 16,
  },
  emph: {
    fontStyle: 'italic',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 8,
  },
  itemIcon: {
    marginTop: 8,
    marginRight: 10,
    width: 6,
    height: 6,
    borderRadius: 4,
    backgroundColor: 'theme.text.primary',
  },
  text: {
    flexShrink: 1,
  },
})
