import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { HealthMetric, HealthMetricsParam } from '@src/types'
import { AppStackParamList } from '@src/navigation/types'
import { Analytics, CustomEventTypes } from '@src/config'
import { HealthMetricValueProps } from './HealthMetricValue'
import { HealthMetricCard } from './HealthMetricCard'

interface BiomarkerCardProps {
  title: string
  metricKey: HealthMetricsParam
  unit: string | undefined
  data: HealthMetric[]
}

export const BiomarkerCard = (props: BiomarkerCardProps) => {
  const { title, metricKey, unit, data } = props
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const values = data.map(
    (entry) =>
      ({
        onEditAction: () => {
          Analytics.track(CustomEventTypes.OutcomesTrackingIndividualValueTapped)
          navigation.navigate('BiomarkerForm', {
            id: entry.id,
            biomarkerName: title,
            biomarkerKind: metricKey.toString(),
            value: entry.value,
            date: entry.date,
            unit,
          })
        },
        value: entry.value.toString(),
        date: entry.date.toString(),
        unit,
      } as HealthMetricValueProps),
  )

  return (
    <HealthMetricCard
      onAddAction={() => {
        navigation.navigate('BiomarkerForm', {
          biomarkerName: title,
          biomarkerKind: metricKey.toString(),
        })
      }}
      title={title}
      metricKey={metricKey}
      unit={unit}
      values={values}
    />
  )
}
