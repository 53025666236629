import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useGlucosePrecision } from '@src/screens/Settings/utils/hooks'
import { Point } from '@src/screens/Insights/types'
import { insightsCalendarSelector } from '@src/screens/Insights/models/insights.selectors'
import { ChartQueryType } from '../../helper'
import { ByDayOfWeekChart } from './ByDayOfWeekChart'

interface ScoreChartProps {
  currentData: Point[]
  previousData: Point[]
  type: ChartQueryType
  section: string
  yLabel?: string
}

const ScoreChartComponent = ({
  currentData,
  previousData,
  type,
  section,
  yLabel,
}: ScoreChartProps) => {
  const calendar = useSelector(insightsCalendarSelector)

  const glucosePrecision = useGlucosePrecision()

  const precision = section === 'glucose' && type !== 'GLUCOSE_TIME_IN_RANGE' ? glucosePrecision : 0

  return (
    <ByDayOfWeekChart
      datesRange={{ startDate: calendar.startDate, endDate: calendar.endDate }}
      currentData={currentData}
      previousData={previousData}
      precision={precision}
      yLabel={yLabel}
    />
  )
}
export const ScoreChart = memo(ScoreChartComponent)
