import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { useFeatureFlag, Feature } from '@src/components'

export interface OrderSummaryProps {
  price: number
  estimatedTax: number
  discount: number
}

export const OrderSummary = (props: OrderSummaryProps) => {
  const styles = useStyleSheet(themedStyle)
  const enableTaxEstimation = useFeatureFlag(Feature.AvalaraEstimateTax)
  const { price, discount, estimatedTax } = props
  const total = Math.max(price - discount + estimatedTax, 0).toFixed(2)

  return (
    <View style={styles.container}>
      <Text type="small" bold lineSpacing="none" style={styles.title}>
        Order Summary
      </Text>
      <View style={styles.row}>
        <Text type="small">Order</Text>
        <Text type="regular">${price}</Text>
      </View>
      {discount > 0 && (
        <View style={styles.row}>
          <Text type="small">Discount</Text>
          <Text type="regular">-${discount}</Text>
        </View>
      )}
      {enableTaxEstimation && (
        <View style={styles.row}>
          <Text type="small">Estimated Tax</Text>
          <Text type="regular">${estimatedTax}</Text>
        </View>
      )}
      <View style={styles.row}>
        <Text type="regular" bold>
          Total
        </Text>
        <Text type="regular" bold>
          ${total}
        </Text>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 16,
  },
  title: {
    marginTop: 24,
    marginBottom: 16,
    textTransform: 'uppercase',
    letterSpacing: 0.3,
  },
  row: {
    flexDirection: 'row',
    algnItems: 'center',
    justifyContent: 'space-between',
  },
})
