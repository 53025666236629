import { useTheme } from '@ui-kitten/components'
import React from 'react'
import ReactContentLoader, { IContentLoaderProps } from 'react-content-loader/native'

export type ContentLoaderProps = IContentLoaderProps
export const ContentLoader = (props: ContentLoaderProps) => {
  const theme = useTheme()
  const gradientProps = {
    backgroundColor: theme['theme.surface.base2'],
    foregroundColor: theme['theme.surface.base1'],
  }
  return <ReactContentLoader {...gradientProps} {...props} />
}
