import { gql } from '@apollo/client'

export const DELETE_REMINDER = gql`
  mutation deleteReminder($id: ID!) {
    deleteReminder(id: $id) {
      id
      kind
      frequency
      dayOfWeek
      timeOfDay
    }
  }
`
