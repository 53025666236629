import { ChartRangeName, RangeItem } from '@src/screens/Insights/types'

export enum RangeRating {
  GOOD = 'good',
  OK = 'ok',
  BAD = 'bad',
  CRITICAL = 'critical',
}

export const getValueRating = (value: number, ranges: RangeItem[]) => {
  const range = ranges.find((range) => {
    if (!range.from) {
      return value <= (range.to || 0)
    } else if (!range.to) {
      return value >= range.from
    }

    return value >= range.from && value <= range.to
  })

  return range?.rating
}

export const getRatingColor = (rating: ChartRangeName) => {
  if (rating === RangeRating.GOOD) {
    return 'theme.range.good'
  } else if (rating === RangeRating.OK) {
    return 'theme.range.regular'
  } else {
    return 'theme.range.bad'
  }
}

export const getRatingTitle = (rating: string) => {
  if (rating === RangeRating.GOOD) {
    return 'Good'
  } else if (rating === RangeRating.OK) {
    return 'Ok'
  } else {
    return 'Needs Improvement'
  }
}
