import { useEffect, useState } from 'react'
import NfcManager, { OnStateChanged, NfcEvents } from 'react-native-nfc-manager'
import { Device } from '@src/config'

export const useNfcState = () => {
  const [isSupported, setIsSupported] = useState(!Device.web)
  const [isEnabled, setIsEnabled] = useState(!Device.web)

  useEffect(() => {
    if (Device.web) {
      return
    }

    NfcManager.isSupported()
      .then((supported) => {
        setIsSupported(supported)
      })
      .catch((error) => {
        console.error('Failed to check if NFC is supported', error)
      })

    NfcManager.isEnabled()
      .then((enabled) => {
        setIsEnabled(enabled)
      })
      .catch((error) => {
        console.error('Failed to check if NFC is enabled', error)
      })

    NfcManager.setEventListener(NfcEvents.StateChanged, ((event) => {
      setIsEnabled(event.state === 'on')
    }) as OnStateChanged)
  }, [])

  return { isSupported, isEnabled }
}
