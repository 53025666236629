import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@src/components/base'

export const NoNotesPlaceholder = () => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <Text type="regular" style={styles.body}>
        After your video call, here is where you will find information from your nutritionist.
      </Text>
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 16,
    borderWidth: 1,
    borderColor: 'theme.surface.base1',
    shadowColor: 'theme.solid.black',
    backgroundColor: 'theme.background',
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 17,
    elevation: 5, // Android shadow property
    padding: 16,
  },
  body: {
    maxHeight: 48,
    lineHeight: 20,
  },
})
