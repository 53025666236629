import { useState, useMemo } from 'react'
import { Device } from '@src/config'

export const useFitVideoInView = () => {
  const [viewDimensions, setViewDimensions] = useState({ width: 0, height: 0 })
  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 })

  const dimensions = useMemo(() => {
    // min dimensions are needed to ensure that the video controls are shown
    const MIN_WIDTH = 275
    const MIN_HEIGHT = 150
    const { width: viewWidth, height: viewHeight } = viewDimensions
    const { width: videoWidth, height: videoHeight } = videoDimensions
    if ([viewWidth, viewHeight, videoWidth, videoHeight].includes(0)) {
      // Android doesn't fire onReadyForDisplay when no video dimensions are defined and
      // iOS has strange behavior when using viewDimensions (view size seems to change when video expands to fill it)
      return Device.android ? viewDimensions : undefined
    }

    const scale = Math.min(viewWidth / videoWidth, viewHeight / videoHeight)

    const scaledWidth = videoWidth * scale
    const scaledHeight = videoHeight * scale

    const width = Math.max(scaledWidth, MIN_WIDTH)
    const height = Math.max(scaledHeight, MIN_HEIGHT)

    return { width, height }
  }, [videoDimensions, viewDimensions])

  return {
    setViewDimensions,
    setVideoDimensions,
    dimensions,
  }
}
