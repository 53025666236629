import React, { useCallback, useEffect } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { userSelector } from '@src/selectors/app'
import { openUrl, useGoBack, Zendesk } from '@src/utils'
import { ActionRequiredContainer } from '@src/screens/Common/containers'
import { ReferAFriend } from '../ReferAFriend'

export const HQApprovalScreen = () => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const user = useSelector(userSelector)
  const contactSupport = useCallback(() => {
    openUrl(Zendesk.baseUrl(user?.email))
  }, [user])

  useEffect(() => {
    dispatch({
      type: 'users/fetch',
    })
  }, [dispatch])

  return (
    <View style={styles.containerWrapper}>
      <ActionRequiredContainer
        subtitle="Your CGM order is pending approval"
        subtitleProps={{ type: 'regular' }}
        description="This usually takes 1 - 2 business days."
        iconName="clock"
        iconProps={{ color: theme['theme.primary.base'], weight: 'duotone' }}
      >
        <View style={styles.container}>
          <ReferAFriend />
          <View style={styles.content}>
            <Button type="primary" size="block" accessibilityLabel="Explore app" onPress={goBack}>
              Explore app
            </Button>
            <Button
              type="transparent"
              size="block"
              accessibilityLabel="Contact support"
              onPress={contactSupport}
            >
              Need help? Contact Support
            </Button>
          </View>
        </View>
      </ActionRequiredContainer>
    </View>
  )
}

const themedStyle = StyleService.create({
  containerWrapper: { flex: 1 },
  container: {
    flex: 1,
    justifyContent: 'space-between',
    marginHorizontal: 16,
  },
  content: {
    marginHorizontal: 4,
  },
})
