export const LIMITS_STRING_VALUES: { [key: number]: string | undefined } = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
}

export const ADDON_MAX_QUANTITY = 6

export const STRIPE_VIEW_INVOICES =
  'https://billing.nutrisense.io/p/login/5kA6re8gX7ly5wIeUU?prefilled_email='

export const INSURANCE_ELIGIBILITY_FORM_VIEW_TITLE = 'Nutrisense - Video Consult'
