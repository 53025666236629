import React, { useState } from 'react'
import { Image, ImageStyle, View, TouchableOpacity } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation, useRoute } from '@react-navigation/core'
import { RouteProp } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import {
  Feature,
  InlineAlert,
  ProgressBar,
  ScrollViewWithFade,
  VideoPlayer,
  useFeatureFlag,
} from '@components'
import { useImpersonationContext } from '@config/impersonation'
import { AppStackParamList } from '@navigation/types'
import { NavigationContainer } from '@screens/Common/containers'
import { STEPS } from '@src/screens/Education/constants'
import { useVisitedTutorial } from '@screens/Education/hooks'
import { TutorialKey } from '@screens/Education/types'
import { contactSupport } from '@utils'

export const CGMEducation = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const route = useRoute<RouteProp<AppStackParamList, 'CGMEducation'>>()
  const params = route.params || {}

  const isImpersonating = useImpersonationContext()
  const displayActivationButton = !useFeatureFlag(Feature.AbbottPartnership)

  useVisitedTutorial(TutorialKey.CGMEducation)

  const currentStep = STEPS[currentStepIndex]
  const isLastStep = currentStepIndex === STEPS.length - 1

  const onBackPress = () => {
    if (currentStepIndex === 0) {
      navigation.goBack()
    } else {
      setCurrentStepIndex(currentStepIndex - 1)
    }
  }

  const onNextPress = () => {
    if (isLastStep) {
      return
    }

    setCurrentStepIndex(currentStepIndex + 1)
  }

  const onActivatePress = () => {
    navigation.navigate('AddScan', {
      action: 'activate',
      parentScreen: params.parentScreen,
      parentScreenParams: (params as any).parentScreenParams,
    })
  }

  return (
    <NavigationContainer
      title={currentStep.title}
      leftIcon={{ pack: 'eva', name: 'close' }}
      style={styles.container}
    >
      <ProgressBar
        style={styles.progressBar}
        currentStep={currentStepIndex}
        stepsCount={STEPS.length}
        duration={currentStep.duration}
      />
      <ScrollViewWithFade scrollViewStyle={styles.scrollViewContent}>
        {currentStep.imageSource && (
          <View style={styles.mediaContainer}>
            <Image
              source={currentStep.imageSource}
              style={[styles.media as ImageStyle, styles.borderRadius as ImageStyle]}
            />
          </View>
        )}
        {currentStep.video && (
          <View style={styles.mediaContainer}>
            <VideoPlayer
              source={currentStep.video.source}
              style={[styles.media, styles.borderRadius]}
              showControls={false}
              isLooping
              isMuted
              playOnLoad
            />
          </View>
        )}
        {currentStep.description && (
          <Text type="regular" style={styles.description}>
            {currentStep.description}
          </Text>
        )}
        {currentStep.sections?.map((section) => (
          <View style={styles.section} key={section.description}>
            {section.imageSource && (
              <View style={styles.sectionImageContainer}>
                <Image source={section.imageSource} style={styles.media as ImageStyle} />
              </View>
            )}
            {section.description && (
              <Text type="regular" style={styles.sectionDescription}>
                {section.description}
              </Text>
            )}
          </View>
        ))}
        {currentStep.warning && (
          <InlineAlert style={styles.warningMessage} message={currentStep.warning} />
        )}
        {currentStep.showContactSupport && (
          <TouchableOpacity accessibilityLabel="Contact Support" onPress={contactSupport}>
            <Text type="regular" style={styles.contactSupport}>
              Click here to contact support
            </Text>
          </TouchableOpacity>
        )}
      </ScrollViewWithFade>
      <View style={[styles.buttonsContainer, { paddingBottom: (insets?.bottom || 0) + 16 }]}>
        {!isLastStep && (
          <>
            <Button
              type="outline"
              size="block"
              style={styles.backButton}
              onPress={onBackPress}
              accessibilityLabel="Back"
            >
              Back
            </Button>
            <Button
              type="primary"
              size="block"
              style={styles.nextButton}
              onPress={onNextPress}
              accessibilityLabel="Next"
            >
              Next
            </Button>
          </>
        )}
        {isLastStep && displayActivationButton && (
          <Button
            type="primary"
            size="block"
            disabled={isImpersonating}
            style={styles.nextButton}
            onPress={onActivatePress}
            accessibilityLabel="Activate your sensor"
          >
            Activate Your Sensor
          </Button>
        )}
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
  scrollViewContent: {
    padding: 24,
  },
  progressBar: {
    paddingHorizontal: 24,
    marginTop: 16,
    marginBottom: 8,
  },
  mediaContainer: {
    paddingHorizontal: 24,
  },
  media: {
    width: '100%',
    maxWidth: 600,
    height: undefined,
    maxHeight: 600,
    alignSelf: 'center',
    aspectRatio: 1,
  },
  borderRadius: {
    borderRadius: 20,
  },
  description: {
    marginTop: 24,
  },
  section: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 32,
  },
  sectionImageContainer: {
    width: 40,
    height: 40,
    marginRight: 16,
  },
  sectionDescription: {
    flexWrap: 'wrap',
    flex: 1,
  },
  warningMessage: {
    marginTop: 24,
  },
  contactSupport: {
    marginTop: 16,
    color: 'theme.text.link',
  },
  buttonsContainer: {
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
  backButton: {
    flex: 2,
    marginRight: 16,
  },
  nextButton: {
    flex: 3,
  },
})
