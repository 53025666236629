import React, { useState } from 'react'
import { FlatList, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { orderBy } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { FuseSearchBar } from '@components/FuseSearchBar'
import { ActivityListItem } from '@screens/Activities'
import { Activity } from '@src/types'

const FUSE_OPTIONS = {
  includeScore: true,
  threshold: 0.25,
  keys: ['description', 'title'],
}

interface ActivitiesListProps {
  items: Activity[]
  emptyListIcon: React.ReactNode
  emptyListText: string
  onItemSelected: (item: Activity) => void
  onToggleFavorite: (item: Activity) => void
}

export const ActivitiesList = ({
  items,
  emptyListIcon,
  emptyListText,
  onItemSelected,
  onToggleFavorite,
}: ActivitiesListProps) => {
  const styles = useStyleSheet(themedStyle)

  const [filteredList, setFilteredList] = useState<Activity[]>([])

  const orderedItems = orderBy(filteredList, 'createdAt', 'desc')

  if (items.length === 0) {
    return (
      <View style={styles.emptyList}>
        {emptyListIcon}
        <Text type="title-2" style={styles.emptyTitle}>
          It's empty here...
        </Text>
        <Text type="regular" bold style={styles.emptyDescription}>
          {emptyListText}
        </Text>
      </View>
    )
  }

  return (
    <SafeAreaView edges={['bottom']} style={styles.container}>
      <FuseSearchBar rawList={items} onSearchResult={setFilteredList} options={FUSE_OPTIONS} />
      <FlatList
        data={orderedItems}
        keyExtractor={(item: Activity) => item.id}
        renderItem={({ item }) => (
          <ActivityListItem
            item={item}
            onItemSelected={onItemSelected}
            onToggleFavorite={onToggleFavorite}
          />
        )}
        style={styles.list}
      />
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
  },
  list: {
    flex: 1,
    flexBasis: 0,
    paddingHorizontal: 16,
  },
  emptyList: {
    flex: 1,
    alignItems: 'center',
    paddingTop: 64,
    paddingHorizontal: 24,
    paddingBottom: 16,
  },
  emptyTitle: {
    marginTop: 32,
    textAlign: 'center',
  },
  emptyDescription: {
    marginTop: 16,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
})
