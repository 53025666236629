import React from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { isNumber } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { scoreColor } from '@src/components/nutritionCard/utils'
import { scoreSelector } from '@src/selectors/app'

interface ScoreProps {
  number?: number
}

export const Score = ({ number }: ScoreProps) => {
  const theme = useTheme()
  const styles = useStyleSheet(themedStyle)

  const { danger, warning } = useSelector(scoreSelector)

  const color = isNumber(number)
    ? theme[scoreColor(number, danger, warning)]
    : theme['theme.surface.base']

  return (
    <View style={styles.score}>
      <View style={[styles.static, styles.border, { borderColor: color }]} />
      <View style={[styles.static, styles.background, { backgroundColor: color }]} />
      {isNumber(number) ? (
        <Text type="title-3" style={{ color }}>
          {number}
        </Text>
      ) : (
        <Text type="regular" bold style={styles.undefinedScore}>
          N/A
        </Text>
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  score: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 32,
  },
  static: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  border: {
    borderRadius: 16,
    borderWidth: 2,
    opacity: 0.2,
  },
  background: {
    borderRadius: 16,
    opacity: 0.1,
  },
  undefinedScore: {
    color: 'theme.text.tertiary',
  },
})
