import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Video } from 'expo-av'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { VideoPlayer } from '@src/components/VideoPlayer/VideoPlayer'
import { LessonItemContentProps } from '../../lesson.types'
import { LessonItemContentMedia } from '../../models/courses.types'
import { LessonItemContainer } from './LessonItemContainer'

type VideoComponentProps = LessonItemContentProps & {
  item: LessonItemContentMedia
}

export const VideoComponent = (props: VideoComponentProps) => {
  const { item, isCompleted, onNext, onComplete } = props
  const styles = useStyleSheet(themedStyles)
  const videoRef = React.useRef<Video | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  const onNextPress = () => {
    if (isCompleted) {
      videoRef.current?.pauseAsync()
      onNext()
    } else {
      videoRef.current?.playAsync()
    }
  }

  const navButtonOverrideProps = {
    nextDisabled: isLoading,
    onNext: onNextPress,
    preCompleteNextTitle: 'Start',
  }

  return (
    <LessonItemContainer
      {...props}
      {...navButtonOverrideProps}
      lessonItemIcon="video"
      lessonItemTitle={item.title}
    >
      {!hasError ? (
        <View style={styles.contentContainer}>
          <VideoPlayer
            ref={videoRef}
            source={{ uri: item?.media?.data?.attributes?.url }}
            onReadyForDisplay={() => setIsLoading(false)}
            onPlay={onComplete}
          />
        </View>
      ) : (
        <TouchableOpacity
          style={styles.errorContainer}
          onPress={() => setHasError(false)}
          accessibilityLabel="refresh"
        >
          <Icon name="arrow-counter-clockwise" style={styles.replayIcon} />
          <Text type="regular">Unable to play the video, retry</Text>
        </TouchableOpacity>
      )}
    </LessonItemContainer>
  )
}

const themedStyles = StyleService.create({
  contentContainer: {
    flex: 1,
  },
  errorContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 8,
    backgroundColor: 'theme.surface.base2',
  },
  replayIcon: {
    width: 32,
    height: 32,
    marginBottom: 16,
  },
})
