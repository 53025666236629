import { gql } from '@apollo/client'

export const SAVE_SURVEY_RESPONSE = gql`
  mutation saveSurveyResponse(
    $surveyLinkId: ID!
    $questionKey: String!
    $answer: JSON!
    $warnBeforeStopping: Boolean
  ) {
    saveSurveyResponse(
      surveyLinkId: $surveyLinkId
      questionKey: $questionKey
      answer: $answer
      warnBeforeStopping: $warnBeforeStopping
    ) {
      id
    }
  }
`
