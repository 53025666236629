import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { Feature, useFeatureFlag } from '@src/components'
import { SurveysConfigKind } from '@src/types'
import { SubscriptionActionScreen } from '../SubscriptionActionScreen'
import { Actions } from '../ProgramDetails/Actions'
import { isOnCommitment } from '../../utils/utils'
import { useSubscriptionFromRoute } from '../../utils/hooks'
import { Options } from './Options'

export const CancelSubscriptionOtherOptions = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const inAppSubscriptionPausing = useFeatureFlag(Feature.SubscriptionPausing)
  const { subscription, primaryProduct } = useSubscriptionFromRoute()

  if (!subscription) {
    return null
  }

  const onPress = () => {
    const params = { subscriptionId: subscription.id }

    if (isOnCommitment(subscription.commitmentEndAt)) {
      navigation.navigate('CancelSubscriptionOnCommitment', params)
      return
    }

    if (!primaryProduct?.core || !primaryProduct.includesCgm) {
      // Only show the cancellation survey for core cgm subscriptions
      navigation.navigate('CancelSubscriptionConfirmation', params)
      return
    }

    navigation.navigate('Questionnaire', {
      questionnaire: SurveysConfigKind.Cancellation,
      nextScreen: { params } as any,
    })
  }

  const reasons = [
    "Gain valuable insights into your body's unique data and patterns",
    'Make personalized nutrition changes for a healthier lifestyle',
    "Stay on track with Nutrisense's team of experts",
  ]

  return (
    <SubscriptionActionScreen
      name="Modify Subscription"
      title="We hope it’s not goodbye"
      description="We have several different options to snooze or change your subscription, take a look!"
    >
      <Options subscription={subscription} inAppSubscriptionPausing={inAppSubscriptionPausing} />

      <View style={styles.benefitsContainer}>
        <Text type="regular" style={styles.benefitsText}>
          ...and keep enjoying the benefits of a personalized health plan
        </Text>
        <View style={styles.reasonsList}>
          {reasons.map((reason) => (
            <View key={reason} style={styles.reason}>
              <Icon name="check" style={styles.checkmark} />
              <Text type="regular" lineSpacing="tight" style={styles.reasonText}>
                {reason}
              </Text>
            </View>
          ))}
        </View>
      </View>
      <Actions
        style={styles.actions}
        secondaryButtonText="No, I want to cancel my subscription"
        onSecondaryButtonPress={onPress}
      />
    </SubscriptionActionScreen>
  )
}

const themedStyle = StyleService.create({
  benefitsContainer: {
    marginTop: 24,
    alignItems: 'center',
    paddingHorizontal: 40,
  },
  benefitsText: {
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  reasonsList: {
    marginTop: 8,
  },
  reason: {
    marginTop: 16,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  checkmark: {
    color: 'theme.success.base',
  },
  reasonText: {
    flexShrink: 1,
    marginLeft: 16,
  },
  actions: {
    marginTop: 20,
  },
})
