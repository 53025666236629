import React, { ReactNode, useMemo } from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from '@src/components/base'

const BULLET_LIST_INDENT = 16

type SlateText = {
  text: string
}

type SlateElement = {
  type: string
  children: SlateNode[]
}

export type SlateNode = SlateText | SlateElement

const styles = StyleSheet.create({
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 16,
    marginBottom: 4,
  },
  listItemText: {
    flexShrink: 1,
  },
  bulletPoint: {
    marginRight: 4,
  },
})

const extractText = (nodes: SlateNode[]): string => {
  return nodes
    .map((node) => {
      if ('text' in node) {
        return node.text
      }
      if ('children' in node) {
        return extractText(node.children)
      }
      return ''
    })
    .join(' ')
}

const renderText = (nodes: SlateNode[]): ReactNode => {
  return (
    <Text type="regular" numberOfLines={2} ellipsizeMode="tail">
      {extractText(nodes)}
    </Text>
  )
}

const renderNode = (node: SlateNode, key: number, level: number = 1): ReactNode => {
  if ('text' in node) {
    return (
      <Text type="regular" key={key}>
        {node.text}
      </Text>
    )
  }

  const paddingLeft = level * BULLET_LIST_INDENT

  switch (node.type) {
    case 'paragraph':
      return (
        <View key={key}>
          <Text type="regular">{extractText(node.children)}</Text>
        </View>
      )
    case 'heading-one':
      return (
        <Text type="title-1" key={key}>
          {extractText(node.children)}
        </Text>
      )
    case 'heading-two':
      return (
        <Text type="title-2" key={key}>
          {extractText(node.children)}
        </Text>
      )
    case 'bulleted-list':
      return node.children.map((child, j) => {
        const isLastNestingLevel = 'children' in child && 'text' in child.children[0]

        return isLastNestingLevel ? (
          <View key={key}>
            <View key={j} style={[styles.listItem, { paddingLeft }]}>
              <Text type="tiny" style={styles.bulletPoint}>
                •
              </Text>
              <View style={styles.listItemText}>{renderNode(child, j, level + 1)}</View>
            </View>
          </View>
        ) : (
          renderNode(child, j, level + 1)
        )
      })
    default:
      return (
        <Text type="regular" key={key}>
          {extractText(node.children)}
        </Text>
      )
  }
}

export const useSlateToReact = (
  bodyJSON: string = '[]',
): {
  conciseView: React.ReactNode
  detailedView: ReactNode[]
} => {
  const nodes = useMemo<SlateNode[]>(() => JSON.parse(bodyJSON), [bodyJSON])

  return {
    conciseView: useMemo(() => renderText(nodes), [nodes]),
    detailedView: useMemo(() => nodes.map((node, i) => renderNode(node, i)), [nodes]),
  }
}
