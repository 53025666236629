import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { AccessibilityInfo } from 'react-native'

export const AccessibilityContext = React.createContext({
  reduceMotionEnabled: false,
})

export const useAccessibilityContext = () => {
  return useContext(AccessibilityContext)
}

export const useReducedMotion = () => {
  const { reduceMotionEnabled } = useAccessibilityContext()
  return reduceMotionEnabled
}

export const AccessibilityProvider = ({ children }: { children: ReactNode }) => {
  const [reduceMotionEnabled, setReduceMotionEnabled] = useState(false)

  useEffect(() => {
    const reduceMotionChangedSubscription = AccessibilityInfo.addEventListener(
      'reduceMotionChanged',
      (isReduceMotionEnabled) => {
        setReduceMotionEnabled(isReduceMotionEnabled)
      },
    )

    AccessibilityInfo.isReduceMotionEnabled().then((isReduceMotionEnabled) => {
      setReduceMotionEnabled(isReduceMotionEnabled)
    })

    return () => {
      reduceMotionChangedSubscription.remove()
    }
  }, [])

  return (
    <AccessibilityContext.Provider value={{ reduceMotionEnabled }}>
      {children}
    </AccessibilityContext.Provider>
  )
}
