import React from 'react'
import { PanResponder, View, I18nManager } from 'react-native'
import DefaultMarker from '@ptomasroos/react-native-multi-slider/DefaultMarker'
import { StyleService } from '@src/style/service'
import { withLandscapeOrientation } from '@utils/orientation'

const DEFAULT_SLIDER_HEIGHT = 30
const DEFAULT_MAKER_OFFSET_X = 0
const DEFAULT_MARKER_TOUCH_SIZE = 30
const DEFAULT_SLIP_DISPLACEMENT = 200

const MIN_SLIDER_STEP = 0.5 // we can't move slider less than on 0.5
const HIT_SLOP = 16

const closest = (array, n) => {
  let minI = 0
  let maxI = array.length - 1

  if (array[minI] > n) {
    return minI
  } else if (array[maxI] < n) {
    return maxI
  } else if (array[minI] <= n && n <= array[maxI]) {
    let closestIndex = null

    while (closestIndex === null) {
      const midI = Math.round((minI + maxI) / 2)
      const midVal = array[midI]

      if (midVal === n) {
        closestIndex = midI
      } else if (maxI === minI + 1) {
        const minValue = array[minI]
        const maxValue = array[maxI]
        const deltaMin = Math.abs(minValue - n)
        const deltaMax = Math.abs(maxValue - n)

        closestIndex = deltaMax <= deltaMin ? maxI : minI
      } else if (midVal < n) {
        minI = midI
      } else if (midVal > n) {
        maxI = midI
      } else {
        closestIndex = -1
      }
    }

    return closestIndex
  }

  return -1
}

const valueToPosition = (value, valuesArray, sliderLength) => {
  const index = closest(valuesArray, value)

  const arrLength = valuesArray.length - 1
  const validIndex = index === -1 ? arrLength : index

  return (sliderLength * validIndex) / arrLength
}

const positionToValue = (position, valuesArray, sliderLength) => {
  if (position < 0 || sliderLength < position) {
    return null
  }

  const arrLength = valuesArray.length - 1
  const index = Math.round((arrLength * position) / sliderLength)
  return valuesArray[index]
}

const createArray = (start, end, sliderLength, stepValue) => {
  const result = []

  const difference = Math.abs(start - end)

  const step = stepValue ?? difference / (sliderLength / MIN_SLIDER_STEP)

  if (!step) {
    return result
  }

  const direction = start - end > 0 ? -1 : 1
  const length = difference / step + 1
  for (let i = 0; i < length; i++) {
    result.push(start + i * step * direction)
  }
  return result
}

const getConfined = (bottom, top, unconfined) => {
  if (unconfined < bottom) {
    return bottom
  }

  if (unconfined > top) {
    return top
  }

  return unconfined
}

class MultiSlider extends React.Component {
  static defaultProps = {
    values: [],
    min: 0,
    max: 10,
    lowLimit: -1,
    highLimit: -1,
    enabledOne: true,
    enabledTwo: true,
    enabledBetween: true,
    onValuesChangeStart: () => {},
    onValuesChange: () => {},
    onValuesChangeFinish: () => {},
    customMarker: DefaultMarker,
    slipDisplacement: DEFAULT_SLIP_DISPLACEMENT,
    markerTouchSize: DEFAULT_MARKER_TOUCH_SIZE,
    markerOffsetX: DEFAULT_MAKER_OFFSET_X,
    sliderHeight: DEFAULT_SLIDER_HEIGHT,
    isLandscape: false,
  }

  constructor(props) {
    super(props)

    const { min, max, sliderLength, values, step } = this.props

    const optionsArray = createArray(min, max, sliderLength, step)

    const initialValues = values.map((value) => valueToPosition(value, optionsArray, sliderLength))

    this.state = {
      valueOne: values[0] ?? min,
      valueTwo: values[1] ?? max,
      pastOne: initialValues[0] || 0,
      pastTwo: initialValues[1] || 0,
      positionOne: initialValues[0] || 0,
      positionTwo: initialValues[1] || 0,
      optionsArray,
    }

    this.subscribePanResponder()
  }

  componentDidUpdate(prevProps) {
    const { min, max, sliderLength } = prevProps
    if (
      this.props.min !== min ||
      this.props.max !== max ||
      this.props.sliderLength !== sliderLength
    ) {
      this.reset()
    }
  }

  subscribePanResponder = () => {
    const customPanResponder = (start, move, end) => {
      return PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onStartShouldSetPanResponderCapture: () => true,
        onMoveShouldSetPanResponder: () => true,
        onMoveShouldSetPanResponderCapture: () => true,
        onPanResponderGrant: () => start(),
        onPanResponderMove: (_, gestureState) => move(gestureState),
        onPanResponderTerminationRequest: () => false,
        onPanResponderRelease: (_, gestureState) => end(gestureState),
        onPanResponderTerminate: (_, gestureState) => end(gestureState),
        onShouldBlockNativeResponder: () => true,
      })
    }

    this._panResponderBetween = customPanResponder(
      (gestureState) => {
        this.startOne(gestureState)
        this.startTwo(gestureState)
      },
      (gestureState) => {
        this.moveOne(gestureState, true)
        this.moveTwo(gestureState, true)
      },
      (gestureState) => {
        this.endOne(gestureState)
        this.endTwo(gestureState)
      },
    )

    this._panResponderOne = customPanResponder(this.startOne, this.moveOne, this.endOne)
    this._panResponderTwo = customPanResponder(this.startTwo, this.moveTwo, this.endTwo)
  }

  reset = () => {
    const { min, max, sliderLength, onValuesChangeFinish } = this.props

    const optionsArray = createArray(min, max, sliderLength)

    const initialValues = [min, max].map((value) =>
      valueToPosition(value, optionsArray, sliderLength),
    )

    const { valueOne, valueTwo } = this.state

    this.setState({
      valueOne: min,
      valueTwo: max,
      pastOne: initialValues[0] || 0,
      pastTwo: initialValues[1] || 0,
      positionOne: initialValues[0] || 0,
      positionTwo: initialValues[1] || 0,
      optionsArray,
    })

    if (valueOne === min && valueTwo === max) {
      return
    }

    const change = [min, max]
    onValuesChangeFinish(change)
  }

  startOne = () => {
    this.props.onValuesChangeStart()
    this.setState((prevState) => ({
      onePressed: !prevState.onePressed,
    }))
  }

  startTwo = () => {
    this.props.onValuesChangeStart()
    this.setState((prevState) => ({
      twoPressed: !prevState.twoPressed,
    }))
  }

  moveOne = (gestureState, fromPanResponderBetween) => {
    const accumDistance = this.props.isLandscape ? gestureState.dy : gestureState.dx
    const accumDistanceDisplacement = this.props.isLandscape ? gestureState.dx : gestureState.dy

    if (accumDistance > 0 && fromPanResponderBetween && this.state.valueTwo === this.props.max) {
      return
    }

    const unconfined = I18nManager.isRTL
      ? this.state.pastOne - accumDistance
      : accumDistance + this.state.pastOne
    const bottom = 0
    const trueTop = this.state.positionTwo
    const top = trueTop === 0 ? 0 : trueTop || this.props.sliderLength
    const confined = getConfined(bottom, top, unconfined)
    const slipDisplacement = this.props.slipDisplacement

    if (Math.abs(accumDistanceDisplacement) < slipDisplacement || !slipDisplacement) {
      const value = positionToValue(confined, this.state.optionsArray, this.props.sliderLength)

      if (this.props.lowLimit > 0 && value > this.props.lowLimit) {
        return
      }

      if (value !== this.state.valueOne) {
        this.setState(
          {
            valueOne: value,
            positionOne: confined,
          },
          () => {
            this.props.onValuesChange([this.state.valueOne, this.state.valueTwo])
          },
        )
      } else {
        this.setState({
          positionOne: confined,
        })
      }
    }
  }

  moveTwo = (gestureState, fromPanResponderBetween) => {
    const accumDistance = this.props.isLandscape ? gestureState.dy : gestureState.dx
    const accumDistanceDisplacement = this.props.isLandscape ? gestureState.dx : gestureState.dy

    if (accumDistance < 0 && fromPanResponderBetween && this.state.valueOne === this.props.min) {
      return
    }

    const unconfined = I18nManager.isRTL
      ? this.state.pastTwo - accumDistance
      : accumDistance + this.state.pastTwo
    const bottom = this.state.positionOne
    const top = this.props.sliderLength
    const confined = getConfined(bottom, top, unconfined)
    const slipDisplacement = this.props.slipDisplacement

    if (Math.abs(accumDistanceDisplacement) < slipDisplacement || !slipDisplacement) {
      const value = positionToValue(confined, this.state.optionsArray, this.props.sliderLength)

      if (this.props.highLimit > 0 && value < this.props.highLimit) {
        return
      }

      if (value !== this.state.valueTwo) {
        this.setState(
          {
            valueTwo: value,
            positionTwo: confined,
          },
          () => {
            this.props.onValuesChange([this.state.valueOne, this.state.valueTwo])
          },
        )
      } else {
        this.setState({
          positionTwo: confined,
        })
      }
    }
  }

  endOne = () => {
    this.setState((prevState) => ({
      pastOne: prevState.positionOne,
      onePressed: !prevState.onePressed,
    }))
    this.props.onValuesChangeFinish([this.state.valueOne, this.state.valueTwo])
  }

  endTwo = () => {
    this.setState((prevState) => ({
      twoPressed: !prevState.twoPressed,
      pastTwo: prevState.positionTwo,
    }))
    this.props.onValuesChangeFinish([this.state.valueOne, this.state.valueTwo])
  }

  render() {
    const { positionOne, positionTwo } = this.state
    const {
      selectedStyle,
      unselectedStyle,
      sliderLength,
      sliderHeight,
      markerOffsetX,
      markerOffsetY,
      markerTouchSize,
    } = this.props

    const trackOneLength = positionOne
    const trackOneStyle = unselectedStyle

    const trackThreeLength = sliderLength - positionTwo
    const trackThreeStyle = unselectedStyle

    const trackTwoLength = sliderLength - trackOneLength - trackThreeLength
    const trackTwoStyle = selectedStyle

    const Marker = this.props.customMarker

    const markerContainerOne = {
      top: markerOffsetY ?? 0,
      left: trackOneLength + markerOffsetX - markerTouchSize / 2,
      width: markerTouchSize,
      height: markerTouchSize,
    }

    const markerContainerTwo = {
      top: markerOffsetY ?? 0,
      right: trackThreeLength - markerOffsetX - markerTouchSize / 2,
      width: markerTouchSize,
      height: markerTouchSize,
    }

    const containerStyle = [styles.container, this.props.containerStyle]

    const fullTrackStyle = [
      styles.fullTrack,
      this.props.fullTrackStyle,
      { width: sliderLength, height: sliderHeight },
    ]

    return (
      <View style={containerStyle}>
        <View style={fullTrackStyle}>
          <View
            style={[
              this.props.trackStyle,
              trackOneStyle,
              { width: trackOneLength, height: sliderHeight },
            ]}
          />
          <View
            style={[
              this.props.trackStyle,
              trackTwoStyle,
              { width: trackTwoLength, height: sliderHeight },
            ]}
            {...(this.props.enabledBetween ? this._panResponderBetween.panHandlers : {})}
          />
          <View
            style={[
              this.props.trackStyle,
              trackThreeStyle,
              { width: trackThreeLength, height: sliderHeight },
            ]}
          />
          <View
            style={[
              styles.markerContainer,
              markerContainerOne,
              positionOne > sliderLength / 2 && styles.topMarkerContainer,
            ]}
          >
            <View style={styles.touch} {...this._panResponderOne.panHandlers} hitSlop={HIT_SLOP}>
              <Marker
                enabled={this.props.enabledOne}
                pressed={this.state.onePressed}
                markerStyle={this.props.markerStyle}
                pressedMarkerStyle={this.props.pressedMarkerStyle}
                disabledMarkerStyle={this.props.disabledMarkerStyle}
                currentValue={this.state.valueOne}
              />
            </View>
          </View>
          {positionOne !== this.props.sliderLength && (
            <View style={[styles.markerContainer, markerContainerTwo]}>
              <View style={styles.touch} {...this._panResponderTwo.panHandlers} hitSlop={HIT_SLOP}>
                <Marker
                  enabled={this.props.enabledTwo}
                  pressed={this.state.twoPressed}
                  markerStyle={this.props.markerStyle}
                  pressedMarkerStyle={this.props.pressedMarkerStyle}
                  disabledMarkerStyle={this.props.disabledMarkerStyle}
                  currentValue={this.state.valueTwo}
                />
              </View>
            </View>
          )}
        </View>
      </View>
    )
  }
}

const styles = StyleService.create({
  container: {
    justifyContent: 'center',
  },
  fullTrack: {
    flexDirection: 'row',
  },
  markerContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topMarkerContainer: {
    zIndex: 1,
  },
  touch: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: 'theme.transparent',
  },
})

const MultiSlideWithLandscapeOrientation = withLandscapeOrientation(MultiSlider)

export { MultiSlideWithLandscapeOrientation as MultiSlider }
