import React from 'react'
import { CommonSelect } from '@components'

interface SelectOption {
  value: string
  text: string
}

interface AddressSelectProps {
  label: string
  selectedOption: SelectOption
  onChange: (text: string) => void
  options: SelectOption[]
  disabled?: boolean
}

export const AddressSelect = ({
  label,
  selectedOption,
  onChange,
  options,
  disabled,
}: AddressSelectProps) => (
  <CommonSelect
    options={options}
    selectedOption={selectedOption}
    onSelect={({ value }) => onChange(value)}
    accessibilityLabel="Address"
    disabled={disabled}
    label={label}
  />
)
