import React from 'react'
import { View } from 'react-native'
import { capitalize } from 'lodash'
import moment from 'moment'
import FastImage, { ImageStyle } from 'react-native-fast-image'
import { CircleIcon, Score } from '@src/components'
import { Text } from '@src/components/base'
import { formatScore } from '@src/components/nutritionCard/utils'
import { MEAL_ICON_KIND_MAP } from '@src/screens/Meals/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Meal, MealKind } from '@src/types'

interface MealItemProps {
  meal: Meal
  showDate?: boolean
}
export const MealItem = ({ meal, showDate }: MealItemProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.mealContainer}>
      {meal.avatar ? (
        <FastImage style={styles.mealImage as ImageStyle} source={{ uri: meal.avatar }} />
      ) : (
        <CircleIcon
          style={styles.mealImage}
          name={MEAL_ICON_KIND_MAP[meal.kind || MealKind.Lunch]}
        />
      )}
      <View style={styles.mealInfo}>
        <View style={styles.mealTitle}>
          <Text type="large">
            {capitalize(meal.kind || meal.type)}
            {' · '}
          </Text>
          <Text type="small" style={styles.secondaryText}>
            {moment(meal.occurredAt).format(showDate ? 'MMM D, YYYY' : 'h:mm A')}
          </Text>
        </View>
        <Text type="regular" numberOfLines={1} style={styles.secondaryText}>
          {meal.description || meal.ingredients.map((i) => i.description).join(', ')}
        </Text>
      </View>
      <Score number={formatScore(meal.statistics?.glucoseScore)} />
    </View>
  )
}

const themedStyle = StyleService.create({
  mealContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    flexShrink: 1,
    height: 76,
    paddingHorizontal: 16,
  },
  mealImage: {
    width: 56,
    height: 56,
    padding: 0,
    borderRadius: 28,
  },
  mealInfo: {
    flex: 1,
    marginHorizontal: 12,
  },
  mealTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  },
  secondaryText: {
    color: 'theme.text.secondary',
  },
})
