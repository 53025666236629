export const ALLOWED_SERVING_UNITS_TO_PLURALIZE = new Set([
  'dill',
  'quesadilla',
  'fajita',
  'drink',
  'slice',
  'burrito',
  'doughnut',
  'portion',
  'cucumber',
  'container',
  'pint',
  'floret',
  'pop',
  'breast',
  'head',
  'scallop',
  'apricot',
  'muffin',
  'pickle',
  'shell',
  'tablespoon',
  'roll',
  'mandarin',
  'flacker',
  'package',
  'lime',
  'kabob',
  'brownie',
  'packet',
  'cup',
  'pie',
  'sandwich',
  'cracker',
  'tomato',
  'rib',
  'donut',
  'fillet',
  'drumstick',
  'dumpling',
  'milliliter',
  'pattie',
  'yolk',
  'tube',
  'prune',
  'piece',
  'tin',
  'apple',
  'orange',
  'chop',
  'box',
  'bite',
  'leg',
  'heart',
  'triscuit',
  'salad',
  'can',
  'potato',
  'order',
  'cookie',
  'flute',
  'berry',
  'taco',
  'pastry',
  'tot',
  'croissant',
  'rind',
  'biscotti',
  'cupcake',
  'meal',
  'oreo',
  'enchilada',
  'shrimp',
  'plate',
  'walnut',
  'pizza',
  'onion',
  'wing',
  'section',
  'dash',
  'waffle',
  'pita',
  'pancake',
  'cherry',
  'mango',
  'peace',
  'plum',
  'ear',
  'pea',
  'raisin',
  'frankfurter',
  'yield',
  'outer',
  'biscuit',
  'steak',
  'teacup',
  'oyster',
  'nectarine',
  'jumbo',
  'unit',
  'sprig',
  'crisp',
  'drummette',
  'pretzel',
  'beet',
  'cube',
  'pack',
  'ring',
  'ball',
  'bun',
  'bowl',
  'capsule',
  'block',
  'strip',
  'pouch',
  'stalk',
  'bag',
  'chip',
  'bean',
  'tablet',
  'envelope',
  'almond',
  'inch',
  'teaspoon',
  'tray',
  'link',
  'carton',
  'gummy',
  'kernel',
  'leaf',
  'wrap',
  'avocado',
  'olive',
  'cocktail',
  'dog',
  'burger',
  'white',
  'fillet',
  'wedge',
  'drop',
  'thigh',
  'date',
  'square',
  'peanut',
  'meatball',
  'cake',
  'blackberry',
  'floweret',
  'spear',
  'carrot',
  'sprout',
  'bar',
  'clove',
  'omelet',
  'item',
  'raspberry',
  'bagel',
  'shot',
  'grape',
  'bottle',
  'mushroom',
  'glass',
  'half',
  'shake',
  'pepper',
  'serving',
  'cashew',
  'patty',
  'fruit',
  'tortilla',
  'nut',
  'stick',
  'ounce',
  'mug',
  'egg',
  'chicken',
  'gram',
  'scoop',
  'tbsp',
  'oz',
  'tsp',
])
