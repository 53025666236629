import React, { ReactNode } from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { GestureDismissibleModal, ScrollViewWithFade } from '@components'
interface GestureDismissibleStackedModalProps {
  actionTitle: string
  onActionPress: () => void
  secondaryTitle: string
  onSecondaryPress?: () => void
  children?: ReactNode
  dismissButtonText?: string
}
export const GestureDismissibleStackedModal = ({
  actionTitle,
  onActionPress,
  secondaryTitle,
  onSecondaryPress,
  children,
  dismissButtonText,
}: GestureDismissibleStackedModalProps) => {
  const styles = useStyleSheet(themedStyles)

  const insets = useSafeAreaInsets()

  return (
    <GestureDismissibleModal
      containerStyle={styles.modalContainer}
      contentContainerStyle={styles.modalContent}
      dismissButtonText={dismissButtonText}
    >
      <ScrollViewWithFade style={styles.modalContent} scrollViewStyle={styles.scrollView}>
        {children}
      </ScrollViewWithFade>
      <View style={[styles.buttonContainer, { marginBottom: insets.bottom + 16 }]}>
        <Button
          type="primary"
          size="block"
          accessibilityLabel={actionTitle}
          onPress={onActionPress}
        >
          {actionTitle}
        </Button>
        {onSecondaryPress && (
          <Button
            type="transparent"
            size="block"
            style={styles.secondaryButton}
            accessibilityLabel={secondaryTitle}
            onPress={onSecondaryPress}
          >
            {secondaryTitle}
          </Button>
        )}
      </View>
    </GestureDismissibleModal>
  )
}

const themedStyles = StyleService.create({
  modalContainer: {
    flex: 0,
    flexBasis: 'auto',
    marginTop: 'auto',
    padding: 16,
  },
  modalContent: {
    flex: 0,
    flexBasis: 'auto',
  },
  scrollView: {
    paddingBottom: 24,
  },
  buttonContainer: {
    marginTop: 16,
  },
  secondaryButton: {
    marginTop: 10,
  },
})
