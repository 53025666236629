import React from 'react'
import { View } from 'react-native'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Text } from '@components/base'

interface ListTitleProps {
  title: string
  info?: string
}

export const ListTitle = (props: ListTitleProps) => {
  const styles = useStyleSheet(themedStyle)
  const { info, title } = props

  return (
    <View style={styles.titleContainer}>
      <Text type="large" testID="ListCard/Title">
        {title}
      </Text>
      {info && (
        <>
          <View style={styles.dotSeparator} />
          <Text type="small" testID="ListCard/Info" style={styles.info}>
            {info}
          </Text>
        </>
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    color: 'theme.text.secondary',
  },
  dotSeparator: {
    marginHorizontal: 8,
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: 'theme.text.tertiary',
  },
})
