import React, { useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { camelCase, startCase } from 'lodash'
import { useSelector } from 'react-redux'
import { useStyleSheet, StyleService } from '@src/style/service'
import { Text, Button } from '@components/base'
import { BaseIngredient } from '@screens/Ingredients/types'
import { unitsAndDailyValuesSelector } from '@selectors/app'

const BASE_NUTRITION_ITEMS: { [key: string]: string[] } = {
  calories: [],
  totalFat: ['saturatedFat', 'monounsaturatedFat', 'polyunsaturatedFat'],
  totalCarb: ['sugars', 'fiber'],
  protein: [],
  cholesterol: [],
}

const BASE_NUTRITION_ITEMS_KEYS = [
  ...Object.keys(BASE_NUTRITION_ITEMS),
  ...Object.values(BASE_NUTRITION_ITEMS).flat(),
]

interface NutritionFactsProps {
  hideMicroNutrients?: boolean
  nutrition: BaseIngredient['nutrition']
  style?: StyleProp<ViewStyle>
  testID?: string
}

export const NutritionFacts = ({
  hideMicroNutrients,
  nutrition,
  style,
  testID,
}: NutritionFactsProps) => {
  const styles = useStyleSheet(themedStyles)
  const { units } = useSelector(unitsAndDailyValuesSelector)
  const [isExpanded, setExpanded] = useState(false)

  const nutritionItems = Object.entries(nutrition).reduce((acc, [nutrient, value]) => {
    const key = camelCase(nutrient)
    const unit = units[key]

    if (!unit) {
      return acc
    }

    return {
      ...acc,
      [key]: `${Math.round(value)}${unit}`,
    }
  }, {} as Record<string, string>)

  const otherNutrients = Object.keys(nutritionItems).filter(
    (key) => !BASE_NUTRITION_ITEMS_KEYS.includes(key),
  )

  const hasShowMoreButton = !hideMicroNutrients && otherNutrients.length > 0

  const renderNutritionItem = (key: string, isSubItem?: boolean) => {
    const textType = isSubItem ? 'small' : 'regular'
    const isBold = !isSubItem

    return (
      <View key={key} style={[styles.nutritionItem, isSubItem && styles.nutritionSubItem]}>
        <Text type={textType} bold={isBold}>
          {startCase(key)}
        </Text>
        <Text type={textType} bold={isBold} style={styles.value}>
          {nutritionItems[key]}
        </Text>
      </View>
    )
  }

  return (
    <View testID={testID} style={[styles.nutritionContainer, style]}>
      {Object.keys(BASE_NUTRITION_ITEMS).map((key) => {
        const nutritionSubItems = BASE_NUTRITION_ITEMS[key].filter(
          (subKey) => nutritionItems[subKey],
        )

        return (
          <View key={key}>
            {/* render main nutrition item if it's present */}
            {nutritionItems[key] && renderNutritionItem(key)}

            {/* render sub items */}
            {nutritionSubItems.map((subKey) =>
              // render regular item if main item is missing
              renderNutritionItem(subKey, !!nutritionItems[key]),
            )}
          </View>
        )
      })}

      {isExpanded && otherNutrients.map((key) => renderNutritionItem(key))}

      {hasShowMoreButton && (
        <Button
          type="transparent"
          size="small"
          accessibilityLabel={isExpanded ? 'Show Less' : 'Show More'}
          onPress={() => setExpanded((prevExpanded) => !prevExpanded)}
          style={styles.showMoreButton}
        >
          {isExpanded ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  nutritionContainer: {
    flex: 1,
  },
  nutritionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    borderBottomWidth: 2,
    borderBottomColor: 'theme.surface.base2',
  },
  nutritionSubItem: {
    height: 40,
    marginLeft: 8,
  },
  value: {
    textAlign: 'right',
  },
  showMoreButton: {
    marginVertical: 16,
  },
})
