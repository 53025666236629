import { useSelector } from 'react-redux'
import { useTheme } from '@ui-kitten/components'
import { scoreColor } from '@src/components/nutritionCard/utils'
import { scoreSelector } from '@src/selectors/app'

const getScoreTitle = (score: number, danger: number, warning: number) => {
  if (score <= danger) {
    return 'Needs Improvement'
  } else if (score <= warning) {
    return 'Ok'
  } else {
    return 'Good'
  }
}

export const useScoreRepresentations = (score?: number) => {
  const theme = useTheme()

  const { danger, warning } = useSelector(scoreSelector)

  return score
    ? {
        scoreTitle: getScoreTitle(score, danger, warning),
        scoreColor: theme[scoreColor(score, danger, warning)],
      }
    : {}
}
