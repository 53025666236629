import React from 'react'
import { TextElement } from '@ui-kitten/components'
import { Text, TextProps } from '@components/base'

interface TextWithBoldHighlightProps {
  children: string
  style?: TextProps['style']
}

enum TextPartType {
  Regular = 'Regular',
  Bold = 'Bold',
}

interface TextPart {
  type: TextPartType
  text: string
}

export const TextWithBoldHighlight = ({
  children,
  style,
}: TextWithBoldHighlightProps): TextElement => {
  const textParts: TextPart[] = children.split('*').map((text, index) => {
    if (index % 2 === 0) {
      return { type: TextPartType.Regular, text }
    } else {
      return { type: TextPartType.Bold, text }
    }
  })

  const renderTextPart = ({ type, text }: TextPart, index: number): React.ReactElement | string =>
    type === TextPartType.Regular ? (
      <Text type="title-3" key={index}>
        {text}
      </Text>
    ) : (
      text
    )

  return (
    <Text type="title-3" style={style}>
      {textParts.map(renderTextPart)}
    </Text>
  )
}
