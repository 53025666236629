import { palette } from '@style/palette'
import { ThemeName } from '@style/types'
import { flattenObject } from '@utils/flattenObject'

import { theme } from './theme'

const flatPalette = flattenObject({ palette })

export const getTheme = (themeName = ThemeName.Light) => ({
  ...theme[themeName],
  ...flatPalette,
})

export type PaletteKeys = keyof typeof flatPalette

export * from './types'
