import { pick } from 'lodash'
import { DecodedSensor } from 'react-native-freestyle-libre/types/FreeStyleLibre/status'
import { TagEvent } from 'react-native-nfc-manager'
import { CGMInteractionsDetailsObjectTypes } from './types'

export const buildDatadogCGMInteractionsDetailsObject = (
  object: {
    success?: boolean
    sensor?: DecodedSensor | null
    error?: string | null
    tag?: TagEvent | null
  },
  logType: CGMInteractionsDetailsObjectTypes,
) => {
  if (!object) {
    return {}
  }

  switch (logType) {
    case CGMInteractionsDetailsObjectTypes.Scanning:
      return {
        resultStatus: object.success,
        details: pick(object.sensor || {}, ['serialNumber', 'status', 'model']),
        error: object.error,
        tag: JSON.stringify(object.tag),
      }
    case CGMInteractionsDetailsObjectTypes.Activation:
      return {
        resultStatus: object?.success,
        details: pick(object.sensor || {}, ['serialNumber', 'status', 'model']),
        error: object.error,
        tag: JSON.stringify(object.tag),
      }
    case CGMInteractionsDetailsObjectTypes.Error:
      return object ? object : {}
  }
}
