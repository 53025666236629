import { gql } from '@apollo/client'

export const ALL_USERS_AUTOCOMPLETE = gql`
  query allUsersAutocomplete($query: String!) {
    allUsersAutocomplete(query: $query) {
      users {
        email
        fullName
        id
      }
    }
  }
`
