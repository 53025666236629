import { AuthorizeResult } from 'react-native-app-auth'
import { Cache } from '@src/models/app.types'
import {
  HealthDataMeasurementSourceDef,
  HealthDataMeasurementType,
  LinkupConnection,
  NumericalSelect,
  Reminder,
} from '@src/types'
import { IconDescriptor } from '@components'

export enum UnitSystem {
  Metric = 'metric',
  Imperial = 'imperial',
}

export enum DarkMode {
  Auto = 'auto',
  On = 'on',
  Off = 'off',
}

export interface StatsSetting {
  visible: boolean
  order: number
  key: string
  section: string
  __typename: 'StatsSettings'
}

export enum GlucoseSource {
  Combined = 'combined',
}

export type UserSettingsTypeName = 'UserSettings'

export interface MacroGoalsDaily {
  calories: number
  protein: number
  carbs: number
  netCarbs: number
  fat: number
  __typename: 'MacroGoals'
}

export enum DashboardGraph {
  Glucose = 'glucose',
  Combined = 'combined',
}

export interface IntegrationsSyncSettings {
  lastSyncedHealth: string | null
  lastSyncedKetoMojo: string | null
  ketoMojoUser?: AuthorizeResult
}

export interface SettingsStoreState {
  cache: Cache
  healthKitSync: boolean
  ketoMojoSync: boolean
  reminders: boolean
  showNetCarbs: boolean
  canScanExpired: boolean
  scanReminder: number
  glucoseSource: GlucoseSource
  glucoseLowThreshold: number
  glucoseHighThreshold: number
  ketoneLowThreshold: number
  ketoneHighThreshold: number
  dashboardGraph: DashboardGraph
  unitSystem: UnitSystem
  fastingStart: number
  fastingEnd: number
  macroGoalsDaily: MacroGoalsDaily
  patternsNotifications: boolean
  darkMode: DarkMode
  statsSettings: StatsSetting[]
  backup: any
  integrationsSyncSettings: IntegrationsSyncSettings
  terraProviders: TerraProvider[]
  libreLinkupConnection: LinkupConnection | null
  dailyMeasurementsSourcePriorities: SourcePriorities
  userReminders: Reminder[]
  __typename: UserSettingsTypeName
}

export const TERRA_APPLE_HEALTH = 'Apple Health'
export const TERRA_GOOGLE_FIT = 'Google Fit'
export const TERRA_KETO_MOJO = 'Keto-Mojo'

export interface TerraProvider {
  provider: string
  name: string
  active: boolean
  availableTypes: string[]
  connectionId: number | null
  status: string | null
  errorReason: string | null
  disabledTypes: string[] | null
}

export type Option = Omit<NumericalSelect, '__typename'>

export enum ThresholdType {
  Glucose = 'glucose',
  Ketone = 'ketone',
}

export interface SourcePriorityListItem extends HealthDataMeasurementSourceDef {
  icon: IconDescriptor
  title: string
}

export type SourcePriorities = {
  [key in HealthDataMeasurementType]: SourcePriorityListItem[] | null
}
