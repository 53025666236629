import { get, camelCase, keyBy } from 'lodash'
import { set } from 'lodash/fp'
import { fromKeyValuePairs } from '@src/transforms/index'
import {
  ClientConfig,
  HealthDataMeasurementSourceDef,
  HealthDataMeasurementTypeDef,
  TutorialStep,
} from '@src/types'

export type TransformedClientConfig = ClientConfig & {
  onboarding: {
    tutorials: { [key: string]: TutorialStep[] }
  }
  healthData: {
    measurementSourceDefLookup: { [key: string]: HealthDataMeasurementSourceDef }
    measurementTypeDefLookup: { [key: string]: HealthDataMeasurementTypeDef }
    dailyMeasurementSourceDefs: HealthDataMeasurementSourceDef[]
    dailyMeasurementTypeDefs: HealthDataMeasurementTypeDef[]
    dailyMeasurementSourceDefLookup: { [key: string]: HealthDataMeasurementSourceDef }
    dailyMeasurementTypeDefLookup: { [key: string]: HealthDataMeasurementTypeDef }
  }
  biteAi: {
    units: { [key: string]: string }
    dailyValues: { [key: string]: number }
  }
}

export const transform = (response: any): { clientConfig: TransformedClientConfig } => {
  const units = get(response, 'clientConfig.biteAi.units', [])
  const dailyValues = get(response, 'clientConfig.biteAi.dailyValues', [])
  const onboardingTutorials = get(response, 'clientConfig.onboarding.tutorials', [])
  const dayTabs = get(response, 'clientConfig.dayTabs', [])
  const history = get(response, 'clientConfig.history', {})
  const meals = get(response, 'clientConfig.meals', {})
  const historyTypes = fromKeyValuePairs(get(history, 'filters.types', []))
  const measurementSourceDefs = get(response, 'clientConfig.healthData.measurementSourceDefs', [])
  const measurementTypeDefs = get(response, 'clientConfig.healthData.measurementTypeDefs', [])
  const dailyMeasurementSourceDefs = measurementSourceDefs.filter(
    (sourceDef: HealthDataMeasurementSourceDef) => sourceDef.providesDailyMeasurements,
  )
  const dailyMeasurementTypeDefs = measurementTypeDefs.filter(
    (typeDef: HealthDataMeasurementTypeDef) => typeDef.isDailyMeasurement,
  )

  // !!!WARNING!!! lodash/fp set takes arguments in a different order from regular set
  let payload = set('clientConfig.biteAi.units', fromKeyValuePairs(units, camelCase), response)
  payload = set(
    'clientConfig.biteAi.dailyValues',
    fromKeyValuePairs(dailyValues, camelCase),
    payload,
  )
  payload = set(
    'clientConfig.onboarding',
    { tutorials: fromKeyValuePairs(onboardingTutorials, camelCase) },
    payload,
  )
  payload = set('clientConfig.dayTabs', fromKeyValuePairs(dayTabs), payload)
  payload = set('clientConfig.history', history, payload)
  payload = set('clientConfig.history.filters.types', historyTypes, payload)
  payload = set('clientConfig.meals', meals, payload)
  payload = set(
    'clientConfig.healthData.measurementSourceDefLookup',
    keyBy(measurementSourceDefs, 'key'),
    payload,
  )
  payload = set(
    'clientConfig.healthData.measurementTypeDefLookup',
    keyBy(measurementTypeDefs, 'key'),
    payload,
  )
  payload = set(
    'clientConfig.healthData.dailyMeasurementSourceDefs',
    dailyMeasurementSourceDefs,
    payload,
  )
  payload = set(
    'clientConfig.healthData.dailyMeasurementTypeDefs',
    dailyMeasurementTypeDefs,
    payload,
  )
  payload = set(
    'clientConfig.healthData.dailyMeasurementSourceDefLookup',
    keyBy(dailyMeasurementSourceDefs, 'key'),
    payload,
  )
  payload = set(
    'clientConfig.healthData.dailyMeasurementTypeDefLookup',
    keyBy(dailyMeasurementTypeDefs, 'key'),
    payload,
  )

  return payload
}
