import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/core'
import { useRoute } from '@react-navigation/native'
import moment from 'moment'
import { StackNavigationProp } from '@react-navigation/stack'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, IconButton, Text } from '@components/base'
import { Analytics, CustomEventTypes } from '@src/config'
import { AppRouteProp, AppStackParamList } from '@src/navigation/types'
import { useNextIncompleteLesson } from '@src/screens/Learn/hooks'
import { updateEngagementPromptConfig } from '@src/screens/Learn/utils'
import { useGoBack } from '@src/utils/navigation'
import { publishedProgramsSelector } from '@src/screens/Learn/models/courses.selectors'

export const TOOLTIP_START_TEXTS = [
  `While you wait for your CGMs to arrive, our team has prepared an exclusive course for you to jumpstart your learning.`,
  `Discover the secrets to a healthier metabolism! Whether you're interested in weight loss, disease prevention, or just feeling your best, our In-Range course will help you get there. Are you ready to start?`,
  `Are you curious about the connection between your diet, exercise, and metabolic health? Dive into the fascinating world of metabolism and learn how to optimize your health by starting our In-Range course.`,
  `Start your journey to learn about the science behind healthy metabolism and how to improve your own by starting our In-Range course.`,
]
export const TOOLTIP_RESUME_TEXTS = [
  `Ready to improve your metabolic health? Pick up where you left off in the In-Range course and start making positive changes today.`,
  `Investing in your metabolic health is investing in your overall well-being. Keep the momentum going by continuing the In-Range course.`,
  `Metabolic health is linked to a lower risk of chronic diseases such as diabetes and heart disease? Resume the In-Range course and work towards a healthier future!`,
]

export const EngagementPromptContainer = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()

  const {
    params: { type, cycle },
  } = useRoute<AppRouteProp<'CoursesEngagementPrompt'>>()

  const programs = useSelector(publishedProgramsSelector)
  const { lesson, parentScreenParams } = useNextIncompleteLesson(programs[0]?.id)

  const trackInteraction = (action: string) => {
    Analytics.track(CustomEventTypes.CoursesEngagementPromptInteraction, { type, action, cycle })
  }

  const handleActionButtonPress = () => {
    trackInteraction(type)

    if (type === 'start') {
      navigation.replace('CoursesProgram', { programId: programs[0].id })
    } else if (lesson) {
      navigation.replace(lesson.isExperiment ? 'CoursesExperiment' : 'CoursesLesson', {
        lesson,
        parentScreenParams,
      })
    } else {
      goBack()
    }
  }

  const onCloseIconPress = () => {
    trackInteraction('Close')
    goBack()
  }

  const hidePermanently = () => {
    const configKey = type === 'start' ? 'canShowStartPrompt' : 'canShowResumePrompt'

    updateEngagementPromptConfig({ [configKey]: false })
    trackInteraction("Don't Ask Again")
    goBack()
  }

  // Update the display count and last display date when the tooltip is shown
  useEffect(() => {
    if (type === 'start') {
      updateEngagementPromptConfig({
        courseStartDisplayCount: cycle,
        courseStartLastDisplayDate: moment().toISOString(),
      })
    } else {
      updateEngagementPromptConfig({
        courseResumeDisplayCount: cycle,
        courseResumeLastDisplayDate: moment().toISOString(),
      })
    }
  }, [cycle, type])

  const getTitle = () => {
    if (type !== 'start') {
      return 'Resume Course'
    }

    if (cycle > 1) {
      return 'Take the Course!'
    }

    return 'Start the "Making Sense of Glucose" Course'
  }

  const title = getTitle()
  const text = type === 'start' ? TOOLTIP_START_TEXTS[cycle - 1] : TOOLTIP_RESUME_TEXTS[cycle - 1]
  const buttonLabel = type === 'start' ? 'Start course' : 'Resume course'

  return (
    <View style={[styles.tooltipContainer, { marginBottom: insets?.bottom }]}>
      <Text type="regular" bold style={styles.tooltipTitle}>
        {title}
      </Text>
      <IconButton
        accessibilityLabel="Close"
        iconName="x"
        size="s"
        style={styles.closeButton}
        iconProps={{ style: styles.closeIcon }}
        type="transparent"
        onPress={onCloseIconPress}
      />
      <Text type="regular" style={styles.tooltipText}>
        {text}
      </Text>
      <View style={styles.tooltipButtons}>
        <Button
          type="secondary"
          size="small"
          accessibilityLabel={buttonLabel}
          onPress={handleActionButtonPress}
          style={[styles.tooltipButton, styles.actionButton]}
        >
          <Text type="small" bold style={styles.actionText}>
            {buttonLabel}
          </Text>
        </Button>
        <Button
          type="transparent"
          size="small"
          accessibilityLabel="Don't Ask Again"
          onPress={hidePermanently}
          style={styles.tooltipButton}
        >
          <Text type="small" bold style={styles.doNotAskText}>
            Don't Ask Again
          </Text>
        </Button>
      </View>
      <View style={styles.tooltipArrow} />
    </View>
  )
}

const themedStyles = StyleService.create({
  tooltipContainer: {
    position: 'absolute',
    bottom: 74,
    right: 12,
    width: 305,
    padding: 16,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base0',
  },
  closeButton: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  closeIcon: {
    color: 'theme.solid.white',
  },
  tooltipTitle: {
    color: 'theme.solid.white',
    marginBottom: 8,
    marginRight: 32,
  },
  tooltipText: {
    color: 'theme.solid.white',
    marginBottom: 16,
  },
  tooltipArrow: {
    position: 'absolute',
    bottom: -12,
    right: 20,
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderLeftWidth: 6,
    borderRightWidth: 6,
    borderTopWidth: 12,
    borderLeftColor: 'theme.transparent',
    borderRightColor: 'theme.transparent',
    borderTopColor: 'theme.surface.base0',
  },
  tooltipButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 12,
  },
  tooltipButton: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  actionButton: {
    backgroundColor: 'theme.solid.white',
  },
  actionText: {
    textTransform: 'uppercase',
    color: 'theme.solid.black',
  },
  doNotAskText: {
    color: 'theme.solid.white',
  },
})
