import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/core'
import { StackNavigationProp } from '@react-navigation/stack'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useGoBack } from '@src/utils/navigation'
import { NavigationBarAccessory } from '@src/components'
import { AppStackParamList } from '@src/navigation/types'
import { ShareableViews } from '@src/containers/ShareViewModal/constants'
import { ActivityDetail as ActivityDetailComponent } from '../components/ActivityDetail'

export const ActivityDetail = () => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const dispatch = useDispatch()

  const route = useRoute<RouteProp<AppStackParamList, 'ActivityDetail'>>()
  const {
    params: { item },
  } = route

  const [favorite, setFavorite] = useState(item.favorite)

  const onToggleFavorite = () => {
    const newFavorite = !favorite
    setFavorite(newFavorite)

    const payload = {
      ...item,
      startTime: item.startedAt,
      endTime: item.endedAt,
      favorite: newFavorite,
    }

    dispatch({
      payload,
      type: 'activities/updateFavorite',
    })
  }

  const onShare = () => {
    navigation.navigate('ShareViewModal', {
      item,
      shareableViewName: ShareableViews.ActivityGlucoseGraph,
    })
  }

  const onEditButtonPress = () => {
    navigation.replace('AddActivity', {
      item: { ...item, favorite },
    })
  }

  const editAction: NavigationBarAccessory = {
    iconDescriptor: { pack: 'eva', name: 'edit-outline' },
    onPress: onEditButtonPress,
    testID: 'Activity/Edit',
    accessibilityLabel: 'Edit Activity',
  }

  return (
    <NavigationContainer title={item.title} rightAccessories={[editAction]} goBack={goBack}>
      <ActivityDetailComponent
        activity={item}
        favorite={favorite}
        onShare={onShare}
        onToggleFavorite={onToggleFavorite}
      />
    </NavigationContainer>
  )
}
