import React from 'react'
import { useTheme } from '@ui-kitten/components'
import { Path, Svg } from 'react-native-svg'

export interface NSProps {
  color?: string
  width?: number
  height?: number
}

const NS = ({ color, width, height }: NSProps) => {
  const theme = useTheme()

  return (
    <Svg width={width} height={height} fill="none" viewBox="0 0 40 40">
      <Path
        fill={color || theme['theme.surface.base']}
        // eslint-disable-next-line max-len
        d="M14.833 12.873c-2.515 0-4.292 1.938-4.839 3.283-.055.108-.137.296-.301.242-.218-.053-.109-.323-.027-.565a5.205 5.205 0 0 0 .301-1.83v-.915H7.342v13.78h2.625v-6.433c0-3.283 2.187-5.301 4.21-5.301 1.832 0 3.226.888 3.226 4.09v7.644h2.625v-8.182c0-4.037-1.969-5.813-5.195-5.813ZM20.366 22.077c.136 3.041 2.296 5.006 6.096 5.006 3.828 0 6.152-1.803 6.152-4.602 0-2.261-1.859-3.337-5.632-3.741-3.035-.323-3.554-.915-3.554-1.803 0-.915.82-1.803 3.034-1.803 2.461 0 3.063 1.426 3.145 2.314h2.57c-.192-2.584-2.106-4.575-5.633-4.575-3.827 0-5.741 2.18-5.741 4.252 0 1.803 1.148 3.445 4.812 3.822 3.199.323 4.374.7 4.374 1.938 0 .888-.738 1.937-3.417 1.937-2.734 0-3.609-1.695-3.636-2.745h-2.57Z"
      />
    </Svg>
  )
}

export default NS
