import React from 'react'
import { useSelector } from 'react-redux'
import { StyleProp, ViewStyle } from 'react-native'
import { TouchableSection } from '@components'
import { cancellationPolicySelector } from '@src/selectors/app'
import { openUrl } from '@src/utils'

interface CancellationPolicySectionProps {
  style?: StyleProp<ViewStyle>
}

const CancellationPolicySection = (props: CancellationPolicySectionProps) => {
  const url = useSelector(cancellationPolicySelector)

  const onPress = () => {
    openUrl(url)
  }

  const { style } = props

  return <TouchableSection style={style} onPress={onPress} title="Cancellation Policy" />
}

export default CancellationPolicySection
