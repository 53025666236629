import { gql } from '@apollo/client'

export const INSURANCE_POLICY_FIELDS = gql`
  fragment InsurancePolicyFields on InsurancePolicy {
    id
    initialAppointmentType {
      key
      title
      duration
    }
    unitsLeft
    unitsUsed
    unitsOccurred
    unitsAuthorized
    lastState {
      kind
    }
  }
`
