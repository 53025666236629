import React from 'react'
import { View } from 'react-native'
import { StyleService } from '@src/style/service'
import { Text } from '@components/base'
import { Slider, SliderProps } from './Slider'

interface SliderWithEdgeLabelsProps extends SliderProps {
  leftEdgeLabel: string
  rightEdgeLabel: string
}

export const SliderWithEdgeLabels = (props: SliderWithEdgeLabelsProps): JSX.Element => {
  return (
    <View>
      <View style={styles.labelsContainer}>
        <Text type="small">{props.leftEdgeLabel}</Text>
        <Text type="small">{props.rightEdgeLabel}</Text>
      </View>
      <Slider wrapperStyle={styles.sliderWrapperStyle} {...props} />
    </View>
  )
}

const styles = StyleService.create({
  labelsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  sliderWrapperStyle: {
    marginTop: 8,
  },
})
