import Storage from '@src/utils/storage'
import { ProgramWithContent } from '@src/screens/Learn/models/courses.types'
import { EngagementPromptConfig } from './lesson.types'

export const getProgramProgress = (program?: ProgramWithContent) => {
  let totalLessonCount = 0
  let totalCompletedLessons = 0

  program?.modules?.forEach((module) => {
    const completedLessons = module.lessons?.filter((lesson) => lesson.progress?.complete)

    totalLessonCount += module.lessons?.length || 0
    totalCompletedLessons += completedLessons?.length || 0
  })

  return totalLessonCount ? totalCompletedLessons / totalLessonCount : null
}

export const getEngagementPromptConfig = () => {
  const value = Storage.get<Partial<EngagementPromptConfig>>(
    Storage.COURSES_ENGAGEMENT_PROMPT_CONFIG_KEY,
  )

  return {
    canShowStartPrompt: value?.canShowStartPrompt ?? true,
    canShowResumePrompt: value?.canShowResumePrompt ?? true,
    courseStartDisplayCount: value?.courseStartDisplayCount ?? 0,
    courseResumeDisplayCount: value?.courseResumeDisplayCount ?? 0,
    courseStartLastDisplayDate: value?.courseStartLastDisplayDate ?? '',
    courseResumeLastDisplayDate: value?.courseResumeLastDisplayDate ?? '',
    lastLessonCompletedDate: value?.lastLessonCompletedDate ?? '',
  }
}

export const updateEngagementPromptConfig = (config: Partial<EngagementPromptConfig>) => {
  const storedConfig = getEngagementPromptConfig()
  const updatedConfig = { ...storedConfig, ...config }

  Storage.set(Storage.COURSES_ENGAGEMENT_PROMPT_CONFIG_KEY, updatedConfig)

  return updatedConfig
}
