// mock for web build
export enum CustomEventTypes {}

class AnalyticsClient {
  configure() {
    return
  }

  track = () => {
    return
  }

  screenView = () => {
    return
  }

  setUser() {
    return
  }

  trackPurchase() {
    return
  }

  reset() {
    return
  }
}

export default new AnalyticsClient()
