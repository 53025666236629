import { Calendar, MealsStoreState } from '@models/app.types'
import commonReducers from '@models/commonReducers'
import defaultReducers from '@models/defaultReducers'
import { initCalendar } from '@models/helper'
import { BaseIngredient } from '@screens/Ingredients/types'
import { MEALS_DEFAULT_SORT_STATE } from '@screens/Meals/models/meals'
import { DraftMeal } from '@screens/Meals/types'
import { Meal, MealKind } from '@src/types'
import { transform } from '../transforms'

const reducers = {
  ...defaultReducers,
  fetchList: commonReducers.fetchList,
  fetchListInfinite: commonReducers.fetchListInfinite,
  updateListItem: commonReducers.updateListItem,
  appendOrReplaceList: commonReducers.appendOrReplaceList,
  restore: commonReducers.restore,
  updateSort: commonReducers.updateSort,

  saveDraft: (state: MealsStoreState, { payload }: { payload: Partial<DraftMeal> }) => ({
    ...state,
    mealDraft: payload || {},
  }),

  updateDraft: (state: MealsStoreState, { payload }: { payload: Partial<DraftMeal> }) => ({
    ...state,
    mealDraft: {
      ...state.mealDraft,
      ...payload,
    },
  }),

  addDraftIngredient: (state: MealsStoreState, { payload }: { payload: BaseIngredient }) => ({
    ...state,
    mealDraft: {
      ...state.mealDraft,
      ingredients: [...(state.mealDraft.ingredients || []), payload],
    },
  }),

  updateDraftIngredient: (state: MealsStoreState, { payload }: { payload: BaseIngredient }) => ({
    ...state,
    mealDraft: {
      ...state.mealDraft,
      ingredients: (state.mealDraft.ingredients || []).map((item) =>
        item.id === payload.id ? payload : item,
      ),
    },
  }),

  removeDraftIngredient: (state: MealsStoreState, { payload }: { payload: BaseIngredient }) => ({
    ...state,
    mealDraft: {
      ...state.mealDraft,
      ingredients: (state.mealDraft.ingredients || []).filter((item) => item.id !== payload.id),
    },
  }),

  updateMealFavoriteStatus: (state: MealsStoreState, { payload = {} as Meal }) => {
    const meal = transform(payload)
    const { id, favorite = false } = meal

    const favorites = state.favorites.filter((item) => item.id !== id)
    const mealIndex = state.recentMeals.findIndex((item) => item.id === id)

    const newRecentMeals = [...state.recentMeals]
    if (mealIndex >= 0) {
      const newRecentMeal = newRecentMeals[mealIndex]
      newRecentMeals[mealIndex] = {
        ...newRecentMeal,
        favorite,
      }
    }

    if (favorite) {
      return {
        ...state,
        favorites: [...favorites, meal],
        recentMeals: newRecentMeals,
      }
    }

    return {
      ...state,
      favorites,
      recentMeals: newRecentMeals,
    }
  },

  updateRecentMeals: (
    state: MealsStoreState,
    { payload = {} as Meal & { recentItem: boolean } },
  ) => {
    const meal = transform(payload)
    const { recentItem, id } = payload
    const recentMeals = state.recentMeals.filter((item) => item.id !== id)
    if (recentItem) {
      return {
        ...state,
        recentMeals: [...recentMeals, meal],
      }
    }
    return {
      ...state,
      recentMeals,
    }
  },

  setQuery: (state: MealsStoreState, { payload }: { payload: string }) => ({
    ...state,
    sort: {
      ...state.sort,
      query: payload,
    },
  }),

  toggleMealKind: (state: MealsStoreState, { payload }: { payload: MealKind }) => {
    const allMealKinds = Object.values(MealKind)
    const nextTypes = state.sort.types.includes(payload)
      ? state.sort.types.filter((t) => t !== payload)
      : [...state.sort.types, payload]
    const shouldSelectAll = nextTypes.length === allMealKinds.length

    return {
      ...state,
      sort: {
        ...state.sort,
        types: shouldSelectAll ? [] : nextTypes,
      },
    }
  },

  selectAllMealKinds: (state: MealsStoreState) => {
    return {
      ...state,
      sort: {
        ...state.sort,
        types: [],
      },
    }
  },

  updateCalendarState: (state: MealsStoreState, { payload }: { payload: Calendar }) => ({
    ...state,
    calendar: {
      ...state.calendar,
      ...payload,
    },
    sort: {
      ...state.sort,
      startDate: payload.startDate,
      endDate: payload.endDate,
    },
  }),

  resetCalendar: (state: MealsStoreState) => ({
    ...state,
    calendar: initCalendar(),
    sort: {
      ...state.sort,
      startDate: '',
      endDate: '',
    },
  }),

  resetFilters: (state: MealsStoreState) => ({
    ...state,
    calendar: initCalendar(),
    sort: MEALS_DEFAULT_SORT_STATE,
  }),
}

export default reducers
