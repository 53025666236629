import React from 'react'
import { Image, ImageStyle } from 'react-native'
import { IconSources } from '@src/assets/icons'
import { ImageIconName } from '@src/assets/icons/types'

interface ImageIconProps {
  name: ImageIconName
  style?: ImageStyle
}

export const ImageIcon = ({ name, style }: ImageIconProps): JSX.Element => {
  return <Image style={style} source={IconSources[name]} />
}
