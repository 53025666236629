import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

interface IngredientInfoProps {
  name: string
  calories: number
  serving?: string
}

export const IngredientInfo = ({ name, calories, serving }: IngredientInfoProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.ingredient}>
      <View style={styles.nameAndServing}>
        <Text type="regular" numberOfLines={1} testID={`Ingredient/${name}`}>
          {name}
        </Text>
        {!!serving && (
          <Text type="small" style={styles.serving}>
            {serving}
          </Text>
        )}
      </View>
      <Text type="regular" style={!name && styles.emptyText}>
        {calories} Cal
      </Text>
    </View>
  )
}

const themedStyle = StyleService.create({
  ingredient: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    borderBottomWidth: 2,
    borderBottomColor: 'theme.surface.base2',
  },
  nameAndServing: {
    flexShrink: 1,
    marginRight: 16,
  },
  serving: {
    color: 'theme.text.secondary',
  },
  emptyText: {
    color: 'theme.text.tertiary',
  },
})
