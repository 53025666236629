import imageBackgroundLight from './source/image-background-light.png'
import imageNutrisenseFavicon from './source/image-favicon.png'
import imageExercise1 from './source/image-exercise-1.jpg'
import imageExercise2 from './source/image-exercise-2.jpg'
import imageExercise3 from './source/image-exercise-3.jpg'
import imageExercise4 from './source/image-exercise-4.jpg'
import imageExercise5 from './source/image-exercise-5.jpg'
import imageMeditate1 from './source/image-meditate-1.jpg'
import imageSleep1 from './source/image-sleep-1.jpg'
import imageFast1 from './source/image-fast-1.jpg'
import imageNote1 from './source/image-note-1.jpg'
import imageNote2 from './source/image-note-2.jpg'
import imageMeal1 from './source/image-meal-1.jpg'
import imageSwimming1 from './source/image-swimming-1.jpg'
import imageCycling1 from './source/image-cycling-1.jpg'
import imageRunning1 from './source/image-running-1.jpg'
import imageBloodGlucose1 from './source/image-blood-glucose-1.jpg'
import imageBloodKetones1 from './source/image-blood-ketones-1.jpeg'
import imageBloodPressure1 from './source/image-blood-pressure-1.jpg'
import imageWeight1 from './source/image-weight-1.jpg'
import imageFlightsClimbed1 from './source/image-flights-climbed-1.jpg'
import imageHiking1 from './source/image-hiking-1.jpg'
import imageTemperature1 from './source/image-temperature-1.jpg'
import imageReadyToScan1 from './source/image-ready-to-scan.png'
import imageOnboarding1 from './source/image-onboarding1.png'
import imageOnboarding2 from './source/image-onboarding2.png'
import imageOnboarding3 from './source/image-onboarding3.png'
import authBackgroundLight from './source/auth-background-light.png'
import authBackgroundDark from './source/auth-background-dark.png'
import glucoseEducation from './source/glucose-education.png'
import installCgm from './source/install-cgm.png'
import boxContent from './source/box-content.png'
import calendar from './source/calendar.png'
import shower from './source/shower.png'
import bandage from './source/bandage.png'
import knowledgeBaseLight from './source/knowledge-base-light.png'
import knowledgeBaseDark from './source/knowledge-base-dark.png'
import hourglass from './source/hourglass.png'
import dangerSign from './source/danger-sign.png'
import coursesModuleDescription from './source/courses-module-description.png'
import membershipCardBg from './source/membership-card-bg.png'
import notificationsPermissionLight from './source/notifications-permission-light.png'
import notificationsPermissionDark from './source/notifications-permission-dark.png'
import healthKitPermissionLight from './source/health-kit-permission-light.png'
import healthKitPermissionDark from './source/health-kit-permission-dark.png'
import googleFitPermissionLight from './source/google-fit-permission-light.png'
import googleFitPermissionDark from './source/google-fit-permission-dark.png'
import cgm from './source/cgm.jpg'
import walkthroughApp from './source/walktrough/app.png'
import walkthroughAppDark from './source/walktrough/app-dark.png'
import walkthroughCommunity from './source/walktrough/community.png'
import walkthroughEducation from './source/walktrough/education.png'
import walkthroughEducationDark from './source/walktrough/education-dark.png'
import walkthroughNutritionists from './source/walktrough/nutritionists.png'
import garminSource from './source/garmin.png'
import ketomojoSource from './source/ketomojo.png'
import googleFitSource from './source/google_fit.png'
import ouraSource from './source/oura.png'
import myFitnessPalSource from './source/myfitnesspal.png'
import fitbitSource from './source/fitbit.png'
import freeStyleLibreSource from './source/freestylelibre.png'
import sensorSelectionDefault from './source/sensor-selection-default.png'
import sensorSelectionResearch from './source/sensor-selection-research.png'
import goalsCircles from './source/goals-circles.png'
import cgmDexcom from './source/cgm-dexcom.png'
import cgmLibre from './source/cgm-libre.png'
import libre1 from './source/libre1.png'
import libre2 from './source/libre2.png'
import libre3 from './source/libre3.png'
import libre3Bluetooth from './source/libre3bluetooth.png'
import nutritionist from './source/nutritionist.jpeg'
import calendarHeart from './source/calendar-heart.png'
import videoCall from './source/video-call.jpeg'

import blurredGlucoseChart from './source/blurred-glucose-chart.jpg'
import blurredGlucoseChartDark from './source/blurred-glucose-chart-dark.jpg'
import blurredArcIndicators from './source/blurred-arc-indicators.jpg'
import blurredArcIndicatorsDark from './source/blurred-arc-indicators-dark.jpg'

export const authLightBg = {
  imageSource: authBackgroundLight,
}

export const authDarkBg = {
  imageSource: authBackgroundDark,
}

export const authBackground = (isDarkMode: boolean) => {
  return isDarkMode ? authDarkBg : authLightBg
}

export const imageLightBg = {
  imageSource: imageBackgroundLight,
}

export const imageFavicon = {
  imageSource: imageNutrisenseFavicon,
}

export const imageExercise1Bg = {
  imageSource: imageExercise1,
}

export const imageExercise2Bg = {
  imageSource: imageExercise2,
}

export const imageExercise3Bg = {
  imageSource: imageExercise3,
}

export const imageExercise4Bg = {
  imageSource: imageExercise4,
}

export const imageExercise5Bg = {
  imageSource: imageExercise5,
}

export const imageMeditate1Bg = {
  imageSource: imageMeditate1,
}

export const imageStress1Bg = {
  imageSource: imageMeditate1,
}

export const imageSleep1Bg = {
  imageSource: imageSleep1,
}

export const imageFast1Bg = {
  imageSource: imageFast1,
}

export const imageNote1Bg = {
  imageSource: imageNote1,
}

export const imageNote2Bg = {
  imageSource: imageNote2,
}

export const imageMeal1Bg = {
  imageSource: imageMeal1,
}

export const imageSwimming1Bg = {
  imageSource: imageSwimming1,
}

export const imageCycling1Bg = {
  imageSource: imageCycling1,
}

export const imageRunning1Bg = {
  imageSource: imageRunning1,
}

export const imageBloodGlucose1Bg = {
  imageSource: imageBloodGlucose1,
}

export const imageBloodKetones1Bg = {
  imageSource: imageBloodKetones1,
}

export const imageBloodPressure1Bg = {
  imageSource: imageBloodPressure1,
}

export const imageWeight1Bg = {
  imageSource: imageWeight1,
}

export const imageFlightsClimbed1Bg = {
  imageSource: imageFlightsClimbed1,
}

export const imageHiking1Bg = {
  imageSource: imageHiking1,
}

export const imageTemperature1Bg = {
  imageSource: imageTemperature1,
}

export const imageReadyToScan = {
  imageSource: imageReadyToScan1,
}

export const imageOnboarding1Bg = {
  imageSource: imageOnboarding1,
}

export const imageOnboarding2Bg = {
  imageSource: imageOnboarding2,
}

export const imageOnboarding3Bg = {
  imageSource: imageOnboarding3,
}

export const glucoseEducationImage = {
  imageSource: glucoseEducation,
}

export const installCgmImage = {
  imageSource: installCgm,
}

export const boxContentImage = {
  imageSource: boxContent,
}

export const calendarImage = {
  imageSource: calendar,
}

export const showerImage = {
  imageSource: shower,
}

export const dangerSignImage = {
  imageSource: dangerSign,
}

export const hourglassImage = {
  imageSource: hourglass,
}

export const bandageImage = {
  imageSource: bandage,
}

export const knowledgeBaseLightImage = {
  imageSource: knowledgeBaseLight,
}

export const knowledgeBaseDarkImage = {
  imageSource: knowledgeBaseDark,
}

export const coursesModuleDescriptionImage = {
  imageSource: coursesModuleDescription,
}

export const membershipCardBgImage = {
  imageSource: membershipCardBg,
}

export const notificationsPermissionLightImage = {
  imageSource: notificationsPermissionLight,
}

export const notificationsPermissionDarkImage = {
  imageSource: notificationsPermissionDark,
}

export const healthKitPermissionLightImage = {
  imageSource: healthKitPermissionLight,
}

export const healthKitPermissionDarkImage = {
  imageSource: healthKitPermissionDark,
}

export const googleFitPermissionLightImage = {
  imageSource: googleFitPermissionLight,
}

export const googleFitPermissionDarkImage = {
  imageSource: googleFitPermissionDark,
}

export const cgmImage = {
  imageSource: cgm,
}

export const walkthroughAppImage = {
  imageSource: walkthroughApp,
}

export const walkthroughAppDarkImage = {
  imageSource: walkthroughAppDark,
}

export const walkthroughCommunityImage = {
  imageSource: walkthroughCommunity,
}

export const walkthroughEducationImage = {
  imageSource: walkthroughEducation,
}

export const walkthroughEducationDarkImage = {
  imageSource: walkthroughEducationDark,
}

export const walkthroughNutritionistsImage = {
  imageSource: walkthroughNutritionists,
}

export const garminImage = {
  imageSource: garminSource,
}

export const ketomojoImage = {
  imageSource: ketomojoSource,
}

export const googleFitImage = {
  imageSource: googleFitSource,
}

export const ouraImage = {
  imageSource: ouraSource,
}

export const myFitnessPalImage = {
  imageSource: myFitnessPalSource,
}

export const fitbitImage = {
  imageSource: fitbitSource,
}

export const freeStyleLibreImage = {
  imageSource: freeStyleLibreSource,
}

export const sensorSelectionDefaultImage = {
  imageSource: sensorSelectionDefault,
}

export const sensorSelectionResearchImage = {
  imageSource: sensorSelectionResearch,
}

export const goalsQuestionnaireGroupImage = {
  imageSource: goalsCircles,
}

export const cgmDexcomImage = {
  imageSource: cgmDexcom,
}

export const cgmLibreImage = {
  imageSource: cgmLibre,
}

export const libre1SensorBoxImage = {
  imageSource: libre1,
}

export const libre2SensorBoxImage = {
  imageSource: libre2,
}

export const libre3SensorBoxImage = {
  imageSource: libre3,
}

export const libre3BluetoothImage = {
  imageSource: libre3Bluetooth,
}

export const nutritionistImage = {
  imageSource: nutritionist,
}

export const calendarHeartImage = {
  imageSource: calendarHeart,
}

export const blurredGlucoseChartImage = (isDarkMode: boolean) => ({
  imageSource: isDarkMode ? blurredGlucoseChartDark : blurredGlucoseChart,
})

export const blurredArcIndicatorsImage = (isDarkMode: boolean) => ({
  imageSource: isDarkMode ? blurredArcIndicatorsDark : blurredArcIndicators,
})

export const videoCallImage = {
  imageSource: videoCall,
}
