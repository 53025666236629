import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Answer } from '@src/types'
import { Input, Text } from '@components/base'
import { TabsTextInputConfig, InputType } from '../../types'
import { INPUT_SANITIZER } from './Shared/utils'
import { InputPropsBase } from './types'

interface TabbedTextInputProps extends InputPropsBase {
  response: TabsTextInputConfig
}

export const TabbedTextInput = ({ field, response }: TabbedTextInputProps): JSX.Element => {
  const styles = useStyleSheet(themedStyles)
  const fieldValue: { unit?: string; value?: string } | undefined = field.value
  const { type = InputType.STRING, placeholder } = response.kindMetadata
  const [unit, setUnit] = useState<string | undefined>(fieldValue?.unit)
  const [value, setValue] = useState<string>(fieldValue?.value || '')

  const onTextInputChange = (value: string) => {
    const processedValue = INPUT_SANITIZER[type](value)
    if (processedValue !== null) {
      setValue(processedValue)

      if (processedValue && unit) {
        field.onChange({
          unit,
          value: processedValue,
        })
      } else {
        field.onChange(undefined)
      }
    }
  }

  const renderMeasurementSystemButton = ({ value: unitValue }: Answer) => {
    const onMeasurementSystemPress = () => {
      setUnit(unitValue)

      if (value) {
        field.onChange({
          value,
          unit: unitValue,
        })
      } else {
        field.onChange(undefined)
      }
    }

    const isSelected = unit === unitValue

    return (
      <TouchableOpacity
        key={unitValue}
        style={[styles.option, isSelected && styles.optionSelected]}
        onPress={onMeasurementSystemPress}
        accessibilityLabel={`${unitValue} measurement system`}
      >
        <Text type="regular" style={isSelected ? styles.optionTextSelected : styles.optionText}>
          {unitValue}
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <>
      <View style={styles.selectorWrapper}>
        {response.answersGroups[0].answers.map(renderMeasurementSystemButton)}
      </View>
      <Input
        placeholder={placeholder}
        value={value}
        onChangeText={onTextInputChange}
        keyboardType={type === InputType.NUMBER ? 'numeric' : 'default'}
        returnKeyType="done"
      />
    </>
  )
}

const themedStyles = StyleService.create({
  selectorWrapper: {
    flexDirection: 'row',
    padding: 8,
    marginBottom: 16,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base2',
  },
  option: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    borderRadius: 8,
  },
  optionSelected: {
    backgroundColor: 'theme.primary.base',
  },
  optionText: {
    color: 'theme.text.primary',
  },
  optionTextSelected: {
    color: 'theme.solid.white',
  },
})
