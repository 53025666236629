import React, { useMemo } from 'react'
import { IndexPath, Select, SelectItem } from '@ui-kitten/components'
import { capitalCase } from 'change-case'
import { MultiSelectProps } from './MultiSelect.types'

export const MultiSelect = ({
  options,
  selectedOptions,
  onSelect,
  ...restProps
}: MultiSelectProps) => {
  const indexPathByOption = useMemo(
    () => Object.fromEntries(options.map((option, index) => [option, new IndexPath(index)])),
    [options],
  )

  const selectedIndexes = selectedOptions.map((selectedOption) => indexPathByOption[selectedOption])

  const handleOnSelect = (indexes: IndexPath | IndexPath[]) => {
    const nextIndexes = Array.isArray(indexes) ? indexes : [indexes]

    onSelect(nextIndexes.map((index: IndexPath) => options[index.row]))
  }

  const value = selectedOptions.map((option: string) => capitalCase(option)).join(', ')

  return (
    <Select
      {...restProps}
      multiSelect
      value={value}
      selectedIndex={selectedIndexes}
      onSelect={handleOnSelect}
    >
      {options.map((option) => (
        <SelectItem key={option} title={capitalCase(option)} />
      ))}
    </Select>
  )
}
