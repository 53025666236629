import { useContext } from 'react'
import { ScanningContext } from '@src/context/scanningContext'

export const useBluetoothStatus = () => {
  // isBluetoothEnabled: bluetooth is on && bluetooth is permitted && sensor is libre 3
  // isBluetoothPermitted: has the user granted Nutrisense permission to use bluetooth
  const { isBluetoothEnabled, isBluetoothPermitted } = useContext(ScanningContext)

  return { isBluetoothEnabled, isBluetoothPermitted }
}
