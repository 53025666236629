import {
  renderColumnChart,
  SingleDayScoreChartConfig,
  Point as ColumnChartPoint,
} from './renderColumnChart'

type Point = Omit<ColumnChartPoint, 'x'> & { x: number }
type DataType = Omit<SingleDayScoreChartConfig, 'data' | 'xScaleValues'> & {
  data: Point[]
  xScaleValues?: string[]
}

const hourRangeMap: Record<number, string> = {
  0: '12-4 AM',
  1: '4-8 AM',
  2: '8 AM-12 PM',
  3: '12-4 PM',
  4: '4-8 PM',
  5: '8 PM-12 AM',
}

export const renderByHourChart = ({ data, yLabel = '', style, xScaleValues }: DataType) => {
  return renderColumnChart({
    data: data.map((item) => ({ ...item, x: hourRangeMap[item.x] })),
    yLabel,
    style,
    xScaleValues,
  })
}
