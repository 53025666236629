import { gql } from '@apollo/client'
import { LATEST_USER_APPOINTMENT_FIELDS } from './fragments/latestUserAppointment'

const CLAIM_FREE_VIDEO_CALL = gql`
  ${LATEST_USER_APPOINTMENT_FIELDS}
  mutation claimFreeVideoCall {
    claimFreeVideoCall {
      ...LatestUserAppointmentFields
    }
  }
`

export default CLAIM_FREE_VIDEO_CALL
