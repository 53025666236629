/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Rect, Path, Defs, LinearGradient, Stop } from 'react-native-svg'

const AppleHealth = () => (
  <Svg viewBox="0 0 60 60" fill="#fff">
    <Rect width="100%" height="100%" rx={13.2783} fill="#fff" />
    <Path
      d="M25.528 24.609c4.303 4.954 6.735 7.396 11.941 9.653.194.084.42.075.608-.021 6.18-3.185 8.757-5.986 12.531-11.024 1.67-2.23 2.129-5.142 1.271-7.792l-.122-.379c-1.908-5.894-9.346-7.741-13.822-3.458a.388.388 0 01-.567-.033c-3.585-4.335-10.451-3.522-12.925 1.53l-.326.665a9.92 9.92 0 001.41 10.859z"
      fill="url(#paint0_linear_5438_2004)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_5438_2004"
        x1={37.2381}
        y1={2.30774}
        x2={37.2381}
        y2={34.394}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#FF5FA9" />
        <Stop offset={1} stopColor="#FF2718" />
      </LinearGradient>
    </Defs>
  </Svg>
)

export default AppleHealth
