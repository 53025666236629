import React, { useEffect, useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { FieldValues, useForm } from 'react-hook-form'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { OnboardingStackParamsList } from '@src/navigation/types'
import { usePurchaseSubscription } from '@src/screens/Marketplace/utils/hooks'
import { authenticatedUserSelector, usStatesSelector } from '@src/selectors/app'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Address, AddressCountries, SurveysConfigKind } from '@src/types'
import { ControllerComponent } from '@src/components/Form/ControllerComponent'
import { AddressAutoComplete, AddressTextInput } from '@src/components/Address'
import { useAddress } from '@src/hooks/useAddress'
import { Place } from '@src/services/GooglePlaces/GooglePlaces'
import { isValidField } from '@src/utils/form'
import { Input } from '@src/components/base/Input'
import { Text } from '@src/components/base/Text'
import { Button } from '@src/components/base/Button'
import { useDispatchAsync } from '@src/utils/dispatch'
import { useGoBack } from '@src/utils/navigation'
import { ScrollableAvoidKeyboard } from '@src/components/ScrollableAvoidKeyboard'
import { openUrl, useSnack } from '@src/utils'
import { Bugsnag } from '@src/config'

interface AddressFormData extends FieldValues {
  country: AddressCountries
  street: string
  street2: string
  city: string
  state: string
  zipCode: string
}

const LEARN_MORE_URL =
  // eslint-disable-next-line max-len
  'https://support.nutrisense.io/hc/en-us/articles/23565528518679-Nutrisense-Annual-Membership-what-is-my-address-used-for'

export const RegionalEligibilityScreen = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  const showSnack = useSnack()
  const dispatchAsync = useDispatchAsync()
  const navigation = useNavigation()
  const goBack = useGoBack()
  const route = useRoute<RouteProp<OnboardingStackParamsList, 'RegionalEligibility'>>()
  const { product } = route.params

  const purchaseSubscription = usePurchaseSubscription()

  const user = useSelector(authenticatedUserSelector)
  const address = (user.address || {}) as Partial<Address>

  const defaultValues: AddressFormData = {
    country: AddressCountries.Us,
    street: address.street || '',
    street2: address.street2 || '',
    city: address.city || '',
    state: address.state || '',
    zipCode: address.zipCode || '',
  }

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { isValid },
    watch,
  } = useForm({ mode: 'onTouched', defaultValues })

  const { placesMatchingSearch, debounceFetchPlaces, fetchAddressForPlace } = useAddress()

  const street = watch('street')
  const country = watch('country')
  const state = watch('state')

  useEffect(() => {
    debounceFetchPlaces.current(street, AddressCountries.Us)
  }, [street, debounceFetchPlaces])

  const loadAddressData = async (place: Place) => {
    const address = await fetchAddressForPlace(place, country)

    clearErrors(['street', 'city', 'state', 'zipCode'])

    setValue('street', address.street, { shouldValidate: true, shouldTouch: true })
    setValue('street2', '')
    setValue('city', address.city, { shouldValidate: true, shouldTouch: true })
    setValue('state', address.state, { shouldValidate: true, shouldTouch: true })
    setValue('zipCode', address.zipCode, {
      shouldValidate: true,
      shouldTouch: true,
    })
  }

  const usStates = useSelector(usStatesSelector)
  const states = usStates.map((item) => ({
    text: item.abbreviation,
    value: item.abbreviation,
  }))
  const statesMatchingSearch = states.filter((item) =>
    item.text.toLowerCase().includes(state.toLowerCase()),
  )
  const isStateValid = (text: string) => {
    return states.some((item) => item.text.toLowerCase() === text.toLowerCase())
  }

  const [isSubmitting, setIsSubmitting] = useState(false)
  const onSubmit = async (data: AddressFormData) => {
    if (isSubmitting) {
      return
    }

    const stateFromServer = states.find((item) => item.text.toLowerCase() === state.toLowerCase())
    setIsSubmitting(true)

    try {
      const formattedAddress = {
        ...data,
        name: address.name || `${user.firstName} ${user.lastName}`,
        state: stateFromServer?.value,
      }
      const updatedAddress = (await dispatchAsync({
        type: 'address/update',
        payload: formattedAddress,
      })) as typeof user.address

      await purchaseSubscription({
        productKey: product.key,
        address: updatedAddress,
      })

      navigation.navigate('Questionnaire', {
        questionnaire: SurveysConfigKind.Insurance,
        showEligibilitySuccessNote: true,
      })
    } catch (error: any) {
      showSnack('An error happened while confirming your address', null, 'error')
      Bugsnag.notify(error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleLearnMorePress = () => {
    openUrl(LEARN_MORE_URL)
  }

  return (
    <NavigationContainer
      title="Regional Eligibility"
      style={styles.navigationContainer}
      goBack={goBack}
    >
      <ScrollableAvoidKeyboard
        style={styles.container}
        contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        bounces={false}
      >
        <Text type="regular" style={styles.title}>
          Please verify your address for Nutrisense services that may differ by region, including
          app access and nutritionist support:
        </Text>
        <Input disabled label="Country" value="United States" style={styles.country} />
        <ControllerComponent
          control={control}
          Component={AddressAutoComplete}
          name="street"
          label="Street"
          placeholder="53 Fox Hill Lane"
          required
          validate={(value) => isValidField(country)(value, 'street')}
          onSelect={(idx: number) => {
            loadAddressData(placesMatchingSearch[idx])
          }}
          options={placesMatchingSearch.map((place) => ({
            value: place.place_id,
            text: place.description,
          }))}
        />
        <ControllerComponent
          control={control}
          Component={AddressTextInput}
          label="Street 2"
          name="street2"
          placeholder="apt. 52"
        />
        <ControllerComponent
          control={control}
          Component={AddressTextInput}
          label="City"
          name="city"
          placeholder="Center Conway"
          required
          validate={(value) => isValidField(country)(value, 'city')}
        />
        <View style={styles.stateZipCode}>
          <View style={styles.stateZipCodeField}>
            <ControllerComponent
              control={control}
              Component={AddressAutoComplete}
              label="State"
              placeholder="NH"
              name="state"
              required
              options={statesMatchingSearch}
              validate={(value) => isStateValid(value)}
            />
          </View>
          <View style={styles.stateZipCodeField}>
            <ControllerComponent
              control={control}
              Component={AddressTextInput}
              label="Zip Code"
              name="zipCode"
              required
              placeholder="00001"
              validate={(value) => isValidField(country)(value, 'zipCode')}
            />
          </View>
        </View>
        <Button
          type="primary"
          size="block"
          disabled={!isValid}
          style={styles.button}
          onPress={handleSubmit(onSubmit)}
          accessibilityLabel="Save"
          allowPressWhenDisabled
        >
          Save
        </Button>
        <Text type="regular" style={styles.learnMoreContainer}>
          <Text type="regular" style={styles.learnMoreText} bold onPress={handleLearnMorePress}>
            Learn more{' '}
          </Text>
          about support app access with CGM usage within the United States
        </Text>
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  navigationContainer: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'space-between',
  },
  title: {
    textAlign: 'center',
    paddingHorizontal: 16,
    marginBottom: 24,
  },
  country: {
    marginBottom: 16,
  },
  stateZipCode: {
    flexDirection: 'row',
    gap: 16,
  },
  stateZipCodeField: {
    flex: 1,
  },
  button: {
    marginTop: 24,
    marginBottom: 16,
  },
  learnMoreContainer: {
    textAlign: 'center',
    marginTop: 8,
  },
  learnMoreText: {
    color: 'theme.text.link',
  },
})
