import React from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { BottomSheet } from '@src/components'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList, RootStackParamList } from '@src/navigation/types'
import { useGoBack } from '@src/utils'
import { BillingProduct } from '@src/types.ts'
import { NutritionistPhotoIcon } from '@screens/NutritionistHub/components/NutritionistPhotoIcon.tsx'
import { useFeeProduct } from '@screens/NutritionistHub/hooks/useFeeProduct.ts'

export const LateRescheduleOrCancelVideoCallModal = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const route = useRoute<RouteProp<AppStackParamList, 'LateRescheduleOrCancelVideoCallModal'>>()
  const { videoCallId, appointmentType, nutritionistName } = route.params
  const lateCancellationFeeProduct = useFeeProduct(BillingProduct.VideoCallLateCancellationFee)

  const goBack = useGoBack()

  const onCancelPress = () => {
    navigation.navigate('FeePaymentScreen', {
      videoCallId,
      feeProduct: lateCancellationFeeProduct,
      appointmentType,
    })
  }

  const effectiveNutritionistName = nutritionistName || 'Nutritionist'

  return (
    <BottomSheet
      primaryButton={{ text: 'Pay the Fee and Cancel', onPress: onCancelPress }}
      secondaryButton={{ text: 'Dismiss', onPress: goBack }}
      buttonDirection="column-reverse"
    >
      <NutritionistPhotoIcon style={styles.nutritionistPhoto} />
      <Text style={styles.title} type="title-2">
        Cannot make it?
      </Text>
      <Text style={styles.content} type="regular">
        {effectiveNutritionistName} has reserved this time for you. A no-show or late cancellation
        within 24 hours incurs a ${lateCancellationFeeProduct?.price || ''} fee. Please honor your
        appointment to avoid the charge.
      </Text>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  title: {
    textAlign: 'center',
    paddingTop: 16,
    paddingBottom: 16,
  },
  content: {
    textAlign: 'center',
  },
  nutritionistPhoto: {
    alignSelf: 'center',
  },
})
