import datadog from '@config/datadog'
import analytics from '@config/analytics'
import Device from './device'

export enum Messages {
  StartScanning = 'CGM scanning has started',
  FinishedScanning = 'CGM scanning has successfully finished',
  StartActivation = 'CGM activation has started',
  FinishedActivation = 'CGM activation has successfully finished',
  Cancel = 'User has canceled CGM scanning',
  SaveScan = 'CGM scan was saved',
  SaveScanAttempt = 'CGM scan is about to be saved',
  SaveActivation = 'CGM activation was saved',
  SaveActivationAttempt = 'CGM activation is about to be saved',
  MealsSubmitAttempt = 'Meal is about to be submitted',
  MealsSubmitSuccess = 'Meal submitted successfully',
  GeneralSearch = 'A member types in the search bar',
  KeywordsSearch = 'A member types in the keywords input',
}

export enum ErrorMessages {
  ActivationNotSaved = 'Activation was not saved!',
  ScanNotSaved = 'Scan was not saved!',
  MealNotSubmitted = 'Meal was not submitted!',
  TerraAuthorizationFailed = 'Authorization flow failed for Terra provider',
  ServerError = 'Server error, please try again',
  ScanFailed = 'Scan failed',
}

export enum LoggerScreens {
  GlobalNFCListener = 'GlobalNFCListener',
  Bluetooth = 'Bluetooth',
  NFCListenerContainer = 'NFCListenerContainer',
  EffectParams = 'EffectParams',
  MealEditor = 'MealEditor',
  PushNotification = 'PushNotification',
  History = 'History',
  TerraProvider = 'TerraProvider',
}

export enum BetterStackCgmLogKind {
  CgmBluetoothLogs = 'cgm_bluetooth_logs',
  CgmNfcLogs = 'cgm_nfc_logs',
}

/**
 * Consolidated interface for event logging in Mixpanel and Datadog.
 */
class Logger {
  sendInfo = (
    screen: LoggerScreens,
    message: Messages | string,
    context?: Record<string, unknown>,
  ) => {
    if (Device.web) {
      return
    }
    analytics.track(`${screen} - ${message}`, context)
    datadog.sendInfo(screen, message, context)
  }

  sendError = (
    screen: LoggerScreens,
    message: ErrorMessages | string,
    context?: Record<string, unknown>,
    trace?: string,
  ) => {
    if (Device.web) {
      return
    }

    analytics.track(`${screen} - ${message}${trace ? `- ${trace}` : ''}`, context)
    datadog.sendError(screen, message, context, trace)
  }
}

export default new Logger()
