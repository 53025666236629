import moment from 'moment'
import { ByDateColumnChartData, OneDayColumnConfig } from './types'

export const datesDataToColumnConfigs = (data: ByDateColumnChartData): OneDayColumnConfig[] =>
  data.map((dateRecord, index) => ({
    index,
    type: 'oneDay',
    date: moment(dateRecord.x, 'YYYY-MM-DD'),
    value: dateRecord.y,
  }))
