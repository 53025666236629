import { Model } from '@models'
import commonReducers from '@src/models/commonReducers'
import { reducers } from '@src/screens/Tutorials/reducers'
import { FETCH_TUTORIAL } from '../graphql/fetchCMSTutorial'
import { FETCH_TUTORIAL_GROUPS } from '../graphql/fetchCMSTutorialGroups'
import { TutorialGroup, TutorialGroupResponse, TutorialResponse } from './tutorials.types'

const transformAttributes = (tutorialGroup: TutorialGroupResponse) => {
  const { id, attributes } = tutorialGroup
  const transformedAttributes: TutorialGroup = {
    id,
    ...attributes,
    tutorials: attributes.tutorials.data.map((tutorial) => ({
      id: tutorial.id,
      ...tutorial.attributes,
    })),
  }

  return transformedAttributes
}

const DEFAULT_STATE = {
  groups: [],
  currentTutorial: {},
}

export default class Tutorials {
  namespace = 'tutorials'

  state = DEFAULT_STATE

  effects = {
    fetchTutorialGroups: Model.buildEffect({
      name: `${this.namespace}/fetchTutorialGroups`,
      query: FETCH_TUTORIAL_GROUPS,
      dataPath: 'tutorialGroups',
      isSuccess: (response: any) => response.data.length > 0,
      caching: false,
      reducers: [
        {
          name: 'fetchList',
          dataPath: 'data',
          storePath: 'groups',
          transform: transformAttributes,
        },
        { name: 'setTutorial', payload: {} },
      ],
    }),
    fetchTutorial: Model.buildEffect({
      name: `${this.namespace}/fetchTutorial`,
      query: FETCH_TUTORIAL,
      dataPath: 'tutorial',
      caching: false,
      reducers: [
        {
          name: 'setTutorial',
          payload: (response: TutorialResponse) => {
            return {
              id: response.data.id,
              ...response.data.attributes,
              tutorialSteps: response.data.attributes.tutorialSteps.data.map((tutorialStep) => ({
                id: tutorialStep.id,
                ...tutorialStep.attributes,
              })),
            }
          },
        },
      ],
    }),
    resetTutorial: Model.buildEffect({
      name: `${this.namespace}/resetTutorial`,
      caching: false,
      reducers: [
        {
          name: 'setTutorial',
          payload: {},
        },
      ],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    ...reducers,
    fetchList: commonReducers.fetchList,
  }
}
