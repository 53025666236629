import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { get, partition, uniqBy } from 'lodash'
import Config from 'react-native-config'
import md5 from 'md5'
import { Bugsnag, App } from '@config'
import {
  NutritionixSearchResults,
  NutritionixWithNutrientsResults,
  NutritionixItemWithNutrients,
} from '@services/Nutritionix/types'
import { isBrandedIngredient } from '@services/Nutritionix/utils'
import { Storage } from '@utils'
import { Login } from '@src/models/app.types'

// Nutritionix API Docs
// https://docs.google.com/document/d/1_q-K-ObMTZvO0qUEAxROrN3bwMujwAN25sLHwJzliK0

const client = Axios.create({
  baseURL: 'https://trackapi.nutritionix.com/v2',
  headers: {
    'x-app-id': Config.NUTRITIONIX_APPLICATION_ID,
    'x-app-key': Config.NUTRITIONIX_APPLICATION_KEYS,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

const getSecureUserId = () => {
  if (!App.build.production) {
    return null
  }

  const login = Storage.get<Login>('login')
  const userId = get(login, 'user.id')

  return userId ? md5(userId) : null
}

const getDefaultOptions = () => {
  const secureUserId = getSecureUserId()

  return { headers: { 'x-remote-user-id': secureUserId || 0 } } as AxiosRequestConfig
}

const request = async (options: AxiosRequestConfig, { showNotFoundWarnings = true } = {}) => {
  const onError = (error: AxiosError) => {
    if (error.response) {
      if (error.response.status === 404 && !showNotFoundWarnings) {
        return
      }

      console.warn('Data:', error.response.data)
      console.warn('Status:', error.response.status)
      console.warn('Headers:', error.response.headers)
    } else {
      console.warn('Error Message:', error.message)
    }
  }

  try {
    Bugsnag.leaveBreadcrumb('nutritionix', { options }, 'request')

    const defaults = getDefaultOptions()
    const opts = { ...defaults, ...options }

    const response = await client.request(opts)
    const { data } = response

    if (data || response.status === 204) {
      return data
    } else {
      return onError({ response } as AxiosError)
    }
  } catch (error: any) {
    if (error.response?.status !== 404) {
      Bugsnag.notify(error, (event) => {
        // eslint-disable-next-line no-param-reassign
        event.severity = 'info'
      })
    }

    return onError(error)
  }
}

const searchItems = async (query: string) => {
  // This endpoint returns 400 if query is invalid (empty string, some special characters like "+")
  // Bug on Nutritionix side: query "no" returns no results
  const response: NutritionixSearchResults | undefined = await request({
    method: 'GET',
    url: `/search/instant?query=${query}&self=false&branded=true&detailed=true`,
  })

  if (!response) {
    return null
  }

  // De-duplicate common products by `tag_id`
  // e.g. "apple", "apples" and "apple raw" are the same ingredient
  const commonProducts = uniqBy(response.common || [], 'tag_id')
  const brandedProducts = response.branded || []

  // Filter out edge case items without full_nutrients
  const itemsWithNutrients = [...commonProducts, ...brandedProducts].filter(
    (item) => item.full_nutrients,
  )
  const [startsWithQuery, containsQuery] = partition(itemsWithNutrients, (ingredient) => {
    if (isBrandedIngredient(ingredient)) {
      return (
        query.toLowerCase().includes(ingredient.brand_name.toLowerCase()) &&
        query.toLowerCase().includes(ingredient.food_name.toLowerCase())
      )
    }

    return ingredient.food_name.toLowerCase().startsWith(query.toLowerCase())
  })

  return [
    ...startsWithQuery.sort((a, b) => a.food_name.localeCompare(b.food_name)),
    ...containsQuery.sort((a, b) => a.food_name.localeCompare(b.food_name)),
  ]
}

const searchItemsWithNutrients = async (query: string) => {
  const response: NutritionixWithNutrientsResults = await request(
    {
      method: 'POST',
      url: `/natural/nutrients`,
      data: {
        query,
      },
    },
    { showNotFoundWarnings: false },
  )

  return get(response, 'foods', []) as NutritionixItemWithNutrients[]
}

const getItemsByBarcode = async ({ barcode }: { barcode: string }) => {
  const response: NutritionixWithNutrientsResults = await request({
    method: 'GET',
    url: `/search/item?upc=${barcode}`,
  })

  return get(response, 'foods', []) as NutritionixItemWithNutrients[]
}

const getBrandedItemById = async (nixItemId: string) => {
  const response: NutritionixWithNutrientsResults = await request({
    method: 'GET',
    url: `/search/item?nix_item_id=${nixItemId}`,
  })

  return get(response, 'foods', [])[0]
}

export default {
  searchItems,
  searchItemsWithNutrients,
  getItemsByBarcode,
  getBrandedItemById,
}
