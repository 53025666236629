import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/core'
import { RouteProp, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { AppStackParamList } from '@src/navigation/types'
import { sensorSelector } from '@src/selectors/sensor'
import { ModuleComponent } from '../components/Module'
import { LessonWithContent } from '../models/courses.types'
import { useModule } from '../hooks'

export const ModuleContainer = () => {
  const styles = useStyleSheet(themedStyle)

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const { params } = useRoute<RouteProp<AppStackParamList, 'CoursesModule'>>()
  const { module, lessons, isLoading } = useModule(params)
  const sensor = useSelector(sensorSelector)
  const sensorIsMissing = !sensor

  useEffect(() => {
    // Show a sensor activation modal if recommended and the user has not yet activated their sensor
    // (do not show for locked modules since the user can't interact w/ the content anyway)
    if (module?.sensorActivationRecommended && !params.locked && sensorIsMissing) {
      navigation.navigate('CoursesSensorActivation', params)
    }
  }, [module?.sensorActivationRecommended, navigation, params, sensorIsMissing])

  const onLessonPress = (lesson: LessonWithContent) => {
    if (params.locked) {
      return
    }

    const screen = lesson.isExperiment ? 'CoursesExperiment' : 'CoursesLesson'
    navigation.navigate(screen, { lesson, parentScreenParams: params })
  }

  return (
    module && (
      <NavigationContainer
        title={module.title}
        goBack={navigation.goBack}
        style={styles.navbar}
        navigationBarProps={{ backgroundColor: 'transparent' }}
      >
        <ModuleComponent
          module={module}
          lessons={lessons}
          isLoading={isLoading}
          locked={params.locked}
          onLessonPress={onLessonPress}
        />
      </NavigationContainer>
    )
  )
}

const themedStyle = StyleService.create({
  navbar: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
})
