import { LoggerScreens, Messages } from './logger'
import logger from './logger'

export enum LoggerComponents {
  ListFilter = 'listFilter',
}

export const LogMap = {
  [LoggerComponents.ListFilter]: {
    history: {
      message: Messages.KeywordsSearch,
      screen: LoggerScreens.History,
    },
  },
}

export const logInfoWithDetails = (component: LoggerComponents, details: string, value: string) => {
  const info = (LogMap[component] as any)?.[details]

  if (!info?.screen || !info?.message) {
    return
  }

  logger.sendInfo(info.screen, info.message, { value })
}
