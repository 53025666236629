import Axios from 'axios'
import Config from 'react-native-config'
import moment from 'moment'
import { AuthConfiguration, refresh, revoke } from 'react-native-app-auth'
import { Bugsnag } from '@config'
import { oauthLogin } from '@src/utils/oauth'

/* eslint-disable camelcase */
interface ReadingFromApi {
  reading_id: string
  reading_timestamp: string
  reading_type: string
  reading_unit: string | null
  reading_value: string | null
}
/* eslint-enable camelcase */

export interface Reading {
  readingId: string
  readingTimestamp: string
  readingType: string
  readingUnit: string | null
  readingValue: number | null
}

const client = Axios.create({
  baseURL: 'https://api.us.mymojohealth.com/',
})

client.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

const config: AuthConfiguration = {
  clientId: Config.KETO_MOJO_CLIENT_ID,
  redirectUrl: 'nutrisense.auth://app/oauth/ketomojo',
  additionalParameters: {
    prompt: 'login',
  },
  scopes: ['readings:retrieve'],
  serviceConfiguration: {
    authorizationEndpoint: 'https://auth.us.mymojohealth.com/oauth/authorize',
    tokenEndpoint: 'https://auth.us.mymojohealth.com/api/v1/oauth/token',
    revocationEndpoint: 'https://auth.us.mymojohealth.com/api/v1/oauth/revoke',
  },
}

const request = async <Result>(options: any): Promise<Result[] | undefined> => {
  try {
    const response = await client(options)
    const { data } = response
    return data
  } catch (error: any) {
    Bugsnag.notify(error)
    return undefined
  }
}

const login = async () => {
  const result = await oauthLogin(config)

  return result
}

const refreshToken = async (token: string) => {
  try {
    const response = await refresh(config, {
      refreshToken: token,
    })
    return response
  } catch (error: any) {
    Bugsnag.notify(error)
    return null
  }
}

const logout = async (token: string) => {
  try {
    return await revoke(config, {
      tokenToRevoke: token,
      sendClientId: true,
    })
  } catch (error: any) {
    Bugsnag.notify(error)
    return null
  }
}

const getReadings = async (startDate: string, endDate: string, token: string) => {
  const response = await request<ReadingFromApi>({
    method: 'get',
    url: 'api/v1/readings',
    params: {
      from_date: startDate,
      to_date: endDate,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  // transform data
  //
  if (response && response.length > 0) {
    return response.reduce((acc: Reading[], obj) => {
      if (moment(obj.reading_timestamp).isSame(moment(startDate))) {
        return acc
      }

      acc.push({
        readingId: obj.reading_id,
        readingTimestamp: obj.reading_timestamp,
        readingValue: Number(obj.reading_value),
        readingUnit: obj.reading_unit,
        readingType: obj.reading_type,
      })

      return acc
    }, [])
  }
  return response as undefined | []
}

export default { login, logout, refreshToken, getReadings }
