import React from 'react'
import { findIndex } from 'lodash'
import { IndexPath, Select as KittenSelect, SelectItem } from '@ui-kitten/components'
import { SelectOption, SelectProps } from './CommonSelect.types'

export const CommonSelect = ({ options, selectedOption, onSelect, ...restProps }: SelectProps) => {
  const initialIndex = findIndex(
    options,
    (o: SelectOption) => o.value === selectedOption?.value || o.text === selectedOption?.text,
  )

  const indexPath = initialIndex >= 0 ? new IndexPath(initialIndex) : undefined

  const generatedOptions = options?.map((option) => (
    <SelectItem key={option.value} title={option.text} />
  ))

  const handleOnSelect = (index: IndexPath | IndexPath[]) => {
    const currentIndex = Array.isArray(index) ? index[0] : index
    onSelect(options?.[currentIndex.row])
  }

  return (
    <KittenSelect
      {...restProps}
      value={selectedOption?.text}
      selectedIndex={indexPath}
      onSelect={handleOnSelect}
    >
      {generatedOptions}
    </KittenSelect>
  )
}
