import moment from 'moment'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { get, set } from 'lodash'
import { Model } from '@src/models'
import { Sort } from '@src/models/app.types'
import { toKeyValuePairs } from '@transforms'
import { Caching } from '@utils'
import { RootStoreState } from '@src/models/app.types'
import { DEFAULT_PAGE_SIZE } from '@src/components/list/DataProviderList'
import commonReducers from '@src/models/commonReducers'
import { Variables } from '@src/utils/caching'
import { transformHistoryOrEventItem } from '@src/transforms/eventAndHistoryItemTransform'
import ALL_HISTORY from '../graphql/allIHistory'
import { HistoryFilter, HistoryStoreState } from './history.types'

const defaultSortState: Sort = {
  orderBy: 'occurred_at',
  order: 'descending',
  types: {},
  query: '',
  startDate: '',
  endDate: '',
  startHour: 0,
  endHour: 24,
}

interface Effects {
  select: () => RootStoreState
}

export default class History {
  namespace = 'history'

  state: HistoryStoreState = {
    ...Model.defaultState,
    backup: null,

    sort: defaultSortState,
    history: [],
  }

  effects = {
    fetch: Model.buildEffect({
      name: `${this.namespace}/fetch`,
      query: ALL_HISTORY,
      dataPath: 'allEvents',
      *variables(payload: HistoryFilter, { select }: Effects) {
        let resultPayload = payload

        if (!resultPayload) {
          const state: RootStoreState = yield select()
          const filter: Sort = { ...state.history.sort, scope: 'history' }

          resultPayload = {
            filter,
            pagination: {
              page: 1,
              pageSize: DEFAULT_PAGE_SIZE,
              type: 'date',
              endTime: moment(state.app.calendar.endDate).endOf('day').toISOString(),
            },
          }
        }

        return set(
          resultPayload,
          'filter.types',
          toKeyValuePairs(get(resultPayload, 'filter.types')),
        )
      },
      caching: true,
      cacheKey: (variables: Variables) => Caching.listFilterCacheKey('history/fetch', variables),
      reducers: [
        {
          name: 'fetchListInfinite',
          dataPath: 'events.events',
          storePath: 'history',
          transform: transformHistoryOrEventItem,
        },
      ],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    updateListItem: commonReducers.updateListItem,
    appendOrReplaceList: commonReducers.appendOrReplaceList,
    deleteList: commonReducers.deleteList,
    backup: commonReducers.backup,
    restore: commonReducers.restore,
    updateSort: commonReducers.updateSort,
    fetchListInfinite: commonReducers.fetchListInfinite,
  }
}
