import {
  Platform,
  StyleSheet,
  TextStyle,
  TouchableNativeFeedback,
  TouchableNativeFeedbackProps,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native'
import React from 'react'
import { Text } from '@components/base'

export const ErrorMessage = ({ message, style }: { message: string; style: TextStyle }) => (
  <View style={styles.errorWrapper}>
    <Text type="regular" style={style}>
      {message}
    </Text>
  </View>
)

export const getMinutesSecondsFromMilliseconds = (ms: number) => {
  const totalSeconds = ms / 1000
  const seconds = String(Math.floor(totalSeconds % 60))
  const minutes = String(Math.floor(totalSeconds / 60))

  return minutes.padStart(1, '0') + ':' + seconds.padStart(2, '0')
}

type ButtonProps = (TouchableNativeFeedbackProps | TouchableOpacityProps) & {
  accessibilityLabel: string
  children: React.ReactNode
}
export const TouchableButton = ({ accessibilityLabel, ...props }: ButtonProps) =>
  Platform.OS === 'android' ? (
    <TouchableNativeFeedback
      accessibilityLabel={accessibilityLabel}
      background={TouchableNativeFeedback.Ripple('white', true)}
      {...props}
    />
  ) : (
    <TouchableOpacity accessibilityLabel={accessibilityLabel} {...props} />
  )

export const styles = StyleSheet.create({
  errorWrapper: {
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 20,
    justifyContent: 'center',
  },
})
