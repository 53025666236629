import { gql } from '@apollo/client'
import { CORE_USER_FIELDS } from './fragments/user'

export const CREATE_THIRD_PARTY_USER = gql`
  ${CORE_USER_FIELDS}
  mutation createThirdPartyUser(
    $provider: ThirdPartySignInProvider!
    $providerToken: String!
    $providerUserId: String
    $firstName: String!
    $lastName: String!
  ) {
    createThirdPartyUser(
      authorization: {
        provider: $provider
        providerToken: $providerToken
        providerUserId: $providerUserId
      }
      firstName: $firstName
      lastName: $lastName
    ) {
      token
      role
      success
      status
      user {
        ...CoreUserFields
      }
      features
    }
  }
`
