import { ChartRangeName, Point, RangeItem } from '@src/screens/Insights/types'
import { nbInternationalFont } from './fonts/nbInternational'

export interface InsightsLineChartConfig {
  data: Point[]
  ranges: RangeItem[]
  yLabel?: string
  yTicks?: number[]
  yMin?: number | null
  yMax?: number | null
  isAnimationDisabled?: boolean
  isScanDisabled?: boolean
  style: {
    line: Record<ChartRangeName | 'default', string>
    axisLine: string
    axisLabel: string
    goodRangeBackground: string
    tooltipBackground: string
    tooltipMarkerFill: string
    tooltipMarkerStroke: string
    tooltipTextColor: string
    tooltipDateTextColor: string
    noDataTextColor: string
  }
}

export const insightsLineChart = ({
  data,
  ranges,
  yLabel = '',
  yTicks,
  yMin,
  yMax,
  isAnimationDisabled,
  isScanDisabled,
  style,
}: InsightsLineChartConfig) => `(() => {
    const data = ${JSON.stringify(data)};
    const ranges = ${JSON.stringify(ranges)};

    const style = document.createElement('style');
    style.textContent = \`
      ${nbInternationalFont}
      .f2-tooltip {
        padding: 6px 8px;
        border-radius: 4px;
        color: ${style.tooltipTextColor};
        background-color: ${style.tooltipBackground};
        font-family: 'NBInternationalProReg';
      }
      .f2-tooltip:after {
        border-top-color: ${style.tooltipBackground};
      }
    \`;
    document.body.append(style)

    chart = new F2.Chart({
      id: 'chart',
      padding: [56, 32, 'auto', 50],
      pixelRatio: window.devicePixelRatio,
    });

    const chartValues = data.filter(({ y }) => y !== null).map(({ y }) => y);

    /* Calculate Y axis min and max values */
    const minValue = Math.min(...chartValues);
    const maxValue = Math.max(...chartValues);

    let yMin = ${yMin};
    let yMax = ${yMax};

    if (yMin === undefined || (typeof yMin === 'number' && minValue < yMin)) {
      const bottomBuffer = Math.max(minValue * 1.1, 10);
      yMin = minValue - bottomBuffer;
    }
    if (yMax === undefined || (typeof yMax === 'number' && maxValue > yMax)) {
      const topBuffer = Math.min(maxValue * 1.1, 10);
      yMax = maxValue + topBuffer;
    }

    chart.source(data, {
      x: {
        mask: 'M/D',
        range: [0, 1],
        tickCount: ${data.length},
        type: 'timeCat',
      },
      y: {
        min: yMin,
        max: yMax,
        ${yTicks ? `ticks: ${JSON.stringify(yTicks)},` : ''}
      },
    });

    chart.axis('x', {
      line: {
        stroke: '${style.axisLine}',
      },
      grid: (text, index, total) => {
        const shouldDisplayGrid =
          total <= 30 ||
          index === 0 ||
          index === total - 1 ||
          new Date(data[index].x).getDay() === 1;

        return shouldDisplayGrid ? {
          lineDash: null,
          stroke: '${style.axisLine}'
        } : null
      },
      label: function label(text, index, total) {
        let shouldDisplayLabel;

        if (total <= 7) {
          shouldDisplayLabel = true;
        } else if (total <= 14) {
          shouldDisplayLabel = index % 2 === 0;
        } else if (total <= 60) {
          shouldDisplayLabel = new Date(data[index].x).getDay() === 1
        } else {
          shouldDisplayLabel = new Date(data[index].x).getDate() === 1
        }

        return {
          fill: shouldDisplayLabel ? '${style.axisLabel}' : 'transparent',
          fontSize: 10,
          fontFamily: 'NBInternationalProReg',
        }
      }
    });
    chart.axis('y', {
      grid: null,
      label: {
        fill: '${style.axisLabel}',
        fontFamily: 'NBInternationalProReg',
        textBaseline: 'bottom',
      },
    });

    chart.legend({
      custom: true,
      items: [{ name: '${yLabel}' }],
      offsetX: 16,
      offsetY: 16,
      nameStyle: {
        fontSize: 10,
        fontFamily: 'NBInternationalProReg',
        fill: '${style.axisLabel}',
        textAlign: 'right',
      }
    });

    /* Good ranges background */
    if (chartValues.length > 0) {
      ranges
        .filter(({ rating }) => rating === 'good')
        .forEach(
          ({ from, to }) => {
            chart.guide().rect({
              start: ['min', from],
              end: ['max', to],
              style: {
                fill: '${style.goodRangeBackground}',
                opacity: 0.1,
              }
            });
          }
        );
    }

    /* Chart ranges line color */
    ${ranges.map(
      ({ from, to, rating }) => `
        chart.guide().regionFilter({
          start: ['min', ${from}],
          end: ['max', ${to}],
          color: '${style.line[rating]}',
        })
      `,
    )}

    chart.line({
      sortable: false,
      connectNulls: ranges.length === 0
    })
    .color('${style.line.default}')
    .position('x*y')
    .shape(ranges.length > 0 ? 'smooth' : 'line')
    .style({
      lineCap: 'butt',
    });

    if (ranges.length === 0) {
      chart.point().position('x*y').style({
        fill: '${style.line.default}',
        stroke: '${style.line.default}',
        lineWidth: 1,
        r: 3,
      });
    }

    /* Isolated data points */
    const isolatedDataPoints = data.filter((point, index, array) => {
      const previousValue = array[index - 1] && array[index - 1].y;
      const nextValue = array[index + 1] && array[index + 1].y;

      const hasNoPreviousData = !previousValue && previousValue !== 0;
      const hasNoNextData = !nextValue && nextValue !== 0;

      return hasNoPreviousData && hasNoNextData;
    });

    const rangeColors = ${JSON.stringify(style.line)};

    isolatedDataPoints.forEach(point => {
      const pointColorConfig = ranges.find(({ from, to }) => point.y >= from && point.y <= to);
      const fill = pointColorConfig ? rangeColors[pointColorConfig.rating] : '${
        style.line.default
      }';

      chart.guide().point({
        position: [point.x, point.y],
        style: {
          fill,
          r: 3,
          lineWidth: 0,
        },
      });
    });

    /* Tooltip */
    const tooltipMarker = chart.guide().point({
      position: [0, -100],
      style: {
        fill: '${style.tooltipMarkerFill}',
        r: 5,
        stroke: '${style.tooltipMarkerStroke}',
        strokeWidth: 2.5,
      },
    });

    chart.tooltip({
      showCrosshairs: false,
      showItemMarker: false,
      custom: true,
      onChange: ({ items }) => {
        const { x, y } = items[0].origin;

        tooltipMarker.position = [x, y];
        tooltipMarker.repaint();
      },
      onShow: ({ items, ...rest }) => {
        const tooltipEl = document.querySelector(".f2-tooltip");
        const { x, y, value, origin } = items[0];

        let formattedUnits = '${yLabel}';

        if (formattedUnits === 'score') {
          formattedUnits = '';
        }

        if (formattedUnits !== '%') {
          formattedUnits = ' ' + formattedUnits;
        }

        const valueEl = '<div style="font-size: 13px;">' + value + formattedUnits + '</div>';
        const dateEl = JSON.parse(${data.length > 7})
          ? '<div style="font-size: 10px; color: ${style.tooltipDateTextColor}">'
            + new Date(origin.x).toLocaleString('default', { day: 'numeric', month: 'long' })
            + '</div>'
          : '';

        tooltipEl.innerHTML = '<div>' + valueEl + dateEl + '</div>';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = (x - tooltipEl.clientWidth / 2) + 'px';
        tooltipEl.style.top = (y - tooltipEl.clientHeight - 12) + 'px';
        tooltipEl.style.whiteSpace = 'nowrap';
      },
    });

    /* No data text */
    if (chartValues.length === 0) {
      chart.guide().text({
        content: 'No Data Available',
        offsetY: ${isScanDisabled ? 0 : -10},
        position: ['50%', '50%'],
        style: {
          fill: '${style.noDataTextColor}',
          fontSize: 16,
          fontWeight: 700,
          fontFamily: 'NBInternationalProReg',
        },
      });

      if (${!isScanDisabled}) {
        chart.guide().text({
          content: 'Scan now to see available data',
          offsetY: 12,
          position: ['50%', '50%'],
          style: {
            fill: '${style.noDataTextColor}',
            fontSize: 13,
            fontWeight: 600,
            fontFamily: 'NBInternationalProReg',
          },
        });
      }
    }

    ${isAnimationDisabled ? 'chart.animate(false);' : ''}

    chart.render();
  })(); true;
`
