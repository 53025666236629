import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'

interface Placeholder {
  events: PlaceholderItem
  history: PlaceholderItem
  measurements: PlaceholderItem
  scans: PlaceholderItem
  ingredients: PlaceholderItem
  journalEntries: PlaceholderItem
  activities: PlaceholderItem
  meals: PlaceholderItem
  measurementSources: PlaceholderItem
}

interface PlaceholderItem {
  title: string
  subtitle: string
  instructions: string
  placeholderItems: any
}

const PlaceholderData: Placeholder = {
  events: {
    title: '',
    subtitle: 'Track your meals and activities using the "+" button',
    instructions: 'No events logged for this time period',
    placeholderItems: [
      {
        __typename: 'Meal',
        avatar: null,
        createdAt: '2020-01-16T21:21:45-08:00',
        description: 'Meal placeholder',
        occurredAt: '2020-01-16T21:21:23-08:00',
        title: 'Dinner',
        type: 'dinner',
      },
      {
        __typename: 'Activity',
        createdAt: '2020-01-14T19:19:36-08:00',
        description: 'test activities description',
        endedAt: '2020-01-14T19:19:02-08:00',
        length: 0,
        occurredAt: '2020-01-14T19:19:02-08:00',
        startedAt: '2020-01-14T19:19:02-08:00',
        status: null,
        title: 'Exercise',
        type: 'Exercise',
      },
    ],
  },
  history: {
    title: 'No history yet',
    subtitle: 'Track your measurements, journal and activities using the "+" button',
    instructions: 'No items logged for this time period',
    placeholderItems: [
      {
        __typename: 'Measurement',
        type: 'BloodGlucose',
        title: 'Blood Glucose',
        avatar: 'weight',
        status: 'normal',
        value: 86,
        units: 'mg/dL',
        percent: 27,
        occurredAt: '2020-01-16T21:26:57-08:00',
        createdAt: '2020-01-16T21:27:20-08:00',
      },
      {
        __typename: 'Activity',
        createdAt: '2020-01-14T19:19:36-08:00',
        description: 'test activities description',
        endedAt: '2020-01-14T19:19:02-08:00',
        length: 0,
        occurredAt: '2020-01-14T19:19:02-08:00',
        startedAt: '2020-01-14T19:19:02-08:00',
        status: null,
        title: 'Exercise',
        type: 'Exercise',
      },
    ],
  },
  measurements: {
    title: 'No measurements today',
    subtitle: 'Add a measurement using "+"',
    instructions: 'Use measurements to track things like body weight and ketone levels',
    placeholderItems: [
      {
        __typename: 'Measurement',
        type: 'BloodGlucose',
        title: 'Blood Glucose',
        avatar: 'weight',
        status: 'normal',
        value: 86,
        units: 'mg/dL',
        percent: 27,
        occurredAt: '2020-01-16T21:26:57-08:00',
        createdAt: '2020-01-16T21:27:20-08:00',
      },
    ],
  },
  scans: {
    title: 'No data today',
    subtitle: 'Scan your sensor to view your data',
    instructions: '',
    placeholderItems: [
      {
        __typename: 'Measurement',
        type: 'BloodGlucose',
        title: 'Blood Glucose',
        avatar: 'weight',
        status: 'normal',
        value: 86,
        units: 'mg/dL',
        percent: 27,
        occurredAt: '2020-01-16T21:26:57-08:00',
        createdAt: '2020-01-16T21:27:20-08:00',
      },
      {
        __typename: 'Measurement',
        type: 'BloodGlucose',
        title: 'Blood Glucose',
        avatar: 'weight',
        status: 'normal',
        value: 101,
        units: 'mg/dL',
        percent: 27,
        occurredAt: '2020-01-16T21:26:57-08:00',
        createdAt: '2020-01-16T21:27:20-08:00',
      },
      {
        __typename: 'Measurement',
        type: 'BloodGlucose',
        title: 'Blood Glucose',
        avatar: 'weight',
        status: 'normal',
        value: 104,
        units: 'mg/dL',
        percent: 27,
        occurredAt: '2020-01-16T21:26:57-08:00',
        createdAt: '2020-01-16T21:27:20-08:00',
      },
    ],
  },
  ingredients: {
    title: 'No ingredients yet',
    subtitle: 'Create an ingredient by tapping "Add" in the top right corner',
    instructions: 'Add your own custom ingredients to the food database',
    placeholderItems: [],
  },
  journalEntries: {
    title: 'No journal entries today',
    subtitle: 'Write a journal by tapping "+" in the top right corner',
    instructions:
      'Add journal to capture subjective feelings (tired, poor energy, good energy), menstrual cycle, or an illness.',
    placeholderItems: [
      {
        __typename: 'JournalEntry',
        title: 'Journal',
        body: '76/65/2.6',
        createdAt: '2020-01-14T01:46:50-08:00',
      },
    ],
  },
  activities: {
    title: 'No activities today',
    subtitle: 'Log an activity by tapping "+" in the top right corner',
    instructions: 'Activities such as exercise help lower blood glucose levels',
    placeholderItems: [
      {
        __typename: 'Activity',
        type: 'Exercise',
        title: 'Exercise',
        startedAt: '2020-01-16T21:24:41-08:00',
        endedAt: '2020-01-16T22:27:44-08:00',
        status: null,
        length: 3783,
        description: 'gym',
        occurredAt: '2020-01-16T21:24:41-08:00',
        createdAt: '2020-01-16T21:25:02-08:00',
      },
    ],
  },
  meals: {
    title: 'No meals today',
    subtitle: 'Add a meal by tapping "+" in the top right corner',
    instructions: 'Tracking your meals helps determine the cause of large glucose spikes',
    placeholderItems: [
      {
        __typename: 'Meal',
        title: 'Breakfast',
        type: 'breakfast',
        avatar: null,
        description: 'venison and coconut creamer',
        occurredAt: '2020-01-16T21:21:23-08:00',
        createdAt: '2020-01-16T21:21:45-08:00',
      },
    ],
  },
  measurementSources: {
    title: '',
    subtitle: 'Loading sources...',
    instructions: '',
    placeholderItems: [],
  },
}

export type PlaceholderTypes =
  | 'events'
  | 'history'
  | 'measurements'
  | 'scans'
  | 'ingredients'
  | 'journalEntries'
  | 'activities'
  | 'meals'
  | 'measurementSources'

interface PlaceholderViewProps {
  type: PlaceholderTypes
  showSearchResults?: boolean
}

interface IconStyle {
  color: string
  size: number
}

export const PlaceholderView = (props: PlaceholderViewProps) => {
  const { type, showSearchResults } = props
  const styles = useStyleSheet(themedStyle)
  const isScanDisabled = useDisableInAppScan()

  const getPlaceholderViewIcon = () => {
    const iconStyle = styles.iconStyle as IconStyle
    switch (type) {
      case 'measurements':
        return <Icon name="ruler" {...iconStyle} />
      case 'scans':
        return <Icon name="scan" {...iconStyle} />
      case 'journalEntries':
        return <Icon name="pen" {...iconStyle} />
      case 'activities':
        return <Icon name="person-simple-walk" {...iconStyle} />
      case 'meals':
        return <Icon name="fork-knife" {...iconStyle} />
      case 'ingredients':
        return <Icon name="fork-knife" {...iconStyle} />
      case 'history':
        return <Icon name="clock" {...iconStyle} />
      case 'measurementSources':
        return <Icon name="ruler" {...iconStyle} />
      default:
        return null
    }
  }

  const renderPlaceholderViewIcon = () => {
    const icon = getPlaceholderViewIcon()
    if (icon) {
      return <View style={styles.iconContainer}>{icon}</View>
    }
    return null
  }

  const renderPlaceholderViewInfo = () => {
    const {
      title: placeholderTitle,
      subtitle: placeholderSubtitle,
      instructions: placeholderInstructions,
    } = PlaceholderData[type]
    const fontSize = type === 'events' ? 13 : 15
    const marginTop = !showSearchResults && type === 'events' ? 0 : 24
    const title = showSearchResults ? 'No results found' : placeholderTitle
    const subtitle = showSearchResults ? '' : placeholderSubtitle
    const instructions = showSearchResults ? '' : placeholderInstructions
    return (
      <View style={[styles.messageContainer, { marginTop }]}>
        {!!title && (
          <Text type="title-1" style={styles.title}>
            {title}
          </Text>
        )}
        {!!subtitle && !(type === 'scans' && isScanDisabled) && (
          <Text type="regular" bold style={[styles.text, { fontSize }]}>
            {subtitle}
          </Text>
        )}
        {!!instructions && (
          <Text type="regular" style={[styles.text, { fontSize }]}>
            {instructions}
          </Text>
        )}
      </View>
    )
  }

  return (
    <View style={styles.container}>
      {renderPlaceholderViewIcon()}
      {renderPlaceholderViewInfo()}
    </View>
  )
}

export const themedStyle = StyleService.create({
  container: {
    flex: 1,
  },
  iconContainer: {
    marginTop: 48,
    alignSelf: 'center',
  },
  iconStyle: {
    width: 64,
    height: 64,
  },
  messageContainer: {
    marginTop: 24,
    alignItems: 'center',
  },
  title: {
    marginHorizontal: 40,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
  text: {
    marginTop: 16,
    marginHorizontal: 40,
    textAlign: 'center',
    color: 'theme.text.secondary',
  },
})
