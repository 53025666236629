/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Rect, Path, G } from 'react-native-svg'

const GoogleFit = () => (
  <Svg viewBox="0 0 60 60" fill="#fff">
    <Rect width="100%" height="100%" rx={13.2783} fill="#fff" />
    <G clipPath="url(#clip0_0_20)">
      <Path
        d="M13.827 31.044l2.015 2.142 4.353-4.608-1.999-2.142-.914-.972c-.728-.774-1.118-1.782-1.118-2.88 0-.954.305-1.818.83-2.502.711-.954 1.795-1.566 3.015-1.566 1.032 0 1.98.432 2.726 1.206l.898.918 2.015 2.16 4.369-4.608-2.032-2.142-.915-.936C25.258 13.188 22.77 12 20.01 12 14.47 12 10 16.752 10 22.602c0 1.458.271 2.844.779 4.122a10.383 10.383 0 002.15 3.366l.898.954z"
        fill="#EA4335"
      />
      <Path
        d="M23.802 33.996l6.215-6.57-4.37-4.626-5.452 5.778-4.353 4.608 2.338 2.502 2.015 2.124 2.1-2.232"
        fill="#FBBC04"
      />
      <Path
        d="M34.352 43.608l9.822-10.422-4.369-4.608L30 38.982l-5.453-5.778-4.352 4.608 5.453 5.796-.017.018L30 48l4.37-4.392h-.018z"
        fill="#34A853"
      />
      <Path
        d="M47.07 30.09c2.032-2.16 3.201-5.472 2.88-8.82-.475-5.076-4.438-8.892-9.247-9.234-3.032-.216-5.809.99-7.773 3.078L25.648 22.8 30 27.426l7.282-7.704c.88-.918 2.1-1.35 3.353-1.134 1.626.27 2.947 1.692 3.167 3.42a4.234 4.234 0 01-1.1 3.474l-2.897 3.096 4.37 4.608 2.895-3.096z"
        fill="#4285F4"
      />
    </G>
  </Svg>
)

export default GoogleFit
