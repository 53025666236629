import React, { useState } from 'react'
import { View } from 'react-native'
import { AutoCompleteInput, useMessageInputContext } from 'stream-chat-react-native'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconButton, Text } from '@components/base'
import { useImpersonationContext } from '@config/impersonation'
import { useIsProcessing } from '../utils/hooks'

const maxNumberOfLines = 6
const maxTextLength = 300

export const CustomMessageInput = () => {
  const styles = useStyleSheet(themedStyle)
  const theme = useTheme()
  const { sendMessage, text } = useMessageInputContext()
  const [isFocused, setIsFocused] = useState(false)
  const { isProcessing } = useIsProcessing()
  const isImpersonating = useImpersonationContext()

  const isTextValid = text.length !== 0 && text.length <= maxTextLength
  const isSendEnabled = !isProcessing && isTextValid && !isImpersonating

  const inputBorderColor = isFocused ? theme['theme.primary.base'] : theme['theme.surface.base']
  const additionalTextInputProps = {
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    placeholder: 'Write here...',
    placeholderColor: theme['theme.text.tertiary'],
    style: {
      flex: 1,
      fontSize: 17,
      lineHeight: 20,
      color: theme['theme.text.primary'],
      paddingHorizontal: 16,
      paddingTop: 12,
      paddingBottom: 12,
      maxHeight: 20 * maxNumberOfLines + 32,
    },
  }

  return (
    <View style={styles.container}>
      <View style={[styles.inputContainer, { borderColor: inputBorderColor }]}>
        <AutoCompleteInput
          numberOfLines={maxNumberOfLines}
          maxMessageLength={maxTextLength}
          additionalTextInputProps={additionalTextInputProps}
        />
        <Text type="small" lineSpacing="none" style={styles.characterCount}>
          {text.length}/{maxTextLength}
        </Text>
      </View>
      <IconButton
        accessibilityLabel="Send message"
        disabled={!isSendEnabled}
        iconName="paper-plane-tilt"
        size="m"
        type="primary"
        onPress={sendMessage}
      />
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inputContainer: {
    flexDirection: 'row',
    flex: 1,
    borderWidth: 2,
    borderRadius: 8,
    minHeight: 48,
    marginRight: 8,
  },
  characterCount: {
    position: 'absolute',
    right: 6,
    bottom: 2,
    color: 'theme.text.tertiary',
  },
})
