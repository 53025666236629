import { gql } from '@apollo/client'

export const DEAUTH_TERRA_PROVIDER = gql`
  mutation deauthTerraProvider($provider: String!) {
    deauthTerraProvider(provider: $provider) {
      providers {
        provider
        active
        connectionId
        name
        status
        errorReason
        availableTypes
        disabledTypes
      }
    }
  }
`
