import React from 'react'
import { useNavigation } from '@react-navigation/core'
import { TouchableSection } from '@components'

const IntegrationsSection = () => {
  const navigation = useNavigation()

  const onPress = () => {
    navigation.navigate('Integrations')
  }

  return <TouchableSection onPress={onPress} iconName="stack" title="Integrations" showRightArrow />
}

export default IntegrationsSection
