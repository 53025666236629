import React from 'react'
import isNumber from 'lodash/isNumber'
import { useSelector } from 'react-redux'
import { View, ViewStyle } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { scoreColor } from '@src/components/nutritionCard/utils'
import { scoreSelector } from '@src/selectors/app'

interface ScoreBarProps {
  number?: number
  containerStyle?: ViewStyle
  barStyle?: ViewStyle
}

export const ScoreBar = ({ number, containerStyle, barStyle }: ScoreBarProps) => {
  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()

  const { danger, warning, max } = useSelector(scoreSelector)

  if (!isNumber(number)) {
    return <View style={[styles.barContainer, containerStyle]} />
  }

  const color = theme[scoreColor(number, danger, warning)]

  return (
    <View style={[styles.barContainer, containerStyle]}>
      <View style={[styles.bar, barStyle, { flex: number / max, backgroundColor: color }]} />
    </View>
  )
}

const themedStyles = StyleService.create({
  barContainer: {
    overflow: 'hidden',
    flexDirection: 'row',
    width: 128,
    height: 8,
    borderRadius: 4,
    backgroundColor: 'theme.surface.base1',
  },
  bar: {
    borderRadius: 4,
    backgroundColor: 'theme.surface.base1',
  },
  last: {
    marginRight: 0,
  },
})
