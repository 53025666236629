import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName, IconButton, Text } from '@components/base'
import { UiInlineAlert } from '@src/types'
import { useIsDarkMode } from '@src/config/theme'

interface InlineAlertProps {
  category?: UiInlineAlert
  message: string
  secondaryMessage?: string
  showIcon?: boolean
  style?: StyleProp<ViewStyle>
  onClose?: () => void
}

const categoryIconMap: Record<UiInlineAlert, IconName> = {
  [UiInlineAlert.Info]: 'info',
  [UiInlineAlert.Warning]: 'warning',
  [UiInlineAlert.Danger]: 'warning-octagon',
  [UiInlineAlert.Success]: 'check',
  [UiInlineAlert.Neutral]: 'info',
}

export const InlineAlert = ({
  category = UiInlineAlert.Warning,
  message,
  secondaryMessage,
  showIcon = true,
  style,
  onClose,
}: InlineAlertProps) => {
  const styles = useStyleSheet(themedStyles)
  const isDarkMode = useIsDarkMode()

  const popupStyle = styles[`${category}Popup`]
  const backgroundStyle = styles[`${category}Background`]
  const alertIconStyle = styles[`${category}AlertIcon`]
  const textStyle = styles[`${category}Text`]

  return (
    <View style={[styles.popup, popupStyle, style]}>
      <View style={[styles.background, backgroundStyle, isDarkMode && styles.withOpacity]} />
      <View style={styles.contentWrapper}>
        {showIcon && (
          <Icon name={categoryIconMap[category]} style={[styles.alertIcon, alertIconStyle]} />
        )}
        <View style={styles.textWrapper}>
          <Text type="regular" lineSpacing="tight" style={textStyle}>
            {message}
          </Text>
          {!!secondaryMessage && (
            <Text type="small" lineSpacing="tight" style={[styles.secondaryText, textStyle]}>
              {secondaryMessage}
            </Text>
          )}
        </View>
      </View>
      {onClose && (
        <IconButton
          accessibilityLabel="Close"
          iconName="x"
          size="xs"
          style={styles.closeIconButton}
          type="transparent"
          onPress={onClose}
        />
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  popup: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderRadius: 8,
    borderWidth: 1,
    overflow: 'hidden',
  },
  background: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  withOpacity: {
    opacity: 0.1,
  },
  warningPopup: {
    borderColor: 'theme.warning.lighter',
  },
  dangerPopup: {
    borderColor: 'theme.error.lighter',
  },
  infoPopup: {
    borderColor: 'theme.info.lighter',
  },
  successPopup: {
    borderColor: 'theme.success.lighter',
  },
  neutralPopup: {
    borderColor: 'theme.surface.base2',
  },
  warningBackground: {
    backgroundColor: 'theme.warning.lightest',
  },
  dangerBackground: {
    backgroundColor: 'theme.error.lightest',
  },
  infoBackground: {
    backgroundColor: 'theme.info.lightest',
  },
  successBackground: {
    backgroundColor: 'theme.success.lightest',
  },
  neutralBackground: {
    backgroundColor: 'theme.surface.base2',
  },
  warningAlertIcon: {
    color: 'theme.warning.darkest',
  },
  dangerAlertIcon: {
    color: 'theme.error.darkest',
  },
  infoAlertIcon: {
    color: 'theme.info.darkest',
  },
  successAlertIcon: {
    color: 'theme.success.darkest',
  },
  neutralAlertIcon: {
    color: 'theme.text.secondary',
  },
  warningText: {
    color: 'theme.text.primary',
  },
  dangerText: {
    color: 'theme.text.primary',
  },
  infoText: {
    color: 'theme.text.primary',
  },
  successText: {
    color: 'theme.text.primary',
  },
  neutralText: {
    color: 'theme.text.secondary',
  },
  contentWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  alertIcon: {
    marginRight: 16,
  },
  textWrapper: {
    flex: 1,
  },
  secondaryText: {
    marginTop: 8,
  },
  closeIconButton: {
    marginLeft: 8,
  },
})
