import { gql } from '@apollo/client'

export const UPDATE_EFFECTIVE_DAILY_MEASUREMENT = gql`
  mutation updateEffectiveDailyMeasurement(
    $date: String!
    $type: HealthDataMeasurementType!
    $selectedSource: HealthDataMeasurementSource!
  ) {
    updateEffectiveDailyMeasurement(date: $date, type: $type, selectedSource: $selectedSource) {
      date
      type
      selectedSource
      defaultSource
      availableMeasurements {
        id
        type
        title
        value
        values {
          ... on BloodPressureMeasurement {
            systolic
            diastolic
          }
          ... on SingleValueMeasurement {
            value
          }
        }
        units
        description
        occurredAt
        createdAt
        scan {
          id
          createdAt
        }
        externalSource
      }
    }
  }
`
