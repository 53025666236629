import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName, Text } from '@components/base'

interface SectionHeaderProps {
  title: string
  icon?: IconName
}

export const SectionHeader = ({ title, icon }: SectionHeaderProps) => {
  const styles = useStyleSheet(themedStyle)
  const viewStyle = icon ? styles.containerWithIcon : styles.container

  return (
    <View style={viewStyle}>
      <Text type="small" bold style={styles.title}>
        {title}
      </Text>
      {!!icon && <Icon name={icon} style={styles.icon} />}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: 32,
    paddingHorizontal: 16,
  },
  containerWithIcon: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: 32,
    paddingLeft: 16,
    flexDirection: 'row',
  },
  icon: {
    color: 'theme.info.base',
    height: 16,
    width: 16,
  },
  title: {
    color: 'theme.text.secondary',
  },
})
