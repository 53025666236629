import React from 'react'
import { TouchableOpacity } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@src/components/base'
import { WithWalkthrough } from '@src/components/WithWalkthrough'

const ACCESSIBILITY_LABEL = 'Goal info'
const DISPLAY_INSETS = { top: 0, bottom: 0, left: 0, right: 12 }
const HIT_SLOP = { top: 12, bottom: 12, left: 12, right: 12 }

export const GoalInfo = () => {
  const styles = useStyleSheet(themedStyles)

  const [goalInfoVisible, setGoalInfoVisible] = React.useState(false)

  const showTooltip = () => {
    setGoalInfoVisible(true)
  }

  const hideTooltip = () => {
    setGoalInfoVisible(false)
  }

  return (
    <WithWalkthrough
      isVisible={goalInfoVisible}
      placement="bottom"
      onClose={hideTooltip}
      contentStyle={styles.goalInfoTooltip}
      disableShadow
      backgroundColor="transparent"
      tooltipStyle={styles.tooltipStyle}
      content={
        <Text type="regular" style={styles.tooltipText}>
          Your nutritionist can help set primary metrics based on your health goals and glucose data
        </Text>
      }
      accessibilityLabel={ACCESSIBILITY_LABEL}
      displayInsets={DISPLAY_INSETS}
    >
      <TouchableOpacity
        hitSlop={HIT_SLOP}
        onPress={showTooltip}
        accessibilityLabel={ACCESSIBILITY_LABEL}
      >
        <Icon name="info" style={styles.icon} />
      </TouchableOpacity>
    </WithWalkthrough>
  )
}

const themedStyles = StyleService.create({
  goalInfoTooltip: {
    backgroundColor: 'theme.tooltip.surface',
    padding: 12,
  },
  tooltipText: {
    color: 'theme.tooltip.textPrimary',
    textAlign: 'center',
  },
  icon: {
    color: 'theme.text.secondary',
  },
  tooltipStyle: {
    maxWidth: 282,
    width: '100%',
  },
})
