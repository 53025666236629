import React, { Fragment, useContext, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-native'
import { NavigatorContext, useDispatchAsync, useGoBack } from '@src/utils'
import { GestureDismissibleActionModal } from '@src/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { goalOptionsSelector, primaryGoalSelector } from '@src/selectors/app'
import { Option, OptionType } from '@src/screens/Questionnaire/components/inputs/Shared/Option'
import { UserGoalKind } from '@src/types'
import { Input, Text } from '@src/components/base'

const DESCRIPTION_CHARACTERS_LIMIT = 30

export const ChooseGoalModal = () => {
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()
  const dispatch = useDispatchAsync()
  const goalOptions = useSelector(goalOptionsSelector)
  const primaryGoal = useSelector(primaryGoalSelector)

  const [selectedGoal, setSelectedGoal] = useState(primaryGoal?.kind)
  const [description, setDescription] = useState(primaryGoal?.description || '')

  const { enableLoader, disableLoader } = useContext(NavigatorContext)
  const loadingRef = useRef(false)

  const onSaveButtonPress = async () => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true
    enableLoader()

    try {
      await dispatch({
        type: 'goals/upsertPrimaryGoal',
        payload: { kind: selectedGoal, description: description || undefined },
      })
      await dispatch({
        type: 'users/fetch',
      })
      goBack()
    } catch (e) {
      loadingRef.current = false
    } finally {
      disableLoader()
    }
  }

  return (
    <GestureDismissibleActionModal
      title="Choose your current goal"
      doneTitle="Cancel"
      actionTitle="Save"
      onActionPress={onSaveButtonPress}
      actionDisabled={!selectedGoal}
      avoidKeyboard
    >
      <View style={styles.container}>
        {goalOptions.map((goalOption) => {
          const selected = goalOption.value === selectedGoal
          return (
            <Fragment key={goalOption.value}>
              <Option
                label={goalOption.text}
                selected={selected}
                onPress={() => {
                  setSelectedGoal(goalOption.value)
                  if (goalOption.value !== UserGoalKind.Other) {
                    setDescription('')
                  }
                }}
                type={OptionType.Radio}
              />
              {goalOption.value === UserGoalKind.Other && selected && (
                <>
                  <Input
                    label="Describe your goal"
                    onChangeText={(newDescription) => {
                      if (newDescription.length > DESCRIPTION_CHARACTERS_LIMIT) {
                        return
                      }
                      setDescription(newDescription)
                    }}
                    value={description}
                    blurOnSubmit
                    returnKeyType="done"
                    numberOfLines={3}
                  />
                  <Text type="small" style={styles.charactersLimit}>
                    Max {DESCRIPTION_CHARACTERS_LIMIT} characters
                  </Text>
                </>
              )}
            </Fragment>
          )
        })}
      </View>
    </GestureDismissibleActionModal>
  )
}

const themedStyles = StyleService.create({
  container: {
    marginTop: 16,
  },
  charactersLimit: {
    textAlign: 'right',
    color: 'theme.text.secondary',
    marginTop: 8,
  },
})
