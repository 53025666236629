import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { Carousel } from '@components'
import {
  walkthroughAppDarkImage,
  walkthroughAppImage,
  walkthroughCommunityImage,
  walkthroughEducationDarkImage,
  walkthroughEducationImage,
  walkthroughNutritionistsImage,
} from '@src/assets/images'
import { Storage } from '@src/utils'
import { Analytics, CustomEventTypes } from '@src/config'
import { useIsDarkMode } from '@src/config/theme'
import { AppStackParamList } from '@src/navigation/types'

const getData = (isDarkMode: boolean) => {
  return [
    {
      title: '1:1 Nutritionist Support',
      description: `Learning good habits is hard. Your personal nutrition coach will help you make lasting changes, and enjoy the process.`,
      image: walkthroughNutritionistsImage.imageSource,
    },
    {
      title: 'World Class App',
      description: `Your next level upgrade is here. Make sense of your numbers and chart a course to a healthier you.`,
      image: (isDarkMode ? walkthroughAppDarkImage : walkthroughAppImage).imageSource,
      fullScreenImage: true,
    },
    {
      title: 'A community of peers for support',
      description: `You're not alone. Connect with other active members to see first-hand how Nutrisense is changing lives.`,
      image: walkthroughCommunityImage.imageSource,
      fullScreenImage: true,
    },
    {
      title: 'Tools for continued education',
      description: `Taking care of your health means becoming a lifelong learner. Our team produces helpful articles, tips, & tricks weekly!`,
      image: (isDarkMode ? walkthroughEducationDarkImage : walkthroughEducationImage).imageSource,
      fullScreenImage: true,
    },
  ]
}

const keyExtractor = (item: ReturnType<typeof getData>[0]) => item.title

export const WalkthroughCarousel = () => {
  const styles = useStyleSheet(themedStyle)
  const isDarkMode = useIsDarkMode()
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const onSlideChange = (newSlideIndex: number) => {
    setCurrentSlideIndex(newSlideIndex)
  }

  const data = getData(isDarkMode)

  const isLastSlide = currentSlideIndex === data.length - 1

  const onButtonPress = () => {
    Storage.set(Storage.WALKTHROUGH_SHOWN_KEY, true)
    Analytics.track(CustomEventTypes.WalkthroughScreenClosed)
    navigation.replace('Auth')
  }

  return (
    <Carousel data={data} keyExtractor={keyExtractor} onSlideChange={onSlideChange}>
      {isLastSlide ? (
        <Button
          type="primary"
          size="block"
          onPress={onButtonPress}
          accessibilityLabel="Get Started"
          style={styles.button}
        >
          Get Started
        </Button>
      ) : (
        <Button
          type="transparent"
          size="block"
          onPress={onButtonPress}
          accessibilityLabel="Skip"
          style={styles.button}
        >
          Skip
        </Button>
      )}
    </Carousel>
  )
}

const themedStyle = StyleService.create({
  button: {
    marginBottom: 16,
    marginHorizontal: 24,
  },
})
