import React from 'react'
import { View, StyleProp, ViewStyle, useWindowDimensions } from 'react-native'
import { default as Slider } from '@ptomasroos/react-native-multi-slider'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { MultiSlider } from '@components'
import { Option } from '@src/screens/Settings/models/settings.types'

interface ThresholdSliderProps {
  selectedOptions: [Option?, Option?]
  label?: string
  minLabel?: string
  maxLabel?: string
  limits?: [number, number]
  options: Option[]
  onChange: (firstValue: Option, secondValue?: Option) => void
  showSelection?: boolean
  style?: StyleProp<ViewStyle>
}

const TOUCH_DIMENSIONS = {
  height: 40,
  width: 40,
  borderRadius: 20,
  slipDisplacement: 40,
}

export const OptionsSlider = (props: ThresholdSliderProps) => {
  const styles = useStyleSheet(themedStyle)
  const dimensions = useWindowDimensions()

  const {
    selectedOptions,
    label,
    minLabel,
    maxLabel,
    limits,
    options,
    onChange,
    showSelection = true,
    style,
  } = props

  const firstOption = options[0]
  const lastOption = options[options.length - 1]
  const step = Math.abs(options[1].value - firstOption.value)

  let offset = 0
  if (firstOption.value < 0) {
    //offset needed to be able to use slider with negative values options
    offset = Math.abs(firstOption.value)
  }

  const [firstSelected, secondSelected] = selectedOptions
  const values = [(firstSelected?.value ?? firstOption.value) + offset]
  if (secondSelected) {
    values.push((secondSelected?.value ?? lastOption.value) + offset)
  }

  const onValueChange = ([first, second]: number[]) => {
    const firstValue = +(first - offset).toPrecision(2)
    const secondValue = +(second - offset).toPrecision(2)

    const firstOption = options.find((x) => x.value === firstValue)
    const secondOption = options.find((x) => x.value === secondValue)

    if (firstOption) {
      onChange(firstOption, secondOption)
    }
  }

  const SliderComponent = values.length === 1 ? Slider : MultiSlider
  const multiSliderProps =
    values.length > 1
      ? {
          containerStyle: styles.sliderInnerContainer,
          lowLimit: limits?.[0],
          highLimit: limits?.[1],
          markerOffsetY: -16,
          sliderHeight: 4,
          enabledOne: true,
          enabledTwo: true,
          enabledBetween: false,
        }
      : undefined

  return (
    <View style={style}>
      <View style={styles.header}>
        <Text type="small" style={styles.label}>
          {minLabel ?? firstSelected?.text}
        </Text>
        {label && (
          <Text type="small" style={styles.label}>
            {label}
          </Text>
        )}
        <Text type="small" style={styles.label}>
          {maxLabel ?? secondSelected?.text}
        </Text>
      </View>
      <SliderComponent
        min={firstOption.value + offset}
        max={lastOption.value + offset}
        step={step}
        values={values}
        markerStyle={styles.marker}
        pressedMarkerStyle={styles.pressedMarker}
        selectedStyle={showSelection ? styles.selectedStyle : styles.unselectedStyle}
        unselectedStyle={styles.unselectedStyle}
        touchDimensions={TOUCH_DIMENSIONS}
        sliderLength={dimensions.width - 48}
        onValuesChange={onValueChange}
        {...multiSliderProps}
      />
    </View>
  )
}

const themedStyle = StyleService.create({
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    top: 8,
    textAlign: 'center',
  },
  sliderInnerContainer: {
    paddingVertical: 24,
    paddingHorizontal: 8,
  },
  unselectedStyle: {
    borderRadius: 2,
    backgroundColor: 'theme.surface.base',
  },
  selectedStyle: {
    borderRadius: 2,
    backgroundColor: 'theme.primary.base',
  },
  marker: {
    width: 24,
    height: 24,
    backgroundColor: 'theme.primary.base',
    borderWidth: 0,
    marginTop: 6,
  },
  pressedMarker: {
    height: 24,
    width: 24,
  },
})
