import React from 'react'
import { useDispatch } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StackNavigationProp } from '@react-navigation/stack'
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList } from '@src/navigation/types'
import { NavigationBar, ScrollViewWithFade } from '@components'
import { Measurement } from '@src/screens/Events/models/events.types'
import { useCheckModuleCompletion, useResetNavigationToModule } from '@src/screens/Learn/hooks'
import { Activity, HealthDataMeasurementType } from '@src/types'
import { LessonItemContentExperimentAction } from '../models/courses.types'
import { RichTextView } from '../components/items/RichTextView'
import { NavigationButtons } from '../components/items/NavigationButtons'

export const LessonExperimentContainer = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const route = useRoute<RouteProp<AppStackParamList, 'CoursesExperiment'>>()
  const { lesson, parentScreenParams } = route.params

  const experiment = lesson.items[0]

  const resetNavigationToModule = useResetNavigationToModule(parentScreenParams)
  const { goToModuleCompletion } = useCheckModuleCompletion(lesson.id, parentScreenParams)

  const completeLesson = () => {
    dispatch({
      type: 'courses/recordLessonProgress',
      payload: { lessonId: lesson.id, complete: true },
    })
    resetNavigationToModule()
    goToModuleCompletion()
  }

  const handleActionPress = () => {
    completeLesson()

    switch (experiment.action) {
      case LessonItemContentExperimentAction.LogMeal:
        return navigation.navigate('AddMeal', { shouldGoBack: true })
      case LessonItemContentExperimentAction.LogActivity:
        return navigation.navigate('AddActivity')
      case LessonItemContentExperimentAction.LogStress:
        return navigation.navigate('AddMeasurement', {
          item: { type: HealthDataMeasurementType.Stress } as Measurement,
        })
      case LessonItemContentExperimentAction.LogExercise:
        return navigation.navigate('AddActivity', {
          item: { type: 'Exercise' } as Activity,
        })
      case LessonItemContentExperimentAction.LogNote:
        return navigation.navigate('AddJournalEntry', { body: '' })
      case LessonItemContentExperimentAction.AdjustGlucoseThreshold:
        return navigation.navigate('UserSettings')
      case LessonItemContentExperimentAction.AdjustMacros:
        return navigation.navigate('UserSettings')
      case LessonItemContentExperimentAction.Calibrate:
        return navigation.navigate('CalibrateSensor')
    }
  }

  return (
    <SafeAreaView style={styles.container} edges={['bottom']}>
      <NavigationBar
        title={lesson.title}
        backgroundColor={styles.container.backgroundColor}
        leftAccessory={{
          iconDescriptor: { pack: 'eva', name: 'close' },
          onPress: resetNavigationToModule,
        }}
      />
      <ScrollViewWithFade style={styles.content}>
        <RichTextView body={experiment.body} />
      </ScrollViewWithFade>
      <NavigationButtons
        canGoBack
        backTitle={experiment.skipTitle}
        nextTitle={experiment.actionTitle}
        onBack={completeLesson}
        onNext={handleActionPress}
      />
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
    paddingHorizontal: 16,
    paddingTop: 16,
  },
})
