import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pick } from 'lodash'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Toggle } from '@components/base'
import { Section } from '@components'
import {
  ketoMojoLastSyncedSelector,
  ketoMojoSyncStatusSelector,
  ketoMojoUserSelector,
} from '@src/selectors/integrations'
import { KetoMojo } from '@src/services'
import { useSnack } from '@src/utils/navigatorContext'
import { AuthResultStatus } from '@src/utils/oauth'
import { NavigationContainer } from '@src/screens/Common/containers'
import LastSyncedSection from './LastSyncedSection'

export const KetoMojoIntegration = () => {
  const styles = useStyleSheet(themedStyle)
  const ketoMojoUser = useSelector(ketoMojoUserSelector)
  const ketoMojoSync = useSelector(ketoMojoSyncStatusSelector)
  const lastSyncedKetoMojo = useSelector(ketoMojoLastSyncedSelector)
  const dispatch = useDispatch()
  const showSnack = useSnack()

  const [toggleActive, setToggleActive] = useState(ketoMojoSync)

  const onAuth = async () => {
    const { user, status } = await KetoMojo.login()

    if (user?.accessToken) {
      showSnack(`KetoMojo Login ${status}!`)

      dispatch({
        type: 'settings/updateIntegrationsSyncSettings',
        payload: {
          ketoMojoUser: pick(user, ['refreshToken', 'accessToken', 'accessTokenExpirationDate']),
        },
      })

      dispatch({
        type: 'settings/update',
        payload: {
          ketoMojoSync: true,
        },
      })

      // automatically pull 12 months data
      dispatch({
        type: 'app/updateKetoMojoData',
        payload: { initial: true },
      })
    } else {
      dispatch({
        type: 'settings/updateIntegrationsSyncSettings',
        payload: { ketoMojoUser: null },
      })
      setToggleActive(false)
      showSnack(
        `KetoMojo Login ${status}!`,
        null,
        status === AuthResultStatus.Failed ? 'error' : 'warning',
      )
    }
  }

  const onLogout = async (token: string) => {
    const result = await KetoMojo.logout(token)
    if (result) {
      dispatch({
        type: 'settings/updateIntegrationsSyncSettings',
        payload: { ketoMojoUser: null },
      })
      showSnack('KetoMojo Logout Successful!')
    }
  }

  const onSyncToggle = () => {
    if (ketoMojoSync && ketoMojoUser?.accessToken) {
      setToggleActive(false)
      onLogout(ketoMojoUser?.accessToken)
    } else {
      setToggleActive(true)
      onAuth()
    }
  }

  const onSyncPress = () => {
    if (ketoMojoSync && ketoMojoUser?.accessToken) {
      dispatch({
        type: 'app/updateKetoMojoData',
        payload: {
          initial: false,
        },
      })
    }
  }

  return (
    <NavigationContainer title="Keto-Mojo" showLoadingIndicator>
      <View style={styles.container}>
        <Section title="Keto-Mojo Sync">
          <Toggle checked={toggleActive} onChange={onSyncToggle} />
        </Section>

        {ketoMojoSync && onSyncPress && (
          <Section title="Sync">
            <Button type="primary" size="small" accessibilityLabel="Sync Now" onPress={onSyncPress}>
              Sync Now
            </Button>
          </Section>
        )}
        {ketoMojoSync && <LastSyncedSection lastSynced={lastSyncedKetoMojo} />}
      </View>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    flexGrow: 1,
    backgroundColor: 'theme.background',
  },
})
