import { isArray } from 'lodash'
import { fromKeyValuePairs } from '@transforms'
import { Meal } from '@src/types'

export const transform = (item: Meal) => {
  return {
    ...item,
    nutrition: isArray(item.nutrition) ? fromKeyValuePairs(item.nutrition) : item.nutrition,
  }
}
