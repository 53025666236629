import React, { useCallback, useContext, useMemo } from 'react'
import { ReactNativeFile } from '@src/utils/image'
import {
  PhotoUploading,
  PhotoUploadingProps,
  QuestionnairePhoto,
} from '@src/components/PhotoUploading'
import { NavigatorContext, useSnack } from '@src/utils/navigatorContext'
import { UserFileTag } from '@src/types'
import { useDispatchAsync } from '@src/utils'
import { useDirectUpload } from '@src/hooks/useDirectUpload'
import { useUploadedFile } from '../../hooks'
import { FileUploadEndpoints, FileUploadInputConfig } from '../../types'
import { InputPropsBase } from './types'
import { useSetDefaultValue } from './Shared/hooks'

interface PhotoUploadingInputProps extends InputPropsBase {
  response: FileUploadInputConfig
}

export const PhotoUploadingInput = ({ field, response }: PhotoUploadingInputProps): JSX.Element => {
  const endpoints = response.kindMetadata.endpoints
  const questionKey = field.name
  const dispatchAsync = useDispatchAsync()
  const showSnack = useSnack()
  const uploadFile = useDirectUpload()

  const { onChange: fieldOnChange, value: fieldValue } = field

  useSetDefaultValue({ fieldValue, onChange: fieldOnChange, defaultValue: null })

  const upload = useUploadedFile(questionKey as UserFileTag, endpoints)

  const { enableLoader, disableLoader } = useContext(NavigatorContext)

  const handleUserFileUpload = useCallback(
    async (photo: ReactNativeFile): Promise<QuestionnairePhoto | undefined> => {
      enableLoader()

      let result: QuestionnairePhoto | undefined = undefined

      try {
        const file = await uploadFile({
          dataURL: photo.uri.substring(8),
          fileTag: questionKey as UserFileTag,
          filename: photo.name,
          contentType: photo.type,
        })

        if (!file) {
          throw new Error('Failed to upload photo')
        }

        result = {
          type: 'file_upload',
          name: file.fileName,
          uri: file.downloadFileUrl,
          id: file.id,
        }
      } finally {
        disableLoader()
      }

      return result
    },
    [disableLoader, enableLoader, questionKey, uploadFile],
  )

  const handleUserFileDelete = useCallback(() => {
    return dispatchAsync({
      type: 'app/deleteFile',
      payload: {
        id: (upload.file as QuestionnairePhoto).id,
      },
    })
  }, [dispatchAsync, upload])

  const endpointActions = useMemo(
    () => ({
      [FileUploadEndpoints.USER_FILES]: {
        upload: handleUserFileUpload,
        delete: handleUserFileDelete,
      },
    }),
    [handleUserFileUpload, handleUserFileDelete],
  )

  const onChange = useCallback<PhotoUploadingProps['onPhotoChange']>(
    async (photo) => {
      if ('loading' in upload) {
        return
      }

      if (!photo) {
        await endpointActions[endpoints].delete()
        fieldOnChange(null)
        upload.clear()
        return
      }

      upload.set(photo)

      const questionnairePhoto = await endpointActions[endpoints].upload(photo)

      if (typeof questionnairePhoto === 'undefined') {
        console.error("Expected questionnairePhoto to be defined, but it's not.")
        showSnack('Failed to upload photo', 'Try again in a few seconds')
        upload.clear()
        return
      }

      upload.set(questionnairePhoto)
      fieldOnChange(true)
    },
    [fieldOnChange, endpoints, upload, endpointActions, showSnack],
  )

  return <PhotoUploading onPhotoChange={onChange} photo={upload.file} />
}
