import { gql } from '@apollo/client'

const CREATE_INGREDIENT = gql`
  mutation createCustomIngredient(
    $ingredientId: ID
    $description: String!
    $servingAmount: Float!
    $servingUnits: String!
    $calories: Float!
    $nutrition: [KeyValueFloat!]!
  ) {
    createCustomIngredient(
      ingredientId: $ingredientId
      description: $description
      servingAmount: $servingAmount
      servingUnits: $servingUnits
      calories: $calories
      nutrition: $nutrition
    ) {
      id
      description
      servingAmount
      servingUnits
      calories
      createdAt
      nutrition {
        key
        value
      }
    }
  }
`

export default CREATE_INGREDIENT
