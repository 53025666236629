import React from 'react'
// eslint-disable-next-line no-restricted-imports
import { Text as UIKittenText, TextProps as UIKittenTextProps } from '@ui-kitten/components'
import Animated from 'react-native-reanimated'
import { useStyleSheet } from '@src/style/service'
import { StyleService } from '@style/service'
import { textStyleValues } from './styles'

export type TextProps = Omit<UIKittenTextProps, 'appearance' | 'category' | 'status'> &
  (
    | {
        type: 'large' | 'regular' | 'small' | 'tiny'
        lineSpacing?: 'none' | 'tight' | 'normal'
        bold?: boolean
      }
    | {
        type: 'micro'
        lineSpacing?: never
        bold?: boolean
      }
    | {
        type: 'title-1' | 'title-2' | 'title-3' | 'score'
        lineSpacing?: never
        bold?: never
      }
  )

export const Text = (props: TextProps) => {
  const { type = 'regular', lineSpacing = 'normal', ...restProps } = props

  const styles = useStyleSheet(themeStyle)

  const { fontSize, lineHeights, fontWeight } = textStyleValues.types[type]
  const lineHeight = lineHeights[lineSpacing] || lineHeights.normal

  const bold = props.bold || fontWeight === '700'

  const textSizeStyles = [{ fontSize, lineHeight }, fontWeight && { fontWeight }]

  return (
    <UIKittenText
      {...restProps}
      style={[styles.text, textSizeStyles, bold && styles.bold, props.style]}
    />
  )
}

const themeStyle = StyleService.create({
  text: {
    color: textStyleValues.color,
    fontFamily: textStyleValues.fontFamilies.normal,
    fontWeight: textStyleValues.fontWeights.normal,
  },
  bold: {
    fontFamily: textStyleValues.fontFamilies.bold,
    fontWeight: textStyleValues.fontWeights.bold,
  },
})

// Animated.createAnimatedComponent requires this to be a class component
class TextClassComponent extends React.Component<TextProps> {
  render() {
    return <Text {...this.props} />
  }
}

export const AnimatedText = Animated.createAnimatedComponent(TextClassComponent)
