import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TutorialKey } from '@screens/Education/types'
import { visitedTutorialsSelector } from '@selectors/education'
import Storage from '@utils/storage'

export const useVisitedTutorial = (key: TutorialKey) => {
  const dispatch = useDispatch()

  const visitedTutorials = useSelector(visitedTutorialsSelector)

  useEffect(() => {
    if (!visitedTutorials[key]) {
      dispatch({ type: 'education/setVisitedTutorial', payload: key })
    }
  }, [dispatch, key, visitedTutorials])

  useEffect(() => {
    Storage.set(Storage.VISITED_TUTORIALS_KEY, visitedTutorials)
  }, [visitedTutorials])
}
