import React, { useState } from 'react'
import { useTheme } from '@ui-kitten/components'
import { TouchableOpacity } from 'react-native'
import DateTimePicker from 'react-native-modal-datetime-picker'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { useIsDarkMode } from '@src/config/theme'
import { DateInputConfig } from '../../types'
import { InputPropsBase } from './types'
import { useSetDefaultValue } from './Shared/hooks'

interface DateInputProps extends InputPropsBase {
  response: DateInputConfig
}

export const DateInput = ({ field }: DateInputProps) => {
  const isDarkMode = useIsDarkMode()
  const theme = useTheme()
  const styles = useStyleSheet(themedStyles)
  const [isDatePickerVisible, setDatePickerVisible] = useState(false)

  const fieldValue: string | null = field.value

  useSetDefaultValue({ fieldValue, onChange: field.onChange, defaultValue: null })

  const onDatePicker = (date: Date) => {
    field.onChange(date.toISOString())
    setDatePickerVisible(false)
  }

  return (
    <TouchableOpacity
      style={styles.container}
      accessibilityLabel="Select Date"
      activeOpacity={0.7}
      onPress={() => setDatePickerVisible(true)}
    >
      <Text type="regular" style={[styles.label, fieldValue ? styles.labelSelected : []]}>
        {fieldValue ? new Date(fieldValue).toLocaleDateString() : 'Select Date'}
      </Text>
      <DateTimePicker
        isDarkModeEnabled={isDarkMode}
        date={fieldValue ? new Date(fieldValue) : new Date()}
        isVisible={isDatePickerVisible}
        onConfirm={onDatePicker}
        onCancel={() => setDatePickerVisible(false)}
        mode="date"
        textColor={theme['theme.text.primary']}
        pickerContainerStyleIOS={styles.pickerContainerIOS}
        themeVariant={isDarkMode ? 'dark' : 'light'}
      />
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 16,
    borderWidth: 1,
    borderColor: 'theme.surface.base',
    borderRadius: 8,
  },
  label: {
    color: 'theme.text.tertiary',
  },
  labelSelected: {
    color: 'theme.text.primary',
  },
  pickerContainerIOS: {
    backgroundColor: 'theme.secondary.base',
  },
})
