import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import Rate from 'react-native-rate'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName } from '@components/base'
import { App } from '@src/config'
import { openUrl } from '@src/utils'

interface IconInfo {
  label: string
  iconName: IconName
  onPress: () => void
}

const icons: IconInfo[] = [
  {
    label: 'Open in Instagram',
    iconName: 'instagram-logo',
    onPress: () => openUrl('https://www.instagram.com/nutrisenseio/'),
  },
  {
    label: 'Open in Facebook',
    iconName: 'facebook-logo',
    onPress: () => openUrl('https://www.facebook.com/nutrisenseio/'),
  },
  {
    label: 'Rate the App',
    iconName: 'star',
    onPress: () =>
      Rate.rate({
        AppleAppID: App.appleAppID,
        GooglePackageName: App.googlePackageName,
      }),
  },
]

export const DrawerFooter = () => {
  const styles = useStyleSheet(themedStyle)
  const insets = useSafeAreaInsets()

  return (
    <View style={[styles.container, { marginBottom: 16 + insets?.bottom ?? 0 }]}>
      {icons.map(({ label, iconName, onPress }) => (
        <TouchableOpacity
          key={label}
          onPress={onPress}
          style={styles.iconContainer}
          accessibilityLabel={label}
        >
          <Icon name={iconName} />
        </TouchableOpacity>
      ))}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 16,
  },
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    marginRight: 12,
  },
})
