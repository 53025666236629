import { isBoolean } from 'lodash'
import { createSelector } from 'reselect'
import { Device } from '@src/config'
import { TerraProvider } from '@src/screens/Settings/models/settings.types'
import { integrationsSyncSettingsSelector, settingsSelector } from './settings'

//health selectors
export const healthKitSyncStatusSelector = createSelector(settingsSelector, (settings) =>
  isBoolean(settings.healthKitSync) ? settings.healthKitSync : Device.hasHealthKit,
)

export const healthLastSyncedSelector = createSelector(
  integrationsSyncSettingsSelector,
  (app) => app.lastSyncedHealth,
)

// KetoMojo selectors
export const ketoMojoUserSelector = createSelector(
  integrationsSyncSettingsSelector,
  (app) => app.ketoMojoUser,
)

export const ketoMojoSyncStatusSelector = createSelector(
  [settingsSelector, ketoMojoUserSelector],
  (settings, ketoMojoUser) => settings.ketoMojoSync && !!ketoMojoUser?.accessToken,
)

export const ketoMojoLastSyncedSelector = createSelector(
  integrationsSyncSettingsSelector,
  (app) => app.lastSyncedKetoMojo,
)

export const terraProvidersSelector = createSelector(
  settingsSelector,
  (settings) => settings.terraProviders || [],
)

export const terraProviderSelector = createSelector(
  settingsSelector,
  (settings) => (providerId?: string) =>
    (settings.terraProviders.find((provider: TerraProvider) => provider.provider === providerId) ||
      {}) as TerraProvider,
)

export const libreLinkupConnectionSelector = createSelector(
  settingsSelector,
  (settings) => settings.libreLinkupConnection,
)

export const dailyMeasurementsSourcePrioritiesSelector = createSelector(
  settingsSelector,
  (settings) => {
    return settings.dailyMeasurementsSourcePriorities
  },
)
