import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Keyboard, View } from 'react-native'
import { Controller, useForm } from 'react-hook-form'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { ScrollableAvoidKeyboard } from '@components'
import { SecureInput } from '@components/inputs/SecureInput'
import { PasswordValidationInfo } from '@components/inputs/PasswordValidationInfo'
import { NavigationContainer } from '@screens/Common/containers'
import { useGoBack, useSnack, Validators } from '@utils'

export const ChangePassword = () => {
  const styles = useStyleSheet(themedStyle)
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const showSnack = useSnack()
  const insets = useSafeAreaInsets()

  const isSubmitting = useRef(false)

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      password: '',
    },
  })

  const password = watch('password')

  const onSubmit = handleSubmit(({ password }) => {
    Keyboard.dismiss()

    if (isSubmitting.current) {
      return
    }

    isSubmitting.current = true

    dispatch({
      type: 'users/updatePassword',
      payload: { password },
      success: () => {
        showSnack('Password updated')
        goBack()
      },
      failure: () => {
        showSnack('Failed to update!', null, 'error')
      },
      complete: () => {
        isSubmitting.current = false
      },
    })
  })

  return (
    <NavigationContainer style={styles.container} title="Change Password" goBack={goBack}>
      <ScrollableAvoidKeyboard
        style={styles.container}
        contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
        keyboardShouldPersistTaps="handled"
      >
        <View>
          <Controller
            control={control}
            name="password"
            rules={{ required: true, validate: Validators.PasswordValidator }}
            render={({ field }) => (
              <SecureInput
                {...field}
                hasError={!!errors.password}
                label="New Password"
                placeholder="Set New Password"
                returnKeyType="done"
                style={styles.input}
                onSubmitEditing={onSubmit}
              />
            )}
          />
          <PasswordValidationInfo password={password} />
        </View>
        <Button
          type="primary"
          size="block"
          disabled={!isValid}
          onPress={onSubmit}
          accessibilityLabel="Save new password"
          allowPressWhenDisabled
        >
          Save New Password
        </Button>
      </ScrollableAvoidKeyboard>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    justifyContent: 'space-between',
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 16,
  },
  input: {
    marginTop: 24,
    marginBottom: 16,
  },
})
