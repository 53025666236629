import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { ImageWithPlaceholder } from '@src/components/Image/FastImageWithPlaceholder'
import { Text } from '@src/components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { User } from '@src/types'

export interface MemberNoteCreatorInfoProps {
  creator: User
}

export const MemberNoteCreatorInfo = ({ creator }: MemberNoteCreatorInfoProps) => {
  const { firstName, lastName, coachProfile } = creator
  const { photo } = coachProfile || {}
  const styles = useStyleSheet(themedStyle)

  const nutritionistName = `${firstName} ${lastName.charAt(0)}`
  return (
    <View style={styles.content}>
      {photo && (
        <ImageWithPlaceholder
          style={styles.photo as ImageStyle}
          source={{ uri: photo }}
          placeholderComponent={<ActivityIndicator animating size="small" {...styles.spinner} />}
        />
      )}
      <View style={styles.name}>
        <Text type="large">{nutritionistName}</Text>
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  content: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
    gap: 8,
  },
  photo: {
    width: 44,
    height: 44,
    borderRadius: 32,
  },
  spinner: {
    color: 'theme.surface.base',
  },
  name: {
    justifyContent: 'space-between',
  },
})
