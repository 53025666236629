import { Platform } from 'react-native'
import { ImageLibraryOptions, ImagePickerResponse, Asset } from 'react-native-image-picker'
export { Asset } from 'react-native-image-picker'
import { launchImageLibrary } from 'react-native-image-picker'

export const LIBRARY_OPTION = 'Choose from Library...'
export const PHOTO_OPTION = 'Take photo...'
export const REMOVE_PHOTO_OPTION = 'Remove photo...'

export type DefaultOptions = typeof LIBRARY_OPTION | typeof PHOTO_OPTION
export type Options = DefaultOptions | 'Remove photo...'

export const DEFAULT_OPTIONS: DefaultOptions[] = [LIBRARY_OPTION, PHOTO_OPTION]

export const IMAGE_SIZE = 800

const IMAGE_PICKER_OPTIONS: ImageLibraryOptions = {
  mediaType: 'photo',
  maxWidth: IMAGE_SIZE,
  maxHeight: IMAGE_SIZE,
}
export class ReactNativeFile {
  uri: string
  name: string
  type: string
  constructor({ uri, name, type }: { uri: string; name: string; type: string }) {
    this.uri = uri
    this.name = name
    this.type = type
  }
}

export const chooseFromLibrary = (
  onSelect: (file: ReactNativeFile) => void,
  onPermissionDenied: () => void,
) => {
  launchImageLibrary(IMAGE_PICKER_OPTIONS, (response: ImagePickerResponse) => {
    handleResponse(response, onSelect, onPermissionDenied)
  })
}

export const generateReactNativeFile = ({
  uri,
  name,
  type,
}: {
  uri: string
  name?: string
  type?: string
}) => {
  let fileName = 'Untitled.jpg'

  if (Platform.OS === 'ios' && name?.toLowerCase().endsWith('.heic')) {
    fileName = `${name.split('.')[0]}.jpg`
  }

  return new ReactNativeFile({
    uri,
    name: fileName,
    type: type || 'image/jpeg',
  })
}

const actionCallback = (asset: Asset, onSelect: (file: ReactNativeFile) => void) => {
  const { uri, fileName, type } = asset
  if (!uri) {
    return
  }

  const file = generateReactNativeFile({ uri, name: fileName, type })
  onSelect(file)
}

const handleResponse = (
  response: ImagePickerResponse,
  onSelect: (file: ReactNativeFile) => void,
  onPermissionDenied: () => void,
) => {
  if (response.didCancel) {
    console.log('User cancelled image picker')
  } else if (response.errorCode === 'permission') {
    onPermissionDenied()
  } else if (response.errorCode || response.errorMessage) {
    console.log('ImagePicker Error Code: ', response.errorCode)
    console.log('ImagePicker Error Message: ', response.errorMessage)
  } else if (response.assets) {
    const asset = response.assets[0]
    actionCallback(asset, onSelect)
  }
}
