import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { referralCreditsSelector } from '@src/selectors/app'
import { Icon, Text } from '@components/base'

export const ReferAFriend = () => {
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyle)

  const referralCredits = useSelector(referralCreditsSelector)

  const onPress = () => {
    navigation.navigate('Referral', { source: 'Onboarding' })
  }

  return (
    <TouchableOpacity
      accessibilityLabel="Refer a friend"
      style={styles.container}
      onPress={onPress}
    >
      <Icon name="gift" style={styles.giftIcon} />
      <View style={styles.textContent}>
        <Text type="large" bold>
          Refer a friend
        </Text>
        <Text type="regular" style={styles.secondaryText}>
          Get ${referralCredits} Off!
        </Text>
      </View>
      <Icon name="caret-right" style={styles.chevronIcon} />
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: 'theme.surface.base1',
  },
  giftIcon: {
    color: 'theme.like',
  },
  textContent: {
    flex: 1,
    marginHorizontal: 16,
  },
  secondaryText: {
    color: 'theme.text.secondary',
  },
  chevronIcon: {
    width: 16,
    height: 16,
    color: 'theme.text.tertiary',
  },
})
