import React from 'react'
import { View } from 'react-native'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { PaymentMethodCard } from '@src/screens/Marketplace/components/checkout/PaymentMethodCard'
import { AddressForm, AddressType } from '@src/screens/Marketplace/components/checkout/AddressForm'
import { PaymentMethod } from '@src/models/user.types'
import { Address } from '@src/types'

export interface SubscriptionFormProps {
  address?: Pick<Address, 'name' | 'street' | 'street2' | 'city' | 'state' | 'zipCode'> | null
  paymentMethod?: PaymentMethod | null
  onAddressFocus: () => void
  onPaymentMethodFocus: () => void
}

const SubscriptionForm = (props: SubscriptionFormProps) => {
  const { address, onAddressFocus, paymentMethod, onPaymentMethodFocus } = props

  const styles = useStyleSheet(themedStyles)

  return (
    <View style={styles.container}>
      <PaymentMethodCard
        paymentMethod={paymentMethod}
        onEditPaymentMethodPress={onPaymentMethodFocus}
      />
      <Divider appearance="large" />
      <AddressForm
        addressType={AddressType.Shipping}
        address={address}
        onEditAddressPress={onAddressFocus}
      />
      <Divider appearance="large" />
    </View>
  )
}

export default SubscriptionForm

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
})
