import React from 'react'
import { useWindowDimensions, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Device } from '@src/config'
import { Point } from '@src/screens/Insights/types'
import { Text } from '@components/base'
import {
  ScoreSectionEnum,
  GlucoseKetonesEnum,
  MacrosSectionEnum,
  MeasurementEnum,
} from '../utils/pageConfig'
import { ValueWithEmoji } from './ValueWithEmoji'

type CustomOverview = {
  title?: string
  minDateRange?: number
}
export interface ScoreOverviewProps {
  data: Point[]
  statMetaKey: ScoreSectionEnum | GlucoseKetonesEnum | MacrosSectionEnum | MeasurementEnum
  defaultUnit?: string
  customOverview?: Partial<Record<'average' | 'min' | 'max', CustomOverview>>
}

interface ScoreValueItemProps {
  label: string
  value: string
  unit: string
  metricSection: string
  metricKey: string
}

export const ScoreValueItem = ({
  label,
  value,
  unit,
  metricSection,
  metricKey,
}: ScoreValueItemProps) => {
  const styles = useStyleSheet(themedStyles)
  const dimensions = useWindowDimensions()

  const hasSmallScreen = Device.hasSmallScreen(dimensions)

  return (
    <View>
      <Text type="regular" bold style={styles.label}>
        {label}
      </Text>
      <View style={styles.valueRow}>
        {metricSection === 'journal' ? (
          <ValueWithEmoji
            type={hasSmallScreen ? 'title-2' : 'title-1'}
            value={value}
            emojiSize={24}
            itemKey={metricKey}
          />
        ) : (
          <>
            <Text type={hasSmallScreen ? 'title-2' : 'title-1'}>{value}</Text>
            <Text type="small" bold={hasSmallScreen}>
              {unit === '%' ? ` ${unit}` : unit}
            </Text>
          </>
        )}
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  label: {
    color: 'theme.text.tertiary',
  },
  valueRow: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
})
