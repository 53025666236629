import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fastingSettingsSelector } from '@src/selectors/settings'
import { useGoBack } from '@src/utils/navigation'
import {
  FastingWindowModal as FastingWindowModalComponent,
  HOURS_IN_DAY,
} from '../components/FastingWindowModal'

export const FastingWindowModalContainer = () => {
  const dispatch = useDispatch()
  const { fastingStart, fastingEnd } = useSelector(fastingSettingsSelector)

  const goBack = useGoBack()

  const [fasting, setFasting] = useState({
    fastingStart,
    fastingEnd: fastingEnd > fastingStart ? fastingEnd : fastingEnd + HOURS_IN_DAY,
  })

  const handleFastingPeriodChange = (name: 'startHour' | 'endHour') => (value: number) => {
    const period = name === 'startHour' ? 'fastingStart' : 'fastingEnd'
    setFasting({
      ...fasting,
      [period]: value,
    })
  }

  const onSaveButtonPress = () => {
    if (fastingStart !== fasting.fastingStart || fastingEnd !== fasting.fastingEnd) {
      dispatch({
        type: 'settings/update',
        payload: fasting,
      })
    }
    goBack()
  }

  return (
    <FastingWindowModalComponent
      fastingStart={fasting.fastingStart}
      fastingEnd={fasting.fastingEnd}
      handleFastingPeriodChange={handleFastingPeriodChange}
      onCancelButtonPress={goBack}
      onSaveButtonPress={onSaveButtonPress}
    />
  )
}
