import React, { useContext, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-native'
import { NavigatorContext, useDispatchAsync, useGoBack } from '@src/utils'
import { BottomSheet } from '@src/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { goalMetricOptionsSelector, goalMetricsSelector } from '@src/selectors/app'
import { Option, OptionType } from '@src/screens/Questionnaire/components/inputs/Shared/Option'

const MAX_ALLOWED_PRIMARY_GOAL_METRICS = 2

export const ChoosePrimaryGoalMetricsModal = () => {
  const styles = useStyleSheet(themedStyles)
  const goBack = useGoBack()
  const dispatch = useDispatchAsync()

  const goalMetricOptions = useSelector(goalMetricOptionsSelector)

  const goalMetrics = useSelector(goalMetricsSelector)

  const [selectedPrimaryGoalMetricKinds, setSelectedPrimaryGoalMetricKinds] = useState(() =>
    goalMetrics.filter((metric) => metric.primary).map((metric) => metric.kind),
  )

  const { enableLoader, disableLoader } = useContext(NavigatorContext)
  const loadingRef = useRef(false)

  const onSaveButtonPress = async () => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true
    enableLoader()

    try {
      await dispatch({
        type: 'goals/setPrimaryGoalMetrics',
        payload: { kinds: selectedPrimaryGoalMetricKinds },
      })
      await dispatch({
        type: 'users/fetch',
      })
      goBack()
    } catch (e) {
      loadingRef.current = false
    } finally {
      disableLoader()
    }
  }

  return (
    <BottomSheet
      header="Choose your primary metrics"
      primaryButton={{ text: 'Save', onPress: onSaveButtonPress }}
      secondaryButton={{ text: 'Cancel', onPress: goBack }}
    >
      <View style={styles.container}>
        {goalMetricOptions.map((goalMetricOption) => {
          const selected = selectedPrimaryGoalMetricKinds.includes(goalMetricOption.value)

          const onPress = () => {
            setSelectedPrimaryGoalMetricKinds((metricKinds) =>
              selected
                ? metricKinds.filter((kind) => kind !== goalMetricOption.value)
                : [...metricKinds, goalMetricOption.value],
            )
          }

          return (
            <Option
              key={goalMetricOption.value}
              label={goalMetricOption.text}
              selected={selected}
              onPress={onPress}
              disabled={
                !selected &&
                selectedPrimaryGoalMetricKinds.length >= MAX_ALLOWED_PRIMARY_GOAL_METRICS
              }
              type={OptionType.Checkbox}
            />
          )
        })}
      </View>
    </BottomSheet>
  )
}

const themedStyles = StyleService.create({
  container: {
    marginTop: 16,
  },
  charactersLimit: {
    textAlign: 'right',
    color: 'theme.text.secondary',
    marginTop: 8,
  },
})
