import React from 'react'
import { TouchableOpacity } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Icon } from '@src/components/base'

interface CardProps {
  children?: React.ReactNode
  buttonText: string
  onButtonPress?: () => void
}

export const Card = (props: CardProps) => {
  const { children, buttonText, onButtonPress } = props
  const styles = useStyleSheet(themedStyle)

  return (
    <TouchableOpacity
      accessibilityLabel={buttonText}
      style={styles.container}
      onPress={onButtonPress}
    >
      {children}
      <Button
        type="primary"
        accessoryRight={<Icon name="arrow-right" />}
        size="small"
        accessibilityLabel={buttonText}
        onPress={onButtonPress}
        style={styles.button}
      >
        {buttonText}
      </Button>
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  container: {
    padding: 16,
    flex: 1,
    backgroundColor: 'theme.surface.base2',
    borderRadius: 15,
    flexDirection: 'column',
  },
  button: {
    alignSelf: 'flex-start',
    marginTop: 'auto',
  },
})
