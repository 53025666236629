import React from 'react'
import { useDispatch } from 'react-redux'
import { StyleProp, ViewStyle } from 'react-native'
import { TouchableSection } from '@components'
import { useSnack } from '@src/utils/navigatorContext'
import { useApplicationActionSheet } from '@src/hooks/useApplicationActionSheet'

const TITLE = 'Export Your Data'
const MESSAGE = `
    All of your CGM, meal, activity data and notes will be exported and sent to your email as a CSV attachment.
    You should receive your data within the next hour.
  `

interface ExportDataSectionProps {
  style?: StyleProp<ViewStyle>
}

const ExportDataSection = ({ style }: ExportDataSectionProps) => {
  const dispatch = useDispatch()
  const showSnack = useSnack()
  const { showDestructiveActionSheet } = useApplicationActionSheet()

  const onPress = () => {
    showDestructiveActionSheet({
      title: TITLE,
      message: MESSAGE,
      label: 'Export',
      handler: () => {
        dispatch({
          type: 'app/exportData',
          success: () => showSnack('Export Started. Expect an email soon!'),
        })
      },
    })
  }

  return <TouchableSection style={style} onPress={onPress} iconName="export" title="Export Data" />
}

export default ExportDataSection
