import React, { useEffect } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { Button, Text } from '@src/components/base'
import { videoCallProductSelector } from '@src/selectors/app'
import { BillingProductCategory } from '@src/types'
import { CustomEventTypes } from '@src/config'
import { useNutritionistHubTracker } from '../hooks'
import { NutritionistPhotoIcon } from './NutritionistPhotoIcon'

export const PaidCallsIntroScreen = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const videoCallProduct = useSelector(videoCallProductSelector)
  const track = useNutritionistHubTracker()

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      payload: { category: BillingProductCategory.NutritionistHub },
    })
  }, [dispatch])

  return (
    <NavigationContainer title="Video Call">
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <NutritionistPhotoIcon style={styles.nutritionistPhoto} />
        <Text type="large" style={styles.descriptionText}>
          Sign up for a video call with your nutritionist to discuss your goals and determine a plan
          so that you can get the most out of the program.
        </Text>
        <Text type="title-3" style={styles.productDescription}>
          {`$${videoCallProduct?.price} for 30 min call`}
        </Text>
        <Text type="tiny" style={styles.text}>
          FSA/HSA ACCEPTED
        </Text>
        <View style={styles.buttons}>
          <Button
            type="primary"
            size="block"
            accessibilityLabel="Book Call"
            onPress={() => {
              track(CustomEventTypes.NutritionistHubBookPaidCallTapped)
              navigation.navigate('VideoCallCheckout')
            }}
          >
            Book Call
          </Button>
        </View>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  nutritionistPhoto: {
    alignSelf: 'center',
    marginTop: 48,
  },
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
    justifyContent: 'center',
  },
  descriptionText: {
    textAlign: 'center',
    paddingHorizontal: 16,
    marginVertical: 48,
  },
  productDescription: {
    textAlign: 'center',
    paddingHorizontal: 16,
    marginBottom: 48,
  },
  text: {
    textAlign: 'center',
    paddingHorizontal: 16,
  },
  buttons: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingBottom: 16,
  },
})
