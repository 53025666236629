import React, { ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { ActivityIndicator, useWindowDimensions, View, ViewStyle } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { useNavigation } from '@react-navigation/core'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { useHasLargeScreen } from '@src/config/device'
import { NavigationBar } from '@src/components'
import { FlexView } from '@src/components/FlexView'
import { useGoBack } from '@src/utils/navigation'
import { loadingSelector } from '@src/selectors/loading'
import { IconDescriptor } from '@src/components/Icon'
import { NavigationBarProps } from '@src/components/navigationBar/NavigationBar'
import { ThemeColor } from '@src/style/theme'
import { useForceLandscapeOrientation } from '@src/utils/orientation'
import { Icon } from '@src/components/base'

interface NavigationContainerProps {
  title?: string | ReactNode
  isDrawerScreen?: boolean
  allowBackNavigation?: boolean
  children?: ReactNode
  rightAccessories?: NavigationBarProps['rightAccessories']
  goBack?: () => void
  onLongPress?: () => void
  leftIcon?: IconDescriptor | ReactNode
  style?: ViewStyle
  showLoadingIndicator?: boolean
  NavigationBarComponent?: typeof NavigationBar
  leftIconTintColor?: ThemeColor
  navigationBarProps?: Omit<NavigationBarProps, 'setHeaderHeight'>
  shouldOverlayNavigationBar?: boolean
}

const NavigationContainerContext = React.createContext({ headerHeight: 0 })

const NavigationContainer = (props: NavigationContainerProps) => {
  const styles = useStyleSheet(themedStyles)

  const {
    title,
    isDrawerScreen = false,
    allowBackNavigation = true,
    children,
    rightAccessories: rightAccessoriesFromProps,
    goBack: goBackFromProps,
    onLongPress,
    leftIcon = <Icon name="caret-left" size="20" weight="bold" style={styles.defaultLeftIcon} />,
    leftIconTintColor,
    style,
    showLoadingIndicator = false,
    NavigationBarComponent = NavigationBar,
    navigationBarProps = {},
    shouldOverlayNavigationBar = false,
  } = props

  const navigation = useNavigation()
  const loadingGlobal = useSelector(loadingSelector)
  const loading = showLoadingIndicator ? loadingGlobal : false

  const goBack = useGoBack()
  const hasLargeScreen = useHasLargeScreen()

  const getBackAction = () => {
    if (
      (!hasLargeScreen || !isDrawerScreen) &&
      (goBackFromProps || (allowBackNavigation && navigation.canGoBack()))
    ) {
      return {
        onPress: goBackFromProps || goBack,
        testID: `${title}/Back`,
        ...(React.isValidElement(leftIcon)
          ? { renderIconComponent: () => leftIcon as ReactNode }
          : { iconDescriptor: leftIcon as IconDescriptor, iconTintColor: leftIconTintColor }),
      }
    }

    if (goBackFromProps && React.isValidElement(leftIcon)) {
      return {
        renderIconComponent: () => leftIcon as ReactNode,
        onPress: goBackFromProps,
        onLongPress,
        accessibilityLabel: 'Account',
        testID: 'account',
      }
    }

    return undefined
  }

  const backAction = getBackAction()

  const theme = useTheme()
  const forceLandscapeOrientation = useForceLandscapeOrientation()

  const [headerHeight, setHeaderHeight] = useState(0)
  const dimensions = useWindowDimensions()
  const insets = useSafeAreaInsets()

  const rightAccessories = loading
    ? [
        <ActivityIndicator
          key="activity-indicator"
          color={theme['theme.text.secondary']}
          style={styles.activityIndicator}
        />,
      ]
    : rightAccessoriesFromProps

  const content = (
    <FlexView style={style}>
      <NavigationBarComponent
        title={title}
        leftAccessory={backAction}
        rightAccessories={rightAccessories}
        setHeaderHeight={setHeaderHeight}
        {...navigationBarProps}
        style={[shouldOverlayNavigationBar && styles.navBarWhenOverlaying]}
      />
      {shouldOverlayNavigationBar ? (
        <View style={styles.contentWhenOverlaying}>{children}</View>
      ) : (
        <NavigationContainerContext.Provider value={{ headerHeight }}>
          {children}
        </NavigationContainerContext.Provider>
      )}
    </FlexView>
  )

  if (forceLandscapeOrientation) {
    return (
      <View style={styles.landscapeContainer}>
        <View
          style={[
            {
              paddingLeft: insets?.top,
              paddingRight: insets?.bottom,
              width: dimensions.height,
              height: dimensions.width,
              transform: [{ rotate: '90deg' }],
            },
            style,
          ]}
        >
          {content}
        </View>
      </View>
    )
  }

  return content
}

const themedStyles = StyleService.create({
  landscapeContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'theme.background',
  },
  navBarWhenOverlaying: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
  contentWhenOverlaying: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  activityIndicator: {
    width: 44,
    height: 44,
    justifyContent: 'center',
  },
  defaultLeftIcon: {
    marginLeft: 16,
  },
})

export default NavigationContainer
