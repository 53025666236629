import React from 'react'
import { useTheme } from '@ui-kitten/components'
import { Button, ButtonProps, Icon } from '@components/base'
import { GoogleLogo, AppleLogo } from '@src/assets/svgs'

export enum SignInButtonVariant {
  Email,
  Google,
  Apple,
}

type CustomProps = 'accessibilityLabel' | 'children' | 'size' | 'type'

interface SignInButtonProps extends Omit<ButtonProps, CustomProps> {
  accessibilityLabel?: string
  children?: React.ReactNode
  variant: SignInButtonVariant
}

export const SignInButton = ({
  accessibilityLabel,
  children,
  variant,
  ...rest
}: SignInButtonProps) => {
  const theme = useTheme()

  const renderButtonIcon = () => {
    switch (variant) {
      case SignInButtonVariant.Email:
        return <Icon name="envelope-simple" />
      case SignInButtonVariant.Google:
        return <GoogleLogo />
      case SignInButtonVariant.Apple:
        return <AppleLogo fill={theme['theme.text.primary']} />
    }
  }

  const buildAccessibilityLabel = () => {
    switch (variant) {
      case SignInButtonVariant.Email:
        return 'Sign Up with Email button'
      case SignInButtonVariant.Google:
        return 'Continue with Google button'
      case SignInButtonVariant.Apple:
        return 'Continue with Apple button'
    }
  }

  const buildTitle = () => {
    switch (variant) {
      case SignInButtonVariant.Email:
        return 'Sign Up with Email'
      case SignInButtonVariant.Google:
        return 'Continue with Google'
      case SignInButtonVariant.Apple:
        return 'Continue with Apple'
    }
  }

  return (
    <Button
      {...rest}
      accessibilityLabel={accessibilityLabel ?? buildAccessibilityLabel()}
      accessoryLeft={renderButtonIcon()}
      size="block"
      type="outline"
    >
      {children || buildTitle()}
    </Button>
  )
}
