import { FontAwesome6ProIconName } from '@src/components'

interface CmsMedia {
  data: {
    attributes: {
      url: string
      previewUrl: string
    }
  }
}

export enum TutorialStepActionTypes {
  TutorialStepActionLink = 'ComponentActionActionLink',
  TutorialStepActionVideo = 'ComponentActionActionVideo',
  TutorialStepPlatformLink = 'ComponentActionPlatformLink',
}

export enum TutorialTypes {
  List = 'list',
  Carousel = 'carousel',
}

export interface TutorialPreview {
  id: string
  position: string
  name: string
  title: string
  duration: string
  description: string
  buttonText: string
  image: CmsMedia
}
export interface TutorialGroup {
  id: string
  title: string
  duration: string
  summary: string
  tutorials: TutorialPreview[]
}

export interface TutorialStepActionLink {
  __typename: TutorialStepActionTypes.TutorialStepActionLink
  id: string
  url: string
}
export interface TutorialStepActionVideo {
  __typename: TutorialStepActionTypes.TutorialStepActionVideo
  id: string
  description: string
  title: string
  video: CmsMedia
}

export interface TutorialStepPlatformLink {
  __typename: TutorialStepActionTypes.TutorialStepPlatformLink
  id: string
  android: {
    url: string
  }
  ios: {
    url: string
  }
}
export interface TutorialStep {
  title: string
  description: string
  duration: string
  buttonText: string
  buttonIcon: FontAwesome6ProIconName
  position: string
  image: CmsMedia
  warning: string | null
  action: (TutorialStepActionLink | TutorialStepActionVideo | TutorialStepPlatformLink)[]
}

export interface Tutorial {
  id: string
  position: string
  name: string
  title: string
  duration: string
  description: string
  buttonText: string
  buttonIcon: FontAwesome6ProIconName
  image: CmsMedia
  tutorialType: TutorialTypes
  tutorialSteps: TutorialStep[]
}

export interface TutorialsStoreState {
  groups: TutorialGroup[]
  currentTutorial: Tutorial
}

export interface TutorialGroupResponse {
  id: string
  attributes: {
    title: string
    duration: string
    summary: string
    tutorials: { data: TutorialPreviewResponse[] }
  }
}

interface TutorialPreviewResponse {
  id: string
  attributes: {
    position: string
    name: string
    title: string
    duration: string
    description: string
    buttonText: string
    image: CmsMedia
  }
}

interface TutorialStepResponse {
  id: string
  attributes: {
    title: string
    description: string
    duration: string
    buttonText: string
    buttonIcon: FontAwesome6ProIconName
    position: string
    image: CmsMedia
    warning: string | null
    action: (TutorialStepActionLink | TutorialStepActionVideo | TutorialStepPlatformLink)[]
  }
}
export interface TutorialResponse {
  data: {
    id: string
    attributes: {
      position: string
      name: string
      title: string
      duration: string
      description: string
      buttonText: string
      buttonIcon: FontAwesome6ProIconName
      image: CmsMedia
      tutorialType: TutorialTypes
      tutorialSteps: { data: TutorialStepResponse[] }
    }
  }
}
