import { gql } from '@apollo/client'

export const SURVEY_OUTCOME = gql`
  fragment SurveyOutcome on Outcome {
    id
    key
    reason {
      headline
      notes
      message {
        content
        links {
          placeholder
          url
        }
      }
      inlineAlert {
        type
        message
      }
    }
  }
`
