import { BloodPressureMeasurement, SingleValueMeasurement } from '@src/types'

export enum MeasurementFormType {
  Double = 'double',
  Single = 'single',
  Slider = 'slider',
}

export interface MeasurementFormOptions {
  sliderRange?: { min: number; max: number }
  labels?: string[]
}

export interface MeasurementFormData {
  value: string
  secondValue?: string
}

export type MeasurementValues =
  | Partial<BloodPressureMeasurement & SingleValueMeasurement>
  | undefined
