import { gql } from '@apollo/client'

export const FETCH_COURSES = gql`
  query allCourses {
    allCourses {
      programs {
        id
        contentId
        position
        progress {
          complete
        }
        modules {
          id
          contentId
          position
          progress {
            complete
          }
          lessons {
            id
            contentId
            position
            progress {
              complete
            }
          }
        }
      }
    }
  }
`
