/* eslint-disable max-len */
import * as React from 'react'
import Svg, { Rect, G, Path, Circle, Defs, ClipPath } from 'react-native-svg'

interface NsPermissionProps {
  children: React.ReactNode
}

const NsPermission = ({ children }: NsPermissionProps) => {
  return (
    <Svg width={241} height={95} viewBox="0 0 241 95" fill="none">
      <Rect width={241} height={95} rx={39.0459} fill="#F2F4F5" />
      <Path
        d="M92 34.88h56M92 61.726h56"
        stroke="#A9B7B9"
        strokeWidth={2.44037}
        strokeDasharray="6.1 6.1"
      />
      <Circle cx={136.5} cy={34.5} r={4.5} fill="#11494F" />
      <Circle cx={114.5} cy={61.5} r={4.5} fill="#11494F" />
      <Rect x={16} y={8} width={79} height={79} rx={17.2442} fill="#102F32" />
      <G clipPath="url(#clip0_925_7775)" fill="#fff">
        <Path d="M44.313 29.963c-5.82 0-9.93 4.645-11.196 7.87-.126.258-.316.71-.696.58-.506-.128-.253-.773-.063-1.354.57-1.677.696-3.032.696-4.386v-2.194h-6.072v33.027h6.072V48.09c0-7.87 5.06-12.708 9.74-12.708 4.239 0 7.465 2.129 7.465 9.805v18.32h6.072v-19.61c0-9.676-4.554-13.934-12.018-13.934zM57.113 52.024c.316 7.29 5.313 11.998 14.105 11.998 8.855 0 14.232-4.322 14.232-11.03 0-5.418-4.301-7.999-13.03-8.966-7.021-.774-8.223-2.194-8.223-4.322 0-2.193 1.898-4.322 7.021-4.322 5.693 0 7.084 3.419 7.274 5.547h5.946c-.443-6.192-4.87-10.966-13.03-10.966-8.856 0-13.283 5.225-13.283 10.192 0 4.322 2.656 8.257 11.132 9.16 7.4.774 10.12 1.677 10.12 4.645 0 2.128-1.707 4.644-7.906 4.644-6.325 0-8.35-4.064-8.413-6.58h-5.945z" />
      </G>
      <G clipPath="url(#clip1_925_7775)" fill="#fff">
        <Rect x={146} y={8} width={79} height={79} rx={38} />
        <Rect x={146} y={8} width={79.3119} height={79.3119} rx={17.5521} />
      </G>
      <Rect x={146.5} y={8.5} width={78} height={78} rx={37.5} stroke="#F2F4F5" />
      {children}
      <Defs>
        <ClipPath id="clip0_925_7775">
          <Path fill="#fff" transform="translate(26.982 29.963)" d="M0 0H58.5688V34.1651H0z" />
        </ClipPath>
        <ClipPath id="clip1_925_7775">
          <Rect x={146} y={8} width={79} height={79} rx={38} fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export default NsPermission
