import moment from 'moment'
import { Moment } from 'moment'
import { first, isEmpty, last } from 'lodash'
import { ItemPoint } from '@src/containers/ActivityChart/types'
import { TWELVE_HOURS_TIME_FORMAT } from '@src/config/momentFormat'
import { ActivityChartProps } from '@src/containers/ActivityChart/ActivityChart'
import { Measurement } from '@src/types'

const MEASUREMENTS_WINDOW_IN_HOURS = 2

// it should be consistent with timeline chart and for timeline chart we add gaps only if interval is > 30 minutes
// (controlled on BE side: TimelineChartService#merge_measurements)
const MAX_MEASUREMENTS_INTERVAL_IN_MINUTES = 30

export const getChartData = (item: ActivityChartProps['item']) => {
  const { values = [] } = item.glucose ?? {}

  const startTime = moment(item.occurredAt)
  const endTime = moment(startTime).add(MEASUREMENTS_WINDOW_IN_HOURS, 'hours')
  const chartStartTime = moment(startTime).subtract(1, 'hour')
  const chartEndTime = moment(endTime).add(1, 'hour')

  const filteredValues = filterChartData({ values, start: chartStartTime, end: chartEndTime })

  const minValue = Math.min(...values.map((v) => v.value as number))
  const maxValue = Math.max(...values.map((v) => v.value as number))

  // we use 30% of delta as bottom offset for chart
  const offset = (maxValue === minValue ? minValue : maxValue - minValue) / 3.0
  const bottomValue = minValue - offset

  const chartData = toChartData(filteredValues, true, bottomValue)
  const activityData = filterChartData({ values: chartData, start: startTime, end: endTime })

  const itemPoint = first(activityData)
  const limitPoint = last(activityData)

  const centeredChartData = [...chartData]
  fillEmptyIntervals({
    values: centeredChartData,
    leftLimit: chartStartTime,
    rightLimit: chartEndTime,
    bottomValue,
  })

  const startTimeTick = {
    x: moment(startTime).unix(),
    y: interpolate(centeredChartData, startTime) ?? (itemPoint?.y || 0),
    time: moment(startTime).format(TWELVE_HOURS_TIME_FORMAT),
  }
  const endTimeTick = {
    x: moment(endTime).unix(),
    y: interpolate(centeredChartData, endTime) ?? (limitPoint?.y || 0),
    time: moment(endTime).format(TWELVE_HOURS_TIME_FORMAT),
  }

  return {
    activityData,
    centeredChartData,
    startTimeTick,
    endTimeTick,
  }
}

export const toChartData = (
  values: Measurement[],
  fillSpaces = true,
  bottomValue = 0,
): ItemPoint[] => {
  if (isEmpty(values)) {
    return []
  }

  const data = values.map((v: Measurement) => ({
    x: moment(v.timestamp),
    y: Number(v.value),
    fake: false,
  }))

  if (fillSpaces) {
    let i = data.length - 1
    while (i !== 0) {
      const d1 = data[i]
      const d2 = data[i - 1]
      const intervalLength = moment(d1.x).diff(d2.x, 'minutes')

      if (intervalLength > MAX_MEASUREMENTS_INTERVAL_IN_MINUTES) {
        if (d1.y) {
          data.splice(i, 0, { x: moment(d1.x), y: bottomValue, fake: true })
        }

        data.splice(i, 0, {
          x: moment(d1.x).subtract(MAX_MEASUREMENTS_INTERVAL_IN_MINUTES, 'minutes'),
          y: bottomValue,
          fake: true,
        })

        if (d2.y) {
          data.splice(i, 0, { x: moment(d2.x), y: bottomValue, fake: true })
        }
      } else {
        i -= 1
      }
    }
  }

  return data
}

export const filterChartData = <T>({
  values,
  start,
  end,
}: {
  values: T[]
  start: Moment
  end: Moment
}) => {
  return values.filter((point: any) =>
    moment(point.x ?? point.timestamp).isBetween(start, end, undefined, '[]'),
  )
}

export const fillEmptyIntervals = ({
  values,
  leftLimit,
  rightLimit,
  bottomValue,
}: {
  values: ItemPoint[]
  leftLimit: Moment
  rightLimit: Moment
  bottomValue: number
}) => {
  if (values.length === 0) {
    return
  }

  const hasLeftSpaces = values.findIndex((value: ItemPoint) => leftLimit.unix() > value.x.unix())
  if (hasLeftSpaces === -1) {
    values.unshift({ x: moment(values[0].x), y: bottomValue })
    while (leftLimit.unix() < values[0].x.unix()) {
      const x = moment(values[0].x).subtract(MAX_MEASUREMENTS_INTERVAL_IN_MINUTES, 'minutes')
      values.unshift({ x, y: bottomValue })
    }
  }

  const hasRightSpaces = values.findIndex((value: ItemPoint) => rightLimit.unix() < value.x.unix())
  if (hasRightSpaces === -1) {
    values.push({ x: moment(values[values.length - 1].x), y: bottomValue })
    while (rightLimit.unix() > values[values.length - 1].x.unix()) {
      const x = moment(values[values.length - 1].x).add(
        MAX_MEASUREMENTS_INTERVAL_IN_MINUTES,
        'minutes',
      )
      values.push({ x, y: bottomValue })
    }
  }
}

const interpolate = (data: ItemPoint[], initX: Moment): number | null => {
  const i1 = data.findIndex((value) => value.x.unix() - initX.unix() > 0)
  if (i1 > 0) {
    const i2 = i1 - 1
    const v1 = data[i2]
    const v2 = data[i1]
    const k = (v2.y - v1.y) / (v2.x.unix() - v1.x.unix())
    const b = k * v2.x.unix() - v2.y
    return k * initX.unix() - b
  }

  return null
}
