import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { LessonWithContent } from '../models/courses.types'

interface LessonCardExperimentProps {
  lesson: LessonWithContent
}

export const LessonCardExperiment = ({ lesson }: LessonCardExperimentProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <>
      <Icon name="sparkle" />
      <View style={styles.cardContent}>
        <Text type="regular" bold numberOfLines={2}>
          {lesson.title}
        </Text>
        {!!lesson.description && (
          <Text type="regular" style={styles.cardDescription} numberOfLines={1}>
            {lesson.description}
          </Text>
        )}
      </View>
    </>
  )
}

const themedStyle = StyleService.create({
  cardContent: {
    flex: 1,
    marginLeft: 12,
  },
  cardDescription: {
    color: 'theme.text.secondary',
  },
})
