import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService } from '@src/style/service'
import { InlineAlert } from '@src/components/InlineAlert'
import { useIsActiveResearchDexcom, usePrevious } from '@src/utils/hooks'
import { UiInlineAlert } from '@src/types'
import { dexcomConnectionSelector } from '@src/selectors/app'

const USER_REFETCH_INTERVAL = 5000

export const DexcomDataSyncPopup = () => {
  const dispatch = useDispatch()
  const isActiveResearchDexcom = useIsActiveResearchDexcom()
  const dexcomConnection = useSelector(dexcomConnectionSelector)

  const hasData = !!dexcomConnection?.lastSyncedAt
  const hidePopup = !isActiveResearchDexcom || !dexcomConnection || hasData

  const hasPreviousData = usePrevious(hasData)

  useEffect(() => {
    if (hasPreviousData === false && hasData) {
      dispatch({ type: 'app/refreshDataUponDexcomSync' })
    }
  }, [dispatch, hasData, hasPreviousData])

  useEffect(() => {
    if (hidePopup) {
      return
    }

    const interval = setInterval(() => {
      dispatch({ type: 'users/fetch' })
    }, USER_REFETCH_INTERVAL)

    return () => clearInterval(interval)
  }, [dispatch, hidePopup])

  if (hidePopup) {
    return null
  }

  return (
    <InlineAlert
      style={styles.popup}
      category={UiInlineAlert.Info}
      message="Data Pending: your Dexcom G7 Integration is syncing..."
    />
  )
}

const styles = StyleService.create({
  popup: {
    marginHorizontal: 16,
    marginTop: 8,
  },
})
