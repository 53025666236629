import React from 'react'
import { View } from 'react-native'
import { Text } from '@components/base'

interface AutocompleteUserItemProps {
  email: string
  fullName: string
}

const AutocompleteUserItem = (props: AutocompleteUserItemProps) => {
  const { email, fullName } = props
  return (
    <View>
      <Text type="small" bold>
        {fullName}
      </Text>
      <Text type="tiny">{email}</Text>
    </View>
  )
}

export default AutocompleteUserItem
