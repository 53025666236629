/* eslint-disable max-len */
import * as React from 'react'
import { Path } from 'react-native-svg'
import NsPermission from './nsPermission'

const NsLocation = () => {
  return (
    <NsPermission>
      <Path
        d="M186 50.375a5.5 5.5 0 100-11 5.5 5.5 0 000 11z"
        stroke="#3599EF"
        strokeWidth={2.44037}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M199.75 44.876c0 12.375-13.75 22-13.75 22s-13.75-9.626-13.75-22A13.752 13.752 0 01186 31.126a13.75 13.75 0 0113.75 13.75v0z"
        stroke="#3599EF"
        strokeWidth={2.44037}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </NsPermission>
  )
}

export default NsLocation
