import { useSelector } from 'react-redux'
import { measurementsConfigSelector } from '@src/selectors/app'
import { unitSystemStoreStateSelector } from '@src/selectors/settings'
import { eventsStatisticsSelector, eventsScoreSelector } from '@src/selectors/events'

export const useScoreStatistics = () => {
  const measurements = useSelector(measurementsConfigSelector)
  const unitSystem = useSelector(unitSystemStoreStateSelector)
  const units = measurements.find(({ name }) => name === 'glucose')?.[unitSystem]?.units ?? 'mg/dL'

  const summaryStats = useSelector(eventsStatisticsSelector)
  const summary = summaryStats.today ?? {}

  const scoreStats = useSelector(eventsScoreSelector)
  const score = scoreStats?.today ?? {}

  return { units, summary, score }
}
