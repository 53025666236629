import moment, { Moment } from 'moment'
import { first, last, isNull, round, isEmpty } from 'lodash'
import { reverse } from 'lodash/fp'
import { Chart, ChartRange } from '@src/types'

export const transformCharts = (charts: Chart[]) => {
  const primaryChart = charts.find((x) => x.meta.key === 'primary')
  const secondaryChart = charts.find((x) => x.meta.key === 'secondary')

  const primary = transformChart(primaryChart)
  const secondary = transformChart(secondaryChart)

  if (secondary) {
    return {
      primary,
      secondary,
    }
  }

  return {
    primary,
  }
}

const transformChart = (chart: Chart | undefined) => {
  if (!chart) {
    return
  }

  const chartData = {
    ...chart,
    range: { ...chart.range },
    meta: { ...chart.meta },
    values: chart.values.map((v) => ({
      x: v.x,
      y: ('interpolated' in v && v.interpolated) || v.y === null ? null : Number(v.y),
      tag: 'interpolated' in v && v.interpolated ? null : chart.meta.tag,
    })),
  }

  return chartData
}

export const interpolateEvents = (
  sortedValues: { x: Moment; y: number | null }[],
  events: any[],
  range: ChartRange,
) => {
  if (sortedValues.length === 0 || isEmpty(events)) {
    return []
  }

  const timeKey = 'x'
  const valueKey = 'y'

  const data = sortedValues.filter((value) => value[valueKey])

  // events points must be sorted by occurred_at
  return events
    .map((event) => {
      const time = moment(event.occurredAt || event.x)
      return {
        x: time,
        y: interpolate(data, time, range, valueKey, timeKey),
        canShowGlucoseScore: time.isBetween(data[0]?.[timeKey], last(data)?.[timeKey]),
        id: event.id,
        type: event.type || event.kind,
        tag: event.tag || event.__typename,
        imageUrl: event.avatar,
        description: event.description,
        fake: event.fake,
        startedAt: event.startedAt && moment(event.startedAt).toISOString(),
        endedAt: event.endedAt && moment(event.endedAt).toISOString(),
        displayDate: event.displayDate,
        body: event.body,
      }
    })
    .sort((v0, v1) => v0.x.unix() - v1.x.unix())
}

export const interpolate = (
  data: { x: Moment; y: number | null }[],
  timestamp: Moment,
  range: ChartRange = { min: 0, max: 0 },
  valueKey: 'y',
  timeKey: 'x',
) => {
  // handle case where a point with current timestamp exists
  const pointIdx = data.findIndex((el) => timestamp.isSame(el.x))
  let val

  if (pointIdx > -1) {
    val = Number(data[pointIdx]?.[valueKey])
  } else if (timestamp.isBefore(data[0]?.[timeKey])) {
    val = Number(first(data)?.[valueKey])
  } else if (timestamp.isAfter(last(data)?.[timeKey])) {
    val = Number(last(data)?.[valueKey])
  } else {
    const index = data.findIndex((v) => timestamp.isSameOrBefore(v[timeKey])) - 1
    if (index < 0) {
      val = Number(first(data)?.[valueKey])
    } else {
      const prevIndex =
        data.length -
        reverse(data).findIndex(
          (v) => !isNull(v[valueKey]) && timestamp.isSameOrAfter(v[timeKey]),
        ) -
        1
      const nextIndex = data.findIndex(
        (v) => !isNull(v[valueKey]) && timestamp.isSameOrBefore(v[timeKey]),
      )
      const prevValue = data[prevIndex] || data[nextIndex] || { [valueKey]: range.min }
      const nextValue = data[nextIndex] || data[prevIndex] || { [valueKey]: range.min }
      const slices = !prevIndex || !nextIndex || prevIndex > data.length ? 1 : nextIndex - prevIndex
      val = Number(
        (prevValue[valueKey] as number) +
          (((nextValue[valueKey] as number) - (prevValue[valueKey] as number)) / slices) *
            (index - prevIndex),
      )
    }
  }

  return round(val, 1)
}
