import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'
import { calendarDateSelector } from '@src/selectors/app'

export const insightsStateSelector = (state: RootStoreState) => state.insights

export const insightsCacheSelector = createSelector(insightsStateSelector, (insights) => {
  return insights.cache
})

export const insightsCalendarSelector = createSelector(
  insightsStateSelector,
  // since calendarData is empty in the insights state,
  // we need to share glucose data from the app calendar state for the calendar
  calendarDateSelector,
  (insights, calendar) => {
    return {
      ...insights.calendar,
      calendarData: calendar.calendarData,
    }
  },
)

export const statsSelector = createSelector(insightsStateSelector, (state) => state.stats)
