import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FlatList, TouchableOpacity, View } from 'react-native'
import { isEqual, omit } from 'lodash'
import { useNavigation } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NavigationContainer } from '@src/screens/Common/containers'
import { StatsSetting } from '@src/screens/Settings/models/settings.types'
import { statsSettingsStateSelector } from '@src/selectors/settings'
import { useDebouncedCallback } from '@src/utils/hooks'
import { MeasurementEnum, ScoreSectionEnum } from '@src/screens/Insights/utils/pageConfig'
import { BLOOD_PRESSURE_KEYS } from '@src/screens/Insights/constants'
import { StatToggle } from '@src/screens/Insights/components'
import { GLUCOSE_SECTION, getTitle } from '@src/screens/Insights/utils/utils'
import { Input, Text } from '@src/components/base'
import { Feature, useFeatureFlag } from '@src/components'
import {
  goalMetricOptionsSelector,
  goalMetricsSelector,
  primaryGoalSelector,
} from '@src/selectors/app'
import { User } from '@src/utils'
import { MobileAppFeature } from '@src/types'

const IGNORED_KEYS: string[] = [
  ScoreSectionEnum.DAILY_AVERAGE,
  ScoreSectionEnum.MEALS_AVERAGE,
  MeasurementEnum.BLOOD_PRESSURE,
  MeasurementEnum.SWIMMING_DISTANCE,
]

const keyExtractor = (data: StatsSetting) => data.key + data.section

const DEBOUNCE_SAVE_MS = 2000

const hideGlucoseSection = (data: StatsSetting[]) =>
  data.filter(({ section }) => section !== GLUCOSE_SECTION)

export const InsightsSettings = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const statsSettings = useSelector(statsSettingsStateSelector)
  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)
  const [data, setData] = useState(() =>
    statsSettings
      .map((item) =>
        BLOOD_PRESSURE_KEYS.includes(item.key) ? { ...item, key: 'blood_pressure' } : { ...item },
      )
      .filter(({ key }) => !IGNORED_KEYS.includes(key)),
  )

  const dispatch = useDispatch()

  const saveResults = useDebouncedCallback(() => {
    const settings = data.map((item) => omit(item, '__typename'))
    const compare = statsSettings.map((item) => omit(item, '__typename'))

    if (isEqual(settings, compare)) {
      return
    }
    dispatch({
      type: 'settings/update',
      payload: { statsSettings: settings },
    })
  }, DEBOUNCE_SAVE_MS)

  useEffect(() => saveResults(), [saveResults, data])

  const renderItem = useCallback(({ item, ...rest }: { item: typeof data[0] }) => {
    return (
      <StatToggle
        onChange={() =>
          setData((items) =>
            items.map((el) =>
              keyExtractor(item) === keyExtractor(el) ? { ...el, visible: !el.visible } : el,
            ),
          )
        }
        defaultState={item.visible}
        title={getTitle(item.key, item.section)}
        id={keyExtractor(item)}
        {...rest}
      />
    )
  }, [])

  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)

  const primaryGoal = useSelector(primaryGoalSelector)

  const goalMetricOptions = useSelector(goalMetricOptionsSelector)

  const goalMetrics = useSelector(goalMetricsSelector)

  const primaryGoalMetrics = goalMetrics.filter((metric) => metric.primary)

  const insets = useSafeAreaInsets()

  const onChooseGoalPress = () => {
    navigation.navigate('ChooseGoalModal')
  }

  const onChoosePrimaryGoalMetricsPress = () => {
    navigation.navigate('ChoosePrimaryGoalMetricsModal')
  }

  const selectedPrimaryGoalMetricsValue = primaryGoalMetrics
    .map((metric) => goalMetricOptions.find((option) => option.value === metric.kind)?.text)
    .join(', ')

  const insightsTitle = userGoalsEnabled ? 'Trends' : 'Insights'

  return (
    <NavigationContainer
      title={`${insightsTitle} Settings`}
      style={styles.container}
      showLoadingIndicator
    >
      <FlatList
        data={isCGMFeatureAvailable ? data : hideGlucoseSection(data)}
        contentContainerStyle={{ paddingBottom: insets.bottom }}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        ListHeaderComponent={
          <>
            {userGoalsEnabled && isCGMFeatureAvailable && (
              <>
                <TouchableOpacity
                  accessibilityLabel="Choose your current goal"
                  onPress={onChooseGoalPress}
                  style={styles.inputContainer}
                >
                  <View pointerEvents="none">
                    <Input
                      label="Choose your current goal"
                      caretHidden
                      iconRight="caret-down"
                      placeholder="Choose goal"
                      value={primaryGoal?.description || primaryGoal?.title}
                      showSoftInputOnFocus={false}
                    />
                  </View>
                </TouchableOpacity>
                <TouchableOpacity
                  accessibilityLabel="Choose your primary metrics"
                  onPress={onChoosePrimaryGoalMetricsPress}
                  style={styles.inputContainer}
                >
                  <View pointerEvents="none">
                    <Input
                      label="Choose your primary metrics"
                      caretHidden
                      iconRight="caret-down"
                      placeholder="Choose primary metrics"
                      caption="You can select up to two"
                      value={selectedPrimaryGoalMetricsValue}
                      showSoftInputOnFocus={false}
                    />
                  </View>
                </TouchableOpacity>
              </>
            )}
            <View style={styles.sectionTitleContainer}>
              <Text type="small" bold>
                CUSTOMIZE {insightsTitle.toUpperCase()}
              </Text>
            </View>
          </>
        }
      />
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
  sectionTitleContainer: {
    padding: 16,
  },
  inputContainer: {
    padding: 16,
  },
})
