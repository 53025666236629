import React, { useState } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { Tooltip } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Radio, Text } from '@components/base'
import { Meal } from '@src/types'
import { MealItem } from './MealItem'

interface MealHistoryListItemProps {
  item: Meal
  onMealSelect: (meal: Meal) => void
}

export const MealHistoryListItem = ({ item, onMealSelect }: MealHistoryListItemProps) => {
  const styles = useStyleSheet(themedStyle)

  const [isTooltipVisible, setTooltipVisible] = useState(false)

  const hasNoScore = !item.statistics?.glucoseScore

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      accessibilityLabel={`${item.kind} - ${item.description}`}
      style={styles.item}
      onPress={() => {
        if (hasNoScore) {
          setTooltipVisible((prevTooltipVisible) => !prevTooltipVisible)
        } else {
          onMealSelect(item)
        }
      }}
    >
      <View pointerEvents="none">
        <Radio disabled={hasNoScore} />
      </View>
      <MealItem meal={item} />
      <View style={styles.divider} />
      {hasNoScore && (
        <Tooltip
          anchor={() => <View style={styles.tooltipAnchor} />}
          visible={isTooltipVisible}
          placement="left"
          onBackdropPress={() => setTooltipVisible(false)}
        >
          {() => (
            <View style={styles.tooltipContainer}>
              <Text type="small" bold style={styles.tooltipTitle}>
                Meal Score Unavailable
              </Text>
              <Text type="tiny" style={styles.tooltipDescription}>
                Meal scores requires glucose data during or within 2 hours after meal for accurate
                calculation.
              </Text>
            </View>
          )}
        </Tooltip>
      )}
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    height: 80,
  },
  divider: {
    position: 'absolute',
    left: 76,
    right: 0,
    bottom: 0,
    height: 2,
    backgroundColor: 'theme.surface.base2',
  },
  tooltipContainer: {
    width: 200,
  },
  tooltipTitle: {
    color: 'theme.tooltip.textPrimary',
  },
  tooltipDescription: {
    color: 'theme.tooltip.textSecondary',
  },
  tooltipAnchor: {
    position: 'absolute',
    right: 64,
    width: 0,
    height: 0,
  },
})
