import { gql } from '@apollo/client'

export const GENERATE_TERRA_AUTH_URL = gql`
  query generateTerraAuthUrl($provider: String!) {
    generateTerraAuthUrl(provider: $provider) {
      url
      userId
    }
  }
`
