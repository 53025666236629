import { gql } from '@apollo/client'

export const CORE_SUBSCRIPTION_FIELDS = gql`
  fragment CoreSubscriptionFields on Subscription {
    primaryProduct {
      id
      key
      uncancelable
      title
      details
      requiresShipping
      includesCgm
      interval {
        key
        description
        suffix
      }
      eligibleUpgradeProducts {
        id
      }
      incompatibleAddons {
        id
      }
    }
    primaryPlan {
      stripeId
      amount
      product {
        id
      }
    }
    price
    id
    latestInvoice {
      id
    }
    stripeId
    subscribedAt
    startAt
    cancelAt
    paused
    commitmentEndAt
    currentPeriodEndAt
    resetBillingCycleAnchorAt
    trialEndAt
    resumesAt
    allowedPauseCycles
    status
    checkoutOptions {
      kind
    }
  }
`
