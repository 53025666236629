import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { InsuranceBerryStreetAppointmentType } from '@src/types.ts'
import { clientConfigStoreStateSelector } from '@selectors/app.ts'

export const useVideoCallDurations = () => {
  const dispatch = useDispatch()
  const clientConfig = useSelector(clientConfigStoreStateSelector)

  useEffect(() => {
    dispatch({ type: 'app/config' })
  }, [dispatch])

  return {
    getVideoCallDuration: (type: InsuranceBerryStreetAppointmentType | undefined | null) => {
      if (!type) {
        return 0
      }

      return (
        clientConfig.appointmentTypes.find((appointmentType) => appointmentType.key === type)
          ?.duration || 0
      )
    },
  }
}
