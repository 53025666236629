import React from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { useNavigation } from '@react-navigation/core'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Input, Text } from '@components/base'
import { ScrollableAvoidKeyboard } from '@components'
import { PasswordResetTopNavigation } from '@screens/PasswordReset'
import { Validators } from '@utils'
import { useSnack } from '@utils/navigatorContext'

export const PasswordResetRequest = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const dispatch = useDispatch()
  const showSnack = useSnack()

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      email: '',
    },
  })

  const onSubmitPress = handleSubmit(({ email }) => {
    dispatch({
      type: 'users/resetPasswordRequest',
      payload: { email },
      success: () => navigation.navigate('PasswordResetRequestSuccess', { email }),
      failure: () => showSnack("Error! Email wasn't sent.", null, 'error'),
    })
  })

  return (
    <ScrollableAvoidKeyboard keyboardShouldPersistTaps="handled">
      <SafeAreaView edges={['top', 'bottom']} style={styles.container}>
        <PasswordResetTopNavigation title="Recover Password" />
        <View style={styles.content}>
          <Text type="title-2" style={styles.headerTitle}>
            Don't worry
          </Text>
          <Text type="regular">It happens to the best of us.</Text>
          <Text type="regular">Please type your email so we can send you the recovery link.</Text>
          <Controller
            control={control}
            name="email"
            rules={{ required: true, validate: Validators.EmailValidator }}
            render={({ field }) => (
              <Input
                {...field}
                accessibilityLabel="Email"
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="email-address"
                hasError={!!errors.email}
                label="Email"
                placeholder="Enter your email address"
                returnKeyType="done"
                style={styles.input}
                testID="SigninScreen/Email"
                onSubmitEditing={onSubmitPress}
              />
            )}
          />
        </View>
        <Button
          allowPressWhenDisabled
          accessibilityLabel="Submit"
          disabled={!isValid}
          size="block"
          style={styles.button}
          type="primary"
          onPress={onSubmitPress}
        >
          Send Recovery Link
        </Button>
      </SafeAreaView>
    </ScrollableAvoidKeyboard>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 16,
  },
  headerTitle: {
    marginVertical: 16,
  },
  input: {
    marginTop: 24,
  },
  button: {
    margin: 16,
  },
})
