import EventEmitter from 'events'
import { useState, useEffect } from 'react'

const PERSISTED_KEYS = ['admin_login', 'endpoint', 'WALKTHROUGH_SHOWN']

// since sessionStorage is cleared when a tab/window is closed as opposed to localStorage which is persisted,
// we're using sessionStorage for most of the keys when in presentationMode to avoid
// a situation where the user sees the data from another presentation when we open a new window/tab
// but we keep admin_login in localStorage as it can be shared between different presentations and thus
// does not force the admin to log in again

// @ts-ignore-next-line
const localStorage = window.localStorage
// @ts-ignore-next-line
const sessionStorage = window.sessionStorage

const decideStorageType = (key: string) =>
  // @ts-ignore-next-line
  !window.isPresentationMode || PERSISTED_KEYS.includes(key) ? localStorage : sessionStorage

const eventEmitter = new EventEmitter()
export function get<T>(key: string): T | null
export function get<T>(key: string, defaultValue: T): T
// eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
export function get<T>(key: string, defaultValue?: T) {
  const storage = decideStorageType(key)
  const value = storage.getItem(key)

  if (value) {
    return JSON.parse(value) as T
  }

  return defaultValue ?? null
}

export const set = (key: string, value: any) => {
  const storage = decideStorageType(key)
  storage.setItem(key, JSON.stringify(value))
  eventEmitter.emit('change', key)
}

export const remove = (key: string) => {
  const storage = decideStorageType(key)

  storage.removeItem(key)
}

export const clear = () => {
  localStorage.clear()
  sessionStorage.clear()
}

export const useStorageValue = <T>(key: string) => {
  const [value, setValue] = useState(get<T>(key))

  useEffect(() => {
    const listener = () => {
      setValue(get<T>(key))
    }

    eventEmitter.on('change', listener)

    return () => {
      eventEmitter.off('change', listener)
    }
  }, [key])

  return [value, set] as const
}
