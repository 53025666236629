import { Model } from '@models'
import commonReducers from '@src/models/commonReducers'
import {
  ALL_INGREDIENTS,
  CREATE_INGREDIENT,
  DELETE_INGREDIENT,
} from '@src/screens/Ingredients/graphql'
import { transform, transformPayload } from '@src/screens/Ingredients/transforms'
import { Ingredient } from '@src/types'
import { Caching } from '@src/utils'

export default class Ingredients {
  namespace = 'ingredients'

  state = {
    ...Model.defaultState,
    ingredients: [],
    order: [],
    sort: {
      order: 'ascending',
      orderBy: 'occurredAt',
    },
  }

  effects = {
    fetch: Model.buildEffect({
      name: `${this.namespace}/fetch`,
      query: ALL_INGREDIENTS,
      dataPath: 'allCustomIngredients',
      caching: true,
      variables: () => {
        const page = 1
        const pageSize = 100

        return {
          pagination: { page, pageSize },
          filter: {},
        }
      },
      cacheKey: (variables) => Caching.listFilterCacheKey('ingredients/fetch', variables),
      reducers: [{ name: 'fetchList' }, { name: 'setInitialOrder' }],
    }),

    submit: Model.buildEffect({
      name: `${this.namespace}/submit`,
      query: CREATE_INGREDIENT,
      dataPath: 'createCustomIngredient',
      variables: (payload: Ingredient) => {
        return transformPayload(payload)
      },
      reducers: [
        { name: 'appendOrReplaceList', transform },
        {
          name: 'updateOrderByItem',
          unless: (_: any, { effectPayload }: any) => effectPayload.id,
        },
      ],
      errorReducers: [{ name: 'restore' }],
      cacheReducers: [{ name: 'cacheClear', payload: { matchName: 'ingredients/fetch' } }],
    }),

    delete: Model.buildEffect({
      name: `${this.namespace}/delete`,
      optimistic: true,
      query: DELETE_INGREDIENT,
      dataPath: 'deleteCustomIngredient',
      variables: (payload: Ingredient) => ({ id: payload.id }),
      optimisticReducers: [{ name: 'backup' }, { name: 'deleteList' }],
      errorReducers: [{ name: 'restore' }],
      cacheReducers: [{ name: 'cacheClear', payload: { matchName: 'ingredients/fetch' } }],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    fetchList: commonReducers.fetchList,
    appendOrReplaceList: commonReducers.appendOrReplaceList,
    deleteList: commonReducers.deleteList,
    backup: commonReducers.backup,
    restore: commonReducers.restore,
    setInitialOrder: commonReducers.setInitialOrder,
    updateOrder: commonReducers.updateOrder,
    updateOrderByItem: commonReducers.updateOrderByItem,
  }
}
