import moment from 'moment'
import { orderBy } from 'lodash'
import { Model } from '@models'
import { ALL_NOTES } from '@src/screens/NutritionistHub/graphql/allNotest'
import { memberNotesUpdatedAtTimestampSelector } from '@src/screens/NutritionistHub/models/nutritionistHub.selectors'
import { Storage } from '@src/utils'
import {
  AppointmentDynamicFilterField,
  AppointmentSortField,
  DynamicFilterItemOperation,
  DynamicFilterOperator,
  NoteCollection,
  SortDirection,
} from '@src/types'
import { FETCH_VIDEO_CALL_INFO } from '../graphql/fetchVideoCallInfo'
import { FETCH_AVAILABLE_DAYS } from '../graphql/fetchAvailableDays'
import { FETCH_AVAILABLE_SLOTS } from '../graphql/fetchAvailableSlots'
import { BOOK_VIDEO_CALL } from '../graphql/bookVideoCall'
import { CANCEL_VIDEO_CALL } from '../graphql/cancelVideoCall'
import { RESCHEDULE_VIDEO_CALL } from '../graphql/rescheduleVideoCall'
import { FETCH_INSURANCE_POLICY } from '../graphql/fetchInsurancePolicy'
import SUBMIT_INSURANCE_POLICY from '../graphql/submitInsurancePolicy'
import { FETCH_COACH_ASSIGNMENTS } from '../graphql/fetchCoachAssignments'
import CLAIM_FREE_VIDEO_CALL from '../graphql/claimFreeVideoCall'
import { ALL_APPOINTMENTS } from '../graphql/allAppointments'
import reducers from './nutritionistHub.reducers'

export default class NutritionistHub {
  namespace = 'nutritionistHub'

  state = {
    ...Model.defaultState,
    latestUserAppointment: null,
    insurancePolicy: null,
    coachAssignment: null,
    allMemberNotes: [],
    memberNotesUpdatedAt: false,
    upcomingAppointments: [],
    pastAppointments: [],
  }

  effects = {
    fetchVideoCallInfo: Model.buildEffect({
      name: `${this.namespace}/fetchVideoCallInfo`,
      query: FETCH_VIDEO_CALL_INFO,
      caching: false,
      dataPath: 'latestUserAppointment',
      reducers: [{ name: 'updateLastUserAppointment' }],
    }),
    fetchAvailableDays: Model.buildEffect({
      name: `${this.namespace}/fetchAvailableDays`,
      query: FETCH_AVAILABLE_DAYS,
      caching: false,
      dataPath: 'insuranceSchedulingAvailableDates',
    }),
    fetchAvailableSlots: Model.buildEffect({
      name: `${this.namespace}/fetchAvailableSlots`,
      query: FETCH_AVAILABLE_SLOTS,
      caching: false,
      dataPath: 'insuranceSchedulingAvailableSlots',
    }),
    bookVideoCall: Model.buildEffect({
      name: `${this.namespace}/bookVideoCall`,
      query: BOOK_VIDEO_CALL,
      caching: false,
      dataPath: 'bookVideoCall',
      reducers: [{ name: 'updateLastUserAppointment' }],
    }),
    cancelVideoCall: Model.buildEffect({
      name: `${this.namespace}/cancelVideoCall`,
      query: CANCEL_VIDEO_CALL,
      caching: false,
      dataPath: 'cancelVideoCall',
    }),
    rescheduleVideoCall: Model.buildEffect({
      name: `${this.namespace}/rescheduleVideoCall`,
      query: RESCHEDULE_VIDEO_CALL,
      caching: false,
      dataPath: 'rescheduleVideoCall',
    }),
    fetchInsurancePolicy: Model.buildEffect({
      name: `${this.namespace}/fetchInsurancePolicy`,
      query: FETCH_INSURANCE_POLICY,
      caching: false,
      warnings: false, // disable warnings as response is empty
      dataPath: 'insurancePolicy',
      reducers: [{ name: 'updateInsurancePolicy' }],
    }),
    submitInsurancePolicy: Model.buildEffect({
      name: `${this.namespace}/submitInsurancePolicy`,
      query: SUBMIT_INSURANCE_POLICY,
      caching: false,
      dataPath: 'submitInsurancePolicy',
      reducers: [{ name: 'updateInsurancePolicy' }],
    }),
    fetchCoachAssignments: Model.buildEffect({
      name: `${this.namespace}/fetchCoachAssignments`,
      query: FETCH_COACH_ASSIGNMENTS,
      caching: false,
      dataPath: 'coachAssignments',
      reducers: [{ name: 'updateTopPriorityCoachAssignment' }],
    }),
    claimFreeVideoCall: Model.buildEffect({
      name: `${this.namespace}/claimFreeVideoCall`,
      query: CLAIM_FREE_VIDEO_CALL,
      caching: false,
      dataPath: 'claimFreeVideoCall',
      reducers: [{ name: 'updateLastUserAppointment' }],
    }),
    fetchMemberNotes: Model.buildEffect({
      name: `${this.namespace}/fetchMemberNotes`,
      query: ALL_NOTES,
      caching: false,
      dataPath: 'allNotes',
      reducers: [
        { name: 'updatedMemberNotes' },
        {
          name: 'updateMemberNotesUpdatedAtTimestamp',
          *payload(
            response: NoteCollection,
            { select }: any,
          ): Generator<NoteCollection, string, string> {
            const nextUpdatedAtTimestamp = orderBy(response.notes, 'updatedAt', 'desc')[0]
              ?.updatedAt
            const lastUpdatedAtTimestamp = yield select(memberNotesUpdatedAtTimestampSelector)

            if (!lastUpdatedAtTimestamp) {
              return nextUpdatedAtTimestamp || moment().toISOString()
            }

            const hasChanged =
              nextUpdatedAtTimestamp &&
              moment(nextUpdatedAtTimestamp).isAfter(moment(lastUpdatedAtTimestamp))

            if (hasChanged) {
              Storage.set(Storage.NUTRITIONIST_HUB_RED_DOT, true)
              return nextUpdatedAtTimestamp
            }

            return lastUpdatedAtTimestamp
          },
        },
      ],
    }),
    fetchUpcomingAppointments: Model.buildEffect({
      name: `${this.namespace}/fetchUpcomingAppointments`,
      query: ALL_APPOINTMENTS,
      caching: false,
      dataPath: 'allAppointments',
      variables: {
        sorts: [
          {
            direction: SortDirection.Asc,
            field: AppointmentSortField.MeetingAt,
          },
        ],
        dynamicFilters: {
          items: [
            {
              field: AppointmentDynamicFilterField.MeetingStatus,
              operation: DynamicFilterItemOperation.IsNull,
              value: 'true',
            },
            {
              field: AppointmentDynamicFilterField.MeetingAt,
              operation: DynamicFilterItemOperation.Gt,
              value: new Date(),
            },
          ],
          operator: DynamicFilterOperator.And,
        },
      },
      reducers: [{ name: 'updateUpcomingAppointments' }],
    }),
    fetchPastAppointments: Model.buildEffect({
      name: `${this.namespace}/fetchPastAppointments`,
      query: ALL_APPOINTMENTS,
      caching: false,
      dataPath: 'allAppointments',
      variables: {
        sorts: [
          {
            direction: SortDirection.Desc,
            field: AppointmentSortField.MeetingAt,
          },
        ],
        dynamicFilters: {
          items: [
            {
              field: AppointmentDynamicFilterField.MeetingStatus,
              operation: DynamicFilterItemOperation.NotNull,
              value: 'true',
            },
            {
              field: AppointmentDynamicFilterField.MeetingAt,
              operation: DynamicFilterItemOperation.Lt,
              value: new Date(),
            },
          ],
          operator: DynamicFilterOperator.And,
        },
      },
      reducers: [{ name: 'updatePastAppointments' }],
    }),
  }

  reducers = reducers
}
