import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { SensorTypes } from 'react-native-freestyle-libre'
import { sensorActivationTimeSelector, sensorSelector } from '@src/selectors/sensor'
import { Feature, useFeatureFlag } from '@src/components'

export const useSecondsAfterSensorActivation = ({
  updateCondition,
}: {
  updateCondition: (params: {
    secondsAfterSensorActivation: number
    sensorModel?: SensorTypes
    liveDataEnabled: boolean
  }) => boolean
}) => {
  const sensorActivationTime = useSelector(sensorActivationTimeSelector)
  const sensor = useSelector(sensorSelector)
  const sensorModel = sensor?.model as SensorTypes | undefined
  const liveDataEnabled = useFeatureFlag(Feature.Libre3RealTime)

  const [secondsAfterActivation, setSecondsAfterActivation] = useState(() =>
    sensorActivationTime ? moment().diff(sensorActivationTime, 'seconds') : undefined,
  )

  useEffect(() => {
    if (!sensorActivationTime) {
      return
    }

    const secondsAfterSensorActivation = moment().diff(sensorActivationTime, 'seconds')

    if (!updateCondition({ secondsAfterSensorActivation, sensorModel, liveDataEnabled })) {
      setSecondsAfterActivation(secondsAfterActivation)
      return
    }

    const interval = setInterval(() => {
      const newSecondsAfterSensorActivation = moment().diff(sensorActivationTime, 'seconds')
      setSecondsAfterActivation(newSecondsAfterSensorActivation)

      if (
        !updateCondition({
          secondsAfterSensorActivation: newSecondsAfterSensorActivation,
          sensorModel,
          liveDataEnabled,
        })
      ) {
        clearInterval(interval)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [liveDataEnabled, secondsAfterActivation, sensorActivationTime, sensorModel, updateCondition])

  return secondsAfterActivation
}
