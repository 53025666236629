import { CaptureOptions, captureRef } from 'react-native-view-shot'

const captureOptions: CaptureOptions = {
  format: 'jpg',
  quality: 0.8,
}

const captureView = (
  ref: number | React.ReactInstance | React.RefObject<unknown>,
  onCapture: (result: { uri: string | null }) => void,
) => {
  captureRef(ref, captureOptions).then(
    (uri) => onCapture({ uri: !uri.startsWith('file://') ? `file://${uri}` : uri }),
    () => onCapture({ uri: null }),
  )
}

export default { captureView }
