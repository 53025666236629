import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { FastImageProps } from 'react-native-fast-image'
import { useTheme } from '@ui-kitten/components'
import { Text } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ImageWithFullscreen } from '@src/components/Image/FastImageWithPlaceholder'
import {
  ImageRequestActionSheetOption,
  useImageRequestActionSheet,
} from '@src/hooks/useImageRequestActionSheet'
import { CircleIcon, Feature, useFeatureFlag } from '@src/components'
import { IconButton } from '@src/components/base'
import { useUserInitials } from '@src/utils/hooks'
import { useIsDarkMode } from '@src/config/theme'
import { ReactNativeFile } from '@src/utils/image'

interface ProfilePhotoProps extends Omit<FastImageProps, 'style'> {
  style?: StyleProp<ViewStyle>
  source: { uri?: string }
  onPhotoChange?: (photo: ReactNativeFile) => void
}

export const ProfilePhoto = ({
  style,
  source,
  onPhotoChange = () => null,
  ...fastImageProps
}: ProfilePhotoProps) => {
  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()
  const isDarkMode = useIsDarkMode()
  const initialsAvatarBackgroundColor = isDarkMode
    ? theme['theme.accent.brick']
    : theme['theme.accent.citron']

  const { showImageRequestActionSheet } = useImageRequestActionSheet(onPhotoChange)
  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)
  const initials = useUserInitials()

  const onShowImageRequest = () =>
    showImageRequestActionSheet({
      options: [
        ImageRequestActionSheetOption.ChoseFromLibrary,
        ImageRequestActionSheetOption.TakePhoto,
      ],
    })

  const size = ((style as ViewStyle)?.height ?? styles.circle.height) as number
  const sizeStyles = { width: size, height: size, borderRadius: size / 2 }

  const placeholderIcon =
    appNavigationUpdatesFeatureEnabled && initials ? (
      <View
        style={[
          styles.initialsAvatarContainer,
          {
            backgroundColor: initialsAvatarBackgroundColor,
          },
        ]}
      >
        <Text type="title-1" style={styles.initials}>
          {initials}
        </Text>
      </View>
    ) : (
      <CircleIcon
        name="user"
        style={[sizeStyles, styles.circleIcon]}
        iconStyle={{ width: size / 2, height: size / 2 }}
      />
    )

  return (
    <View style={[styles.container, style, sizeStyles]}>
      {source.uri ? (
        <ImageWithFullscreen
          resizeMode="cover"
          source={source}
          style={sizeStyles}
          placeholderComponent={placeholderIcon}
          {...fastImageProps}
        />
      ) : (
        placeholderIcon
      )}
      <IconButton
        type="primary"
        size="xs"
        iconName="plus"
        style={styles.uploadPhoto}
        onPress={onShowImageRequest}
        accessibilityLabel="Upload photo"
      />
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'theme.background',
  },
  initialsAvatarContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
    borderRadius: 100,
  },
  initials: {
    color: 'theme.primary.dark',
  },
  circle: {
    height: 80,
  },
  circleIcon: {
    marginRight: 0,
  },
  uploadPhoto: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
})
