import { createElement, Component } from 'react'
import { StyleSheet } from 'react-native'

let source = require('./f2chart.html').default

source = source.replace('ReactNativeWebView', 'parent')

const injectJavaScript = (html, injectedJavaScript) => {
  return html.replace('</body>', `<script>${injectedJavaScript}</script></body>`)
}

// It was initially copied from
// https://github.com/react-native-web-community/react-native-web-webview
export class WebView extends Component {
  constructor(props) {
    super(props)

    const iframeId = Math.random().toString()
    this.state = {
      iframeId,
      html: injectJavaScript(source, `window.iframeId = '${iframeId}'; window.chart = null;`),
    }
  }

  setRef = (ref) => {
    this.frameRef = ref
  }

  componentDidMount() {
    if (this.props.onMessage) {
      window.addEventListener('message', this.onMessage, true)
    }
  }

  componentWillUnmount() {
    if (this.props.onMessage) {
      window.removeEventListener('message', this.onMessage, true)
    }
  }

  reload = () => {
    this.frameRef.contentWindow.location.reload()
  }

  injectJavaScript = (script) => {
    const document = this.frameRef.contentWindow.document
    const scriptElement = document.createElement('script')
    scriptElement.textContent = script
    document.body.appendChild(scriptElement)
  }

  onMessage = (nativeEvent) => {
    const { iframeId } = this.state

    if (nativeEvent.source.iframeId !== iframeId) {
      // ignore message not from our iframe
      return
    }

    this.props.onMessage({ nativeEvent })
  }

  handleInjectedJavaScript = () => {
    const { injectedJavaScript } = this.props
    const { html } = this.state

    let result = html

    if (injectedJavaScript) {
      result = injectJavaScript(
        result,
        injectedJavaScript.replaceAll('ReactNativeWebView', 'parent'),
      )
    }

    return result
  }

  render() {
    const { onLoadEnd, style } = this.props
    const { html } = this.state
    const styleObj = StyleSheet.flatten(style)

    return createElement('iframe', {
      ref: this.setRef,
      src: html,
      srcDoc: this.handleInjectedJavaScript(),
      width: styleObj && styleObj.width,
      height: styleObj && styleObj.height,
      style: StyleSheet.flatten([styles.iframe, style]),
      allowFullScreen: true,
      allowpaymentrequest: 'true',
      frameBorder: '0',
      scrolling: 'no',
      onLoad: onLoadEnd,
    })
  }
}

export default WebView

const styles = StyleSheet.create({
  iframe: {
    width: '100%',
    height: '100%',
    borderWidth: 0,
    overflow: 'hidden',
  },
})
