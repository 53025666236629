import { gql } from '@apollo/client'

export const TOGGLE_PROVIDER_DATA_TYPE = gql`
  mutation toggleProviderDataType($provider: String!, $dataType: String!) {
    toggleProviderDataType(provider: $provider, dataType: $dataType) {
      providers {
        provider
        active
        connectionId
        name
        status
        errorReason
        availableTypes
        disabledTypes
      }
    }
  }
`
