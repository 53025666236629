import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useTheme } from '@ui-kitten/components'
import { terraProvidersSelector } from '@src/selectors/integrations'
import { Device } from '@src/config'
import { TERRA_APPLE_HEALTH, TERRA_GOOGLE_FIT } from '@src/screens/Settings/models/settings.types'
import { useStorageValue } from '@src/utils/storage'
import { Storage } from '@src/utils'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppleHealth, GoogleFit } from '@src/assets/svgs'
import { Icon, Text } from '@src/components/base'
import { useIsDarkMode } from '@src/config/theme'

const TERRA_PROVIDER = Device.ios ? TERRA_APPLE_HEALTH : TERRA_GOOGLE_FIT

export const HealthProviderBanner = () => {
  const dispatch = useDispatch()

  const styles = useStyleSheet(themedStyles)

  const navigation = useNavigation()

  const theme = useTheme()

  const isDarkMode = useIsDarkMode()

  useEffect(() => {
    dispatch({
      type: 'settings/fetchTerraProviders',
    })
  }, [dispatch])

  const [alertClosed, setAlertClosed] = useStorageValue<boolean>(
    Storage.CONNECT_HEALTH_PROVIDER_ALERT_CLOSED_KEY,
  )

  const terraProviders = useSelector(terraProvidersSelector)

  if (alertClosed) {
    return null
  }

  const healthProvider = terraProviders.find((provider) => provider.name === TERRA_PROVIDER)

  if (!healthProvider || healthProvider.active) {
    return null
  }

  const onClose = () => {
    setAlertClosed(true)
  }

  const onPress = () => {
    navigation.navigate('TerraProvider', { provider: healthProvider.provider })
  }

  return (
    <TouchableOpacity
      accessibilityLabel={`Integrate ${TERRA_PROVIDER}`}
      style={[
        styles.container,
        // add 20% opacity to background color in dark mode for better contrast
        isDarkMode ? { backgroundColor: `${styles.container.backgroundColor?.toString()}33` } : {},
      ]}
      onPress={onPress}
    >
      <View style={styles.icon}>{Device.ios ? <AppleHealth /> : <GoogleFit />}</View>
      <View style={styles.content}>
        <Text type="regular" bold>
          {TERRA_PROVIDER}
          <Icon name="caret-right" style={styles.arrowRightIcon} />
        </Text>
        <Text type="small">
          You can integrate with {TERRA_PROVIDER} to get your weight data automatically.
        </Text>
      </View>
      <TouchableOpacity
        hitSlop={{ bottom: 12, top: 12, left: 12, right: 12 }}
        accessibilityLabel="Dismiss"
        onPress={onClose}
      >
        <Icon
          name="x"
          size={16}
          weight="bold"
          style={
            {
              color: isDarkMode
                ? // add 60% opacity to color in dark mode
                  `${styles.container.backgroundColor?.toString()}99`
                : theme['theme.warning.darkest'],
            } as StyleProp<ViewStyle>
          }
        />
      </TouchableOpacity>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    backgroundColor: 'theme.warning.lightest',
    paddingHorizontal: 16,
    paddingVertical: 12,
    marginBottom: 16,
  },
  arrowRightIcon: {
    width: 16,
    height: 16,
    marginLeft: 4,
  },
  icon: {
    width: 60,
    height: 60,
  },
  content: {
    flex: 1,
    marginLeft: 16,
    marginRight: 4,
  },
  closeIcon: {
    color: 'theme.warning.darkest',
  },
})
