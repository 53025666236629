import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation, useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { AuthRouteProp } from '@navigation/types'
import { PasswordResetTopNavigation } from '@screens/PasswordReset'

export const PasswordResetRequestSuccess = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const { params } = useRoute<AuthRouteProp<'PasswordResetRequestSuccess'>>()

  const onArrowBackClick = () => navigation.navigate('SignIn')

  const onResendEmail = () => navigation.navigate('PasswordResetRequest')

  return (
    <SafeAreaView style={styles.container}>
      <PasswordResetTopNavigation title="Recover password" onArrowBackClick={onArrowBackClick} />
      <View style={styles.content}>
        <Text type="title-2" style={styles.headerTitle}>
          We've sent you an email!
        </Text>
        <View style={styles.confirmationText}>
          <Text type="regular">An email has been sent to the following address:</Text>
          <Text type="regular" bold>
            {params?.email ?? ''}
          </Text>
        </View>
        <Text type="regular">
          Please follow instructions in the email to recover your password.
        </Text>
      </View>
      <Button
        allowPressWhenDisabled
        accessibilityLabel="Resend Email"
        size="block"
        style={styles.button}
        type="primary"
        onPress={onResendEmail}
      >
        Resend Email
      </Button>
    </SafeAreaView>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    width: '100%',
    maxWidth: 600,
    paddingHorizontal: 16,
  },
  headerTitle: {
    marginVertical: 16,
  },
  confirmationText: {
    marginBottom: 24,
  },
  button: {
    margin: 16,
  },
})
