import React from 'react'
import { useRoute } from '@react-navigation/core'
import { RouteProp } from '@react-navigation/native'
import { TouchableOpacity } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { List } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { NavigationContainer } from '@screens/Common/containers'
import { AppStackParamList } from '@navigation/types'
import { EducationArticle } from '@src/types'
import { openUrl } from '@utils'
import { useFeatureFlag, Feature } from '@src/components'

export const Articles = () => {
  const styles = useStyleSheet(themedStyles)
  const insets = useSafeAreaInsets()
  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)

  const routeName = appNavigationUpdatesFeatureEnabled ? 'FAQsArticles' : 'Articles'
  const { params } = useRoute<RouteProp<AppStackParamList, typeof routeName>>()
  const { articles } = params

  const renderItem = ({ item }: { item: EducationArticle }) => (
    <TouchableOpacity
      accessibilityLabel={item.title}
      style={styles.cell}
      onPress={() => openUrl(item.url)}
    >
      <Text type="regular">{item.title}</Text>
      {!!item.author && (
        <Text type="small" style={styles.author}>
          {item.author}
        </Text>
      )}
    </TouchableOpacity>
  )

  return (
    <NavigationContainer title="Frequently Asked Questions">
      <List
        data={articles}
        keyExtractor={(item) => item.url}
        renderItem={renderItem}
        showsVerticalScrollIndicator={false}
        style={styles.container}
        contentContainerStyle={{ paddingBottom: (insets?.bottom ?? 0) + 16 }}
      />
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingTop: 16,
    paddingHorizontal: 16,
    backgroundColor: 'theme.background',
  },
  cell: {
    justifyContent: 'space-between',
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 16,
    marginBottom: 16,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base2',
  },
  author: {
    marginTop: 4,
    color: 'theme.text.secondary',
  },
})
