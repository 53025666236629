import React from 'react'
import { Image, ImageStyle, ScrollView, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { AppStackParamList } from '@src/navigation/types'
import { InlineAlert } from '@src/components'
import { UiInlineAlert } from '@src/types'
import { calendarHeartImage } from '@src/assets/images'

export const LabResultsSubmittedScreen = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const onFinish = () => {
    navigation.replace('Drawer', { screen: 'Dashboard' })
  }

  const onSeeLabResultsPressed = () => {
    navigation.navigate('LabResultsScreen')
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.containerContent}>
      <View style={styles.content}>
        <Image
          style={styles.calendarHeartImage as ImageStyle}
          source={calendarHeartImage.imageSource}
        />
        <Text type="title-2" style={styles.titleText}>
          Lab results saved successfully
        </Text>
        <Text type="large" style={styles.informationText}>
          Thanks! Your lab results will help your nutritionist guide you in your health journey.
        </Text>
        <Text type="large" style={styles.informationText}>
          We’ll remind you in 3 months to update your lab results. You can always modify your
          notifications from the Settings screen.
        </Text>
      </View>
      <View style={styles.footer}>
        <InlineAlert
          category={UiInlineAlert.Info}
          message="You can now access your lab results from your Account screen"
          style={styles.infoContainer}
        />
        <View style={styles.buttons}>
          <Button
            size="block"
            accessibilityLabel="See Lab Results"
            type="primary"
            onPress={onSeeLabResultsPressed}
          >
            See Lab Results
          </Button>

          <Button size="block" accessibilityLabel="Done" type="outline" onPress={onFinish}>
            Done
          </Button>
        </View>
      </View>
    </ScrollView>
  )
}

const themedStyles = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
    paddingHorizontal: 20,
    paddingTop: 60,
    paddingBottom: 24,
  },
  containerContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  content: {
    flex: 2,
  },
  calendarHeartImage: {
    alignSelf: 'center',
    marginVertical: 24,
  },
  titleText: {
    textAlign: 'center',
    marginBottom: 24,
  },
  informationText: {
    textAlign: 'center',
    marginTop: 24,
  },
  infoContainer: {
    flex: 1,
  },
  footer: {
    flex: 1,
    justifyContent: 'flex-end',
    gap: 24,
  },
  buttons: {
    flex: 3,
    gap: 16,
  },
})
