import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName } from '@components/base'

interface SectionItemProps {
  children: React.ReactNode
  hasBottomBorder?: boolean
  icon?: IconName
  rightAccessory?: React.ReactNode
  onPress?: () => void
}

export const SectionItem = ({
  icon,
  children,
  hasBottomBorder,
  rightAccessory,
  onPress,
}: SectionItemProps) => {
  const styles = useStyleSheet(themedStyles)

  const staticContent = (
    <View style={[styles.container, hasBottomBorder && styles.borderBottom]}>
      {icon && <Icon name={icon} style={styles.icon} />}
      {children}
      {rightAccessory}
      {onPress && <Icon name="caret-right" style={styles.arrowRightIcon} />}
    </View>
  )

  return onPress ? (
    <TouchableOpacity accessibilityLabel="Update Settings" onPress={onPress}>
      {staticContent}
    </TouchableOpacity>
  ) : (
    staticContent
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 64,
    marginHorizontal: 16,
    paddingVertical: 12,
  },
  borderBottom: {
    borderBottomWidth: 2,
    borderBottomColor: 'theme.surface.base2',
  },
  icon: {
    marginRight: 16,
  },
  arrowRightIcon: {
    width: 16,
    height: 16,
    marginLeft: 16,
    color: 'theme.surface.base0',
  },
})
