import React, { useState } from 'react'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { FlatList, View } from 'react-native'
import { DateData } from 'react-native-calendars'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Rect } from 'react-content-loader/native'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { appointmentTypesSelector, authenticatedUserSelector } from '@src/selectors/app'
import { Appointment, Slot, UiInlineAlert } from '@src/types'
import { ContentLoader } from '@src/components/ContentLoader'
import { InlineAlert } from '@src/components'
import { Button } from '@components/base'
import { StyleService, useStyleSheet } from '@src/style/service'
import { AppStackParamList, RootStackParamList } from '@src/navigation/types'
import { hasValue, useGoBack, useSnack } from '@src/utils'
import { CustomEventTypes, ErrorMessages } from '@src/config'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useAvailableDates, useAvailableSlots, useNutritionistHubTracker } from '../hooks'
import { SchedulingCalendar } from '../components/SchedulingCalendar'

const DEFAULT_TITLE = 'Video Call'

export const CallScheduling = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()

  const route = useRoute<RouteProp<RootStackParamList, 'CallScheduling'>>()
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const showSnack = useSnack()
  const allowBackNavigation = route.params?.allowBackNavigation

  const { appointmentId: rescheduleAppointmentId, appointmentType, pendingAppointmentId } =
    route.params || {}

  const appointmentTypes = useSelector(appointmentTypesSelector)
  const appointmentTypeConfig = appointmentTypes.find((config) => config.key === appointmentType)
  const title = rescheduleAppointmentId
    ? 'Reschedule Appointment'
    : `Schedule ${appointmentTypeConfig?.title || DEFAULT_TITLE}`

  const {
    availableDates,
    selectedDate,
    loading: loadingDates,
    setSelectedDate,
    error: loadingDatesError,
  } = useAvailableDates(appointmentType, rescheduleAppointmentId)

  const { availableSlots, loading: fetchingSlots } = useAvailableSlots(
    appointmentType,
    selectedDate,
  )

  const user = useSelector(authenticatedUserSelector)

  const track = useNutritionistHubTracker()

  const insets = useSafeAreaInsets()

  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null)
  const onDayPress = (day: DateData) => {
    setSelectedDate(day.dateString)
  }

  const confirmSlot = () => {
    rescheduleAppointmentId ? rescheduleCall() : scheduleCall()
  }

  const scheduleCall = () => {
    if (!selectedSlot) {
      return
    }

    dispatch({
      type: 'nutritionistHub/bookVideoCall',
      payload: {
        appointmentType,
        pendingAppointmentId,
        timeZone: user.timeZone,
        date: selectedSlot.time,
        coachId: selectedSlot.coachId,
      },
      success: (response: Appointment) => {
        track(CustomEventTypes.NutritionistHubAppointmentBooked)

        if (hasValue(response, 'date')) {
          navigation.replace('BookingConfirmationScreen', { date: response.date })
        }
      },
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        setSelectedSlot(null)
        showSnack(errorMessage, null, 'error')
      },
    })
  }

  const rescheduleCall = () => {
    if (!selectedSlot) {
      return
    }

    dispatch({
      type: 'nutritionistHub/rescheduleVideoCall',
      payload: {
        date: selectedSlot.time,
        id: rescheduleAppointmentId,
      },
      success: () => {
        navigation.replace('Drawer', { screen: 'Dashboard', params: { screen: 'NutritionistHub' } })
      },
      failure: (error: any) => {
        const errorMessage = error?.message || ErrorMessages.ServerError
        setSelectedSlot(null)
        showSnack(errorMessage, null, 'error')
      },
    })
  }

  if (loadingDates || !availableDates) {
    return (
      <LoadingContainer
        loadingMessage="Loading calendar"
        errorMessage={"Can't load available dates"}
        error={loadingDatesError}
      >
        <Button type="primary" size="block" accessibilityLabel="Go Back" onPress={goBack}>
          Go Back
        </Button>
      </LoadingContainer>
    )
  }

  const renderSlotItem = ({ item: slot }: { item: Slot }) => {
    return (
      <View style={styles.slotItemContainer}>
        <Button
          style={styles.slotButton}
          type="outline"
          onPress={() => {
            setSelectedSlot({ ...slot })
          }}
          accessibilityLabel=""
          size="block"
        >
          {moment(slot.time).format('hh:mm A')}
        </Button>
        {slot.time === selectedSlot?.time && (
          <Button
            style={styles.slotButton}
            type="primary"
            onPress={confirmSlot}
            accessibilityLabel=""
            size="block"
          >
            Confirm
          </Button>
        )}
      </View>
    )
  }

  const renderLoadingItem = () => (
    <View style={styles.loadingSlot}>
      <ContentLoader width="100%" height="100%">
        <Rect x="0" y="0" width="100%" height="100%" rx="14" ry="14" />
      </ContentLoader>
    </View>
  )

  return (
    <NavigationContainer
      title={title}
      allowBackNavigation={allowBackNavigation}
      navigationBarProps={{ titleStyle: styles.navigationTitle }}
    >
      <View style={styles.content}>
        <SchedulingCalendar
          availableDates={availableDates}
          selectedDate={selectedDate}
          onDayPress={onDayPress}
        />
        <InlineAlert
          style={styles.timezoneAlert}
          category={UiInlineAlert.Info}
          message={`Timezone: ${user.timeZone}`}
        />
        <FlatList
          style={styles.slotsList}
          data={fetchingSlots ? Array(3).fill(0) : availableSlots}
          renderItem={fetchingSlots ? renderLoadingItem : renderSlotItem}
          contentContainerStyle={{ paddingBottom: insets.bottom }}
          showsVerticalScrollIndicator={false}
        />
      </View>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  navigationTitle: {
    maxWidth: '90%',
  },
  content: {
    flex: 1,
    backgroundColor: 'theme.background',
    paddingHorizontal: 16,
  },
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  timezoneAlert: {
    marginVertical: 8,
  },
  slotsList: {
    flex: 1,
    marginTop: 8,
  },
  slotItemContainer: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 8,
  },
  slotButton: {
    flexGrow: 1,
  },
  loadingSlot: {
    height: 56,
    marginBottom: 8,
    borderRadius: 40,
  },
  backIcon: {
    marginLeft: 16,
  },
})
