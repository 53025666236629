import { gql } from '@apollo/client'

export const FETCH_MODULES = gql`
  query allModules($ids: [ID]!) @api(name: cms) {
    modules(pagination: { pageSize: 100 }, sort: "position", filters: { id: { in: $ids } }) {
      data {
        id
        attributes {
          position
          title
          description
          sensorActivationRecommended
        }
      }
    }
  }
`
