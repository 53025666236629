import { gql } from '@apollo/client'

export const COMPLETE_LIBRE_LINKUP_CONNECTION = gql`
  mutation completeLinkupConnection($libre2faCode: String!) {
    completeLinkupConnection(libre2faCode: $libre2faCode) {
      active
      status
      lastSyncedAt
    }
  }
`
