import { gql } from '@apollo/client'
import { INSURANCE_POLICY_FIELDS } from './fragments/insurancePolicy'

export const FETCH_INSURANCE_POLICY = gql`
  ${INSURANCE_POLICY_FIELDS}
  query insurancePolicy {
    insurancePolicy {
      ...InsurancePolicyFields
    }
  }
`
