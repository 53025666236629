import React from 'react'
import { Image, ImageStyle, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { knowledgeBaseDarkImage, knowledgeBaseLightImage } from '@assets/images'
import { Text, Button } from '@components/base'
import { useIsDarkMode } from '@config/theme'
import { NavigationContainer } from '@screens/Common/containers'

export const SkipTutorial = () => {
  const isDarkMode = useIsDarkMode()
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()

  const goToDashboard = () => {
    navigation.navigate('Dashboard', { screen: 'Dashboard' })
  }

  const imageSource = isDarkMode
    ? knowledgeBaseDarkImage.imageSource
    : knowledgeBaseLightImage.imageSource

  return (
    <NavigationContainer title="Skip the tutorial">
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <Text type="title-2">Just so you know</Text>
        <Text type="regular" style={styles.subtitle}>
          You can always find it in the Knowledge Base in the navigation drawer.
        </Text>
        <View style={styles.imageContainer}>
          <Image source={imageSource} style={styles.image as ImageStyle} />
        </View>
        <Button type="primary" size="block" accessibilityLabel="Close" onPress={goToDashboard}>
          Close
        </Button>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'theme.background',
  },
  subtitle: {
    marginTop: 16,
  },
  imageContainer: {
    flex: 1,
    alignItems: 'center',
    margin: 32,
  },
  image: {
    aspectRatio: 1,
    width: '100%',
    height: undefined,
    maxWidth: 600,
    maxHeight: 600,
  },
})
