import { last } from 'lodash'
import { BleEventType, BleManagerDidUpdateStateEvent, BleState } from 'react-native-ble-manager'
import {
  GlucoseData,
  OneMinuteGlucoseData,
  libre3BluetoothManager,
  Libre3BluetoothManagerConnectionState,
  Libre3BluetoothManagerEvents,
  PatchInfoData,
} from 'react-native-freestyle-libre'
import { DeviceEventEmitter } from 'react-native'
import { Storage } from '@src/utils'
import { FORMATTED_BLUETOOTH_CONNECTION_STATE } from '@src/utils/bluetooth'
import { Device } from '@src/config'
import { UnitSystem } from '@src/screens/Settings'
import { BackgroundServiceEvents, DEFAULT_HISTORY_LIFE_COUNT } from './constants'
import { ListenersManager } from './listenersManager'
import {
  checkBluetoothState,
  getHistoryLifeCountKey,
  showSnack,
  updateRealTimeGlucoseValueNotification,
} from './utils'
import BackgroundService from './backgroundService'

export const backgroundServiceHandler = ({
  currentSensorSerialNumber,
  unitSystem: originalUnitSystem,
  baselineAdjustment: originalBaselineAdjustment,
  bleAddress,
  blePin,
  submitBluetoothScan,
}: {
  currentSensorSerialNumber: string
  unitSystem: UnitSystem
  baselineAdjustment: number
  bleAddress: string
  blePin: string
  submitBluetoothScan: (params: {
    serialNumber: string
    glucoseData: GlucoseData[]
    onSuccess?: () => void
  }) => void
}) => {
  let historyLifeCount: number | null = null
  let bleState: BleState | null = null

  let connectionState = Libre3BluetoothManagerConnectionState.NotConnected
  const setConnectionState = (newConnectionState: Libre3BluetoothManagerConnectionState) => {
    connectionState = newConnectionState
    DeviceEventEmitter.emit(BackgroundServiceEvents.ConnectionState, newConnectionState)
  }

  let patchInfo: PatchInfoData | null = null
  const setPatchInfo = (newPatchInfo: PatchInfoData | null) => {
    patchInfo = newPatchInfo
    DeviceEventEmitter.emit(BackgroundServiceEvents.PatchInfo, newPatchInfo)
  }

  let realTimeGlucoseValue: number | null = null
  const setRealTimeGlucoseValue = (newRealTimeGlucoseValue: number | null) => {
    realTimeGlucoseValue = newRealTimeGlucoseValue
    DeviceEventEmitter.emit(BackgroundServiceEvents.RealTimeGlucoseValue, {
      newRealTimeGlucoseValue,
      newRealTimeGlucoseTime: newRealTimeGlucoseValue ? new Date() : null,
    })
  }

  let bluetoothEnabled: boolean | undefined = undefined
  const setBluetoothEnabled = (newBluetoothEnabled: boolean | undefined) => {
    bluetoothEnabled = newBluetoothEnabled
    DeviceEventEmitter.emit(BackgroundServiceEvents.BluetoothEnabled, newBluetoothEnabled)
  }

  let unitSystem = originalUnitSystem
  let baselineAdjustment = originalBaselineAdjustment

  const listenersManager = new ListenersManager()

  listenersManager.addBackgroundServiceEventListener(
    BackgroundServiceEvents.UnitSystem,
    (newUnitSystem: UnitSystem) => {
      unitSystem = newUnitSystem
      if (realTimeGlucoseValue) {
        updateRealTimeGlucoseValueNotification({
          realTimeGlucoseValue,
          unitSystem,
          baselineAdjustment,
        })
      }
    },
  )

  listenersManager.addBackgroundServiceEventListener(
    BackgroundServiceEvents.baselineAdjustment,
    (newBaselineAdjustment: number) => {
      baselineAdjustment = newBaselineAdjustment
      if (realTimeGlucoseValue) {
        updateRealTimeGlucoseValueNotification({
          realTimeGlucoseValue,
          unitSystem,
          baselineAdjustment,
        })
      }
    },
  )

  listenersManager.addBackgroundServiceEventListener(BackgroundServiceEvents.SyncData, () => {
    setConnectionState(connectionState)
    setPatchInfo(patchInfo)
    setRealTimeGlucoseValue(realTimeGlucoseValue)
    setBluetoothEnabled(bluetoothEnabled)
  })

  const connectionStateListener = async (
    connectionState: Libre3BluetoothManagerConnectionState,
  ) => {
    setConnectionState(connectionState)
    if (BackgroundService.isRunning()) {
      BackgroundService.updateNotification({
        taskDesc: FORMATTED_BLUETOOTH_CONNECTION_STATE[connectionState],
      })
    }

    switch (connectionState) {
      case Libre3BluetoothManagerConnectionState.Connected: {
        const lifeCountFromState = Storage.get<number>(
          getHistoryLifeCountKey({ serialNumber: currentSensorSerialNumber as string }),
        )

        if (!historyLifeCount) {
          historyLifeCount = DEFAULT_HISTORY_LIFE_COUNT
        }

        if (lifeCountFromState && lifeCountFromState > historyLifeCount) {
          historyLifeCount = lifeCountFromState
        }

        try {
          await libre3BluetoothManager.requestHistory(historyLifeCount)
        } catch (error) {
          console.error(error)
        }
        break
      }
      case Libre3BluetoothManagerConnectionState.NotConnectedWrongBlePin: {
        setPatchInfo(null)
        setRealTimeGlucoseValue(null)
        showSnack('Please scan the sensor for bluetooth connection!', null, 'warning')
        break
      }
      case Libre3BluetoothManagerConnectionState.FailedToConnect: {
        setPatchInfo(null)
        setRealTimeGlucoseValue(null)
        showSnack('Failed to connect to sensor!', null, 'error')
        break
      }
      case Libre3BluetoothManagerConnectionState.Connecting: {
        setPatchInfo(null)
        setRealTimeGlucoseValue(null)
        break
      }
      case Libre3BluetoothManagerConnectionState.NotConnectedStoppedScanning: {
        setPatchInfo(null)
        setRealTimeGlucoseValue(null)
        break
      }
      case Libre3BluetoothManagerConnectionState.NotConnected: {
        setPatchInfo(null)
        setRealTimeGlucoseValue(null)

        if (await checkBluetoothState()) {
          libre3BluetoothManager.connectToSensor({
            bluetoothMACAddress: bleAddress,
            blePin,
            store: Storage,
          })
        }
        break
      }
    }
  }

  listenersManager.addLibre3BluetoothManagerListener(
    Libre3BluetoothManagerEvents.ConnectionStateUpdated,
    connectionStateListener,
  )

  const historyReceivedListener = (glucoseData: GlucoseData[]) => {
    console.log('History', { glucoseData })

    const lastHistoryReading = last(glucoseData.filter((reading) => reading.value > 0))

    if (!lastHistoryReading) {
      return
    }

    submitBluetoothScan({
      serialNumber: currentSensorSerialNumber,
      glucoseData,
      onSuccess: () => {
        Storage.set(
          getHistoryLifeCountKey({ serialNumber: currentSensorSerialNumber }),
          lastHistoryReading.lifeCount,
        )

        historyLifeCount = lastHistoryReading.lifeCount
      },
    })
  }

  listenersManager.addLibre3BluetoothManagerListener(
    Libre3BluetoothManagerEvents.HistoryReceived,
    historyReceivedListener,
  )

  const patchInfoReceivedListener = (patchInfo: PatchInfoData) => {
    console.log('Patch info data', { patchInfo })
    setPatchInfo(patchInfo)
  }

  listenersManager.addLibre3BluetoothManagerListener(
    Libre3BluetoothManagerEvents.PatchInfoReceived,
    patchInfoReceivedListener,
  )

  const oneMinuteGlucoseDataReceivedListener = async (
    oneMinuteGlucoseData: OneMinuteGlucoseData,
  ) => {
    console.log('One minute glucose data', { oneMinuteGlucoseData })
    setRealTimeGlucoseValue(oneMinuteGlucoseData.value)

    updateRealTimeGlucoseValueNotification({
      realTimeGlucoseValue: oneMinuteGlucoseData.value,
      unitSystem,
      baselineAdjustment,
    })

    if (libre3BluetoothManager.requestingHistory) {
      return
    }

    if (historyLifeCount && oneMinuteGlucoseData.historicalLifeCount > historyLifeCount) {
      try {
        await libre3BluetoothManager.requestHistory(historyLifeCount)
      } catch (error) {
        console.error(error)
      }
    }
  }

  listenersManager.addLibre3BluetoothManagerListener(
    Libre3BluetoothManagerEvents.OneMinuteGlucoseDataReceived,
    oneMinuteGlucoseDataReceivedListener,
  )

  const bleManagerDidUpdateStateListener = async (event: BleManagerDidUpdateStateEvent) => {
    const previousBleState = bleState
    bleState = event.state

    const bluetoothEnabled = bleState === BleState.On
    setBluetoothEnabled(bluetoothEnabled)

    if (
      bleState === BleState.On &&
      (previousBleState !== bleState ||
        connectionState !== Libre3BluetoothManagerConnectionState.Connecting)
    ) {
      libre3BluetoothManager.connectToSensor({
        bluetoothMACAddress: bleAddress,
        blePin,
        store: Storage,
      })
    }

    // BleManagerDisconnectPeripheral event is not sent on iOS when Bluetooth is disabled
    // https://github.com/innoveit/react-native-ble-manager/issues/986
    if (Device.ios && previousBleState !== bleState && bleState === BleState.Off) {
      // same logic as for Libre3BluetoothManagerConnectionState.NotConnected event
      setConnectionState(Libre3BluetoothManagerConnectionState.NotConnected)
      setPatchInfo(null)
      setRealTimeGlucoseValue(null)

      if (await checkBluetoothState()) {
        libre3BluetoothManager.connectToSensor({
          bluetoothMACAddress: bleAddress,
          blePin,
          store: Storage,
        })
      }
    }
  }

  listenersManager.addBleManagerListener(
    BleEventType.BleManagerDidUpdateState,
    bleManagerDidUpdateStateListener,
  )

  checkBluetoothState() // triggers initial connection if bluetooth is enabled
}
