import React, { useState } from 'react'
import { View, ViewStyle } from 'react-native'
import Animated, { Extrapolate, interpolate, useAnimatedStyle } from 'react-native-reanimated'
import { Rect } from 'react-content-loader/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { ContentLoader } from '@src/components/ContentLoader'
import { LoadingFastImage, ImageStyle } from '@src/components/LoadingFastImage'
import { LinearGradient } from '@src/components/LinearGradient'
import { useResponsiveStyleSheet } from '@src/hooks/useResponsiveStyleSheet'
import { ProgramWithContent } from '@src/screens/Learn/models/courses.types'
import { ProgressSlider } from '@src/components'
import { getProgramProgress } from '@src/screens/Learn/utils'
import { AnimatedText, Text } from '@components/base'

interface ProgramCardProps {
  program?: ProgramWithContent
  animatedHeight?: Animated.SharedValue<number>
  style?: ViewStyle
}

export const PROGRAM_CARD_MAX_HEIGHT = 260
export const PROGRAM_CARD_MIN_HEIGHT = 172
const FONT_SIZE_BASE_TITLE = 30
const FONT_SIZE_BASE_SUBTITLE = 16
const FONT_SIZE_SCALE = 0.75

export const ProgramCard = (props: ProgramCardProps) => {
  const { program, animatedHeight, style } = props
  const styles = useResponsiveStyleSheet({ styles: themedStyle, smallScreenStyles })
  const [isImageLoading, setIsImageLoading] = useState(true)
  const progress = getProgramProgress(program) || 0

  const titleFontSize = useAnimatedStyle(() => ({
    fontSize: interpolate(
      animatedHeight?.value || 0,
      [PROGRAM_CARD_MIN_HEIGHT, PROGRAM_CARD_MAX_HEIGHT],
      [FONT_SIZE_BASE_TITLE * FONT_SIZE_SCALE, FONT_SIZE_BASE_TITLE],
      Extrapolate.CLAMP,
    ),
  }))

  const subtitleFontSize = useAnimatedStyle(() => ({
    fontSize: interpolate(
      animatedHeight?.value || 0,
      [PROGRAM_CARD_MIN_HEIGHT, PROGRAM_CARD_MAX_HEIGHT],
      [FONT_SIZE_BASE_SUBTITLE * FONT_SIZE_SCALE, FONT_SIZE_BASE_SUBTITLE],
      Extrapolate.CLAMP,
    ),
  }))

  return (
    <View style={[styles.container, style]}>
      <LoadingFastImage
        source={{ uri: program?.featuredImage?.data?.attributes?.url }}
        style={styles.image as ImageStyle}
        onLoad={() => setIsImageLoading(false)}
      >
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 1 }}
          startColor="#000000"
          startOpacity={0}
          endColor="#000000"
          endOpacity={0.6}
          style={styles.image}
        />
      </LoadingFastImage>
      <View style={styles.content}>
        <AnimatedText type="title-2" style={[!isImageLoading && styles.title, titleFontSize]}>
          {program?.title}
        </AnimatedText>
        <AnimatedText type="regular" style={[!isImageLoading && styles.subtitle, subtitleFontSize]}>
          {program?.subtitle}
        </AnimatedText>
        <View style={styles.progressContainer}>
          <ProgressSlider progress={progress} />
          <Text type="regular" style={styles.percentage}>
            {Math.floor(progress * 100)}%
          </Text>
        </View>
      </View>
    </View>
  )
}

interface LoadingProgramCardProps {
  style?: ViewStyle
}
export const LoadingProgramCard = (props: LoadingProgramCardProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={[styles.container, props.style]}>
      <ContentLoader width="100%" height="100%">
        <Rect x="0" y="0" width="100%" height="100%" rx="14" ry="14" />
      </ContentLoader>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    maxHeight: PROGRAM_CARD_MAX_HEIGHT,
    borderRadius: 14,
    overflow: 'hidden',
    zIndex: 3,
  },
  image: {
    position: 'absolute',
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  content: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingHorizontal: 24,
    paddingBottom: 16,
  },
  title: {
    color: 'theme.solid.white',
  },
  subtitle: {
    color: 'theme.solid.white',
    marginTop: 4,
    textTransform: 'uppercase',
  },
  progressContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 6,
  },
  percentage: {
    marginLeft: 8,
    color: 'theme.solid.white',
  },
})

const smallScreenStyles = StyleService.create({
  image: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
})
