import React from 'react'
import { ColorValue, ViewStyle } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { LinearGradient } from '@src/components'

export enum ContentFadeEdge {
  Top,
  Bottom,
}

interface ContentFadeProps {
  edge: ContentFadeEdge
  style: ViewStyle
  fadeColor?: ColorValue
}

export const ContentFade = ({ edge, style, fadeColor: fadeColorProp }: ContentFadeProps) => {
  const theme = useTheme()

  const fadeColor = fadeColorProp ?? theme['theme.background']

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 0, y: 1 }}
      startColor={fadeColor}
      startOpacity={edge === ContentFadeEdge.Top ? 1 : 0}
      endColor={fadeColor}
      endOpacity={edge === ContentFadeEdge.Top ? 0 : 1}
      style={style}
    />
  )
}
