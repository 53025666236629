import React, { useState } from 'react'
import { View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, Text } from '@components/base'
import { Ingredient } from '@src/types'
import { pluralizeServingUnit } from '@screens/Ingredients/utils'
import { IngredientInfo } from './IngredientInfo'

const DEFAULT_VISIBLE_INGREDIENTS_COUNT = 5

interface IngredientsTotalProps {
  ingredients: Ingredient[]
  expandable?: boolean
  showTotal?: boolean
  defaultVisibleIngredientsCount?: number
  style?: ViewStyle
}

export const IngredientsTotal = (props: IngredientsTotalProps) => {
  const styles = useStyleSheet(themedStyle)
  const {
    ingredients,
    style,
    expandable = true,
    showTotal = true,
    defaultVisibleIngredientsCount = DEFAULT_VISIBLE_INGREDIENTS_COUNT,
  } = props

  const [showAll, setShowAll] = useState(false)

  const ingredientData = ingredients
    .map(({ calories, description, servingAmount, servingUnits }) => ({
      calories: Math.round(calories),
      name: description,
      serving: `${servingAmount} ${pluralizeServingUnit(servingUnits, servingAmount)}`,
    }))
    .sort((a, b) => b.calories - a.calories)

  const visibleIngredients = ingredientData.slice(0, defaultVisibleIngredientsCount)
  const restIngredients = ingredientData.slice(defaultVisibleIngredientsCount)

  const totalCalories = ingredientData.reduce((sum, { calories }) => sum + calories, 0)
  const restCalories = restIngredients.reduce((sum, { calories }) => sum + calories, 0)

  return (
    <View style={[styles.container, style]}>
      {showTotal && (
        <View style={styles.header}>
          <Text type="regular" bold>
            Ingredients
          </Text>
          <Text type="regular" bold style={[!totalCalories && styles.emptyText]}>
            {totalCalories} Cal
          </Text>
        </View>
      )}
      {visibleIngredients.length === 0 && (
        <IngredientInfo key="placeholder" name="N/A" calories={0} />
      )}
      {visibleIngredients.map((ingredient, index) => (
        <IngredientInfo key={index} {...ingredient} />
      ))}
      {restIngredients.length > 0 && !showAll && (
        <>
          <IngredientInfo name={`Other (${restIngredients.length})`} calories={restCalories} />
          {expandable && (
            <Button
              accessibilityLabel="Show all"
              size="small"
              type="transparent"
              style={styles.showAllButton}
              onPress={() => setShowAll(true)}
            >
              Show All
            </Button>
          )}
        </>
      )}
      {showAll && (
        <>
          {restIngredients.map((ingredient, index) => (
            <IngredientInfo key={index} {...ingredient} />
          ))}
          <Button
            accessibilityLabel="Show less"
            size="small"
            type="transparent"
            style={styles.showAllButton}
            onPress={() => setShowAll(false)}
          >
            Show Less
          </Button>
        </>
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    paddingHorizontal: 16,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 48,
    paddingTop: 8,
  },
  emptyText: {
    color: 'theme.text.tertiary',
  },
  showAllButton: {
    marginVertical: 16,
  },
})
