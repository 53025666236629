export * from 'react-native-web'
import React from 'react'
import { NativeModules as WebNativeModules } from 'react-native'

export const ViewPropTypes = {
  style: () => {},
}

export const requireNativeComponent = () => {}

export const NativeModules = {
  ...WebNativeModules,
  Configuration: {},
  RNShare: {},
  SourceCode: {
    scriptURL: '',
  },
  RNViewShot: {},
}

export default React
