import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { TextStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { Modal, Portal, ActivityIndicator } from 'react-native-paper'
import { find } from 'lodash'
import { NavigationProp } from '@react-navigation/core'
import { StyleService, useStyleSheet } from '@src/style/service'
import { SnackContent, SnackLevel } from '@src/navigation/components/SnackContent'
import loadingOpts from '@src/config/state/loadingOpts'
import { loadingSelector, loadingEffectsSelector } from '@selectors/loading'
import { DynamicStatusBar } from '@src/components'
import { NavigatorContext } from '@src/utils/navigatorContext'
import { QuickActions } from '@src/config'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'

type NavigationProps = any & {
  navigation: NavigationProp<ReactNavigation.RootParamList>
}

export const createNavigator = (WrappedComponent: React.ComponentType<any>) => {
  const Navigator = ({ navigation, ...restProps }: NavigationProps) => {
    const styles = useStyleSheet(themedStyles)
    const globalLoading = useSelector(loadingSelector)
    const loadingEffects = useSelector(loadingEffectsSelector)

    const [snackVisible, setSnackVisible] = useState(false)
    const [snackTitle, setSnackTitle] = useState('')
    const [snackContent, setSnackContent] = useState<string | undefined | null>(null)
    const [snackLevel, setSnackLevel] = useState<SnackLevel>('success')
    const [forceGlobalLoader, setForceGlobalLoader] = useState(false)
    const [loaderVisible, setLoaderVisible] = useState(false)

    const disableInAppScan = useDisableInAppScan()

    const showSnack = useCallback(
      (snackTitle: string, snackContent?: string | null, snackLevel: SnackLevel = 'success') => {
        setSnackVisible(true)

        setSnackTitle(snackTitle)
        setSnackContent(snackContent)
        setSnackLevel(snackLevel)
      },
      [],
    )

    const hideSnack = useCallback(() => {
      setSnackVisible(false)
    }, [])

    const enableLoader = useCallback(() => {
      setForceGlobalLoader(true)
    }, [])

    const disableLoader = useCallback(() => {
      setForceGlobalLoader(false)
    }, [])

    useEffect(() => {
      QuickActions.configure(disableInAppScan)
    }, [disableInAppScan])

    useEffect(() => {
      const showOrHideLoader = () => {
        let visible = false

        if (globalLoading) {
          const disableGlobalLoader = loadingOpts.exceptGlobal
          const disableLoader = find(disableGlobalLoader, (key) => !!loadingEffects[key])

          visible = !disableLoader || forceGlobalLoader
        } else {
          visible = forceGlobalLoader
        }

        setLoaderVisible(visible)
      }

      showOrHideLoader()
    }, [globalLoading, loadingEffects, forceGlobalLoader])

    const screenProps = useMemo(
      () => ({
        showSnack,
        hideSnack,
        enableLoader,
        disableLoader,
      }),
      [showSnack, hideSnack, enableLoader, disableLoader],
    )

    return (
      <>
        <DynamicStatusBar />
        <NavigatorContext.Provider value={screenProps}>
          <WrappedComponent navigation={navigation} {...restProps} />
        </NavigatorContext.Provider>
        <Portal>
          <Modal visible={loaderVisible} contentContainerStyle={styles.loaderContainer}>
            <ActivityIndicator
              animating
              size="large"
              color={(styles.spinner as TextStyle).color as string}
            />
          </Modal>
          <SnackContent
            title={snackTitle}
            content={snackContent}
            level={snackLevel}
            isVisible={snackVisible}
            onDismiss={hideSnack}
          />
        </Portal>
      </>
    )
  }

  return Navigator
}

const themedStyles = StyleService.create({
  loaderContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    color: 'theme.text.primary',
  },
})
