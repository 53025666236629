import React, { useState } from 'react'
import { useGoBack } from '@src/utils/navigation'
import { Env, Apollo, Stripe } from '@config'
import { useSnack } from '@src/utils/navigatorContext'
import StreamChat from '@src/services/StreamChat'
import { AdminModal as AdminModalComponent } from '../components/AdminModal'

export const AdminModalContainer = () => {
  const [endpoint, setEndpoint] = useState(Apollo.endpointEnv)

  const showSnack = useSnack()
  const goBack = useGoBack()

  const onSelect = (option: { value: Env }) => {
    Apollo.resetEndpoint({ env: option.value })
    Stripe.configure(Apollo.endpointEnv)
    StreamChat.configure(Apollo.endpointEnv)

    setEndpoint(option.value)
    goBack()
    showSnack(`Endpoint changed to '${option.value}'`, null, 'warning')
  }

  return (
    <AdminModalComponent
      selectedOption={endpoint as Env}
      onSelect={onSelect}
      onCancelPress={goBack}
    />
  )
}
