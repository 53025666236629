import React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { ScoreBar } from '@src/components/nutritionCard/ScoreBar'
import { scoreSelector } from '@src/selectors/app'

interface ScoreValue {
  key: string
  title: string
  description?: string | null
  value: number
}

interface ScoreBreakdownProps {
  values: ScoreValue[]
  size?: 'small' | 'regular'
  style?: StyleProp<ViewStyle>
}

export const ScoreBreakdown = ({ values, size = 'regular', style }: ScoreBreakdownProps) => {
  const styles = useStyleSheet(themedStyle)
  const { max } = useSelector(scoreSelector)

  return (
    <View style={style}>
      {values.map(({ key, title, description, value }) => (
        <View key={key} style={styles.scoreItem}>
          <View style={styles.topRow}>
            <Text type={size} bold style={styles.scoreTitle}>
              {title}
            </Text>
            <Text type={size === 'regular' ? 'large' : 'regular'} bold lineSpacing="none">
              {value}
              <Text type="small">/{max}</Text>
            </Text>
          </View>
          <ScoreBar
            number={value}
            containerStyle={size === 'regular' ? styles.barContainer : styles.barContainerSmall}
            barStyle={styles.bar}
          />
          {!!description && (
            <Text type={size} style={styles.description}>
              {description}
            </Text>
          )}
        </View>
      ))}
    </View>
  )
}

const themedStyle = StyleService.create({
  topRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  scoreTitle: {
    textTransform: 'uppercase',
  },
  scoreItem: {
    marginBottom: 16,
  },
  description: {
    color: 'theme.text.secondary',
  },
  barContainer: {
    width: '100%',
    marginTop: 8,
    marginBottom: 4,
  },
  barContainerSmall: {
    width: '100%',
    height: 4,
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 2,
  },
  bar: {
    borderRadius: 8,
  },
})
