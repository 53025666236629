import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName } from '@components/base'

export interface OutlinedIconProps {
  style?: StyleProp<ViewStyle>
  iconName: IconName
  iconStyle?: StyleProp<ViewStyle>
}

export const OutlinedIcon = ({ style, iconName, iconStyle }: OutlinedIconProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <View style={[styles.iconContainer, style]}>
      <View style={styles.iconCircle}>
        <Icon name={iconName} style={[styles.icon, iconStyle]} />
      </View>
    </View>
  )
}

const themedStyles = StyleService.create({
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 120,
    height: 100,
    marginVertical: 24,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base2',
  },
  iconCircle: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
    borderRadius: 40,
    backgroundColor: 'theme.solid.white',
  },
  icon: {
    width: 40,
    height: 40,
    color: 'theme.warning.base',
  },
})
