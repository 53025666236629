import { base64Url } from './fonts/nbInternationalBoldBase64Url'
const fontFamily = 'NBInternationalProBol'

export interface DataPoint {
  x: number
  y: number
}

export interface ActivityChartPoint extends DataPoint {
  name: string
  tag: number | string
  time: string
  tagPosition: string
}

export interface ActivityChartConfig {
  data: DataPoint[]
  points: ActivityChartPoint[]
  min: number
  max: number
  unit: string
  disableAnimation: boolean
  height: number
  horizontalPadding: number
  style: any
  startTime?: { x: number; y: number; time: string }
  endTime?: { x: number; y: number; time: string }
}

export const activityMiniChart = ({
  data,
  points,
  min,
  max,
  unit,
  disableAnimation,
  height,
  style,
  horizontalPadding = 0,
  startTime,
  endTime,
}: ActivityChartConfig) => {
  return `(function(){
var nbInternationalFont = new FontFace('${fontFamily}', 'url(${base64Url})');
nbInternationalFont.load().then(function(font) {
  document.fonts.add(font);

  chart = new F2.Chart({
    id: 'chart',
    height: ${height},
    padding: [10, ${horizontalPadding}, 25, ${horizontalPadding}],
    pixelRatio: window.devicePixelRatio
  });

  chart.source(${JSON.stringify(data)}, {
    y: {
      min: ${min},
      max: ${max},
      alias: 'Glucose ${unit}'
    },
    x: {
      tickCount: 6,
      nice: false,
    },
  });

  chart.axis('x',false);
  chart.axis('y', false);

  if ('${startTime}') {
    chart.guide().line({
      start: ['${startTime?.x}', 'min'],
      end: ['${startTime?.x}', ${startTime?.y}],
      style: {
        lineWidth: 1,
        stroke: '${style.limitPointColor}'
      }
    });

    chart.guide().text({
      content: '${startTime?.time}',
      position: ['${startTime?.x}', '${min - (max - min) * 0.2}' ],
      style: {
        fontSize: ${style.fontSize},
        fontFamily: '${fontFamily}',
        fill: '${style.textColor}',
      }
    })
  }

  if ('${endTime}') {
    chart.guide().line({
      start: ['${endTime?.x}', 'min'],
      end: ['${endTime?.x}', '${endTime?.y}'],
      style: {
        lineWidth: 1,
        stroke: '${style.limitPointColor}'
      }
    });

    chart.guide().text({
      content: '${endTime?.time}',
      position: ['${endTime?.x}', '${min - (max - min) * 0.2}' ],
      style: {
        fontSize: ${style.fontSize},
        fontFamily: '${fontFamily}',
        fill: '${style.textColor}',
      }
    })
  }

  ${points
    .map(
      (point: ActivityChartPoint) => `chart.guide().tag({
        position: [ '${point.x}', ${point.y} ],
        content: '${point.tag}',
        autoAdjust: false,
        pointStyle: {
          r: '${point.name}' === 'max' ? 7.5 : 4.5,
          stroke: '${style.stroke}',
          fill: '${style[`${point.name}PointColor`]}',
        },
        background: {
          fill: 'rgba(0, 0, 0, 0)',
          padding: 2,
        },
        offsetY: -4,
        textStyle: {
          fontSize: ${style.fontSize},
          fontFamily: '${fontFamily}',
          fill: '${style.textColor}',
        },
        direct: '${point.tagPosition}'
      });`,
    )
    .join('\n')}

  chart.tooltip(false);

  chart.guide().regionFilter({
    top: true,
    start: ['${startTime ? startTime.x : 'min'}', ${1.01 * min}],
    end: ['${endTime ? endTime.x : 'max'}', 'max'],
    style: {
      lineWidth: 1,
      stroke:'${style.limitPointColor}',
      fill: 'l(90) 0:rgba(0,135,63,1) 1:transparent',
    },
  });

  chart.area()
    .position('x*y')
    .style({
      fill: 'l(90) 0.03:rgba(218,218,219,0.4) 1:rgba(218,218,219,0)',
      fillOpacity: '${style.opacity}'
    });

  chart.line()
    .position('x*y')
    .style({
      lineWidth: 1,
      stroke: '${style.strokeColor}',
      fill: 'transparent'
    });

  ${disableAnimation ? 'chart.animate(false);' : ''}
  chart.render();

  var object = { type: 'loaded' }
  window.ReactNativeWebView.postMessage(stringify(object))
})
})(); true;
`
}
