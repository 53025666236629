/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export const FONT_AWESOME5_ICONS = 'fontAwesome5' as const

/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export enum FontAwesome5Pack {
  MoneyBill = 'money-bill',
}

/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export type FontAwesome5Icon = FontAwesome5Pack[keyof FontAwesome5Pack]

/**
 * @deprecated Use generic Icon component with iconDescriptor
 */
export const fontAwesome5Icon = (name: FontAwesome5Icon) => ({
  name,
  pack: FONT_AWESOME5_ICONS,
})
