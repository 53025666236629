import { bandageImage, boxContentImage, calendarImage, showerImage } from '@assets/images'
import {
  alignApplicatorVideo,
  applyCgmVideo,
  bandageVideo,
  connectApplicatorVideo,
  getReadyToApplyVideo,
  prepareArmVideo,
  prepareSensorVideo,
  sanitizeVideo,
} from '@assets/videos'

export const STEPS = [
  {
    title: 'What’s in the box?',
    description:
      'There are four components in the box: Bandage, CGM Applicator, CGM Sensor, Alcohol Wipe.',
    imageSource: boxContentImage.imageSource,
    duration: 2000,
  },
  {
    title: 'Prepare an arm',
    description:
      'Choose the portion of the back of your arm with the most fat.' +
      ' This section should be in-tact skin without irritation.',
    video: {
      source: prepareArmVideo.source,
    },
    duration: 1930,
  },
  {
    title: 'Sanitize',
    description:
      'Clean the area with the alcohol wipe before applying.' +
      ' If you have oily skin, consider a gentle exfoliation beforehand.',
    video: {
      source: sanitizeVideo.source,
    },
    duration: 4830,
  },
  {
    title: 'Prepare the sensor',
    description:
      'Peel off the lid of the sensor pack and twist off the cap of the sensor applicator.',
    video: {
      source: prepareSensorVideo.source,
    },
    duration: 14130,
  },
  {
    title: 'Align applicator',
    description: 'Line up the dark marks on the sensor applicator and the sensor pack.',
    video: {
      source: alignApplicatorVideo.source,
    },
    duration: 2060,
  },
  {
    title: 'Connect the two',
    description:
      'Connect the 2 pieces. Use a hard surface and push down (Sometimes you can hear a click)',
    video: {
      source: connectApplicatorVideo.source,
    },
    duration: 5960,
  },
  {
    title: 'Get ready to apply',
    description:
      'Now the small needle should be visible in the grey applicator. Don’t set the applicator down.',
    showContactSupport: true,
    video: {
      source: getReadyToApplyVideo.source,
    },
    duration: 4960,
  },
  {
    title: 'Apply your CGM',
    description:
      'Push firmly to apply the sensor. ' +
      'Don’t be nervous, insertion might feel like a tiny pinch, or nothing at all. You are all set! ',
    video: {
      source: applyCgmVideo.source,
    },
    duration: 8190,
  },
  {
    title: 'Bandage',
    description:
      'Put your Nutrisense bandage on. ' +
      'The use of the included adhesive bandage is strongly encouraged to protect the sensor during use.',
    video: {
      source: bandageVideo.source,
    },
    warning: "Don't remove the middle piece of white film, only the 4 outer-most pieces.",
    duration: 8540,
  },
  {
    title: 'Tips to use',
    sections: [
      {
        description: 'Your sensor will last for approximately 14 days.',
        imageSource: calendarImage.imageSource,
      },
      {
        description:
          'You can shower with the sensor! If you swim regularly, extra waterproof bandaging is recommended.',
        imageSource: showerImage.imageSource,
      },
      {
        description: 'Carefully swap bandages if the first comes loose.',
        imageSource: bandageImage.imageSource,
      },
    ],
    duration: 2000,
  },
]
