import React, { useState } from 'react'
import { ChoiceList } from '@components/Choice'
import {
  LessonItemContentMultipleChoice,
  LessonItemContentMultipleChoiceAnswerOption,
} from '../../models/courses.types'
import { LessonItemContentProps } from '../../lesson.types'
import { MultipleChoiceExplanation } from './MultipleChoiceExplanation'
import { LessonItemContainer } from './LessonItemContainer'

export const MultipleChoiceComponent = (props: LessonItemContentProps) => {
  const { item, isCompleted, onNext, onComplete } = props
  const [selectedOptionId, setSelectedOptionId] = useState('')
  const [revealed, setRevealed] = useState(false)

  const question = item as LessonItemContentMultipleChoice
  const selectedOption = question.answers.find((o) => o.id === selectedOptionId)

  const handleAnswerOptionPress = (option: LessonItemContentMultipleChoiceAnswerOption) => {
    setSelectedOptionId(option.id)
  }

  const handleNextPress = () => {
    if (isCompleted) {
      onNext()
      return
    }
    if (!selectedOptionId) {
      return
    }
    setRevealed(true)
    onComplete()
  }
  const navButtonOverrideProps = {
    nextDisabled: !selectedOption,
    onNext: handleNextPress,
  }

  return (
    <LessonItemContainer
      {...props}
      {...navButtonOverrideProps}
      lessonItemIcon="question"
      lessonItemTitle={question.question}
    >
      {revealed ? (
        <MultipleChoiceExplanation question={question} selectedOption={selectedOption} />
      ) : (
        <ChoiceList
          items={question.answers}
          selectedItemId={selectedOptionId}
          onPress={handleAnswerOptionPress}
        />
      )}
    </LessonItemContainer>
  )
}
