import { gql } from '@apollo/client'

const DELETE_MEAL = gql`
  mutation deleteMeal($id: ID!) {
    deleteMeal(id: $id) {
      id
      type
      title
      avatar
      description
      nutrition {
        key
        value
      }
      statistics {
        aucScore
        glucoseMax
        glucoseMin
        glucoseDelta
      }
      occurredAt
      createdAt
    }
  }
`

export default DELETE_MEAL
