import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'

interface SubscriptionFieldProps {
  name: string
  value: string
  secondaryValue?: string
  size?: 'normal' | 'small'
}

export const SubscriptionField = (props: SubscriptionFieldProps) => {
  const styles = useStyleSheet(themedStyle)
  const { name, value, secondaryValue, size = 'normal' } = props
  const isSmall = size === 'small'

  return (
    <View style={[styles.field, styles[size]]}>
      <Text type="regular">{name}</Text>
      <View style={styles.textContainer}>
        <Text type={isSmall ? 'regular' : 'large'} bold numberOfLines={1} lineSpacing="none">
          {value}
        </Text>
        {!!secondaryValue && (
          <Text type="regular" style={styles.textSecondary} numberOfLines={1} lineSpacing="none">
            {secondaryValue}
          </Text>
        )}
      </View>
    </View>
  )
}

const themedStyle = StyleService.create({
  field: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 64,
    borderBottomWidth: 2,
    borderBottomColor: 'theme.surface.base2',
  },
  normal: {
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  small: {
    paddingHorizontal: 0,
    paddingVertical: 12,
  },
  textSecondary: {
    marginTop: 4,
    color: 'theme.text.secondary',
  },
  textContainer: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
})
