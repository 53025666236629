import { Model } from '@models'

import UPDATE_ADDRESS from '../graphql/updateAddress'

export default class Address {
  namespace = 'address'

  state = {
    ...Model.defaultState,
  }

  effects = {
    update: Model.buildEffect({
      name: `${this.namespace}/update`,
      query: UPDATE_ADDRESS,
      dataPath: 'updateAddress',
      *onSuccess(_, { put, effectPayload }) {
        if (effectPayload.country) {
          // unit system may be updated on country change
          yield put({ type: 'users/fetch' })
          yield put({ type: 'app/clearCaches' })
          yield put({ type: 'app/config' })
          return
        }
      },
      reducers: [
        {
          name: 'app/updateUserState',
          payload: (response) => ({ address: response }),
        },
      ],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
  }
}
