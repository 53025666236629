import { useIsFocused, useNavigation } from '@react-navigation/native'
import { useEffect, useRef, useState } from 'react'
import { useFlagsStatus } from '@unleash/proxy-client-react'

export const useScreenStay = (wait: number = 2000) => {
  const navigation = useNavigation()
  const timeoutRef = useRef<NodeJS.Timeout>()
  const [screenIsFocused, setScreenIsFocused] = useState(false)
  const { flagsReady, flagsError } = useFlagsStatus()
  const flagsLoaded = flagsReady || flagsError
  const isFocused = useIsFocused()

  useEffect(() => {
    /**
     *  we need to wait for flags to be loaded before showing the tooltip to
     * avoid a race condition where the tooltip is shown on top of other modals
     * triggered by `useOnboardingModals`
     */
    if (flagsLoaded && isFocused) {
      timeoutRef.current = setTimeout(() => {
        setScreenIsFocused(true)
      }, wait)
    }

    if (!isFocused) {
      setScreenIsFocused(false)
      clearTimeout(timeoutRef.current)
    }

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [wait, navigation, flagsLoaded, isFocused])

  return screenIsFocused
}
