import React, { useState, useEffect, useImperativeHandle } from 'react'
import { StyleProp, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'
import { Audio, AVPlaybackSource, AVPlaybackStatus } from 'expo-av'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Device } from '@src/config'
import { Icon } from '@src/components/base'
import { LoadingIndicator } from '../LoadingIndicator'
import { AudioPlayerSlider } from './AudioPlayerSlider'
export { Audio }

interface AudioPlayerProps {
  source: AVPlaybackSource
  style?: StyleProp<ViewStyle>
  onReady?: (soundPlayer: Audio.Sound) => void
  onPlay?: () => void
  onPause?: () => void
}

export const AudioPlayer = React.forwardRef<Audio.Sound | null, AudioPlayerProps>(
  ({ source, style, onReady = () => 0, onPlay = () => 0, onPause = () => 0 }, forwardedRef) => {
    const styles = useStyleSheet(themedStyles)
    const [soundPlayer, setSoundPlayer] = useState<Audio.Sound | null>(null)
    const [status, setStatus] = React.useState<AVPlaybackStatus>({ isLoaded: false })
    const [position, setPosition] = useState(0)
    const [playableDuration, setPlayableDuration] = useState<number>(0)
    const [duration, setDuration] = useState(0)

    const isPlaying = status.isLoaded && status.isPlaying
    const statusIsBuffering = status.isLoaded && status.isBuffering
    const isBuffering = statusIsBuffering || !playableDuration // status.isBuffering doesn't work as expected on iOS
    const showBufferingIndicator =
      isBuffering &&
      position < duration &&
      (Device.android ? playableDuration <= position : isPlaying) // Android sets isPlaying to false when buffering

    useImperativeHandle(forwardedRef, () => soundPlayer as Audio.Sound, [soundPlayer])

    useEffect(() => {
      return () => {
        soundPlayer?.unloadAsync()
      }
    }, [soundPlayer])

    useEffect(() => {
      const setupSoundPlayer = async () => {
        try {
          await Audio.setAudioModeAsync({
            staysActiveInBackground: true,
            playsInSilentModeIOS: true,
          })
          const { sound, status } = await Audio.Sound.createAsync(source)
          if (!status.isLoaded) {
            throw new Error()
          }
          setSoundPlayer(sound)
          onReady(sound)
          setDuration(status.durationMillis || 0)
        } catch (error) {
          console.error('failed to load the sound', error)
        }
      }

      setupSoundPlayer()
    }, [source, onReady])

    useEffect(() => {
      if (!soundPlayer) {
        return
      }

      const interval = setInterval(async () => {
        const newStatus = await soundPlayer?.getStatusAsync()
        if (!newStatus.isLoaded || !status.isLoaded) {
          setStatus(newStatus)
          return
        }
        const { positionMillis: position, playableDurationMillis: playableDuration } = newStatus
        setPosition(position)

        // status.isBuffering doesn't seem to be working as expected on iOS
        const newIsBuffering = (status.isLoaded && status.isBuffering) || !playableDuration

        const hasPlayingChanged = status.isPlaying !== newStatus.isPlaying
        const hasBufferingChanged = isBuffering !== newIsBuffering

        if (hasPlayingChanged) {
          if (newStatus.isPlaying) {
            onPlay()
          } else if (!hasBufferingChanged) {
            onPause()
          }
        }

        setPlayableDuration(playableDuration || 0)
        setStatus(newStatus)
      }, 50)

      return () => clearInterval(interval)
    }, [onPause, onPlay, soundPlayer, status, isBuffering, duration])

    return (
      <View style={[styles.container, style]}>
        {!status.isLoaded || showBufferingIndicator ? (
          <TouchableOpacity
            style={
              showBufferingIndicator ? styles.playPauseButton : styles.loadingIndicatorContainer
            }
            onPress={() => soundPlayer?.pauseAsync()}
            accessibilityLabel={showBufferingIndicator ? 'Buffering' : 'Loading'}
            disabled={!showBufferingIndicator}
          >
            <LoadingIndicator
              color={
                ((showBufferingIndicator ? styles.icon : styles.loadingIndicator) as TextStyle)
                  .color
              }
            />
          </TouchableOpacity>
        ) : (
          <TouchableOpacity
            style={styles.playPauseButton}
            onPress={() => {
              if (isPlaying) {
                soundPlayer?.pauseAsync()
              } else if (position === duration) {
                soundPlayer?.playFromPositionAsync(0)
              } else {
                soundPlayer?.playAsync()
              }
            }}
            accessibilityLabel={isPlaying ? 'Pause' : 'Play'}
          >
            <Icon name={isPlaying ? 'pause' : 'play'} weight="fill" style={styles.icon} />
          </TouchableOpacity>
        )}
        <AudioPlayerSlider
          duration={duration}
          position={position}
          playableDuration={playableDuration || position}
          onSeek={async (position) => {
            if (isPlaying) {
              await soundPlayer?.pauseAsync()
              await soundPlayer?.playFromPositionAsync(position)
            } else {
              await soundPlayer?.setPositionAsync(position)
            }
            setPlayableDuration(position)
          }}
        />
      </View>
    )
  },
)

const themedStyles = StyleService.create({
  container: {
    width: '100%',
    margin: 24,
    padding: 16,
    paddingBottom: 8,
    backgroundColor: 'theme.surface.base2',
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  playPauseButton: {
    backgroundColor: 'theme.primary.base',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginTop: 9,
  },
  loadingIndicatorContainer: {
    width: 30,
    height: 30,
    alignSelf: 'flex-start',
    marginTop: 9,
  },
  loadingIndicator: {
    color: 'theme.text.primary',
  },
  icon: {
    color: 'theme.solid.white',
    width: 12,
    height: 12,
  },
})
