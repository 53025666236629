import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'

const loadingStoreStateSelector = (state: RootStoreState) => state.loading

export const loadingSelector = createSelector(
  loadingStoreStateSelector,
  (loading) => loading.global,
)

export const loadingEffectsSelector = createSelector(
  loadingStoreStateSelector,
  (loading) => loading.effects,
)
