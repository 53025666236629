import React from 'react'
import { TouchableOpacity } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { DATE_FORMAT, MONTH_NAME_AND_DATE_FORMAT } from '@config/momentFormat'
import { Calendar } from '@models/app.types'
import { getCustomCalendarTabLabel } from '@screens/Insights/utils/utils'
import { Haptic } from '@utils'
import { CALENDAR_TABS } from '../../constants'

const CUSTOM_TAB_KEY = 1

interface CalendarTabsOldProps {
  calendar: Calendar
  onChange: (newCalendar: Calendar) => void
}

export const CalendarTabsOld = ({ calendar, onChange }: CalendarTabsOldProps) => {
  const styles = useStyleSheet(themedStyle)

  const selectedTab = CALENDAR_TABS[calendar.rangeDays] ? calendar.rangeDays : CUSTOM_TAB_KEY

  const customLabel = getCustomCalendarTabLabel(calendar)

  const handleTabPress = (numberOfDays: number) => {
    Haptic.heavyTap()

    const startDate = moment(calendar.endDate)
      .subtract(numberOfDays - 1, 'days')
      .format(DATE_FORMAT)

    onChange({
      ...calendar,
      startDate,
      endDate: calendar.endDate,
      rangeDays: numberOfDays,
    })
  }

  const renderTab = ([key, value]: [string, string]) => {
    const tabRange = Number(key)
    const isCustomTab = tabRange === CUSTOM_TAB_KEY
    const isTabActive = tabRange === selectedTab
    const range =
      isCustomTab && !Object.keys(CALENDAR_TABS).includes(calendar.rangeDays.toString())
        ? calendar.rangeDays
        : tabRange

    const startDate = moment(calendar.endDate).subtract(range - 1, 'days')
    const endDate = moment(calendar.endDate)

    const formattedStartDate = startDate.format(MONTH_NAME_AND_DATE_FORMAT)
    const formattedEndDate = endDate.format(MONTH_NAME_AND_DATE_FORMAT)

    const isMultipleDays = range > 1
    const hasSubtitle = isMultipleDays || !customLabel.includes(' ')

    return (
      <TouchableOpacity
        key={key}
        accessibilityLabel={value}
        onPress={() => handleTabPress(range)}
        style={[styles.tab, isTabActive ? styles.activeTab : styles.defaultTab]}
      >
        {isCustomTab ? (
          <>
            <Text type="regular" style={isTabActive && styles.activeTabLabel}>
              {isMultipleDays ? `${endDate.diff(startDate, 'd') + 1} days` : customLabel}
            </Text>

            {hasSubtitle && (
              <Text type="tiny" style={isTabActive && styles.activeSubtitleLabel}>
                {isMultipleDays
                  ? `${formattedStartDate} - ${formattedEndDate}`
                  : formattedStartDate}
              </Text>
            )}
          </>
        ) : (
          <>
            <Text type="regular" style={isTabActive && styles.activeTabLabel}>
              {value}
            </Text>
            <Text type="tiny" style={isTabActive && styles.activeSubtitleLabel}>
              {`${formattedStartDate} - ${formattedEndDate}`}
            </Text>
          </>
        )}
      </TouchableOpacity>
    )
  }

  return Object.entries(CALENDAR_TABS).map(renderTab)
}

const themedStyle = StyleService.create({
  tab: {
    height: 48,
    borderRadius: 32,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultTab: {
    backgroundColor: 'theme.surface.base2',
  },
  activeTab: {
    backgroundColor: 'theme.primary.base',
  },
  activeTabLabel: {
    color: 'theme.solid.white',
  },
  activeSubtitleLabel: {
    color: 'theme.text.inColor',
  },
})
