import { pick } from 'lodash'
import { KEYS_TO_INCLUDE, DEFAULT_STATE } from '@src/screens/Settings/models/settings'
import { SettingsStoreState } from '@src/screens/Settings/models/settings.types'

export const reducers = {
  backup: (state: SettingsStoreState) => ({
    ...state,
    backup: pick(state, KEYS_TO_INCLUDE),
  }),

  restore: (state: SettingsStoreState) => {
    if (!state.backup) {
      return state
    }
    return {
      ...state,
      ...state.backup,
    }
  },

  updateSettings: (state: SettingsStoreState, { payload }: any) =>
    pick({ ...state, ...payload }, Object.keys(DEFAULT_STATE)),
}
