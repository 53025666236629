import React, { useRef } from 'react'
import { View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { SafeAreaView } from 'react-native-safe-area-context'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { upperFirst } from 'lodash'
import { useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NotificationEngineReminderDayOfWeek } from '@src/types'
import { Text, IconName, Button } from '@src/components/base'
import { CircleIcon, GestureDismissibleModal } from '@src/components'
import { showSnack } from '@src/services/Bluetooth/utils'
import { useGoBack } from '@src/utils'
import { AppRouteProp } from '@src/navigation/types'
import { remindersHoursIntervalSelector } from '@src/selectors/app'
import { ReminderFormData, ReminderFormInputs } from './ReminderFormInputs'
import {
  buildDayOfWeek,
  buildHoursInterval,
  buildTimeOfDay,
  frequencyKeyByTitle,
  frequencyTitle,
  orderIntervalRanges,
} from './utils'

export const EditReminder = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const {
    params: { reminder, reminderConfig: reminderKind },
  } = useRoute<AppRouteProp<'EditReminderModal'>>()
  const goBack = useGoBack()
  const isSubmitting = useRef(false)

  const reminderIntervalSettings = useSelector(remindersHoursIntervalSelector)
  const hoursIntervalRanges = orderIntervalRanges(reminderIntervalSettings.glucoseCheckOptions)
  const currentHoursInterval = hoursIntervalRanges.find(
    (option) => option.value === reminder.hoursInterval,
  )?.text

  const { control, handleSubmit, watch } = useForm<ReminderFormData>({
    defaultValues: {
      kind: reminder.kind,
      frequency: frequencyTitle(reminder.frequency),
      timeOfDay: reminder.timeOfDay ?? moment().format('hh:mmA'),
      dayOfWeek: upperFirst(reminder.dayOfWeek ?? NotificationEngineReminderDayOfWeek.Monday),
      hoursInterval: currentHoursInterval,
    },
  })

  const onDeleteButtonPress = () => {
    if (isSubmitting.current) {
      return
    }
    isSubmitting.current = true
    dispatch({
      type: 'settings/deleteReminder',
      payload: { id: reminder.id },
      success: () => {
        showSnack('Reminder deleted!')
        goBack()
      },
      failure: (error: any) => {
        if (error?.message) {
          showSnack(error.message, null, 'error')
        }
      },
      complete: () => {
        isSubmitting.current = false
      },
    })
  }

  const onSaveButtonPress = handleSubmit((data: ReminderFormData) => {
    if (isSubmitting.current) {
      return
    }
    isSubmitting.current = true

    const payload = {
      id: reminder.id,
      kind: reminderKind.key,
      frequency: frequencyKeyByTitle(data.frequency),
      dayOfWeek: buildDayOfWeek(data),
      timeOfDay: buildTimeOfDay(data),
      hoursInterval: buildHoursInterval(data, hoursIntervalRanges),
      __typename: 'Reminder',
    }
    dispatch({
      type: 'settings/updateReminder',
      payload,
      success: () => {
        showSnack('Reminder updated!')
        goBack()
      },
      failure: (error: any) => {
        if (error?.message) {
          showSnack(error.message, null, 'error')
        }
      },
      complete: () => {
        isSubmitting.current = false
      },
    })
  })

  return (
    <GestureDismissibleModal dismissButtonText="Cancel">
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <Text type="title-3" style={styles.header}>
          Edit Reminder
        </Text>
        <View style={styles.form}>
          <View style={styles.typeTitle}>
            {!!reminderKind.icon && (
              <CircleIcon name={reminderKind.icon as IconName} style={styles.icon} />
            )}
            <Text type="title-2">{reminderKind.title}</Text>
          </View>
          <ReminderFormInputs
            reminderKind={reminderKind}
            control={control}
            watch={watch}
            hoursIntervalRanges={hoursIntervalRanges}
          />
        </View>
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Add reminder"
          onPress={onSaveButtonPress}
        >
          Save
        </Button>
        <Button
          type="transparent"
          size="block"
          accessibilityLabel="Delete reminder"
          onPress={onDeleteButtonPress}
          style={styles.deleteButton}
          textStyle={styles.deleteButtonText}
        >
          Delete Reminder
        </Button>
      </SafeAreaView>
    </GestureDismissibleModal>
  )
}
const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    marginBottom: 16,
  },
  header: {
    textAlign: 'center',
    marginBottom: 16,
  },
  form: {
    flex: 1,
  },
  typeTitle: {
    marginVertical: 16,
    flexDirection: 'row',
    alignItems: 'center',
  },
  deleteButton: {
    marginTop: 8,
  },
  deleteButtonText: {
    color: 'theme.error.base',
  },
  icon: {
    marginRight: 16,
    padding: 16,
  },
})
