import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { BaseSuccessContainer } from '@src/screens/Common/containers/BaseSuccessContainer'
import { SUPPORT_EMAIL } from '@src/config/constants'
import { contactSupport, openUrl, Zendesk } from '@src/utils'
import { userSelector } from '@src/selectors/app'

export const OrderConfirmed = () => {
  const styles = useStyleSheet(themedStyles)

  const user = useSelector(userSelector)

  const onContactSupportPress = () => {
    openUrl(Zendesk.baseUrl(user?.email))
  }

  return (
    <BaseSuccessContainer
      navigationTitle="Order Confirmed"
      title="All Set!"
      description="Your order is being prepared"
      timeSectionDescription="You can expect your order to be delivered in 3-5 business days"
      dangerSectionDescription={
        <>
          If you experience any shipping delays, please reach out to{' '}
          <TouchableOpacity accessibilityLabel="contactSupport" onPress={contactSupport}>
            <Text type="regular" lineSpacing="none" style={styles.link}>
              {SUPPORT_EMAIL}
            </Text>
          </TouchableOpacity>
        </>
      }
      secondaryButton={
        <Button
          type="transparent"
          size="block"
          style={styles.button}
          onPress={onContactSupportPress}
          accessibilityLabel="Contact Support"
        >
          Contact
        </Button>
      }
    />
  )
}

const themedStyles = StyleService.create({
  link: {
    color: 'theme.text.link',
    marginTop: 'auto',
  },
  button: {
    marginTop: 8,
  },
})
