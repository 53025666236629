import React from 'react'
import { SvgProps } from 'react-native-svg'
import { useTheme } from '@src/config/theme'
import { Nutrisense } from '@src/assets/svgs'

export const NutrisenseLogo = (props: SvgProps) => {
  const { theme, themeName } = useTheme()

  const fill = theme[`palette.${themeName}.text.primary`]

  return <Nutrisense fill={fill} {...props} />
}
