import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'

const activitiesSelector = (state: RootStoreState) => state.activities

export const favoriteActivitiesStoreState = createSelector(
  activitiesSelector,
  (activities) => activities.favorites,
)

export const recentActivitiesStoreState = createSelector(
  activitiesSelector,
  (activities) => activities.recentActivities,
)
