import React, { useCallback, useMemo } from 'react'
import { Point } from '@src/screens/Insights/types'
import { renderDayOfWeekChart } from './renderDayOfWeekChart'
import { groupByDayOfWeek } from './helper'
import { BaseChart } from './BaseChart'
import { SingleDayScoreChartConfig } from './renderColumnChart'
import { DatesRange } from './types'

interface ByDayOfWeekChartProps {
  currentData: Point[]
  previousData: Point[]
  precision: 0 | 1
  yLabel?: string
  onLoad?: () => void
  datesRange: DatesRange
}

export const ByDayOfWeekChart = ({
  currentData,
  previousData,
  precision,
  yLabel,
  onLoad,
  datesRange,
}: ByDayOfWeekChartProps) => {
  const byDayOfWeekData = useMemo(() => groupByDayOfWeek(currentData, previousData, precision), [
    currentData,
    previousData,
    precision,
  ])

  const hasWeekData = byDayOfWeekData.some((dayData) => dayData.type === 'current' && dayData.y)

  const renderWeekFunction = useCallback(
    (style: SingleDayScoreChartConfig['style']) => {
      if (!hasWeekData) {
        return null
      }

      return renderDayOfWeekChart({
        data: byDayOfWeekData,
        yLabel,
        style,
      })
    },
    [byDayOfWeekData, hasWeekData, yLabel],
  )

  return (
    <BaseChart
      renderFunction={renderWeekFunction}
      title="By day of week"
      height={235}
      onLoad={onLoad}
      datesRange={datesRange}
    />
  )
}
