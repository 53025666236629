import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@components/base'
import { SurveysConfigKind, SurveyLink, SurveyLinkCollection } from '@src/types'
import { useDispatchAsync } from '@src/utils'
import { NavigatorContext, useSnack } from '@src/utils/navigatorContext'
import { LoadingContainer } from '@src/screens/Common/containers/LoadingContainer'
import { AccountInterestQuestionnaireComponent } from './AccountInterestQuestionnaireComponent'

const SURVEY_KIND = SurveysConfigKind.AccountInterest

export const AccountInterestQuestionnaireContainer = () => {
  const dispatch = useDispatch()
  const dispatchAsync = useDispatchAsync()

  const showSnack = useSnack()
  const { enableLoader, disableLoader } = useContext(NavigatorContext)
  const [error, setError] = useState(false)

  const questionnaireSubmittedRef = useRef(false)

  const [surveyLink, setSurveyLink] = useState<null | SurveyLink>(null)

  const onFailure = useCallback(() => {
    setError(true)
  }, [])

  const loadQuestionnaire = useCallback(() => {
    dispatch({
      type: 'app/surveyQuestionnaire',
      failure: onFailure,
      success: ({ surveyLinks }: SurveyLinkCollection) => {
        const surveyLink = surveyLinks.find((link) => link.survey.kind === SURVEY_KIND)

        if (surveyLink) {
          setSurveyLink(surveyLink)
          return
        }

        dispatch({
          type: 'app/startQuestionnaire',
          payload: {
            kind: SURVEY_KIND,
          },
          success: setSurveyLink,
          failure: onFailure,
        })
      },
    })
  }, [dispatch, onFailure])

  useEffect(() => {
    loadQuestionnaire()
  }, [dispatch, loadQuestionnaire])

  if (!surveyLink || !surveyLink.inProgress) {
    return (
      <LoadingContainer
        loadingMessage="Loading Questionnaire"
        errorMessage="Failed to load questionnaire"
        error={error}
      >
        <Button
          type="primary"
          size="block"
          accessibilityLabel="Retry"
          onPress={() => {
            setError(false)
            loadQuestionnaire()
          }}
        >
          Retry
        </Button>
      </LoadingContainer>
    )
  }

  const saveSurveyResponse = ({
    questionKey,
    answer,
  }: {
    questionKey: string
    answer: { value: string }
  }) => {
    return dispatchAsync({
      type: 'app/saveSurveyResponse',
      payload: {
        surveyLinkId: surveyLink.id,
        questionKey,
        answer,
      },
    })
  }

  const completeSurvey = async (responses: { [key: string]: any }) => {
    const saveSurveyResponsePromises: Promise<unknown>[] = []

    Object.keys(responses).forEach((questionKey) => {
      saveSurveyResponsePromises.push(
        saveSurveyResponse({ questionKey, answer: { value: responses[questionKey] } }),
      )
    })

    await Promise.all(saveSurveyResponsePromises)

    await dispatchAsync({
      type: 'app/completeSurvey',
      payload: {
        id: surveyLink.id,
      },
    })
  }

  const handleSubmit = async (responses: { [key: string]: any }) => {
    if (questionnaireSubmittedRef.current) {
      return
    }

    questionnaireSubmittedRef.current = true
    enableLoader()

    try {
      await completeSurvey(responses)
      await dispatchAsync({
        type: 'users/fetch',
      })
    } catch (e) {
      showSnack('Failed to save survey', null, 'error')
      questionnaireSubmittedRef.current = false
      return
    } finally {
      disableLoader()
    }
  }

  const onSkipPress = () => {
    dispatch({
      type: 'app/skipSurvey',
      payload: surveyLink.survey.kind,
    })
  }

  return (
    <AccountInterestQuestionnaireComponent
      surveyLink={surveyLink}
      onSkipPress={onSkipPress}
      onSubmit={handleSubmit}
    />
  )
}
