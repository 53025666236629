import { useState, useMemo } from 'react'
import moment, { Moment } from 'moment'
import { Subscription } from '@src/types'

const START_DATE_BUFFER_HOURS = 1

export const useCalendarSelection = (subscription?: Pick<Subscription, 'currentPeriodEndAt'>) => {
  const [selectedDate, setSelectedDate] = useState<Moment>()

  const selectableRange = useMemo(() => {
    if (!subscription) {
      return { start: '', end: '' }
    }
    const allowedStart = moment.min(
      moment().add(START_DATE_BUFFER_HOURS, 'hour'),
      moment().add(1, 'day').startOf('day'),
    )
    const allowedEnd = moment(subscription.currentPeriodEndAt).startOf('day')

    return { start: allowedStart.toISOString(), end: allowedEnd.toISOString() }
  }, [subscription])

  const onSelectDate = (date: string) => {
    const bufferedDate = moment.max(moment(selectableRange.start), moment(date))
    setSelectedDate(bufferedDate)
  }

  return { selectedDate, selectableRange, onSelectDate }
}
