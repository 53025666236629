import { AddressCountries } from '@src/types'
import * as Validators from './validators'

export const isValidField = (country: string) => (value: string, field: string) => {
  switch (field) {
    case 'name':
    case 'street':
    case 'city':
      return Validators.NonEmptyStringValidator(value)
    case 'state':
      if (country === AddressCountries.Us) {
        return Validators.StateValidator(value)
      } else {
        return Validators.NonEmptyStringValidator(value)
      }
    case 'zipCode':
      if (country === AddressCountries.Us) {
        return Validators.ZipCodeValidator(value)
      } else if (country === AddressCountries.Ca) {
        return Validators.CanadianPostalCodeValidator(value)
      } else if (country === AddressCountries.Gb) {
        return Validators.UkPostalCodeValidator(value)
      } else {
        return Validators.NonEmptyStringValidator(value)
      }
  }
  return true // Default to true for fields that do not need validation
}
