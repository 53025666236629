import { findKey } from 'lodash'
import { NotificationEngineReminderFrequency, NumericalSelect } from '@src/types'
import { ReminderFormData } from './ReminderFormInputs'

const FrequencyTitles: { [key in NotificationEngineReminderFrequency]: string } = {
  [NotificationEngineReminderFrequency.Daily]: 'Daily',
  [NotificationEngineReminderFrequency.Weekly]: 'Weekly',
  [NotificationEngineReminderFrequency.HoursInterval]: 'Interval',
  [NotificationEngineReminderFrequency.Monthly]: 'Monthly',
  [NotificationEngineReminderFrequency.Bimonthly]: 'Bimonthly',
  [NotificationEngineReminderFrequency.Quarterly]: 'Quarterly',
}

export const TempScanReminderKind = 'scan_reminder'

export const frequencyTitle = (frequencyKey: NotificationEngineReminderFrequency) =>
  FrequencyTitles[frequencyKey]

export const frequencyKeyByTitle = (frequencyTitle: string) =>
  findKey(FrequencyTitles, (title) => title === frequencyTitle)

export const buildDayOfWeek = (data: ReminderFormData) => {
  const weekly = frequencyKeyByTitle(data.frequency) === NotificationEngineReminderFrequency.Weekly
  return weekly ? data.dayOfWeek.toLowerCase() : undefined
}

export const buildTimeOfDay = (data: ReminderFormData) => {
  const frequencyKey = frequencyKeyByTitle(data.frequency)
  const timeOfDayRelevant = ![
    NotificationEngineReminderFrequency.HoursInterval,
    NotificationEngineReminderFrequency.Bimonthly,
    NotificationEngineReminderFrequency.Quarterly,
    NotificationEngineReminderFrequency.Monthly,
  ].includes(frequencyKey as NotificationEngineReminderFrequency)

  return timeOfDayRelevant ? data.timeOfDay : undefined
}

export const buildHoursInterval = (
  data: ReminderFormData,
  reminderIntervalSettings?: NumericalSelect[],
) => {
  if (reminderIntervalSettings === undefined) {
    return undefined
  }
  const hourly =
    frequencyKeyByTitle(data.frequency) === NotificationEngineReminderFrequency.HoursInterval
  return hourly
    ? reminderIntervalSettings.find((option) => option.text === data.hoursInterval)?.value
    : undefined
}

export const orderIntervalRanges = (ranges: NumericalSelect[]) =>
  ranges.sort((a, b) => a.value - b.value)
