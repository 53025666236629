import React from 'react'
import { Image, ImageSourcePropType, View, TouchableOpacity, ImageStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'

interface EducationSectionProps {
  accessibilityLabel: string
  imageSource: ImageSourcePropType
  title: string
  visited?: boolean
  onPress: () => void
}

export const EducationSection = ({
  accessibilityLabel,
  imageSource,
  title,
  visited,
  onPress,
}: EducationSectionProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <TouchableOpacity
      accessibilityLabel={accessibilityLabel}
      style={styles.container}
      onPress={onPress}
    >
      <Image source={imageSource} style={styles.image as ImageStyle} />
      <Text type="large" bold style={styles.title}>
        {title}
      </Text>
      <Icon name="caret-right" style={styles.icon} />
      {!visited && <View style={styles.unreadNotification} />}
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
    marginBottom: 16,
    paddingHorizontal: 20,
    paddingVertical: 16,
    borderRadius: 12,
    backgroundColor: 'theme.surface.base2',
  },
  image: {
    width: 48,
    height: 48,
  },
  title: {
    flex: 1,
    marginHorizontal: 20,
  },
  unreadNotification: {
    position: 'absolute',
    top: -4,
    right: 0,
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: 'theme.error.base',
  },
  icon: {
    width: 16,
    height: 16,
    color: 'theme.text.secondary',
  },
})
