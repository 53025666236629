import { useTheme } from '@ui-kitten/components'
import { ThemeColor } from '@src/style/theme'

export type ThemedColors = Record<string, ThemeColor>

export const useThemeColors = <T>(themedColors: T): { [key in keyof T]: string } => {
  const theme = useTheme()

  return Object.keys(themedColors as ThemedColors).reduce((acc, currentKey) => {
    acc[currentKey as keyof T] = theme[(themedColors as ThemedColors)[currentKey]]
    return acc
  }, {} as Record<keyof T, typeof theme[ThemeColor]>)
}
