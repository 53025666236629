import { noop } from 'lodash'
import { createContext, Dispatch, SetStateAction } from 'react'

export interface TempAppState {
  goalsQuestionnaireWasShown: boolean
}

export const defaultTempAppState: TempAppState = {
  goalsQuestionnaireWasShown: false,
}

export const TempAppStateContext = createContext<{
  tempAppState: TempAppState
  setTempAppState: Dispatch<SetStateAction<TempAppState>>
}>({
  tempAppState: defaultTempAppState,
  setTempAppState: noop,
})
