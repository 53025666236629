import { EducationStoreState, TutorialKey } from '@screens/Education/types'

const reducers = {
  setVisitedTutorial: (state: EducationStoreState, { payload }: { payload: TutorialKey }) => ({
    ...state,
    visitedTutorials: { ...state.visitedTutorials, [payload]: true },
  }),
}

export default reducers
