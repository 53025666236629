import React from 'react'
import { View } from 'react-native'
import { StyleService } from '@src/style/service'
import { GlucoseStatistics } from '@src/components/list/common/GlucoseStatistics'
import { GeneralCard } from '@src/components/nutritionCard/GeneralCard'
import { ActivityChart } from '@src/containers'
import { LoadingIndicator } from '@src/components/LoadingIndicator'
import { EntryStatistics } from '@src/types'

interface GlucoseGraphCardProps {
  item: any
  contentWidth?: number
  renderCharts: boolean
  statistics: EntryStatistics
  onLoad?: () => void
  tutorialKey?: string
  disableAnimation?: boolean
}

export const GlucoseGraphCard = (props: GlucoseGraphCardProps) => {
  const {
    contentWidth,
    renderCharts,
    item,
    statistics,
    onLoad,
    tutorialKey,
    disableAnimation = false,
  } = props
  const styles = StyleService.create(themedStyles)

  return (
    <View style={[styles.sliderCard, { width: contentWidth }]}>
      {renderCharts ? (
        <GeneralCard title="Glucose Graph" mode="compact" tutorialKey={tutorialKey}>
          <View style={[styles.container, styles.graphCard]}>
            <GlucoseStatistics statistics={statistics} mode="large" style={styles.statistics} />
            <ActivityChart item={item} onLoad={onLoad} disableAnimation={disableAnimation} />
          </View>
        </GeneralCard>
      ) : (
        <LoadingIndicator />
      )}
    </View>
  )
}

const themedStyles = StyleService.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 16,
    marginBottom: 16,
  },
  graphCard: {
    marginBottom: 0,
  },
  sliderCard: {
    height: 144,
  },
  statistics: {
    marginRight: 24,
  },
})
