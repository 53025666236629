import { gql } from '@apollo/client'

export const CHECKLIST_ITEMS = gql`
  query checklistItems($kind: UserChecklistItemsKind!) {
    checklistItems(kind: $kind) {
      checklistItems {
        title
        key
        completed
        deepLink
      }
    }
  }
`
