import React from 'react'
import { View } from 'react-native'
import { useDispatch } from 'react-redux'
import { RouteProp, useRoute } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text, Button } from '@components/base'
import { FadeFromBottomModal } from '@components'
import { useGoBack } from '@src/utils/navigation'
import { AppStackParamList } from '@src/navigation/types'

export const ChatDelimiterConfirmationModal = () => {
  const styles = useStyleSheet(themedStyles)
  const dispatch = useDispatch()
  const goBack = useGoBack()
  const {
    params: { channelId },
  } = useRoute<RouteProp<AppStackParamList, 'ChatDelimiterConfirmationModal'>>()

  const onConfirm = () => {
    dispatch({
      type: 'chatbot/createChatbotDelimiter',
      payload: {
        providerConversationId: channelId,
      },
    })
    goBack()
  }

  return (
    <FadeFromBottomModal closeModal={goBack}>
      <View style={styles.container}>
        <Text type="title-2">Clear the conversation</Text>
        <Text type="regular" style={styles.description}>
          Do you want to manually clear the context of the conversation?
        </Text>
        <Button
          accessibilityLabel="Clear conversation"
          size="block"
          type="primary"
          onPress={onConfirm}
        >
          Clear
        </Button>
        <Text type="small" style={styles.finePrint}>
          You'll still be able to access previous messages
        </Text>
      </View>
    </FadeFromBottomModal>
  )
}

const themedStyles = StyleService.create({
  container: {
    alignItems: 'center',
    paddingTop: 16,
  },
  description: {
    marginTop: 8,
    marginBottom: 24,
    textAlign: 'center',
  },
  finePrint: {
    marginTop: 16,
    textAlign: 'center',
  },
})
