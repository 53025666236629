import { startCase } from 'lodash'
import moment from 'moment'

import { DATE_FORMAT, MONTH_NAME_AND_DATE_FORMAT, TODAY, YESTERDAY } from '@src/config/momentFormat'
import { Calendar } from '@src/models/app.types'
import { isToday } from '@src/utils/global'
import { MeasurementConfig, Statistic } from '@src/types'

import { CalendarTab, RangeItem } from '@src/screens/Insights/types'
import {
  GlucoseKetonesEnum,
  MeasurementEnum,
  ScoreSectionEnum,
  SectionEnum,
} from '@src/screens/Insights/utils/pageConfig'
import { getRatingColor, getValueRating } from '@src/utils/ratings'
import { DetailedPageUrlParams } from '@src/navigation/types'
import { UnitSystem } from '@src/screens/Settings'

export const getValuePercentChangeWithSign = (item: Statistic) => {
  const { value, valuePrev } = item
  const valueChange = value - valuePrev
  const percentChangeDirection = valueChange > 0 ? 'higher' : 'lower'

  const percentChange = Math.abs(Number(((valueChange / valuePrev) * 100).toFixed()))

  return { percentChange, percentChangeDirection }
}

export const getValueChangeText = (item: Statistic) => {
  const { value, valuePrev } = item
  const valueChange = value - valuePrev
  const sign = valueChange > 0 ? '+' : ''

  if (valueChange === 0) {
    return 'No change'
  }
  return `${sign}${Number(valueChange.toFixed(2))}`
}

export const getPeriodComparisonText = (calendar: Calendar) => {
  const isFromToday = isToday(calendar.endDate)

  if (isFromToday && calendar.rangeDays === 1) {
    return 'vs yesterday'
  }
  if (isFromToday && calendar.rangeDays === 7) {
    return 'vs last week'
  }

  return 'vs past period'
}

export const getCustomCalendarTabLabel = (calendar: Calendar) => {
  const startDate = moment(calendar.endDate)

  if (startDate.diff(moment(), 'days') === 0) {
    return TODAY
  }
  if (startDate.diff(moment(), 'days') === -1) {
    return YESTERDAY
  }
  return startDate.format(MONTH_NAME_AND_DATE_FORMAT)
}

export const getValueColor = (item: Statistic, ranges: RangeItem[]) => {
  const rating = getValueRating(item.value, ranges)

  if (!rating || item.meta.section !== 'glucose') {
    return 'theme.text.primary'
  } else {
    return getRatingColor(rating)
  }
}

export const getStartDateFromTab = (endDate: string, tab: CalendarTab) => {
  return moment(endDate)
    .subtract(Number(tab) - 1, 'days')
    .format(DATE_FORMAT)
}

const SECTIONS_WITH_NO_UNITS = [SectionEnum.JOURNAL, SectionEnum.SCORE]

// Temporary solution until units are handled correctly on the backend
export const getMeasurementUnitSymbol = ({
  stat,
  defaultUnit,
  unitSystem,
  measurementConfig,
}: {
  unitSystem: UnitSystem
  measurementConfig?: MeasurementConfig
  stat: Statistic
  defaultUnit?: string
}) => {
  let unit = stat.units

  if (SECTIONS_WITH_NO_UNITS.includes(stat.meta.section as SectionEnum)) {
    return ''
  }

  if (!unit) {
    if (measurementConfig) {
      unit = measurementConfig[unitSystem].units
      if (unit === 'kilometers') {
        return 'km'
      }
      if (unit === 'percent') {
        return '%'
      }
      if (unit === 'kilos') {
        return 'kg'
      }
      if (unit === 'pounds') {
        return 'lbs'
      }
      if (unit === 'stress') {
        return ''
      }
    } else {
      unit = defaultUnit || ''
    }
  }

  return unit && unit !== '%' ? ` ${unit}` : '%'
}

const duplicateStatNames = ['glucose', 'ketones']

export const getTitle = (key: string, section: string) => {
  let updatedKey = key
  if (updatedKey === GlucoseKetonesEnum.STANDARD_DEVIATION) {
    return 'Glucose Variability'
  }
  if (updatedKey === GlucoseKetonesEnum.TIME_WITHIN_RANGE) {
    return `${startCase(section)} Time In Range`
  }
  if (updatedKey === ScoreSectionEnum.DAILY_AVERAGE) {
    return 'Daily Score'
  }
  if (updatedKey === ScoreSectionEnum.MEALS_AVERAGE) {
    return 'Meal Score'
  }
  if (updatedKey === MeasurementEnum.STEP_COUNT) {
    return 'Steps'
  }
  if (updatedKey === GlucoseKetonesEnum.MEAN) {
    updatedKey = 'average'
  }

  const prefix = duplicateStatNames.includes(section) ? section : ''
  const result = `${prefix}_${updatedKey}`.split('_').join(' ')

  return startCase(result)
}

export const getShortStatTitle = (key: string, section: string) => {
  const title = getTitle(key, section)
  // Remove the word 'Glucose' and 'Ketones' from the start of the titles
  // as it's already displayed in the section header
  if (['glucose', 'ketones'].includes(section)) {
    return title.slice(title.indexOf(' ') + 1)
  }

  return title
}

interface KeyParams {
  key: string
  section: string
}
export const getKey = ({ section, key }: KeyParams) => `${section}_${key}`

interface Section {
  title: string
  items: Statistic[]
}

export const GLUCOSE_SECTION = 'glucose'

export const getInitialSections = (): Record<string, Section> => ({
  glucose: {
    title: 'Glucose Values',
    items: [],
  },
  macros: {
    title: 'Nutrition',
    items: [],
  },
  measurements: {
    title: 'Measurements',
    items: [],
  },
  ketones: {
    title: 'Ketones',
    items: [],
  },
  journal: {
    title: 'Journal',
    items: [],
  },
})

export const isScoreSection = (section: string): section is ScoreSectionEnum => {
  return Object.values(ScoreSectionEnum).includes(section as ScoreSectionEnum)
}

export const isDetailsNavigatedByUrl = (
  params: { statistic: Statistic } | DetailedPageUrlParams,
): params is DetailedPageUrlParams => !('statistic' in params)
