import { DeviceEventEmitter } from 'react-native'
import { Libre3BluetoothManagerConnectionState } from 'react-native-freestyle-libre'
import BleManager, { BleState } from 'react-native-ble-manager'
import { SnackLevel } from '@src/navigation/components/SnackContent'
import { UnitSystem } from '@src/screens/Settings'
import { FORMATTED_BLUETOOTH_CONNECTION_STATE, enableBluetoothAndroid } from '@src/utils/bluetooth'
import { getGlucoseUnit, getGlucoseValue } from '@src/utils/units'
import { Device } from '@src/config'
import { BackgroundServiceEvents } from './constants'
import BackgroundService from './backgroundService'

export const getHistoryLifeCountKey = ({ serialNumber }: { serialNumber: string }) => {
  return `${serialNumber}-history-life-count`
}

export const updateRealTimeGlucoseValueNotification = ({
  realTimeGlucoseValue,
  unitSystem,
  baselineAdjustment,
}: {
  realTimeGlucoseValue: number
  unitSystem: UnitSystem
  baselineAdjustment: number
}) => {
  const glucoseUnit = getGlucoseUnit(unitSystem)
  const value = getGlucoseValue(unitSystem, realTimeGlucoseValue + baselineAdjustment)

  BackgroundService.updateNotification({
    taskDesc: `${
      FORMATTED_BLUETOOTH_CONNECTION_STATE[Libre3BluetoothManagerConnectionState.Connected]
    }.\nCurrent glucose value: ${value} ${glucoseUnit}`,
  })
}

export const showSnack = (
  snackTitle: string,
  snackContent?: string | null | undefined,
  level?: SnackLevel | undefined,
) => {
  DeviceEventEmitter.emit(BackgroundServiceEvents.ShowSnack, snackTitle, snackContent, level)
}

export const checkBluetoothState = async () => {
  const bleState = await BleManager.checkState()

  if (bleState !== BleState.On) {
    if (Device.ios || !(await enableBluetoothAndroid())) {
      if (BackgroundService.isRunning()) {
        BackgroundService.updateNotification({
          taskDesc: 'Enable bluetooth in phone settings',
        })
      }
      return false
    }

    return false
  }

  return true
}
