import { Model } from '@models'
import { initCalendar, processDateRangePayload, validateDateRange } from '@src/models/helper'
import ALL_STATS_DATA from '@src/screens/Insights/graphql/allStatsData'
import CHARTS_DATA from '@src/screens/Insights/graphql/chartsData'
import reducers from '@src/screens/Insights/models/insights.reducer'
import { Caching } from '@src/utils'
import { Payload } from '@src/utils/caching'

export const cacheKey = (payload: Payload) =>
  Caching.dateRangeTypesCacheKey('insights/fetch', payload)

export default class Insights {
  namespace = 'insights'

  state = {
    ...Model.defaultState,
    calendar: initCalendar(),
    stats: [],
  }

  effects = {
    changeDateRange: Model.buildEffect({
      name: `${this.namespace}/changeDateRange`,
      validation: validateDateRange,
      reducers: [
        {
          name: 'updateCalendarState',
          payload: processDateRangePayload,
        },
      ],
    }),

    fetchChartsData: Model.buildEffect({
      name: `${this.namespace}/fetchChartsData`,
      query: CHARTS_DATA,
      dataPath: 'chartsData',
      caching: true,
      cacheKey,
    }),

    fetchStats: Model.buildEffect({
      name: `${this.namespace}/fetchStats`,
      query: ALL_STATS_DATA,
      dataPath: 'allStats',
      caching: true,
      cacheKey: (variables) => Caching.listFilterCacheKey('insights/fetchStats', variables),
      reducers: [{ name: 'fetchList', dataPath: 'statistics', storePath: 'stats' }],
    }),
  }

  reducers = reducers
}
