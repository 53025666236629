import React from 'react'
import { Image, ImageStyle, View } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconSources } from '@assets/icons'
import { Icon, Text } from '@components/base'
import { PaymentMethod } from '@models/user.types'

type SupportedCard = 'visa' | 'mastercard' | 'amex'

const supportedCardIcons = ['visa', 'mastercard', 'amex'] as SupportedCard[]

const isSupportedIcon = (icon: string): icon is SupportedCard =>
  supportedCardIcons.includes(icon as SupportedCard)

interface PaymentMethodInfoProps {
  paymentMethod: Pick<PaymentMethod, 'expirationDate' | 'last4' | 'cardType'>
}

export const PaymentMethodInfo = ({ paymentMethod }: PaymentMethodInfoProps) => {
  const styles = useStyleSheet(themedStyle)
  const cardType = paymentMethod.cardType.toLowerCase()
  const cardIcon = isSupportedIcon(cardType) ? cardType : null
  const date = moment(paymentMethod.expirationDate).format('MM/YY')

  return (
    <View style={styles.container}>
      {cardIcon ? (
        <Image
          resizeMode="center"
          style={styles.cardImage as ImageStyle}
          source={IconSources[cardIcon]}
        />
      ) : (
        <Icon name="credit-card" />
      )}
      <Text type="regular" bold style={styles.dotSeparator}>
        •
      </Text>
      <Text type="regular">{paymentMethod.last4}</Text>
      <Text type="regular" style={styles.date}>
        {date}
      </Text>
    </View>
  )
}

const themedStyle = StyleService.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  cardImage: {
    height: 40,
  },
  dotSeparator: {
    marginHorizontal: 8,
  },
  date: {
    marginLeft: 20,
  },
})
