import React, { createContext, useContext } from 'react'
import { StyleSheet, TextStyle } from 'react-native'
import { IconProps as PhosphorIconProps } from 'phosphor-react-native'
import { useTheme } from '@ui-kitten/components'
import { UiIcon } from '@src/types'
import { iconMap } from './iconMap.generated'

export const iconNames = Object.keys(iconMap)

type IconStyle = Omit<TextStyle, 'color'> & { color?: string; tintColor?: string }
export type IconName = keyof typeof iconMap
export type IconProps = PhosphorIconProps & {
  name: IconName
}

export const IconContext = createContext<PhosphorIconProps>({
  size: 24,
  weight: 'regular',
  mirrored: false,
})

export const UI_ICONS_MAPPING: { [key in UiIcon]: IconName } = {
  [UiIcon.Coaching]: 'person-arms-spread',
  [UiIcon.Done]: 'check-circle',
  [UiIcon.Food]: 'fork-knife',
  [UiIcon.Glucose]: 'chart-line',
  [UiIcon.Goals]: 'target',
  [UiIcon.Health]: 'first-aid',
  [UiIcon.Lifecycle]: 'barbell',
  [UiIcon.Sleep]: 'moon-stars',
  [UiIcon.Bluetooth]: 'bluetooth',
  [UiIcon.Confetti]: 'confetti',
  [UiIcon.Warning]: 'warning',
  [UiIcon.Gift]: 'gift',
}

export const Icon = ({ name, ...props }: IconProps) => {
  const theme = useTheme()
  const PhosphorIcon = iconMap[name]
  const propStyle = StyleSheet.flatten<IconStyle>(props.style)

  const {
    color: contextColor = theme['theme.text.primary'],
    size: contextSize,
    weight: contextWeight,
    mirrored: contextMirrored,
    style: contextStyle,
  } = useContext(IconContext)

  const color = props.color || propStyle?.color || propStyle?.tintColor || contextColor
  const size =
    props.size || (propStyle?.width as number) || (propStyle?.height as number) || contextSize
  const weight = props.weight || contextWeight
  const mirrored = props.mirrored || contextMirrored
  const style = [contextStyle, propStyle]

  if (!PhosphorIcon) {
    console.error(`Icon "${name}" not found`)
    return null
  }

  return (
    <PhosphorIcon color={color} size={size} weight={weight} mirrored={mirrored} style={style} />
  )
}
