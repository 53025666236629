import React, { useRef, useEffect, useState } from 'react'
import { StyleProp, View, ViewStyle, Animated, Easing } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'

interface ProgressBarProps {
  currentStep: number
  stepsCount: number
  style?: StyleProp<ViewStyle>
  duration?: number
  withoutSpaces?: boolean
}

export const ProgressBar = (props: ProgressBarProps) => {
  const {
    currentStep: currentStepFromProps,
    stepsCount,
    style,
    duration = 2000,
    withoutSpaces = false,
  } = props
  const styles = useStyleSheet(themedStyles)

  const width = useRef(new Animated.Value(0)).current

  const [currentStep, setCurrentStep] = useState(currentStepFromProps)

  useEffect(() => {
    setCurrentStep(currentStepFromProps)
    width.setValue(0)
    Animated.timing(width, {
      toValue: 1,
      duration,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start()
  }, [currentStepFromProps, duration, width])

  return (
    <View style={[styles.progressBar, style]}>
      {Array.from({ length: stepsCount }).map((_, index) => {
        return (
          <View
            style={[
              styles.bar,
              index < currentStep && styles.active,
              index === 0 && styles.firstBar,
              !withoutSpaces && index !== stepsCount - 1 && styles.withSpaces,
              !withoutSpaces && styles.rounded,
              index === stepsCount - 1 && styles.lastBar,
            ]}
            key={index}
          >
            {index === currentStep && (
              <Animated.View
                style={[
                  styles.active,
                  styles.activeAnimated,
                  index === 0 && styles.firstBar,
                  !withoutSpaces && styles.rounded,
                  {
                    width: width.interpolate({
                      inputRange: [0, 1],
                      outputRange: ['0%', '100%'],
                    }),
                  },
                ]}
              />
            )}
          </View>
        )
      })}
    </View>
  )
}

const themedStyles = StyleService.create({
  progressBar: {
    flexDirection: 'row',
  },
  bar: {
    flex: 1,
    height: 4,
    width: 20,
    backgroundColor: 'theme.secondary.base',
  },
  firstBar: {
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
  },
  lastBar: {
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    marginRight: 0,
  },
  active: {
    backgroundColor: 'theme.primary.base',
  },
  activeAnimated: {
    position: 'absolute',
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 1,
  },
  rounded: {
    borderRadius: 2,
  },
  withSpaces: {
    marginRight: 8,
  },
})
