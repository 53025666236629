import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { ImageStyle } from 'react-native-fast-image'
import { capitalize } from 'lodash'
import { StyleService, useStyleSheet } from '@src/style/service'
import { NS } from '@assets/svgs'
import { Icon, Text } from '@components/base'
import { LoadingFastImage } from '@components'
import { BaseIngredient } from '@screens/Ingredients/types'
import { pluralizeServingUnit } from '@src/screens/Ingredients/utils'

interface IngredientItemProps {
  hasPlusIcon?: boolean
  item: BaseIngredient
  onItemSelect: (item: BaseIngredient) => void
  rightAccessory?: React.ReactNode
}

export const IngredientItem = ({
  hasPlusIcon,
  item,
  onItemSelect,
  rightAccessory,
}: IngredientItemProps) => {
  const styles = useStyleSheet(themedStyle)

  const { calories, description, imageURL, servingAmount, servingUnits } = item

  const pluralizedServing =
    servingAmount > 1 ? pluralizeServingUnit(servingUnits, servingAmount) : servingUnits

  const image = imageURL ? (
    <LoadingFastImage source={{ uri: imageURL }} style={styles.image as ImageStyle} />
  ) : (
    <NS width={40} height={40} />
  )

  return (
    <TouchableOpacity
      accessibilityLabel={description}
      activeOpacity={0.7}
      onPress={() => onItemSelect(item)}
      style={[styles.itemContainer, !item.isUpToDate ? styles.disabledItemEdit : {}]}
      testID={description}
      disabled={!item.isUpToDate}
    >
      {image}
      <View style={styles.textContainer}>
        <Text type="large" lineSpacing="tight" numberOfLines={2}>
          {description}
        </Text>
        <Text type="regular" lineSpacing="none" style={styles.itemInfo}>
          {`${servingAmount} ${capitalize(pluralizedServing)} · ${Math.round(calories)} cal`}
        </Text>
      </View>
      {hasPlusIcon && <Icon name="plus" style={styles.plusIcon} />}
      {rightAccessory}
    </TouchableOpacity>
  )
}

const themedStyle = StyleService.create({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 16,
    borderBottomWidth: 2,
    borderBottomColor: 'theme.surface.base2',
  },
  disabledItemEdit: {
    opacity: 0.3,
  },
  image: {
    width: 40,
    height: 40,
    resizeMode: 'cover',
  },
  textContainer: {
    flex: 1,
    marginHorizontal: 16,
  },
  itemInfo: {
    marginTop: 4,
    color: 'theme.text.secondary',
  },
  iconButton: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
  plusIcon: {
    marginRight: 16,
    width: 16,
    height: 16,
    color: 'theme.text.primary',
  },
})
