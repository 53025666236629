import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { StackNavigationProp } from '@react-navigation/stack'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { AppStackParamList } from '@src/navigation/types'
import { useGoBack } from '@src/utils/navigation'
import { useResetNavigationToModule } from '@src/screens/Learn/hooks'
import { NavigationButtons } from '../components/items/NavigationButtons'

export const ModuleCompletion = () => {
  const styles = useStyleSheet(themedStyles)
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const goBack = useGoBack()
  const route = useRoute<RouteProp<AppStackParamList, 'CoursesModuleCompletion'>>()
  const { title, isProgramComplete, nextModuleParams } = route.params
  const resetNavigationToModule = useResetNavigationToModule(nextModuleParams)

  const getNavigationButtons = () => {
    if (isProgramComplete) {
      return <NavigationButtons canGoBack={false} nextTitle="Thank you!" onNext={goBack} />
    } else {
      const hasNextModule = !!nextModuleParams.moduleId
      return (
        <NavigationButtons
          canGoBack={hasNextModule}
          nextTitle={hasNextModule ? 'Next Module' : 'Done'}
          onNext={() => {
            resetNavigationToModule()
            if (hasNextModule) {
              navigation.replace('CoursesModule', nextModuleParams)
            } else {
              navigation.replace('CoursesLearn')
            }
          }}
          backTitle="Close"
          onBack={goBack}
        />
      )
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.congratsContainer}>
        <Icon name="confetti" style={styles.congratsIcon} />
        <Text type="title-3" style={styles.congratsTitle}>
          Congratulations!
        </Text>
        <Text type="large" style={styles.congratsDescription}>
          You have successfully completed{' '}
          <Text type="large" bold style={styles.titleText}>
            {title}
          </Text>{' '}
          by Nutrisense.
        </Text>
      </View>
      <View style={styles.footer}>
        {isProgramComplete ? (
          <Text type="regular" style={styles.footerText}>
            To continue learning, please check out the
            <TouchableOpacity
              accessibilityLabel="Go to Knowledge Base"
              onPress={() => navigation.navigate('EducationModal')}
              style={styles.linkContainer}
            >
              <Text type="regular" lineSpacing="none" style={styles.link}>
                Knowledge Base
              </Text>
            </TouchableOpacity>
            . Additional courses will be available soon.
          </Text>
        ) : (
          <Text type="regular" style={styles.footerText}>
            To continue learning, you can check out the next module.
          </Text>
        )}
        {getNavigationButtons()}
      </View>
    </SafeAreaView>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    justifyContent: 'space-between',
  },
  congratsContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  congratsIcon: {
    alignSelf: 'center',
    width: 48,
    height: 48,
    color: 'theme.text.secondary',
  },
  congratsTitle: {
    marginTop: 16,
    marginBottom: 24,
    textAlign: 'center',
  },
  congratsDescription: {
    textAlign: 'center',
  },
  titleText: {
    textDecorationLine: 'underline',
  },
  footer: {
    alignSelf: 'flex-end',
    alignItems: 'center',
  },
  footerText: {
    textAlign: 'center',
    margin: 24,
  },
  linkContainer: {
    justifyContent: 'flex-end',
  },
  link: {
    color: 'theme.text.link',
  },
})
