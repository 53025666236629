import React, { useContext, useMemo } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { RouteProp, useRoute } from '@react-navigation/native'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { Divider } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { RootStackParamList } from '@src/navigation/types'
import { CheckoutContainer } from '@src/screens/Marketplace/components/checkout/CheckoutContainer'
import { ProductDescription } from '@src/screens/Marketplace/components/checkout/ProductDescription'
import { ProductPayment } from '@src/screens/Marketplace/components/checkout/ProductPayment'
import {
  usePromotionCode,
  usePurchaseOneTimePayment,
  useTaxEstimation,
} from '@screens/Marketplace/utils/hooks'
import { BillingProduct, UiInlineAlert } from '@src/types'
import { InlineAlert } from '@src/components'
import { NavigatorContext } from '@utils'
import { getPriceRangeDescription } from '../../utils/utils'

export const DietitianMenuCheckout = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>()
  const route = useRoute<RouteProp<RootStackParamList, 'DietitianMenuCheckout'>>()
  const { enableLoader, disableLoader } = useContext(NavigatorContext)

  const { groupedProducts, selection, goBackOnSuccess } = route.params
  const { title, description, shortDescription, details } =
    groupedProducts ?? selection?.product ?? {}
  const productDescription = description || shortDescription || ''

  const productKey = selection?.product.key as BillingProduct
  const promotionCode = usePromotionCode(productKey)
  const estimatedTax = useTaxEstimation(productKey, promotionCode)

  const priceDescription = useMemo(() => {
    if (selection) {
      return `$${selection.product.price}`
    }
    if (!groupedProducts?.products) {
      return ''
    }
    return getPriceRangeDescription(groupedProducts)
  }, [groupedProducts, selection])

  const onSuccessNavigationAction = () => {
    if (!selection) {
      return
    }

    navigation.replace('DietitianAddonOrderConfirmed', {
      product: selection.product,
      groupedProducts,
      goBackOnSuccess,
    })
  }

  const purchaseOneTimePayment = usePurchaseOneTimePayment()

  if (!groupedProducts && !selection) {
    console.error(
      'DietitianMenuCheckout requires either a ProductSelection or a ProductGroup and ProductList',
    )
    return null
  }

  const onSignUpPress = async () => {
    if (!selection) {
      return
    }

    enableLoader()

    try {
      await purchaseOneTimePayment(selection, promotionCode)
      onSuccessNavigationAction()
    } finally {
      disableLoader()
    }
  }

  return (
    <CheckoutContainer
      checkoutButtonTitle="Purchase Now"
      checkoutDisabled={!selection}
      onCheckout={onSignUpPress}
    >
      <ProductDescription
        title={title ?? ''}
        description={productDescription}
        price={priceDescription}
      />
      {groupedProducts && (
        <>
          <Divider appearance="large" />
          <View style={styles.groupProductContainer}>
            <Text type="small" bold lineSpacing="none" style={styles.title}>
              Choose Question
            </Text>
            <TouchableOpacity
              accessibilityLabel="Choose a question"
              style={styles.groupProductSelector}
              onPress={() =>
                navigation.navigate('DietitianMenuGroupProductSelector', {
                  groupedProducts,
                  selection,
                })
              }
            >
              <View style={styles.groupProductSelectorTitleContainer}>
                {selection ? (
                  <Text type="regular">{selection.product.title}</Text>
                ) : (
                  <Text type="regular" style={styles.groupProductSelectorTitle}>
                    Choose a question
                  </Text>
                )}
              </View>
              <Icon name="caret-right" style={styles.arrowIcon} />
            </TouchableOpacity>
          </View>
          <Divider appearance="large" />
        </>
      )}
      {selection && (
        <ProductPayment
          product={selection.product.key as BillingProduct}
          fullPrice={selection.product.price}
          estimatedTax={estimatedTax}
          promotionCode={promotionCode}
        />
      )}
      {details && (
        <InlineAlert
          category={UiInlineAlert.Info}
          message="HOW IT WORKS"
          secondaryMessage={details.join('\n')}
          style={styles.nextSteps}
        />
      )}
    </CheckoutContainer>
  )
}

const themedStyle = StyleService.create({
  groupProductContainer: {
    paddingHorizontal: 16,
    marginBottom: 16,
  },
  title: {
    marginTop: 24,
    marginBottom: 16,
    textTransform: 'uppercase',
    letterSpacing: 0.3,
  },
  groupProductSelector: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 40,
  },
  groupProductSelectorTitleContainer: {
    marginRight: 16,
    flexShrink: 1,
  },
  groupProductSelectorTitle: {
    color: 'theme.text.secondary',
  },
  arrowIcon: {
    width: 16,
    height: 16,
  },
  nextSteps: {
    marginHorizontal: 16,
    marginVertical: 32,
  },
  bottomSpacing: {
    marginBottom: 16,
  },
})
