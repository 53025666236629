import { useDispatch, useSelector } from 'react-redux'
import { useMemo } from 'react'
import { IconName } from '@components/base'
import { Feature, useFeatureFlag } from '@components'
import { referralCreditsSelector } from '@src/selectors/app'
import { visitedTutorialsSelector } from '@src/selectors/education'
import { TutorialKey } from '@screens/Education/types'
import { useHasLargeScreen } from '@src/config/device'
import { DrawerParamList } from '@src/navigation/types'
import {
  HistoryScreen,
  ReferralScreen,
  SettingsScreen,
  EducationScreen,
  LearnScreen,
  ProfileScreen,
  InsightsScreen,
  NutritionistHubScreen,
} from '@screens'
import { SubscriptionsNavigator } from '@src/navigation/navigators/subscriptions.navigator'

export interface DrawerItemInfo {
  title: string
  routeName?: keyof DrawerParamList
  onItemPress?: () => void
  icon: IconName
  component?: React.ComponentType
  routeParams?: Record<string, any>
  tag?: string
  indicator?: string
  includeIf?: boolean
}

export const useDrawerItems = () => {
  const dispatch = useDispatch()
  const hasLargeScreen = useHasLargeScreen()

  const referralCredits = useSelector(referralCreditsSelector)
  const visitedTutorials = useSelector(visitedTutorialsSelector)
  const tutorialsKeys = Object.values(TutorialKey)
  const visitedTutorialsKeys = Object.keys(visitedTutorials)
  const unreadTutorialsCount = tutorialsKeys.filter(
    (tutorialKey) => !visitedTutorialsKeys.includes(tutorialKey),
  ).length

  const displayUnreadTutorialsCount = !useFeatureFlag(Feature.AbbottPartnership)
  const appNavigationUpdatesFeatureEnabled = useFeatureFlag(Feature.AppNavigationUpdates)
  const userGoalsEnabled = useFeatureFlag(Feature.UserGoals)

  const insightsTitle = userGoalsEnabled ? 'Trends' : 'Insights'

  const allDrawerItems = useMemo((): DrawerItemInfo[] => {
    return [
      {
        title: 'Home',
        routeName: 'Dashboard',
        icon: 'house-simple',
        includeIf: hasLargeScreen,
      },
      {
        title: 'History',
        routeName: 'History',
        icon: 'clock',
        component: HistoryScreen,
        includeIf: hasLargeScreen,
      },
      {
        title: insightsTitle,
        routeName: 'Insights',
        icon: 'trend-up',
        component: InsightsScreen,
        includeIf: hasLargeScreen,
      },
      {
        title: 'Nutritionist',
        routeName: 'NutritionistHub',
        icon: 'users',
        component: NutritionistHubScreen,
        includeIf: hasLargeScreen,
      },
      {
        title: 'Learn',
        routeName: 'Learn',
        icon: 'book-open',
        component: LearnScreen,
        includeIf: hasLargeScreen,
      },
      {
        title: 'Programs',
        routeName: 'Subscriptions',
        icon: 'shopping-cart-simple',
        component: SubscriptionsNavigator,
        includeIf: !appNavigationUpdatesFeatureEnabled,
      },
      {
        title: 'Knowledge Base',
        routeName: 'Education',
        icon: 'book-open',
        component: EducationScreen,
        includeIf: !appNavigationUpdatesFeatureEnabled,
        indicator:
          displayUnreadTutorialsCount && unreadTutorialsCount > 0
            ? unreadTutorialsCount.toString()
            : undefined,
      },
      {
        title: 'Refer a friend',
        routeName: 'Referral',
        icon: 'users',
        component: ReferralScreen,
        routeParams: {
          source: 'Drawer',
        },
        tag: referralCredits > 0 ? `GET $${referralCredits}` : undefined,
        includeIf: !appNavigationUpdatesFeatureEnabled,
      },
      {
        title: 'Settings',
        routeName: 'Settings',
        icon: 'gear',
        component: SettingsScreen,
        includeIf: !appNavigationUpdatesFeatureEnabled,
      },
      {
        title: 'Sign out',
        onItemPress: () => dispatch({ type: 'app/logout' }),
        icon: 'sign-out',
      },
    ]
  }, [
    appNavigationUpdatesFeatureEnabled,
    dispatch,
    displayUnreadTutorialsCount,
    hasLargeScreen,
    insightsTitle,
    referralCredits,
    unreadTutorialsCount,
  ])

  return {
    visibleDrawerItems: allDrawerItems.filter(({ includeIf = true }) => includeIf),
    allDrawerItems,
  }
}

export interface DrawerRoute {
  routeName: keyof DrawerParamList
  component: React.ComponentType
}

export const useDrawerRoutes = (): DrawerRoute[] => {
  const { allDrawerItems } = useDrawerItems()
  const drawerRoutes = allDrawerItems
    .map(({ routeName, component }) => ({ routeName, component }))
    .filter(({ routeName, component }) => routeName && component) as DrawerRoute[]

  return [
    ...drawerRoutes,
    {
      routeName: 'Account',
      component: ProfileScreen,
    },
  ]
}
