import Axios from 'axios'
import Config from 'react-native-config'
import moment from 'moment'
import { BetterStackCgmLogKind } from '@src/config/logger'

const client = Axios.create({
  baseURL: 'https://in.logs.betterstack.com/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${Config.BETTER_STACK_API_KEY}`,
  },
})

const saveLog = (
  event: string,
  userId: string,
  kind: BetterStackCgmLogKind,
  context?: Record<string, unknown>,
) => {
  client
    .post('/', {
      dt: moment().utc().format('YYYY-MM-DD HH:mm:ss [UTC]'),
      message: {
        event,
        user_id: userId,
        kind,
        context,
      },
    })
    .catch((error: any) => {
      throw new Error(`BetterStack Network Error: ${JSON.stringify(error)}`)
    })
}

export default { saveLog }
