import React, { useEffect } from 'react'
import { Linking, TouchableOpacity } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button, IconName } from '@components/base'
import { InlineAlert, ScrollViewWithFade, TouchableSection } from '@src/components'
import { useDisableInAppScan } from '@src/hooks/useDisableInAppScan'
import { NavigationContainer } from '@src/screens/Common/containers'
import { remindersSelector } from '@src/selectors/settings'
import { MobileAppFeature, NotificationEngineReminderKind, UiInlineAlert } from '@src/types'
import { ReminderKind } from '@src/types'
import { remindersConfigSelector } from '@src/selectors/app'
import { User } from '@src/utils'
import SettingsSection from '../UserSettings/SettingsSection'
import ToggleSetting, { fieldValueSelector } from '../UserSettings/ToggleSetting'
import { useNotificationsPermissionsStatus } from '../../utils/hooks'
import { useAvailableReminders, useIncludeGlucoseCheckReminder } from './hooks'

export const NotificationSettings = () => {
  const styles = useStyleSheet(themedStyles)
  const notificationAuthStatus = useNotificationsPermissionsStatus()
  const navigation = useNavigation()

  const dispatch = useDispatch()
  const availableReminders = useAvailableReminders()
  const isScanDisabled = useDisableInAppScan()
  const isCGMFeatureAvailable = User.hasFeature(MobileAppFeature.ScanCgm)

  const glucoseCheckReminderEnabled = useIncludeGlucoseCheckReminder()
  const reminders = useSelector(remindersSelector)
  const filteredReminders = glucoseCheckReminderEnabled
    ? reminders
    : reminders.filter((reminder) => reminder.kind !== NotificationEngineReminderKind.GlucoseCheck)
  const remindersConfig = useSelector(remindersConfigSelector)
  const scanReminderEnabled = useSelector(fieldValueSelector('reminders'))

  const remindersWithConfig = filteredReminders.map((reminder) => {
    const config =
      remindersConfig.find((reminderKind) => reminderKind.key === reminder.kind) ||
      ({} as ReminderKind)
    return { reminder, reminderConfig: config }
  })

  useEffect(() => {
    dispatch({ type: 'settings/fetchReminders' })
  }, [dispatch])

  return (
    <NavigationContainer title="Notifications">
      <SafeAreaView edges={['bottom']} style={styles.container}>
        <ScrollViewWithFade>
          {notificationAuthStatus && notificationAuthStatus.disabled && (
            <TouchableOpacity
              accessibilityLabel="Enable notifications"
              activeOpacity={0.7}
              style={styles.alertContainer}
              onPress={() => Linking.openSettings()}
            >
              <InlineAlert
                category={UiInlineAlert.Warning}
                message="Push Notifications are currently disabled"
              />
            </TouchableOpacity>
          )}

          {isCGMFeatureAvailable && (
            <SettingsSection title="Notifications">
              <ToggleSetting title="Patterns Notifications" field="patternsNotifications" />
            </SettingsSection>
          )}
          <SettingsSection title="Reminders">
            {!isScanDisabled && isCGMFeatureAvailable && scanReminderEnabled && (
              <TouchableSection
                iconName="bell-ringing"
                title="CGM Scans"
                showRightArrow
                onPress={() => navigation.navigate('EditScanReminderModal')}
              />
            )}
            {remindersWithConfig.map(({ reminder, reminderConfig }) => (
              <TouchableSection
                key={reminder.id}
                iconName={reminderConfig.icon as IconName}
                title={reminderConfig.title}
                showRightArrow
                onPress={() =>
                  navigation.navigate('EditReminderModal', { reminder, reminderConfig })
                }
              />
            ))}
          </SettingsSection>
        </ScrollViewWithFade>
        <Button
          type="primary"
          style={styles.button}
          size="block"
          disabled={availableReminders.length === 0}
          accessibilityLabel="Add reminder"
          onPress={() => navigation.navigate('AddReminderModal')}
        >
          {availableReminders.length !== 0 ? 'Add reminder' : 'No Reminders Left to Add'}
        </Button>
      </SafeAreaView>
    </NavigationContainer>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    paddingBottom: 16,
    backgroundColor: 'theme.background',
  },
  alertContainer: {
    margin: 16,
  },
  button: {
    marginHorizontal: 16,
  },
})
