import React, { forwardRef } from 'react'
// eslint-disable-next-line no-restricted-imports
import { CheckBox, CheckBoxProps as CheckBoxBaseProps } from '@ui-kitten/components'
import { View, ViewStyle } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'

export interface CheckboxProps extends Omit<CheckBoxBaseProps, 'status'> {
  hasError?: boolean
  style?: ViewStyle
  text?: string
  value?: boolean
}

export const Checkbox = forwardRef<CheckBox, CheckboxProps>(
  ({ checked, disabled, hasError, indeterminate, style, text, value, ...rest }, ref) => {
    const styles = useStyleSheet(themedStyle)

    const isChecked = value ?? checked
    const isFilled = isChecked || indeterminate

    const checkboxStyles = [
      styles.box,
      isFilled && styles.filled,
      hasError && styles.error,
      disabled && styles.disabled,
      isFilled && disabled && styles.filledDisabled,
    ]

    return (
      <CheckBox {...rest} checked={isChecked} disabled={disabled} ref={ref}>
        {() => (
          <View style={[style, styles.checkboxContainer]}>
            <View style={checkboxStyles}>
              {isChecked && !indeterminate && (
                <Icon name="check" style={styles.boxIcon} weight="bold" />
              )}
              {indeterminate && <Icon name="minus" style={styles.boxIcon} weight="bold" />}
            </View>
            {!!text && (
              <Text type="regular" style={styles.text}>
                {text}
              </Text>
            )}
          </View>
        )}
      </CheckBox>
    )
  },
)

const themedStyle = StyleService.create({
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
  },
  box: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 24,
    height: 24,
    borderWidth: 2,
    borderRadius: 6,
    borderColor: 'theme.surface.base0',
  },
  boxIcon: {
    width: 16,
    height: 16,
    color: 'theme.solid.white',
  },
  filled: {
    borderColor: 'theme.primary.base',
    backgroundColor: 'theme.primary.base',
  },
  error: {
    borderColor: 'theme.error.base',
  },
  disabled: {
    borderColor: 'theme.surface.base1',
    backgroundColor: 'theme.transparent',
  },
  filledDisabled: {
    borderColor: 'theme.surface.base1',
    backgroundColor: 'theme.surface.base1',
  },
  text: {
    marginLeft: 8,
  },
})
