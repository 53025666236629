import React from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from '@ui-kitten/components'
import { scoreSelector } from '@src/selectors/app'
import { ArcIndicator, ArcIndicatorProps } from './ArcIndicator'

export type ScoreArcIndicatorProps = Omit<
  ArcIndicatorProps,
  'stateConfigs' | 'minValue' | 'maxValue'
>

export const ScoreArcIndicator = (arcIndicatorProps: ScoreArcIndicatorProps) => {
  const theme = useTheme()
  const {
    warning: warningScoreThreshold,
    danger: dangerScoreThreshold,
    max: maxScore,
  } = useSelector(scoreSelector)

  return (
    <ArcIndicator
      {...arcIndicatorProps}
      minValue={0}
      maxValue={maxScore}
      stateConfigs={{
        rangeConfigs: [
          {
            minValue: 0,
            maxValue: dangerScoreThreshold,
            color: theme['theme.range.bad'],
            status: 'Needs Improvement',
          },
          {
            minValue: dangerScoreThreshold + 1,
            maxValue: warningScoreThreshold,
            color: theme['theme.range.regular'],
            status: 'OK',
          },
          {
            minValue: warningScoreThreshold + 1,
            maxValue: maxScore,
            color: theme['theme.range.good'],
            status: 'Good',
          },
        ],
        noDataAvailableConfig: {
          color: theme['theme.disabled.text'],
          status: 'No Data',
        },
      }}
    />
  )
}
