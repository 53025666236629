// mock for web build

export default {
  configure: (app: any) => app,
  codePushDownloadDidProgress: () => {
    return
  },
  codePushStatusDidChange: () => {
    return
  },
  getUpdateMetadata: () => {
    return Promise.resolve(false)
  },
}
