import { createSelector } from 'reselect'
import { RootStoreState } from '@src/models/app.types'

export const mealsStoreStateSelector = (state: RootStoreState) => state.meals

export const allMealsSelector = createSelector(mealsStoreStateSelector, (meals) => meals.meals)

export const favoriteMealsSelector = createSelector(
  mealsStoreStateSelector,
  (meals) => meals.favorites,
)

export const recentMealsSelector = createSelector(
  mealsStoreStateSelector,
  (meals) => meals.recentMeals,
)

export const mealDraftSelector = createSelector(mealsStoreStateSelector, (meals) => meals.mealDraft)

export const mealsCalendarSelector = createSelector(
  mealsStoreStateSelector,
  (meals) => meals.calendar,
)

export const mealsSortSelector = createSelector(mealsStoreStateSelector, (meals) => meals.sort)

export const mealsByIdSelector = (mealsIdList: string[]) =>
  createSelector(mealsStoreStateSelector, (mealsStore) =>
    mealsStore.meals.filter((meal) => mealsIdList.includes(meal.id)),
  )
