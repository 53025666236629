import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { StackNavigationProp } from '@react-navigation/stack'
import { HealthDataMeasurementType, HealthMetricsParam } from '@src/types'
import { AppStackParamList } from '@src/navigation/types'
import { Measurement } from '@src/screens/Events/models/events.types'
import { Analytics, CustomEventTypes } from '@src/config'
import { HealthMetricValueProps } from './HealthMetricValue'
import { HealthMetricCard } from './HealthMetricCard'

interface MeasurementCardProps {
  title: string
  metricKey: HealthMetricsParam
  unit: string | undefined
  data: Measurement[]
  type: HealthDataMeasurementType
}

export const MeasurementCard = (props: MeasurementCardProps) => {
  const { title, metricKey, unit, data, type } = props
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()

  const values = data.map((entry) => {
    const value =
      type === HealthDataMeasurementType.BloodPressure
        ? `${entry.values?.systolic}/${entry.values?.diastolic}`
        : entry.values?.value?.toString()

    return {
      onEditAction: () => {
        Analytics.track(CustomEventTypes.OutcomesTrackingIndividualValueTapped)
        navigation.navigate('EditMeasurement', {
          item: { ...entry, type },
        })
      },
      value,
      date: entry.occurredAt.toString(),
      unit,
    } as HealthMetricValueProps
  })

  return (
    <HealthMetricCard
      onAddAction={() => {
        navigation.navigate('AddMeasurement', {
          type,
        })
      }}
      title={title}
      metricKey={metricKey}
      unit={unit}
      values={values}
    />
  )
}
