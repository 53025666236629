import React from 'react'
import { View, ViewStyle } from 'react-native'
import { Text } from '@components/base'

export interface ListSettingsFilterProps {
  label: string
  children: React.ReactNode
  style?: ViewStyle
  testID?: string
}

export const ListSettingsSection = ({
  label,
  children,
  style,
  testID,
}: ListSettingsFilterProps) => (
  <View testID={testID} style={style}>
    <Text type="regular" style={{ marginBottom: 8 }}>
      {label}
    </Text>
    {children}
  </View>
)
