import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName, Text } from '@components/base'

interface OptionProps {
  iconName: IconName
  title: string
  description: string
  onPress: () => void
}

export const Option = ({ iconName, title, description, onPress }: OptionProps) => {
  const styles = useStyleSheet(themedStyles)

  return (
    <TouchableOpacity
      accessibilityLabel={title}
      style={styles.container}
      activeOpacity={0.7}
      onPress={onPress}
    >
      <View style={styles.row}>
        <View style={styles.iconContainer}>
          <Icon style={styles.icon} name={iconName} />
        </View>
        <View style={styles.textContent}>
          <Text type="regular" bold lineSpacing="none">
            {title}
          </Text>
          <Text
            type="regular"
            lineSpacing="tight"
            style={styles.description}
            numberOfLines={3}
            adjustsFontSizeToFit
          >
            {description}
          </Text>
        </View>
        <Icon name="caret-right" style={styles.chevronRight} />
      </View>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    padding: 16,
    borderRadius: 24,
    backgroundColor: 'theme.secondary.base',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  iconContainer: {
    width: 64,
    height: 64,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'theme.secondary.dark',
    borderRadius: 32,
  },
  icon: {
    width: 24,
    height: 24,
    color: 'theme.primary.base',
  },
  textContent: {
    flex: 1,
    marginLeft: 16,
  },
  description: {
    marginTop: 8,
    color: 'theme.text.secondary',
  },
  chevronRight: {
    width: 16,
    height: 16,
    marginLeft: 16,
    color: 'theme.primary.base',
  },
})
