import React from 'react'
import { Text } from '@components/base'
import { LessonItemContentProps } from '../../lesson.types'
import { useLessonCompleted } from '../../hooks'
import { LessonItemContainer } from './LessonItemContainer'

export const UnknownComponent = (props: LessonItemContentProps) => {
  const { item, onComplete } = props

  useLessonCompleted(onComplete)

  return (
    <LessonItemContainer {...props} lessonItemIcon="warning" lessonItemTitle="Unknown type">
      <Text type="large">{`Unhandled item type: ${item.__typename}`}</Text>
    </LessonItemContainer>
  )
}
