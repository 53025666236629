import { Model } from '@models'
import commonReducers from '@src/models/commonReducers'
import { RecordedProgress } from '@src/types'
import { FETCH_COURSES } from '../graphql/fetchCourses'
import { FETCH_PROGRAMS } from '../graphql/fetchCmsPrograms'
import { FETCH_MODULES } from '../graphql/fetchCmsModules'
import { FETCH_LESSONS } from '../graphql/fetchCmsLessons'
import { RECORD_LESSON_PROGRESS } from '../graphql/recordLessonProgress'
import { attributesTransform, knownItemAttributesTransform } from '../transforms/courses'
import { CoursesStoreState } from './courses.types'

export default class Courses {
  namespace = 'courses'

  state = {
    ...Model.defaultState,

    // Structure and progress from the backend
    published: {
      programs: [],
    },

    // Content from the CMS
    programs: [],
    modules: [],
    lessons: [],
  }

  effects = {
    fetchCourses: Model.buildEffect({
      name: `${this.namespace}/fetchCourses`,
      query: FETCH_COURSES,
      dataPath: 'allCourses',
      reducers: [
        {
          name: 'fetchList',
          dataPath: 'programs',
          storePath: 'published.programs',
        },
      ],
    }),

    fetchPrograms: Model.buildEffect({
      name: `${this.namespace}/fetchPrograms`,
      query: FETCH_PROGRAMS,
      dataPath: 'programs',
      reducers: [
        {
          name: 'fetchList',
          dataPath: 'data',
          storePath: 'programs',
          transform: attributesTransform,
        },
      ],
    }),

    fetchModules: Model.buildEffect({
      name: `${this.namespace}/fetchModules`,
      query: FETCH_MODULES,
      dataPath: 'modules',
      reducers: [
        {
          name: 'fetchList',
          dataPath: 'data',
          storePath: 'modules',
          transform: attributesTransform,
        },
      ],
    }),

    fetchLessons: Model.buildEffect({
      name: `${this.namespace}/fetchLessons`,
      query: FETCH_LESSONS,
      dataPath: 'lessons',
      reducers: [
        {
          name: 'fetchList',
          dataPath: 'data',
          storePath: 'lessons',
          transform: knownItemAttributesTransform,
        },
      ],
    }),

    recordLessonProgress: Model.buildEffect({
      name: `${this.namespace}/recordLessonProgress`,
      query: RECORD_LESSON_PROGRESS,
      dataPath: 'recordLessonProgress',
      reducers: [{ name: 'updateCourseProgress' }],
    }),
  }

  reducers = {
    ...Model.defaultReducers,
    fetchList: commonReducers.fetchList,

    updateCourseProgress: (
      state: CoursesStoreState,
      { payload }: { payload: RecordedProgress },
    ) => {
      const programIndex = state.published.programs.findIndex((p) => p.id === payload.program.id)
      const program = programIndex > -1 ? state.published.programs[programIndex] : undefined

      const moduleIndex = program?.modules?.findIndex((m) => m.id === payload.module.id) ?? -1
      const module = (moduleIndex > -1 && program?.modules?.[moduleIndex]) || undefined

      const lessonIndex = module?.lessons?.findIndex((l) => l.id === payload.lesson.id) ?? -1
      const lesson = (lessonIndex > -1 && module?.lessons?.[lessonIndex]) || undefined

      if (!program || !module || !lesson || !program.modules || !module.lessons) {
        return state
      }

      const lessonState = {
        ...lesson,
        progress: payload.lesson.progress,
      }

      const moduleState = {
        ...module,
        progress: payload.module.progress,
        lessons: [
          ...module.lessons.slice(0, lessonIndex),
          lessonState,
          ...module.lessons.slice(lessonIndex + 1, module.lessons.length),
        ],
      }

      const programState = {
        ...program,
        progress: payload.program.progress,
        modules: [
          ...program.modules.slice(0, moduleIndex),
          moduleState,
          ...program.modules.slice(moduleIndex + 1, program.modules.length),
        ],
      }

      return {
        ...state,
        published: {
          ...state.published,
          programs: [
            ...state.published.programs.slice(0, programIndex),
            programState,
            ...state.published.programs.slice(programIndex + 1, state.published.programs.length),
          ],
        },
      }
    },
  }
}
