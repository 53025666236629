import React, { ReactNode, useState } from 'react'
import FastImage, { FastImageProps, ImageStyle } from 'react-native-fast-image'
import { View, Modal, TouchableOpacity } from 'react-native'
import ImageView from 'react-native-image-zoom-viewer'
import { ActivityIndicator } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@components/base'

interface ImageWithPlaceholderProps extends FastImageProps {
  placeholderComponent: ReactNode
}

export const ImageWithPlaceholder = (props: ImageWithPlaceholderProps) => {
  const styles = useStyleSheet(themedStyle)
  const { placeholderComponent, style, testID, ...restProps } = props

  const [isImageLoaded, setIsImageLoaded] = useState(false)

  return (
    <View>
      <FastImage
        testID={testID}
        style={[style, !isImageLoaded ? (styles.transparent as ImageStyle) : undefined]}
        {...restProps}
        onLoadStart={() => setIsImageLoaded(false)}
        onLoad={() => setIsImageLoaded(true)}
        onError={() => setIsImageLoaded(false)}
      />
      {!isImageLoaded && <View style={styles.placeholderContainer}>{placeholderComponent}</View>}
    </View>
  )
}

const withFullscreenImage = (WrappedComponent: React.ComponentType<ImageWithPlaceholderProps>) => {
  return (props: Omit<ImageWithPlaceholderProps, 'source'> & { source: { uri?: string } }) => {
    const [isFullscreenVisible, setIsFullscreenVisible] = useState(false)

    const insets = useSafeAreaInsets()

    const styles = useStyleSheet(themedStyle)

    if (!props.source.uri) {
      return <WrappedComponent {...props} />
    }

    const onRequestClose = () => {
      setIsFullscreenVisible(false)
    }

    return (
      <>
        <Modal
          animationType="fade"
          visible={isFullscreenVisible}
          onRequestClose={onRequestClose}
          hardwareAccelerated
        >
          <View style={styles.modalContent}>
            <View style={[styles.closeButtonContainer, { top: insets.top }]}>
              <TouchableOpacity
                accessible={false}
                style={styles.closeButton}
                onPress={onRequestClose}
                hitSlop={{ top: 16, left: 16, bottom: 16, right: 16 }}
                accessibilityLabel="Close"
              >
                <Icon name="x" style={styles.closeIcon} />
              </TouchableOpacity>
            </View>
            <ImageView
              imageUrls={[{ url: props.source.uri }]}
              onCancel={onRequestClose}
              renderIndicator={() => null as any}
              loadingRender={() => <ActivityIndicator animating size="small" {...styles.spinner} />}
              renderImage={(props) => <FastImage {...props} />}
              enableSwipeDown
              saveToLocalByLongPress={false}
            />
          </View>
        </Modal>
        <TouchableOpacity
          accessible={false}
          accessibilityLabel="setIsFullscreenVisible"
          onPress={() => setIsFullscreenVisible(true)}
        >
          <WrappedComponent {...props} />
        </TouchableOpacity>
      </>
    )
  }
}

export const ImageWithFullscreen = withFullscreenImage(ImageWithPlaceholder)

const themedStyle = StyleService.create({
  placeholderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  transparent: {
    opacity: 0,
  },
  spinner: {
    color: 'theme.text.secondary',
  },
  closeButtonContainer: {
    alignItems: 'flex-end',
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    top: 0,
  },
  closeButton: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16,
    marginTop: 16,
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: 'theme.surface.base',
  },
  closeIcon: {
    color: 'theme.solid.white',
  },
  modalContent: {
    flex: 1,
    backgroundColor: 'theme.solid.black',
  },
})
