import React, { ReactNode, useCallback } from 'react'
import XDate from 'xdate'
import moment from 'moment'
import { Calendar, DateData } from 'react-native-calendars'
import { View } from 'react-native'
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Text } from '@components/base'
import { useIsDarkMode } from '@src/config/theme'
import Day from './Day'

const HEADER_HEIGHT = 60
const HEADER_BUTTON_WIDTH = 60
const CALENDAR_HEADER_WIDTH = 218 // min width that fits "September 2020" with arrows

interface SchedulingCalendarProps {
  availableDates: string[]
  selectedDate: string | null
  onDayPress: (date: DateData) => void
}
export const SchedulingCalendar = (props: SchedulingCalendarProps) => {
  const styles = useStyleSheet(themedStyles)
  const { availableDates, selectedDate, onDayPress } = props
  const isDarkMode = useIsDarkMode()

  const colorConfig = styles.colors as {
    color: string
    textColor: string
  }

  const renderArrow = useCallback(
    (direction: 'left' | 'right') => {
      return <Icon name={`caret-${direction}`} style={styles.arrowIcon} />
    },
    [styles],
  )

  const renderHeader = useCallback(
    (date?: XDate): ReactNode => {
      return (
        <Text type="large" bold style={styles.headerTitle}>
          {date?.toString('MMMM yyyy')}
        </Text>
      )
    },
    [styles.headerTitle],
  )

  const mainStyles = useStyleSheet(calendarMainStyles)
  const headerStyles = useStyleSheet(commonCalendarHeaderStyles)

  const markedDates = () => {
    const dates = {} as { [key: string]: MarkingProps }
    if (!availableDates) {
      return dates
    }
    availableDates.map((date) => {
      return (dates[moment(date).format('YYYY-MM-DD')] = {
        disabled: false,
        selected: date === selectedDate,
        ...colorConfig,
      })
    })
    return dates
  }
  return (
    <View>
      <Calendar
        key={`${isDarkMode}${headerStyles.header.marginLeft}`}
        theme={{
          calendarBackground: 'transparent',
          // @ts-ignore https://github.com/wix/react-native-calendars/issues/1864
          'stylesheet.calendar.main': mainStyles,
          'stylesheet.calendar.header': headerStyles,
        }}
        style={styles.calendarContainer}
        initialDate={availableDates[0]}
        onDayPress={onDayPress}
        renderHeader={renderHeader}
        renderArrow={renderArrow}
        dayComponent={Day}
        markedDates={markedDates()}
        disabledByDefault
      />
    </View>
  )
}

const calendarMainStyles = StyleService.create({
  container: {
    padding: 0,
  },
  week: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
})

const commonCalendarHeaderStyles = StyleService.create({
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'center',
    width: CALENDAR_HEADER_WIDTH,
    height: HEADER_HEIGHT,
    marginLeft: 0,
  },
  week: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: 40,
  },
  dayHeader: {
    width: 32,
    textAlign: 'center',
    fontSize: 13,
    fontFamily: 'NBInternationalProReg',
    fontWeight: '400',
    color: 'theme.text.secondary',
  },
})

const themedStyles = StyleService.create({
  calendarContainer: {
    paddingBottom: 8,
    borderWidth: 2,
    borderRadius: 8,
    borderColor: 'theme.surface.base',
    marginBottom: 8,
  },
  headerButton: {
    position: 'absolute',
    right: 0,
    width: HEADER_BUTTON_WIDTH,
    height: HEADER_HEIGHT,
    marginRight: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderColor: 'theme.surface.base2',
  },
  headerTitle: {
    textAlign: 'center',
  },
  colors: {
    color: 'theme.primary.base',
    textColor: 'theme.solid.white',
  },
  arrowIcon: {
    height: 24,
    width: 24,
    color: 'theme.text.secondary',
  },
})
