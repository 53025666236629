import { gql } from '@apollo/client'

const DELETE_JOURNAL_ENTRY = gql`
  mutation deleteJournalEntry($id: ID!) {
    deleteJournalEntry(id: $id) {
      id
      title
      body
      occurredAt
      createdAt
      score
      measurements {
        id
        type
        title
        description
        value
        values {
          ... on SingleValueMeasurement {
            value
          }
        }
        units
        occurredAt
        createdAt
      }
    }
  }
`

export default DELETE_JOURNAL_ENTRY
