class IntercomClient {
  setUser = () => {
    return
  }

  updateUser = () => {
    return
  }

  reset = () => {
    return
  }

  showIntercomMessenger = () => {
    return
  }

  sendTokenToIntercom = () => {
    return
  }

  handlePushMessage = () => {
    return
  }
}

export default new IntercomClient()
