import { gql } from '@apollo/client'
import { APPOINTMENT_FIELDS } from './fragments/appointment'

export const ALL_APPOINTMENTS = gql`
  ${APPOINTMENT_FIELDS}
  query allAppointments(
    $sorts: [AppointmentSort!]
    $dynamicFilters: [AppointmentDynamicFilterItem!]
  ) {
    allAppointments(sorts: $sorts, dynamicFilters: $dynamicFilters) {
      appointments {
        ...AppointmentFields
      }
    }
  }
`
