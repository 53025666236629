import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BillingProduct, BillingProductCategory } from '@src/types.ts'
import { productsByCategorySelector } from '@selectors/app.ts'

const category = BillingProductCategory.Fee

export const useFeeProduct = (billingProduct: BillingProduct) => {
  const dispatch = useDispatch()
  const products = useSelector(productsByCategorySelector(category)) ?? []

  useEffect(() => {
    dispatch({
      type: 'app/fetchProducts',
      payload: { category },
    })
  }, [dispatch])

  return products.find((product) => product.key === billingProduct)
}
