import React from 'react'
import { Control } from 'react-hook-form'
import { View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { AsYouType } from 'libphonenumber-js'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { Feature, ScrollableAvoidKeyboard, useFeatureFlag } from '@components'
import { InlineAlert } from '@src/components/InlineAlert'
import { Validators } from '@utils'
import { AddressCountries, UiInlineAlert } from '@src/types'
import { getCountryCodeByCountry } from '@src/utils/phoneInput'
import { userSelector } from '@src/selectors/app'
import { ControllerComponent } from '@src/components/Form/ControllerComponent'
import { AddressAutoComplete, AddressSelect, AddressTextInput } from '@src/components/Address'
import { isValidField } from '@src/utils/form'

const COUNTRY_DEPENDENT_LABELS = {
  [AddressCountries.Us]: {
    state: 'State',
    zipCode: 'Zip Code',
  },
  [AddressCountries.Ca]: {
    state: 'Province',
    zipCode: 'Postal Code',
  },
  [AddressCountries.Gb]: {
    state: undefined,
    zipCode: 'Postal Code',
  },
}

interface Place {
  description: string
  // eslint-disable-next-line camelcase
  place_id: string
}

interface AddressModalProps {
  placesMatchingSearch: Place[]
  loadAddressData: (place: Place) => void
  onSubmit: () => void
  control: Control<any>
  country: AddressCountries
  state: string
  isValid: boolean
  states: { text: string; value: string }[]
  allowCountryChange: boolean
  phoneNumber: string
  showPhoneNumber: boolean
  showShippingAddressAlert: boolean
}

export const AddressModal = ({
  placesMatchingSearch,
  loadAddressData,
  onSubmit,
  control,
  country,
  state,
  isValid,
  states,
  allowCountryChange,
  phoneNumber,
  showPhoneNumber,
  showShippingAddressAlert,
}: AddressModalProps) => {
  const styles = useStyleSheet(themedStyle)

  const user = useSelector(userSelector)

  const address = user?.address

  const statesMatchingSearch = states.filter((item) =>
    item.text.toLowerCase().includes(state.toLowerCase()),
  )
  const isStateValid = (text: string) => {
    return states.some((item) => item.text.toLowerCase() === text.toLowerCase())
  }

  const insets = useSafeAreaInsets()

  const usOption = { value: AddressCountries.Us, text: 'United States' }
  const caOption = { value: AddressCountries.Ca, text: 'Canada' }
  const ukOption = { value: AddressCountries.Gb, text: 'United Kingdom' }

  const countryOptions = [usOption, caOption]

  const enableUkSupport = useFeatureFlag(Feature.UkSupport)

  if (enableUkSupport) {
    countryOptions.push(ukOption)
  }

  const selectedCountryOption = countryOptions.find(({ value }) => value === country)

  const countryCode = getCountryCodeByCountry(country)

  return (
    <ScrollableAvoidKeyboard
      style={styles.container}
      contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
      bounces={false}
    >
      <View>
        <ControllerComponent
          Component={allowCountryChange ? AddressSelect : AddressTextInput}
          useFieldRef={false}
          control={control}
          required
          label="Country"
          name="country"
          selectedOption={selectedCountryOption}
          value={selectedCountryOption?.text}
          options={countryOptions}
          disabled={!allowCountryChange}
        />
        <ControllerComponent
          control={control}
          Component={AddressTextInput}
          name="name"
          label="Full Name"
          placeholder="Jeremy Clarks"
          required
          validate={(value) => isValidField(country)(value, 'name')}
        />
        <ControllerComponent
          control={control}
          Component={AddressAutoComplete}
          name="street"
          label="Street"
          placeholder="53 Fox Hill Lane"
          required
          validate={(value) => isValidField(country)(value, 'street')}
          onSelect={(idx: number) => {
            loadAddressData(placesMatchingSearch[idx])
          }}
          options={placesMatchingSearch.map((place) => ({
            value: place.place_id,
            text: place.description,
          }))}
        />
        <ControllerComponent
          control={control}
          Component={AddressTextInput}
          label="Street 2"
          name="street2"
          placeholder="apt. 52"
        />
        <ControllerComponent
          control={control}
          Component={AddressTextInput}
          label="City"
          name="city"
          placeholder="Center Conway"
          required
          validate={(value) => isValidField(country)(value, 'city')}
        />
        {COUNTRY_DEPENDENT_LABELS[country].state && (
          <ControllerComponent
            control={control}
            Component={AddressAutoComplete}
            label={COUNTRY_DEPENDENT_LABELS[country].state}
            placeholder="NH"
            name="state"
            required
            options={statesMatchingSearch}
            validate={(value) => isStateValid(value)}
          />
        )}
        <ControllerComponent
          control={control}
          Component={AddressTextInput}
          label={COUNTRY_DEPENDENT_LABELS[country].zipCode}
          name="zipCode"
          required
          placeholder="00001"
          validate={(value) => isValidField(country)(value, 'zipCode')}
        />
        {(showPhoneNumber || (address && address.country !== country)) && (
          <ControllerComponent
            control={control}
            Component={AddressTextInput}
            label="Phone Number"
            name="phoneNumber"
            required
            placeholder="Phone Number"
            keyboardType="phone-pad"
            validate={(value) => Validators.PhoneNumberValidator(value, countryCode)}
            onChangeText={(text: string) => {
              const newText = text.trim()
              const formattedNewText = new AsYouType(countryCode).input(newText)

              if (newText.length < (phoneNumber || '').length && formattedNewText === phoneNumber) {
                // do not re-format on character removal
                return newText
              }

              return formattedNewText
            }}
          />
        )}
        {showShippingAddressAlert && (
          <InlineAlert
            style={styles.alert}
            category={UiInlineAlert.Info}
            message={
              'Please verify that your shipping address is accurate' +
              ' and current prior to your payment method being charged. ' +
              `Failure to do so will result in your product being shipped to the address on file at the time of billing.`
            }
          />
        )}
      </View>
      <Button
        type="primary"
        size="block"
        disabled={!isValid}
        style={styles.button}
        onPress={onSubmit}
        accessibilityLabel="Save"
        allowPressWhenDisabled
      >
        Save
      </Button>
    </ScrollableAvoidKeyboard>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
    padding: 16,
  },
  content: {
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'space-between',
  },
  input: {
    marginBottom: 16,
  },
  alert: {
    marginVertical: 16,
  },
  button: {
    marginTop: 24,
    marginBottom: 16,
  },
})
