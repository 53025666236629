import { gql } from '@apollo/client'
import { CORE_MEASUREMENT_FIELDS } from '@src/graphql/fragments/measurement'

const CREATE_MEASUREMENT = gql`
  ${CORE_MEASUREMENT_FIELDS}
  mutation createMeasurement($id: ID, $type: String!, $values: MultiValue!, $occurredAt: String!) {
    createMeasurement(id: $id, type: $type, values: $values, time: $occurredAt) {
      ...CoreMeasurementFields
      values {
        ... on BloodPressureMeasurement {
          systolic
          diastolic
        }
        ... on SingleValueMeasurement {
          value
        }
      }
    }
  }
`

export default CREATE_MEASUREMENT
