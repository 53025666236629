import React, { useContext } from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@components/base'
import { IntercomContext } from '@src/containers/Intercom'

export const MessageIcon = () => {
  const styles = useStyleSheet(themedStyles)
  const { unreadMessagesCount } = useContext(IntercomContext)

  return (
    <View style={styles.iconContainer}>
      <Icon name="chat-circle-text" />
      {unreadMessagesCount > 0 && <View style={styles.unreadMessageDot} />}
    </View>
  )
}

const themedStyles = StyleService.create({
  iconContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    marginRight: 12,
  },
  unreadMessageDot: {
    position: 'absolute',
    top: 4,
    right: -8,
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: 'theme.error.base',
  },
})
