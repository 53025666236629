import React from 'react'
import { TouchableOpacity } from 'react-native'
import moment from 'moment'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Text } from '@components/base'
import { DATE_FORMAT } from '@config/momentFormat'
import { Calendar } from '@models/app.types'
import { Haptic } from '@utils'
import { DateRange, calculateRangeDays } from '@src/models/helper'
import { CALENDAR_TABS } from '../../constants'

interface CalendarTabsProps {
  calendar: Calendar
  onChange: (newCalendar: Calendar) => void
}

export const CalendarTabs = ({ calendar, onChange }: CalendarTabsProps) => {
  const styles = useStyleSheet(themedStyle)

  const handleTabPress = ({ startDate, endDate }: DateRange) => {
    Haptic.heavyTap()

    const rangeDays = calculateRangeDays(startDate, endDate)

    onChange({
      ...calendar,
      startDate,
      endDate,
      rangeDays,
    })
  }

  const renderTab = ([key, value]: [string, string]) => {
    const numberOfDays = Number(key)
    const tabStartDate = moment()
      .subtract(numberOfDays - 1, 'days')
      .format(DATE_FORMAT)
    const tabEndDate = moment().format(DATE_FORMAT)
    const isTabActive = calendar.startDate === tabStartDate && calendar.endDate === tabEndDate

    return (
      <TouchableOpacity
        key={key}
        accessibilityLabel={value}
        onPress={() => handleTabPress({ startDate: tabStartDate, endDate: tabEndDate })}
        style={[styles.tab, isTabActive ? styles.activeTab : styles.defaultTab]}
      >
        <Text type="regular" style={isTabActive && styles.activeTabLabel}>
          {value}
        </Text>
      </TouchableOpacity>
    )
  }

  return Object.entries(CALENDAR_TABS).map(renderTab)
}

const themedStyle = StyleService.create({
  tab: {
    height: 48,
    borderRadius: 32,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  defaultTab: {
    backgroundColor: 'theme.surface.base2',
  },
  activeTab: {
    backgroundColor: 'theme.primary.base',
  },
  activeTabLabel: {
    color: 'theme.solid.white',
  },
})
