import React from 'react'
import { Keyboard, TouchableOpacity, Insets, ViewStyle } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigation } from '@react-navigation/native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { clientConfigStoreStateSelector } from '@src/selectors/app'
import { Icon, Text, IconName } from '@components/base'

interface TutorialLinkProps {
  tutorialKey: string
  showText?: boolean
  hitSlop?: Insets
  style?: ViewStyle
  iconStyle?: ViewStyle
  iconName?: IconName
}

const defaultHitSlop = { top: 10, bottom: 10, right: 5, left: 5 }

export const TutorialLink = ({
  hitSlop = defaultHitSlop,
  iconName = 'info',
  iconStyle,
  showText = true,
  style,
  tutorialKey,
}: TutorialLinkProps) => {
  const navigation = useNavigation()
  const styles = useStyleSheet(themedStyles)
  const {
    onboarding: { tutorials },
  } = useSelector(clientConfigStoreStateSelector)

  const showTutorial = () => {
    Keyboard.dismiss()
    navigation.navigate('ShowTutorial', { tutorial: tutorials[tutorialKey] })
  }

  return (
    <TouchableOpacity
      accessibilityLabel={iconName}
      style={[styles.hint, style]}
      onPress={showTutorial}
      hitSlop={hitSlop}
    >
      <Icon style={[styles.hintIcon, iconStyle]} name={iconName} />
      {showText && (
        <Text type="small" style={styles.hintText}>
          Learn more
        </Text>
      )}
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  hint: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  hintIcon: {
    width: 20,
    height: 20,
    color: 'theme.text.secondary',
  },
  hintText: {
    marginLeft: 4,
    color: 'theme.text.secondary',
  },
})
