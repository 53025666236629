import React, { useEffect } from 'react'
import { ScrollView, TouchableOpacity, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { startCase } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, Pill, Text } from '@components/base'
import { NavigationContainer } from '@screens/Common/containers'
import { authenticatedUserSelector } from '@selectors/app'
import { useGoBack } from '@utils'
import { SurveyLink, SurveysConfigKind } from '@src/types'

export const ResearchSurveys = () => {
  const styles = useStyleSheet(themedStyle)
  const navigation = useNavigation()
  const goBack = useGoBack()
  const dispatch = useDispatch()

  const user = useSelector(authenticatedUserSelector)

  const researchSurveys = user.surveyLinks
    .filter(({ survey }) => survey.research)
    .sort((a, b) => moment(b.startedAt).valueOf() - moment(a.startedAt).valueOf())

  const handleSurveyPress = (
    surveyLink: Pick<SurveyLink, 'finished'> & { survey: { kind: SurveysConfigKind } },
  ) => {
    if (surveyLink.finished) {
      return
    }

    navigation.navigate('Questionnaire', {
      questionnaire: surveyLink.survey.kind,
      allowEarlyExit: true,
    })
  }

  useEffect(() => {
    dispatch({ type: 'users/fetch' })
  }, [dispatch])

  return (
    <NavigationContainer style={styles.container} title="Research Surveys" goBack={goBack}>
      <ScrollView contentContainerStyle={styles.content} showsVerticalScrollIndicator={false}>
        {researchSurveys.map((surveyLink) => (
          <TouchableOpacity
            accessibilityLabel="Survey"
            key={surveyLink.id}
            activeOpacity={surveyLink.finished ? 1 : 0.7}
            style={styles.surveyContainer}
            onPress={() => handleSurveyPress(surveyLink)}
          >
            <View style={styles.surveyInfo}>
              <Text type="regular" bold numberOfLines={1} style={styles.surveyName}>
                {surveyLink.survey.header || startCase(surveyLink.survey.kind)}
              </Text>
              <Text type="small" style={styles.surveyTime}>
                {moment(surveyLink.startedAt).format('L - LT')}
              </Text>
            </View>
            <Pill
              text={surveyLink.finished ? 'Completed' : 'Incomplete'}
              status={surveyLink.finished ? 'success' : 'neutral'}
              style={styles.statusPill}
            />
            <Icon name="caret-right" style={styles.icon} weight="bold" />
          </TouchableOpacity>
        ))}
        {researchSurveys?.length === 0 && (
          <Text type="regular" style={styles.noData}>
            No research surveys found
          </Text>
        )}
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    flex: 1,
    backgroundColor: 'theme.background',
  },
  content: {
    padding: 16,
  },
  surveyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 64,
    borderBottomWidth: 2,
    borderBottomColor: 'theme.surface.base2',
  },
  surveyInfo: {
    flexShrink: 1,
    marginRight: 16,
  },
  surveyName: {
    flexShrink: 1,
  },
  surveyTime: {
    color: 'theme.text.secondary',
  },
  statusPill: {
    marginLeft: 'auto',
    marginRight: 12,
  },
  icon: {
    width: 16,
    height: 16,
    color: 'theme.surface.base0',
  },
  noData: {
    marginTop: 24,
    textAlign: 'center',
    color: 'theme.text.tertiary',
  },
})
