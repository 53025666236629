import moment from 'moment'
import pluralize from 'pluralize'
import React, { useEffect, useRef, useState } from 'react'
import { View, ViewStyle } from 'react-native'
import { StyleService } from '@src/style/service'
import { Text } from '@components/base'
import { Slider } from './Slider'

const LOW_VALUE = 0
const HIGH_VALUE = 24

export interface TimeOfDaySliderProps {
  startValue: number
  endValue: number
  min?: number
  max?: number
  modulo?: number
  onChange: (time: 'startHour' | 'endHour') => (value: number) => void
  containerStyle?: ViewStyle
}

const usePrevious = (value: any) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const TimeOfDaySlider = ({
  endValue = HIGH_VALUE,
  startValue = LOW_VALUE,
  min = LOW_VALUE,
  max = HIGH_VALUE,
  modulo,
  onChange,
  containerStyle = {},
}: TimeOfDaySliderProps) => {
  const [startTime, setStartTime] = useState(startValue)
  const [endTime, setEndTime] = useState(endValue)

  const startEndDiff = endTime - startTime

  useEffect(() => {
    setStartTime(startValue)
    setEndTime(endValue)
  }, [startValue, endValue])

  const prevStartTime = usePrevious(startTime)
  const prevEndTime = usePrevious(endTime)

  const updateSliderHours = (sliderValues: number[]) => {
    const [start, end] = sliderValues
    if (prevStartTime !== start) {
      setStartTime(start)
    }
    if (prevEndTime !== end) {
      setEndTime(end)
    }
  }

  const handleSliderValueChange = () => {
    if (prevStartTime !== startTime) {
      onChange('startHour')(startTime)
    } else {
      onChange('endHour')(endTime)
    }
  }

  const lowText = moment(modulo ? startTime % modulo : startTime, 'h').format('hh:mm a')
  const highText = moment(modulo ? endTime % modulo : endTime, 'h').format('hh:mm a')

  return (
    <View style={containerStyle}>
      <View style={[styles.labelContainer]}>
        <Text type="small" testID="TimeOfDay/Min">
          {lowText}
        </Text>
        <Text type="small">{pluralize('hour', startEndDiff, true)}</Text>
        <Text type="small" testID="TimeOfDay/Max">
          {highText}
        </Text>
      </View>

      <Slider
        testID="TimeOfDay/MultiSlider"
        min={min}
        max={max}
        step={1}
        values={[startTime, endTime]}
        onValuesChange={updateSliderHours}
        onValuesChangeFinish={handleSliderValueChange}
      />
    </View>
  )
}

const styles = StyleService.create({
  labelContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
})
