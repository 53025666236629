import { gql } from '@apollo/client'

export const CREATE_DIRECT_UPLOAD = gql`
  mutation createDirectUpload($file: FileMetadata!) {
    createDirectUpload(file: $file) {
      url
      signedId
      headers {
        key
        value
      }
    }
  }
`
