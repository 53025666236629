import React from 'react'
import { View, ViewProps } from 'react-native'

interface FlexViewProps extends ViewProps {
  children?: React.ReactNode
}

export const FlexView = (props: FlexViewProps) => {
  const { style, ...restOfProps } = props
  return <View style={[{ flex: 1 }, style]} {...restOfProps} />
}
