/* eslint-disable max-len */
import * as React from 'react'
import { Path } from 'react-native-svg'
import NsPermission from './nsPermission'

const NsBluetooth = () => {
  return (
    <NsPermission>
      <Path
        d="M184.625 32.5l11 8.25-11 8.25V32.5zM184.625 49l11 8.25-11 8.25V49zM173.625 40.75l11 8.25M173.625 57.25l11-8.25"
        stroke="#3599EF"
        strokeWidth={2.44037}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </NsPermission>
  )
}

export default NsBluetooth
