import React from 'react'
import { View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon, IconName } from '@components/base'

export interface TabBarIconProps {
  iconName: IconName
  focused: boolean
  redDot?: boolean
}

export const TabBarIcon = ({ iconName, focused, redDot = false }: TabBarIconProps) => {
  const styles = useStyleSheet(themedStyle)
  const focusedStyle = focused && styles.focusedIcon

  return (
    <View>
      <Icon style={[styles.icon, focusedStyle]} name={iconName} />
      {redDot && <Icon name="circle" style={styles.unreadMessageDot} weight="fill" />}
    </View>
  )
}

const themedStyle = StyleService.create({
  icon: {
    marginTop: 8,
    color: 'theme.text.tertiary',
  },
  focusedIcon: {
    color: 'theme.text.primary',
  },
  unreadMessageDot: {
    position: 'absolute',
    top: 4,
    right: -8,
    width: 8,
    height: 8,
    color: 'theme.error.base',
  },
})
