import React, { ReactNode, createContext, useCallback, useState } from 'react'
import { useContext } from 'react'
import { StackNavigationProp } from '@react-navigation/stack'
import { useNavigation } from '@react-navigation/core'
import { AppStackParamList } from '@src/navigation/types'
import { ActionButtonProps } from '../BottomSheet'

interface ActionBottomSheetProps {
  icon?: ReactNode
  title?: string
  body?: string | ReactNode
  primaryButton?: ActionButtonProps
  secondaryButton?: ActionButtonProps
}

interface ActionBottomSheetContextProps {
  props: ActionBottomSheetProps
  setProps: (props: ActionBottomSheetProps) => void
}

const actionBottomSheetDefaultProps: ActionBottomSheetProps = {}

interface ActionBottomSheetProviderProps {
  children: ReactNode
}

export const ActionBottomSheetContext = createContext<ActionBottomSheetContextProps>({
  props: actionBottomSheetDefaultProps,
  setProps: () => {},
})

export const ActionBottomSheetProvider = ({ children }: ActionBottomSheetProviderProps) => {
  const [props, setProps] = useState<ActionBottomSheetProps>(actionBottomSheetDefaultProps)
  return (
    <ActionBottomSheetContext.Provider value={{ props, setProps }}>
      {children}
    </ActionBottomSheetContext.Provider>
  )
}
export const useActionBottomSheet = () => {
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>()
  const { setProps } = useContext(ActionBottomSheetContext)

  return useCallback(
    (props: ActionBottomSheetProps) => {
      setProps(props)

      navigation.navigate('ActionBottomSheet')
    },
    [setProps, navigation],
  )
}
