import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { View } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { SafeAreaView } from 'react-native-safe-area-context'
import moment, { Moment } from 'moment'
import { MarkedDates } from 'react-native-calendars/src/types'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Button } from '@components/base'
import { DateRangePicker, DismissibleModal } from '@components'
import { DATE_FORMAT } from '@src/config/momentFormat'
import { scoreColor } from '@src/components/nutritionCard/utils'
import { scoreSelector } from '@src/selectors/app'
import { Calendar } from '@src/models/app.types'
import { DateRangePickerRef } from '@components/DateRangePicker/DateRangePicker.types'

interface CalendarModalComponentProps {
  calendar: Calendar
  allowRangeSelection: boolean
  loadCalendarData: (date: Moment) => void
  onDateChange: (startDate: string, endDate: string) => void
  onCancelButtonPress?: () => void
  onIconPress?: () => void
  onDatePickerRangeSelectionStart: () => void
}

export const CalendarModalComponent = forwardRef<DateRangePickerRef, CalendarModalComponentProps>(
  (props, ref) => {
    const {
      calendar: { startDate, endDate, calendarData = [] },
      allowRangeSelection,
      loadCalendarData,
      onDateChange,
      onCancelButtonPress,
      onDatePickerRangeSelectionStart,
    } = props
    const styles = useStyleSheet(themedStyles)
    const theme = useTheme()
    const { warning, danger } = useSelector(scoreSelector)

    const markedDates: MarkedDates = {}
    const todayDate = moment().format(DATE_FORMAT)
    const items = calendarData.filter((item) => item.score)

    items.forEach(({ score, date }) => {
      markedDates[date] = {
        marked: true,
        dotColor: theme[scoreColor(score, danger, warning)],
      }
    })

    return (
      <DismissibleModal modalStyle={styles.modalContainer}>
        <SafeAreaView edges={['bottom']}>
          <View>
            <DateRangePicker
              ref={ref}
              markedDates={markedDates}
              initialRange={[startDate, endDate]}
              allowRangeSelection={allowRangeSelection}
              loadCalendarData={loadCalendarData}
              onSuccess={onDateChange}
              onRangeSelectionStart={onDatePickerRangeSelectionStart}
              showTutorialLink={allowRangeSelection}
              enableSwipeMonths
            />
          </View>
          <View style={styles.buttonContainer}>
            <Button
              type="outline"
              size="large"
              accessibilityLabel="Today"
              onPress={() => onDateChange(todayDate, todayDate)}
              style={[styles.button, styles.todayButton]}
            >
              TODAY
            </Button>
            <Button
              type="outline"
              size="large"
              accessibilityLabel="Cancel"
              onPress={onCancelButtonPress}
              style={styles.button}
            >
              CANCEL
            </Button>
          </View>
        </SafeAreaView>
      </DismissibleModal>
    )
  },
)

const themedStyles = StyleService.create({
  modalContainer: {
    marginTop: 'auto',
    paddingBottom: 16,
  },
  buttonContainer: {
    marginHorizontal: 24,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    flex: 1,
  },
  todayButton: {
    marginRight: 34,
  },
})
