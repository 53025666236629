import { gql } from '@apollo/client'
import { CORE_MEAL_FIELDS } from '@src/graphql/fragments/meal'

const ALL_MEALS = gql`
  ${CORE_MEAL_FIELDS}
  query allMeals($pagination: Pagination, $filter: MealFilter) {
    allMeals(pagination: $pagination, filter: $filter) {
      meals {
        ...CoreMealFields
      }
    }
  }
`

export default ALL_MEALS
