import { useNavigation, useRoute } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDispatch, useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { InsightsRouteProp } from '@src/navigation/types'
import { NavigationContainer } from '@src/screens/Common/containers'
import { useGoBack } from '@src/utils/navigation'
import { insightsCalendarSelector } from '@src/screens/Insights/models/insights.selectors'
import { HealthDataMeasurementType, Period, Statistic } from '@src/types'
import { Point } from '@src/screens/Insights/types'
import { ByDateColumnChart } from '@src/components/charts/ByDateColumnChart/ByDateColumnChart'
import { CommonRefreshControl, RangesLegend, Feature, useFeatureFlag } from '@components'
import {
  CalendarBar,
  LineChartController,
  ScoreArcIndicator,
  ScoreOverview,
  ScoreBreakdown,
} from '@src/screens/Insights/components'
import { ChartQueryType, DetailedFetchKeyMap } from '@src/screens/Insights/helper'
import { useChartData } from '@src/screens/Insights/components/ScoreChart/useChartData'
import { MealTypeChartController } from '@src/screens/Insights/components/MealTypeChart/MealTypeChartController'
import { PeriodsDisplayType } from '@src/screens/Insights/components/ScoreChart/ByHourChart'
import { ByHourChartManager } from '@src/screens/Insights/components/ScoreChart/ByHourChartManager'
import { WithinTimeChartManager } from '@src/screens/Insights/components/ScoreChart/WithinTimeChartManager'
import { MeasurementEnum, SectionEnum, usePageConfig } from '@src/screens/Insights/utils/pageConfig'
import { ScoreChart } from '@src/screens/Insights/components/ScoreChart/ScoreChart'
import { useFetchStats } from '@src/screens/Insights/utils/hooks'
import { isDetailsNavigatedByUrl, isScoreSection } from '@src/screens/Insights/utils/utils'
import { Button, Icon, Text } from '@components/base'
import { WeekOverWeekToolTip, WeekOverWeekToolTipProps } from '../components/WeekOverWeekTooltip'
import { ScoreOverviewProps } from '../components/ScoreOverview'
import { HealthProviderBanner } from '../components/HealthProviderBanner'

export const DetailedPage = () => {
  const dispatch = useDispatch()
  const route = useRoute<InsightsRouteProp<'InsightsDetailedPage'>>()
  const { params } = route

  const { section, key } =
    'statistic' in params
      ? params.statistic.meta
      : { section: params.statSection, key: params.statMetaKey }

  const navigation = useNavigation()
  const goBack = useGoBack()

  const [refreshing, setRefreshing] = useState(false)

  const config = usePageConfig(section, key)
  const calendar = useSelector(insightsCalendarSelector)

  const styles = useStyleSheet(themedStyle)
  const { refetch: refetchStats } = useFetchStats()
  const insets = useSafeAreaInsets()

  const enableTooltip = useFeatureFlag(Feature.InsightsTooltip)

  const outcomesTrackingEnabled = useFeatureFlag(Feature.OutcomesTracking)

  const isWeightMeasurementStat =
    section === SectionEnum.MEASUREMENTS && key === MeasurementEnum.WEIGHT

  const [metric, setMetric] = useState<Statistic | null>(
    'statistic' in params ? params.statistic : ({} as Statistic),
  )

  useEffect(() => {
    if (!section || !config) {
      goBack()
    }
  }, [config, section, goBack])

  useEffect(() => {
    if (!isDetailsNavigatedByUrl(params)) {
      return
    }

    setRefreshing(true)

    const { statSection, statMetaKey, startDate, endDate } = params
    const filter = {
      startDate,
      endDate,
      types: [
        {
          key: statSection,
          value: [statMetaKey],
        },
      ],
    }

    dispatch({
      type: 'insights/fetchStats',
      payload: {
        filter,
      },
      success: (stats: { statistics: Statistic[] }) => {
        const statToDisplay = stats.statistics.find((stat) => stat.meta.key === statMetaKey)
        if (!statToDisplay) {
          return
        }
        dispatch({
          type: 'insights/changeDateRange',
          payload: { startDate, endDate },
        })
        setMetric(statToDisplay)
      },
      complete: () => {
        setRefreshing(false)
      },
    })
  }, [dispatch, navigation, params])

  const {
    showScores,
    chartsToDisplay = [],
    chartQueryType,
    yAxisConfig,
    title,
    showData,
    showWeeklyDiffTooltip,
    customOverview,
  } = config || {}

  const fetchKey = chartQueryType ? DetailedFetchKeyMap[chartQueryType] : ''
  const { data, refetch: refetchCharts } = useChartData<Point>(
    calendar,
    fetchKey,
    section,
    Period.Day,
  )

  if (!config) {
    return null
  }

  const yLabel = metric?.units || config?.yLabel || ''
  const [currentData, previousData] = data
  const showScoreArc = showScores && calendar.rangeDays === 1

  const onRefresh = async () => {
    setRefreshing(true)
    try {
      await Promise.all([refetchCharts(), refetchStats()])
    } finally {
      setRefreshing(false)
    }
  }

  return (
    <NavigationContainer
      title={title}
      style={styles.container}
      showLoadingIndicator={!refreshing}
      goBack={goBack}
    >
      <ScrollView
        contentContainerStyle={[styles.content, { paddingBottom: insets.bottom + 16 }]}
        showsVerticalScrollIndicator={false}
        refreshControl={<CommonRefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      >
        {outcomesTrackingEnabled && isWeightMeasurementStat && <HealthProviderBanner />}
        <CalendarBar />
        {showScoreArc && <ScoreArcIndicator scoreKey={key} title={title as string} />}
        {!showScoreArc && showData && (
          <>
            <ScoreOverview
              data={currentData}
              statMetaKey={key as ScoreOverviewProps['statMetaKey']}
              statMetaSection={section}
              defaultUnit={config.units}
              customOverview={customOverview}
            />
            {showWeeklyDiffTooltip && enableTooltip && (
              <WeekOverWeekToolTip
                statMetaKey={key as WeekOverWeekToolTipProps['statMetaKey']}
                statName={title as string}
              />
            )}
          </>
        )}
        {chartsToDisplay.includes('line') && (
          <>
            {calendar.rangeDays > 1 && (
              <LineChartController
                data={currentData}
                type={chartQueryType as ChartQueryType}
                yAxisConfig={yAxisConfig}
                yLabel={yLabel}
              />
            )}
            <RangesLegend metric={key} style={styles.rangesLegend} />
          </>
        )}
        {chartsToDisplay.includes('byDate') && calendar.rangeDays > 1 && (
          <ByDateColumnChart data={currentData} yLabel={yLabel} />
        )}
        {outcomesTrackingEnabled && isWeightMeasurementStat && (
          <Button
            style={styles.button}
            type="primary"
            size="small"
            accessibilityLabel="Add Weight"
            onPress={() => {
              navigation.navigate('AddMeasurement', {
                type: HealthDataMeasurementType.Weight,
              })
            }}
            accessoryRight={<Icon name="plus" weight="bold" />}
          >
            Add Weight
          </Button>
        )}
        <View style={styles.metricDetails}>
          <Text type="large" bold style={styles.title}>
            {title}
          </Text>
          {!!metric?.description && <Text type="regular">{metric.description}</Text>}
        </View>
        {showScores && isScoreSection(key) && <ScoreBreakdown score={key} />}
        {chartsToDisplay.includes('byTimeInRange') && <WithinTimeChartManager section={section} />}
        {chartsToDisplay.includes('byHour') && (
          <ByHourChartManager
            type={chartQueryType as ChartQueryType}
            section={section}
            yLabel={yLabel}
            periodsDisplayType={
              key === DetailedFetchKeyMap.GLUCOSE_SLEEP_AVERAGE
                ? PeriodsDisplayType.OnlySleepHours
                : PeriodsDisplayType.AllPeriods
            }
          />
        )}
        {chartsToDisplay.includes('weekly') && calendar.rangeDays >= 2 && (
          <ScoreChart
            currentData={currentData}
            previousData={previousData}
            type={chartQueryType as ChartQueryType}
            section={section}
            yLabel={yLabel}
          />
        )}
        {chartsToDisplay.includes('meal') && <MealTypeChartController />}
      </ScrollView>
    </NavigationContainer>
  )
}

const themedStyle = StyleService.create({
  container: {
    backgroundColor: 'theme.background',
  },
  content: {
    backgroundColor: 'theme.background',
  },
  row: {
    flexDirection: 'row',
  },
  button: {
    marginHorizontal: 16,
    marginTop: 12,
    alignSelf: 'auto',
  },
  metricDetails: {
    marginHorizontal: 16,
    marginTop: 24,
    marginBottom: 32,
  },
  rangesLegend: {
    marginHorizontal: 16,
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderRadius: 16,
    backgroundColor: 'theme.surface.base3',
  },
  title: {
    marginBottom: 8,
  },
})
