import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { StyleService, useStyleSheet } from '@src/style/service'
import { Icon } from '@components/base'
import { MealItem } from '@screens/Meals/components/CompareMeals'
import { Meal } from '@src/types'

interface MealComparisonItemProps {
  meal: Meal
  onRemove: () => void
}

export const MealComparisonItem = ({ meal, onRemove }: MealComparisonItemProps) => {
  const styles = useStyleSheet(themedStyle)

  return (
    <View style={styles.comparisonItem}>
      <MealItem meal={meal} />
      <TouchableOpacity
        accessibilityLabel="Remove meal from comparison"
        hitSlop={{ top: 8, right: 8, bottom: 8, left: 8 }}
        style={styles.removeButton}
        onPress={onRemove}
      >
        <Icon name="x" weight="bold" style={styles.removeIcon} />
      </TouchableOpacity>
    </View>
  )
}

const themedStyle = StyleService.create({
  comparisonItem: {
    marginBottom: 16,
    borderRadius: 8,
    borderWidth: 2,
    borderColor: 'theme.surface.base1',
  },
  removeButton: {
    position: 'absolute',
    top: -8,
    right: -8,
    alignItems: 'center',
    justifyContent: 'center',
    width: 20,
    height: 20,
    borderRadius: 10,
    backgroundColor: 'theme.text.tertiary',
  },
  removeIcon: {
    width: 12,
    height: 12,
    color: 'theme.solid.white',
  },
})
