export enum JournalMeasurementEnum {
  MOOD = 'mood',
  ENERGY = 'energy',
  FOCUS = 'focus',
  STRESS = 'stress',
  APPETITE = 'appetite',
}

export interface JournalMeasurement {
  id?: number
  type: string
  value: number
}

export interface JournalEngagementPromptConfig {
  promptLastDisplayDate: string
}
