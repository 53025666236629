export interface DataPoint {
  x: string
  y: number
  tag: string
}

export interface SliderChartConfig {
  data: DataPoint[]
  min: number
  max: number
  padding: string
  style: any
  connectNulls: boolean
  disableAnimation: boolean
}

export const sliderMiniChart = ({
  data,
  min,
  max,
  padding,
  style,
  connectNulls = false,
  disableAnimation,
}: SliderChartConfig) => `(function(){
  chart = new F2.Chart({
    id: 'chart',
    pixelRatio: window.devicePixelRatio,
    padding: ${padding},
  });

  chart.source(${JSON.stringify(data)}, {
    y: {
      tickCount: 10,
      min: ${min},
      max: ${max}
    },
    x: {
      nice: false,
      range: [0, 1],
      tickCount: 3,
    }
  });
  chart.axis(false);
  chart.tooltip(false);
  chart.legend(false);

  chart.area({connectNulls: ${connectNulls}}).position('x*y').style({
    stroke: '${style.strokeColor}',
    lineWidth: ${style.lineWidth},
    fill: "${style?.fillColor}",
    fillOpacity: '${style?.fillOpacity || 1}',
  })

  ${disableAnimation ? 'chart.animate(false);' : ''}

  chart.render();
})(); true;
`
