import { filter, some } from 'lodash'
import React from 'react'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { StyleService, useStyleSheet } from '@src/style/service'
import { CommonChart } from '@src/components'
import { CurrentPreviousPeriodLegend } from '@src/components/charts/CurrentPreviousPeriodLegend'
import NoData from '@src/components/charts/NoData/NoData'
import { clientConfigStoreStateSelector } from '@src/selectors/app'
import { Text } from '@components/base'
import { DatesRange } from '../ScoreChart/types'
import { mergeChartData } from './helper'
import { useMealTypeChartRenderFunction } from './useMealTypeChartRenderFunction'

export interface MealTypeChartDataItem {
  x: string
  y: number
}

const BASIC_HEIGHT = 52
const HEIGHT_PER_ITEM = 42

interface MealTypeChartProps {
  currentData: MealTypeChartDataItem[]
  previousData: MealTypeChartDataItem[]
  datesRange: DatesRange
}

export const MealTypeChart = ({ currentData, previousData, datesRange }: MealTypeChartProps) => {
  const styles = useStyleSheet(themedStyle)

  const data = mergeChartData(currentData, previousData).filter(
    (item) => item.type === 'current' || (item.type === 'previous' && item.hasPair),
  )

  const haveDataToDisplay = some(data, { type: 'current' })

  const height = BASIC_HEIGHT + filter(data, { type: 'current' }).length * HEIGHT_PER_ITEM
  const { max: maxScore } = useSelector(clientConfigStoreStateSelector).score
  const chartScript = useMealTypeChartRenderFunction({
    data,
    maxScore,
  })

  return (
    <View style={styles.chartWrapper}>
      <View style={styles.topContainer}>
        <Text type="large" bold>
          By meal type
        </Text>
        <CurrentPreviousPeriodLegend datesRange={datesRange} />
      </View>
      {haveDataToDisplay ? (
        <CommonChart chartScript={chartScript} height={height} />
      ) : (
        <NoData style={styles.noData} />
      )}
    </View>
  )
}

const themedStyle = StyleService.create({
  chartWrapper: {
    paddingHorizontal: 16,
  },
  noData: {
    paddingVertical: 60,
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
    marginBottom: -24,
  },
})
