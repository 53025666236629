import React, { ReactNode } from 'react'
import { TouchableOpacity, View, ViewStyle } from 'react-native'
import { useTheme } from '@ui-kitten/components'
import { StyleService, useStyleSheet } from '@src/style/service'
import { IconName, Text } from '@components/base'
import { Icon } from '@components/base'

export interface NavigationCardProps {
  iconName: IconName
  pill?: ReactNode
  title: string
  onPress: () => void
  style?: ViewStyle
}

export const NavigationCard = ({ iconName, title, onPress, pill, style }: NavigationCardProps) => {
  const styles = useStyleSheet(themedStyles)
  const theme = useTheme()
  return (
    <TouchableOpacity
      style={[styles.container, style]}
      onPress={onPress}
      accessibilityLabel={title}
    >
      <View style={styles.contentContainer}>
        <View style={styles.topRow}>
          <Icon name={iconName} weight="bold" color={theme['theme.text.secondary']} />
          {pill}
        </View>
        <Text type="small">{title}</Text>
      </View>
    </TouchableOpacity>
  )
}

const themedStyles = StyleService.create({
  container: {
    flex: 1,
    borderRadius: 16,
    borderWidth: 2,
    height: 84,
    backgroundColor: 'theme.background',
    borderColor: 'theme.surface.base1',
  },
  topRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  contentContainer: {
    rowGap: 8,
    padding: 14,
  },
})
