export const DEFAULT_VIDEO_CALL_INFO = {
  title: 'Video Call',
  buttonText: 'Learn more',
}
export const INSURANCE_BASED_VIDEO_CALLS_PROMOTION_SLOGAN = '$0 out of pocket with most insurance'
export const VIDEO_CALL_CARD_DATE_FORMAT = 'ddd, MMM Do ∙ h:mma'
export const VIDEO_CALL_CARD_TIME_FORMAT = 'h:mma'
export const FULL_DAY_MONTH_AND_DATE_FORMAT = 'dddd, MMMM D'
export const VIDEO_CALL_DETAILS_MODAL_TIME_FORMAT = 'h:mm A'
export const VIDEO_CALL_POLICY_URL = 'https://www.nutrisense.io/legal/video-call-policy'
export const INSURANCE_CHECK_IN_PROGRESS_MESSAGE =
  'We are checking your insurance to make sure it covers video\
 calls with your nutritionist. You should hear back from us at least 24 hours before your call.'
export const VIDEO_CHAT_AND_PAID_ASYNC_CHAT = {
  title: 'Message your Nutritionist',
  description: 'Expect a reply in 1 business day',
  buttonText: 'Message',
}
export const NO_CURRENT_CHAT_SUPPORT = {
  title: 'Monthly Nutrition Coaching',
  description: 'Unlimited 1-1 chat',
  buttonText: 'Learn More',
}
