import pluralize from 'pluralize'
import { partition } from 'lodash'
import { Nutritionix } from '@services'
import { BaseIngredient } from '@screens/Ingredients/types'
import {
  getThirdPartyIngredientId,
  normalizeNutrisenseItem,
  normalizeNutritionixItem,
  ALLOWED_SERVING_UNITS_TO_PLURALIZE,
} from '@screens/Ingredients/utils'
import { Ingredient, IngredientSource } from '@src/types'

export const NORMAL_GRAM = 'Gram'
export const ABBREVIATED_GRAM = 'g'

export const isBaseIngredient = (item: unknown): item is BaseIngredient => {
  const { servingOptions } = item as BaseIngredient
  return !!servingOptions
}

export const pluralizeServingUnit = (servingUnit: string, amount?: number) => {
  const lastWord = servingUnit.slice(servingUnit.lastIndexOf(' ') + 1)
  const canBePluralized = ALLOWED_SERVING_UNITS_TO_PLURALIZE.has(lastWord.toLowerCase())

  return canBePluralized ? pluralize(servingUnit, amount) : servingUnit
}

export const ingredientsAreEqual = (
  a: Pick<BaseIngredient, 'id' | 'thirdPartyIngredientSource' | 'thirdPartyIngredientId'>,
  b: Pick<BaseIngredient, 'id' | 'thirdPartyIngredientSource' | 'thirdPartyIngredientId'>,
) => {
  // Nutrisense ingredients don't have `thirdPartyIngredientSource`
  if (!a.thirdPartyIngredientSource && !b.thirdPartyIngredientSource) {
    return a.id === b.id
  }

  return (
    a.thirdPartyIngredientSource === b.thirdPartyIngredientSource &&
    a.thirdPartyIngredientId === b.thirdPartyIngredientId
  )
}

export const shouldUpdateIngredientData = (ingredient: BaseIngredient | Ingredient) =>
  !isBaseIngredient(ingredient) || !ingredient.isUpToDate

export const updateIngredientsData = async (
  ingredients: (BaseIngredient | Ingredient)[],
): Promise<BaseIngredient[]> => {
  const ingredientsToUpdate = ingredients.filter(shouldUpdateIngredientData)

  if (ingredientsToUpdate.length === 0) {
    return ingredients as BaseIngredient[]
  }

  const thirdPartyIngredients = ingredientsToUpdate.filter((item) => item.thirdPartyIngredientId)
  const newIngredientsData = await getFullNutritionixData(thirdPartyIngredients)

  return ingredients.map((ingredient) => {
    if (!ingredientsToUpdate.includes(ingredient as BaseIngredient)) {
      return ingredient as BaseIngredient
    }

    const updatedIngredient = normalizeNutrisenseItem(ingredient as Ingredient)

    if (ingredient.thirdPartyIngredientSource === IngredientSource.Nutritionix) {
      // Legacy common ingredients have an id that contains the food name instead of the id (number)
      const isLegacyNutritionixIngredient = ingredient.thirdPartyIngredientId?.match(/^common\/\D+/)
      const detailedNutritionixIngredient = newIngredientsData.find((item) => {
        const itemId = isLegacyNutritionixIngredient
          ? `common/${item.food_name}`
          : getThirdPartyIngredientId(item)

        return itemId === ingredient.thirdPartyIngredientId
      })

      if (!detailedNutritionixIngredient) {
        return updatedIngredient
      }

      const baseIngredient = normalizeNutritionixItem(detailedNutritionixIngredient)

      const servingOptionForChosenServingUnit = baseIngredient.servingOptions.find(
        (option) => option.name === updatedIngredient.servingOptions[0].name,
      )
      const servingOptions = servingOptionForChosenServingUnit
        ? baseIngredient.servingOptions
        : [...baseIngredient.servingOptions, updatedIngredient.servingOptions[0]]

      return {
        ...updatedIngredient,
        id: baseIngredient.id,
        description: baseIngredient.description,
        servingOptions,
        imageURL: baseIngredient.imageURL,
        thirdPartyIngredientId: baseIngredient.thirdPartyIngredientId,
        thirdPartyIngredientSource: baseIngredient.thirdPartyIngredientSource,
      }
    }

    return updatedIngredient
  })
}

export const getFullNutritionixData = (ingredients: (BaseIngredient | Ingredient)[]) => {
  const allIngredientsAreFromNutritionix = ingredients.every(
    (ingredient) => ingredient.thirdPartyIngredientSource === IngredientSource.Nutritionix,
  )

  if (!allIngredientsAreFromNutritionix) {
    return []
  }

  const [common, branded] = partition(ingredients, (item) =>
    item.thirdPartyIngredientId?.startsWith('common/'),
  )
  const commonIngredientsPromises = common.map((item) =>
    Nutritionix.searchItemsWithNutrients(item.description),
  )
  const brandedIngredientsPromises = branded.map((item) => {
    const ingredientId = item.thirdPartyIngredientId?.replace('branded/', '')
    return Nutritionix.getBrandedItemById(ingredientId as string)
  })

  return Promise.all([
    ...commonIngredientsPromises,
    ...brandedIngredientsPromises,
  ]).then((responses) => responses.flat().filter((item) => !!item))
}
